import { createSlice } from "@reduxjs/toolkit";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  permissions: [],
};

const PermissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    getPermissions: (state) => {
      state.loading = true;
    },
    getPermissionsSuccess: (state, { payload }) => {
      state.permissions = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getPermissionsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getPermissions,
  getPermissionsSuccess,
  getPermissionsFailure,
} = PermissionsSlice.actions;
export const PermissionsSelector = (state) => state.permissions;
export default PermissionsSlice.reducer;

export function fetchPermissionsService(orgId) {
  return async (dispatch) => {
    dispatch(getPermissions());

    try {
      const response = await Api.getPermissions(orgId);

      dispatch(getPermissionsSuccess(response));
    } catch (error) {
      dispatch(getPermissionsFailure(error));
    }
  };
}

export function fetchPermissionsInventoryService() {
  return async (dispatch) => {
    dispatch(getPermissions());
    try {
      const response = await Api.getPermissionsInventory();
      dispatch(getPermissionsSuccess(response));
    } catch (error) {
      dispatch(getPermissionsFailure(error));
    }
  };
}
