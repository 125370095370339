import React, { useEffect, useState, useCallback } from "react";
import { CircularProgress, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

const filterOptions = createFilterOptions({
    // matchFrom: "any",

    stringify: (option) => option.title + option.phone /*+ option.email*/,
});

function filterBySpaceSeparatedInputValue(options, state) {
    const terms = state.inputValue.split(" ").map((term) => {
        return new RegExp(term, "i");
    });
    return options.filter((option) => {
        return terms.some((term) => {
            return term.test(option.name + option.phone + option.email);
        });
    });
}


//var searchText = "";
//var pendingSearchText = null;
//var isLoadingData = false;

export default function AutoCompleteAsyncGeneric(props) {
    const {
        user,
        label,
        onChange,
        value,
        disabled,
        disableClear,
        required,
        id,
        isLoading,
        optionsTitle,
        optionsId,
        className,
        options,
        fullWidth = false,
        sliceData,
        sliceGet,
        mapResults,
    } = props;
    const { authUser } = useSelector((state) => state.auth);
    const [open, setOpen] = useState(false);
    const [localoptions, setLocalOptions] = useState([]);

    const [inputValue, setInputValue] = useState('');
    const [selectvalue, setSelectValue] = useState(null);
    const [loading, setLoading] = useState(false);

    var sliceDataEntity = {
        getFilters: {
            include: [],
            limit: 5,
            page: 1,
            params: [],
            filters: [],
            search: "",
        },
        dataList: {
            showPageLoading: false,
            successHandle: null,
        },
        errorHandle: null,
    };




    const dispatch = useDispatch();

    //debounce save
    const debouncedSave = useCallback(
        debounce((newValue) =>
            loadData(newValue)
            , 500),
        []
    );
    //set loading when open without options
    useEffect(() => {
        setLoading(open && localoptions.length === 0);
    }, [open, localoptions])

    //update localslice based on external
    useEffect(() => {
        sliceDataEntity = sliceData ? sliceData : sliceDataEntity;
        //sliceDataEntity.dataList = !sliceDataEntity.dataList
        //    ? {}
        //    : sliceDataEntity.dataList;
    }, [])

    //update local options when external change
    useEffect(() => {
        //console.log('options changed')
        
        setLocalOptions(mapResults?mapResults(options):options);
        if (options && options.length == 0) {
            setSelectValue(null);
        }
    }, [options])

    //when selected value change
    useEffect(() => {
        //console.log('change value')
        onChange(selectvalue);
    }, [selectvalue])

    //when input value change
    useEffect(() => {
        if (selectvalue == null
            || selectvalue[optionsTitle] != inputValue) {
            //setSelectValue(null);
            debouncedSave(inputValue);
        }
        if (!inputValue) setLoading(false);
    }, [inputValue])


    const loadData = (search) => {
        if (search) {  //no search for empty
            setLoading(true);
            //sliceDataEntity.getFilters.search = inputValue;
            //setSelectValue('');
            //setInputValue(search);

            sliceDataEntity.getFilters.search = search;
            sliceDataEntity.dataList.successHandle = loadDataSuccess;
            sliceDataEntity.errorHandle = errorHandle
            dispatch(sliceGet(sliceDataEntity));
            // console.log('make request');
        }
    };

    const errorHandle = (e) => {
        console.log('error request:');
        console.log(e);
        setLoading(false);
    }

    const loadDataSuccess = () => {
        // console.log('request ok');
        //setSelectValue(null);
        setLoading(false);
    };



    return (
        <Autocomplete
            id={id ? id : "new"}
            open={open}
            autoHighlight
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={selectvalue}
            className={className}
            includeInputInList={true}
            getOptionSelected={(option, value) =>
                option[optionsId]
                    ? option[optionsId] === value[optionsId]
                    : false}
            getOptionLabel={
                (option) =>
                    option[optionsTitle]
                        ? option[optionsTitle]
                        : ''
            }
            inputValue={inputValue /*? inputValue : value ? value.name : ""*/}
            options={localoptions}
            onChange={(event, newSelectValue) => {
                setSelectValue(newSelectValue);
            }}
            fullWidth={fullWidth}
            loading={loading}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label ? label : ""}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
