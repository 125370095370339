import React, { useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { Grid, makeStyles, Box } from "@material-ui/core";
import { Tip } from "../controls/Tip";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  error: {
    margin: '0px',
    marginLeft: 10,
    color: "#d73131",
    fontWeight: 600,
  },
}));

export default function SocialPlatformForm(props) {
  const { socialPlatformForEdit, onSaveSocialPlatform, socialPlatforms } =
    props;
  const { handleSubmit, reset, control, errors } = useForm();

  const classes = useStyles();

  const onSubmit = (data) => {
    onSaveSocialPlatform(data);
  };

  const socialOptions = () => {
    return socialPlatforms.map((social) => {
      return {
        id: social.id,
        title: social.name,
      };
    });
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (socialPlatformForEdit) {
      // console.log("PLAT EDIT:",socialPlatformForEdit);
    }
  }, [socialPlatformForEdit]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Box m={4}>
      <Tip title={`Notice: `} message={`* Indicates a required field`} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name="socialplatform_id"
              control={control}
              defaultValue={socialPlatformForEdit?.id}
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Social Platform*"
                  options={socialOptions()}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{
                required: "*Social platform is required",
              }}
            />
            {errors.socialplatform_id && (
              <p className={classes.error}>{errors.socialplatform_id.message}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="account_name"
              control={control}
              defaultValue={
                socialPlatformForEdit
                  ? socialPlatformForEdit.pivot.account_name
                  : ""
              }
              as={<Controls.Input label="Account*" />}
              rules={{ required: "*Account is required" }}
            />
            {errors.account_name && (
              <p className={classes.error}>{errors.account_name.message}</p>
            )}
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="followers"
              control={control}
              defaultValue={
                socialPlatformForEdit
                  ? socialPlatformForEdit.pivot.followers
                  : 0
              }
              as={
                <Controls.Input
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  type="number"
                  label="Followers"
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ padding: "5px", display: "grid", justifyContent: "end" }}>
        <Controls.Button type="submit" text="Submit" />
      </div>
    </form>
  );
}
