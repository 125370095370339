import React, { useEffect, useRef } from "react";

import {
  makeStyles,
  Grid,
  Badge,
  IconButton as MuiIconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  Fade,
  TextField,
  Menu,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  DialogContentText,
  Button,
  CircularProgress,
  Paper,
  RadioGroup,
  Radio,
  FormControlLabel,
  Popper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Fragment } from "react";

//icons
import SubjectIcon from "@material-ui/icons/Subject";
import CommentIcon from "@material-ui/icons/Comment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FlagIcon from "@material-ui/icons/Flag";
import CloseIcon from "@material-ui/icons/Close";
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  badge: {
    marginRight: 20,
  },
  menu_grid: {
    padding: 0,
    margin: 0,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogPaper: {
    flex: 1,
  },
  dialogContent: {
    minHeight: 150,
  },
  stepper: {
    flex: 1,
  },
  center: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  alignCenter: {
    textAlign: "center",
  },
  centerCenter: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  stepCompleted: {
    color: "green !important",
  },
  stepActive: {
    color: "green !important",
  },
  stepInactive: {
    color: "gray",
  },
  buttonProgress: {
    position: "absolute",
  },
  textArea: {
    width: "80%",
  },
  pollPopper: {
    zIndex: theme.zIndex.drawer + 1,
    cursor: "pointer",
  },
  popperPaper: {
    //padding: theme.spacing(2)
  },
  popperArrow: {
    width: 0,
    height: 0,
    borderLeft: "1em solid transparent",
    borderRight: "1em solid transparent",
    borderBottom: "1em solid transparent",
    marginTop: "-0.9em",
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&:before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: "transparent transparent white transparent",
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  popperClose: {
    position: "absolute",
    right: "0.1rem",
    top: "0.1rem",
    fontSize: "medium",
    "&:hover": {
      color: "red",
    },
  },
}));

import { fetchPoolByContactService } from "../../pollModule/slices/pollContactSlice";
import { fetchQuestByPollByContact } from "../../pollModule/slices/pollQuestionSlice";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../../components/Popup";
import Api from "../../../lib/api";

//redux
var dataSlicePoll = {
  stateListName: "",
  dataUrl: {},
  getFilters: {
    include: [],
    limit: 0,
    page: 0,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceQuestion = {
  stateListName: "",
  dataUrl: {},
  getFilters: {
    include: [],
    limit: 0,
    page: 0,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const QuestionStepper = (props) => {
  const { pollView } = props;
  if (!Boolean(pollView)) return null;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [questions, setQuestions] = React.useState([]);
  const [, setRender] = React.useState();
  const [fullysubmited, setFullySubmited] = React.useState(false);
  const [waitToClose, setWaitToClose] = React.useState(false);

  const { t } = useTranslation(["poll"]);

  const { authUser } = useSelector((state) => state.auth);

  //selector
  const { pollQuestion, loading, pollQuestionLoading } = useSelector(
    (state) => state.pollQuestion
  );

  useEffect(() => {
    let start_step = 0;
    const questions = pollQuestion.map((q, index) => {
      var value = "";
      var submited = false;

      if (q.response) {
        value = q.is_input
          ? q.response.data.response
          : q.response.data.option_id;
        submited = true;
      }
      return {
        ...q,
        value: value,
        submited: submited,
      };
    });

    start_step =
      questions.filter((q) => !q.submited).length > 0
        ? questions
            .map((q, i) => ({ index: i, ...q }))
            .filter((q) => !q.submited)[0].index
        : questions.length;

    setQuestions(questions);
    setActiveStep(start_step);
    if (questions.filter((q) => q.submited == false).length == 0)
      setFullySubmited(true);
    //return () => { setQuestions([]);setActiveStep(0); setRender({}) }
  }, [pollQuestion]);

  //wait the form to be fully submited and waiting to close
  useEffect(() => {
    if (fullysubmited && waitToClose) props.handleClose();
  }, [fullysubmited, waitToClose]);

  useEffect(() => {
    dataSliceQuestion.getFilters.params["id"] = pollView.id;
    dataSliceQuestion.getFilters.include = ["options", "response"];
    dispatch(fetchQuestByPollByContact(dataSliceQuestion));
  }, [pollView]);

  const setOptionResponse = async (response_data) => {
    let { cur_step, question_id, contact_id, option_id, response } =
      response_data;
      
    if (questions[cur_step].is_input) {
      try {
        const { data } = await Api.setPollQuestionResponse(
          contact_id,
          question_id,
          option_id,
          response
        ).catch((e) => {
          console.log(e);
        });
        let r = data.data;
        let value = r.option_id == null ? r.response : r.option_id;
        if (
          questions[cur_step].id == r.question_id &&
          questions[cur_step].value == value
        ) {
          questions[cur_step].submited = true;
        }
        if (questions.filter((q) => q.submited == false).length == 0)
          setFullySubmited(true);
        setRender({});
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleNext = () => {
    let cur_step = activeStep;
    if (cur_step < questions.length && questions[cur_step].submited == false) {
      //solo enviar la solicitud si se cambia
      setOptionResponse({
        contact_id: authUser.id,
        question_id: questions[cur_step].id,
        option_id: questions[cur_step].is_input
          ? null
          : questions[cur_step].value,
        response: questions[cur_step].is_input ? questions[cur_step].value : "",
        cur_step: cur_step,
      });
    }

    if (activeStep == questions.length) setWaitToClose(true);
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    let cur_step = activeStep;
    if (cur_step < questions.length && questions[cur_step].submited == false) {
      //solo enviar la solicitud si se cambia
      setOptionResponse({
        contact_id: authUser.id,
        question_id: questions[cur_step].id,
        option_id: questions[cur_step].is_input
          ? null
          : questions[cur_step].value,
        response: questions[cur_step].is_input ? questions[cur_step].value : "",
        cur_step: cur_step,
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const radioSelect = (event) => {
    var previous = questions;
    previous[activeStep].value = previous[activeStep].is_input
      ? event.target.value
      : Number(event.target.value);
    previous[activeStep].submited = false;

    setQuestions(previous);
    setRender({}); //force render because react doesn detect change on complex objects
  };

  const FinalStepperFlag = (props) => {
    const { active, completed } = props;
    return (
      <FlagIcon
        className={active ? classes.stepActive : classes.stepInactive}
      />
    );
  };

  return (
    <Popup
      openPopup={Boolean(pollView)}
      onClose={props.handleClose}
      classes={{
        dialog: classes.dialog,
        paper: classes.dialogPaper,
      }}
      title={pollView.title}
    >
      <DialogContent className={classes.dialogContent}>
        {pollQuestionLoading ? (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        ) : (
          <DialogContentText>
            <Fragment>
              {questions.map((question, q_index) => (
                <TabPanel
                  key={q_index}
                  className={classes.menu_grid}
                  value={activeStep}
                  index={q_index}
                >
                  <Typography variant="h6" style={{ marginBottom: 15 }}>
                    {question.title}
                  </Typography>
                  {question.is_input ? (
                    <TextField
                      multiline
                      autoFocus
                      value={question.value}
                      onChange={radioSelect}
                      maxRows={4}
                      className={classes.textArea}
                    />
                  ) : (
                    <RadioGroup
                      aria-label="select"
                      name={`question.id`}
                      value={question.value}
                      onChange={radioSelect}
                    >
                      {question.options.data.map((option, k_option) => (
                        <FormControlLabel
                          value={option.id}
                          control={<Radio />}
                          label={option.title}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </TabPanel>
              ))}
              <TabPanel
                key={questions.length}
                className={classes.menu_grid}
                value={activeStep}
                index={questions.length}
              >
                <div className={classes.centerCenter}>
                  <Typography variant="h5">
                    {t("portal.steeper.thank_you")}
                  </Typography>
                  <CheckCircleOutlineIcon />
                </div>
              </TabPanel>
            </Fragment>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {!pollQuestionLoading ? (
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {questions.map((question, index) => (
              <Step key={index}>
                {question.submited ? (
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: classes.stepCompleted,
                        active: classes.stepActive,
                      },
                    }}
                  />
                ) : (
                  <StepLabel />
                )}
              </Step>
            ))}
            <Step key={questions.length}>
              <StepLabel StepIconComponent={FinalStepperFlag} />
            </Step>
          </Stepper>
        ) : (
          ""
        )}
        <Button
          disabled={activeStep === 0 || pollQuestionLoading || waitToClose}
          onClick={handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={
            pollQuestionLoading ||
            waitToClose ||
            (questions.length > 0 &&
              activeStep < questions.length &&
              questions[activeStep].is_input &&
              (questions[activeStep].value == 0 ||
                questions[activeStep].value == ""))
          }
        >
          {activeStep === pollQuestion.length
            ? t("portal.steeper.finish")
            : t("portal.steeper.next")}
          {waitToClose && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Popup>
  );
};

const PollMenu = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [items, setItems] = React.useState(null);
  const [total_items, setTotalItems] = React.useState(0);
  const [pollView, setPollView] = React.useState(null);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [popperText, setpopperText] = React.useState("");
  const [timerPopper, setTimerPopper] = React.useState(null);
  const [timerClosePopper, setTimerClosePopper] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation(["poll"]);

  const iconButtonref = useRef();

  const { pollContacts, pollContactsLoading } = useSelector(
    (state) => state.pollContacts
  );
  const { authUser } = useSelector((state) => state.auth);

  const updatePoll = () => {
    dataSlicePoll.getFilters.params["contact_id"] = authUser.id;
    dataSlicePoll.getFilters.include = ["cases", "questions"];
    dispatch(fetchPoolByContactService(dataSlicePoll));
  };
  useEffect(() => {
    //dataSlicePoll.dataList.successHandle = pollgetSuccess;
    updatePoll();
  }, [authUser, props.interval]);

  useEffect(() => {
    var poll_with_cases = pollContacts.filter(
      (polls) => polls.cases.data.length > 0
    );
    var poll_without_cases = pollContacts.filter(
      (polls) => polls.cases.data.length == 0
    );

    if (poll_with_cases.length > 0 || poll_without_cases.length > 0) {
      poll_with_cases = poll_with_cases.map((p) => ({
        ...p,
        title: p.title + "(continue)",
      }));
      var items = [...poll_with_cases, ...poll_without_cases];

      setItems(items);
      setTotalItems(items.length);
      if (!timerPopper) {
        setTimerPopper(
          setTimeout(() => {
            let total = items.length;
            handleOpenPopper();
          }, 2000)
        );
      }
    } else {
      setItems(null);
      setTotalItems(0);
      setTimerPopper(null);
    }
  }, [pollContacts]);

  const handlePollClick = (poll) => {
    setPollView(poll);
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setPopperAnchorEl(null);
    setAnchorEl(iconButtonref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseStepper = () => {
    setPollView(null);
    updatePoll();
  };

  const handleOpenPopper = () => {
    setPopperAnchorEl(iconButtonref.current ? iconButtonref.current : null);
    setTimeout(() => {
      setPopperAnchorEl(null);
      setpopperText("");
    }, 5000);
  };

  const handleClosePopper = (e) => {
    e.stopPropagation();
    setPopperAnchorEl(null);
  };

  if (items == null) return null;

  const PollPoper = (props) => {
    const [arrowRef, setArrowRef] = React.useState(null);

    return (
      <>
        <Popper
          key="PollPopper"
          anchorEl={props.anchorEl}
          open={props.open}
          className={classes.pollPopper}
          placement="bottom-end"
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          onClick={handleMenu}
        >
          <span
            ref={(node) => setArrowRef(node)}
            className={classes.popperArrow}
          ></span>
          <CloseIcon
            className={classes.popperClose}
            onClick={props.handleClose}
          />
          <Fade in={props.open}>
            <Paper className={classes.popperPaper}>
              <DialogTitle>{t("portal.menu.poll_available_title")}</DialogTitle>
              <DialogContent>
                <Typography variant="body1">{props.text}</Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={props.handleClose}>
                  {t("portal.menu.poll_available_action_later")}
                </Button>
              </DialogActions>
            </Paper>
          </Fade>
        </Popper>
      </>
    );
  };

  return (
    <Fade in={Boolean(items)}>
      <Fragment>
        <IconButton
          ref={(node) => (iconButtonref.current = node)}
          aria-label="polls menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {total_items > 0 ? (
            <Badge badgeContent={total_items} style={{ color: "#607d8b" }}>
              <SubjectIcon />
            </Badge>
          ) : (
            <SubjectIcon />
          )}
        </IconButton>
        <PollPoper
          anchorEl={popperAnchorEl}
          open={Boolean(popperAnchorEl)}
          text={t("portal.menu.poll_available")}
          handleClose={handleClosePopper}
        />
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            {items == null ? (
              <CircularProgress />
            ) : (
              <Grid container direction="column" className={classes.menu_grid}>
                <Grid item>
                  <List
                    className={classes.menu_grid}
                    aria-label="main mailbox folders"
                  >
                    {items.map((poll, polls_index) => (
                      <ListItem
                        button
                        key={polls_index}
                        onClick={() => handlePollClick(poll)}
                      >
                        <ListItemIcon>
                          {poll.questions.data.length > 0 ? (
                            <Badge
                              className={classes.badge}
                              badgeContent={poll.questions.data.length}
                              color="primary"
                            >
                              <CommentIcon />
                            </Badge>
                          ) : (
                            <CommentIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={poll.title} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
          </MenuItem>
        </Menu>
        <QuestionStepper pollView={pollView} handleClose={handleCloseStepper} />
      </Fragment>
    </Fade>
  );
};

export default PollMenu;
