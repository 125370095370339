import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Fab,
  Grid,
  InputAdornment,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import Iconify from "../Iconify";
import { fetchEntitiesService as fetchFraudReasonsService } from "../../redux/slices/fraudolentContactsReasonsSlice";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../lib/api";

const useStyles = makeStyles((theme) => ({
    error: {
        margin: 10,
        marginTop: 0,
        color: "#ff1616",
      },
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  iconStyle: {
    marginRight: 5,
    fontSize: 18,
    color: "#577a8f",
  },
  fab: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '11px',
    background: '#dfe5eb'
  }
}));

var dataSliceFraudulentContactsReasons = {
  stateListName: "fraudulentContactsReasons",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

export default function FraudolentContactForm(props) {
  const { authUser } = useSelector((state) => state.auth);
  const { entityForEdit, setEntityForEdit, setOpenFraudPopup,userCanUnmarkContacts = false, disabledAll = false } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState(
    !isEmpty(entityForEdit.fraudolent.data) &&
      entityForEdit.fraudolent.data.reason_id
      ? entityForEdit.fraudolent.data.reason.data
      : ""
  );
  const classes = useStyles();
  const [proof, setProof] = useState(
    entityForEdit &&
      !isEmpty(entityForEdit.fraudolent.data) &&
      entityForEdit.fraudolent.data.file
      ? entityForEdit.fraudolent.data.file
      : ""
  );
  const { fraudulentContactsReasons } = useSelector(
    (state) => state.fraudulentContactsReasons
  );
  const dispatch = useDispatch();

  const onSubmit = async (payload) => {
    setIsLoading(true);

    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.setContactAsFraudolent(
      entityForEdit.id,
      formData
    );

    setIsLoading(false);
    setEntityForEdit({
      ...entityForEdit,
      ...{ fraudolent: { data: { ...data.data } } },
    });
    setOpenFraudPopup(false);
  };

  const getFraudReasonsOptions = () => {
    return fraudulentContactsReasons.map((reason) => {
      return {
        id: reason.id,
        title: reason.name,
      };
    });
  };

  useEffect(() => {
    if (isEmpty(fraudulentContactsReasons)) {
      dispatch(fetchFraudReasonsService(dataSliceFraudulentContactsReasons));
    }
  }, []);

  useEffect(() => {
    console.log(entityForEdit);
  }, [entityForEdit]);

  useEffect(() => {
    console.log("selectedReason: ", selectedReason);
  }, [selectedReason]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container style={{alignItems: 'center'}} spacing={0}>
        <Grid item xs={proof ? 0 : 1}>
          {proof ? (
            <Tooltip title="View proof">
              <a href={proof} target="_blank" rel="noreferrer">
                <Fab className={classes.fab}>
                  <Iconify style={{fontSize:20, color: '#001c5a'}} icon="mdi:attach-file" />
                </Fab>
              </a>
            </Tooltip>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={proof ? 11 : 12}>
          <Controller
            control={control}
            name="file"
            defaultValue={proof}
            as={({ onChange, value }) => (
              <Controls.DropzoneDialog
                startIcon={<Iconify icon="ph:upload-simple-bold" />}
                disableSubmitButton={
                  isEmpty(entityForEdit.fraudolent.data)
                    ? false
                    : (!isEmpty(entityForEdit.fraudolent.data) &&
                      authUser.id != entityForEdit.fraudolent.data.created_by && !userCanUnmarkContacts) || disabledAll
                    ? true
                    : false
                }
                label="Upload Proof"
                onChange={(files) => {
                  setProof(window.URL.createObjectURL(files[0]));
                  onChange(files[0]);
                }}
                value={value}
              />
            )}
            rules={{ required: "Proof is required" }}
          />
          {errors.file && <p className={classes.error}>{errors.file.message}</p>}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={selectedReason && selectedReason.card_required ? 6 : 12}>
          <Controller
            disabled={
              isEmpty(entityForEdit.fraudolent.data)
                ? false
                : (!isEmpty(entityForEdit.fraudolent.data) &&
                  authUser.id != entityForEdit.fraudolent.data.created_by && !userCanUnmarkContacts) || disabledAll
                ? true
                : false
            }
            name="reason_id"
            control={control}
            defaultValue={selectedReason ? selectedReason.id : null}
            as={({ onChange, value }) => (
              <Controls.Select
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      className={classes.iconStyle}
                      icon="material-symbols:notes-rounded"
                    />
                  </InputAdornment>
                }
                disabled={
                  isEmpty(entityForEdit.fraudolent.data)
                    ? false
                    : (!isEmpty(entityForEdit.fraudolent.data) &&
                      authUser.id != entityForEdit.fraudolent.data.created_by && !userCanUnmarkContacts)  || disabledAll
                    ? true
                    : false
                }
                label="*Fraud Reason"
                options={getFraudReasonsOptions()}
                value={value}
                onChange={(value) => {
                  onChange(value);
                  setSelectedReason(
                    fraudulentContactsReasons.find(
                      (a) => a.id == value.target.value
                    )
                  );
                }}
              />
            )}
            rules={{ required: "Reason is required" }}
          />
          {errors.reason_id && (
            <p className={classes.error}>{errors.reason_id.message}</p>
          )}
        </Grid>

        <Grid item xs={selectedReason && selectedReason.card_required ? 6 : 0}>
          {selectedReason && selectedReason.card_required ? (
            <>
              <Controller
                name="card_number"
                control={control}
                defaultValue={
                  !isEmpty(entityForEdit.fraudolent.data) &&
                  entityForEdit.fraudolent.data.card_number
                    ? entityForEdit.fraudolent.data.card_number
                    : ""
                }
                as={
                  <Controls.Input
                    disabled={
                      isEmpty(entityForEdit.fraudolent.data)
                        ? false
                        : (!isEmpty(entityForEdit.fraudolent.data) &&
                          authUser.id !=
                            entityForEdit.fraudolent.data.created_by && !userCanUnmarkContacts) || disabledAll
                        ? true
                        : false
                    }
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <div className={classes.iconStyle}>
                          <Iconify icon="bi:credit-card-2-back-fill" />
                        </div>
                      ),
                    }}
                    label="*Card Number"
                  />
                }
                rules={{ required: "Card number is required" }}
              />
              {errors.card_number && (
                <p className={classes.error}>{errors.card_number.message}</p>
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Controller
        disabled={
          isEmpty(entityForEdit.fraudolent.data)
            ? false
            : (!isEmpty(entityForEdit.fraudolent.data) &&
              authUser.id != entityForEdit.fraudolent.data.created_by && !userCanUnmarkContacts) ||  disabledAll
            ? true
            : false
        }
        name="comment"
        control={control}
        defaultValue={
          !isEmpty(entityForEdit.fraudolent.data) &&
          entityForEdit.fraudolent.data.comment
            ? entityForEdit.fraudolent.data.comment
            : ""
        }
        as={
          <Controls.Textarea
          rows={3}
            disabled={
              isEmpty(entityForEdit.fraudolent.data)
                ? false
                : (!isEmpty(entityForEdit.fraudolent.data) &&
                  authUser.id != entityForEdit.fraudolent.data.created_by && !userCanUnmarkContacts) ||  disabledAll
                ? true
                : false
            }
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <div className={classes.iconStyle}>
                  <Iconify icon="fa6-solid:comment-dots" />
                </div>
              ),
            }}
            label="Comment"
          />
        }
      />

      <div>
        <Controls.Button
          disabled={
            isEmpty(entityForEdit.fraudolent.data)
              ? false
              : isLoading ||
                (!isEmpty(entityForEdit.fraudolent.data) &&
                  authUser.id != entityForEdit.fraudolent.data.created_by  && !userCanUnmarkContacts) ||  disabledAll
              ? true
              : false
          }
          style={{ marginTop: 8, marginLeft: 6 }}
          startIcon={
            isLoading ? (
              <CircularProgress style={{ width: 19, height: 19 }} />
            ) : (
              ""
            )
          }
          type="submit"
          text="Submit"
        />
      </div>
    </form>
  );
}
