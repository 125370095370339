import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CommentIcon from "@material-ui/icons/Comment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { useTranslation } from "react-i18next";

import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DraftsIcon from "@material-ui/icons/Drafts";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import FaceIcon from "@material-ui/icons/Face";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DialerSipIcon from "@material-ui/icons/DialerSip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import MicOffIcon from "@material-ui/icons/MicOff";

import Api from "../../lib/api";
import Controls from "../../components/controls";
//import CRMUtils from "../../utils";

import CallMissedIcon from "@material-ui/icons/CallMissed";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";

import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";

import InfoIcon from "@material-ui/icons/Info";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";

import {
  /*Paper,
  Card as MuiCard,
  makeStyles,
  Backdrop,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  CircularProgress,
  Grid,
  Card,
  Box,
  AppBar,
  FormControl,
  FormLabel,
  FormGroup,
  Popover,*/
  IconButton,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  iconSet: {
    '& MuiTimelineItem-oppositeContent .':{
      margin: "2px"
    }
  },
  iconPlay: {
    backgroundColor: "#CCC",
  },
}));

export default function CallTimeline(props) {
  const classes = useStyles();

  const { data, users = null, pbxInformation = null } = props;

  //Permissions
  /*const pbxInformation = CRMUtils.userHasPermission(authUser, [
    "user_pbxInformation",
  ]);*/

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";

  const { t, i18n } = useTranslation(["common", "records"]);

  const [recording, setRecording] = useState(null);

  const getRecording = async (domain, call) => {
    const { data } = await Api.getRecording(domain, call);
    setRecording(data);
    //setOpenLogPopup(true);
  };

  let backendServer = process.env.REACT_APP_API_ENDPOINT;

  const getUserName = (id) => {
    let tmp = {};
    if (users && id)
      tmp = users.find((user) => {
        return user.id === id;
      });
    // console.error("tmp", tmp);
    if (tmp && tmp.dropdown_name) return tmp.dropdown_name;
    else return "No Info...";
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          created by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          {data.object.direction === "outbound" ? (
            <AddIcCallIcon />
          ) : (
            <PhoneCallbackIcon />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {data.object.direction === "inbound"
              ? "Contact did a call to ext. " +
                data.object.to +
                " (" +
                getUserName(data.object.to_id) +
                ")"
              : "Contact was called by ext. " +
                data.object.from +
                " (" +
                getUserName(data.object.from_id) +
                ")"}
          </Typography>

          <div className={classes.iconSet}>
            <DialerSipIcon color="primary" />
            {data.object.direction === "inbound" ? (
              <Tooltip title="Inbound Call (Received Call)">
                <CallReceivedIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Outbound Call (Made Call)">
                <CallMadeIcon />
              </Tooltip>
            )}
            {data.object.final_state === "answered" ? (
              <Tooltip title="Answered Call">
                <CallIcon />
              </Tooltip>
            ) : data.object.final_state === "rejected" ? (
              <Tooltip title="Rejected Call">
                <CallEndIcon />
              </Tooltip>
            ) : data.object.final_state === "voicemail" ? (
              <Tooltip title="Voice Mail">
                <VoicemailIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Missed Call">
                <CallMissedIcon />
              </Tooltip>
            )}
            {/*<Typography>Call uuid:{data.object.pbx_uuid}</Typography>*/}

            {data.object.aditional_info && data.object.aditional_info != "" && (
              <Tooltip title={data.object.aditional_info}>
                <InfoIcon />
              </Tooltip>
            )}

              {/*
              <IconButton
                color="primary"
                aria-label="Get Record"
                size="small"
                component="span"
                className={classes.iconPlay}
              >
                <RecordVoiceOverIcon />
              </IconButton>
              */}


            {!recording && pbxInformation && (
            <Controls.Button
              variant="contained"
              startIcon={<RecordVoiceOverIcon />}
              color="secondary"
              disabled={false}
              text={"Get Record@"}
              onClick={() => {
                getRecording(data.object.pbx_domain, data.object.pbx_recording);
              }}
            />
            )}
            {recording && recording !== "NO_VALID_FILE" && pbxInformation && (
              <audio controls autoplay name="media">
                <source
                  src={backendServer.replace("com/api", "com") + recording}
                  type={"audio/mpeg"}
                ></source>
              </audio>
            )}
            {recording && recording === "NO_VALID_FILE" && (
              <p>
                <MicOffIcon /> No Record available!
              </p>
            )}
            {!pbxInformation && (
              <p>
                <MicOffIcon /> No Permission!
              </p>
            )}
          </div>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
  {
    /*<iframe
              src={
                process.env.REACT_APP_API_ENDPOINT.replace("/api", "") +
                recording
              }
              width="300"
              height="200"
            ></iframe> */
  }
}
