import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
  _updateOnlyEntityService,
  _updateOnlyMessageCountService,
  _unreadMessageCountService,
  _changeColorService,
  _changeIsImportantService,
  _addIsImportantService,
  _changeBodyService,
  _addOnlyEntityService,
  _updateOnlyWhatsAppConfirmedService
} from "./common/sliceFunctionsSmsMms";

var entitiesName = "userContactsSMSMMS";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_USERCONTACTSMSMMS";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}
export function updateOnlyMessageCountService(payload) {
  return _updateOnlyMessageCountService(payload, events);
}

export function unreadMessageCountService(payload) {
  return _unreadMessageCountService(payload, events);
}

export function changeColorService(payload) {
  return _changeColorService(payload, events);
}

export function changeIsImportantService(payload) {
  return _changeIsImportantService(payload, events);
}

export function addIsImportantService(payload) {
  return _addIsImportantService(payload, events);
}

export function changeBodyService(payload) {
  return _changeBodyService(payload, events);
}

export function addOnlyEntityService(payload) {
  return _addOnlyEntityService(payload, events);
}

export function updateOnlyWhatsAppConfirmedService(payload) {
  return _updateOnlyWhatsAppConfirmedService(payload, events);
}


