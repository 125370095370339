import React  from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textfield: {
    borderRadius: 10,

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

export default function CustomSelect(props) {
  const {
    styleProp = null,
    label,
    value,
    allowEmpty,
    emptyLabel,
    onChange,
    options,
    disabled,
    variant,
    ...other
  } = props;
  const classes = useStyles();

  return (
    <FormControl
      style={{ width: styleProp ? "100%" : "" }}
      variant={variant ? variant : "outlined"}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        renderValue={(value) => {
          return options.find((a) => a.id == value)
            ? options.find((a) => a.id == value).title
            : null;
        }}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...other}
        className={classes.textfield}
      >
        {allowEmpty && (
          <MenuItem value={-1}>{emptyLabel ? emptyLabel : "None"}</MenuItem>
        )}

        {options != undefined &&
          options.map((item) =>
            item.disabled && item.qtyWaitList == 0 ? (
              <Tooltip title="Not available for the selected date">
                <span>
                  <MenuItem disabled={true} key={item.id} value={item.id}>
                    <Typography style={{ width: "100%" }}>
                      {item.title}
                    </Typography>
                    {item.available != null ? (
                      <Tooltip title="Quantity available">
                        <Typography
                          style={{
                            color: "red",
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {item.available}
                        </Typography>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </MenuItem>
                </span>
              </Tooltip>
            ) : (
              <MenuItem key={item.id} value={item.id}>
                <Typography style={{ width: "100%" }}>{item.title}</Typography>
                {item.available != null ||
                (item.disabled && item.qtyWaitList > 0) ? (
                  <Tooltip title="Spaces available">
                    <Typography
                      style={{
                        color:
                          item.disabled && item.qtyWaitList > 0
                            ? "rgb(86 146 192)"
                            : "rgb(0 187 96)",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      {item.disabled && item.qtyWaitList > 0
                        ? item.qtyWaitList + " on wait list"
                        : item.available}
                    </Typography>
                  </Tooltip>
                ) : (
                  ""
                )}
              </MenuItem>
            )
          )}
      </MuiSelect>
    </FormControl>
  );
}
