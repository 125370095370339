import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  organizations: [],
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    getOrganizations: (state) => {
      state.loading = true;
    },
    getOrganizationsSuccess: (state, { payload }) => {
      state.organizations = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getOrganizationsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createOrganization: (state) => {},
    createOrganizationSuccess: (state, { payload }) => {
      state.organizations = [...state.organizations, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createOrganizationFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateOrganization: (state) => {},
    updateOrganizationSuccess: (state, { payload }) => {
      const index = findIndex(state.organizations, ["id", payload.data.id]);
      const organization = { ...state.organizations[index], ...payload.data };

      let organizations = [...state.organizations];
      organizations[index] = organization;

      state.organizations = [...organizations];
      state.loading = false;
      state.errors = [];
    },
    updateOrganizationFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteOrganization: (state) => {},
    deleteOrganizationSuccess: (state, { payload }) => {
      state.organizations = state.organizations.filter((organization) => {
        return organization.id !== payload;
      });
    },
    deleteOrganizationFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getOrganizations,
  getOrganizationsSuccess,
  getOrganizationsFailure,
  createOrganization,
  createOrganizationSuccess,
  createOrganizationFailure,
  updateOrganization,
  updateOrganizationSuccess,
  updateOrganizationFailure,
  deleteOrganization,
  deleteOrganizationSuccess,
  deleteOrganizationFailure,
} = organizationsSlice.actions;

export const organizationsSelector = (state) => state.organizations;
export default organizationsSlice.reducer;

export function fetchOrganizationsService(orgId) {
  return async (dispatch) => {
    dispatch(getOrganizations());

    try {
      const response = await Api.getOrganizations(orgId);
      dispatch(getOrganizationsSuccess(response.data));
    } catch (error) {
      dispatch(getOrganizationsFailure(error));
    }
  };
}

export function createOrganizationService(payload) {
  return async (dispatch) => {
    dispatch(createOrganization());

    try {
      const response = await Api.saveOrganization(payload);

      dispatch(createOrganizationSuccess(response.data));
    } catch (error) {
      dispatch(createOrganizationFailure(error));
    }
  };
}

export function updateOrganizationService(id, payload) {
  return async (dispatch) => {
    dispatch(updateOrganization());

    try {
      const response = await Api.updateOrganization(id, payload);

      dispatch(
        updateOrganizationSuccess(response.data)
      );
    } catch (error) {
      dispatch(updateOrganizationFailure(error));
    }
  };
}

export function deleteOrganizationService(id) {
  return async (dispatch) => {
    dispatch(deleteOrganization());

    try {
      const response = await Api.deleteOrganization(id);

      dispatch(deleteOrganizationSuccess(id));
    } catch (error) {
      dispatch(deleteOrganizationFailure(error));
    }
  };
}