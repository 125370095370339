import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import PaymentForm from "./forms/PaymentForm";
import { isEmpty, find } from "lodash";
import MaterialTable from "material-table";
import CRMUtils from "../utils";
import { findIndex } from "lodash";
import {
  List,
  ListItemText,
  Paper,
  ListItem as MuiListItem,
  Divider,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Fab,
  Box,
  LinearProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

import useTable from "./useTable";
import Popup from "./Popup";

import RefundRequestForm2 from "./forms/RefundRequestForm2";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import Controls from "./controls";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import CloseIcon from "@material-ui/icons/Close";
import Api from "../lib/api";
import ConfirmDialog from "./ConfirmDialog";
import RefundForm from "./forms/RefundForm";

import Iconify from "./Iconify";
import ContactRefunds from "./ContactRefunds";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "50px",
    top: "100px",
    zIndex: 10,
  },
  newButton2: {
    position: "absolute",
    right: "100px",
    top: "335px",
    zIndex: 10,
  },
}));

function RefundsPatient(props) {
  const classes = useStyles();
  const {
    invoice,
    gateways,
    onSave,
    maxAmount,
    refundReasons,
    invoiceSelected,
    record,
    contact_id,
    //  dataRefundRequests,
    setInvoice = null,
    onDelete = null,
    //invoices = [],
    rReadonly = false,
  } = props;

  const [dataRefundRequestsLocal, setDataRefundRequestsLocal] = useState([]);
  const [progressBarReq, setProgressBarReq] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [refunds, setRefunds] = useState([]);

  const dataMaterialTable = dataRefundRequestsLocal.map((o) => ({
    ...o,
    created: o.user ? o.user.data.title : "",
  }));

  const filterRefunds = () => {
    var refunds = [];

    invoices.forEach((invoice) => {
      var temp = invoice.payments.data.filter((payment) => {
        return payment.isRefund;
      });
      refunds = refunds.concat(temp);
    });

    setRefunds(refunds);
    return refunds;
  };

  const headCells = [
    //{ id: "selected", label: "", disableSorting: true, filter: false },
    { id: "type", label: "Type" },
    { id: "amount", label: "Amount" },
    { id: "details", label: "Details" },
    { id: "date", label: "Date" },
    { id: "created_by", label: "Created by" },
    { id: "attachment", label: "Attachment" },
    //{ id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ];

  const headCellsMaterial = [
    { field: "contact_name", title: "Contact" },
    { field: "amount", title: "Amount" },
    { field: "comment", title: "Comment" },
    { field: "date", title: "Date" },
    { field: "product_name", title: "Product" },
    { field: "created", title: "Created by" },
    //  { field: "status", title: "Status" },
  ];

  const headCellsMaterialRefunds = [
    { field: "payment_type", title: "Type" },
    {
      field: "amount",
      title: "Amount",
    },
    {
      field: "cc_last_4",
      title: "Last 4",
    },
    {
      field: "details",
      title: "Details",
    },
    { field: "building_name", title: "Building" },
    {
      field: "date",
      title: "Created",
    },
    {
      field: "created_by",
      title: "By",
      render: (rowData) => {
        return rowData.user.data ? rowData.user.data.title : "";
      },
    },
    {
      field: "attachment",
      title: "Attachment",
      render: (rowData) => {
        if (rowData.document.data?.url || rowData.file) {
          return (
            <a
              href={
                rowData.document.data?.url
                  ? rowData.document.data.url
                  : rowData.file
              }
              target="_blank"
              rel="noreferrer"
            >
              <AttachFileIcon />
            </a>
          );
        }
      },
    },
  ];

  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [refundRequestsForEdit, setRefundRequestsForEdit] = useState(null);
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);

  const [paymentForEdit, setPaymentForEdit] = useState(null);

  const userCanAdd = CRMUtils.userHasPermission(authUser, [
    "refundrequest_approve",
  ]);

  const saveRefund = (data) => {
    //  console.log("saveRefund2", data);
    data.isRefund = 1;
    onSave(data);
    onClose();
    onClose2();
  };

  const closeRefundPopup = () => {
    setOpenRefund(false);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addOrEdit = async (payload) => {
    const date = moment(payload.date).format("YYYY-MM-DD HH:mm:ss");
    payload.date = date;

    if (refundRequestsForEdit) {
      const { data } = await Api.updateRefundRequest(
        refundRequestsForEdit.id,
        payload
      );
      const index = findIndex(dataRefundRequestsLocal, ["id", data.data.id]);
      const refundRequest = {
        ...dataRefundRequestsLocal[index],
        ...data.data,
      };

      let refundRequests = [...dataRefundRequestsLocal];
      refundRequests[index] = refundRequest;

      setDataRefundRequestsLocal([...refundRequests]);
    } else {
      if (!isEmpty(authUser)) {
        payload.organization_id = authUser.organization_id;
        const { data } = await Api.saveRefundRequest(payload);

        setDataRefundRequestsLocal([...dataRefundRequestsLocal, data.data]);
      }
    }
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
    setRefundRequestsForEdit(null);
    onClose();
  };

  useEffect(() => {
    setProgressBarReq(true);
    if (record) {
      getContractsReports().then(() => {
        setProgressBarReq(false);
      });
    }
  }, [record]);

  const getContractsReports = async () => {
    const { data } = await Api.getRecordRefundRequests(record.id);

    setDataRefundRequestsLocal(
      data.data.filter((rrq) => {
        return rrq.status === "pending" && rrq.amount > 0;
      })
    );
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(refunds, headCells, filterFn);

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose2 = () => {
    setOpenPopup2(false);
    setPaymentForEdit(null);
  };

  const onClose = () => {
    setOpenPopup(false);
  };
  //const onDelete = (id) => {};
  console.error(
    "recordsAfterPagingAndSorting()",
    recordsAfterPagingAndSorting()
  );

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const orgId = authUser.organization_id;
  }, []);

  const selectedColumns = () => {
    if (authUser.organization.data.multiple_locations === 1) {
      return headCellsMaterialRefunds;
    }

    return headCellsMaterialRefunds.filter((column) => {
      return column.field != "building_name";
    });
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact_id) {
      setProgressBar(true);
      getInvoicesContact().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const getInvoicesContact = async () => {
    const { data } = await Api.getInvoicesContacts(contact_id);
    setInvoices([...data.data]);
  };

  const renderPaymentDetails = (paymentDetails) => {
    return (
      <MaterialTable
        isLoading={progressBarReq}
        title="Payments"
        columns={[
          { title: "Name", field: "item_name" },
          { title: "Amount", field: "amount" },
        ]}
        data={paymentDetails.map((payment) => {
          return {
            item_name: payment.item_name,
            amount: payment.amount,
          };
        })}
        options={{
          loadingType: "linear",
          pageSize: 20, // make initial page size
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          search: false,
          header: true,
          paging: false,
          showTitle: false,
          padding: "dense",
          toolbar: false,
          exportButton: false,
        }}
      />
    );
  };

  const editPayment = async (payload) => {
    const momentDate = new moment(payload.payment_date);
    payload.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");

    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    if (paymentForEdit) {
      /*const { data } = await Api.saveInvoicePayment(invoice.id, formData);

      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });*/

      if (payload.file === null) {
        formData.delete("file");
      }

      const { data } = await Api.editInvoicePayment(
        invoice.id,
        paymentForEdit.id,
        formData
      );

      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });
    } else {
    }

    //onClose();
    onClose2();
  };

  const deletePayment = (id) => {
    onDelete(id);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const openInPopupEdit = (item) => {
    setPaymentForEdit(item);

    //setOpenPopup(true);
    setOpenPopup2(true);
  };

  function userHasPermission(user, routePermissions) {
    if (!routePermissions) return true;

    let hasPermission = false;

    const permissions = user.permissions.data.map((permission) => {
      return permission.name;
    });
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  }

  let action;
  if ((invoice && invoice.status !== "canceled") || record) {
    action = [
      {
        icon: () => (
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        ),
        tooltip: "Add Refund",
        hidden: !userHasPermission(authUser, ["refund_create"]) || rReadonly,
        isFreeAction: true,
        onClick: (event) => {
          //setOpenPopup(true);
          setOpenPopup2(true);
        },
      },
      (rowData) => ({
        icon: () =>
          setInvoice ? (
            <Controls.IconButton color="secondary">
              <EditOutlinedIcon fontSize="small" />
            </Controls.IconButton>
          ) : (
            ""
          ),
        tooltip: "Edit",
        hidden: !userHasPermission(authUser, ["refund_edit"]) || rReadonly,
        onClick: (event, rowData) => {
          openInPopupEdit(rowData);
          //setOpenPopup2(true);
        },
      }),
      /*(rowData) => ({
        icon: () => (
          <Controls.IconButton color="secondary">
            <CallSplitIcon fontSize="small" />
          </Controls.IconButton>
        ),
        tooltip: "Transfer",
        hidden: !userHasPermission(authUser, [
          "invoicepaymenthistory_transfer",
        ]),
        onClick: (event, rowData) => {
          openTransfer(rowData);
        },
      }),*/
      (rowData) => ({
        icon: () =>
          onDelete ? (
            <Controls.IconButton color="secondary">
              <CloseIcon fontSize="small" />
            </Controls.IconButton>
          ) : (
            ""
          ),
        tooltip: "Delete",
        hidden: !userHasPermission(authUser, ["refund_delete"]) || rReadonly,
        onClick: (event, rowData) => {
          //console.error("Click on Delete!");
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this refund?",
            subTitle: "You can't undo this operation",
            onConfirm: () => {
              //console.log("delete refund! ", rowData.id);
              deletePayment(rowData.id);
            },
          });
        },
      }),
    ];
  } else {
    action = [
      {
        icon: () => <></>,
        tooltip: "",
        isFreeAction: true,
      },
    ];
  }

  const onTransferPayment = () => {
    onCloseTransfer();
  };

  useEffect(() => {
    filterRefunds();
  }, [invoices]);

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        {/*((invoice &&
          (invoice.status === "partial_paid" || invoice.status === "paid")) ||
          refunds) &&
          !rReadonly && (
            <Fab
              color="primary"
              aria-label="Make Refund Request"
              size="small"
              onClick={() => {
                setOpenPopup(true);
              }}
              className={classes.newButton}
            >
              <AddIcon />
            </Fab>
            )*/}

        <TblContainer>
          <MaterialTable
            isLoading={progressBarReq}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              headerStyle: {
                backgroundColor: "#e0f2f1",
                color: "#00695c",
              },
              loadingType: "linear",
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              search: false,
            }}
            title="Refunds Requests"
            actions={[
              {
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        !userHasPermission(authUser, ["refundrequest_edit"]) ||
                        rReadonly
                          ? "#bdbdbd"
                          : "#009688",
                    }}
                  >
                    <Iconify
                      icon={"fluent:edit-24-filled"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "Edit",
                hidden:
                  !userHasPermission(authUser, ["refundrequest_edit"]) ||
                  rReadonly,
                onClick: (event, rowData) => {
                  setOpenPopup(true);
                  setRefundRequestsForEdit(rowData);
                },
              },
            ]}
          />
        </TblContainer>

        <Box>
          {/*<EnhancedTableToolbar
            label="Refunds"
            numSelected={selected.length}
            advanceFilter={() => console.log("open advance filter")}
          />*/}

          {/*invoice && userCanAdd ? (
            <Fab
              color="primary"
              aria-label="Make Refund"
              size="small"
              onClick={() => {
                setOpenPopup2(true);
              }}
              className={classes.newButton2}
            >
              <AddIcon />
            </Fab>
          ) : (
            ""
          )*/}
          
        
          {/* <TblContainer> */}
            {/*console.error(recordsAfterPagingAndSorting())*/}

            {/* {invoice && (
              <MaterialTable
                columns={selectedColumns()}
                data={recordsAfterPagingAndSorting()}
                actions={[...action]}
                isLoading ={progressBarReq}
                options={{
                  loadingType: 'linear',
                  pageSize: 5, // make initial page size
                  emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                  actionsColumnIndex: -1,
                }}
                title="Refunds"
                detailPanel={(rowData) => {
                  return renderPaymentDetails(rowData.paymentdetail.data);
                }}
              />
            )}

            {record && (
              <>
                <TblHead />
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item) => {
                    const isItemSelected = isSelected(item.id);
                    return (
                      <TableRow key={item.id} role="checkbox">
                        <TableCell>{item.payment_type}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>{item.details}</TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell>
                          {item.created_by ? item.user.data.title : ""}
                        </TableCell>
                        <TableCell>
                          {!isEmpty(item.document.data) || item.file ? (
                            <a
                              href={
                                !isEmpty(item.document.data)
                                  ? item.document.data.url
                                  : item.file
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <AttachFileIcon />
                            </a>
                          ) : (
                            ""
                          )}
                        </TableCell>
                     
                      </TableRow>
                    );
                  })}
               
                </TableBody>
              </>
            )}
     
          </TblContainer>
          {progressBar &&
          <div>
          <div style={{padding: 10, textAlign: 'center'}}>No records to display</div>
          <LinearProgress />
      </div>
        }
          <TblPagination /> */}


          <ContactRefunds contact_id={contact_id}/>
        </Box>
      </Paper>
      <Popup
        title={"Refund Items"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <RefundRequestForm2
          refundRequestsForEdit={refundRequestsForEdit}
          onClose={closeRefundPopup}
          addOrEdit={addOrEdit}
          refundReasons={refundReasons}
          invoiceSelected={invoice}
        />
      </Popup>

      <Popup
        title={"Refund Items"}
        fullWidth={true}
        openPopup={openPopup2}
        onClose={onClose2}
      >
        {
          <RefundForm
            invoice={invoice}
            savePayment={paymentForEdit ? editPayment : saveRefund}
            gateways={gateways}
            className={classes.form}
            maxAmount={1}
            paymentForEdit={paymentForEdit ? paymentForEdit : null}
            isRefund={true}
            refundReasons={refundReasons}
          />
        }
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RefundsPatient;
