import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import UserToUserConversation from "./UserToUserConversation";
import UserToGroupConversation from "./UserToGroupConversation";
import ChatGroupConversation from "./ChatGroupConversation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "92vh",
  },
}));

const ConversationPanel = (props) => {
  const classes = useStyles();
  const {
    selectedMessage,
    setSelectedMessage,
    selectedTab,
    selectedHeader,
    conversationOpened,
    setHeaders,
    headers,
    setSelectedHeader,
    getMessagesNeeded,
    setGetMessagesNeeded,
    customHeight
  } = props;

  return (
    <div className={classes.chatWrapper}>
      {selectedTab == 0 ? (
        <UserToUserConversation
          customHeight={customHeight}
          selectedMessageID={selectedMessage}
          setSelectedMessageID={setSelectedMessage}
          headers={headers}
          setHeaders={setHeaders}
          conversationOpened={conversationOpened}
          selectedHeader={selectedHeader}
          getMessagesNeeded={getMessagesNeeded}
          setGetMessagesNeeded={setGetMessagesNeeded}
          setSelectedHeader={setSelectedHeader}
        />
      ) : selectedTab == 1 ? (
        <UserToGroupConversation
          customHeight={customHeight}
          selectedMessageID={selectedMessage}
          setSelectedMessageID={setSelectedMessage}
          headers={headers}
          setHeaders={setHeaders}
          conversationOpened={conversationOpened}
          selectedHeader={selectedHeader}
          getMessagesNeeded={getMessagesNeeded}
          setGetMessagesNeeded={setGetMessagesNeeded}
          setSelectedHeader={setSelectedHeader}
        />
      ) : (
        <ChatGroupConversation
          customHeight={customHeight}
          selectedMessageID={selectedMessage}
          setSelectedMessageID={setSelectedMessage}
          headers={headers}
          setHeaders={setHeaders}
          conversationOpened={conversationOpened}
          selectedHeader={selectedHeader}
          getMessagesNeeded={getMessagesNeeded}
          setGetMessagesNeeded={setGetMessagesNeeded}
          setSelectedHeader={setSelectedHeader}
        />
      )}
    </div>
  );
};

export default ConversationPanel;
