import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  creditMemos: [],
};

const creditMemosSlice = createSlice({
  name: "creditMemos",
  initialState,
  reducers: {
    getCreditMemos: (state) => {
      state.loading = true;
    },
    getCreditMemosSuccess: (state, { payload }) => {
      state.creditMemos = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCreditMemosFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createCreditMemo: (state) => {},
    createCreditMemoSuccess: (state, { payload }) => {
      state.creditMemos = [...state.creditMemos, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createCreditMemoFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateCreditMemo: (state) => {},
    updateCreditMemoSuccess: (state, { payload }) => {
      const index = findIndex(state.creditMemos, ["id", payload.data.id]);
      const creditMemo = { ...state.creditMemos[index], ...payload.data };

      let creditMemos = [...state.creditMemos];
      creditMemos[index] = creditMemo;

      state.creditMemos = [...creditMemos];
      state.loading = false;
      state.errors = [];
    },
    updateCreditMemoFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteCreditMemo: (state) => {},
    deleteCreditMemoSuccess: (state, { payload }) => {
      state.creditMemos = state.creditMemos.filter((creditMemo) => {
        return creditMemo.id !== payload;
      });
    },
    deleteCreditMemoFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getCreditMemos,
  getCreditMemosSuccess,
  getCreditMemosFailure,
  createCreditMemo,
  createCreditMemoSuccess,
  createCreditMemoFailure,
  updateCreditMemo,
  updateCreditMemoSuccess,
  updateCreditMemoFailure,
  deleteCreditMemo,
  deleteCreditMemoSuccess,
  deleteCreditMemoFailure,
} = creditMemosSlice.actions;

export const creditMemosSelector = (state) => state.creditMemos;
export default creditMemosSlice.reducer;

export function fetchCreditMemosService(orgId) {
  return async (dispatch) => {
    dispatch(getCreditMemos());

    try {
      const response = await Api.getCreditMemos(orgId);
      dispatch(getCreditMemosSuccess(response.data));
    } catch (error) {
      dispatch(getCreditMemosFailure(error));
    }
  };
}

export function createCreditMemoService(payload) {
  return async (dispatch) => {
    dispatch(createCreditMemo());

    try {
      const response = await Api.saveCreditMemo(payload);

      dispatch(createCreditMemoSuccess(response.data));
    } catch (error) {
      dispatch(createCreditMemoFailure(error));
    }
  };
}

export function updateCreditMemoService(id, payload) {
  return async (dispatch) => {
    dispatch(updateCreditMemo());

    try {
      const response = await Api.updateCreditMemo(id, payload);

      dispatch(updateCreditMemoSuccess(response.data));
    } catch (error) {
      dispatch(updateCreditMemoFailure(error));
    }
  };
}

export function deleteCreditMemoService(id) {
  return async (dispatch) => {
    dispatch(deleteCreditMemo());

    try {
      const response = await Api.deleteCreditMemo(id);

      dispatch(deleteCreditMemoSuccess(id));
    } catch (error) {
      dispatch(deleteCreditMemoFailure(error));
    }
  };
}

export function fetchCreditMemosByContactService(contactId) {
  return async (dispatch) => {
    dispatch(getCreditMemos());

    try {
      const response = await Api.getContactCreditMemos(contactId);

      dispatch(getCreditMemosSuccess(response.data));
    } catch (error) {
      dispatch(getCreditMemosFailure(error));
    }
  };
}
