import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import {
  Typography,
  Grid,
  CardContent,
  CardActions,
  TextField,
  Box,
  Avatar,
  Switch,
  withStyles,
  FormHelperText,
} from "@material-ui/core";

import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { FormProvider } from "../hook-form";
import Controls from "../controls";
import CRMUtils from "../../utils";
import Iconify from "../Iconify";
import styled from "styled-components";
import moment from "moment";

import { fetchEntitiesService as fetchBuildingsDropdownService } from "../../redux/slices/buildingsDropdownSlice.js";

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "80px",
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
  dialogTitle2: {
    padding: "5px 10px",
    paddingRight: "0px",
  },
  dialogWrapper: {
    backgroundColor: "#ffffff",
  },
  indicator: {
    color: "#673ab7",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "63%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  primaryHeadingDisabled: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.disabled,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  dialogWrapper2: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
    zindex: 999999,
  },
  error: {
    color: "red",
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
};

const Wrapper = styled.div`
  padding: 0;
  flex-direction: column;
  display: flex;
  height: 100%;
`;
export default function PaymentFormNew(props) {
  const { authUser } = useSelector((state) => state.auth);
  dataSliceBuildingsDropdown.orgId = authUser.organization_id;

  const { buildingsDropdown, buildingsDropdownLoading } = useSelector(
    (state) => state.buildingsDropdown
  );

  const methods = useForm();

  const {
    invoice,
    onSubmit,
    onClose,
    usersDropdown,
    gateways,
    refundReasons,
    paymentForEdit = null,
  } = props;
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
    errors,
    setValue,
  } = methods;

  const watchAllFields = watch();

  const [lockDate, setLockDate] = useState(false);

  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);

  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);

  const [totalRefund, setTotalRefund] = React.useState(0);

  const onSubmitLocal = (info) => {
    let refundsData = {};

    const formData = new FormData();

    Object.keys(info).forEach((key) => {
      const keyParts = key.split("-");

      if (keyParts[0] !== "applyCredit") {
        const payment = keyParts[1];

        if (!refundsData[payment]) {
          refundsData[payment] = {};
        }

        if (keyParts[0] === "items") {
          if (!refundsData[payment]["items"]) {
            refundsData[payment]["items"] = [];
          }

          refundsData[payment]["items"].push({
            payment_id: keyParts[1],
            parent_detail_id: keyParts[2],
            item_id: keyParts[3],
            amount: info[key],
          });
        } else {
          refundsData[payment][keyParts[0]] = info[key];
        }
      }
    });

    Object.keys(refundsData).forEach((payment) => {
      Object.keys(refundsData[payment]).forEach((key) => {
        if (key === "items") {
          refundsData[payment][key].forEach((item, index) => {
            Object.keys(item).forEach((itemKey) => {
              formData.append(
                `refundsData[${payment}][${key}][${index}][${itemKey}]`,
                item[itemKey]
              );
            });
          });
        } else {
          formData.append(
            `refundsData[${payment}][${key}]`,
            refundsData[payment][key]
          );
        }
      });
    });

    formData.append("applyCredit", info["applyCredit-"]);

    onSubmit(formData);
  };

  const setValueDateLock = (building_id) => {
    if (!userCanEditPaymentDateLock) {
      const building = buildingsDropdown.find(
        (building) => building.id === building_id
      );

      if (building && building.lock_payment_date) {
        setValue(`payment_date`, moment().format("YYYY-MM-DD HH:mm:ss"));

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  };

  const checkDateLock = (building_id) => {
    if (!userCanEditPaymentDateLock) {
      const building = buildingsDropdown.find(
        (building) => building.id === building_id
      );

      if (building && building.lock_payment_date) {
        return true;
      }
    }
    return false;
  };

  const paymentOptions = () => {
    let options = gateways.map((gateway) => {
      return {
        id: gateway.name,
        title: gateway.name,
      };
    });
    // options.push({
    //   id: "ONLINE DEBIT/CREDIT CARD",
    //   name: "ONLINE DEBIT/CREDIT CARD",
    // });
    return options;
  };

  const buildingOptions = () => {
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const calculateTotalSum = () => {
    return Object.keys(watchAllFields)
      .filter((fieldName) => fieldName.startsWith("items"))
      .reduce((sum, fieldName) => {
        const fieldValue = watchAllFields[fieldName]
          ? parseFloat(watchAllFields[fieldName])
          : 0;
        return sum + fieldValue;
      }, 0);
  };

  useEffect(() => {
    //   console.log("useeffect refundformnew ");
    setTotalRefund(calculateTotalSum());
  }, [watchAllFields]);

  useEffect(() => {
    if (isEmpty(buildingsDropdown) && !buildingsDropdownLoading) {
      dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
    }
  }, []);

  return (
    <Wrapper>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitLocal)}>
        <CardContent style={{ marginLeft: 0, marginRight: 0, marginTop: 3 }}>
          <Grid
            container
            spacing={2}
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: 5,
            }}
          >
            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="payment_type"
                control={control}
                defaultValue={paymentForEdit?.payment_type || ""}
                as={({ onChange, value }) => (
                  <Controls.Select
                    label="Payment Type"
                    options={paymentOptions()}
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                  />
                )}
                rules={{
                  required: "Payment Type is required",
                }}
              />
              <FormHelperText
                className={classes.error}
                id="payment_type-helper-text"
              >
                {errors.payment_type ? errors.payment_type.message : " "}
              </FormHelperText>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="payment_date"
                type="date"
                control={control}
                defaultValue={
                  paymentForEdit?.payment_type ||
                  moment().format("YYYY-MM-DD HH:mm:ss")
                }
                as={({ onChange, value }) => (
                  <Controls.DateTimePicker
                    label="Date"
                    shouldCloseOnSelect
                    fullWidth
                    disabled={
                      !userCanEditPayment ||
                      lockDate ||
                      checkDateLock(
                        paymentForEdit?.id,
                        paymentForEdit?.building_id
                      )
                    }
                    onChange={(date) => {
                      const fdate = moment(date).format("yyyy-MM-DD HH:mm:ss");
                      value = fdate;
                      onChange(fdate);
                    }}
                    value={value}
                  />
                )}
                rules={{
                  required: "Date is required",
                }}
              />
              <FormHelperText
                className={classes.error}
                id="payment_date-helper-text"
              >
                {errors.payment_date ? errors.payment_date.message : " "}
              </FormHelperText>
            </Grid>

            <Grid item xs={6} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Grid
                container
                spacing={1}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  {watchAllFields[`file`] ? (
                    <Box
                      width={"100px"}
                      height={"100px"}
                      margin={"auto"}
                      padding={"8px"}
                    >
                      {watchAllFields[`file`].type === "image/png" ||
                      watchAllFields[`file`].type === "image/jpeg" ? (
                        <Avatar
                          alt={`file`}
                          src={window.URL.createObjectURL(
                            watchAllFields[`file`]
                          )}
                          className={classes.largeAvatar}
                        />
                      ) : (
                        <Avatar
                          className={classes.largeAvatar}
                          style={{
                            backgroundColor: "#B30B00",
                            color: "#ffffff",
                          }}
                        >
                          PDF
                        </Avatar>
                      )}{" "}
                    </Box>
                  ) : (
                    <Box
                      width={"100px"}
                      height={"100px"}
                      margin={"auto"}
                      borderRadius={"50%"}
                      padding={"8px"}
                      border={"1px dashed rgba(145, 158, 171, 0.32)"}
                    >
                      <Box
                        className="placeholder"
                        style={{
                          Zindex: 0,
                          width: "100%",
                          height: "100%",
                          outline: "none",
                          overflow: "hidden",
                          flexDirection: "column",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          color: "#637381",
                          backgroundColor: "#F4F6F8",
                          "&:hover": { opacity: 0.72 },
                          display: "flex",
                        }}
                      >
                        <Iconify
                          icon={"ic:baseline-receipt-long"}
                          style={{
                            width: 24,
                            height: 24,
                            marginBottom: 1,
                          }}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          Receipt
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="file"
                    control={control}
                    defaultValue=""
                    as={({ onChange, value }) => (
                      <Controls.DropzoneDialog
                        label="*Attach file"
                        style={{
                          backgroundColor: "#e3f2fd",
                          borderRadius: 50,
                          color: "#01579b",
                          marginTop: 10,
                        }}
                        onChange={(files) => {
                          onChange(files[0]);
                        }}
                        value={value}
                      />
                    )}
                    rules={{
                      required: "Attach file is required",
                    }}
                  />
                  <FormHelperText
                    className={classes.error}
                    id="file-helper-text"
                  >
                    {errors[`file`] ? errors[`file`].message : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="cc_last_4"
                control={control}
                defaultValue={paymentForEdit?.cc_last_4 || ""}
                render={({ onChange }) => (
                  <TextField
                    label="Last 4 CC"
                    variant="outlined"
                    onChange={(event) => onChange(event)}
                    fullWidth
                  />
                )}
              />
              <FormHelperText
                className={classes.error}
                id="cc_last_4-helper-text"
              >
                {errors.cc_last_4 ? errors.cc_last_4.message : " "}
              </FormHelperText>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="transaction_id"
                control={control}
                defaultValue={paymentForEdit?.transaction_id || ""}
                render={({ onChange }) => (
                  <TextField
                    label="Transaction ID"
                    variant="outlined"
                    onChange={(event) => onChange(event)}
                    fullWidth
                  />
                )}
              />
              <FormHelperText
                className={classes.error}
                id="transaction_id-helper-text"
              >
                {errors.transaction_id ? errors.transaction_id.message : " "}
              </FormHelperText>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="building_id"
                control={control}
                defaultValue={paymentForEdit?.building_id || ""}
                as={({ onChange, value }) => (
                  <Controls.Select
                    label="Building"
                    disabled={!userCanChangeBuilding}
                    options={buildingOptions()}
                    onChange={(event) => {
                      onChange(event.target.value);
                      setValueDateLock(paymentForEdit?.id, event.target.value);
                    }}
                    value={value}
                  />
                )}
                rules={{ required: "Building is required" }}
              />
              <FormHelperText
                className={classes.error}
                id="building_id-helper-text"
              >
                {errors.building_id ? errors.building_id.message : " "}
              </FormHelperText>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="cardholder"
                control={control}
                defaultValue={paymentForEdit?.cardholder || ""}
                render={({ onChange }) => (
                  <TextField
                    label="Card Holder"
                    variant="outlined"
                    onChange={(event) => onChange(event)}
                    fullWidth
                  />
                )}
              />
              <FormHelperText
                className={classes.error}
                id="cardholder-helper-text"
              >
                {errors.cardholder ? errors.cardholder.message : " "}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Controller
                name="details"
                control={control}
                defaultValue={paymentForEdit?.details || ""}
                render={({ onChange }) => (
                  <TextField
                    label="Details"
                    variant="outlined"
                    onChange={(event) => onChange(event)}
                    fullWidth
                    multiline
                    maxRows={4}
                    minRows={4}
                  />
                )}
              />
              <FormHelperText
                className={classes.error}
                id="details-helper-text"
              >
                {errors.details ? errors.details.message : " "}
              </FormHelperText>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Grid
                container
                spacing={1}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              ></Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: 15,
                }}
              >
                {/* {payment.paymentsDetail.map((item, index) => (
                        <>
                          <Grid item xs={6}>
                            <Typography
                              className={
                                item.type === "forfeiture" ||
                                item.type === "system" ||
                                (item.amount ? item.amount : 0) -
                                  (item?.refund_payment_details_simple_sum_amount ||
                                    0) <
                                  0 ||
                                item.void === 1
                                  ? classes.primaryHeadingDisabled
                                  : classes.primaryHeading
                              }
                              key={`item_name-${item.id}`}
                            >
                              {item.item_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              className={
                                item.type === "forfeiture" ||
                                item.type === "system" ||
                                (item.amount ? item.amount : 0) -
                                  (item?.refund_payment_details_simple_sum_amount ||
                                    0) <
                                  0 ||
                                item.void === 1
                                  ? classes.primaryHeadingDisabled
                                  : classes.primaryHeading
                              }
                              key={`refundableamount_${item.id}`}
                            >
                              {`Refundable: $${
                                item.type === "forfeiture" ||
                                item.type === "system"
                                  ? "0.00"
                                  : (
                                      (item.amount ? item.amount : 0) -
                                      (item?.refund_payment_details_simple_sum_amount ||
                                        0)
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                              }`}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            {expanded.includes(item.payment_id) && (
                              <InputMoneyOk
                                name={`items-${item.payment_id}-${item.id}-${item.item_id}`}
                                label="Amount"
                                type="number"
                                disabled={
                                  item.type === "forfeiture" ||
                                  item.type === "system" ||
                                  (item.amount ? item.amount : 0) -
                                    (item?.refund_payment_details_simple_sum_amount ||
                                      0) <
                                    0 ||
                                  item.void === 1
                                }
                                maxValue={
                                  item.type === "forfeiture" ||
                                  item.type === "system"
                                    ? "0"
                                    : (item.amount ? item.amount : 0) -
                                      (item?.refund_payment_details_simple_sum_amount ||
                                        0)
                                }
                                minValue={
                                  userCanEditNegativePayments ? "-999999" : "1"
                                }
                              />
                            )}
                          </Grid>
                        </>
                      ))} */}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Controls.Button
            text="Cancel"
            onClick={onClose}
            style={{ marginRight: 25 }}
          />

          <Controls.Button
            type="submit"
            text="Submit"
            disabled={totalRefund <= 0}
          />
        </CardActions>
      </FormProvider>
    </Wrapper>
  );
}
