import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  makeStyles,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Paper,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";

import { findIndex } from "lodash";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Controls from "../controls";
import Api from "../../lib/api";
import CRMUtils from "../../utils";
import Iconify from "../Iconify";
import { SettingsPhoneTwoTone } from "@material-ui/icons";

function HeaderTitle(props) {
  const { title, icon } = props;

  return (
    <div style={{ marginBottom: 5, marginTop: 8 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Iconify
          style={{
            marginRight: "5px",
            color: "rgb(92 167 143)",
            width: 22,
            height: 22,
          }}
          icon={icon}
        />
        <Typography style={{ fontSize: 16, fontWeight: 500, color: "#4d4d4d" }}>
          {title}
        </Typography>
      </div>

      <Divider style={{ margin: "7px", marginBottom: 10 }} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  moreButton: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 2,
  },
  lessButton: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 2,
    transform: "rotate(180deg)",
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  inputBase: {
    padding: 10,
    width: "100%",
    height: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        //boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  listItem: {
    listStyleType: "none",
  },
}));

export default function InvoicesToMultipaymentsForm(props) {
  const {
    onChange,
    contact_id,
    headerForEdit,
    setMinValidations,
    minValidations,
  } = props;
  const [invoicesValues, setInvoicesValues] = useState([]);
  const [invoicesLaoding, setInvoicesLoading] = useState(false);
  const [contactInvoices, setContactInvoices] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  // const [onlinePaymentValidationThrown, setOnlinePaymentValidationThrown] =
  //   useState(false);
  const classes = useStyles();

  useEffect(() => {
    console.log("invoicesValues ", invoicesValues);
  }, [invoicesValues]);

  useEffect(() => {
    console.log("zxc minVla ", minValidations);
  }, [minValidations]);

  useEffect(() => {
    if (contact_id) {
      getContactInvoices();
    }
  }, [contact_id]);

  const getContactInvoices = async () => {
    try {
      setInvoicesLoading(true);
      const { data } = await Api.getInvoicesContacts(contact_id);
      const tempInvoices = [
        ...data.data.filter((invoice) => invoice.status !== "canceled"),
      ];
      setContactInvoices([...tempInvoices]);

      var tempInvoiceValues = [
        ...tempInvoices.map((invoice) => {
          return {
            balance: invoice.balance,
            invoice_id: invoice.id,
            histories:
              headerForEdit &&
              headerForEdit.paymentHistories &&
              headerForEdit.paymentHistories.data &&
              headerForEdit.paymentHistories.data.find(
                (h) => h.invoice == invoice.id
              )
                ? headerForEdit.paymentHistories.data
                    .filter((h) => h.invoice == invoice.id)
                    .map((history) => {
                      console.log("prev history ", headerForEdit);
                      return {
                        editable: history.editable,
                        forfeiture_approved_date:
                          history.forfeiture_approved_date,
                        possible_refund_from_id:
                          history.possible_refund_from_id,
                        possible_refund: history.possible_refund,
                        paymentdetail: history.paymentdetail,
                        id: history.id,
                        isOnline: headerForEdit.isOnline,
                        originalAmount: history.amount,
                        amount: history.amount,
                        items: history.paymentdetail.data.map((detail) => ({
                          id: detail.item_id,
                          amount: detail.amount,
                          edit_id: detail.id,
                        })),
                      };
                    })
                : [{ id: -1, items: [], amount: 0 }],
            amount:
              headerForEdit &&
              headerForEdit.paymentHistories &&
              headerForEdit.paymentHistories.data
                ? headerForEdit.paymentHistories.data
                    .filter((h) => h.invoice == invoice.id)
                    .reduce(
                      (total, item) =>
                        total + parseFloat(item.amount ? item.amount : 0),
                      0
                    )
                : 0,
          };
        }),
      ];

      setInvoicesValues([...tempInvoiceValues]);

      console.log("tempInvoiceValues: ", tempInvoiceValues);
      setInvoicesLoading(false);
    } catch (err) {
      console.error("err!: ", err);
      setInvoicesLoading(false);
    }
  };

  useEffect(() => {
    if (typeof onChange == "function") {
      onChange(invoicesValues);
    }
  }, [invoicesValues]);

  const updateInvoiceItemValue = (invoice_id, item_id, value, history) => {
    const index = findIndex(invoicesValues, ["invoice_id", invoice_id]);

    const histories = [...invoicesValues[index]["histories"]];
    const indexHistory = findIndex(histories, ["id", history.id]);

    const items = histories[indexHistory]["items"];
    const indexItem = findIndex(items, ["id", item_id]);

    // setOnlinePaymentValidationThrown(
    //   history.originalAmount && history.originalAmount != history.amount
    // );
    /* 
      if the payment is Online, and the distribuited amount among invoice items is different than the payment amount, disable the update option
      adding a validation to prevent the payment from being updated
    */
    let newMin = minValidations;
    newMin[`onlineValidation[${history.id}]`] =
      history.originalAmount && history.originalAmount != history.amount;
    setMinValidations(newMin);

    if (indexItem != -1) {
      const updatedItemEvent = {
        ...items[indexItem],
        ...{ amount: value },
      };
      items[indexItem] = updatedItemEvent;
    } else {
      items.push({
        id: item_id,
        amount: value,
        edit_id: null,
      });
    }

    const updatedEventHistory = {
      ...histories[indexHistory],
      ...{
        items: items,
        amount: items.reduce(
          (total, item) => total + parseFloat(item.amount ? item.amount : 0),
          0
        ),
      },
    };

    histories[indexHistory] = updatedEventHistory;

    const updatedInvoice = {
      ...invoicesValues[index],
      ...{
        histories: histories,
        amount: histories.reduce(
          (total, item) => total + parseFloat(item.amount ? item.amount : 0),
          0
        ),
      },
    };

    let newInvoicesValues = [...invoicesValues];
    newInvoicesValues[index] = updatedInvoice;
    setInvoicesValues([...newInvoicesValues]);
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return item.type == "package"
      ? (item.package_price ? item.package_price : 0) * parseInt(quantity) -
          discount * parseInt(quantity)
      : item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
          discount * (item.price_includes_qty ? 1 : parseInt(quantity));
  };

  const calculateItemBalance = (item) => {
    const itemPrice = item.price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;

    let itemRefunds = 0;
    let refunddetails;
    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      itemPayments = paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds = refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    if (item.creditmemodetail) {
      itemCredits = item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }

    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;
    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const calculatePackageBalance = (item) => {
    const itemPrice = item.package_price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;

    let itemRefunds = 0;
    let refunddetails;

    item.childs.forEach((child) => {
      if (child.paymentwith_payments_detail) {
        const paymentdetails = child.paymentwith_payments_detail.filter(
          (paymentdetail) => {
            return !paymentdetail.isRefund;
          }
        );

        itemPayments += paymentdetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);

        refunddetails = child.payment_with_refunds_detail.filter(
          (paymentdetail) => {
            return paymentdetail.isRefund;
          }
        );

        if (refunddetails.length > 0) {
          itemRefunds += refunddetails.reduce((total, payment) => {
            return total + parseFloat(payment.amount);
          }, 0);
        }
      }

      if (child.credit_memo_detail) {
        itemCredits += child.credit_memo_detail.reduce((total, creditmemo) => {
          return total + parseFloat(creditmemo.amount);
        }, 0);
      }
    });

    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      itemPayments += paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds += refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    if (item.creditmemodetail) {
      itemCredits += item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }

    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;

    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const getDetail = (item_id, history) => {
    let paymentdetail = history.paymentdetail.data.find((paymentdetail1) => {
      return paymentdetail1.item_id === item_id;
    });

    return paymentdetail ? paymentdetail.amount : 0;
  };

  const getPackageDetail = (item, history) => {
    var childIds = item.childs.map((item) => {
      return item.id;
    });

    let paymentdetail = history.paymentdetail.data.filter((paymentdetail1) => {
      return childIds.includes(paymentdetail1.item_id);
    });

    var total = paymentdetail.reduce((total, item) => total + item.amount, 0);

    //  console.log(item_id, paymentdetail);
    return total ? total : 0;
  };

  const getDetailMin = (item, history) => {
    var item_id = item.id;
    let paymentdetail =
      history && history.id != -1
        ? history.paymentdetail.data.find((paymentdetail1) => {
            return paymentdetail1.item_id === item_id;
          })
        : null;

    var minDetailValue =
      paymentdetail && paymentdetail.min_detail_amount != 0
        ? parseFloat(paymentdetail.min_detail_amount)
        : 0;

    var minBasedOnDoneQty = paymentdetail
      ? parseFloat(item.itemPaid) -
          parseFloat(item.itemRefund) -
          parseFloat(paymentdetail.amount) >=
        parseFloat(item.done_quantity_price)
        ? null
        : parseFloat(paymentdetail.amount) >=
          parseFloat(item.done_quantity_price)
        ? parseFloat(item.done_quantity_price) -
          (parseFloat(item.itemPaid) -
            parseFloat(item.itemRefund) -
            parseFloat(paymentdetail.amount) >
          0
            ? parseFloat(item.itemPaid) -
              parseFloat(item.itemRefund) -
              parseFloat(paymentdetail.amount)
            : 0)
        : parseFloat(paymentdetail.amount) -
          (parseFloat(item.itemPaid) -
            parseFloat(item.itemRefund) -
            parseFloat(paymentdetail.amount) >
          0
            ? parseFloat(item.itemPaid) -
              parseFloat(item.itemRefund) -
              parseFloat(paymentdetail.amount)
            : 0)
      : null;
    if (
      minBasedOnDoneQty !== null &&
      minDetailValue !== null &&
      minBasedOnDoneQty < minDetailValue
    ) {
      return minDetailValue;
    } else {
      return minBasedOnDoneQty;
    }
  };

  const renderInvoiceItems = (invoice, history) => {
    let overPaidItem = invoice.items.data.filter((item) => {
      return item.type === "system";
    });
    var mins = [];
    //setOverPaymentControl(0);
    let overPaidPriority = overPaidItem[0]
      ? overPaidItem[0].itemPaid - overPaidItem[0].itemRefund > 0
        ? true
        : false
      : false;

    return invoice.items.data
      .filter((a) =>
        headerForEdit ? a.type != "package" : a.parent_id == null
      )
      .map((item, index) => {
        mins[index] = getDetailMin(item, history)
          ? getDetailMin(item, history)
          : userCanEditNegativePayments
          ? "-999999"
          : "0";
        const balance =
          item.type == "package"
            ? calculatePackageBalance(item)
            : calculateItemBalance(item);
        return (
          <TableRow key={item.id}>
            <TableCell style={{ maxWidth: "160px" }} component="th" scope="row">
              {item.name}
            </TableCell>
            <TableCell align="right">
              {" "}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>{item.quantity || 1}</Typography>
                {item.void_quantity ? (
                  <Typography
                    style={{
                      fontWeight: 500,
                      marginLeft: 5,
                      color: "#a76c65",
                      fontSize: 12,
                    }}
                  >{`(${item.void_quantity} VOID)`}</Typography>
                ) : (
                  ""
                )}
              </div>
            </TableCell>
            <TableCell align="right">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ marginRight: 4 }}>
                  $ {item.type == "package" ? item.package_price : item.price}
                </Typography>
                {item.price_includes_qty ? (
                  <Tooltip title="The price of this item is total, not unitary per item.">
                    <span>
                      <Iconify
                        style={{
                          color: "#6579e3",
                          width: 18,
                          height: 18,
                          marginTop: "6px",
                        }}
                        icon={"fluent:info-24-filled"}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </TableCell>
            <TableCell align="right">
              $ {calculateItemTotal(item).toFixed(2)}
            </TableCell>
            <TableCell align="right">$ {balance.toFixed(2)}</TableCell>
            <TableCell align="right">
              <Controls.InputMoneyToPaymentForm
                onChange={(value) => {
                  updateInvoiceItemValue(invoice.id, item.id, value, history);
                }}
                defaultValue={
                  history && history.id != -1
                    ? item.type == "package"
                      ? getPackageDetail(item, history)
                      : getDetail(item.id, history)
                    : 0
                }
                disabled={
                  item.done_product_control &&
                  parseFloat(item.done_quantity_price) ==
                    parseFloat(item.itemPaid) - parseFloat(item.itemRefund)
                    ? true
                    : history &&
                      history.id != -1 &&
                      (history.possible_refund ||
                        history.forfeiture_approved_date ||
                        (!history.editable &&
                          !CRMUtils.userHasPermission(authUser, [
                            "invoicepaymenthistory_superedit",
                          ])))
                    ? true
                    : (history &&
                        history.id != -1 &&
                        history.paymentdetail &&
                        // item.itemBalance == 0 &&
                        history.paymentdetail.data &&
                        history.paymentdetail.data.findIndex(
                          (a) => a.is_recordProduct_finalized == true
                        ) != -1) ||
                      item.done
                    ? true
                    : history &&
                      history.id != -1 &&
                      history.possible_refund_from_id
                    ? true
                    : history && history.id != -1 && item.type !== "system"
                    ? false
                    : item.void === 1
                    ? true
                    : overPaidPriority && item.type === "system"
                    ? true
                    : false
                }
                itemToPrint={item}
                indexValue={index}
                minValidations2={minValidations}
                setMinValidations2={setMinValidations}
                maximumValue={999999}
                minimumValue={userCanEditNegativePayments ? "-999999" : "0"}
                mins={mins}
              />

              {minValidations && minValidations[`itemsAmount[${index}]`] ? (
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#b92e2e",
                  }}
                >
                  *Invalid Amount
                </p>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell align="right">{item.void === 1 ? "VOID" : ""}</TableCell>
          </TableRow>
        );
      });
  };

  return (
    <form className={classes.root}>
      <HeaderTitle icon="fluent:person-12-filled" title={"Contact Invoices"} />
      {invoicesLaoding ? <LinearProgress style={{ margin: "4px 8px" }} /> : ""}
      {contactInvoices.map((invoice) => {
        return (
          <Accordion
            expanded={invoice.expanded}
            style={{ background: "rgb(245 245 245)", margin: "8px 2px" }}
          >
            <AccordionSummary
              onClick={() => {
                const index = findIndex(contactInvoices, ["id", invoice.id]);
                const updatedEvent = {
                  ...contactInvoices[index],
                  ...{
                    expanded: contactInvoices[index]["expanded"] ? false : true,
                  },
                };

                let newInvoices = [...contactInvoices];
                newInvoices[index] = updatedEvent;
                setContactInvoices([...newInvoices]);
              }}
              style={{
                margin: 5,
                background: "rgb(245 245 245)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Iconify
                    className={
                      invoice.expanded ? classes.lessButton : classes.moreButton
                    }
                    icon="material-symbols:expand-more-rounded"
                  />
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                  >
                    {" "}
                    {invoice.name}
                  </Typography>
                </div>
                <Typography
                  style={{
                    color: "rgb(239 27 27)",
                    fontSize: "14.4px",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  {`Balance: $${invoice.balance}`}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ display: "grid", marginRight: 10 }}>
              {invoicesValues.find((a) => a.invoice_id == invoice.id)
                ? invoicesValues
                    .find((a) => a.invoice_id == invoice.id)
                    .histories.map((history) => {
                      console.log("rec hisory ", history);
                      return (
                        <Accordion
                          expanded={history.expanded}
                          style={{
                            borderRadius: 8,
                            background: "rgb(228 237 233)",
                            border: "1px solid rgba(128, 128, 128, 0.22)",
                            margin: 5,
                            width: "100%",
                          }}
                        >
                          <AccordionSummary
                            onClick={() => {
                              const index = findIndex(invoicesValues, [
                                "invoice_id",
                                invoice.id,
                              ]);

                              const histories =
                                invoicesValues[index]["histories"];
                              const indexItem = findIndex(histories, [
                                "id",
                                history.id,
                              ]);

                              const updatedItemEvent = {
                                ...histories[indexItem],
                                ...{
                                  expanded: histories[indexItem]["expanded"]
                                    ? false
                                    : true,
                                },
                              };
                              histories[indexItem] = updatedItemEvent;

                              const updatedEvent = {
                                ...invoicesValues[index],
                                ...{
                                  histories: histories,
                                },
                              };

                              let newInvoicesValues = [...invoicesValues];
                              newInvoicesValues[index] = updatedEvent;
                              setInvoicesValues([...newInvoicesValues]);
                            }}
                            style={{
                              background: "rgb(228 237 233)",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Iconify
                                  className={
                                    history.expanded
                                      ? classes.lessButton
                                      : classes.moreButton
                                  }
                                  icon="material-symbols:expand-more-rounded"
                                />
                                <Typography
                                  style={{
                                    color: "black",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {" "}
                                  {"History"}
                                </Typography>
                              </div>
                              <Typography
                                style={{
                                  color: "black",
                                  fontSize: "13.6px",
                                  fontWeight: 500,
                                }}
                              >
                                {" "}
                                {`Total Amount: $${history.amount}`}
                              </Typography>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer
                              style={{ padding: "10px 18px" }}
                              component={Paper}
                            >
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">
                                      Quantity
                                    </TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell align="right">
                                      {"Balance"}
                                    </TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {" "}
                                  {renderInvoiceItems(invoice, history)}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                          {history.isOnline && (
                            <TableCell>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: "#b92e2e",
                                  margin: 5,
                                }}
                              >
                                {"For Online Payments, the amount distributed among the items must be equal to the payment amount $" +
                                  history.originalAmount}
                              </p>
                            </TableCell>
                          )}
                        </Accordion>
                      );
                    })
                : ""}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </form>
  );
}
