import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
} from "./common/sliceFunctions2";

var entitiesName = "recordsPersonalAssistant";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_RECORDPA";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
  

  return _fetchEntitiesService(dataSlice, events);
}

/*
export function fetchSearchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_RECORD_SEARCH";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
  

  return _fetchEntitiesService(dataSlice, events);
}

export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code = "POST_RECORD";
  return _createEntityService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "PUT_RECORD";
  return _updateEntityService(dataSlice, events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code = "DELETE_RECORD";
  return _deleteEntityService(dataSlice, events);
}*/
