// icons
import { Icon } from "@iconify/react";
// @mui
import { Box } from "@material-ui/core";

// ----------------------------------------------------------------------

export default function Iconify({ icon, sx, ...other }) {
  return <Box component={Icon} icon={icon} style={{ ...sx }} {...other} />;
}
