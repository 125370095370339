import React, { useState, useEffect } from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { isEmpty } from "lodash";
import Api from "../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntitiesService as fetchBuildingsDropdownService } from "../../redux/slices/buildingsDropdownSlice.js";
import moment from "moment/moment";
import Iconify from "../Iconify";
import CRMUtils from "../../utils";
import { Tip } from "../controls/Tip";
import EditHeaderHistoryToMultiRefundForm from "./EditHeaderHistoryToMultiRefundForm";
import { fetchRefundReasonsService } from "../../redux/slices/refundReasons";
import { fetchUsersService } from "../../redux/slices/users";
import BindOnlineTransactionButton from "../BindOnlineTransactionButton.js";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: "90px",
    height: "90px",
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
}));

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

function HeaderTitle(props) {
  const { title, icon, divider = true, secondaryEL } = props;

  return (
    <div style={{ marginBottom: 5, marginTop: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify
            style={{
              marginRight: "5px",
              color: "rgb(92 167 143)",
              width: 22,
              height: 22,
            }}
            icon={icon}
          />
          <Typography
            style={{ fontSize: 16, fontWeight: 500, color: "#4d4d4d" }}
          >
            {title}
          </Typography>
        </div>
        {/* Render secondaryEL if it exists */}
        {secondaryEL && <div>{secondaryEL}</div>}
      </div>

      {divider ? <Divider style={{ margin: "7px", marginBottom: 15 }} /> : ""}
    </div>
  );
}

export default function EditMultiRefundForm(props) {
  const {
    updateRefund,
    extraForEdit,
    type,
    headerForEdit,
    contact_id,
    onClose = null,
  } = props;
  const dispatch = useDispatch();
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const watchAllFields = watch();
  const [gateways, setGateways] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const { buildingsDropdown, buildingsDropdownLoading } = useSelector(
    (state) => state.buildingsDropdown
  );
  const { users } = useSelector((state) => state.users);
  const { refundReasons } = useSelector((state) => state.refundReasons);
  const [lockDate, setLockDate] = useState(false);
  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [contactInvoices, setContactInvoices] = useState([]);

  // Bind Online Transaction
  const userCanBindOnlineTransaction = CRMUtils.userHasPermission(authUser, [
    "merchants_bindonlinetransaction",
  ]);

  const [openBindTransactionPopup, setOpenBindTransactionPopup] =
    useState(false);

  const [merchantIdVerification, setMerchantIdVerification] = useState({
    billingInformation: { cardholder: null, last_4: null },
    transactionId: null,
    isVerified: null,
    isLoading: false,
    message: null,
  });

  const classes = useStyles();

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    let totalToRefund = watchAllFields["payment_histories"].reduce(
      (total, item) => total + parseFloat(item.new_amount),
      0
    );

    if (watchAllFields["payment_histories"]) {
      data["total_amount"] = totalToRefund;
    }

    data["payment_histories"] = JSON.stringify(
      data["payment_histories"].filter((a) => a.amount != 0)
    );

    if (data["file"]) {
      const result = await fileToBase64(data["file"]);
      data["file"] = result;
    }

    data["payment_date"] = moment(data["payment_date"]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    /*
        If the userCanBindOnlineTransaction and the merchantIdVerification is verified,
        add the validation data to the formData
      */
    if (userCanBindOnlineTransaction && merchantIdVerification.isVerified) {
      let merchantTransactionIdVerification = {
        transaction_id: merchantIdVerification.transactionId,
        amount: totalToRefund,
        is_refund: true,
      };

      data["merchant_transaction_id_verification"] = JSON.stringify(
        merchantTransactionIdVerification
      );
    }

    await updateRefund(data);
    setIsSubmitting(false);
  };

  const getGateways = async () => {
    const params = new URLSearchParams([["contact_id", contact_id]]);

    const { data } = await Api.getPaymentGateways(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );

    setGateways([...data.data]);
  };

  // const paymentOptions = () => {
  //   return gateways.map((gateway) => {
  //     return {
  //       id: gateway.name,
  //       title: gateway.name,
  //     };
  //   });
  // };

  const paymentOptions = () => {
    const options = gateways.map((gateway) => ({
      id: gateway.name,
      title: gateway.name,
      selected:
        headerForEdit &&
        gateway.name.toLowerCase() === headerForEdit.payment_type.toLowerCase(),
    }));

    if (headerForEdit && !options.some((option) => option.selected)) {
      options.push({
        id: headerForEdit?.payment_type,
        title: headerForEdit?.payment_type,
        selected: true,
      });
    }
    return options;
  };

  const buildingOptions = () => {
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const getUserOptions = () => {
    return users
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const getBuildingId = () => watchAllFields["building_id"];
  const getTotalAmount = () =>
    watchAllFields["payment_histories"].reduce(
      (total, item) => total + parseFloat(item.new_amount),
      0
    );

  useEffect(() => {
    if (isEmpty(buildingsDropdown) && !buildingsDropdownLoading) {
      dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
    }
    if (isEmpty(refundReasons)) {
      dispatch(fetchRefundReasonsService(authUser.organization_id));
    }
    if (isEmpty(users)) {
      dispatch(fetchUsersService(authUser.organization_id));
    }
    getGateways();
  }, []);

  const refundReasonsOptions = () => {
    return refundReasons.map((refundReason) => {
      return {
        id: refundReason.id,
        title: refundReason.name,
      };
    });
  };

  useEffect(() => {
    if (watchAllFields.building_id && !userCanEditPaymentDateLock) {
      const building = buildingsDropdown.find((building) => {
        return building.id === watchAllFields.building_id;
      });
      if (building && building.lock_payment_date) {
        setValue("payment_date", moment().format("YYYY-MM-DD HH:mm:ss"));

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  }, [watchAllFields.building_id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Tip title={`Notice: `} message={`* Indicates a required field`} />
      <div
        style={{
          padding: "16px 16px",
          boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 5,
            borderBottom: "1px solid #8080803b",
            marginBottom: "14px",
          }}
        >
          <HeaderTitle
            icon="ion:wallet"
            title="Refund details"
            divider={false}
          />

          <Controller
            name={"file"}
            control={control}
            defaultValue={null}
            as={({ onChange, value }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {watchAllFields["file"] ||
                (headerForEdit &&
                  headerForEdit.file &&
                  headerForEdit.file.path &&
                  headerForEdit.file.name) ? (
                  <a
                    style={{ marginLeft: 8 }}
                    target="_blank"
                    href={
                      watchAllFields["file"]
                        ? window.URL.createObjectURL(watchAllFields["file"])
                        : headerForEdit.file.path
                    }
                  >
                    {watchAllFields["file"]
                      ? watchAllFields["file"]["name"]
                      : headerForEdit.file.name}{" "}
                  </a>
                ) : (
                  ""
                )}
                {errors.file && (
                  <p className={classes.error}>{errors.file.message}</p>
                )}
                <Controls.DropzoneDialog
                  startIcon={<Iconify icon="basil:upload-solid" />}
                  label="Upload Receipt*"
                  st={{
                    borderRadius: 22,
                    padding: "6px 15px",
                    marginLeft: 25,
                    backgroundColor: "rgb(229 240 249 / 55%)",
                  }}
                  variant="outlined"
                  onChange={(files) => {
                    onChange(files[0]);
                  }}
                  value={value}
                />
              </div>
            )}
            rules={
              headerForEdit && headerForEdit.file && headerForEdit.file.path
                ? undefined
                : {
                    required: "*Receipt is required",
                  }
            }
          />
        </div>

        <Grid container spacing={2}>
          {!merchantIdVerification.isVerified &&
            !merchantIdVerification.transactionId && (
              <Grid item xs={6}>
                <Controller
                  name={"payment_type"}
                  control={control}
                  defaultValue={headerForEdit?.payment_type || null}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label="Payment Type*"
                      options={paymentOptions()}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                    />
                  )}
                  rules={{
                    required: "*Payment Type is required",
                  }}
                />
                {errors.payment_type && (
                  <p className={classes.error}>{errors.payment_type.message}</p>
                )}
              </Grid>
            )}

          <Grid item xs={6}>
            <Controller
              name={`refund_reason_id`}
              control={control}
              defaultValue={headerForEdit?.refund_reason_id || null}
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Refund Reason*"
                  options={refundReasonsOptions()}
                  onChange={(event) => onChange(event.target.value)}
                  value={value}
                />
              )}
              rules={{
                required: "*Reason is required",
              }}
            />
            {errors[`refund_reason_id`] && (
              <p className={classes.error}>
                {errors[`refund_reason_id`].message}
              </p>
            )}
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="payment_date"
              type="date"
              control={control}
              defaultValue={
                headerForEdit
                  ? moment(headerForEdit.payment_date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : moment().format("YYYY-MM-DD HH:mm:ss")
              }
              as={({ onChange, value }) => (
                <Controls.DateTimePicker
                  label="Date*"
                  fullWidth
                  shouldCloseOnSelect
                  defaultValue={
                    headerForEdit
                      ? moment(headerForEdit.payment_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : moment().format("YYYY-MM-DD HH:mm:ss")
                  }
                  onChange={(date) => onChange(date)}
                  value={value}
                  disabled={!userCanEditPayment || lockDate}
                />
              )}
              rules={{
                required: "*Date is required",
              }}
            />
            {errors.payment_date && (
              <p className={classes.error}>{errors.payment_date.message}</p>
            )}
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`responsible_id`}
              control={control}
              defaultValue={headerForEdit?.responsible_id || null}
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Surgeon"
                  // disabled={!userCanChangeBuilding}
                  options={getUserOptions()}
                  onChange={(event) => onChange(event.target.value)}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="building_id"
              control={control}
              defaultValue={
                headerForEdit?.building_id || authUser.building_id || ""
              }
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Building*"
                  options={buildingOptions()}
                  onChange={(value) => onChange(value)}
                  value={value}
                  disabled={!userCanChangeBuilding}
                />
              )}
              rules={{
                required: "*Building is required",
              }}
            />
            {errors.building_id && (
              <h4 className={classes.errors}>{errors.building_id.message}</h4>
            )}
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="details"
              control={control}
              defaultValue={headerForEdit?.details || null}
              as={
                <Controls.Textarea
                  rows={4}
                  style={{ width: "100%" }}
                  label="Details"
                />
              }
            />
            {errors.building_id && (
              <h4 className={classes.errors}>{errors.building_id.message}</h4>
            )}
          </Grid>
          <Grid
            style={{
              marginTop: 12,
              paddingLeft: 20,
            }}
            item
            xs={4}
          >
            <FormControlLabel
              labelPlacement="end"
              control={
                <Controller
                  name={`ptChangePayment`}
                  control={control}
                  defaultValue={headerForEdit?.ptChangePayment || false}
                  render={({ onChange, value }) => (
                    <SSwitch
                      key={`ptChangePayment`}
                      checked={value}
                      onChange={(value) => {
                        onChange(value.target.checked);
                      }}
                    />
                  )}
                />
              }
              label={
                <>
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginBottom: 0.5,
                      color: "#607d8b",
                      textAlign: "center",
                    }}
                  >
                    Patient Change Payment
                  </Typography>
                </>
              }
            />

            <FormControlLabel
              labelPlacement="end"
              control={
                <Controller
                  name={`isChargeback`}
                  control={control}
                  defaultValue={headerForEdit?.isChargeback || false}
                  render={({ onChange, value }) => (
                    <SSwitch
                      key={`isChargeback`}
                      checked={value}
                      onChange={(value) => {
                        onChange(value.target.checked);
                      }}
                    />
                  )}
                />
              }
              label={
                <>
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginBottom: 0.5,
                      color: "#607d8b",
                      textAlign: "center",
                    }}
                  >
                    Chargeback
                  </Typography>
                </>
              }
            />
          </Grid>
        </Grid>
        <br />
        {merchantIdVerification.transactionId ||
        ((!headerForEdit ||
          (headerForEdit && !headerForEdit.merchant_transaction_id)) &&
          userCanBindOnlineTransaction) ||
        (watchAllFields["payment_type"] &&
          (gateways.find((a) => a.name == watchAllFields["payment_type"])
            ?.is_card_required ||
            false)) ? (
          <>
            {" "}
            <HeaderTitle
              icon="solar:card-bold"
              title="Transaction details"
              secondaryEL={
                userCanBindOnlineTransaction ? (
                  <BindOnlineTransactionButton
                    openBindTransactionPopup={openBindTransactionPopup}
                    setOpenBindTransactionPopup={setOpenBindTransactionPopup}
                    setMerchantIdVerification={setMerchantIdVerification}
                    merchantIdVerification={merchantIdVerification}
                    getBuildingId={getBuildingId}
                    getTotalAmount={getTotalAmount}
                    isRefund={true}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="cardholder"
                  control={control}
                  defaultValue={headerForEdit?.cardholder || ""}
                  as={<Controls.Input fullWidth label="Card holder" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="cc_last_4"
                  control={control}
                  defaultValue={headerForEdit?.cc_last_4 || ""}
                  as={<Controls.Input fullWidth label="Last 4 CC" />}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="transaction_id"
                  control={control}
                  defaultValue={headerForEdit?.transaction_id || ""}
                  as={<Controls.Input fullWidth label="Transaction id" />}
                />
              </Grid>
            </Grid>
            <br />
          </>
        ) : (
          ""
        )}

        <Controller
          name={"payment_histories"}
          control={control}
          defaultValue={null}
          render={({ onChange, value }) => (
            <EditHeaderHistoryToMultiRefundForm
              histories={
                headerForEdit &&
                headerForEdit.paymentHistories &&
                headerForEdit.paymentHistories.data &&
                !isEmpty(headerForEdit.paymentHistories.data)
                  ? headerForEdit.paymentHistories.data
                  : []
              }
              onChange={onChange}
              header={headerForEdit}
              isEdit={true}
            />
          )}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: 8,
        }}
      >
        <Controls.Button
          onClick={() => {
            if (typeof onClose == "function") {
              onClose();
            }
          }}
          disabled={isSubmitting}
          variant="contained"
          color="inherit"
          text={"Cancel"}
        />
        <Controls.Button
          disabled={
            isSubmitting ||
            (merchantIdVerification.transactionId &&
              merchantIdVerification.transactionId != "" &&
              merchantIdVerification.isVerified === false)
          }
          type="submit"
          variant="contained"
          color="primary"
          text={isSubmitting ? "Submitting..." : "Submit"}
        />
      </div>
    </form>
  );
}
