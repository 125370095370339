import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import useTable from "./useTable";
import { isEmpty } from "lodash";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import Api from "../lib/api";
import { Paper } from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));
function DocumentsSentContacts(props) {
  const classes = useStyles();
  const { documents, contact_id, consultantErrorHandle = null } = props;

  const [documentsSend, setDocumentsSend] = useState([]);

  const dataMaterialTable = documentsSend
    ? documentsSend.map((o) => ({ ...o }))
    : [];

  const [progressBar, setProgressBar] = useState(false);

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact_id) {
      setProgressBar(true);
      documentsSendFunc().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const documentsSendFunc = async () => {
    try {
    const { data } = await Api.getDocumentsSend(contact_id);
    var documents = [];
    data.data.forEach((element)=>{
      documents = [].concat(documents, element.document.data)
    })
    setDocumentsSend([...documents]);
  } catch (e) {
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(e.data.message);
    }
  }
  };

  const headCellsMaterial = [
    {
      field: "created_at",
      title: "Sent Date",
      render: (rowData) => {
       
        return rowData.created_at
          ? moment(rowData.created_at).format("YYYY-MM-DD hh:mm A")
          : "";
      },
    },
    {
      field: "name",
      title: "Name",
      render: (rowData) => {
        return !isEmpty(rowData) ? rowData.name : "";
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.name.toLowerCase().indexOf(term.toLowerCase()) != -1,
    },
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return !isEmpty(rowData) ? rowData.collection_name : "";
      },
    },

    {
      field: "file",
      title: "File",
      render: (rowData) =>
        !isEmpty(rowData) ? (
          <a href={rowData.url} target="_blank" rel="noreferrer">
            <AttachFileIcon />
          </a>
        ) : (
          ""
        ),
    },
  ];

  const { TblContainer } = useTable();

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              loadingType: "linear",
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Documents Sent"
          />
        </TblContainer>
      </Paper>
    </Wrapper>
  );
}

export default DocumentsSentContacts;
