import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

export default function DatePicker(props) {
  const { label, onChange, inputVariant = "outlined", value, ...other } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant={"inline"}
        inputVariant={inputVariant}
        label={label}
        format="MM/DD/yyyy"
        onChange={(date) => onChange(date)}
        value={value}
        className={classes.textfield}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
