import React, { useState } from "react";
import { AppBar, Box, Snackbar, Tab, Tabs } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Payments from "./Payments";
import PaymentLinkRequests from "./PaymentLinkRequests";
import Notification from "./Notification";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

export default function PaymentsComponent(props) {
  const {
    invoice,
    payments,
    setInvoice,
    getInvoicesContact,
    onDelete,
    gateways,
    authUser,
    reload,
    userIsbillingMGAssistant,
    onMarkPossibleRefund,
    setOpenDetailPopup
  } = props;
  const [tabValue, setTabValue] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Payments" />
          <Tab label="Non-3DS Payment Link Requests" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <Payments
          invoice={invoice}
          payments={payments}
          setInvoice={setInvoice}
          getInvoicesContact={getInvoicesContact}
          onDelete={onDelete}
          gateways={gateways}
          authUser={authUser}
          reload={reload}
          userIsbillingMGAssistant={userIsbillingMGAssistant}
          onMarkPossibleRefund={onMarkPossibleRefund}
          setOpenDetailPopup={setOpenDetailPopup}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <PaymentLinkRequests
          invoiceID={invoice?.id || null}
          setNotify={setNotify}
        />
      </TabPanel>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
