import React, { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CRMUtils from "../../utils";
import { isEmpty } from "lodash";
import moment from "moment/moment";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
}));

export default function ChangeProcedureStatusForm(props) {
  const {
    selectedRecordProduct = null,
    cancellationMotives = [],
    changeStatus,
    selectedStatus,
    isSubmitting = false,
    showPredate = false,
    selectedRecordProducts = [],
  } = props;
  const { t, i18n } = useTranslation(["common", "records"]);
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);
  const userCanOverrideOfferDateLock =
    CRMUtils.userHasPermission(authUser, [
      "recordproduct_overrideOfferDateLock",
    ]) || CRMUtils.userHasRole(authUser, ["super-admin"]);

  const cancellationOptions = () => {
    return cancellationMotives.map((cancellationMotive) => {
      return {
        id: cancellationMotive.name,
        title: cancellationMotive.name,
      };
    });
  };

  const onSubmit = (data) => {
    changeStatus(data);
  };

  const disableDates2 = (dateL) => {
    // Allow super-admin or permission recordproduct_overrideOfferDateLock to select any date
    if (userCanOverrideOfferDateLock) {
      return false;
    }

    const date = moment(dateL);
    const weekDay = date.isoWeekday();

    if (weekDay === 7) return true;

    if (selectedRecordProducts && !isEmpty(selectedRecordProducts)) {
      var result = false;
      selectedRecordProducts.forEach((recordProduct) => {
        const userPriceA =
          recordProduct && recordProduct.userProductPrice
            ? recordProduct.userProductPrice
            : null;

        if (
          (userPriceA &&
            userPriceA.booking_from &&
            date.isBefore(userPriceA.booking_from, "day")) ||
          (userPriceA &&
            userPriceA.booking_to &&
            date.isAfter(userPriceA.booking_to, "day"))
        ) {
          result = true;
        }
      });
      return result;
    }

    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2}>
        {selectedStatus === "TENTATIVE" && (
          <Grid item xs={12}>
            <Controller
              name="tentative_date"
              type="date"
              control={control}
              defaultValue={null}
              as={({ onChange, value }) => (
                <Controls.DateTimePicker
                  style={{ width: "100%" }}
                  label={t("common:general.Tentative_date")}
                  shouldCloseOnSelect
                  onChange={(date) => {
                    onChange(date);
                  }}
                  value={value}
                />
              )}
              rules={{
                required:
                  t("common:general.Tentative_date") +
                  " " +
                  t("common:general.Required"),
                pattern: {
                  value:
                    /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,
                },
              }}
            />

            {errors.tentative_date && (
              <p className={classes.error}>{errors.tentative_date.message}</p>
            )}
          </Grid>
        )}

        {selectedStatus === "SCHEDULED" && (
          <>
            {showPredate ? (
              <Grid item xs={6}>
                <Controller
                  name="preop_date"
                  type="date"
                  control={control}
                  defaultValue={null}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      label={"Pre Date"}
                      shouldCloseOnSelect
                      onChange={(date) => {
                        onChange(date);
                      }}
                      fullWidth
                      value={value}
                    />
                  )}
                />
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={showPredate ? 6 : 12}>
              <Controller
                name="date"
                type="date"
                control={control}
                defaultValue={null}
                as={({ onChange, value }) => (
                  <Controls.DateTimePicker
                    label={t("common:general.Date") + " *"}
                    shouldCloseOnSelect
                    onChange={(date) => {
                      onChange(date);
                    }}
                    fullWidth
                    value={value}
                    shouldDisableDate={disableDates2}
                  />
                )}
                rules={{
                  required:
                    t("common:general.Date") +
                    " " +
                    t("common:general.Required"),
                  pattern: {
                    value:
                      /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,
                  },
                }}
              />

              {errors.date && (
                <p className={classes.error}>{errors.date.message}</p>
              )}
            </Grid>
          </>
        )}

        {selectedStatus === "CANCELED" && (
          <Grid item xs={12}>
            <Controller
              name="cancellation_reason"
              control={control}
              defaultValue={null}
              as={({ onChange, value }) => (
                <Controls.Select
                  label={"Cancellation Reason *"}
                  options={cancellationOptions()}
                  onChange={(value) => {
                    onChange(value.target.value);
                  }}
                  value={value}
                />
              )}
              rules={{
                required:
                  "Cancellation Reason" + " " + t("common:general.Required"),
              }}
            />

            {errors.cancellation_reason && (
              <p className={classes.error}>
                {errors.cancellation_reason.message}
              </p>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          {selectedStatus != "SCHEDULED" ||
          (selectedStatus == "SCHEDULED" &&
            selectedRecordProduct &&
            selectedRecordProduct.has_had_date) ||
          !selectedRecordProduct ? (
            <>
              {" "}
              <Controller
                name="comment"
                control={control}
                defaultValue=""
                as={<Controls.Textarea label="Comment *" />}
                rules={{
                  required:
                    t("common:general.Comment") +
                    " " +
                    t("common:general.Required"),
                }}
              />
              {errors.comment && (
                <p className={classes.error}>{errors.comment.message}</p>
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
        <Controls.Button
          disabled={isSubmitting}
          type="submit"
          text={isSubmitting ? "Submitting..." : "Submit"}
        />
      </Grid>
    </form>
  );
}
