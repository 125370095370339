import React, { useState, useEffect } from "react";
import { Alert, Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../controls";
import UploadMultiFile from "./UploadMultiFile";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
    "& .MuiDropzoneArea-root": {
      minHeight: "100px",
    },
  },
  error: {
    color: "red",
    fontWeight: "700",
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

export default function DocumentForm(props) {
  const {
    user,
    documentTypes,
    saveDocument,
    enableCamera,
    changeDimensions = false,
    ...other
  } = props;

  const defaultValues = {
    filename: "",
    docType: "",
    files: [],
  };

  const { handleSubmit, reset, control, errors } = useForm({
    defaultValues,
  });

  const [files, setFiles] = useState([]);

  const classes = useStyles();

  const documentOptions = () => {
    return documentTypes.map((type) => {
      return {
        id: type.id,
        title: type.name,
      };
    });
  };

  const onDropFiles = (newFiles) => {
    setFiles([...files, ...newFiles]);
  };

  const onSubmit = (data) => {
    data["doc_type_id"] = data["docType"];

    data["docType"] =
      documentTypes.find((type) => type.id == data["doc_type_id"])?.name ||
      null;
      
    data["files"] = files;

    saveDocument(data);

    reset(defaultValues);
  };

  // useEffect(() => {
  //   console.log("filesTfilesT", filesT);
  // }, [filesT]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Controller
            name="filename"
            control={control}
            defaultValue=""
            as={<Controls.Input label="File Name" />}
            rules={{
              required: "Contact first name is required",
              pattern: {
                value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/i,
                message: "File name can only accept characters",
              },
            }}
          />
          {errors.filename && (
            <p className={classes.error}>{errors.filename.message}</p>
          )}
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="docType"
            control={control}
            defaultValue=""
            as={({ onChange, value }) => (
              <Controls.Select
                label="Document Type"
                options={documentOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
            rules={{ required: "File type is required" }}
          />
          {errors.docType && (
            <p className={classes.error}>{errors.docType.message}</p>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <UploadMultiFile
            files={files}
            showPreview
            name="files"
            accept={{
              "image/jpeg": [".jpg", ".jpeg"],
              "image/png": [".png"],
              "application/pdf": [".pdf"],
            }}
            maxSize={1249999}
            onDrop={onDropFiles}
            iconSelect={"streamline:ai-generate-portrait-image-spark-solid"}
            // onRemove={handleRemove}
            // onRemoveAll={handleRemoveAll}
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={{ justifyContent: "flex-end", alignItems: "center" }}
        spacing={3}
      >
        <Grid item>
          <Controls.Button text="Reset" onClick={() => reset(defaultValues)} />
        </Grid>
        <Grid item>
          <Controls.Button
            type="submit"
            text="Submit"
            disabled={files.length <= 0}
          />
        </Grid>
      </Grid>
    </form>
  );
}
