import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import SelectActive from "./SelectActive";

import MultipleSelect from "./MultipleSelect";
import MultipleSelectCheckbox from "./MultipleSelectCheckbox";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import DatePicker2 from "./DatePicker2";
import DatePickerPeriod from "./DatePickerPeriod";
import DatePicker3 from "./DatePicker";
import Button from "./Button";
import AdornedButton from "./AdornedButton";
import ActionButton from "./ActionButton";
import IconButton from "./IconButton";
import IconDownloadButton from "./IconDownloadButton";
import DropzoneDialog from "./DropzoneDialog";
import DropzoneDialogIcon from "./DropzoneDialogIcon";
import DropzoneSendPicture from "./DropzoneSendPicture";
import AutocompleteAsyncMessages from "./AutocompleteAsyncMessages";

import DropzoneBox from "./DropzoneBox";
import DateTimePicker from "./DateTimePicker";
import InputMoney from "./InputMoney";
import InputMoneyToPaymentForm from "./InputMoneyToPaymentForm";
import InputDecoration from "./InputDecoration";
import Textarea from "./Textarea";
import Autocomplete from "./Autocomplete";
import AutocompleteMultiple from "./AutocompleteMultiple";
import AutocompleteTags from "./AutocompleteTags";
import AutocompleteTagsD from "./AutocompleteTagsD";

import Autocomplete2 from "./Autocomplete2";
import AutocompleteCustom from "./AutocompleteCustom";

import NumberFormatCustom from "./NumberFormatCustom";
import ColorPicker from "./ColorPicker";
import SelectModel from "./SelectModel";
import CheckboxesGroup from "./CheckboxesGroup";
import Popper from "./Popper";
import AutocompleteAsync from "./AutocompleteAsync";
import RichTextarea from "./RichTextarea";
import AutocompleteMultiple2 from "./AutocompleteMultiple2";
import ActionButtonFloating from "./ActionButtonFloating";
import PieChart from "./PieChart";
import IconSubmitButton from "./IconSubmitButton";
import MultipleSelectChipID from "./MultipleSelectChipID";
import SelectWColor from "./SelectWColor";
import TimePicker from "./TimePicker";
import { Tip } from "./Tip";

import InputMoneyOk from "./InputMoneyOk";


const Controls = {
  Input,
  RadioGroup,
  Select,
  SelectActive,
  MultipleSelect,
  Checkbox,
  DatePicker,
  DatePicker2,
  DatePicker3,
  DatePickerPeriod,
  DateTimePicker,
  Button,
  AdornedButton,
  ActionButton,
  IconButton,
  IconDownloadButton,
  DropzoneDialog,
  DropzoneDialogIcon,
  DropzoneBox,
  InputMoney,
  InputMoneyToPaymentForm,
  InputDecoration,
  Textarea,
  Autocomplete,
  Autocomplete2,
  AutocompleteCustom,
  AutocompleteMultiple,
  AutocompleteTags,
  SelectModel,
  ColorPicker,
  NumberFormatCustom,
  CheckboxesGroup,
  Popper,
  MultipleSelectCheckbox,
  AutocompleteAsync,
  RichTextarea,
  AutocompleteMultiple2,
  ActionButtonFloating,
  PieChart,
  DropzoneSendPicture,
  IconSubmitButton,
  AutocompleteAsyncMessages,
  MultipleSelectChipID,
  AutocompleteTagsD,
  SelectWColor,
  TimePicker,
  InputMoneyOk,
  Tip
};

export default Controls;
