import React, { useState, useEffect } from "react";
import {
  Grid,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
  Checkbox,
  Fab,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { findIndex, isEmpty } from "lodash";
import Api from "../../lib/api";
import Iconify from "../Iconify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { useRef } from "react";
import ProductsControlForm from "./ProductsControlForm";
import Popup from "../Popup";
import ConfirmDialog from "../ConfirmDialog";
import CustomSelect from "../controls/CustomSelect";
import { fetchEntitiesService as fetchAppointmentsNewCancelationReasonsService } from "../../redux/slices/appointmentCancelationReasonSlice";
import CRMUtils from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import InstructionsForm from "./InstructionsForm";
import ScrollBar from "react-perfect-scrollbar";
import LinkAppointmentsToRecordProducts from "../LinkAppointmentsToRecordProducts";

const useStyles = makeStyles((theme) => ({
  fromInvoice: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    padding: 7,
    margin: 8,
    backgroundColor: "#f1f1f1",
    borderRadius: 10,
  },
  dropzoneButton: {
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
  },
  notDone: {
    background: "#fbdfdf",
    color: "#b23838",
    fontWeight: 600,
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
  done: {
    background: "#c8ffe2",
    color: "#01934c",
    fontWeight: 600,
  },
  checkedDate: {
    background: "#eaedef",
    color: "#2f4d74",
    fontWeight: 600,
  },
  menu: {
    boxShadow: "0 0 14px 0 rgb(53 64 82 / 15%)",
    border: "1px solid #eae8e8",
  },
  alert: {
    "& .MuiAlert-icon": {
      display: "flex",
      opacity: 0.9,
      padding: "7px 0",
      fontSize: 20,
      marginRight: 3,
    },
    width: "fit-content",
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 10,
    marginLeft: 5,
  },
  error: {
    margin: 10,
    marginTop: 0,
    color: "#ff1616",
  },
  inputs: {
    width: "100%",
  },
  icon: {
    margin: 5,
    fontSize: 26,
    color: "#5ea8f6",
  },
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
}));

var dataSliceAppointmentsNewCancelationReasons = {
  stateListName: "appointmentsNewCancelationReasons",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

export default function AppointmentNewForm(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const contactsPage = useRef(0);
  const { authUser } = useSelector((state) => state.auth);
  const [openPopupProduct, setOpenPopupProduct] = useState(false);
  const userCanResponsibleDone = CRMUtils.userHasPermission(authUser, [
    "appointmentsnew_doneonlybyresponsible",
  ]);
  const { appointmentsNewCancelationReasons } = useSelector(
    (state) => state.appointmentsNewCancelationReasons
  );
  const userCanEditClosedAppointments = CRMUtils.userHasPermission(authUser, [
    "appointmentsnew_editclosedappointments",
  ]);
  const {
    contactProp = null,
    disabledContact = null,
    users,
    addOrEdit,
    appointmentNewForEdit,
    buildings,
    date = null,
    setPopupWidth = null,
  } = props;
  const [selectedRecordProducts, setSelectedRecordProducts] = useState(
    appointmentNewForEdit?.recordProducts || []
  );
  const formDisabled =
    appointmentNewForEdit &&
    (appointmentNewForEdit.status == "canceled" ||
      appointmentNewForEdit.status == "completed") &&
    !userCanEditClosedAppointments
      ? true
      : false;
  const [isCompleteByOwner, setIsCompleteByOwner] = useState(
    appointmentNewForEdit && appointmentNewForEdit.is_completed_by_owner
  );
  const [notifyUser, setNotifyUser] = useState(
    appointmentNewForEdit && appointmentNewForEdit.notify_user ? 1 : 0
  );
  const [isCompleteByResponsible, setIsCompleteByResponsible] = useState(
    appointmentNewForEdit && appointmentNewForEdit.is_completed_by_responsible
  );
  const [showAlert, setShowAlert] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const cancelationReason = useRef(
    appointmentNewForEdit && appointmentNewForEdit.status == "canceled"
      ? appointmentNewForEdit.cancelation_reason_id
      : ""
  );
  const [selectedType, setSelectedType] = useState(
    appointmentNewForEdit && appointmentNewForEdit.type_id
      ? appointmentNewForEdit.type_id
      : null
  );
  const [selectedDate, setSelectedDate] = useState(
    appointmentNewForEdit && !isEmpty(appointmentNewForEdit.recordProducts)
      ? moment(appointmentNewForEdit.recordProducts[0].date).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      : appointmentNewForEdit && appointmentNewForEdit.date_of_appointment
      ? appointmentNewForEdit.date_of_appointment
      : moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [buildingSelected, setBuildingSelected] = useState(
    appointmentNewForEdit && appointmentNewForEdit.building_id
      ? appointmentNewForEdit.building_id
      : authUser.building_id
  );
  const [searchContact, setSearchContact] = useState({
    contact_id: appointmentNewForEdit
      ? appointmentNewForEdit?.contact_id || -1
      : contactProp
      ? contactProp.contact_id
      : -1,
    search: appointmentNewForEdit
      ? appointmentNewForEdit.contact?.data?.full_name || ""
      : contactProp
      ? contactProp.search
      : "",
    has_procedure_done: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(
    appointmentNewForEdit && appointmentNewForEdit.owner_id
      ? appointmentNewForEdit.owner_id
      : null
  );
  const [selectedStatus, setSelectedStatus] = useState(
    appointmentNewForEdit ? appointmentNewForEdit.status : "registered"
  );
  const [responsibles, setResponsibles] = useState(
    appointmentNewForEdit &&
      appointmentNewForEdit.usersresponsibles &&
      appointmentNewForEdit.usersresponsibles.data.length > 0
      ? appointmentNewForEdit.usersresponsibles.data.map((user) => {
          return user.id;
        })
      : []
  );
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const classes = useStyles();
  const [openAttachment, setOpenAttachment] = useState(false);
  const [contactsIsLoading, setContactsIsLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [urlMedia, setUrlMedia] = useState(
    appointmentNewForEdit && appointmentNewForEdit.tempUrl
      ? appointmentNewForEdit.tempUrl
      : null
  );
  const [totalContacts, setTotalContacts] = useState(-1);
  const [file, setFile] = useState(null);
  const [informationOpened, setInformationOpened] = useState(
    appointmentNewForEdit && appointmentNewForEdit.instruction ? true : false
  );
  const [appointmentInstruction, setAppointmentInstruction] = useState(
    appointmentNewForEdit && appointmentNewForEdit.instruction
  );
  const [instructions, setInstructions] = useState([]);
  const [addOrEditInstructionOpened, setAddOrEditInstructionOpened] =
    useState(false);
  const [instructionForEdit, setInstructionForEdit] = useState(null);

  const onSubmit = async (data, event) => {
    data["instruction_id"] = appointmentInstruction?.id || null;
    data["record_products"] = selectedRecordProducts.map((rp) => rp.id);
    const isAdd = (event?.nativeEvent?.submitter?.id || "") == "add_button_id";
    setShowAlert(false);
    if (searchContact.contact_id && searchContact.contact_id != -1) {
      var type = appointmentTypes.find((a) => a.id == data["type_id"]);

      if (
        type.disabled &&
        type.wait_list > 0 &&
        type.available == 0 &&
        !(isCompleteByOwner && isCompleteByResponsible)
      ) {
        data["status"] = "pending_to_confirm";
        data["is_waitlist"] = 1;
      } else {
        data["is_waitlist"] = 0;
      }

      var fileAttach = null;
      let formData = new FormData();
      if (file) {
        fileAttach = {
          media_type: file.type,
          id: 1,
          errors: [],
          file: file,
          valid: true,
        };
        Object.keys(fileAttach).forEach((key) =>
          formData.append(key, fileAttach[key])
        );
        formData.append("docType", "AppointmentsNew_media");
      } else {
        if (!file && !urlMedia) {
          data = { ...data, ...{ deleteAttach: true } };
        }
      }

      data.contact_id = searchContact.contact_id;
      if (isAdd) {
        setIsAdding(true);
      } else {
        setIsLoading(true);
      }

      if (!appointmentNewForEdit) {
        if (data.responsibles) {
          data = {
            ...data,
            ...{ responsibles: JSON.stringify(data.responsibles) },
          };
        }
        var payload = {};
        Object.keys(data).forEach((key) => {
          if (data[key] != null) {
            payload[key] = data[key];
          }
        });

        if (file) {
          let fileAttach = {
            media_type: file.type,
            id: 1,
            errors: [],
            file: file,
            valid: true,
          };
          payload = { ...payload, ...fileAttach };
        }

        let formData = new FormData();
        Object.keys(payload).forEach((key) =>
          formData.append(key, payload[key])
        );
        formData.append("docType", "AppointmentsNew_media");
        await addOrEdit(formData, null, isAdd);
      } else {
        await addOrEdit(data, file ? formData : null, isAdd);
      }

      if (isAdd) {
        setValue("date_of_appointment", null);
        setIsAdding(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setSearchContact({ ...searchContact, ...{ contact_id: null } });
    }
  };

  useEffect(() => {
    getAvailableUsers();
    control.setValue("type_id", selectedType);
  }, [selectedType]);

  const getAppointmentInstructions = async () => {
    const { data } = await Api.getAppointmentInstructions();

    setInstructions([...data]);
  };

  const getAppointmentsTypes = async () => {
    var payload = {
      date: selectedDate,
      user: selectedOwner,
      building_id: buildingSelected,
      appointment_id: appointmentNewForEdit ? appointmentNewForEdit.id : null,
    };
    const { data } = await Api.getAvailableTypes(payload);
    setAppointmentTypes([...data]);
  };

  useEffect(() => {
    if (selectedOwner) {
      setShowAlert(false);
    }
    getAppointmentsTypes();
  }, [selectedDate, selectedOwner, buildingSelected]);

  const [statusOptions, setStatusOptions] = useState([
    { id: "pending_to_confirm", title: "Pending to Confirm" },
    { id: "pending_to_schedule", title: "Pending to Schedule" },
    { id: "pending_to_reschedule", title: "Pending to Reschedule" },
    { id: "registered", title: "Registered" },
    { id: "canceled", title: "Canceled" },
  ]);

  const handleMenu = (event) => {
    setAnchorEl(event);
  };

  const openConfirmDialog = (
    setConfirmDialogObj,
    onConfirmHandle,
    onConfirmParam,
    title,
    subtitle = null
  ) => {
    setConfirmDialogObj({
      isOpen: true,
      title: title,
      subTitle: "",
      onConfirm: () => onConfirmHandle(onConfirmParam),
    });
  };

  const closeConfirmDialog = (setConfirmDialogObj, confirmDialogObj) => {
    setConfirmDialogObj({
      ...confirmDialogObj,
      isOpen: false,
    });
  };

  const closeInstruction = () => {
    setInformationOpened(false);
    setAppointmentInstruction(null);
    if (setPopupWidth) {
      setPopupWidth("md");
    }
  };

  const getAvailableUsers = async () => {
    var payload = {
      selectedDate: selectedDate,
      org_id: authUser.organization_id,
      type_id: selectedType,
    };

    const { data } = await Api.usersAvailablesDropDown(payload);
    if (
      selectedOwner != null &&
      availableUsers &&
      data.findIndex((a) => a.id == selectedOwner) == -1
    ) {
      setShowAlert(true);
      setValue("owner_id", null);
      setSelectedOwner(null);
    } else {
      setShowAlert(false);
    }

    setAvailableUsers([...data]);
  };

  useEffect(() => {
    getAvailableUsers();
  }, [selectedDate]);

  const contactsOptions = async (value, contactsList) => {
    var payload = {
      building_id: buildingSelected,
      search: value,
      type_id: selectedType,
    };
    var limit = 50;

    setContactsIsLoading(true);
    var page = contactsPage.current + 1;
    contactsPage.current = contactsPage.current + 1;
    const { data } = await Api.getContactsforBuilding(payload, page, limit);
    setContactsIsLoading(false);
    setContacts([...contactsList, ...data.data]);
    setTotalContacts(data.meta.pagination.total);
  };

  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const typesOptions = () => {
    return appointmentTypes.map((type) => {
      return {
        id: type.id,
        title: type.name,
        disabled: type.disabled ? true : false,
        qtyWaitList: type.wait_list == null ? 0 : type.wait_list,
        available: type.available ? type.available : null,
      };
    });
  };

  const instructionOptions = () => {
    return instructions.map((instruction) => {
      return {
        id: instruction.id,
        name: instruction.name,
        body: instruction.body,
        created_by: instruction.created_by,
      };
    });
  };

  useEffect(() => {
    if (appointmentTypes) {
      var item = appointmentTypes.find(
        (a) => a.id == control.getValues("type_id")
      );
      if (item && item.disabled && item.qtyWaitList == 0) {
        control.setValue("type_id", null);
        selectedType(null);
      }
    }
  }, [appointmentTypes]);

  const usersOptions = () => {
    return users.map((user) => {
      return {
        id: user.id,
        title: user.dropdown_name,
      };
    });
  };

  const ownersOptions = () => {
    var userslist =
      availableUsers && availableUsers.length
        ? availableUsers.map((user) => {
            return {
              id: user.id,
              title: user.dropdown_name,
            };
          })
        : [];

    return userslist;
  };

  const cancelationReasonsOptions = () => {
    var reasons =
      appointmentsNewCancelationReasons &&
      appointmentsNewCancelationReasons.length > 0
        ? appointmentsNewCancelationReasons.map((reason) => {
            return {
              id: reason.id,
              title: reason.reason,
            };
          })
        : [];

    return reasons;
  };

  useEffect(() => {
    if (isEmpty(appointmentsNewCancelationReasons)) {
      dispatch(
        fetchAppointmentsNewCancelationReasonsService(
          dataSliceAppointmentsNewCancelationReasons
        )
      );
    }
    getAppointmentInstructions();
  }, []);

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const onSearchChange = (value, event) => {
    contactsPage.current = 0;
    handleMenu(event);
    setTotalContacts(-1);
    contactsOptions(value, []);
  };

  const onClosePopupProduct = () => {
    setOpenPopupProduct(false);
  };

  const calcUsed = (productcontrols) => {
    let total = 0;
    productcontrols.forEach((productcontrol) => {
      if (productcontrol.in_out === "out") {
        total = total + productcontrol.quantity;
      } else {
        total = total - productcontrol.quantity;
      }
    });
    return total;
  };

  const addItem = async (itemForAdd, payload) => {
    const momentDate = new moment(payload.product_control_date);
    payload.product_control_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    payload.user_id = authUser.id;
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    if (!payload.file) {
      formData.delete("file");
    }

    await Api.createInvoiceItemControlHistory(
      itemForAdd.data.invoice_id,
      itemForAdd.data.id,
      formData
    );

    setOpenPopupProduct(false);
  };

  const addOrEditInstruction = async (payload) => {
    if (instructionForEdit) {
      const { data } = await Api.updateAppointmentInstruction(
        instructionForEdit.id,
        payload
      );

      const index = findIndex(instructions, ["id", data.id]);
      const updatedEvent = {
        ...instructions[index],
        ...data,
      };
      let newInstructions = [...instructions];
      newInstructions[index] = updatedEvent;
      setInstructions([...newInstructions]);

      setAppointmentInstruction({ ...updatedEvent });
    } else {
      const { data } = await Api.storeAppointmentInstruction(payload);

      setInstructions([data, ...instructions]);

      setAppointmentInstruction({ ...data });
    }

    setAddOrEditInstructionOpened(false);
    setInstructionForEdit(null);
  };

  useEffect(() => {
    if (
      (isCompleteByOwner && isCompleteByResponsible) ||
      control.getValues("status") == "completed"
    ) {
      setStatusOptions([
        ...[{ id: "completed", title: "Completed" }],
        ...(userCanEditClosedAppointments
          ? [
              { id: "pending_to_confirm", title: "Pending to confirm" },
              { id: "pending_to_schedule", title: "Pending to schedule" },
              { id: "pending_to_reschedule", title: "Pending to reschedule" },
              { id: "registered", title: "Registered" },
              { id: "canceled", title: "Canceled" },
            ]
          : []),
      ]);
      if (isCompleteByOwner && isCompleteByResponsible) {
        setSelectedStatus("completed");
        setValue("status", "completed");
      } else {
        setSelectedStatus(null);
        setValue("status", null);
      }
    } else {
      var statuses = [
        { id: "pending_to_confirm", title: "Pending to confirm" },
        { id: "pending_to_schedule", title: "Pending to schedule" },
        { id: "pending_to_reschedule", title: "Pending to reschedule" },
        { id: "registered", title: "Registered" },
        { id: "canceled", title: "Canceled" },
      ];
      if (appointmentNewForEdit) {
        if (!statuses.find((s) => s.id == appointmentNewForEdit.status)) {
          setStatusOptions([
            ...statuses,
            {
              id: appointmentNewForEdit.status,
              title: appointmentNewForEdit.status
                .replace(/^\w/, (i) => i.toUpperCase())
                .replaceAll("_", " "),
            },
          ]);
        } else {
          setStatusOptions([...statuses]);
        }
      } else {
        setStatusOptions([...statuses]);
      }
    }
  }, [isCompleteByOwner, isCompleteByResponsible, appointmentNewForEdit]);

  useEffect(() => {
    setValue("date_of_appointment", selectedDate);
  }, [selectedDate]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={informationOpened ? 8 : 12}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  minWidth: "fit-content",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!urlMedia && (
                  <Tooltip title="Add attachment">
                    <Controls.DropzoneDialog
                      disableSubmitButton={formDisabled}
                      className={classes.dropzoneButton}
                      filesLimit={1}
                      st={{
                        color: "#1976d2",
                        padding: 2,
                        background: "none",
                        minWidth: "fit-content",
                        margin: 0,
                        padding: 2,
                      }}
                      formats={["image/jpeg", "image/png"]}
                      label=""
                      variant="contained"
                      startIcon={
                        <Iconify
                          style={{ color: "#3e77dd" }}
                          icon="ic:twotone-attachment"
                          rotate="270deg"
                        />
                      }
                      color="secondary"
                      onChange={(files) => {
                        setUrlMedia(URL.createObjectURL(files[0]));
                        setFile(files[0]);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
              {urlMedia && (
                <IconButton
                  onClick={() => {
                    setFile(null);
                    setUrlMedia(null);
                  }}
                >
                  <Iconify
                    style={{ color: "#3e77dd" }}
                    icon="mdi:attachment-off"
                    rotate="270deg"
                  />
                </IconButton>
              )}

              {!informationOpened ? (
                <Tooltip title="Add appointment instructions">
                  <IconButton
                    onClick={() => {
                      setInformationOpened(true);
                      if (setPopupWidth) {
                        setPopupWidth("lg");
                      }
                    }}
                  >
                    <Iconify
                      style={{ color: "#3e77dd" }}
                      icon="majesticons:textbox"
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}

              {informationOpened ? (
                <Tooltip title="Remove instructions">
                  <IconButton
                    onClick={() => {
                      closeInstruction();
                    }}
                  >
                    <Iconify
                      style={{ color: "#3e77dd" }}
                      icon="mdi:text-box-remove"
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}

              <div style={{ width: "100%", textAlign: "right" }}>
                {selectedStatus == "completed" ? (
                  <Chip
                    className={classes.done}
                    icon={
                      <Iconify
                        style={{ fontSize: 18, color: "#01934c" }}
                        icon={"ic:round-done"}
                      />
                    }
                    label={"Done"}
                  />
                ) : (
                  ""
                )}
                {urlMedia && (
                  <Tooltip title="View attachment">
                    <Button
                      endIcon={<Iconify icon="fluent:attach-24-regular" />}
                      style={{ backgroundColor: "#f2f2f2" }}
                      onClick={() => {
                        setOpenAttachment(true);
                      }}
                    >
                      {file
                        ? file.path
                        : appointmentNewForEdit &&
                          appointmentNewForEdit.media_name
                        ? appointmentNewForEdit.media_name
                        : ""}
                    </Button>
                  </Tooltip>
                )}
                {appointmentNewForEdit &&
                appointmentNewForEdit.time_of_arrival ? (
                  <Chip
                    className={classes.checkedDate}
                    label={
                      <Typography>
                        {"Checked by " +
                          (appointmentNewForEdit?.checkupby?.data
                            ?.users_fname || "patient") +
                          " at "}
                        <strong>
                          {moment(appointmentNewForEdit.time_of_arrival).format(
                            "MM/DD/YYYY hh:mm A"
                          )}
                        </strong>
                      </Typography>
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {appointmentNewForEdit &&
              appointmentNewForEdit.status != "canceled" &&
              appointmentNewForEdit.invoiceitem &&
              appointmentNewForEdit.invoiceitem.data.balance > 0 && (
                <Alert
                  style={{
                    padding: "0px 5px",
                    marginBottom: 8,
                    marginLeft: 3,
                    maxWidth: "fit-content",
                  }}
                  hidden
                  severity="warning"
                >
                  The item associated with this appointment has not been paid
                  yet
                </Alert>
              )}
            {appointmentNewForEdit &&
              appointmentNewForEdit.status == "registered" &&
              moment(appointmentNewForEdit.date_of_appointment).format(
                "YYYY-MM-DD"
              ) < moment().format("YYYY-MM-DD") &&
              moment(control.getValues("date_of_appointment")).format(
                "YYYY-MM-DD"
              ) < moment().format("YYYY-MM-DD") &&
              control.getValues("status") == "registered" && (
                <Alert className={classes.alert} severity="error">
                  Please update the appointment status or change it's date
                </Alert>
              )}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {appointmentNewForEdit && appointmentNewForEdit.name ? (
                  <Controller
                    disabled
                    name="name"
                    control={control}
                    defaultValue={appointmentNewForEdit.name}
                    as={
                      <Controls.Input
                        style={{ width: "100%" }}
                        InputProps={{
                          startAdornment: (
                            <Iconify
                              icon="mdi:calendar-text-outline"
                              className={classes.icon}
                            />
                          ),
                        }}
                        label="Name"
                      />
                    }
                  />
                ) : (
                  ""
                )}

                <Controller
                  disabled={formDisabled}
                  name="date_of_appointment"
                  type="date"
                  control={control}
                  rules={{
                    required:
                      selectedStatus == "pending_to_schedule" ||
                      selectedStatus == "pending_to_reschedule"
                        ? false
                        : "*Date is required",
                  }}
                  defaultValue={
                    date
                      ? date
                      : appointmentNewForEdit &&
                        appointmentNewForEdit.date_of_appointment
                      ? appointmentNewForEdit.date_of_appointment
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      disabled={
                        formDisabled ||
                        (selectedRecordProducts.length > 0 &&
                          selectedType &&
                          appointmentTypes.length > 0 &&
                          appointmentTypes.find((a) => a.id == selectedType) &&
                          appointmentTypes.find((a) => a.id == selectedType)
                            .unique_key == "surgical")
                      }
                      minDate={
                        selectedStatus == "registered" ? moment() : undefined
                      }
                      style={{ width: "100%" }}
                      label={
                        selectedStatus == "pending_to_schedule" ||
                        selectedStatus == "pending_to_reschedule"
                          ? "Appointment Date"
                          : "Appointment Date*"
                      }
                      shouldCloseOnSelect
                      onChange={(date) => {
                        const fdate = date
                          ? moment(date).format("yyyy-MM-DD HH:mm:ss")
                          : null;
                        onChange(fdate);
                        setSelectedDate(fdate);
                      }}
                      value={value}
                    />
                  )}
                />
                {selectedRecordProducts.length > 0 &&
                selectedType &&
                appointmentTypes.length > 0 &&
                appointmentTypes.find((a) => a.id == selectedType) &&
                appointmentTypes.find((a) => a.id == selectedType).unique_key ==
                  "surgical" ? (
                  <div>
                    <Typography
                      style={{
                        margin: "0px 4px",
                        background: "#dfeeff",
                        padding: "2px 8px",
                        borderRadius: 10,
                        fontSize: 12,
                        marginBottom: 15,
                        fontWeight: 500,
                        color: "#082337",
                      }}
                    >
                      *This appointment is linked to procedures, its date can
                      only be changed in the EMR
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
                {errors.date_of_appointment && (
                  <Typography className={classes.error}>
                    {errors.date_of_appointment.message}
                  </Typography>
                )}
                {selectedType == 2 || selectedType == 5 ? (
                  <Typography
                    style={{ color: "#059b6f", fontWeight: 500, padding: 7 }}
                  >
                    *Notice: The system will only show you patients who have had
                    at least one complete surgery
                  </Typography>
                ) : (
                  ""
                )}
                <TextField
                  disabled={disabledContact || formDisabled}
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={searchContact.search}
                  className={classes.textfield}
                  onClick={(e) => {
                    handleMenu(e.currentTarget);
                  }}
                  onChange={(value) => {
                    setSearchContact({
                      ...searchContact,
                      ...{ search: value.target.value },
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <Iconify
                        icon="fluent:person-16-regular"
                        className={classes.icon}
                      />
                    ),
                    endAdornment: (
                      <Button
                        style={{
                          padding: "0px 17px",
                          color:
                            contactsIsLoading || disabledContact
                              ? "rgb(163 161 161)"
                              : "#69ace7",
                          border:
                            contactsIsLoading || disabledContact
                              ? "1px solid rgb(183 188 193)"
                              : "1px solid #c2e3ff",
                        }}
                        endIcon={
                          <Iconify
                            style={{
                              fontSize: 16,
                              color:
                                contactsIsLoading || disabledContact
                                  ? "rgb(166 167 168)"
                                  : "#69ace7",
                            }}
                            icon="icomoon-free:search"
                          />
                        }
                        disabled={
                          contactsIsLoading || disabledContact || formDisabled
                        }
                        onClick={(e) => {
                          onSearchChange(searchContact.search, e.currentTarget);
                          setContacts([]);
                        }}
                      >
                        Search
                      </Button>
                    ),
                  }}
                  label="Contact/Patient*"
                />
                {!searchContact.contact_id && (
                  <Typography className={classes.error}>
                    *Contact/Patient is required
                  </Typography>
                )}
                <Controller
                  disabled={formDisabled}
                  name="type_id"
                  control={control}
                  defaultValue={selectedType}
                  as={({ onChange, value }) => (
                    <CustomSelect
                      disabled={formDisabled}
                      styleProp={true}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify
                            className={classes.icon}
                            icon="carbon:reminder-medical"
                          />
                        </InputAdornment>
                      }
                      label="Type*"
                      options={typesOptions()}
                      onChange={(value) => {
                        setSelectedType(value.target.value);

                        if (
                          value.target.value == 2 ||
                          value.target.value == 5
                        ) {
                          if (!searchContact.has_procedure_done) {
                            setSearchContact({
                              has_procedure_done: true,
                              contact_id: -1,
                              search: "",
                            });
                          }
                          setContacts(
                            contacts.filter((a) => a.has_procedure_done == true)
                          );
                        }

                        onChange(value);
                        if (
                          value.target.value &&
                          selectedRecordProducts.length > 0 &&
                          value.target.value &&
                          appointmentTypes.length > 0 &&
                          appointmentTypes.find(
                            (a) => a.id == value.target.value
                          ) &&
                          appointmentTypes.find(
                            (a) => a.id == value.target.value
                          ).unique_key != "surgical"
                        ) {
                          setSelectedRecordProducts([]);
                        }
                      }}
                      value={value}
                    />
                  )}
                  rules={{ required: "*Type is required" }}
                />
                {errors.type_id && (
                  <Typography className={classes.error}>
                    {errors.type_id.message}
                  </Typography>
                )}
                <Grid container spacing={1} style={{ alignItems: "center" }}>
                  <Grid
                    item
                    xs={
                      selectedType &&
                      appointmentTypes.length > 0 &&
                      appointmentTypes.find((a) => a.id == selectedType)
                        .notify_user == true
                        ? 11
                        : 12
                    }
                  >
                    <Controller
                      disabled={formDisabled}
                      name="owner_id"
                      control={control}
                      defaultValue={
                        appointmentNewForEdit && appointmentNewForEdit.owner_id
                          ? appointmentNewForEdit.owner_id
                          : null
                      }
                      value={selectedOwner}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          disabled={formDisabled}
                          styleProp={true}
                          startAdornment={
                            <InputAdornment position="start">
                              <Iconify
                                className={classes.icon}
                                icon="healthicons:doctor-outline"
                              />
                            </InputAdornment>
                          }
                          label="Assign to*"
                          options={ownersOptions()}
                          onChange={(value) => {
                            setSelectedOwner(value.target.value);
                            onChange(value);
                          }}
                          value={value}
                        />
                      )}
                      rules={{ required: "*Assign to is required" }}
                    />
                    {showAlert && !formDisabled && (
                      <Alert className={classes.alert} severity="error">
                        The selected user is not available. Please select
                        another user
                      </Alert>
                    )}
                    {errors.owner_id && (
                      <Typography className={classes.error}>
                        {errors.owner_id.message}
                      </Typography>
                    )}
                  </Grid>
                  {selectedType &&
                    appointmentTypes.length > 0 &&
                    appointmentTypes.find((a) => a.id == selectedType)
                      .notify_user == true && (
                      <Grid item xs={1}>
                        <Controller
                          disabled={
                            formDisabled ||
                            (selectedType &&
                              appointmentTypes.length > 0 &&
                              appointmentTypes.find((a) => a.id == selectedType)
                                .notify_user == false)
                          }
                          name="notify_user"
                          control={control}
                          defaultValue={notifyUser}
                          as={({ onChange, value }) => (
                            <Tooltip
                              title={
                                notifyUser
                                  ? "Notify User OFF"
                                  : "Notify User ON"
                              }
                            >
                              <IconButton
                                style={{ padding: 4 }}
                                disabled={
                                  formDisabled ||
                                  (selectedType &&
                                    appointmentTypes.length > 0 &&
                                    appointmentTypes.find(
                                      (a) => a.id == selectedType
                                    ).notify_user == false)
                                }
                                onClick={() => {
                                  setNotifyUser(notifyUser == 1 ? 0 : 1);
                                  onChange(notifyUser == 1 ? 0 : 1);
                                }}
                              >
                                <Iconify
                                  style={{
                                    color:
                                      notifyUser &&
                                      !(
                                        formDisabled ||
                                        (selectedType &&
                                          appointmentTypes.length > 0 &&
                                          appointmentTypes.find(
                                            (a) => a.id == selectedType
                                          ).notify_user == false)
                                      )
                                        ? "rgb(78 109 199)"
                                        : "rgb(165 165 165)",
                                    fontSize: 30,
                                  }}
                                  icon={
                                    notifyUser
                                      ? "basil:notification-on-solid"
                                      : "basil:notification-solid"
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          rules={{ required: "*Assign to is required" }}
                        />
                      </Grid>
                    )}
                </Grid>

                <Controller
                  disabled={formDisabled}
                  name="status"
                  control={control}
                  defaultValue={
                    appointmentNewForEdit && appointmentNewForEdit.status
                      ? appointmentNewForEdit.status
                      : "registered"
                  }
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={formDisabled}
                      styleProp={true}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify
                            className={classes.icon}
                            icon="mdi:signal-cellular-outline"
                          />
                        </InputAdornment>
                      }
                      label="Status*"
                      options={statusOptions}
                      onChange={(value) => {
                        if (value.target.value == "completed") {
                          setIsCompleteByOwner(true);
                          setIsCompleteByResponsible(true);
                          setValue("is_completed_by_owner", true);
                          setValue("is_completed_by_responsible", true);
                        } else {
                          if (isCompleteByOwner && isCompleteByResponsible) {
                            setIsCompleteByOwner(false);
                            setIsCompleteByResponsible(false);
                            setValue("is_completed_by_owner", false);
                            setValue("is_completed_by_responsible", false);
                          }
                        }

                        if (
                          appointmentNewForEdit &&
                          value.target.value == "completed" &&
                          appointmentNewForEdit.invoiceitem.data &&
                          appointmentNewForEdit.invoiceitem.data.is_procedure ==
                            false &&
                          appointmentNewForEdit.is_done == false
                        ) {
                          openConfirmDialog(
                            setConfirmDialog,
                            () => {
                              setOpenPopupProduct(true);
                              closeConfirmDialog(
                                setConfirmDialog,
                                confirmDialog
                              );
                            },
                            appointmentNewForEdit.invoiceitem.data.id,
                            "Would you like to update the status of the product associated with this appointment"
                          );
                        }
                        if (value.target.value != "canceled") {
                          cancelationReason.current = control.getValues(
                            "cancelation_reason_id"
                          );
                          setValue("cancelation_reason_id", null);
                        } else {
                          setValue(
                            "cancelation_reason_id",
                            cancelationReason.current
                          );
                        }
                        setSelectedStatus(value.target.value);
                        onChange(value);
                      }}
                      value={value}
                    />
                  )}
                  rules={{ required: "Status is required" }}
                />
                {errors.status && (
                  <Typography className={classes.error}>
                    *Status is required
                  </Typography>
                )}
                <Controller
                  disabled={formDisabled}
                  name="responsibles"
                  control={control}
                  defaultValue={
                    appointmentNewForEdit &&
                    appointmentNewForEdit.responsibles &&
                    appointmentNewForEdit.responsibles.data.length > 0
                      ? appointmentNewForEdit.responsibles.data.map((user) => {
                          return user.user_id;
                        })
                      : []
                  }
                  as={({ onChange, value }) => (
                    <Controls.MultipleSelect
                      disabled={formDisabled}
                      styleProp={true}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify
                            className={classes.icon}
                            icon="heroicons:user-group"
                          />
                        </InputAdornment>
                      }
                      label="Responsibles"
                      options={usersOptions()}
                      value={value}
                      onChange={(value) => {
                        setResponsibles(value);
                        onChange(value);
                      }}
                    />
                  )}
                  //rules={{ required: "Building is required" }}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  disabled={formDisabled}
                  name="building_id"
                  control={control}
                  defaultValue={
                    appointmentNewForEdit && appointmentNewForEdit.building_id
                      ? appointmentNewForEdit.building_id
                      : authUser.building_id
                  }
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={formDisabled}
                      styleProp={true}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify
                            className={classes.icon}
                            icon="pixelarticons:buildings"
                          />
                        </InputAdornment>
                      }
                      label="Building*"
                      options={buildingOptions()}
                      onChange={(value) => {
                        setBuildingSelected(value.target.value);
                        onChange(value);
                      }}
                      value={value}
                    />
                  )}
                  rules={{ required: "*Building is required" }}
                />
                {errors.building_id && (
                  <Typography className={classes.error}>
                    {errors.building_id.message}
                  </Typography>
                )}

                <Controller
                  disabled={formDisabled}
                  type="number"
                  name="duration"
                  control={control}
                  defaultValue={
                    appointmentNewForEdit && appointmentNewForEdit.duration
                      ? appointmentNewForEdit.duration
                      : null
                  }
                  as={
                    <Controls.Input
                      disabled={formDisabled}
                      style={{ width: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <Iconify
                            icon="material-symbols:timer-outline-rounded"
                            className={classes.icon}
                          />
                        ),
                      }}
                      label="Duration/minutes*"
                    />
                  }
                  rules={{ required: "*Duration is required" }}
                />
                {errors.duration && (
                  <Typography className={classes.error}>
                    {errors.duration.message}
                  </Typography>
                )}
                <Controller
                  name="reminder_on"
                  disabled={formDisabled}
                  control={control}
                  defaultValue={
                    appointmentNewForEdit && appointmentNewForEdit.reminder_on
                      ? appointmentNewForEdit.reminder_on
                      : null
                  }
                  as={
                    <TextField
                      className={classes.textfield}
                      disabled={formDisabled}
                      style={{ width: "100%" }}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <Iconify
                            icon="ic:outline-notifications-active"
                            className={classes.icon}
                          />
                        ),
                      }}
                      label="Reminder On"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }
                />

                <Controller
                  disabled={formDisabled}
                  name="comment"
                  control={control}
                  defaultValue={appointmentNewForEdit?.comment || ""}
                  as={
                    <Controls.Textarea
                      disabled={formDisabled}
                      inputProps={{ style: { maxHeight: 78 } }}
                      rows={5}
                      style={{ width: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <Iconify
                            icon="ant-design:comment-outlined"
                            className={classes.icon}
                          />
                        ),
                      }}
                      label="Comment"
                    />
                  }
                />
                <Controller
                  name="cancelation_reason_id"
                  control={control}
                  defaultValue={
                    appointmentNewForEdit &&
                    appointmentNewForEdit.status == "canceled"
                      ? appointmentNewForEdit.cancelation_reason_id
                      : ""
                  }
                  rules={{
                    required: selectedStatus === "canceled" ? true : false,
                  }}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={selectedStatus != "canceled" || formDisabled}
                      styleProp={true}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify
                            className={classes.icon}
                            icon="carbon:rule-cancelled"
                            style={{
                              color:
                                selectedStatus != "canceled"
                                  ? "gray"
                                  : "#5ea8f6",
                            }}
                          />
                        </InputAdornment>
                      }
                      label={
                        selectedStatus === "canceled"
                          ? "Cancellation Reason*"
                          : "Cancellation Reason"
                      }
                      onChange={(value) => onChange(value)}
                      options={cancelationReasonsOptions()}
                      value={value}
                    />
                  )}
                />
                {errors.cancelation_reason_id && (
                  <Typography className={classes.error}>
                    *Cancelation Reason is required
                  </Typography>
                )}
                {appointmentNewForEdit && (
                  <div style={{ padding: 3 }}>
                    <Typography style={{ fontWeight: 600, marginLeft: 12 }}>
                      Mark as done by:{" "}
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Controller
                        disabled={formDisabled}
                        name="is_completed_by_owner"
                        control={control}
                        defaultValue={isCompleteByOwner}
                        as={({ onChange, value }) => (
                          <Checkbox
                            disabled={
                              userCanResponsibleDone &&
                              !formDisabled &&
                              !(
                                appointmentNewForEdit &&
                                appointmentNewForEdit.status != "canceled" &&
                                appointmentNewForEdit.invoiceitem &&
                                (appointmentNewForEdit.invoiceitem.data
                                  .balance > 0 ||
                                  appointmentNewForEdit.invoiceitem.data
                                    .package_balance > 0)
                              )
                                ? !(
                                    userCanResponsibleDone &&
                                    appointmentNewForEdit.responsibles.data.findIndex(
                                      (a) => a.user_id == authUser.id
                                    ) != -1
                                  )
                                : formDisabled ||
                                  (appointmentNewForEdit &&
                                    appointmentNewForEdit.status !=
                                      "canceled" &&
                                    appointmentNewForEdit.invoiceitem &&
                                    (appointmentNewForEdit.invoiceitem.data
                                      .balance > 0 ||
                                      appointmentNewForEdit.invoiceitem.data
                                        .package_balance > 0)) ||
                                  authUser.id !=
                                    appointmentNewForEdit.owner_id ||
                                  selectedOwner !=
                                    appointmentNewForEdit.owner_id
                            }
                            checked={isCompleteByOwner}
                            onChange={(value) => {
                              setIsCompleteByOwner(!isCompleteByOwner);
                              onChange(!isCompleteByOwner);
                            }}
                            value={value}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        )}
                      />
                      Assigned
                      <Controller
                        disabled={formDisabled}
                        name="is_completed_by_responsible"
                        control={control}
                        defaultValue={isCompleteByResponsible}
                        as={({ onChange, value }) => (
                          <Checkbox
                            disabled={
                              formDisabled ||
                              (appointmentNewForEdit &&
                                appointmentNewForEdit.status != "canceled" &&
                                appointmentNewForEdit.invoiceitem &&
                                appointmentNewForEdit.invoiceitem.data.balance >
                                  0) ||
                              appointmentNewForEdit.responsibles.data.findIndex(
                                (a) => a.user_id == authUser.id
                              ) == -1 ||
                              responsibles.findIndex((a) => a == authUser.id) ==
                                -1
                            }
                            checked={isCompleteByResponsible}
                            onChange={(value) => {
                              onChange(!isCompleteByResponsible);
                              setIsCompleteByResponsible(
                                !isCompleteByResponsible
                              );
                            }}
                            value={value}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      />
                      Responsible
                    </div>
                  </div>
                )}
              </Grid>

              {appointmentNewForEdit &&
              appointmentNewForEdit.invoice_item_id ? (
                <div className={classes.fromInvoice}>
                  <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                    {`From invoice:  ${appointmentNewForEdit.invoiceitem.data.invoice_name} - ${appointmentNewForEdit.invoiceitem.data.name}`}{" "}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {(!appointmentNewForEdit ||
                !appointmentNewForEdit.invoice_item_id) &&
              (authUser?.organization?.data?.unified_daily_surgery_time ||
                false) ? (
                <div style={{ width: "100%", marginTop: 4, marginBottom: 8 }}>
                  <LinkAppointmentsToRecordProducts
                    hidden={
                      !selectedType ||
                      !appointmentTypes.find((t) => t.id == selectedType) ||
                      appointmentTypes.find((t) => t.id == selectedType)
                        .unique_key != "surgical"
                    }
                    contactId={searchContact?.contact_id || null}
                    selectedRecordProducts={selectedRecordProducts}
                    setSelectedRecordProducts={setSelectedRecordProducts}
                    appointmentID={appointmentNewForEdit?.id || null}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                </div>
              ) : (
                ""
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                {!appointmentNewForEdit ? (
                  <Controls.Button
                    id={"add_button_id"}
                    startIcon={
                      isAdding ? (
                        <CircularProgress
                          style={{ width: 20, height: 20, color: "#5ebe57" }}
                        />
                      ) : (
                        ""
                      )
                    }
                    disabled={isLoading || formDisabled || isAdding}
                    type="submit"
                    text="Add"
                  />
                ) : (
                  ""
                )}
                <Controls.Button
                  startIcon={
                    isLoading ? (
                      <CircularProgress
                        style={{ width: 20, height: 20, color: "#5ebe57" }}
                      />
                    ) : (
                      ""
                    )
                  }
                  disabled={isLoading || formDisabled || isAdding}
                  type="submit"
                  text="Submit"
                />
              </div>
            </Grid>

            {((contactsIsLoading && contacts.length == 0) ||
              contacts.length > 0) && (
              <Menu
                classes={{ paper: classes.menu }}
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={!contactsIsLoading ? handleClose : ""}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div
                  id={"scrollableDiv1"}
                  style={{
                    padding: 4,
                    maxHeight: "600px",
                    width: "100%",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InfiniteScroll
                    dataLength={contacts.length}
                    next={() => {
                      if (contacts.length != totalContacts) {
                        contactsOptions(searchContact.search, contacts);
                      }
                    }}
                    style={{ display: "flex", flexDirection: "column" }}
                    hasMore={totalContacts != contacts.length ? true : false}
                    loader={
                      totalContacts != contacts.length ? (
                        <h4>Loading...</h4>
                      ) : (
                        ""
                      )
                    }
                    scrollableTarget={"scrollableDiv1"}
                    // initialScrollY={0}
                  >
                    {contacts.map((contact) => {
                      return (
                        <MenuItem
                          key={contact.id}
                          onClick={() => {
                            setSearchContact({
                              contact_id: contact.id,
                              search: contact.full_name,
                              has_procedure_done: contact.has_procedure_done
                                ? true
                                : false,
                            });
                            setAnchorEl(null);
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ minWidth: "fit-content" }}>
                              <Typography> {contact.full_name}</Typography>
                            </div>

                            <div
                              style={{
                                marginLeft: 10,
                                width: "100%",
                                textAlign: "right",
                              }}
                            >
                              {contact.chart_id ? (
                                <Typography>
                                  {" "}
                                  {`Chart ID: ${contact.chart_id}`}
                                </Typography>
                              ) : contact.phone ? (
                                <Typography>
                                  {" "}
                                  {`Phone: ${contact.phone}`}
                                </Typography>
                              ) : contact.email ? (
                                <Typography>
                                  {" "}
                                  {`Email: ${contact.email}`}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </MenuItem>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </Menu>
            )}
          </form>
        </Grid>
        {informationOpened ? (
          <Grid item xs={4} style={{ padding: 15 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 8,
                marginBottom: 10,
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: 500,
                  marginRight: 8,
                }}
              >
                Appointment Instructions
              </Typography>
              <Tooltip title="These instructions will be visible to the contact within the confirmation link.">
                <span style={{ display: "grid", alignItems: "center" }}>
                  <Iconify
                    style={{
                      width: "21px",
                      height: "21px",
                      marginLeft: "3px",
                      color: "#2072c9",
                    }}
                    icon={"ic:round-info"}
                  />
                </span>
              </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Controls.Select
                label="Instructions*"
                options={instructionOptions()}
                setSelectedItem={setAppointmentInstruction}
                value={appointmentInstruction?.id || null}
              />
              <Fab
                style={{ height: 38, width: 42, marginLeft: "10px" }}
                color="primary"
                aria-label="add"
                onClick={() => {
                  setAddOrEditInstructionOpened(true);
                }}
              >
                <Iconify icon={"ic:round-plus"} width={28} height={28} />
              </Fab>
            </div>

            <div
              style={{
                minHeight: "70%",
                marginTop: 8,
                borderRadius: 8,
                boxShadow: "0 0 14px 0 rgba(53,64,82,0.09)",
                padding: "1px 12px",
              }}
            >
              {appointmentInstruction ? (
                <div style={{ display: "grid", justifyContent: "right" }}>
                  <IconButton
                    style={{ padding: 5 }}
                    onClick={() => {
                      setInstructionForEdit(appointmentInstruction);
                      setAddOrEditInstructionOpened(true);
                    }}
                  >
                    <Iconify
                      style={{ width: 19, height: 19 }}
                      icon="mdi:edit"
                    />
                  </IconButton>
                </div>
              ) : (
                ""
              )}

              <ScrollBar
                style={{ maxHeight: 265 }}
                dangerouslySetInnerHTML={{
                  __html: appointmentInstruction?.body || null,
                }}
              ></ScrollBar>
            </div>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Popup
        title={
          appointmentNewForEdit && appointmentNewForEdit.invoiceitem.data
            ? ` ${appointmentNewForEdit.invoiceitem.data.name} for ${appointmentNewForEdit.contact.data.full_name} `
            : ""
        }
        openPopup={openPopupProduct}
        onClose={onClosePopupProduct}
      >
        <>
          <ProductsControlForm
            contact_id={
              appointmentNewForEdit ? appointmentNewForEdit.contact_id : null
            }
            itemForAdd={
              appointmentNewForEdit ? appointmentNewForEdit.invoiceitem : null
            }
            contact={
              appointmentNewForEdit ? appointmentNewForEdit.contact.data : null
            }
            user={authUser}
            maxQuantity={
              appointmentNewForEdit
                ? appointmentNewForEdit.invoiceitem.data.quantity -
                  (appointmentNewForEdit.invoiceitem.data.productcontrols &&
                  appointmentNewForEdit.invoiceitem.data.productcontrols
                    .length > 0
                    ? calcUsed(
                        appointmentNewForEdit.invoiceitem.data.productcontrols
                      )
                    : 0)
                : null
            }
            addItem={addItem}
          />
        </>
      </Popup>
      <Popup
        title={"image.png"}
        openPopup={openAttachment}
        onClose={() => {
          setOpenAttachment(false);
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <img src={urlMedia} />
        </div>
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={false}
      />

      <Popup
        title={"Instructions"}
        openPopup={addOrEditInstructionOpened}
        onClose={() => {
          setAddOrEditInstructionOpened(false);
          setInstructionForEdit(null);
        }}
      >
        <InstructionsForm
          instructionsForEdit={instructionForEdit}
          addOrEdit={addOrEditInstruction}
        />
      </Popup>
    </>
  );
}
