import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  documentTypes: [],
};

const documentTypesSlice = createSlice({
  name: "documentTypes",
  initialState,
  reducers: {
    getDocumentTypes: (state) => {
      state.loading = true;
    },
    getDocumentTypesSuccess: (state, { payload }) => {
      state.documentTypes = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getDocumentTypesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createDocumentType: (state) => {},
    createDocumentTypesuccess: (state, { payload }) => {
      state.documentTypes = [...state.documentTypes, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createDocumentTypeFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateDocumentType: (state) => {},
    updateDocumentTypesuccess: (state, { payload }) => {
      const index = findIndex(state.documentTypes, ["id", payload.data.id]);
      const documentType = { ...state.documentTypes[index], ...payload.data };

      let documentTypes = [...state.documentTypes];
      documentTypes[index] = documentType;

      state.documentTypes = [...documentTypes];
      state.loading = false;
      state.errors = [];
    },
    updateDocumentTypeFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteDocumentType: (state) => {},
    deleteDocumentTypesuccess: (state, { payload }) => {
      state.documentTypes = state.documentTypes.filter((documentType) => {
        return documentType.id !== payload;
      });
    },
    deleteDocumentTypeFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getDocumentTypes,
  getDocumentTypesSuccess,
  getDocumentTypesFailure,
  createDocumentType,
  createDocumentTypesuccess,
  createDocumentTypeFailure,
  updateDocumentType,
  updateDocumentTypesuccess,
  updateDocumentTypeFailure,
  deleteDocumentType,
  deleteDocumentTypesuccess,
  deleteDocumentTypeFailure,
} = documentTypesSlice.actions;

export const documentTypesSelector = (state) => state.documentTypes;
export default documentTypesSlice.reducer;

export function fetchDocumentTypesService(orgId) {
  return async (dispatch) => {
    dispatch(getDocumentTypes());

    try {
      const response = await Api.getDocumentTypes(orgId);
      dispatch(getDocumentTypesSuccess(response.data));
    } catch (error) {
      dispatch(getDocumentTypesFailure(error));
    }
  };
}
export function fetchPortalDocumentTypesService(orgId) {
  return async (dispatch) => {
    dispatch(getDocumentTypes());

    try {
      const response = await Api.getPortalDocumentTypes(orgId);
      dispatch(getDocumentTypesSuccess(response.data));
    } catch (error) {
      dispatch(getDocumentTypesFailure(error));
    }
  };
}

export function createDocumentTypeService(payload) {
  return async (dispatch) => {
    
    dispatch(createDocumentType());

    try {
      const response = await Api.saveDocumentType(payload);

      dispatch(createDocumentTypesuccess(response.data));
    } catch (error) {
      dispatch(createDocumentTypeFailure(error));
    }
  };
}

export function updateDocumentTypeService(id, payload) {
  return async (dispatch) => {
    dispatch(updateDocumentType());

    try {
      const response = await Api.updateDocumentType(id, payload);

      dispatch(
        updateDocumentTypesuccess(response.data)
      );
    } catch (error) {
      dispatch(updateDocumentTypeFailure(error));
    }
  };
}

export function deleteDocumentTypeService(id) {
  return async (dispatch) => {
    dispatch(deleteDocumentType());

    try {
      await Api.deleteDocumentType(id);

      dispatch(deleteDocumentTypesuccess(id));
    } catch (error) {
      dispatch(deleteDocumentTypeFailure(error));
    }
  };
}