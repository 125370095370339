import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	LinearProgress,
	Typography,
} from "@material-ui/core";
import { Add, Description, ExpandMore, Visibility } from "@material-ui/icons";
import Carousel from "../../refunds/components/Carousel";
import { useCallback, useMemo, useState } from "react";
import FileModal from "./FileModal";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import Api from "../../../lib/api";
import { useQuery } from "react-query";

const FileComponent = ({ disabled = false, entityType, entityId, filecount=0 }) => {
	const [expanded, setExpanded] = useState(false);
	const toggleExpanded = useCallback(() => {
		setExpanded((e) => !e);
	}, [setExpanded]);

	const { control, setValue, register } = useFormContext();

	const [openModal, setOpenModal] = useState(false);
	const [file, setFile] = useState(null);
	const [filesToStore, setFilesToStore] = useState([]);

	

    
    
    const { data: files=[], isLoading: filesLoading } = useQuery(['files', entityType, entityId, filecount], () => Api.fileIndex(entityType, entityId), {
        select: (data) => data?.data ?? [],
        placeholderData: [],
        enabled: Boolean(entityId) && Boolean(entityType)
    })

    
    const allFiles = useMemo(() => {
		let af = [...(files ?? []), ...filesToStore];
		setValue(
			"files",
			af.map((f) => (f?.data instanceof File ? f : f.id))
		);
		return af;
	}, [files, filesToStore]);

	const handleShowFile = (f) => {
		console.log(f);
		setFile(f.data ? f.data : f);
		setOpenModal(true);
	};

	const handleDelete = () => {
		setFilesToStore(filesToStore.filter((f) => f != file));
		setOpenModal(false);
		setFile(null);
	};

	const handleCloseModal = () => {
		setFile(null);
		setOpenModal(false);
	};

	const handleFileStore = (file) => {
		setFilesToStore((f) => [...f, file]);
		setFile(null);
		setOpenModal(false);
	};

	return (
		<>
			<Grid item md={12}>
				<input type="hidden" {...register("files")} />
				<Accordion expanded={expanded} onChange={toggleExpanded}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography
							variant="h6"
							style={{
								display: "flex",
								alignItems: "center",
								gap: "5px",
							}}
						>
							<Description /> Files
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: "block",
							maxWidth: "unset",
						}}
					>
						{filesLoading ? (
							<LinearProgress />
						) : (
							<>
								<Carousel
									cards={[
										...(allFiles?.map((f) => ({
											...f,
											title:
												(f?.data?.name ?? f?.name ?? "")
													.length > 10
													? (
															f?.data?.name ??
															f?.name ??
															""
													  ).substring(0, 5) +
													  "..." +
													  (
															f?.data?.name ??
															f?.name ??
															""
													  ).substring(
															(
																f?.data?.name ??
																f?.name ??
																""
															).length - 4
													  )
													: f?.data?.name ??
													  f?.name ??
													  "" ??
													  "",
											content: (
												<Box
													direction="column"
													display="flex"
													flexDirection="column"
												>
													{/* <Typography variant="subtitle">
														{(f?.comment ?? "")
															.length > 10
															? f.comment.substring(
																	0,
																	10
															  ) + "..."
															: f?.comment ?? ""}
                                                    </Typography> */}
													<Typography variant="subtitle">
														{moment(
															f?.created_at
														).format("DD/MM/yyyy")}
													</Typography>
													<Visibility
														style={{
															marginRight: "auto",
															marginLeft: "auto",
														}}
                                                    />
                                                    <Typography variant="subtitle" style={{
                                                        fontSize: 10
                                                    }}>
														{f?.user?.dropdown_name}
													</Typography>
												</Box>
											),
											data: f,
										})) ?? []),
										...(disabled
											? []
											: [
													{
														title: "Add",
														content: <Add />,
													},
											  ]),
									]}
									onClick={handleShowFile}
								/>
								{/* {isHistoryRefetching && <LinearProgress />} */}
							</>
						)}
					</AccordionDetails>
				</Accordion>
			</Grid>
			<FileModal
				open={openModal}
				onClose={handleCloseModal}
				file={file}
				onStore={handleFileStore}
				onDelete={handleDelete}
			/>
		</>
	);
};

export default FileComponent;
