import React, { useEffect, useState, useCallback } from "react";
import { CircularProgress, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import Api from "../../lib/api";
import { debounce } from "lodash";

const filterOptions = createFilterOptions({
  // matchFrom: "any",

  stringify: (option) => option.title + option.phone /*+ option.email*/,
});

function filterBySpaceSeparatedInputValue(options, state) {
  const terms = state.inputValue.split(" ").map((term) => {
    return new RegExp(term, "i");
  });
  return options.filter((option) => {
    return terms.some((term) => {
      return term.test(option.name + option.phone + option.email);
    });
  });
}

export default function AutocompleteAsyncControl(props) {
  const {
    user,
    label,
    onChange,
    value,
    disabled,
    disableClear,
    required,
    id,
    className,
    fullWidth = false,
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState();

  const [inputValue, setInputValue] = useState(null);
  const loading = open && options.length === 0;

  const debouncedSave = useCallback(
    debounce((newValue) => getContact(newValue), 1500),
    []
  );

  const getContact = async (search) => {
    if (search) {
      // setLoading(true);
      const params = new URLSearchParams([["search", `${search}`]]);

      const { data } = await Api.getSearchContacts2(
        authUser.organization_id,
        `?1=1&${params.toString()}`
      );

      const data1 = data.data.map((user) => {
        return {
          id: user.id,
          name: user.full_name,
          phone: user.phone,
          email: user.email,
        };
      });

      setOptions(data1);

      // setLoading(false);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    //getContact(inputValue);

    return () => {
      active = false;
    };
  }, [loading, inputValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id ? id : "new"}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      className={className}
      filterOptions={filterBySpaceSeparatedInputValue}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      inputValue={inputValue ? inputValue : value ? value.name : ""}
      options={options}
      onChange={(event, newInputValue) => {
        onChange(newInputValue);
      }}
      fullWidth={fullWidth}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        debouncedSave(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : ""}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
