import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  recordProductTemplates: [],
};

const templatesSlice = createSlice({
  name: "recordProductTemplates",
  initialState,
  reducers: {
    getTemplates: (state) => {
      state.loading = true;
    },
    getTemplatesSuccess: (state, { payload }) => {
      state.recordProductTemplates = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getTemplatesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createTemplate: (state) => {},
    createTemplateSuccess: (state, { payload }) => {
      state.recordProductTemplates = [
        ...state.recordProductTemplates,
        payload.data,
      ];
      state.loading = false;
      state.errors = [];
    },
    createTemplateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateTemplate: (state) => {},
    updateTemplateSuccess: (state, { payload }) => {
      const index = findIndex(state.recordProductTemplates, [
        "id",
        payload.data.id,
      ]);
      const user = { ...state.recordProductTemplates[index], ...payload.data };

      let recordProductTemplates = [...state.recordProductTemplates];
      recordProductTemplates[index] = user;

      state.recordProductTemplates = [...recordProductTemplates];
      state.loading = false;
      state.errors = [];
    },
    updateTemplateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteTemplate: (state) => {},
    deleteTemplateSuccess: (state, { payload }) => {
      state.recordProductTemplates = state.recordProductTemplates.filter(
        (template) => {
          return template.id !== payload;
        }
      );
    },
    deleteTemplateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    cleanTemplates: (state) => {
      state.loading = true;
      state.recordProductTemplates = [];
    },
    cleanTemplatesSuccess: (state, { payload }) => {
      state.loading = false;
      state.errors = [];
    },
    cleanTemplatesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [];
    },
  },
});

export const {
  getTemplates,
  getTemplatesSuccess,
  getTemplatesFailure,
  createTemplate,
  createTemplateSuccess,
  createTemplateFailure,
  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFailure,
  deleteTemplate,
  deleteTemplateSuccess,
  deleteTemplateFailure,
  cleanTemplates,
  cleanTemplatesSuccess,
  cleanTemplatesFailure,
} = templatesSlice.actions;
export const templatesSelector = (state) => state.recordProductTemplates;
export default templatesSlice.reducer;

export function fetchTemplatesService(orgId, building_id, billing) {
  return async (dispatch) => {
    dispatch(getTemplates());

    try {
      const response = await Api.getTemplatesBuilding(
        orgId,
        building_id,
        billing
      );

      dispatch(getTemplatesSuccess(response.data));
    } catch (error) {
      dispatch(getTemplatesFailure(error));
    }
  };
}

export function createTemplateService(payload) {
  return async (dispatch) => {
    dispatch(createTemplate());
    try {
      const response = await Api.saveTemplate(payload);

      dispatch(createTemplateSuccess(response.data));
    } catch (error) {
      dispatch(createTemplateFailure(error));
    }
  };
}

export function updateTemplateService(id, payload) {
  return async (dispatch) => {
    dispatch(updateTemplate());

    try {
      const response = await Api.updateTemplate(id, payload);

      dispatch(updateTemplateSuccess(response.data));
    } catch (error) {
      dispatch(updateTemplateFailure(error));
    }
  };
}

export function deleteTemplateService(id) {
  return async (dispatch) => {
    dispatch(deleteTemplate());

    try {
      const response = await Api.deleteTemplate(id);

      dispatch(deleteTemplateSuccess(id));
    } catch (error) {
      dispatch(deleteTemplateFailure(error));
    }
  };
}

export function cleanTemplatesService(orgId, building_id) {
  return async (dispatch) => {
    dispatch(cleanTemplates());

    try {
      dispatch(cleanTemplatesSuccess());
    } catch (error) {
      dispatch(cleanTemplatesFailure());
    }
  };
}
