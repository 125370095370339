import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Api from "../lib/api";
import { isEmpty, forEach } from "lodash";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "./controls";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  invoiceCard: {
    margin: "5px 0",
    background: "#dedede",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#FFF",
    alignItems: "center",
    marginTop: "5px",
  },
}));

function TransferPayment2(props) {
  const { payment, onTransferPayment = null } = props;
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const classes = useStyles();

  const [siblingInvoices, setSiblingInvoices] = useState([]);

  const [transferSaveLoading, setTransferSaveLoading] = useState(false);

  const getSiblingInvoices = async () => {
    const { data } = await Api.getInvoicesSiblings(payment.invoice);
    // console.log("siblingInvoices ", data);
    setSiblingInvoices(data.data);
  };

  const transferPayment = async (invoice) => {
    setTransferSaveLoading(true);
    const invoiceItems = invoice.items.data;
    let paymentdetails = [];
    invoiceItems.forEach((item) => {
      const itemInput = inputs[item.id];
      if (!isEmpty(itemInput)) {
        paymentdetails.push({
          amount: itemInput,
          isRefund: payment.isRefund,
          item_id: item.id,
          item_name: item.name,
          invoice: invoice.id,
          payment: payment.id,
        });
      }
    });
    const movePayment = {
      ...payment,
      newInvoice: invoice.id,
      newPaymentdetail: paymentdetails,
    };

    const total2pay = paymentdetails.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.amount);
    }, 0);

    const myError = Object.assign({}, inputs);
    if (payment.amount != total2pay) {
      myError[invoice.id] = "Amount need to match";
      setErrors(myError);
    } else {
      const { data } = await Api.transferInvoicePayment(
        invoice.id,
        payment.id,
        movePayment
      );

      if (data) {
        // console.log("movePayment ", movePayment);
        delete myError[invoice.id];
        setErrors(myError);
        setTransferSaveLoading(false);
       
        if (typeof onTransferPayment === "function") {
          onTransferPayment();
        }
      
      }
    }
  };

  const renderInvoices = () => {
    return siblingInvoices.map((invoice) => {
      return (
        <Card key={invoice.id} className={classes.invoiceCard}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4} spacing={3}>
                {invoice.name}
              </Grid>
              <Grid item xs={4} spacing={3}>
                Total: $ {invoice.total_price}
              </Grid>
              <Grid item xs={4} spacing={3}>
                Balance: $ {invoice.balance}
              </Grid>
              {invoice.items.data
                ? renderInvoiceItems(
                    invoice.items.data.filter((a) => a.type != "package")
                  )
                : ""}
            </Grid>
          </CardContent>
          <CardActions>
            <Controls.Button
              text="Transfer"
              onClick={() => transferPayment(invoice)}
              disabled={transferSaveLoading}
            />
            {errors[invoice.id] && (
              <Typography variant="caption" color="error">
                {errors[invoice.id]}
              </Typography>
            )}
          </CardActions>
        </Card>
      );
    });
  };

  const renderInvoiceItems = (items) => {
    return items.map((item) => {
      return (
        <Grid container spacing={2} my={2} className={classes.paper}>
          <Grid item xs={4} spacing={3}>
            {item.name}
          </Grid>
          <Grid item xs={4} spacing={3}>
            $ {item.price}
          </Grid>
          <Grid item xs={4} spacing={3}>
            <Controls.InputMoney
              label="Amount"
              onChange={(v) => {
                const myInput = Object.assign({}, inputs);
                myInput[item.id] = v;
                setInputs(myInput);
              }}
            />
          </Grid>
        </Grid>
      );
    });
  };

  useEffect(() => {
    getSiblingInvoices();
  }, []);
  if (!payment) return null;
  return (
    <Box my={5}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          Type: {payment.payment_type}
        </Grid>
        <Grid item xs={4}>
          Amount: $ {payment.amount}
        </Grid>
        <Grid item xs={4}>
          Date: {payment.date}
        </Grid>
        <Grid item xs={4}>
          Card Holder: {payment.cardholder}
        </Grid>
        <Grid item xs={4}>
          Last 4: {payment.cc_last_4}
        </Grid>
        <Grid item xs={4}>
          Created By: {payment.user ? payment.user.data.title : ""}
        </Grid>
      </Grid>

      <Box my={5}>
        <Typography variant="h4">Transfer to:</Typography>
        {renderInvoices()}
      </Box>
    </Box>
  );
}

export default TransferPayment2;
