import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuList,
} from "@material-ui/core";
import Api from "../lib/api";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TimerIcon from "@material-ui/icons/Timer";
import WorkOffIcon from "@material-ui/icons/WorkOff";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
//import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },

  select: {
    color: "black",
    borderRadius: "30px",
    height: "40px",
    width: "135px",
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 38,
      maxHeight: 224,
      width: 120,
    },
  },
};

function UserStatusList(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const { authUser } = props;
  const classes = useStyles();
  const [status, setStatus] = React.useState(authUser.status);

  const handleChange = (event) => {
    // console.log("event", event);
    setStatus(event.target.value);
    updateStatus(authUser.id, event.target.value);
  };

  const updateStatus = (id, status) => {
    Api.updateStatusUser(id, status);
  };

  const statusOptions = () => {
    return [
      { name: "Available", id: "available" },
      { name: "Busy", id: "busy" },
      { name: "Lunch", id: "lunch" },
      { name: "Out of Desk", id: "out-of-desk" },
    ];
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          key={"StatusButton"}
          style={{
            backgroundColor: !anchorEl ? "white" : "#f5f3f3",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgb(192 192 192)",
            color: "black",
            borderRadius: "30px",
            height: "40px",
            width: "140px",
          }}
        >
          {status == "available" && (
            <FiberManualRecordIcon
              style={{
                fontSize: "16",
                color: "rgba(35, 208, 8)",
                marginRight: "5px",
              }}
            />
          )}
          {status == "busy" && (
            <RemoveCircleIcon
              style={{
                fontSize: "16",
                color: "rgba(240, 9, 9)",
                marginRight: "5px",
              }}
            />
          )}
          {status == "lunch" && (
            <TimerIcon
              style={{
                fontSize: "16",
                color: "rgba(0, 37, 208)",
                marginRight: "5px",
              }}
            />
          )}
          {status == "out-of-desk" && (
            <DesktopAccessDisabledIcon
              style={{ fontSize: "16", color: "#7E878C", marginRight: "5px" }}
            />
          )}
          {statusOptions().find((a) => a.id == status).name}

          {!anchorEl && <ArrowDropDownIcon style={{ color: "gray" }} />}
          {anchorEl && <ArrowDropUpIcon style={{ color: "gray" }} />}
        </Button>
        <Menu
          style={{ marginLeft: 6, marginTop: 36.5 }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          key={"SMenus"}
        >
          {statusOptions().map((name) => (
            <MenuItem
              key={name.id}
              value={name.id}
              onClick={() => {
                handleClose();
                updateStatus(authUser.id, name.id);
                setStatus(name.id);
              }}
            >
              <ListItemText>
                {name.id == "available" && (
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "16",
                      color: "rgba(35, 208, 8)",
                      marginRight: "5px",
                    }}
                  />
                )}
                {name.id == "busy" && (
                  <RemoveCircleIcon
                    style={{
                      fontSize: "16",
                      color: "rgba(240, 9, 9)",
                      marginRight: "5px",
                    }}
                  />
                )}
                {name.id == "lunch" && (
                  <TimerIcon
                    style={{
                      fontSize: "16",
                      color: "rgba(0, 37, 208)",
                      marginRight: "5px",
                    }}
                  />
                )}
                {name.id == "out-of-desk" && (
                  <DesktopAccessDisabledIcon
                    style={{
                      fontSize: "16",
                      color: "#7E878C",
                      marginRight: "5px",
                    }}
                  />
                )}
                {name.name}
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
}
export default UserStatusList;
