import { newSlice, _fetchEntitiesService, _loadEvents, _createEntityService, _updateEntityService, _deleteEntityService } from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'invWhProducts';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_ACC_INVWHPRODUCTS";
  return _fetchEntitiesService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "PUT_ACC_INVWHPRODUCT";  
  return _updateEntityService(dataSlice, events);
}

/* Custom functions */
export function fetchWhProductsByWh(dataSlice) {
  dataSlice.dataUrl.code = "GET_ACC_INVWHPRODUCTS_BY_WH";
  return _fetchEntitiesService(dataSlice, events);
}
export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code="DELETE_ACC_INVWHPRODUCT";
  return _deleteEntityService(dataSlice,events);
}