import { useMemo } from "react";
import GenericTable from "../../refunds/components/GenericTable";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import TaskTypeComponents from "../components/types";
import Select from "../../../components/controls/Select";

const TaskType = ({objectData}) => {
    const columns = useMemo(
        () => [
            { field: "label", title: "Label" },
            { field: "component", title: "Component" },
        ],
        []
    );

    const model = "tk_task_type";

    
    const taskComponentList = useMemo(() => {
        return Object.keys(TaskTypeComponents).map((c) => ({
            id: c,
            name: c,
        }));
    }, [TaskTypeComponents]);

    console.log(taskComponentList);

    const FormData = () => {
        const {
            control,
            formState: { errors },
        } = useFormContext();
        return (
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Controller
                        name="label"
                        P
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Label is required",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <TextField
                                label="Label"
                                name="label"
                                value={value}
                                variant="outlined"
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.label}
                                helperText={errors.label?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="component"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Component required",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <Select
                                label="Component"
                                variant="outlined"
                                name="component"
                                value={value ?? -1}
                                emptyLabel="Select one"
                                allowEmpty={true}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.component}
                                helperText={errors.component?.message}
                                options={taskComponentList}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    const useStyles = makeStyles((theme) => ({
        headerIcon: {
            color: theme.palette.primary.main,
        },
    }));
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>Task priorities</title>
            </Helmet>
            <PageHeader
                title="Task types"
                subTitle=""
                icon={
                    <PeopleOutlineTwoToneIcon
                        fontSize="large"
                        className={classes.headerIcon}
                    />
                }
            />
            <GenericTable
                model={model}
                columns={columns}
                FormData={FormData}
                tableTitle="Task types"
            />
        </>
    );
};

export default TaskType;
