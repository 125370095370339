import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Box,
  Fab,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import useTable from "./useTable";
import Popup from "./Popup";
import Api from "../lib/api";
import Iconify from "./Iconify";
import { useSelector } from "react-redux";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MultipaymentForm from "./forms/MultipaymentForm";
import CRMUtils from "../utils";
import { findIndex, isEmpty } from "lodash";
import IconButton from "./controls/IconButton";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import Controls from "./controls";
import ViewLogsTable from "../components/logs/ViewLogsTable";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  customTooltip1: {
    backgroundColor: "#e3f2fd",
  },
  customArrow1: { color: "gray", borderColor: "#ffffff" },
}));

function ContactPayments(props) {
  const classes = useStyles();
  const {
    contact_id,
    contact,
    consultantErrorHandle = null,
    selectedTransactionsIds = [],
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultipaymentsPopupOpen, setIsMultipaymentsPopupOpen] =
    useState(false);
  const [selectedPaymentHeader, setSelectedPaymentHeader] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const userCanMakePayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_create",
  ]);
  const userIsbillingMGAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingMGAssistant",
  ]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const flickerColors = ["rgb(236 242 246)", "rgb(194 216 222)"];
  const [flickerColor, setFlickerColor] = useState(flickerColors[0]);
  const userIsCoordinator_SalesManager =
    CRMUtils.userHasPermission(authUser, ["user_salesGroupManager"]) ||
    authUser.is_coordinator == 1;
  const userIsCallCenter =
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterManager"])
      ? true
      : false;
  const userIsSocialMedia =
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaManager"])
      ? true
      : false;
  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);
  const userCanViewInvoice = CRMUtils.userHasPermission(authUser, [
    "invoice_view",
  ]);
  const userCanMakeMultipayments = CRMUtils.userHasPermission(authUser, [
    "invoices_createmultipayment",
  ]);
  const userCanUpdateMultipayments = CRMUtils.userHasPermission(authUser, [
    "invoices_updatemultipayment",
  ]);
  const userCanDeleteMultipayments = CRMUtils.userHasPermission(authUser, [
    "invoices_deletemultipayment",
  ]);
  const userCanUnreconcile = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_unreconciliation",
  ]);
  const userCanUnreconcileSuper = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_unreconciliationsuper",
  ]);
  const userCanUnreconcileOnline = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_onlinepaymentsunreconciliation",
  ]);
  const userCanUnreconcileOnlineSuper = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_onlinepaymentsunreconciliationsuper",
  ]);
  const [openLogPopup, setOpenLogPopup] = useState(false);
  const userCanSeeLogs =
    CRMUtils.userHasRole(authUser, ["admin", "super-admin"]) ||
    CRMUtils.userHasPermission(authUser, ["invoices_viewLogs"]);

  const readOnlySalesManager =
    userCanViewEditContactMark ||
    userCanViewInvoice ||
    (userIsCoordinator_SalesManager && !contact.editable) ||
    userIsCallCenter ||
    userIsSocialMedia ||
    userIsbillingMGAssistant;

  const { TblContainer } = useTable();

  const getPayments = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api.getContactPayments(contact_id);

      // Sort payments based on selectedTransactionsIds
      const sortedPayments = data.data.sort((paymentA, paymentB) => {
        const isInSelected = (payment) =>
          selectedTransactionsIds.includes(payment.id);
        if (isInSelected(paymentA) && !isInSelected(paymentB)) {
          return -1; // A comes first if it's in selected and B is not
        } else if (!isInSelected(paymentA) && isInSelected(paymentB)) {
          return 1; // B comes first if A is not in selected but B is
        } else {
          return 0; // Otherwise, maintain the current order
        }
      });

      setPayments([...sortedPayments]);
      setIsLoading(false);
    } catch (e) {
      // console.error(err);
      setIsLoading(false);
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const removeDuplicates = (arr) => {
    return arr.filter((valor, indice) => {
      return arr.indexOf(valor) === indice;
    });
  };

  const headCellsMaterial = [
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{rowData?.paymentHistories?.data[0]?.payment_type || ""}</div>
            {rowData.reconciled ? (
              <Tooltip title={"Reconciled (Can't be updated or deleted)"} arrow>
                <span style={{ marginLeft: 6 }}>
                  <Iconify
                    icon={"ic:round-info"}
                    width={20}
                    height={20}
                    style={{
                      color: "rgb(71, 145, 219)",
                    }}
                  />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "amount",
      title: "Amount",
      render: (rowData) => (
        <ListItem>
          <ListItemText primary={"$" + rowData.amount} />
        </ListItem>
      ),
    },
    {
      field: "tags",
      title: "",
      sorting: false,
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            {rowData.paymentHistories &&
            rowData.paymentHistories.data &&
            !isEmpty(rowData.paymentHistories.data) &&
            rowData.paymentHistories.data.find(
              (a) => a.refunded_amount && a.refunded_amount > 0
            ) ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Refunded: $${rowData.paymentHistories.data
                          .reduce(
                            (total, item) =>
                              total + parseFloat(item.refunded_amount),
                            0
                          )
                          .toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <span style={{ margin: "0px !important" }}>
                    <IconButton
                      style={{
                        padding: "0px !important",
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"mdi:letter-r-circle"}
                        width={27}
                        height={27}
                        style={{
                          color: "#e65100",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            ) : (
              ""
            )}
            {rowData.paymentHistories &&
            rowData.paymentHistories.data &&
            !isEmpty(rowData.paymentHistories.data) &&
            rowData.paymentHistories.data.find(
              (a) =>
                a.transferred_parent_invoice_name &&
                a.transferred_parent_invoice_id > 0
            ) ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={
                          <>
                            {removeDuplicates(
                              rowData.paymentHistories.data
                                .filter(
                                  (a) =>
                                    a.transferred_parent_invoice_name &&
                                    a.transferred_parent_invoice_id > 0
                                )
                                .map((a) => a.transferred_parent_invoice_name)
                            ).map((name) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Iconify icon="tabler:point-filled" />
                                  <Typography>{name}</Typography>
                                </div>
                              );
                            })}
                          </>
                        }
                      />
                    </Box>
                  }
                  arrow
                >
                  <span>
                    {" "}
                    <IconButton
                      style={{
                        padding: "0px !important",
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"mdi:letter-t-circle"}
                        width={25}
                        height={25}
                        style={{
                          color: "#e91e63",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
            {rowData.paymentHistories &&
            rowData.paymentHistories.data &&
            !isEmpty(rowData.paymentHistories.data) &&
            rowData.paymentHistories.data.length > 1 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText secondary={`Multipayment`} />
                    </Box>
                  }
                  arrow
                >
                  <span>
                    {" "}
                    <IconButton
                      style={{
                        marginLeft: 3,
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"emojione-monotone:letter-m"}
                        width={25}
                        height={25}
                        style={{
                          color: "rgb(175 30 233)",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "last_4",
      title: "Last 4",
      render: (rowData) => {
        return <div>{rowData?.cc_last_4 || ""}</div>;
      },
    },
    {
      field: "payment_date",
      title: "Date",
      render: (rowData) => {
        return (
          <div>
            {rowData.payment_date
              ? moment(rowData.payment_date).format("MM/DD/YYYY, HH:mm")
              : ""}
          </div>
        );
      },
    },

    {
      field: "building",
      title: "Building",
      render: (rowData) => {
        return (
          <div>{rowData?.paymentHistories?.data[0]?.building_name || ""}</div>
        );
      },
    },
    {
      field: "by",
      title: "By",
      render: (rowData) => {
        return (
          <div>
            {rowData?.paymentHistories?.data[0]?.user?.data?.dropdown_name ||
              ""}
          </div>
        );
      },
    },
    {
      field: "file",
      title: "File",
      render: (rowData) => {
        return rowData.file && rowData.file.path ? (
          <a href={rowData.file.path} target="_blank" rel="noreferrer">
            <AttachFileIcon />
          </a>
        ) : (
          ""
        );
      },
    },
  ];

  const headCellsMaterialPayments = [
    {
      field: "amount",
      title: "Amount",
      render: (rowData) => (
        <ListItem>
          <ListItemText primary={"$" + rowData.amount} />
        </ListItem>
      ),
    },
    {
      field: "tags",
      title: "",
      sorting: false,
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {rowData.refunded_amount && rowData.refunded_amount > 0 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Refunded: $${rowData.refunded_amount.toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <span style={{ margin: "0px" }}>
                    <IconButton
                      style={{
                        padding: "0px !important",
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"mdi:letter-r-circle"}
                        width={27}
                        height={27}
                        style={{
                          color: "#e65100",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            ) : (
              ""
            )}{" "}
            {rowData.transferred_parent_invoice_name &&
            rowData.transferred_parent_invoice_id > 0 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`${rowData.transferred_parent_invoice_name}`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <span>
                    <IconButton
                      style={{
                        padding: "0px !important",
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"mdi:letter-t-circle"}
                        width={25}
                        height={25}
                        style={{
                          color: "#e91e63",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
            {rowData.payments_in_header > 1 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Belongs to a multipayment of $${
                          rowData.payments_in_header_amount
                            ? rowData.payments_in_header_amount.toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"
                        }`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <span>
                    {" "}
                    <IconButton
                      style={{
                        marginLeft: 3,
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"emojione-monotone:letter-m"}
                        width={25}
                        height={25}
                        style={{
                          color: "rgb(175 30 233)",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "invoice_name",
      title: "To Invoice",
    },
  ];

  useEffect(() => {
    if (contact_id) {
      getPayments();
    }
  }, [contact_id]);

  useEffect(() => {
    // After one second, switch to lightblue
    const timeout1 = setTimeout(() => {
      setFlickerColor(flickerColors[1]);
    }, 2000);

    // After two seconds, switch back to lightgreen
    const timeout2 = setTimeout(() => {
      setFlickerColor(flickerColors[0]);
    }, 2400);

    // Cleanup function to clear the timeouts when component unmounts
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  const selectedColumns = () => {
    if (authUser.organization.data.multiple_locations === 1) {
      return headCellsMaterialPayments;
    }

    return headCellsMaterialPayments.filter((column) => {
      return column.field != "building_name";
    });
  };

  const renderPaymentDetails = (rowData) => {
    return (
      <Box m={2}>
        <MaterialTable
          options={{
            showTitle: false,
            search: false,
            toolbar: false,
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            headerStyle: {
              backgroundColor: "rgb(201 213 219 / 34%)",
              color: "#00695c",
            },
            paging: false,
          }}
          columns={[
            { title: "Name", field: "item_name" },
            { title: "Amount", field: "amount" },
          ]}
          data={rowData.paymentdetail.data.map((payment) => {
            return {
              item_name: payment.item_name,
              amount: payment.amount,
            };
          })}
          headerColor="#00695c"
        />
      </Box>
    );
  };

  const renderPaymentHistories = (histories) => {
    return (
      <MaterialTable
        columns={selectedColumns()}
        data={histories}
        title=""
        options={{
          showTitle: false,
          search: false,
          toolbar: false,
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          headerStyle: {
            backgroundColor: "rgb(201 213 219 / 34%)",
            color: "#00695c",
          },
          paging: false,
        }}
        toolbar={false}
        showPaging={true}
        pageSize={5}
        pageSizeOptions={[5, 10, 20]}
        detailPanel={(rowData) => {
          return renderPaymentDetails(rowData);
        }}
      />
    );
  };

  const closeMultipaymentPopup = () => {
    setIsMultipaymentsPopupOpen(false);
    setSelectedPaymentHeader(null);
  };

  const openInPopup = (item = null) => {
    if (item) {
      console.log("selectedh ", item);
      setSelectedPaymentHeader(item);
    }
    setIsMultipaymentsPopupOpen(true);
  };

  const closeLogPopup = () => {
    setOpenLogPopup(false);
    setSelectedPaymentHeader(null);
  };

  const addOrEdit = async (payload) => {
    try {
      if (selectedPaymentHeader) {
        const { data } = await Api.updateMultipayment(
          selectedPaymentHeader.id,
          payload
        );

        const index = findIndex(payments, ["id", data.data.id]);
        const updatedEvent = {
          ...payments[index],
          ...data.data,
        };
        let newPayments = [...payments];
        newPayments[index] = updatedEvent;
        setPayments([...newPayments]);
        closeMultipaymentPopup();
        setNotify({
          isOpen: true,
          message: "Updated successfully!",
          type: "success",
        });
      } else {
        const { data } = await Api.createMultipayment(payload);

        setPayments([data.data, ...payments]);
        closeMultipaymentPopup();
        setNotify({
          isOpen: true,
          message: "Created successfully!",
          type: "success",
        });
      }
    } catch (error) {
      if (
        typeof consultantErrorHandle === "function" &&
        error ===
          "*** This contact is already assigned to another consultant***"
      ) {
        consultantErrorHandle(err.data.message);
      } else {
        if (error.status == 409 && (error?.data?.error || false)) {
          closeMultipaymentPopup();
          setNotify({
            isOpen: true,
            message: error.data.error,
            type: "error",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Something went wrong!",
            type: "error",
          });
        }
      }
    }
  };

  const deleteMultipayment = async (id) => {
    try {
      await Api.deleteMultipayment(id);
      setNotify({
        isOpen: true,
        message: "Deleted successfully!",
        type: "success",
      });

      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
      setConfirmDialogLoading(false);

      setPayments([...payments.filter((p) => p.id != id)]);
    } catch (err) {
      if (
        typeof consultantErrorHandle === "function" &&
        err === "*** This contact is already assigned to another consultant***"
      ) {
        consultantErrorHandle(err.data.message);
      }
      if (err.status == 409 && (err?.data?.error || false)) {
        setNotify({
          isOpen: true,
          message: err.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
      setConfirmDialogLoading(false);
    }
  };

  const getRowStyle = (rowData) => {
    if (selectedTransactionsIds.includes(rowData.id)) {
      return { backgroundColor: flickerColor };
    }
  };

  const unreconcilePayment = async (id) => {
    try {
      setConfirmDialogLoading(true);
      const { data } = await Api.unreconcilePayment(id);
      const index = findIndex(payments, ["id", data.data.id]);
      const updatedEvent = {
        ...payments[index],
        ...data.data,
      };
      let newPayments = [...payments];
      newPayments[index] = updatedEvent;
      setPayments([...newPayments]);
      setNotify({
        isOpen: true,
        message: "Unreconciled successfully!",
        type: "success",
      });
      setConfirmDialog({
        ...{
          isOpen: false,
        },
        ...confirmDialog,
      });
    } catch (error) {
      if (error.status == 401) {
        setNotify({
          isOpen: true,
          message: "Forbidden",
          type: "error",
        });
      } else {
        if (error.status == 409 && (error?.data?.error || false)) {
          setNotify({
            isOpen: true,
            message: error.data.error,
            type: "error",
          });
        } else {
          console.error(error);
          setNotify({
            isOpen: true,
            message: "Something went wrong!",
            type: "error",
          });
        }
      }
    } finally {
      setConfirmDialogLoading(false);
    }
  };
  return (
    <div>
      <TblContainer>
        <MaterialTable
          detailPanel={(rowData) => {
            return renderPaymentHistories(rowData.paymentHistories.data);
          }}
          columns={headCellsMaterial}
          data={payments}
          options={{
            loadingType: "linear",
            pageSize: 5, // make initial page size
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            actionsColumnIndex: -1,
            sorting: true,
            headerStyle: {
              backgroundColor: "#e0f2f1",
              color: "#00695c",
            },
            rowStyle: getRowStyle,
          }}
          actions={[
            {
              icon: () => (
                <Fab color="primary" aria-label="add">
                  <Iconify
                    icon={"fluent:add-12-filled"}
                    width={23}
                    height={23}
                  />
                </Fab>
              ),
              tooltip: "New payment",
              disabled:
                readOnlySalesManager ||
                userCanViewEditContactMark ||
                userCanViewInvoice,
              isFreeAction: true,
              onClick: (event) => {
                openInPopup();
              },
              hidden: !userCanMakePayment || !userCanMakeMultipayments,
              disabled:
                readOnlySalesManager ||
                userCanViewEditContactMark ||
                userCanViewInvoice,
              position: "toolbar",
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <IconButton
                      disabled={rowData.reconciled}
                      color="primary"
                      aria-label="add"
                    >
                      <Iconify icon={"bxs:pencil"} width={22} height={22} />
                    </IconButton>
                  ),
                  tooltip: "Edit",
                  disabled: rowData.reconciled,
                  hidden:
                    !CRMUtils.userHasPermission(authUser, [
                      "invoicepaymenthistory_edit",
                    ]) || !userCanUpdateMultipayments,
                  onClick: (event) => {
                    openInPopup(rowData);
                  },
                  position: "row",
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color: "rgb(63 68 73)",
                      }}
                    >
                      <Iconify
                        icon={"tabler:notes-off"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "Unreconcile",

                  hidden:
                    rowData.reconciled &&
                    ((!rowData.merchant_transaction_id &&
                      ((userCanUnreconcile &&
                        rowData.reconciled_by == authUser.id) ||
                        userCanUnreconcileSuper)) ||
                      (rowData.merchant_transaction_id &&
                        ((userCanUnreconcileOnline &&
                          rowData.reconciled_by == authUser.id) ||
                          userCanUnreconcileOnlineSuper)))
                      ? false
                      : true,
                  onClick: (event) => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to unreconcile this payment?",
                      onConfirm: () => {
                        unreconcilePayment(rowData.id);
                      },
                    });
                  },
                  position: "row",
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <IconButton
                      color="primary"
                      aria-label="remove"
                      disabled={
                        rowData.reconciled
                          ? true
                          : (rowData.paymentHistories &&
                              rowData.paymentHistories.data &&
                              rowData.paymentHistories.data.findIndex(
                                (history) =>
                                  history.paymentdetail &&
                                  history.paymentdetail.data &&
                                  history.paymentdetail.data.findIndex(
                                    (a) => a.has_product_done
                                  ) != -1
                              )) != -1
                          ? true
                          : ((!rowData.paymentHistories ||
                              !rowData.paymentHistories.data ||
                              (rowData.paymentHistories &&
                                rowData.paymentHistories.data &&
                                rowData.paymentHistories.data.find(
                                  (history) => history.editable == false
                                ))) &&
                              !CRMUtils.userHasPermission(authUser, [
                                "invoicepaymenthistory_superedit",
                              ])) ||
                            (rowData.paymentHistories &&
                              rowData.paymentHistories.data &&
                              rowData.paymentHistories.data.find(
                                (history) => history.possible_refund
                              )) ||
                            (rowData.paymentHistories &&
                              rowData.paymentHistories.data &&
                              rowData.paymentHistories.data.find(
                                (history) =>
                                  history.refunded_amount &&
                                  history.refunded_amount > 0
                              )) ||
                            (rowData.paymentHistories &&
                              rowData.paymentHistories.data &&
                              rowData.paymentHistories.data.findIndex(
                                (history) =>
                                  history.paymentdetail &&
                                  history.paymentdetail.data &&
                                  history.paymentdetail.data.findIndex(
                                    (a) => a.is_recordProduct_finalized
                                  ) != -1
                              )) != -1
                      }
                    >
                      <Iconify
                        icon={"solar:trash-bin-minimalistic-2-bold"}
                        width={22}
                        height={22}
                      />
                    </IconButton>
                  ),
                  tooltip: "Delete payment",
                  disabled: rowData.reconciled
                    ? true
                    : (rowData.paymentHistories &&
                        rowData.paymentHistories.data &&
                        rowData.paymentHistories.data.findIndex(
                          (history) =>
                            history.paymentdetail &&
                            history.paymentdetail.data &&
                            history.paymentdetail.data.findIndex(
                              (a) => a.has_product_done
                            ) != -1
                        )) != -1
                    ? true
                    : ((!rowData.paymentHistories ||
                        !rowData.paymentHistories.data ||
                        (rowData.paymentHistories &&
                          rowData.paymentHistories.data &&
                          rowData.paymentHistories.data.find(
                            (history) => history.editable == false
                          ))) &&
                        !CRMUtils.userHasPermission(authUser, [
                          "invoicepaymenthistory_superedit",
                        ])) ||
                      (rowData.paymentHistories &&
                        rowData.paymentHistories.data &&
                        rowData.paymentHistories.data.find(
                          (history) => history.possible_refund
                        )) ||
                      (rowData.paymentHistories &&
                        rowData.paymentHistories.data &&
                        rowData.paymentHistories.data.find(
                          (history) =>
                            history.refunded_amount &&
                            history.refunded_amount > 0
                        )) ||
                      (rowData.paymentHistories &&
                        rowData.paymentHistories.data &&
                        rowData.paymentHistories.data.findIndex(
                          (history) =>
                            history.paymentdetail &&
                            history.paymentdetail.data &&
                            history.paymentdetail.data.findIndex(
                              (a) => a.is_recordProduct_finalized
                            ) != -1
                        )) != -1,

                  hidden:
                    !CRMUtils.userHasPermission(authUser, [
                      "invoicepaymenthistory_delete",
                    ]) || !userCanDeleteMultipayments,
                  onClick: (event, rowData) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this payment?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        console.log("ROW DATA: ", rowData);
                        deleteMultipayment(rowData.id);
                      },
                    }),
                  position: "row",
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <IconButton aria-label="add">
                      <Iconify
                        icon={"tabler:eye-filled"}
                        style={{ color: "#7a52d9" }}
                        width={25}
                        height={25}
                      />
                    </IconButton>
                  ),
                  tooltip: "See logs",

                  hidden: !userCanSeeLogs,
                  onClick: (event) => {
                    setSelectedPaymentHeader(rowData);
                    setOpenLogPopup(true);
                  },
                  position: "row",
                };
              },
            },
          ]}
          title="Payments"
          isLoading={isLoading}
        />
      </TblContainer>

      <Popup
        title={selectedPaymentHeader ? "Update payment" : "Make payment"}
        fullWidth
        openPopup={isMultipaymentsPopupOpen}
        onClose={closeMultipaymentPopup}
        maxWidth="lg"
      >
        <MultipaymentForm
          addOrEdit={addOrEdit}
          headerForEdit={selectedPaymentHeader}
          contact_id={contact_id}
          onClose={closeMultipaymentPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmLoading={confirmDialogLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Payment Logs"
        openPopup={openLogPopup}
        onClose={closeLogPopup}
        fullWidth={true}
      >
        <ViewLogsTable
          modelId={selectedPaymentHeader?.id || null}
          getLogs={Api.getPaymentHeaderActivitiesLogs}
        />
      </Popup>
    </div>
  );
}

export default ContactPayments;
