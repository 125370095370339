import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],

};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    deleteDocument: (state) => {},
    deleteDocumentSuccess: (state, { payload }) => {
      
     
    },
    deleteDocumentFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
    deleteDocument,
    deleteDocumentSuccess,
    deleteDocumentFailure,
 
} = documentsSlice.actions;

export const documentsSelector = (state) => state.documents;
export default documentsSlice.reducer;

export function deleteDocumentService(orgId,id) {
  
    return async (dispatch) => {
      dispatch(deleteDocument());
  
      try {
        const response = await Api.deleteDocument(orgId,id);
  
        dispatch(deleteDocumentSuccess(id));
      } catch (error) {
        dispatch(deleteDocumentFailure(error));
      }
    };
  }


