import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import HeadersSidebar from "./HeadersSidebar";
import ConversationPanel from "./ConversationPanel";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Controls from "../controls";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import { debounce, isEmpty } from "lodash";
import { Tip } from "../controls/Tip";
import ScrollBar from "react-perfect-scrollbar";
import { Controller, useForm } from "react-hook-form";
import Picker from "emoji-picker-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntitiesService as fetchGroupsDropdownService } from "../../redux/slices/groupsDropdownSlice.js";

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  error: {
    marginLeft: 8,
    color: "#d73131",
    fontWeight: 600,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
  resultsTitle: {
    margin: 14,
    fontWeight: 500,
    marginBottom: 0,
    borderBottom: "1px solid #80808036",
    paddingBottom: 10,
  },
  memberHeader: {
    marginBottom: 12,
    borderRadius: 8,
    backgroundColor: "#efefef",
    display: "flex",
    alignItems: "center",
  },
}));

var dataSliceGroupsDropdown = {
  stateListName: "groupsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },

  dataDelete: {
    id: null,
    successHandle: null,
  },

  errorHandle: null,
};

const NewChatGroup = (props) => {
  const { groupForEdit, selectedHeader } = props;
  const dispatch = useDispatch();
  const [selectionStart, setSelectionStart] = useState(-1);
  const { onStartChat, onGroupCreated } = props;
  const classes = useStyles();
  const { groupsDropdown } = useSelector((state) => state.groupsDropdown);
  const [search, setSearch] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const allUsers = useRef();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    groupForEdit ? groupForEdit.members.map((u) => u.id) : []
  );
  //edit griup
  const { handleSubmit, reset, control, errors, setValue, watch } = useForm();
  const [anchorElEmojiPopover, setAnchorElEmojiPopover] = useState(null);
  const emojiPopoverOpened = Boolean(anchorElEmojiPopover);
  const idEmojiPopoverOpen = emojiPopoverOpened ? "simple-popover" : undefined;
  const inputRef = useRef();
  const searchValue = useRef("");
  const selectedGroup = useRef(null);
  const { authUser } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [avatar, setAvatar] = useState({
    file_url: groupForEdit?.avatar || null,
    file: null,
  });

  const watchAllFields = watch();

  const getUsers = async (payload = {}) => {
    setIsLoadingUsers(true);
    const { data } = await Api.getAvailableUsersToChat({ search: "" });

    allUsers.current = [...data.data];
    setUsers([...data.data]);
    setIsLoadingUsers(false);
  };

  const updateSelectionStart = () => {
    setSelectionStart(inputRef.current.selectionStart);
  };

  const onSearchChange = (value) => {
    setSearch(value);
    getUsers({ search: value });
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => onSearchChange(newValue), 800),
    []
  );

  const onEmojiClick = (event, emojiObject) => {
    const start = watchAllFields["description"]
      ? watchAllFields["description"].substr(0, selectionStart)
      : "";
    const end = watchAllFields["description"]
      ? watchAllFields["description"].substr(
          selectionStart,
          watchAllFields["description"].length
        )
      : "";

    setValue(
      "description",
      selectionStart >= 0
        ? start + emojiObject.emoji + end
        : (watchAllFields["description"] ? watchAllFields["description"] : "") +
            emojiObject.emoji
    );

    setAnchorElEmojiPopover(null);
  };

  const filterUsers = (field, value, perfectMatch = true) => {
    searchValue.current = value;
    if (perfectMatch) {
      var result = [...allUsers.current.filter((u) => u[field] == value)];

      if (selectedGroup.current) {
        result = result.filter(
          (user) =>
            user.groups &&
            !isEmpty(user.groups.data) &&
            user.groups.data.find((group) => group.id == selectedGroup.current)
        );
      }
      setUsers([...result]);
    } else {
      var result = [
        ...allUsers.current.filter((u) =>
          u[field].toUpperCase().includes(value.toUpperCase())
        ),
      ];
      if (selectedGroup.current) {
        result = result.filter(
          (user) =>
            user.groups &&
            !isEmpty(user.groups.data) &&
            user.groups.data.find((group) => group.id == selectedGroup.current)
        );
      }
      setUsers([...result]);
    }
  };

  const checkAll = () => {
    var usersSet = new Set([...users.map((user) => user.id), ...selectedUsers]);

    setSelectedUsers([...usersSet]);
  };

  const updateUsersSelection = (id) => {
    if (selectedUsers.find((i) => i == id)) {
      setSelectedUsers([...selectedUsers.filter((i) => i != id)]);
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const getGroupsOptions = () => {
    return groupsDropdown.map((group) => {
      return {
        id: group.id,
        title: group.name,
      };
    });
  };

  const onSubmit = async (payload) => {
    setIsSubmitting(true);
    payload["members"] = [...selectedUsers, authUser.id];
    payload['conversation_id'] = selectedHeader?.conversation_id || null;

    if (!(!avatar.file && avatar.file_url)) {
      payload["file"] = avatar.file;
    }

    if (groupForEdit) {
      const { data } = await Api.updateChatGroup(payload, groupForEdit.id);

      onGroupCreated({ ...data.data });
    } else {
      const { data } = await Api.createChatGroup(payload);

      onGroupCreated({ ...data.data });
    }
    setIsSubmitting(false);
  };

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  useEffect(() => {
    if (isEmpty(groupsDropdown)) {
      dispatch(fetchGroupsDropdownService(dataSliceGroupsDropdown));
    }
    getUsers();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <ScrollBar style={{ maxHeight: "700px" }}>
        <Box m={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Avatar
                src={avatar.file_url}
                style={{
                  backgroundColor: "rgba(164, 174, 185, 0.3)",
                  width: "160px",
                  height: "160px",
                  margin: "0 auto ",
                  border: "1px dashed gray",
                }}
              >
                <Iconify
                  style={{
                    color: "#616a8d",
                    color: "rgb(97, 106, 141)",
                    width: 45,
                    height: 45,
                  }}
                  icon={"heroicons:user-group-20-solid"}
                />
              </Avatar>

              {avatar && avatar.file_url ? (
                <Button
                  onClick={() => {
                    setAvatar({ file_url: null, file: null });
                  }}
                  style={{
                    background: "none",
                    color: "rgb(97, 106, 141)",
                    borderRadius: 18,
                    boxShadow: "none",
                  }}
                  startIcon={<Iconify icon="ic:round-close" />}
                >
                  Remove avatar
                </Button>
              ) : (
                <Controls.DropzoneDialog
                  startIcon={<Iconify icon="tabler:pencil" />}
                  label="Change avatar"
                  st={{
                    background: "none",
                    color: "rgb(97, 106, 141)",
                    borderRadius: 18,
                    boxShadow: "none",
                  }}
                  onChange={async (files) => {
                    setAvatar({
                      file: await fileToBase64(files[0]),
                      file_url: URL.createObjectURL(files[0]),
                    });
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={groupForEdit?.name || null}
                as={<Controls.Input fullWidth label="Name*" />}
                rules={{ required: "Group name is required" }}
              />
              {errors.name && <p className={classes.error}>{errors.name.message}</p>}
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={groupForEdit?.description || null}
                as={
                  <Controls.Textarea
                    inputRef={inputRef}
                    onSelect={updateSelectionStart}
                    label="Description"
                    fullWidth
                    rows={4}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Add emoji">
                          <span>
                            <IconButton
                              onClick={(e) => {
                                setAnchorElEmojiPopover(e.currentTarget);
                              }}
                            >
                              <Iconify
                                style={{ color: "#4f536b" }}
                                icon="fluent:emoji-24-regular"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    }}
                  />
                }
              />
              {errors.description && (
                <p className="error">{errors.description.message}</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.memberHeader}>
                <Checkbox
                  checked={
                    users.every((user) => selectedUsers.includes(user.id)) &&
                    users.length > 0
                  }
                  onClick={() => {
                    if (
                      users.every((user) => selectedUsers.includes(user.id))
                    ) {
                      setSelectedUsers([
                        ...selectedUsers.filter(
                          (id) => !users.find((u) => u.id == id)
                        ),
                      ]);
                    } else {
                      checkAll();
                    }
                  }}
                />
                <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                  {"Members" +
                    " (Selected: " +
                    (groupForEdit
                      ? selectedUsers.length - 1
                      : selectedUsers.length) +
                    ")"}
                </Typography>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Controls.Input
                  fullWidth
                  onChange={(e) => {
                    filterUsers("dropdown_name", e.target.value, false);
                  }}
                  InputProps={{
                    startAdornment: (
                      <Iconify
                        style={{
                          width: 22,
                          height: 22,
                          color: "#5e6e8b",
                          marginRight: 10,
                        }}
                        icon="tabler:search"
                      />
                    ),
                  }}
                  label="Search..."
                />
                <Controls.Select
                  formStyle={{ maxWidth: 185, marginLeft: 6 }}
                  label={"Groups"}
                  options={[{ id: null, title: "-" }, ...getGroupsOptions()]}
                  onChange={(e) => {
                    selectedGroup.current = e.target.value;
                    if (e.target.value) {
                      var result = [
                        ...allUsers.current.filter(
                          (user) =>
                            user.groups &&
                            !isEmpty(user.groups.data) &&
                            user.groups.data.find(
                              (group) => group.id == e.target.value
                            )
                        ),
                      ];

                      if (searchValue.current) {
                        result = result.filter((user) =>
                          user.dropdown_name
                            .toUpperCase()
                            .includes(searchValue.current.toUpperCase())
                        );
                      }
                      setUsers([...result]);
                    } else {
                      var result = [...allUsers.current];

                      if (searchValue.current) {
                        result = result.filter((user) =>
                          user.dropdown_name
                            .toUpperCase()
                            .includes(searchValue.current.toUpperCase())
                        );
                      }
                      setUsers([...result]);
                    }
                  }}
                />
              </div>
              {isLoadingUsers ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 8,
                    paddingTop: 12,
                  }}
                >
                  <Iconify
                    className={classes.loadingIcon}
                    style
                    icon="eos-icons:bubble-loading"
                  />
                  <Typography className={classes.loadingTitle}>
                    Loading users
                  </Typography>
                </div>
              ) : (
                ""
              )}
              <List>
                {users.map((user) => {
                  return (
                    <ListItem
                      style={{
                        paddingLeft: 0,
                        backgroundColor: selectedUsers.includes(user.id)
                          ? "#e5f1f59c"
                          : "",
                        borderRadius: 12,
                        padding: "4px 0px",
                      }}
                    >
                      <ListItemAvatar>
                        <Checkbox
                          checked={selectedUsers.includes(user.id)}
                          onClick={() => {
                            updateUsersSelection(user.id);
                          }}
                        />
                      </ListItemAvatar>

                      <ListItemText
                        primaryTypographyProps={{
                          style: { fontWeight: 600 },
                        }}
                        primary={user.dropdown_name}
                        secondary={user.position.replace(/^\w/, (c) =>
                          c.toUpperCase()
                        )}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </Box>
      </ScrollBar>
      <Grid
        container
        spacing={4}
        item
        xs={12}
        md={12}
        style={{justifyContent: 'flex-end'}}
      >
        <div>
          <Controls.Button
            disabled={
              (groupForEdit
                ? selectedUsers.length == 1
                : selectedUsers.length == 0) || isSubmitting
            }
            startIcon={<Iconify icon="heroicons:user-group-20-solid" />}
            type="submit"
            text={
              groupForEdit
                ? isSubmitting
                  ? "Saving..."
                  : "Save changes"
                : isSubmitting
                ? "Creating group..."
                : "Create group"
            }
          />
        </div>
      </Grid>

      <Popover
        id={idEmojiPopoverOpen}
        open={emojiPopoverOpened}
        anchorEl={anchorElEmojiPopover}
        onClose={() => setAnchorElEmojiPopover(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Picker onEmojiClick={onEmojiClick} />
      </Popover>
    </form>
  );
};

export default NewChatGroup;
