import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  LinearProgress,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Iconify from "./Iconify";
import Popup from "./Popup";
import { style } from "@material-ui/system";
import { padding } from "polished";
import Api from "../lib/api";
import ScrollBar from "react-perfect-scrollbar";
import CRMUtils from "../utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    width: "100%",
    textAlign: "left",
    background: "#e6ffe8",
    padding: "2px 12px",
    borderRadius: 12,
    border: "1px solid #c8f6d1",
    margin: "2px 0px",
  },
  primaryText: {
    fontSize: 14,
    fontWeight: 500,
  },
  secondaryText: {
    fontWeight: 500,
  },
  icon: {
    color: "#06874a",
    width: 24,
    height: 24,
  },
  couponTitleStyle: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 16,

    padding: "4px",
    marginBottom: 6,
    color: "#2b5356",
  },
  couponStyle: {
    background: "#f1fff5",
    padding: "5px 15px",
    border: "1px dashed #94dac1",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  disabledCouponStyle: {
    background: "#f4f4f4",
    padding: "5px 15px",
    border: "1px dashed #bdc0bf",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

export default function AvailableCoupons(props) {
  const {
    availableCoupons = [],
    invoiceValue = 0,
    loadingCoupons = false,
    applyCouponsToInvoice,
    maxCouponsToApply = 0,
    defaultSelectedCoupons=[],
    allowEmptySubmit=false
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [selectedCoupons, setSelectedCoupons] = useState(defaultSelectedCoupons);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userCanApplyCoupons = CRMUtils.userHasPermission(authUser, [
    "invoice_applycoupons",
  ]);

  const classes = useStyles();

  const onClickCheckBox = (coupon) => {
    if (selectedCoupons.find((c) => c.id == coupon.id)) {
      setSelectedCoupons(selectedCoupons.filter((c) => c.id != coupon.id));
    } else {
      setSelectedCoupons([coupon, ...selectedCoupons]);
    }
  };

  const isCouponAvailable = (coupon) => {
    if (selectedCoupons.find((c) => c.id == coupon.id)) {
      return true;
    } else {
      if (selectedCoupons.length >= maxCouponsToApply) {
        return false;
      }
      let appliedCouponsValue = selectedCoupons.reduce(
        (total, item) => total + parseFloat(item.coupon_value),
        0
      );
      if (
        parseFloat(invoiceValue) - appliedCouponsValue <
          coupon.min_invoice_price ||
        selectedCoupons.find(
          (c) =>
            parseFloat(invoiceValue) -
              (appliedCouponsValue -
                c.coupon_value +
                parseFloat(coupon.coupon_value)) <
            c.min_invoice_price
        )
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const renderCoupons = () => {
    return availableCoupons.map((coupon) => {
      var disabled = !isCouponAvailable(coupon);
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "5px",

            backgroundColor: selectedCoupons.find((c) => c.id == coupon.id)
              ? "#f5f5f5"
              : "unset",
          }}
        >
          <Checkbox
            edge="start"
            checked={selectedCoupons.find((c) => c.id == coupon.id)}
            onClick={() => {
              onClickCheckBox(coupon);
            }}
            tabIndex={-1}
            disabled={disabled}
          />
          <div
            className={
              disabled ? classes.disabledCouponStyle : classes.couponStyle
            }
          >
            <div style={{ marginLeft: 4, width: "100%" }}>
              <Typography className={classes.couponTitleStyle}>
                {coupon.name}
              </Typography>
              <div
                style={{
                  background: disabled
                    ? "rgb(116 142 129 / 12%)"
                    : "rgb(36 188 109 / 12%)",
                  borderRadius: 10,
                  padding: "4px 10px",
                  marginBottom: 8,
                }}
              >
                <Typography>
                  <strong style={{ fontWeight: 500 }}>Coupon value: </strong>
                  {` $${coupon.coupon_value}`}
                </Typography>
                <Typography>
                  <strong style={{ fontWeight: 500 }}>
                    Required invoice price:{" "}
                  </strong>
                  {` $${coupon.min_invoice_price}`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedCoupons([]);
  };

  const applyCoupons = async () => {
    setIsSubmitting(true);
    var payload = {
      coupons: selectedCoupons,
    };

    await applyCouponsToInvoice(payload);
    setIsSubmitting(false);
    closePopup();
  };

  if (
    !availableCoupons ||
    availableCoupons.length == 0 ||
    maxCouponsToApply == 0 ||
    !userCanApplyCoupons
  ) {
    return "";
  }

  return (
    <div>
      <Button
        className={classes.button}
        startIcon={
          <Iconify className={classes.icon} icon="vaadin:book-dollar" />
        }
        onClick={() => {
            setSelectedCoupons(defaultSelectedCoupons);
          setIsPopupOpen(true);
   
        }}
      >
        <ListItemText
          primaryTypographyProps={{ className: classes.primaryText }}
          secondaryTypographyProps={{ className: classes.secondaryText }}
          primary={"This invoice is eligible for some coupons!"}
          secondary={"(Click to see them)"}
        />
      </Button>

      <Popup
        title={"Coupons"}
        fullWidth={true}
        maxWidth={"sm"}
        openPopup={isPopupOpen}
        onClose={() => {
          closePopup();
        }}
        dialogContentProps={{ style: { padding: "12px 8px", paddingLeft: 18 } }}
      >
        <Typography style={{ margin: 6, textAlign: "center" }} variant="h6">
          Available Coupons
        </Typography>
        {loadingCoupons ? (
          <LinearProgress />
        ) : (
          <>
            <ScrollBar style={{ maxHeight: "calc(-380px + 100vh)" }}>
              {renderCoupons()}
            </ScrollBar>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: 5,
                marginTop: 8,
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography>
                  <strong>Max to apply:</strong>
                  {` ${maxCouponsToApply}`}
                </Typography>
                <Typography style={{ marginLeft: 10 }}>
                  <strong>Selected:</strong>
                  {` ${selectedCoupons.length}`}
                </Typography>
              </div>
              <Button
                disabled={(selectedCoupons.length == 0 && !allowEmptySubmit) || isSubmitting}
                variant="contained"
                color="primary"
                onClick={applyCoupons}
              >
                {isSubmitting ? "Applying..." : `Apply coupon(s)`}
              </Button>
            </div>
          </>
        )}
      </Popup>
    </div>
  );
}
