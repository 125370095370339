import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import useTable from "./useTable";
import { isEmpty } from "lodash";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import { Paper } from "@material-ui/core";
import Api from "../lib/api";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));
function DocumentsSent(props) {
  const classes = useStyles();
  const [documents, setDocuments] = useState([]);
  const {record_id}=props;
  const dataMaterialTable = documents? documents.map((o) => ({ ...o })): [];
  const [progressBar, setProgressBar] = useState(false);



  const headCellsMaterial = [
    {
      field: "created_at",
      title: "Sent Date",
      render: (rowData) => {
        return rowData.created_at
          ? moment(rowData.created_at).format("YYYY-MM-DD hh:mm A")
          : "";
      },
    },
    {
      field: "name",
      title: "Name",
      render: (rowData) => {
        return !isEmpty(rowData.document.data)
          ? rowData.document.data[0].name
          : "";
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.document.data[0].name
          .toLowerCase()
          .indexOf(term.toLowerCase()) != -1,
    },
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return !isEmpty(rowData.document.data)
          ? rowData.document.data[0].collection_name
          : "";
      },
    },

    {
      field: "file",
      title: "File",
      render: (rowData) =>
        !isEmpty(rowData.document.data) ? (
          <a
            href={rowData.document.data[0].url}
            target="_blank"
            rel="noreferrer"
          >
            <AttachFileIcon />
          </a>
        ) : (
          ""
        ),
    },
  ];


  
  useEffect(() => {
    //  setLocalToDos(toDos);
    if (record_id) {
      setProgressBar(true);
      getDocumentsSend().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getDocumentsSend = async () => {
    const { data } = await Api.getDocumentsSendRecord(record_id);
    setDocuments([...data.data]);
  };


  const { TblContainer } = useTable();

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
          isLoading= {progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              loadingType: 'linear',
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Documents Sent"
          />
        </TblContainer>
      </Paper>
    </Wrapper>
  );
}

export default DocumentsSent;
