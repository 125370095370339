import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import Iconify from "../Iconify";
import {
  Avatar,
  Button,
  CardActionArea,
  CircularProgress,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Api from "../../lib/api";
import moment from "moment/moment";
import { findIndex, isEmpty, trim } from "lodash";
import Controls from "../controls";
import Picker from "emoji-picker-react";
import { useDispatch, useSelector } from "react-redux";
import ScrollBar from "react-perfect-scrollbar";
import Popup from "../Popup";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePublicNotificationChannel } from "../../helpers/websockets";
import { updateTotalUnreadChatMessages } from "../../redux/slices/totalUnreadChatMessages";
import { updateGeneralTotalUnreadChatMessages } from "../../redux/slices/generalTotalUnreadChatMessages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  noHeaderMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    maxHeight: "80vh",
    margin: 10,
    width: "100%",
    borderRadius: 20,
  },
  noHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "96vh",
  },
  selectUserMessage: {
    fontWeight: 600,
    color: "#193b59",
    listStyle: "upper-roman",
    background: "#d5d3e37d",
    padding: "4px 15px",
    borderRadius: "15px",
    fontSize: 22,
  },
  selectUserIcon: {
    width: 60,
    height: 60,
    marginBottom: "12px",
    color: "#5e6e8b",
  },
  outMessage: {
    padding: "4px 3px",
    borderRadius: "10px",
    margin: "8px 0px",
    maxWidth: "500px",
    background: "#dfeeff",
    whiteSpace: "pre-line",
    textAlign: "left",
    width: "fit-content",
  },
  inMessage: {
    padding: "4px 3px",
    borderRadius: "10px",
    margin: "8px 0px",
    maxWidth: "500px",
    background: "#e5e8eb82",
    whiteSpace: "pre-line",
    textAlign: "left",
    width: "fit-content",
  },
  messagesContainer: {
    backgroundColor: "white",
    padding: 14,
    width: "100%",
    border: "1px solid #13678712",
    borderRadius: "15px",
  },
  date: {
    textAlign: "right",
    fontSize: 11,
    fontWeight: 500,
    color: "#6b6868",
  },
  respondsToContainer: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: "0px 7px",
    borderLeft: "2px solid #b9cae7",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  writeMessageContainer: {
    height: "12vh",
    width: "100%",
    display: "grid",
    alignItems: "center",
  },
  blockAlertButton: {
    background: "#dbdbdbb5",
    borderRadius: 18,
    padding: "6px 20px",
  },
  blockAlert: {
    margin: "0 auto",
    padding: "6px 20px",
    background: "#ffdfdf",
    borderRadius: "13px",
    maxWidth: "fit-content",
    color: "#a50000",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  loadingIconConv: {
    width: 26,
    height: 26,
    color: "#004cff",
  },
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
  textfieldWithRespondsTo: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      backgroundColor: "white",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
  textFieldRespondsToHeader: {
    padding: 4,
    border: "1px solid rgb(0 0 0 / 14%)",
    borderBottom: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    background: "white",
  },
  respondsToTextFieldText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "74vh",
  },
  disabledChatAlert: {
    margin: "0 auto",
    padding: "6px 20px",
    background: "#e5e5e5",
    borderRadius: "13px",
    maxWidth: "fit-content",
    color: "#393939",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  headerNameDisabled: {
    fontSize: "16.5px",
    fontWeight: 500,
    color: "#3f3f3f",
  },
  headerName: {
    fontSize: "16.5px",
    fontWeight: 500,
    color: "#3f3f3f",
    cursor: "pointer",
    "&:hover": {
      color: "#1b3c7b",
    },
  },
  userInformationRowContainer: {
    display: "flex",
    alignItems: "center",
    padding: "7px 20px",
    borderBottom: "1px solid #80808024",
  },
  userInformationRowTitle: {
    fontWeight: 600,
    marginRight: 10,
  },
  typingContainer: {
    fontStyle: "italic",
    fontSize: 12,
    fontWeight: 700,
    color: "#004f95",
  },
}));

var limit = 20;
const UserToUserConversation = (props) => {
  const classes = useStyles();
  const {
    customHeight,
    selectedMessageID,
    setSelectedMessageID,
    getMessagesNeeded,
    setGetMessagesNeeded,
    selectedTab,
    selectedHeader,
    selectedHeaderID,
    headers,
    setHeaders,
    setSelectedHeader,
  } = props;
  const dispatch = useDispatch();
  const [focusedMessage, setFocusedMessage] = useState(null);
  const messagesPage = useRef(0);
  const [totalMessages, setTotalMessages] = useState(-1);
  const [selectionStart, setSelectionStart] = useState(-1);
  const [message, setMessage] = useState("");
  const [anchorElMenuOptions, setAnchorElMenuOptions] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [anchorElEmojiPopover, setAnchorElEmojiPopover] = useState(null);
  const emojiPopoverOpened = Boolean(anchorElEmojiPopover);
  const idEmojiPopoverOpen = emojiPopoverOpened ? "simple-popover" : undefined;
  const inputRef = useRef();
  const { authUser } = useSelector((state) => state.auth);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [respondsToMessage, setRespondsToMessage] = useState(null);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const openPopover = Boolean(anchorElPopover);
  const popoverID = openPopover ? "simple-popover" : undefined;
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [openedUser, setOpenedUser] = useState(null);
  const [userIsTyping, setUserIsTyping] = useState(false);
  const typing = useRef(false);
  const typingTimeoutRef = useRef(null);
  const messagesContainerRef = useRef();
  const { totalUnreadChatMessages } = useSelector(
    (state) => state.totalUnreadChatMessages
  );
  const { generalTotalUnreadChatMessages } = useSelector(
    (state) => state.generalTotalUnreadChatMessages
  );

  const sendMessage = async (fileObj = null) => {
    const uniqueId = `id_${new Date().getTime()}_${Math.floor(
      Math.random() * 1000
    )}`;
    var payload = {
      from_user_id: authUser.id,
      to_model_type: selectedHeader.recipient_model_type,
      to_model_id: selectedHeader.recipient_model_id,
      body: message,
      file_name:
        fileObj && fileObj.file && fileObj.file_name
          ? fileObj.file_name
          : "File",
      organization_id: authUser.organization_id,
      file: fileObj && fileObj.file ? fileObj.file : null,
      responds_to_message: respondsToMessage ? respondsToMessage.id : null,
    };

    setMessage("");

    var tempMessages = [
      {
        ...payload,
        ...{
          ...fileObj,
          ...{
            message_id: uniqueId,
            direction: "out",
            status: "pending",
            message_date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            view: 0,
            respondsTo: respondsToMessage ? { data: respondsToMessage } : null,
          },
        },
      },
      ...messages,
    ];
    setRespondsToMessage(null);

    setMessages([...tempMessages]);

    const { data } = await Api.sendChatMessage(payload);

    const index = findIndex(tempMessages, ["message_id", uniqueId]);
    const updatedEvent = {
      ...tempMessages[index],
      ...data.data,
    };

    let newMessages = [...tempMessages];
    newMessages[index] = updatedEvent;

    setMessages([...newMessages]);

    setTotalMessages(totalMessages + 1);

    if (selectedHeader.id) {
      const indexHeader = findIndex(headers, ["id", selectedHeader.id]);
      const updatedEventHeader = {
        ...headers[indexHeader],
        ...data.data.header.data,
      };

      let newHeaders = [...headers];
      newHeaders[indexHeader] = updatedEventHeader;
      newHeaders = newHeaders.sort((a, b) => {
        if (b.is_pinned - a.is_pinned !== 0) {
          return b.is_pinned - a.is_pinned;
        }

        if (a.is_pinned && b.is_pinned) {
          return new Date(b.pinned_at) - new Date(a.pinned_at);
        }

        return (
          new Date(b.conversation.data.last_message_date) -
          new Date(a.conversation.data.last_message_date)
        );
      });
      setHeaders([...newHeaders]);
    } else {
      let newHeaders = [
        {
          ...selectedHeader,
          ...data.data.header.data,
        },
        ...headers,
      ];

      newHeaders = newHeaders.sort((a, b) => {
        if (b.is_pinned - a.is_pinned !== 0) {
          return b.is_pinned - a.is_pinned;
        }

        if (a.is_pinned && b.is_pinned) {
          return new Date(b.pinned_at) - new Date(a.pinned_at);
        }

        return (
          new Date(b.conversation.data.last_message_date) -
          new Date(a.conversation.data.last_message_date)
        );
      });
      setHeaders([...newHeaders]);
    }

    setSelectedHeader({
      ...selectedHeader,
      ...data.data.header.data,
    });
  };

  const getMessages = async (messages, messageID) => {
    setGetMessagesNeeded(false);
    if (selectedHeader && selectedHeader.id) {
      setLoadingMessages(true);
      messagesPage.current = messagesPage.current + 1;
      const { data } = await Api.getChatConversationMessages(
        selectedHeader.conversation_id,
        selectedHeader.id,
        messageID ? messageID : "",
        messagesPage.current,
        limit
      );

      if (messagesPage.current == 1 && data.data.length > limit) {
        messagesPage.current = Math.ceil(data.data.length / limit);
      }
      setFocusedMessage(messageID);
      setSelectedMessageID(null);

      setMessages([...messages, ...data.data]);
      setTotalMessages(data.meta.pagination.total);
      setLoadingMessages(false);
    } else {
      setMessages([]);
    }
  };

  const blockUser = async () => {
    const { data } = await Api.blockUserInChat({
      header_id: selectedHeader.id,
      block: selectedHeader.is_blocked ? false : true,
    });

    setSelectedHeader({ ...selectedHeader, ...data.data });

    const index = findIndex(headers, ["id", selectedHeader.id]);
    const updatedEvent = {
      ...headers[index],
      ...data.data,
    };

    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    setHeaders([...newHeaders]);
    handleCloseMenuOptions();
  };

  const pinChat = async () => {
    const { data } = await Api.updateChatHeader(selectedHeader.id, {
      is_pinned: selectedHeader.is_pinned ? false : true,
      pinned_at: selectedHeader.is_pinned
        ? null
        : moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
    });

    setSelectedHeader({ ...selectedHeader, ...data.data });

    const index = findIndex(headers, ["id", selectedHeader.id]);
    const updatedEvent = {
      ...headers[index],
      ...data.data,
    };

    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }

      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }

      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
    handleCloseMenuOptions();
  };

  const updateSelectionStart = () => {
    setSelectionStart(inputRef.current.selectionStart);
  };

  const renderRespondsTo = (message) => {
    return (
      <CardActionArea
        className={classes.respondsToContainer}
        onClick={() => {
          setFocusedMessage(null);

          clickToMessage(message.id);
        }}
      >
        {message.file_url ? (
          message.file_type && message.file_type.includes("image") ? (
            <img
              style={{ maxWidth: 35, maxHeight: 35, marginRight: 12 }}
              src={message.file_url}
            />
          ) : message.file_type && message.file_type.includes("video") ? (
            <video
              style={{ maxWidth: 35, maxHeight: 35, marginRight: 12 }}
              src={message.file_url}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div>
          <div>
            <Typography style={{ fontWeight: 700, fontSize: 12 }}>
              {message.from_user_full_name}
            </Typography>
          </div>
          {message.file_url ? (
            message.file_type && message.file_type.includes("image") ? (
              <Typography style={{ color: "gray", fontStyle: "italic" }}>
                Sent an image
              </Typography>
            ) : message.file_type && message.file_type.includes("video") ? (
              <Typography style={{ color: "gray", fontStyle: "italic" }}>
                Sent a video
              </Typography>
            ) : (
              <div
                style={{
                  maxWidth: "fit-content",
                  padding: "4px 0px",

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Iconify
                  style={{ color: "#6d747b", marginRight: 4 }}
                  icon="iconamoon:file-fill"
                />
                {message.file_name}
              </div>
            )
          ) : (
            <Typography> {message.body} </Typography>
          )}
        </div>
      </CardActionArea>
    );
  };

  const handleTyping = () => {
    if (selectedHeader) {
      if (!typing.current) {
        Api.chatUserTyping({
          conversation_id: selectedHeader.conversation_id,
          to_user_id: selectedHeader.recipient_model_id,
          typing: true,
        });
        typing.current = true;
      }

      if (typing.current && typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      if (typing) {
        typingTimeoutRef.current = setTimeout(() => {
          Api.chatUserTyping({
            conversation_id: selectedHeader.conversation_id,
            to_user_id: selectedHeader.recipient_model_id,
            typing: false,
          });
          typing.current = false;
        }, 1000);
      }
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    handleTyping();
    const start = message.substr(0, selectionStart);
    const end = message.substr(selectionStart, message.length);

    setMessage(
      selectionStart >= 0
        ? start + emojiObject.emoji + end
        : message + emojiObject.emoji
    );

    setAnchorElEmojiPopover(null);
  };

  const updateMessage = (id, props) => {
    const index = findIndex(messages, ["id", id]);
    const updatedEvent = {
      ...messages[index],
      ...props,
    };

    let newMessages = [...messages];
    newMessages[index] = updatedEvent;

    setMessages([...newMessages]);
  };

  useEffect(() => {
    if (focusedMessage) {
      setTimeout(() => {
        setFocusedMessage(null);
      }, 3000);
    }
  }, [focusedMessage]);

  useEffect(() => {
    if (messages && focusedMessage) {
      scrollToMessage(focusedMessage);
    }
  }, [messages]);

  const scrollToMessage = (messageId) => {
    const container = messagesContainerRef.current;

    const element = container.querySelector(`#message-id-${messageId}`);

    if (element) {
      element.scrollIntoView({ block: "start", inline: "nearest" });
    }
  };

  const clickToMessage = async (id) => {
    var messageFound = messages.find((msg) => msg.id == id) ? true : false;

    if (messageFound) {
      setFocusedMessage(id);
      scrollToMessage(id);
    } else {
      messagesPage.current = 0;
      await getMessages([], id);
      scrollToMessage(id);
    }
  };

  const renderMessages = () => {
    return messages.map((message) => {
      if (message.from_user_id == authUser.id) {
        var icon = { icon: null, color: null };

        if (message.view) {
          icon.icon = "ci:check-all";
          icon.color = "rgb(14 67 227)";
        } else {
          switch (message.status) {
            case "sent":
              icon.icon = "uil:check";
              icon.color = "rgb(107 110 123)";
              break;
            case "failed":
              icon.icon = "ic:round-error-outline";
              icon.color = "rgb(225 53 53)";
              break;
            default:
              icon.icon = "uil:clock";
              icon.color = "#5a5f75";
              break;
          }
        }

        return (
          <div
            id={"message-id-" + message.id}
            style={{
              backgroundColor:
                focusedMessage && focusedMessage == message.id
                  ? "#d1dee72e"
                  : "#ffffff00",
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <div
              style={{
                maxWidth: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
              onMouseOver={() => {
                updateMessage(message.id, { mouseOver: true });
              }}
              onMouseLeave={() => {
                updateMessage(message.id, { mouseOver: false });
              }}
            >
              {" "}
              {message.mouseOver ? (
                <div style={{ display: "grid", textAlign: "center" }}>
                  {message.file_url ? (
                    <Tooltip title={"Open"}>
                      <span>
                        <a
                          href={message.file_url}
                          download={message.file_name}
                          target="_blank"
                        >
                          <Iconify
                            style={{ width: 20, height: 20, color: "#616161" }}
                            icon="majesticons:open"
                          />
                        </a>
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {!selectedHeader.is_blocked &&
                  !selectedHeader.blocked_by_recipient &&
                  selectedHeader.recipient &&
                  selectedHeader.recipient.enable_chat ? (
                    <Tooltip title={"Reply message "}>
                      <span>
                        <IconButton
                          style={{ padding: 5 }}
                          onClick={() => {
                            setRespondsToMessage(message);
                            if (inputRef.current) {
                              inputRef.current.focus();
                            }
                          }}
                        >
                          <Iconify
                            style={{ width: 20, height: 20 }}
                            icon="lucide:reply"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className={classes.outMessage}>
                {message.respondsTo && !isEmpty(message.respondsTo.data)
                  ? renderRespondsTo(message.respondsTo.data)
                  : ""}{" "}
                {message.file_url && message.file_type ? (
                  <div>
                    {message.file_type.includes("image") ? (
                      <CardActionArea
                        style={{
                          height: 180,
                          background: "#393939",
                          borderRadius: 8,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSelectedMessage(message);
                          setIsPreviewOpen(true);
                        }}
                      >
                        <img
                          style={{
                            maxWidth: 300,
                            maxHeight: 180,
                            padding: "5px 5px 0px 5px",
                          }}
                          src={message.file_url}
                        />
                      </CardActionArea>
                    ) : message.file_type.includes("video") ? (
                      <CardActionArea
                        style={{
                          height: 180,
                          background: "#393939",
                          borderRadius: 8,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSelectedMessage(message);
                          setIsPreviewOpen(true);
                        }}
                      >
                        <video
                          style={{
                            maxHeight: "180px",
                            maxWidth: "300px",
                            padding: "5px 5px 0px 5px",
                          }}
                          controls
                        >
                          <source
                            src={message.file_url}
                            type={message.file_type}
                          ></source>
                        </video>
                      </CardActionArea>
                    ) : (
                      <a
                        href={message.file_url}
                        download={message.file_name}
                        target="_blank"
                      >
                        <Button
                          startIcon={
                            <Iconify
                              icon="iconamoon:file-fill"
                              style={{ color: "#595959" }}
                            />
                          }
                        >
                          <Typography>
                            {" "}
                            {message.file_name ? message.file_name : "File"}
                          </Typography>
                        </Button>
                      </a>
                    )}
                  </div>
                ) : (
                  <Typography
                    style={{
                      margin: "0px 4px",
                      fontSize: "14.4px",
                      wordBreak: "break-word",
                    }}
                  >
                    {message.body}
                  </Typography>
                )}
                <div
                  style={{
                    padding: "0px 5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography className={classes.date}>
                    {moment(message.message_date).format("MM/DD/YY hh:mm A")}
                  </Typography>
                  <Iconify
                    style={{
                      color: icon.color,
                      width: 17,
                      height: 17,
                      marginLeft: 3,
                    }}
                    icon={icon.icon}
                  />
                </div>
              </div>
            </div>
            <Avatar
              style={{
                backgroundColor: "rgb(164 174 185 / 30%)",
                width: 28,
                height: 28,
                marginLeft: 8,
              }}
              src={selectedHeader?.user?.data?.avatar || null}
            >
              <Iconify
                style={{ color: "#616a8d" }}
                icon={
                  selectedHeader.user &&
                  selectedHeader.user.data &&
                  selectedHeader.user.data.gender == "F"
                    ? "formkit:avatarwoman"
                    : "formkit:avatarman"
                }
              />
            </Avatar>
          </div>
        );
      } else {
        return (
          <div
            id={"message-id-" + message.id}
            style={{
              backgroundColor:
                focusedMessage && focusedMessage == message.id
                  ? "#f2f9ff"
                  : "#ffffff00",
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "rgb(164 174 185 / 30%)",
                width: 28,
                height: 28,
                marginRight: 8,
              }}
              src={message?.fromUser?.data?.avatar || null}
            >
              <Iconify
                style={{ color: "#616a8d" }}
                icon={
                  message.fromUser &&
                  message.fromUser.data &&
                  message.fromUser.data.gender == "F"
                    ? "formkit:avatarwoman"
                    : "formkit:avatarman"
                }
              />
            </Avatar>
            <div
              style={{
                maxWidth: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
              onMouseOver={() => {
                updateMessage(message.id, { mouseOver: true });
              }}
              onMouseLeave={() => {
                updateMessage(message.id, { mouseOver: false });
              }}
            >
              <div className={classes.inMessage}>
                {message.respondsTo && !isEmpty(message.respondsTo.data)
                  ? renderRespondsTo(message.respondsTo.data)
                  : ""}
                {message.file_url && message.file_type ? (
                  <div>
                    {message.file_type.includes("image") ? (
                      <CardActionArea
                        style={{
                          height: 180,
                          background: "#393939",
                          borderRadius: 8,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSelectedMessage(message);
                          setIsPreviewOpen(true);
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            maxWidth: 300,
                            maxHeight: 180,
                            padding: "5px 5px 0px 5px",
                          }}
                          src={message.file_url}
                        />
                      </CardActionArea>
                    ) : message.file_type.includes("video") ? (
                      <CardActionArea
                        style={{
                          height: 180,
                          background: "#393939",
                          borderRadius: 8,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSelectedMessage(message);
                          setIsPreviewOpen(true);
                        }}
                      >
                        {" "}
                        <video
                          style={{
                            maxHeight: "180px",
                            maxWidth: "300px",
                            padding: "5px 5px 0px 5px",
                          }}
                          controls
                        >
                          <source
                            src={message.file_url}
                            type={message.file_type}
                          ></source>
                        </video>
                      </CardActionArea>
                    ) : (
                      <a
                        href={message.file_url}
                        download={message.file_name}
                        target="_blank"
                      >
                        <Button
                          startIcon={
                            <Iconify
                              icon="iconamoon:file-fill"
                              style={{ color: "#595959" }}
                            />
                          }
                        >
                          <Typography>
                            {" "}
                            {message.file_name ? message.file_name : "File"}
                          </Typography>
                        </Button>
                      </a>
                    )}
                  </div>
                ) : (
                  <Typography
                    style={{
                      margin: "0px 4px",
                      fontSize: "14.4px",
                      wordBreak: "break-word",
                    }}
                  >
                    {message.body}
                  </Typography>
                )}
                <div
                  style={{
                    padding: "0px 5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography className={classes.date}>
                    {moment(message.message_date).format("MM/DD/YY hh:mm A")}
                  </Typography>
                </div>
              </div>
              {message.mouseOver ? (
                <div style={{ display: "grid", textAlign: "center" }}>
                  {message.file_url ? (
                    <Tooltip title={"Open"}>
                      <span>
                        <a
                          href={message.file_url}
                          download={message.file_name}
                          target="_blank"
                        >
                          <Iconify
                            style={{ width: 20, height: 20, color: "#616161" }}
                            icon="majesticons:open"
                          />
                        </a>
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {!selectedHeader.is_blocked &&
                  !selectedHeader.blocked_by_recipient &&
                  selectedHeader.recipient.enable_chat ? (
                    <Tooltip title={"Reply message "}>
                      <span>
                        <IconButton
                          style={{ padding: 5 }}
                          onClick={() => {
                            setRespondsToMessage(message);
                            if (inputRef.current) {
                              inputRef.current.focus();
                            }
                          }}
                        >
                          <Iconify
                            style={{ width: 20, height: 20 }}
                            icon="lucide:reply"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }
    });
  };

  const handleClickMenuOptions = (e) => {
    setAnchorElMenuOptions(e.currentTarget);
  };

  const handleCloseMenuOptions = () => {
    setAnchorElMenuOptions(null);
  };

  useEffect(() => {
    if (getMessagesNeeded) {
      setSelectedMessageID(null);
      setUserIsTyping(false);
      setMessages([]);
      messagesPage.current = 0;
      getMessages([]);
      setMessage("");
      setRespondsToMessage(null);
    }
  }, [getMessagesNeeded]);

  useEffect(() => {
    if (selectedMessageID) {
      setUserIsTyping(false);
      setMessages([]);
      messagesPage.current = 0;
      getMessages([], selectedMessageID);
      setMessage("");
      setRespondsToMessage(null);
    }
  }, [selectedMessageID]);

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleClickOpenPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const renderUserStatus = (status) => {
    var icon =
      status == "available"
        ? { icon: "pepicons-pop:circle-filled", color: "#0ba735" }
        : status == "busy"
        ? { icon: "ic:round-do-not-disturb-on", color: "rgb(213 21 47)" }
        : status == "lunch"
        ? { icon: "material-symbols:timer-outline-rounded", color: "#09569b" }
        : {
            icon: "material-symbols:desktop-access-disabled-outline-rounded",
            color: "#505f6d",
          };
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "8px",
          padding: "0px 6px",
          background: "white",
          borderRadius: 10,
          border: "1px solid #80808036",
        }}
      >
        <Iconify
          icon={icon.icon}
          style={{ color: icon.color, width: 15, height: 15, marginRight: 2 }}
        />
        <Typography style={{ fontSize: 12, color: "#797979", fontWeight: 600 }}>
          {status.replaceAll("-", " ").replace(/^\w/, (c) => c.toUpperCase())}
        </Typography>
      </div>
    );
  };

  const getUserInfo = async (id) => {
    setLoadingUserInfo(true);
    const { data } = await Api.getUser(id);

    setOpenedUser({ ...data.data });
    setLoadingUserInfo(false);
  };

  const typingListener = (data) => {
    if (
      selectedHeader &&
      selectedHeader.conversation_id == data.conversation_id
    ) {
      setUserIsTyping(data.typing);
    } else {
      setUserIsTyping(false);
    }
  };

  const messageReceivedListener = (data) => {
    if (data.header && data.header.data) {
      if (
        selectedHeader &&
        selectedHeader.id &&
        data.header.data.id == selectedHeader.id
      ) {
        setSelectedHeader({
          ...selectedHeader,
          ...{ ...data.header.data, ...{ unread_messages: 0 } },
        });

        if (data?.header?.data?.unread_messages || false) {
          const indexTotal = findIndex(totalUnreadChatMessages, [
            "model_type",
            "App\\Models\\User",
          ]);
          const newTotal =
            totalUnreadChatMessages[indexTotal].total -
            data.header.data.unread_messages;
          const updatedEventTotal = {
            ...totalUnreadChatMessages[indexTotal],
            ...{
              total: newTotal < 0 ? 0 : newTotal,
            },
          };

          let newTotals = [...totalUnreadChatMessages];
          newTotals[indexTotal] = updatedEventTotal;

          var generalTotal = generalTotalUnreadChatMessages - data.header.data.unread_messages;
          dispatch(
            updateGeneralTotalUnreadChatMessages(
              generalTotal > 0 ? generalTotal : 0
            )
          );
          dispatch(updateTotalUnreadChatMessages(newTotals));
        }

        if (data.message && data.message.data) {
          Api.viewChatMessage(data.message.data.id);
          setMessages([
            { ...data.message.data, ...{ direction: "in" } },
            ...messages,
          ]);
        }
      }

      const index = findIndex(headers, ["id", data.header.data.id]);

      if (index >= 0) {
        const updatedEvent = {
          ...headers[index],
          ...data.header.data,
        };

        if (
          selectedHeader &&
          selectedHeader.id &&
          data.header.data.id == selectedHeader.id
        ) {
          updatedEvent["unread_messages"] = 0;
        }

        let newHeaders = [...headers];
        newHeaders[index] = updatedEvent;
        newHeaders = newHeaders.sort((a, b) => {
          if (b.is_pinned - a.is_pinned !== 0) {
            return b.is_pinned - a.is_pinned;
          }

          if (a.is_pinned && b.is_pinned) {
            return new Date(b.pinned_at) - new Date(a.pinned_at);
          }

          return (
            new Date(b.conversation.data.last_message_date) -
            new Date(a.conversation.data.last_message_date)
          );
        });
        setHeaders([...newHeaders]);
      } else {
        let newHeaders = [data.header.data, ...headers];

        newHeaders = newHeaders.sort((a, b) => {
          if (b.is_pinned - a.is_pinned !== 0) {
            return b.is_pinned - a.is_pinned;
          }

          if (a.is_pinned && b.is_pinned) {
            return new Date(b.pinned_at) - new Date(a.pinned_at);
          }

          return (
            new Date(b.conversation.data.last_message_date) -
            new Date(a.conversation.data.last_message_date)
          );
        });
        setHeaders([...newHeaders]);
      }
    }
  };

  const messageViewedListener = (data) => {
    if (
      selectedHeader &&
      selectedHeader.id &&
      data.conversation_id == selectedHeader.conversation_id
    ) {
      setMessages([
        ...messages.map((message) => {
          if (
            message.id <= data.message_id &&
            message.from_user_id == authUser.id &&
            !message.view
          ) {
            return { ...message, ...{ view: true } };
          } else {
            return message;
          }
        }),
      ]);
      if (
        selectedHeader.conversation &&
        selectedHeader.conversation.data &&
        selectedHeader.conversation.data.lastMessage &&
        selectedHeader.conversation.data.lastMessage.data &&
        selectedHeader.conversation.data.lastMessage.data.id == data.message_id
      ) {
        var tempHeader = { ...selectedHeader };
        tempHeader["conversation"]["data"]["lastMessage"]["data"][
          "view"
        ] = true;

        setSelectedHeader({ ...tempHeader });
      }
    }

    setLastConversationMessageAsViewed(data.conversation_id, data.message_id);
  };

  const setLastConversationMessageAsViewed = (conversation_id, id) => {
    const indexHeader = findIndex(headers, [
      "conversation_id",
      conversation_id,
    ]);

    var tempHeader = { ...headers[indexHeader] };

    if (
      tempHeader.conversation &&
      tempHeader.conversation.data &&
      tempHeader.conversation.data.lastMessage &&
      tempHeader.conversation.data.lastMessage.data &&
      tempHeader.conversation.data.lastMessage.data.id == id
    ) {
      tempHeader["conversation"]["data"]["lastMessage"]["data"]["view"] = true;

      const updatedEventHeader = {
        ...headers[indexHeader],
        ...tempHeader,
      };

      let newHeaders = [...headers];
      newHeaders[indexHeader] = updatedEventHeader;
      newHeaders = newHeaders.sort((a, b) => {
        if (b.is_pinned - a.is_pinned !== 0) {
          return b.is_pinned - a.is_pinned;
        }

        if (a.is_pinned && b.is_pinned) {
          return new Date(b.pinned_at) - new Date(a.pinned_at);
        }

        return (
          new Date(b.conversation.data.last_message_date) -
          new Date(a.conversation.data.last_message_date)
        );
      });
      setHeaders([...newHeaders]);
    }
  };

  const onBlockedUserEvent = (data) => {
    const index = findIndex(headers, ["conversation_id", data.conversation_id]);
    const updatedEvent = {
      ...headers[index],
      ...{ blocked_by_recipient: data.blocked ? true : false },
    };

    if (
      selectedHeader &&
      selectedHeader.conversation_id &&
      data.conversation_id == selectedHeader.conversation_id
    ) {
      setSelectedHeader({ ...selectedHeader, ...updatedEvent });
    }

    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }

      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }

      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
  };

  const onHeaderUpdatedReceived = (data) => {
    if (data.header && data.header.data) {
      if (
        selectedHeader &&
        selectedHeader.id &&
        data.header.data.id == selectedHeader.id
      ) {
        setSelectedHeader({ ...selectedHeader, ...data.header.data });
      }

      const index = findIndex(headers, ["id", data.header.data.id]);

      if (index >= 0) {
        const updatedEvent = {
          ...headers[index],
          ...data.header.data,
        };

        let newHeaders = [...headers];
        newHeaders[index] = updatedEvent;
        newHeaders = newHeaders.sort((a, b) => {
          if (b.is_pinned - a.is_pinned !== 0) {
            return b.is_pinned - a.is_pinned;
          }

          if (a.is_pinned && b.is_pinned) {
            return new Date(b.pinned_at) - new Date(a.pinned_at);
          }

          return (
            new Date(b.conversation.data.last_message_date) -
            new Date(a.conversation.data.last_message_date)
          );
        });
        setHeaders([...newHeaders]);
      }
    }
  };

  useEffect(() => {
    if (
      authUser &&
      authUser.organization &&
      authUser.organization.data &&
      authUser.organization.data.enable_websockets &&
      window.Echo
    ) {
      window.Echo.channel("user_to_user_chat." + authUser.id).listen(
        ".user_typing",
        typingListener
      );

      window.Echo.channel("user_to_user_chat." + authUser.id).listen(
        ".on_message",
        messageReceivedListener
      );

      window.Echo.channel("user_to_user_chat." + authUser.id).listen(
        ".on_message_viewed",
        messageViewedListener
      );

      window.Echo.channel("user_to_user_chat." + authUser.id).listen(
        ".blocked_user",
        onBlockedUserEvent
      );

      window.Echo.channel("user_to_user_chat." + authUser.id).listen(
        ".header_updated",
        onHeaderUpdatedReceived
      );

      return () => {
        window.Echo.channel("user_to_user_chat." + authUser.id).stopListening(
          ".user_typing",
          typingListener
        );
        window.Echo.channel("user_to_user_chat." + authUser.id).stopListening(
          ".on_message",
          messageReceivedListener
        );
        window.Echo.channel("user_to_user_chat." + authUser.id).stopListening(
          ".on_message_viewed",
          messageViewedListener
        );
        window.Echo.channel("user_to_user_chat." + authUser.id).stopListening(
          ".blocked_user",
          onBlockedUserEvent
        );
        window.Echo.channel("user_to_user_chat." + authUser.id).stopListening(
          ".header_updated",
          onHeaderUpdatedReceived
        );
      };
    }
  }, [
    authUser,
    typingListener,
    messageReceivedListener,
    messageViewedListener,
    onBlockedUserEvent,
    onHeaderUpdatedReceived,
  ]);

  return (
    <div>
      {!selectedHeader ? (
        <div className={classes.noHeaderContainer}>
          <div className={classes.noHeaderMessage}>
            <div style={{ textAlign: "center" }}>
              <Iconify
                className={classes.selectUserIcon}
                icon={"streamline:chat-two-bubbles-oval-solid"}
              />
              <Typography className={classes.selectUserMessage}>
                Select a user to start chatting
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "7px", position: "relative" }}>
          {loadingMessages ? (
            <div
              style={{
                position: "absolute",
                top: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 8,
                paddingTop: 12,
                zIndex: 100,
                width: "100%",
              }}
            >
              <div
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 2px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 37,
                  padding: "10px",
                }}
              >
                {" "}
                <Iconify
                  className={classes.loadingIconConv}
                  style
                  icon="eos-icons:bubble-loading"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              background: "rgb(251, 251, 251)",
              display: "flex",
              alignItems: "center",
              padding: 4,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "rgb(164 174 185 / 30%)",
                  width: 36,
                  height: 36,
                  marginRight: 10,
                  marginLeft: 6,
                }}
                src={selectedHeader?.recipient?.avatar || null}
              >
                <Iconify
                  style={{ color: "#616a8d" }}
                  icon={
                    selectedHeader.recipient_deleted
                      ? "mingcute:ghost-fill"
                      : selectedHeader.recipient &&
                        selectedHeader.recipient.gender == "F"
                      ? "formkit:avatarwoman"
                      : "formkit:avatarman"
                  }
                />
              </Avatar>
              <div>
                {selectedHeader.recipient_deleted ? (
                  <Typography className={classes.headerNameDisabled}>
                    {"Deleted user"}
                  </Typography>
                ) : (
                  <Typography
                    onClick={(e) => {
                      handleClickOpenPopover(e);
                      getUserInfo(selectedHeader.recipient.id);
                    }}
                    className={classes.headerName}
                  >
                    {selectedHeader.recipient
                      ? (selectedHeader?.recipient?.fname || "") +
                        " " +
                        (selectedHeader?.recipient?.lname || "")
                      : "Unknown user"}
                  </Typography>
                )}

                {userIsTyping ? (
                  <div className={classes.typingContainer}>{"Typing..."}</div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: "rgb(129 129 129)",
                      }}
                    >
                      {selectedHeader &&
                      selectedHeader.recipient &&
                      selectedHeader.recipient.position
                        ? selectedHeader.recipient.position.replace(
                            /^\w/,
                            (c) => c.toUpperCase()
                          )
                        : ""}
                    </Typography>
                    {selectedHeader.recipient &&
                    selectedHeader.recipient.user_status
                      ? renderUserStatus(selectedHeader.recipient.user_status)
                      : ""}
                  </div>
                )}
              </div>
            </div>
            {selectedHeader && selectedHeader.id ? (
              <IconButton onClick={handleClickMenuOptions}>
                <Iconify
                  className={classes.mediumIcon}
                  icon="mingcute:more-2-fill"
                />
              </IconButton>
            ) : (
              ""
            )}
          </div>

          <div
            ref={messagesContainerRef}
            className={classes.messagesContainer}
            style={{
              minHeight:
                selectedHeader.is_blocked ||
                selectedHeader.blocked_by_recipient ||
                selectedHeader.recipient_deleted ||
                (selectedHeader.recipient &&
                  !isEmpty(selectedHeader.recipient) &&
                  !selectedHeader.recipient.enable_chat)
                  ? customHeight
                    ? (83 - customHeight).toString() + "vh"
                    : "83vh"
                  : respondsToMessage
                  ? customHeight
                    ? (71 - customHeight).toString() + "vh"
                    : "71vh"
                  : customHeight
                  ? (79 - customHeight).toString() + "vh"
                  : "79vh",
              maxHeight:
                selectedHeader.is_blocked ||
                selectedHeader.blocked_by_recipient ||
                selectedHeader.recipient_deleted ||
                (selectedHeader.recipient &&
                  !isEmpty(selectedHeader.recipient) &&
                  !selectedHeader.recipient.enable_chat)
                  ? customHeight
                    ? (83 - customHeight).toString() + "vh"
                    : "83vh"
                  : respondsToMessage
                  ? customHeight
                    ? (71 - customHeight).toString() + "vh"
                    : "71vh"
                  : customHeight
                  ? (79 - customHeight).toString() + "vh"
                  : "79vh",
              display: "flex",
              flexDirection: "column-reverse",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            id={"scrollableDiv1"}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={() => {
                if (messages.length != 0) {
                  getMessages(messages);
                }
              }}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              inverse={true} //
              initialScrollY={0}
              hasMore={totalMessages != messages.length ? true : false}
              scrollableTarget={"scrollableDiv1"}
            >
              {renderMessages()}
            </InfiniteScroll>
          </div>
          {selectedHeader.is_blocked ? (
            <div style={{ textAlign: "center", marginTop: 12 }}>
              <Button
                onClick={() => {
                  blockUser();
                }}
                className={classes.blockAlertButton}
                startIcon={<Iconify icon="ic:baseline-block" />}
              >
                You blocked this user. Click to unblock.
              </Button>
            </div>
          ) : selectedHeader.blocked_by_recipient ? (
            <div style={{ textAlign: "center", marginTop: 12 }}>
              <div className={classes.blockAlert}>
                <Iconify
                  style={{ width: 19, height: 19, marginRight: 5 }}
                  icon="fluent:chat-off-20-filled"
                />
                This user has blocked you. You can't communicate with him.
              </div>
            </div>
          ) : (selectedHeader.recipient &&
              !isEmpty(selectedHeader.recipient) &&
              !selectedHeader.recipient.enable_chat) ||
            selectedHeader.recipient_deleted ? (
            <div style={{ textAlign: "center", marginTop: 12 }}>
              <div className={classes.disabledChatAlert}>
                <Iconify
                  style={{ width: 19, height: 19, marginRight: 5 }}
                  icon="fluent:chat-off-20-filled"
                />
                {selectedHeader.recipient_deleted
                  ? "This user has been deleted. You can't communicate with him."
                  : "This user has disabled chat. You can't communicate with him"}
              </div>
            </div>
          ) : (
            <div className={classes.writeMessageContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Send file">
                  <span>
                    <Controls.DropzoneDialogIcon
                      formControlStyle={{ margin: 0 }}
                      className={classes.pictureActionButton}
                      filesLimit={1}
                      formats={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "video/mp4",
                      ]}
                      icon={
                        <Iconify
                          style={{ color: "#4f536b" }}
                          icon="material-symbols:upload-rounded"
                        />
                      }
                      color="secondary"
                      onChange={async (files) => {
                        sendMessage({
                          file: await fileToBase64(files[0]),
                          file_url: URL.createObjectURL(files[0]),
                          file_name: files[0].name,
                          file_type: files[0].type,
                        });
                      }}
                    />
                  </span>
                </Tooltip>

                <div style={{ width: "100%", margin: "18px 0px" }}>
                  {respondsToMessage ? (
                    <div className={classes.textFieldRespondsToHeader}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 6px",
                          borderLeft: "2px solid #61616180",
                          margin: "4px 0px",
                        }}
                      >
                        {respondsToMessage.file_url ? (
                          respondsToMessage.file_type &&
                          respondsToMessage.file_type.includes("image") ? (
                            <img
                              style={{
                                maxWidth: 35,
                                maxHeight: 35,
                                marginRight: 12,
                              }}
                              src={respondsToMessage.file_url}
                            />
                          ) : respondsToMessage.file_type &&
                            respondsToMessage.file_type.includes("video") ? (
                            <video
                              style={{
                                maxWidth: 35,
                                maxHeight: 35,
                                marginRight: 12,
                              }}
                              src={respondsToMessage.file_url}
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                style={{
                                  backgroundColor: "rgb(164 174 185 / 30%)",
                                  width: 15,
                                  height: 15,
                                  marginRight: 8,
                                }}
                                src={
                                  respondsToMessage?.fromUser?.data?.avatar ||
                                  null
                                }
                              >
                                <Iconify
                                  style={{ color: "#616a8d" }}
                                  icon={
                                    respondsToMessage.fromUser &&
                                    respondsToMessage.fromUser.data &&
                                    respondsToMessage.fromUser.data.gender ==
                                      "F"
                                      ? "formkit:avatarwoman"
                                      : "formkit:avatarman"
                                  }
                                />
                              </Avatar>
                              <Typography
                                style={{ fontWeight: 700, fontSize: 12 }}
                              >
                                {respondsToMessage.from_user_full_name}
                              </Typography>
                            </div>
                            <IconButton
                              onClick={() => {
                                setRespondsToMessage(null);
                              }}
                              style={{ padding: 6 }}
                            >
                              <Iconify
                                style={{ width: 20, height: 20 }}
                                icon="majesticons:close"
                              />
                            </IconButton>
                          </div>

                          <div>
                            {respondsToMessage.file_url ? (
                              respondsToMessage.file_type &&
                              respondsToMessage.file_type.includes("image") ? (
                                <Typography
                                  style={{ color: "gray", fontStyle: "italic" }}
                                >
                                  Sent an image
                                </Typography>
                              ) : respondsToMessage.file_type &&
                                respondsToMessage.file_type.includes(
                                  "video"
                                ) ? (
                                <Typography
                                  style={{ color: "gray", fontStyle: "italic" }}
                                >
                                  Sent a video
                                </Typography>
                              ) : (
                                <div
                                  style={{
                                    maxWidth: "fit-content",
                                    padding: "2px 10px",
                                    background: "#e0e1e5c7",
                                    borderRadius: "11px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Iconify
                                    style={{ color: "#6d747b", marginRight: 4 }}
                                    icon="iconamoon:file-fill"
                                  />
                                  {respondsToMessage.file_name}
                                </div>
                              )
                            ) : (
                              <Typography
                                className={classes.respondsToTextFieldText}
                              >
                                {" "}
                                {respondsToMessage.body}{" "}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Controls.Textarea
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (!isEmpty(e.target.value)) {
                          if (e.target.value.trim()) {
                            sendMessage(null);
                          }
                        }
                      } else if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();

                        setMessage((prevValue) => {
                          const start = prevValue.substr(0, selectionStart);
                          const end = prevValue.substr(
                            selectionStart,
                            prevValue.length
                          );

                          return selectionStart >= 0
                            ? start + "\n" + end
                            : prevValue + "\n";
                        });
                      }
                    }}
                    className={
                      respondsToMessage
                        ? classes.textfieldWithRespondsTo
                        : classes.textfield
                    }
                    inputRef={inputRef}
                    onSelect={updateSelectionStart}
                    value={message}
                    onChange={(e) => {
                      handleTyping();
                      setMessage(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Add emoji">
                          <span>
                            <IconButton
                              onClick={(e) => {
                                setAnchorElEmojiPopover(e.currentTarget);
                              }}
                            >
                              <Iconify
                                style={{ color: "#4f536b" }}
                                icon="fluent:emoji-24-regular"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    }}
                    rows={3}
                    fullWidth
                    placeholder="Type something..."
                  />{" "}
                </div>

                <Tooltip title="Send message">
                  <span>
                    <IconButton
                      onClick={sendMessage}
                      disabled={!message || !trim(message)}
                      style={{
                        color:
                          !message || !trim(message)
                            ? "rgb(180 183 185)"
                            : "#424d8d",
                      }}
                    >
                      <Iconify icon="ion:send" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorElMenuOptions}
        open={Boolean(anchorElMenuOptions)}
        onClose={handleCloseMenuOptions}
      >
        <MenuItem onClick={pinChat}>
          <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
            <Iconify
              style={{ width: 18, height: 18 }}
              icon={
                selectedHeader && selectedHeader.is_pinned
                  ? "fluent:pin-off-24-filled"
                  : "fluent:pin-12-filled"
              }
            />
          </ListItemIcon>
          <Typography>
            {selectedHeader && selectedHeader.is_pinned
              ? "Unpin chat"
              : "Pin chat"}
          </Typography>
        </MenuItem>
        {selectedHeader && !selectedHeader.recipient_deleted ? (
          <MenuItem onClick={blockUser}>
            <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
              <Iconify
                style={{ width: 18, height: 18 }}
                icon={"ic:baseline-block"}
              />
            </ListItemIcon>
            <Typography>
              {selectedHeader && selectedHeader.is_blocked
                ? "Unblock user"
                : "Block user"}
            </Typography>
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>

      <Popover
        id={idEmojiPopoverOpen}
        open={emojiPopoverOpened}
        anchorEl={anchorElEmojiPopover}
        onClose={() => setAnchorElEmojiPopover(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Picker onEmojiClick={onEmojiClick} />
      </Popover>

      <Popup
        fullWidth={true}
        title={
          selectedMessage && selectedMessage.file_name
            ? selectedMessage.file_name
            : "Message"
        }
        openPopup={isPreviewOpen}
        onClose={() => {
          setSelectedMessage(null);
          setIsPreviewOpen(false);
        }}
        maxWidth={"md"}
      >
        <ScrollBar style={{ maxHeight: 740 }}>
          {selectedMessage && selectedMessage.file_url ? (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                padding: 15,
                boxShadow: "0 0 14px 0 rgba(53,64,82,.09)",
              }}
            >
              {selectedMessage.file_type.includes("image") ? (
                <div>
                  <img
                    style={{ maxWidth: 600, maxHeight: 600 }}
                    src={selectedMessage.file_url}
                  />
                </div>
              ) : selectedMessage.file_type.includes("video") ? (
                <video
                  style={{
                    maxHeight: "600px",
                    maxWidth: "600px",
                    padding: "5px 5px 0px 5px",
                  }}
                  controls
                >
                  <source
                    src={selectedMessage.file_url}
                    type={selectedMessage.file_type}
                  ></source>
                </video>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </ScrollBar>
      </Popup>
      <Popover
        id={popoverID}
        open={openPopover}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "2px 10px" }}>
          {loadingUserInfo ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px 8px",
              }}
            >
              <Iconify
                className={classes.loadingIcon}
                style
                icon="eos-icons:bubble-loading"
              />
              <Typography className={classes.loadingTitle}>
                Loading information
              </Typography>
            </div>
          ) : (
            <>
              {" "}
              <div className={classes.userInformationRowContainer}>
                <Typography className={classes.userInformationRowTitle}>
                  Building:{" "}
                </Typography>
                <Typography>{openedUser?.building_name || "-"} </Typography>
              </div>
              <div className={classes.userInformationRowContainer}>
                <Typography className={classes.userInformationRowTitle}>
                  Department:{" "}
                </Typography>
                <Typography>{openedUser?.department_name || "-"} </Typography>
              </div>
              <div
                className={classes.userInformationRowContainer}
                style={{ borderBottom: "none" }}
              >
                <Typography className={classes.userInformationRowTitle}>
                  Position:{" "}
                </Typography>
                <Typography>
                  {openedUser?.position?.replace(/^\w/, (c) =>
                    c.toUpperCase()
                  ) || "-"}{" "}
                </Typography>
              </div>
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default UserToUserConversation;
