import React, { useEffect, useState } from "react";
import { Button, Chip, TablePagination, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import Notification from "./Notification";
import Api from "../lib/api";
import moment from "moment/moment";
import { findIndex } from "lodash";
import { green, grey, red } from "@material-ui/core/colors";
import Popup from "./Popup";
import TransferProcedureForm from "./forms/TransferProcedureForm";
import Iconify from "./Iconify";
import MultiTransferProceduresForm from "./forms/MultiTransferProceduresForm";
import CRMUtils from "../utils";
import { useSelector } from "react-redux";

const EMRTransferRequests = (props) => {
  const { recordID } = props;
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [paginator, setPaginator] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [transferPopup, setTransferPopup] = useState(false);
  const rowsPerPage = 5;
  const [transferForEdit, setTransferForEdit] = useState(null);
  const { authUser } = useSelector((state) => state.auth);

  const userCanTransfer = CRMUtils.userHasPermission(authUser, [
    "recordproduct_transfer",
  ]);

  const userCanSuperTransfer = CRMUtils.userHasPermission(authUser, [
    "recordproduct_supertransfer",
  ]);
  const [coordinatorFromDoctor, setCoordinatorFromDoctor] = useState(false);
  const [coordinatorToDoctor, setCoordinatorToDoctor] = useState(false);
  const [userDoctors, setUserDoctors] = useState([]);

  const statusArray = [
    {
      key: "pending",
      label: "Pending",
      color: "rgb(238 248 193)",
    },
    {
      key: "approved",
      label: "Approved",
      color: "rgb(197 248 199)",
    },
    {
      key: "declined",
      label: "Declined",
      color: "rgb(244 212 212)",
    },
  ];

  const getStatus = (status) => {
    const index = findIndex(statusArray, ["key", status]);
    if (index) {
      return statusArray[index];
    }

    return statusArray[0];
  };

  const columns = [
    {
      field: "created",
      title: "Created at",
      sorting: false,
      render: (rowData) => {
        return new moment(rowData.created_at).format("MM-DD-YYYY");
      },
    },
    { field: "createdBy_fullname", title: "Created by", sorting: false },

    {
      field: "status",
      title: "Status",
      sorting: false,
      render: (rowData) => {
        return (
          <Chip
            label={getStatus(rowData.status).label}
            style={{ background: getStatus(rowData.status).color }}
          />
        );
      },
    },
    {
      field: "closedBy_fullname",
      title: "Closed by",
      sorting: false,
    },
    {
      field: "approved_at",
      title: "Closed at",
      sorting: false,
      render: (rowData) => {
        return rowData.approved_at
          ? new moment(rowData.approved_at).format("MM-DD-YYYY")
          : "";
      },
    },
    {
      field: "coordinator",
      title: "To Coordinator",
      sorting: false,
      render: (rowData) => {
        return (
          <div>
            {/* <Typography>
              <strong>From: </strong>{" "}
              {` ${rowData.old_coordinator_fullname || "None"}`}
            </Typography> */}
            <Typography>
              {/* <strong>To: </strong>{" "} */}
              {` ${rowData.coordinator_fullname || "None"}`}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "responsible",
      title: "To Surgeon",
      sorting: false,
      render: (rowData) => {
        return (
          <div>
            {/* <Typography>
              <strong>From: </strong>{" "}
              {` ${rowData.old_responsible_fullname || "None"}`}
            </Typography> */}
            <Typography>
              {/* <strong>To: </strong>{" "} */}
              {` ${rowData.responsible_fullname || "None"}`}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "building",
      title: "To Building",
      sorting: false,
      render: (rowData) => {
        return (
          <div>
            {/* <Typography>
              <strong>From: </strong>{" "}
              {` ${rowData.old_building_name || "None"}`}
            </Typography> */}
            <Typography>
              {/* <strong>To: </strong>  */}
              {` ${rowData.building_name || "None"}`}
            </Typography>
          </div>
        );
      },
    },
  ];

  const getDoctorsUser = async () => {
    const { data } = await Api.getUserDoctors(authUser.id);

    setUserDoctors(data.data);
  };

  const recordProductsColumns = [{ field: "item_name", title: "Name" }];

  //Get Requests fucntion
  const getTransferRequests = async () => {
    setIsLoading(true);
    try {
      const { data } = await Api.getRecordTransferRequests(
        recordID,
        page + 1,
        rowsPerPage
      );
      if (data) {
        setRequests(data.data);
        setPaginator(data.meta.pagination);
      }
    } catch (error) {
      if (error.status == 401) {
        setNotify({
          isOpen: true,
          message: "Forbidden",
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const doctorTransfer = (responsibles) => {
    if (userDoctors.find((u) => responsibles.includes(u.id))) {
      return true;
    } else {
      return false;
    }
  };

  const AcceptTransferDoctor = (transferResponsibleID) => {
    const index = findIndex(userDoctors, ["id", transferResponsibleID]);

    return index >= 0 ? true : false;
  };

  //Fetch requests each time the table page changes
  useEffect(() => {
    getTransferRequests();
  }, [page]);

  useEffect(() => {
    getDoctorsUser();
  }, []);

  const detailPanel = (rowData) => {
    return (
      <MaterialTable
        columns={recordProductsColumns}
        data={rowData.siblings}
        title=""
        options={{
          showTitle: false,
          search: false,
          toolbar: false,
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          headerStyle: {
            backgroundColor: "rgb(201 213 219 / 34%)",
            color: "#00695c",
          },
          paging: false,
        }}
        toolbar={false}
        showPaging={true}
        pageSize={5}
      />
    );
  };

  const closeTransferPopup = () => {
    setTransferPopup(false);
  };

  const editTransfer = (row) => {
    setTransferPopup(true);
    setTransferForEdit(row);
  };

  const onCloseRequest = (data) => {
    const index = findIndex(
      requests,
      data.transfer_token
        ? ["transfer_token", data.transfer_token]
        : ["id", data.id]
    );
    const updatedEvent = {
      ...requests[index],
      ...data,
    };

    let newRequests = [...requests];
    newRequests[index] = updatedEvent;
    setRequests([...newRequests]);
    closeTransferPopup();
  };

  return (
    <>
      <MaterialTable
        // title={title ? title : null}
        columns={columns}
        data={requests}
        // style={{ marginTop: title ? "" : -40 }}
        isLoading={isLoading}
        options={{
          actionsColumnIndex: -1,
          toolbar: false,
          showTitle: false,
          search: false,
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [],
          emptyRowsWhenPaging: false,
        }}
        detailPanel={detailPanel}
        components={{
          //Custom Pagination
          Pagination: (defaultProps) => (
            <TablePagination
              {...defaultProps}
              count={paginator?.total ? paginator.total : defaultProps.count}
              page={page ? page : defaultProps.page}
              rowsPerPage={rowsPerPage ? rowsPerPage : defaultProps.rowsPerPage}
              onChangePage={(e, newPage) => {
                setPage(newPage);
                //defaultProps.onChangePage(e, newPage);
              }}
              /*
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(event.target.value);
            }}
            */
            />
          ),
        }}
        actions={[
          {
            action: (rowData) => {
              return {
                icon: () => (
                  <Iconify
                    style={{ color: "#196cb4", width: 25, height: 25 }}
                    icon="mdi:list-status"
                  />
                  //   <div style={{ fontSize: 14 }}>Accept / Decline</div>
                ),
                tooltip: "Accept or decline transfer",
                hidden:
                  rowData.status !== "pending" ||
                  (!userCanSuperTransfer &&
                    !(
                      AcceptTransferDoctor(rowData.responsible_id) &&
                      userCanTransfer
                    ) &&
                    !(
                      doctorTransfer(
                        rowData.siblings.map((s) => s.responsible_id)
                      ) && userCanTransfer
                    )),
                position: "row",
                onClick: (event, row) => {
                  editTransfer(row);
                },
              };
            },
          },
        ]}
      />
      <Notification notify={notify} setNotify={setNotify} />

      <Popup
        title={`Close transfer request`}
        fullWidth={true}
        openPopup={transferPopup}
        onClose={closeTransferPopup}
      >
        <MultiTransferProceduresForm
          transferForEdit={transferForEdit}
          onCloseRequest={onCloseRequest}
        />
      </Popup>
    </>
  );
};

export default EMRTransferRequests;
