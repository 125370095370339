import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import AnesthesiologistForm from "./forms/AnesthesiologistForm";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";

import MaterialTable from "material-table";
import { Paper, Fab } from "@material-ui/core";
import Popup from "./Popup";

import { useTranslation } from "react-i18next";
import Api from "../lib/api";
import { async } from "@firebase/util";
import Notification from "./Notification";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));
function RecordProductAnesthesiologist(props) {
  const { authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anesthesiologist, setAnesthesiologist] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const { usersDropdown } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation(["common", "records"]);

  const [anesthesiologistsDropdown, setAnesthesiologistsDropdown] = useState(
    []
  );

  const {
    record_id,
    onSave,
    onDeleteAnesthesiologist,
    coordinatorsFromDoctor,
    recordProductdatas,
  } = props;

  const dataMaterialTable = anesthesiologist
    ? anesthesiologist.map((o) => ({ ...o }))
    : [];

  const headCellsMaterial = [
    { field: "title", title: "Name" },
    { field: "position", title: "Position" },
    { field: "email", title: "email" },
    { field: "department_name", title: "Department" },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [noAdd, setNoAdd] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onDelete = async (id) => {
    onDeleteAnesthesiologist(id);
    // console.log("ID deLETE: ", id);
    setAnesthesiologist(anesthesiologist.filter((a) => a.id != id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: t("common:general.Del_Doc_ok"),
      type: "error",
    });
  };

  const saveAnesthesiologist = (info) => {
    const anesthesiologistT = anesthesiologistsDropdown.find(
      (anesthesiologists) => anesthesiologists.id == info.anesthesiologistToAdd
    );

    const doctorConfiguration =
      anesthesiologistT && !isEmpty(anesthesiologistT.doctor_anesthesiologists)
        ? anesthesiologistT.doctor_anesthesiologists.find(
            (anesthesiologistsDoctor) =>
              anesthesiologistsDoctor.doctor_id ===
              recordProductdatas?.responsible_id
          )
        : null;

    const anesthesiologistToAdd = {
      user_id: info.anesthesiologistToAdd,
      anesthesiologist_value: doctorConfiguration
        ? doctorConfiguration.anesthesiologist_value
        : null,
      percent: doctorConfiguration ? doctorConfiguration.percent : null,
    };

    const payload = {
      anesthesiologistToAdd: JSON.stringify(anesthesiologistToAdd),
    };

    onSave(payload).then(async () => {
      //   console.log("ID: ",d);
      const { data } = await Api.getUser(info.anesthesiologistToAdd);
      if (data && data.data) {
        setAnesthesiologist([data.data, ...anesthesiologist]);
        setNotify({
          isOpen: true,
          message: "Created Successfully",
          type: "success",
        });
      }

      onClose();
    });
  };

  const anesthesiologistsOptions = () => {
    return usersDropdown.filter((user) => {
      return user.is_anesthesiologist === 1;
    });
  };

  useEffect(() => {
    if (isEmpty(usersDropdown))
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  const getAnesthesiologists = async () => {
    const { data } = await Api.getAnesthesiologists(authUser.organization_id);

    if (data && data.data) {
      setAnesthesiologistsDropdown(data.data);
    }
  };

  useEffect(() => {
    getAnesthesiologists();
  }, []);

  useEffect(() => {
    if (anesthesiologist && anesthesiologist.length >= 1) {
      setNoAdd(true);
    } else {
      setNoAdd(false);
    }
  }, [anesthesiologist]);

  useEffect(() => {
    if (record_id) {
      setProgressBar(true);
      getAnesthesiologist().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getAnesthesiologist = async () => {
    const { data } = await Api.getAnesthesiologistsRP(record_id);
    setAnesthesiologist([...data.data]);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                ),
                tooltip: "Add Anesthesiologist (Max 1)",
                disabled: noAdd || !coordinatorsFromDoctor,
                hidden: noAdd || !coordinatorsFromDoctor,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton color="secondary">
                    <CloseIcon fontSize="small" />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                disabled: !coordinatorsFromDoctor,
                hidden: !coordinatorsFromDoctor,

                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this Anesthesiologist?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.id),
                  }),
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 3, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Anesthesiologist (Max 1)"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Add Anesthesiologist"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <AnesthesiologistForm
          saveAnesthesiologist={saveAnesthesiologist}
          users={anesthesiologistsOptions()}
          anesthesiologist={anesthesiologist}
          className={classes.form}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RecordProductAnesthesiologist;
