import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import quoteApproved from "../approved.png";
import moment from "moment";
import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
} from "@material-ui/core";
import { isEmpty } from "lodash";

import ContactFormTemplateViewer from "./ContactFormTemplateViewer";
import BallotIcon from "@material-ui/icons/Ballot";
import { useEffect } from "react";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: "block";
  
  width: 100%;
  margin: "10px",
  padding: "5px",
`;

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;
const Approved = styled.img`
  position: absolute;
  width: 450px;
  opacity: 0.4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;
const Logo = styled.img`
  position: absolute;
  width: 150px;
  top: 15px;
  right: 15px;
`;
const QuoteFooter = styled(MuiBox)`
  ${spacing};
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    mainProduct: {},
    childProduct: {
      //marginLeft: "15px",
      paddingLeft: "15px",
      color: "#999",
    },
  },
}));

function PrintQuoteDetails(props) {
  const { quote, contract } = props;

  //console.log(contract);

  const classes = useStyles();

  const checkMainProduct = (id) => {
    //Check that Main product exists
    return quote.items.data
      ? quote.items.data.filter((element) => {
          //return element && element.childProduct && pluck(element.childProduct,'child_product_id').includes(id) === true;
          //console.error(id,element,(element && element.childProducts)?element.childProducts.filter((row)=>{return row.child_product_id == id}).length:"");
          return (
            element &&
            element.childProducts &&
            element.childProducts.filter((row) => {
              return row.child_product_id == id;
            }).length > 0
          );
        })
      : [];
  };

  const renderItems = () => {
    const items = quote.items.data;
    return items.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell
            component="th"
            scope="row"
            className={
              item &&
              item.mainProducts &&
              item.mainProducts.length > 0 &&
              checkMainProduct(item.product_id).length > 0
                ? classes.childProduct
                : classes.mainProduct
            }
          >
            {item.childProducts.length > 0 && <BallotIcon />}
            {checkMainProduct(item.product_id).length > 0
              ? "-------" + item.name
              : item.name}
          </TableCell>
          <TableCell>$ {parseFloat(item.price).toFixed(2)}</TableCell>
          <TableCell>{item.quantity || 1}</TableCell>

          <TableCell align="right">
            $ {calculateItemTotal(item).toFixed(2)}
          </TableCell>
        </TableRow>
      );
    });
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };
  const calculateSubtotal = () => {
    const items = quote.items.data;
    return items.reduce((total, item) => total + calculateItemTotal(item), 0);
  };
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;

    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  // useEffect(() => {
  //   console.log("QUOTE: ", quote);
  //   console.log(
  //     "HTML: ",
  //     contract && contract.contract
  //       ? htmlDecode(contract.contract.html)
  //       : "<p></p>"
  //   );
  // }, [quote]);

  const formatPhoneNumber = (num) => {
    let cleaned = ("" + num).replace(/\D/g, "");
    let formattedNumber = cleaned;

    if (cleaned.length === 10) {
      formattedNumber =
        "(" +
        cleaned.substring(0, 3) +
        ") " +
        cleaned.substring(3, 6) +
        "-" +
        cleaned.substring(6);
    } else if (cleaned.length === 11) {
      formattedNumber =
        "+" +
        cleaned.substring(0, 1) +
        " (" +
        cleaned.substring(1, 4) +
        ") " +
        cleaned.substring(4, 7) +
        "-" +
        cleaned.substring(7);
    } else if (cleaned.length > 11) {
      let extraDigits = cleaned.substring(0, cleaned.length - 10);
      let baseNumber =
        "(" +
        cleaned.substring(cleaned.length - 10, cleaned.length - 7) +
        ") " +
        cleaned.substring(cleaned.length - 7, cleaned.length - 4) +
        "-" +
        cleaned.substring(cleaned.length - 4);
      if (extraDigits.length > 4) {
        formattedNumber = "+" + extraDigits + " " + baseNumber;
      } else {
        formattedNumber = baseNumber + " x" + extraDigits;
      }
    }
    return formattedNumber;
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Logo
            src={
              quote.building_id
                ? quote.building_logo
                : quote.organization.data.logo
            }
          />
          {quote.status === "approved" && <Approved src={quoteApproved} />}
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box fontWeight="fontWeightMedium" m={1}>
                Quote # {quote.name}
              </Box>
              <Box fontWeight="fontWeightMedium" m={1}>
                Quote Date: {moment(quote.date).format("YYYY/MM/DD")}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  FROM:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote.building_id
                    ? quote.building_name
                    : quote.organization_name}
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote.building_id
                    ? quote.building_address
                    : quote.organization_address}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  TO:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote.contact.data.full_name}
                </Box>
                <Box fontWeight="fontWeightMedium" m={1}>
                  {quote.contact.data.address || ""}
                </Box>
                <Box fontWeight="fontWeightMedium" m={1}>
                  {quote && quote.contact.data.phone
                    ? formatPhoneNumber(quote.contact.data.phone)
                    : ""}
                </Box>
                {quote.contact && quote.contact.data.coordinator_name && (
                  <Box m={1}>
                    {" "}
                    Consultant: {quote.contact.data.coordinator_name}
                  </Box>
                )}
                {/*quote.expiration_date && (
                  <Box m={1}> Expiration Date: {quote.expiration_date}</Box>
                )*/}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {quote && !isEmpty(quote.expiration_date) && (
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" m={1}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="see"
                      style={{ float: "right" }}
                      disabled
                    >
                      {"Expire: " + quote.expiration_date}
                    </Fab>
                  </Box>
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <Grid item xs={12}>
          <div
            dangerouslySetInnerHTML={{
              __html:
                contract && contract.contract
                  ? htmlDecode(contract.contract.html)
                  : "<p></p>",
            }}
          ></div>
        </Grid>

        <QuoteFooter></QuoteFooter>
      </Card>
    </Wrapper>
  );
}

export default PrintQuoteDetails;
