import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Api from "../../lib/api";
import { useSelector } from "react-redux";
export default function SelectModel(props) {
  const {
    label,
    allowEmpty,
    onChange,
    disabled,
    variant,
    modelid,
    value,
    ...other
  } = props;

  const [modelrecords, setModelRecords] = useState([]);
  const { authUser } = useSelector((state) => state.auth);

  const fillmodelrecords = async () => {
    try {
      const orgId = authUser.organization_id;
      const modelrecordstemp = await Api.getCustomFieldsRecordsbyModel(
        orgId,
        modelid
      );

      setModelRecords(
        modelrecordstemp.data.data.map((modelrecord) => {
          return {
            id: modelrecord.id,
            title: modelrecord.dropdown_name,
          };
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fillmodelrecords();
  }, []);

  return (
    <FormControl variant={variant ? variant : "outlined"} fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...other}
      >
        {allowEmpty && <MenuItem value={null}>None</MenuItem>}

        {modelrecords
          ? modelrecords.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))
          : ""}
      </MuiSelect>
    </FormControl>
  );
}
