import { TextField } from "@material-ui/core";
import Iconify from "../Iconify";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function InputMoneyOkWithoutController(props) {
  const {
    onChange,
    label,
    maxValue = 999999,
    minValue = 0,
    customInputProps = {},
    defaultValue = "",
    step = "0.01",
    min = "0.01",
    greaterThanZero = true,
    ...other
  } = props;

  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      label={label}
      type="text"
      fullWidth
      defaultValue={defaultValue}
      className={classes.textfield}
      onChange={(event) => {
        if (
          event.target.value == "" ||
          (((greaterThanZero && parseFloat(event.target.value) > 0) ||
            !greaterThanZero) &&
            Number(event.target.value) <= parseFloat(maxValue) &&
            Number(event.target.value) >= parseFloat(minValue) &&
            onChange)
        ) {
          onChange(event.target.value);
        }
      }}
      InputProps={{
        ...{
          classes: { input: classes.textfield },
          startAdornment: (
            <Iconify
              icon={"material-symbols:attach-money"}
              style={{ color: "#616161" }}
              width={20}
              height={20}
            />
          ),
        },
        ...customInputProps,
      }}
      inputProps={{
        pattern: "^[0-9]*[.,]?[0-9]*$",
        inputMode: "numeric",
        step: step,
        min: min,
      }}
      {...other}
    />
  );
}
