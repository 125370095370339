import { ButtonGroup, CircularProgress, Step, StepButton, Stepper } from "@material-ui/core";
import { InvertColors } from "@material-ui/icons";
import { isInteger } from "lodash";
import React, { useEffect } from "react";
import Api from "../../lib/api";
import Button from "../controls/Button";
import Notification from "../Notification";

import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const ButtonStates = (props) => {

    const { currentstate, nextStates, changeStep, updating, showall } = props;

    const handleButtonClick = (index) => {
        if (changeStep) {
            let next_step = nextStates[index];
            changeStep(next_step);
        }
    }
    if (currentstate === null && nextStates == null) {
        return null;
    }

    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }

        // invert color components
        var r = (parseInt(hex.slice(0, 2), 16)),
            g = (parseInt(hex.slice(2, 4), 16)),
            b = (parseInt(hex.slice(4, 6), 16));
        let brightness = r * 0.299 + g * 0.587 + b * 0.114
        // pad each with zeros and return
        if (brightness > 180) {
            return '#' + padZero(r) + padZero(g) + padZero(b);
        }
        else return '#ffffff';
    }

    const padZero = (str, len) => {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    return (
        <ButtonGroup size='small'>
            {nextStates && nextStates.map((stateto, index) => (
                <Button disabled={showall && !stateto.enable && (currentstate == null || stateto.id != currentstate.id)}
                    style={{
                        backgroundColor: !stateto.enable && (currentstate == null || stateto.id != currentstate.id) ? 'gray' : stateto.color,
                        color: !stateto.enable && (currentstate == null || stateto.id != currentstate.id) ? '#DDDDDD' : invertColor(stateto.color)
                    }}
                    key={stateto.code}
                    onClick={() => handleButtonClick(index)}
                    text={updating ? <CircularProgress color='inherit' size={20} /> : stateto.name}
                    endIcon={stateto.enable && (currentstate == null || stateto.id != currentstate.id) ? <PlayArrowIcon /> : null} />
            ))}
        </ButtonGroup>
    );
}


export default ButtonStates;