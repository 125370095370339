import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  buildingsLoading: false,
  errors: [],
  buildings: [],
  fields: [],
};

const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    getBuildings: (state) => {
      state.loading = true;
      state.buildingsLoading = true;
    },
    getBuildingsSuccess: (state, { payload }) => {
      state.buildings = payload.data;
      state.loading = false;
      state.buildingsLoading = false;
      state.errors = [];
    },
    getBuildingsFailure: (state, { payload }) => {
      state.loading = false;
      state.buildingsLoading = false;
      state.errors = [...state.errors, payload.message];
    },
    createBuilding: (state) => {
      state.saveLoading = true;
    },
    createBuildingSuccess: (state, { payload }) => {
      state.buildings = [...state.buildings, payload.data];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    createBuildingFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    updateBuilding: (state) => {},
    updateBuildingsuccess: (state, { payload }) => {
      const index = findIndex(state.buildings, ["id", payload.data.id]);
      const building = { ...state.buildings[index], ...payload.data };

      let buildings = [...state.buildings];
      buildings[index] = building;

      state.buildings = [...buildings];
      state.loading = false;
      state.errors = [];
    },
    updateBuildingFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    deleteBuilding: (state) => {},
    deleteBuildingSuccess: (state, { payload }) => {
      state.buildings = state.buildings.filter((building) => {
        return building.id !== payload;
      });
    },
    deleteBuildingFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getBuildings,
  getBuildingsSuccess,
  getBuildingsFailure,
  createBuilding,
  createBuildingSuccess,
  createBuildingFailure,
  updateBuilding,
  updateBuildingsuccess,
  updateBuildingFailure,
  deleteBuilding,
  deleteBuildingSuccess,
  deleteBuildingFailure,
} = buildingsSlice.actions;
export const buildingSelector = (state) => state.buildings;
export default buildingsSlice.reducer;

export function fetchBuildingsService(orgId) {
  return async (dispatch) => {
    dispatch(getBuildings());

    try {
      const response = await Api.getBuildings(orgId);

      dispatch(getBuildingsSuccess(response.data));
    } catch (error) {
      dispatch(getBuildingsFailure(error));
    }
  };
}

export function createBuildingService(payload) {
  console.log(payload);
  return async (dispatch) => {
    dispatch(createBuilding());
    try {
      const response = await Api.saveBuilding(payload);
      dispatch(createBuildingSuccess(response.data));
    } catch (error) {
      dispatch(createBuildingFailure(error));
    }
  };
}
export function updateBuildingService(id, payload) {
  return async (dispatch) => {
    dispatch(updateBuilding());
    try {
      const response = await Api.updateBuilding(id, payload);
      dispatch(updateBuildingsuccess(response.data));
    } catch (error) {
      dispatch(updateBuildingFailure(error));
    }
  };
}

export function deleteBuildingService(id) {
  return async (dispatch) => {
    dispatch(deleteBuilding());

    try {
      const response = await Api.deleteBuilding(id);

      dispatch(deleteBuildingSuccess(id));
    } catch (error) {
      dispatch(deleteBuildingFailure(error));
    }
  };
}
