import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { isEmpty, findIndex, capitalize } from "lodash";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import Api from "../lib/api";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CRMUtils from "../utils";
import Controls from "./controls";
import {
  Paper,
  Card as MuiCard,
  makeStyles,
  Chip,
  Checkbox,
  Fab,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";
import useTable from "./useTable";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import InvoiceDetails from "../pages/invoices/details";
import InvoiceDetailsDeleted from "../pages/invoices/detailsDeleted";
import Popup from "./Popup";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import PersonIcon from "@material-ui/icons/Person";
import { fetchEntitiesService as fetchProductsService } from "../redux/slices/productsnewSlice.js";

var dataSliceProduct = {
  stateListName: "productsNew",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
import { fetchPaymentGatewaysService } from "../redux/slices/paymentgateways";
import { fetchDocumentTypesService } from "../redux/slices/documenttypes";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import InvoiceBuilder from "./InvoiceBuilder";

import Logs from "./logs";
import InvoicesContactLogs from "./InvoicesContactLogs";
import Iconify from "./Iconify";

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
`;
const statusArray = [
  {
    key: "unsend",
    label: "Unsend",
    color: grey[700],
  },
  {
    key: "sent",
    label: "Sent",
    color: blue[700],
  },
  {
    key: "unpaid",
    label: "Unpaid",
    color: red[700],
  },
  {
    key: "partial_paid",
    label: "Partial paid",
    color: yellow[800],
  },
  {
    key: "paid",
    label: "Paid in full",
    color: green[700],
  },
  {
    key: "canceled",
    label: "Canceled",
    color: grey[900],
  },
];

const Totals = styled.div`
  text-align: right;
  margin: 10px 0 0 10px;
`;

const headCells = [
  { id: "selected", label: "", disableSorting: true },
  { id: "name", label: "Name" },
  { id: "contact", label: "Contact" },
  { id: "user", label: "User" },

  { id: "status", label: "Status" },
  { id: "invoicetotal", label: "Invoice Total" },
  { id: "amountdue", label: "Balance Due" },
];

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {},
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  estrapayment: {
    color: "grey",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "150px",
  },
  childProduct: {
    marginLeft: "35px",
    color: "#999",
  },
}));

function InvoicesContact(props) {
  const {
    fields,
    onSelect,
    selectedInvoice,
    contact,
    userTeamLeader = false,
    rReadonly = false,
    userIsCallCenter = false,
    userIsFrontDesk = false,
    userIsSocialMedia = false,
    consultantErrorHandle = null,
  } = props;
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const { authUser } = useSelector((state) => state.auth);
  dataSliceProduct.orgId = authUser.organization_id;
  const userCanAdd =
    CRMUtils.userHasPermission(authUser, ["invoice_create"]) && !rReadonly;
  const userCanEdit =
    CRMUtils.userHasPermission(authUser, ["invoice_edit"]) && !rReadonly;
  const userCanDelete = CRMUtils.userHasPermission(authUser, [
    "invoice_delete",
  ]);

  const userIsbillingMGAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingMGAssistant",
  ]);
  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  const userCanViewInvoice = CRMUtils.userHasPermission(authUser, [
    "invoice_view",
  ]);
  const readOnlySalesManager = userTeamLeader && !contact.editable;
  const { productsNew, productsNewLoanding } = useSelector(
    (state) => state.productsNew
  );
  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const { usersDropdown, usersDropdownLoanding } = useSelector(
    (state) => state.users
  );
  const { documentTypes } = useSelector((state) => state.documenttypes);
  const [invoicesContact, setInvoicesContact] = useState([]);
  const [progressBar, setProgressBar] = useState(false);

  const [invoicesContactDeleted, setInvoicesContactDeleted] = useState([]);
  const [progressBar2, setProgressBar2] = useState(false);
  const [selected, setSelected] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);

  const [invoiceDetailDeleted, setInvoiceDetailDeleted] = useState(null);
  const [openDetailPopupDeleted, setOpenDetailPopupDeleted] = useState(false);

  const openInvoiceDeleted = async (invoice) => {
    // const { data } = await Api.getInvoiceDeleted(
    //   invoice.id,
    //   "?include=organization"
    // );
    // setInvoiceDetailDeleted(data.data);
    setInvoiceDetailDeleted(invoice);
    setOpenDetailPopupDeleted(true);
  };

  const closePopupDeleted = () => {
    setInvoiceDetailDeleted(null);
    setOpenDetailPopupDeleted(false);
  };

  const onDelete = async (id) => {
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      var deleteInvoice = await Api.deleteInvoice(id).then(() => {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
        setInvoicesContact(invoicesContact.filter((a) => a.id != id));
      });
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };
  const fullHeadCells = [].concat(headCells, [
    { id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ]);
  const openInPopup = (item) => {
    openInvoice(item);
    if (onSelect) onSelect(item);
  };
  const openInvoice = async (invoice) => {
    // const { data } = await Api.getInvoice(invoice.id, "?include=organization");
    // setInvoiceDetail(data.data);
    setInvoiceDetail(invoice);
    setOpenDetailPopup(true);
  };

  const closePopup = () => {
    setOpenDetailPopup(false);
  };


  const refreshInvoices = () => {  
    getInvoicesContact();   
    closePopup();

  };
  const closeNewInvoicePopup = () => {
    setOpenPopup(false);
  };
  const calculateSubtotal = (items) => {
    return items
      .filter((item) => item.void == 0)
      .reduce((total, item) => total + calculateItemTotal(item), 0);
  };
  const calculateTotal = (items) => {
    const subtotal = calculateSubtotal(items);

    return subtotal;
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };

  const calulateTotalPayments = (payments) => {
    const paymentsFiltered = payments.filter((payment) => {
      return !payment.isRefund;
      /* &&
        (payment.possible_refund && isEmpty(payment.paymentPossibleRefund.data)
          ? false
          : true) */
    });

    return paymentsFiltered.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
  };
  const calulateTotalRefunds = (payments) => {
    const refundsFiltered = payments.filter((payment) => {
      return payment.isRefund;
    });

    return refundsFiltered.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
  };
  const calculateTotalDue = (items, payments, credits) => {
    const total = calculateTotal(items);
    const totalPayments = calulateTotalPayments(payments);
    const totalRefunds = calulateTotalRefunds(payments);

    return (
      Math.round(total * 100) / 100 -
      Math.round(totalPayments * 100) / 100 -
      Math.round(credits * 100) / 100 +
      Math.round(totalRefunds * 100) / 100
    );
  };

  const calculateTotalb = () => {
    return invoicesContact
      ? invoicesContact
          .reduce((total, invoice) => total + invoice.total_price, 0)
          .toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      : [];
  };

  const calculateTotalBalanceb = () => {
    return invoicesContact
      ? invoicesContact
          .reduce((total, invoice) => total + invoice.balance, 0)
          .toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      : [];
  };

  //console.log("selectedInvoice:", selectedInvoice);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const saveInvoice = (data) => {
    //   console.log("ITEMS CONTACTS: ", data);
    const invoiceitems = data.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        product_id: item.product_id,
        item_name: item.name,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });

    if (!isEmpty(authUser)) {
      var payload = {
        items: invoiceitems,
        name: `Invoice for ${data.contact_name}`,
        contact_id: data.contact_id ? data.contact_id : data.contact.id,
        user_id: authUser.id,
        organization_id: authUser.organization_id,
        financing_id: data.financing_id,
        interest: data.interest ? data.interest : null,
        pay_before: data.pay_before ? data.pay_before : null,
        building_id:
          authUser.organization.data.multiple_locations === 1 &&
          authUser.building_id
            ? authUser.building_id
            : null,
        coupons: data?.coupons || null,
      };
      console.log("save invoice... ", payload);

      createInvoice(payload).then((result) => {
        setNotify({
          isOpen: true,
          message: "Created Successfully",
          type: "success",
        });
      });
      closeNewInvoicePopup();
    }
  };

  const createInvoice = async (payload) => {
    try {
      const { data } = await Api.saveInvoice(payload);
      getInvoicesContact();
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const { TblContainer } = useTable();
  const getStatus = (status) => {
    const index = findIndex(statusArray, ["key", status]);
    if (index) {
      return statusArray[index];
    }
    return statusArray[0];
  };
  const userCanSeeLogs =
    CRMUtils.userHasRole(authUser, ["admin", "super-admin"]) ||
    CRMUtils.userHasPermission(authUser, ["invoices_viewLogs"]);

  const [openLogPopup, setOpenLogPopup] = useState(false);

  const [logs, setLogs] = useState([]);

  const closeLogPopup = () => {
    setOpenLogPopup(false);
  };

  const showInvoiceLogs = async (row) => {
    const { data } = await Api.getInvoiceLogs(row.id);
    setLogs(data);
    setOpenLogPopup(true);
  };

  const [openLogPopupNew, setOpenLogPopupNew] = useState(false);
  const [logsNew, setLogsNew] = useState(null);

  const closeLogPopupNew = () => {
    setOpenLogPopupNew([]);
    setLogsNew(null);
  };

  const showInvoiceLogsNew = async (row) => {
    const { data } = await Api.showInvoiceLogs(row.id);
    setLogsNew(data.data);
    setOpenLogPopupNew(true);
  };

  const checkMainProduct = (id, items) => {
    //Check that Main product exists
    return items
      ? items.filter((element) => {
          //return element && element.childProduct && pluck(element.childProduct,'child_product_id').includes(id) === true;
          //console.error(id,element,(element && element.childProducts)?element.childProducts.filter((row)=>{return row.child_product_id == id}).length:"");
          return (
            element &&
            element.childProducts &&
            element.childProducts.filter((row) => {
              return row.child_product_id == id;
            }).length > 0
          );
        })
      : [];
  };

  const headCellsMaterial = [
    {
      field: "created_at",
      title: "Date",
      render: (rowData) => {
        return rowData.date ? moment(rowData.date).format("YYYY-MM-DD") : "";
      },
    },
    { field: "name", title: "Name" },
    {
      field: "contact",
      title: "Contact",
      render: (rowData) => {
        return rowData.contact_name;
      },
    },
    {
      field: "user",
      title: "User",
      render: (rowData) => {
        return rowData.user_name;
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <Chip
            label={getStatus(rowData.status)?.label || ""}
            style={{
              background: getStatus(rowData.status)?.color || "",
              color: "white",
            }}
          />
        );
      },
    },
    {
      field: "items",
      title: "Items",
      render: (rowData) => {
        return rowData.items.data.map((item) => {
          return (
            <ListItem>
              <ListItemText
                primary={
                  <Typography>
                    {item.name}
                    {item.is_revision ? (
                      <strong
                        style={{
                          color: "#326a9b",
                          marginLeft: 5,
                          fontWeight: 400,
                        }}
                      >
                        {"(Revision)"}
                      </strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                }
                secondary={item.responsible ? item.responsible.title : null}
                className={
                  item && item.parent_id != null ? classes.childProduct : ""
                }
              />
            </ListItem>
          );
        });
      },
    },
    {
      field: "total_price",
      title: "Invoice Total",
      render: (rowData) => {
        return `$ ${rowData.total_price.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },

    {
      field: "balance",
      title: "Balance Due",
      render: (rowData) => {
        return `$ ${rowData.balance.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      field: "payment_transferred_to",
      title: "Re-Assigned Payments",
      sorting: false,
      render: (rowData) => {
        return (
          <Box
            style={{
              backgroundColor: rowData.payment_transferred_to
                ? "#fbe9e7"
                : "#e8f5e9",
              borderRadius: 25,
              textAlign: "center",
              width: 100,
            }}
          >
            <Typography
              style={{
                padding: 5,
                fontWeight: 600,
              }}
            >
              {rowData.payment_transferred_to ? "Yes" : "No"}
            </Typography>
          </Box>
        );
      },
    },
  ];
  const headCellsMaterialDeleted = [
    {
      field: "created_at",
      title: "Date",
      render: (rowData) => {
        return rowData.date ? moment(rowData.date).format("YYYY-MM-DD") : "";
      },
    },
    { field: "name", title: "Name" },
    {
      field: "contact",
      title: "Contact",
      render: (rowData) => {
        return rowData.contact_name;
      },
    },
    {
      field: "user",
      title: "User",
      render: (rowData) => {
        return rowData.user_name;
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <Chip
            label={getStatus(rowData.status)?.label || ""}
            style={{
              background: getStatus(rowData.status)?.color || "",
              color: "white",
            }}
          />
        );
      },
    },
    {
      field: "items",
      title: "Items",
      render: (rowData) => {
        return rowData.items.data.map((item) => {
          return (
            <ListItem>
              <ListItemText
                primary={item.name}
                secondary={item.responsible ? item.responsible.title : null}
                className={
                  item && item.parent_id != null ? classes.childProduct : ""
                }
              />
            </ListItem>
          );
        });
      },
    },
    {
      field: "total_price",
      title: "Invoice Total",
      render: (rowData) => {
        return `$ ${rowData.total_price.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      field: "balance",
      title: "Balance Due",
      render: (rowData) => {
        return `$ ${rowData.balance.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      field: "payment_transferred_to",
      title: "Re-Assigned Payments",
      sorting: false,
      render: (rowData) => {
        return (
          <Box
            style={{
              backgroundColor: rowData.payment_transferred_to
                ? "#fbe9e7"
                : "#e8f5e9",
              borderRadius: 25,
              textAlign: "center",
              width: 100,
            }}
          >
            <Typography
              style={{
                padding: 5,
                fontWeight: 600,
              }}
            >
              {rowData.payment_transferred_to ? "Yes" : "No"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "deleted_by",
      title: "Deleted By",
    },
  ];

  useEffect(() => {
    if (isEmpty(productsNew) && !productsNewLoanding) {
      dispatch(fetchProductsService(dataSliceProduct));
    }

    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));
    /*if (isEmpty(contacts))
      dispatch(fetchContactsService(authUser.organization_id));*/
    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));
    if (isEmpty(usersDropdown) && !usersDropdownLoanding)
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));

    /*  Api.getContactsSelect(authUser.organization_id).then((result) => {
      setContacts(result.data.data);
    });*/
  }, []);

  useEffect(() => {
    if (selectedInvoice) openInvoice(selectedInvoice);
  }, [selectedInvoice]);

  useEffect(() => {
    if (contact.id) {
      setProgressBar(true);

      getInvoicesContact().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact.id]);

  useEffect(() => {
    if (contact.id && tabValue == 1) {
      setProgressBar2(true);

      getInvoicesContactDeleted().then(function () {
        setProgressBar2(false);
      });
    }
  }, [contact.id, tabValue]);

  const getInvoicesContact = async () => {
    try {
      const { data } = await Api.getInvoicesContactsPlain2(contact.id);
      setInvoicesContact([...data.data]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const getInvoicesContactDeleted = async () => {
    try {
      const { data } = await Api.getInvoicesContactsPlainDeleted(contact.id);
      setInvoicesContactDeleted([...data.data]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const closeOnConsultantChange = (error) => {
    setOpenDetailPopup(false);
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(error);
    }
  };

  const dataMaterialTable = invoicesContact
    ? invoicesContact.map((o) => ({ ...o }))
    : [];

  const dataMaterialTableDeleted = invoicesContactDeleted
    ? invoicesContactDeleted.map((o) => ({ ...o }))
    : [];

  return (
    <Wrapper>
      <Paper className={classes.pageContent}>
        <div style={{ display: "flex" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="tabs"
            TabIndicatorProps={{ style: { background: "#009688" } }}
          >
            <Tab
              label={
                <Typography
                  style={{
                    color: tabValue == 0 ? "#009688" : "#212b36",
                    fontFamily: "Nunito,Roboto",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    margin: 12,
                  }}
                >
                  Invoices
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography
                  style={{
                    color: tabValue == 1 ? "#009688" : "#212b36",
                    fontFamily: "Nunito,Roboto",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    margin: 12,
                  }}
                >
                  Deleted Invoices
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <TblContainer>
            <MaterialTable
              isLoading={progressBar}
              columns={headCellsMaterial}
              data={dataMaterialTable}
              onRowClick={(event, rowData) =>
                (userCanEdit && !userTeamLeader) ||
                (userCanEdit && userTeamLeader && contact.editable) ||
                userIsCallCenter ||
                userIsFrontDesk ||
                userIsSocialMedia ||
                userIsbillingMGAssistant
                  ? openInPopup(rowData)
                  : console.log("User Can't Edit")
              }
              actions={[
                {
                  icon: () => (
                    <Fab color="primary" aria-label="add">
                      <Iconify
                        icon={"fluent:add-12-filled"}
                        width={25}
                        height={25}
                      />
                    </Fab>
                  ),
                  tooltip: "Add Invoice",
                  disabled:
                    !userCanAdd || (userTeamLeader && !contact.editable),
                  hidden: !userCanAdd || (userTeamLeader && !contact.editable),
                  isFreeAction: true,
                  onClick: (event) => {
                    setOpenPopup(true);
                  },
                },
                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color: "#3f51b5",
                      }}
                    >
                      <Iconify
                        icon={"material-symbols:view-compact-rounded"}
                        width={20}
                        height={20}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "View",
                  disabled:
                    userCanViewEditContactMark || userCanViewInvoice
                      ? false
                      : true,
                  hidden:
                    userCanViewEditContactMark || userCanViewInvoice
                      ? false
                      : true,
                  onClick: (event, rowData) => {
                    openInPopup(rowData);
                  },
                }),
                {
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color:
                          !userCanEdit &&
                          !userIsCallCenter &&
                          !userIsFrontDesk &&
                          !userIsSocialMedia &&
                          !userIsbillingMGAssistant
                            ? "#bdbdbd"
                            : "#009688",
                      }}
                    >
                      <Iconify
                        icon={"fluent:edit-24-filled"}
                        width={20}
                        height={20}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "Edit",
                  disabled:
                    !userCanEdit &&
                    !userIsCallCenter &&
                    !userIsFrontDesk &&
                    !userIsSocialMedia &&
                    !userIsbillingMGAssistant,
                  // hidden:
                  //   !userCanEdit &&
                  //   !userIsCallCenter &&
                  //   !userIsFrontDesk &&
                  //   !userIsSocialMedia &&
                  //   !userIsbillingMGAssistant /*|| (userTeamLeader && !contact.editable)*/,
                  onClick: (event, rowData) => {
                    openInPopup(rowData);
                  },
                },
                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color:
                          rowData.paid > 0 ||
                          !userCanDelete ||
                          (userTeamLeader && !contact.editable)
                            ? "#bdbdbd"
                            : "#f44336",
                      }}
                    >
                      <Iconify
                        icon={"mingcute:delete-fill"}
                        width={20}
                        height={20}
                      />
                    </Controls.IconButton>
                  ),
                  disabled:
                    rowData.paid > 0 ||
                    !userCanDelete ||
                    (userTeamLeader && !contact.editable),

                  // hidden:
                  //   rowData.paid > 0 ||
                  //   !userCanDelete ||
                  //   (userTeamLeader && !contact.editable),
                  tooltip: "Delete",
                  onClick: (event, rowData) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this invoice?",
                      subTitle: "You can't undo this operation",

                      onConfirm: () => onDelete(rowData.id),
                    }),
                }),
                // {
                //   icon: () => (
                //     <Controls.IconButton
                //       style={{ color: !userCanSeeLogs ? "#bdbdbd" : "#673ab7" }}
                //     >
                //       <Iconify icon={"subway:eye"} width={20} height={20} />
                //     </Controls.IconButton>
                //   ),
                //   tooltip: "Logs",
                //   onClick: (event, rowData) => {
                //     showInvoiceLogs(rowData);
                //   },
                //   hidden: !userCanSeeLogs,
                // },

                {
                  icon: () => (
                    <Controls.IconButton
                      style={{ color: !userCanSeeLogs ? "#bdbdbd" : "#673ab7" }}
                    >
                      <Iconify icon={"subway:eye"} width={20} height={20} />
                    </Controls.IconButton>
                  ),
                  tooltip: "Logs",
                  onClick: (event, rowData) => {
                    showInvoiceLogsNew(rowData);
                  },
                  hidden: !userCanSeeLogs,
                },
              ]}
              options={{
                loadingType: "linear",
                pageSize: 20, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                actionsColumnIndex: -1,
                exportButton: false,
                rowStyle: (rowData, index) => {
                  if (rowData.payment_transferred_to) {
                    return {
                      backgroundColor: "#fffde7",
                    };
                  }
                  return {
                    backgroundColor: "#ffffff",
                  };
                },
              }}
              title="Invoices"
            />

            <Totals>
              {" "}
              <Typography variant="h6">
                {"Invoiced: $ "}
                {calculateTotalb()}
                {"    "} {"          Due: $ "}
                {calculateTotalBalanceb()}
              </Typography>
            </Totals>
          </TblContainer>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TblContainer>
            <MaterialTable
              isLoading={progressBar2}
              columns={headCellsMaterialDeleted}
              data={dataMaterialTableDeleted}
              onRowClick={(event, rowData) => openInvoiceDeleted(rowData)}
              options={{
                loadingType: "linear",
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                actionsColumnIndex: -1,
                exportButton: false,
                rowStyle: (rowData, index) => {
                  if (rowData.payment_transferred_to) {
                    return {
                      backgroundColor: "#fffde7",
                    };
                  }
                  return {
                    backgroundColor: "#ffffff",
                  };
                },
              }}
              title="Invoices"
              actions={[
                {
                  icon: () => (
                    <Controls.IconButton
                      style={{ color: !userCanSeeLogs ? "#bdbdbd" : "#673ab7" }}
                    >
                      <Iconify icon={"subway:eye"} width={20} height={20} />
                    </Controls.IconButton>
                  ),
                  tooltip: "Logs",
                  onClick: (event, rowData) => {
                    showInvoiceLogsNew(rowData);
                  },
                  hidden: !userCanSeeLogs,
                },
              ]}
            />
          </TblContainer>
        </TabPanel>
      </Paper>

      <Popup
        title="Invoice Logs"
        openPopup={openLogPopup}
        onClose={closeLogPopup}
        fullWidth
      >
        <Logs logs={logs} />
      </Popup>

      {logsNew && (
        <Popup
          title="Invoice Logs"
          openPopup={openLogPopupNew}
          onClose={closeLogPopupNew}
          fullScreen
        >
          <InvoicesContactLogs logs={logsNew} />
        </Popup>
      )}

      <Popup
        fullScreen
        title={
          invoiceDetail ? `Invoice # ${invoiceDetail.name}` : "New Invoice"
        }
        openPopup={openDetailPopup}
        onClose={closePopup}
      >
        <InvoiceDetails
          setOpenDetailPopup={setOpenDetailPopup}
          invoice={invoiceDetail}
          invoices={invoicesContact}
          setInvoices={setInvoicesContact}
          getInvoicesContact={getInvoicesContact}
          readOnlySalesManager={
            readOnlySalesManager ||
            userIsCallCenter ||
            userIsSocialMedia ||
            userIsbillingMGAssistant
          }
          userIsCallCenter={userIsCallCenter}
          userIsSocialMedia={userIsSocialMedia}
          userIsFrontDesk={userIsFrontDesk}
          userIsbillingMGAssistant={userIsbillingMGAssistant}
          closeOnConsultantChange={closeOnConsultantChange}
          refreshInvoices={refreshInvoices}
        />
      </Popup>
      <Popup
        fullWidth={true}
        title={"New Invoice"}
        openPopup={openPopup}
        onClose={closeNewInvoicePopup}
      >
        <InvoiceBuilder
          // contacts={contacts}
          users={usersDropdown}
          authUser={authUser}
          products={productsNew}
          contact={contact}
          statusOptions={statusArray}
          onSaveInvoice={saveInvoice}
          openItemDefault={true}
        />
      </Popup>

      <Popup
        fullScreen
        title={
          invoiceDetailDeleted ? `Invoice # ${invoiceDetailDeleted.name}` : ""
        }
        openPopup={openDetailPopupDeleted}
        onClose={closePopupDeleted}
      >
        <InvoiceDetailsDeleted
          invoice={invoiceDetailDeleted}
          invoices={invoicesContact}
          setInvoices={setInvoicesContact}
          getInvoicesContact={getInvoicesContact}
          readOnlySalesManager={true}
          userIsCallCenter={userIsCallCenter}
          userIsSocialMedia={userIsSocialMedia}
          userIsFrontDesk={userIsFrontDesk}
          userIsbillingMGAssistant={userIsbillingMGAssistant}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default InvoicesContact;
