import React, { useState, useEffect } from "react";
import Picker from "emoji-picker-react";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import {
  Grid,
  makeStyles,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PerfectScrollbar from "react-perfect-scrollbar";
import { blue } from "@material-ui/core/colors";
import AttachmentIcon from "@material-ui/icons/Attachment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import styled from "styled-components";
import Popover from "@material-ui/core/Popover";
import Api from "../../lib/api";
import { findIndex, isEmpty } from "lodash";
import { fetchEntitiesService as fetchProceduresService } from "../../redux/slices/procedureDropdownSlice.js";
import { useDispatch, useSelector } from "react-redux";
import CRMUtils from "../../utils/index.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

const Scrollbar = styled(PerfectScrollbar)`
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

function EditorTemplate(props) {
  const {
    setFile,
    templateForEdit = null,
    file,
    setTextTemplate,
    textTemplate,
  } = props;
  const [urlMedia, setUrlMedia] = useState(
    templateForEdit && templateForEdit.tempUrl ? templateForEdit.tempUrl : null
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElButton, setAnchorElButton] = useState(null);
  const open = Boolean(anchorEl);
  const idopen = open ? "simple-popover" : undefined;
  const optionsTokens = [
    { title: "Customer ID", id: "CUSTOMER_ID" },
    { title: "Customer First Name", id: "CUSTOMER_FNAME" },
    { title: "Customer Last Name", id: "CUSTOMER_LNAME" },
    { title: "Customer Email", id: "CUSTOMER_EMAIL" },
    { title: "Customer Phone", id: "CUSTOMER_PHONE" },
    { title: "Customer Date Of Birth", id: "CUSTOMER_DOB" },
    { title: "Customer Sex", id: "CUSTOMER_SEX" },
    { title: "Customer Weight", id: "CUSTOMER_WEIGHT" },
    { title: "Customer Height", id: "CUSTOMER_HEIGHT" },
    { title: "Customer BMI", id: "CUSTOMER_BMI" },
    { title: "Organization Name", id: "ORGANIZATION_NAME" },
    { title: "Organization Address", id: "ORGANIZATION_ADDRESS" },
    { title: "Organization Phone", id: "ORGANIZATION_PHONE" },
    { title: "Organization Fax", id: "ORGANIZATION_FAX" },
    { title: "Consultant Full Name", id: "CONSULTANT_FULL_NAME" },

  ];

  const onEmojiClick = (event, emojiObject) => {
    setTextTemplate(
      textTemplate ? textTemplate + emojiObject.emoji : emojiObject.emoji
    );
  };

  const handleClickButton = (event) => {
    setAnchorElButton(event.currentTarget);
  };

  const handleCloseButton = () => {
    setAnchorElButton(null);
  };

  return (
    <div
      style={{
        boxShadow: "0 0 12px 0 rgb(53 64 82 / 8%)",
        borderRadius: 10,
        padding: 15,
        marginTop: 20,
      }}
    >
      <Grid
        style={{
          borderBottom: "1px solid #ebebeb",
          margin: 10,
          maxWidth: "98%",
        }}
        container
        spacing={1}
      >
        <Grid item xs={4}></Grid>

        <Grid item xs={1}>
          <>
            <Tooltip title="Insert Emoji">
              <Controls.IconButton
                aria-label="Emoticon Icon"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <InsertEmoticonIcon style={{ color: blue[700] }} />
              </Controls.IconButton>
            </Tooltip>

            <Popover
              id={idopen}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </Popover>
          </>
        </Grid>

        <Grid item xs={2}>
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClickButton}
              key={"Tokens"}
              style={{
                marginTop: 4,
                backgroundColor: !anchorEl ? "white" : "#f5f3f3",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#D8D8D8",
                color: "black",
                borderRadius: "30px",
                height: "40px",
                width: "140px",
              }}
            >
              Auto Fields
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              style={{ marginTop: 180 }}
              id="simple-menu"
              anchorEl={anchorElButton}
              keepMounted
              open={Boolean(anchorElButton)}
              onClose={handleCloseButton}
              key={"SMenus"}
            >
              <Scrollbar style={{ maxHeight: 300 }}>
                {optionsTokens.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    onClick={() => {
                      setTextTemplate(
                        textTemplate
                          ? textTemplate + "{{" + item.id + "}}"
                          : "{{" + item.id + "}}"
                      );
                      handleCloseButton();
                    }}
                  >
                    <ListItemText>{item.title}</ListItemText>
                  </MenuItem>
                ))}
              </Scrollbar>
            </Menu>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Controls.DropzoneDialog
            maxFileSize={2097152}
            st={{
              width: "80%",
              color: "#000",
              border: "1px solid #c2f6f3",
              borderRadius: 18,
              backgroundColor: "#e4fbff",
              fontSize: 12,
              height: 40,
              margin: 0,
            }}
            formats={["image/jpeg", "image/png", "video/mp4"]}
            label="Add Attachment"
            variant="contained"
            startIcon={<AttachFileIcon />}
            color="secondary"
            onChange={(files) => {
              setUrlMedia(URL.createObjectURL(files[0]));
              setFile(files[0]);
            }}
          />
        </Grid>

        <Grid item xs={2} style={{ marginLeft: -50, marginTop: 4 }}>
          <Button
            style={{
              margin: 0,
              color: "rgb(0, 0, 0)",
              border: "1px solid rgb(253 240 234)",
              borderRadius: 18,
              backgroundColor: "rgb(255 237 237)",
              width: 165,
              fontSize: 12,
              height: 40,
              marginLeft: 15,
            }}
            //disabled={ !this.imageRef.current?.src }
            //color="red"
            aria-label="upload picture"
            onClick={() => {
              setUrlMedia("");
              setFile(null);
            }}
          >
            Remove Attachment
            <DeleteIcon color="warning" style={{ color: "#c62828" }} />
          </Button>
        </Grid>
      </Grid>
      {urlMedia && (
        <div style={{ marginTop: -20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: 16,
              left: 21,
              backgroundColor: "white",

              paddingLeft: 8,
              width: "fit-content",
              paddingRight: 8,
            }}
          >
            <AttachmentIcon />
            <Typography>{"Picture or Video"}</Typography>
          </div>
          <div
            style={{
              minHeight: 400,
              textAlign: "center",
              padding: 20,
              margin: 5,
              borderRadius: 5,
              border: "1px solid #cbc4c4",
            }}
          >
            {urlMedia ? (
              file && file.type == "video/mp4" ? (
                <video style={{ maxHeight: 650, maxWidth: 650 }} controls>
                  <source src={urlMedia} type="video/mp4"></source>
                </video>
              ) : (
                <img style={{ maxHeight: 650, maxWidth: 650 }} src={urlMedia} />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <TextField
        style={{ width: "99%" }}
        defaultValue={
          templateForEdit && templateForEdit.body ? templateForEdit.body : ""
        }
        inputProps={{ maxLength: 1999 }}
        value={textTemplate}
        multiline
        variant="outlined"
        label={"Template Text"}
        fullWidth
        rows={16}
        onChange={(event) => {
          setTextTemplate(event.target.value);
        }}
      ></TextField>
    </div>
  );
}

var dataSliceProceduresDropdown = {
  stateListName: "proceduresDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

export default function MyTemplatesForm(props) {
  const {
    authUser,
    setTemplates,
    templates,
    templateForEdit,
    setTemplateForEdit,
    closeMyTemplatesForm,
  } = props;
  const [textTemplate, setTextTemplate] = useState(
    templateForEdit && templateForEdit.body ? templateForEdit.body : ""
  );
  const userCanViewAll = CRMUtils.userHasPermission(authUser, [
    "user_viewallsmsmmstemplates",
  ]);
  const [buildingSelected, setBuildingSelected] = useState(
    templateForEdit && templateForEdit.building_id
      ? templateForEdit.building_id
      : null
  );
  const [doctors, setDoctors] = useState([]);
  const { proceduresDropdown } = useSelector(
    (state) => state.proceduresDropdown
  );
  const [buildingsDropdown, setBuildingsDropdown] = useState([]);
  const { handleSubmit, control, getValues, errors } = useForm();
  const [file, setFile] = useState(
    templateForEdit && templateForEdit.media_type
      ? { type: templateForEdit.media_type }
      : null
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const getBuildings = async () => {
    const { data } = await Api.getBuildingsWithDoctors();
    if(userCanViewAll){
      setBuildingsDropdown([...data.data]);
    }else{
      setBuildingsDropdown([...data.data].filter(b=> b.id == authUser.building_id));
    }
    
  };

  const buildingOptions = () => {
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const doctorsOptions = () => {
    if (buildingSelected) {
      var building = buildingsDropdown.find((a) => a.id == buildingSelected);
      return building?.doctors.data.map((doctor) => {
        return {
          id: doctor.id,
          title: doctor.users_fname,
        };
      });
    } else {
      return doctors?.map((doctor) => {
        return {
          id: doctor.id,
          title: doctor.fullname,
        };
      });
    }
  };

  const getDoctors = async () => {
    const { data } = await Api.getDoctors(1);
    setDoctors([...data.data]);
  };

  const procedureOptions = () => {
    let procedure = [];

    procedure = proceduresDropdown.map((product) => {
      return {
        id: product.id,
        title: product.name,
      };
    });

    return procedure;
  };

  useEffect(() => {
    getBuildings();
    getDoctors();
    if (isEmpty(proceduresDropdown)) {
      dispatch(fetchProceduresService(dataSliceProceduresDropdown));
    }
  }, []);

  const onSubmitTemplate = async (dataSubmit) => {
    if (templateForEdit) {
      dataSubmit = {
        ...dataSubmit,
        ...{
          owner_id: authUser.id,
        },
      };

      if (!file) {
        dataSubmit = { ...dataSubmit, ...{ attch_url: null } };
      }

      if (file) {
        dataSubmit = {
          ...dataSubmit,
          ...{ media_type: file.type, type_of_message: "mms" },
        };
      } else {
        dataSubmit = {
          ...dataSubmit,
          ...{ type_of_message: "sms", deleteAttach: true, media_type: null },
        };
      }

      if (textTemplate) {
        dataSubmit = { ...dataSubmit, ...{ body: textTemplate } };
      }

      const { data } = await Api.updateSMSMMSTemplate(
        templateForEdit.id,
        dataSubmit
      );

      const index = findIndex(templates, ["id", data.data.id]);
      const entity = { ...templates[index], ...data.data };
      let entities = [...templates];
      entities[index] = entity;

      setTemplates([...entities]);

      if (
        file &&
        JSON.stringify(file) !=
          JSON.stringify({ type: templateForEdit.media_type })
      ) {
        let fileAttch = {
          id: 1,
          errors: [],
          file: file,
          valid: true,
        };

        let formData = new FormData();
        Object.keys(fileAttch).forEach((key) =>
          formData.append(key, fileAttch[key])
        );
        formData.append("docType", "SMS/MMS Template");
        Api.attachElementToSMSMMSTemplate(formData, templateForEdit.id).then(
          (response) => {
            const index = findIndex(templates, ["id", data.data.id]);
            const entity = {
              ...templates[index],
              ...{ ...data.data, ...{ tempUrl: response.data.data } },
            };
            let entities = [...templates];
            entities[index] = entity;

            setTemplates([...entities]);
            closeMyTemplatesForm();
          }
        );
      } else {
        const index = findIndex(templates, ["id", data.data.id]);
        const entity = { ...templates[index], ...data.data };
        let entities = [...templates];
        entities[index] = entity;

        setTemplates([...entities]);
        closeMyTemplatesForm();
      }
    } else {
      var payload = {};
      Object.keys(dataSubmit).forEach((key) => {
        if (dataSubmit[key] != null) {
          payload[key] = dataSubmit[key];
        }
      });

      payload = {
        ...payload,
        ...{ created_by: authUser.id, category_id: 5, owner_id: authUser.id },
      };

      if (file) {
        let fileAttach = {
          type_of_message: "mms",
          media_type: file.type,
          id: 1,
          errors: [],
          file: file,
          valid: true,
        };
        payload = { ...payload, ...fileAttach };
      } else {
        payload = { ...payload, ...{ type_of_message: "sms" } };
      }
      if (textTemplate) {
        payload = { ...payload, ...{ body: textTemplate } };
      }

      let formData = new FormData();
      Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
      formData.append("docType", "sms_mms_template");

      const { data } = await Api.createSMSMMSTemplate(formData);

      setTemplates([data.data, ...templates]);
      closeMyTemplatesForm();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitTemplate)} className={classes.root}>
      <Grid container spacing={1} style={{ alignItems: "center" }}>
        <Grid item xs={8}>
          <Controller
            name="name"
            control={control}
            defaultValue={templateForEdit ? templateForEdit.name : ""}
            as={<Controls.Input style={{ width: "100%" }} label="Name*" />}
            rules={{ required: "Template Name is required" }}
          />
          {errors.name && (
            <p className={classes.error}>{errors.name.message}</p>
          )}
        </Grid>

        <Grid item xs={2}>
          <div style={{ marginLeft: 12 }}>
            <Controls.Button
              type="submit"
              variant="contained"
              color="primary"
              text="Submit"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={3} style={{ marginRight: 8 }}>
          <Controller
            name="building_id"
            control={control}
            defaultValue={templateForEdit?.building_id || null}
            // rules={{ required: "Building is required" }}
            as={({ onChange, value }) => (
              <Controls.Select
                allowEmpty={true}
                styleProp={true}
                label="Select a Building"
                options={buildingOptions()}
                onChange={(value) => {
                  setBuildingSelected(
                    value.target.value == -1 ? null : value.target.value
                  );
                  value.target.value == -1 ? onChange(null) : onChange(value);
                }}
                value={value == -1 ? null : value}
              />
            )}
          />
          {
            //errors.building_id && (
            // <p className={classes.error}>{errors.building_id.message}</p>
            // )
          }
        </Grid>

        <Grid item xs={4} style={{ marginRight: 8 }}>
          <Controller
            name="doctor_id"
            control={control}
            defaultValue={templateForEdit?.doctor_id || null}
            as={({ onChange, value }) => (
              <Controls.Select
                allowEmpty={true}
                styleProp={true}
                label={"Select a Doctor"}
                options={doctorsOptions()}
                onChange={(value) => {
                  value.target.value == -1 ? onChange(null) : onChange(value);
                }}
                value={value == -1 ? null : value}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="procedure_id"
            control={control}
            defaultValue={templateForEdit?.procedure_id || null}
            as={({ onChange, value }) => (
              <Controls.Select
                allowEmpty={true}
                styleProp={true}
                label={"Select a Procedure"}
                options={procedureOptions()}
                onChange={(value) => {
                  value.target.value == -1 ? onChange(null) : onChange(value);
                }}
                value={value == -1 ? null : value}
              />
            )}
          />
        </Grid>
      </Grid>
      <EditorTemplate
        templateForEdit={templateForEdit}
        setFile={setFile}
        file={file}
        setTextTemplate={setTextTemplate}
        textTemplate={textTemplate}
      />
    </form>
  );
}
