import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import StatesComponent from "../../../../../components/statescomponent/StatesComponent";
import RefundDoc from "../../../../refunds/components/RefundDoc";
import moment from "moment";
import Api from "../../../../../lib/api";
import { useQuery } from "react-query";

const RefundView = ({ entityId }) => {
	const { data: refund, isLoading: refundLoading } = useQuery(
		["rfs_tickets", entityId],
		() =>
			Api.genericQueryByIdModel("rfs_tickets", entityId, {
				with: [
					"invoice",
					"invoice.contact",
					"invoice_items",
					"invoice.user",
					"invoice.payments",
				],
			}),
		{
			enabled: Boolean(entityId),
			select: (data) => data?.data?.data,
		}
	);

	return (
		<Grid container direction="column">
			<Grid item>
				<Card>
					<CardContent>
						<Typography variant="caption">
							Refund tickets:
							{refund
								? `${refund?.subject}-${moment(
										refund?.created_at
								  ).format("MM/DD/yyyy")}`
								: "...loading..."}
						</Typography>
						<StatesComponent
							workflow={"refund_basic"}
							objectId={refund?.id}
							showallstates={true}
							readOnly={true}
						/>
					</CardContent>
				</Card>
			</Grid>
			<Grid item>
				<RefundDoc
					invoice={refund?.invoice}
					selectedItems={refund?.invoice_items}
					amount={refund?.amount}
				/>
			</Grid>
		</Grid>
	);
};

export default RefundView;
