import { makeStyles, Popover } from "@material-ui/core";
import { useState } from "react";
import Controls from "../components/controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "90%",
      margin: theme.spacing(1),
    },
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
  },
  outerpicker: {
    margin: 4,
    display: "inline-flex",
    gap: 12,
  },
}));

const ColorPickerPopup = ({
  value,
  onChange,
  defaultColor,
  label,
  colors = [
    "#FF6900",
    "#FCB900",
    "#7BDCB5",
    "#00D084",
    "#8ED1FC",
    "#0693E3",
    "#ABB8C3",
    "#EB144C",
    "#F78DA7",
    "#9900EF",
  ],
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.outerpicker}>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} style={{ backgroundColor: value }} />
      </div>
      <div>{label}</div>
      {anchorEl != null ? (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          onClose={handleClose}
        >
          <Controls.ColorPicker
            defaultColor={value}
            value={value}
            triangle="top-left"
            colors={colors}
            onChange={(color) => {
              onChange(color);
            }}
          />
        </Popover>
      ) : null}
    </div>
  );
};

export default ColorPickerPopup;
