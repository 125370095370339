import React, { useState, useRef } from "react";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";

//Import a third-party plugin.
import "froala-editor/js/third_party/image_tui.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/third_party/spell_checker.min.js";



function RichTextarea(props) {
    const { defaultValue, onChange, zIndex=2501, extraConfig={}} = props;
    const froala = useRef();
    const [editorModel, setEditorModel] = useState(defaultValue);

    const handleModelChange = (modelNew) => {
        setEditorModel(modelNew);
        onChange(modelNew);
    };

    const editorConfig = {...{
        key: process.env.REACT_APP_FROALA_KEY,
        zIndex: zIndex,
        attribution: false,
        dragInline: false,
        useClasses: false,
        htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["style"],
    }, ...extraConfig}


    return (
        <FroalaEditorComponent
            config={editorConfig}
            ref={froala} //create a ref to the instance
            tag="textarea"
            model={editorModel}
            onModelChange={handleModelChange}
        />
    );
}

export default RichTextarea;
