import { createSlice } from "@reduxjs/toolkit";
import Api from "../../lib/api";
import { findIndex } from "lodash";

export const initialState = {
  loading: false,
  errors: [],
  recordproducts: [],
};

const recordproductsSlice = createSlice({
  name: "recordproducts",
  initialState,
  reducers: {
    getRecordproducts: (state) => {
      state.loading = true;
    },
    getRecordproductsSuccess: (state, { payload }) => {
      state.recordproducts = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getRecordproductsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateRecordproduct: (state) => {},
    updateRecordproductSuccess: (state, { payload }) => {
      const index = findIndex(state.recordproducts, ["id", payload.id]);
      const record = { ...state.recordproducts[index], ...payload };

      let records = [...state.recordproducts];
      records[index] = record;

      state.recordproducts = [...records];
      state.loading = false;
      state.errors = [];
    },
    updateRecordproductFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getRecordproducts,
  getRecordproductsSuccess,
  getRecordproductsFailure,
  updateRecordproduct,
  updateRecordproductSuccess,
  updateRecordproductFailure
 
} = recordproductsSlice.actions;

export const recordproductsSelector = (state) => state.reports;
export default recordproductsSlice.reducer;

export function fetchRecordproductsService(orgId) {
  return async (dispatch) => {
    dispatch(getRecordproducts());

    try {
      const response = await Api.getOrgRecordProducts(orgId);
      dispatch(getRecordproductsSuccess(response.data));
    } catch (error) {
      dispatch(getRecordproductsFailure(error));
    }
  };
}


export function updateRecordProductService(payload) {
  return async (dispatch) => {
    dispatch(updateRecordproduct());
    try {
      console.log("#### ", payload);
      
      dispatch(updateRecordproductSuccess(payload));
    } catch (error) {
      dispatch(updateRecordproductFailure(error));
    }
  };
}

