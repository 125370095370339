import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  loadingTags: false,
  errors: [],
  tags: [],
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    getTags: (state) => {
      state.loading = true;
      state.loadingTags = true;
    },
    getTagsSuccess: (state, { payload }) => {
      state.tags = payload.data;
      state.loading = false;
      state.loadingTags = false;
      state.errors = [];
    },
    getTagsFailure: (state, { payload }) => {
      state.loading = false;
      state.loadingTags = false;
      state.errors = [...state.errors, payload.message];
    },
    createTag: (state) => {},
    createTagSuccess: (state, { payload }) => {
      state.tags = [...state.tags, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createTagFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateTag: (state) => {},
    updateTagSuccess: (state, { payload }) => {
      const index = findIndex(state.tags, ["id", payload.data.id]);
      const tag = { ...state.tags[index], ...payload.data };

      let tags = [...state.tags];
      tags[index] = tag;

      state.tags = [...tags];
      state.loading = false;
      state.errors = [];
    },
    updateTagFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteTag: (state) => {},
    deleteTagSuccess: (state, { payload }) => {
      state.tags = state.tags.filter((tag) => {
        return tag.id !== payload;
      });
    },
    deleteTagFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getTags,
  getTagsSuccess,
  getTagsFailure,
  createTag,
  createTagSuccess,
  createTagFailure,
  updateTag,
  updateTagSuccess,
  updateTagFailure,
  deleteTag,
  deleteTagSuccess,
  deleteTagFailure,
} = tagsSlice.actions;

export const tagsSelector = (state) => state.tags;
export default tagsSlice.reducer;

export function fetchTagsService(orgId, params="") {
  return async (dispatch) => {
    dispatch(getTags());

    try {
      const response = await Api.getTags(orgId, params);

      dispatch(getTagsSuccess(response.data));
    } catch (error) {
      dispatch(getTagsFailure(error));
    }
  };
}

export function createTagService(payload,orgId) {
  return async (dispatch) => {
    dispatch(createTag(orgId));

    try {
      const response = await Api.saveTag(payload);

      dispatch(createTagSuccess(response.data));
    } catch (error) {
      dispatch(createTagFailure(error));
    }
  };
}

export function updateTagService(id, payload) {
  return async (dispatch) => {
    dispatch(updateTag());

    try {
      const response = await Api.updateTag(id, payload);

      dispatch(
        updateTagSuccess(response.data)
      );
    } catch (error) {
      dispatch(updateTagFailure(error));
    }
  };
}

export function deleteTagService(id) {
  return async (dispatch) => {
    dispatch(deleteTag());

    try {
      const response = await Api.deleteTag(id);

      dispatch(deleteTagSuccess(id));
    } catch (error) {
      dispatch(deleteTagFailure(error));
    }
  };
}