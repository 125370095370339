import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, find, findIndex, map } from "lodash";
import moment from "moment";
import Api from "../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import PreopForm from "./forms/PreopForm";
import RecordProductCostsForm2 from "./forms/RecordProductCostsForm2";
import { withStyles } from "@material-ui/styles";
import { fetchDocumentTypesService } from "../redux/slices/documenttypes";
import { fetchCancellationMotivesService } from "../redux/slices/cancellationMotives";
import Comments from "./Comments";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import RecordProductDocuments from "../components/RecordProductDocuments";
import RecordProductAssistants from "../components/RecordProductAssistants";
import RecordProductAnesthesiologist from "../components/RecordProductAnesthesiologist";
import RecordProductCirculators from "../components/RecordProductCirculators";

import StatusHistories from "../components/StatusHistories";
import RecordProductTemplateViewer from "./RecordProductTemplateViewer";
import CRMUtils from "../utils";
import { fetchEntitiesService as fetchRecordProductCostsService } from "../redux/slices/recordProductCostsSlice.js";
import { useTranslation } from "react-i18next";
import { fetchTemplatesService } from "../redux/slices/recordProductTemplates";
import CommentsRP from "./CommentsRP";
import RecordProductReschedulingRequests from "./RecordProductReschedulingRequests";
const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

var dataSliceRecordProductCosts = {
  stateListName: "recordProductCosts",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    //limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function PreoperationData(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);

  if (authUser) {
    dataSliceRecordProductCosts.orgId = authUser.organization_id;
  }

  const userCanAdd = CRMUtils.userHasPermission(authUser, [
    "recordproduct_create",
  ]);
  const userCanEdit = CRMUtils.userHasPermission(authUser, [
    "recordproduct_edit",
  ]);
  const userCanDelete = CRMUtils.userHasPermission(authUser, [
    "recordproduct_delete",
  ]);

  const userCanSendMCD = CRMUtils.userHasPermission(authUser, [
    "record_sendMCD",
  ]);

  const userCanOverrideOfferDateLock= CRMUtils.userHasPermission(authUser, [
    "recordproduct_overrideOfferDateLock",
  ]) || CRMUtils.userHasRole(authUser, [
    "super-admin",
  ]);

  
  const userCanCloseTransferDateRequestsSuper = CRMUtils.userHasPermission(
    authUser,
    ["recordproduct_supertransferdate"]
  );

  const userCanCloseTransferDateRequestsCoordinator =
    CRMUtils.userHasPermission(authUser, [
      "recordproduct_coordinatormanagertransferdate",
    ]);

  const userCanCloseTransferDateRequests = CRMUtils.userHasPermission(
    authUser,
    ["recordproduct_transferdate"]
  );

  const [coordinatorsFromDoctor, setCoordinatorsFromDoctor] = useState(false);

  const {
    recordProductCosts /*,
    loading,
    errors,
    recordProductCostsPagination,
    recordProductCostsLoading,
    recordProductCostsSaveLoading,
    recordProductCostsDeleteLoading,*/,
  } = useSelector((state) => state.recordProductCosts);

  const { recordProductdatas, closePopup } = props;
  const {
    updateRecordProductData,
    updateRecordProductData2,
    updateRecordProductDataCost,
    updateOnlyTransfer = false,
    setSubmittingTransfer=null
  } = props;

  const { documentTypes } = useSelector((state) => state.documenttypes);

  const { cancellationMotives } = useSelector(
    (state) => state.cancellationMotives
  );
  const [productRecords, setProductRecords] = useState([]);
  const [userDoctors, setUserDoctors] = useState([]);

  const [recordProductForEdit, setRecordProductForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const { t, i18n } = useTranslation(["common", "records"]);
  const [coordinators, setCoordinators] = useState([]);
  const headCells = [
    { id: "name", label: t("common:general.Name") },
    { id: "pre-date", label: t("records:records.predate") },
    { id: "date", label: t("common:general.Date") },
    { id: "salesman", label: t("common:general.Salesman") },
    { id: "responsible", label: t("common:general.Responsible") },
    { id: "coordinator", label: t("records:records.coordinator") },
    { id: "completed", label: t("common:general.Completed") },
  ];

  const saveRecordProductAssistantsAnesthesiologistsCirculators = async (
    payload
  ) => {
    const { data } = await Api.updateProductRecord(
      recordProductForEdit.id,
      payload
    );

    updateRecordProductData(data.data);
  };

  const updateRecordProductCosts = async (payload) => {
    payload.cost = JSON.stringify(payload);

    const { data } = await Api.updateRecordProductsCosts(
      recordProductForEdit.id,
      payload
    );

    updateRecordProductDataCost(recordProductForEdit.id, data.data, null);
  };

  const saveRecordProductCosts = async (payload) => {
    payload.cost = JSON.stringify(payload);
    const { data } = await Api.addRecordProductsCosts(
      recordProductForEdit.id,
      payload
    );

    updateRecordProductDataCost(recordProductForEdit.id, data.data, null);
  };

  const delteRecordProductCosts = async (deleted_id) => {
    const { data } = await Api.deleteRecordProductsCosts(
      recordProductForEdit.id,
      deleted_id
    );

    updateRecordProductDataCost(recordProductForEdit.id, null, deleted_id);
  };

  const saveRecordProductTransfer = async (payload) => {
    if( typeof setSubmittingTransfer == "function"){
      setSubmittingTransfer(true);
    }
    if (!payload.transfer) {
      payload.organization_id = authUser.organization_id;
      const { data } = await Api.saveRecordProductTransfer(payload);

      const localproductRecord = {
        ...recordProductForEdit,
        recordProductTransfers: {
          data: [
            ...recordProductForEdit.recordProductTransfers.data,
            data.data,
          ],
        },
      };

      updateRecordProductData(localproductRecord);
    } else {
      payload.approved_at = new moment().format("YYYY-MM-DD HH:mm:ss");

      const { data } = await Api.updateRecordProductTransfer(
        payload.id,
        payload
      );

      if (updateOnlyTransfer) {
        updateRecordProductData2(data.data);
      }
      let localRecordProductTransfers =
        recordProductForEdit.recordProductTransfers.data;

      const index = findIndex(localRecordProductTransfers, (e) => {
        return e.id == payload.id;
      });

      localRecordProductTransfers[index] = data.data;

      const localproductRecord = {
        ...recordProductForEdit,
        recordProductTransfers: {
          data: localRecordProductTransfers,
        },
      };

      updateRecordProductData(localproductRecord);
    }
    if( typeof setSubmittingTransfer == "function"){
      setSubmittingTransfer(false);
    }
  };

  const onDeleteAssistant = async (id) => {
    const { data } = await Api.deleteProductRecordAssistants(
      recordProductForEdit.id,
      id
    );
    /*let filteredArray = recordProductForEdit.assistants.data.filter(
      (item) => item.id !== id
    );

    const newRecord = {
      ...recordProductForEdit,
      assistants: {
        data: filteredArray,
      },
    };

    updateRecordProductData(newRecord);
    */
  };

  const onDeleteAnesthesiologist = async (id) => {
    const { data } = await Api.deleteProductRecordAnesthesiologist(
      recordProductForEdit.id,
      id
    );
  };

  const onDeleteCirculator = async (id) => {
    const { data } = await Api.deleteProductRecordCirculator(
      recordProductForEdit.id,
      id
    );
  };

  /* const saveComment = async (payload) => {
    const { data } = await Api.createRecordComment(
      recordProductForEdit.record.data.id,
      payload
    );

    let record = recordProductForEdit.record;

    const newRecord = {
      ...record.data,
      contactRecordComments: {
        ...record.data.contactRecordComments,
        data: [...record.data.contactRecordComments.data, data.data],
      },
    };

    const newRecordProductForEdit = {
      ...recordProductForEdit,
      record: { data: newRecord },
    };
    //console.log("newRecordProductForEdit", newRecordProductForEdit);
    //return;

    updateRecordProductData(newRecordProductForEdit);
  };

  */

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const saveRecordFollowup = async (
    id,
    data,
    recordProductID,
    recordProductData
  ) => {
    try {
      const date = recordProductData.date
        ? new moment(recordProductData.date).format("YYYY-MM-DD HH:mm:ss")
        : null;
      recordProductData.date = date;

      const pre_date = recordProductData.pre_date
        ? new moment(recordProductData.pre_date).format("YYYY-MM-DD HH:mm:ss")
        : null;
      recordProductData.pre_date = pre_date;

      Api.updateProductRecord(recordProductID, recordProductData).then(
        (updatedResult) => {
          const localproductRecord = {
            ...recordProductForEdit,
            ...updatedResult.data.data,
          };
          if (id) {
            Api.updaterecordfollowup(id, data).then((result) => {
              const newRecord = {
                ...localproductRecord,
                recordfollowup: {
                  data: result.data.data,
                },
              };

              updateRecordProductData(newRecord);
              closePopup();
            });
          } else {
            Api.saverecordfollowup(data).then((result) => {
              const newRecord = {
                ...localproductRecord,
                recordfollowup: {
                  data: result.data.data,
                },
              };

              updateRecordProductData(newRecord);
              closePopup();
            });
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const updateOnlyRecordProduct = async (
    recordProductID,
    recordProductData,
    payload
  ) => {
    try {
      payload.approved_at = new moment().format("YYYY-MM-DD HH:mm:ss");

      Api.updateRecordProductTransfer(payload.id, payload).then(
        (updatedResult) => {
          if (updateOnlyTransfer) {
            updateRecordProductData2(updatedResult.data);
          }
          Api.updateProductRecord(recordProductID, recordProductData).then(
            (updatedResult) => {
              const localproductRecord = {
                ...recordProductForEdit,
                ...updatedResult.data.data,
              };

              updateRecordProductData(localproductRecord);
            }
          );
        }
      );
      closePopup();
    } catch (e) {
      console.log(e);
    }
  };
  const getDoctorsUser = async () => {
    const { data } = await Api.getUserDoctors(authUser.id);

    setUserDoctors(data.data);
  };

  const getSurgeonCoordinator = async () => {
    if (recordProductForEdit && recordProductForEdit.responsible_id) {
      const { data } = await Api.getUserCoordinators(
        recordProductForEdit.responsible_id
      );
      setCoordinators(data.data);
    }
  };

  const userCanSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_super",
  ]);

  const userFromDoctor = () => {
    if (userCanSuper) {
      return true;
    }

    if (!isEmpty(coordinators)) {
      const index = findIndex(coordinators, ["id", authUser.id]);

      return index >= 0 ? true : false;
    }
    return false;
  };

  useEffect(() => {
    getDoctorsUser();
    if (
      authUser &&
      authUser.organization.data.recordproduct_template_variant == 2
    ) {
      dispatch(fetchTemplatesService(authUser.organization_id, -1));
    }

    if (isEmpty(cancellationMotives))
      dispatch(fetchCancellationMotivesService(authUser.organization_id));

    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));

    if (isEmpty(recordProductCosts))
      dispatch(fetchRecordProductCostsService(dataSliceRecordProductCosts));
  }, []);

  useEffect(() => {
    setRecordProductForEdit(recordProductdatas);
  }, [recordProductdatas]);

  useEffect(() => {
    getSurgeonCoordinator();
  }, [recordProductForEdit]);

  useEffect(() => {
    setCoordinatorsFromDoctor(userFromDoctor());
  }, [coordinators]);

  const userIsBilling =
    CRMUtils.userHasPermission(authUser, ["dashboard_billingBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingAssistant"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingManager"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingManagerAssistant"])
      ? true
      : false;

  const userIsBillingSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_billingSuper",
  ]);

  const userIsBillingManagerAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingManagerAssistant",
  ]);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
          <Tab label={t("records:records.title")} {...a11yProps(0)} />
          <Tab
            label={"Comments"}
            {...a11yProps(1)}
            disabled={userCanViewEditContactMark}
          />
          <Tab label={"Surgical Assistants"} {...a11yProps(2)} disabled={!userCanEdit} />
          <Tab
            label={"Anesthesiologist"}
            {...a11yProps(3)}
            disabled={!userCanEdit}
          />
          <Tab
            label={"Circulators"}
            {...a11yProps(4)}
            disabled={!userCanEdit}
          />
          <Tab
            label={t("common:general.Documents")}
            {...a11yProps(5)}
            disabled={!userCanEdit}
          />
          <Tab
            label={t("common:general.MCD")}
            {...a11yProps(6)}
            disabled={
              !userCanEdit && !coordinatorsFromDoctor && !userCanSendMCD
            }
          />
          <Tab
            label={t("common:general.SH")}
            {...a11yProps(7)}
            disabled={
              !userCanEdit &&
              !userIsBillingSuper &&
              !userIsBillingManagerAssistant
            }
          />

          <Tab
            label={t("common:general.Cost")}
            {...a11yProps(6)}
            disabled={!userCanEdit}
          />

          {userIsBilling && (
            <Tab label={"Billing Documents"} {...a11yProps(7)} />
          )}

          {userCanCloseTransferDateRequestsSuper ||
          (userCanCloseTransferDateRequestsCoordinator && userFromDoctor()) ||
          (userCanCloseTransferDateRequests &&
            recordProductForEdit &&
            recordProductForEdit.coordinator_id &&
            authUser.id == recordProductForEdit.coordinator_id) ? (
            <Tab
              label={"Procedure Scheduling Requests"}
              {...a11yProps(userIsBilling ? 10 : 9)}
            />
          ) : (
            ""
          )}
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={0}>
        {recordProductForEdit && (
          <PreopForm
            saveRecordFollowup={saveRecordFollowup}
            recordProductForEdit={recordProductForEdit}
            updateRecordProductData={updateRecordProductData}
            closePopup={closePopup}
            cancellationMotives={cancellationMotives}
            saveRecordProductTransfer={saveRecordProductTransfer}
            updateOnlyRecordProduct={updateOnlyRecordProduct}
            userDoctors={userDoctors}
            userCanEdit={userCanEdit}
            coordinatorsFromDoctor={coordinatorsFromDoctor}
            userIsBillingSuper={userIsBillingSuper}
            userCanOverrideOfferDateLock={userCanOverrideOfferDateLock}
          />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CommentsRP
          record_id={
            recordProductForEdit ? recordProductForEdit.record.data.id : null
          }
          recordProductForEdit={recordProductForEdit}
          updateRecordProductData={updateRecordProductData}
          canAdd={userCanEdit && coordinatorsFromDoctor}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <RecordProductAssistants
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          coordinatorsFromDoctor={coordinatorsFromDoctor}
          onSave={saveRecordProductAssistantsAnesthesiologistsCirculators}
          onDeleteAssistant={onDeleteAssistant}
          recordProductdatas={recordProductdatas}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <RecordProductAnesthesiologist
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          coordinatorsFromDoctor={coordinatorsFromDoctor}
          onSave={saveRecordProductAssistantsAnesthesiologistsCirculators}
          onDeleteAnesthesiologist={onDeleteAnesthesiologist}
          recordProductdatas={recordProductdatas}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <RecordProductCirculators
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          coordinatorsFromDoctor={coordinatorsFromDoctor}
          onSave={saveRecordProductAssistantsAnesthesiologistsCirculators}
          onDeleteCirculator={onDeleteCirculator}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <RecordProductDocuments
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          recordProductForEdit={recordProductForEdit}
          updateRecordProductData={updateRecordProductData}
          coordinatorsFromDoctor={coordinatorsFromDoctor}
          documentTypes={documentTypes ? documentTypes : []}
          showDelete={true}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <RecordProductTemplateViewer
          recordProductForEdit={
            recordProductForEdit ? recordProductForEdit : ""
          }
        />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <StatusHistories
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          contact={props.contact}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={8}>
        {recordProductForEdit && (
          <RecordProductCostsForm2
            itemstoAdd={recordProductCosts}
            record_id={recordProductForEdit ? recordProductForEdit.id : null}
            updateRecordProductDataCost={updateRecordProductDataCost}
            recordProductForEdit={recordProductForEdit}
            coordinatorsFromDoctor={coordinatorsFromDoctor}
          />
        )}
      </TabPanel>
      {userIsBilling && (
        <TabPanel value={tabValue} index={9}>
          <RecordProductTemplateViewer
            recordProductForEdit={
              recordProductForEdit ? recordProductForEdit : ""
            }
            billing
          />
        </TabPanel>
      )}

      <TabPanel value={tabValue} index={userIsBilling ? 10 : 9}>
        <RecordProductReschedulingRequests
          setRecordProductForEdit={setRecordProductForEdit}
          recordProduct={recordProductForEdit}
        />
      </TabPanel>
    </div>
  );
}

export default PreoperationData;
