import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  errors: [],
  portalMessagesNotifications: null,
};

const portalMessagesNotificationsSlice = createSlice({
  name: "portalMessagesNotifications",
  initialState,
  reducers: {
    createPortalMessagesNotifications: (state) => {
      state.loading = true;
    },
    createPortalMessagesNotificationsSuccess: (state, { payload }) => {
      state.portalMessagesNotifications = payload;
      state.loading = false;
      state.errors = [];
    },
    createPortalMessagesNotificationsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },
    updatePortalMessagesNotifications: (state) => {},
    updatePortalMessagesNotificationsSuccess: (state, { payload }) => {
      switch (payload.count) {
        case 1:
          state.portalMessagesNotifications =
            state.portalMessagesNotifications + 1;
          break;
        case "0":
          state.portalMessagesNotifications = 0;
          break;
        case "-1":
          state.portalMessagesNotifications =
            state.portalMessagesNotifications >= 1
              ? state.portalMessagesNotifications - 1
              : 0;
          break;
        default:
          break;
      }
      state.loading = false;
      state.errors = [];
    },
    updatePortalMessagesNotificationsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },
  },
});

export const {
  createPortalMessagesNotifications,
  createPortalMessagesNotificationsSuccess,
  createPortalMessagesNotificationsFailure,
  updatePortalMessagesNotifications,
  updatePortalMessagesNotificationsSuccess,
  updatePortalMessagesNotificationsFailure,
} = portalMessagesNotificationsSlice.actions;
export const portalMessagesNotificationsSelector = (state) => state.invoices;
export default portalMessagesNotificationsSlice.reducer;

export function createPortalMessagesNotificationsService(payload) {
  return async (dispatch) => {
    dispatch(createPortalMessagesNotifications());

    try {
      dispatch(createPortalMessagesNotificationsSuccess(payload));
    } catch (error) {
      dispatch(createPortalMessagesNotificationsFailure(error));
    }
  };
}

export function updatePortalMessagesNotificationsService(payload) {
  return async (dispatch) => {
    dispatch(updatePortalMessagesNotifications());
    try {
      dispatch(updatePortalMessagesNotificationsSuccess(payload));
    } catch (error) {
      dispatch(updatePortalMessagesNotificationsailure(error));
    }
  };
}
