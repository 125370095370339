import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import {
  Grid,
  makeStyles,
  Fab,
  Card as MuiCard,
  Paper as MuiPaper,
  Box as MuiBox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { isEmpty, uniqueId, find } from "lodash";
import Controls from "../controls";
import ConfirmDialog from "../ConfirmDialog";
import moment from "moment";
import Popup from "../Popup";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import InvoiceItemForm from "./InvoiceItemForm";
import DeleteIcon from "@material-ui/icons/Delete";
import NumberFormatCustom from "../controls/NumberFormatCustom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchEntitiesService as fetchProductGiftReasonService } from "../../redux/slices/productGiftReasonSlice.js";

var dataSliceproductGiftReason = {
  stateListName: "productGiftReason",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

export default function ExtraPaymentForm(props) {
  const dispatch = useDispatch();
  const [editItem, setEditItem] = useState(null);
  const { productGiftReason } = useSelector((state) => state.productGiftReason);
  const { gateways, saveExtraPayment, products, users, contactID } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [openItem, setOpenItem] = useState(false);
  const [items, setItems] = useState([]);
  const [file, setFile] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const closeNotification = () => {
    setOpenNotification(false);
  };
  const { authUser } = useSelector((state) => state.auth);

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };
  const onDeleteItem = (id) => {
    const newItems = items.filter((item) => {
      return item.id !== id;
    });
    setItems(newItems);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const saveInvoiceItem = (data) => {
    // console.log("SAVE INVOICE ITEM ",data);
    setEditItem(data);
    const existItem = find(items, {
      name: data.productName,
      responsible: data.responsible,
    });

    if (existItem) {
      setOpenNotification(true);
      return;
    }
    const item = {
      product_id: data.productId,
      name: data.productName,
      price: parseFloat(data.productPrice).toFixed(2),
      type: data.type,
      responsible: !isEmpty(data.responsible) ? data.responsible : "",
      calendar_date: !isEmpty(data.invoiceItemDate)
        ? moment(data.invoiceItemDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      override_price: null,
      override_price_by: null,
      override_price_date: null,
      fromAdd: true,
      base_price: parseFloat(data.productPrice),
      product_gift_reason_id: data.product_gift_reason_id,
      gift_reference_price: data.gift_reference_price,
      gift_user_id: data.gift_user_id
        ? data.gift_user_id
        : data.product_gift_reason_id && data.product_gift_reason_id > 0
        ? authUser.id
        : null,
      quantity: data.quantity,
      isProcedure: data.isProcedure,
      void: 0,
      product_apply_financing: data.productApplyFinancing,
      user_product_price_id: data.user_product_price_id,
      full_payment_due: data.full_payment_due,
      late_payment_fee: data.late_payment_fee,
      is_revision: data?.is_revision || 0,
      revision_comment: data?.revision_comment || null,
    };

    setItems([
      ...items,
      {
        ...item,
        id: uniqueId("invoice_item_"),
        quantity: 1,
      },
    ]);

    closeItemPopup();
    setValue("amount", calculateTotal(), {
      shouldValidate: true,
    });
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    setValue("amount", subtotal, {
      shouldValidate: true,
    });
    return subtotal;
  };

  const calculateSubtotal = () => {
    return items.reduce((total, item) => total + calculateItemTotal(item), 0);
  };

  const classes = useStyles();

  const paymentOptions = () => {
    return gateways.map((gateway) => {
      return {
        id: gateway.name,
        title: gateway.name,
      };
    });
  };
  const addInvoiceItem = () => {
    setEditItem(null);
    setOpenItem(true);
  };

  const closeItemPopup = () => {
    setEditItem(null);
    setOpenItem(false);
  };

  const onSubmit = (data) => {
    data.items = items;
    // console.log("DATA ON SUBMIT: ", data);
    saveExtraPayment(data);
    reset();
  };
  const updateItemQuantity = (id, value) => {
    const newItems = items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: value,
        };
      }
      return item;
    });
    setItems(newItems);
  };

  useEffect(() => {
    if (isEmpty(productGiftReason)) {
      dispatch(fetchProductGiftReasonService(dataSliceproductGiftReason));
    }
  }, []);

  const renderItems = () => {
    return items.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            <ListItem>
              {item.responsible && (
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={item.name}
                secondary={item.responsible ? item.responsible.title : null}
              />
            </ListItem>
          </TableCell>
          <TableCell>
            {" "}
            {item.calendar_date
              ? moment(new Date(item.calendar_date)).format("MM/DD/YYYY")
              : ""}
          </TableCell>
          <TableCell>$ {parseFloat(item.price).toFixed(2)}</TableCell>
          <TableCell>
            <TextField
              className={classes.formControl}
              value={item.quantity || 1}
              onChange={(e) => updateItemQuantity(item.id, e.target.value)}
              inputProps={{
                decimalPrecision: 0,
                decimalSeparator: false,
                allowNegative: false,
                isAllowed: (values) => {
                  const { floatValue } = values;
                  return floatValue >= 1 && floatValue <= 100;
                },
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </TableCell>

          <TableCell align="right">
            $ {calculateItemTotal(item).toFixed(2)}
          </TableCell>

          <TableCell align="right">
            <Controls.IconButton
              color="primary"
              onClick={() =>
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this item?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => onDeleteItem(item.id),
                })
              }
            >
              <DeleteIcon size="small" />
            </Controls.IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing="2">
        <Grid item xs={12}>
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            style={{ float: "right" }}
            onClick={addInvoiceItem}
          >
            <AddIcon />
          </Fab>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Calendar Date</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Quantity</TableCell>
                {/*<TableCell>Discount</TableCell>*/}
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {renderItems()}

              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="right">Subtotal</TableCell>
                <TableCell align="right">
                  $ {calculateSubtotal().toFixed(2)}
                </TableCell>
                <TableCell />
              </TableRow>

              {/*
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">Discount</TableCell>
                    <TableCell align="right">
                      $ {calculateTotalDiscount().toFixed(2)}
                    </TableCell>
                    
                    <TableCell />
                  </TableRow>
                  */}

              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    Total
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    $ {calculateTotal().toFixed(2)}
                  </Box>
                </TableCell>
                <TableCell />
              </TableRow>

              <TableRow></TableRow>
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container spacing="2">
        <Grid item xs={5}>
          <Controller
            name="payment_type"
            control={control}
            defaultValue=""
            as={({ onChange, value }) => (
              <Controls.Select
                label="Payment"
                options={paymentOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
            rules={{ required: "Payment type is required" }}
          />
          {errors.payment_type && (
            <p className="error">{errors.payment_type.message}</p>
          )}
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="amount"
            control={control}
            defaultValue=""
            as={<Controls.InputMoney label="Amount" disabled />}
            rules={{ required: "Amount is required" }}
          />
          {errors.amount && <p className="error">{errors.amount.message}</p>}
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="payment_date"
            type="date"
            control={control}
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.DateTimePicker
                label="Date"
                shouldCloseOnSelect
                onChange={(date) => {
                  const fdate = moment(date).format("MM/DD/yyyy");
                  //console.log(fdate);
                  onChange(fdate);
                }}
                value={value}
              />
            )}
            rules={{
              required: "Date is required",
              pattern: {
                value:
                  /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,
              },
            }}
          />
          {errors.payment_date && (
            <p className="error">{errors.payment_date.message}</p>
          )}
        </Grid>

        <Grid item xs={2}>
          <Controller
            control={control}
            name="file"
            as={({ onChange, value }) => (
              <Controls.DropzoneDialog
                label="Attach file"
                onChange={(files) => {
                  setFile(files[0]);
                  onChange(files[0]);
                }}
                value={value}
              />
            )}
            rules={{ required: "File is required" }}
          />
          {errors.file && <p className="error">{errors.file.message}</p>}
          {file && <span>{file.path}</span>}
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="details"
            control={control}
            defaultValue=""
            as={<Controls.Textarea label="Details" rows="2" />}
          />

          <div>
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
        <Popup
          title="Invoice item"
          fullWidth={true}
          openPopup={openItem}
          onClose={closeItemPopup}
        >
          <InvoiceItemForm
            users={users}
            products={products}
            item={editItem}
            saveItem={(data) => saveInvoiceItem(data)}
            productGiftReason={productGiftReason}
            contactID={contactID}
          />
        </Popup>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Popup
          title="Notification"
          fullWidth={false}
          openPopup={openNotification}
          onClose={closeNotification}
        >
          This item already exist , please select another one
        </Popup>
      </Grid>
    </form>
  );
}
