import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../lib/api";
import DocumentFormNew from "./forms/NewUploadDocumentForm/DocumentFormNew.js";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  Paper,
  Checkbox,
  Fab,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
  Select as MuiSelect,
  Button as MuiButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Popup from "./Popup";
import PropTypes from "prop-types";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "./controls/Button";
import { Edit } from "@material-ui/icons";
import { findIndex } from "lodash";
import CRMUtils from "../utils";

import Iconify from "./Iconify.js";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DocumentsContact(props) {
  const {
    //  documents,
    contact_id,
    documentTypes,
    onSave,
    //  onDeleteDocument,
    showDelete,
    canAdd = true,
    canEdit = true,
    canDelete = true,
    enableCamera = false,
    rReadonly = false,
    documentsTab = 0,
    consultantErrorHandle = null,
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [documentstoshow, setdocumentstoshow] = useState([]);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(documentsTab);
  const [filterValue, setFilterValue] = useState("All Documents");
  const [types, setTypes] = useState([]);
  const [dataMaterialTablePhotosSent, setDataMaterialTable] = useState([]);
  const [documentForEdit, setDocumentForEdit] = useState(null);
  const [documentName, setDocumentName] = useState(
    documentForEdit ? documentForEdit.name : ""
  );
  const userCanEditDocument = CRMUtils.userHasPermission(authUser, [
    "user_editcontactdocuments",
  ]);
  const dataMaterialTable = documentstoshow
    ? documentstoshow.map((o) => ({ ...o }))
    : [];

  const canUserViewTheDocument = (documentType = "", documentID = null) => {
    if (documentType && documentID) {
      var cleanName = documentType
        .replaceAll(" ", "")
        .replaceAll("_", "")
        .toLowerCase();

      return CRMUtils.userHasPermission(authUser, [
        "doctypesview_" + cleanName + "_" + documentID,
      ]);
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (
      documentstoshow &&
      (filterValue == "All Documents" ||
        filterValue == "Image Uploaded from Invitation Link")
    ) {
      setDataMaterialTable(
        documentstoshow
          ? documentstoshow
              .filter(
                (a) =>
                  a.collection_name == "Image Uploaded from Invitation Link"
              )
              .map((o) => ({ ...o }))
          : []
      );
    }
  }, [documentstoshow]);

  const headCellsMaterial = [
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return rowData.collection_name;
      },
    },
    {
      field: "name",
      title: "Name",
      render: (rowData) => {
        return <div className={classes.wrapText}>{rowData.name}</div>;
      },
    },
    {
      field: "uploaded_by",
      title: "Uploaded By",
    },

    {
      field: "created_at",
      title: "Created",
      render: (rowData) => {
        return rowData.created_at
          ? moment(rowData.created_at).format("YYYY-MM-DD")
          : "";
      },
    },
    {
      field: "file",
      title: "File",

      render: (rowData) =>
        canUserViewTheDocument(rowData.doc_type_name, rowData.doc_type_id) ? (
          rowData.url &&
          rowData.mime_type &&
          rowData.mime_type.includes("image/") ? (
            <a href={rowData.url} target="_blank" rel="noreferrer">
              <Tooltip
                title={
                  <img
                    style={{ maxWidth: 220, maxHeight: 220 }}
                    src={rowData.url}
                  />
                }
              >
                <span>
                  <MuiButton>
                    <img
                      style={{ maxWidth: 90, maxHeight: 90 }}
                      src={rowData.url}
                    />
                  </MuiButton>
                </span>
              </Tooltip>
            </a>
          ) : rowData.url ? (
            <a href={rowData.url} target="_blank" rel="noreferrer">
              <AttachFileIcon />
            </a>
          ) : (
            ""
          )
        ) : rowData.url ? (
          <AttachFileIcon style={{ color: "#999999" }} />
        ) : (
          ""
        ),
    },
    {
      field: "model",
      title: "From",
    },
    /*{
      field: "mime",
      title: "Mime",
      render: (rowData) => {
        return rowData.mime_type;
      },
    },*/
  ];

  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);

  const onClickEdit = (rowData) => {
    setDocumentForEdit(rowData);
    setOpenPopupEdit(true);
  };

  const onCloseEdit = () => {
    setOpenPopupEdit(false);
    setDocumentForEdit(null);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const [progressBar, setProgressBar] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onDeleteDocument = async (orgId, id) => {
    try {
      const { data } = await Api.deleteDocument(orgId, id);

      let filteredArray = documentstoshow.filter((item) => item.id !== id);

      setdocumentstoshow(filteredArray);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const onDelete = async (id) => {
    const orgId = authUser.organization_id;
    onDeleteDocument(orgId, id);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact_id) {
      setProgressBar(true);
      documentsFunc().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const documentsFunc = async (payload = null) => {
    try {
      const { data } = await Api.getContactDocument(contact_id, payload);
      setdocumentstoshow(data.data);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  var docTypes = [];

  useEffect(() => {
    if (documentstoshow && types.length == 0) {
      var id = 0;
      documentstoshow?.map((a) => {
        var index = docTypes.filter((g) => a.collection_name == g.title);

        if (index.length == 0) id += 1;
        return index.length == 0
          ? docTypes.push({ id: id, title: a.collection_name })
          : "";
      });
      setTypes([...docTypes]);
    }
  }, [documentstoshow]);

  // const saveDocument = async (payload) => {
  //   try {
  //     setOpenPopup(false);
  //     let formData = new FormData();
  //     Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

  //     const { data } = await Api.createContactDocument(contact_id, formData);
  //     setdocumentstoshow([data.data, ...documentstoshow]);
  //   } catch (e) {
  //     if (typeof consultantErrorHandle === "function") {
  //       consultantErrorHandle(e.data.message);
  //     }
  //   }
  // };

  const saveDocument = async (payload) => {
    try {
      setOpenPopup(false);

      let formData = new FormData();  

      Object.keys(payload).forEach((key) => {
        if (key === "files") {
          payload[key].forEach((element, index) => {
            formData.append(`files[${index}]`, element);
          });
        } else {
          formData.append(key, payload[key]);
        }
      });

      
      const { data } = await Api.createContactDocuments(contact_id, formData);

      if (data && data.data) {
        setdocumentstoshow([...data.data, ...documentstoshow]);
      }
    } catch (e) {
      console.log("error _", e);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const editDocummentName = async (payload, id) => {
    const { data } = await Api.updateContactDocumment(payload, id);

    const index = findIndex(documentstoshow, ["id", data.data.id]);
    const entity = { ...documentstoshow[index], ...data.data };
    let entities = [...documentstoshow];
    entities[index] = entity;

    setdocumentstoshow([...entities]);
    onCloseEdit();
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <AppBar
          style={{ maxHeight: 67, marginLeft: 12, marginBottom: -23 }}
          position="static"
          color="default"
        >
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab
              icon={<DescriptionIcon />}
              label={"ALL DOCUMENTS"}
              {...a11yProps(0)}
            />
            <Tab
              label={"UPLOADED IMAGES"}
              icon={<PhotoLibraryIcon />}
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={tabValue} index={0} style={{ marginTop: -77 }}>
          <>
            <TblContainer>
              <div
                style={{
                  zIndex: 10,
                  position: "relative",
                  top: -6,
                  left: 344,
                  maxWidth: "fit-content",
                }}
              >
                <TextField
                  style={{ marginRight: 6, maxWidth: 130 }}
                  variant="outlined"
                  defaultValue={filterValue}
                  label="Types"
                  onChange={(value) => {
                    console.log(value.target.value);
                    setFilterValue(value.target.value);
                  }}
                  select
                >
                  <MenuItem key={0} value="All Documents">
                    All Documents
                  </MenuItem>
                  {types.map((item) =>
                    item.title != null ? (
                      <MenuItem key={item.id} value={item.title}>
                        {item.title}
                      </MenuItem>
                    ) : (
                      <MenuItem key={item.id} value={item.title}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
                </TextField>
                <Button
                  startIcon={<FilterListIcon />}
                  text="Filter"
                  onClick={() => {
                    setProgressBar(true);
                    documentsFunc(filterValue).then(function () {
                      setProgressBar(false);
                    });
                  }}
                />
                <Button
                  startIcon={<CloseIcon />}
                  text="Clear"
                  onClick={() => {
                    setProgressBar(true);
                    setFilterValue("All Documents");
                    documentsFunc("null").then(function () {
                      setProgressBar(false);
                    });
                  }}
                />
              </div>

              <MaterialTable
                isLoading={progressBar}
                columns={headCellsMaterial}
                data={dataMaterialTable}
                actions={[
                  {
                    icon: () => (
                      <Fab color="primary" aria-label="add">
                        <Iconify
                          icon={"fluent:add-12-filled"}
                          width={25}
                          height={25}
                        />
                      </Fab>
                    ),
                    tooltip: "Attach document",
                    disabled: !canAdd || rReadonly,
                    hidden: !canAdd || rReadonly,
                    isFreeAction: true,
                    onClick: (event) => {
                      openInPopup();
                    },
                  },
                  (rowData) => ({
                    icon: () => (
                      <Controls.IconButton
                        style={{
                          color:
                            !userCanEditDocument || rReadonly
                              ? "#bdbdbd"
                              : "#009688",
                        }}
                      >
                        <Iconify
                          icon={"fluent:edit-24-filled"}
                          width={20}
                          height={20}
                        />
                      </Controls.IconButton>
                    ),
                    tooltip: "Edit Document Name",
                    disabled: !userCanEditDocument,
                    hidden: !userCanEditDocument || rReadonly,
                    onClick: (event, rowData) => onClickEdit(rowData),
                  }),
                  (rowData) => ({
                    icon: () => (
                      <Controls.IconButton
                        style={{
                          color:
                            !showDelete || !canDelete || rReadonly
                              ? "#bdbdbd"
                              : "#f44336",
                        }}
                      >
                        <Iconify
                          icon={"mingcute:delete-fill"}
                          width={20}
                          height={20}
                        />
                      </Controls.IconButton>
                    ),
                    tooltip: "Delete",
                    disabled: !showDelete || !canDelete || rReadonly,
                    hidden: !showDelete || !canDelete || rReadonly,
                    onClick: (event, rowData) =>
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this document?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => onDelete(rowData.id),
                      }),
                  }),
                ]}
                options={{
                  loadingType: "linear",
                  pageSize: 10, // make initial page size
                  emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                  actionsColumnIndex: -1,
                }}
                title="Documents"
              />
            </TblContainer>
          </>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TblContainer>
            <MaterialTable
              isLoading={progressBar}
              columns={headCellsMaterial}
              data={dataMaterialTablePhotosSent}
              options={{
                loadingType: "linear",
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                actionsColumnIndex: -1,
              }}
              title="Uploaded Images"
              actions={[
                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton color="primary">
                      <Edit fontSize="small" />
                    </Controls.IconButton>
                  ),
                  tooltip: "Edit Document Name",
                  disabled: false,
                  hidden: !showDelete || !canDelete || rReadonly,
                  onClick: (event, rowData) => onClickEdit(rowData),
                }),
              ]}
            />
          </TblContainer>
        </TabPanel>
      </Paper>
      <Popup
        title={"Add documents"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <DocumentFormNew
          saveDocument={saveDocument}
          documentTypes={documentTypes}
          className={classes.form}
          enableCamera={enableCamera}
        />
      </Popup>

      <Popup
        maxWidth={"xs"}
        title={"Edit Document"}
        fullWidth={true}
        openPopup={openPopupEdit}
        onClose={onCloseEdit}
      >
        <div style={{ display: "flex" }}>
          <TextField
            onChange={(e) => {
              setDocumentName(e.target.value);
            }}
            style={{ width: "100%" }}
            label="Documment Name"
            variant="outlined"
            defaultValue={
              documentForEdit && documentForEdit.name
                ? documentForEdit.name
                : ""
            }
          ></TextField>
          <Button
            text={"Submit"}
            onClick={() => {
              var payload = { name: documentName };
              editDocummentName(payload, documentForEdit.id);
            }}
          />
        </div>
      </Popup>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default DocumentsContact;
