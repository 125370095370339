import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  contractsReports: [],
};

const contractsReportsSlice = createSlice({
  name: "contractsReports",
  initialState,
  reducers: {
    getcontractsReports: (state) => {
      state.loading = true;
    },
    getcontractsReportsSuccess: (state, { payload }) => {
      state.contractsReports = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getcontractsReportsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    
  },
});

export const {
  getcontractsReports,
  getcontractsReportsSuccess,
  getcontractsReportsFailure,
 
} = contractsReportsSlice.actions;

export const contractsReportsSelector = (state) => state.reports;
export default contractsReportsSlice.reducer;

export function fetchcontractsReportsService(orgId) {
  return async (dispatch) => {
    dispatch(getcontractsReports());

    try {
      const response = await Api.getContractsReport(orgId);
      dispatch(getcontractsReportsSuccess(response.data));
    } catch (error) {
      dispatch(getcontractsReportsFailure(error));
    }
  };
}


