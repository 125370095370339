import React from "react";
import { InputAdornment, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function InputDecoration(props) {
  const { name, label, position, decoration, onChange, ...other } = props;
  const classes = useStyles();
  let inputProps = {};
  if (position === "start")
    inputProps.startAdornment = (
      <InputAdornment position="start">{decoration}</InputAdornment>
    );
  if (position === "end")
    inputProps.endAdornment = (
      <InputAdornment position="end">{decoration}</InputAdornment>
    );
  return (
    <TextField
      label={label}
      onChange={onChange}
      {...other}
      InputProps={inputProps}
      variant="outlined"
      className={classes.textfield}
    />

  );
}
