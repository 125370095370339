import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import CommentForm from "./forms/CommentForm";
import moment from "moment";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";

import useTable from "./useTable";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
}));

function TicketMessages(props) {
  const classes = useStyles();
  const { comments, onSave, ticketclosed } = props;

  const { TblContainer } = useTable();

  const saveComment = (data) => {
    onSave(data);
  };

  const dataMaterialTable = comments.map((o) => ({ ...o }));
  const headCellsMaterial = [
    { field: "comment", title: "Message" },
    {
      field: "user_id",
      title: "Created By",
      render: (rowData) => {
        return rowData.user.data ? rowData.user.data.title : "";
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.user.data.title.toLowerCase().indexOf(term.toLowerCase()) != -1,
    },
    {
      field: "created",
      title: "Created Date",
      render: (rowData) => {
        return new moment(rowData.created_at).format("YYYY-MM-DD");
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.created_at.toLowerCase().indexOf(term.toLowerCase()) != -1,
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              pageSize: 5,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
            }}
            title="Ticket Messages"
          />
        </TblContainer>
      </Paper>

      <CommentForm
        saveComment={saveComment}
        className={classes.form}
        ticketclosed={ticketclosed}
      />
    </Wrapper>
  );
}

export default TicketMessages;
