import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, find } from "lodash";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "./UserProcedureHeatmap.css";
import { Paper, Card as MuiCard, makeStyles } from "@material-ui/core";

import Moment from "moment";
import { extendMoment } from "moment-range";

import Api from "../lib/api";
import HeatMapCalendarMonthly from "./HeatMapCalendarMonthly";

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const moment = extendMoment(Moment);

function UserProcedureHeatmap4(props) {
  const {
    user,
    setDate,
    disableDates2,
    user_id = null,
    monthWidth = "190px",
    showDay = true,
    date = null,
    minDate = moment().add(1, "days"),
  } = props;
  const { t, i18n } = useTranslation("users");

  const userLimit = user.daily_limit ? parseInt(user.daily_limit) : 10;

  const [totalRecords, setTotalRecords] = useState([]);

  const getDayColor = (date) => {
    if (
      disableDates2(moment(date).format("YYYY-MM-DD")) ||
      moment(moment(date).format("YYYY-MM-DD")).isBefore(
        moment(moment(minDate).format("YYYY-MM-DD"))
      )
    ) {
      return "rgb(67, 67, 67)";
    }

    const value = find(totalRecords, {
      date: moment(date).format("YYYY-MM-DD"),
    });
    var total = 0;
    if (value) {
      total = value.total;
    }

    var result = (total / userLimit) * 100;

    if (result <= 45) {
      return "rgb(77, 217, 131)";
    } else {
      if (result > 0 && result <= 90) {
        return "rgb(239, 239, 23)";
      } else {
        if (result > 0 && result <= 100) {
          return "rgb(219, 71, 71)";
        } else {
          return "rgb(109, 109, 109)";
        }
      }
    }
  };

  const getRemoteData = async () => {
    const { data } = await Api.getUserRecordProductsGroupByDate(
      user_id ? user_id : user.id
    );
    setTotalRecords(data);
  };

  useEffect(() => {
    getRemoteData();
  }, [user, user_id]);

  return (
    <HeatMapCalendarMonthly
      minDate={minDate}
      date={date}
      setDate={setDate}
      showDay={showDay}
      monthWidth={monthWidth}
      dayColor={getDayColor}
      isDateDisabled={disableDates2}
    />
  );
}

export default UserProcedureHeatmap4;
