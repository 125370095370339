import React, { useState, useEffect} from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import CommentForm from "./forms/CommentForm";
import moment from "moment";
import { Fab, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import useTable from "./useTable";
import Popup from "./Popup";
import Api from "../lib/api";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
}));

function CommentsRP(props) {
  const classes = useStyles();
  const {
    record_id,
   // onSave,
    recordProductForEdit,
    updateRecordProductData,
    rReadonly = false,
    readonly = false,
    canAdd = true,
    editableNoDoctor = false,
  } = props;
  const [progressBar, setProgressBar] = useState(false);
  const [comments, setComments] = useState([]);
  const { TblContainer } = useTable();
  const [openPopup, setOpenPopup] = useState(false);

  const saveComment = (data) => {
    onSave(data);
    onClose();
  };

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onSave = async (payload) => {
    const { data } = await Api.createRecordComment(
      recordProductForEdit.record.data.id,
      payload
    );

    setComments([data.data,...comments]);

    let record = recordProductForEdit.record;

    const newRecord = {
      ...record.data,
      contactRecordComments: {
        ...record.data.contactRecordComments,
        data: [...record.data.contactRecordComments.data, data.data],
      },
    };

   // const newRecordProductForEdit = {
  //    ...recordProductForEdit,
 //     record: { data: newRecord },
 //   };
    //console.log("newRecordProductForEdit", newRecordProductForEdit);
    //return;

    //updateRecordProductData(newRecordProductForEdit);
  };

  const onClose = () => {
    setOpenPopup(false);
  };
  const dataMaterialTable =comments? comments.map((o) => ({ ...o })) : [];
  const headCellsMaterial = [
    { field: "comment", title: "Comment" },
    {
      field: "user_id",
      title: "Created By",
      render: (rowData) => {
        return rowData.user.data ? rowData.user.data.title : "";
      },
    },
    {
      field: "created_at",
      title: "Created",
      render: (rowData) => {
        return new moment(rowData.created_at).format("YYYY-MM-DD HH:mm:ss");
      },

      customSort: (a, b) =>
        new Date(
          moment(a.created_at).utc().format("YYYY-MM-DD HH:mm:ss")
        ).getTime() -
        new Date(
          moment(b.created_at).utc().format("YYYY-MM-DD HH:mm:ss")
        ).getTime(),
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });


  useEffect(() => {
    //  setLocalToDos(toDos);
    if (record_id) {
      setProgressBar(true);
      getDocumentsSend().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getDocumentsSend = async () => {
    const { data } = await Api.getCommentsRecord(record_id);
    setComments([...data.data]);
  };


  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
          isLoading= {progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              loadingType: 'linear',
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              sorting: true,
            }}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                ),
                tooltip: "Attach document",
                disabled: !canAdd || readonly,
                hidden: !canAdd || readonly,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },]}
            title="Comments"
          />
        </TblContainer>
      </Paper>

      <Popup  title={"Add comment"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >      
      <CommentForm
        saveComment={saveComment}
        className={classes.form}
        canAdd={canAdd}
        readonly={readonly}
        //rReadonly={rReadonly}
        editableNoDoctor={editableNoDoctor}
      />
      </Popup>

    </Wrapper>
  );
}

export default CommentsRP;
