import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Fab,
  Tooltip,
} from "@material-ui/core";
import Api from "../lib/api";
import { useSelector } from "react-redux";
import CRMUtils from "../utils";
import Iconify from "./Iconify";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";

export default function MarkOrUnamrkContactAsTransfer(props) {
  const { recordId, transferedAt, massiveArray } = props;

  const { authUser } = useSelector((state) => state.auth);

  const userCanCreateTransferedDate = CRMUtils.userHasPermission(authUser, [
    "record_createtransfereddate",
  ]);
  const userCanEditTransferedDate = CRMUtils.userHasPermission(authUser, [
    "record_edittransfereddate",
  ]);

  const [patientTransferedAt, setPatientTransferedAt] = useState(transferedAt);
  const [isMarkingContact, setIsMarkingContact] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const MarkOrUnamrkContactAsTransfer = async (option) => {
    try {
      handleCloseMenu();
      setIsMarkingContact(true);
      const { data } = await Api.MarkOrUnamrkContactAsTransfer(recordId, {
        massiveArray: massiveArray,
        option: option,
      });
      setIsMarkingContact(false);
      setPatientTransferedAt(data.transfered_at);
      setNotify({
        isOpen: true,
        message:
          (massiveArray ? "Patients" : "Patient") + " Updated Succesfully",
        type: "success",
      });
      setConfirmDialog({
        isOpen: false,
      });
    } catch (e) {
      setNotify({
        isOpen: true,
        message: "Unable To Update " + (massiveArray ? "Patients" : "Patient"),
        type: "error",
      });
      setConfirmDialog({
        isOpen: false,
      });
    }
  };

  if (!userCanCreateTransferedDate && !userCanEditTransferedDate) {
    return null;
  }

  if (massiveArray) {
    return (
      <div>
        <Tooltip
          title={
            userCanEditTransferedDate
              ? "Transfer or Untransfer Patient "
              : "Transfer Patient"
          }
        >
          <Fab
            onClick={
              userCanEditTransferedDate
                ? handleClickMenu
                : () =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are You Sure To Transfer These Patients?",
                      onConfirm: () => MarkOrUnamrkContactAsTransfer("set"),
                    })
            }
            disabled={isMarkingContact ? true : false}
            color="primary"
          >
            <Iconify icon={"mdi:user-switch"} width={25} height={25} />
          </Fab>
        </Tooltip>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are You Sure To Transfer These Patients?",
                onConfirm: () => MarkOrUnamrkContactAsTransfer("set"),
              });
            }}
          >
            <div style={{ display: "flex" }}>
              <Iconify
                icon={"material-symbols:check-small"}
                width={25}
                height={25}
              />
              <Typography>Transfer Selected Patients</Typography>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are You Sure To Untransfer These Patients?",
                onConfirm: () => MarkOrUnamrkContactAsTransfer("clear"),
              });
            }}
          >
            <div style={{ display: "flex" }}>
              <Iconify icon={"iconoir:cancel"} width={20} height={20} />
              <Typography style={{ marginLeft: 5 }}>
                {" "}
                Untransfer Selected Patients
              </Typography>
            </div>
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              MarkOrUnamrkContactAsTransfer("switch");
            }}
          >
            Switch selected patients transferred propertie
          </MenuItem> */}
        </Menu>
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          disableConfirm={isMarkingContact}
        />
      </div>
    );
  } else {
    return (
      <div>
        {" "}
        <Button
          startIcon={
            <Iconify
              icon={
                patientTransferedAt
                  ? "pajamas:status-cancelled"
                  : "ic:round-check-circle"
              }
            />
          }
          variant="contained"
          onClick={() =>
            setConfirmDialog({
              isOpen: true,
              title:
                "Are You Sure To " +
                (patientTransferedAt ? "Untransfer" : "Transfer") +
                " This Patient?",
              onConfirm: () => MarkOrUnamrkContactAsTransfer(null),
            })
          }
          color="primary"
          disabled={
            userCanEditTransferedDate
              ? isMarkingContact
                ? true
                : false
              : isMarkingContact || patientTransferedAt
              ? true
              : false
          }
        >
          <Typography>
            {userCanEditTransferedDate
              ? patientTransferedAt
                ? "Untransfer"
                : "Transfer"
              : patientTransferedAt
              ? "Transferred"
              : "Transfer"}
          </Typography>

          {isMarkingContact && (
            <CircularProgress size={20} style={{ marginLeft: 10 }} />
          )}
        </Button>
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          disableConfirm={isMarkingContact}
        />
      </div>
    );
  }
}
