import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import {
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Iconify from "../../components/Iconify";

import Comments from "../../components/Comments";
import Documents from "../../components/Documents";

import Responsible from "../../components/Responsible";
import Timeline from "../../components/Timeline";
import moment from "moment";
import { findIndex, isEmpty, isObject } from "lodash";
import { spacing, display } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { approveQuoteService } from "../../redux/slices/quotes";
import Api from "../../lib/api";
import { fetchContactsService } from "../../redux/slices/contacts";

import { fetchPaymentGatewaysService } from "../../redux/slices/paymentgateways";
import { fetchDocumentTypesService } from "../../redux/slices/documenttypes";
import { fetchUsersService } from "../../redux/slices/users";
import QuoteBuilderDeleted from "../../components/QuoteBuilderDeleted";

import CRMUtils from "../../utils";
import {
  createInvoiceService,
  fetchInvoicesByContactService,
} from "../../redux/slices/invoices";
import { fetchEntitiesService as fetchFinancingService } from "../../redux/slices/financingSlice.js";
import { fetchEntitiesService as fetchProductsService } from "../../redux/slices/productsnewSlice.js";

var dataSliceProduct = {
  stateListName: "productsNew",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
import Notification from "../../components/Notification";
import { yellow } from "@material-ui/core/colors";

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    height: "100%",
  },
  card: {
    position: "relative",
  },
  ribbon: {
    backgroundColor: "skyblue",
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    padding: 5,
    font: "Lato",
    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 60,
    marginLeft: -40,
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const statusArray = [
  {
    label: "Unsend",
    color: grey[700],
    id: "unsend",
  },
  {
    label: "Sent",
    color: blue[700],
    id: "sent",
  },
  {
    label: "Approved",
    color: green[700],
    id: "approved",
  },
  {
    label: "Declined",
    color: red[700],
    id: "declined",
  },

  {
    label: "Canceled",
    color: red[700],
    id: "canceled",
  },
  {
    label: "Re-sign Needed",
    color: yellow[700],
    id: "re-sign_needed",
  },
];

function QuoteDetailsDeleted(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    onApprove,
    readOnlySalesManager = false,
    setQuotes,
    quotes,
    contact_id,
    userIsbillingMGAssistant = false,
  } = props;
  const { contacts } = useSelector((state) => state.contacts);
  const { authUser } = useSelector((state) => state.auth);
  dataSliceProduct.orgId = authUser.organization_id;

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  const { productsNew } = useSelector((state) => state.productsNew);
  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const { users } = useSelector((state) => state.users);
  const { documentTypes } = useSelector((state) => state.documenttypes);
  const [value, setValue] = useState(0);
  const [quote, setQuote] = useState({});
  const [items, setItems] = useState([]);

  const { financing } = useSelector((state) => state.financing);

  const saveQuote = async (localquote) => {
    const quoteitems = localquote.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        product_id: item.product_id,
        item_name: item.name,
        product_gift_reason_id: item.product_gift_reason_id,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });
    var payload = {
      items: quoteitems,
      status: localquote.status,
      contact_id: quote.contact_id,
      included: localquote.included,
      expiration_date: localquote.expiration_date
        ? localquote.expiration_date
        : null,
      financing_id: localquote.financing_id,
      interest: localquote.interest ? localquote.interest : null,
      user_id: localquote.user_id ? localquote.user_id : authUser.id,
      approved_at: localquote.approved_at,
    };

    const { data } = await Api.updateQuote(quote.id, payload);
    setQuote(data.data);

    if (data.data) {
      const index = findIndex(quotes, ["id", data.data.id]);
      const entity = { ...quotes[index], ...data.data };
      let entities = [...quotes];
      entities[index] = entity;

      setQuotes([...entities]);
    }
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getQuote = async () => {
    const { data } = await Api.getQuoteDelted(props.quote.id);
    setQuote(data.data);
  };

  const saveComment = async (payload) => {
    const { data } = await Api.createtQuoteComment(quote.id, payload);

    setQuote({
      ...quote,
      items: {
        ...quote.items,
        data: items,
      },
      comments: {
        ...quote.comments,
        data: [...quote.comments.data, data.data],
      },
    });
  };

  const updateContact = (data) => {
    setQuote({
      ...quote,
      contact: {
        data: data,
      },
      contact_id: data.id,
    });
  };

  const saveDocument = async (payload) => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.createtQuoteDocument(quote.id, formData);

    setQuote({
      ...quote,
      items: {
        ...quote.items,
        data: items,
      },
      documents: {
        ...quote.documents,
        data: [...quote.documents.data, data.data],
      },
    });
  };

  const saveResponsible = async (payload) => {
    const { data } = await Api.attachQuoteResponsible(quote.id, {
      responsibleId: payload.responsibles.id,
    });

    setQuote({
      ...quote,
      items: {
        ...quote.items,
        data: items,
      },
      responsibles: {
        data: data.data,
      },
    });
  };
  const deleteResponsible = async (id) => {
    await Api.deleteQuoteResponsible(quote.id, id).then(() => {
      const responsibles = quote.responsibles.data.filter((responsible) => {
        return responsible.id !== id;
      });
      setQuote({
        ...quote,
        responsibles: {
          data: [...responsibles],
        },
      });
    });
  };

  const convert2Invoice = (items) => {
    const payload = {
      name: `Invoice for ${quote.contact.data.full_name}`,
      quote_id: quote.id,
      building_id: quote.building_id,
      contact_id: quote.contact.data.id,
      included: quote.included,
      user_id: quote.user_id,
      organization_id: authUser.organization_id,
      items: items,
      financing_id: quote.financing_id,
      interest: quote.interest,
      downpayment_per_procedure: quote.downpayment_per_procedure,
      downpayment_amount: quote.downpayment_amount,
    };
    dispatch(createInvoiceService(payload)).then((result) => {
      console.log("createInvoiceService ", result);
    });
  };

  const approveQuote = async (id, items, payment, contract, credit) => {
    if (isObject(payment)) {
      const momentDate = new moment(payment.payment_date);
      payment.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    }

    let formData = new FormData();
    if (isObject(payment)) {
      Object.keys(payment).forEach((key) => {
        //   console.log(key, payment[key]);
        formData.append(key, payment[key]);
      });
    }

    if (isObject(credit)) {
      Object.keys(credit).forEach((key) => {
        //  console.log(key, credit[key]);
        formData.append(key, credit[key]);
      });
    }
    formData.append(
      "downpayment_per_procedure",
      quote.downpayment_per_procedure
    );
    formData.append("downpayment_amount", quote.downpayment_amount);
    formData.append("quote_id", quote.id);
    formData.append("name", `Invoice for ${quote.contact.data.full_name}`);
    formData.append("contact_id", quote.contact.data.id);
    formData.append("user_id", authUser.id);
    formData.append("organization_id", authUser.organization_id);
    if (quote.included) {
      formData.append("included", quote.included);
    }

    formData.append("items", JSON.stringify(items));
    if (contract) formData.append("contract", contract);

    await Api.approveQuote(id, formData).then((result) => {
      const index = findIndex(quotes, ["id", quote.id]);
      const entity = {
        ...quotes[index],
        ...{
          ...quote,
          ...{
            status: "approved",
            approved_by: result.data.data.approved_by,
            approved_at: result.data.data.approved_at,
            approvedBy: result.data.data.approvedBy,
          },
        },
      };
      let entities = [...quotes];
      entities[index] = entity;
      setQuotes([...entities]);
      setQuote({
        ...quote,
        status: "approved",
      });
      dispatch(approveQuoteService(result.data)).then(() => {
        dispatch(fetchInvoicesByContactService(quote.contact.data.id));
      });
      setNotify({
        isOpen: true,
        message: "Invoice created",
        type: "success",
      });
      if (typeof onApprove === "function") {
        onApprove();
      }
    });
  };

  useEffect(() => {
    getQuote();
    if (isEmpty(productsNew)) {
      dispatch(fetchProductsService(dataSliceProduct));
    }
    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));
    if (isEmpty(contacts))
      dispatch(fetchContactsService(authUser.organization_id));
    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));
    if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));
    if (isEmpty(financing)) dispatch(fetchFinancingService(dataSliceFinancing));
  }, []);

  useEffect(() => {
    if (!isEmpty(quote)) setItems(quote.items.data);
  }, [quote]);

  if (isEmpty(quote)) return "loading...";

  return (
    <React.Fragment>
      <Helmet title="Quote Details" />

      <Grid container>
        <Grid item xs={7}>
          <QuoteBuilderDeleted
            setQuotes={setQuotes}
            quotes={quotes}
            quote={quote}
            statusOptions={statusArray}
            contacts={contacts}
            users={users}
            products={productsNew}
            contact={quote.contact.data}
            paymentGateways={paymentGateways}
            onSaveQuote={saveQuote}
            onApproveQuote={approveQuote}
            onConvert2Invoice={convert2Invoice}
            readOnlySalesManager={readOnlySalesManager}
            getQuote={getQuote}
          />
        </Grid>
        <Grid item xs={5}>
          <Paper square>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
            >
              <Tab
                icon={
                  <Iconify
                    icon={"mdi:chart-timeline-variant-shimmer"}
                    style={{ color: "#ff5722" }}
                    width={24}
                    height={24}
                  />
                }
                label="Timeline"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fa:comments"}
                    style={{ color: "#607d8b" }}
                    width={24}
                    height={24}
                  />
                }
                label="Comments"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"bi:file-text-fill"}
                    style={{ color: "#03a9f4" }}
                    width={24}
                    height={24}
                  />
                }
                label="Documents"
              />

              <Tab
                icon={
                  <Iconify
                    icon={"fluent:people-24-filled"}
                    style={{ color: "#8bc34a" }}
                    width={24}
                    height={24}
                  />
                }
                label="Responsibles"
              />
            </Tabs>

            <TabPanel value={value} index={0} {...a11yProps(0)}>
              <Timeline
                modelType="Quote"
                model={quote}
                typel="Quote"
                createdAt={quote.created_at}
              />
            </TabPanel>
            <TabPanel value={value} index={1} {...a11yProps(1)}>
              <Comments
                comments={quote.comments.data}
                onSave={saveComment}
                canAdd={false}
              />
            </TabPanel>
            <TabPanel value={value} index={2} {...a11yProps(2)}>
              <Documents
                documents={quote.documents.data}
                documentTypes={documentTypes}
                onSave={saveDocument}
                showDelete={false}
                canAdd={false}
                canDelete={false}
              />
            </TabPanel>

            <TabPanel value={value} index={3} {...a11yProps(4)}>
              <Responsible
                responsibles={quote.responsibles.data}
                users={users}
                onSave={saveResponsible}
                onDelete={deleteResponsible}
                canAdd={false}
                canDelete={false}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </React.Fragment>
  );
}

export default QuoteDetailsDeleted;
