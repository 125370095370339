import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
  _updateOnlyEntityService,
  _updateOnlyMessageCountService,
  _addOnlyEntityService,
} from "./common/sliceFunctions2";

var entitiesName = "newSMS";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function addOnlyEntityService(payload) {
  return _addOnlyEntityService(payload, events);
}
