import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ImagesGroupForm(props) {
  const { addOrEdit, groupForEdit} = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const onSubmit = (data) => {
    addOrEdit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            defaultValue={groupForEdit?.name || ""}
            as={<Controls.Input label="Name" />}
            rules={{ required: "Group name is required" }}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Reset" color="default" />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
