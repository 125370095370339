import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import CameraHD from "../Camera";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
    "& .MuiDropzoneArea-root": {
      minHeight: "100px",
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

export default function DocumentForm(props) {
  const {
    user,
    documentTypes,
    saveDocument,
    enableCamera,
    isSubmitting = false,
    changeDimensions = false,
  } = props;
  const [openCamera, setOpenCamera] = useState(false);
  const [cameraImage, setCameraImage] = useState("");

  const initialData = {
    filename: "",
    docType: "",
    file: null,
  };

  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const documentOptions = () => {
    return documentTypes.map((type) => {
      return {
        id: type.id,
        title: type.name,
      };
    });
  };

  const dataUrlToFile = (dataUrl, fileName, mimeType) => {
    return new File([dataUrl], fileName, { type: mimeType });
  };
  const onCaptureImage = async (data) => {
    const file = dataUrlToFile(data, "captured_image.png", "image/png");

    setValue("fileplain", data);
    //console.log("IMG final ", file);
  };

  const onSubmit = (data) => {
    // console.log("DDDDDDATA ", data);
    //return;

    data["doc_type_id"] = data["docType"];
    data["docType"] =
      documentTypes.find((type) => type.id == data["doc_type_id"])?.name ||
      null;

    saveDocument(data);
    reset(initialData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      {changeDimensions ? (
        <div>
          <Controller
            name="filename"
            control={control}
            defaultValue=""
            as={<Controls.Input label="File Name" />}
            rules={{
              required: "File name is required",
              pattern: {
                value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/i,
                message: "File name can only accept characters",
              },
            }}
          />
          {errors.filename && (
            <p className="error">{errors.filename.message}</p>
          )}

          <Controller
            name="docType"
            control={control}
            defaultValue=""
            as={({ onChange, value }) => (
              <Controls.Select
                label="Document"
                options={documentOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
            rules={{ required: "File type is required" }}
          />
          {errors.docType && <p className="error">{errors.docType.message}</p>}
        </div>
      ) : (
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="filename"
              control={control}
              defaultValue=""
              as={<Controls.Input label="File Name" />}
              rules={{
                required: "File name is required",
                pattern: {
                  value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/i,
                  message: "File name can only accept characters",
                },
              }}
            />
            {errors.filename && (
              <p className="error">{errors.filename.message}</p>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="docType"
              control={control}
              defaultValue=""
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Document"
                  options={documentOptions()}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{ required: "File type is required" }}
            />
            {errors.docType && (
              <p className="error">{errors.docType.message}</p>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          {openCamera ? (
            <div>
              <Controller control={control} defaultValue="" name="fileplain" />
              <CameraHD onCapture={onCaptureImage} />
            </div>
          ) : (
            <div>
              <Controller
                control={control}
                defaultValue=""
                name="file"
                as={({ onChange, value }) => (
                  <Controls.DropzoneBox
                    label="Document"
                    onChange={(files) => onChange(files[0])}
                    value={value}
                  />
                )}
                rules={{ required: "File is required" }}
              />
              {errors.file && <p className="error">{errors.file.message}</p>}
            </div>
          )}
          <div>
            <Controls.Button
              disabled={isSubmitting}
              type="submit"
              text={isSubmitting ? "Submitting..." : "Submit"}
            />
            <Controls.Button
              disabled={isSubmitting}
              text="Reset"
              onClick={() => reset(initialData)}
            />
            {enableCamera && (
              <Controls.Button
                disabled={isSubmitting}
                text={openCamera ? "Close camera" : "Use camera"}
                onClick={() => setOpenCamera(!openCamera)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
