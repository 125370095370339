import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CommentIcon from "@material-ui/icons/Comment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { useTranslation } from "react-i18next";

import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DraftsIcon from "@material-ui/icons/Drafts";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import FaceIcon from "@material-ui/icons/Face";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { Tooltip } from "@material-ui/core";
import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  icon: {
    color: "#237bd1",
    width: 25,
    height: 25,
  },
}));

export default function ToDoTimeline(props) {
  const classes = useStyles();

  const { data } = props;

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";

  const { t, i18n } = useTranslation(["common", "records"]);

  const showType = (type) => {
    switch (type) {
      case "followup":
        return (
          <Tooltip title="Follow up">
            <span>
              <Iconify icon="basil:alarm-solid" className={classes.icon} />
            </span>
          </Tooltip>
        );
        break;
      case "call":
        return (
          <Tooltip title="Call">
            <span>
              <Iconify
                icon="fluent:call-add-20-filled"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      case "sms":
        return (
          <Tooltip title="SMS">
            <span>
              <Iconify
                icon="eva:message-circle-fill"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      case "email":
        return (
          <Tooltip title="Email">
            <span>
              <Iconify icon="fluent:mail-32-filled" className={classes.icon} />
            </span>
          </Tooltip>
        );
      case "contact":
        return (
          <Tooltip title="Make Contact">
            <span>
              <Iconify icon="mingcute:user-3-fill" className={classes.icon} />
            </span>
          </Tooltip>
        );
        break;
      case "deposit":
        return (
          <Tooltip title="Deposit">
            <span>
              <Iconify
                icon="solar:dollar-minimalistic-bold-duotone"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      case "pending_consultation":
        return (
          <Tooltip title="Pending Consultation">
            <span>
              <Iconify
                icon="solar:users-group-rounded-bold-duotone"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      default:
      // code block
    }
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          created by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <AssignmentIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {"To Do for " + data.object.schedule_to}
          </Typography>
          {showType(data.object.type)}
          <Typography>{data.object.comment}</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
