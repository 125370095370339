import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { isEmpty, uniqueId, map, findIndex, find, filter } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import QuoteDetails from "../pages/quotes/details";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ReactToPrint from "react-to-print";
import InfoIcon from "@material-ui/icons/Info";
import CRMUtils from "../utils";
import BackspaceIcon from "@material-ui/icons/Backspace";

import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  withStyles,
  IconButton,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { spacing, display } from "@material-ui/system";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import SendIcon from "@material-ui/icons/Send";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PaymentIcon from "@material-ui/icons/Payment";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import Controls from "./controls";
import Popup from "./Popup";
import InvoiceItemForm from "./forms/InvoiceItemForm";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import PaymentForm from "./forms/PaymentForm";
import PaymentFormNew from "./forms/PaymentFormNew";
import CreditMemosInvoices from "./CreditMemosInvoices";
import ApplyItemDiscountForm from "./forms/ApplyItemDiscountForm";
import Api from "../lib/api";
import NumberFormatCustom from "./controls/NumberFormatCustom";
import PrintInvoiceDetails from "./PrintInvoiceDetails";
import RefundRequestForm2 from "./forms/RefundRequestForm2";

import {
  createEntityService as createRefundRequestsNewService,
  updateEntityService as updateRefundRequestsNewService,
} from "../redux/slices/refundRequestsSlice.js";

import { fetchEntitiesService as fetchProductGiftReasonService } from "../redux/slices/productGiftReasonSlice.js";

import { fetchUsersServiceDropdown } from "../redux/slices/users";

import Alert from "@material-ui/lab/Alert";

var dataSliceproductGiftReason = {
  stateListName: "productGiftReason",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

import { fetchRefundReasonsService } from "../redux/slices/refundReasons";
import { fetchEntitiesService as fetchFinancingService } from "../redux/slices/financingSlice.js";
import Iconify from "./Iconify";
import ChangeItemDownpaymentForm from "./forms/ChangeItemDownpaymentForm.js";
import VoidInvoiceItemQuantityForm from "./forms/VoidInvoiceItemQuantityForm.js";
import PaymentLinkRequestForm from "./forms/PaymentLinkRequestForm.js";
import SpecialPaymentLinkForm from "./forms/SpecialPaymentLinkForm.js";
import SplitInvoiceForm from "./forms/SplitInvoiceForm";
import AvailableCoupons from "./AvailableCoupons.js";

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
var dataSliceRefundRequestsNew = {
  stateListName: "refundRequestsNew",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 50,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "90%",
  },
  included_margin: {
    width: "100%",
    margin: "25px 0px",
    marginBottom: 5,
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainProduct: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "170px",
  },

  childProduct: {
    marginLeft: "35px",
    color: "#999",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "170px",
  },

  childProductResp: {
    marginLeft: "5px",
    color: "#999",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "170px",
  },

  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Nunito,Roboto",
  },
  wrapText2: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
    textAlign: "center",
    color: "#f44336",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Nunito,Roboto",
  },
  customTooltip: {
    backgroundColor: "#e0f2f1",
    color: "#607d8b",
  },
  customArrow: {
    color: "#e0f2f1",
  },
  generateInvoiceButton: {
    padding: "5px 20px",
    borderRadius: "18px",
  },
  offerField: {
    fontWeight: 500,
    color: "#405465",
    fontSize: 12,
  },
  offerFieldTypography: {
    fontSize: 12,
  },
  couponTitleStyle: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 16,

    padding: "4px",
    marginBottom: 6,
    color: "#2b5356",
  },
  couponStyle: {
    background: "#f1fff5",
    padding: "5px 15px",
    border: "1px dashed #94dac1",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: 4,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    marginTop: -1,
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function InvoiceBuilder(props) {
  const {
    setInvoices = null,
    setInvoice = null,
    getInvoicesContact = null,
    invoices = null,
    invoice,
    statusOptions,
    users,
    products,
    contact,
    readOnlySalesManager,
    onSaveInvoice,
    onMakePayment,
    onSaveCreditMemo,
    openItemDefault,
    authUser,
    userIsCallCenter = false,
    userIsSocialMedia = false,
    userIsFrontDesk = false,
    refreshInvoices = null,
  } = props;
  const [loadingCoupons, setLoadingCoupons] = useState(null);
  const isInitialMount = useRef(true);
  if (authUser) {
    dataSliceRefundRequestsNew.orgId = authUser.organization_id;
  }

  const { usersDropdown, usersDropdownLoanding } = useSelector(
    (state) => state.users
  );

  const invoiceRef = useRef();
  const dispatch = useDispatch();

  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const [editItemUserPrice, setEditItemUserPrice] = useState(null);

  const [userID, setUserID] = useState(null);

  const [userAZ, setUserAZ] = useState(null);

  const { financing } = useSelector((state) => state.financing);
  const { productGiftReason } = useSelector((state) => state.productGiftReason);
  const [sendPaymentLinkSmsStatus, setSendPaymentLinkSmsStatus] =
    useState(null);
  const [openChangePricePopup, setOpenChangePricePopup] = useState(false);
  const [selectedPaymentTypeTab, setSelectedPaymentTypeTab] = useState(0);

  const [recordproducts, setRecordProducts] = useState([]);
  const [userMinPriceAvailableQty, setUserMinPriceAvailableQty] = useState(0);
  const [payBefore, setPayBefore] = useState(
    invoice && invoice.pay_before
      ? moment(invoice.pay_before).format("yyyy-MM-DD")
      : null
  );

  const userCanChangePayBefore = CRMUtils.userHasPermission(authUser, [
    "invoice_EditPayBefore",
  ]);
  const userCanAdditem = CRMUtils.userHasPermission(authUser, [
    "invoices_additem",
  ]);

  const userCanCreateCredit = CRMUtils.userHasPermission(authUser, [
    "creditmemo_create",
  ]);

  const userCanSplitInvoice = CRMUtils.userHasPermission(authUser, [
    "invoice_split",
  ]);

  const userCanMakePayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_create",
  ]);
  const userCanMakePaymentLinkRequest = CRMUtils.userHasPermission(authUser, [
    "paymentlinkrequest_create",
  ]);
  const userCanClosePaymentLinkRequest = CRMUtils.userHasPermission(authUser, [
    "paymentlinkrequest_close",
  ]);
  const userCanClosePaymentLinkRequestSuper = CRMUtils.userHasPermission(
    authUser,
    ["paymentlinkrequest_closesuper"]
  );
  const userCanSendInvoicePayment = CRMUtils.userHasPermission(authUser, [
    "invoice_sendPayment",
  ]);

  const userCanFinancing = CRMUtils.userHasPermission(authUser, [
    "invoice_financing",
  ]);
  const userCanEditFinancingInterest = CRMUtils.userHasPermission(authUser, [
    "user_editFinancingInterest",
  ]);

  const userCanSuperEditInvoice = CRMUtils.userHasPermission(authUser, [
    "invoice_superedit",
  ]);

  const userCanEditIncluded = CRMUtils.userHasPermission(authUser, [
    "invoice_editIncluded",
  ]);

  const userIsBillingBasic = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingBasic",
  ]);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  const userCanViewInvoice = CRMUtils.userHasPermission(authUser, [
    "invoice_view",
  ]);

  const userIsBillingMGAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingMGAssistant",
  ]);

  const userCanApplyItemDiscountAdd = CRMUtils.userHasPermission(authUser, [
    "invoice_ItemDiscountAdd",
  ]);

  const userCanApplyItemDiscountEdit = CRMUtils.userHasPermission(authUser, [
    "invoice_ItemDiscountEdit",
  ]);

  const userCanApplyItemDiscountDelete = CRMUtils.userHasPermission(authUser, [
    "invoice_ItemDiscountDelete",
  ]);

  const userIsBillingAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingAssistant",
  ]);

  const userCanAddGiftItem = CRMUtils.userHasPermission(authUser, [
    "invoices_addGiftItem",
  ]);

  const userCanOverrideOfferChange = CRMUtils.userHasPermission(authUser, [
    "invoices_overrideOfferItemLock",
  ]);

  const userCanChangeConsultant = CRMUtils.userHasPermission(authUser, [
    "invoice_ChangeConsultant",
  ]);

  const userCanChangeConsultantAll = CRMUtils.userHasPermission(authUser, [
    "invoice_ChangeConsultantAll",
  ]);

  const userCanChangeDownpayment = CRMUtils.userHasPermission(authUser, [
    "invoice_changeitemdownpayment",
  ]);

  const canUserVoidItem = CRMUtils.userHasPermission(authUser, [
    "invoice_voidItem",
  ]);

  const canUserDeleteItemP = CRMUtils.userHasPermission(authUser, [
    "invoice_deleteItem",
  ]);

  const canUserEditItemP = CRMUtils.userHasPermission(authUser, [
    "invoice_editItem",
  ]);

  const canEditPackageItemPrice = CRMUtils.userHasPermission(authUser, [
    "invoice_changepackageitemprice",
  ]);

  const canSuperAddItem = CRMUtils.userHasPermission(authUser, [
    "invoices_additemsuper",
  ]);
  const userCanUseMinPrice =
    CRMUtils.userHasPermission(authUser, ["user_useminprice"]) &&
    userMinPriceAvailableQty > 0;

  const org_footer = authUser ? authUser.organization.data.invoice_footer : 1;

  const [items, setItems] = useState(invoice ? invoice.items.data : []);
  const [status, setStatus] = useState(invoice ? invoice.status : "unsend");
  const [included, setIncluded] = useState(invoice ? invoice.included : "");
  const classes = useStyles();
  const [localContact, setLocalContact] = useState(null);
  const [updateNeeded, setUpdateNeeded] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [editItemIndex, setEditItemIndex] = useState(null);
  const [openItem, setOpenItem] = useState(false);
  const [openItemGift, setOpenItemGift] = useState(false);
  const [openQuote, setOpenQuote] = useState(false);
  const [quote, setQuote] = useState();
  const [openPayment, setOpenPayment] = useState(false);
  const [openPaymentNew, setOpenPaymentNew] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [openCreditMemo, setOpenCreditMemo] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isPaymentLinkCopied, setIsPaymentLinkCopied] = useState(false);
  const [selectedPackageItem, setSelectedPackageItem] = useState(null);
  const [packageItemPrice, setPackageItemPrice] = useState(null);
  const [financingInterest, setFinancingInterest] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [refundRequestsForEdit, setRefundRequestsForEdit] = useState(null);
  const { refundReasons } = useSelector((state) => state.refundReasons);

  const [financingOption, setFinancingOption] = useState(
    invoice ? (invoice.financing_id ? invoice.financing_id : -1) : -1
  );
  const [updatePackagePriceNeeded, setUpdatePackagePriceNeeded] =
    useState(false);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [plLan, setPlLan] = useState(1);
  const [isDownpaymentPopupOpened, setIsDownpaymentPopupOpened] =
    useState(false);
  const [downpaymentItem, setDownpaymentItem] = useState(null);
  const [generatingQuote, setGeneratingQuote] = useState(false);
  const [isVoidItemPopupOpen, setIsVoidItemPopupOpen] = useState(false);
  const [paymentLinkRequestOpened, setPaymentLinkRequestOpened] =
    useState(false);
  const [specialPaymentLinkPopupOpened, setSpecialPaymentLinkPopupOpened] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);

  const [splitInvoicePopup, setSplitInvoicePopup] = useState(false);

  const [splitInvoiceLoading, setSplitInvoiceLoading] = useState(false);

  const addOrEdit = (data) => {
    const date = moment(data.date).format("YYYY-MM-DD HH:mm:ss");

    data.date = date;

    if (refundRequestsForEdit) {
      dataSliceRefundRequestsNew.dataUpdate.id = refundRequestsForEdit.id;
      dataSliceRefundRequestsNew.dataUpdate.data = data;

      dispatch(updateRefundRequestsNewService(dataSliceRefundRequestsNew));
    } else {
      if (!isEmpty(authUser)) {
        dataSliceRefundRequestsNew.dataCreate.data = data;

        dispatch(createRefundRequestsNewService(dataSliceRefundRequestsNew));
      }
    }
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
    setRefundRequestsForEdit(null);
    setOpenRefund(false);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const splitInvoiceOpen = () => {
    setSplitInvoicePopup(true);
  };

  const closeSplitInvoice = () => {
    setSplitInvoicePopup(false);
  };

  const onSplit = async (info) => {
    setSplitInvoiceLoading(true);
    try {
      const { data } = await Api.onSplitInvoice(invoice.id, info);

      if (data && data.data) {
        closeSplitInvoice();
        setSplitInvoiceLoading(false);
        if (typeof refreshInvoices === "function") {
          refreshInvoices();
        }
      }
    } catch (error) {
      setSplitInvoiceLoading(false);
    }
  };

  const saveInvoice = (text = null) => {
    if (contact || localContact) {
      const itemsUpdated = items.map((item) => {
        return {
          ...item,
          base_price: parseFloat(item.base_price || item.price).toFixed(2),
          product_apply_financing: item.product_apply_financing,
          user_product_price_id: item.user_product_price_id,
          full_payment_due: item.full_payment_due,
          late_payment_fee: item.late_payment_fee,
          product_items:
            item.product_items && item.product_items.length > 0
              ? item.product_items.map((product_item) => ({
                  ...product_item,
                  ...{
                    price: getItemPrice(
                      product_item.price,
                      product_item.price,
                      item.product_apply_financing
                    ),
                    base_price: product_item.price,
                  },
                }))
              : [],
          price: getItemPrice(
            item.type == "package" && item.package_price != null
              ? item.package_price
              : item.price,
            item.type == "package" && item.base_package_price != null
              ? item.base_package_price
              : item.base_price,
            item.product_apply_financing
          ),
        };
      });

      const payload = {
        ...invoice,
        financing_id: financingOption > 0 ? financingOption : null,
        items: itemsUpdated,
        status: status,
        contact: contact ? contact : localContact ? localContact : null,
        included: included,
        interest: financingInterest,
        user_id: userID ? userID : invoice?.user_id,
        coupons: availableCoupons.filter((c) => selectedCoupons.includes(c.id)),
      };

      if (invoice) {
        payload.pay_before = payBefore
          ? moment(new Date(payBefore)).format("yyyy-MM-DD")
          : moment(new Date(invoice.pay_before)).format("yyyy-MM-DD");
      }

      onSaveInvoice(payload);
      setUpdatePackagePriceNeeded(false);
      setUpdateNeeded(false);
      setNotify({
        isOpen: true,
        message: "Submitted Successfully",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Please Select a Contact",
        type: "error",
      });
    }
  };

  const applyInvoiceCreditMemoupdate = (payload) => {
    onSaveCreditMemo(payload);

    closeCreditMemo();
  };

  const getMinPriceAvailableQtyToUse = async () => {
    const { data } = await Api.getMinPriceAvailableQtyToUse(
      authUser.building_id,
      contact.id,
      authUser?.id || null
    );

    setUserMinPriceAvailableQty(data);
  };

  const getInvoiceAvailableCoupons = async () => {
    setLoadingCoupons(true);
    const { data } = await Api.getInvoiceAvailableCoupons(invoice.id);

    setAvailableCoupons([...data.data]);
    setLoadingCoupons(false);
  };

  const onDeleteItem = async (id, confirm) => {
    if (invoice && !confirm) {
      if (
        checkCouponsCompability(
          calculateTotal() - items.find((i) => i.id == id).price
        ) == false
      ) {
        setConfirmDialog({
          isOpen: true,
          title:
            "This change will remove some of the coupons already applied to the invoice.",
          subTitle: "Are you sure you want to proceed?",
          onConfirm: () => {
            onDeleteItem(id, true);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          },
        });
        return null;
      }
    }

    if (Number.isInteger(id))
      try {
        const { data } = await Api.deleteInvoiceItem(invoice.id, id);

        if (data) {
          const newItems = items.filter(
            (item) => item.id != id && item.parent_id != id
          );

          setItems(newItems);

          getInvoiceLocal();

          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
        }
      } catch (error) {
        if (error.status == 409 && (error?.data?.error || false)) {
          setNotify({
            isOpen: true,
            message: error.data.error,
            type: "error",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Something went wrong!",
            type: "error",
          });
        }

        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        console.log(error);
      }
  };
  const getInvoiceLocal = async () => {
    await Api.getInvoice(invoice.id).then((result) => {
      if (invoices && getInvoicesContact) {
        getInvoicesContact();
      } else if (invoices && setInvoices) {
        const index = findIndex(invoices, ["id", invoice.id]);
        const entity = { ...invoices[index], ...result.data.data };
        let entities = [...invoices];
        entities[index] = entity;

        setInvoices([...entities]);
      }

      if (setInvoice) {
        setInvoice({
          ...result.data.data,
        });
      }
    });
  };

  const onUnVoidItem = async (id) => {
    const { data } = await Api.unvoidInvoiceItem(invoice.id, id);

    getInvoiceLocal();

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const onVoidItem = async (id, confirm = false) => {
    if (invoice && !confirm) {
      if (
        checkCouponsCompability(
          calculateTotal() - items.find((i) => i.id == id).price
        ) == false
      ) {
        setConfirmDialog({
          isOpen: true,
          title:
            "This change will remove some of the coupons already applied to the invoice.",
          subTitle: "Are you sure you want to proceed?",
          onConfirm: () => {
            onVoidItem(id, true);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          },
        });
        return null;
      }
    }

    const { data } = await Api.voidInvoiceItem(invoice.id, id);

    await Api.getInvoice(invoice.id).then((result) => {
      if (invoices && getInvoicesContact) {
        getInvoicesContact();
      } else if (invoices && setInvoices) {
        const index = findIndex(invoices, ["id", invoice.id]);
        const entity = { ...invoices[index], ...result.data.data };
        let entities = [...invoices];
        entities[index] = entity;

        setInvoices([...entities]);
      }

      if (setInvoice) {
        setInvoice({
          ...result.data.data,
        });
      }
    });

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const addInvoiceItem = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItem(true);
  };

  const openRefQuote = async () => {
    if (!isEmpty(invoice.quote.data)) {
      setQuote(invoice.quote.data);
    }

    setOpenQuote(true);
  };

  const closeRefQuote = () => {
    setOpenQuote(false);
  };

  const closeItemPopup = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItem(false);
  };

  const addInvoiceItemGift = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItemGift(true);
  };

  const closeItemPopupGift = () => {
    setOpenItemGift(false);
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };
  const saveInvoiceItem = (data, confirm = false) => {
    setUpdateNeeded(true);
    const item = {
      product_id: data.productId,
      fromAdd: true,
      name: data.productName,
      base_price: parseFloat(data.productPrice),
      price: parseFloat(
        getItemPrice(data.productPrice, null, data.product_apply_financing)
      ),
      type: data.type,
      responsible: !isEmpty(data.responsible) ? data.responsible : "",
      calendar_date: !isEmpty(data.invoiceItemDate)
        ? moment(data.invoiceItemDate).format("YYYY-MM-DD")
        : null,
      override_price: null,
      product_gift_reason_id: data.product_gift_reason_id,
      product_items: data.product_items,
      parent_id: data.parent_id,
      gift_user_id: data.product_gift_reason_id > 0 ? authUser.id : null,
      gift_reference_price: data.gift_reference_price,
      override_price_by: null,
      override_price_date: null,
      quantity: data.quantity,
      isProcedure: data.isProcedure,
      is_package: data.is_package,
      void: 0,
      product_apply_financing: data.productApplyFinancing,
      user_product_price_id: data.user_product_price_id,
      full_payment_due: data.full_payment_due,
      late_payment_fee: data.late_payment_fee,
      downpayment_amount: data.downpayment_amount,
      is_revision: data.is_revision,
      revision_comment: data.revision_comment,
      product_standard_price: data.product_standard_price,
      product_min_price: data.product_min_price,
    };

    if (editItem) {
      if (invoice && !confirm) {
        if (
          checkCouponsCompability(
            calculateTotal() - editItem.price + item.price
          ) == false
        ) {
          setConfirmDialog({
            isOpen: true,
            title:
              "This change will remove some of the coupons already applied to the invoice.",
            subTitle: "Are you sure you want to proceed?",
            onConfirm: () => {
              saveInvoiceItem(data, true);
              setConfirmDialog({ ...confirmDialog, isOpen: false });
            },
          });
          return null;
        }
      }

      items[editItemIndex] = {
        ...item,
        id: editItem.id,
      };

      setItems(items);
      setUpdateNeeded(false);
      setUpdatePackagePriceNeeded(false);
      if (invoice && !isEmpty(items)) saveInvoice();
    } else {
      setItems(
        [
          ...items,
          {
            ...item,
            id: uniqueId("invoice_item_"),
          },
        ],

        () => console.log("callback called now!! =)", items)
      );
    }

    closeItemPopup();
    closeItemPopupGift();
  };

  const calulateTotalPayments = () => {
    const payments = invoice
      ? invoice.payments.data.filter((payment) => {
          return !payment.isRefund;
        })
      : [];

    return payments.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const changeIncluded = (value) => {
    setIncluded(value);
  };

  const calulateTotalRefunds = () => {
    const refunds = invoice
      ? invoice.payments.data.filter((payment) => {
          return payment.isRefund;
        })
      : [];

    return refunds.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const getItemPrice = (price, base, product_apply_financing) => {
    if (financingInterest && product_apply_financing) {
      const value = base ? base : price;
      return parseFloat(value + (value * financingInterest) / 100).toFixed(2);
    }
    return base || price;
  };

  const itemHasRefunds = (item) => {
    let treturn = "";
    item.paymentdetail?.data.forEach((paymentdetailL) => {
      if (paymentdetailL.isRefund == 1) {
        treturn = "PARTIAL REFUND";
      }
    });
    if (
      !isEmpty(item.paymentdetail?.data) &&
      item.itemBalance == item.totalPrice
    ) {
      treturn = "TOTAL REFUND";
    }
    return treturn;
  };

  const calulateTotalPaymentsCreditRefund = () => {
    const totalPayments = calulateTotalPayments();
    const totalRefunds = calulateTotalRefunds();
    const credits = calculateTotalCredits();

    return (
      Math.round(totalPayments * 100) / 100 +
      Math.round(credits * 100) / 100 -
      Math.round(totalRefunds * 100) / 100
    );
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    const itemPrice = getItemPrice(
      item.type == "package" && item.package_price
        ? item.package_price
        : item.price,
      item.type == "package" && item.package_price
        ? item.base_package_price
        : item.base_price,
      item.product_apply_financing
    );

    return (
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };

  const calculateSubtotal = () => {
    const subtotal1 = items.filter((item) => item.void == 0);

    const subtotal2 = subtotal1.reduce(
      (total, item) =>
        total +
        calculateItemTotal(
          invoice
            ? { ...item, ...{ package_price: 0, base_package_price: 0 } }
            : item
        ),
      0
    );

    return subtotal2;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  const calculateTotalCredits = () => {
    return invoice?.totalCredits || 0;
  };

  const updateItemQuantity = (id, value) => {
    const newItems = items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: value,
        };
      }
      return item;
    });
    setItems(newItems);
  };

  const updateInvoice = (invoice) => {};

  const closePaymentPopup = () => {
    setOpenPayment(false);
  };

  const closePaymentPopupNew = () => {
    setOpenPaymentNew(false);
  };
  const closeRefundPopup = () => {
    setOpenRefund(false);
  };
  const closeCreditMemo = () => {
    setOpenCreditMemo(false);
  };

  function ItemPriceHlp({ item }) {
    const calculatePrice = (item) => {
      const price =
        item.type === "package" && item.package_price
          ? item.package_price
          : item.price;
      const basePrice =
        item.type === "package" && item.package_price
          ? item.base_package_price
          : item.base_price;
      return parseFloat(
        getItemPrice(price, basePrice, item.product_apply_financing)
      ).toFixed(2);
    };

    const icon = item.product_gift_reason_id ? (
      <CardGiftcardIcon
        fontSize="small"
        style={{ color: "#673ab7", marginRight: 5 }}
      />
    ) : item && item.discount_applied ? (
      <Iconify
        icon={"fluent:credit-card-person-24-filled"}
        width={20}
        height={20}
        style={{ color: "#673ab7", marginRight: 5 }}
      />
    ) : null;

    const displayPrice = calculatePrice(item);
    const referencePrice = item.product_gift_reason_id
      ? `$${item.gift_reference_price}`
      : "";
    const discountedPrice = item.discount_applied
      ? `$${(
          parseFloat(displayPrice) + parseFloat(item.discount_applied)
        ).toFixed(2)}`
      : "";

    return (
      <div>
        {icon}${displayPrice}
        {(item.product_gift_reason_id || item.discount_applied) && (
          <>
            <span> / </span>
            <span style={{ color: "#8080808a" }}>
              {item.product_gift_reason_id ? referencePrice : discountedPrice}
            </span>
          </>
        )}
        {item.full_payment_due && (
          <ListItemText
            secondary={`Promotional pricing available until ${
              item.full_payment_due
            }. Regular pricing applies thereafter,
            ($${
              item?.late_payment_fee || 0
            } will be added to the invoice as a separate item).`}
            secondaryTypographyProps={{
              style: {
                fontStyle: "italic",
              },
            }}
          />
        )}
      </div>
    );
  }

  const savePayment = async (data) => {
    if (typeof onMakePayment === "function") {
      await onMakePayment(data);

      try {
        await Api.getInvoice(invoice.id).then((result) => {
          if (setInvoice) {
            setInvoice({
              ...result.data.data,
            });
          }
        });
      } catch (err) {
        setNotify({
          isOpen: true,
          message:
            "An error occurred while fetching the invoice after payment. Please reopen it to avoid errors.",
          type: "success",
        });
        setOpenPayment(false);
      }
    }

    closePaymentPopup();
  };

  const canUserDeleteItem = (item) => {
    return (
      (item.package_balance == null ||
        item.package_balance == item.package_price) &&
      item.paymentdetail &&
      isEmpty(item.paymentdetail.data) &&
      item.void === 0 &&
      canUserDeleteItemP
    );
  };

  const canVoid = (item) => {
    return item?.parent_id &&
      item?.totalPrice > item?.itemBalance &&
      items?.find((a) => a.id == item?.parent_id) &&
      item?.totalPrice - item?.itemBalance <=
        items?.find((a) => a.id == item?.parent_id).package_balance -
          item?.itemBalance &&
      item?.void == 0 &&
      canUserVoidItem
      ? true
      : canUserVoidItem &&
          (item?.package_balance == null ||
            item?.package_balance == item?.package_price) &&
          item?.itemBalance == item?.totalPrice &&
          item?.void == 0;
  };

  const userCanUnvoidItem = CRMUtils.userHasPermission(authUser, [
    "invoices_unvoidItem",
  ]);

  const userCanEditInvoice =
    CRMUtils.userHasRole(authUser, ["admin", "super-admin"]) ||
    CRMUtils.userHasPermission(authUser, ["invoice_edit"]);

  const [applyDiscountPopUp, setApplyDiscountPopUp] = useState(false);
  const [applyDiscountItem, setApplyDiscountItem] = useState(null);

  const openApplyDiscount = (item) => {
    setApplyDiscountItem(item);
    setApplyDiscountPopUp(true);
  };

  const closeApplyDiscount = () => {
    setApplyDiscountPopUp(false);
    setApplyDiscountItem(null);
  };

  const onApplyDiscount = async (info, confirm = false) => {
    let itemNewPrice =
      financingOption > 0 && applyDiscountItem.product_apply_financing
        ? ((applyDiscountItem.discount_applied && applyDiscountItem.base_price
            ? parseFloat(applyDiscountItem.discount_applied) +
              parseFloat(applyDiscountItem?.base_price)
            : parseFloat(applyDiscountItem?.base_price)) -
            parseFloat(info.discount_applied)) *
          (1 +
            (financingInterest
              ? financingInterest
              : quote && quote.interest
              ? quote.interest
              : 1) /
              100)
        : (applyDiscountItem.discount_applied && applyDiscountItem.base_price
            ? parseFloat(applyDiscountItem.discount_applied) +
              parseFloat(applyDiscountItem?.base_price)
            : parseFloat(applyDiscountItem?.base_price)) -
          parseFloat(info.discount_applied);

    if (invoice && !confirm) {
      if (
        checkCouponsCompability(
          calculateTotal() - applyDiscountItem.price + itemNewPrice
        ) == false
      ) {
        setConfirmDialog({
          isOpen: true,
          title:
            "This change will remove some of the coupons already applied to the invoice.",
          subTitle: "Are you sure you want to proceed?",
          onConfirm: () => {
            onApplyDiscount(info, true);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          },
        });
        return null;
      }
    }

    const payload = {
      discount_applied: info.discount_applied,
      discount_comment: info.discount_comment,
      discount_user_id: authUser?.id,
      price: itemNewPrice,

      base_price:
        (applyDiscountItem.discount_applied && applyDiscountItem.base_price
          ? parseFloat(applyDiscountItem.discount_applied) +
            parseFloat(applyDiscountItem?.base_price)
          : parseFloat(applyDiscountItem?.base_price)) -
        parseFloat(info.discount_applied),
    };

    // console.log("onApplyDiscount", payload);

    if (typeof applyDiscountItem?.id === "number") {
      const { data } = await Api.invoiceApplyItemDiscount(
        invoice?.id,
        applyDiscountItem?.id,
        payload
      );

      if (data.data) {
        getInvoiceLocal();
      }
    } else {
      // console.log("onApplyDiscount", payload);
      // const newItems = items.map((item) => {
      //   if (item.id === applyDiscountItem?.id) {
      //     return {
      //       ...item,
      //       ...payload,
      //     };
      //   }
      //   return item;
      // });
      // setItems(newItems);
    }

    closeApplyDiscount();
  };

  const onRemoveDiscount = async (item) => {
    const { data } = await Api.invoiceRemoveItemDiscount(invoice?.id, item?.id);

    if (data.data) {
      getInvoiceLocal();
    }

    closeApplyDiscount();
  };

  const canUserEditItem = (item) => {
    if (!invoice) return false;
    return invoice
      ? item && item.done && item.done == 1
        ? false
        : item?.itemPaid <= 0 &&
          item.void == 0 &&
          item.editable &&
          userCanEditInvoice &&
          canUserEditItemP
        ? //   ||
          // (item?.itemPaid > 0 &&
          //   item.void == 0 &&
          //   item.editable &&
          //   userCanSuperEditInvoice &&
          //   userCanEditInvoice &&
          //   canUserEditItemP)
          true
        : false
      : false;
  };

  const closeChangePrice = () => {
    setOpenChangePricePopup(false);
    setSelectedPackageItem(null);
    setPackageItemPrice(null);
  };

  const changePackagePrice = () => {
    setUpdatePackagePriceNeeded(true);
    const index = findIndex(items, ["id", selectedPackageItem.id]);
    const updatedEvent = {
      ...items[index],
      ...{
        price: parseFloat(packageItemPrice),
        totalPrice: parseFloat(
          packageItemPrice *
            (selectedPackageItem.price_includes_qty
              ? 1
              : selectedPackageItem.quantity)
        ),
        base_price: parseFloat(packageItemPrice),
      },
    };

    const indexParent = findIndex(items, ["id", selectedPackageItem.parent_id]);
    const updatedEventParent = {
      ...items[indexParent],
      ...{
        package_price:
          parseFloat(items[indexParent].package_price) -
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(selectedPackageItem.price) +
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(packageItemPrice),
        totalPrice:
          (parseFloat(items[indexParent].package_price) -
            (selectedPackageItem.price_includes_qty
              ? 1
              : selectedPackageItem.quantity) *
              parseFloat(selectedPackageItem.price) +
            (selectedPackageItem.price_includes_qty
              ? 1
              : selectedPackageItem.quantity) *
              parseFloat(packageItemPrice)) *
          (items[indexParent].price_includes_qty
            ? 1
            : parseFloat(items[indexParent].quantity)),
        base_package_price:
          parseFloat(items[indexParent].base_package_price) -
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(selectedPackageItem.base_price) +
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(packageItemPrice),
      },
    };

    let newItems = [...items];
    newItems[index] = updatedEvent;
    newItems[indexParent] = updatedEventParent;

    setItems([...newItems]);
    closeChangePrice();
  };

  useEffect(() => {
    if (invoice) {
      setStatus(invoice.status);
      setItems(invoice.items.data);

      if (getMaxCouponsToApply() > 0) {
        getInvoiceAvailableCoupons();
      }
    }

    if (invoice && invoice.interest) {
      setFinancingInterest(invoice.interest);
    } else {
      if (financingOption > 0) {
        const selectedFinancing = find(financing, { id: financingOption });
        setFinancingInterest(selectedFinancing.interest);
      }
    }
  }, [invoice]);

  const getAvailableCouponsForNewInvoice = async () => {
    setLoadingCoupons(true);
    console.log("ITEMS: ", items);
    var payload = {
      discountedItems: items.find(
        (i) =>
          (i.price < i.product_standard_price && i.isProcedure) ||
          i.product_items.find(
            (pi) => pi.price < pi.product_standard_price && pi.is_procedure
          )
      )
        ? true
        : false,
      totalPrice: calculateTotal(),
    };

    const { data } = await Api.getAvailableCouponsForNewInvoice(payload);

    setAvailableCoupons([...data.data]);
    setLoadingCoupons(false);
  };

  useEffect(() => {
    setSelectedCoupons(
      selectedCoupons.filter((c) =>
        availableCoupons.find((coupon) => coupon.id == c)
      )
    );
  }, [availableCoupons]);

  useEffect(() => {
    if (!invoice && items) {
      getAvailableCouponsForNewInvoice();
    }
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (updateNeeded) {
      try {
        setUpdateNeeded(false);
        setUpdatePackagePriceNeeded(false);
        if (invoice && !isEmpty(items)) saveInvoice();
      } catch ($e) {
        console.log($e);
      }
    }
  }, [items]);

  useEffect(() => {
    const orgId = authUser.organization_id;

    if (openItemDefault) {
      setOpenItem(true);
    }

    if (isEmpty(refundReasons)) {
      dispatch(fetchRefundReasonsService(orgId));
    }

    if (isEmpty(financing)) dispatch(fetchFinancingService(dataSliceFinancing));

    if (isEmpty(productGiftReason)) {
      dispatch(fetchProductGiftReasonService(dataSliceproductGiftReason));
    }
    getMinPriceAvailableQtyToUse();
    if (isEmpty(usersDropdown) && !usersDropdownLoanding)
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  useEffect(() => {
    if (sendPaymentLinkSmsStatus !== "sending") {
      setSendPaymentLinkSmsStatus(null);
    }
  }, [sendPaymentLinkSmsStatus]);

  const removeCoupon = (id) => {
    setSelectedCoupons(selectedCoupons.filter((c) => c != id));
  };

  const renderSelectedCoupons = () => {
    var coupons = availableCoupons.filter((c) =>
      selectedCoupons.includes(c.id)
    );

    return (
      <div style={{ padding: "4px 8px" }}>
        <Typography variant="h6">Selected coupons</Typography>
        {coupons.map((coupon) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={classes.couponStyle}>
                <div style={{ marginLeft: 4, width: "100%" }}>
                  <Typography className={classes.couponTitleStyle}>
                    {coupon.name}
                  </Typography>
                  <div
                    style={{
                      background: "rgb(36 188 109 / 12%)",
                      borderRadius: 10,
                      padding: "4px 10px",
                      marginBottom: 8,
                    }}
                  >
                    <Typography>
                      <strong style={{ fontWeight: 500 }}>
                        Coupon value:{" "}
                      </strong>
                      {` $${coupon.coupon_value}`}
                    </Typography>
                    <Typography>
                      <strong style={{ fontWeight: 500 }}>
                        Required invoice price:{" "}
                      </strong>
                      {` $${coupon.min_invoice_price}`}
                    </Typography>
                  </div>
                </div>
              </div>
              <Tooltip title="Remove coupon">
                <IconButton
                  onClick={() => {
                    removeCoupon(coupon.id);
                  }}
                >
                  <Iconify icon="ic:baseline-delete" style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  const renderItems = () => {
    return items
      .filter((item) => {
        return item.type !== "system";
      })
      .map((item, index) => {
        return (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              <ListItem>
                {item.type == "package" ? (
                  <ListItemIcon
                    style={{ marginRight: 0, paddingRight: 0, minWidth: 35 }}
                  >
                    <Iconify
                      icon="entypo:shopping-bag"
                      style={{ fontSize: 18, color: "gray" }}
                    />
                  </ListItemIcon>
                ) : (
                  ""
                )}
                {item.responsible && (
                  <ListItemIcon
                    style={{ marginRight: 0, paddingRight: 0, minWidth: 35 }}
                  >
                    <PersonIcon />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={
                    <Typography>
                      {item.name}
                      {item.is_revision ? (
                        <strong
                          style={{
                            color: "#326a9b",
                            marginLeft: 5,
                            fontWeight: 400,
                          }}
                        >
                          {"(Revision)"}
                        </strong>
                      ) : (
                        ""
                      )}
                    </Typography>
                  }
                  secondary={item.responsible ? item.responsible.title : null}
                  className={
                    item && item.parent_id
                      ? item.responsible
                        ? classes.childProductResp
                        : classes.childProduct
                      : classes.mainProduct
                  }
                />
                {item.is_revision && item.revision_comment ? (
                  <HtmlTooltip
                    title={
                      <div>
                        <div
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid rgb(187 187 187 / 87%)",
                            padding: "2px 0px",
                          }}
                        >
                          Revision comment
                        </div>
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            {item.revision_comment}
                          </Typography>
                        </div>{" "}
                      </div>
                    }
                  >
                    <span>
                      <Iconify
                        style={{
                          width: "22px",
                          height: "22px",
                          color: "rgb(113, 141, 165)",
                        }}
                        icon="mage:message-dots-round-fill"
                      />
                    </span>
                  </HtmlTooltip>
                ) : (
                  ""
                )}
                {item.user_product_price_id &&
                item.user_product_price &&
                item.user_product_price.offer_from ? (
                  <HtmlTooltip
                    title={
                      <div>
                        <div
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid rgb(187 187 187 / 87%)",
                            padding: "2px 0px",
                          }}
                        >
                          Offer info
                        </div>
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            <strong className={classes.offerField}>
                              Name:{" "}
                            </strong>
                            {` ${item?.user_product_price.offer_name || ""}`}
                          </Typography>
                        </div>{" "}
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            <strong className={classes.offerField}>
                              Price:{" "}
                            </strong>
                            {` $${item?.user_product_price.price || ""}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            <strong className={classes.offerField}>
                              Sale from:{" "}
                            </strong>
                            {` ${item?.user_product_price.offer_from || "-"}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            <strong className={classes.offerField}>
                              Sale to:{" "}
                            </strong>
                            {` ${item?.user_product_price.offer_to || "-"}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            <strong className={classes.offerField}>
                              Booking from:{" "}
                            </strong>
                            {` ${item?.user_product_price.booking_from || "-"}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography className={classes.offerFieldTypography}>
                            <strong className={classes.offerField}>
                              Booking to:{" "}
                            </strong>
                            {` ${item?.user_product_price.booking_to || "-"}`}
                          </Typography>
                        </div>
                      </div>
                    }
                  >
                    <span>
                      <Iconify
                        style={{
                          width: "22px",
                          height: "22px",
                          color: "#718da5",
                        }}
                        icon="f7:tag-circle-fill"
                      />
                    </span>
                  </HtmlTooltip>
                ) : (
                  ""
                )}
              </ListItem>
            </TableCell>
            <TableCell width="35%">
              {item.parent_id ? (
                authUser.organization &&
                authUser.organization.data &&
                authUser.organization.data.zero_package_value &&
                item.product &&
                !item.product.is_procedure ? (
                  <div>$0.00</div>
                ) : (
                  <div
                    style={{
                      fontWeight: 600,
                      color: "rgb(14, 85, 126)",
                      backgroundColor: "#deeced",
                      textAlign: "center",
                      padding: "0px 15px",
                      maxWidth: "fit-content",
                      borderRadius: 12,
                    }}
                  >
                    {"INCLUDED"}
                  </div>
                )
              ) : (
                <ItemPriceHlp item={item} />
              )}
            </TableCell>
            <TableCell width="9%">
              {!invoice ||
              invoice.status !== "paid" ||
              invoice.status !== "partial_paid" ? (
                item.parent_id ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      color: "#adadad",
                      borderBottom: "1px dotted gray",
                    }}
                  >
                    <Typography>{item.quantity}</Typography>
                    {item.void_quantity ? (
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginLeft: 5,
                          color: "#a76c65",
                          fontSize: 12,
                        }}
                      >{`(${item.void_quantity} VOID)`}</Typography>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <TextField
                    className={classes.formControl}
                    value={item.quantity || 1}
                    onChange={(e) =>
                      updateItemQuantity(item.id, e.target.value)
                    }
                    disabled={
                      item.isProcedure === 1 ||
                      item.void === 1 ||
                      item.type == "package" ||
                      (invoice && invoice.status === "canceled") ||
                      (invoice && invoice.paid > 0) ||
                      item.parent_id ||
                      !canUserEditItemP
                        ? true
                        : false
                    }
                    inputProps={{
                      decimalPrecision: 0,
                      decimalSeparator: false,
                      allowNegative: false,
                      isAllowed: (values) => {
                        const { floatValue } = values;
                        return floatValue >= 1 && floatValue <= 100;
                      },
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                )
              ) : (
                <span>{item.quantity || 1}</span>
              )}
            </TableCell>
            {invoice && invoice.downpayment_per_procedure ? (
              <TableCell align="right">
                {item.type == "package"
                  ? item.package_downpayment_amount !== null &&
                    item.package_downpayment_amount !== undefined
                    ? "$" + item.package_downpayment_amount
                    : ""
                  : item.downpayment_amount !== null &&
                    !item.parent_id &&
                    item.downpayment_amount !== undefined
                  ? "$" + (item?.downpayment_amount || 0)
                  : ""}
              </TableCell>
            ) : (
              ""
            )}

            <TableCell align="right">
              {item && item.parent_id
                ? ""
                : "$" + calculateItemTotal(item).toFixed(2)}
            </TableCell>
            <TableCell align="right">
              {item.void === 1 ? (
                <Typography className={classes.wrapText}>VOID</Typography>
              ) : (
                <Typography className={classes.wrapText2}>
                  {itemHasRefunds(item)}
                </Typography>
              )}
            </TableCell>
            {invoice ? (
              <TableCell align="right">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                  title={
                    <div>
                      <Box fontWeight="fontWeightBold" m={1}>
                        {`Payments: $${
                          item && item.itemPaid
                            ? item.itemPaid.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"
                        }   `}
                      </Box>
                      <Box fontWeight="fontWeightBold" m={1}>
                        {`Credits: $${
                          item && item.itemTotalCredits
                            ? item.itemTotalCredits.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"
                        }   `}
                      </Box>

                      <Box fontWeight="fontWeightBold" m={1}>
                        {`Refunds: $${
                          item && item.itemRefund
                            ? item.itemRefund.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"
                        }   `}
                      </Box>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon style={{ color: "#26a69a" }} />
                </Tooltip>
              </TableCell>
            ) : (
              <TableCell>{""}</TableCell>
            )}
            {item.type != "package" &&
              item.void === 1 &&
              userCanUnvoidItem &&
              !item.price_includes_qty && (
                <TableCell align="right">
                  {
                    <Tooltip title={"unVoid Item"}>
                      <div>
                        <Controls.IconButton
                          color="primary"
                          onClick={() =>
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to unvoid this item?",
                              //subTitle: "You can't undo this operation",
                              onConfirm: () => onUnVoidItem(item.id),
                            })
                          }
                        >
                          <BackspaceIcon size="small" />
                        </Controls.IconButton>
                      </div>
                    </Tooltip>
                  }
                </TableCell>
              )}
            {/*invoice &&
            invoice.status !== "paid" &&
            invoice.status !== "partial_paid" &&
            item.void === 0 &&
            invoice.status !== "canceled" && (
              <TableCell align="right">
                <Controls.IconButton
                  color="primary"
                  onClick={() => {
                    setEditItem(item);
                    setEditItemIndex(index);
                    setOpenItem(true);
                  }}
                >
                  <EditOutlinedIcon size="small" />
                </Controls.IconButton>
                <Controls.IconButton
                  color="primary"
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this item?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => onDeleteItem(item.id),
                    })
                  }
                >
                  <DeleteIcon size="small" />
                </Controls.IconButton>
              </TableCell>
                )*/}
            {invoice && invoice.status !== "canceled" ? (
              <TableCell align="right">
                <div style={{ display: "flex" }}>
                  {
                    //allow edit for admin

                    canUserEditItem(item) &&
                      item &&
                      !item.parent_id &&
                      item.type != "package" &&
                      item.type != "forfeiture" && (
                        <Tooltip title={"Edit Item"}>
                          <div>
                            <Controls.IconButton
                              disabled={
                                readOnlySalesManager ||
                                (item.type === "system_fee" &&
                                  !userCanOverrideOfferChange)
                              }
                              style={{
                                color:
                                  readOnlySalesManager ||
                                  (item.type === "system_fee" &&
                                    !userCanOverrideOfferChange)
                                    ? "#bdbdbd"
                                    : "#009688",
                              }}
                              onClick={async () => {
                                if (item.user_product_price_id) {
                                  const { data } =
                                    await Api.getUserProductPrice(
                                      authUser.organization_id,
                                      item.user_product_price_id
                                    );

                                  const userIdNew =
                                    item && item.responsible_id
                                      ? item.responsible_id
                                      : item && !isEmpty(item.responsible)
                                      ? item.responsible.id
                                      : null;

                                  if (userIdNew) {
                                    const response = await Api.getUser(
                                      userIdNew
                                    );

                                    setUserAZ(response.data.data);

                                    setEditItemUserPrice(data.data);
                                    setEditItem({
                                      ...item,
                                      ...{
                                        price_b: item.product.price,
                                        min_sale_price:
                                          item.product.min_sale_price,
                                      },
                                    });
                                    setEditItemIndex(index);
                                    setOpenItem(true);
                                  }
                                } else {
                                  setEditItem({
                                    ...item,
                                    ...{
                                      price_b: item.product.price,
                                      min_sale_price:
                                        item.product.min_sale_price,
                                    },
                                  });
                                  setEditItemIndex(index);
                                  setOpenItem(true);
                                }
                              }}
                            >
                              <Iconify
                                icon={"fluent:edit-24-filled"}
                                width={20}
                                height={20}
                              />
                            </Controls.IconButton>
                          </div>
                        </Tooltip>
                      )
                  }

                  {invoice &&
                    invoice.allow_discounts &&
                    userCanApplyItemDiscountAdd &&
                    !item.discount_applied &&
                    item &&
                    !item.parent_id &&
                    item.type != "package" &&
                    item.type != "forfeiture" &&
                    !item.product_gift_reason_id &&
                    item.void !== 1 && (
                      <Tooltip title={"Apply Discount"}>
                        <div>
                          <Controls.IconButton
                            disabled={
                              readOnlySalesManager ||
                              userIsBillingAssistant ||
                              userCanViewEditContactMark ||
                              (item.type === "system_fee" &&
                                !userCanOverrideOfferChange)
                            }
                            style={{
                              color:
                                readOnlySalesManager ||
                                userIsBillingAssistant ||
                                userCanViewEditContactMark ||
                                (item.type === "system_fee" &&
                                  !userCanOverrideOfferChange)
                                  ? "#bdbdbd"
                                  : "#673ab7",
                            }}
                            onClick={() => {
                              openApplyDiscount(item);
                            }}
                          >
                            <Iconify
                              icon={"majesticons:creditcard-plus"}
                              size="small"
                            />
                          </Controls.IconButton>
                        </div>
                      </Tooltip>
                    )}
                  {invoice &&
                    item.discount_applied &&
                    userCanApplyItemDiscountEdit &&
                    item &&
                    !item.parent_id &&
                    item.type != "package" &&
                    item.type != "forfeiture" &&
                    !item.product_gift_reason_id &&
                    item.void !== 1 && (
                      <Tooltip title={"Edit Discount"}>
                        <div>
                          <Controls.IconButton
                            disabled={
                              readOnlySalesManager ||
                              userIsBillingAssistant ||
                              userCanViewEditContactMark ||
                              (item.type === "system_fee" &&
                                !userCanOverrideOfferChange)
                            }
                            style={{
                              color:
                                readOnlySalesManager ||
                                userIsBillingAssistant ||
                                userCanViewEditContactMark ||
                                (item.type === "system_fee" &&
                                  !userCanOverrideOfferChange)
                                  ? "#bdbdbd"
                                  : "#673ab7",
                            }}
                            onClick={() => {
                              openApplyDiscount(item);
                            }}
                          >
                            <Iconify
                              icon={"mdi:credit-card-refresh"}
                              size="small"
                            />
                          </Controls.IconButton>
                        </div>
                      </Tooltip>
                    )}
                  {invoice &&
                    item.discount_applied &&
                    userCanApplyItemDiscountDelete &&
                    item &&
                    !item.parent_id &&
                    item.type != "package" &&
                    item.type != "forfeiture" &&
                    !item.product_gift_reason_id &&
                    item.void !== 1 && (
                      <Tooltip title={"Delete Discount"}>
                        <div>
                          <Controls.IconButton
                            disabled={
                              readOnlySalesManager ||
                              userIsBillingAssistant ||
                              userCanViewEditContactMark ||
                              (item.type === "system_fee" &&
                                !userCanOverrideOfferChange)
                            }
                            style={{
                              color:
                                readOnlySalesManager ||
                                userIsBillingAssistant ||
                                userCanViewEditContactMark ||
                                (item.type === "system_fee" &&
                                  !userCanOverrideOfferChange)
                                  ? "#bdbdbd"
                                  : "#e91e63",
                            }}
                            onClick={() => {
                              onRemoveDiscount(item);
                            }}
                          >
                            <Iconify
                              icon="pepicons-print:credit-card-off"
                              size="small"
                            />
                          </Controls.IconButton>
                        </div>
                      </Tooltip>
                    )}
                  {
                    //allow edit for admin
                    invoice &&
                    canEditPackageItemPrice &&
                    canUserEditItem(item) &&
                    item &&
                    item.parent_id != null &&
                    !item.product_gift_reason_id &&
                    !item.gift_reference_price &&
                    //item.product.is_procedure &&
                    item.type != "forfeiture" ? (
                      // && item.price > 0
                      <Tooltip title={"Change item price"}>
                        <span>
                          <Controls.IconButton
                            disabled={
                              readOnlySalesManager ||
                              (item.type === "system_fee" &&
                                !userCanOverrideOfferChange)
                            }
                            color="primary"
                            onClick={() => {
                              setSelectedPackageItem(item);
                              setPackageItemPrice(item.base_price);
                              setOpenChangePricePopup(true);
                            }}
                          >
                            <Iconify
                              style={{
                                width: "22px",
                                height: "22px",
                                color: "#03a55c",
                              }}
                              icon="material-symbols:currency-exchange-rounded"
                              size="small"
                            />
                          </Controls.IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )
                  }

                  {
                    //allow edit for admin
                    canUserDeleteItem(item) &&
                      item &&
                      !item.parent_id &&
                      item.type != "forfeiture" &&
                      !item.quantity_done && (
                        <Tooltip title={"Delete Item"}>
                          <div>
                            <Controls.IconButton
                              disabled={
                                readOnlySalesManager ||
                                (item.type === "system_fee" &&
                                  !userCanOverrideOfferChange)
                              }
                              style={{
                                color:
                                  readOnlySalesManager ||
                                  (item.type === "system_fee" &&
                                    !userCanOverrideOfferChange)
                                    ? "#bdbdbd"
                                    : "#f44336",
                              }}
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this item?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => onDeleteItem(item.id),
                                })
                              }
                            >
                              <Iconify
                                icon="mingcute:delete-fill"
                                size="small"
                              />
                            </Controls.IconButton>
                          </div>
                        </Tooltip>
                      )
                  }
                  {
                    //allow edit for admin

                    item.type != "forfeiture" &&
                      (item.price_includes_qty ? (
                        item.available_quantity_to_void ? (
                          <Tooltip title={"Void Item"}>
                            <div>
                              <Controls.IconButton
                                disabled={
                                  readOnlySalesManager ||
                                  (item.type === "system_fee" &&
                                    !userCanOverrideOfferChange)
                                }
                                color="primary"
                                onClick={() => {
                                  setIsVoidItemPopupOpen(true);
                                  setSelectedItem(item);
                                }}
                              >
                                <Iconify icon="tabler:pencil-cancel" />
                                {/* <HighlightOffIcon size="small" /> */}
                              </Controls.IconButton>
                            </div>
                          </Tooltip>
                        ) : (
                          ""
                        )
                      ) : canVoid(item) && !item.quantity_done ? (
                        <Tooltip title={"Void Item"}>
                          <div>
                            <Controls.IconButton
                              disabled={
                                readOnlySalesManager ||
                                (item.type === "system_fee" &&
                                  !userCanOverrideOfferChange)
                              }
                              color="primary"
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to void this item?",
                                  subTitle:
                                    item.parent_id &&
                                    item.totalPrice > item.itemBalance &&
                                    items.find((a) => a.id == item.parent_id) &&
                                    item.totalPrice - item.itemBalance <=
                                      items.find((a) => a.id == item.parent_id)
                                        .package_balance -
                                        item.itemBalance &&
                                    item?.void == 0 &&
                                    canUserVoidItem
                                      ? "Payments and credits for this item will be distributed among the other items in this package"
                                      : "You can't undo this operation",
                                  onConfirm: () => onVoidItem(item.id),
                                })
                              }
                            >
                              <HighlightOffIcon size="small" />
                            </Controls.IconButton>
                          </div>
                        </Tooltip>
                      ) : (
                        ""
                      ))
                  }
                  {item.product &&
                  !item.void &&
                  userCanChangeDownpayment &&
                  invoice &&
                  invoice.downpayment_per_procedure &&
                  item.use_downpayment &&
                  item.product.is_procedure ? (
                    <Tooltip title={"Change downpayment"}>
                      <span>
                        <Controls.IconButton
                          color="primary"
                          onClick={() => {
                            openCustomDownpaymentPopup(item);
                          }}
                        >
                          <Iconify
                            icon="fluent:money-hand-24-filled"
                            style={{
                              width: 26,
                              height: 26,
                              marginBottom: 4,
                              color: "rgb(46 91 161)",
                            }}
                          />
                        </Controls.IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </TableCell>
            ) : (
              <TableCell>{""}</TableCell>
            )}
          </TableRow>
        );
      });
  };

  const onSend = async (id) => {
    try {
      const { data } = await Api.sendInvoice(id);
      if (data) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Invoice Sent Successfully",
          type: "success",
        });
        if (invoices && getInvoicesContact) {
          getInvoicesContact();
        } else if (setInvoices && invoices) {
          const index = findIndex(invoices, ["id", id]);
          const entity = {
            ...invoices[index],
            ...{ status: resp.data.status },
          };
          let entities = [...invoices];
          entities[index] = entity;

          //    console.log(entities);
          // setContactTickets([...entities]);

          setInvoices([...entities]);
        }

        setStatus("sent");
      }
    } catch (error) {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      setNotify({
        isOpen: true,
        message: error?.data?.message,
        type: "error",
      });
    }
    // Api.sendInvoice(id).then((resp) => {
    //   setConfirmDialog({
    //     ...confirmDialog,
    //     isOpen: false,
    //   });
    //   setNotify({
    //     isOpen: true,
    //     message: "Invoice Sent Successfully",
    //     type: "success",
    //   });
    //   if (invoices && getInvoicesContact) {
    //     getInvoicesContact();
    //   } else if (setInvoices && invoices) {
    //     const index = findIndex(invoices, ["id", id]);
    //     const entity = { ...invoices[index], ...{ status: resp.data.status } };
    //     let entities = [...invoices];
    //     entities[index] = entity;

    //     //    console.log(entities);
    //     // setContactTickets([...entities]);

    //     setInvoices([...entities]);
    //   }

    //   setStatus("sent");
    // });
  };

  const getActiveStep = () => {
    const index = findIndex(statusOptions, ["key", status]);
    return index;
  };

  const financingOptions = () => {
    return financing.map((financing) => {
      return {
        id: financing.id,
        title: financing.name,
        interest: financing.interest,
      };
    });
  };

  const applyFinancing = () => {
    const selectedFinancing = find(financing, { id: financingOption });
    if (selectedFinancing) {
      setFinancingInterest(
        invoice && invoice.interest
          ? invoice.interest
          : selectedFinancing.interest
      );
    } else {
      setFinancingInterest(false);
    }
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const handleClickPaymentLinkLan = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const sendPaymentLink = async (lan) => {
    setAnchorElLanguage(null);
    setSendPaymentLinkSmsStatus("sending");
    const payload = {
      invoice_id: invoice.id,
      lan: lan,
    };
    const { data } = await Api.sendContactPaymentLinkBySms(contact.id, payload);
    setSendPaymentLinkSmsStatus(data.data.status);
  };

  const salesmanOptions = () => {
    if (userCanChangeConsultantAll) {
      return usersDropdown.map((user) => {
        return {
          id: user.id,
          title: `${user.fname} ${user.lname}`,
        };
      });
    } else if (userCanChangeConsultant) {
      const salesman = usersDropdown
        .filter((user) => {
          return (
            (authUser.groups.data[0] &&
              user.groups.data[0] &&
              authUser.groups.data[0].id === user.groups.data[0].id &&
              user.is_salesman) ||
            (invoice && invoice.user_id == user.id)
          );
        })
        .map((user) => {
          return {
            id: user.id,
            title: `${user.fname} ${user.lname}`,
          };
        });

      return salesman;
    }

    return [];
  };

  const openCustomDownpaymentPopup = (item) => {
    setDownpaymentItem(item);
    setIsDownpaymentPopupOpened(true);
  };

  const closeDownpaymentPopup = () => {
    setDownpaymentItem(null);
    setIsDownpaymentPopupOpened(false);
  };

  const updateInvoiceItemDownpayment = async (data) => {
    try {
      await Api.updateInvoiceItemDownpayment(downpaymentItem.id, data);
      getInvoiceLocal();

      closeDownpaymentPopup();
      setNotify({
        isOpen: true,
        message: "Updated Successfully!",
        type: "success",
      });
    } catch (ex) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!",
        type: "error",
      });
    }
  };

  const generateQuote = async () => {
    try {
      if (invoice) {
        setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
        setGeneratingQuote(true);
        const { data } = await Api.generateInvoiceQuote(invoice?.id);

        setInvoice({ ...invoice, ...{ quote_id: data.data.id, quote: data } });

        setNotify({
          isOpen: true,
          message: "Quote created successfully!",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Invoice not found!",
          type: "error",
        });
      }
    } catch (err) {
      if ((err.status = 409)) {
        setNotify({
          isOpen: true,
          message: "This invoice already has a quote created!",
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setGeneratingQuote(false);
    }
  };

  const onCloseVoidItemPopup = () => {
    setIsVoidItemPopupOpen(false);
    setSelectedItem(null);
  };

  const onClosePaymentLinkRequest = () => {
    setPaymentLinkRequestOpened(false);
  };

  const onCloseSpecialPaymentLinkPopup = () => {
    setSpecialPaymentLinkPopupOpened(false);
  };

  const updateInvoiceData = (invoiceData) => {
    if (invoices && setInvoices) {
      const index = findIndex(invoices, ["id", invoiceData.id]);
      const entity = { ...invoices[index], ...invoiceData };
      let entities = [...invoices];
      entities[index] = entity;

      setInvoices([...entities]);
    }

    if (setInvoice) {
      setInvoice({
        ...invoice,
        ...invoiceData,
      });
    }
  };

  const addCoupons = (payload) => {
    var coupons = payload.coupons || [];
    setSelectedCoupons(coupons.map((c) => c.id));
  };
  const applyCouponsToInvoice = async (payload) => {
    try {
      const { data } = await Api.applyCouponsToInvoice(invoice.id, payload);

      setInvoice({ ...data.data });
      setNotify({
        isOpen: true,
        message: "Successfully applied!",
        type: "success",
      });
    } catch (err) {
      if (err?.data?.message || false) {
        setNotify({
          isOpen: true,
          message: err.data.message,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const getMaxCouponsToApply = () => {
    if (
      authUser &&
      authUser.building &&
      authUser.building.data &&
      authUser.building.data.max_number_of_coupons_per_invoice > 0
    ) {
      let availableQty =
        authUser.building.data.max_number_of_coupons_per_invoice -
        (invoice?.applied_coupons_qty || 0);
      return availableQty > 0 ? availableQty : 0;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    applyFinancing();
  }, [financingOption]);

  const checkCouponsCompability = (invoiceValue) => {
    if (invoice) {
      var result = true;
      const appliedCoupons = invoice.applied_coupons;

      appliedCoupons.forEach((coupon) => {
        var value =
          parseFloat(invoiceValue) -
            (parseFloat(invoice.totalCredits) -
              parseFloat(coupon.coupon_value)) <=
          0
            ? 0
            : parseFloat(invoiceValue) -
              (parseFloat(invoice.totalCredits) -
                parseFloat(coupon.coupon_value));
        if (
          value < parseFloat(coupon.min_invoice_price) ||
          (!coupon.available_for_discounted_invoices && invoice.allow_discounts)
        ) {
          result = false;
        }
      });
    }
    return result;
  };

  return (
    <Shadow>
      <div>
        <Card className={classes.card}>
          <CardContent>
            {userCanAdditem ||
            canSuperAddItem ||
            (status !== "paid" &&
              status !== "partial_paid" &&
              status !== "canceled") ||
            (invoice && invoice.paid > 0 && userCanSuperEditInvoice) ? (
              <Tooltip title={"Add Item"}>
                <Fab
                  color="primary"
                  aria-label="add"
                  size="small"
                  style={{ float: "right" }}
                  onClick={addInvoiceItem}
                  disabled={
                    canSuperAddItem
                      ? false
                      : readOnlySalesManager ||
                        userIsBillingBasic ||
                        userCanViewEditContactMark ||
                        userCanViewInvoice
                  }
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            ) : (
              ""
            )}

            <Stepper activeStep={getActiveStep()}>
              {map(statusOptions, (status) => (
                <Step key={status.label}>
                  <StepLabel>{status.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Grid container spacing={6}>
              <Grid item xs={12}>
                {invoice && !isEmpty(invoice.quote.data) ? (
                  <Typography component="div">
                    <Box fontWeight="fontWeightMedium" m={1}>
                      <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="see"
                        style={{ float: "right" }}
                        onClick={openRefQuote}
                      >
                        {"Reference Quote #   " + invoice.quote.data.name}
                      </Fab>
                    </Box>
                  </Typography>
                ) : invoice ? (
                  <div style={{ textAlign: "right" }}>
                    <Button
                      disabled={generatingQuote}
                      className={classes.generateInvoiceButton}
                      variant="contained"
                      color="primary"
                      startIcon={<Iconify icon="majesticons:textbox-plus" />}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            "Are you sure to generate a quote for this invoice?",
                          //subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            generateQuote();
                          },
                        });
                      }}
                    >
                      {generatingQuote
                        ? "Generating quote..."
                        : "Generate quote"}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12}>
                <AvailableCoupons
                  loadingCoupons={loadingCoupons}
                  availableCoupons={availableCoupons}
                  invoiceValue={
                    invoice
                      ? invoice.total_price - invoice.totalCredits
                      : calculateTotal()
                  }
                  applyCouponsToInvoice={
                    invoice ? applyCouponsToInvoice : addCoupons
                  }
                  maxCouponsToApply={getMaxCouponsToApply()}
                  defaultSelectedCoupons={availableCoupons.filter((c) =>
                    selectedCoupons.includes(c.id)
                  )}
                  allowEmptySubmit={!invoice}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ margin: 0, padding: 0, paddingLeft: 15 }}
              >
                INVOICE Date:
                {invoice
                  ? moment(invoice?.date).format("YYYY/MM/DD")
                  : moment().format("YYYY/MM/DD")}
              </Grid>
              <Grid item xs={6} style={{ marginTop: 0, marginTop: 0 }}>
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" m={1}>
                    BILLED FROM:
                  </Box>
                  <Box fontWeight="fontWeightBold" m={1}>
                    {invoice
                      ? invoice.building_id
                        ? invoice.building_name
                        : invoice.organization_name
                      : authUser.building_id
                      ? authUser.building_name
                      : authUser.organization.data.name}
                  </Box>
                  <Box fontWeight="fontWeightBold" m={1}>
                    {invoice
                      ? invoice.building_id
                        ? invoice.building_address
                        : invoice.organization_address
                      : authUser.building_id
                      ? authUser.building_address
                      : authUser.organization.data.address}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginTop: 0, marginTop: 0 }}>
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" m={1}>
                    BILLED TO:
                  </Box>

                  {contact ? (
                    <Box fontWeight="fontWeightBold" m={1}>
                      <Typography component="div">
                        {contact.full_name}
                      </Typography>{" "}
                    </Box>
                  ) : (
                    <Box m={3}>
                      <Controls.AutocompleteAsync
                        id={"contact_id"}
                        label={"Lead/Patient"}
                        fullWidth={true}
                        onChange={(newValue) => {
                          setLocalContact(newValue);
                        }}
                      />
                    </Box>
                  )}

                  <Box fontWeight="fontWeightMedium" m={1}>
                    {contact ? contact.address : ""}
                  </Box>
                  <Box m={1}>
                    {contact && contact.coordinator_name
                      ? "Consultant: " + contact.coordinator_name
                      : ""}
                  </Box>
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                {invoice && !isEmpty(invoice.pay_before) && (
                  <Typography component="div">
                    <Box fontWeight="fontWeightMedium" m={1}>
                      <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="see"
                        style={{ float: "right" }}
                        disabled
                      >
                        {"Pay Before: " + invoice.pay_before}
                      </Fab>
                    </Box>
                  </Typography>
                )}
              </Grid> */}

              <Grid item xs={3}>
                {invoice && !isEmpty(invoice.pay_before) && (
                  <Typography component="div">
                    <Box fontWeight="fontWeightMedium" m={1}>
                      <Controls.DatePicker2
                        fullWidth
                        label="Pay Before"
                        onChange={(date) => setPayBefore(date)}
                        value={payBefore}
                        disabled={!userCanChangePayBefore}
                      />
                    </Box>
                  </Typography>
                )}
              </Grid>
              {invoice &&
              invoice.quote &&
              invoice.quote.data &&
              invoice.quote.data.status == "re-sign_needed" ? (
                <Grid item xs={12}>
                  <div
                    style={{
                      width: "100%",
                      padding: "4px 18px",
                      display: "flex",
                      alignItems: "center",
                      background: "#fbf7d2cf",
                      borderRadius: 12,
                      maxWidth: "fit-content",
                    }}
                  >
                    <Iconify
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 4,
                        color: "#b1a609",
                      }}
                      icon="mingcute:alert-fill"
                    />
                    <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                      It is necessary to sign the quote for this invoice again.
                      You must send the contract to the contact
                    </Typography>
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {userCanFinancing && (
                <Grid item xs={4}>
                  <Controls.Select
                    disabled={
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      userCanViewInvoice ||
                      (invoice?.paid > 0 &&
                        userCanEditIncluded &&
                        !userCanSuperEditInvoice)
                    }
                    allowEmpty={true}
                    emptyLabel="None"
                    options={financingOptions()}
                    value={financingOption}
                    label="Financing"
                    onChange={(value) => {
                      setFinancingOption(value.target.value);
                    }}
                  />
                </Grid>
              )}
              {userCanFinancing &&
                financingOption > 0 &&
                userCanEditFinancingInterest && (
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      disabled={
                        readOnlySalesManager ||
                        userCanViewEditContactMark ||
                        userCanViewInvoice ||
                        (invoice?.paid > 0 &&
                          userCanEditIncluded &&
                          !userCanSuperEditInvoice)
                      }
                      fullWidth
                      defaultValue={financingInterest ? financingInterest : 0}
                      value={financingInterest}
                      label="Financing Interest"
                      onChange={(value) => {
                        setFinancingInterest(value.target.value);
                      }}
                    />
                  </Grid>
                )}
              {((status !== "paid" &&
                status !== "partial_paid" &&
                status !== "canceled") ||
                (invoice && invoice.paid > 0 && userCanSuperEditInvoice)) &&
              userCanAddGiftItem ? (
                <Grid item xs={6}>
                  <Tooltip title={"Add Gift Item"}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      size="small"
                      style={{ float: "right" }}
                      onClick={addInvoiceItemGift}
                      disabled={
                        readOnlySalesManager ||
                        userIsBillingBasic ||
                        userCanViewEditContactMark ||
                        userCanViewInvoice
                      }
                    >
                      <Iconify
                        icon="fluent:gift-card-add-24-filled"
                        style={{ fontSize: 24, color: "#fff" }}
                      />
                    </Fab>
                  </Tooltip>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Price</TableCell>
                {/*
                <TableCell>
                  {financingInterest ? "Cash Value / Regular Price" : "Price"}
                </TableCell>
                */}

                <TableCell>Quantity</TableCell>
                {invoice && invoice.downpayment_per_procedure ? (
                  <TableCell align="right">{"Downpayment"}</TableCell>
                ) : (
                  ""
                )}

                <TableCell align="right">
                  {invoice?.invoice_total_name
                    ? invoice?.invoice_total_name
                    : "Total"}
                </TableCell>
                {/* {invoice && invoice.status !== "paid" && ( */}
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                {/* )} */}
                {userCanUnvoidItem ||
                canVoid() ||
                canUserEditItemP ||
                canUserDeleteItemP ? (
                  <TableCell align="right"></TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {renderItems()}

              <TableRow>
                <TableCell />
                <TableCell />
                {invoice && invoice.downpayment_per_procedure ? (
                  <TableCell />
                ) : (
                  ""
                )}
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    {invoice?.invoice_total_name
                      ? invoice?.invoice_total_name
                      : "Total"}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    ${""}
                    {calculateTotal().toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Box>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow></TableRow>
              <TableRow></TableRow>
              {org_footer == 1 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  {invoice && invoice.downpayment_per_procedure ? (
                    <TableCell />
                  ) : (
                    ""
                  )}
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      Pymts/Credits
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      ${" "}
                      {calulateTotalPaymentsCreditRefund().toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      <Tooltip
                        title={
                          <div>
                            <Box fontWeight="fontWeightBold" m={1}>
                              {`Payments: $${calulateTotalPayments().toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}   `}
                            </Box>

                            <Box fontWeight="fontWeightBold" m={1}>
                              {`Credits: $${calculateTotalCredits().toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}   `}
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                              {`Refunds: $${calulateTotalRefunds().toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}   `}
                            </Box>
                          </div>
                        }
                        arrow
                      >
                        <InfoIcon style={{ color: "#26a69a" }} />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />

                  <TableCell />
                </TableRow>
              )}
              {invoice && org_footer == 2 && calulateTotalPayments() > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  {invoice && invoice.downpayment_per_procedure ? (
                    <TableCell />
                  ) : (
                    ""
                  )}
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      Payments
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      ${" "}
                      {calulateTotalPayments().toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}

              {invoice && org_footer == 2 && invoice.totalCredits > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  {invoice && invoice.downpayment_per_procedure ? (
                    <TableCell />
                  ) : (
                    ""
                  )}
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      Credits
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      ${" "}
                      {calculateTotalCredits().toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
              {invoice && org_footer == 2 && calulateTotalRefunds() > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  {invoice && invoice.downpayment_per_procedure ? (
                    <TableCell />
                  ) : (
                    ""
                  )}
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="red">
                      Refunds
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="red">
                      ${" "}
                      {calulateTotalRefunds().toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
              {invoice && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  {invoice && invoice.downpayment_per_procedure ? (
                    <TableCell />
                  ) : (
                    ""
                  )}
                  <TableCell align="right">
                    <Box
                      fontWeight="fontWeightBold"
                      m={1}
                      style={{
                        color: invoice.balance <= 0 ? "#4caf50" : "#f44336",
                      }}
                    >
                      Balance
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      fontWeight="fontWeightBold"
                      m={1}
                      style={{
                        color: invoice.balance <= 0 ? "#4caf50" : "#f44336",
                      }}
                    >
                      {` ${
                        invoice.balance && invoice.balance < 0
                          ? " (OverPaid)"
                          : ""
                      } $${
                        invoice.balance
                          ? invoice.balance.toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"
                      }`}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
      </div>
      {!invoice && selectedCoupons.length > 0 ? renderSelectedCoupons() : ""}
      {!isEmpty(items) && (
        <Card>
          <CardContent>
            <Grid
              container
              spacing={4}
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid
                item
                xs={
                  (userCanChangeConsultant || userCanChangeConsultantAll) &&
                  invoice
                    ? 6
                    : 12
                }
              >
                <Controls.Input
                  defaultValue={included}
                  disabled={!userCanEditIncluded && !userCanSuperEditInvoice}
                  label="Included"
                  className={classes.included_margin}
                  onChange={(event) => {
                    changeIncluded(event.target.value);
                  }}
                />{" "}
              </Grid>
              {(userCanChangeConsultant || userCanChangeConsultantAll) &&
              invoice ? (
                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    options={salesmanOptions()}
                    variant="outlined"
                    value={userID ? userID : invoice?.user_id}
                    label="Owner"
                    onChange={(value) => {
                      setUserID(value.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={
                      (!userCanChangeConsultant &&
                        !userCanChangeConsultantAll) ||
                      readOnlySalesManager ||
                      userIsBillingAssistant ||
                      userCanViewEditContactMark
                    }
                  >
                    {salesmanOptions().map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : (
                ""
              )}
              {invoice?.payment_link &&
                invoice.payment_link !== "" &&
                userCanSendInvoicePayment && (
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", marginBottom: "2px" }}
                  >
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="Copy_to_clipboard_payment_link"
                        label="Payment link"
                        defaultValue={invoice.payment_link}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Tooltip
                      title={isPaymentLinkCopied ? "Copied!" : "Click to Copy!"}
                      placement="top"
                      //arrow
                    >
                      <Controls.IconButton
                        color="primary"
                        onClick={() => {
                          setIsPaymentLinkCopied(true);
                          navigator.clipboard.writeText(invoice.payment_link);
                        }}
                        onMouseLeave={() => {
                          setIsPaymentLinkCopied(false);
                        }}
                      >
                        <FileCopyIcon size="small" />
                      </Controls.IconButton>
                    </Tooltip>
                    <Tooltip
                      title={"Send Payment Link By Sms"}
                      placement="right"
                      //arrow
                    >
                      <Controls.IconButton
                        color="primary"
                        disabled={sendPaymentLinkSmsStatus || !authUser.phone}
                        onClick={handleClickPaymentLinkLan}
                      >
                        <SendIcon size="small" />
                      </Controls.IconButton>
                    </Tooltip>
                    {sendPaymentLinkSmsStatus == "sending" && (
                      <Alert severity="info">Sending Payment Link...</Alert>
                    )}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorElLanguage}
                      keepMounted
                      open={Boolean(anchorElLanguage)}
                      onClose={handleCloseLanguage}
                    >
                      <MenuItem
                        onClick={() => {
                          sendPaymentLink(1);
                        }}
                      >
                        <Iconify
                          style={{ margin: 4 }}
                          icon={"emojione:flag-for-united-states"}
                        />
                        Send Payment link in English
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          sendPaymentLink(2);
                        }}
                      >
                        <Iconify
                          style={{ margin: 4 }}
                          icon={"emojione:flag-for-spain"}
                        />
                        Send Payment link in Spanish
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}

              {invoice && invoice.enable_payment_non_3ds_option ? (
                userCanClosePaymentLinkRequestSuper ||
                (userCanClosePaymentLinkRequest &&
                  invoice.building &&
                  invoice.building.data &&
                  invoice.building.data.building_payment_closers &&
                  invoice.building.data.building_payment_closers.find(
                    (u) => u == authUser.id
                  )) ? (
                  <Grid item xs={12}>
                    <Button
                      startIcon={<Iconify icon="lucide:link" />}
                      variant="outlined"
                      color="primary"
                      style={{ marginBottom: 10 }}
                      onClick={() => {
                        setSpecialPaymentLinkPopupOpened(true);
                      }}
                    >
                      Send Non-3DS Payment Link
                    </Button>
                  </Grid>
                ) : userCanMakePaymentLinkRequest ? (
                  <Grid item xs={12}>
                    <Button
                      startIcon={<Iconify icon="lucide:link" />}
                      variant="outlined"
                      color="primary"
                      style={{ marginBottom: 10 }}
                      onClick={() => {
                        setPaymentLinkRequestOpened(true);
                      }}
                    >
                      Request non-3DS payment link
                    </Button>
                  </Grid>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Grid>
            <Grid container justifyContent="flex-start">
              {invoice && (
                <ReactToPrint
                  trigger={() => (
                    <Controls.Button
                      disabled={
                        (readOnlySalesManager &&
                          !userIsFrontDesk &&
                          !userIsSocialMedia &&
                          !userIsCallCenter &&
                          !userIsBillingMGAssistant) ||
                        userCanViewEditContactMark ||
                        userCanViewInvoice ||
                        updateNeeded ||
                        updatePackagePriceNeeded ||
                        (invoice.financing_id &&
                          financingOption != -1 &&
                          financingOption != invoice.financing_id) ||
                        (invoice.financing_id && financingOption == -1) ||
                        (!invoice.financing_id && financingOption != -1)
                      }
                      color="primary"
                      startIcon={<PrintIcon />}
                      text="Print Invoice"
                    />
                  )}
                  content={() => invoiceRef.current}
                />
              )}

              {!invoice ||
              (invoice && invoice?.paid <= 0) ||
              userCanFinancing ||
              (invoice &&
                invoice?.paid > 0 &&
                (userCanSuperEditInvoice || userCanEditIncluded)) ? (
                <Controls.Button
                  disabled={
                    !invoice || userCanFinancing
                      ? false
                      : (submitDisabled && !invoice) ||
                        (invoice?.paid > 0 &&
                          !userCanSuperEditInvoice &&
                          !userCanEditIncluded) ||
                        readOnlySalesManager ||
                        userCanViewEditContactMark ||
                        userCanViewInvoice
                      ? true
                      : false
                  }
                  variant="contained"
                  color="secondary"
                  text={invoice ? "Update" : "Create"}
                  startIcon={<SaveIcon />}
                  align="right"
                  onClick={() => {
                    setSubmitDisabled(true);
                    saveInvoice();
                  }}
                />
              ) : (
                ""
              )}

              {invoice && (
                <Controls.Button
                  disabled={
                    (readOnlySalesManager &&
                      !userIsFrontDesk &&
                      !userIsSocialMedia &&
                      !userIsCallCenter) ||
                    userCanViewEditContactMark ||
                    userCanViewInvoice ||
                    updateNeeded ||
                    updatePackagePriceNeeded ||
                    (invoice.financing_id &&
                      financingOption != -1 &&
                      financingOption != invoice.financing_id) ||
                    (invoice.financing_id && financingOption == -1) ||
                    (!invoice.financing_id && financingOption != -1)
                  }
                  variant="contained"
                  color="secondary"
                  startIcon={<SendIcon />}
                  text="Send Invoice"
                  align="right"
                  onClick={(event, rowData) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to send this invoice?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => onSend(invoice.id),
                    })
                  }
                />
              )}

              {invoice &&
                invoice.status !== "paid" &&
                invoice.status !== "canceled" &&
                userCanMakePayment && (
                  <Controls.Button
                    disabled={
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      userCanViewInvoice ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (invoice.financing_id &&
                        financingOption != -1 &&
                        financingOption != invoice.financing_id) ||
                      (invoice.financing_id && financingOption == -1) ||
                      (!invoice.financing_id && financingOption != -1)
                    }
                    variant="contained"
                    color="secondary"
                    startIcon={<PaymentIcon />}
                    text="Make Payment"
                    align="right"
                    onClick={() => {
                      setOpenPayment(true);
                    }}
                  />
                )}

              {invoice &&
                calulateTotalPayments().toFixed(2) > 0 &&
                invoice.status !== "canceled" && (
                  <Controls.Button
                    disabled={
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      userCanViewInvoice ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (invoice.financing_id &&
                        financingOption != -1 &&
                        financingOption != invoice.financing_id) ||
                      (invoice.financing_id && financingOption == -1) ||
                      (!invoice.financing_id && financingOption != -1)
                    }
                    variant="contained"
                    color="secondary"
                    startIcon={<MoneyOffIcon />}
                    text="Refund items"
                    align="right"
                    onClick={() => {
                      setOpenRefund(true);
                    }}
                  />
                )}
              {invoice &&
                invoice.status !== "canceled" &&
                userCanCreateCredit && (
                  <Controls.Button
                    disabled={
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      userCanViewInvoice ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (invoice.financing_id &&
                        financingOption != -1 &&
                        financingOption != invoice.financing_id) ||
                      (invoice.financing_id && financingOption == -1) ||
                      (!invoice.financing_id && financingOption != -1)
                    }
                    variant="contained"
                    color="secondary"
                    startIcon={<CardGiftcardIcon />}
                    text="Add Credit Memo To Invoice"
                    align="right"
                    onClick={() => {
                      setOpenCreditMemo(true);
                    }}
                  />
                )}
              {invoice &&
                invoice.status !== "canceled" &&
                userCanSplitInvoice && (
                  <Controls.Button
                    disabled={
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      userCanViewInvoice ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (invoice.financing_id &&
                        financingOption != -1 &&
                        financingOption != invoice.financing_id) ||
                      (invoice.financing_id && financingOption == -1) ||
                      (!invoice.financing_id && financingOption != -1) ||
                      (items &&
                        filter(
                          items,
                          (item) => item?.type !== "system" && !item?.parent_id
                        )?.length <= 1)
                    }
                    variant="contained"
                    color="secondary"
                    startIcon={
                      <Iconify
                        icon={"humbleicons:arrow-main-split-side"}
                        width={24}
                        height={24}
                        style={{ color: "#ffffff" }}
                      />
                    }
                    text="Split Invoice"
                    align="right"
                    onClick={() => {
                      splitInvoiceOpen();
                    }}
                  />
                )}
            </Grid>
          </CardContent>
        </Card>
      )}

      <Popup
        title="Invoice item"
        fullWidth={true}
        maxWidth="lg"
        openPopup={openItem}
        onClose={closeItemPopup}
      >
        <InvoiceItemForm
          invoice={invoice}
          users={users}
          products={products}
          recordproducts={recordproducts}
          item={editItem}
          saveItem={(data) => saveInvoiceItem(data)}
          productGiftReason={productGiftReason}
          editItemUserPrice={editItemUserPrice}
          userAZ={userAZ}
          allowDiscounts={
            (invoice && invoice.allow_discounts) ||
            (!invoice &&
              !availableCoupons.find(
                (c) =>
                  selectedCoupons.includes(c.id) &&
                  !c.available_for_discounted_invoices
              ))
          }
          contactID={contact?.id || null}
        />
      </Popup>

      <Popup
        title="Invoice Gift item"
        fullWidth={true}
        maxWidth="lg"
        openPopup={openItemGift}
        onClose={closeItemPopupGift}
      >
        <InvoiceItemForm
          invoice={invoice}
          users={users}
          products={products}
          recordproducts={recordproducts}
          item={editItem}
          saveItem={(data) => saveInvoiceItem(data)}
          productGiftReason={productGiftReason}
          giftCheckAuto
          contactID={contact?.id || null}
          allowDiscounts={invoice && invoice.allow_discounts}
        />
      </Popup>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Popup
        fullScreen
        title="Ref Quote"
        fullWidth={true}
        openPopup={openQuote}
        onClose={closeRefQuote}
      >
        <QuoteDetails quote={quote} />
      </Popup>

      <Popup
        title="Make Payment"
        fullWidth
        maxWidth="lg"
        openPopup={openPayment}
        onClose={closePaymentPopup}
        disableEscapeKeyDown={selectedPaymentTypeTab !== 1 ? false : true}
        disableBackdropClick={selectedPaymentTypeTab !== 1 ? false : true}
      >
        <PaymentForm
          invoice={invoice}
          savePayment={savePayment}
          gateways={paymentGateways}
          className={classes.form}
          setNotify={setNotify}
          selectedPaymentTypeTab={selectedPaymentTypeTab}
          setSelectedPaymentTypeTab={setSelectedPaymentTypeTab}
        />
      </Popup>

      <Popup
        title="Make Payment"
        fullWidth
        openPopup={openPaymentNew}
        onClose={closePaymentPopupNew}
        disableEscapeKeyDown={selectedPaymentTypeTab !== 1 ? false : true}
        disableBackdropClick={selectedPaymentTypeTab !== 1 ? false : true}
        maxWidth="lg"
      >
        <PaymentFormNew
          invoice={invoice}
          savePayment={savePayment}
          gateways={paymentGateways}
          className={classes.form}
          setNotify={setNotify}
          selectedPaymentTypeTab={selectedPaymentTypeTab}
          setSelectedPaymentTypeTab={setSelectedPaymentTypeTab}
        />
      </Popup>

      <Popup
        title="Downpayment amount"
        fullWidth={true}
        openPopup={isDownpaymentPopupOpened}
        onClose={closeDownpaymentPopup}
        maxWidth={"xs"}
      >
        <ChangeItemDownpaymentForm
          addOrEdit={updateInvoiceItemDownpayment}
          item={downpaymentItem}
        />
      </Popup>

      {invoice && (
        <Popup
          title="Refund Request Form"
          openPopup={openRefund}
          onClose={closeRefundPopup}
          fullWidth={true}
        >
          <RefundRequestForm2
            onClose={closeRefundPopup}
            addOrEdit={addOrEdit}
            refundReasons={refundReasons}
            invoiceSelected={invoice}
          />
        </Popup>
      )}

      {invoice && (
        <Popup
          title="Add Credit Memo"
          fullWidth={true}
          openPopup={openCreditMemo}
          onClose={closeCreditMemo}
        >
          <CreditMemosInvoices
            checkCouponsCompability={checkCouponsCompability}
            invoice={invoice}
            contact={invoice.contact.data}
            updateInvoice={updateInvoice}
            applyInvoiceCreditMemoupdate={applyInvoiceCreditMemoupdate}
          />
        </Popup>
      )}

      <Popup
        title="Change item price"
        fullWidth={true}
        maxWidth="xs"
        openPopup={openChangePricePopup}
        onClose={closeChangePrice}
      >
        <div>
          <Typography style={{ fontWeight: 500, color: "#003f76" }}>
            {`Edit ${
              selectedPackageItem && selectedPackageItem.name
                ? selectedPackageItem.name
                : ""
            } price`}
          </Typography>
          <Grid container style={{ alignItems: "center" }} spacing={1}>
            <Grid item xs={10}>
              <TextField
                type="number"
                fullWidth
                placeholder="Price"
                onChange={(event) => setPackageItemPrice(event.target.value)}
                variant="outlined"
                value={packageItemPrice}
                defaultValue={
                  selectedPackageItem && selectedPackageItem.price
                    ? selectedPackageItem.price
                    : null
                }
              ></TextField>
              {packageItemPrice != null &&
              selectedPackageItem &&
              ((invoice &&
                !invoice.allow_discounts &&
                packageItemPrice < selectedPackageItem.base_price) ||
                (userCanUseMinPrice &&
                packageItemPrice.product_min_price !== null
                  ? packageItemPrice < selectedPackageItem.product_min_price
                  : packageItemPrice < selectedPackageItem.base_price)) ? (
                <Typography style={{ color: "red" }}>
                  {`*Price must be greater than ${
                    invoice && !invoice.allow_discounts
                      ? selectedPackageItem.base_price
                      : userCanUseMinPrice &&
                        packageItemPrice.product_min_price !== null
                      ? selectedPackageItem.product_min_price
                      : selectedPackageItem.base_price
                  }`}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={1}>
              <Button
                disabled={
                  (invoice &&
                    !invoice.allow_discounts &&
                    selectedPackageItem &&
                    packageItemPrice < selectedPackageItem.base_price) ||
                  (packageItemPrice != null &&
                    selectedPackageItem &&
                    (userCanUseMinPrice &&
                    packageItemPrice.product_min_price !== null
                      ? packageItemPrice < selectedPackageItem.product_min_price
                      : packageItemPrice < selectedPackageItem.base_price))
                }
                color="primary"
                variant="contained"
                onClick={() => {
                  changePackagePrice();
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popup>

      <Notification notify={notify} setNotify={setNotify} autoHide={6000} />

      <Popup
        title="Notification"
        fullWidth={false}
        openPopup={openNotification}
        onClose={closeNotification}
      >
        This item already exist in the invoice, please select another one or
        change responsible person.
      </Popup>

      <Dialog
        aria-labelledby="discount-invoiceitem-dialog"
        open={applyDiscountPopUp}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            borderRadius: 20,
          },
        }}
        onClose={closeApplyDiscount}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }}>
            <MuiTypography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              {`Discount to ${applyDiscountItem?.name}`}
            </MuiTypography>
            <Controls.IconButton
              color="secondary"
              onClick={closeApplyDiscount}
              style={{ textAlign: "right" }}
            >
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers={true}>
          <ApplyItemDiscountForm
            addOrEdit={onApplyDiscount}
            entityForEdit={applyDiscountItem}
          />
        </DialogContent>
      </Dialog>
      {invoice && (
        <div style={{ display: "none" }}>
          <div ref={invoiceRef}>
            <PrintInvoiceDetails
              invoice={invoice}
              financingInterest={financingInterest}
            />
          </div>
        </div>
      )}

      <Popup
        title={"Edit void quantity"}
        fullWidth={true}
        openPopup={isVoidItemPopupOpen}
        onClose={onCloseVoidItemPopup}
        maxWidth={"xs"}
      >
        <VoidInvoiceItemQuantityForm
          updateInvoice={updateInvoiceData}
          onClose={onCloseVoidItemPopup}
          item={selectedItem}
        />
      </Popup>

      <Popup
        title={"Make a non-3DS Payment Link Request "}
        fullWidth={true}
        openPopup={paymentLinkRequestOpened}
        onClose={onClosePaymentLinkRequest}
        maxWidth={"sm"}
      >
        <PaymentLinkRequestForm
          invoiceID={invoice?.id || null}
          onClose={onClosePaymentLinkRequest}
          onSuccessfulSubmit={() => {
            setNotify({
              isOpen: true,
              message: "Requested Successfully!",
              type: "success",
            });
          }}
          minValue={invoice?.min_payment_amount || 0}
          setNotify={setNotify}
        />
      </Popup>

      <Popup
        title={"Send Non-3DS Payment Link"}
        fullWidth={true}
        openPopup={specialPaymentLinkPopupOpened}
        onClose={onCloseSpecialPaymentLinkPopup}
        maxWidth={"sm"}
      >
        <SpecialPaymentLinkForm
          invoiceID={invoice?.id || null}
          setNotify={setNotify}
          onClose={onCloseSpecialPaymentLinkPopup}
          onSuccessfulSubmit={() => {
            setNotify({
              isOpen: true,
              message: "Sent Successfully!",
              type: "success",
            });
          }}
          minValue={invoice?.min_payment_amount || 0}
        />
      </Popup>

      {!isEmpty(items) ? (
        <Popup
          title="Select Items to Send to the New Invoice"
          fullWidth
          openPopup={splitInvoicePopup}
          onClose={closeSplitInvoice}
          maxWidth="md"
        >
          <SplitInvoiceForm
            localItems={filter(
              items,
              (item) => item.type !== "system" && !item.parent_id
            )}
            onSplit={onSplit}
            splitInvoiceLoading={splitInvoiceLoading}
            getItemPrice={getItemPrice}
          />
        </Popup>
      ) : (
        ""
      )}
    </Shadow>
  );
}
export default InvoiceBuilder;
