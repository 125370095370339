import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'pollQuestionOption';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

/*export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code="GET_ACC_POLLQUESTION";
  return _fetchEntitiesService(dataSlice,events);
}*/
export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code="POST_ACC_POLLQUESTIONOPTION";
  return _createEntityService(dataSlice,events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code="PUT_ACC_POLLQUESTIONOPTION";
  dataSlice.dataUpdate.isPost=true;
  return _updateEntityService(dataSlice,events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code="DELETE_ACC_POLLQUESTIONOPTION";
  return _deleteEntityService(dataSlice,events);
}

/* Custom functions */
export function fetchOptionByQuest(dataSlice) {
  dataSlice.dataUrl.code = "GET_ACC_POLLQUESTIONOPTION_BY_QUESTION";
  return _fetchEntitiesService(dataSlice, events);
}