import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// @mui
import {
  Box,
  Badge,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  ListItemText,
} from "@material-ui/core";
import MaterialTable, { MTableBody } from "material-table";

import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  paper: {
    zIndex: "1000",
    height: "100%",
    width: "100%",
    borderRadius: 18,
    padding: 10,
  },

  customPaper: {
    minHeight: "100%",
    width: "100%",
    boxShadow: "none",
    margin: 0,
    padding: 0,
    "& > table": {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      boxShadow: "none",
    },
  },
  boldclass: {
    fontWeight: "600",
    fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
  },
  normalclass: {
    fontWeight: "normal",
    fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
  },
}));

// ----------------------------------------------------------------------

export default function MessageRight(props) {
  const {
    emailOrder,
    emailStore,
    pagination,
    loading,
    title,
    onPageChange,
    onRowPerPageChange,
    onEmailClick,
    refreshMessages,
    fromIndex,
  } = props;

  const handleChangePage = async (page) => {
    if (_.isFunction(onPageChange)) {
      onPageChange(page);
    } else {
      console.error("No prop function onPageChange");
    }
  };
  const classes = useStyles();
  const handleChangeRowsPerPage = async (rpp) => {
    if (_.isFunction(onRowPerPageChange)) {
      onRowPerPageChange(rpp);
    } else {
      console.error("No prop function onRowPerPageChange");
    }
  };

  const handleRowClick = async (message) => {
    if (_.isFunction(onEmailClick)) {
      onEmailClick(message);
    } else {
      console.error("No prop function onEmailClick");
    }
  };

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let m = [...emailOrder]
      .sort((a, b) => (a < b ? 1 : -1))
      .map((msg_id) => ({
        id: msg_id,
        ...emailStore[msg_id],
      }));
    setMessages(m);
  }, [emailOrder, emailStore]);

  const headColumns = [
    // {
    //   title: (
    //     <Tooltip title="Recent">
    //       <FlareIcon />
    //     </Tooltip>
    //   ),
    //   render: (row) =>
    //     Object.hasOwn(row, "flags") && Object.hasOwn(row.flags, "recent") ? (
    //       <FlareIcon color="primary" />
    //     ) : null,
    //   width: 5,
    // },
    {
      title: (
        <Tooltip title="Attachments">
          <Iconify
            icon={"ic:outline-attach-email"}
            width={25}
            height={25}
            style={{ color: "#607d8b" }}
          />
        </Tooltip>
      ),
      render: (row) =>
        row.has_attachment ? (
          <Badge
            badgeContent={row.attachment_count}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Iconify
              icon={"typcn:attachment"}
              width={25}
              height={25}
              style={{ color: "#90a4ae" }}
            />
          </Badge>
        ) : null,
      width: 3,
    },
    {
      title: "From",
      field: "from",
      render: (rowData) => {
        const from = rowData.from ? rowData.from.split("<") : "";
        return (
          <ListItemText
            style={{ marginBottom: 1, marginTop: 1 }}
            classes={{
              primary:
                Object.hasOwn(rowData, "flags") &&
                !Object.hasOwn(rowData.flags, "seen")
                  ? classes.boldclass
                  : classes.normalclass,
              secondary:
                Object.hasOwn(rowData, "flags") &&
                !Object.hasOwn(rowData.flags, "seen")
                  ? classes.boldclass
                  : classes.normalclass,
            }}
            primary={rowData.from ? from[0] : ""}
            secondary={rowData.from ? from[1].split(">")[0] : ""}
          />
        );
      },
    },
    {
      title: "Subject",
      field: "subject",
    },

    {
      title: "Date",
      field: "date",
      defaultSort: "desc",
    },
  ];

  const options = {
    pageSize: pagination?.per_page ? pagination.per_page : 10,
    search: false,
    emptyRowsWhenPaging: true,
    pageSizeOptions: [10],
    rowStyle: (row) => ({
      fontWeight:
        row && Object.hasOwn(row, "flags") && !Object.hasOwn(row.flags, "seen")
          ? "600"
          : "normal",
      fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
    }),
  };

  const components = {
    Body: (props) => (
      <React.Fragment>
        <MTableBody {...props} />
        <Box as="tbody" height={50}></Box>
      </React.Fragment>
    ),
    Container: (props) => <Paper className={classes.customPaper} {...props} />,
  };

  return (
    <Paper className={classes.paper}>
      <MaterialTable
        columns={headColumns}
        data={messages}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        options={options}
        totalCount={pagination.total}
        page={pagination.current_page - 1}
        title={
          <Box>
            <Typography variant="h4" style={{ display: "inline-block" }}>
              {title}
            </Typography>
            <Tooltip title="Refresh" style={{ marginLeft: 15 }}>
              <IconButton onClick={refreshMessages}>
                <Iconify
                  icon={"ic:twotone-refresh"}
                  width={25}
                  height={25}
                  style={{ color: "#607d8b" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        }
        onRowClick={(event, row) => handleRowClick(row)}
        components={components}
      />
    </Paper>
  );
}
