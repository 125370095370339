import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function AutocompleteControl(props) {
  const {
    label,
    options,
    onChange,
    defaultValue,
    allowNew,
    disabled,
    disableClear,
    required,
  } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      value={defaultValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onChange({
            title: newValue.inputValue,
          });
        } else {
          onChange(newValue);
        }
      }}
      renderOption={(option) => option.title}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "" && allowNew === true) {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      freeSolo={allowNew ? allowNew : false}
      disableClearable={disableClear ? disableClear : false}
      selectOnFocus
      handleHomeEndKeys
      disabled={disabled ? disabled : false}
      id={label}
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          variant="outlined"
          className={classes.textfield}
        />
      )}
    />
  );
}
