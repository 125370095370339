import { useQuery } from "react-query";
import Api from "../../../lib/api";
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	LinearProgress,
	Tab,
	Tabs,
	Typography,
	makeStyles,
} from "@material-ui/core";
import PieChart from "../../../components/controls/PieChart";
import Chart from "react-apexcharts";
import moment from "moment";
import { useMemo, useState } from "react";
import Select from "../../../components/controls/Select";
import { green, red } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

let month_array = [6, 12, 24, 36, 48];

const useStyle = makeStyles((theme) => ({
	card: {
		position: "relative",
	},
	ticketCount: {
		position: "absolute",
		bottom: 0,
		right: 15,
    },
    dateRange: {
		border: `1px solid ${theme.palette.grey[500]}`,
		padding: 15,
		borderRadius: theme.shape.borderRadius,
		"&>div": {
			border: 0,
		},
	},
}));

const TaskDashboad = () => {
	const classes = useStyle();
    const [tabValue, setTabValue] = useState(null);
    const [dateRange, setDateRange] = useState([moment().startOf('month'),moment().endOf('month')]);

	const previousMonth = useMemo(() => {
		return month_array.map((m) => ({
			id: m,
			name: `Previous ${m} Month`,
		}));
	}, []);

	const [prevMonthInput, setPrevMonthInput] = useState(6);

	const { data: taskTypes, isLoading: taskTypeLoading } = useQuery(
		["tk_task_type"],
		() =>
			Api.genericQueryModel("tk_task_type", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
			onSuccess: (data) => {
				if (tabValue == null) {
					setTabValue(data[0].id);
				}
			},
		}
	);

	const { data: users = [], isLoading: usersisLoading } = useQuery(
		["reportbyuser", dateRange],
		() =>
			Api.generiReport("taskreportbyuser", {
				history: dateRange,
				taskType: tabValue,
			}),
		{
			select: (data) => data.data,
			enabled: tabValue != null,
		}
	);

	const { data: priorities = [], prioritiesisLoading } = useQuery(
		["reportbypriorities", dateRange],
		() =>
			Api.generiReport("taskreportbypriorities", {
				history: dateRange,
				taskType: tabValue,
			}),
		{
			select: (data) => data.data,
			enabled: tabValue != null,
		}
	);

	const { data: states = [], statesisLoading } = useQuery(
		["reportbystates", dateRange],
		() =>
			Api.generiReport("taskreportbystates", {
				history: dateRange,
				taskType: tabValue,
			}),
		{
			select: (data) => data.data,
			enabled: tabValue != null,
		}
	);

	const { data: duration = [], durationisLoading } = useQuery(
		["reportbyduration", dateRange],
		() =>
			Api.generiReport("taskreportbyduration", {
				history: dateRange,
				taskType: tabValue,
			}),
		{
			select: (data) => data.data,
			enabled: tabValue != null,
		}
	);

	return (
		<>
			<Grid
				container
				style={{
					paddingTop: 20,
				}}
			>
				<Grid item md={5} xs={12} >
					<DateRangePicker
						onChange={setDateRange}
						value={dateRange}
                        className={classes.dateRange}
                        format={'dd/MM/yyyy'}
					/>
                </Grid>
                <Grid item md={7} xs={12}>
					<ButtonGroup fullWidth={true}>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().startOf('week'),
                                moment(),
                            ])
                        }}>
                            This week
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(1,'week').startOf('week'),
                                moment().subtract(1,'week').endOf('week'),
                            ])
                        }}>
                            Last Week
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().startOf('month'),
                                moment(),
                            ])
                        }}>
                            This month
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(1,'month').startOf('month'),
                                moment().subtract(1,'month').endOf('month'),
                            ])
                        }}>
                            Last month
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(3,'month').startOf('month'),
                                moment(),
                            ])
                        }}>
                            Last 3 Months
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(6,'month').startOf('month'),
                                moment(),
                            ])
                        }}>
                            Last 6 Months
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().startOf('year'),
                                moment(),
                            ])
                        }}>
                            This Year
                        </Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			{taskTypeLoading && <LinearProgress />}
			<Tabs value={tabValue}>
				{taskTypes?.map((tt) => (
					<Tab value={tt.id} label={tt.label} />
				))}
			</Tabs>
			<Grid container direction="column" spacing={4}>
				<Typography
					style={{
						fontSize: 20,
					}}
				>
					Task by priorities
				</Typography>
				<Grid container item xs={12} spacing={4}>
					<Grid container item xs={12} sm={6} md={4} xl={2} spacing={2}>
						{prioritiesisLoading ? (
							<Skeleton
								style={{
									width: "100%",
									height: 200,
								}}
							/>
						) : (
							priorities.map((p) => (
								<Grid item xs={6}>
									<Card
										style={{
											height: 100,
										}}
									>
										<CardContent className={classes.card}>
											<Typography
												variant="h5"
												style={{
													color: p.color,
												}}
											>
												{p.name}:
											</Typography>
											<Typography
												style={{
													display: "inline",
												}}
												variant="h3"
											>
												{p?.amount?.toLocaleString(
													"en-US",
													{
														style: "currency",
														currency: "USD",
													}
												)}
											</Typography>
											<Typography
												className={classes.ticketCount}
											>
												{p.count} task(s)
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							))
						)}
					</Grid>
					<Grid item xs={12} md={4} sm={6} xl={5}>
						{prioritiesisLoading ? (
							<Skeleton
								style={{
									width: "100%",
									height: 200,
								}}
							/>
						) : (
							<PieChart
								labels={priorities?.map((p) => p.name) ?? []}
								label={"Task by priorities"}
								info={priorities?.map((p) => p.count) ?? []}
								backgroundColors={
									priorities?.map((p) => p.color) ?? []
								}
							/>
						)}
					</Grid>
					<Grid item md={4} sm={12} xs={12} xl={5}>
						<Typography>Task priorities by Months</Typography>
						{prioritiesisLoading ? (
							<Skeleton
								style={{
									width: "100%",
									height: 200,
								}}
							/>
						) : (
							<Chart
								type="line"
								options={{
									chart: {
										id: "priorities-series",
									},
									xaxis: {
										categories:
											priorities.length > 0
												? priorities[0].series?.map(
														(s) => s.date
												  )
												: [],
									},
									stroke: {
										curve: "smooth",
										width: 1,
									},
								}}
								series={priorities?.map((p) => ({
									name: p.name,
									data: p?.series?.map((s) => s.count),
								}))}
							/>
						)}
					</Grid>
				</Grid>
				<Grid item container xs={12} direction="column">
					<Typography
						style={{
							fontSize: 20,
						}}
					>
						Tasks by states
					</Typography>
					<Grid container item xs={12} spacing={4}>
						<Grid
							item
							container
                            md={4}
                            sm={6}
                            xs={12}
                            xl={2}
							spacing={2}
							direction="column"
						>
							{statesisLoading ? (
								<Skeleton
									style={{
										width: "100%",
										height: 100,
									}}
								/>
							) : (
								states?.map((p) => (
									<Grid item xs={12}>
										<Card
											style={{
												height: 100,
											}}
										>
											<CardContent
												className={classes.card}
											>
												<Typography
													variant="h5"
													style={{
														color: p.color,
													}}
												>
													{p.name}:
												</Typography>
												<Typography
													style={{
														display: "inline",
													}}
													variant="h3"
												>
													{p?.amount?.toLocaleString(
														"en-US",
														{
															style: "currency",
															currency: "USD",
														}
													)}
												</Typography>
												<Typography
													className={
														classes.ticketCount
													}
												>
													{p.count} task(s)
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							)}
						</Grid>
						<Grid item md={6} sm={6} xs={12} xl={6}>
							{statesisLoading ? (
								<Skeleton
									style={{
										width: "100%",
										height: 100,
									}}
								/>
							) : (
								<PieChart
									labels={states?.map((p) => p.name) ?? []}
									label={"Tickets by priorities"}
									info={states?.map((p) => p.count) ?? []}
									backgroundColors={
										states?.map((p) => p.color) ?? []
									}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={4}>
					<Grid container item sm={6} xs={12} spacing={4} direction="column">
						<Typography
							style={{
								fontSize: 20,
							}}
						>
							Amount of task by duration in days
                        </Typography>
                        { durationisLoading ? (
                            <Skeleton
                                style={{
                                    width: "100%",
                                    height: 100,
                                }}
                            />
                        ) :
                            <Chart
                                type="bar"
                                options={{
                                    chart: {
                                        id: "task-duration",
                                    },
                                    xaxis: {
                                        categories:
                                            duration.length > 0
                                                ? duration?.map((s) => s.label)
                                                : [],
                                    },
                                    stroke: {
                                        curve: "smooth",
                                        width: 1,
                                    },
                                }}
                                series={[
                                    {
                                        name: "Tasks",
                                        data: duration?.map((p) => p.value),
                                    },
                                ]}
                            />
                        }
					</Grid>
                    <Grid container item sm={6} xs={12} spacing={4} direction="column">
						<Typography
							style={{
								fontSize: 20,
							}}
						>
							User with most task
						</Typography>
						{ usersisLoading ? (
							<Skeleton
								style={{
									width: "100%",
									height: 100,
								}}
							/>
						) : (
							<Chart
								type="bar"
								options={{
									chart: {
										id: "task-duration",
									},
									xaxis: {
										categories:
											users.length > 0
												? users?.map(
														(s) => s.dropdown_name
												  )
												: [],
									},
									plotOptions: {
										bar: {
											horizontal: true,
											borderRadius: 4,
										},
									},
								}}
								series={[
									{
										name: "Tasks",
										data: users?.map((p) => p.count),
									},
								]}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default TaskDashboad;
