import React, { useState } from "react";
import { TwitterPicker } from "react-color";

export default function MyColorPicker(props) {
  const { onChange, defaultColor, ...other } = props;
  const [color, setColor] = useState(defaultColor);
  return (
    <TwitterPicker  
      color={color}
      onChange={(color, event) => {
        setColor(color.hex);
        onChange(color.hex);
      }}
      {...other}
    />
  );
}
