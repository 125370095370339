import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DocumentForm from "./forms/DocumentForm";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import Controls from "./controls";
import {
  Paper,
  Fab,
  TextField,
  MenuItem,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  Tooltip,
  CardHeader,
  CardContent,
  Button,
  Chip,
} from "@material-ui/core";
import Popup from "./Popup";
import {
  fetchUserFavoriteSites,
  updateUserFavorites,
} from "../redux/slices/userFavoriteSites.js";
import Iconify from "./Iconify.js";
import { filter, isEmpty } from "lodash";
import { sidebarRoutes } from "../routes/index.js";
import CRMUtils from "../utils/index.js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import Api from "../lib/api.js";
import ScrollBar from "react-perfect-scrollbar";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { fontSize } from "@material-ui/system";
const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  detail: {
    margin: "5px 2px",
    padding: "4px 8px",
    borderLeft: "2px solid #b4b6dc",
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    background: "whitesmoke",
  },
  detailTitle: {
    fontWeight: 500,
    marginRight: 5,
  },
  cardTitle: {
    textAlign: "center",
    padding: 6,
    fontSize: 15,
  },
  approveButton: {
    backgroundColor: "#bef4c1",
    color: "#1aa12e",
    padding: "6px 16px",
    "&:hover": {
      backgroundColor: "#a2d9a5",
      color: "#00640f",
    },
  },
  rejectButton: {
    backgroundColor: "#fed3cf",
    color: "#a11a1a",
    marginLeft: 5,
    padding: "6px 16px",
    "&:hover": {
      backgroundColor: "#e6afaa",
      color: "#780e0e",
    },
  },
}));

function PaymentLinkRequest(props) {
  const {
    paymentLinkRequest = null,
    onSuccessfulSubmit = null,
    setNotify = null,
    onClose,
  } = props;
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const statuses = [
    {
      id: "pending",
      bcColor: "rgb(247 255 183)",
      color: "rgb(125 127 0)",
      label: "PENDING",
    },
    {
      id: "rejected",
      bcColor: "#ffe3d8",
      color: "#b44a20",
      label: "REJECTED",
      icon: "line-md:cancel",
    },
    {
      id: "approved",
      bcColor: "#ddffdf",
      color: "#069e22",
      label: "APPROVED",
      icon: "iconamoon:check-bold",
    },
  ];

  const closeRequest = async (status = null) => {
    try {
      setIsSubmitting(true);
      const { data } = await Api.closePaymentLinkRequest(
        { status: status },
        paymentLinkRequest.id
      );

      if (typeof onSuccessfulSubmit == "function") {
        onSuccessfulSubmit({ ...data.data });
      }

      setIsSubmitting(false);
    } catch (error) {
      console.log("error: ", error);
      if (setNotify) {
        if (error.status == 409 && (error?.data?.error || false)) {
          setNotify({
            isOpen: true,
            message: error.data.error,
            type: "error",
          });
        } else if (error.status == 401) {
          setNotify({
            isOpen: true,
            message: "Forbidden",
            type: "error",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Something went wrong",
            type: "error",
          });
        }
      }
    } finally {
      if (typeof onClose == "function") {
        onClose();
      }
    }
  };

  return (
    <div>
      <Card style={{ padding: 12 }}>
        <Typography variant="h6" className={classes.cardTitle}>
          {" "}
          {"Payment Link Request"}{" "}
        </Typography>
        <div>
          {" "}
          <div className={classes.detail}>
            <Typography className={classes.detailTitle}>
              Requested At:
            </Typography>
            <Typography>{paymentLinkRequest?.requested_at || ""}</Typography>
          </div>
          {paymentLinkRequest &&
          paymentLinkRequest.requestedBy &&
          paymentLinkRequest.requestedBy.data ? (
            <div className={classes.detail}>
              <Typography className={classes.detailTitle}>
                Requested By:
              </Typography>
              <Typography>
                {paymentLinkRequest.requestedBy.data.dropdown_name}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div className={classes.detail}>
            <Typography className={classes.detailTitle}>
              Card Number:
            </Typography>
            <Typography>{paymentLinkRequest?.card_number || ""}</Typography>
          </div>
          {paymentLinkRequest && paymentLinkRequest.max_amount !== null ? (
            <div className={classes.detail}>
              <Typography className={classes.detailTitle}>
                Max Amount:
              </Typography>
              <Typography>{"$" + paymentLinkRequest.max_amount}</Typography>
            </div>
          ) : (
            ""
          )}
          <div className={classes.detail}>
            <Typography className={classes.detailTitle}>
              Credit Card Authorization:
            </Typography>
            <a
              href={paymentLinkRequest?.media_url || ""}
              target="_blank"
              rel="noreferrer"
            >
              <AttachFileIcon style={{ marginTop: 5 }} />
            </a>
          </div>
          <div className={classes.detail}>
            <Typography className={classes.detailTitle}> Contact:</Typography>
            <Typography>
              {paymentLinkRequest?.contact?.data?.title || ""}
            </Typography>
          </div>
          <div className={classes.detail}>
            <Typography className={classes.detailTitle}> Invoice:</Typography>
            <Typography>
              {paymentLinkRequest?.invoice?.data?.name || ""}
            </Typography>
          </div>
        </div>

        {paymentLinkRequest && paymentLinkRequest.status != "pending" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "5px 2px",
            }}
          >
            <Chip
              icon={
                <Iconify
                  icon={
                    statuses.find((s) => s.id == paymentLinkRequest.status)
                      ?.icon || ""
                  }
                  style={{
                    fontSize: 18,
                    marginLeft: 10,
                    color:
                      statuses.find((s) => s.id == paymentLinkRequest.status)
                        ?.color || "#282828",
                  }}
                />
              }
              label={
                statuses.find((s) => s.id == paymentLinkRequest.status)
                  ?.label || paymentLinkRequest.status.toUpperCase()
              }
              style={{
                color:
                  statuses.find((s) => s.id == paymentLinkRequest.status)
                    ?.color || "#282828",
                backgroundColor:
                  statuses.find((s) => s.id == paymentLinkRequest.status)
                    ?.bcColor || "#eff0ef",
                fontWeight: 500,
              }}
            />
          </div>
        ) : (
          ""
        )}
      </Card>

      <div style={{ justifyContent: "center", display: "flex", marginTop: 12 }}>
        {paymentLinkRequest && paymentLinkRequest.status == "pending" ? (
          isSubmitting ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Iconify
                style={{ color: "#3378b5", fontSize: 18, marginRight: 8 }}
                icon="eos-icons:bubble-loading"
              />
              <Typography style={{ fontWeight: 500, color: "gray" }}>
                Processing
              </Typography>
            </div>
          ) : (
            <>
              {" "}
              <Button
                onClick={() => {
                  closeRequest("approved");
                }}
                className={classes.approveButton}
                startIcon={<Iconify icon="iconamoon:check-bold" />}
              >
                Approve
              </Button>
              <Button
                onClick={() => {
                  closeRequest("rejected");
                }}
                className={classes.rejectButton}
                startIcon={<Iconify icon="line-md:cancel" />}
              >
                Reject
              </Button>
            </>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PaymentLinkRequest;
