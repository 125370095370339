import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Iconify from "../Iconify";
import UserToUserHeaders from "./UserToUserHeaders";
import UserToGroupHeaders from "./UserToGroupHeaders";
import ChatGroupHeaders from "./ChatGroupHeaders";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  tabIcon: {
    width: "21px",
    height: "21px",
    marginRight: "3px",
  },
  tabTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  panelTitle: {
    fontWeight: 500,
    fontSize: 16,
    color: "#3f3f3f",
    marginLeft: 10,
  },
  searchIcon: {
    color: "rgb(105 118 135)",
    width: 21,
    height: 21,
  },
  unreadMessagesAvatar: {
    minWidth: "18px",
    maxWidth: "fit-content",
    padding: "0px 5px",
    height: 18,
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 5,
    background: "rgb(58 82 147)",
  },
}));

function TabPanel(props) {
  const { children, value, index, st = null, ...other } = props;

  return (
    <div
      style={st != null ? st : { width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const HeadersSidebar = (props) => {
  const {
    customHeight,
    newHeader,
    setNewHeader,
    selectedTab,
    setSelectedTab,
    setSelectedHeader,
    selectedHeader,
    headers,
    setHeaders,
    getMessagesNeeded,
    setGetMessagesNeeded,
    setSelectedMessage,
  } = props;
  const { totalUnreadChatMessages } = useSelector(
    (state) => state.totalUnreadChatMessages
  );
  const classes = useStyles();

  const getTabTotalUnread = (modelType) => {
    var obj =
      totalUnreadChatMessages &&
      totalUnreadChatMessages.find((o) => o.model_type == modelType);
    if (obj && obj.total) {
      return obj.total;
    } else {
      return 0;
    }
  };

  return (
    <div style={{ minHeight: "100%" }}>
      <AppBar
        style={{ boxShadow: "none", background: "rgb(251, 251, 251)" }}
        position="static"
      >
        <Tabs
          value={selectedTab}
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
          TabIndicatorProps={{ style: { display: "none" } }}
          indicatorColor="primary"
          style={{ color: "#495c7e" }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{ minWidth: "fit-content", padding: "6px 5px" }}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 8px",
                  justifyContent: "center",
                  background: selectedTab == 0 ? "rgb(198 202 219 / 37%)" : "",
                  borderRadius: 16,
                }}
              >
                <Iconify className={classes.tabIcon} icon="gridicons:user" />
                <Typography className={classes.tabTitle}>Users</Typography>
                {getTabTotalUnread("App\\Models\\User") > 0 ? (
                  <Avatar className={classes.unreadMessagesAvatar}>
                    {getTabTotalUnread("App\\Models\\User") > 99
                      ? "+99"
                      : getTabTotalUnread("App\\Models\\User")}
                  </Avatar>
                ) : (
                  ""
                )}
              </div>
            }
          />

          <Tab
            style={{ minWidth: "fit-content", padding: "6px 2px" }}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 8px",
                  justifyContent: "center",
                  background: selectedTab == 1 ? "rgb(198 202 219 / 37%)" : "",
                  borderRadius: 16,
                }}
              >
                <Iconify
                  className={classes.tabIcon}
                  icon="fluent:building-24-filled"
                />
                <Typography className={classes.tabTitle}>
                  Company Groups
                </Typography>
                {getTabTotalUnread("App\\Models\\Group") > 0 ? (
                  <Avatar className={classes.unreadMessagesAvatar}>
                    {getTabTotalUnread("App\\Models\\Group") > 99
                      ? "+99"
                      : getTabTotalUnread("App\\Models\\Group")}
                  </Avatar>
                ) : (
                  ""
                )}
              </div>
            }
          />

          <Tab
            style={{ minWidth: "fit-content", padding: "6px 2px" }}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 8px",
                  justifyContent: "center",
                  background: selectedTab == 2 ? "rgb(198 202 219 / 37%)" : "",
                  borderRadius: 16,
                }}
              >
                <Iconify
                  className={classes.tabIcon}
                  icon="heroicons:user-group-20-solid"
                />
                <Typography className={classes.tabTitle}>Groups</Typography>
                {getTabTotalUnread("App\\Models\\ChatGroup") > 0 ? (
                  <Avatar className={classes.unreadMessagesAvatar}>
                    {getTabTotalUnread("App\\Models\\ChatGroup") > 99
                      ? "+99"
                      : getTabTotalUnread("App\\Models\\ChatGroup")}
                  </Avatar>
                ) : (
                  ""
                )}
              </div>
            }
          />
        </Tabs>
      </AppBar>

      <TabPanel value={selectedTab} index={0}>
        <UserToUserHeaders
          customHeight={customHeight}
          setSelectedMessage={setSelectedMessage}
          getMessagesNeeded={getMessagesNeeded}
          setGetMessagesNeeded={setGetMessagesNeeded}
          headers={headers}
          setHeaders={setHeaders}
          selectedHeader={selectedHeader}
          setSelectedHeader={setSelectedHeader}
        />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <UserToGroupHeaders
          customHeight={customHeight}
          setSelectedMessage={setSelectedMessage}
          getMessagesNeeded={getMessagesNeeded}
          setGetMessagesNeeded={setGetMessagesNeeded}
          headers={headers}
          setHeaders={setHeaders}
          selectedHeader={selectedHeader}
          setSelectedHeader={setSelectedHeader}
        />
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <ChatGroupHeaders
          customHeight={customHeight}
          setSelectedMessage={setSelectedMessage}
          getMessagesNeeded={getMessagesNeeded}
          setGetMessagesNeeded={setGetMessagesNeeded}
          headers={headers}
          setHeaders={setHeaders}
          selectedHeader={selectedHeader}
          setSelectedHeader={setSelectedHeader}
        />
      </TabPanel>
    </div>
  );
};

export default HeadersSidebar;
