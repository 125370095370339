import PropTypes from 'prop-types';
// @mui
// import { alpha } from '@mui/material/styles';
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Box, Paper, Typography } from '@material-ui/core';
// utils
import { fData } from "../../dashboard/formatNumber";
import getFileData from './getFileData';

// ----------------------------------------------------------------------

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array.isRequired,
};

export default function RejectionFiles({ fileRejections }) {
  return (
    <Paper
      variant="outlined"
      style={{
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2,
        marginTop: 3,        
        borderColor: 'error.light',
        bgcolor: (theme) => fade(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = getFileData(file);

        return (
          <Box key={path} style={{  marginTop: 1, marginBottom: 1}}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => (
              <Box key={error.code} component="li" style={{ typography: 'caption' }}>
                {error.message}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}
