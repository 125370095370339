import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  cancellationMotives: [],
};

const cancellationMotivesSlice = createSlice({
  name: "cancellationMotives",
  initialState,
  reducers: {
    getCancellationMotives: (state) => {
      state.loading = true;
    },
    getCancellationMotivesSuccess: (state, { payload }) => {
      state.cancellationMotives = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCancellationMotivesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createCancellationMotive: (state) => {},
    createCancellationMotivesuccess: (state, { payload }) => {
      state.cancellationMotives = [...state.cancellationMotives, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createCancellationMotiveFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateCancellationMotive: (state) => {},
    updateCancellationMotivesuccess: (state, { payload }) => {
      const index = findIndex(state.cancellationMotives, [
        "id",
        payload.data.id,
      ]);
      const cancellationMotive = {
        ...state.cancellationMotives[index],
        ...payload.data,
      };

      let cancellationMotives = [...state.cancellationMotives];
      cancellationMotives[index] = cancellationMotive;

      state.cancellationMotives = [...cancellationMotives];
      state.loading = false;
      state.errors = [];
    },
    updateCancellationMotiveFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteCancellationMotive: (state) => {},
    deleteCancellationMotivesuccess: (state, { payload }) => {
      state.cancellationMotives = state.cancellationMotives.filter(
        (cancellationMotive) => {
          return cancellationMotive.id !== payload;
        }
      );
    },
    deleteCancellationMotiveFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getCancellationMotives,
  getCancellationMotivesSuccess,
  getCancellationMotivesFailure,
  createCancellationMotive,
  createCancellationMotivesuccess,
  createCancellationMotiveFailure,
  updateCancellationMotive,
  updateCancellationMotivesuccess,
  updateCancellationMotiveFailure,
  deleteCancellationMotive,
  deleteCancellationMotivesuccess,
  deleteCancellationMotiveFailure,
} = cancellationMotivesSlice.actions;

export const cancellationMotivesSelector = (state) => state.cancellationMotives;
export default cancellationMotivesSlice.reducer;

export function fetchCancellationMotivesService(orgId) {
  return async (dispatch) => {
    dispatch(getCancellationMotives());

    try {
      const response = await Api.getCancellationMotives(orgId);
      dispatch(getCancellationMotivesSuccess(response.data));
    } catch (error) {
      dispatch(getCancellationMotivesFailure(error));
    }
  };
}

export function createCancellationMotiveService(payload) {
  return async (dispatch) => {
    dispatch(createCancellationMotive());

    try {
      const response = await Api.saveCancellationMotive(payload);

      dispatch(createCancellationMotivesuccess(response.data));
    } catch (error) {
      dispatch(createCancellationMotiveFailure(error));
    }
  };
}

export function updateCancellationMotiveService(id, payload) {
  return async (dispatch) => {
    dispatch(updateCancellationMotive());

    try {
      const response = await Api.updateCancellationMotive(id, payload);

      dispatch(updateCancellationMotivesuccess(response.data));
    } catch (error) {
      dispatch(updateCancellationMotiveFailure(error));
    }
  };
}

export function deleteCancellationMotiveService(id) {
  return async (dispatch) => {
    dispatch(deleteCancellationMotive());

    try {
      await Api.deleteCancellationMotive(id);

      dispatch(deleteCancellationMotivesuccess(id));
    } catch (error) {
      dispatch(deleteCancellationMotiveFailure(error));
    }
  };
}
