import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  userFavoriteSites: [],
};

const userFavoriteSites = createSlice({
  name: "userFavoriteSites",
  initialState,
  reducers: {
    updateFavorites: (state, { payload }) => {
      state.userFavoriteSites = payload;
    },
    getFavoritesSuccess: (state, { payload }) => {
      state.userFavoriteSites = payload;
      state.loading = false;
      state.errors = [];
    },
  },
});

export const { getFavoritesSuccess, updateFavorites } =
  userFavoriteSites.actions;

export const templatesSelector = (state) => state.userFavoriteSites;

export default userFavoriteSites.reducer;

export function fetchUserFavoriteSites(user_id) {
  return async (dispatch) => {
    try {
      const { data } = await Api.getUserFavoriteSites(user_id);

      dispatch(getFavoritesSuccess([...data]));
    } catch (error) {}
  };
}

export function updateUserFavorites(payload) {
  return async (dispatch) => {
    dispatch(updateFavorites(payload));
  };
}
