import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useForm, Controller, watch } from "react-hook-form";
import Controls from "../../components/controls";
import { head, isEmpty } from "lodash";
import Api from "../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntitiesService as fetchBuildingsDropdownService } from "../../redux/slices/buildingsDropdownSlice.js";
import moment from "moment/moment";
import Iconify from "../Iconify";
import LinkIcon from "@material-ui/icons/Link";
import InvoicesToMultipaymentsForm from "./InvoicesToMultipaymentsForm";
import CRMUtils from "../../utils";
import { Tip } from "../controls/Tip";
import ScrollBar from "react-perfect-scrollbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { VerifiedUserRounded } from "@material-ui/icons";
import Popup from "../Popup.js";
import BindOnlineTransactionButton from "../BindOnlineTransactionButton.js";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: "90px",
    height: "90px",
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
  totalAmount: {
    textAlign: "right",
    fontWeight: 600,
    fontSize: 16,
    margin: 6,
    paddingTop: 10,
    paddingBottom: 15,
    borderTop: "1px solid #80808042",
    color: "#2a3643",
  },
}));

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function HeaderTitle(props) {
  const { title, icon, divider = true, secondaryEL } = props;

  return (
    <div style={{ marginBottom: 5, marginTop: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify
            style={{
              marginRight: "5px",
              color: "rgb(92 167 143)",
              width: 22,
              height: 22,
            }}
            icon={icon}
          />
          <Typography
            style={{ fontSize: 16, fontWeight: 500, color: "#4d4d4d" }}
          >
            {title}
          </Typography>
        </div>
        {/* Render secondaryEL if it exists */}
        {secondaryEL && <div>{secondaryEL}</div>}
      </div>

      {divider ? <Divider style={{ margin: "7px", marginBottom: 15 }} /> : ""}
    </div>
  );
}

export default function MultipaymentForm(props) {
  const {
    addOrEdit,
    extraForEdit,
    type,
    headerForEdit,
    contact_id,
    onClose = null,
  } = props;
  const dispatch = useDispatch();
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const watchAllFields = watch();
  const [gateways, setGateways] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const { buildingsDropdown, buildingsDropdownLoading } = useSelector(
    (state) => state.buildingsDropdown
  );
  const [minValidations, setMinValidations] = useState({});
  const [lockDate, setLockDate] = useState(false);
  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);
  const userCanBindOnlineTransaction = CRMUtils.userHasPermission(authUser, [
    "merchants_bindonlinetransaction",
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openBindTransactionPopup, setOpenBindTransactionPopup] =
    useState(false);

  const [merchantIdVerification, setMerchantIdVerification] = useState({
    billingInformation: { cardholder: null, last_4: null },
    date: null,
    transactionId: null,
    isVerified: null,
    isLoading: false,
    message: null,
  });

  const classes = useStyles();

  const isSubmitDisable = () => {
    /*
      If the cation.transactionId is setted and it is not an empty string
      and the merchantIdVerification is not verified,
      disable the submit button
    */
    if (
      merchantIdVerification.transactionId &&
      merchantIdVerification.transactionId != "" &&
      merchantIdVerification.isVerified === false
    ) {
      return true;
    }

    var result = false;
    Object.keys(minValidations).forEach((key) => {
      if (minValidations[key] == true) {
        result = true;
      }
    });
    return (
      isSubmitting ||
      !watchAllFields["invoices"] ||
      (watchAllFields["invoices"] &&
        watchAllFields["invoices"].reduce(
          (total, item) => total + parseFloat(item.amount),
          0
        ) == 0) ||
      result
    );
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (watchAllFields["invoices"]) {
      data["total_amount"] = watchAllFields["invoices"].reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
    }

    data["invoices"] = JSON.stringify(
      data["invoices"].filter((a) => a.amount != 0)
    );

    let formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    if (data.file === null) {
      formData.delete("file");
    }

    /*
      If the userCanBindOnlineTransaction and the merchantIdVerification is verified,
      add the validation data to the formData
    */
    if (userCanBindOnlineTransaction && merchantIdVerification.isVerified) {
      let merchantTransactionIdVerification = {
        transaction_id: merchantIdVerification.transactionId,
        amount: data["total_amount"],
        is_refund: false,
      };

      formData.append(
        "merchant_transaction_id_verification",
        JSON.stringify(merchantTransactionIdVerification)
      );
    }

    await addOrEdit(formData);
    setIsSubmitting(false);
  };

  const getGateways = async () => {
    const params = new URLSearchParams([["contact_id", contact_id]]);

    const { data } = await Api.getPaymentGateways(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );

    setGateways([...data.data]);
  };

  const paymentOptions = () => {
    const options = gateways.map((gateway) => ({
      id: gateway.name,
      title: gateway.name,
      selected:
        headerForEdit &&
        gateway.name.toLowerCase() === headerForEdit.payment_type.toLowerCase(),
    }));

    if (headerForEdit && !options.some((option) => option.selected)) {
      options.push({
        id: headerForEdit?.payment_type,
        title: headerForEdit?.payment_type,
        selected: true,
      });
    }
    return options;
  };

  const buildingOptions = () => {
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  /**
   * Verify an online transaction by its ID.
   * This function sends a request to the backend to verify an online transaction based on the provided data.
   */
  const verifyOnlineTransactionById = async () => {
    // Set loading state to indicate the verification process has started
    setMerchantIdVerification((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      // Make an API call to verify the transaction
      const { data } = await Api.verifyOnlineTransactionById({
        // Construct payload with necessary data
        building_id: watchAllFields["building_id"],
        merchant_transaction_id: merchantIdVerification.transactionId,
        is_refund: false,
        amount: watchAllFields["invoices"].reduce(
          // Calculate total amount from invoices
          (total, item) => total + parseFloat(item.amount ? item.amount : 0),
          0
        ),
      });

      // Update state with verification result and message
      setMerchantIdVerification((prevState) => ({
        ...prevState,
        isVerified: !!data.data.result,
        billingInformation: data.data.billing_information ?? {
          cardholder: null,
          last_4: null,
        },
        payment_date: data?.data?.date["0"] || null,
        message: data.message,
        isLoading: false,
      }));
    } catch (error) {
      // Handle error if API call fails
      console.log("Error: ", error);
      setMerchantIdVerification((prevState) => ({
        ...prevState,
        isVerified: false,
        message: error.data.message,
        isLoading: false,
        payment_date: null,
        billingInformation: {
          cardholder: null,
          last_4: null,
        },
      }));
    }
  };

  /**
   * Render the UI for binding a transaction.
   * This component includes input fields for merchant transaction ID, a verification button,
   * and displays status messages based on verification status.
   */
  const renderBindTransactionMenu = () => {
    const {
      transactionId,
      isVerified,
      isLoading,
      message,
      billingInformation,
    } = merchantIdVerification;

    const handleTransactionIdChange = (event) => {
      const { value } = event.target;
      setMerchantIdVerification((prevState) => ({
        ...prevState,
        transactionId: value,
        billingInformation: {
          cardholder: null,
          last_4: null,
        },
        payment_date: null,
      }));
    };

    const handleVerifyButtonClick = () => {
      verifyOnlineTransactionById();
    };

    return (
      <Grid
        item
        xs={12}
        style={{ padding: 20, display: "flex", justifyContent: "center" }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              value={transactionId}
              label="Merchant Transaction ID"
              type="number"
              inputProps={{ min: 0 }}
              onChange={handleTransactionIdChange}
            />
            {isVerified ? (
              <Chip
                icon={<VerifiedUserRounded />}
                label="Verified"
                color="primary"
                style={{ margin: 10 }}
              />
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerifyButtonClick}
                  disabled={!transactionId || isVerified || isLoading}
                  style={{ marginLeft: 20, height: 30, marginTop: 10 }}
                >
                  Verify
                </Button>
                {isLoading && (
                  <LinearProgress
                    style={{ width: "100%", marginLeft: 10, marginTop: 5 }}
                  />
                )}
              </div>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <Alert
              severity={isVerified ? "success" : message ? "error" : "info"}
            >
              <AlertTitle>Verify transaction</AlertTitle>
              {isVerified
                ? `You are updating the "MERCHANT TRANSACTION ID" field of this transaction. This action will bind this transaction to the selected transaction on the merchant's platform and consider the transaction as "online" on the CRM records.`
                : message ||
                  `Before you can update the "MERCHANT TRANSACTION ID" field, you must verify it.`}
            </Alert>
          </div>
        </div>
      </Grid>
    );
  };

  const getBuildingId = () => watchAllFields["building_id"];
  const getTotalAmount = () =>
    watchAllFields["invoices"].reduce(
      (total, item) => total + parseFloat(item.amount ? item.amount : 0),
      0
    );

  /**
   * Effect to handle changes in the merchant transaction ID field.
   * Resets verification state when the field is empty, and sets isVerified to false when a value is present.
   */
  useEffect(() => {
    // Check if user can bind online transactions
    if (userCanBindOnlineTransaction) {
      // Check if the merchant transaction ID is empty
      if (
        !merchantIdVerification.transactionId ||
        merchantIdVerification.transactionId == ""
      ) {
        // Reset verification state if the field is empty
        setMerchantIdVerification((prevState) => ({
          isVerified: null,
          isLoading: false,
          message: null,
          billingInformation: {
            cardholder: null,
            last_4: null,
          },
          payment_date: null,
        }));
      } else if (merchantIdVerification.transactionId) {
        // Set isVerified to false when a value is present in the field
        setMerchantIdVerification((prevState) => ({
          ...prevState,
          isVerified: false,
          isLoading: false,
          message: null,
          billingInformation: {
            cardholder: null,
            last_4: null,
          },
          payment_date: null,
        }));
      }
    }
  }, [merchantIdVerification.transactionId]);

  useEffect(() => {
    if (merchantIdVerification.payment_date) {
      setValue(
        "payment_date",
        moment(merchantIdVerification.payment_date, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }, [merchantIdVerification.payment_date]);

  useEffect(() => {
    if (isEmpty(buildingsDropdown) && !buildingsDropdownLoading) {
      dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
    }

    getGateways();
  }, []);

  useEffect(() => {
    if (watchAllFields.building_id && !userCanEditPaymentDateLock) {
      const building = buildingsDropdown.find((building) => {
        return building.id === watchAllFields.building_id;
      });
      if (building && building.lock_payment_date) {
        setValue("payment_date", moment().format("YYYY-MM-DD HH:mm:ss"));

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  }, [watchAllFields.building_id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ScrollBar style={{ maxHeight: 640 }}>
        <>
          {" "}
          <Tip title={`Notice: `} message={`* Indicates a required field`} />
          <div
            style={{
              padding: "16px 16px",
              boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 5,
                borderBottom: "1px solid #8080803b",
                marginBottom: "14px",
              }}
            >
              <HeaderTitle
                icon="ion:wallet"
                title="Payment details"
                divider={false}
              />

              <Controller
                name={"file"}
                control={control}
                defaultValue={null}
                as={({ onChange, value }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {watchAllFields["file"] ||
                    (headerForEdit &&
                      headerForEdit.file &&
                      headerForEdit.file.path &&
                      headerForEdit.file.name) ? (
                      <a
                        style={{ marginLeft: 8 }}
                        target="_blank"
                        href={
                          watchAllFields["file"]
                            ? window.URL.createObjectURL(watchAllFields["file"])
                            : headerForEdit.file.path
                        }
                      >
                        {watchAllFields["file"]
                          ? watchAllFields["file"]["name"]
                          : headerForEdit.file.name}{" "}
                      </a>
                    ) : (
                      ""
                    )}
                    {errors.file && (
                      <p className={classes.error}>{errors.file.message}</p>
                    )}
                    <Controls.DropzoneDialog
                      startIcon={<Iconify icon="basil:upload-solid" />}
                      label="Upload Receipt*"
                      st={{
                        borderRadius: 22,
                        padding: "6px 15px",
                        marginLeft: 25,
                        backgroundColor: "rgb(229 240 249 / 55%)",
                      }}
                      variant="outlined"
                      onChange={(files) => {
                        onChange(files[0]);
                      }}
                      value={value}
                    />
                  </div>
                )}
                rules={
                  headerForEdit && headerForEdit.file && headerForEdit.file.path
                    ? undefined
                    : {
                        required: "*Receipt is required",
                      }
                }
              />
            </div>

            <Grid container spacing={2}>
              {/* If merchant_transaction_id has a value, then hide the payment type since it will be considered 'online'
              and the payment type will be populated on the backend */}
              {!merchantIdVerification.isVerified &&
                !merchantIdVerification.transactionId && (
                  <Grid item xs={4}>
                    <Controller
                      name={"payment_type"}
                      control={control}
                      defaultValue={headerForEdit?.payment_type || null}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          label="Payment Type*"
                          options={paymentOptions()}
                          onChange={(event) => onChange(event.target.value)}
                          value={value}
                        />
                      )}
                      rules={{
                        required: "*Payment Type is required",
                      }}
                    />
                    {errors.payment_type && (
                      <p className={classes.error}>
                        {errors.payment_type.message}
                      </p>
                    )}
                  </Grid>
                )}

              <Grid item xs={4}>
                <Controller
                  name="payment_date"
                  type="date"
                  control={control}
                  defaultValue={
                    headerForEdit
                      ? moment(headerForEdit.payment_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : moment().format("YYYY-MM-DD HH:mm:ss")
                  }
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      label="Date*"
                      fullWidth
                      shouldCloseOnSelect
                      defaultValue={
                        headerForEdit
                          ? moment(headerForEdit.payment_date).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : moment().format("YYYY-MM-DD HH:mm:ss")
                      }
                      onChange={(date) => onChange(moment(date).format("YYYY-MM-DD HH:mm:ss"))}
                      value={value}
                      disabled={
                        !userCanEditPayment ||
                        lockDate ||
                        merchantIdVerification.payment_date
                      }
                    />
                  )}
                  rules={{
                    required: "*Date is required",
                  }}
                />
                {errors.payment_date && (
                  <p className={classes.error}>{errors.payment_date.message}</p>
                )}
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="building_id"
                  control={control}
                  defaultValue={
                    headerForEdit?.building_id || authUser.building_id || ""
                  }
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label="Building*"
                      options={buildingOptions()}
                      onChange={(value) => {
                        onChange(value);
                        setMerchantIdVerification((prevState) => ({
                          ...prevState,
                          isVerified: false,
                          message: null,
                          isLoading: false,
                        }));
                      }}
                      value={value}
                      disabled={!userCanChangeBuilding}
                    />
                  )}
                  rules={{
                    required: "*Building is required",
                  }}
                />
                {errors.building_id && (
                  <h4 className={classes.errors}>
                    {errors.building_id.message}
                  </h4>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="details"
                  control={control}
                  defaultValue={headerForEdit?.details || ""}
                  as={
                    <Controls.Textarea
                      rows={4}
                      style={{ width: "100%" }}
                      label="Details"
                    />
                  }
                />
                {errors.building_id && (
                  <h4 className={classes.errors}>
                    {errors.building_id.message}
                  </h4>
                )}
              </Grid>
            </Grid>
            <br />
            {/* Render the transaction details section if either merchant_transaction_id is present or payment_type requires card details */}
            {merchantIdVerification.transactionId ||
            ((!headerForEdit ||
              (headerForEdit && !headerForEdit.merchant_transaction_id)) &&
              userCanBindOnlineTransaction) ||
            (watchAllFields["payment_type"] &&
              (gateways.find((a) => a.name == watchAllFields["payment_type"])
                ?.is_card_required ||
                false)) ? (
              <>
                {" "}
                {/*
                  Render the  title for transaction details
                */}
                <HeaderTitle
                  icon="solar:card-bold"
                  title="Transaction details"
                  secondaryEL={
                    userCanBindOnlineTransaction &&
                    !headerForEdit?.merchant_transaction_id ? (
                      <BindOnlineTransactionButton
                        openBindTransactionPopup={openBindTransactionPopup}
                        setOpenBindTransactionPopup={
                          setOpenBindTransactionPopup
                        }
                        setMerchantIdVerification={setMerchantIdVerification}
                        merchantIdVerification={merchantIdVerification}
                        getBuildingId={getBuildingId}
                        getTotalAmount={getTotalAmount}
                        isRefund={false}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
                {/*
                  Render card details inputs if payment_type requires card details
                */}
                {(merchantIdVerification.isVerified ||
                  (watchAllFields["payment_type"] &&
                    (gateways.find(
                      (a) => a.name == watchAllFields["payment_type"]
                    )?.is_card_required ||
                      false))) && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        name="cardholder"
                        control={control}
                        defaultValue={
                          merchantIdVerification?.billingInformation
                            .cardholder ??
                          (headerForEdit?.cardholder || "")
                        }
                        as={
                          <Controls.Input
                            fullWidth
                            label="Card holder"
                            disabled={
                              merchantIdVerification?.billingInformation
                                .cardholder
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="cc_last_4"
                        control={control}
                        defaultValue={
                          merchantIdVerification?.billingInformation.last_4 ??
                          (headerForEdit?.cc_last_4 || "")
                        }
                        as={
                          <Controls.Input
                            fullWidth
                            label="Last 4 CC"
                            disabled={
                              merchantIdVerification?.billingInformation.last_4
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="transaction_id"
                        control={control}
                        defaultValue={headerForEdit?.transaction_id || ""}
                        as={<Controls.Input fullWidth label="Transaction id" />}
                      />
                    </Grid>
                  </Grid>
                )}
                <br />
              </>
            ) : (
              ""
            )}
            <Controller
              name={"invoices"}
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <InvoicesToMultipaymentsForm
                  setMinValidations={setMinValidations}
                  minValidations={minValidations}
                  headerForEdit={headerForEdit}
                  contact_id={contact_id}
                  onChange={(value) => {
                    onChange(value);
                    setMerchantIdVerification((prevState) => ({
                      ...prevState,
                      isVerified: false,
                      message: null,
                      isLoading: false,
                    }));
                  }}
                  value={value}
                />
              )}
            />
          </div>
        </>
      </ScrollBar>
      <Typography className={classes.totalAmount}>{`Total Amount: $${
        watchAllFields && watchAllFields["invoices"]
          ? watchAllFields["invoices"].reduce(
              (total, item) =>
                total + parseFloat(item.amount ? item.amount : 0),
              0
            )
          : "0.00"
      }`}</Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: 8,
        }}
      >
        <Controls.Button
          onClick={() => {
            if (typeof onClose == "function") {
              onClose();
            }
          }}
          disabled={isSubmitting}
          variant="contained"
          color="inherit"
          text={"Cancel"}
        />
        <Controls.Button
          disabled={isSubmitDisable()}
          type="submit"
          variant="contained"
          color="primary"
          text={isSubmitting ? "Submitting..." : "Submit"}
        />
      </div>
    </form>
  );
}
