// @mui
import { Box, Typography, Grid } from "@material-ui/core";

import Iconify from "../../Iconify";

// ----------------------------------------------------------------------

export default function BlockContent({hasFiles, iconSelect=null}) {

  return (
    <Grid
      spacing={2}
      style={{
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >

      <Grid xs={12}>
        <Box
          width={"150px"}
          height={"150px"}
          margin={"auto"}
          borderRadius={"50%"}      
          style={{padding: 5}}
          border={"1px dashed rgba(145, 158, 171, 0.32)"}
        >
           
          <Box
            className="placeholder"
            style={{
              Zindex: 0,
              width: "100%",
              height: "100%",
              outline: "none",
              overflow: "hidden",
              flexDirection: "column",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              color: "#637381",
              backgroundColor: "#F4F6F8",
              "&:hover": { opacity: 0.72 },
              display: "flex",
            }}
          >
        
            <Iconify
              icon={iconSelect || "ic:round-add-a-photo"}
              style={{ width: 28, height: 28, marginBottom: 2 }}
            />
            <Typography variant="body1">{hasFiles ? "Upload More Documents": "Upload Documents"}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box style={{ padding: 1 }}>
          <Typography gutterBottom variant="h5">
            Drop or Select file
          </Typography>

          <Typography variant="body2" style={{ color: "text.secondary" }}>
            Drop files here or click&nbsp;
            <Typography
              variant="body2"
              component="span"
              style={{ color: "primary.main", textDecoration: "underline" }}
            >
              browse
            </Typography>
            &nbsp;thorough your machine
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
