import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../lib/api";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ChangeProcedureBuilding(props) {
  const { changeBuilding, selectedRecordProducts } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { usersDropdown } = useSelector((state) => state.users);
  const { authUser } = useSelector((state) => state.auth);
  const [buildings, setBuildings] = useState([]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await changeBuilding(data);
    setIsSubmitting(false);
  };

  const getBuildingOptions2 = () => {
    if (!buildings) return [];
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  useEffect(() => {
    if (
      selectedRecordProducts &&
      selectedRecordProducts.find((a) => a.responsible_id)
    ) {
      Api.getUserBuildings(
        selectedRecordProducts.find((a) => a.responsible_id).responsible_id
      ).then((result) => {
        setBuildings(result.data.data);
      });
    } else {
      Api.getBuildings(authUser.organization_id).then((result) => {
        setBuildings(result.data.data);
      });
    }
  }, [selectedRecordProducts]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="building_id"
            control={control}
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.Select
                fullWidth
                styleProp
                label="Building"
                options={getBuildingOptions2()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
          />

          <div>
            <Controls.Button
              disabled={isSubmitting}
              type="submit"
              text={isSubmitting ? "Submitting..." : "Submit"}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
