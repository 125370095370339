import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  cordinationReports: [],
};

const cordinationReportsSlice = createSlice({
  name: "cordinationReports",
  initialState,
  reducers: {
    getcordinationReports: (state) => {
      state.loading = true;
    },
    getcordinationReportsSuccess: (state, { payload }) => {
      state.cordinationReports = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getcordinationReportsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateCordinationReports: (state) => {},
    updateCordinationReportsSuccess: (state, { payload }) => {
      const index = findIndex(state.cordinationReports, ["id", payload.id]);
      const record = { ...state.cordinationReports[index], ...payload };

      let records = [...state.cordinationReports];
      records[index] = record;

      state.cordinationReports = [...records];
      state.loading = false;
      state.errors = [];
    },
    updateCordinationReportsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getcordinationReports,
  getcordinationReportsSuccess,
  getcordinationReportsFailure,
  updateCordinationReports,
  updateCordinationReportsSuccess,
  updateCordinationReportsFailure,
} = cordinationReportsSlice.actions;

export const cordinationReportsSelector = (state) => state.reports;
export default cordinationReportsSlice.reducer;

export function fetchcordinationReportsService(orgId) {
  return async (dispatch) => {
    dispatch(getcordinationReports());

    try {
      const response = await Api.getPayrollReport(orgId);
      dispatch(getcordinationReportsSuccess(response.data));
    } catch (error) {
      dispatch(getcordinationReportsFailure(error));
    }
  };
}

export function updateCordinationReportsService(payload) {
  return async (dispatch) => {
    dispatch(updateCordinationReports());
    try {
      console.log("#### ", payload);

      dispatch(updateCordinationReportsSuccess(payload));
    } catch (error) {
      dispatch(updateCordinationReportsFailure(error));
    }
  };
}
