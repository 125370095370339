import React from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function InputMoney(props) {
  const { name, label, onChange, minimumValue, maximumValue, setOverPaymentControl = null,/* setCustomValue = null,*//* overPaymentControl = null, overPaidItem = null, itemType = null,*/ ...other } = props;
  const classes = useStyles();

  return (
    <CurrencyTextField
      label={label}
      variant="outlined"
      currencySymbol="$"
      outputFormat="string"
      maximumValue={maximumValue ? maximumValue : "999999"}
      onChange={(e, value) => {
        onChange(value);
        setOverPaymentControl?setOverPaymentControl(value):'';
        //console.log("InputMoney",value);
      }}
      className={classes.textfield}
      minimumValue={minimumValue ? minimumValue : "0"}
      //value={setCustomValue?setCustomValue:0}
      //className={"yea"}
      {...other}
    />
  );
}
