import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'admFaqProducts';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code="GET_ADM_FAQ_PRODUCT";
  return _fetchEntitiesService(dataSlice,events);
}
export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code="POST_ADM_FAQ_PRODUCT";
  return _createEntityService(dataSlice,events);
}


export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code="PUT_ADM_FAQ_PRODUCT";
  return _updateEntityService(dataSlice,events);
}


export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code="DELETE_ADM_FAQ_PRODUCT";
  return _deleteEntityService(dataSlice,events);
}