import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

export default function DatePicker(props) {
  const { label, onChange, value, ...other } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        margin="normal"
        className={classes.textfield}
        id="time-picker"
        variant="inline"
        inputVariant="outlined"
        label={label}
        value={value? moment(value, [moment.ISO_8601, "HH:mm:ss"]): null}
        onChange={(date) => onChange(date? moment(date).format("HH:mm:00"): null)}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
