import {
  newSlice,
  _fetchEntitiesService,
  _fetchEntitiesServiceGroups,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
  _updateOnlyEntityService,
  //_updateOnlyMessageCountService,
  _updateOnlyMessageCountServiceByGroups,
  //_unreadMessageCountService,
  _unreadMessageCountServiceByGroups,
  //_changeColorService,
  _changeColorServiceByGroups,
  _changeBodyService,
  //_addOnlyEntityService,
  _addOnlyEntityServiceByGroups,
} from "./common/sliceFunctionsSmsMms";

var entitiesName = "userContactsSMSMMSByGroups";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_USERCONTACTSMSMMSBYGROUPS";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  //return _fetchEntitiesService(dataSlice, events);
  return _fetchEntitiesServiceGroups(dataSlice, events);
}
export function updateOnlyMessageCountService(payload) {
  return _updateOnlyMessageCountServiceByGroups(payload, events);
}

export function unreadMessageCountService(payload) {
  return _unreadMessageCountServiceByGroups(payload, events);
}

export function changeColorService(payload) {
  return _changeColorServiceByGroups(payload, events);
}

export function changeBodyService(payload) {
  return _changeBodyService(payload, events);
}

export function addOnlyEntityService(payload) {
  return _addOnlyEntityServiceByGroups(payload, events);
}
