import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import HeadersSidebar from "./HeadersSidebar";
import ConversationPanel from "./ConversationPanel";
import {
  Avatar,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import Controls from "../controls";
import IconButton from "../controls/IconButton";
import Iconify from "../Iconify";
import { debounce } from "lodash";
import Api from "../../lib/api";
import ScrollBar from "react-perfect-scrollbar";
import moment from "moment";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  chatWrapper: {
    padding: 8,
    minHeight: "94vh",
    maxHeight: "94vh",
    paddingLeft: 4,
  },
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
}));

var limit = 40;
const SearchMessagesPanel = (props) => {
  const classes = useStyles();
  const {
    setSelectedMessage,
    modelType,
    setIsOpened,
    setSelectedHeader,
    setGetMessagesNeeded,
    chatHeaders = [],
  } = props;
  const messagesPage = useRef(0);
  const [totalMessages, setTotalMessages] = useState(-1);
  const { authUser } = useSelector((state) => state.auth);
  const [search, setSearch] = useState(null);
  const [loadingHeaders, setLoadingHeaders] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [messages, setMessages] = useState([]);

  const searchInHeaders = async (value) => {
    setLoadingHeaders(true);
    const { data } = await Api.searchInHeaders({
      model_type: modelType,
      search: value,
    });
    setLoadingHeaders(false);
    setHeaders([...data.data]);
  };

  const searchInMessages = async (messagesToMerge = [], value) => {
    messagesPage.current = messagesPage.current + 1;
    setLoadingMessages(true);
    const { data } = await Api.searchInMessages(
      {
        model_type: modelType,
        search: value,
      },
      messagesPage.current,
      limit
    );
    setLoadingMessages(false);
    setMessages([...messagesToMerge, ...data.data]);
    setTotalMessages(data?.meta?.pagination?.total || 0);
  };

  const onSearchChange = async (value) => {
    messagesPage.current = 0;
    setSearch(value);

    searchInHeaders(value);

    searchInMessages([], value);
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => onSearchChange(newValue), 800),
    []
  );

  const getMessageHeader = (message) => {
    setSelectedMessage(message.id);
    setSelectedHeader(message.userHeader.data);
    setIsOpened(false);

  };

  return (
    <div className={classes.chatWrapper}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            if (setIsOpened) {
              setIsOpened(false);
            }
          }}
        >
          <Iconify icon="lets-icons:back" />
        </IconButton>
        <Controls.Input
          onChange={(e) => {
            !loadingHeaders && !loadingMessages
              ? debouncedSearch(e.target.value)
              : onSearchChange(e.target.value);
          }}
          fullWidth
          variant="outlined"
          label="Search..."
          autoFocus
          InputProps={{
            endAdornment: (
              <Iconify
                style={{ width: 22, height: 22 }}
                icon="majesticons:search-line"
              />
            ),
          }}
        />
      </div>
      <div
        className={classes.messagesContainer}
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: 800,
          overflowY: "auto",
          overflowX: "hidden",
        }}
        id={"scrollableDiv1"}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            if (messages.length != 0) {
              searchInMessages(messages, search);
            }
          }}
          style={{
            flexDirection: "column",
            display: "flex",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          hasMore={totalMessages != messages.length ? true : false}
          scrollableTarget={"scrollableDiv1"}
        >
          <div style={{ paddingLeft: "5px" }}>
            <Typography
              style={{
                fontWeight: 600,
                borderBottom: "1px solid #efefef",
                paddingBottom: 6,
              }}
            >
              {"Chats"}
            </Typography>
            {loadingHeaders ? <LinearProgress /> : ""}

            {search && !loadingHeaders && headers.length == 0 ? (
              <div style={{ padding: 5, textAlign: "center", fontWeight: 600 }}>
                No results
              </div>
            ) : (
              ""
            )}
            <List>
              {headers.map((header) => {
                var headerTitle =
                  modelType == "App\\Models\\User"
                    ? header.recipient.fname + " " + header.recipient.lname
                    : header.recipient.name;
                const headerParts = headerTitle.split(
                  new RegExp(`(${search})`, "i")
                );

                const headerResult = headerParts.map((part, index) => {
                  return part.toLowerCase() === search.toLowerCase() ? (
                    <strong style={{ color: "black" }} key={index}>
                      {part}
                    </strong>
                  ) : (
                    <span key={index}>{part}</span>
                  );
                });

                const headerObject = (
                  <div style={{ color: "#474747" }}>{headerResult}</div>
                );

                return (
                  <ListItem
                    button
                    onClick={() => {
                      setGetMessagesNeeded(true);
                      setSelectedHeader(header);
                      setIsOpened(false);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          backgroundColor: "rgb(164 174 185 / 30%)",
                        }}
                        src={
                          header.recipient && header.recipient.avatar
                            ? header.recipient.avatar
                            : null
                        }
                      >
                        <Iconify
                          style={{ color: "#616a8d" }}
                          icon={
                            modelType == "App\\Models\\User"
                              ? header.recipient &&
                                header.recipient.gender &&
                                header.recipient.gender == "F"
                                ? "formkit:avatarwoman"
                                : "formkit:avatarman"
                              : "heroicons:user-group-20-solid"
                          }
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ style: { fontWeight: 600 } }}
                      primary={headerObject}
                    />
                  </ListItem>
                );
              })}
            </List>

            <Typography
              style={{
                fontWeight: 600,
                borderBottom: "1px solid #efefef",
                paddingBottom: 6,
              }}
            >
              {"Messages"}
            </Typography>

            {search && !loadingMessages && messages.length == 0 ? (
              <div style={{ padding: 5, textAlign: "center", fontWeight: 600 }}>
                No results
              </div>
            ) : (
              ""
            )}

            <List>
              {messages.map((message) => {
                const messageParts = message.body.split(
                  new RegExp(`(${search})`, "i")
                );

                const messageResult = messageParts.map((part, index) => {
                  return part.toLowerCase() === search.toLowerCase() ? (
                    <strong key={index}>{part}</strong>
                  ) : (
                    <span key={index}>{part}</span>
                  );
                });

                const messageObject = (
                  <div>
                    <span style={{ color: "gray" }}>
                      {message.from_user_id == authUser.id
                        ? "You: "
                        : message.from_user_full_name
                        ? message.from_user_full_name + ": "
                        : ""}
                    </span>
                    {messageResult}
                  </div>
                );

                return (
                  <ListItem
                    button
                    onClick={() => {
                      getMessageHeader(message);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          backgroundColor: "rgb(164 174 185 / 30%)",
                        }}
                        src={
                          message.userHeader &&
                          message.userHeader.data &&
                          message.userHeader.data.recipient &&
                          message.userHeader.data.recipient.avatar
                            ? message.userHeader.data.recipient.avatar
                            : null
                        }
                      >
                        <Iconify
                          style={{ color: "#616a8d" }}
                          icon={
                            modelType == "App\\Models\\User"
                              ? message.userHeader &&
                                message.userHeader.data &&
                                message.userHeader.data.recipient &&
                                message.userHeader.data.recipient.gender &&
                                message.userHeader.data.recipient.gender == "F"
                                ? "formkit:avatarwoman"
                                : "formkit:avatarman"
                              : "heroicons:user-group-20-solid"
                          }
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontWeight: 600,
                          maxWidth: 390,
                          wordBreak: "break-word",
                          fontSize: 14,
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          maxWidth: 390,
                          wordBreak: "break-word",
                          color: "#2f2f2f",
                        },
                      }}
                      primary={
                        modelType == "App\\Models\\User"
                          ? message.userHeader &&
                            message.userHeader.data &&
                            message.userHeader.data.recipient
                            ? message.userHeader.data.recipient.dropdown_name
                            : ""
                          : message?.userHeader?.data?.recipient?.name || ""
                      }
                      secondary={messageObject}
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontSize: 12,
                          color: "#6b707b",
                        }}
                      >
                        {moment(message.message_date).format(
                          "MM/DD/YY, hh:mm A"
                        )}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            {loadingMessages ? <LinearProgress /> : ""}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SearchMessagesPanel;
