import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { spacing } from "@material-ui/system";
import {
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography,
  Button,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ReactPlayer from "react-player";
import UploadVideoFromUrl from "../../components/UploadVideoFromUrl";
import UploadVideoFromStorage from "../../components/UploadVideoFromStorage";
import UltraSoundVideo from "../../components/ultraSoundVideo";
import Api from "../../lib/api";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Iconify from "../../components/Iconify";
import Controls from "../../components/controls";
import ScrollBar from "react-perfect-scrollbar";

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  videoContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  videoPlayer: {
    margin: theme.spacing(4),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function UltrasoundsVideos(props) {
  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);
  const [extractedVideos, setExtractedVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRecordVideos, setIsLoadingRecordVideos] = useState(true);
  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [areVideosUploading, setAreVideosUploading] = useState(false);
  const [isFinishedUploading, setIsFinishedUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [videoOnLoopIndex, setvideoOnLoopideoOnLoopIndex] = useState(0);
  const [videosToLoopAmount, setVideosToLoopAmount] = useState(0);
  const { record } = props;
  const [recordVideos, setRecordVideos] = useState([]);
  const recordVideosRef = useRef([]);
  const [patientHasNotVideos, setPatientHasNotVideos] = useState(false);
  const [productRecords, setProductRecords] = useState([]);
  const [productRecordsDropDown, setProductRecordsDropDown] = useState();

  const [openUploadFromUrl, setOpenUploadFromUrl] = useState(false);
  const [openUploadFromStorage, setOpenUploadFromStorage] = useState(false);
  const [videosFromStorage, setVideosFromStorage] = useState([]);

  const renderVideos = () => {
    return recordVideos.map((video) => {
      return <UltraSoundVideo video={video} isLoading={true} />;
    });
  };

  //const getRecordProducts = async () => {};

  const handleProductRecord = (event) => {
    setProductRecordsDropDown(event.target.value);
    return;
  };

  const handleOpenUploadFromUrl = () => {
    setOpenUploadFromUrl(true);
    setIsLoading(false);
  };
  const handleOpenUploadFromStorage = () => {
    setOpenUploadFromStorage(true);
    setIsLoading(false);
  };

  const onCloseUploadFromUrl = () => {
    setOpenUploadFromUrl(false);
  };

  const onSubmitVideos = async (url, selectedVideos) => {
    try {
      let videos = [];

      //Get the videos from url and submit them or just submit them if were selected from storage
      if (url) {
        const { data } = await Api.extractVideos(url);
        videos = data;
      } else {
        videos = { data: selectedVideos };
      }

      setExtractedVideos(videos);
      setIsLoadingRecordVideos(false);
      let videosAmount = videos.data.length;
      setVideosToLoopAmount(videos.data.length);
      let pivot = 0;
      videos.data.map(async (video) => {
        let payload = null;
        if (url) {
          payload = {
            url: video,
          };
        } else {
          let file = {
            id: 1,
            errors: [],
            file: video,
            valid: true,
          };

          let formData = new FormData();
          Object.keys(file).forEach((key) => formData.append(key, file[key]));
          formData.append("docType", "Ultrasound Video");
          payload = formData;
        }

        console.log("video ", video, " video2 ", { ...video });
        const { data } = await Api.postRecordVideo(
          record.contact_id,
          productRecordsDropDown,
          payload
        );
        pivot += 1;
        setvideoOnLoopideoOnLoopIndex(pivot);
        recordVideosRef.current = [...[data.data], ...recordVideosRef.current];
     
        setRecordVideos(recordVideosRef.current);
        if (pivot == videosAmount) {
          setAreVideosUploading(false);
          setIsLoading(false);
          setIsFinishedUploading(false);
          setIsError(false);
        }
      });
      setvideoOnLoopideoOnLoopIndex(0);
    } catch (err) {
    
      setIsLoading(false);
      setIsFinishedUploading(false);
      setIsError(true);
    }
  };

  const getRecordVideos = async (pr) => {
    const { data } = await Api.getRecordVideos(record.contact_id, pr);
    setRecordVideos(data.data);
    recordVideosRef.current = data.data;
    setIsLoadingRecordVideos(false);
    if (data.data.length > 0) {
      setPatientHasNotVideos(false);
    } else {
      setPatientHasNotVideos(true);
    }
  };

  const getRecordProducts = async () => {
    const { data } = await Api.getRecordProductsPlain(record.id);
    setProductRecords(data.data);
    setIsLoadingStart(false);
  };

  useEffect(() => {
    getRecordProducts();
  }, []);

  useEffect(() => {
    if (recordVideos.length > 0) {
      setPatientHasNotVideos(false);
    }
  }, [recordVideos]);

  useEffect(() => {
    if (productRecordsDropDown) getRecordVideos(productRecordsDropDown);
  }, [productRecordsDropDown]);

  useEffect(() => {}, [productRecords]);

  useEffect(() => {
    if (extractedVideos.data) {
      setIsLoadingRecordVideos(false);
      setAreVideosUploading(true);
      if (extractedVideos.data.length > 0) {
        setIsLoading(false);
        setIsFinishedUploading(true);
      }
    }
  }, [extractedVideos]);

  //Loading Bar
  if (isLoadingStart) {
    return (
      <Box style={{ padding: 300 }}>
        <Typography variant="body2" color="textSecondary" component="p">
          Loading...
        </Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Ultrasounds" />
      <Paper className={classes.pageContent}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="healthicons:critical-care-outline"
            style={{ fmargin: 7, fontSize: 31, color: "rgb(0 116 232)" }}
          />
          <Typography
            style={{ marginLeft: 0, margin: 15, fontWeight: 600, fontSize: 17 }}
          >
            Ultrasounds for Patient {record.fname} {record.lname}
          </Typography>
        </div>

        <div
          style={{
            marginTop: 25,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <Typography variant="subtitle1">
              Please select a Procedure:
            </Typography>

            <FormControl className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "15px" }}
              >
                Procedure
              </InputLabel>
              <Select
                //variant="outlined"
                value={productRecordsDropDown}
                onChange={handleProductRecord}
              >
                {productRecords.map((pr) => {
                  return (
                    <MenuItem value={pr.id}>{pr.invoice_item_name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              width: "100%",
              textAlign: "right",
              marginTop: -50,
              marginRight: 60,
            }}
          >
            {!isLoadingStart && productRecordsDropDown && (
              <div
                style={{
                  display: "block",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <Box>
                  <Button
                    startIcon={
                      <CloudUploadIcon className={classes.extendedIcon} />
                    }
                    disabled={areVideosUploading}
                    onClick={handleOpenUploadFromUrl}
                    style={{
                      backgroundColor: "#e0e0e0",
                      borderRadius: 18,
                      fontSize: 14,
                      paddingLeft: 20,
                      paddingRight: 10,
                      marginRight: 20,
                      fontWeight: 700,
                    }}
                  >
                    Upload from URL
                  </Button>
                </Box>
                <Typography
                  disabled={areVideosUploading}
                  style={{ paddingRight: 95, marginTop: 10 }}
                >
                  OR
                </Typography>
                <Box style={{ marginTop: 10 }}>
                  <Controls.DropzoneDialog
                    startIcon={
                      <CloudUploadIcon className={classes.extendedIcon} />
                    }
                    disableSubmitButton={areVideosUploading}
                    label="Upload from Storage"
                    formats={["video/mp4", "video/mkv"]}
                    filesLimit={20}
                    st={{
                      color: areVideosUploading ? "#a6a6a6" : "#000",
                      borderRadius: 18,
                      backgroundColor: "#e0e0e0",
                      fontSize: "14px",
                      height: 34,
                      margin: 0,
                    }}
                    onChange={(files) => {
                      console.log("asdasdasd ", files),
                        onSubmitVideos(null, files);
                    }}
                    value={openUploadFromStorage}
                  />
                </Box>
              </div>
            )}
          </div>
        </div>

        {productRecordsDropDown && (
          <>
            <Box
              style={{
                paddingTop: "50px",
                paddingBottom: areVideosUploading ? "120px" : "60px",
              }}
            >
              {areVideosUploading ? (
                <>
                  <Typography
                    color="textSecondary"
                    component="p"
                    style={{ fontSize: "15px" }}
                  >
                    Uploading videos...
                    {"(" + videoOnLoopIndex + "/" + videosToLoopAmount + ")"}
                  </Typography>
                  <LinearProgress />
                </>
              ) : (
                <div style={{ height: 10 }}></div>
              )}
            </Box>
            <ScrollBar style={{ marginTop: -100, maxHeight: 550 }}>
              <Box mt={5} className={classes.videoContainer}>
                {recordVideos.map((video) => {
                  return (
                    <ReactPlayer
                      key={video.id}
                      url={video.videoUrl}
                      controls={true}
                      width={240}
                      height={320}
                      className={classes.videoPlayer}
                    />
                  );
                })}
              </Box>
            </ScrollBar>
            {!isLoadingRecordVideos ? (
              <Grid container spacing={8} style={{ paddingTop: 20 }}>
                {!patientHasNotVideos ? (
                  <></>
                ) : (
                  <Box className={classes.videoContainer}>
                    <Typography
                      variant="caption"
                      style={{ fontSize: 20, paddingLeft: "40%" }}
                    >
                      This patient has not videos yet
                    </Typography>
                  </Box>
                )}
                <UploadVideoFromUrl
                  openPopup={openUploadFromUrl}
                  onClose={onCloseUploadFromUrl}
                  onSubmit={onSubmitVideos}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isFinishedUploading={isFinishedUploading}
                  isError={isError}
                  setIsError={setIsError}
                />
                <UploadVideoFromStorage
                  openPopup={openUploadFromStorage}
                  onClose={onCloseUploadFromUrl}
                  onSubmit={onSubmitVideos}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isFinishedUploading={isFinishedUploading}
                  isError={isError}
                  setIsError={setIsError}
                />
              </Grid>
            ) : (
              <Box style={{ padding: 250 }}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Loading patient videos, please wait...
                </Typography>
                <LinearProgress />
              </Box>
            )}
          </>
        )}
      </Paper>
    </React.Fragment>
  );
}

export default UltrasoundsVideos;
