import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";

const ContactOption = ({ option, selected }) => {
    return (
        <Card
            style={{
                width: "100%",
            }}
        >
            <CardContent
                style={{
                    backgroundColor: selected ? "lightgreen" : "inherit",
                }}
            >
                <Grid container direction="row">
                    <Grid item container direction="column" md={6}>
                        <Grid item xs={9} container direction="column">
                            <Typography variant="subtitle1">
                                {option?.full_name}
                            </Typography>
                            <Typography
                                noWrap
                                variant="body"
                                style={{
                                    fontSize: 12,
                                }}
                            >
                                {option?.email}
                            </Typography>
                            <Typography
                                noWrap
                                variant="body"
                                style={{
                                    fontSize: 12,
                                }}
                            >
                                Phone: {option?.phone ?? "<None>"}
                            </Typography>
                            <Typography
                                variant="body"
                                style={{
                                    fontSize: 12,
                                }}
                            >
                                Gender: {option?.sex ?? "<No specified>"}
                            </Typography>
                            <Typography
                                noWrap
                                variant="body"
                                style={{
                                    fontSize: 12,
                                }}
                            >
                                {option.languages?.length > 0
                                    ? `Languages:${option.languages
                                          ?.map((l) => l.name)
                                          .join(", ")}`
                                    : "No languages set"}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container direction="column" md={6}>
                        <Grid container item direction="row">
                            <Grid item xs={6}>
                                <Avatar
                                    src={option.organization?.menu_logo}
                                    alt={option.organization?.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {option.organization?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option.organization?.email}
                        </Typography>
                        <Typography
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            Phone: {option.organization?.phone}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default ContactOption;
