import { CssBaseline, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Header from "../modules/portalModule/pages/header";
import Footer from "../modules/portalModule/pages/footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchPortalUser as fetchUser } from "../redux/slices/auth";
import { isEmpty } from "lodash";
import { Redirect, useParams } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #FFF;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: #fff;
  padding: 0px 20px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const PortalDashboard = ({ children, routes, width }) => {
  const dispatch = useDispatch();
  const { authUser, loading, errors } = useSelector((state) => state.auth);
  const { token } = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const iid = urlParams.get("iid");

  useEffect(() => {
    if (isEmpty(authUser)) dispatch(fetchUser());
  }, []);

  if (!isEmpty(loading)) return null;
  if (!isEmpty(errors))
    return (
      <Redirect
        to={"/portal/" + token + "/auth" + (iid ? "?iid=" + iid : "")}
      />
    );
  if (!isEmpty(authUser))
    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent style={{ maxWidth: "100%" }}>
          <Header />
          <MainContent style={{ padding: "0px 2%" }}>{children}</MainContent>
          <Footer />
        </AppContent>
      </Root>
    );
  return null;
};

export default PortalDashboard;
