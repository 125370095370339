import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  contacts: [],
  fields: [],
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    getContacts: (state) => {
      state.loading = true;
    },
    getContactsSuccess: (state, { payload }) => {
      state.contacts = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getContactsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createContact: (state) => {
      state.saveLoading = true;
    },
    createContactSuccess: (state, { payload }) => {
      state.contacts = [...state.contacts, payload.data];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    createContactFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    updateContact: (state) => {
      state.saveLoading = true;
    },
    updateContactSuccess: (state, { payload }) => {
      const index = findIndex(state.contacts, ["id", payload.data.id]);
      const contact = { ...state.contacts[index], ...payload.data };

      let contacts = [...state.contacts];
      contacts[index] = contact;

      state.contacts = [...contacts];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    updateContactFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    deleteContact: (state) => {},
    deleteContactSuccess: (state, { payload }) => {
      state.contacts = state.contacts.filter((contact) => {
        return contact.id !== payload;
      });
    },
    deleteContactsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    getCustomFields: (state) => {
      //state.loading = true;
    },
    getCustomFieldsSuccess: (state, { payload }) => {
      state.fields = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCustomFieldsFailure: (state, { payload }) => {
      state.loading = false;
      state.fields = [];
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getContacts,
  getContactsSuccess,
  getContactsFailure,
  createContact,
  createContactSuccess,
  createContactFailure,
  updateContact,
  updateContactSuccess,
  updateContactFailure,
  deleteContact,
  deleteContactSuccess,
  deleteContactFailure,
  getCustomFields,
  getCustomFieldsSuccess,
  getCustomFieldsFailure,
} = contactsSlice.actions;

export const contactsSelector = (state) => state.contacts;
export default contactsSlice.reducer;

export function fetchContactsService(orgId, params = null) {
  return async (dispatch) => {
    dispatch(getContacts());

    try {
      const response = await Api.getContactsSelect(orgId, params);
      dispatch(getContactsSuccess(response.data));
    } catch (error) {
      dispatch(getContactsFailure(error));
    }
  };
}

export function createContactService(payload) {
  return async (dispatch) => {
    dispatch(createContact());

    try {
      const response = await Api.saveContact(payload);

      dispatch(createContactSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(createContactFailure(error));
      return error;
    }
  };
}

export function updateContactService(id, payload) {
  return async (dispatch) => {
    dispatch(updateContact());

    try {
      const response = await Api.updateContact(id, payload);

      dispatch(updateContactSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(updateContactFailure(error));
    }
  };
}

export function updateContact2(payload) {
  return async (dispatch) => {
    dispatch(updateContactSuccess(payload));
  };
}

export function deleteContactService(id) {
  return async (dispatch) => {
    dispatch(deleteContact());

    try {
      const response = await Api.deleteContact(id);

      dispatch(deleteContactSuccess(id));
    } catch (error) {
      dispatch(deleteContactFailure(error));
    }
  };
}

export function fetchCustomFieldsService(orgId) {
  return async (dispatch) => {
    dispatch(getCustomFields());

    try {
      const response = await Api.getContactCustomFields(orgId);
      dispatch(getCustomFieldsSuccess(response.data));
    } catch (error) {
      dispatch(getCustomFieldsFailure(error));
    }
  };
}
