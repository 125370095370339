import React from "react";
import { Paper, Card, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
  },
  pageHeader: {
    padding: theme.spacing(4),
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  pageIcon: {
    display: "grid",
    alignItems: 'center',
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));

export default function PageHeader(props) {
  const classes = useStyles();
  const { title, subTitle, icon, st = null } = props;
  return (
    <Paper style={st? st : {}} elevation={0} square className={classes.root}>
      <div className={classes.pageHeader}>
        <Card className={classes.pageIcon}>{icon}</Card>
        <div className={classes.pageTitle}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          {/*
          <Typography variant="subtitle2" component="div">
            {subTitle}
          </Typography>
          */}
        </div>
      </div>
    </Paper>
  );
}
