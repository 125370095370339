import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ResponsibleForm from "./forms/ResponsibleForm";
import { find } from "lodash";
import Controls from "./controls";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "./ConfirmDialog";
import MaterialTable from "material-table";
import { Paper, ListItem as MuiListItem, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import useTable from "./useTable";
import Popup from "./Popup";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

function Responsible(props) {
  const classes = useStyles();
  const {
    responsibles,
    users,
    onSave,
    onDelete,
    canAdd = true,
    canEdit = true,
    canDelete = true,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);

  const saveResponsible = (data) => {
    onSave(data);
    onClose();
  };

  const dataMaterialTable = responsibles.map((o) => ({ ...o }));

  const headCellsMaterial = [
    {
      field: "name",
      title: "Name",
      render: (rowData) => {
        return rowData.title ? rowData.title : rowData.first_name;
      },
    },

    {
      field: "position",
      title: "Position",
      render: (rowData) => {
        return rowData.position;
      },
    },
    {
      field: "department",
      title: "Department",
      render: (rowData) => {
        return rowData.department_name;
      },
    },
    {
      field: "email",
      title: "Email",
      render: (rowData) => {
        return rowData.email;
      },
    },
  ];

  const [filteredusers, setFilteredusers] = useState([]);

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const deleteResponsible = (id) => {
    onDelete(id);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const filterusers = () => {
    if (filteredusers.length == 0) {
      setFilteredusers(users);
    }

    let notemp = [];
    users.forEach((c) => {
      if (!find(responsibles, { id: c.id })) {
        notemp.push(c);
      }
    });

    setFilteredusers(notemp);
  };

  useEffect(() => {
    filterusers();
  }, [users]);
  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                ),
                tooltip: "Add Responsible",
                disabled: !canAdd,
                hidden: !canAdd,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton color="secondary">
                    <CloseIcon fontSize="small" />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                disabled: !canDelete,
                hidden: !canDelete,
                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this responsible?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => deleteResponsible(rowData.id),
                  }),
              }),
            ]}
            options={{
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Responsibles"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Add Responsible"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <ResponsibleForm
          saveResponsible={saveResponsible}
          users={filteredusers}
          className={classes.form}
          responsibles={responsibles}
        />
      </Popup>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default Responsible;
