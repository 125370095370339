import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import { darken } from "polished";
import { useDispatch, useSelector } from "react-redux";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import CallMissedRoundedIcon from "@material-ui/icons/CallMissedRounded";
import { isEmpty, findIndex } from "lodash";
import Popup from "./Popup";
import ContactForm from "./forms/ContactForm";
import Iconify from "./Iconify";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import CRMUtils from "../utils";
import {
  Badge,
  Grid,
  InputBase,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Card,
  Popover,
  Tooltip,
  ListItemIcon,
  ListItem,
  ListItemText,
  Fade,
  Zoom,
  Button,
  Chip,
  CardHeader,
  Box,
  LinearProgress,
  DialogActions,
  Drawer,
} from "@material-ui/core";
import { green, red, blue } from "@material-ui/core/colors";
import { Menu as MenuIcon } from "@material-ui/icons";
import { createEntityService as createContactService } from "../redux/slices/contactsSlice.js";
import { fetchTagsService } from "../redux/slices/tags";
import {
  fetchEntitiesService as fetchLiveNotificationsService,
  addUnreadToPaginationEntityService,
} from "../redux/slices/liveNotificationsSlice.js";
import { createMissedCallsService } from "../redux/slices/missedCallsSlice.js";
import Notification2 from "./Notification2";

import ContactDetails from "../pages/contacts/details2";

import {
  createEntityService as createTicketsService,
  updateEntityService as updateTicketsService,
  updateOnlyEntityService as updateOnlyTiketService,
} from "../redux/slices/ticketsSlice.js";

import auth, {
  updateOnlyEntityService as updateOnlyUserService,
} from "../redux/slices/auth";

var dataSliceContact = {
  stateListName: "contacts",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },

  dataCreate: {
    data: null,
    successHandle: null,
  },

  errorHandle: null,
};

var dataSliceConsultantBuildingDropdown = {
  stateListName: "consultantBuildingDropdown",
  dataUrl: {},
  orgId: 1,
  getFilters: {
    include: [],
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  errorHandle: null,
};

import { createNewItemService } from "../redux/slices/newItems";
import moment from "moment";

// import AddIcCallIcon from "@material-ui/icons/AddIcCall";
// import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
// import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
// import EmailIcon from "@material-ui/icons/Email";
// import SearchIcon from "@material-ui/icons/Search";
// import HeaderMenu from "./HeaderMenu";
// import Popup from "./Popup";
// import MaterialTable from "material-table";
// import NotificationObserver from "./notifications/NotificationObserver";
// import HeaderMenu2 from "./HeaderMenu2";
// import PhoneIcon from "@material-ui/icons/Phone";
// import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
// import MicOffIcon from "@material-ui/icons/MicOff";
// import VolumeOffIcon from "@material-ui/icons/VolumeOff";
// import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
// import ErrorIcon from "@material-ui/icons/Error";
// import CallMissed from "@material-ui/icons/CallMissed";

import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Link, useHistory /* , Redirect */ } from "react-router-dom";
import i18n from "i18next";
import { logOutService } from "../redux/slices/auth";

import Api from "../lib/api";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useInterval } from "beautiful-react-hooks";
import List from "@material-ui/core/List";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import MesagesNotification2 from "./MesagesNotification2";
import { updatePbxInfo } from "../redux/slices/auth";
import MicIcon from "@material-ui/icons/Mic";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import MusicOffIcon from "@material-ui/icons/MusicOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import UserStatusList from "./UserStatusList";
import Clock from "./forms/Clock";

import PopupTicket from "./PopupTicket";
import TicketFormResolve from "./forms/TicketFormResolve";
import { fetchEntitiesService as fetchDepartmentsNewService } from "../redux/slices/departmentsNewSlice.js";
import { fetchEntitiesService as fetchAppointmentTypesService } from "../redux/slices/appointmentTypesSlice";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import AppointmentNewForm from "./forms/AppointmentNewForm";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ScrollBar from "react-perfect-scrollbar";
import AllNotifications from "./forms/AllNotifications";
import InvoiceDetails from "../pages/invoices/details";
import { useMemo } from "react";
import TaskForm from "../modules/task/components/TaskForm";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import Controls from "./controls";
import RefundTicketForm from "../modules/refunds/components/RefundTicketForm";
import InternalChat from "./internalChat/InternalChat.js";
import FavoriteSitesList from "./FavoriteSitesList.js";
import BookmarkButton from "./BookmarkButton.js";
import PaymentLinkRequest from "./PaymentLinkRequest.js";
import RecordDetails from "../pages/records/details.js";

const Accordion = withStyles({
  root: {
    borderTop: "1px solid rgb(147 147 147 / 11%)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  heidth: auto;
`;

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 12,
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  newDiv: {
    fontSize: "11px",
    fontWeight: 600,
    color: "#0e9792",
    background: "#c7f9e7",
    borderRadius: 7,
    padding: "0px 4px",
    border: "1px solid #66c1c182",
    marginLeft: -2,
  },
  scroll: {
    "& .ps__rail-x": {
      visibility: "hidden",
    },
  },
  iconProps: {
    marginRight: "2px",
    fontSize: 15,
  },
  paper: {
    top: 67,
    right: 1,
  },
  chipStyle: {
    background: "#deefff",
    color: "#0080db",
    fontWeight: 600,
    border: "1px solid rgb(143 194 237)",
  },
  margin: {
    margin: theme.spacing(1),
  },

  listItemIcon: {
    minWidth: "35px",
  },
  menuItemHeigh: {
    minHeight: "40px !important",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "250px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsPopup: {
    width: "700px !important",
  },
  root: {
    width: "100%",
    maxWidth: "70ch",
    backgroundColor: theme.palette.background.paper,
  },
  accordionDetails: {
    width: "100%",
    marginTop: -20,
    background: "#00000008",
    borderRadius: 25,

    "&.MuiPaper-elevation1": {
      boxShadow: "none",
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 15,
      marginBottom: 25,
      padding: "5px 0px",
      maxWidth: "92%",
    },
  },
  inline: {
    display: "inline",
  },
  inline2: {
    display: "inline",
    color: "#999",
  },
  hide: {
    display: "none",
  },
  hide: {
    display: "inLine",
  },
  details: {
    "&.MuiAccordionDetails-root": {
      display: "block",
    },
  },
  notificationsGroup: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    marginBottom: -20,
    justifyContent: "center",
  },
  smallIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: green[200],
  },
  smallIconSelected: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: green[800],
  },
}));

var dataSliceAppointmentTypes = {
  stateListName: "appointmentTypes",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 50,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const languages = [
  {
    id: "en",
    title: "English",
  },
  {
    id: "es",
    title: "Spanish",
  },
];

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [language, setLanguage] = useState(i18n.language);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const selectLanguage = (lang) => {
    i18n.changeLanguage(lang, () => {
      setLanguage(lang);
    });
    closeMenu();
  };

  const renderLanguageItems = () => {
    return languages.map((language) => {
      return (
        <MenuItem key={language.id} onClick={() => selectLanguage(language.id)}>
          {language.title}
        </MenuItem>
      );
    });
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Flag src={`/static/img/flags/${language}.png`} alt={language} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {renderLanguageItems()}
      </Menu>
    </React.Fragment>
  );
}

function UserMenu() {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const doLogout = async () => {
    const token = localStorage.getItem("deviceToken");
    localStorage.removeItem("mmsUnread");
    localStorage.removeItem("live_notification_data");
    if (token) {
      await Api.unregisterToken(authUser.id, token, "web").then((result) => {
        dispatch(logOutService(authUser.id));
      });
    } else {
      dispatch(logOutService(authUser.id));
    }
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <PowerSettingsNewIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu} component={Link} to="/profile">
          Profile
        </MenuItem>
        <MenuItem onClick={doLogout}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function LiveNotifications(props) {
  const classes = useStyles();
  const { setUnseenNotification, unseenNotification } = props;
  // const [seconds, setSeconds] = useState(0);

  let firstUse = true;

  /* props.useInterval(() => {
    if (firstUse) {
      props.getNotifications();
      firstUse = false;
    }

    setSeconds(120 + seconds);
  }, 120000); */

  return (
    <>
      <IconButton
        style={{ color: green[700] }}
        className={props.classes.margin}
        aria-label="notification"
        onClick={(event) => {
          if (setUnseenNotification) {
            setUnseenNotification(false);
          }

          props.handleSelectNotificationType(null);
          props.visible === false ? props.setVisible(true) : "";
          props.setAnchorEl(event.currentTarget);
        }}
      >
        <Indicator badgeContent={props.count}>
          <Iconify
            icon={"ic:round-notifications"}
            style={{
              color: unseenNotification ? "rgb(63 118 189)" : green[700],
            }}
            width={25}
            height={25}
          />
        </Indicator>
        {unseenNotification ? <div className={classes.newDiv}>New</div> : ""}
      </IconButton>
    </>
  );
}

const Header = ({
  setOpenNewSMS,
  isPC = null,
  onDrawerToggle,
  onChatDrawerToggle,
  onMailDrawerToggle,
  isViewIcons = null,
  unseenNotification = false,
  setUnseenNotification,
  unseenMessage = false,
  setUnseenMessage,
  buildingsDropdown = buildingsDropdown,
}) => {
  const { generalTotalUnreadChatMessages } = useSelector(
    (state) => state.generalTotalUnreadChatMessages
  );
  const [openInternalChat, setOpenInternalChat] = useState(false);
  const [loadingSetAllAsRead, setLoadingSetAllAsRead] = useState(false);
  const [notificationsUnreadByType, setNotificationsUnreadByType] = useState(
    []
  );
  const methodsTasks = useForm();
  const methodsRefundTickets = useForm();
  const { missedCallsCount } = useSelector((state) => state.missedCallsCount);
  const [openAllNotifications, setOpenAllNotifications] = useState(false);
  const portal = localStorage.getItem("portal");
  const { authUser, pbxConfig } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let history = useHistory();
  const [notData, setNotData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);
  const {
    liveNotifications,
    liveNotificationsPagination,
    liveNotificationsLoading,
  } = useSelector((state) => state.liveNotifications);
  const tableRef = React.createRef();
  const [timer, setTimer] = useState(null);

  const [receivingControlManual, setReceivingControlManual] = useState(false);

  const [callsMissed, setCallsMissed] = useState(null);

  const [anchorElNew, setAnchorElNew] = useState(null);

  const [anchorElGoTo, setAnchorElGoTo] = useState(null);
  const [anchorElFavoriteSites, setAnchorElFavoriteSites] = useState(null);

  const handleClickNew = (event) => {
    setAnchorElNew(event.currentTarget);
  };

  const handleCloseNew = () => {
    setAnchorElNew(null);
  };

  const handleClickGoTo = (event) => {
    setAnchorElGoTo(event.currentTarget);
  };

  const handleCloseGoTo = () => {
    setAnchorElGoTo(null);
  };

  const handleClickFavoriteSites = (event) => {
    setAnchorElFavoriteSites(event.currentTarget);
  };

  const closeFavoriteSites = () => {
    setAnchorElFavoriteSites(null);
  };

  const [openPopupNewContact, setOpenPopupNewContact] = useState(false);
  const [recordDetail, setRecordDetail] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const [isLoadingRecord, setIsLoadingRecord] = useState(false);
  const [openRecordPopup, setOpenRecordPopup] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [openTicketPopup, setOpenTicketPopup] = useState(false);
  const [openInvoicePopup, setOpenInvoicePopup] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [invoiceDetail, setInvoiceDetail] = useState(null);

  const [contactSaved, setContactSaved] = useState(false);

  const documentsTabRef = useRef(null);

  const onCloseContact = () => {
    setOpenContactPopup(false);
  };
  const onCloseInvoice = () => {
    setOpenInvoicePopup(false);
  };

  const [task, setTask] = useState(null);
  const [isOpenRefundTask, setIsOpenRefundTask] = useState(null);

  const [refundTicket, setRefundTicket] = useState(null);
  const [isOpenRefundTicket, setIsOpenRefundTicket] = useState(null);
  const { contactsnew, errors } = useSelector((state) => state.contactsnew);

  const { ticketCategories } = useSelector((state) => state.ticketCategories);
  const { ticketPriorities } = useSelector((state) => state.ticketPriorities);
  const { departmentsNew, departmentsNewLoading } = useSelector(
    (state) => state.departmentsNew
  );
  const { usersDropdown } = useSelector((state) => state.users);
  const { buildings } = useSelector((state) => state.buildings);
  const { groups } = useSelector((state) => state.groups);
  const [paymentLinkRequest, setPaymentLinkRequest] = useState(null);
  const [openPaymentLinkRequestPopup, setOpenPaymentLinkRequestPopup] =
    useState(null);

  var dataSliceDepartmentsNew = {
    stateListName: "departmentsNew",

    dataUrl: {},
    orgId: 1,

    getFilters: {
      include: [],

      page: 1,
      params: [],
      filters: [],
    },
    dataList: {
      showPageLoading: false,
      successHandle: null,
    },
    dataUpdate: {
      id: null,
      data: null,
      successHandle: null,
    },
    dataCreate: {
      data: null,
      successHandle: null,
    },
    dataDelete: {
      id: null,
      successHandle: null,
    },
    errorHandle: null,
  };

  const {
    data: updateValue,
    isLoading: updateTaskIsLoading,
    mutate: updateModel,
  } = useMutation(
    ({ id, data }) => Api.genericUpdateModel("tk_tasks", id, data),
    {
      onSuccess: (data) => {
        // let d = data?.data?.data;
        setIsOpenRefundTask(false);
        setTask(null);
      },
    }
  );

  const { isLoading: updateRefundsTicketsLoading, mutate: updateRefundTicket } =
    useMutation(
      ({ id, data }) => Api.genericUpdateModel("rfs_tickets", id, data),
      {
        onSuccess: (data) => {
          // let d = data?.data?.data;
          setIsOpenRefundTicket(false);
          setRefundTicket(null);
        },
      }
    );

  const handleSubmitEdit = (data) => {
    if (data == null) {
      return;
    }
    updateModel({ id: task?.id, data });
  };

  const handleSubmitEditRefundTicket = (data) => {
    if (data == null) {
      return;
    }

    updateRefundTicket({ id: refundTicket?.id, data });
  };

  useEffect(() => {
    //update values after has opened
    if (isOpenRefundTask) {
      setTimeout(() => {
        Object.entries(task).forEach(([key, value]) => {
          methodsTasks.setValue(key, value);
        });
      });
    }
  }, [isOpenRefundTask, task]);

  useEffect(() => {
    //update values after has opened
    if (isOpenRefundTicket) {
      setTimeout(() => {
        Object.entries(refundTicket).forEach(([key, value]) => {
          methodsRefundTickets.setValue(key, value);
        });
      });
    }
  }, [isOpenRefundTicket, refundTicket]);

  const openContactSaved = async () => {
    if (!isEmpty(contactsnew)) {
      setContactDetail(contactsnew[0]);
      setOpenContactPopup(true);
    }
  };

  const onCloseNewContact = () => {
    setOpenPopupNewContact(false);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const openContactSavedAlert = async (id) => {
    if (id) {
      const { data } = await Api.getContact(id);
      setContactDetail(data.data);

      setOpenContactPopup(true);
    }
  };

  const openRefundTicket = async (id) => {
    if (id) {
      const { data } = await Api.genericQueryByIdModel("rfs_tickets", id, {
        with: [
          "priority",
          "reasons",
          "invoice",
          "invoice.contact",
          "getState",
          "invoice_items",
          "tags",
        ],
      });
      setRefundTicket(data.data);

      setIsOpenRefundTicket(true);
    }
  };

  const openTask = async (id) => {
    if (id) {
      const { data } = await Api.genericQueryByIdModel("tk_tasks", id, {
        with: ["priority", "getState", "user_assigned", "task_type"],
      });
      setTask(data.data);

      setIsOpenRefundTask(true);
    }
  };

  const openPaymentLinkRequest = async (id) => {
    if (id) {
      const { data } = await Api.getPaymentLinkRequest(id);
      setPaymentLinkRequest(data.data);

      setOpenPaymentLinkRequestPopup(true);
    }
  };

  const onClosePaymentLinkRequest = () => {
    setOpenPaymentLinkRequestPopup(false);
    setPaymentLinkRequest(null);
  };

  const openTicketSavedAlert = async (id) => {
    if (id) {
      const { data } = await Api.getTicket(id);
      setTicketDetail(data.data);

      setOpenTicketPopup(true);
    }
  };

  const showMessage = (notifyObj, msg, type = "success") => {
    notifyObj({
      isOpen: true,
      message: msg,
      type: type,
    });
  };

  const genericErrorHandle = () => {
    if (errors && errors.length > 0) {
      let error = errors[errors.length - 1];
      showMessage(setNotify, error, "error");
      return error;
    }
  };
  dataSliceContact.errorHandle = genericErrorHandle;

  const addContactSuccefull = () => {
    setOpenPopupNewContact(false);
    setContactSaved(true);
  };

  const addContact = (data) => {
    data.organization_id = authUser.organization_id;
    data.user_id = data.user_id ? data.user_id : authUser.id;
    data.addNotification = true;
    if (!isEmpty(authUser)) {
      dataSliceContact.dataCreate.data = data;
      dataSliceContact.dataCreate.successHandle = addContactSuccefull;
      dispatch(createContactService(dataSliceContact));
    }
  };

  const getBuildingOptions = () => {
    if (!buildingsDropdown) return [];
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };
  const { consultantBuildingDropdown } = useSelector(
    (state) => state.consultantBuildingDropdown
  );
  const getCoordinatorOptions = () => {
    if (!consultantBuildingDropdown) return [];
    return consultantBuildingDropdown.map((user) => {
      return {
        id: user.id,
        title: user.dropdown_name + "  (" + user.group_name + ")",
      };
    });
  };

  const { tags } = useSelector((state) => state.tags);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [appointmentPopup, setAppointmentPopup] = useState(false);
  const [anchorElPhoneMenu, setAnchorElPhoneMenu] = React.useState(null);
  const [appointmentForEdit, setAppointmentForEdit] = useState(null);
  const [invoiceForEdit, setinvoiceForEdit] = useState(null);
  const objRefRowForClickToCall = useRef();
  const [openClickToCallMenu, setOpenClickToCallMenu] = useState(false);
  const [userNotificationsTypes, setUserNotificationsTypes] = useState([]);
  const { appointmentTypes } = useSelector((state) => state.appointmentTypes);
  const [selectedNotificationsType, setSelectedNotificationsType] =
    useState(null);

  const permissions = useMemo(() => {
    return authUser?.permissions?.data?.map((p) => p.name) ?? [];
  }, [authUser]);

  var dataSliceLiveNotifications = {
    stateListName: "liveNotifications",
    dataUrl: {},
    orgId: 1,

    getFilters: {
      include: [],
      params: [],
      limit: 15,
      page: 1,
      filters: [],
    },
    dataList: {
      showPageLoading: false,
      successHandle: null,
    },

    errorHandle: null,
  };
  var dataSliceTickets = {
    stateListName: "tickets",
    dataUrl: {},
    orgId: 1,
    contact_id: null,

    getFilters: {
      include: [],
      limit: 50,
      page: 1,
      params: [],
      filters: [],
    },
    dataList: {
      showPageLoading: false,
      successHandle: null,
    },
    dataUpdate: {
      id: null,
      data: null,
      successHandle: null,
    },
    dataCreate: {
      data: null,
      successHandle: null,
    },
    dataDelete: {
      id: null,
      successHandle: null,
    },
    errorHandle: null,
  };
  const handleClose = () => {
    setAnchorElPhoneMenu(null);
  };

  const [anchorElPhoneSettingMenu, setAnchorElPhoneSettingMenu] =
    React.useState(null);
  const [limit, setLimit] = useState(15);
  const handleClosePhoneSetting = () => {
    setAnchorElPhoneSettingMenu(null);
  };

  const onCloseAppointmentPopup = () => {
    setAppointmentPopup(false);
    setAppointmentForEdit(null);
  };

  const classes = useStyles();

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const makeACall = () => {
    let tmp = {};

    tmp = {
      ...pbxConfig,
      makeACall: true,
    };

    if (pbxConfig.callToPhone || pbxConfig.callToName) {
      tmp.callToPhone = null;
      tmp.callToName = null;
    }

    dispatch(updatePbxInfo(tmp));
  };

  const receivingControl = () => {
    let tmp = {};

    setReceivingControlManual(true);
    if (
      (localStorage.getItem("registered") === "1" &&
        pbxConfig.receive_active) ||
      (!localStorage.getItem("registered") && !pbxConfig.receive_active)
    ) {
      tmp = {
        ...pbxConfig,
        receive_active: pbxConfig.receive_active ? false : true,
        callCheckOutput: pbxConfig.receive_active
          ? false
          : pbxConfig.callCheckOutput,
        callCheckInpup: pbxConfig.receive_active
          ? false
          : pbxConfig.callCheckInput,
        callCheckMedia: pbxConfig.receive_active
          ? false
          : pbxConfig.callCheckMedia,
        callCheckNotification: pbxConfig.receive_active
          ? false
          : pbxConfig.callCheckNotification,
        reciebingControlManual: true,
      };

      dispatch(updatePbxInfo(tmp));
    }
  };

  const callCheckRequirements = () => {
    let tmp = {};

    tmp = {
      ...pbxConfig,
      checkCallReq: true,
    };

    dispatch(updatePbxInfo(tmp));
  };

  // const getAllNotifications = async () => {
  //   try {
  //     const { data } = await Api.getUserNotifications(
  //       authUser ? authUser.id : 0
  //     );

  //     setNotData(data);
  //     setCount(0);
  //   } catch {
  //     console.error("getUserNotifications error");
  //   }
  // };

  const toggleDrawerInternalChat = () => {
    if (window.Echo && window.location.pathname != "/internalchat") {
      window.Echo.disconnect();

      //  window.Echo = undefined;
    }

    setOpenInternalChat(false);
  };
  const onOpenInternalChat = () => {
    setOpenInternalChat(true);
  };

  const getCountOfMissedCallsOnly = async () => {
    const { data } = await Api.getCountOfMissedCalls();

    dispatch(createMissedCallsService(data));
    // setCallsMissed(data);
  };

  const getUserNotificationsTypes = async () => {
    const { data } = await Api.getUserNotificationsTypes(authUser.id);
    setUserNotificationsTypes(data);
    // setCallsMissed(data);
  };

  // const getCountOfMissedCalls = async () => {
  //   const { data } = await Api.getCountOfMissedCalls();
  //   setCallsMissed(data);

  //   let tmp = {};

  //   tmp = {
  //     ...pbxConfig,
  //     missedCallsCount: data,
  //   };

  //   dispatch(updatePbxInfo(tmp));
  // };

  // const getNotifications = async () => {
  //   try {
  //     const { data } = await Api.getUserNotificationsNoRead(
  //       authUser ? authUser.id : 0
  //     );

  //     if (data.length > 0) {
  //       setNotData(data);
  //       setCount(data.length);
  //     } else getAllNotifications();
  //   } catch {
  //     console.error("getUserNotificationsNoRead error");
  //   }
  // };

  const handleSelectNotificationType = (type) => {
    setSelectedNotificationsType(type);
    if (!isEmpty(authUser) && !portal) {
      dataSliceLiveNotifications.orgId = authUser.id;
      dataSliceLiveNotifications.getFilters.filters = {
        notification_type: type,
      };
      dataSliceLiveNotifications.getFilters.limit = 15;
      dispatch(fetchLiveNotificationsService(dataSliceLiveNotifications));
    }
  };

  const setNotificationView = async () => {
    try {
      setLoadingSetAllAsRead(true);
      const { data } = await Api.setUserViewNotificationsGroup(
        authUser ? authUser.id : 0
      );
      dispatch(addUnreadToPaginationEntityService(0));
      setCount(0);
      setNotificationsUnreadByType([]);
      setNotData([...notData.map((not) => ({ ...not, ...{ view: 1 } }))]);
      setLoadingSetAllAsRead(false);
      localStorage.removeItem("live_notification_data");
    } catch {
      console.error("setUserViewNotificationsGroup error");
    }
  };

  const EditAppointment = async (payload, file) => {
    if (appointmentForEdit) {
      await Api.updateNewAppointment(appointmentForEdit.id, payload);

      if (file) {
        Api.attachToAppointment(appointmentForEdit.id, file);
      }

      onCloseAppointmentPopup();
    }
  };

  const getAppointmentForEdit = async (id) => {
    const { data } = await Api.getAppointmentNew(id);

    setAppointmentForEdit(data.data);

    setAppointmentPopup(true);
  };

  const getPaymentForEdit = async (item) => {
    /* Retrieve the invoice in order to access the payment */
    if (item.invoice_id) {
      setIsloading(true);
      const { data } = await Api.getInvoice(item.invoice_id);
      setInvoiceDetail(data.data);
      setIsloading(false);
      setOpenInvoicePopup(true);
    }
  };

  const renderNotificationDataNew = (data, type, notification) => {
    const dataLocal = JSON.parse(data);

    switch (type) {
      case "contact":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Contact">
                      <IconButton
                        color="inherit"
                        aria-label="open-Contact"
                        onClick={() => {
                          documentsTabRef.current = dataLocal[0].documentsTab;
                          setNotify({
                            isOpen: false,
                            message: "",
                            type: "",
                          });
                          item.id ? openContactSavedAlert(item.id) : "";
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="mingcute:phone-fill"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Phone: ${item.phone}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="eva:email-fill"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Email: ${item.email}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "ticket":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Ticket">
                      <IconButton
                        color="inherit"
                        aria-label="open-Ticket"
                        onClick={() => {
                          documentsTabRef.current = dataLocal[0].documentsTab;
                          setNotify({
                            isOpen: false,
                            message: "",
                            type: "",
                          });
                          item.id ? openTicketSavedAlert(item.id) : "";
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="majesticons:text-line"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Subject: ${item.subject}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        className={classes.iconProps}
                        icon="mdi:state-machine"
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Status: ${item.status}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "appointment":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Appointment">
                      <IconButton
                        color="inherit"
                        aria-label="open-Ticket"
                        onClick={() => {
                          getAppointmentForEdit(item.id);
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={25}
                          height={25}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        className={classes.iconProps}
                        icon="bxs:calendar-event"
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Date: ${item.date}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        className={classes.iconProps}
                        icon="ic:round-person"
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Contact/Patient: ${item.contact}`}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        className={classes.iconProps}
                        icon="jam:medical"
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Type: ${item.type_of_appointment}`}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        className={classes.iconProps}
                        icon="mdi:state-machine"
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Status: ${item.status?.replace(
                          /^\w/,
                          (c) => c.toUpperCase() || "-"
                        )}`}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        className={classes.iconProps}
                        icon="carbon:user-filled"
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Assigned to: ${item.doctor}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });

      case "picture":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              {item.url ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: 300, marginTop: 23 }}
                    src="https://venngage-wordpress.s3.amazonaws.com/uploads/2022/08/10-Concept-Map-Examples-to-Showcase-Your-Ideas-2048x1152.png"
                  />
                </div>
              ) : (
                ""
              )}
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Contact">
                      <IconButton
                        color="inherit"
                        aria-label="open-Contact"
                        onClick={() => {
                          documentsTabRef.current = dataLocal[0].documentsTab;
                          setNotify({
                            isOpen: false,
                            message: "",
                            type: "",
                          });
                          item.id ? openContactSavedAlert(item.id) : "";
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="mingcute:phone-fill"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Phone: ${item.phone}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="eva:email-fill"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Email: ${item.email}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "payment":
        return dataLocal.map((item, index) => {
          console.log("itemmm123 ", item);
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item ? (
                    <Tooltip title="View Payment">
                      <IconButton
                        color="inherit"
                        aria-label="open-Payment"
                        onClick={() => {
                          getPaymentForEdit(item);
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={25}
                          height={25}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                // title={
                //   <Typography
                //     style={{
                //       color: "rgb(62 101 141)",
                //       fontSize: 14,
                //       fontWeight: 600,
                //     }}
                //   >
                //     Payment Details
                //   </Typography>
                // }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      {/* <Iconify
                        className={classes.iconProps}
                        icon="ph:money"
                      /> */}
                      {item.paid_by ? (
                        <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                          {` Paid By: ${item.paid_by}`}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      {/* <Iconify
                        className={classes.iconProps}
                        icon="ph:money"
                      /> */}

                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {` Paid Amount: $${item.paid_amount}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "refunds_tickets":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Ticket">
                      <IconButton
                        color="inherit"
                        aria-label="open-Ticket"
                        onClick={() => {
                          setNotify({
                            isOpen: false,
                            message: "",
                            type: "",
                          });
                          item.id ? openRefundTicket(item.id) : "";
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="solar:dollar-bold"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Amount: $${item.amount}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="mdi:alert-rhombus"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Priority: ${item.priority}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "refunds_task":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Task">
                      <IconButton
                        color="inherit"
                        aria-label="open-Task"
                        onClick={() => {
                          setNotify({
                            isOpen: false,
                            message: "",
                            type: "",
                          });
                          item.id ? openTask(item.id) : "";
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="mdi:stopwatch-start-outline"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Start date: ${
                          item.start_date
                            ? moment(item.start_date).format("MM-DD-YYYYY")
                            : "-"
                        }`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="healthicons:i-schedule-school-date-time"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Due date: ${
                          item.due_date
                            ? moment(item.due_date).format("MM-DD-YYYYY")
                            : "-"
                        }`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="mdi:alert-rhombus"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Priority: ${item.priority}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "patient":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Patient">
                      <IconButton
                        color="inherit"
                        aria-label="open-Patient"
                        onClick={() => {
                          openRecord(item.id);
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={
                  <Typography
                    style={{
                      color: "rgb(62 101 141)",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {item.patient_name}
                  </Typography>
                }
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="mingcute:phone-fill"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Phone: ${item.phone}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Iconify
                        icon="eva:email-fill"
                        className={classes.iconProps}
                      />
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Email: ${item.email}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
      case "payment_link_request":
        return dataLocal.map((item, index) => {
          return (
            <Card className={classes.accordionDetails}>
              <CardHeader
                action={
                  item.id ? (
                    <Tooltip title="Open Request">
                      <IconButton
                        color="inherit"
                        aria-label="open-Request"
                        onClick={() => {
                          setNotify({
                            isOpen: false,
                            message: "",
                            type: "",
                          });
                          item.id ? openPaymentLinkRequest(item.id) : "";
                        }}
                      >
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          style={{ color: "rgb(62 101 141)" }}
                          width={23}
                          height={23}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )
                }
                title={""}
                subheader={
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Requested by: ${item?.requested_by || ""}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`Requested at: ${item?.requested_at || ""}`}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#434644",
                      }}
                    >
                      <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                        {`To contact: ${item.contact}`}
                      </Typography>
                    </div>
                  </>
                }
              />
            </Card>
          );
        });
    }

    return "";
  };

  const closePopup = () => {
    setVisible(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { userContactsSMSMMS } = useSelector(
    (state) => state.userContactsSMSMMS
  );

  const { userContactsSMSMMSByGroups } = useSelector(
    (state) => state.userContactsSMSMMSByGroups
  );

  /*  const updateNotifications = (data) => {
    // getNotifications();
  }; */
  const [totalMessagesUnread, setTotalMessagesUnread] = React.useState(0);
  // useInterval(() => {
  //   if (
  //     authUser &&
  //     authUser.organization &&
  //     authUser.organization.data &&
  //     authUser.organization.data.pbx_integration === 1 &&
  //     authUser.organization.data.pbx_integration_port &&
  //     pbxConfig.aor
  //   ) {
  //     getCountOfMissedCallsOnly();
  //   }
  // }, 60000);

  const updateTicketComment = (data) => {
    dispatch(updateOnlyTiketService(data));
  };

  const addOrEditTicketSuccefull = () => {
    showMessage(setNotify, "Submitted Successfully");
    setEntityForEdit(null);
    setOpenPopup(false);
    setOpenPopupEdit(false);
  };

  const addOrEditTicket = (data) => {
    let formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    if (data.file === null) {
      formData.delete("file");
    }
    if (data.contact_id === null) {
      formData.delete("contact_id");
    }
    formData.delete("users_notifications");

    if (ticketDetail) {
      dataSliceTickets.dataUpdate = {
        id: ticketDetail.id,
        data: formData,
        successHandle: addOrEditTicketSuccefull,
      };
      dispatch(updateTicketsService(dataSliceTickets));
    } else {
      if (!isEmpty(authUser)) {
        formData.append("organization_id", authUser.organization_id);
        dataSliceTickets.dataCreate.data = formData;
        dataSliceTickets.dataCreate.successHandle = addOrEditSuccefull;
        dispatch(createTicketsService(dataSliceTickets));
      }
    }
  };

  useEffect(() => {
    let total = 0;
    if (!isEmpty(userContactsSMSMMS)) {
      total = userContactsSMSMMS
        .concat(userContactsSMSMMSByGroups)
        .reduce((total, item) => total + item.unread, 0);
    }
    setTotalMessagesUnread(total);
  }, [userContactsSMSMMS, userContactsSMSMMSByGroups]);

  useEffect(() => {
    setCallsMissed(missedCallsCount);
  }, [missedCallsCount]);

  useEffect(() => {
    if (window.Echo && window.location.pathname != "/internalchat") {
      window.Echo.disconnect();
    }

    let isMounted = true;

    //executes at start and with every timer
    // if (notData.length === 0) getNotifications();

    /* if (pbxConfig && pbxConfig.aor) { */
    getCountOfMissedCallsOnly();
    /* } */

    async function gUserNotificationsTypes() {
      const { data } = await Api.getUserNotificationsTypes(authUser.id);
      if (isMounted) {
        setUserNotificationsTypes(data);
      }
    }

    // getUserNotificationsTypes();
    gUserNotificationsTypes();

    return () => {
      isMounted = false; // set flag to false when component unmounts
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(authUser)) {
      if (!portal) {
        dataSliceLiveNotifications.orgId = authUser.id;
        dataSliceLiveNotifications.getFilters.filters = {
          notification_type: selectedNotificationsType,
        };
        dispatch(fetchLiveNotificationsService(dataSliceLiveNotifications));
      }

      if (isEmpty(departmentsNew) || !departmentsNewLoading)
        dispatch(fetchDepartmentsNewService(dataSliceDepartmentsNew));

      if (isEmpty(appointmentTypes)) {
        dispatch(fetchAppointmentTypesService(dataSliceAppointmentTypes));
      }

      if (isEmpty(usersDropdown)) {
        dispatch(fetchUsersServiceDropdown(authUser.organization_id));
      }

      if (isEmpty(tags)) dispatch(fetchTagsService(authUser.organization_id));
    }
  }, [authUser]);

  // useEffect(() => {
  //   if (visible === true) setNotificationView();
  // }, [visible]);

  useEffect(() => {
    if (liveNotifications) {
      setNotData(liveNotifications);
      setIsloading(false);
    }
  }, [liveNotifications]);

  useEffect(() => {
    if (liveNotificationsPagination) {
      setCount(liveNotificationsPagination.unread);
      setNotificationsUnreadByType(liveNotificationsPagination.countByType);
    }
  }, [liveNotificationsPagination]);

  useEffect(() => {
    if (contactSaved) {
      showMessage(
        setNotify,
        <ListItem
          button
          onClick={(e) => {
            openContactSaved();
          }}
          style={{ padding: 30 }}
        >
          <ListItemText primary="You can see the saved contact here" />
        </ListItem>,
        "success"
      );

      setContactSaved(false);
    }
  }, [contactSaved]);

  const updateStatus = async (id, status) => {
    const payload = {
      status: status,
    };
    const { data } = await Api.updateClickToCall(id, payload);

    dispatch(updateOnlyUserService(data));
  };

  useEffect(() => {
    if (
      pbxConfig &&
      pbxConfig.aor &&
      pbxConfig.receive_calls &&
      !pbxConfig.receive_active &&
      !pbxConfig.secondary &&
      !receivingControlManual &&
      !pbxConfig.reciebingControlManual
    )
      receivingControl();
  }, [pbxConfig]);

  const statusOptions = () => {
    return [
      { name: "Web Phone", id: "web_phone" },
      { name: "External Phone", id: "external_phone" },
    ];
  };

  const [status, setStatus] = React.useState(authUser.click_to_call);

  const [clickToCallExternal, setClickToCallExternal] = React.useState(
    authUser?.click_to_call_external || false
  );

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onCloseRecord = () => {
    setOpenRecordPopup(false);
  };

  const openRecord = async (id) => {
    setIsLoadingRecord(true);
    const { data } = await Api.getRecord(id);

    setRecordDetail(data.data);
    setOpenRecordPopup(true);
    setIsLoadingRecord(false);
  };

  const markNotfAsView = async (notf) => {
    await Api.setUserViewNotifications(authUser.id, notf.id);

    const index = findIndex(notData, ["id", notf.id]);
    const updatedEvent = {
      ...notData[index],
      ...{ view: 1 },
    };

    let newnotf = [...notData];
    newnotf[index] = updatedEvent;
    setNotData([...newnotf]);

    setCount(count - 1);

    const index2 = findIndex(notificationsUnreadByType, [
      "notification_type",
      notf.notification_type,
    ]);

    if (index2 != -1) {
      const updatedEvent2 = {
        ...notificationsUnreadByType[index2],
        ...{
          total:
            notificationsUnreadByType[index2].total - 1 == 0
              ? null
              : notificationsUnreadByType[index2].total - 1,
        },
      };

      let newNotificationsUnreadBytype = [...notificationsUnreadByType];
      newNotificationsUnreadBytype[index2] = updatedEvent2;
      setNotificationsUnreadByType([...newNotificationsUnreadBytype]);
    }
  };

  // useEffect(() => {
  //   console.log(
  //     "generalTotalUnreadChatMessages: ",
  //     generalTotalUnreadChatMessages
  //   );
  // }, [generalTotalUnreadChatMessages]);

  return (
    <React.Fragment>
      <Popover
        // title={`Notifications`}
        open={open}
        onClose={closePopup}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            marginTop: 50,
            maxWidth: "fit-content",
            minWidth: "fit-content",
            right: 0,
            left: "1528px",
            marginRight: 15,
            border: "1px solid #e6e6e663",
          },
        }}
      >
        <List className={classes.root}>
          <div
            style={{
              display: "flex",
              paddingRight: "10px",
              alignItems: "center",
            }}
          >
            {" "}
            <center
              style={{
                padding: "2px 12px",
                background: "#e5f1fb",
                borderRadius: "0px 12px 12px 0px",
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: 17 }}>
                Notifications
              </Typography>
            </center>
            <div
              style={{
                display: "grid",
                justifyContent: "right",
                width: "100%",
              }}
            >
              <Button
                disabled={loadingSetAllAsRead}
                color="primary"
                onClick={setNotificationView}
                endIcon={<Iconify icon="mdi:check-all" />}
                startIcon={
                  loadingSetAllAsRead ? (
                    <Iconify icon="eos-icons:three-dots-loading" />
                  ) : undefined
                }
              >
                {"Mark all as read"}
              </Button>
            </div>
          </div>
          <div className={classes.notificationsGroup}>
            <Tooltip title={"All notifications"}>
              <IconButton onClick={() => handleSelectNotificationType(null)}>
                <Iconify
                  icon={
                    selectedNotificationsType == null
                      ? "fluent:apps-list-detail-24-filled"
                      : "fluent:apps-list-detail-24-regular"
                  }
                  className={
                    selectedNotificationsType == null
                      ? classes.smallIconSelected
                      : classes.smallIcon
                  }
                />
              </IconButton>
            </Tooltip>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: "370px",
              }}
            >
              {userNotificationsTypes.length > 0 &&
                userNotificationsTypes.map((user_notification_type) => {
                  let icon = null;
                  let selectedIcon = null;
                  let tooltip = "";
                  switch (user_notification_type) {
                    case "contact":
                      icon = "fluent:book-contacts-32-regular";
                      selectedIcon = "fluent:book-contacts-28-filled";
                      tooltip = "Contacts Notifications";
                      break;
                    case "ticket":
                      icon = "fluent:ticket-diagonal-24-regular";
                      selectedIcon = "fluent:ticket-diagonal-24-filled";
                      tooltip = "Tickets Notifications";
                      break;
                    case "appointment":
                      icon = "ph:calendar-blank-duotone";
                      selectedIcon = "ph:calendar-blank-fill";
                      tooltip = "Appointments Notifications";
                      break;
                    case "payment":
                      icon = "fluent:payment-20-regular";
                      selectedIcon = "fluent:payment-20-filled";
                      tooltip = "Payments Notifications";
                      break;
                    case "picture":
                      icon = "bi:file-image";
                      selectedIcon = "bi:file-image-fill";
                      tooltip = "Pictures notifications";
                      break;
                    case "refunds_tickets":
                      icon = "ri:refund-line";
                      selectedIcon = "ri:refund-fill";
                      tooltip = "Refund tickets";
                      break;
                    case "refunds_task":
                      icon = "mingcute:task-line";
                      selectedIcon = "mingcute:task-fill";
                      tooltip = "Tasks";
                      break;
                    case "payment_link_request":
                      icon = "fluent:contact-card-link-16-regular";
                      selectedIcon = "fluent:contact-card-link-16-filled";
                      tooltip = "Payment Link Request";
                      break;
                    case "patient":
                      icon = "streamline:health-care-2";
                      selectedIcon = "streamline:health-care-2-solid";
                      tooltip = "Patients Notifications";
                      break;
                    case "system":
                      icon = "icon-park-outline:computer";
                      selectedIcon = "icon-park-solid:computer";
                      tooltip = "System";
                      break;

                    default:
                      icon = "ic:round-notifications-none";
                      selectedIcon = "ic:round-notifications";
                      tooltip = user_notification_type;
                      break;
                  }

                  var countType = notificationsUnreadByType
                    ? notificationsUnreadByType.find(
                        (a) => a.notification_type == user_notification_type
                      )
                    : null;

                  return (
                    <Tooltip title={tooltip ? tooltip : ""}>
                      <StyledBadge
                        badgeContent={countType?.total || 0}
                        color="primary"
                      >
                        {" "}
                        <IconButton
                          style={{ padding: 5 }}
                          onClick={() =>
                            handleSelectNotificationType(user_notification_type)
                          }
                        >
                          <Iconify
                            icon={
                              selectedNotificationsType ==
                              user_notification_type
                                ? selectedIcon
                                : icon
                            }
                            className={
                              selectedNotificationsType ==
                              user_notification_type
                                ? classes.smallIconSelected
                                : classes.smallIcon
                            }
                          />
                        </IconButton>
                      </StyledBadge>
                    </Tooltip>
                  );
                })}
            </div>
          </div>
          <div
            id="scrollableDiv1"
            style={{
              marginTop: 20,
            }}
          >
            {isLoading && <LinearProgress />}
            <ScrollBar className={classes.scroll} style={{ maxHeight: 700 }}>
              <div>
                {notData.length > 0 ? (
                  notData.map((notification) => {
                    return (
                      <>
                        {
                          <Box style={{ maxWidth: "100%" }}>
                            <Accordion
                              style={{
                                backgroundColor:
                                  notification &&
                                  (notification.view == 1 ||
                                    notification.view == "1")
                                    ? "white"
                                    : "#f8f9fb",
                                width: 408,
                              }}
                              square
                              expanded={expanded === notification.id}
                              onChange={handleChange(notification.id)}
                            >
                              <AccordionSummary
                                style={{
                                  backgroundColor:
                                    notification &&
                                    (notification.view == 1 ||
                                      notification.view == "1")
                                      ? "white"
                                      : "#f8f9fb",
                                }}
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <div style={{ display: "flex", width: "100%" }}>
                                  <div style={{ width: "100%" }}>
                                    <Tooltip
                                      title={
                                        expanded === notification.id
                                          ? "Click here for less information"
                                          : "Click here for more information"
                                      }
                                    >
                                      <CardHeader
                                        style={{
                                          padding: 6,
                                        }}
                                        avatar={
                                          <Avatar
                                            style={{
                                              backgroundColor: "#00000012",
                                            }}
                                          >
                                            <Iconify
                                              icon={
                                                notification
                                                  ? notification.notification_type ==
                                                    "payment_link_request"
                                                    ? "fluent:contact-card-link-16-filled"
                                                    : notification.notification_type ==
                                                      "contact"
                                                    ? "fluent:person-16-filled"
                                                    : notification.notification_type ==
                                                      "appointment"
                                                    ? "heroicons-solid:calendar"
                                                    : notification.notification_type ==
                                                      "ticket"
                                                    ? "heroicons:ticket-solid"
                                                    : notification.notification_type ==
                                                      "patient"
                                                    ? "streamline:health-care-2-solid"
                                                    : "octicon:bell-fill-24"
                                                  : "octicon:bell-fill-24"
                                              }
                                              style={{
                                                color: "rgb(12 187 130)",
                                              }}
                                              width={25}
                                              height={25}
                                            />
                                          </Avatar>
                                        }
                                        title={
                                          <Typography
                                            id="title"
                                            style={{
                                              color: "#355174",
                                              fontSize: 15,
                                              fontWeight:
                                                notification &&
                                                (notification.view == 0 ||
                                                  notification.view == "0")
                                                  ? 600
                                                  : 400,
                                            }}
                                          >
                                            {notification?.text ||
                                              "System Notification"}
                                          </Typography>
                                        }
                                        subheader={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              margin: 4,
                                            }}
                                          >
                                            <Iconify
                                              style={{
                                                marginRight: 4,
                                                color: "#8295a9",
                                              }}
                                              icon="tabler:clock-filled"
                                            />
                                            <Typography
                                              id="subheader"
                                              style={{ fontSize: 12 }}
                                            >
                                              {notification.created_at
                                                ? moment(
                                                    notification.created_at
                                                  ).format("YYYY-MM-DD") +
                                                  " at " +
                                                  moment(
                                                    notification.created_at
                                                  ).format("hh:mm:ss A")
                                                : ""}
                                            </Typography>
                                          </div>
                                        }
                                      />
                                    </Tooltip>
                                  </div>

                                  {notification &&
                                  (notification.view == 0 ||
                                    notification.view == "0") ? (
                                    <div style={{ maxWidth: "min-content" }}>
                                      <Chip
                                        className={classes.chipStyle}
                                        label={`New`}
                                        variant="outlined"
                                        size="small"
                                      ></Chip>

                                      {notification &&
                                      (notification.view == 0 ||
                                        notification.view == "0") ? (
                                        <Tooltip title="Mark as read">
                                          <IconButton
                                            color="primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              markNotfAsView(notification);
                                            }}
                                          >
                                            <Iconify icon="mdi:check-all" />
                                          </IconButton>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </AccordionSummary>

                              {expanded &&
                              notification &&
                              expanded == notification.id ? (
                                <AccordionDetails className={classes.details}>
                                  {notification.data.length > 0
                                    ? renderNotificationDataNew(
                                        notification.data,
                                        notification.notification_type,
                                        notification
                                      )
                                    : ""}
                                </AccordionDetails>
                              ) : (
                                ""
                              )}
                            </Accordion>
                          </Box>
                        }
                        {/* <ListItem alignItems="flex-start" key={notification.id}>
                    <ListItemAvatar>
                      <Avatar
                        alt="System"
                        src="/static/img/avatars/system_notification.jpg"
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={notification.text + " "}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            System
                          </Typography>
                          {" " +
                            notification.created_at
                              .split(".")[0]
                              .replace("T", " ")}
                          <div>
                            {notification.data.length > 0
                              ? renderNotificationData(notification.data)
                              : ""}
                          </div>
                        </React.Fragment>
                      }
                    />
                  </ListItem> */}
                        {/*
                    <Divider
                      variant="middle"
                      component="li"
                      style={{
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    />
                    */}
                      </>
                    );
                  })
                ) : (
                  <>
                    <Card style={{ boxShadow: "none", width: 395 }}>
                      <CardHeader
                        avatar={
                          <Iconify
                            icon={"ci:notification-active"}
                            style={{ color: "#A09E9E" }}
                            width={25}
                            height={25}
                          />
                        }
                        title={
                          <Typography id="innerTitle" style={{ fontSize: 16 }}>
                            {"No Notifications to Show"}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            id="innerSubheader"
                            style={{ fontSize: 12 }}
                          >
                            {"System"}
                          </Typography>
                        }
                      />
                    </Card>
                    {/* <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="System"
                    src="/static/img/avatars/system_notification.jpg"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={"No Notifications to Show"}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        System
                      </Typography>
                      {""}
                    </React.Fragment>
                  }
                />
              </ListItem> */}
                  </>
                )}
                {notData.length > 0 ? (
                  <ListItem
                    onClick={() => {
                      setOpenAllNotifications(true);
                      setVisible(false);
                      setAnchorEl(null);
                    }}
                    button
                    style={{ justifyContent: "center", color: "#227495" }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      VIEW MORE
                    </Typography>
                  </ListItem>
                ) : (
                  ""
                )}
              </div>
            </ScrollBar>
          </div>
        </List>
      </Popover>

      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            {isViewIcons && (
              <Logo
                src={
                  authUser.building_id
                    ? authUser.building_logo
                    : authUser.organization.data.menu_logo
                }
              />
            )}

            <BookmarkButton />
            {!isPC && (
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}

            {/*<Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>*/}
            <Grid item>
              {
                <Search>
                  {/*
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input placeholder="Search patient" fullWidth />
                  */}
                </Search>
              }
            </Grid>

            {<Grid item xs></Grid>}

            <Button
              onClick={handleClickFavoriteSites}
              key={"favorites"}
              variant="outlined"
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "125px",
                marginRight: 15,
              }}
            >
              Favorites
              {!anchorElFavoriteSites && (
                <ArrowDropDownIcon style={{ color: "gray" }} />
              )}
              {anchorElFavoriteSites && (
                <ArrowDropUpIcon style={{ color: "gray" }} />
              )}
            </Button>

            {clickToCallExternal ? (
              <Tooltip title="Default Call From">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick2}
                  key={"StatusButton2"}
                  style={{
                    backgroundColor: !anchorEl2 ? "white" : "#f5f3f3",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "rgb(192 192 192)",
                    color: "black",
                    borderRadius: "30px",
                    height: "40px",
                    width: "150px",
                    marginRight: "20px",
                    marginLeft: "5px",
                  }}
                >
                  {statusOptions().find((a) => a.id == status).name}

                  {!anchorEl2 && (
                    <ArrowDropDownIcon style={{ color: "gray" }} />
                  )}
                  {anchorEl2 && <ArrowDropUpIcon style={{ color: "gray" }} />}
                </Button>
              </Tooltip>
            ) : (
              ""
            )}

            <Menu
              style={{ marginLeft: 6, marginTop: 36.5 }}
              id="simple-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose2}
              key={"SeMenus"}
            >
              {statusOptions().map((name) => (
                <MenuItem
                  key={name.id}
                  value={name.id}
                  onClick={() => {
                    handleClose2();
                    updateStatus(authUser.id, name.id);
                    setStatus(name.id);
                  }}
                >
                  <ListItemText>
                    {name.id == "available" && (
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "16",
                          color: "rgba(35, 208, 8)",
                          marginRight: "5px",
                        }}
                      />
                    )}
                    {name.id == "busy" && (
                      <RemoveCircleIcon
                        style={{
                          fontSize: "16",
                          color: "rgba(240, 9, 9)",
                          marginRight: "5px",
                        }}
                      />
                    )}
                    {name.id == "lunch" && (
                      <TimerIcon
                        style={{
                          fontSize: "16",
                          color: "rgba(0, 37, 208)",
                          marginRight: "5px",
                        }}
                      />
                    )}
                    {name.id == "out-of-desk" && (
                      <DesktopAccessDisabledIcon
                        style={{
                          fontSize: "16",
                          color: "#7E878C",
                          marginRight: "5px",
                        }}
                      />
                    )}
                    {name.name}
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>

            <Button
              onClick={handleClickGoTo}
              key={"goto"}
              variant="outlined"
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "110px",
                marginRight: 15,
              }}
            >
              Go To
              {!anchorElGoTo && <ArrowDropDownIcon style={{ color: "gray" }} />}
              {anchorElGoTo && <ArrowDropUpIcon style={{ color: "gray" }} />}
            </Button>

            <Menu
              id="menu-goto"
              anchorEl={anchorElGoTo}
              keepMounted
              open={Boolean(anchorElGoTo)}
              onClose={handleCloseGoTo}
              PaperProps={{
                style: {
                  marginTop: 50,
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {CRMUtils.userHasPermission(authUser, ["user_directory"]) && (
                <MenuItem
                  key={"usersDirectory"}
                  onClick={() => {
                    handleCloseGoTo();
                    history.push(`/directory`);
                  }}
                >
                  <ListItemIcon>
                    <Iconify
                      icon={"fluent:people-32-filled"}
                      style={{ color: "#009688" }}
                      width={32}
                      height={32}
                    />
                  </ListItemIcon>
                  <Typography variant="inherit" style={{ color: "#009688" }}>
                    Users' Directory
                  </Typography>
                </MenuItem>
              )}

              {CRMUtils.userHasPermission(authUser, ["products_directory"]) && (
                <MenuItem
                  key={"ProductsServicesList"}
                  onClick={() => {
                    handleCloseGoTo();
                    history.push(`/products-list`);
                  }}
                >
                  <ListItemIcon>
                    <div style={{ display: "flex", position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 10,
                          top: 8,
                          left: 22,
                          fontSize: 20,
                        }}
                      >
                        <Iconify
                          icon={"f7:money-dollar"}
                          style={{ color: "#009688" }}
                          width={18}
                          height={18}
                        />
                      </div>
                      <Iconify
                        icon={"fluent:shopping-bag-tag-24-filled"}
                        style={{ color: "#009688" }}
                        width={25}
                        height={25}
                      />
                    </div>
                  </ListItemIcon>
                  <Typography variant="inherit" style={{ color: "#009688" }}>
                    Products/Services List
                  </Typography>
                </MenuItem>
              )}
            </Menu>

            <Button
              onClick={handleClickNew}
              key={"New"}
              variant="outlined"
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "100px",
                marginRight: 15,
              }}
            >
              New
              {!anchorElNew && <ArrowDropDownIcon style={{ color: "gray" }} />}
              {anchorElNew && <ArrowDropUpIcon style={{ color: "gray" }} />}
            </Button>

            <Menu
              id="menu-new"
              anchorEl={anchorElNew}
              keepMounted
              open={Boolean(anchorElNew)}
              onClose={handleCloseNew}
              PaperProps={{
                style: {
                  marginTop: 50,
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                key={"NewContact"}
                onClick={() => {
                  setOpenPopupNewContact(true);
                  handleCloseNew();
                }}
              >
                <ListItemIcon>
                  <Iconify
                    icon={"fluent:person-48-filled"}
                    style={{ color: "#009688" }}
                    width={25}
                    height={25}
                  />
                </ListItemIcon>
                <Typography variant="inherit" style={{ color: "#009688" }}>
                  Contact
                </Typography>
              </MenuItem>
              <MenuItem
                key={"NewTicket"}
                onClick={() => {
                  const payload = {
                    id: 1,
                    name: "newTicket",
                  };
                  dispatch(createNewItemService(payload));
                  handleCloseNew();
                  history.push(`/mytickets`);
                }}
              >
                <ListItemIcon>
                  <Iconify
                    icon={"mdi:ticket-confirmation"}
                    style={{ color: "#2196f3" }}
                    width={25}
                    height={25}
                  />
                </ListItemIcon>
                <Typography variant="inherit" style={{ color: "#2196f3" }}>
                  Ticket
                </Typography>
              </MenuItem>
              {
                //MMS HERE
              }
              <MenuItem
                key={"NewSMS"}
                onClick={() => {
                  setOpenNewSMS(true);
                  onChatDrawerToggle();
                  handleCloseNew();
                  // const payload = {
                  //    id: 1,
                  //     name: "NewSMS",
                  //     };
                  //</Menu>   dispatch(createNewItemService(payload));
                  //     history.push(`/messagessms`);
                  //    handleCloseNew();
                }}
              >
                <ListItemIcon>
                  <Iconify
                    icon={"eva:message-square-fill"}
                    style={{ color: "#3f51b5" }}
                    width={25}
                    height={25}
                  />
                </ListItemIcon>
                <Typography variant="inherit" style={{ color: "#3f51b5" }}>
                  SMS
                </Typography>
              </MenuItem>
            </Menu>

            {/*
            <Button
              onClick={() => {
                setOpenPopupNewContact(true);

                  const payload = {
                  id: 1,
                  name: "newContact",
                };
                dispatch(createNewItemService(payload));

                // if (CRMUtils.userHasPermission(authUser, ["menu_salesMenu"])) {
                //   history.push(`/sales-contacts`);
                // } else if (
                //   CRMUtils.userHasPermission(authUser, [
                //     "menu_coordinationMenu",
                //   ])
                // ) {
                //   history.push(`/coordination-contacts`);
                // } else if (
                //   CRMUtils.userHasPermission(authUser, ["menu_callCenterMenu"])
                // ) {
                //   history.push(`/callcenter-contacts`);
                // } else if (
                //   CRMUtils.userHasPermission(authUser, ["menu_frontdeskMenu"])
                // ) {
                //   history.push(`/frontdesk-contacts`);
                // } else if (
                //   CRMUtils.userHasPermission(authUser, ["menu_socialmediaMenu"])
                // ) {
                //   history.push(`/socialmedia-contacts`);
                // } else if (
                //   CRMUtils.userHasPermission(authUser, ["menu_billingMenu"])
                // ) {
                //   history.push(`/billing-contacts`);
                // } else if (
                //   CRMUtils.userHasPermission(authUser, ["menu_CRMMenu"])
                // ) {
                //   history.push(`/contacts`);
                // } 
              }}
              key={"NewContact"}
              variant="outlined"
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "140px",
                marginRight: 15,
              }}
            >
              New Contact
            </Button>

            <Button
              onClick={() => {
                const payload = {
                  id: 1,
                  name: "newTicket",
                };
                dispatch(createNewItemService(payload));
                history.push(`/mytickets`);
              }}
              key={"NewTicket"}
              variant="outlined"
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "140px",
                marginRight: 15,
              }}
            >
              New Ticket
            </Button>
            <Button
              onClick={() => {
                const payload = {
                  id: 1,
                  name: "NewSMS",
                };
                dispatch(createNewItemService(payload));
                history.push(`/messagessms`);
              }}
              key={"NewSMS"}
              variant="outlined"
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "140px",
                marginRight: 15,
              }}
            >
              New SMS
            </Button>
*/}
            <Clock />
            <UserStatusList authUser={authUser} />

            <Grid item>
              {/* <Tooltip title="Show my favorites">
                <span>
                  <IconButton onClick={handleClickFavoriteSites}>
                    <Iconify
                      style={{ color: green[700] }}
                      icon={"teenyicons:star-circle-solid"}
                    />
                  </IconButton>
                </span>
              </Tooltip> */}

              <LiveNotifications
                unseenNotification={unseenNotification}
                setUnseenNotification={setUnseenNotification}
                handleSelectNotificationType={handleSelectNotificationType}
                setVisible={setVisible}
                setAnchorEl={setAnchorEl}
                // getNotifications={getNotifications}
                useInterval={useInterval}
                classes={classes}
                count={count}
                visible={visible}
              />

              {authUser &&
              authUser.enable_chat &&
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.enable_websockets ? (
                <Tooltip title="Internal chat">
                  <span>
                    <IconButton
                      style={{ color: green[700] }}
                      onClick={onOpenInternalChat}
                    >
                      <Indicator badgeContent={generalTotalUnreadChatMessages}>
                        <Iconify
                          icon={"entypo:chat"}
                          style={{ color: green[700] }}
                          width={25}
                          height={25}
                        />
                        {/* <ChatBubbleRoundedIcon style={{ color: green[700] }} /> */}
                      </Indicator>
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
              {authUser.organization &&
                authUser.organization.data &&
                authUser.organization.data.sms_integration === 1 &&
                authUser.receive_sms === 1 && (
                  <IconButton
                    style={{ color: green[700] }}
                    onClick={() => {
                      if (setUnseenMessage) {
                        setUnseenMessage(false);
                      }

                      onChatDrawerToggle();
                    }}
                  >
                    <Indicator
                      badgeContent={
                        totalMessagesUnread ? totalMessagesUnread : 0
                      }
                    >
                      <Iconify
                        icon={"bxs:message"}
                        style={{
                          color: unseenMessage ? "rgb(63 118 189)" : green[700],
                        }}
                        width={25}
                        height={25}
                      />
                      {/* <ChatBubbleRoundedIcon style={{ color: green[700] }} /> */}
                    </Indicator>
                    {unseenMessage ? (
                      <div className={classes.newDiv}>New</div>
                    ) : (
                      ""
                    )}
                  </IconButton>
                )}
              {CRMUtils.userHasPermission(authUser, ["user_email"]) && (
                <IconButton
                  style={{ color: green[700] }}
                  onClick={onMailDrawerToggle}
                >
                  <Indicator badgeContent={0}>
                    <Iconify
                      icon={"dashicons:email"}
                      style={{ color: green[700] }}
                      width={25}
                      height={25}
                    />
                  </Indicator>
                </IconButton>
              )}
              {pbxConfig &&
              pbxConfig.aor &&
              (!pbxConfig.callCheckMedia ||
                !pbxConfig.callCheckInput ||
                (!pbxConfig.callCheckOutput &&
                  !pbxConfig.callCheckNotification)) ? (
                <Tooltip title=" The Browser have Media Audio Problems, please make sure you have microphone and speaker connected to the computer, configured and, you must allow permission to the browser media and windows notifications!">
                  <MusicOffIcon
                    fontSize="small"
                    style={{
                      color: "#e57373",
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}

              {authUser.organization &&
                authUser.organization.data &&
                authUser.organization.data.pbx_integration === 1 &&
                authUser.organization.data.pbx_integration_port &&
                ((pbxConfig &&
                  pbxConfig.aor &&
                  !pbxConfig.secundary &&
                  !pbxConfig.receive_calls) ||
                  (pbxConfig &&
                    pbxConfig.aor &&
                    !pbxConfig.secundary &&
                    pbxConfig.receive_calls) ||
                  (pbxConfig &&
                    pbxConfig.aor &&
                    pbxConfig.secundary &&
                    pbxConfig.receive_calls)) && (
                  <>
                    <Tooltip title="Call Menu">
                      <IconButton
                        style={{
                          color:
                            pbxConfig.registrationError ||
                            !pbxConfig.receive_active
                              ? red[500]
                              : green[700],
                        }}
                        onClick={(event) =>
                          setAnchorElPhoneMenu(event.currentTarget)
                        }
                      >
                        <Indicator
                          badgeContent={
                            callsMissed && callsMissed > 0 ? callsMissed : 0
                          }
                        >
                          <Iconify
                            icon={"carbon:phone-filled"}
                            style={{
                              color:
                                pbxConfig.registrationError ||
                                !pbxConfig.receive_active
                                  ? red[500]
                                  : green[700],
                            }}
                            width={25}
                            height={25}
                          />

                          {/* <PhoneRoundedIcon
                            style={{
                              color:
                                pbxConfig.registrationError ||
                                !pbxConfig.receive_active
                                  ? red[500]
                                  : green[700],
                            }}
                          /> */}
                        </Indicator>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="phone-menu"
                      anchorEl={anchorElPhoneMenu}
                      keepMounted
                      open={Boolean(anchorElPhoneMenu)}
                      TransitionComponent={Fade}
                      onClose={handleClose}
                      elevation={1}
                      getContentAnchorEl={null}
                      PaperProps={{
                        style: {
                          borderRadius: 10,
                          borderColor: "rgba(180, 180, 180, 0.87)",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          marginTop: 6,
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {pbxConfig.registrationError ? (
                        <MenuItem>
                          <ListItemIcon>
                            <ErrorOutlineRoundedIcon
                              fontSize="small"
                              style={{ color: red[600] }}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="inherit"
                            className={classes.wrapText}
                          >
                            {"Please be sure you have the right configuration! " +
                              "Phone System Error: " +
                              pbxConfig.registrationError}
                          </Typography>
                        </MenuItem>
                      ) : (
                        ""
                      )}

                      {!pbxConfig.registrationError &&
                      pbxConfig &&
                      pbxConfig.aor &&
                      pbxConfig.callCheckMedia &&
                      pbxConfig.callCheckInput &&
                      pbxConfig.callCheckOutput ? (
                        <MenuItem
                          onClick={(event) => {
                            makeACall();

                            handleClose();
                          }}
                          className={classes.menuItemHeigh}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <Iconify
                              icon={"fluent:call-outbound-48-filled"}
                              style={{
                                color: blue[800],
                              }}
                              width={25}
                              height={25}
                            />
                          </ListItemIcon>

                          <Typography variant="inherit">New Call</Typography>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      <MenuItem
                        onClick={(event) => {
                          console.log(event.currentTarget);
                        }}
                        className={classes.menuItemHeigh}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <Brightness1Icon
                            fontSize="small"
                            style={{
                              color: pbxConfig.receive_active
                                ? "#4caf50"
                                : "#e57373",
                            }}
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {pbxConfig.receive_active
                            ? "Available"
                            : "Not Available"}
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        onClick={(event) => {
                          history.push(`/callshistory`);
                          handleClose();
                        }}
                        className={classes.menuItemHeigh}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <Iconify
                            icon={"fluent:history-24-filled"}
                            style={{
                              color: blue[800],
                            }}
                            width={25}
                            height={25}
                          />
                          {/* <HistoryIcon fontSize="small" color={"primary"} /> */}
                        </ListItemIcon>
                        <Typography variant="inherit">Calls History</Typography>
                      </MenuItem>

                      {(callsMissed && callsMissed) > 0 && (
                        <MenuItem
                          onClick={(event) => {
                            history.push(
                              `/unreadmissedcalls/unreadmissedcalls`
                            );
                            handleClose();
                          }}
                          className={classes.menuItemHeigh}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <Badge
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              badgeContent={
                                callsMissed && callsMissed > 0 ? callsMissed : 0
                              }
                              color="primary"
                            >
                              <CallMissedRoundedIcon
                                fontSize="small"
                                style={{ color: red[500] }}
                              />
                            </Badge>
                          </ListItemIcon>
                          <Typography variant="inherit">
                            Unread Missed Calls
                          </Typography>
                        </MenuItem>
                      )}
                      {pbxConfig.receive_active ? (
                        <MenuItem
                          onClick={(event) => {
                            setAnchorElPhoneSettingMenu(event.currentTarget);
                          }}
                          className={classes.menuItemHeigh}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <Iconify
                              icon={"dashicons:admin-settings"}
                              style={{
                                color: blue[800],
                              }}
                              width={25}
                              height={25}
                            />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            Phone Settings
                          </Typography>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {pbxConfig.receive_active && (
                        <Menu
                          id="phoneSetting-menu"
                          anchorEl={anchorElPhoneSettingMenu}
                          keepMounted
                          open={Boolean(anchorElPhoneSettingMenu)}
                          TransitionComponent={Zoom}
                          onClose={handleClosePhoneSetting}
                          elevation={1}
                          getContentAnchorEl={null}
                          PaperProps={{
                            style: {
                              borderRadius: 10,
                              borderColor: "rgba(180, 180, 180, 0.87)",
                              borderWidth: "1px",
                              borderStyle: "solid",
                            },
                          }}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem className={classes.menuItemHeigh}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <MusicNoteIcon
                                fontSize="small"
                                style={{
                                  color:
                                    pbxConfig &&
                                    pbxConfig.aor &&
                                    pbxConfig.callCheckMedia
                                      ? "#1976d2"
                                      : "#e57373",
                                }}
                              />
                            </ListItemIcon>
                            <Typography variant="inherit">
                              {pbxConfig &&
                              pbxConfig.aor &&
                              pbxConfig.callCheckMedia
                                ? "Browser Media ON"
                                : "Browser Media OFF"}
                            </Typography>
                          </MenuItem>
                          <MenuItem className={classes.menuItemHeigh}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <NotificationsActiveIcon
                                fontSize="small"
                                style={{
                                  color:
                                    pbxConfig &&
                                    pbxConfig.aor &&
                                    !pbxConfig.secundary &&
                                    pbxConfig.receive_active &&
                                    pbxConfig.receive_calls &&
                                    pbxConfig.callCheckNotification
                                      ? "#1976d2"
                                      : "#e57373",
                                }}
                              />
                            </ListItemIcon>
                            <Typography variant="inherit">
                              {pbxConfig &&
                              pbxConfig.aor &&
                              !pbxConfig.secundary &&
                              pbxConfig.receive_active &&
                              pbxConfig.receive_calls &&
                              pbxConfig.callCheckNotification
                                ? "Windows Notifications ON"
                                : "Windows Notifications OFF"}
                            </Typography>
                          </MenuItem>
                          <MenuItem className={classes.menuItemHeigh}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <VolumeUpIcon
                                fontSize="small"
                                style={{
                                  color:
                                    pbxConfig &&
                                    pbxConfig.aor &&
                                    pbxConfig.callCheckOutput
                                      ? "#1976d2"
                                      : "#e57373",
                                }}
                              />
                            </ListItemIcon>
                            <Typography
                              variant="inherit"
                              className={classes.wrapText}
                            >
                              {pbxConfig &&
                              pbxConfig.aor &&
                              pbxConfig.callCheckOutput
                                ? pbxConfig.defaultsDevices.find((device) => {
                                    return device.kind === "audiooutput";
                                  })
                                  ? pbxConfig.defaultsDevices.find((device) => {
                                      return device.kind === "audiooutput";
                                    }).label
                                  : "No default device found!"
                                : "No default device found!"}
                            </Typography>
                          </MenuItem>
                          <MenuItem className={classes.menuItemHeigh}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <MicIcon
                                fontSize="small"
                                style={{
                                  color:
                                    pbxConfig &&
                                    pbxConfig.aor &&
                                    pbxConfig.callCheckInput
                                      ? "#1976d2"
                                      : "#e57373",
                                }}
                              />
                            </ListItemIcon>
                            <Typography
                              variant="inherit"
                              className={classes.wrapText}
                            >
                              {pbxConfig &&
                              pbxConfig.aor &&
                              pbxConfig.callCheckInput
                                ? pbxConfig.defaultsDevices.find((device) => {
                                    return device.kind === "audioinput";
                                  })
                                  ? pbxConfig.defaultsDevices.find((device) => {
                                      return device.kind === "audioinput";
                                    }).label
                                  : "No default device found"
                                : "No default device found"}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      )}

                      {!pbxConfig.registrationError &&
                        pbxConfig &&
                        pbxConfig.aor &&
                        !pbxConfig.secundary &&
                        ((pbxConfig.callCheckMedia &&
                          pbxConfig.callCheckInput &&
                          pbxConfig.callCheckOutput &&
                          pbxConfig.callCheckNotification &&
                          pbxConfig.receive_active) ||
                          !pbxConfig.receive_active) &&
                        pbxConfig.receive_calls && (
                          <MenuItem
                            onClick={(event) => {
                              receivingControl();
                              handleClose();
                            }}
                            className={classes.menuItemHeigh}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              {/* <PhonePausedIcon
                                fontSize="small"
                                style={{
                                  color: pbxConfig.receive_active
                                    ? "#1976d2"
                                    : "#e57373",
                                }}    /> */}

                              <Iconify
                                icon={
                                  pbxConfig.receive_active
                                    ? "ic:baseline-phone-paused"
                                    : "ic:round-phone-in-talk"
                                }
                                style={{
                                  color: pbxConfig.receive_active
                                    ? "#1976d2"
                                    : "#e57373",
                                }}
                                width={25}
                                height={25}
                              />
                            </ListItemIcon>
                            <Typography variant="inherit">
                              {pbxConfig.receive_active
                                ? "Disconnect Calls"
                                : "Connect Calls"}
                            </Typography>
                          </MenuItem>
                        )}
                    </Menu>
                  </>
                )}

              <MesagesNotification2 />

              <LanguageMenu />
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {openPopupNewContact && (
        <Popup
          title="New Contact"
          openPopup={openPopupNewContact}
          onClose={onCloseNewContact}
        >
          <ContactForm
            fullScreen
            user={authUser}
            contactForEdit={null}
            addOrEdit={addContact}
            tags={tags}
            buildings={getBuildingOptions()}
            customFields={[]}
            coordinators={getCoordinatorOptions()}
            error={errors ? errors : false}
          />
        </Popup>
      )}
      {contactDetail && (
        <Popup
          fullScreen
          title={`Contact ${contactDetail.fname} ${contactDetail.lname}`}
          openPopup={openContactPopup}
          onClose={onCloseContact}
        >
          <ContactDetails
            contact={contactDetail}
            onDetailClose={onCloseContact}
            opentab={documentsTabRef.current ? documentsTabRef.current : 0}
            documentsTab={1}
          />
        </Popup>
      )}
      {ticketDetail && (
        <PopupTicket
          fullWidth={true}
          title={"Add Ticket"}
          openPopup={openTicketPopup}
          onClose={() => setOpenTicketPopup(false)}
        >
          <TicketFormResolve
            ticket={ticketDetail}
            addOrEdit={addOrEditTicket}
            authUser={authUser}
            ticketCategories={ticketCategories}
            ticketPriorities={ticketPriorities}
            ldepartments={departmentsNew}
            lusers={usersDropdown}
            lgroups={groups}
            updateTicketComment={updateTicketComment}
            buildings={buildings}
          />
        </PopupTicket>
      )}
      {invoiceDetail && (
        <Popup
          fullScreen
          title={
            invoiceDetail ? `Invoice # ${invoiceDetail.name}` : "New Invoice"
          }
          openPopup={openInvoicePopup}
          onClose={onCloseInvoice}
        >
          <InvoiceDetails invoice={invoiceDetail} selectedTab={3} />
        </Popup>
      )}

      <Popup
        title={
          appointmentForEdit && appointmentForEdit.name
            ? "Appointment: " + appointmentForEdit.name
            : "Appointment"
        }
        fullWidth={true}
        openPopup={appointmentPopup}
        onClose={onCloseAppointmentPopup}
      >
        <>
          <AppointmentNewForm
            users={usersDropdown}
            appointmentTypes={appointmentTypes}
            buildings={buildingsDropdown}
            appointmentNewForEdit={appointmentForEdit}
            addOrEdit={EditAppointment}
          />
        </>
      </Popup>

      <Popup
        title={"Payment Link Request"}
        fullWidth={true}
        openPopup={openPaymentLinkRequestPopup}
        onClose={onClosePaymentLinkRequest}
        maxWidth={"sm"}
      >
        <PaymentLinkRequest
          paymentLinkRequest={paymentLinkRequest}
          onClose={onClosePaymentLinkRequest}
          setNotify={setNotify}
        />
      </Popup>

      <Popup
        title="Notifications"
        fullWidth={true}
        openPopup={openAllNotifications}
        onClose={() => {
          setOpenAllNotifications(false);
        }}
      >
        <AllNotifications
          setNotificationView={setNotificationView}
          markNotfAsView={markNotfAsView}
          renderNotificationDataNew={renderNotificationDataNew}
          selectedType={selectedNotificationsType}
        />
      </Popup>

      <Popup
        title="Task"
        openPopup={isOpenRefundTask}
        isfullWidth={true}
        onClose={() => {
          setIsOpenRefundTask(false);
          setTask(null);
        }}
      >
        <FormProvider {...methodsTasks}>
          <form onSubmit={methodsTasks.handleSubmit(handleSubmitEdit)}>
            <TaskForm
              {...{
                objectData: task,
                userId: authUser.id,
                permissions: permissions,
              }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Controls.Button
                text="Save"
                disabled={
                  !methodsTasks.formState.isDirty || updateTaskIsLoading
                }
                onClick={methodsTasks.handleSubmit(handleSubmitEdit)}
              />
              <Controls.Button
                text="Close"
                onClick={() => {
                  setIsOpenRefundTask(false);
                  setTask(null);
                }}
                // disabled={updatingOrCreating}
              />
            </div>

            {updateTaskIsLoading && <LinearProgress />}
          </form>
        </FormProvider>
      </Popup>

      <Popup
        title="Refund Ticket"
        openPopup={isOpenRefundTicket}
        isfullWidth={true}
        onClose={() => {
          setIsOpenRefundTicket(false);
          setRefundTicket(null);
        }}
      >
        <FormProvider {...methodsRefundTickets}>
          <form
            onSubmit={methodsRefundTickets.handleSubmit(
              handleSubmitEditRefundTicket
            )}
          >
            <RefundTicketForm
              {...{
                objectData: refundTicket,
                userId: authUser.id,
                permissions: permissions,
              }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Controls.Button
                text="Save"
                disabled={
                  !methodsRefundTickets.formState.isDirty ||
                  updateRefundsTicketsLoading
                }
                onClick={methodsRefundTickets.handleSubmit(
                  handleSubmitEditRefundTicket
                )}
              />
              <Controls.Button
                text="Close"
                onClick={() => {
                  setIsOpenRefundTicket(false);
                  setRefundTicket(null);
                }}
                // disabled={updatingOrCreating}
              />
            </div>

            {updateRefundsTicketsLoading && <LinearProgress />}
          </form>
        </FormProvider>
      </Popup>

      <Notification2
        notify={notify}
        setNotify={setNotify}
        autoHide={10000}
        preventAutoHide={openContactPopup}
      />
      <Drawer
        PaperProps={{ style: { minWidth: "70%" } }}
        style={{ overflowX: "none" }}
        anchor="right"
        open={openInternalChat}
        onClose={toggleDrawerInternalChat}
      >
        <div role="presentation" style={{ height: "100%" }}>
          {/*<Chat />*/}
          <InternalChat />
        </div>
      </Drawer>

      <Menu
        classes={{ paper: classes.menu }}
        style={{ marginTop: 2 }}
        id="more-menu"
        anchorEl={anchorElFavoriteSites}
        keepMounted
        open={Boolean(anchorElFavoriteSites)}
        onClose={closeFavoriteSites}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FavoriteSitesList closeList={closeFavoriteSites} />
      </Menu>

      {recordDetail && (
        <Popup
          fullScreen
          title={`Patient ${recordDetail.fname} ${recordDetail.lname}`}
          openPopup={openRecordPopup}
          onClose={onCloseRecord}
        >
          <RecordDetails
            isLoading={isLoadingRecord}
            setIsLoading={setIsLoadingRecord}
            record={recordDetail}
            onClose={onCloseRecord}
            openTabHeader={1}
          />
        </Popup>
      )}
    </React.Fragment>
  );
};

export default withTheme(Header);
