import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import AttachmentIcon from "@material-ui/icons/Attachment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { useTranslation } from "react-i18next";
import CRMUtils from "../../utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
}));

export default function MediaTimeline(props) {
  const classes = useStyles();
  const { data } = props;
  const { authUser, loading, errors } = useSelector((state) => state.auth);

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";

  const { t, i18n } = useTranslation(["common", "records"]);


  const canUserViewTheDocument = (documentType = "", documentID = null) => {
    if (documentType && documentID) {
      var cleanName = documentType
        .replaceAll(" ", "")
        .replaceAll("_", "")
        .toLowerCase();

      return CRMUtils.userHasPermission(authUser, [
        "doctypesview_" + cleanName + "_" + documentID,
      ]);
    } else {
      return true;
    }
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          created by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <AttachmentIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {t("common:general.Document")}
          </Typography>

          {canUserViewTheDocument(
            data.object.doc_type_name,
            data.object.doc_type_id
          ) ? (
            <Typography
              button
              component="a"
              href={data.object.url}
              target="_blank"
            >
              {data.object.name}
            </Typography>
          ) : (
            <Typography>{data.object.name}</Typography>
          )}
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
