import React from "react";
import { grey, blueGrey, lightGreen } from "@material-ui/core/colors";
import moment from "moment";
import Popup from "./Popup";
import {
  Card,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ImageIcon from "@material-ui/icons/Image";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Done } from "@material-ui/icons";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FeedbackIcon from "@material-ui/icons/Feedback";
import Iconify from "./Iconify";
const ChatRoomRightItem = ({
  item,
  styleList,
  classes,
  saveToContactD = false,
  saveToContact,
  documentTypes,
  isPrinting,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const idopen = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //const [localDocumentTypes, setLocalDocumentTypes] = React.useState(null);

  const [anchorElSaveAs, setAnchorElSaveAs] = React.useState(null);

  const handleClickSaveAs = (event) => {
    setAnchorElSaveAs(event.currentTarget);
    // console.error("handleClickSaveAs", event.currentTarget);
  };

  const handleCloseSaveAs = () => {
    setAnchorElSaveAs(null);
  };

  const [imageToDownload, setImageToDownload] = React.useState({
    tempUrl: "",
    file_name: "",
    media_id: "",
  });

  /* const saveToContactLocal = () => {
    saveToContact(imageToDownload.media_id);
  };*/

  const [imageToShow, setImageToShow] = React.useState({
    tempUrl: "",
    file_name: "",
  });
  const [imageToShowOpenP, setImageToShowOpenP] = React.useState(false);

  const imageToShowOpen = (tempUrl, file_name) => {
    setImageToShow({
      tempUrl: tempUrl,
      file_name: file_name,
    });

    setImageToShowOpenP(true);
  };

  const imageToShowClose = (tempUrl, file_name) => {
    setImageToShow({
      tempUrl: "",
      file_name: "",
    });

    setImageToShowOpenP(false);
  };

  const [videoToShow, setVideoToShow] = React.useState({
    tempUrl: "",
    file_name: "",
  });
  const [videoToShowOpenP, setVideoToShowOpenP] = React.useState(false);

  const videoToShowOpen = (tempUrl, file_name) => {
    setVideoToShow({
      tempUrl: tempUrl,
      file_name: file_name,
    });

    setVideoToShowOpenP(true);
  };

  const videoToShowClose = (tempUrl, file_name) => {
    setImageToShow({
      tempUrl: "",
      file_name: "",
    });

    setVideoToShowOpenP(false);
  };

  const renderTypes = () => {
    if (documentTypes) {
      return documentTypes.map((item) => {
        return (
          <MenuItem
            onClick={() => {
              saveToContact(imageToDownload.media_id, item.name);
              handleCloseSaveAs();
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        );
      });
    }
  };

  const downloadImage = () => {
    /*  var xhr = new XMLHttpRequest();
    xhr.open("get", `${imageToDownload.tempUrl}`, true);
    xhr.responseType = "blob";

    const link = document.createElement("a");
    link.href = imageToDownload.tempUrl;
    link.setAttribute("download", `${imageToDownload.file_name}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);*/

    saveAs(`${imageToDownload.tempUrl}`, `${imageToDownload.file_name}`);
  };

  return (
    <div style={Object.assign({}, styles.parentView, styleList)}>
      <Box m={3}>
        <Card style={styles.cardView} variant={"elevation"} elevation={0.9}>
          {item.created_by &&
            (item.from_type === "group" || item.to_type === "group") && (
              <Typography style={styles.userMessage}>
                {item.created_by + ": "}
              </Typography>
            )}
          {item.impersonator && item.impersonator.data && (
            <Typography style={styles.userMessage}>
              {"By " +
                item.impersonator.data.fname +
                " " +
                item.impersonator.data.lname +
                " as you: "}
            </Typography>
          )}
          <Typography style={styles.userMessage}>
            {item.body ? item.body : ""}
          </Typography>
          {item &&
          item.tempUrl &&
          (item.type == "mms" || item.type == "whatsapp") ? (
            <div style={{ display: "flex", position: "relative" }}>
              {isPrinting ? (
                <ImageIcon />
              ) : item.mediaType && item.mediaType == "video/mp4" ? (
                <Button
                  onClick={(event) => {
                    videoToShowOpen(item.tempUrl, item.file_name);
                  }}
                >
                  <video
                    style={{
                      width: "auto",
                      height: "auto",
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                    controls
                  >
                    <source src={item.tempUrl} type="video/mp4"></source>
                  </video>
                </Button>
              ) : (
                <div>
                  <Button
                    onClick={(event) => {
                      imageToShowOpen(item.tempUrl, item.file_name);
                    }}
                  >
                    <img
                      src={item.tempUrl}
                      alt={"Attach"}
                      style={{
                        width: "auto",
                        height: "auto",
                        maxHeight: "150px",
                        maxWidth: "150px",
                      }}
                    />
                  </Button>
                  <IconButton
                    aria-label="More"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setImageToDownload({
                        tempUrl: item.tempUrl,
                        file_name: item.file_name,
                        media_id: item.media_id,
                      });
                    }}
                    // onFocus={onFocusHandle}
                    //   onBlur={onFocusoutHandle}
                    className={
                      !Boolean(anchorEl) ? classes.more : classes.moreMenu
                    }
                    boxShadow={3}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    TransitionComponent={Fade}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        downloadImage();
                        handleClose();
                      }}
                    >
                      Download
                    </MenuItem>
                    <MenuItem
                      /*  onClick={(event) => {
                     handleClickSaveAs(event);
                     // handleClose();
                   }}*/

                      onMouseOver={handleClickSaveAs}
                      disabled={!saveToContactD}
                    >
                      Save As
                    </MenuItem>
                  </Menu>
                  <Menu
                    id="saveAs-menu"
                    anchorEl={anchorElSaveAs}
                    keepMounted
                    open={Boolean(anchorElSaveAs)}
                    TransitionComponent={Fade}
                    onClose={() => {
                      handleCloseSaveAs();
                      handleClose();
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {renderTypes()}
                  </Menu>

                  {/*<Controls.IconButton
                aria-label="Expand"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className={classes.popover}
                boxShadow={3}
              >
                <ExpandMoreIcon />
              </Controls.IconButton>*/}
                </div>
              )}
            </div>
          ) : item && item.tempUrlDocument && item.type == "whatsapp" ? (
            <div>
              {isPrinting ? (
                <Iconify
                  icon={"solar:document-bold"}
                  width={50}
                  height={50}
                  style={{ color: "#00A884" }}
                />
              ) : (
                <div>
                  <ListItem>
                    <ListItemIcon>
                      <Iconify
                        icon={"solar:document-bold"}
                        width={50}
                        height={50}
                        style={{ color: "#00A884" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.file_name ? item.file_name : "Document"}
                    />

                    <IconButton
                      aria-label="More"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setImageToDownload({
                          tempUrl: item.tempUrlDocument,
                          file_name: item.file_name,
                          media_id: item.media_id,
                        });
                      }}
                      className={
                        !Boolean(anchorEl) ? classes.more : classes.moreMenu
                      }
                      boxShadow={3}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </ListItem>
                  <Menu
                    id="more-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    TransitionComponent={Fade}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        downloadImage();
                        handleClose();
                      }}
                    >
                      Download
                    </MenuItem>
                    <MenuItem
                      /*  onClick={(event) => {
                              handleClickSaveAs(event);
                              // handleClose();
                            }}*/

                      onMouseOver={handleClickSaveAs}
                      disabled={!saveToContactD}
                    >
                      Save As
                    </MenuItem>
                  </Menu>

                  <Menu
                    id="saveAs-menu"
                    anchorEl={anchorElSaveAs}
                    keepMounted
                    open={Boolean(anchorElSaveAs)}
                    TransitionComponent={Fade}
                    onClose={() => {
                      handleCloseSaveAs();
                      handleClose();
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {renderTypes()}
                  </Menu>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          <Box display="flex" justifyContent="right" alignItems="center">
            <Typography style={styles.userTime}>
              {item.created_at
                ? moment(item.created_at).format("YYYY-MM-DD hh:mm:ss A")
                : ""}
            </Typography>

            {item && item.response && item.type === "whatsapp" ? (
              item.whatsapp_message_status === "sending" ? (
                <Tooltip title="Sending">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AccessTimeIcon
                      style={{ color: "rgb(0 114 255)", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(0 114 255)", fontSize: 11 }}
                    >
                      Sending...
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.whatsapp_message_status === "warning" ? (
                <Tooltip title="Deferred (It is not guaranteed that this message was sent successfully)">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FeedbackIcon
                      style={{ color: "rgb(246 184 0)", width: 17, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(200 161 19)", fontSize: 11 }}
                    >
                      Warning
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.whatsapp_message_status === "sent" ? (
                <Tooltip title="Sent successfully!">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Done
                      style={{ color: "#8696a0", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "#8696a0", fontSize: 11 }}
                    >
                      Sent
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.whatsapp_message_status === "delivered" ? (
                <Tooltip title="Delivered">
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <DoneAllIcon
                      style={{ color: "#8696a0", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "#8696a0", fontSize: 11 }}
                    >
                      Delivered
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.whatsapp_message_status === "read" ? (
                <Tooltip title="Read">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DoneAllIcon
                      fontSize="small"
                      style={{ color: "#53bdeb", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "#53bdeb", fontSize: 11 }}
                    >
                      Read
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.whatsapp_message_status === "failed" ? (
                <Tooltip  title={`Failed ${item.error}`}  >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ErrorOutlineIcon
                      fontSize="small"
                      style={{ color: "red", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(217 40 40)", fontSize: 11 }}
                    >
                       Failed
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.whatsapp_message_status === "deleted" ? (
                <Tooltip title="Deleted">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteForeverIcon
                      fontSize="small"
                      style={{ color: "red", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(217 40 40)", fontSize: 11 }}
                    >
                      Deleted
                    </Typography>
                  </div>
                </Tooltip>
              ) : (
                ""
              )
            ) : item && item.response && item.type !== "whatsapp" ? (
              item.deferred == true ? (
                <Tooltip title="Deferred (It is not guaranteed that this message was sent successfully)">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FeedbackIcon
                      style={{ color: "rgb(246 184 0)", width: 17, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(200 161 19)", fontSize: 11 }}
                    >
                      Warning
                    </Typography>
                  </div>
                </Tooltip>
              ) : item.failed == false ? (
                <Tooltip title="Sent successfully!">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Done
                      style={{ color: "rgb(12 185 102)", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(121 175 149)", fontSize: 11 }}
                    >
                      Sent
                    </Typography>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Failed">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ErrorOutlineIcon
                      fontSize="small"
                      style={{ color: "red", width: 15, margin: 4 }}
                    />
                    <Typography
                      style={{ color: "rgb(217 40 40)", fontSize: 11 }}
                    >
                      Failed
                    </Typography>
                  </div>
                </Tooltip>
              )
            ) : (
              <Tooltip title="Sending">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeIcon
                    style={{ color: "rgb(0 114 255)", width: 15, margin: 4 }}
                  />
                  <Typography style={{ color: "rgb(0 114 255)", fontSize: 11 }}>
                    Sending...
                  </Typography>
                </div>
              </Tooltip>
            )}
          </Box>
        </Card>
      </Box>
      {imageToShow && (
        <Popup
          title={`Image: ${imageToShow.file_name}`}
          openPopup={imageToShowOpenP}
          onClose={imageToShowClose}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              src={imageToShow.tempUrl}
              alt={imageToShow.file_name}
              style={{
                width: "auto",
                height: "auto",
                maxHeight: "500px",
                maxWidth: "500px",
              }}
            />
          </div>
        </Popup>
      )}
      {videoToShow && (
        <Popup
          title={`Video: ${videoToShow.file_name}`}
          openPopup={videoToShowOpenP}
          onClose={videoToShowClose}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <video style={{ maxHeight: "600px", maxWidth: "600px" }} controls>
              <source src={videoToShow.tempUrl} type="video/mp4"></source>
            </video>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default ChatRoomRightItem;

const styles = {
  parentView: {
    maxWidth: "60%",
    width: "98%",
    right: 0,
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flexDirection: "row",
    display: "flex",
    marginLeft: "auto",
    marginRight: 0,
  },
  profileImage: {
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
  },
  userName: {
    fontSize: 16,
    marginTop: 3,
  },
  userMessage: {
    fontSize: 14,
    color: "#000",
    marginTop: 5,
    alignSelf: "flex-start",
  },
  userTime: {
    fontSize: 11,
    color: grey[500],
    textAlign: "right",
  },
  msgIcon: {
    fontSize: 26,
    color: grey[50],
    marginTop: 3,
    alignSelf: "flex-end",
    marginRight: -10,
  },
  textMsgCountView: {
    fontSize: 12,
    color: blueGrey[500],
    backgroundColor: lightGreen[100],
    justifyContent: "center",
    alignSelf: "flex-end",
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    marginTop: 10,
  },
  textMsgCount: {
    fontSize: 12,
    color: blueGrey[0],
    justifyContent: "center",
    alignSelf: "center",
  },
  cardView: {
    backgroundColor: "#E1FFC7",
    paddingTop: 5,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 3,
    marginTop: 2,
    marginBottom: 2,
  },
};
