import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";

// Define styles for the component
const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

// DatePicker2 component with props
export default function DatePicker2(props) {
  // Destructure props
  const { label, onChange, value, useDateTime, ...other } = props;

  // Get classes from defined styles
  const classes = useStyles();

  // Determine which picker to use based on useDateTime prop
  const PickerComponent = useDateTime
    ? KeyboardDateTimePicker
    : KeyboardDatePicker;

  // Render the component
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <PickerComponent
        inputVariant="outlined"
        label={label}
        format={useDateTime ? "MM/DD/yyyy HH:mm" : "MM/DD/yyyy"} // Set format based on useDateTime prop
        onChange={(date) => onChange(date)}
        value={value}
        InputProps={{ readOnly: true }}
        className={classes.textfield}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
