import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  languages: [],
  fields: [],
};

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    getLanguages: (state) => {
      state.loading = true;
    },
    getLanguagesSuccess: (state, { payload }) => {
      state.languages = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getLanguagesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getLanguages,
  getLanguagesSuccess,
  getLanguagesFailure,
} = languagesSlice.actions;
export const languagesSelector = (state) => state.languages;
export default languagesSlice.reducer;

export function fetchLanguagesService(orgId) {
  return async (dispatch) => {
    dispatch(getLanguages());

    try {
      const response = await Api.getLanguages(orgId);

      dispatch(getLanguagesSuccess(response.data));
    } catch (error) {
      dispatch(getLanguagesFailure(error));
    }
  };
}
