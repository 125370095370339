import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Grid,
	Paper,
	Typography,
	makeStyles,
} from "@material-ui/core";
import {
	ExpandMore as ExpandMoreIcon,
	FilterList as FilterListIcon,
} from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

const FilterContext = React.createContext();
const FilterControlContext = React.createContext();

export const useFilterContext = () => React.useContext(FilterContext);
export const useFilterControlContext = () =>
	React.useContext(FilterControlContext);

const useStyles = makeStyles((theme) => ({
	pageContent: {
		margin: theme.spacing(5),
		padding: theme.spacing(3),
	},
}));

const GenericFilter = ({ Form, children }) => {
	const methods = useForm();
	const classes = useStyles();
	const handleSubmit = (data) => {
		// console.log("Submit form filter", data);
		setFilter(data);

		// queryClient.refetchQueries();
	};

	const [enabled, setEnabled] = useState(true);

	const filterControl = useMemo(() => {
		return {
			setEnabled,
		};
	}, []);

	// console.log(filterControl)

	const resetSubmit = (data) => {
		let d = Object.keys(data).reduce(
			(prev, curr) => ({ ...prev, [curr]: null }),
			{}
		);
		// methods.reset(d);
		Object.keys(d).forEach((key) => methods.setValue(key, ""));
		setFilter(d);
	};

	const [filters, setFilter] = useState({});

	const [expanded, setExpanded] = useState(null);
	const handleChangeExpanded = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<FilterControlContext.Provider value={filterControl}>
				<Accordion
					expanded={expanded == "filters"}
					onChange={handleChangeExpanded("filters")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography
							variant="h6"
							style={{
								display: "flex",
								alignItems: "center",
								gap: "5px",
							}}
						>
							<FilterListIcon /> Filters
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ display: "block" }}>
						<Paper className={classes.pageContent}>
							<Grid
								container
								direction="column"
								className={classes.table}
								spacing={2}
							>
								<Grid item>
									<FormProvider {...methods}>
										<form
											onSubmit={methods.handleSubmit(
												handleSubmit
											)}
										>
											<Form />
										</form>
									</FormProvider>
								</Grid>
								<Grid
									item
									container
									justifyContent="flex-end"
									spacing={2}
								>
									<Grid item>
										<Button
											variant="outlined"
											color="primary"
											onClick={methods.handleSubmit(
												resetSubmit
											)}
										>
											Reset
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="outlined"
											color="primary"
											onClick={methods.handleSubmit(
												handleSubmit
											)}
											disabled={!enabled}
										>
											Apply
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</AccordionDetails>
				</Accordion>
			</FilterControlContext.Provider>
			<FilterContext.Provider value={filters}>
				{children}
			</FilterContext.Provider>
		</>
	);
};

export default GenericFilter;
