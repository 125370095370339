import React, { useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { FormControl } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Controls from ".";

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);

export default function FileDialog(props) {
  const {
    label,
    onChange,
    value,
    maxFileSize = null,
    formats = null,
    st = null,
    filesLimit = 1,
    disableSubmitButton = false,
    formControlStyle = null,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files) => {
    onChange(files);
    setOpen(false);
  };
  2097152;

  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();

  return (
    <FormControl style={formControlStyle ? formControlStyle : null}>
      <Controls.Button
        {...rest}
        style={st ? st : {}}
        text={label}
        onClick={handleOpen}
        disabled={disableSubmitButton}
      />

      <DropzoneDialog
        open={open}
        filesLimit={filesLimit}
        onSave={handleSave}
        acceptedFiles={
          formats ? formats : ["application/pdf", "image/jpeg", "image/png"]
        }
        maxFileSize={maxFileSize ? maxFileSize : 1073741824}
        onClose={handleClose}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Selected files"
        submitButtonText="Accept"
      />
    </FormControl>
  );
}
