import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
} from "./common/sliceFunctions2";

var entitiesName = "surgeonDropdownRestricted";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_SURGEONDROPDOWNRESTRICTED";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}
