import React, { useState } from "react";
import { deepOrange } from "@material-ui/core/colors";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { blue, red } from "@material-ui/core/colors";
import moment from "moment";
import { Grid, makeStyles, Box, Typography } from "@material-ui/core";
import { isEmpty, find } from "lodash";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import Api from "../../lib/api";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  wroot: {
    width: 500,
  },
  bcolor: { background: blue[50] },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  error: { color: red[500] },
}));

export default function TicketForm(props) {
  const {
    onsaveTicket,
    addOrEdit,
    authUser,
    ticket,
    contact_id = null,
    ticketCategories,
    ticketPriorities,
    departments,
    usersNotifications,
    buildings,
  } = props;

  const { handleSubmit, reset, setValue, control, errors } = useForm();

  const classes = useStyles();
  const [priorityId, SetPriorityId] = React.useState(
    ticketPriorities ? ticketPriorities[0] : 0
  );

  const [submitFlag, setSubmitFlag] = useState(false);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [attached, setAttached] = useState([]);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedBuilding, setSelectedBuilding] = useState(null);

  const [usersLocalNotifications, setUsersLocalNotifications] = useState([]);

  const [overdue, setOverdue] = useState(
    ticketPriorities[0]?.overdue_hours
      ? moment()
          .add(ticketPriorities[0].overdue_hours, "hours")
          .format("yyyy-MM-DD HH:mm:ss")
      : moment().format("yyyy-MM-DD HH:mm:ss")
  );

  const onSubmit = (data) => {
    setSubmitFlag(true);
    if (contact_id) {
      data.contact_id = contact_id;
    } else if (data.contact_id) {
      data.contact_id = data.contact_id.id;
    }

    if (!isEmpty(usersLocalNotifications)) {
      let notificationsArray = [];

      usersLocalNotifications.forEach((item) => {
        notificationsArray.push(item.id);
      });
      data.notificationsArray = notificationsArray;
    }
    data.priority_id = priorityId ? priorityId.id : 1;
    data.user_id = authUser.id;
    data.status = "new";
    addOrEdit(data);
  };

  const calcOverdue = (data) => {
    setOverdue(
      moment().add(data.overdue_hours, "hours").format("yyyy-MM-DD HH:mm:ss")
    );
  };

  const getTicketCategoriesOptions = () => {
    return ticketCategories.map((ticketCategory) => {
      return {
        id: ticketCategory.id,
        title: ticketCategory.name,
      };
    });
  };

  const getTicketPrioritiesOptions = () => {
    return ticketPriorities.map((ticketPriority) => {
      return {
        id: ticketPriority.id,
        title: ticketPriority.name,
      };
    });
  };

  const getDepartmentOptions = () => {
    return departments.map((department) => {
      return {
        id: department.id,
        title: department.name,
      };
    });
  };

  const getBuildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const selectBuilding = (id) => {
    setSelectedBuilding(id);
    setValue("department_id", null);
    setValue("group_id", null);
    setValue("agent_id", null);
  };

  const getGroupsOptions = () => {
    return groups
      .filter((item) => {
        return item.enable_ticket == 1;
      })
      .map((group) => {
        return {
          id: group.id,
          title: group.name,
        };
      });
  };

  const getUsersOptions = () => {
    return users
      .filter((user) => {
        return user.active === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: user.title,
        };
      });
  };

  const getUserNotificationsOptions = () => {
    if (usersNotifications) {
      return usersNotifications
        .filter((user) => {
          return user.active === 1;
        })
        .map((user) => {
          return {
            id: user.id,
            title: user.dropdown_name,
          };
        });
    }
  };

  const fillGroups = (department_id) => {
    // console.log(id);
    setValue("agent_id", null);
    const department = find(departments, { id: department_id });
    // console.log(department);

    const groups = department.groups.data.filter(
      (item) => item.building_id == selectedBuilding
    );

    setGroups(groups);
  };

  const fillUsers = async (group_id) => {
    const { data } = await Api.getGroupUsers(group_id);

    const filterUsers = data.data.filter((user) => {
      return user.enable_ticket === 1;
    });

    setUsers(filterUsers);
  };

  const getProductOptions = () => {
    return contacts.map((contact) => {
      return {
        id: contact.id,
        title: `${contact.full_name}`,
      };
    });
  };
  const renderpriority = () => {
    return (
      <Box boxShadow={3} m={1}>
        <BottomNavigation
          value={priorityId}
          onChange={(event, newValue) => {
            SetPriorityId(newValue);
            calcOverdue(newValue);
          }}
          showLabels
          className={classes.wroot}
        >
          {ticketPriorities.map((ticketPriority) => {
            return (
              <BottomNavigationAction
                label={ticketPriority.name}
                icon={<AccessTimeIcon />}
                value={ticketPriority}
              />
            );
          })}
        </BottomNavigation>
      </Box>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Box m={2}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box boxShadow={3} m={1}>
              <Controller
                name="building_id"
                control={control}
                defaultValue={ticket?.building_id || ""}
                as={({ onChange, value }) => (
                  <Controls.Select
                    label="Building"
                    options={getBuildingOptions()}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      selectBuilding(newValue.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box boxShadow={3} m={1}>
              <Controller
                name="department_id"
                control={control}
                defaultValue={ticket?.department_id || ""}
                as={({ onChange, value }) => (
                  <Controls.Select
                    label="Department"
                    disabled={!selectedBuilding}
                    options={getDepartmentOptions()}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      fillGroups(newValue.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            {
              <Box boxShadow={3} m={1}>
                <Controller
                  name="group_id"
                  control={control}
                  defaultValue={ticket?.group_id || ""}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label="Group"
                      disabled={isEmpty(groups)}
                      value={value}
                      options={getGroupsOptions()}
                      onChange={(newValue) => {
                        onChange(newValue);
                        fillUsers(newValue.target.value);
                      }}
                    />
                  )}
                  rules={{ required: "Group name is required" }}
                />
                {errors.group_id && (
                  <p className={classes.error}>{errors.group_id.message}</p>
                )}
              </Box>
            }
          </Grid>
          <Grid item xs={4}>
            {
              <Box justifyContent="center">
                <Box boxShadow={3} borderColor={blue[50]} m={1}>
                  <Controller
                    name="agent_id"
                    control={control}
                    defaultValue={ticket?.agent_id || ""}
                    as={({ onChange, value }) => (
                      <Controls.Select
                        label="Assign To:"
                        disabled={isEmpty(users)}
                        value={value}
                        options={getUsersOptions()}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                      />
                    )}
                    rules={{ required: "Agent name is required" }}
                  />
                  {errors.agent_id && (
                    <p className={classes.error}>{errors.agent_id.message}</p>
                  )}
                </Box>
              </Box>
            }
          </Grid>

          <Grid item xs={4}>
            {
              <Box boxShadow={3} borderColor="primary.main" m={1}>
                <Controller
                  name="category_id"
                  control={control}
                  defaultValue={ticket?.category_id || ""}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label="Category"
                      value={value}
                      options={getTicketCategoriesOptions()}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                  rules={{ required: "Ticket's Category is required" }}
                />
                {errors.category_id && (
                  <p className={classes.error}>{errors.category_id.message}</p>
                )}
              </Box>
            }
          </Grid>
          <Grid item xs={4}>
            {!contact_id ? (
              <Box boxShadow={3} m={1}>
                <Controller
                  name="contact_id"
                  control={control}
                  defaultValue={null}
                  as={({ onChange, value }) => (
                    <Controls.AutocompleteAsync
                      id={"contact_id"}
                      label={"Lead/Patient"}
                      fullWidth={true}
                      value={value}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                />
              </Box>
            ) : (
              ""
            )}
          </Grid>
          <Grid container item xs={12} md={12}>
            <Grid item xs={2} alignItems="flex-end">
              <Box m={5}>
                <Typography gutterBottom variant="body1">
                  Priority :
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              {renderpriority()}{" "}
            </Grid>
            <Grid item xs={3}>
              <Box boxShadow={3} m={1}>
                <Controller
                  name="overdue_date"
                  control={control}
                  defaultValue={overdue}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      label="Overdue Date"
                      shouldCloseOnSelect
                      defaultValue={overdue}
                      onChange={(date) => {
                        const fdate = moment(date).format(
                          "yyyy-MM-DD HH:mm:ss"
                        );
                        value = fdate;
                        onChange(fdate);
                        setOverdue(fdate);
                      }}
                      value={overdue}
                    />
                  )}
                  rules={{ required: "Ticket's Overdue is required" }}
                />
                {errors.overdue_date && (
                  <p className={classes.error}>{errors.overdue_date.message}</p>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box boxShadow={3} m={1}>
              <Controller
                name="subject"
                control={control}
                defaultValue={ticket?.subject || ""}
                as={<Controls.Input label="Subject" />}
                rules={{
                  required: "Ticket's subject is required",
                  validate: {
                    notEmpty: (value) =>
                      value.trim() !== "" ||
                      "Subject cannot be empty",
                  },
                }}
              />
              {errors.subject && (
                <p className={classes.error}>{errors.subject.message}</p>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box boxShadow={3} m={1}>
              <Controller
                name="content"
                control={control}
                defaultValue={ticket?.content || ""}
                as={<Controls.Textarea label="Text" rows="7 " />}
                rules={{ required: "Ticket's Text is required" }}
              />
              {errors.content && (
                <p className={classes.error}>{errors.content.message}</p>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="file"
              defaultValue={null}
              as={({ onChange, value }) => (
                <Controls.DropzoneDialog
                  label="File"
                  onChange={(files) => {
                    onChange(files[0]);
                    setAttached(files[0]);
                  }}
                  value={value}
                />
              )}
            />
            {attached && <span>{attached.path}</span>}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="users_notifications"
              defaultValue={[]}
              control={control}
              as={({ onChange, value }) => (
                <Controls.AutocompleteMultiple2
                  variant="outlined"
                  defaultValue=""
                  label="Notifications"
                  options={getUserNotificationsOptions()}
                  onChange={(value) => {
                    onChange(value);
                    setUsersLocalNotifications(value);
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={4}
        item
        xs={12}
        md={12}
        justifyContent="flex-end"
      >
        <div>
          <Controls.Button
            type="submit"
            text="Create Ticket"
            disabled={submitFlag}
          />
        </div>
      </Grid>
    </form>
  );
}
