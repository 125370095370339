import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ContactToDoForm from "./forms/ContactToDoForm";
import ContactToDoFormUpdate from "./forms/ContactToDoFormUpdate";
import moment from "moment";
import { Fab, IconButton, Paper, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";

import useTable from "./useTable";

import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DraftsIcon from "@material-ui/icons/Drafts";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import FaceIcon from "@material-ui/icons/Face";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Iconify from "./Iconify.js";
import Controls from "./controls";
import ViewLogsTable from "../components/logs/ViewLogsTable";
import Api from "../lib/api";
import Notification from "./Notification";
import Popup from "./Popup";
import CRMUtils from "../utils/index.js";
import { useSelector } from "react-redux";

import { findIndex } from "lodash";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  icon: {
    color: "#237bd1",
    width: 27,
    height: 27,
  },
  noButton: {
    padding: "10px 13px",
    minWidth: "fit-content",
    borderRadius: 23,
    background: "#19c17d",
    "&:hover": {
      background: "#1a9362",
    },
  },
  yesButton: {
    padding: "10px 13px",
    minWidth: "fit-content",
    borderRadius: 23,
    background: "#cd5d5d",
    "&:hover": {
      background: "#b53838",
    },
  },
}));

function ContactToDoContacts(props) {
  const classes = useStyles();
  const {
    notify,
    setNotify,
    canAdd = true,
    canEdit = true,
    canDelete = true,
    contact_id,
    consultantErrorHandle = null,
    category = null,
    getAll = false,
    editable = true,
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [progressBar, setProgressBar] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedToDoID, setSelectedToDoID] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [localToDos, setLocalToDos] = useState([]);
  const [update, setUpdate] = useState({});
  const [openLogPopup, setOpenLogPopup] = useState(false);

  const [entityForEdit, setEntityForEdit] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);

  const canViewToDos = CRMUtils.userHasPermission(authUser, [
    "contact_viewToDoLogs",
  ]);

  const canEditToDos = CRMUtils.userHasPermission(authUser, [
    "contacts_editToDo",
  ]);

  const userSalesmanCoordinator = CRMUtils.userHasPermission(authUser, [
    "dashboard_salesmanager",
    "dashboard_sales",
    "dashboard_coordinationManager",
    "dashboard_coordinationBasic",
  ]);

  const onClose = () => {
    setOpenPopup(false);
  };

  const openInPopup = () => {
    setOpenPopup(true);
  };

  const editToDo = (toDo) => {
    setEntityForEdit(toDo);
    setOpenEdit(true);
  };
  const onCloseEdit = () => {
    setOpenEdit(false);
    setEntityForEdit(null);
  };

  const updateToDo = async (cid, todoid, newValue) => {
    try {
      const { data } = await Api.updateContactToDo(cid, todoid, newValue);

      const tmp = localToDos.map((todo) => {
        if (todo.id === todoid) {
          todo.done = newValue.done;
          return todo;
        } else return todo;
      });
      setLocalToDos(tmp);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }

    /*
    //setContactToShow(data ? data.data : null);
    let tmp = localToDos;
    tmp.find((todo)=>{
      return todo.id === todoid;
    }).done = newValue.done;

    setLocalToDos(tmp);
    //setLocalToDos(tmp);
    //setUpdate(newValue);
    
    console.error(localToDos.find((todo)=>{
      return todo.id === todoid;
    }));
    */

    //openContact();
  };

  const getToDo = async (cid, todoid) => {
    const { data } = await Api.getContactToDos(cid);
    //setContactToShow(data ? data.data : null);

    //openContact();
  };

  const showType = (type) => {
    switch (type) {
      case "followup":
        return (
          <Tooltip title="Follow up">
            <span>
              <Iconify icon="basil:alarm-solid" className={classes.icon} />
            </span>
          </Tooltip>
        );
        break;
      case "call":
        return (
          <Tooltip title="Call">
            <span>
              <Iconify
                icon="fluent:call-add-20-filled"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      case "sms":
        return (
          <Tooltip title="SMS">
            <span>
              <Iconify
                icon="eva:message-circle-fill"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      case "email":
        return (
          <Tooltip title="Email">
            <span>
              <Iconify icon="fluent:mail-32-filled" className={classes.icon} />
            </span>
          </Tooltip>
        );
      case "contact":
        return (
          <Tooltip title="Make Contact">
            <span>
              <Iconify icon="mingcute:user-3-fill" className={classes.icon} />
            </span>
          </Tooltip>
        );
        break;
      case "deposit":
        return (
          <Tooltip title="Deposit">
            <span>
              <Iconify
                icon="solar:dollar-minimalistic-bold-duotone"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      case "pending_consultation":
        return (
          <Tooltip title="Pending Consultation">
            <span>
              <Iconify
                icon="solar:users-group-rounded-bold-duotone"
                className={classes.icon}
              />
            </span>
          </Tooltip>
        );
        break;
      default:
      // code block
    }
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const { TblContainer } = useTable();

  const saveToDo = async (payload) => {
    console.log("payloadpayload", payload);

    if (payload.followup) payload.type = "followup";
    if (payload.call) payload.type = "call";
    if (payload.sms) payload.type = "sms";
    if (payload.email) payload.type = "email";
    if (payload.contact) payload.type = "contact";
    if (payload.deposit) payload.type = "deposit";
    if (payload.pending_consultation) payload.type = "pending_consultation";

    try {
      if (entityForEdit) {
        const { data } = await Api.updateContactToDoNew(
          entityForEdit.contact_id,
          entityForEdit.id,
          payload
        );

        if (data && data.data) {
          const index = findIndex(localToDos, ["id", entityForEdit.id]);

          if (index >= 0) {
            const entity = { ...localToDos[index], ...data.data };
            let entities = [...localToDos];
            entities[index] = entity;

            setLocalToDos([...entities]);
          }

          onCloseEdit();
          setNotify({
            isOpen: true,
            message: "To Do Updated!",
            type: "success",
          });
        }
      } else {
        payload.category = category;

        const { data } = await Api.addContactToDo(contact_id, payload);
        setLocalToDos([data, ...localToDos]);

        setNotify({
          isOpen: true,
          message: "To Do Created!",
          type: "success",
        });
      }
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }

      console.error("Error Creating To Do: ", e);
      setNotify({
        isOpen: true,
        message: "Error Creating To Do!",
        type: "error",
      });
    }
  };

  const dataMaterialTable = localToDos ? localToDos.map((o) => ({ ...o })) : [];
  const headCellsMaterial = [
    {
      field: "schedule_to",
      title: "Schedule",
      render: (rowData) => {
        return new moment(rowData.schedule_to).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return showType(rowData.type);
      },
    },
    {
      field: "comment",
      title: "Comment",
      render: (rowData) => {
        return rowData.comment;
      },
    },
    {
      field: "done",
      title: "Complete",
      render: (rowData) => {
        return (
          <Controls.Button
            variant="contained"
            className={
              rowData.done === 1 ? classes.noButton : classes.yesButton
            }
            disabled={!canAdd}
            text={rowData.done === 1 ? "YES" : "NO"}
            onClick={() => {
              let tmp = {};
              rowData.done == 1 ? (tmp.done = 0) : (tmp.done = 1);
              //tmp.contact_id = rowData.contact_id
              //tmp.id = rowData.id
              setUpdate(tmp);
              updateToDo(rowData.contact_id, rowData.id, tmp);
              //getToDo(rowData.contact_id,rowData.id);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      title: "Created At",
      render: (rowData) => {
        return new moment(rowData.created_at).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const closeLogPopup = () => {
    setOpenLogPopup(false);
    setSelectedToDoID(null);
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact_id) {
      setProgressBar(true);
      dataFunc().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const dataFunc = async () => {
    try {
      if (getAll) {
        const { data } = await Api.getContactToDos(contact_id);
        setLocalToDos(data);
      } else {
        const params = new URLSearchParams([["category", category]]);

        const { data } = await Api.getContactToDos(
          contact_id,
          `?1=1&${params.toString()}`
        );
        setLocalToDos(data);
      }
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  /*useEffect(() => {
    console.error("useeffect localToDos");
    //const orgId = authUser.organization_id;
    //setLoading(true);
    //getRecordProducts(orgId);
    //getToDos();
    //setLoading(false);
  }, [localToDos,dataMaterialTable]);*/

  return (
    <Wrapper>
      <Notification notify={notify} setNotify={setNotify} />
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              loadingType: "linear",
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            onRowClick={(event, rowData) => {
              const isEditable =
                (canEditToDos &&
                  rowData?.done !== 1 &&
                  !authUser.is_coordinator &&
                  !authUser.is_salesman) ||
                (canEditToDos &&
                  rowData?.done !== 1 &&
                  (authUser.is_coordinator ||
                    authUser.is_salesman ||
                    userSalesmanCoordinator) &&
                  editable);
              if (isEditable) {
                editToDo(rowData);
              }
              return false;
            }}
            title="To Do"
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <Iconify
                      icon={"fluent:add-12-filled"}
                      width={25}
                      height={25}
                    />
                  </Fab>
                ),
                tooltip: "Add To Do",
                disabled: !canAdd,
                hidden: !canAdd,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              {
                icon: () => (
                  <IconButton>
                    <Iconify
                      icon={"tabler:eye-filled"}
                      style={{ color: "#7a52d9" }}
                      width={25}
                      height={25}
                    />
                  </IconButton>
                ),
                tooltip: "See logs",
                hidden: !canViewToDos,
                onClick: (event, rowData) => {
                  setSelectedToDoID(rowData.id);
                  setOpenLogPopup(true);
                },
                position: "row",
              },
              (rowData) => ({
                icon: () => (
                  <IconButton
                    style={{
                      color:
                        !canEditToDos || rowData?.done === 1
                          ? "#bdbdbd"
                          : "#009688",
                    }}
                  >
                    <Iconify
                      icon={"fluent:edit-24-filled"}
                      width={20}
                      height={20}
                    />
                  </IconButton>
                ),
                tooltip: "Edit",
                disabled: !canEditToDos || rowData?.done === 1,
                onClick: (event, rowData) => {
                  editToDo(rowData);
                },
              }),
            ]}
          />
        </TblContainer>
      </Paper>

      <Popup title={"Add To Do"} openPopup={openPopup} onClose={onClose}>
        <ContactToDoForm
          setOpenPopup={setOpenPopup}
          saveToDo={saveToDo}
          className={classes.form}
        />
      </Popup>

      <Popup title="Edit To Do" openPopup={openEdit} onClose={onCloseEdit}>
        <ContactToDoFormUpdate
          entityForEdit={entityForEdit}
          saveToDo={saveToDo}
          className={classes.form}
        />
      </Popup>

      <Popup
        title="To-Do Logs"
        openPopup={openLogPopup}
        onClose={closeLogPopup}
        fullWidth={true}
      >
        <ViewLogsTable
          modelId={selectedToDoID}
          getLogs={Api.getToDoActivityLogs}
        />
      </Popup>
    </Wrapper>
  );
}

export default ContactToDoContacts;
