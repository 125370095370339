import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { isEmpty, find } from "lodash";

import {
  List,
  ListItemText,
  Paper,
  ListItem as MuiListItem,
  Divider,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Controls from "./controls";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
}));

function Quote2Invoice(props) {
  const classes = useStyles();
  const { items, onConvert } = props;

  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (items && items.length > 0) {
      setChecked([...items.map((item) => item.id)]);
    }
  }, [items]);

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <List className={classes.paymentList}>
          {items.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <ListItem
                  role={undefined}
                  dense
                  //button
                >
                  <ListItemIcon>
                    <Checkbox
                      disabled
                      edge="start"
                      checked={checked.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      // onClick={handleToggle(item.id)}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.name} />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            );
          })}
        </List>
        <Controls.Button
          variant="contained"
          color="secondary"
          text="Convert"
          startIcon={<ReceiptIcon />}
          disabled={isEmpty(checked)}
          onClick={() => {
            onConvert(checked);
          }}
        />
      </Paper>
    </Wrapper>
  );
}

export default Quote2Invoice;
