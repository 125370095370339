import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CommentIcon from "@material-ui/icons/Comment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
}));

export default function CommentTimeline(props) {
  const classes = useStyles();

  const { data } = props;

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";

  const { t, i18n } = useTranslation(["common", "records"]);

  //replaceAll('###',"\r\n•")
  let checkComment =
    data.object.comment.indexOf("Incoming Contact Comment:") !== -1 &&
    typeof data.object.comment === "string"
      ? data.object.comment.split("###")
      : data.object.comment;

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          created by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <CommentIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {t("common:general.Comment")}
          </Typography>
          {/*typeof checkComment === 'string' && <Typography>{data.object.comment}</Typography>*/}
          {typeof checkComment === "object" ? (
            checkComment.map((line) => {
              if (line != "Incoming Contact Comment: ")
                return (
                  <Typography
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      maxWidth: "370px",
                      fontFamily:
                        '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                    }}
                    key={`${line}`}
                  >
                    {"• " + line + "."}
                  </Typography>
                );
              else return <Typography key={`${line}`}>{line}</Typography>;
            })
          ) : (
            <Typography
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                maxWidth: "370px",
                fontFamily:
                  '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
              }}
            >
              {data.object.comment}
            </Typography>
          )}
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
