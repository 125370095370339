import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import useTable from "./useTable";
import Popup from "./Popup";
import Api from "../lib/api";
import Iconify from "./Iconify";
import { useSelector } from "react-redux";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "./controls/IconButton";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import MultiRefundForm from "./forms/MultiRefundForm";
import CRMUtils from "../utils";
import EditMultiRefundForm from "./forms/EditMultiRefundForm";
import { findIndex } from "lodash";
import Controls from "./controls";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { isEmpty } from "lodash";
import { getMessaging, onMessage } from "firebase/messaging";
import ViewLogsTable from "../components/logs/ViewLogsTable";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
  customTooltip1: {
    backgroundColor: "#e3f2fd",
  },
  customArrow1: { color: "gray", borderColor: "#ffffff" },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ContactRefunds(props) {
  const classes = useStyles();
  const { contact_id, consultantErrorHandle = null, rReadonly = false } = props;
  const [refunds, setRefunds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openWinLosePopup, setOpenWinLosePopup] = useState(false);
  const [selectedRefundHeader, setSelectedRefundHeader] = useState(null);
  const [isMultirefundsEditPopupOpen, setIsMultirefundsEditPopupOpen] =
    useState(false);
  const [isMultirefundsPopupOpen, setIsMultirefundsPopupOpen] = useState(false);
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const { authUser } = useSelector((state) => state.auth);
  const { TblContainer } = useTable();
  const { control, watch, errors, handleSubmit } = useForm();
  const wonlost = useRef("");
  const messaging = getMessaging();
  const [openLogPopup, setOpenLogPopup] = useState(false);

  const getRefunds = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api.getContactRefunds(contact_id);

      setRefunds([...data.data]);
      setIsLoading(false);
    } catch (err) {
      //  console.error(err);
      setIsLoading(false);
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(err.data.message);
      }
    }
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const userCanMakeMultirefunds = CRMUtils.userHasPermission(authUser, [
    "invoices_createmultirefund",
  ]);
  const userCanUpdateMultirefunds = CRMUtils.userHasPermission(authUser, [
    "invoices_updatemultirefund",
  ]);
  const userCanDeleteMultirefunds = CRMUtils.userHasPermission(authUser, [
    "invoices_deletemultirefund",
  ]);
  const userCanUnreconcile = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_unreconciliation",
  ]);
  const userCanUnreconcileSuper = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_unreconciliationsuper",
  ]);
  const userCanUnreconcileOnline = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_onlinepaymentsunreconciliation",
  ]);
  const userCanUnreconcileOnlineSuper = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistoryheader_onlinepaymentsunreconciliationsuper",
  ]);
  const userCanSeeLogs =
  CRMUtils.userHasRole(authUser, ["admin", "super-admin"]) ||
  CRMUtils.userHasPermission(authUser, ["invoices_viewLogs"]);

  const headCellsMaterial = [
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{rowData?.paymentHistories?.data[0]?.payment_type || ""}</div>
            {rowData.reconciled ? (
              <Tooltip title={"Reconciled (Can't be updated or deleted)"} arrow>
                <span style={{ marginLeft: 6 }}>
                  <Iconify
                    icon={"ic:round-info"}
                    width={20}
                    height={20}
                    style={{
                      color: "rgb(71, 145, 219)",
                    }}
                  />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "amount",
      title: "Amount",
      render: (rowData) => (
        <ListItem>
          <ListItemText primary={"$" + rowData.amount} />
        </ListItem>
      ),
    },
    {
      field: "cc_last_4",
      title: "Last 4",
      render: (rowData) => rowData?.cc_last_4 || "-",
    },
    {
      field: "tags",
      title: "",
      sorting: false,
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {rowData.paymentHistories &&
            rowData.paymentHistories.data &&
            !isEmpty(rowData.paymentHistories.data) &&
            rowData.paymentHistories.data.length > 1 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText secondary={`Multirefund`} />
                    </Box>
                  }
                  arrow
                >
                  <span>
                    {" "}
                    <IconButton
                      style={{
                        marginLeft: 3,
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"emojione-monotone:letter-m"}
                        width={25}
                        height={25}
                        style={{
                          color: "rgb(175 30 233)",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "building",
      title: "Building",
      render: (rowData) => {
        return (
          <div>{rowData?.paymentHistories?.data[0]?.building_name || ""}</div>
        );
      },
    },
    {
      field: "payment_date",
      title: "Refunded At",
      render: (rowData) => {
        return (
          <div>
            {rowData.payment_date
              ? moment(rowData.payment_date).format("MM/DD/YYYY, HH:mm")
              : ""}
          </div>
        );
      },
    },
    {
      field: "by",
      title: "By",
      render: (rowData) => {
        return (
          <div>
            {rowData?.paymentHistories?.data[0]?.user?.data?.dropdown_name ||
              ""}
          </div>
        );
      },
    },
    {
      field: "file",
      title: "File",
      render: (rowData) => {
        return rowData.file && rowData.file.path ? (
          <a href={rowData.file.path} target="_blank" rel="noreferrer">
            <AttachFileIcon />
          </a>
        ) : (
          ""
        );
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <div className={classes.wrapText3}>
            {rowData.isChargeback == 1 && rowData.refund_state
              ? `CHGBACK ${rowData.refund_state.toUpperCase()}`
              : ""}
          </div>
        );
      },
    },
  ];

  const headCellsMaterialRefunds = [
    {
      field: "amount",
      title: "Amount",
      render: (rowData) => (
        <ListItem>
          <ListItemText primary={"$" + rowData.amount} />
        </ListItem>
      ),
    },
    {
      field: "tags",
      title: "",
      sorting: false,
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {rowData.payments_in_header > 1 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Belongs to a multirefund of $${
                          rowData.payments_in_header_amount
                            ? rowData.payments_in_header_amount.toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"
                        }`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <span>
                    {" "}
                    <IconButton
                      style={{
                        marginLeft: 3,
                        boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                      }}
                    >
                      <Iconify
                        icon={"emojione-monotone:letter-m"}
                        width={25}
                        height={25}
                        style={{
                          color: "rgb(175 30 233)",
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "invoice_name",
      title: "To Invoice",
    },
  ];

  const selectedColumns = () => {
    if (authUser.organization.data.multiple_locations === 1) {
      return headCellsMaterialRefunds;
    }

    return headCellsMaterialRefunds.filter((column) => {
      return column.field != "building_name";
    });
  };

  const renderPaymentDetails = (rowData) => {
    return (
      <Box m={2}>
        <MaterialTable
          options={{
            showTitle: false,
            search: false,
            toolbar: false,
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            headerStyle: {
              backgroundColor: "rgb(201 213 219 / 34%)",
              color: "#00695c",
            },
            paging: false,
          }}
          columns={[
            { title: "Name", field: "item_name" },
            { title: "Amount", field: "amount" },
          ]}
          data={rowData.paymentdetail.data.map((payment) => {
            return {
              item_name: payment.item_name,
              amount: payment.amount,
            };
          })}
          headerColor="#00695c"
        />
      </Box>
    );
  };

  const renderPaymentHistories = (histories) => {
    return (
      <MaterialTable
        columns={selectedColumns()}
        data={histories}
        title=""
        options={{
          showTitle: false,
          search: false,
          toolbar: false,
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          headerStyle: {
            backgroundColor: "rgb(201 213 219 / 34%)",
            color: "#00695c",
          },
          paging: false,
        }}
        toolbar={false}
        showPaging={true}
        pageSize={5}
        pageSizeOptions={[5, 10, 20]}
        detailPanel={(rowData) => {
          return renderPaymentDetails(rowData);
        }}
      />
    );
  };

  const deleteMultipayment = async (id) => {
    try {
      await Api.deleteMultipayment(id);
      setNotify({
        isOpen: true,
        message: "Deleted successfully!",
        type: "success",
      });

      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
      setConfirmDialogLoading(false);

      setRefunds([...refunds.filter((ref) => ref.id != id)]);
    } catch (err) {
      if (
        typeof consultantErrorHandle === "function" &&
        err === "*** This contact is already assigned to another consultant***"
      ) {
        consultantErrorHandle(err.data.message);
      }
      if (err.status == 409 && (err?.data?.error || false)) {
        setNotify({
          isOpen: true,
          message: err.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
      setConfirmDialogLoading(false);
    }
  };

  const closeMultiRefundPopup = () => {
    setIsMultirefundsEditPopupOpen(false);
    setIsMultirefundsPopupOpen(false);
    setSelectedRefundHeader(null);
  };

  const updateRefund = async (payload) => {
    try {
      const { data } = await Api.updateMultirefund(
        payload,
        selectedRefundHeader.id
      );

      const index = findIndex(refunds, ["id", data.data.id]);
      const updatedEvent = {
        ...refunds[index],
        ...data.data,
      };

      let newRefunds = [...refunds];
      newRefunds[index] = updatedEvent;
      setRefunds([...newRefunds]);
      closeMultiRefundPopup();
      setNotify({
        isOpen: true,
        message: "Updated successfully!",
        type: "success",
      });
    } catch (err) {
      // console.error("ERROR!: ", err);
      if (
        typeof consultantErrorHandle === "function" &&
        err === "*** This contact is already assigned to another consultant***"
      ) {
        consultantErrorHandle(err.data.message);
      } else {
        if (err.status == 409 && (err?.data?.error || false)) {
          setNotify({
            isOpen: true,
            message: err.data.error,
            type: "error",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Something went wrong!",
            type: "error",
          });
        }
      }
    }
  };

  const openInPopup = () => {
    setIsMultirefundsPopupOpen(true);
  };

  const openEditPopup = (item = null) => {
    if (item) {
      setSelectedRefundHeader(item);
    }
    setIsMultirefundsEditPopupOpen(true);
  };

  const addRefund = async (payload) => {
    try {
      const { data } = await Api.createMultirefund({ refunds: payload });
      await getRefunds();
      closeMultiRefundPopup();
      setNotify({
        isOpen: true,
        message: "Created successfully!",
        type: "success",
      });
    } catch (err) {
      if (
        typeof consultantErrorHandle === "function" &&
        err === "*** This contact is already assigned to another consultant***"
      ) {
        consultantErrorHandle(err.data.message);
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const markRefundWinLose = (item) => {
    setSelectedRefundHeader(item);
    setOpenWinLosePopup(true);
  };

  const onCloseWonLosePopup = () => {
    setSelectedRefundHeader(null);
    setOpenWinLosePopup(false);
  };

  const changeRefundStatus = async (payload) => {
    try {
      setConfirmDialogLoading(true);
      payload["refund_state"] = wonlost.current;
      const { data } = await Api.changeMultipaymentStatus(
        payload,
        selectedRefundHeader.id
      );

      if (data.deleted) {
        setRefunds([...refunds.filter((a) => a.id != selectedRefundHeader.id)]);
        onCloseWonLosePopup();
        setNotify({
          isOpen: true,
          message: "Updated successfully!",
          type: "success",
        });
        setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
        setConfirmDialogLoading(false);
      } else {
        const index = findIndex(refunds, ["id", data.data.id]);
        const updatedEvent = {
          ...refunds[index],
          ...data.data,
        };

        let newRefunds = [...refunds];
        newRefunds[index] = updatedEvent;
        setRefunds([...newRefunds]);
        onCloseWonLosePopup();
        setNotify({
          isOpen: true,
          message: "Updated successfully!",
          type: "success",
        });
        setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
        setConfirmDialogLoading(false);
      }
    } catch (err) {
      if (
        typeof consultantErrorHandle === "function" &&
        err === "*** This contact is already assigned to another consultant***"
      ) {
        consultantErrorHandle(err.data.message);
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
      setConfirmDialogLoading(false);
    }
  };

  const markAsConfirm = (data) => {
    setConfirmDialog({
      isOpen: true,
      title: `Are you sure to mark this refund as ${wonlost.current} ?`,
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        changeRefundStatus(data);
      },
    });
  };

  const handlePaymentNotification = (notification) => {
    if (
      notification?.data?.type &&
      notification?.data?.type === "merchant_transaction"
    ) {
      getRefunds();
    }
  };

  const unreconcilePayment = async (id) => {
    try {
      setConfirmDialogLoading(true);
      const { data } = await Api.unreconcilePayment(id);
      const index = findIndex(refunds, ["id", data.data.id]);
      const updatedEvent = {
        ...refunds[index],
        ...data.data,
      };
      let newRefunds = [...refunds];
      newRefunds[index] = updatedEvent;
      setRefunds([...newRefunds]);
      setNotify({
        isOpen: true,
        message: "Unreconciled successfully!",
        type: "success",
      });
      setConfirmDialog({
        ...{
          isOpen: false,
        },
        ...confirmDialog,
      });
    } catch (error) {
      if (error.status == 401) {
        setNotify({
          isOpen: true,
          message: "Forbidden",
          type: "error",
        });
      } else {
        if (error.status == 409 && (error?.data?.error || false)) {
          setNotify({
            isOpen: true,
            message: error.data.error,
            type: "error",
          });
        } else {
          console.error(error);
          setNotify({
            isOpen: true,
            message: "Something went wrong!",
            type: "error",
          });
        }
      }
    } finally {
      setConfirmDialogLoading(false);
    }
  };

  useEffect(() => {
    if (contact_id) {
      getRefunds();
    }
  }, [contact_id]);

  
  const closeLogPopup = () => {
    setOpenLogPopup(false);
    setSelectedRefundHeader(null);
  };

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("receiving payload from payment miltirefund", payload);
      handlePaymentNotification(payload);
    });
  }, []);

  return (
    <div>
      <TblContainer>
        <MaterialTable
          detailPanel={(rowData) => {
            return renderPaymentHistories(rowData.paymentHistories.data);
          }}
          columns={headCellsMaterial}
          data={refunds}
          options={{
            loadingType: "linear",
            pageSize: 5, // make initial page size
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            actionsColumnIndex: -1,
            sorting: true,
            headerStyle: {
              backgroundColor: "#e0f2f1",
              color: "#00695c",
            },
          }}
          actions={[
            {
              icon: () => (
                <Fab color="primary" aria-label="add">
                  <Iconify
                    icon={"fluent:add-12-filled"}
                    width={23}
                    height={23}
                  />
                </Fab>
              ),
              tooltip: "New refund",
              hidden:
                !CRMUtils.userHasPermission(authUser, ["refund_create"]) ||
                rReadonly ||
                !userCanMakeMultirefunds,
              isFreeAction: true,
              onClick: (event) => {
                openInPopup();
              },
              position: "toolbar",
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <IconButton
                      disabled={rowData.reconciled}
                      color="primary"
                      aria-label="add"
                    >
                      <Iconify icon={"bxs:pencil"} width={22} height={22} />
                    </IconButton>
                  ),

                  tooltip: "Edit",
                  disabled: rowData.reconciled,
                  hidden:
                    !CRMUtils.userHasPermission(authUser, ["refund_edit"]) ||
                    rReadonly ||
                    !userCanUpdateMultirefunds,
                  onClick: (event) => {
                    openEditPopup(rowData);
                  },
                  position: "row",
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <Controls.IconButton color="secondary">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Iconify
                          style={{ height: 21, width: 21, color: "#18b52c" }}
                          icon="solar:like-bold-duotone"
                        />
                        <Iconify
                          style={{
                            transform: "rotate(180deg)",
                            color: "#cf5353",
                            height: 21,
                            width: 21,
                          }}
                          icon="solar:like-bold-duotone"
                        />
                      </div>
                    </Controls.IconButton>
                  ),

                  tooltip: "Mark as Won/Lost",
                  hidden:
                    !CRMUtils.userHasPermission(authUser, ["refund_winlose"]) ||
                    rReadonly ||
                    !rowData.isChargeback ||
                    rowData.refund_state == "lost",
                  onClick: (event) => {
                    markRefundWinLose(rowData);
                  },
                  position: "row",
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color: "rgb(63 68 73)",
                      }}
                    >
                      <Iconify
                        icon={"tabler:notes-off"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "Unreconcile",

                  hidden:
                    rowData.reconciled &&
                    ((!rowData.merchant_transaction_id &&
                      ((userCanUnreconcile &&
                        rowData.reconciled_by == authUser.id) ||
                        userCanUnreconcileSuper)) ||
                      (rowData.merchant_transaction_id &&
                        ((userCanUnreconcileOnline &&
                          rowData.reconciled_by == authUser.id) ||
                          userCanUnreconcileOnlineSuper)))
                      ? false
                      : true,
                  onClick: (event) => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to unreconcile this payment?",
                      onConfirm: () => {
                        unreconcilePayment(rowData.id);
                      },
                    });
                  },
                  position: "row",
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <IconButton
                      disabled={rowData.reconciled}
                      color="primary"
                      aria-label="remove"
                    >
                      <Iconify
                        icon={"solar:trash-bin-minimalistic-2-bold"}
                        width={22}
                        height={22}
                      />
                    </IconButton>
                  ),
                  tooltip: "Delete payment",
                  hidden:
                    !CRMUtils.userHasPermission(authUser, ["refund_delete"]) ||
                    rReadonly ||
                    !userCanDeleteMultirefunds,
                  onClick: (event, rowData) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this refund?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        console.log("ROW DATA: ", rowData);
                        deleteMultipayment(rowData.id);
                      },
                    }),
                  position: "row",
                  disabled: rowData.reconciled,
                };
              },
            },
            {
              action: (rowData) => {
                return {
                  icon: () => (
                    <IconButton aria-label="add">
                      <Iconify
                        icon={"tabler:eye-filled"}
                        style={{ color: "#7a52d9" }}
                        width={25}
                        height={25}
                      />
                    </IconButton>
                  ),
                  tooltip: "See logs",

                  hidden: !userCanSeeLogs,
                  onClick: (event) => {
                    setSelectedRefundHeader(rowData);
                    setOpenLogPopup(true);
                  },
                  position: "row",
                };
              },
            },
          ]}
          title="Refunds"
          isLoading={isLoading}
        />
      </TblContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmLoading={confirmDialogLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Popup
        title="Update Refund"
        fullWidth={true}
        openPopup={isMultirefundsEditPopupOpen}
        onClose={closeMultiRefundPopup}
      >
        <EditMultiRefundForm
          updateRefund={updateRefund}
          headerForEdit={selectedRefundHeader}
          contact_id={contact_id}
          onClose={closeMultiRefundPopup}
        />
      </Popup>

      <Popup
        title="Make Refund"
        fullWidth={true}
        maxWidth="lg"
        openPopup={isMultirefundsPopupOpen}
        onClose={closeMultiRefundPopup}
      >
        <MultiRefundForm
          createMultiRefund={addRefund}
          contact_id={contact_id}
          onClose={closeMultiRefundPopup}
        />
      </Popup>

      <Dialog
        aria-labelledby="winlose-dialog"
        open={openWinLosePopup}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
          },
        }}
        onClose={onCloseWonLosePopup}
        maxWidth="sx"
        style={{ borderRadius: "50px" }}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit(markAsConfirm)} className={classes.root}>
          <DialogTitle className={classes.dialogTitle}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#212b36",
                  fontFamily: "Nunito,Roboto",
                  fontWeight: 700,
                  fontSize: "1.125rem",
                  margin: 12,
                }}
              >
                How do you like to mark this Refund?
              </Typography>
              <Controls.IconButton
                onClick={onCloseWonLosePopup}
                style={{
                  textAlign: "right",
                  color: "#000000",
                  fontWeight: 700,
                }}
              >
                <CloseIcon />
              </Controls.IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{ marginTop: 20 }}>
            <Controller
              name="refund_state_notes"
              control={control}
              defaultValue=""
              as={
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Comments"
                  error={errors.refund_state_notes}
                  helperText={errors.refund_state_notes?.message}
                  multiline
                  maxRows={4}
                  minRows={4}
                />
              }
              rules={{ required: "Comment is required" }}
            />
          </DialogContent>

          <DialogActions
            dividers={true}
            style={{ justifyContent: "center", margin: 20 }}
          >
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#e0f2f1", margin: 15 }}
              onClick={() => {
                wonlost.current = "won";
              }}
            >
              Won
            </Button>

            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#ffebee", margin: 15 }}
              onClick={() => {
                wonlost.current = "lost";
              }}
            >
              Lost
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Popup
        title="Refund Logs"
        openPopup={openLogPopup}
        onClose={closeLogPopup}
        fullWidth={true}
      >
        <ViewLogsTable
          modelId={selectedRefundHeader?.id || null}
          getLogs={Api.getPaymentHeaderActivitiesLogs}
        />
      </Popup>
    </div>
  );
}

export default ContactRefunds;
