import React, { useState, useEffect } from "react";
import { Card, Typography } from "@material-ui/core";
import Controls from "../../components/controls";
import Iconify from "../../components/Iconify";
import Api from "../../lib/api";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/styles";

export default function CheckInHeader(props) {
  const checkInLogo = process.env.REACT_APP_CHECKINLOGO
    ? process.env.REACT_APP_CHECKINLOGO
    : null;
  const theme = useTheme();
  return (
    <div>
      {checkInLogo ? (
        <div
          style={{
            backgroundColor: "rgb(255 255 255 / 59%)",
            borderBottomLeftRadius: 100,
            borderBottomRightRadius: 100,
            color: theme.palette.checkInLogo,
            textAlign: "left",
            padding: 23,
            boxShadow:
              "0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(81 81 81 / 12%)",
          }}
        >
          <div>
            <img
              style={{ width: 222, height: "auto", marginLeft: "42px" }}
              src="https://crm.beautylandplasticsurgery.com/static/media/logo_beautylandplasticsurgery.f08cd826.png"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "rgb(255 255 255 / 59%)",
            borderBottomLeftRadius: 100,
            borderBottomRightRadius: 100,
            color: theme.palette.checkInLogo,
            textAlign: "center",
            padding: 23,
            boxShadow:
              "0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(81 81 81 / 12%)",
          }}
        >
          <div style={{ justifyContent: "center", width: "100%" }}>
            <Iconify
              style={{ width: 45, height: 45 }}
              icon={"lets-icons:book-check-fill"}
            />
          </div>
          <Typography style={{ fontWeight: 100, fontSize: 36 }}>
            CHECK IN
          </Typography>
        </div>
      )}
    </div>
  );
}
