import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import {
  Typography,
  Grid,
  TextField,
  Box,
  Avatar,
  Switch,
  withStyles,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import CRMUtils from "../../utils";
import Iconify from "../Iconify";
import moment from "moment";
import InputMoneyOk from "../controls/InputMoneyOk";
import { FormProvider } from "../hook-form";
import Api from "../../lib/api";
import ConfirmDialog from "../ConfirmDialog";
import Notification from "../Notification";

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "80px",
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
  dialogTitle2: {
    padding: "5px 10px",
    paddingRight: "0px",
  },
  dialogWrapper: {
    backgroundColor: "#ffffff",
  },
  indicator: {
    color: "#673ab7",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "63%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  primaryHeadingDisabled: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.disabled,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  dialogWrapper2: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
    zindex: 999999,
  },
  error: {
    color: "red",
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

export default function EditRefundForm(props) {
  const { authUser } = useSelector((state) => state.auth);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const methods = useForm();

  const {
    buildings,
    usersDropdown,
    gateways,
    refundReasons,
    payment,
    paymentsData,
    onClose,
  } = props;

  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
    errors,
    setValue,
  } = methods;

  const watchAllFields = watch();

  const [lockDate, setLockDate] = useState(false);

  const [parentPaymentData, setParentPaymentData] = useState([]);

  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);

  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const refundReasonsOptions = () => {
    return refundReasons.map((refundReason) => {
      return {
        id: refundReason.id,
        title: refundReason.name,
      };
    });
  };

  const setValueDateLock = (payment_id, building_id) => {
    if (!userCanEditPaymentDateLock) {
      const building = buildings.find(
        (building) => building.id === building_id
      );

      if (building && building.lock_payment_date) {
        setValue(
          `payment_date-${payment_id}`,
          moment().format("YYYY-MM-DD HH:mm:ss")
        );

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  };

  const checkDateLock = (payment_id, building_id) => {
    if (!userCanEditPaymentDateLock) {
      const building = buildings.find(
        (building) => building.id === building_id
      );

      if (building && building.lock_payment_date) {
        return true;
      }
    }
    return false;
  };

  // const paymentOptions = () => {
  //   let options = gateways.map((gateway) => {
  //     return {
  //       id: gateway.name,
  //       title: gateway.name,
  //     };
  //   });
  //   // options.push({
  //   //   id: "ONLINE DEBIT/CREDIT CARD",
  //   //   name: "ONLINE DEBIT/CREDIT CARD",
  //   // });
  //   return options;
  // };

  const paymentOptions = () => {
    const options = gateways.map((gateway) => ({
      id: gateway.name,
      title: gateway.name,
      selected:
        payment &&
        gateway.name.toLowerCase() === payment.payment_type.toLowerCase(),
    }));

    if (payment && !options.some((option) => option.selected)) {
      options.push({
        id: payment?.payment_type,
        title: payment?.payment_type,
        selected: true,
      });
    }
    return options;
  };

  const getUserOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const onSubmit = async (data) => {
    if (payment && payment.payments_in_header > 1 && !data["confirm"]) {
      setConfirmDialog({
        isOpen: true,
        title:
          "This refunds belongs to a multiple refund that affects other invoices, so all the modifications made except the amount will also be modified in the rest of the refunds belonging to the multiple refund.",
        subTitle: "Are you sure to continue?",
        showAsNotification: false,
        onConfirm: async () => {
          data["confirm"] = true;
          setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
          onSubmit(data);
        },
      });
      return null;
    }
    setIsLoading(true);
    data.payment_id = payment.id;
    const formData = new FormData();

    for (const key in data) {
      if (key === "items_amount") {
        // Assuming items_amount is an object with item IDs as keys and amounts as values
        const itemsAmountObject = data[key];
        for (const item_id in itemsAmountObject) {
          formData.append(
            `items_amount[${item_id}]`,
            itemsAmountObject[item_id]
          );
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    // Replace this with your actual API call
    try {
      const response = await Api.editInvoiceRefund(
        payment.invoice,
        payment.id,
        formData
      );

      onClose();
      setIsLoading(false);
    } catch (error) {
      if (error.status == 409 && (error?.data?.error || false)) {
        setNotify({
          isOpen: true,
          message: error.data.error,
          type: "error",
        });
      } else {
        console.error(error);
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const getParentPaymentData = (parent_id, data) => {
    let match = data.find((item) => item.id === parent_id);
    return match ?? [];
  };

  useEffect(() => {
    if (payment && paymentsData) {
      setParentPaymentData(
        getParentPaymentData(payment.parent_id, paymentsData)
      );
    }
  }, [payment, paymentsData]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        style={{
          justifyContent: "center",
          alignItems: "center",
          margin: 15,
          maxWidth: "99%",
        }}
      >
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"refund_reason_id"}
            control={control}
            defaultValue={payment.refund_reason_id}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Refund Reason"
                options={refundReasonsOptions()}
                onChange={(event) => onChange(event.target.value)}
                value={value}
              />
            )}
            rules={{
              required: "Refund Reason is required",
            }}
          />
          <FormHelperText
            className={classes.error}
            id="refund_reason_id-helper-text"
          >
            {errors[`refund_reason_id-${payment.id}`]
              ? errors[`refund_reason_id-${payment.id}`].message
              : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"payment_type"}
            control={control}
            defaultValue={payment.payment_type}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Payment Type"
                options={paymentOptions()}
                onChange={(event) => onChange(event.target.value)}
                value={value}
              />
            )}
            rules={{
              required: "Payment Type is required",
            }}
          />
          <FormHelperText
            className={classes.error}
            id="payment_type-helper-text"
          >
            {errors["payment_type"] ? errors["payment_type"].message : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"payment_date"}
            type="date"
            control={control}
            defaultValue={payment.date}
            as={({ onChange, value }) => (
              <Controls.DateTimePicker
                label="Date"
                shouldCloseOnSelect
                fullWidth
                disabled={
                  !userCanEditPayment ||
                  lockDate ||
                  checkDateLock(payment.id, payment.building_id)
                }
                onChange={(date) => {
                  const fdate = moment(date).format("yyyy-MM-DD HH:mm:ss");
                  value = fdate;
                  onChange(fdate);
                }}
                value={value}
              />
            )}
            rules={{
              required: "Date is required",
            }}
          />
          <FormHelperText
            className={classes.error}
            id="payment_date-helper-text"
          >
            {errors["payment_date"] ? errors["payment_date"].message : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"building_id"}
            control={control}
            defaultValue={payment.building_id}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Building"
                disabled={!userCanChangeBuilding}
                options={buildingOptions()}
                onChange={(event) => {
                  onChange(event.target.value);
                  setValueDateLock(payment.id, event.target.value);
                }}
                value={value}
              />
            )}
            rules={{ required: "Building is required" }}
          />
          <FormHelperText
            className={classes.error}
            id="building_id-helper-text"
          >
            {errors["building_id"] ? errors["building_id"].message : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name="cc_last_4"
            control={control}
            defaultValue={payment.cc_last_4}
            as={({ onChange, value }) => (
              <TextField
                label="Last 4 CC"
                variant="outlined"
                onChange={(event) => onChange(event.target.value)}
                value={value}
                fullWidth
              />
            )}
          />

          <FormHelperText className={classes.error} id="cc_last_4-helper-text">
            {errors["cc_last_4"] ? errors["cc_last_4"].message : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"cardholder"}
            control={control}
            defaultValue={payment.cardholder}
            as={({ onChange, value }) => (
              <TextField
                label="Card Holder"
                variant="outlined"
                onChange={(event) => onChange(event.target.value)}
                value={value}
                fullWidth
              />
            )}
          />
          <FormHelperText className={classes.error} id="cardholder-helper-text">
            {errors["cardholder"] ? errors["cardholder"].message : " "}
          </FormHelperText>
        </Grid>

        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"responsible_id"}
            control={control}
            defaultValue={payment.responsible_id}
            // rules={{
            //   required: "Responsible is required",
            // }}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Surgeon"
                // disabled={!userCanChangeBuilding}
                options={getUserOptions()}
                onChange={(event) => onChange(event.target.value)}
                value={value}
              />
            )}
          />
          <FormHelperText
            className={classes.error}
            id="responsible_id-helper-text"
          >
            {errors["responsible_id"] ? errors["responsible_id"].message : " "}
          </FormHelperText>
        </Grid>

        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              {watchAllFields["file"] ? (
                <Box
                  width={"100px"}
                  height={"100px"}
                  margin={"auto"}
                  padding={"8px"}
                >
                  {watchAllFields["file"].type === "image/png" ||
                  watchAllFields["file"].type === "image/jpeg" ? (
                    <Avatar
                      alt={"file"}
                      src={window.URL.createObjectURL(watchAllFields["file"])}
                      className={classes.largeAvatar}
                    />
                  ) : (
                    <Avatar
                      className={classes.largeAvatar}
                      style={{
                        backgroundColor: "#B30B00",
                        color: "#ffffff",
                      }}
                    >
                      PDF
                    </Avatar>
                  )}{" "}
                </Box>
              ) : (
                <Box
                  width={"100px"}
                  height={"100px"}
                  margin={"auto"}
                  borderRadius={"50%"}
                  padding={"8px"}
                  border={"1px dashed rgba(145, 158, 171, 0.32)"}
                >
                  <Box
                    className="placeholder"
                    style={{
                      Zindex: 0,
                      width: "100%",
                      height: "100%",
                      outline: "none",
                      overflow: "hidden",
                      flexDirection: "column",
                      position: "relative",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      color: "#637381",
                      backgroundColor: "#F4F6F8",
                      "&:hover": { opacity: 0.72 },
                      display: "flex",
                    }}
                  >
                    <Iconify
                      icon={"ic:baseline-receipt-long"}
                      style={{
                        width: 24,
                        height: 24,
                        marginBottom: 1,
                      }}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      Receipt
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"file"}
                control={control}
                defaultValue=""
                as={({ onChange, value }) => (
                  <Controls.DropzoneDialog
                    label="Attach file"
                    style={{
                      backgroundColor: "#e3f2fd",
                      borderRadius: 50,
                      color: "#01579b",
                      marginTop: 10,
                    }}
                    onChange={(files) => {
                      onChange(files[0]);
                    }}
                    value={value}
                  />
                )}
                // rules={{
                //   required: "Attach file is required",
                // }}
              />
              <FormHelperText className={classes.error} id="file-helper-text">
                {errors["file"] ? errors["file"].message : ""}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Controller
            name={"details"}
            control={control}
            defaultValue={payment.details}
            as={({ onChange, value }) => (
              <TextField
                label="Details"
                variant="outlined"
                onChange={(event) => onChange(event.target.value)}
                value={value}
                fullWidth
              />
            )}
          />
          <FormHelperText className={classes.error} id="details-helper-text">
            {errors["details"] ? errors["details"].message : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Grid item xs={7}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Controller
                    name={"ptChangePayment"}
                    control={control}
                    defaultValue={payment.ptChangePayment}
                    render={({ onChange, value }) => (
                      <SSwitch
                        key={"ptChangePayment"}
                        checked={value}
                        onChange={(value) => {
                          onChange(value.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography
                      variant="subtitle2"
                      style={{
                        marginBottom: 0.5,
                        color: "#607d8b",
                        textAlign: "center",
                      }}
                    >
                      Patient Change Payment
                    </Typography>
                  </>
                }
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Controller
                    name={"isChargeback"}
                    control={control}
                    defaultValue={payment.isChargeback}
                    render={({ onChange, value }) => (
                      <SSwitch
                        key={"isChargeback"}
                        checked={value}
                        onChange={(value) => {
                          onChange(value.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography
                      variant="subtitle2"
                      style={{
                        marginBottom: 0.5,
                        color: "#607d8b",
                        textAlign: "center",
                      }}
                    >
                      Chargeback
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            {payment?.paymentdetail?.data?.map((item, index) => {
              let matchingDetail = parentPaymentData?.paymentsDetail?.find(
                (detail) => detail.id === item.parent_detail_id
              );
              return (
                <>
                  <Grid item xs={6}>
                    <Typography
                      // className={
                      //   item.type === "forfeiture" ||
                      //   item.type === "system" ||
                      //   (item.amount ? item.amount : 0) -
                      //     (item?.refund_payment_details_simple_sum_amount || 0) <
                      //     0 ||
                      //   item.void === 1
                      //     ? classes.primaryHeadingDisabled
                      //     : classes.primaryHeading
                      // }
                      key={`item_name-${item.item_id}`}
                    >
                      {item.item_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={
                        item.item_type === "forfeiture" ||
                        item.item_type === "system" ||
                        (item.amount ? item.amount : 0) -
                          (matchingDetail?.refund_payment_details_simple_sum_amount ||
                            0) <
                          0 ||
                        item.void === 1
                          ? classes.primaryHeadingDisabled
                          : classes.primaryHeading
                      }
                      key={`refundableamount_${item.id}`}
                    >
                      {`Refundable: $${
                        item.item_type === "forfeiture" ||
                        item.item_type === "system"
                          ? "0.00"
                          : (
                              payment.amount -
                              (matchingDetail?.refund_payment_details_simple_sum_amount -
                                matchingDetail?.amount)
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InputMoneyOk
                      // name={`items_amount[${item.item_id}[${item.parent_id}]]`}
                      // name="amount"
                      name={`items_amount[${item.item_id}]`}
                      defaultValue={item.amount}
                      label="Amount"
                      type="number"
                      disabled={
                        item.item_type === "forfeiture" ||
                        item.item_type === "system" ||
                        (item.amount ? item.amount : 0) -
                          (item?.refunded_amount || 0) <
                          0 ||
                        item.void === 1
                      }
                      maxValue={
                        item.item_type === "forfeiture" ||
                        item.item_type === "system"
                          ? "0.00"
                          : (
                              payment.amount -
                              (matchingDetail?.refund_payment_details_simple_sum_amount -
                                matchingDetail?.amount)
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }
                      minValue={userCanEditNegativePayments ? "-999999" : "0"}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ justifyContent: "flex-end" }}>
        <Controls.Button type="submit" text="Submit" disabled={isLoading} />
      </Grid>
      <ConfirmDialog
        disableConfirm={isSubmitting}
        confirmLoading={isSubmitting}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </FormProvider>
  );
}
