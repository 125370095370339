import { isInteger } from "lodash";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import RefundInput from "./RefundInput";
import RefundView from "./RefundView";
import RefundFilter from "./RefundFilter";
export const TicketsOptions = ({ option, selected }) => {
    return (
        <Card
            style={{
                width: "100%",
            }}
        >
            <CardContent
                style={{
                    backgroundColor: selected ? "lightgreen" : "inherit",
                }}
            >
                <Grid container direction="row">
                    <Grid item container direction="column" md={8}>
                        <Grid item xs={9} container direction="column">
                            <Typography variant="subtitle1">
                                {option?.subject}
                            </Typography>
                            <Typography variant="caption">
                                {option?.user_assigned?.dropdown_name}
                            </Typography>
                            <Box display={"flex"} gridGap={2}>
                                <Typography variant="caption">
                                    Priority:
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: option?.priority?.color,
                                    }}
                                >
                                    {option?.priority?.name}
                                </Typography>
                            </Box>
                            <Box display={"flex"} gridGap={2}>
                                <Typography variant="caption">
                                    State:
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: option?.get_state?.color,
                                    }}
                                >
                                    {option?.get_state?.name}
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                {moment(option?.created_at).format(
                                    "MM/DD/yyyy"
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container direction="column" md={4}>
                        <Typography variant="subtitle1">
                            {option?.building?.name}
                        </Typography>
                        <Typography variant="caption">
                            {option?.department?.name}
                        </Typography>
                        <Typography variant="caption">
                            {option?.user_assigned?.fname}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export const optionLabelUser = (option, single) => {
    if (isInteger(option)) {
        if (option == single?.id) {
            return `${single.subject} - ${single?.priority?.name} - ${
                single?.get_state?.name
            } - ${moment(single?.created_at).format("MM/DD/yyyy")}`;
        }
    } else {
        return `${option.subject} - ${option?.priority?.name} - ${
            option?.get_state?.name
        } - ${moment(option?.created_at).format("MM/DD/yyyy")}`;
    }
};


const EntityTypeRefundTask = "App\\Models\\RfsTickets";

const RefundTask = ({ entityId, isUpdate, isFilter = false }) => {
    
    if (isFilter) { //for using to get filters specific to task type
        return <RefundFilter/>
    }

    //when creating parent element this output if for selecting
    if (!isUpdate) {
        return (
            <RefundInput EntityTypeRefundTask = {EntityTypeRefundTask} entityId={entityId}/>
        );
    }

    if (isUpdate) {
        //if is updating this field es for read only
        return (
            <RefundView entityId={entityId} />
        );
    }
};

export default RefundTask;
