import React, { useEffect, useState, useRef } from "react";
import { find, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  fetchTemplatesService,
  cleanTemplatesService,
} from "../redux/slices/recordProductTemplates";

import { fetchBuildingsService } from "../redux/slices/buildings";
import ReactToPrint from "react-to-print";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Paper,
  Card as MuiCard,
  makeStyles,
  Grid,
  Fab,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import SendIcon from "@material-ui/icons/Send";
import Controls from "./controls";
import reactStringReplace from "react-string-replace";

import styled from "styled-components";
import Api from "../lib/api";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";

import { fetchEntitiesService as fetchTemplatesCategoriesNewService } from "../redux/slices/templatesCategoriesSlice.js";

var dataSliceTemplatesCategoriesNew = {
  stateListName: "templatesCategoriesNew",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
const useStyles = makeStyles((theme) => ({
  pageContent: {
    position: "relative",
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  docHtmlWrapper: {
    padding: "5px",
    marginTop: "5px",
    marginRight: "5px",
  },
  docPageBreack: {
    border: "1px red solid",
  },
  docContent: {
    margin: "5px",
    padding: "1px",
    borderRadius: "3px",
  },
  speedDial: {
    position: "absolute",
    right: theme.spacing(10),
  },
}));

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: "block";
  height: 100%;
  width: 100%;
`;

function RecordProductTemplateViewer(props) {
  const { recordProductForEdit, billing = false } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["templates", "common", "records"]);
  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);

  dataSliceTemplatesCategoriesNew.orgId = authUser.organization_id;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const docRef = useRef();
  const allDocsRef = useRef();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [selectedDocumentType, setSelectedDocumentType] = useState(-1);
  const [selectedBuilding, setSelectedBuilding] = useState(-1);
  const [loadedDocument, setLoadedDocument] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { recordProductTemplates, loading, errors } = useSelector(
    (state) => state.recordProductTemplates
  );

  const { templatesCategoriesNew } = useSelector(
    (state) => state.templatesCategoriesNew
  );

  const { buildings } = useSelector((state) => state.buildings);

  const documentTypeOptions = () => {
    return templatesCategoriesNew.map((docType) => {
      return {
        id: docType.id,
        title: docType.name,
      };
    });
  };

  const documentOptions = () => {
    return recordProductTemplates
      .filter((doc) => {
        if (selectedDocumentType === -1) return true;

        return doc.templates_category_id === selectedDocumentType;
      })
      .filter((doc) => {
        if (selectedBuilding === -1) return true;

        return doc.building_id === selectedBuilding;
      })
      .map((doc) => {
        return {
          id: doc.id,
          title: doc.name,
        };
      });
  };
  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };
  const htmlReplaceTokens = (html) => {
    const customFields = recordProductForEdit.record
      ? JSON.parse(recordProductForEdit.record.data.properties)
      : {};
    /* let company_logo = recordProductForEdit.invoice.data.organization
          ? recordProductForEdit.invoice.data.organization.data.logo
          : "";
        html =
          "<img src=" +
          company_logo +
          ' alt="Logo"style="display: block;  margin-left: auto;  margin-right: auto; ">' +
          html;*/
    html = reactStringReplace(html, "{{CUSTOMER_FNAME}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact.fname;
    });
    html = reactStringReplace(html, "{{CHARTID}}", (match, i) => {
      return recordProductForEdit.record
        ? recordProductForEdit.record.data.chart_id
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_LNAME}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact.lname;
    });
    html = reactStringReplace(html, "{{CUSTOMER_EMAIL}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact
        ? recordProductForEdit.invoice.data.contact.email
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_PHONE}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact
        ? recordProductForEdit.invoice.data.contact.phone
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_ADDRESS}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact
        ? recordProductForEdit.invoice.data.contact.address
          ? recordProductForEdit.invoice.data.contact.address
          : "" + recordProductForEdit.invoice.data.contact.city
          ? recordProductForEdit.invoice.data.contact.city
          : "" + recordProductForEdit.invoice.data.contact.state
          ? recordProductForEdit.invoice.data.contact.state
          : "" + recordProductForEdit.invoice.data.contact.zip
          ? recordProductForEdit.invoice.data.contact.zip
          : ""
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_AGE}}", (match, i) => {
      return recordProductForEdit.record
        ? recordProductForEdit.record.data.age
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_SEX}}", (match, i) => {
      return recordProductForEdit.record
        ? recordProductForEdit.record.data.sex
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_HEIGHT}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact
        ? recordProductForEdit.invoice.data.contact.height
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_WEIGHT}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact
        ? recordProductForEdit.invoice.data.contact.weight
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_BMI}}", (match, i) => {
      return recordProductForEdit.invoice.data.contact.bmi;
    });
    html = reactStringReplace(html, "{{CUSTOMER_HGB}}", (match, i) => {
      return recordProductForEdit.record
        ? recordProductForEdit.record.data.hgb
        : "";
    });
    html = reactStringReplace(
      html,
      "{{CUSTOMER_MARITALSTATUS}}",
      (match, i) => {
        return recordProductForEdit.invoice.data.contact.marital_status;
      }
    );
    html = reactStringReplace(html, "{{CUSTOMER_SMOKE}}", (match, i) => {
      return customFields ? customFields.smoke : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_ALCOHOL}}", (match, i) => {
      return customFields ? customFields.alcohol : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_DISEASES}}", (match, i) => {
      return customFields ? customFields.diseases : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_ALLERGIES}}", (match, i) => {
      return customFields ? customFields.allergies : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_MEDICATIONS}}", (match, i) => {
      return customFields ? customFields.medications : "";
    });
    html = reactStringReplace(
      html,
      "{{CUSTOMER_FAMILY_HISTORY}}",
      (match, i) => {
        return customFields ? customFields["family-history"] : "";
      }
    );
    html = reactStringReplace(html, "{{CUSTOMER_MR_NUMBER}}", (match, i) => {
      return recordProductForEdit.record
        ? recordProductForEdit.record.data.id
        : "";
    });
    html = reactStringReplace(html, "{{CUSTOMER_DOB}}", (match, i) => {
      return recordProductForEdit.invoice
        ? recordProductForEdit.invoice.data.contact.dob
        : "";
    });

    html = reactStringReplace(html, "{{ORGANIZATION_NAME}}", (match, i) => {
      return recordProductForEdit.invoice
        ? recordProductForEdit.invoice.data.organization_name
        : "";
    });
    html = reactStringReplace(html, "{{ORGANIZATION_ADDRESS}}", (match, i) => {
      return recordProductForEdit.invoice
        ? recordProductForEdit.invoice.data.organization_address
        : "";
    });
    html = reactStringReplace(html, "{{ORGANIZATION_PHONE}}", (match, i) => {
      return recordProductForEdit.invoice
        ? recordProductForEdit.invoice.data.organization_phone
        : "";
    });
    html = reactStringReplace(html, "{{ORGANIZATION_FAX}}", (match, i) => {
      return recordProductForEdit.invoice
        ? recordProductForEdit.invoice.data.organization_fax
        : "";
    });

    html = reactStringReplace(html, "{{ORGANIZATION_PHONE}}", (match, i) => {
      return recordProductForEdit.invoice
        ? recordProductForEdit.invoice.data.organization_phone
        : "";
    });

    html = reactStringReplace(
      html,
      "{{RESPONSIBLE_NATIONAL_PROVIDER_IDENTIFIER}}",
      (match, i) => {
        return recordProductForEdit.responsible
          ? recordProductForEdit.responsible.data.national_provider_identifier
          : "";
      }
    );
    html = reactStringReplace(html, "{{RESPONSIBLE_FULL_NAME}}", (match, i) => {
      return recordProductForEdit.responsible
        ? recordProductForEdit.responsible.data.title
        : "";
    });
    html = reactStringReplace(html, "{{DX_PREOP}}", (match, i) => {
      return recordProductForEdit.pre_date
        ? moment(recordProductForEdit.pre_date).format("MM/DD/YYYY")
        : "";
    });

    html = reactStringReplace(html, "{{SURGERY_DATE}}", (match, i) => {
      return recordProductForEdit.date
        ? moment(recordProductForEdit?.date).format("MM/DD/YYYY")
        : "";
    });
    html = reactStringReplace(html, "{{PROCEDURE}}", (match, i) => {
      let string = "";
      if (recordProductForEdit.recordProductsSDate) {
        recordProductForEdit.recordProductsSDate.map((itemsName) => {
          string = string + itemsName + ", ";
        });

        string = string.slice(0, -2);
      }

      return recordProductForEdit.recordProductsSDate
        ? string
        : recordProductForEdit.invoiceItem
        ? recordProductForEdit.invoiceItem.data.name
        : "";
    });
    html = reactStringReplace(html, "{{DATE}}", (match, i) => {
      return moment().format("MM/DD/YYYY");
    });

    html = reactStringReplace(html, "{{RESPONSIBLE_SIGNATURE}}", (match, i) => {
      /*return recordProductForEdit.responsible
        ? `<div style="margin-left: 60%;"  ><div style="background-image: url(${recordProductForEdit.responsible.data.signature});background-position:
         right;background-repeat: no-repeat;background-size:cover;width:280px;height:75px"  /> </div>`
        : "";*/
      return `<table style="width:100%"><tr> <td style="width:66%"></td> </td> <td style="width:34%"><img src="${recordProductForEdit.responsible.data.signature_url}"
         alt="Signature" style="width: 190px; height: auto;" /></td> </tr> </table>`;

      /*     return `<table style="width:100%"><tr> <td style="width:66%"></td> </td> <td style="width:34%"><img src="${recordProductForEdit.responsible.data.signature}"
         alt="Signature" width="190" height="auto" /></td> </tr> </table>`; */
    });

    html = reactStringReplace(
      html,
      "{{RESPONSIBLE_PRIVILEGES}}",
      (match, i) => {
        return recordProductForEdit.responsible
          ? recordProductForEdit.responsible.data.privileges
          : "";
      }
    );
    html = reactStringReplace(html, "{{BUILDING_NAME}}", (match, i) => {
      return recordProductForEdit.building
        ? recordProductForEdit.building.data.name
        : "";
    });
    html = reactStringReplace(html, "{{BUILDING_ADDRESS}}", (match, i) => {
      return recordProductForEdit.building
        ? recordProductForEdit.building.data.address
        : "";
    });

    return html;
  };

  const createDocMarkup = () => {
    let html = decodeURI(loadedDocument.html);
    html = htmlReplaceTokens(html);

    return { __html: html.join(" ") };
  };

  const createAllDocsMarkup = () => {
    const htmlDocs = recordProductTemplates
      .filter((doc) => {
        if (selectedDocumentType === -1) return true;

        return doc.templates_category_id === selectedDocumentType;
      })
      .filter((doc) => {
        if (selectedBuilding === -1) return true;

        return doc.building_id === selectedBuilding;
      })
      .map((doc) => {
        return htmlReplaceTokens(
          decodeURI(`<div style="page-break-after: always;">${doc.html}</div>`)
        ).join("");
      });

    return { __html: htmlDocs.join("") };
  };

  const onSend = async () => {
    const html = document.getElementById("sendemail");

    let tempc = html.querySelectorAll("input[type=checkbox]");
    let html2 = html.innerHTML;

    let newhtml = "";
    tempc.forEach((element) => {
      if (element.checked) {
        html2 = html2.replace(
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True">',
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked disabled >'
        );

        html2 = html2.replace(
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked>',
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked disabled >'
        );

        html2 = html2.replace(
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked="true">',
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked disabled >'
        );
      } else {
        html2 = html2.replace(
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked>',
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" disabled >'
        );
        html2 = html2.replace(
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" checked="true">',
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" disabled >'
        );
        html2 = html2.replace(
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True">',
          '<input type="checkbox" id="' +
            element.id +
            '" name="' +
            element.id +
            '" value="True" disabled >'
        );
      }
    });
    //

    //return;
    if (loadedDocument) {
      const payload = {
        doc_name: loadedDocument.name,
        html: encodeURI(html2),
        id: selectedDocument,
        /*
        sms:
          loadedDocument && loadedDocument.sms
            ? loadedDocument.sms.toString()
            : "",
        */
      };

      if (selectedBuilding) {
        const sbuilding = find(buildings, { id: selectedBuilding });
        // console.log("sbuilding ", sbuilding);
        if (sbuilding) payload.building = sbuilding.name;
      }

      try {
        const { data } = await Api.sendTemplate(
          recordProductForEdit.record.data.id,
          payload
        );
        if (data) {
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          setNotify({
            isOpen: true,
            message: "Document Sent Successfully",
            type: "success",
          });
        }
      } catch (error) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });

        setNotify({
          isOpen: true,
          message: error?.data?.message,
          type: "error",
        });
      }

      // Api.sendTemplate(recordProductForEdit.record.data.id, payload).then(
      //   () => {
      //     setConfirmDialog({
      //       ...confirmDialog,
      //       isOpen: false,
      //     });
      //     setNotify({
      //       isOpen: true,
      //       message: "Document Sent Successfully",
      //       type: "success",
      //     });
      //   }
      // );
    }
  };

  const actions = [
    {
      icon: (
        <ReactToPrint
          trigger={() => <PrintIcon />}
          content={() => docRef.current}
        />
      ),
      name: "Print",
      operation: "print",
    },
    {
      icon: <SendIcon />,
      name: "Send to email",
      operation: "send",
    },
  ];

  const handleClickSubMenu = (e, operation) => {
    e.preventDefault();
    if (operation == "send") {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to send this document?",
        subTitle: "You can't undo this operation",
        onConfirm: () => onSend(),
      });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedDocument) {
      const doc = find(recordProductTemplates, { id: selectedDocument });

      setLoadedDocument(doc);
    } else {
      setSelectedDocument(0);
      setLoadedDocument(null);
    }
  }, [selectedDocument]);

  useEffect(() => {
    const orgId = authUser.organization_id;

    if (templatesCategoriesNew) {
      dispatch(
        fetchTemplatesCategoriesNewService(dataSliceTemplatesCategoriesNew)
      );
    }
    if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));
  }, []);

  useEffect(() => {
    if (
      authUser &&
      authUser.organization.data.recordproduct_template_variant == 1 &&
      selectedBuilding > 0
    ) {
      dispatch(
        fetchTemplatesService(
          authUser.organization_id,
          selectedBuilding,
          billing ? 2 : 1
        )
      );
    }
  }, [selectedBuilding]);

  return (
    <Paper className={classes.pageContent}>
      <Grid container spacing={2}>
        {authUser.organization.data.multiple_locations === 1 && (
          <Grid item xs={2}>
            <Controls.Select
              label="Building"
              allowEmpty={true}
              emptyLabel="ALL"
              options={buildingOptions()}
              onChange={(el) => setSelectedBuilding(el.target.value)}
              value={selectedBuilding}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <Controls.Select
            label="SIDE"
            allowEmpty={true}
            emptyLabel="ALL"
            options={documentTypeOptions()}
            onChange={(el) => setSelectedDocumentType(el.target.value)}
            value={selectedDocumentType}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Select
            label={t("records:records.lDocuments")}
            allowEmpty={true}
            options={documentOptions()}
            onChange={(el) => setSelectedDocument(el.target.value)}
            value={selectedDocument}
          />
        </Grid>
        <Grid item xs={1}>
          {selectedDocumentType >= 1 && (
            <ReactToPrint
              trigger={() => (
                <Fab color="primary" variant="extended" aria-label="Print All">
                  <PrintIcon />
                  Print All
                </Fab>
              )}
              content={() => allDocsRef.current}
            />
          )}
        </Grid>
      </Grid>
      {loadedDocument && (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Wrapper>
              <div
                ref={docRef}
                className={classes.docHtmlWrapper}
                id="sendemail"
              >
                {loadedDocument ? (
                  <div
                    dangerouslySetInnerHTML={createDocMarkup()}
                    className={classes.docContent}
                  />
                ) : (
                  <p>Select Document to load</p>
                )}
              </div>
            </Wrapper>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={"Print"}>
              <IconButton>
                <ReactToPrint
                  trigger={() => <PrintIcon> </PrintIcon>}
                  content={() => docRef.current}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Send to email"}>
              <IconButton
                onClick={(e) => {
                  handleClickSubMenu(e, "send");
                }}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {selectedDocumentType >= 1 && (
        <div style={{ display: "none" }}>
          <div ref={allDocsRef} className={classes.docHtmlWrapper}>
            <div
              dangerouslySetInnerHTML={createAllDocsMarkup()}
              className={classes.docContent}
            />
          </div>
        </div>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} autoHide={6000} />
    </Paper>
  );
}

export default RecordProductTemplateViewer;
