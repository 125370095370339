import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { fetchContractsService } from "../redux/slices/contracts";
import { fetchBuildingsService } from "../redux/slices/buildings";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ContactsIcon from "@material-ui/icons/Contacts";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import Controls from "./controls";
import Alert from "@material-ui/lab/Alert";
import Api from "../lib/api";
import Popup from "./Popup";
import { AccountCircle } from "@material-ui/icons";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { color, width } from "@material-ui/system";
import Iconify from "./Iconify";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
}));

function SendContract(props) {
  const { model_id, model, contact, onSentContract } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("contracts");

  const { authUser } = useSelector((state) => state.auth);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [openSelectContracts, setOpenSelectContracts] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(null);
  const { buildings } = useSelector((state) => state.buildings);
  const [requiresExternalSigner, setRequiresExternalSigner] = useState();
  const [selectedBuilding, setSelectedBuilding] = useState(
    buildings.length === 1 ? buildings[0].id : 0
  );
  const { contracts, loading, errors } = useSelector(
    (state) => state.contracts
  );
  const [externalSignerInformation, setExternalSignerInformation] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  });
  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  });

  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const handleContractChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    const allowMultiSelect =
      authUser.organization.data.allow_to_send_multiple_contracts_at_once;

    if (allowMultiSelect) {
      // console.log(value, checked);
      if (checked) {
        setSelectedContracts([...selectedContracts, parseInt(value)]);
      } else {
        setSelectedContracts(
          selectedContracts.filter((c) => c !== parseInt(value))
        );
      }
    } else {
      setSelectedContracts([parseInt(value)]);
      hanldeCloseSelectContracts();
    }
  };

  const sendContract = async (verifyIfExternalSignerIsRequired = true) => {
    let localRequiresExternalSigner = false;
    if (verifyIfExternalSignerIsRequired) {
      localRequiresExternalSigner = contracts.some(
        (contract) =>
          selectedContracts.includes(contract.contract_id) &&
          contract.requiresExternalSigner == true
      );
      setRequiresExternalSigner(localRequiresExternalSigner);
    }

    if (!verifyIfExternalSignerIsRequired || !localRequiresExternalSigner) {
      setRequiresExternalSigner(false);
      const payload = {
        contact_id: contact.id,
        model: model,
        model_id: model_id,
        contracts: selectedContracts,
        subject: subject,
        message: message,
        building_id: selectedBuilding ? selectedBuilding : 0,
        externalSignerInformation: externalSignerInformation,
      };

      const { data } = await makeContractRequest(payload);
      await sendRequest(data.data.id, payload);
      setStep(3);

      // console.log("contract request ", payload);
      onSentContract(data.data);
    }
  };

  const makeContractRequest = async (payload) => {
    setStep(1);
    wait(5000);
    return Api.createContractRequest(authUser.organization_id, payload);
  };

  const sendRequest = async (id, payload) => {
    setStep(2);
    wait(5000);
    return Api.sendContractRequest(authUser.organization_id, id, payload);
  };

  const renderProgress = () => {
    switch (step) {
      case 1:
        return (
          <Alert icon={<ContactsIcon fontSize="inherit" />} severity="success">
            Preparing contract for {contact.full_name}
          </Alert>
        );
        break;
      case 2:
        return (
          <Alert icon={<DraftsIcon fontSize="inherit" />} severity="success">
            Packing all to be send
          </Alert>
        );
        break;
      case 3:
        return (
          <Alert
            icon={<CheckCircleIcon fontSize="inherit" />}
            severity="success"
          >
            Contracts sent!
          </Alert>
        );
        break;
      default:
      // code block
    }
  };

  async function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // External Signer Form validation
  // // Helper function to update formErrors in the state
  const updateFormErrors = (fieldName, errorMessage) => {
    console.log("logingsdf 3 " + fieldName + " >>> " + errorMessage);
    // Update formErrors in the state

    setFormErrors((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };
  const validateExternalSignerField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
        console.log("logingsdf 1");
        // Full Name validation
        if (!value || value.trim() === "") {
          console.log("logingsdf 2 " + value);
          updateFormErrors("firstName", "The First Name is Required");
          return false;
        } else {
          updateFormErrors("firstName", null);
          return true;
        }

      case "lastName":
        console.log("logingsdf 1");
        // Full Name validation
        if (!value || value.trim() === "") {
          console.log("logingsdf 2 " + value);
          updateFormErrors("lastName", "The Last Name is Required");
          return false;
        } else {
          updateFormErrors("lastName", null);
          return true;
        }

      case "email":
        // Email Address validation
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!value || value.trim() === "") {
          updateFormErrors("email", "The Email Address is Required");
          return false;
        } else if (!emailRegex.test(value)) {
          updateFormErrors("email", "The Email Address is Not Valid");
          return false;
        } else {
          updateFormErrors("email", null);
          return true;
        }

      case "phone":
        // Phone Number validation
        const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        if (!value || value.trim() === "") {
          updateFormErrors("phone", "The Phone Number is Required");
          return false;
        } else if (!phoneRegex.test(value)) {
          updateFormErrors("phone", "The Phone Number is Not Valid");
          return false;
        } else {
          updateFormErrors("phone", null);
          return true;
        }

      default:
        return false;
    }
  };

  // Handle change in External Signer fields
  const handleExternalSignerFieldChange = (e) => {
    const { name, value } = e.target;
    console.log("typing in name value " + name + " " + value);

    setExternalSignerInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // // Submit External Signer Information
  const submitExternalSignerInformation = () => {
    let isSubmitDisabled = false;

    // Iterate through formErrors and validate each field
    Object.keys(externalSignerInformation).forEach((key) => {
      const value = externalSignerInformation[key];
      let validationResult = validateExternalSignerField(key, value);

      // If validation fails, log the field
      if (!validationResult) {
        console.log("receiving wrong " + key);
        isSubmitDisabled = true;
      }
    });

    // Log whether submit is disabled or not
    if (!isSubmitDisabled) {
      console.log("sendingocntract");
      sendContract(false);
    } else {
      console.log("notsedning cause submit is diasbled");
    }
  };

  const hanldeOpenSelectContracts = () => {
    setOpenSelectContracts(true);
  };
  const hanldeCloseSelectContracts = () => {
    setOpenSelectContracts(false);
  };
  const renderContracts = (multiSelect = true) => {
    let myContracts = contracts;
    if (authUser.organization.data.multiple_locations === 1) {
      myContracts = contracts.filter((c) => {
        return c.building_id == selectedBuilding;
      });
    }

    if (multiSelect) {
      return (
        <>
          <Typography>Available Contracts</Typography>
          {myContracts.map((contract) => {
            return (
              <>
                {Boolean(contract.is_active) && (
                  <FormControlLabel
                    key={contract.id}
                    control={
                      <Checkbox
                        checked={selectedContracts.includes(
                          contract.contract_id
                        )}
                        onChange={handleContractChange}
                        name="contract"
                        value={contract.contract_id}
                      />
                    }
                    label={contract.name}
                  />
                )}
              </>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <Typography style={{ color: "black" }}>Contract to Send:</Typography>
          <Select
            open={openSelectContracts}
            onClose={hanldeCloseSelectContracts}
            onOpen={hanldeOpenSelectContracts}
            value={selectedContracts[0]}
            onChange={handleContractChange}
            style={{
              width: 250,
              marginTop: 5,
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {myContracts.map((contract) => {
              return (
                <MenuItem value={contract.contract_id}>
                  {contract.name}
                </MenuItem>
              );
            })}
          </Select>
        </>
      );
    }
  };

  useEffect(() => {
    console.log("formerror " + formErrors);
  }, [formErrors]);

  useEffect(() => {
    const orgId = authUser.organization_id;

    dispatch(fetchContractsService(orgId));

    if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));
  }, []);

  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.paper}>
      {authUser.organization.data.multiple_locations === 1 && (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Controls.Select
              label="Building"
              fullWidth
              options={buildingOptions()}
              onChange={(el) => setSelectedBuilding(el.target.value)}
              value={selectedBuilding}
            />
          </Grid>
          <Grid item xs={4}>
            {!!(
              selectedBuilding &&
              !authUser.organization.data
                .allow_to_send_multiple_contracts_at_once
            ) && renderContracts(false)}
          </Grid>
        </Grid>
      )}

      {authUser.organization.data.multiple_locations === 0 ||
      selectedBuilding ? (
        <Box mt={5}>
          {!!authUser.organization.data
            .allow_to_send_multiple_contracts_at_once && renderContracts(true)}
          <Box m={2} pt={3}>
            <Grid container spacing="2">
              <Grid item xs="12">
                <TextField
                  fullWidth={true}
                  label="Subject"
                  placeholder="Email subject"
                  variant="outlined"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>

              <Grid item xs="12">
                <TextField
                  fullWidth={true}
                  label="Body"
                  placeholder="Email body"
                  variant="outlined"
                  multiline
                  rows={2}
                  rowsMax={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>

          {!step && (
            <FormControl>
              <Controls.Button
                disabled={selectedContracts.length == 0}
                variant="contained"
                color="secondary"
                text="Send"
                startIcon={<SendIcon />}
                onClick={sendContract}
              />
            </FormControl>
          )}

          {step && (
            <Box m={2} pt={3}>
              {renderProgress()}
            </Box>
          )}
        </Box>
      ) : (
        <Box mt={5}>Select Building First</Box>
      )}
      <Popup
        openPopup={requiresExternalSigner}
        onClose={() => setRequiresExternalSigner(false)}
        maxWidth={"xs"}
      >
        <Card style={{ padding: "10px 10px" }}>
          <CardHeader
            title="This contract requires an External Signer Information"
            subheader="Complete the fields below with information about the designated signer. Please ensure the provided information is accurate; it will be used in the contract"
            subheaderTypographyProps={{ style: { marginTop: 5 } }}
          />
          <CardActionArea>
            <CardContent>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={1}>
                  <Iconify
                    icon="solar:user-circle-bold"
                    style={{ width: 23, height: 23, color: "#4b4e65" }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    id="input-with-icon-grid"
                    name="firstName"
                    label="First Name"
                    type="text"
                    inputProps={{ maxLength: 50 }}
                    required
                    value={externalSignerInformation?.firstName || ""}
                    onChange={(e) =>
                      handleExternalSignerFieldChange(e, "firstName")
                    }
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "red" }}
                  >
                    {formErrors?.firstName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={1}>
                  <Iconify
                    icon="solar:user-circle-bold"
                    style={{ width: 23, height: 23, color: "#4b4e65" }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    id="input-with-icon-grid"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    inputProps={{ maxLength: 50 }}
                    required
                    value={externalSignerInformation?.lastName || ""}
                    onChange={(e) =>
                      handleExternalSignerFieldChange(e, "lastName")
                    }
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "red" }}
                  >
                    {formErrors?.lastName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={1}>
                  <Iconify
                    icon="ic:round-email"
                    style={{ width: 23, height: 23, color: "#4b4e65" }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    id="input-with-icon-grid"
                    name="email"
                    label="Email Address"
                    type="email"
                    required
                    value={externalSignerInformation?.email || ""}
                    onChange={(e) =>
                      handleExternalSignerFieldChange(e, "email")
                    }
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "red" }}
                  >
                    {formErrors?.email}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={1}>
                  <Iconify
                    icon="solar:phone-bold"
                    style={{ width: 23, height: 23, color: "#4b4e65" }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <FormControl style={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      id="input-with-icon-grid"
                      label="Phone Number"
                      name="phone"
                      type="tel"
                      inputProps={{ maxLength: 15 }}
                      required
                      value={externalSignerInformation?.phone || ""}
                      onChange={(e) =>
                        handleExternalSignerFieldChange(e, "phone")
                      }
                    />
                  </FormControl>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "red" }}
                  >
                    {formErrors?.phone}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
          <Grid container justify="flex-end">
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Icon>send</Icon>}
                onClick={submitExternalSignerInformation}
              >
                Send
              </Button>
            </CardActions>
          </Grid>
        </Card>
      </Popup>
    </Paper>
  );
}

export default SendContract;
