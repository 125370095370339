import React, { useEffect, useState } from "react";
import { makeStyles, Grid, FormHelperText } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

import { isEmpty } from "lodash";
import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  error: {
    color: "red",
    marginLeft: 15,
  },
  moneyIcon: {
    width: 26,
    height: 26,
    marginRight: 5,
    color: "#5f7dab",
  },
  error: {
    marginLeft: 10,
    color: "#d73131",
    fontWeight: 600,
  },
}));

export default function ChangeItemDownpaymentForm(props) {
  const { addOrEdit, item } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const classes = useStyles();
  const [submittingDownpayment, setSubmittingDownpayment] = useState(false);

  const onSubmit = async (data) => {
    setSubmittingDownpayment(true);
    await addOrEdit(data);
    setSubmittingDownpayment(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Controller
          name="downpayment_amount"
          control={control}
          defaultValue={item?.downpayment_amount || 0}
          as={
            <Controls.Input
              InputProps={{
                startAdornment: (
                  <Iconify
                    icon="ri:money-dollar-circle-fill"
                    className={classes.moneyIcon}
                  />
                ),
              }}
              fullWidth
              variant="outlined"
              type="number"
              label="Downpayment amount*"
            />
          }
          rules={{
            required: "Downpayment amount is required",
            validate: (value) => {
              if (value < 0) {
                return "*Amount must be greater than or equal to zero";
              } else {
                if (value > item.price) {
                  return "*Amount must be less than the procedure price";
                }
              }
            },
          }}
        />

        <Controls.Button
          disabled={submittingDownpayment}
          type="submit"
          text={submittingDownpayment ? "Submitting..." : "Submit"}
        />
      </div>
      {errors.downpayment_amount && (
        <p className={classes.error}>{errors.downpayment_amount.message}</p>
      )}
    </form>
  );
}
