import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card,
  makeStyles,
  CardHeader,
  Typography,
} from "@material-ui/core";
import Iconify from "./Iconify";
import { fade } from "@material-ui/core/styles/colorManipulator";


const IconWrapperStyle = styled.div`
  margin: auto;
  display: flex;
  border-radius: 50%;
  align-items: center;
  width: ${(props) => props.theme.spacing(12)}px;
  height: ${(props) => props.theme.spacing(12)}px;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
  margin-top: ${(props) => props.theme.spacing(3)}px;
`;

export default function BMI2(props) {
  const {
    height,
    weight,
    setBmiform,
    cardHeight = "70px",
    pTop = "5px",
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    chip: {
      margin: theme.spacing(0.5),
      width: "100%",
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    cardS: {
      boxShadow: 0,
      borderRadius: "10%",
      textAlign: "center",
      height: cardHeight,
      width: "98%",
      marginTop: "15px",
    },

    iconS: {
      color: "#0C53B7",
      backgroundImage: `linear-gradient(135deg, ${fade(
        "#0C53B7",
        0
      )} 0%, ${fade("#0C53B7", 0.24)} 100%)`,
    },
  }));

  const classes = useStyles();

  const [BMILabel, setBMILabel] = useState({
    label: "No data to display",
    color: "#eceff1",
    color2: "",
  });

  const [BMI, setBMI] = useState(0);

  const BmiLabel = () => {
    let bmiResults = {
      label: "",
      color: "",
      color2: "",
    };

    if (BMI) {
      if (BMI <= 18.5) {
        bmiResults.label = "Underweight";
        bmiResults.color = "#e3f2fd";
        bmiResults.color2 = "#000000";
      } else if (BMI <= 24.9) {
        bmiResults.label = "Normal weight";
        bmiResults.color = "#e0f2f1";
        bmiResults.color2 = "#000000";
      } else if (BMI <= 29.9) {
        bmiResults.label = "Overweight";
        bmiResults.color = "#f06292";
        bmiResults.color2 = "#ffffff";
      } else if (BMI >= 30) {
        bmiResults.label = "Obesity";
        bmiResults.color = "#d32f2f";
        bmiResults.color2 = "#ffffff";
      }
    }
    setBMILabel(bmiResults);
  };

  const BmiCalc = () => {
    const heightsplit = height ? height.split(".") : [];
    const heightft = heightsplit[0] ? heightsplit[0] * 12 : 0;
    const heightin = heightsplit[1] ? heightsplit[1] : 0;

    const height_inchs = parseInt(heightft) + parseInt(heightin);

    const result = height_inchs
      ? ((weight / (height_inchs * height_inchs)) * 703).toFixed(1)
      : 0.0;
    setBmiform(result);
    setBMI(result);
  };

  useEffect(() => {
    if ((height, weight)) {
      BmiCalc();
    }
  }, [height, weight]);

  useEffect(() => {
    if (BMI) {
      BmiLabel();
    }
  }, [BMI]);

  return (
    <Card className={classes.cardS} style={{ backgroundColor: BMILabel.color }}>
      <CardHeader
        style={{ marginTop: 0 }}
        avatar={
          <Iconify
            icon={"fa6-solid:weight-scale"}
            width={35}
            height={35}
            style={{ color: BMILabel.color2 }}
          />
        }
        title={
          <Typography
            variant={"h3"}
            style={{ color: BMILabel.color2, paddingTop: pTop }}
            gutterBottom
          >
            BMI
          </Typography>
        }
        subheader={
          <>
            <Typography variant={"h5"} style={{ color: BMILabel.color2 }}>
              {BMI}
            </Typography>
            <Typography variant={"body1"} style={{ color: BMILabel.color2 }}>
              {BMILabel.label}
            </Typography>
          </>
        }
      />
    </Card>

    /*
    <div className={classes.root}>
      <div className={classes.section1}>
        <Divider variant="middle" />
        <div className={classes.section2}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography gutterBottom variant="h4">
                BMI
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="h6">
                {BmiCalc()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {BmiCalc() > 0 && (
                <Chip
                  className={classes.chip}
                  color="primary"
                  style={{ backgroundColor: BmiLabel().color }}
                  label={BmiLabel().label}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <Divider variant="middle" />
      </div>
    </div>  */
  );
}
