import { useMemo } from "react";
import GenericTable from "../components/GenericTable";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import CRMUtils from "../../../utils";
import { useSelector } from "react-redux";

const RefundUserContactVia = () => {
    const { authUser } = useSelector((state) => state.auth);
    const userCanAddVia = CRMUtils.userHasPermission(authUser, [
        "refundscontactvia_create",
      ]);
    const userCanEditVia = CRMUtils.userHasPermission(authUser, [
        "refundscontactvia_edit",
      ]);

    const columns = useMemo(
        () => [
            { field: "via", title: "Via" },
        ],
        []
    );

    const model = "rfs_user_contact_via";

    const FormData = () => {
        const {
            control,
            formState: { errors },
        } = useFormContext();
        return (
            <Grid container direction="column">
                <Grid item>
                    <Controller
                        name="via"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Via is required",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <TextField
                                label="Via"
                                name="via"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    const useStyles = makeStyles((theme) => ({
        headerIcon: {
          color: theme.palette.primary.main,
        },
      }));
      const classes = useStyles();

    return (
    <>
    <Helmet>
        <title>Refund User Contact Via</title>
    </Helmet>
    <PageHeader
        title="User Contact Via"
        subTitle=""
        icon={
          <PeopleOutlineTwoToneIcon
            fontSize="large"
            className={classes.headerIcon}
          />
        }
      />
      <GenericTable userCanAdd={userCanAddVia} userCanEdit={userCanEditVia} model={model} columns={columns} FormData={FormData} tableTitle="User Contact Via"/>
      </>
      );
};

export default RefundUserContactVia;
