import React from "react";
import { IconButton as MuiIconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function IconButton(props) {
  const { children, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <MuiIconButton
      component="span"
      className={`${classes.root}`}
      onClick={onClick}
      {...other}
    >
      {children}
    </MuiIconButton>
  );
}
