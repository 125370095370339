import React, { useState } from "react";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import moment from "moment/moment";
import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function RescheduleProcedureRequestForm(props) {
  const { recordProduct, submitRequest } = props;
  const { handleSubmit, reset, control, errors } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await submitRequest(data);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2}>
        {recordProduct && recordProduct.date ? (
          <Grid item xs={6}>
            <Controller
              name="old_date"
              control={control}
              defaultValue={recordProduct.date}
              as={
                <Controls.DateTimePicker
                  disabled
                  style={{ width: "100%" }}
                  label={"Current Date"}
                />
              }
            />
            {errors.roles && <p className="error">{errors.roles.message}</p>}
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={recordProduct && recordProduct.date? 6 : 12}>
          <Controller
            name="requested_date"
            type="date"
            control={control}
            rules={{
              required: "*Requested date is required",
            }}
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.DateTimePicker
                minDate={moment()}
                style={{ width: "100%" }}
                label={"Requested date*"}
                shouldCloseOnSelect
                onChange={(date) => {
                  const fdate = date
                    ? moment(date).format("yyyy-MM-DD HH:mm:ss")
                    : null;
                  onChange(fdate);
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="reason"
            control={control}
            rules={{
              required: "*Reason date is required",
            }}
            defaultValue={""}
            as={<Controls.Textarea label="Reason*" />}
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <Controls.Button
          disabled={isSubmitting}
          type="submit"
          startIcon={<Iconify icon="mingcute:send-fill" />}
          text={isSubmitting ? "Submitting..." : "Submit request"}
        />
      </div>
    </form>
  );
}
