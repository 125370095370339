import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Api from "../lib/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Container,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import Iconify from "./Iconify";
import { useSelector } from "react-redux";
import CRMUtils from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "inline-block",
    border: 1,
  },
  media: {
    height: 200,
    width: 200,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const AccordionStyle = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    margin: 15,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const AccordionSummaryStyle = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "#f8f9fb",
  },
})(AccordionSummary);

const AccordionDetailsStyle = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

const Scrollbar = styled(PerfectScrollbar)``;

export default function EvaluationForm(props) {
  const { authUser } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const {    
    contact,
    consultantErrorHandle= null,
  } = props;

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [expanded, setExpanded] = React.useState(null);
  const [buildingsWithTypeformDropDown, setBuildingsWithTypeformDropDown] =
    useState([]);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const userCanUploadEvaluation = CRMUtils.userHasPermission(authUser, [
    "contacts_uploadevaluation",
  ]);

  const userCanUploadEvaluationSuper = CRMUtils.userHasPermission(authUser, [
    "contacts_uploadevaluationsuper",
  ]);

  const handleChange = (newExpanded) => {
    if (newExpanded && newExpanded == expanded) {
      setExpanded(null);
    } else {
      setExpanded(newExpanded ? newExpanded : null);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getEvaluations = async () => {
    try {
    setIsLoading(true);
    const { data } = await Api.getContactEvaluation(contact.id);
    if (data) {
      setEvaluations(data.data);
      //   console.log("data ", data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  } catch (e) {
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(e.data.message);
    }
  }
  };

  const getBuildingsWithTypeform = async () => {
    const { data } = await Api.getBuildingsWithTypeformDropDown();
    setBuildingsWithTypeformDropDown(data.data);
  };

  const handleSelectBuildingToEvaluation = async (building_id, contact_id) => {
    if (contact_id) {
      var payload = {
        language_id: 2,
        building_id: building_id,
        only_url: true,
      };

      const { data } = await Api.generateEvaluationInvitation(
        contact_id,
        payload
      );
      window.open(`${data.data.link}`);
      handleCloseMenu();
    }
  };

  // const handleSelectBuildingToEvaluation = async (building_id, contact_id) => {
  //   if (contact_id) {
  //     var payload = {
  //       language_id: 2,
  //       building_id: building_id,
  //       only_url: true,
  //     };
  //     const { data } = await Api.createCode(contact_id, payload);

  //     window.open(`${data.link}`);

  //     handleCloseMenu();
  //   }
  // };

  useEffect(() => {
    getEvaluations();
    getBuildingsWithTypeform();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0px 5px",
          backgroundColor: "rgb(205 231 255 / 79%)",
          justifyContent: "space-between",
          borderRadius: "12px 12px 0px 0px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="healthicons:health-worker-form-outline"
            style={{ fmargin: 7, fontSize: 31, color: "rgb(22 43 67)" }}
          />
          <Typography
            style={{
              marginLeft: 0,
              margin: 12,
              color: "rgb(22 43 67)",
              fontWeight: 600,
              fontSize: 17,
            }}
          >
            Evaluation Forms
          </Typography>

          <Tooltip title="Refresh">
            <span>
              {" "}
              <IconButton
                onClick={() => {
                  getEvaluations();
                }}
              >
                <Iconify
                  style={{ width: 27, height: 27, color: "#395db1" }}
                  icon="ic:round-refresh"
                />
              </IconButton>
            </span>
          </Tooltip>
        </div>

        {contact &&
        ((authUser.id == contact.user_id && userCanUploadEvaluation) ||
          userCanUploadEvaluationSuper) ? (
          <div>
            <Button
              onClick={handleClickMenu}
              style={{ color: "#395db1" }}
              startIcon={<Iconify icon="basil:upload-solid" />}
            >
              Upload evaluation
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>

      {!isLoading &&
      (!evaluations || (evaluations && evaluations.length == 0)) ? (
        <Typography
          style={{
            padding: 10,
            textAlign: "center",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {" "}
          This contact has not yet submitted evaluations{" "}
        </Typography>
      ) : (
        <div>
          {isLoading ? <LinearProgress /> : ""}
          <div
            style={{
              display: "flex",
              background: "rgb(229 229 229 / 7%)",
              border: "1px solid #8080800f",
              borderTop: "none",
            }}
          >
            <div style={{ maxWidth: "45%" }}>
              <Scrollbar
                style={{
                  maxHeight: 700,
                  width: "fit-content",
                  textAlign: "left",
                }}
              >
                {evaluations.map((evaluation) => {
                  return (
                    <Button
                      style={{
                        justifyContent: "left",
                        width: "-webkit-fill-available",
                        backgroundColor:
                          expanded && expanded.id == evaluation.id
                            ? "#3080d2"
                            : "",
                        color:
                          expanded && expanded.id == evaluation.id
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        handleChange(evaluation);
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <ListAltIcon
                          style={{
                            scale: 0.9,
                            color:
                              expanded && expanded.id == evaluation.id
                                ? "white"
                                : "#00458d",
                            marginRight: 6,
                          }}
                        />
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="body1" style={{}}>
                              Evaluation Form Submited at{" "}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ marginLeft: 3, fontWeight: 600 }}
                            >
                              {evaluation.date}
                            </Typography>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              style={{
                                fontWeight: 500,
                                color:
                                  expanded && expanded.id == evaluation.id
                                    ? "white"
                                    : "#2b496f",
                              }}
                            >
                              Requested by:
                            </Typography>

                            <Typography style={{ marginLeft: 6 }}>
                              {evaluation?.requested_by || "System"}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Button>
                  );
                })}
              </Scrollbar>
            </div>

            {expanded != null && (
              <div
                style={{
                  maxWidth: "630px",
                  width: "100%",
                  padding: "14px",
                  borderRadius: "8px",
                  backgroundColor: "#eef0f3",
                }}
              >
                <Scrollbar style={{ maxHeight: 655 }}>
                  {Object.entries(JSON.parse(expanded.evaluation)).map((ev) => {
                    return (
                      <div style={{ width: "100%" }}>
                        <Card
                          style={{
                            padding: 12,
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "rgb(243 243 243)",
                          }}
                          className={classes.root}
                        >
                          {Object.values(ev)[1].includes("https:") ? (
                            <div>
                              <Typography
                                style={{ fontWeight: 700, fontSize: 16 }}
                              >
                                {Object.values(ev)[0]}
                              </Typography>
                              <CardMedia
                                style={{
                                  maxHeight: 400,
                                  maxWidth: 400,
                                  padding: 12,
                                }}
                                className={classes.media}
                                component="img"
                                src={Object.values(ev)[1]}
                              />
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <Typography
                                style={{ fontWeight: 700, fontSize: 16 }}
                              >
                                {`${Object.values(ev)[0]}: `}
                              </Typography>
                              <Typography
                                style={{ marginTop: 2, marginLeft: 6 }}
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {Object.values(ev)[1]}
                              </Typography>
                            </div>
                          )}
                        </Card>
                      </div>
                    );
                  })}
                </Scrollbar>
              </div>
            )}
          </div>
        </div>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {buildingsWithTypeformDropDown.length > 0 ? (
          buildingsWithTypeformDropDown.map((item) => {
            return (
              <MenuItem
                onClick={() =>
                  handleSelectBuildingToEvaluation(item.value, contact.id)
                }
              >
                {item.title + " General Typeform"}
              </MenuItem>
            );
          })
        ) : (
          <Typography>No typeform links available</Typography>
        )}
      </Menu>
    </div>
  );
}
