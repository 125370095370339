import React, { useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  error: {
    fontSize: 14,
    color: "red",
    fontWeight: 500,
    marginRight: 4,
  },
}));

export default function EditPackageItemPriceForm(props) {
  const { defaultPrice = 0, onSavePrice = null, onClose, minPrice } = props;
  const [price, setPrice] = useState(defaultPrice);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const onSubmit = async () => {
    if (onSavePrice && typeof onSavePrice == "function") {
      setIsSubmitting(true);
      await onSavePrice(parseFloat(price));
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Controls.Input
        style={{ width: "100%" }}
        type="number"
        label="Price"
        defaultValue={price}
        onChange={(e) => {
          setPrice(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <Iconify
              style={{
                width: "28px",
                height: "28px",
                color: "rgb(123, 154, 181)",
                marginRight: 5,
              }}
              icon="f7:money-dollar-circle-fill"
            />
          ),
        }}
        inputProps={{
          min: 0,
          //  max: defaultPrice,
        }}
        value={price}
      />
      {price < minPrice ? (
        <Typography className={classes.error}>
          {"*Price must be greater than " + minPrice}
        </Typography>
      ) : (
        ""
      )}
      <div
        style={{
          marginTop: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Controls.Button
          onClick={() => {
            if (onClose && typeof onClose == "function") {
              onClose();
            }
          }}
          disabled={isSubmitting}
          text="Cancel"
          color="default"
        />{" "}
        <Controls.Button
          disabled={isSubmitting || price < minPrice}
          text={isSubmitting ? "Submitting..." : "Submit"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}
