import React  from "react";
import { TextField, makeStyles } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: "90px",
    height: "90px",
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function AutocompleteMultipleControl(props) {
  const { label, options, onChange, variant, disabled, value, ...others } =
    props;
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      id="multiple"
      disabled={disabled}
      fullWidth
      options={options}
      value={value}
      getOptionLabel={(option) => option.title}
      defaultValue={[]}
      onChange={(event, values) => {
        onChange(values);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant ? variant : "standard"}
          label={label}
          placeholder={label}
          className={classes.textfield}
        />
      )}
    />
  );
}
