import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

export const initialState = {
  loading: false,
  errors: [],
  missedCallsCount: 0,
};

const invoicesSlice = createSlice({
  name: "missedCallsCount",
  initialState,
  reducers: {
    createMissedCalls: (state) => {
      state.loading = true;
    },
    createMissedCallsCountSuccess: (state, { payload }) => {
      state.missedCallsCount = payload;
      state.loading = false;
      state.errors = [];
    },
    createMissedCallsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },
    updateMissedCalls: (state) => {},
    updateMissedCallsSuccess: (state, { payload }) => {
    
      switch (payload.count) {
        case 1:
          state.missedCallsCount = state.missedCallsCount + 1;
          break;
        case "0":
          state.missedCallsCount = 0;
          break;
        case "-1":
          state.missedCallsCount = state.missedCallsCount - 1;
          break;
        default:
          break;
      }
      state.loading = false;
      state.errors = [];
    },
    updateMissedCallsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },
  },
});

export const {
  createMissedCalls,
  createMissedCallsCountSuccess,
  createMissedCallsFailure,
  updateMissedCalls,
  updateMissedCallsSuccess,
  updateMissedCallsFailure,
} = invoicesSlice.actions;
export const invoicesSelector = (state) => state.invoices;
export default invoicesSlice.reducer;

export function createMissedCallsService(payload) {
  return async (dispatch) => {
    dispatch(createMissedCalls());

    try {
      dispatch(createMissedCallsCountSuccess(payload));
    } catch (error) {
      dispatch(createMissedCallsFailure(error));
    }
  };
}

export function updateMissedCallsService(payload) {
  return async (dispatch) => {
    dispatch(updateMissedCalls());
    try {
      dispatch(updateMissedCallsSuccess(payload));
    } catch (error) {
      dispatch(updateMissedCallsFailure(error));
    }
  };
}
