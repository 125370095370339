import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, find } from "lodash";
import MaterialTable from "material-table";
import MTable from "../components_generic/MTableR";
import CRMUtils from "../utils";
import { findIndex } from "lodash";
import { getMessaging, onMessage } from "firebase/messaging";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SendIcon from "@material-ui/icons/Send";
import {
  Paper,
  ListItem as MuiListItem,
  TableBody,
  TableRow,
  TableCell,
  Fab,
  Box,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Button,
  TextField,
  ListItemText,
  ListItemIcon,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Grid,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useForm, Controller } from "react-hook-form";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import useTable from "./useTable";
import Popup from "./Popup";
import RefundRequestForm2 from "./forms/RefundRequestForm2";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Controls from "./controls";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Api from "../lib/api";
import ConfirmDialog from "./ConfirmDialog";
import RefundForm from "./forms/RefundForm";
import RefundFormNew from "./forms/RefundFormNew";
import Alert from "@material-ui/lab/Alert";
import LoadingScreen from "./LoadingScreen";

import { fetchBuildingsService } from "../redux/slices/buildings";

import { fetchUsersServiceDropdown } from "../redux/slices/users";
import EditRefundForm from "./forms/EditRefundForm";
import Iconify from "./Iconify";
import MultipaymentDetails from "./MultipaymentDetails";
import { ContactSupportOutlined } from "@material-ui/icons";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    // position: "",
    // float: "right",
    // right: "50px",
    // top: "100px",
    // zIndex: 10,
  },
  newButton2: {
    position: "absolute",
    right: "100px",
    top: "335px",
    zIndex: 10,
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "50px",
  },
  wrapText2: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "90px",
  },
  wrapText3: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "90px",
    textAlign: "center",
  },
  customTooltip4: {
    backgroundColor: "#e3f2fd",
  },
  customArrow4: { color: "#e3f2fd", borderColor: "#ffffff" },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Refunds(props) {
  const classes = useStyles();
  const {
    invoice,
    refunds,
    gateways,
    onSave,
    maxAmount,
    refundReasons,
    invoiceSelected,
    contact,
    dataRefundRequests,
    setInvoice = null,
    onDelete = null,
    invoices = [],
    rReadonly = false,
    getInvoicesContact = null,
    readOnly = false,
  } = props;

  const messaging = getMessaging();
  const { control, watch, errors, handleSubmit } = useForm();
  const wonlost = useRef("");
  const [isSendingRefundRequestLink, setIsSendingRefundRequestLink] =
    useState(false);
  const [paymentToRefund, setPaymentToRefund] = useState(null);

  const tableRef1 = useRef();
  const tableRef2 = useRef();
  const tableRef3 = useRef();

  const [refundTab, setRefundTab] = useState(0);
  const [isLoadingRefundTab, setIsLoadingRefundTab] = useState(false);

  const [dataRefundRequestsLocal, setDataRefundRequestsLocal] = useState(
    dataRefundRequests ? dataRefundRequests : []
  );
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [isLoadingRefundability, setIsLoadingRefundability] = useState(false);
  const [onlineRefundabilityInfo, setOnlineRefundabilityInfo] = useState([]);
  const [pendingRefunds, setPendingRefunds] = useState([]);
  const [declinedRefunds, setDecliendRefunds] = useState([]);

  const dataMaterialTable = dataRefundRequestsLocal.map((o) => ({
    ...o,
    created: o.user ? o.user.data.title : "",
  }));
  const [isRefundRequestLinkCopied, setIsRefundRequestLinkCopied] =
    useState(false);

  const headCells = [
    //{ id: "selected", label: "", disableSorting: true, filter: false },
    { id: "type", label: "Type" },
    { id: "amount", label: "Amount" },
    { id: "details", label: "Details" },
    { id: "date", label: "Date" },
    { id: "created_by", label: "Created by" },
    { id: "attachment", label: "Attachment" },
    //{ id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ];

  const headCellsMaterial = [
    { field: "contact_name", title: "Contact" },
    { field: "amount", title: "Amount" },
    { field: "comment", title: "Comment" },
    { field: "date", title: "Date" },
    { field: "product_name", title: "Product" },
    { field: "created", title: "Created by" },
    //  { field: "status", title: "Status" },
  ];

  const handleClickRefundRequestLinkLan = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const headCellsMaterialRefunds = [
    {
      field: "payment_type",
      title: "Type",
      render: (rowData) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.wrapText2}>{rowData?.payment_type}</div>
            {rowData.header && rowData.header.reconciled ? (
              <Tooltip title={"Reconciled (Can't be updated or deleted)"} arrow>
                <span style={{ marginLeft: 6 }}>
                  <Iconify
                    icon={"ic:round-info"}
                    width={20}
                    height={20}
                    style={{
                      color: "rgb(71, 145, 219)",
                    }}
                  />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "amount",
      title: "Amount",
      customSort: (a, b) => a.amount - b.amount,
      render: (rowData) => (
        <ListItem style={{ display: "flex" }}>
          <ListItemText primary={rowData.amount} />
          <ListItemIcon>
            {rowData.payments_in_header > 1 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip4,
                    arrow: classes.customArrow4,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Belongs to a multirefund of $${
                          rowData.payments_in_header_amount
                            ? rowData.payments_in_header_amount.toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"
                        }`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <IconButton
                    style={{
                      marginLeft: 3,
                      boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                    }}
                  >
                    <Iconify
                      icon={"emojione-monotone:letter-m"}
                      width={25}
                      height={25}
                      style={{
                        color: "rgb(175 30 233)",
                      }}
                    />
                  </IconButton>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
          </ListItemIcon>
        </ListItem>
      ),
    },
    {
      field: "cc_last_4",
      title: "Last 4",
    },
    {
      field: "details",
      title: "Details",
      render: (rowData) => {
        return <div className={classes.wrapText}>{rowData.details}</div>;
      },
    },
    { field: "building_name", title: "Building" },
    {
      field: "date",
      title: "Created",
    },
    {
      field: "created_by",
      title: "By",
      render: (rowData) => {
        return rowData.user.data ? rowData.user.data.title : "";
      },
    },
    {
      field: "attachment",
      title: "Attach",
      render: (rowData) => {
        if (rowData.document.data?.url || rowData.file) {
          return (
            <a
              href={
                rowData.document.data?.url
                  ? rowData.document.data.url
                  : rowData.file
              }
              target="_blank"
              rel="noreferrer"
            >
              <AttachFileIcon />
            </a>
          );
        }
      },
    },

    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <div className={classes.wrapText3}>
            {rowData.isChargeback == 1 && rowData.refund_state
              ? `CHGBACK ${rowData.refund_state.toUpperCase()}`
              : ""}
          </div>
        );
      },
    },
  ];

  const pendingAndDeclinedRefundsColumns = [
    { field: "created_at", title: "Created At" },
    { field: "created_by", title: "Created By" },
    {
      title: "Amount",
      render: (rowData) => {
        return <Typography>${rowData.amount}</Typography>;
      },
    },
  ];

  const { authUser } = useSelector((state) => state.auth);
  const { usersDropdown, usersDropdownLoading } = useSelector(
    (state) => state.users
  );
  const { buildings, buildingsLoading } = useSelector(
    (state) => state.buildings
  );
  const [isMultipaymentOpen, setIsMultipaymentOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openPopup3, setOpenPopup3] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [refundRequestsForEdit, setRefundRequestsForEdit] = useState(null);
  const dispatch = useDispatch();

  const [openPopupRefundNew, setOpenPopupRefundNew] = useState(false);

  const [paymentForEdit, setPaymentForEdit] = useState(null);
  const userCanDeleteMultirefundHistory = CRMUtils.userHasPermission(authUser, [
    "invoices_multipaymenthistorydelete",
  ]);
  const userCanAdd = CRMUtils.userHasPermission(authUser, [
    "refundrequest_approve",
  ]);

  const userCanSendRefundRequestLink = CRMUtils.userHasPermission(authUser, [
    "invoices_sendrefundrequestlink",
  ]);

  const onClosePopupRefundNew = () => {
    setOpenPopupRefundNew(false);
    setPaymentForEdit(null);
  };

  const closeRefundPopup = () => {
    setOpenRefund(false);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const onSubmit = async (info) => {
    const { data } = await Api.saveInvoiceRefundNew(invoice.id, info);

    onClosePopupRefundNew();
    handleChangeRefundTab();
    Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });
  };

  const addOrEdit = async (payload) => {
    const date = moment(payload.date).format("YYYY-MM-DD HH:mm:ss");
    payload.date = date;

    if (refundRequestsForEdit) {
      const { data } = await Api.updateRefundRequest(
        refundRequestsForEdit.id,
        payload
      );
      const index = findIndex(dataRefundRequestsLocal, ["id", data.data.id]);
      const refundRequest = {
        ...dataRefundRequestsLocal[index],
        ...data.data,
      };

      let refundRequests = [...dataRefundRequestsLocal];
      refundRequests[index] = refundRequest;

      setDataRefundRequestsLocal([...refundRequests]);
    } else {
      if (!isEmpty(authUser)) {
        payload.organization_id = authUser.organization_id;
        const { data } = await Api.saveRefundRequest(payload);

        setDataRefundRequestsLocal([...dataRefundRequestsLocal, data.data]);
      }
    }
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
    setRefundRequestsForEdit(null);
    onClose();
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(refunds, headCells, filterFn);

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose2 = () => {
    setOpenPopup2(false);
    Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });
    // setPaymentForEdit(null);
  };

  const onClose = () => {
    setOpenPopup(false);
  };
  //const onDelete = (id) => {};
  // console.error(
  //   "recordsAfterPagingAndSorting()",
  //   recordsAfterPagingAndSorting()
  // );

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const getDataToRefund = async () => {
    const { data } = await Api.getAllPaymentsWItems(invoice.id);
    if (data && data.data) {
      setPaymentToRefund(data?.data);
    }
  };

  const getOnlineRefundabilityInfo = async (contactId, invoiceId) => {
    setIsLoadingRefundability(true);
    const { data } = await Api.checkContactOnlinePaymentsRefundability(
      contactId,
      invoiceId
    );
    setOnlineRefundabilityInfo(data);
    setIsLoadingRefundability(false);
  };

  const handleNotifications = async (notification) => {
    if (
      notification?.data?.type &&
      notification?.data?.type === "merchant_transaction"
    ) {
      // Merchant transaction Notification
      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });
      if (typeof getInvoicesContact === "function") {
        getInvoicesContact();
      }
    }
  };

  useEffect(() => {
    const orgId = authUser.organization_id;

    if (isEmpty(buildings) && !usersDropdownLoading)
      dispatch(fetchBuildingsService(orgId));
    if (isEmpty(usersDropdown) && !usersDropdownLoading)
      dispatch(fetchUsersServiceDropdown(orgId));

    onMessage(messaging, (payload) => {
      handleNotifications(payload);
    });
  }, []);

  useEffect(() => {
    getDataToRefund();
    handleChangeRefundTab();
  }, [invoice]);

  const selectedColumns = () => {
    if (authUser.organization.data.multiple_locations === 1) {
      return headCellsMaterialRefunds;
    }

    return headCellsMaterialRefunds.filter((column) => {
      return column.field != "building_name";
    });
  };

  const renderPaymentDetails = (rowData) => {
    return (
      <Box m={2}>
        <MTable
          title="Payments"
          tableRef={tableRef1}
          columns={[
            { title: "Name", field: "item_name" },
            { title: "Amount", field: "amount" },
          ]}
          data={rowData.paymentdetail.data.map((payment) => {
            return {
              item_name: payment.item_name,
              amount: payment.amount,
            };
          })}
          headerColor="#263238"
          headerBackgroundColor="#e3f2fd"
        />
      </Box>
    );
  };

  const onCloseMultipaymentDetails = () => {
    setIsMultipaymentOpen(false);
    setPaymentForEdit(null);
  };

  const deletePayment = (id) => {
    onDelete(id);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const openInPopupEdit = (item) => {
    setPaymentForEdit(item);
    setOpenPopup2(true);
  };

  const markRefundWinLose = (item) => {
    setPaymentForEdit(item);
    setOpenPopup3(true);
  };

  const onClose3 = () => {
    setOpenPopup3(false);
    setPaymentForEdit(null);
    wonlost.current = "";
  };

  const markAsConfirm = (data) => {
    setConfirmDialog({
      isOpen: true,
      title: `Are you sure to mark ${wonlost.current} this refund?`,
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        markAs(data);
      },
    });
  };

  const getPendingRefunds = async (invoice_id) => {
    setIsLoadingRefundTab(true);
    const { data } = await Api.getPendingRefunds(invoice_id);
    setPendingRefunds(data.data);
    setIsLoadingRefundTab(false);
  };
  const getDeclinedRefunds = async (invoice_id) => {
    setIsLoadingRefundTab(true);
    const { data } = await Api.getDeclinedRefunds(invoice_id);
    setDecliendRefunds(data.data);
    setIsLoadingRefundTab(false);
  };

  const handleChangeRefundTab = (event = null, newValue = 0) => {
    if (invoice?.id && invoice.canRefundOnline) {
      switch (newValue) {
        case 0:
          // Get pending refunds
          getPendingRefunds(invoice.id);
          break;
        case 1:
          // Get declined refunds
          getDeclinedRefunds(invoice.id);
          break;
      }
      setRefundTab(newValue);
    } else {
      setRefundTab(2);
    }
  };

  const markAs = async (data) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    const payload = {
      refund_state: wonlost.current,
      refund_state_notes: data.refund_state_notes
        ? data.refund_state_notes
        : "",
    };

    await Api.markRefundAsWonLost(invoice.id, paymentForEdit.id, payload);
    await Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });
    if (getInvoicesContact) {
      getInvoicesContact();
    }
    onClose3();
  };

  function userHasPermission(user, routePermissions) {
    if (!routePermissions) return true;

    let hasPermission = false;

    const permissions = user.permissions.data.map((permission) => {
      return permission.name;
    });
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  }

  const openMultiPaymentDetails = (payment) => {
    setIsMultipaymentOpen(true);
    setPaymentForEdit(payment);
  };

  let action;
  if ((invoice && invoice.status !== "canceled") || contact) {
    action = !readOnly
      ? [
          {
            icon: () => (
              <Fab color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            ),
            tooltip: "Add Refund",
            // hidden: !CRMUtils.userHasRole(authUser, ["super-admin"]),
            hidden:
              !userHasPermission(authUser, ["refund_create"]) ||
              rReadonly ||
              invoice.payments.data.length == 0,
            // !userHasPermission(authUser, ["refund_create"]) || rReadonly,
            isFreeAction: true,
            onClick: (event) => {
              if (invoice?.contact?.data?.id) {
                setOpenPopupRefundNew(true);
                getOnlineRefundabilityInfo(invoice.contact.data.id, invoice.id);
              }
            },
          },
          (rowData) => ({
            icon: () =>
              setInvoice ? (
                <Controls.IconButton
                  disabled={
                    rowData.header && rowData.header.reconciled ? true : false
                  }
                  color="secondary"
                >
                  <EditOutlinedIcon fontSize="small" />
                </Controls.IconButton>
              ) : (
                ""
              ),
            tooltip: "Edit",
            hidden: !userHasPermission(authUser, ["refund_edit"]) || rReadonly,
            onClick: (event, rowData) => {
              openInPopupEdit(rowData);
              //setOpenPopup2(true);
            },
            disabled:
              rowData.header && rowData.header.reconciled ? true : false,
          }),
          (rowData) => ({
            icon: () =>
              setInvoice ? (
                <Controls.IconButton color="secondary">
                  <CheckOutlinedIcon fontSize="small" />
                </Controls.IconButton>
              ) : (
                ""
              ),
            tooltip: "Mark as Win/Lose",
            hidden:
              !userHasPermission(authUser, ["refund_winlose"]) ||
              rReadonly ||
              !rowData.isChargeback ||
              rowData.refund_state == "lost",
            onClick: (event, rowData) => {
              markRefundWinLose(rowData);
            },
          }),
          (rowData) => ({
            icon: () =>
              onDelete ? (
                <Controls.IconButton
                  disabled={
                    rowData.header && rowData.header.reconciled
                      ? true
                      : rowData.payments_in_header > 1 &&
                        !userCanDeleteMultirefundHistory
                  }
                  color="secondary"
                >
                  <CloseIcon fontSize="small" />
                </Controls.IconButton>
              ) : (
                ""
              ),
            tooltip:
              rowData.payments_in_header > 1 && !userCanDeleteMultirefundHistory
                ? "This refund also affects other invoices, you cannot delete it"
                : "Delete",
            disabled:
              rowData.header && rowData.header.reconciled
                ? true
                : rowData.payments_in_header > 1 &&
                  !userCanDeleteMultirefundHistory,
            hidden:
              !userHasPermission(authUser, ["refund_delete"]) || rReadonly,
            onClick: (event, rowData) => {
              //console.error("Click on Delete!");
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure to delete this refund?",
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  deletePayment(rowData.id);
                },
              });
            },
          }),
          (rowData) => ({
            icon: () => (
              <Controls.IconButton>
                <Iconify
                  icon={"fa6-solid:file-invoice-dollar"}
                  width={24}
                  height={24}
                  style={{
                    color:
                      rowData.payments_in_header <= 1 ? "#bdbdbd" : "#4caf50",
                  }}
                />
              </Controls.IconButton>
            ),
            tooltip:
              rowData.payments_in_header > 1
                ? "This payment also affects other invoices. Click to see full payment"
                : "",
            disabled: rowData.payments_in_header <= 1,
            onClick: (event, rowData) => openMultiPaymentDetails(rowData),
          }),
        ]
      : [];
  } else {
    action = [
      {
        icon: () => <></>,
        tooltip: "",
        isFreeAction: true,
      },
    ];
  }

  const onTransferPayment = () => {
    onCloseTransfer();
  };

  const sendRefundRequestLink = async (lan) => {
    setAnchorElLanguage(null);
    setIsSendingRefundRequestLink(true);
    const payload = {
      invoice_id: invoice.id,
      lan: lan,
    };
    await Api.sendContactRefundRequestLinkBySms(
      invoice.contact.data.id,
      payload
    );
    setIsSendingRefundRequestLink(false);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          {invoice?.refund_request_link &&
            invoice.refund_request_link !== "" &&
            userCanSendRefundRequestLink && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  background: "whitesmoke",
                  padding: "5px 12px",
                  borderRadius: "12px",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="Copy_to_clipboard_portal_link"
                    label="Refund Request Link"
                    defaultValue={invoice.refund_request_link}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Tooltip
                  title={
                    isRefundRequestLinkCopied ? "Copied!" : "Click to Copy!"
                  }
                  placement="top"
                  //arrow
                >
                  <Controls.IconButton
                    color="primary"
                    onClick={() => {
                      setIsRefundRequestLinkCopied(true);
                      navigator.clipboard.writeText(
                        invoice.refund_request_link
                      );
                    }}
                    onMouseLeave={() => {
                      setIsRefundRequestLinkCopied(false);
                    }}
                  >
                    <FileCopyIcon size="small" />
                  </Controls.IconButton>
                </Tooltip>
                <Tooltip
                  title={"Send Refund Request Link By Sms"}
                  placement="right"
                  //arrow
                >
                  <Controls.IconButton
                    color="primary"
                    disabled={isSendingRefundRequestLink || !authUser.phone}
                    onClick={handleClickRefundRequestLinkLan}
                  >
                    <SendIcon size="small" />
                  </Controls.IconButton>
                </Tooltip>
                {isSendingRefundRequestLink && (
                  <Alert severity="info">Sending Refund Request Link...</Alert>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElLanguage}
                  keepMounted
                  open={Boolean(anchorElLanguage)}
                  onClose={handleCloseLanguage}
                >
                  <MenuItem
                    onClick={() => {
                      sendRefundRequestLink(1);
                    }}
                  >
                    <Iconify
                      style={{ margin: 4 }}
                      icon={"emojione:flag-for-united-states"}
                    />
                    Send refund request link in English
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      sendRefundRequestLink(2);
                    }}
                  >
                    <Iconify
                      style={{ margin: 4 }}
                      icon={"emojione:flag-for-spain"}
                    />
                    Send refund request link in Spanish
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          {invoice.canRefundOnline ? (
            <Tabs
              value={refundTab}
              onChange={handleChangeRefundTab}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
              style={{
                flexGrow: 1,
                width: "fit-content",
                margin: "0 auto",
                marginBottom: 20,
              }}
              // disabled={isSubmitDisable()}
            >
              <Tab
                style={{ maxWidth: "fit-content" }}
                icon={
                  <Iconify
                    style={{ width: 30, height: 30 }}
                    icon={"basil:sand-watch-solid"}
                  />
                }
                label="Pending Refunds"
              />
              <Tab
                style={{ maxWidth: "fit-content" }}
                icon={
                  <Iconify
                    style={{ width: 30, height: 30 }}
                    icon={"fluent:calendar-cancel-16-filled"}
                  />
                }
                label="Declined Refunds"
              />
              <Tab
                style={{ maxWidth: "fit-content" }}
                icon={
                  <Iconify
                    style={{ width: 30, height: 30 }}
                    icon={"mdi:credit-card-refund"}
                  />
                }
                label="Refund Requests"
              />
            </Tabs>
          ) : (
            <></>
          )}

          {invoice.canRefundOnline ? (
            <>
              <TabPanel value={refundTab} index={0}>
                <MaterialTable
                  columns={pendingAndDeclinedRefundsColumns}
                  data={pendingRefunds}
                  options={{
                    pageSize: 5,
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  title="Pending Refunds"
                  isLoading={isLoadingRefundTab}
                />
              </TabPanel>
              <TabPanel value={refundTab} index={1}>
                <MaterialTable
                  columns={pendingAndDeclinedRefundsColumns}
                  data={declinedRefunds}
                  options={{
                    pageSize: 5,
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  title="Declined Refunds"
                  isLoading={isLoadingRefundTab}
                />
              </TabPanel>
            </>
          ) : (
            <></>
          )}
          <TabPanel value={refundTab} index={2}>
            <MaterialTable
              columns={headCellsMaterial}
              data={dataMaterialTable}
              tableRef={tableRef2}
              options={{
                pageSize: 5, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                actionsColumnIndex: -1,
                search: false,
              }}
              title="Refund Requests"
              actions={[
                {
                  icon: () => (
                    <Fab
                      color="primary"
                      aria-label="Make Refund Request"
                      size="small"
                      onClick={() => {
                        setOpenPopup(true);
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  ),
                  tooltip: "Make Refund Request", // Tooltip text
                  isFreeAction: true,
                },
              ]}
            />
          </TabPanel>
        </TblContainer>

        <Box>
          {invoice && (
            <MTable
              columns={selectedColumns()}
              data={refunds}
              actions={[...action]}
              tableRef={tableRef3}
              headerColor="#263238"
              headerBackgroundColor="#e0f2f1"
              title="Refunds"
              toolbar={true}
              showPaging={true}
              pageSize={5}
              pageSizeOptions={[5, 10, 20]}
              detailPanel={(rowData) => {
                return renderPaymentDetails(rowData);
              }}
            />
          )}

          {contact && (
            <>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => {
                  const isItemSelected = isSelected(item.id);
                  return (
                    <TableRow key={item.id} role="checkbox">
                      <TableCell>{item.payment_type}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>{item.details}</TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell>
                        {item.created_by ? item.user.data.title : ""}
                      </TableCell>
                      <TableCell>
                        {!isEmpty(item.document.data) || item.file ? (
                          <a
                            href={
                              !isEmpty(item.document.data)
                                ? item.document.data.url
                                : item.file
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <AttachFileIcon />
                          </a>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          )}
        </Box>
      </Paper>
      <Popup
        title={"Refund Items"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <RefundRequestForm2
          onClose={closeRefundPopup}
          addOrEdit={addOrEdit}
          refundReasons={refundReasons}
          invoiceSelected={invoice}
        />
      </Popup>

      <Popup
        title={"Refund Items"}
        maxWidth="xl"
        fullWidth={true}
        openPopup={openPopup2}
        onClose={onClose2}
      >
        <EditRefundForm
          fullWidth={true}
          buildings={buildings}
          usersDropdown={usersDropdown}
          gateways={gateways}
          refundReasons={refundReasons}
          payment={paymentForEdit}
          paymentsData={paymentToRefund}
          onClose={onClose2}
        />
      </Popup>

      <Popup
        title={"Refund Items"}
        maxWidth={"xl"}
        fullWidth
        // openPopup={openPopupRefundNew && !isEmpty(paymentToRefund)}
        openPopup={openPopupRefundNew}
        onClose={onClosePopupRefundNew}
      >
        <RefundFormNew
          gateways={gateways}
          invoice={invoice}
          paymentToRefund={paymentToRefund}
          onSubmit={onSubmit}
          onClose={onClosePopupRefundNew}
          refundReasons={refundReasons}
          buildings={buildings}
          usersDropdown={usersDropdown}
          setIsLoadingRefundability={setIsLoadingRefundability}
          onlineRefundabilityInfo={onlineRefundabilityInfo}
        />
      </Popup>

      <Dialog
        open={
          (openPopupRefundNew && isEmpty(paymentToRefund)) ||
          isLoadingRefundability
        }
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        {<LoadingScreen />}
      </Dialog>

      <Dialog
        aria-labelledby="winlose-dialog"
        open={openPopup3}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
          },
        }}
        onClose={onClose3}
        maxWidth="sx"
        style={{ borderRadius: "50px" }}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit(markAsConfirm)} className={classes.root}>
          <DialogTitle className={classes.dialogTitle}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#212b36",
                  fontFamily: "Nunito,Roboto",
                  fontWeight: 700,
                  fontSize: "1.125rem",
                  margin: 12,
                }}
              >
                How do you like to mark this Refund?
              </Typography>
              <Controls.IconButton
                onClick={onClose3}
                style={{
                  textAlign: "right",
                  color: "#000000",
                  fontWeight: 700,
                }}
              >
                <CloseIcon />
              </Controls.IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{ marginTop: 20 }}>
            <Controller
              name="refund_state_notes"
              control={control}
              defaultValue=""
              as={
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Comments"
                  error={errors.refund_state_notes}
                  helperText={errors.refund_state_notes?.message}
                  multiline
                  maxRows={4}
                  minRows={4}
                />
              }
              rules={{ required: "Comment is required" }}
            />
          </DialogContent>

          <DialogActions
            dividers={true}
            style={{ justifyContent: "center", margin: 20 }}
          >
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#e0f2f1", margin: 15 }}
              onClick={() => {
                wonlost.current = "won";
              }}
            >
              Won
            </Button>

            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#ffebee", margin: 15 }}
              onClick={() => {
                wonlost.current = "lost";
              }}
            >
              Lost
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title={"Multirefund details"}
        fullWidth={true}
        openPopup={isMultipaymentOpen}
        onClose={onCloseMultipaymentDetails}
      >
        <MultipaymentDetails
          entity={"Refund"}
          history={paymentForEdit || null}
        />
      </Popup>
    </Wrapper>
  );
}

export default Refunds;
