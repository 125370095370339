import React from "react";
import { Pie } from "react-chartjs-2";

export default function PieChart(props) {
  const { labels, label, info, backgroundColors } = props;

  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: info,
        backgroundColor: backgroundColors,
        borderWidth: 3,
      },
    ],
  };

  return (
    <>
      <Pie data={data} />
    </>
  );
}
