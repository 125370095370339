import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
    "& .MuiDropzoneArea-root": {
      minHeight: "100px",
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

export default function SmsOrMmsForm(props) {
  const { user, sendMessage } = props;

  const initialData = {
    filename: "",
    docType: "",
    file: null,
  };

  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const documentOptions = () => {
    return documentTypes.map((type) => {
      return {
        id: type.name,
        title: type.name,
      };
    });
  };

  const onSubmit = (data) => {
    reset(initialData);
    sendMessage(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Controller
            name="body"
            control={control}
            defaultValue=""
            as={<Controls.Textarea label="Message" />}
            rules={{
                required: "A Message is required",
                pattern: {
                  //value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/i,
                  //message: "File name can only accept characters",
                },
              }}
            
          />
         {errors.filename && (
            <p className="error">{errors.filename.message}</p>
          )}
        </Grid>

                
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            control={control}
            defaultValue=""
            name="attch"
            as={({ onChange, value }) => (
              <Controls.DropzoneBox
                label="Attachment"
                onChange={(files) => onChange(files[0])}
                value={value}
              />
            )}
            
          />
          

          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Reset" onClick={() => reset(initialData)} />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
