import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'invWhReportPurchases';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchReportPurchaseByWhService(dataSlice) {
    dataSlice.dataUrl.code = "GET_ACC_REPORTPURCHASE_BY_WH";
    return _fetchEntitiesService(dataSlice, events);
  }