import React, { useState, useEffect } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DraftsIcon from "@material-ui/icons/Drafts";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import FaceIcon from "@material-ui/icons/Face";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import moment from "moment";
import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  selected: {
    width: "100%",
    color: "#000000",
    backgroundColor: "#e8f3fb",
    borderRadius: 11,
    maxWidth: 130,
    border: "1px solid #b3d7f7",
  },
  selectedText: {
    fontWeight: 600,
  },
  root: {
    marginTop: "-6px",
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },

  button: {
    "&.MuiButton-containedPrimary": {
      backgroundColor: "#bdbfcc",
    },
  },
}));

export default function ContactToDoForm(props) {
  const { saveToDo, ticketclosed = false, setOpenPopup = null } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const [schedule, setSchedule] = useState(moment().format("YYYY-MM-DD"));
  const [values, setValues] = useState({ followup: true });

  const classes = useStyles();

  const onSubmit = (data) => {
    const payload = {
      ...values,
      schedule_to: data.schedule_to,
      comment: data.comment,
    };

    saveToDo(payload).then(() => {
      if (setOpenPopup) {
        setOpenPopup(false);
      }
    });
    reset();
  };

  const onCancel = () => {
    if (setOpenPopup) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    console.error(values);
  }, [values]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      {/*<Grid container spacing={9}>
        <Grid item xs={1}>
          <Controller
            name="followup"
            control={control}
            defaultValue={values.followup ? values.followup : 0}
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="Follow Up"
                icon={<AlarmOnIcon fontSize="medium" />}
                checkedIcon={<AlarmOnIcon fontSize="medium" color="primary" />}
                checked={values.followup ? true : false}
                onChange={(value) => {
                  //setValues({});
                  let tmp = {};
                  tmp.followup = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={1}>
          <Controller
            name="call"
            control={control}
            defaultValue={values.call ? values.call : 0}
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="Call"
                icon={<AddIcCallIcon fontSize="medium" />}
                checkedIcon={
                  <AddIcCallIcon fontSize="medium" color="primary" />
                }
                checked={values.call ? true : false}
                onChange={(value) => {
                  let tmp = {};
                  tmp.call = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={1}>
          <Controller
            name="sms"
            control={control}
            defaultValue={values.sms ? values.sms : 0}
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="SMS"
                icon={<AddCommentIcon fontSize="medium" />}
                checkedIcon={
                  <AddCommentIcon fontSize="medium" color="primary" />
                }
                checked={values.sms ? true : false}
                onChange={(value) => {
                  let tmp = {};
                  tmp.sms = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={1}>
          <Controller
            name="email"
            control={control}
            defaultValue={values.email ? values.email : 0}
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="Email"
                icon={<DraftsIcon fontSize="medium" />}
                checkedIcon={<DraftsIcon fontSize="medium" color="primary" />}
                checked={values.email ? true : false}
                onChange={(value) => {
                  let tmp = {};
                  tmp.email = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={1}>
          <Controller
            name="contact"
            control={control}
            defaultValue={values.contact ? values.contact : 0}
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="Make Contact"
                icon={<FaceIcon fontSize="medium" />}
                checkedIcon={<FaceIcon fontSize="medium" color="primary" />}
                checked={values.contact ? true : false}
                onChange={(value) => {
                  let tmp = {};
                  tmp.contact = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            name="deposit"
            control={control}
            defaultValue={values.deposit ? values.deposit : 0}
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="Deposit"
                icon={<MonetizationOnIcon fontSize="medium" />}
                checkedIcon={
                  <MonetizationOnIcon fontSize="medium" color="primary" />
                }
                checked={values.deposit ? true : false}
                onChange={(value) => {
                  let tmp = {};
                  tmp.deposit = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={1}>
          <Controller
            name="pending_consultation"
            control={control}
            defaultValue={
              values.pending_consultation ? values.pending_consultation : 0
            }
            as={({ value, onChange }) => (
              <Controls.Checkbox
                label="Pending Consultation"
                icon={<SupervisorAccountIcon fontSize="medium" />}
                checkedIcon={
                  <SupervisorAccountIcon fontSize="medium" color="primary" />
                }
                checked={values.pending_consultation ? true : false}
                onChange={(value) => {
                  let tmp = {};
                  tmp.pending_consultation = value;
                  setValues(tmp);
                  onChange(value ? 1 : 0);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
*/}
      {/* <Grid container>
        <Grid item xs={3}>
          <Controller
            name="schedule_to"
            control={control}
            defaultValue={schedule}
            as={
              <Controls.DateTimePicker
                fullWidth
                label="Schedule"
                onChange={(date) => {
                  //console.error(date.format("YYYY-MM-DD HH:mm:ss"));;
                  setSchedule(date.format("YYYY-MM-DD HH:mm:ss"));
                }}
              />
            }
            rules={{ required: "Schedule Date is required" }}
          />

          <div>
            <Controls.Button
              type="submit"
              text="Submit"
              disabled={ticketclosed}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="comment"
            control={control}
            defaultValue=""
            as={<Controls.Textarea label="Comment" disabled={ticketclosed} />}
            //rules={{ required: "Comment is required" }}
          />
        </Grid>
      </Grid>*/}

      <Grid container>
        <Grid item>
          <div style={{ width: 150 }}>
            {" "}
            <Controller
              style={{ height: 28 }}
              name="followup"
              control={control}
              defaultValue={values.followup ? values.followup : 0}
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  style={{ height: 28 }}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      Follow Up
                    </Typography>
                  }
                  icon={<Iconify icon="basil:alarm-solid" />}
                  checkedIcon={
                    <Iconify
                      icon="basil:alarm-solid"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.followup ? true : false}
                  onChange={(value) => {
                    //setValues({});
                    let tmp = {};
                    tmp.followup = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
            <Controller
              name="call"
              control={control}
              defaultValue={values.call ? values.call : 0}
              style={{ height: 28 }}
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  style={{ height: 28 }}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      Call
                    </Typography>
                  }
                  icon={<Iconify icon="fluent:call-add-20-filled" />}
                  checkedIcon={
                    <Iconify
                      icon="fluent:call-add-20-filled"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.call ? true : false}
                  onChange={(value) => {
                    let tmp = {};
                    tmp.call = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
            <Controller
              style={{ height: 28 }}
              name="sms"
              control={control}
              defaultValue={values.sms ? values.sms : 0}
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  style={{ height: 28 }}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      SMS
                    </Typography>
                  }
                  icon={<Iconify icon="eva:message-circle-fill" />}
                  checkedIcon={
                    <Iconify
                      icon="eva:message-circle-fill"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.sms ? true : false}
                  onChange={(value) => {
                    let tmp = {};
                    tmp.sms = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
            <Controller
              style={{ height: 28 }}
              name="email"
              control={control}
              defaultValue={values.email ? values.email : 0}
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  style={{ height: 28 }}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      Email
                    </Typography>
                  }
                  icon={<Iconify icon="fluent:mail-32-filled" />}
                  checkedIcon={
                    <Iconify
                      icon="fluent:mail-32-filled"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.email ? true : false}
                  onChange={(value) => {
                    let tmp = {};
                    tmp.email = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
            <Controller
              style={{ height: 28 }}
              name="contact"
              control={control}
              defaultValue={values.contact ? values.contact : 0}
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  style={{ height: 28 }}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      Make Contact
                    </Typography>
                  }
                  icon={<Iconify icon="mingcute:user-3-fill" />}
                  checkedIcon={
                    <Iconify
                      icon="mingcute:user-3-fill"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.contact ? true : false}
                  onChange={(value) => {
                    let tmp = {};
                    tmp.contact = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
            <Controller
              style={{ height: 28 }}
              name="deposit"
              control={control}
              defaultValue={values.deposit ? values.deposit : 0}
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      Deposit
                    </Typography>
                  }
                  style={{ height: 28 }}
                  icon={
                    <Iconify icon="solar:dollar-minimalistic-bold-duotone" />
                  }
                  checkedIcon={
                    <Iconify
                      icon="solar:dollar-minimalistic-bold-duotone"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.deposit ? true : false}
                  onChange={(value) => {
                    let tmp = {};
                    tmp.deposit = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
            <Controller
              style={{ height: 28 }}
              name="pending_consultation"
              control={control}
              defaultValue={
                values.pending_consultation ? values.pending_consultation : 0
              }
              as={({ value, onChange }) => (
                <Controls.Checkbox
                  className={classes.selected}
                  label={
                    <Typography style={{ fontWeight: 600, color: "#4c4e4f" }}>
                      Pending Consultation
                    </Typography>
                  }
                  icon={
                    <Iconify icon="solar:users-group-rounded-bold-duotone" />
                  }
                  checkedIcon={
                    <Iconify
                      icon="solar:users-group-rounded-bold-duotone"
                      style={{ color: "#237bd1" }}
                    />
                  }
                  checked={values.pending_consultation ? true : false}
                  onChange={(value) => {
                    let tmp = {};
                    tmp.pending_consultation = value;
                    setValues(tmp);
                    onChange(value ? 1 : 0);
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item style={{ marginTop: 15 }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              {" "}
              <Controller
                name="schedule_to"
                control={control}
                defaultValue={schedule}
                as={({ value, onChange }) => (
                  <Controls.DateTimePicker
                    fullWidth
                    label="Schedule"
                    value={value}
                    onChange={(date) => {
                      onChange(moment(date).format("YYYY-MM-DD HH:mm:00"));

                      //console.error(date.format("YYYY-MM-DD HH:mm:ss"));;
                      setSchedule(moment(date).format("YYYY-MM-DD HH:mm:00"));
                    }}
                  />
                )}
                rules={{ required: "Schedule Date is required" }}
              />
            </Grid>

            <Grid item xs={5}>
              {" "}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Controls.Button
                  style={{ marginTop: 9 }}
                  type="submit"
                  text="Submit"
                  disabled={ticketclosed}
                />
                <Controls.Button
                  style={{ marginTop: 9 }}
                  className={classes.button}
                  text="Cancel"
                  onClick={onCancel}
                  disabled={ticketclosed}
                />
              </div>
            </Grid>
          </Grid>
          <Controller
            style={{ height: 175, width: 482 }}
            name="comment"
            control={control}
            className={classes.Textarea}
            defaultValue=""
            as={
              <Controls.Textarea
                rows={8}
                style={{ height: 175, width: 482 }}
                label="Comment"
                disabled={ticketclosed}
              />
            }
            //rules={{ required: "Comment is required" }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
