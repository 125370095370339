import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
}));

export default function TicketTimeline(props) {
  const classes = useStyles();

  const { data } = props;

  const action = data.action;

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";

  const { t, i18n } = useTranslation(["common", "tickets"]);

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          {action} by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <ConfirmationNumberIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {t("common:general.Ticket")}
          </Typography>
          <Typography>Subject: {data.object.subject}</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
