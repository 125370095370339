import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function FloatingActionButtons(props) {
  const classes = useStyles();
  const { color, children, href, aria_label, variant, ...other } = props;
  return (
    <div className={classes.root}>
      <Fab
        color={color}
        variant={variant ? variant : "round"}
        aria-label={aria_label}
        href={href}
        {...other}
      >
        {children}
      </Fab>
    </div>
  );
}
