import { React, useState, useRef, useEffect } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MaterialTable from "material-table";
import { TablePagination, Typography } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import Notification from "../Notification";

function HeaderLog(props) {
  const { icon, bckcolor, textColor, iconColor, text } = props;
  return (
    <div
      style={{
        borderRadius: 14,
        display: "flex",
        alignItems: "center",
        backgroundColor: bckcolor,
        padding: 7,
      }}
    >
      <Iconify
        icon={icon}
        style={{ color: iconColor, margin: 7, width: 21, height: 21 }}
      />
      <Typography style={{ color: textColor, fontWeight: 600 }}>
        {text}
      </Typography>
    </div>
  );
}

export default function BasicTreeData(props) {
  const { title, modelId, getLogs, rowsQty = 10 } = props;
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(rowsQty);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const orderArrays = (arr1, arr2) => {
    // Filter elements from the first array that are not in the second array
    const arr1Filtered = arr1.filter((item) => !arr2.includes(item));
    const arr1Filtered2 = arr1.filter((item) => !arr1Filtered.includes(item));
    // Sort both arrays alphabetically
    const sortedArr1 = arr1Filtered.sort();
    const sortedArr2 = arr1Filtered2.sort();

    // Return the two sorted arrays

    return [...sortedArr1, ...sortedArr2];
  };

  const columns = [
    {
      title: "EVENT",
      field: "action",
      render: (rowData) => (
        <HeaderLog
          text={rowData.event.toUpperCase()}
          bckcolor={
            rowData.event == "CREATED"
              ? "rgb(220, 249, 228)"
              : rowData.event == "UPDATED"
              ? "rgb(220 237 255)"
              : rowData.event == "VIEWED"
              ? "rgb(234 232 255)"
              : "#ffe5e5"
          }
          textColor={
            rowData.event == "CREATED"
              ? "rgb(15 189 93)"
              : rowData.event == "UPDATED"
              ? "rgb(15 109 227)"
              : rowData.event == "VIEWED"
              ? "rgb(112 54 205)"
              : "#a91818"
          }
          iconColor={
            rowData.event == "CREATED"
              ? "rgb(15 189 93)"
              : rowData.event == "UPDATED"
              ? "rgb(15 109 227)"
              : rowData.event == "VIEWED"
              ? "rgb(152 88 255)"
              : "rgb(203 62 62)"
          }
          icon={
            rowData.event == "CREATED"
              ? "bx:add-to-queue"
              : rowData.event == "UPDATED"
              ? "bxs:edit-alt"
              : rowData.event == "VIEWED"
              ? "carbon:view-filled"
              : "fluent:delete-32-filled"
          } //{"carbon:view-filled"}
        />
      ),
      headerStyle: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    {
      title: "AT",
      field: "created_at",
      render: (rowData) => (
        <div>
          {
            <div>
              <Typography>{rowData.created_at.split(" ")[0]}</Typography>
              <div style={{ display: "flex" }}>
                <Typography variant="subtitle2">
                  {rowData.created_at.split(" ")[1]}
                </Typography>
                <Typography variant="subtitle2" style={{ marginLeft: "5px" }}>
                  {rowData.created_at.split(" ")[2]}
                </Typography>
              </div>
            </div>
          }
        </div>
      ),
      headerStyle: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    {
      title: "BY",
      field: "by",
      headerStyle: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
  ];

  //Detail Panel for each log
  const detailPanel = [
    (rowData) => ({
      disabled:
        rowData.event !== "VIEWED" && rowData.event !== "DELETED"
          ? false
          : true,
      icon: () => (
        <ArrowForwardIosIcon
          style={{
            display:
              rowData.event !== "VIEWED" && rowData.event !== "DELETED"
                ? ""
                : "none",
            width: 15,
            height: 15,
          }}
        />
      ),
      //openIcon: UnfoldLess,
      render: () => (
        <MaterialTable
          style={{ paddingBottom: "15px", marginLeft: 50 }}
          columns={[
            {
              title: "Properties",
              field: "property",
              headerStyle: {
                fontSize: "16px",
                fontWeight: 600,
              },
            },
            {
              title: "Old",
              field: "old",
              cellStyle: {verticalAlign: 'top'},
              render: (rowData) => (
                <div
                  style={{
                    maxWidth: 300,
                    wordWrap: "break-word",
                    maxHeight: 300,
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#888 transparent",
                  }}
                >
                  {Array.isArray(rowData.old) ? (
                    <div>
                      {orderArrays(rowData.old, rowData?.new || []).map(
                        (item) => {
                          var removed =
                            !rowData.new || !rowData.new.includes(item);
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: removed ? "#d02828" : "unset",
                              }}
                            >
                              <Typography
                                style={{
                                  maxWidth: 260,
                                  wordWrap: "break-word",
                                }}
                              >
                                {item}
                              </Typography>

                              {removed ? (
                                <Iconify icon={"ic:round-remove"} />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    rowData.old
                  )}
                </div>
              ),
              headerStyle: {
                fontSize: "16px",
                fontWeight: 600,
              },
            },
            {
              title: "New",
              field: "new",
              cellStyle: {verticalAlign: 'top'},
              render: (rowData) => (
                <div
                  style={{
                    maxWidth: 300,
                    wordWrap: "break-word",
                    maxHeight: 300,
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#888 transparent",
                  }}
                >
                  {Array.isArray(rowData.new) ? (
                    <div>
                      {orderArrays(rowData.new, rowData?.old || []).map(
                        (item) => {
                          var added =
                            !rowData.old || !rowData.old.includes(item);
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: added ? "rgb(3 149 67)" : "unset",
                              }}
                            >
                              <Typography
                                style={{
                                  maxWidth: 260,
                                  wordWrap: "break-word",
                                }}
                              >
                                {item}
                              </Typography>

                              {added ? <Iconify icon={"ic:round-plus"} /> : ""}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    rowData.new
                  )}
                </div>
              ),
              headerStyle: {
                fontSize: "16px",
                fontWeight: 600,
              },
            },
          ]}
          title={
            <div>
              <div style={{ display: "flex" }}>
                <HistoryIcon />
                <Typography style={{ marginLeft: 5, fontSize: 15 }}>
                  {" "}
                  Activities{" "}
                </Typography>
              </div>
            </div>
          }
          data={rowData?.activities}
          options={{
            search: false,
            padding: "dense",
            paging: false,
          }}
        />
      ),
    }),
  ];

  //Get Logs fucntion
  const getModelActivityLogs = async () => {
    setIsLoading(true);
    try {
      const { data } = await getLogs(modelId, page + 1, rowsPerPage);
      if (data) {
        setLogs(data.data);
        setPaginator(data.meta.pagination);
      }
    } catch (error) {
      if (error.status == 401) {
        setNotify({
          isOpen: true,
          message: "Forbidden",
          type: "error",
        });
      } else {
        console.log("error aa", error);
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  //Fetch logs each time the table page changes
  useEffect(() => {
    getModelActivityLogs();
  }, [page]);

  return (
    <>
      <MaterialTable
        title={title ? title : null}
        columns={columns}
        data={logs}
        // style={{ marginTop: title ? "" : -40 }}
        isLoading={isLoading}
        options={{
          toolbar: title ? true : false,
          showTitle: title ? true : false,
          search: false,
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [],
          emptyRowsWhenPaging: false,
        }}
        detailPanel={detailPanel}
        onRowClick={(event, rowData, togglePanel) =>
          rowData.event !== "VIEWED" && rowData.event !== "DELETED"
            ? togglePanel()
            : null
        }
        components={{
          //Custom Pagination
          Pagination: (defaultProps) => (
            <TablePagination
              {...defaultProps}
              count={paginator?.total ? paginator.total : defaultProps.count}
              page={page ? page : defaultProps.page}
              rowsPerPage={rowsPerPage ? rowsPerPage : defaultProps.rowsPerPage}
              onChangePage={(e, newPage) => {
                setPage(newPage);
                //defaultProps.onChangePage(e, newPage);
              }}
              /*
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(event.target.value);
            }}
            */
            />
          ),
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
