import React, { useEffect, useState, useMemo } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

/**
 * Pusher configuration
 */
// const pusherConfig = {
//   key: '<your_pusher_key_here>',
//   cluster: '<pusher_cluster>',
//   // auth endpoint for private channels
//   // e.g. for Laravel https://example.com/api/broadcasting/auth
//   authEndpoint: '<auth_endpoint_for_private_channels>'
// }

/**
 * Context for Channels
 */
// type TChannels = Echo | undefined
const ChannelsContext = React.createContext(undefined);

/**
 * Channel Context Provider
 */
export function ChannelsProvider({ children, authUserId, authToken }) {
  const [pusher, setPusher] = useState(undefined);
  useEffect(() => {
    const pusher = getPusher(authToken);
    setPusher(pusher);
    // return () => {
    //     // disconnect from server and reset the channels
    //  //   pusher.disconnect()
    //    // setPusher(undefined)
    // }
  }, [authUserId, authToken]);
  return (
    <ChannelsContext.Provider value={{ channels: pusher, authUserId }}>
      {children}
    </ChannelsContext.Provider>
  );
}

/**
 * Hook to use the channels provided via context
 */
export function useChannelsContext() {
  const context = React.useContext(ChannelsContext);
  return context;
}

/**
 * Use private channels
 * It simple return the useChannels with authenticated user bindings
 */
// export function usePrivateChannels() {
//     const { channels, authUserId } = useChannelsContext()
//     return useMemo(() => {
//         return channels && authUserId && channels.private("users." + authUserId)
//     }, [channels, authUserId])
// }

// export function usePublicChannels(channel_name) {
//     const { channels, authUserId } = useChannelsContext()
//     return useMemo(() => {
//         return channels && authUserId && channels.public(channel_name)
//     }, [channels, authUserId, channel_name])
// }

export function usePrivateChannels(channel_name) {
  const { channels, authUserId } = useChannelsContext();
  const [privateChannel, setPrivateChannel] = useState(null);
  useEffect(() => {
    setPrivateChannel(
      channels &&
        authUserId &&
        channels.private(channel_name + "." + authUserId)
    );
  }, [channels, channel_name, authUserId]);

  return privateChannel;
}

export function usePublicChannels(channel_name) {
  const { channels } = useChannelsContext();
  const [publicChannel, setPublicChannel] = useState(null);

  useEffect(() => {
    setPublicChannel(channels && channels.channel(channel_name));
  }, [channels, channel_name]);

  return publicChannel;
}

export function usePrivateNotificationChannel(event, channel_name, onChange) {
  const privateChannel = usePrivateChannels(channel_name);

  useEffect(() => {
    if (privateChannel) {
      privateChannel.listen(event, onChange);
      return () => {
        privateChannel.stopListening(event);
      };
    }
  }, [privateChannel, onChange, event]);
}

export function usePublicNotificationChannel(event, channel_name, onChange) {
  const publicChannel = usePublicChannels(channel_name);

  useEffect(
    () => {
      if (publicChannel) {
        publicChannel.listen(event, onChange);
        return () => {
          publicChannel.stopListening(event);
        };
      }
    },
    publicChannel,
    event,
    onChange
  );
}

/**
 * Get the channels
 */
function getPusher(authToken = null) {
  var baseURL = new URL(
    process.env.REACT_APP_API_ENDPOINT || "http://localhost"
  );

  if (!window.Pusher) {
    window.Pusher = Pusher;
  }

  const appKey = process.env.REACT_APP_MIX_PUSHER_APP_KEY;
  const appHost = process.env.REACT_APP_MIX_PUSHER_HOST;
  const appPort = process.env.REACT_APP_MIX_PUSHER_PORT;
  const cluster = process.env.REACT_APP_PUSHER_APP_CLUSTER;
  const forceTls = process.env.REACT_APP_PUSHER_FORCE_TLS == "false"? false : true;

  // if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: "pusher",
      cluster: cluster,
      key: appKey,
      wsHost: appHost,
      wsPort: appPort,
      forceTLS: forceTls,
      auth: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
      authEndpoint: "/broadcasting/auth",
      encrypted: false,
      enabledTransports: ["ws", "wss"],
      disableStats: false,
    });
  // }

  return window.Echo;
}
