import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	IconButton,
	LinearProgress,
	TextField,
	Typography,
} from "@material-ui/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "../../../components/controls/Select";
import StatesComponent from "../../../components/statescomponent/StatesComponent";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import AutocompleteGeneric from "../../refunds/components/AutocompleteGeneric";
import UserOption from "../../refunds/components/options/UserOption";
import { isInteger } from "lodash";
import DatePicker2 from "../../../components/controls/DatePicker2";
import { useMutation, useQuery } from "react-query";
import Api from "../../../lib/api";
import SelectWColor from "../../../components/controls/SelectWColor";
import TaskTypeComponents from "../components/types";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import {
	Add,
	Event,
	ExpandMore,
	History,
	Visibility,
} from "@material-ui/icons";
import Carousel from "../../refunds/components/Carousel";
import ContactInfoHistory from "../../refunds/components/ContactInfoHistory";
import Popup from "../../../components/Popup";
import { Calendar, momentLocalizer } from "react-big-calendar";

//moment.tz.setDefault("Etc/UTC");

const TaskForm = ({ objectData, userId, permissions = [] }) => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
		getValues,
	} = useFormContext();

	const isUpdate = useMemo(() => {
		return Boolean(objectData?.id);
	}, [objectData]);

	const { authUser } = useSelector((state) => state.auth);

	const organization_id = useMemo(() => {
		return authUser?.organization_id;
	}, [authUser]);

	const editorConfig = {
		key: process.env.REACT_APP_FROALA_KEY,
		zIndex: 2501,
		attribution: false,
		dragInline: false,
		useClasses: false,
		htmlRemoveTags: ["script"],
		pasteDeniedAttrs: ["style"],
		pluginsEnabled: [
			"align",
			"charCounter",
			"codeBeautifier",
			"codeView",
			"colors",
			"draggable",
			"emoticons",
			"entities",
			"fontFamily",
			"fontSize",
			"fullscreen",
			"help",
			"image",
			"inlineClass",
			"inlineStyle",
			"link",
			"lists",
			"paragraphFormat",
			"paragraphStyle",
			"print",
			"quote",
			"save",
			"specialCharacters",
			"table",
			"url",
			"video",
			"wordPaste",
		],
		events: {
			initialized: function () {
				const body = watch("description");
				froala?.current?.editor?.html?.set &&
					froala.current.editor.html.set(body);
			},
			"image.beforeUpload": function (files) {
				const data = new FormData();
				data.append("fileUrl", files[0]);

				Api.uploadImage(organization_id, data)
					.then((res) => {
						this.image.insert(
							res.data.data,
							null,
							null,
							this.image.get()
						);
					})
					.catch((err) => {
						console.log(err);
					});
				return false;
			},
		},
	};

	//to control views and values
	const canSelectAnyUser = useMemo(() => {
		return isUpdate
			? permissions.includes("tktasks_edit")
			: permissions.includes("tktasks_create");
	}, [isUpdate, permissions]);

	useEffect(() => {
		if (!canSelectAnyUser && !isUpdate) {
			setTimeout(() => {
				setValue("user_id", userId);
			});
		}
	}, [canSelectAnyUser, isUpdate, setValue]);

	//priorities
	const { data: priorities, isLoading: prioritiesLoading } = useQuery(
		["tk_task__priority"],
		() =>
			Api.genericQueryModel("tk_task__priority", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	const [expanded, setExpanded] = useState(null);
	const [contactHistoryOpenDialog, setContactHistoryOpen] = useState(false);
	const [contactHistoryElement, setContactHistoryElement] = useState(null);
	const [calendarOpenDialog, setCalendarOpenDialog] = useState(false);
	const calendarLocalizer = momentLocalizer(moment);
	const handleChangeExpanded = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleHistoryCancel = () => {
		setContactHistoryElement(null);
		setContactHistoryOpen(false);
	};

	const handleShowHistory = (h) => {
		if (h?.id) {
			setContactHistoryElement(h);
		} else {
			setContactHistoryElement(h);
		}
		setContactHistoryOpen(true);
	};

	const { isLoading: isHistoryCreating, mutate: createHistory } = useMutation(
		(_data) => Api.genericInsertModel("tk_task_data_history", _data)
	);

	const handleHistoryUpdate = (data) => {
		createHistory(
			{
				...data,
				tk_task_id: objectData?.id,
			},
			{
				onSettled: () => {},
				onSuccess: () => {
					refetchHistory();
					setContactHistoryElement(null);
					setContactHistoryOpen(false);
				},
			}
		);
	};

	//history
	const {
		data: history,
		isLoading: historyLoading,
		isSuccess: historySuccessLoading,
		refetch: refetchHistory,
		isRefetching: isHistoryRefetching,
	} = useQuery(
		["RfsUserDataHistory", objectData?.id],
		() =>
			Api.genericQueryModel("TkTaskDataHistory", {
				page: null,
				per_page: null,
				filters: {
					tk_task_id: objectData?.id,
				},
				with: ["via", "user_created"],
			}),
		{
			enabled: expanded == "history" && Boolean(objectData?.id),
			select: (data) => data?.data?.data,
		}
	);

	const calendarEvents = useMemo(() => {
		return (
			history?.map((h) => ({
				...h,
				title: `Via:${h?.via?.via}`,
				start: moment.utc(h.date).toDate(),
				end: moment.utc(h.date).toDate(),
				allDay: true,
			})) ?? []
		);
	}, [history]);
	//tasktype
	const { data: taskTypes, isLoading: taskTypesLoading } = useQuery(
		["tk_task_type"],
		() =>
			Api.genericQueryModel("tk_task_type", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) =>
				data?.data?.data?.map((rf) => ({
					...rf,
					title: rf.label,
				})),
		}
	);

	const froala = useRef();

	const optionLabelUser = useCallback((option, single) => {
		if (isInteger(option)) {
			if (option == single?.id) {
				return `${single.dropdown_name} (${single.email})`;
			}
		} else {
			return `${option.dropdown_name} (${option.email})`;
		}
	}, []);

	const taskTypeWatch =
		useWatch({ control, name: "task_type_id" }) ??
		objectData?.task_type_id ??
		null;

	const PolimorphicComponent = useMemo(() => {
		const TaskType = taskTypes?.find((tt) => tt.id == taskTypeWatch);
		if (Object.hasOwn(TaskTypeComponents, TaskType?.component)) {
			return TaskTypeComponents[TaskType?.component];
		}
		return null;
	}, [TaskTypeComponents, taskTypes, objectData, taskTypeWatch]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid
					container
					item
					spacing={2}
					xs={PolimorphicComponent && isUpdate ? 8 : 12}
					style={{
						height: "100%",
					}}
				>
					<Grid item container spacing={2} xs={12}>
						<Grid item md={4}>
							<Controller
								name="title"
								control={control}
								defaultValue={""}
								rules={{
									required: {
										value: true,
										message: "Title required",
									},
								}}
								render={({ onChange, onBlur, value }) => (
									<TextField
										label="Title"
										name="title"
										variant="outlined"
										value={value ?? ""}
										onChange={onChange}
										onBlur={onBlur}
										error={!!errors.title}
										helperText={errors.title?.message}
										style={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item md={7}>
							{isUpdate && (
								<StatesComponent
									workflow={"tasks_basic"}
									objectId={objectData?.id}
								/>
							)}
						</Grid>
					</Grid>
					<Grid item>
						<Controller
							name="description"
							control={control}
							defaultValue={""}
							render={({ onChange, onBlur, value }) => (
								<FroalaEditor
									config={editorConfig}
									ref={froala} //create a ref to the instance
									tag="textarea"
									model={value}
									onModelChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid container item spacing={2}>
						<Grid item md={6}>
							<Controller
								name="startDate"
								control={control}
								defaultValue={null}
								rules={{
									required: {
										value: true,
										message: "Start date required",
									},
									validate: (data) =>
										moment(getValues("dueDate")) >
											moment(data) ||
										"Must be before Due Date",
								}}
								render={({ onChange, onBlur, value }) => (
									<DatePicker2
										fullWidth
										label="Start Date"
										format="MM/DD/yyyy"
										onChange={onChange}
										value={
											value && value != "" ? value : null
										}
										error={errors.startDate}
										helperText={errors.startDate?.message}
										disabled={objectData?.startDate != null}
									/>
								)}
							/>
						</Grid>
						<Grid item md={6}>
							<Controller
								name="dueDate"
								control={control}
								defaultValue={null}
								rules={{
									validate: (data) =>
										moment(getValues("startDate")) <
											moment(data) ||
										"Must be after Start Date",
								}}
								render={({ onChange, onBlur, value }) => (
									<DatePicker2
										fullWidth
										label="Due Date"
										format="MM/DD/yyyy"
										onChange={onChange}
										value={
											value && value != "" ? value : null
										}
										disabled={objectData?.dueDate != null}
										error={errors.dueDate}
										helperText={errors.dueDate?.message}
									/>
								)}
							/>
						</Grid>
						<Grid item md={6}>
							<Controller
								name="task_priority_id"
								control={control}
								defaultValue={-1}
								rules={{
									validate: (value) =>
										(value && value != -1) ||
										"Priority required",
								}}
								render={({ onChange, onBlur, value }) => (
									<SelectWColor
										label={"Priority"}
										variant="outlined"
										onChange={onChange}
										value={
											value && value != "" ? value : -1
										}
										loading={prioritiesLoading}
										allowEmpty={true}
										emptyLabel="Select one"
										options={priorities}
										error={!!errors.task_priority_id}
										helperText={
											errors.task_priority_id?.message
										}
									/>
								)}
							/>
						</Grid>
						<Grid item md={6}>
							<Controller
								name="task_type_id"
								control={control}
								defaultValue={-1}
								rules={{
									validate: (value) =>
										(value && value != -1) ||
										"Task type required",
								}}
								render={({ onChange, onBlur, value }) => (
									<Select
										label={"Task Type"}
										variant="outlined"
										disabled={isUpdate}
										onChange={onChange}
										value={
											value && value != ""
												? value
												: objectData?.task_type_id ?? -1
										}
										loading={taskTypesLoading}
										allowEmpty={true}
										emptyLabel="Select one"
										options={taskTypes}
										error={!!errors.task_type_id}
										helperText={
											errors.task_type_id?.message
										}
									/>
								)}
							/>
						</Grid>
						<Grid item md={6}>
							<Controller
								name="user_id"
								control={control}
								defaultValue={-1}
								rules={{
									validate: (value) =>
										(value && value != -1) ||
										"Need an user assigned",
								}}
								render={({ onChange, onBlur, value }) => (
									<AutocompleteGeneric
										label="Assigned user"
										objectId={
											isUpdate
												? objectData?.user_id
												: !canSelectAnyUser
												? userId
												: undefined
										}
										onChange={onChange}
										disabled={!canSelectAnyUser}
										RenderOption={UserOption}
										optionLabel={optionLabelUser}
										model="user"
										include={["department", "languages"]}
										searchBy={{
											"or:": [
												"%fname",
												"%lname",
												"%phone",
												"%email",
											],
										}}
										error={errors.user_id}
										helperText={errors.user_id?.message}
									/>
								)}
							/>
						</Grid>
						{isUpdate && (
							<Grid item md={12}>
								<Accordion
									expanded={expanded == "history"}
									onChange={handleChangeExpanded("history")}
								>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel1bh-content"
										id="panel1bh-header"
									>
										<Typography
											variant="h6"
											style={{
												display: "flex",
												alignItems: "center",
												gap: "5px",
											}}
										>
											<History /> Contact History
											{historySuccessLoading && (
												<IconButton
													onClick={() =>
														setCalendarOpenDialog(
															true
														)
													}
													style={{
														paddingBottom: 0,
													}}
												>
													<Grid
														container
														direction="column"
														alignItems="center"
													>
														<Event />
														<Typography variant="caption">
															Calendar
														</Typography>
													</Grid>
												</IconButton>
											)}
										</Typography>
									</AccordionSummary>
									<AccordionDetails
										style={{
											display: "block",
											maxWidth: "unset",
										}}
									>
										{historyLoading ? (
											<LinearProgress />
										) : (
											<>
												<Carousel
													cards={[
														...(history?.map(
															(h) => ({
																...h,
																title: moment(
																	h.date
																).format(
																	"DD/MM/YYYY HH:mm"
																),
																content: (
																	<Box
																		direction="column"
																		display="flex"
																		flexDirection="column"
																	>
																		<Typography variant="subtitle">
																			Via:{" "}
																			{h
																				?.via
																				?.via ??
																				"Unknow"}
																		</Typography>
																		<Visibility
																			style={{
																				marginRight:
																					"auto",
																				marginLeft:
																					"auto",
																			}}
																		/>
																		{h?.user_created && (
																			<Typography
																				variant="subtitle"
																				noWrap={
																					true
																				}
																			>
																				By:{" "}
																				{
																					h
																						.user_created
																						.dropdown_name
																				}
																			</Typography>
																		)}
																	</Box>
																),
															})
														) ?? []),
														{
															title: "Add",
															content: <Add />,
														},
													]}
													onClick={handleShowHistory}
												/>
												{isHistoryRefetching && (
													<LinearProgress />
												)}
											</>
										)}
									</AccordionDetails>
								</Accordion>
							</Grid>
						)}
						<Grid item md={6}>
							{!isUpdate &&
								PolimorphicComponent && ( //render a select
									<PolimorphicComponent
										entityId={objectData?.entity_id}
										isUpdate={isUpdate}
									/>
								)}
						</Grid>
					</Grid>
				</Grid>
				{PolimorphicComponent && isUpdate && (
					<Grid item container xs={4}>
						<PolimorphicComponent
							entityId={objectData?.entity_id}
							isUpdate={isUpdate}
						/>
					</Grid>
				)}
			</Grid>
			<Popup
				openPopup={contactHistoryOpenDialog}
				title={"Contact History"}
				onClose={() => setContactHistoryOpen(false)}
				isfullWidth={true}
			>
				<ContactInfoHistory
					element={contactHistoryElement}
					onCancel={handleHistoryCancel}
					onUpdate={handleHistoryUpdate}
					updating={isHistoryCreating}
				/>{" "}
			</Popup>
			<Popup
				openPopup={calendarOpenDialog}
				title={"Calendar"}
				onClose={() => setCalendarOpenDialog(false)}
				isfullWidth={true}
			>
				<Calendar
					localizer={calendarLocalizer}
					startAccessor="start"
					endAccessor="end"
					style={{
						height: 500,
						minWidth: 700,
					}}
					events={calendarEvents}
					views={["month"]}
					onSelectEvent={handleShowHistory}
					onSelectSlot={(slot) =>
						handleShowHistory({
							date: slot.start,
						})
					}
					selectable={true}
				/>
			</Popup>
		</>
	);
};

export default TaskForm;
