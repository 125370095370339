import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Api from "../../../lib/api";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  error: {
    color: "red",
  },
}));

export default function ChangePatientPasswordForm(props) {
  const { onClose, setNotify } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [showPasswords, setShowPasswords] = useState({
    current_password: false,
    new_password: false,
    confirmation_password: false,
  });
 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { authUser } = useSelector((state) => state.auth);
  const classes = useStyles();

  const onSubmit = async (payload) => {
    setIsSubmitting(true);
    const { data } = await Api.changePatientPassword(authUser.id, payload);
    setIsSubmitting(false);
    if (data && data.error) {
      setAlertMessage(data.error);
    } else {
      if (data && data.success) {
        setNotify({ isOpen: true, message: data.success, type: "success" });
        setAlertMessage("");
        onClose();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div style={{ padding: "15px" }}>
        <Controller
          name="current_password"
          control={control}
          defaultValue={""}
          as={
            <div>
              <InputLabel>Current Password</InputLabel>
              <Input
                autoComplete="password"
                type={showPasswords.current_password ? "text" : "password"}
                autoFocus
                style={{ width: "100%" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPasswords({
                          ...showPasswords,
                          ...{
                            current_password: !showPasswords.current_password,
                          },
                        });
                      }}
                    >
                      {showPasswords.current_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.current_password && (
                <p className={classes.error}>
                  {errors.current_password.message}
                </p>
              )}
            </div>
          }
          rules={{ required: "Your current password is required" }}
        />

        <Controller
          name="new_password"
          control={control}
          defaultValue={""}
          as={
            <div style={{ marginTop: 7 }}>
              <InputLabel>New Password</InputLabel>
              <Input
                autoComplete="password"
                style={{ width: "100%" }}
                type={showPasswords.new_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPasswords({
                          ...showPasswords,
                          ...{
                            new_password: !showPasswords.new_password,
                          },
                        });
                      }}
                    >
                      {showPasswords.new_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.new_password && (
                <p className={classes.error}>{errors.new_password.message}</p>
              )}
            </div>
          }
          rules={{ required: "New password is required" }}
        />
        <Controller
          name="confirmation_password"
          control={control}
          defaultValue={""}
          as={
            <div style={{ marginTop: 7 }}>
              <InputLabel>Repeat the new password</InputLabel>
              <Input
                style={{ width: "100%" }}
                autoComplete="password"
                type={showPasswords.confirmation_password ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPasswords({
                          ...showPasswords,
                          ...{
                            confirmation_password:
                              !showPasswords.confirmation_password,
                          },
                        });
                      }}
                    >
                      {showPasswords.confirmation_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          }
          rules={{ required: "Confirm your new password is required" }}
        />
        {errors.confirmation_password && (
          <p className={classes.error}>
            {errors.confirmation_password.message}
          </p>
        )}
      </div>

      {alertMessage ? <Alert severity="error">{alertMessage}</Alert> : ""}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Button
          disabled={isSubmitting}
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          startIcon={
            isSubmitting ? (
              <CircularProgress
                color="primary"
                style={{ width: 16, height: 16 }}
              />
            ) : (
              ""
            )
          }
          color="secondary"
          type="submit"
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </div>

    </form>
  );
}
