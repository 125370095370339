import { useQuery } from "react-query";
import Api from "../../../lib/api";
import {
    Button,
	ButtonGroup,
	Card,
	CardContent,
	Grid,
	Typography,
	makeStyles,
} from "@material-ui/core";
import PieChart from "../../../components/controls/PieChart";
import Chart from "react-apexcharts";
import moment from "moment";
import { useMemo, useState } from "react";
import Select from "../../../components/controls/Select";
import { green, red } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
let month_array = [6, 12, 24, 36, 48];

const useStyle = makeStyles((theme) => ({
	card: {
		position: "relative",
	},
	ticketCount: {
		position: "absolute",
		bottom: 0,
		right: 15,
	},
	dateRange: {
		border: `1px solid ${theme.palette.grey[500]}`,
		padding: 15,
		borderRadius: theme.shape.borderRadius,
		"&>div": {
			border: 0,
		},
	},
}));

const RefundTicketDashboard = () => {
	const classes = useStyle();

	const previousMonth = useMemo(() => {
		return month_array.map((m) => ({
			id: m,
			name: `Previous ${m} Month`,
		}));
	}, []);

	// const [prevMonthInput, setPrevMonthInput] = useState(6);
	const [dateRange, setDateRange] = useState([moment().startOf('month'),moment().endOf('month')]);

	const { data: items, isLoading: itemsisLoading } = useQuery(
		["reportbyitems", dateRange],
		() =>
			Api.generiReport("rfsticketsbyitems", {
				history: dateRange,
			}),
		{
            select: (data) => data.data,
            enabled: dateRange != null
		}
	);

	const { data: priorities = [], isLoading: prioritiesIsLoading } = useQuery(
		["reportbypriorities", dateRange],
		() =>
			Api.generiReport("rfsticketsbypriorities", {
				history: dateRange,
			}),
		{
			select: (data) => data.data,
            enabled: dateRange!=null
		}
	);

	const { data: money = [], isLoading: moneyIsLoading } = useQuery(
		["reportbymoney", dateRange],
		() =>
			Api.generiReport("rfsticketsbymoney", {
				history: dateRange,
			}),
		{
			select: (data) => data.data,
            enabled: dateRange!=null
		}
	);

	const { data: states = [], isLoading: statesIsLoading } = useQuery(
		["reportbystates", dateRange],
		() =>
			Api.generiReport("rfsticketsbystates", {
				history: dateRange,
			}),
		{
			select: (data) => data.data,
            enabled: dateRange!=null
		}
	);

	return (
		<>
			<Grid
				container
				style={{
					paddingTop: 20,
				}}
				spacing={2}
			>
				{/* <Grid item md={3}>
					<Select
						label="View History"
						options={previousMonth}
						value={prevMonthInput}
						onChange={(event) =>
							setPrevMonthInput(event.target.value)
						}
					/>
				</Grid> */}
				<Grid item md={5} xs={12} >
					<DateRangePicker
						onChange={setDateRange}
						value={dateRange}
                        className={classes.dateRange}
                        format={'dd/MM/yyyy'}
					/>
				</Grid>
				<Grid item md={7} xs={12}>
					<ButtonGroup fullWidth={true}>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().startOf('week'),
                                moment(),
                            ])
                        }}>
                            This week
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(1,'week').startOf('week'),
                                moment().subtract(1,'week').endOf('week'),
                            ])
                        }}>
                            Last Week
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().startOf('month'),
                                moment(),
                            ])
                        }}>
                            This month
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(1,'month').startOf('month'),
                                moment().subtract(1,'month').endOf('month'),
                            ])
                        }}>
                            Last month
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(3,'month').startOf('month'),
                                moment(),
                            ])
                        }}>
                            Last 3 Months
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().subtract(6,'month').startOf('month'),
                                moment(),
                            ])
                        }}>
                            Last 6 Months
                        </Button>
                        <Button size="small" onClick={() => {
                            setDateRange([
                                moment().startOf('year'),
                                moment(),
                            ])
                        }}>
                            This Year
                        </Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={4}
				style={{
					marginTop: 15,
				}}
			>
				<Grid container item md={12} spacing={2}>
					<Grid container item md={6} xs={12} spacing={2}>
						<Typography
							style={{
								fontSize: 20,
								height: 28,
							}}
						>
							Money
						</Typography>
						<Grid container item md={12} spacing={2}>
							{moneyIsLoading ? (
								<Skeleton
									style={{
										width: "100%",
										height: 200,
									}}
								/>
							) : (
								<>
									<Grid item xs={6}>
										<Card
											style={{
												height: 100,
											}}
										>
											<CardContent
												className={classes.card}
											>
												<Typography variant="h5">
													Won:
												</Typography>
												{!moneyIsLoading && (
													<>
														<Typography
															style={{
																display:
																	"inline",
																color: green[500],
																width: "100%",
															}}
															variant="h3"
														>
															{money?.won?.toLocaleString(
																"en-US",
																{
																	style: "currency",
																	currency:
																		"USD",
																}
															)}
														</Typography>
														<Typography
															className={
																classes.ticketCount
															}
														>
															{money?.won_amount}{" "}
															ticket(s)
														</Typography>
													</>
												)}
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={6}>
										<Card
											style={{
												height: 100,
											}}
										>
											<CardContent
												className={classes.card}
											>
												<Typography variant="h5">
													Lost:
												</Typography>
												{!itemsisLoading && (
													<>
														<Typography
															style={{
																display:
																	"inline",
																color: red[500],
															}}
															variant="h3"
														>
															{money?.lost?.toLocaleString(
																"en-US",
																{
																	style: "currency",
																	currency:
																		"USD",
																}
															)}
														</Typography>
														<Typography
															className={
																classes.ticketCount
															}
														>
															{money?.lost_amount}{" "}
															ticket(s)
														</Typography>
													</>
												)}
											</CardContent>
										</Card>
									</Grid>
								</>
							)}
						</Grid>
						<Grid item container md={12} spacing={2}>
							{prioritiesIsLoading ? (
								<Skeleton
									style={{
										width: "100%",
										height: 200,
									}}
								/>
							) : (
								priorities.map((p) => (
									<Grid item md={6} xs={4}>
										<Card
											style={{
												height: 100,
											}}
										>
											<CardContent
												className={classes.card}
											>
												<Typography
													variant="h5"
													style={{
														color: p.color,
													}}
												>
													{p.name}:
												</Typography>
												<Typography
													style={{
														display: "inline",
													}}
													variant="h3"
												>
													{p?.amount?.toLocaleString(
														"en-US",
														{
															style: "currency",
															currency: "USD",
														}
													)}
												</Typography>
												<Typography
													className={
														classes.ticketCount
													}
												>
													{p.count} ticket(s)
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							)}
						</Grid>
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography
							style={{
								fontWeight: 500,
								fontSize: 15,
								height: 28,
							}}
						>
							Money per months
						</Typography>
						{moneyIsLoading ? (
							<Skeleton
								style={{
									height: 200,
								}}
							/>
						) : (
							<Chart
								options={{
									tooltip: {
										y: [
											{
												formatter: function (value) {
													return `$${value}`;
												},
											},
											{
												formatter: function (value) {
													return `$${value}`;
												},
											},
											{
												formatter: function (value) {
													return `${value} tickets`;
												},
											},
											{
												formatter: function (value) {
													return `${value} tickets`;
												},
											},
										],
										enabledOnSeries: [0, 1, 2, 3],
									},
									chart: {
										id: "money-series",
									},
									xaxis: {
										categories:
											money?.series?.length > 0
												? money?.series.map((s) =>s.date): [],
									},
									stroke: {
										curve: "smooth",
										width: 2,
									},
									yaxis: [
										{
											seriesName: "Win",
											max:
												money?.series?.reduce(
													(prev, curr) => {
														let max =
															curr.won > curr.lost
																? curr.won
																: curr.lost;
														return max > prev
															? max
															: prev;
													},
													0
												) ?? 0,
										},
										{
											seriesName: "Lost",
											show: false,
										},
										{
											opposite: true,
											seriesName: "Win tickets",
											max:
												money?.series?.reduce(
													(prev, curr) => {
														let max =
															curr.lost_amount >
															curr.won_amount
																? curr.lost_amount
																: curr.won_amount;
														return max > prev
															? max
															: prev;
													},
													0
												) ?? 0,
											decimalsInFloat: false,
										},
										{
											opposite: true,
											seriesName: "Lost tickets",
											show: false,
											forceNiceScale: true,
										},
									],
								}}
								series={[
									{
										type: "line",
										name: "Win",
										color: money.win_color,
										data:
											money?.series?.map((s) => s.won) ??
											[],
									},
									{
										type: "line",
										name: "Lost",
										color: money.lost_color,
										data:
											money?.series?.map((s) => s.lost) ??
											[],
									},
									{
										type: "column",
										name: "Win tickets",
										color: "#FFCCCC",
										data:
											money?.series?.map(
												(s) => s.won_amount
											) ?? [],
									},
									{
										type: "column",
										name: "Lost tickets",
										color: "#99CCFF",
										data:
											money?.series?.map(
												(s) => s.lost_amount
											) ?? [],
									},
								]}
							/>
						)}
					</Grid>
				</Grid>
				<Grid container item md={12} spacing={2}>
					<Grid item md={6} xs={12}>
						<Card>
							<CardContent>
								<Typography variant="h5">
									Most Refunded Items
								</Typography>
								<ul>
									{itemsisLoading ? (
										<Skeleton
											style={{
												width: "100%",
												height: 100,
											}}
										/>
									) : (
										items?.map((i) => (
											<li key={i.id}>
												<Typography>{`${i.item_name} (${i.count} times)`}</Typography>
											</li>
										))
									)}
								</ul>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container item md={12} xs={12} spacing={2}>
					<Grid item md={6} xs={12}>
						<Typography
							style={{
								fontSize: 20,
								height: 28,
							}}
						>
							Tickets by priorities
						</Typography>
						{prioritiesIsLoading ? (
							<Skeleton
								style={{
									width: "100%",
									height: 200,
								}}
							/>
						) : (
							<PieChart
								labels={priorities?.map((p) => p.name) ?? []}
								label={"Tickets by priorities"}
								info={priorities?.map((p) => p.count) ?? []}
								backgroundColors={
									priorities?.map((p) => p.color) ?? []
								}
							/>
						)}
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography
							style={{
								fontSize: 15,
								fontWeight: 500,
								height: 28,
							}}
						>
							Tickets grouped by priorities by months
						</Typography>
						{prioritiesIsLoading ? (
							<Skeleton
								style={{
									width: "100%",
									height: 200,
								}}
							/>
						) : (
							<Chart
								type="line"
								options={{
									chart: {
										id: "priorities-series",
									},
									xaxis: {
										categories:
											priorities.length > 0
												? priorities[0].series?.map(
														(s) => s.date
												  )
												: [],
									},
									stroke: {
										curve: "smooth",
										width: 2,
									},
								}}
								series={priorities?.map((p) => ({
									name: p.name,
									data: p?.series?.map((s) => s.count),
								}))}
							/>
						)}
					</Grid>
				</Grid>
				<Grid container item md={12}>
					<Grid item md={6} xs={12}>
						<Typography
							style={{
								fontSize: 20,
							}}
						>
							Tickets by states
						</Typography>
						<PieChart
							labels={states?.count?.map((p) => p.name) ?? []}
							label={"Tickets by priorities"}
							info={states?.count?.map((p) => p.count) ?? []}
							backgroundColors={
								states?.count?.map((p) => p.color) ?? []
							}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography style={{ fontSize: 15, fontWeight: 500 }}>
							Distribution of stay tickets in each state
						</Typography>
						<Chart
							type="line"
							options={{
								chart: {
									id: "states-series",
								},
								xaxis: {
									categories:
										states?.avg_time?.length > 0
											? Object.keys(
													states?.avg_time[0].serie
											  )
											: [],
								},
								stroke: {
									curve: "smooth",
									width: 1,
								},
							}}
							series={
								states?.avg_time?.map((p) => ({
									name: p.name,
									data: Object.values(p?.serie),
								})) ?? []
							}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default RefundTicketDashboard;
