import { Divider, Grid, TextField } from "@material-ui/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import Api from "../../../../lib/api";
import SelectWColor from "../../../../components/controls/SelectWColor";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker2 from "../../../../components/controls/DatePicker2";
import moment from "moment";
import Select from "../../../../components/controls/Select";
import { isInteger } from "lodash";
import AutocompleteGeneric from "../../../refunds/components/AutocompleteGeneric";
import UserOption from "../../../refunds/components/options/UserOption";
import TaskTypeComponents from "../types";

const TaskFilters = () => {
	const { control } = useFormContext();

	const [states, setStates] = useState(null);

	//priorities
	const { data: priorities, isLoading: prioritiesLoading } = useQuery(
		["tk_task_priority"],
		() =>
			Api.genericQueryModel("tk_task_priority", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	const { data: taskTypes, isLoading: taskTypesLoading } = useQuery(
		["tk_task_type"],
		() =>
			Api.genericQueryModel("tk_task_type", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) =>
				data?.data?.data?.map((rf) => ({
					...rf,
					title: rf.label,
				})),
		}
	);

	const optionLabelUser = useCallback((option, single) => {
		if (isInteger(option)) {
			if (option == single?.id) {
				return `${single.dropdown_name} [${single.email}](${single.phone})`;
			}
		} else {
			return `${option.dropdown_name} [${option.email}](${option.phone})`;
		}
    }, []);
    
    const handleEnableDisable = (enabled) => {
        console.log(enabled)
    }

	const taskTypeWatch = useWatch({ control, name: "task_type_id" });

	const PolimorphicComponent = useMemo(() => {
		const TaskType = taskTypes?.find((tt) => tt.id == taskTypeWatch);
		if (Object.hasOwn(TaskTypeComponents, TaskType?.component)) {
			return TaskTypeComponents[TaskType?.component];
		}
		return null;
	}, [TaskTypeComponents, taskTypes, taskTypeWatch]);

	//states
	const getStatesByWorkflow = async () => {
		const { data } = await Api.getStatesByWorkflow("tasks_basic");
		setStates(data.data);
	};

	useEffect(() => {
		getStatesByWorkflow();
	}, []);

	return (
		<>
			<Grid item container spacing={4}>
				<Grid item md={3}>
					<Controller
						name="title"
						control={control}
						defaultValue={""}
						render={({ onChange, onBlur, value }) => (
							<TextField
								label="Title"
								name="title"
								value={value}
								onChange={onChange}
								style={{ width: "100%" }}
								variant="outlined"
							/>
						)}
					/>
				</Grid>
				<Grid item md={3}>
					<Controller
						name="wf_state_id"
						control={control}
						defaultValue={-1}
						render={({ onChange, onBlur, value }) => (
							<SelectWColor
								label={"State"}
								variant="outlined"
								onChange={onChange}
								allowEmpty={true}
								value={value && value != "" ? value : -1}
								emptyLabel="Select one"
								options={states}
							/>
						)}
					/>
				</Grid>
				<Grid item md={3}>
					<Controller
						name="task_priority_id"
						control={control}
						defaultValue={-1}
						render={({ onChange, onBlur, value }) => (
							<SelectWColor
								label={"Priority"}
								variant="outlined"
								onChange={onChange}
								value={value && value != "" ? value : -1}
								allowEmpty={true}
								emptyLabel="Select one"
								options={priorities}
							/>
						)}
					/>
				</Grid>
				<Grid item md={3}>
					<Controller
						name="task_type_id"
						control={control}
						defaultValue={-1}
						render={({ onChange, onBlur, value }) => (
							<Select
								label={"Task Type"}
								variant="outlined"
								onChange={onChange}
								value={value && value != "" ? value : -1}
								allowEmpty={true}
								emptyLabel="Select one"
								options={taskTypes}
							/>
						)}
					/>
				</Grid>

				<Grid item md={3}>
					<Controller
						name=">=startDate"
						control={control}
						defaultValue={null}
						render={({ onChange, onBlur, value }) => (
							<DatePicker2
								fullWidth
								label="Start date after"
								format="MM/DD/yyyy"
								onChange={(value) =>
									onChange(moment(value).startOf("day"))
								}
								value={value && value != "" ? value : null}
							/>
						)}
					/>
				</Grid>
				<Grid item md={3}>
					<Controller
						name="<=startDate"
						control={control}
						defaultValue={null}
						render={({ onChange, onBlur, value }) => (
							<DatePicker2
								fullWidth
								label="Start date before"
								format="MM/DD/yyyy"
								onChange={(value) =>
									onChange(moment(value).endOf("day"))
								}
								value={value && value != "" ? value : null}
							/>
						)}
					/>
				</Grid>
				<Grid item md={3}>
					<Controller
						name="user_id"
						control={control}
						defaultValue={-1}
						render={({ onChange, onBlur, value }) => (
							<AutocompleteGeneric
								label="Assigned To"
								objectId={value && value != "" ? value : null}
								onChange={onChange}
								RenderOption={UserOption}
								optionLabel={optionLabelUser}
								model="user"
								include={["department", "languages"]}
                                searchBy={{
                                    'or:': [
									"%fname",
									"%lname",
									"%phone",
									"%email",
								]}}
								allowEmpty={true}
							/>
						)}
					/>
				</Grid>
				<Grid item md={3}>
					<Controller
						name="user_created_id"
						control={control}
						defaultValue={-1}
						render={({ onChange, onBlur, value }) => (
							<AutocompleteGeneric
								label="Created by"
								objectId={value && value != "" ? value : null}
								onChange={onChange}
								RenderOption={UserOption}
								optionLabel={optionLabelUser}
								model="user"
								include={["department", "languages"]}
                                searchBy={{
                                    'or:': [
									"%fname",
									"%lname",
									"%phone",
									"%email",
								]}}
								allowEmpty={true}
							/>
						)}
					/>
				</Grid>
			</Grid>
			{PolimorphicComponent && (
                <Grid container style={{
                    marginTop: 10
                }}>
					<Divider
						orientation="horizontal"
						style={{
							width: "100%",
						}}
					/>
                    <Grid item container xs={12} style={{
                        marginTop:10
                    }}>
						<PolimorphicComponent isFilter={true} />
					</Grid>
				</Grid>
			)}
		</>
	);
};
export default TaskFilters;
