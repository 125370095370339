import { CssBaseline, Paper } from "@material-ui/core";
import React, {  } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { isWidthUp } from "@material-ui/core/withWidth";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #FFF;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: #fff;
  padding: 0px 20px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const AppointmentConfirmationLayout = ({ children, routes, width }) => {

    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
        
          <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
            {children}
          </MainContent>
         
        </AppContent>
      </Root>
    );
  return null;
};

export default AppointmentConfirmationLayout;
