import { Grid } from "@material-ui/core";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import AutocompleteGeneric from "../../../../refunds/components/AutocompleteGeneric";
import ContactOption from "../../../../refunds/components/options/ContactOption";
import { useCallback, useEffect } from "react";
import { isInteger } from "lodash";
import { useQuery } from "react-query";
import Api from "../../../../../lib/api";
import { useFilterControlContext } from "../../../../refunds/components/GenericFilter";

const RefundFilter = () => {
	const { control, setValue, reset, register } = useFormContext();
	const { control: innerControl,  } = useForm();
	const optionLabelContact = useCallback((option, single) => {
		if (isInteger(option)) {
			if (option == single?.id) {
				return `${single.full_name} (${single.email})`;
			}
		} else {
			return `${option.full_name} (${option.email})`;
		}
	}, []);

	const { contact_id } = useWatch({
		control: innerControl,
		value: "contact_id",
    });
    
    const filterControl = useFilterControlContext();

	// console.log(contact_id);

	const { data:rfsTickets=null, isLoading } = useQuery(
		["rfs_tickets", contact_id],
		() =>
			Api.genericQueryModel("rfs_tickets", {
				filters: {
                    "invoice.contact_id": contact_id,
				},
				per_page: null,
				page: null,
			}),
		{
            enabled: Boolean(contact_id),
            select: data => data?.data?.data?.map(d => d.id),
		}
    );

    console.log(rfsTickets)
    
    useEffect(() => {
        if (rfsTickets) {
            setValue('entity_id', rfsTickets)
        } else {
            setValue('entity_id', "")
        }
    }, [rfsTickets])
    
    useEffect(() => {
        
        filterControl?.setEnabled(!isLoading)
    },[isLoading])

	return (
        <Grid item md={6}>
            <input type="hidden" {...register('entity_id')}/>
			<Controller
				name="contact_id"
				control={innerControl}
				defaultValue={-1}
				render={({ onChange, onBlur, value }) => (
					<AutocompleteGeneric
						label="Invoice Contact"
						objectId={value && value != "" ? value : null}
						onChange={onChange}
						RenderOption={ContactOption}
						optionLabel={optionLabelContact}
						model="contact"
						include={[
							"country",
							"stateID",
							"cityID",
							"organization",
							"languages",
						]}
						searchBy={{"or:":["fname", "email"]}}
                        allowEmpty={true}
                        filters={{
                            "invoice.refundTicket.id": '>0',
                        }}
					/>
				)}
			/>
		</Grid>
	);
};

export default RefundFilter;
