import { TextField } from "@material-ui/core";
import Iconify from "../Iconify";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: "#009688",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#009688",
        borderWidth: "1px",
      },
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "green",
    // },
  },
}));

export default function InputMoneyOk(props) {
  const {
    name,
    label,
    maxValue = 999999,
    minValue = 0,
    defaultValue = "",
    ...other
  } = props;
  const { control } = useFormContext();

  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <TextField
          variant="outlined"
          label={label}
          type="text"
          fullWidth
          value={value}
          className={classes.textField}
          onChange={(event) => {
            if (
              event.target.value == "" ||
              (parseFloat(event.target.value) > 0 &&
                Number(event.target.value) <= parseFloat(maxValue) &&
                Number(event.target.value) >= parseFloat(minValue))
            ) {
              onChange(event.target.value);
            }
          }}
          style={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "orange",
              },
            },
          }}
          InputProps={{
            classes: { input: classes.textField },
            startAdornment: (
              <Iconify
                icon={"material-symbols:attach-money"}
                style={{ color: "#616161" }}
                width={25}
                height={25}
              />
            ),
          }}
          inputProps={{
            pattern: "^[0-9]*[.,]?[0-9]*$",
            inputMode: "numeric",
            step: "0.01",
            min: "0.01",
          }}
          {...other}
        />
      )}
    />
  );
}
