import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import DescriptionIcon from "@material-ui/icons/Description";
import DocumentForm from "./forms/DocumentForm";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import {
  Paper,
  Checkbox,
  Fab,
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Popup from "./Popup";
import Button from "./controls/Button";
import FilterListIcon from "@material-ui/icons/FilterList";

import Iconify from "./Iconify.js";
import CRMUtils from "../utils/index.js";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));

function DocumentsSummary(props) {
  const { authUser } = useSelector((state) => state.auth);
  const [types, setTypes] = useState([]);
  const classes = useStyles();

  const {
    documents,
    documentTypes,
    onSave,
    onDeleteDocument,
    showDelete,
    canAdd = true,
    canEdit = true,
    canDelete = true,
    enableCamera = false,
    rReadonly = false,
  } = props;

  const [dataMaterialTable, setDataMaterialTable] = useState(
    documents.map((o) => ({ ...o }))
  );

  const canUserViewTheDocument = (documentType = "", documentID = null) => {
    if (documentType && documentID) {
      var cleanName = documentType
        .replaceAll(" ", "")
        .replaceAll("_", "")
        .toLowerCase();

      return CRMUtils.userHasPermission(authUser, [
        "doctypesview_" + cleanName + "_" + documentID,
      ]);
    } else {
      return true;
    }
  };

  const headCellsMaterial = [
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return rowData.collection_name;
      },
    },
    {
      field: "name",
      title: "Name",
      render: (rowData) => {
        return <div className={classes.wrapText}>{rowData.name}</div>;
      },
    },
    {
      field: "uploaded_by",
      title: "Uploaded By",
    },

    {
      field: "created_at",
      title: "Created",
      render: (rowData) => {
        return rowData.created_at
          ? moment(rowData.created_at).format("YYYY-MM-DD")
          : "";
      },
    },
    {
      field: "file",
      title: "File",

      render: (rowData) =>
        rowData.url && !rReadonly ? (
          canUserViewTheDocument(rowData.doc_type_name, rowData.doc_type_id) ? (
            <a href={rowData.url} target="_blank" rel="noreferrer">
              <AttachFileIcon />
            </a>
          ) : (
            <AttachFileIcon style={{ color: "#999999" }} />
          )
        ) : (
          ""
        ),
    },
    {
      field: "model",
      title: "From",
    },
    /*{
      field: "mime",
      title: "Mime",
      render: (rowData) => {
        return rowData.mime_type;
      },
    },*/
  ];

  const [documentstoshow, setdocumentstoshow] = useState([]);
  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filterValue, setFilterValue] = useState("All Documents");
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onDelete = async (id) => {
    const orgId = authUser.organization_id;
    onDeleteDocument(orgId, id);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  useEffect(() => {
    setdocumentstoshow(documents);
    setDataMaterialTable(documents.map((o) => ({ ...o })));
  }, [documents]);

  var docTypes = [];

  useEffect(() => {
    if (documentstoshow && types.length == 0) {
      var id = 0;
      documentstoshow?.map((a) => {
        var index = docTypes.filter((g) => a.collection_name == g.title);
        //  console.log("INDEX", index);
        if (index.length == 0) id += 1;
        return index.length == 0
          ? docTypes.push({ id: id, title: a.collection_name })
          : "";
      });
      setTypes([...docTypes]);
      //  console.log("DOCTYPES: ", docTypes);
    }
  }, [documentstoshow]);

  const saveDocument = (data) => {
    onSave(data);
    onClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const filterDocumments = (documents, type) => {
    if (type == "All Documents") {
      setDataMaterialTable([...documents]);
    } else {
      setDataMaterialTable(
        documents
          ? documents
              .filter((a) => a.collection_name == type)
              .map((o) => ({ ...o }))
          : []
      );
    }
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <TextField
            style={{
              marginRight: 6,
              maxWidth: 120,
              minWidth: 50,
              position: "relative",
              top: 24,
              left: 130,
              zIndex: 1,
            }}
            variant="outlined"
            defaultValue={filterValue}
            label="Types"
            onChange={(value) => {
              setFilterValue(value.target.value);
              filterDocumments(documentstoshow, value.target.value);
            }}
            select
          >
            <MenuItem key={0} value="All Documents">
              All Documents
            </MenuItem>
            {types.map((item) =>
              item.title != null ? (
                <MenuItem key={item.id} value={item.title}>
                  {item.title}
                </MenuItem>
              ) : (
                <MenuItem key={item.id} value={item.title}>
                  {item.name}
                </MenuItem>
              )
            )}
          </TextField>

          <MaterialTable
            style={{ marginTop: -45 }}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <Iconify
                      icon={"fluent:add-12-filled"}
                      width={25}
                      height={25}
                    />
                  </Fab>
                ),
                tooltip: "Attach document",
                disabled: !canAdd || rReadonly,
                hidden: !canAdd || rReadonly,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        !showDelete || !canDelete || rReadonly
                          ? "#bdbdbd"
                          : "#f44336",
                    }}
                  >
                    <Iconify
                      icon={"mingcute:delete-fill"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                disabled: !showDelete || !canDelete || rReadonly,
                hidden: !showDelete || !canDelete || rReadonly,
                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this document?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.id),
                  }),
              }),
            ]}
            options={{
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#e0f2f1",
                color: "#00695c",
              },
            }}
            title="Documents"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Attach document"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <DocumentForm
          saveDocument={saveDocument}
          documentTypes={documentTypes}
          className={classes.form}
          enableCamera={enableCamera}
        />
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default DocumentsSummary;
