import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: "90px",
    height: "90px",
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function Textarea(props) {
  const { name, label, rows, variant, ...other } = props;
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      rows={rows || 4}
      multiline
      variant={variant ? variant : "outlined"}
      label={label}
      {...other}
      className={other.className ? other.className : classes.textfield}
    />
  );
}
