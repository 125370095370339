import React, { useState, useEffect } from "react";
import { deepOrange, green } from "@material-ui/core/colors";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import TicketMessages from "../TicketMessages";
import CRMUtils from "../../utils";
import {
  Grid,
  makeStyles,
  Box,
  IconButton,
} from "@material-ui/core";
import Api from "../../lib/api";
import { isEmpty } from "lodash";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ContactDetails from "../../pages/contacts/details2";
import styled from "styled-components";
import moment from "moment";
import LaunchIcon from "@material-ui/icons/Launch";

import Popup from "../Popup";
const Wrapper = styled.div`
  position: relative;
  
  display: "block";
  visibilityIcon
  width: 100%;
  margin: "18px",
  padding: "5px",
`;
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  error: {
    color: "#f44336"
  },
}));

export default function TicketFormResolve(props) {
  const {
    addOrEdit,
    authUser,
    ticket,
    ticketCategories,
    ticketPriorities,
    ldepartments,
    lusers,
    lgroups,
    updateTicketComment,
    buildings,
  } = props;

  const [overdue, setOverdue] = useState(
    ticket
      ? moment(ticket?.overdue_date).format("yyyy-MM-DD HH:mm:ss")
      : moment().format("yyyy-MM-DD HH:mm:ss")
  );
  const userCanReassingAll = CRMUtils.userHasPermission(authUser, [
    "tickets_reassingsuper",
  ]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const { handleSubmit, reset, control, errors, setValue } = useForm();
  const [selectedBuilding, setSelectedBuilding] = useState(
    ticket ? ticket.building_id : null
  );
  /*const [priorityId, SetPriorityId] = React.useState(
    ticket ? ticket.priority_id : null
  );*/
  const [groups, setGroups] = useState(lgroups);
  const [departments, setDepartments] = useState(ldepartments);

  const [messages, setMessages] = useState(ticket ? ticket.comments.data : []);

  const [users, setUsers] = useState([]);

  const classes = useStyles();
  const [usersLocalNotifications, setUsersLocalNotifications] = useState([]);

  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);

  const openContact = async (id) => {
    const { data } = await Api.getContact(id);
    setContactDetail(data.data);
    setOpenContactPopup(true);
  };

  const oncloseContact = () => {
    setOpenContactPopup(false);
    setContactDetail(null);
  };

  const saveComment = async (payload) => {
    const { data } = await Api.createtTicketComment(ticket.id, payload);

    //setMessages([...messages, data.data]);

    setMessages(data.data.comments.data);
    updateTicketComment(data);
  };

  const userCanSuperEditTicket = CRMUtils.userHasPermission(authUser, [
    "tickets_SuperEdit",
  ]);
  const userIscallCenterManager = CRMUtils.userHasPermission(authUser, [
    "dashboard_callCenterManager",
  ]);
  const userIsCallCenterBasic = CRMUtils.userHasPermission(authUser, [
    "dashboard_callCenterBasic",
  ]);
  const userIsSalesManager = CRMUtils.userHasPermission(authUser, [
    "user_salesGroupManager",
  ]);
  const userIsgroupManager = CRMUtils.userHasPermission(authUser, [
    "user_groupManager",
  ]);

  const userIsFrontDeskManager = CRMUtils.userHasPermission(authUser, [
    "dashboard_frontDeskManager",
  ])
    ? true
    : false;

  const userIsBuildingAdmin = CRMUtils.userHasPermission(authUser, [
    "building_admin",
  ]);

  const userIsBillingManager = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingManager",
  ]);

  const userIsBillingAssistantManager = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingAssistant",
  ]);

  const getUserNotificationsOptions = (users) => {
    if (users) {
      return users
        .filter((user) => {
          return user.active === 1;
        })
        .map((user) => {
          return {
            id: user.id,
            title: user.dropdown_name,
          };
        });
    }
  };

  const onSubmit = (data) => {
    setSubmitFlag(true);
    data.organization_id = authUser.organization_id;

    if (!isEmpty(usersLocalNotifications)) {
      let notificationsArray = [];

      usersLocalNotifications.forEach((item) => {
        notificationsArray.push(item.id);
      });
      data.notificationsArray = notificationsArray;
    }

    //console.log("onSubmit", data);
    addOrEdit(data);
  };

  const getBuildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const CompleteTicket = () => {
    let data = [];
    data.organization_id = authUser.organization_id;
    data.status = "completed";

    addOrEdit(data);
  };

  const closeTicket = () => {
    let data = [];
    data.organization_id = authUser.organization_id;
    data.status = "resolved";

    addOrEdit(data);
  };

  const OpenCompleteTicket = () => {
    let data = [];
    data.organization_id = authUser.organization_id;
    data.status = "in_progress";

    addOrEdit(data);
  };

  const fillGroups = (department_id) => {
    const department = departments.find((department) => {
      return department.id === department_id;
    });

    const groups = !isEmpty(department.groups.data)
      ? department.groups.data.filter(
          (item) => item.building_id == selectedBuilding
        )
      : [];

    setGroups(groups);
  };

  const fillUsersInit = async (group_id) => {
    const { data } = await Api.getGroupUsers(group_id);
    const filterUsers = data.data.filter((user) => {
      return user.enable_ticket === 1;
    });

    if (
      ticket &&
      !filterUsers.find((user) => {
        return ticket.agent_id === user.id;
      })
    ) {
      await Api.getUser(ticket.agent_id).then((res) => {
        filterUsers.push(res.data.data);
      });
    }

    setUsers(filterUsers);
  };

  const fillUsers = async (group_id) => {
    const { data } = await Api.getGroupUsers(group_id);
    const filterUsers = data.data.filter((user) => {
      return user.enable_ticket === 1;
    });

    setUsers(filterUsers);
  };

  const getTicketCategoriesOptions = () => {
    return ticketCategories.map((ticketCategory) => {
      return {
        id: ticketCategory.id,
        title: ticketCategory.name,
      };
    });
  };

  const getDepartmentOptions = () => {
    return departments.map((department) => {
      return {
        id: department.id,
        title: department.name,
      };
    });
  };

  const getGroupsOptions = () => {
    let groupsToReturn = [];

    if (userIsBillingManager && !userCanSuperEditTicket) {
      groupsToReturn = groups
        .filter((item) => {
          return (
            item.enable_ticket == 1 &&
            (authUser.groups?.data.map((e) => e.id).indexOf(item.id) >= 0 ||
              item.id == ticket.group_id)
          );
        })
        .map((group) => {
          return {
            id: group.id,
            title: group.name,
          };
        });
    } else {
      groupsToReturn = groups
        .filter((item) => {
          return item.enable_ticket == 1;
        })
        .map((group) => {
          return {
            id: group.id,
            title: group.name,
          };
        });
    }

    return groupsToReturn;
  };

  const getUsersOptions = () => {
    if (
      ticket &&
      users &&
      users.find((user) => {
        return ticket.agent_id === user.id;
      })
    ) {
      // console.log("users", users);
      return users
        .filter((user) => {
          return user.active === 1 || ticket.agent_id === user.id;
        })
        .map((user) => {
          return {
            id: user.id,
            title: user.dropdown_name,
          };
        });
    } else {
      return users
        .filter((user) => {
          return user.active === 1;
        })
        .map((user) => {
          return {
            id: user.id,
            title: user.dropdown_name,
          };
        });
    }
  };

  const getTicketPrioritiesOptions = () => {
    return ticketPriorities.map((ticketPriority) => {
      return {
        id: ticketPriority.id,
        title: ticketPriority.name,
      };
    });
  };




  useEffect(() => {
    if (ticket) {
      fillUsersInit(ticket?.group_id);
      if (ticket?.department_id) {
        fillGroups(ticket?.department_id);
      }
    }
    // console.log(
    //   "cancancan",
    //   userIsSalesManager || userIsgroupManager,
    //   ticket?.status == "completed" ||
    //     ticket?.status == "resolved" ||
    //     ticket?.status == "canceled" ||
    //     !ticket?.editable
    // );
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
            <Box m={1}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Controller
                    name="building_id"
                    control={control}
                    defaultValue={ticket?.building_id || ""}
                    as={({ onChange, value }) => (
                      <Controls.Select
                        label="Building"
                        options={getBuildingOptions()}
                        value={value}
                        disabled={
                          (userCanReassingAll && ticket.editableuser)? false
                          :
                          !userCanSuperEditTicket &&
                          authUser.id != ticket?.user_id
                        }
                        onChange={(newValue) => {
                          onChange(newValue);
                          setSelectedBuilding(newValue.target.value);
                          setGroups([]);
                          setUsers([]);
                          setValue("department_id", null);
                        }}
                      />
                    )}
                    rules={{ required: "Ticket's building is required" }}
                  />
                  {errors.building_id && (
                  <p className={classes.error}>{errors.building_id.message}</p>
                )}
                </Grid>
                <Grid item xs={6}>
                  {
                    <Controller
                      name="department_id"
                      control={control}
                      defaultValue={ticket?.department_id || ""}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          label="Department"
                          options={getDepartmentOptions()}
                          value={value}
                          disabled={
                         (userCanReassingAll && ticket.editableuser)? false:   
                            !userCanSuperEditTicket &&
                            authUser.id != ticket?.user_id
                          }
                          onChange={(newValue) => {
                            onChange(newValue);
                            fillGroups(newValue.target.value);
                            setValue("group_id", null);
                          }}
                        />
                      )}
                      rules={{ required: "Ticket's department is required" }}
                    />
                  }
                  {errors.department_id && (
                  <p className={classes.error}>{errors.department_id.message}</p>
                )}
                </Grid>
                <Grid item xs={6}>
                  {
                    <Controller
                      name="group_id"
                      control={control}
                      defaultValue={ticket?.group_id || ""}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          label="Group"
                          value={value}
                          disabled={
                            (userCanReassingAll && ticket.editableuser)? false:
                            (
                              userIsBillingManager && ticket?.editable
                                ? false
                                : !userCanSuperEditTicket &&
                                  authUser.id != ticket?.user_id
                            )
                              ? true
                              : false
                          }
                          options={getGroupsOptions()}
                          onChange={(newValue) => {
                            onChange(newValue);
                            fillUsers(newValue.target.value);
                            setValue("agent_id", null);
                          }}
                        />
                      )}
                      rules={{ required: "Ticket's group is required" }}
                    />
                    
                  }
                  {errors.group_id && (
                  <p className={classes.error}>{errors.group_id.message}</p>
                )}
                </Grid>
                <Grid item xs={6}>
                  {
                    <Controller
                      name="agent_id"
                      control={control}
                      defaultValue={ticket?.agent_id || ""}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          label="Assigned To:"
                          value={value}
                          disabled={
                            (userCanReassingAll && ticket.editableuser)? false:
                            userIscallCenterManager &&
                            ticket?.status != "completed" &&
                            ticket?.status != "resolved" &&
                            ticket?.status != "canceled" &&
                            ticket?.editableuser
                              ? false
                              : (userIsSalesManager ||
                                  (userIsgroupManager &&
                                    !userIsBillingAssistantManager)) &&
                                (ticket?.status == "completed" ||
                                  ticket?.status == "resolved" ||
                                  ticket?.status == "canceled" ||
                                  ticket?.editable)
                              ? false
                              : !userCanSuperEditTicket &&
                                authUser.id != ticket?.user_id
                              ? true
                              : false
                          }
                          options={getUsersOptions()}
                          onChange={(newValue) => {
                            onChange(newValue);
                          }}
                        />
                      )}
                      rules={{ required: "Ticket's agent is required" }}
                    />
                  }
                   {errors.agent_id && (
                  <p className={classes.error}>{errors.agent_id.message}</p>
                )}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="category_id"
                    control={control}
                    defaultValue={ticket?.category_id || ""}
                    as={({ value, onChange }) => (
                      <Controls.Select
                        label="Category"
                        value={value}
                        disabled={
                          !userCanSuperEditTicket &&
                          authUser.id != ticket?.user_id
                        }
                        options={getTicketCategoriesOptions()}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="priority_id"
                    control={control}
                    defaultValue={ticket?.priority_id || ""}
                    as={({ value, onChange }) => (
                      <Controls.Select
                        label="Priority"
                        value={value}
                        disabled={
                          !userCanSuperEditTicket &&
                          authUser.id != ticket?.user_id
                        }
                        options={getTicketPrioritiesOptions()}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
                {
                  <Grid item xs={11}>
                    <Controller
                      name="contact"
                      control={control}
                      defaultValue={
                        ticket && ticket.contact
                          ? !isEmpty(ticket.contact.data)
                            ? `Name: ${ticket.contact.data.full_name}   Email: ${ticket.contact.data.email}    Phone: ${ticket.contact.data.phone}`
                            : ""
                          : ""
                      }
                      as={<Controls.Input label="Lead/Patient" />}
                      disabled
                    />
                  </Grid>
                }
                {ticket &&
                  ticket.contact &&
                  !isEmpty(ticket.contact.data) &&
                  (ticket.contact.data.user_id == authUser.id ||
                    userIsCallCenterBasic ||
                    userIscallCenterManager) && (
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="Open Ticket"
                        onClick={() => {
                          openContact(ticket.contact.data.id);
                        }}
                        boxShadow={3}
                      >
                        <LaunchIcon style={{ color: green[700] }} />
                      </IconButton>
                    </Grid>
                  )}

                <Grid item xs={12}>
                  <Controller
                    name="subject"
                    control={control}
                    defaultValue={ticket?.subject || ""}
                    as={<Controls.Input label="Subject" />}
                    disabled={
                      !userCanSuperEditTicket && authUser.id != ticket?.user_id
                    }
                    rules={{ required: "Ticket's subject is required" }}
                  />
                  {errors.subject && (
                    <p className="error">{errors.subject.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="content"
                    control={control}
                    defaultValue={ticket?.content || ""}
                    disabled={
                      !userCanSuperEditTicket && authUser.id != ticket?.user_id
                    }
                    as={<Controls.Textarea label="Text" rows="6" />}
                    rules={{ required: "Ticket's Text is required" }}
                  />
                  {errors.content && (
                    <p className="error">{errors.content.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="users_notifications"
                    control={control}
                    defaultValue={getUserNotificationsOptions(
                      ticket?.notifications.data || []
                    )}
                    as={({ onChange, value }) => (
                      <Controls.AutocompleteMultiple2
                        variant="outlined"
                        defaultValue={getUserNotificationsOptions(
                          ticket?.notifications.data || []
                        )}
                        disabled={
                          !userCanSuperEditTicket &&
                          authUser.id != ticket?.user_id
                        }
                        label="Notifications"
                        options={getUserNotificationsOptions(lusers)}
                        onChange={(value) => {
                          onChange(value);
                          setUsersLocalNotifications(value);
                        }}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="overdue_date"
                    control={control}
                    defaultValue={
                      ticket?.overdue_date
                        ? moment(overdue).format("yyyy-MM-DD HH:mm:ss")
                        : null
                    }
                    as={({ onChange, value }) => (
                      <Controls.DateTimePicker
                        label="Overdue Date"
                        shouldCloseOnSelect
                        defaultValue={
                          ticket?.overdue_date
                            ? moment(overdue).format("yyyy-MM-DD HH:mm:ss")
                            : null
                        }
                        disabled={
                          !userCanSuperEditTicket &&
                          authUser.id != ticket?.user_id
                        }
                        onChange={(date) => {
                          const fdate = moment(date).format(
                            "yyyy-MM-DD HH:mm:ss"
                          );
                          value = fdate;
                          onChange(fdate);
                          setOverdue(fdate);
                        }}
                        value={value}
                      />
                    )}
                    rules={{ required: "Ticket's Overdue is required" }}
                  />
                  {errors.overdue_date && (
                    <p className={classes.error}>{errors.overdue_date.message}</p>
                  )}
                </Grid>
              </Grid>
              {ticket && !isEmpty(ticket.documents.data) && (
                <Grid item xs={12}>
                  <div>
                    <Controls.Button
                      fullWidth={true}
                      // type="submit"
                      text="Attached File"
                      startIcon={<AttachmentIcon fontSize="small" />}
                      href={
                        ticket?.documents?.data
                          ? ticket.documents.data[0].url
                          : ""
                      }
                      target="_blank"
                    />
                  </div>
                </Grid>
              )}

              <Grid item xs={12}>
                <div>
                  <Controls.Button
                    disabled={
                      !submitFlag &&
                      (userIscallCenterManager || userIsFrontDeskManager) &&
                      ticket?.status != "completed" &&
                      ticket?.status != "resolved" &&
                      ticket?.status != "canceled" &&
                      ticket?.editableuser
                        ? false
                        : submitFlag ||
                          (ticket &&
                            (ticket?.status == "completed" ||
                              ticket?.status == "resolved" ||
                              ticket?.status == "canceled" ||
                              (authUser.id != ticket.user_id &&
                                !userCanSuperEditTicket &&
                                !userIsgroupManager &&
                                !userIsBuildingAdmin &&
                                !userIsSalesManager))) ||
                          ((userIsgroupManager || userIsSalesManager) &&
                            (ticket?.status == "completed" ||
                              ticket?.status == "resolved" ||
                              ticket?.status == "canceled" ||
                              !ticket?.editable))
                        ? true
                        : false
                    }
                    fullWidth={true}
                    type="submit"
                    text="Update Ticket"
                  />
                </div>
              </Grid>
            </Box>
          </form>

          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div>
                  <Controls.Button
                    disabled={
                      isEmpty(messages)
                        ? true
                        : ticket &&
                          !userIsgroupManager &&
                          !userIsBuildingAdmin &&
                          !userIsSalesManager &&
                          (ticket?.status == "completed" ||
                            ticket?.status == "resolved" ||
                            ticket?.status == "canceled" ||
                            authUser.id != ticket?.agent_id)
                        ? true
                        : ticket &&
                          userIsSalesManager &&
                          (ticket?.status == "completed" ||
                            ticket?.status == "resolved" ||
                            ticket?.status == "canceled" ||
                            !ticket?.editable)
                        ? true
                        : ticket &&
                          userIsgroupManager &&
                          (ticket?.status == "completed" ||
                            ticket?.status == "resolved" ||
                            ticket?.status == "canceled" ||
                            !ticket?.editable)
                        ? true
                        : ticket &&
                          userIsBuildingAdmin &&
                          (ticket?.status == "completed" ||
                            ticket?.status == "resolved" ||
                            ticket?.status == "canceled" ||
                            authUser.building_id != ticket?.agent_building_id)
                        ? true
                        : false
                    }
                    fullWidth={true}
                    type="submit"
                    text="Complete Ticket"
                    onClick={() => {
                      CompleteTicket();
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Controls.Button
                  disabled={
                    isEmpty(messages)
                      ? true
                      : ticket &&
                        !userCanSuperEditTicket &&
                        !userIsSalesManager &&
                        !userIsgroupManager &&
                        (ticket?.status != "completed" ||
                          ticket?.status == "resolved" ||
                          ticket?.status == "canceled" ||
                          authUser.id != ticket?.user_id)
                      ? true
                      : ticket &&
                        (userIsSalesManager || userIsgroupManager) &&
                        (ticket?.status != "completed" ||
                          ticket?.status == "resolved" ||
                          ticket?.status == "canceled" ||
                          !ticket?.editableuser)
                      ? true
                      : false
                  }
                  text="ReOpen Ticket"
                  align="right"
                  fullWidth={true}
                  onClick={() => {
                    OpenCompleteTicket();
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controls.Button
                  disabled={
                    isEmpty(messages)
                      ? true
                      : ticket &&
                        !userCanSuperEditTicket &&
                        !userIsSalesManager &&
                        !userIsgroupManager &&
                        (ticket?.status != "completed" ||
                          ticket?.status == "resolved" ||
                          ticket?.status == "canceled" ||
                          authUser.id != ticket?.user_id)
                      ? true
                      : ticket &&
                        (userIsSalesManager || userIsgroupManager) &&
                        (ticket?.status != "completed" ||
                          ticket?.status == "resolved" ||
                          ticket?.status == "canceled" ||
                          !ticket?.editableuser)
                      ? true
                      : false
                  }
                  text="Close Ticket"
                  align="right"
                  fullWidth={true}
                  onClick={() => {
                    closeTicket();
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={8}>
          {" "}
          <TicketMessages
            ticketclosed={
              ticket
                ? ticket?.status == "resolved" || ticket?.status == "canceled"
                  ? true
                  : false
                : false
            }
            comments={
              //  ticket.comments.data
              messages ? messages : []
            }
            onSave={saveComment}
          />{" "}
        </Grid>
      </Grid>
      <Wrapper>
        {contactDetail && (
          <Popup
            fullScreen
            title={`Contact ${contactDetail.full_name}`}
            openPopup={openContactPopup}
            onClose={oncloseContact}
          >
            <ContactDetails
              contact={contactDetail}
              onDetailClose={oncloseContact}
            />
          </Popup>
        )}
      </Wrapper>
    </>
  );
}
