import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

export default function CommentForm(props) {
  const {
    saveComment,
    ticketclosed = false,
    readonly = false,
    rReadonly = false,
    canAdd = true,
    editableNoDoctor = false,
    commentCategories,
  } = props;

  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const getCommentCategoriesOptions = () => {
    return commentCategories.map((commentCategory) => {
      return {
        id: commentCategory.id,
        title: commentCategory.name,
        color: commentCategory.color,
      };
    });
  };

  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);

  const onSubmit = (data) => {
    saveComment(data);

    setValue("comment", null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="comment"
            control={control}
            defaultValue=""
            as={
              <Controls.Textarea
                label="Comment"
                disabled={
                  ticketclosed ||
                  (!canAdd && !readonly) ||
                  (authUser.is_coordinator && rReadonly && !editableNoDoctor)
                }
              />
            }
            rules={{ required: "Comment is required" }}
          />
          {errors.comment && <p className="error">{errors.comment.message}</p>}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Controller
              name="comment_category_id"
              control={control}
              defaultValue=""
              as={({ value, onChange }) => (
                <Controls.SelectWColor
                  label="Category"
                  value={value}
                  disabled={
                    ticketclosed ||
                    (!canAdd && !readonly) ||
                    (authUser.is_coordinator && rReadonly && !editableNoDoctor)
                  }
                  options={getCommentCategoriesOptions()}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controls.Button
              type="submit"
              text="Submit"
              disabled={
                ticketclosed ||
                (!canAdd && !readonly) ||
                (authUser.is_coordinator && rReadonly && !editableNoDoctor)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
