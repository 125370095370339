import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ListItemText,
  ListItemIcon,
  ListItem,
  CardContent,
  CardActions,
  TextField,
  Box,
  Avatar,
  Switch,
  withStyles,
  FormControlLabel,
  FormHelperText,
  Card,
  CardActionArea,
  Button,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForm, Controller } from "react-hook-form";
import { FormProvider } from "../hook-form";
import InputMoneyOk from "../controls/InputMoneyOk";
import Controls from "../controls";
import CRMUtils from "../../utils";
import Iconify from "../Iconify";
import styled from "styled-components";
import moment from "moment";
import Api from "../../lib/api";
import { Alert } from "@material-ui/lab";

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "80px",
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
  dialogTitle2: {
    padding: "5px 10px",
    paddingRight: "0px",
  },
  dialogWrapper: {
    backgroundColor: "#ffffff",
  },
  indicator: {
    color: "#673ab7",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "63%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  primaryHeadingDisabled: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.disabled,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  dialogWrapper2: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
    zindex: 999999,
  },
  error: {
    color: "red",
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

const Wrapper = styled.div`
  padding: 0;
  flex-direction: column;
  display: flex;
  height: 100%;
`;
export default function RefundFormNew(props) {
  const { authUser } = useSelector((state) => state.auth);

  const methods = useForm();

  const {
    invoice,
    paymentToRefund,
    onSubmit,
    onClose,
    buildings,
    usersDropdown,
    gateways,
    refundReasons,
    onlineRefundabilityInfo,
  } = props;
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
    errors,
    setValue,
  } = methods;

  const watchAllFields = watch();

  const [lockDate, setLockDate] = useState(false);

  const userCanCreateManualChargeback = CRMUtils.userHasPermission(authUser, [
    "refund_manualchargeback",
  ]);

  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);

  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);

  const userCanApplyFullCredit = CRMUtils.userHasPermission(authUser, [
    "refund_applycredit",
  ]);

  const userCanCreateOnlineRefund = CRMUtils.userHasPermission(authUser, [
    "refund_create_online",
  ]);

  const [expanded, setExpanded] = React.useState([]);
  const [totalRefund, setTotalRefund] = React.useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [onlineOrManualRefund, setOnlineOrManualRefund] = useState("online");
  const [enforceManualChargeback, setEnforceManualChargeback] = useState(false);
  const [showRefundOrChargeback, setShowRefundOrChargeback] = useState(true);

  // const [onlineRefundabilityInfo, setOnlineRefundabilityInfo] = useState([]);

  const handleOnlineorManualRefundRadioChange = (event) => {
    setOnlineOrManualRefund(event.target.value);
  };

  const onSubmitLocal = async (info) => {
    setIsLoading(true);
    let refundsData = {};

    const formData = new FormData();

    Object.keys(info).forEach((key) => {
      const keyParts = key.split("-");

      if (keyParts[0] !== "applyCredit") {
        const payment = keyParts[1];

        if (!refundsData[payment]) {
          refundsData[payment] = {};
        }

        if (keyParts[0] === "items") {
          if (!refundsData[payment]["items"]) {
            refundsData[payment]["items"] = [];
          }

          refundsData[payment]["items"].push({
            payment_id: keyParts[1],
            parent_detail_id: keyParts[2],
            item_id: keyParts[3],
            amount: info[key],
          });
        } else {
          refundsData[payment][keyParts[0]] = info[key];
        }
      }
    });

    Object.keys(refundsData).forEach((payment) => {
      Object.keys(refundsData[payment]).forEach((key) => {
        if (key === "items") {
          refundsData[payment][key].forEach((item, index) => {
            Object.keys(item).forEach((itemKey) => {
              formData.append(
                `refundsData[${payment}][${key}][${index}][${itemKey}]`,
                item[itemKey]
              );
            });
          });
        } else {
          formData.append(
            `refundsData[${payment}][${key}]`,
            refundsData[payment][key]
          );
        }
      });
    });

    formData.append("applyCredit", info["applyCredit-"]);

    await onSubmit(formData);
    setIsLoading(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((a) => a !== panel));
    }
  };

  const refundReasonsOptions = (manualChargeBackOnly = false) => {

    return refundReasons
      .filter(
        (refundReason) =>
          !manualChargeBackOnly ||
          refundReason.is_available_for_manual_chargeback
      )
      .map((refundReason) => ({
        id: refundReason.id,
        title: refundReason.name,
      }));
  };

  const setValueDateLock = (payment_id, building_id) => {
    if (!userCanEditPaymentDateLock) {
      const building = buildings.find(
        (building) => building.id === building_id
      );

      if (building && building.lock_payment_date) {
        setValue(
          `payment_date-${payment_id}`,
          moment().format("YYYY-MM-DD HH:mm:ss")
        );

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  };

  const checkDateLock = (payment_id, building_id) => {
    if (!userCanEditPaymentDateLock) {
      const building = buildings.find(
        (building) => building.id === building_id
      );

      if (building && building.lock_payment_date) {
        return true;
      }
    }
    return false;
  };

  const paymentOptions = (manualChargeBackOnly = false) => {
    return gateways
      .filter(
        (gateway) =>
          !manualChargeBackOnly || gateway.is_available_for_manual_chargeback
      )
      .map((gateway) => ({
        id: gateway.name,
        title: gateway.name,
      }));
  };

  const getUserOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const calculateTotalSum = () => {
    return Object.keys(watchAllFields)
      .filter((fieldName) => fieldName.startsWith("items"))
      .reduce((sum, fieldName) => {
        const fieldValue = watchAllFields[fieldName]
          ? parseFloat(watchAllFields[fieldName])
          : 0;
        return sum + fieldValue;
      }, 0);
  };

  const checkIsOnlineRefundable = (onlineRefundabilityInfo, payment) => {
    if (onlineRefundabilityInfo) {
      if (
        onlineRefundabilityInfo.refundableOnlinePaymentHeaders?.includes(
          payment.headerId
        )
      ) {
        return true;
      } else if (
        payment.isOnline &&
        onlineRefundabilityInfo.nonRefundableOnlinePaymentHeaders?.includes(
          payment.headerId
        )
      ) {
        return false;
      }
    }
    return null;
  };

  const onRefundOrChaargebackSelected = (selectedOption) => {
    switch (selectedOption) {
      case "refund":
        setEnforceManualChargeback(false);
        setShowRefundOrChargeback(false);
        break;
      case "chargeback":
        setEnforceManualChargeback(true);
        setShowRefundOrChargeback(false);
        break;
    }
  };

  const renderRefundOrChargebackSelection = () => {
    return (
      <Card style={{ width: "100%", boxShadow: "none" }}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Do you wish to create an Automated Refund or a Manual Chargeback?
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Please note that submitted Manual Chargeback wont be reflected on
              the Merchant's Platofrm
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={() => onRefundOrChaargebackSelected("chargeback")}
          >
            Manual Chargeback
          </Button>
          <Button
            variant="outlined"
            style={{ color: "blue" }}
            onClick={() => onRefundOrChaargebackSelected("refund")}
          >
            Automated Refund
          </Button>
        </CardActions>
      </Card>
    );
  };

  useEffect(() => {
    setTotalRefund(calculateTotalSum());
  }, [watchAllFields]);

  return (
    <Wrapper>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitLocal)}>
        <Box style={{ position: "absolute", top: -2, right: 110 }}>
          <ListItem>
            <ListItemIcon style={{ margin: 0, minWidth: 45, marginRight: 10 }}>
              <Avatar style={{ backgroundColor: "#ffebee" }}>
                <Iconify
                  icon={"fluent:person-money-24-filled"}
                  width={30}
                  height={30}
                  style={{ color: "#f44336" }}
                />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={`Total To Refund: `}
              secondary={`$${totalRefund.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
              primaryTypographyProps={{
                style: {
                  fontSize: 17,
                  fontWeight: 700,
                  textAlign: "center",
                  color: "#f44336",
                },
              }}
              secondaryTypographyProps={{
                style: {
                  fontSize: 15,
                  fontWeight: 600,
                  textAlign: "center",
                  color: "#f44336",
                },
              }}
            />
          </ListItem>
        </Box>

        {userCanApplyFullCredit && (
          <Box style={{ position: "absolute", top: 8, right: "40%" }}>
            <ListItem>
              <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                  <Iconify
                    icon={"solar:hand-money-linear"}
                    width={25}
                    height={25}
                    style={{ color: "#009688" }}
                  />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Controller
                    name="applyCredit-"
                    control={control}
                    defaultValue={0}
                    as={({ onChange, value }) => (
                      <Controls.Checkbox
                        label="Would you like to apply a Total Credit to all Refunds?"
                        checked={value === 1 ? true : false}
                        onChange={(value) => onChange(value ? 1 : 0)}
                      />
                    )}
                  />
                }
              />
            </ListItem>
          </Box>
        )}

        <CardContent style={{ marginLeft: 0, marginRight: 0, marginTop: 3 }}>
          {paymentToRefund
            ? paymentToRefund.map((payment, index) => {
                console.log("payment: ", payment);
                const isOnlineRefundable = checkIsOnlineRefundable(
                  onlineRefundabilityInfo,
                  payment
                );
                const onlineOrManual = !userCanCreateOnlineRefund
                  ? "manual"
                  : watchAllFields[`online_or_manual-${payment.id}`]
                  ? watchAllFields[`online_or_manual-${payment.id}`]
                  : isOnlineRefundable
                  ? "online"
                  : "manual";

                let localRefundReasons = [];
                let localPaymentTypes = [];
                if (onlineOrManual == "manual") {
                  const showOnlyManualChargebackRefundReasons = true;
                  localPaymentTypes = paymentOptions(
                    showOnlyManualChargebackRefundReasons
                  );
                  localRefundReasons = refundReasonsOptions(
                    showOnlyManualChargebackRefundReasons
                  );
                } else {
                  localPaymentTypes = paymentOptions();
                  localRefundReasons = refundReasonsOptions();
                }

                return (
                  <Accordion
                    onChange={handleChange(payment.id)}
                    key={`${index}`}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${payment.payment_type} - ${payment.payment_date}`}
                      id={`${payment.id}`}
                      style={{
                        backgroundColor: "#e3f2fd",
                        borderTopLeftRadius: expanded.includes(payment.id)
                          ? 10
                          : 0,
                        borderTopRightRadius: expanded.includes(payment.id)
                          ? 10
                          : 0,

                        marginBottom: expanded.includes(payment.id) ? 0 : 3,
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 15,
                        }}
                      >
                        <Grid item xs={3}>
                          <ListItem style={{ margin: 0, padding: 0 }}>
                            <ListItemIcon>
                              <Iconify
                                icon={"fluent:payment-28-filled"}
                                width={28}
                                height={28}
                                style={{ color: "#009688" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={`${payment.payment_type}`}
                              secondary={`${payment.payment_date}`}
                            />
                          </ListItem>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            key={`${payment.id}`}
                            style={{ textAlign: "center" }}
                            className={classes.heading}
                          >
                            Last 4:
                          </Typography>
                          <Typography
                            key={`${payment.id}`}
                            style={{ textAlign: "center" }}
                            className={classes.secondaryHeading}
                          >
                            {payment.cc_last_4 ? payment.cc_last_4 : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            key={`${payment.id}`}
                            style={{ textAlign: "center" }}
                            className={classes.heading}
                          >
                            Payment Total:
                          </Typography>
                          <Typography
                            key={`${payment.id}`}
                            style={{ textAlign: "center" }}
                            className={classes.secondaryHeading}
                          >
                            {`$${(payment.amount
                              ? payment.amount
                              : 0
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            key={`${payment.id}`}
                            style={{ textAlign: "center" }}
                            className={classes.heading}
                          >
                            Building:
                          </Typography>
                          <Typography
                            key={`${payment.id}`}
                            style={{ textAlign: "center" }}
                            className={classes.secondaryHeading}
                          >
                            {`${payment.name}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        boxShadow: "0 0 12px 0 rgb(53 64 82 / 8%)",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: isOnlineRefundable
                            ? "flex-start"
                            : "center",
                          alignItems: "center",
                          margin: 15,
                        }}
                      >
                        {expanded.includes(payment.id) && (
                          <>
                            {isOnlineRefundable !== null && (
                              <Grid
                                container
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Alert
                                  severity={
                                    isOnlineRefundable &&
                                    onlineOrManual == "online"
                                      ? "info"
                                      : "warning"
                                  }
                                  style={{
                                    margin: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography>
                                    {isOnlineRefundable &&
                                    onlineOrManual == "online"
                                      ? `This Refund will be automatically
                                      reflected on the Merchant's Platform`
                                      : `This Refund will not be automatically
                                      reflected on the Merchant's Platform`}
                                  </Typography>
                                </Alert>

                                {userCanCreateManualChargeback &&
                                  isOnlineRefundable &&
                                  userCanCreateOnlineRefund && (
                                    <Controller
                                      name={`online_or_manual-${payment.id}`}
                                      control={control}
                                      defaultValue="online"
                                      as={
                                        <Controls.RadioGroup
                                          items={[
                                            {
                                              id: "online",
                                              title: "ONLINE REFUND",
                                            },
                                            {
                                              id: "manual",
                                              title: "MANUAL CHARGEBACK",
                                            },
                                          ]}
                                        />
                                      }
                                      // rules={{
                                      //   required:
                                      //     field.required === 1 ? true : false,
                                      // }}
                                    />
                                  )}

                                {/* <RadioGroup
                                  aria-label="quiz"
                                  name="quiz"
                                  value={onlineOrManualRefund}
                                  onChange={
                                    handleOnlineorManualRefundRadioChange
                                  }
                                >
                                  <FormControlLabel
                                    value="online"
                                    control={<Radio />}
                                    label=""
                                  />
                                  <FormControlLabel
                                    value="manual"
                                    control={<Radio />}
                                    label=""
                                  />
                                </RadioGroup> */}
                              </Grid>
                            )}

                            <Grid
                              item
                              xs={3}
                              style={{
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              <Controller
                                name={`refund_reason_id-${payment.id}`}
                                control={control}
                                defaultValue=""
                                render={({ onChange, value }) => (
                                  <Controls.Select
                                    label="Refund Reason"
                                    options={localRefundReasons}
                                    onChange={(event) =>
                                      onChange(event.target.value)
                                    }
                                    value={value}
                                  />
                                )}
                                rules={{
                                  required: "Refund Reason is required",
                                }}
                              />
                              <FormHelperText
                                className={classes.error}
                                id="refund_reason_id-helper-text"
                              >
                                {errors[`refund_reason_id-${payment.id}`]
                                  ? errors[`refund_reason_id-${payment.id}`]
                                      .message
                                  : " "}
                              </FormHelperText>
                            </Grid>

                            {(!isOnlineRefundable ||
                              (isOnlineRefundable &&
                                onlineOrManual === "manual")) && (
                              <>
                                <Grid
                                  item
                                  xs={3}
                                  style={{ marginBottom: 0, paddingBottom: 0 }}
                                >
                                  <Controller
                                    name={`payment_type-${payment.id}`}
                                    control={control}
                                    defaultValue=""
                                    as={({ onChange, value }) => (
                                      <Controls.Select
                                        label="Payment Type"
                                        options={localPaymentTypes}
                                        onChange={(event) =>
                                          onChange(event.target.value)
                                        }
                                        value={value}
                                      />
                                    )}
                                    rules={{
                                      required: "Payment Type is required",
                                    }}
                                  />
                                  <FormHelperText
                                    className={classes.error}
                                    id="payment_type-helper-text"
                                  >
                                    {errors[`payment_type-${payment.id}`]
                                      ? errors[`payment_type-${payment.id}`]
                                          .message
                                      : " "}
                                  </FormHelperText>
                                </Grid>
                              </>
                            )}

                            <Grid
                              item
                              xs={3}
                              style={{ marginBottom: 0, paddingBottom: 0 }}
                            >
                              <Controller
                                name={`payment_date-${payment.id}`}
                                type="date"
                                control={control}
                                defaultValue={moment().format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                                as={({ onChange, value }) => (
                                  <Controls.DateTimePicker
                                    label="Date"
                                    shouldCloseOnSelect
                                    fullWidth
                                    disabled={
                                      !userCanEditPayment ||
                                      lockDate ||
                                      checkDateLock(
                                        payment.id,
                                        payment.building_id
                                      ) ||
                                      (isOnlineRefundable &&
                                        onlineOrManual == "online")
                                    }
                                    onChange={(date) => {
                                      const fdate = moment(date).format(
                                        "yyyy-MM-DD HH:mm:ss"
                                      );
                                      value = fdate;
                                      onChange(fdate);
                                    }}
                                    value={value}
                                  />
                                )}
                                rules={{
                                  required: "Date is required",
                                }}
                              />
                              <FormHelperText
                                className={classes.error}
                                id="payment_date-helper-text"
                              >
                                {errors[`payment_date-${payment.id}`]
                                  ? errors[`payment_date-${payment.id}`].message
                                  : " "}
                              </FormHelperText>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ marginBottom: 0, paddingBottom: 0 }}
                            >
                              <Controller
                                name={`building_id-${payment.id}`}
                                control={control}
                                defaultValue={payment.building_id}
                                as={({ onChange, value }) => (
                                  <Controls.Select
                                    label="Building"
                                    disabled={
                                      !userCanChangeBuilding ||
                                      isOnlineRefundable
                                    }
                                    options={buildingOptions()}
                                    onChange={(event) => {
                                      onChange(event.target.value);
                                      setValueDateLock(
                                        payment.id,
                                        event.target.value
                                      );
                                    }}
                                    value={value}
                                  />
                                )}
                                rules={{ required: "Building is required" }}
                              />
                              <FormHelperText
                                className={classes.error}
                                id="building_id-helper-text"
                              >
                                {errors[`building_id-${payment.id}`]
                                  ? errors[`building_id-${payment.id}`].message
                                  : " "}
                              </FormHelperText>
                            </Grid>
                            {(!isOnlineRefundable ||
                              (isOnlineRefundable &&
                                watchAllFields[
                                  `online_or_manual-${payment.id}`
                                ] === "manual")) && (
                              <>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                >
                                  <Controller
                                    name={`cc_last_4-${payment.id}`}
                                    control={control}
                                    defaultValue=""
                                    render={({ onChange }) => (
                                      <TextField
                                        label="Last 4 CC"
                                        variant="outlined"
                                        onChange={(event) => onChange(event)}
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <FormHelperText
                                    className={classes.error}
                                    id="cc_last_4-helper-text"
                                  >
                                    {errors[`cc_last_4-${payment.id}`]
                                      ? errors[`cc_last_4-${payment.id}`]
                                          .message
                                      : " "}
                                  </FormHelperText>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                >
                                  <Controller
                                    name={`cardholder-${payment.id}`}
                                    control={control}
                                    defaultValue=""
                                    render={({ onChange }) => (
                                      <TextField
                                        label="Card Holder"
                                        variant="outlined"
                                        onChange={(event) => onChange(event)}
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <FormHelperText
                                    className={classes.error}
                                    id="cardholder-helper-text"
                                  >
                                    {errors[`cardholder-${payment.id}`]
                                      ? errors[`cardholder-${payment.id}`]
                                          .message
                                      : " "}
                                  </FormHelperText>
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                >
                                  <Controller
                                    name={`responsible_id-${payment.id}`}
                                    control={control}
                                    defaultValue=""
                                    as={({ onChange, value }) => (
                                      <Controls.Select
                                        label="Surgeon"
                                        // disabled={!userCanChangeBuilding}
                                        options={getUserOptions()}
                                        onChange={(event) =>
                                          onChange(event.target.value)
                                        }
                                        value={value}
                                      />
                                    )}
                                  />
                                  <FormHelperText
                                    className={classes.error}
                                    id="responsible_id-helper-text"
                                  >
                                    {errors[`responsible_id-${payment.id}`]
                                      ? errors[`responsible_id-${payment.id}`]
                                          .message
                                      : " "}
                                  </FormHelperText>
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid item xs={6}>
                                      {watchAllFields[`file-${payment.id}`] ? (
                                        <Box
                                          width={"100px"}
                                          height={"100px"}
                                          margin={"auto"}
                                          padding={"8px"}
                                        >
                                          {watchAllFields[`file-${payment.id}`]
                                            .type === "image/png" ||
                                          watchAllFields[`file-${payment.id}`]
                                            .type === "image/jpeg" ? (
                                            <Avatar
                                              alt={`file-${payment.id}`}
                                              src={window.URL.createObjectURL(
                                                watchAllFields[
                                                  `file-${payment.id}`
                                                ]
                                              )}
                                              className={classes.largeAvatar}
                                            />
                                          ) : (
                                            <Avatar
                                              className={classes.largeAvatar}
                                              style={{
                                                backgroundColor: "#B30B00",
                                                color: "#ffffff",
                                              }}
                                            >
                                              PDF
                                            </Avatar>
                                          )}{" "}
                                        </Box>
                                      ) : (
                                        <Box
                                          width={"100px"}
                                          height={"100px"}
                                          margin={"auto"}
                                          borderRadius={"50%"}
                                          padding={"8px"}
                                          border={
                                            "1px dashed rgba(145, 158, 171, 0.32)"
                                          }
                                        >
                                          <Box
                                            className="placeholder"
                                            style={{
                                              Zindex: 0,
                                              width: "100%",
                                              height: "100%",
                                              outline: "none",
                                              overflow: "hidden",
                                              flexDirection: "column",
                                              position: "relative",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              borderRadius: "50%",
                                              color: "#637381",
                                              backgroundColor: "#F4F6F8",
                                              "&:hover": { opacity: 0.72 },
                                              display: "flex",
                                            }}
                                          >
                                            <Iconify
                                              icon={"ic:baseline-receipt-long"}
                                              style={{
                                                width: 24,
                                                height: 24,
                                                marginBottom: 1,
                                              }}
                                            />
                                            <Typography
                                              variant="body1"
                                              style={{
                                                textAlign: "center",
                                                fontWeight: 600,
                                              }}
                                            >
                                              Receipt
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Controller
                                        name={`file-${payment.id}`}
                                        control={control}
                                        defaultValue=""
                                        as={({ onChange, value }) => (
                                          <Controls.DropzoneDialog
                                            label="*Attach file"
                                            style={{
                                              backgroundColor: "#e3f2fd",
                                              borderRadius: 50,
                                              color: "#01579b",
                                              marginTop: 10,
                                            }}
                                            onChange={(files) => {
                                              onChange(files[0]);
                                            }}
                                            value={value}
                                          />
                                        )}
                                        rules={{
                                          required: "Attach file is required",
                                        }}
                                      />
                                      <FormHelperText
                                        className={classes.error}
                                        id="file-helper-text"
                                      >
                                        {errors[`file-${payment.id}`]
                                          ? errors[`file-${payment.id}`].message
                                          : ""}
                                      </FormHelperText>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            <Grid
                              item
                              xs={9}
                              style={{ marginBottom: 0, paddingBottom: 0 }}
                            >
                              <Controller
                                name={`details-${payment.id}`}
                                control={control}
                                defaultValue=""
                                render={({ onChange }) => (
                                  <TextField
                                    label="Details"
                                    variant="outlined"
                                    onChange={(event) => onChange(event)}
                                    fullWidth
                                  />
                                )}
                              />
                              <FormHelperText
                                className={classes.error}
                                id="details-helper-text"
                              >
                                {errors[`details-${payment.id}`]
                                  ? errors[`details-${payment.id}`].message
                                  : " "}
                              </FormHelperText>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ marginBottom: 0, paddingBottom: 0 }}
                            >
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: 10,
                                }}
                              >
                                <Grid item xs={7}>
                                  <FormControlLabel
                                    labelPlacement="end"
                                    control={
                                      <Controller
                                        name={`ptChangePayment-${payment.id}`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ onChange, value }) => (
                                          <SSwitch
                                            key={`ptChangePayment-${payment.id}`}
                                            checked={value}
                                            onChange={(value) => {
                                              onChange(value.target.checked);
                                            }}
                                          />
                                        )}
                                      />
                                    }
                                    label={
                                      <>
                                        <Typography
                                          variant="subtitle2"
                                          style={{
                                            marginBottom: 0.5,
                                            color: "#607d8b",
                                            textAlign: "center",
                                          }}
                                        >
                                          Patient Change Payment
                                        </Typography>
                                      </>
                                    }
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <FormControlLabel
                                    labelPlacement="end"
                                    control={
                                      <Controller
                                        name={`isChargeback-${payment.id}`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ onChange, value }) => (
                                          <SSwitch
                                            key={`isChargeback-${payment.id}`}
                                            checked={value}
                                            onChange={(value) => {
                                              onChange(value.target.checked);
                                            }}
                                          />
                                        )}
                                      />
                                    }
                                    label={
                                      <>
                                        <Typography
                                          variant="subtitle2"
                                          style={{
                                            marginBottom: 0.5,
                                            color: "#607d8b",
                                            textAlign: "center",
                                          }}
                                        >
                                          Chargeback
                                        </Typography>
                                      </>
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={3}
                            style={{
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginTop: 15,
                            }}
                          >
                            {payment.paymentsDetail.map((item, index) => (
                              <>
                                <Grid item xs={6}>
                                  <Typography
                                    className={
                                      item.type === "forfeiture" ||
                                      item.type === "system" ||
                                      (item.amount ? item.amount : 0) -
                                        (item?.refund_payment_details_simple_sum_amount ||
                                          0) <
                                        0 ||
                                      item.void === 1
                                        ? classes.primaryHeadingDisabled
                                        : classes.primaryHeading
                                    }
                                    key={`item_name-${item.id}`}
                                  >
                                    {item.item_name}
                                    {item.item_is_revision ? (
                                      <strong
                                        style={{
                                          color: "#326a9b",
                                          marginLeft: 5,
                                          fontWeight: 400,
                                        }}
                                      >
                                        {"(Revision)"}
                                      </strong>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography
                                    className={
                                      item.type === "forfeiture" ||
                                      item.type === "system" ||
                                      (item.amount ? item.amount : 0) -
                                        (item?.refund_payment_details_simple_sum_amount ||
                                          0) <
                                        0 ||
                                      item.void === 1
                                        ? classes.primaryHeadingDisabled
                                        : classes.primaryHeading
                                    }
                                    key={`refundableamount_${item.id}`}
                                  >
                                    {`Refundable: $${
                                      item.type === "forfeiture" ||
                                      item.type === "system"
                                        ? "0.00"
                                        : (
                                            (item.amount ? item.amount : 0) -
                                            (item?.refund_payment_details_simple_sum_amount ||
                                              0)
                                          ).toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                    }`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  {expanded.includes(item.payment_id) && (
                                    <InputMoneyOk
                                      name={`items-${item.payment_id}-${item.id}-${item.item_id}`}
                                      label="Amount"
                                      type="number"
                                      disabled={
                                        item.type === "forfeiture" ||
                                        item.type === "system" ||
                                        (item.amount ? item.amount : 0) -
                                          (item?.refund_payment_details_simple_sum_amount ||
                                            0) <
                                          0 ||
                                        item.void === 1
                                      }
                                      maxValue={
                                        item.type === "forfeiture" ||
                                        item.type === "system"
                                          ? "0"
                                          : (item.amount ? item.amount : 0) -
                                            (item?.refund_payment_details_simple_sum_amount ||
                                              0)
                                      }
                                      minValue={
                                        userCanEditNegativePayments
                                          ? "-999999"
                                          : "1"
                                      }
                                    />
                                  )}
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : ""}
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Controls.Button
            text="Cancel"
            onClick={onClose}
            style={{ marginRight: 25 }}
          />

          <Controls.Button
            type="submit"
            text="Submit"
            disabled={totalRefund <= 0 || isLoading}
          />
        </CardActions>
      </FormProvider>
    </Wrapper>
  );
}
