import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography as MuiTypography,
  AppBar,
  Button,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { updateEntityService as updateRecordService } from "../../redux/slices/recordsSlice.js";
import { fetchCustomFieldsService } from "../../redux/slices/records.js";
import CRMUtils from "../../utils";

import Iconify from "../../components/Iconify";

import { isEmpty } from "lodash";
import { spacing, display, fontStyle } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import Api from "../../lib/api";
import Notification from "../../components/Notification";
import { fetchDocumentTypesService } from "../../redux/slices/documenttypes";

import { fetchTagsService } from "../../redux/slices/tags";
import Preoperation from "../../components/Preoperation";
import RecordProfile from "./profile";

import { useTranslation } from "react-i18next";

import { fetchRefundReasonsService } from "../../redux/slices/refundReasons";
import UltrasoundsVideos from "./ultrasounds.js";
import QuotesContact from "../../components/QuotesContact.js";
import InvoicesContact from "../../components/InvoicesContact.js";
import CreditMemosContact from "../../components/CreditMemosContact.js";
import TicketsContact from "../../components/TicketsContact.js";
import ContactToDoContacts from "../../components/ContactToDoContacts.js";
import RefundsPatient from "../../components/RefundsPatient.js";
import RecordImages from "./images.js";
import AppointmentsContacts from "../../components/AppointmentsContacts.js";

import { fetchEntitiesService as fetchBuildingsDropdownService } from "../../redux/slices/buildingsDropdownSlice.js";
import ContactPayments from "../../components/ContactPayments.js";
import ConfirmDialog from "../../components/ConfirmDialog.js";

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    height: "100%",
  },
  tab: {
    minWidth: "fit-content",
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceRecord = {
  stateListName: "contacts",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function RecordDetails(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "records"]);
  const { authUser } = useSelector((state) => state.auth);
  const userCanEdit = CRMUtils.userHasPermission(authUser, ["record_edit"]);
  const userCanViewBeforeAndAfter = CRMUtils.userHasPermission(authUser, [
    "record_viewbeforeandafter",
  ]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmLoading, setConfirmLoading] = useState(false);
  dataSliceBuildingsDropdown.orgId = authUser.organization_id;

  const { buildingsDropdown, buildingsDropdownLoading } = useSelector(
    (state) => state.buildingsDropdown
  );
  const [dataRefundRequests, setDataRefundRequests] = useState([]);
  const userCanCreateTickets = CRMUtils.userHasPermission(authUser, [
    "tickets_create",
  ]);

  const userCanCreateComments = CRMUtils.userHasPermission(authUser, [
    "comment_create",
  ]);

  const userCanCreateDocuments = CRMUtils.userHasPermission(authUser, [
    "document_create",
  ]);

  const userCanDeleteDocuments = CRMUtils.userHasPermission(authUser, [
    "document_delete",
  ]);

  const userIsBillingSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_billingSuper",
  ]);

  const userIsBillingManager = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingManager",
  ]);

  const userIsBillingManagerAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingManagerAssistant",
  ]);

  const userCanSendMCD = CRMUtils.userHasPermission(authUser, [
    "record_sendMCD",
  ]);

  const userIsPersonalAssistant = CRMUtils.userHasPermission(authUser, [
    "menu_PersonalAssistant",
  ]);

  const userIsCoordinator_SalesManager =
    CRMUtils.userHasPermission(authUser, ["user_salesGroupManager"]) ||
    authUser.is_coordinator == 1;

  const userIsBilling =
    CRMUtils.userHasPermission(authUser, ["dashboard_billingBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingAssistant"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingManager"]) ||
    CRMUtils.userHasPermission(authUser, [
      "dashboard_billingManagerAssistant",
    ]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingMGAssistant"])
      ? true
      : false;

  const userIsCallCenter =
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterManager"])
      ? true
      : false;
  const userIsFrontDesk =
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskManager"])
      ? true
      : false;

  const userIsSocialMedia =
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaManager"])
      ? true
      : false;

  dataSliceRecord.orgId = authUser.organization_id;
  const { tags } = useSelector((state) => state.tags);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const {
    onClose,
    opentab,
    readonly = false,
    openTabHeader,
    errors = null,
    isLoading = null,
    setIsLoading = null,
    selectedTransactionsIds = [],
  } = props;

  const { fields } = useSelector((state) => state.records);
  const { documentTypes } = useSelector((state) => state.documenttypes);

  const [usersMessages, setUsersMessages] = useState([]);

  const [recordValue, setRecordValue] = useState(
    openTabHeader ? openTabHeader : 0
  );
  const [record, setRecord] = useState({});

  const { refundReasons } = useSelector((state) => state.refundReasons);

  const handleRecordTab = (event, newValue) => {
    setRecordValue(newValue);
  };
  const classes = useStyles();
  const [recordDocuments, setRecordDocuments] = useState([]);
  const userCanMarkPhoneAsInvalidForVoice = CRMUtils.userHasPermission(
    authUser,
    ["user_markphoneasinvalidforvoice"]
  );
  const userCanUnmarkPhoneAsInvalidForVoice =
    (CRMUtils.userHasPermission(authUser, [
      "user_unmarkphoneasinvalidforvoice",
    ]) &&
      record &&
      record.contact &&
      record.contact.data &&
      record.contact.data.invalid_phone_for_voice &&
      record.contact.data.invalid_phone_for_voice.created_by == authUser.id) ||
    CRMUtils.userHasPermission(authUser, [
      "user_unmarkphoneasinvalidforvoicesuper",
    ]);

  const getRecord = async () => {
    const { data } = await Api.getRecord(props.record.id);
    setRecord(data.data);

    return data.data;
  };

  const getRecordDocuments = async () => {
    const { data } = await Api.getRecordDocuments(props.record.id);
    setRecordDocuments(data.data);
  };

  const saveComment = async (payload) => {
    const { data } = await Api.createRecordComment(record.id, payload);

    setRecord({
      ...record,
      contactRecordComments: {
        ...record.contactRecordComments,
        data: [data.data, ...record.contactRecordComments.data],
      },
    });
  };

  const onDeleteDocument = async (orgId, id) => {
    const { data } = await Api.deleteDocument(orgId, id);

    let filteredArray = recordDocuments.filter((item) => item.id !== id);

    setRecordDocuments(filteredArray);

    setRecord({
      ...record,
      contactRecordDocuments: {
        data: [
          ...record.contactRecordDocuments.data.filter(
            (item) => item.id !== id
          ),
        ],
      },
    });
  };

  const saveDocument = async (payload) => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.createRecordDocument(record.id, formData);

    setRecord({
      ...record,
      contactRecordDocuments: {
        ...record.contactRecordDocuments,
        data: [data.data, ...record.contactRecordDocuments.data],
      },
    });

    let tempArray = setRecordDocuments([data.data, ...recordDocuments]);
  };

  const addOrEdit = async (payload) => {
    if (record) {
      if (payload.dob) {
        const momentDate = new moment(payload.dob);

        payload.dob = momentDate.format("YYYY-MM-DD HH:mm:ss");
      }

      const contactdata = {
        height: payload.height ? payload.height : null,
        weight: payload.weight ? payload.weight : null,
        bmi: payload.bmi ? payload.bmi : null,
        fname: payload.fname ? payload.fname : null,
        lname: payload.lname ? payload.lname : null,
        email: payload.email ? payload.email : null,
        phone: payload.phone ? payload.phone : null,
        address: payload.address ? payload.address : null,
        dob: payload.dob ? payload.dob : null,
        city_field: payload.city_field ? payload.city_field : null,
        state_field: payload.state_field ? payload.state_field : null,
        country_field: payload.country_field ? payload.country_field : null,
        zip: payload.zip ? payload.zip : null,
        apt: payload.apt ? payload.apt : null,

        medical_conditions: payload.medical_conditions
          ? payload.medical_conditions
          : null,
        surgical_history: payload.surgical_history
          ? payload.surgical_history
          : null,
      };

      payload.contactdata = contactdata;

      dataSliceRecord.dataUpdate = {
        id: record.id,
        data: payload,
        successHandle: addOrEditSuccefull,
      };
      await dispatch(updateRecordService(dataSliceRecord));
      getRecord();
    }
  };

  const addOrEditSuccefull = () => {
    setNotify({
      isOpen: true,
      message: t("common:general.Submit_ok"),
      type: "success",
    });
  };

  const getColor = (status) => {
    switch (status) {
      case "pending":
        return "rgb(97, 97, 97)";
        break;
      case "approved":
        return "rgb(56, 142, 60)";
        break;
      case "declined":
        return "rgb(211, 47, 47)";
        break;
        return null;
    }
  };

  const saveToDo = async (payload) => {
    if (payload.followup) payload.type = "followup";
    if (payload.call) payload.type = "call";
    if (payload.sms) payload.type = "sms";
    if (payload.email) payload.type = "email";
    if (payload.contact) payload.type = "contact";
    if (payload.deposit) payload.type = "deposit";
    if (payload.pending_consultation) payload.type = "pending_consultation";

    try {
      const { data } = await Api.addContactToDo(
        record.contact.data.id,
        payload
      );

      getRecord();

      setNotify({
        isOpen: true,
        message: "To Do Created!",
        type: "success",
      });
    } catch (e) {
      console.error("Error Creating To Do: ", e);
      setNotify({
        isOpen: true,
        message: "Error Creating To Do!",
        type: "error",
      });
    }
  };
  const getContactSMSMMSUsers = async () => {
    const { data } = await Api.getContactSMSMMSUsers(record.contact_id);

    setUsersMessages(data.data);
  };

  useEffect(() => {
    if (isEmpty(buildingsDropdown) && !buildingsDropdownLoading) {
      dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
    }

    const orgId = authUser.organization_id;
    if (setIsLoading) {
      setIsLoading(true);
    }
    getRecordDocuments();
    getRecord().then((record) => {
      dispatch(fetchTagsService(orgId));

      if (isEmpty(documentTypes)) dispatch(fetchDocumentTypesService(orgId));
      if (isEmpty(fields)) dispatch(fetchCustomFieldsService(orgId));

      if (isEmpty(refundReasons)) {
        dispatch(fetchRefundReasonsService(orgId));
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
  }, [props.record?.id]);

  const markPhoneAsInvalidForCalls = async () => {
    if (record && record.contact && record.contact.data) {
      setConfirmLoading(true);
      await Api.markPhoneNumberAsInvalidForVoice(record.contact.data.phone);

      setRecord({
        ...record,
        ...{
          contact: {
            data: {
              ...record.contact.data,
              ...{ is_phone_invalid_for_voice: true },
            },
          },
        },
      });
      setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
      setConfirmLoading(false);
    }
  };

  const unmarkPhoneAsInvalidForCalls = async () => {
    if (record && record.contact && record.contact.data) {
      setConfirmLoading(true);
      await Api.unmarkPhoneNumberAsInvalidForVoice(record.contact.data.phone);
      setRecord({
        ...record,
        ...{
          contact: {
            data: {
              ...record.contact.data,
              ...{ is_phone_invalid_for_voice: false },
            },
          },
        },
      });
      setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
      setConfirmLoading(false);
    }
  };
  const getTimze = () => {
    const time = moment()
      .tz(record?.phoneAreaCode)
      .format("MM-DD-YYYY hh:mm A");

    if (time) {
      return (
        <Typography
          variant="subtitle2"
          style={{
            color: "#009688",
            fontFamily:
              '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
          }}
        >
          {`Current Time for This Patient: ${time}`}{" "}
        </Typography>
      );
    }

    return (
      <Typography
        variant="subtitle2"
        style={{
          fontStyle: "italic",
          color: "#f44336",
          fontFamily:
            '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
        }}
      >
        Please ensure the phone is already entered and formatted correctly to
        check the current time for this patient.
      </Typography>
    );
  };

  if (isEmpty(record) || isLoading) return "";
  return (
    <React.Fragment>
      <Helmet title="Record Details" />
      <div
        style={{
          position: "absolute",
          top: 25,
          left: "25vw",
          zIndex: 1400,
        }}
      >
        {record.phoneAreaCode &&
        typeof record.phoneAreaCode === "string" &&
        record.phoneAreaCode !== "" ? (
          getTimze(record.phoneAreaCode)
        ) : (
          <Typography
            variant="subtitle2"
            style={{
              fontStyle: "italic",
              color: "#f44336",
              fontFamily:
                '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
            }}
          >
            Please ensure the phone is already entered and formatted correctly
            to check the current time for this patient.
          </Typography>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: 8,
          left: "70vw",
          zIndex: 1400,
        }}
      >
        {record &&
        record.contact &&
        record.contact.data &&
        record.contact.data.is_phone_invalid_for_voice &&
        userCanUnmarkPhoneAsInvalidForVoice ? (
          <Button
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title:
                  "Are you sure to unmark the phone " +
                  record.contact.data.phone +
                  " as invalid for calls?",
                subTitle: "",
                onConfirm: () => unmarkPhoneAsInvalidForCalls(),
              });
            }}
            style={{
              borderRadius: 16,
              backgroundColor: "rgb(215 250 203)",
              color: "rgb(89 163 64)",
              marginLeft: 10,
              marginTop: 0,
            }}
            startIcon={
              <Iconify
                style={{ marginLeft: 5 }}
                icon={"fluent:call-checkmark-24-filled"}
              />
            }
          >
            {"Unmark phone as invalid for calls"}
          </Button>
        ) : (
          ""
        )}
        {record &&
        record.contact &&
        record.contact.data &&
        !record.contact.data.is_phone_invalid_for_voice &&
        userCanMarkPhoneAsInvalidForVoice ? (
          <Button
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title:
                  "Are you sure to mark the phone " +
                  record.contact.data.phone +
                  " as invalid for calls?",
                subTitle: "",
                onConfirm: () => markPhoneAsInvalidForCalls(),
              });
            }}
            style={{
              borderRadius: 16,
              backgroundColor: "rgb(238 242 243)",
              color: "rgb(72 71 112)",
              marginLeft: 10,
              marginTop: 7,
            }}
            startIcon={
              <Iconify
                style={{ marginLeft: 5 }}
                icon={"fluent:call-dismiss-24-filled"}
              />
            }
          >
            {"Mark phone as invalid for calls"}
          </Button>
        ) : (
          ""
        )}
      </div>

      <Grid container>
        <Grid item xs={12}>
          <Shadow>
            <Card>
              <AppBar
                position="static"
                style={{
                  borderTopLeftRadius: 25,
                  borderTopRightRadius: 25,
                  backgroundColor: "#e0f2f1",
                  color: "#00695c",
                }}
              >
                <Tabs
                  value={recordValue}
                  onChange={handleRecordTab}
                  variant="fullWidth"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: { background: "#009688" },
                  }}
                >
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"fluent:person-28-filled"}
                        width={28}
                        height={28}
                      />
                    }
                    label={t("common:general.Profile")}
                    disabled={
                      !userCanEdit &&
                      !userIsCallCenter &&
                      !userIsBilling &&
                      !userIsFrontDesk &&
                      !userIsSocialMedia
                    }
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"fluent:calendar-person-24-filled"}
                        width={28}
                        height={28}
                      />
                    }
                    label="EMR"
                    disabled={!userCanEdit && !userIsBilling && !userCanSendMCD}
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"fluent:reciept-24-filled"}
                        width={28}
                        height={28}
                      />
                    }
                    label={t("common:general.Quotes")}
                    disabled={
                      !userCanEdit &&
                      !userIsCallCenter &&
                      !userIsFrontDesk &&
                      !userIsSocialMedia &&
                      !userIsBilling
                    }
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"fa6-solid:file-invoice-dollar"}
                        width={28}
                        height={28}
                      />
                    }
                    label={t("common:general.Invoices")}
                    disabled={
                      !userCanEdit &&
                      !userIsCallCenter &&
                      !userIsFrontDesk &&
                      !userIsSocialMedia &&
                      !userIsBilling
                    }
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"icon-park-twotone:gift-bag"}
                        width={28}
                        height={28}
                      />
                    }
                    label="Credits"
                    disabled={!userCanEdit && !userIsBilling}
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"fluent:payment-16-filled"}
                        width={28}
                        height={28}
                      />
                    }
                    label="Payments"
                    disabled={!userCanEdit && !userIsBilling}
                  />

                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"mdi:credit-card-refund"}
                        width={28}
                        height={28}
                      />
                    }
                    label="Refunds"
                    disabled={!userCanEdit && !userIsBilling}
                  />

                  {
                    <Tab
                      className={classes.tab}
                      icon={
                        <Iconify
                          icon={"heroicons:ticket-solid"}
                          width={28}
                          height={28}
                        />
                      }
                      label="Tickets"
                      disabled={
                        !userCanEdit &&
                        !userIsCallCenter &&
                        !userIsBilling &&
                        !userIsFrontDesk &&
                        !userIsSocialMedia
                      }
                    />
                  }
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"material-symbols:assignment-rounded"}
                        width={28}
                        height={28}
                      />
                    }
                    label="To Do"
                    disabled={
                      !userCanEdit &&
                      !userIsBillingSuper &&
                      !userIsBillingManagerAssistant
                    }
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"fluent:calendar-rtl-28-filled"}
                        width={28}
                        height={28}
                      />
                    }
                    label="Appointments"
                    disabled={
                      !userCanEdit &&
                      !userIsBillingSuper &&
                      !userIsBillingManagerAssistant
                    }
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"material-symbols:hangout-video-rounded"}
                        width={28}
                        height={28}
                      />
                    }
                    label="Ultrasounds"
                    disabled={!userCanEdit}
                  />
                  <Tab
                    className={classes.tab}
                    icon={
                      <Iconify
                        icon={"wpf:stack-of-photos"}
                        width={28}
                        height={28}
                      />
                    }
                    label="Before/After"
                    disabled={!userCanEdit && !userCanViewBeforeAndAfter}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={recordValue} index={0} {...a11yProps(0)}>
                <RecordProfile
                  setRecord={setRecord}
                  usersMessages={usersMessages}
                  record={record}
                  documents={recordDocuments}
                  addOrEdit={addOrEdit}
                  tags={tags}
                  opentab={opentab}
                  fields={fields}
                  documentTypes={documentTypes}
                  saveDocument={saveDocument}
                  saveComment={saveComment}
                  onDeleteDocument={onDeleteDocument}
                  userCanEdit={userCanEdit}
                  userCanCreateComments={userCanCreateComments}
                  userCanCreateDocuments={userCanCreateDocuments}
                  userCanDeleteDocuments={userCanDeleteDocuments}
                  authUser={authUser}
                  readonly={readonly}
                  rReadonly={authUser.is_coordinator ? !record.editable : false}
                  editableNoDoctor={record.editableNoDoctor}
                  userIsCallCenter={userIsCallCenter}
                  userIsFrontDesk={userIsFrontDesk}
                  userIsSocialMedia={userIsSocialMedia}
                  errors={errors}
                  userIsBilling={userIsBilling}
                  userIsPersonalAssistant={userIsPersonalAssistant}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={1} {...a11yProps(1)}>
                <Preoperation
                  record={record}
                  documentTypes={documentTypes}
                  contact={record.contact.data}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={2} {...a11yProps(2)}>
                <QuotesContact
                  contact={record.contact.data}
                  rReadonly={authUser.is_coordinator ? !record.editable : false}
                  userIsCallCenter={userIsCallCenter}
                  userIsSocialMedia={userIsSocialMedia}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={3} {...a11yProps(3)}>
                <InvoicesContact
                  contact={record.contact.data}
                  rReadonly={authUser.is_coordinator ? !record.editable : false}
                  userIsCallCenter={userIsCallCenter}
                  userIsFrontDesk={userIsFrontDesk}
                  userIsSocialMedia={userIsSocialMedia}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={4} {...a11yProps(4)}>
                <CreditMemosContact
                  contact={record.contact.data}
                  rReadonly={authUser.is_coordinator ? !record.editable : false}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={5} {...a11yProps(5)}>
                <ContactPayments
                  contact={record.contact.data}
                  contact_id={record.contact_id}
                  selectedTransactionsIds={selectedTransactionsIds}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={6} {...a11yProps(6)}>
                <RefundsPatient
                  contact_id={record.contact_id}
                  refundReasons={refundReasons}
                  record={record}
                  dataRefundRequests={dataRefundRequests}
                  rReadonly={authUser.is_coordinator ? !record.editable : false}
                />
              </TabPanel>

              {
                <TabPanel value={recordValue} index={7} {...a11yProps(7)}>
                  {
                    <TicketsContact
                      contact_id={record ? record.contact_id : null}
                      canAdd={userCanCreateTickets}
                    />
                  }
                </TabPanel>
              }

              <TabPanel value={recordValue} index={8} {...a11yProps(8)}>
                <ContactToDoContacts
                  category={"coordination"}
                  getAll={true}
                  contact_id={record ? record.contact_id : null}
                  onSave={saveToDo}
                  notify={notify}
                  setNotify={setNotify}
                  canAdd={userCanEdit}
                  canEdit={userCanEdit}
                  canDelete={userCanEdit}
                  editable={record.editable}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={9} {...a11yProps(9)}>
                <AppointmentsContacts
                  buildings={buildingsDropdown}
                  contact={record.contact.data}
                  record_phoneAreaCode={record?.phoneAreaCode}
                />
              </TabPanel>
              <TabPanel value={recordValue} index={10} {...a11yProps(10)}>
                <UltrasoundsVideos record={record} />
              </TabPanel>
              <TabPanel value={recordValue} index={11} {...a11yProps(11)}>
                <RecordImages record={record} userCanEdit={userCanEdit} />
              </TabPanel>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmLoading={confirmLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </React.Fragment>
  );
}

export default RecordDetails;
