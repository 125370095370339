import { CircularProgress, Grid, LinearProgress, Step, StepButton, Stepper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import { isEmpty, isInteger } from "lodash";
import moment from "moment";
import React, { useEffect, useImperativeHandle } from "react";
import Api from "../../lib/api";
import ConfirmDialog from "../ConfirmDialog";
import Button from "../controls/Button";
import Notification from "../Notification";
import Popup from "../Popup";
import ButtonStates from "./ButtonStates";
import SteeperStates from "./SteeperStates";
import HistoryIcon from '@material-ui/icons/History';
import ScrollBar from "react-perfect-scrollbar";
import IconButton from "../controls/IconButton";

import {
    fetchEntitiesService as fetchEntitiesServiceByWorkflow,
} from "../../modules/statesModule/slices/StatesSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSlice } from "../../helpers/util";

const useStyle = makeStyles((theme) => ({
    aligncenter: {
        alignItems: 'center'
    },
    flexextend: {
        flex: 1
    }
}))

const StatesComponent = React.forwardRef(({
    workflow,
    objectId,
    handleObjectStatesUpdate,
    rendertype,
    showallstates,
    showbasedonorder,
    readOnly = false
},ref) => {
    const [currentState, setCurrentState] = React.useState(null);
    const [stateLessObject, setStateLessObject] = React.useState(false);
    const [currentStateHistory, setCurrentStateHistory] = React.useState(null);
    const [historyPopup, setHistoryPopup] = React.useState(false);
    const classes = useStyle();
    const dispatch = useDispatch();
    const stateSlice = getSlice("States");
    const _showbasedorder = showbasedonorder ? showbasedonorder : false;
    const [errorResponse, setErrorResponse] = React.useState({
        show: false,
        msg: "",
        type: "info",
    });

    useImperativeHandle(ref, () => ({
        refresh: () => {
            loadState()
        }
    }))

    const {
        States,
        loading,
        errors,
        StatesPagination,
        StatesLoading,
        StatesSaveLoading,
    } = useSelector((state) => state.States);

    const onConfirmationDialog = async (nextState) => {
        setUpdating(true);
        try {
            let data = await Api.setState(nextState.id, objectId);

            if (data.data && data.data.message) showMessage(data.data.message);
            else showMessage("OK");

            setCurrentState(null);
            getStates(objectId, workflow);

            setUpdating(false);
            if (handleObjectStatesUpdate)
                handleObjectStatesUpdate({ wf_state_id: nextState.id, nextState });
            setConfirmationDialog({ isOṕen: false });
        } catch (e) {
            setUpdating(false);
            if (e.data && e.data.message) {
                showMessage(e.data.message, "error");
            } else {
                showMessage("Unknown error changing states", "error");
            }
            getStates(objectId, workflow);
            setConfirmationDialog({ isOṕen: false });
        }
    };

    const [confirmationDialog, setConfirmationDialog] = React.useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const loadState = () => {
        getStates(objectId, workflow);
        stateSlice.getFilters.params["id"] = workflow;
        stateSlice.getFilters.limit = 0;
        stateSlice.getFilters.page = 0;
        if (isEmpty(States) || States[0]?.workflow_code != workflow)
            dispatch(fetchEntitiesServiceByWorkflow(stateSlice));
    }

    useEffect(() => {
        if (isInteger(objectId)) {
            loadState()
        }
    }, [workflow, objectId]);

    useEffect(() => {
        if (currentState && currentState.id) {
            getStatesHistory(currentState.id);
        }
    }, [currentState]);

    const getStatesHistory = async (state_id) => {
        const { data } = await Api.getStateHistory(state_id, objectId, [
            "stateto",
            "user",
        ]);
        setCurrentStateHistory(data.data);
    };

    const [updating, setUpdating] = React.useState(false);
    const _render = ["stepper", "button"].includes(rendertype)
        ? rendertype
        : "stepper";
    const _showallstates = showallstates ? Boolean(showallstates) : false;

    const [notify, setNotify] = React.useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const getStates = async (objectid, workflow) => {
        const { data } = await Api.getState(objectid, workflow, [
            "stateto",
        ]).catch((e) => {
            if (e.data && e.data.message)
                switch (e.data.message) {
                    case "bad_workflow":
                        setErrorResponse({
                            show: true,
                            msg: "Object in different workflow, can't edit from here",
                            type: "info",
                        });
                        break;
                    default:
                        setErrorResponse({
                            show: true,
                            msg: e.data.message,
                            type: "error",
                        });
                }
            return false;
        });
        if (data) {
            setCurrentState(data.data);
            //object has no state
            if (data.data != null && data.data.id == undefined) {
                setStateLessObject(true);
            } else {
                setStateLessObject(false);
            }
        }
    };

    if (errorResponse.show) {
        return <Alert severity={errorResponse.type}>{errorResponse.msg}</Alert>;
    }

    //return progress if loading
    if (currentState === null || currentState === undefined) {
        return <LinearProgress />;
    }

    const showMessage = (msg, type = "success") => {
        setNotify({
            isOpen: true,
            message: msg,
            type: type,
        });
    };

    const changeStep = (nextState) => {
        setConfirmationDialog({
            isOpen: true,
            title: "Change to state " + nextState.name,
            subTitle: "Change state",
            onConfirm: () => onConfirmationDialog(nextState),
        });
    };
    const historyClick = () => {
        setHistoryPopup(true);
    };

    const StatesControls = () => {
        if (
            currentState === null ||
            currentState === undefined ||
            currentState.length === 0
        ) {
            return null;
        }
        let next_states_id = currentState.stateto.data.map((s) => s.id);
        if (_render == "stepper") {
            let local_states;
            if (_showallstates) {
                local_states = States.map((s) => ({
                    ...s,
                    enable:
                        next_states_id.findIndex((i) => s.id == i) != -1
                            ? true
                            : false,
                }));
            } else {
                local_states = currentState.stateto.data.map((s) => ({
                    ...s,
                    enable:
                        next_states_id.findIndex((i) => s.id == i) != -1
                            ? true
                            : false,
                }));
            }

            return (
                <SteeperStates
                    currentstate={stateLessObject ? null : currentState}
                    showall={_showallstates}
                    nextStates={local_states}
                    changeStep={changeStep}
                    updating={updating}
                    showbasedorder={_showbasedorder}
                    readOnly = {readOnly}
                />
            );
            /*} else {
                const local_states = currentState.stateto.data.map((s) => ({ ...s, enable: next_states_id.findIndex((i) => s.id == i) != -1 ? true : false }));
                return (
                    <SteeperStates
                        currentstate={stateLessObject ? null : currentState}
                        showall={_showallstates}
                        nextStates={local_states}
                        changeStep={changeStep}
                        updating={updating} 
                        showbasedorder = {_showbasedorder}/>
                );
            }*/
        }

        if (_render == "button") {
            if (_showallstates) {
                const local_states = States.map((s) => ({
                    ...s,
                    enable:
                        next_states_id.findIndex((i) => s.id == i) != -1
                            ? true
                            : false,
                }));
                return (
                    <ButtonStates
                        currentstate={stateLessObject ? null : currentState}
                        showall={_showallstates}
                        nextStates={local_states}
                        changeStep={changeStep}
                        updating={updating}
                    />
                );
            } else {
                const local_states = currentState.stateto.data.map((s) => ({
                    ...s,
                    enable:
                        next_states_id.findIndex((i) => s.id == i) != -1
                            ? true
                            : false,
                }));
                return (
                    <ButtonStates
                        currentstate={stateLessObject ? null : currentState}
                        showall={_showallstates}
                        nextStates={local_states}
                        changeStep={changeStep}
                        updating={updating}
                    />
                );
            }
        }
    };

    return (
        <>
            <Grid
                container
                direction="row"
                display="inline-flex"
                /*className={classes.aligncenter}*/ wrap="nowrap"
            >
                <Grid item>
                    {stateLessObject == false && (
                        <IconButton
                            onClick={historyClick}
                            color="primary"
                            edge="end"
                        >
                            <HistoryIcon />
                        </IconButton>
                    )}
                </Grid>
                <Grid item className={classes.flexextend}>
                    <StatesControls />
                </Grid>
                <Popup
                    openPopup={historyPopup}
                    onClose={() => setHistoryPopup(false)}
                    title="History"
                >
                    {currentStateHistory == null ? (
                        <CircularProgress />
                    ) : (
                        <ScrollBar>
                            <Timeline position="alternate">
                                {currentStateHistory.map((history, index) => (
                                    <TimelineItem key={history.id}>
                                        <TimelineOppositeContent color="text.secondary">
                                            {moment(history.created_at).format(
                                                "YYYY-MM-DD HH:mm"
                                            ) +
                                                (history.user
                                                    ? " by " +
                                                      history.user.data.title
                                                    : "")}
                                        </TimelineOppositeContent>

                                        <TimelineSeparator>
                                            <TimelineDot
                                                style={{
                                                    backgroundColor:
                                                        history.stateto.data
                                                            .color,
                                                }}
                                            />
                                            {index <
                                                currentStateHistory.length -
                                                    1 && (
                                                <TimelineConnector
                                                    style={{
                                                        backgroundColor:
                                                            history.stateto.data
                                                                .color,
                                                    }}
                                                />
                                            )}
                                        </TimelineSeparator>

                                        <TimelineContent>
                                            {history.stateto.data.name}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </ScrollBar>
                    )}
                </Popup>

                <Notification notify={notify} setNotify={setNotify} />
                <ConfirmDialog
                    confirmDialog={confirmationDialog}
                    setConfirmDialog={setConfirmationDialog}
                    confirmLoading={updating}
                ></ConfirmDialog>
            </Grid>
        </>
    );
});


export default React.memo(StatesComponent);