import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

const errorColor = { color: "red" };

export default function CreditMemoForm(props) {
  const {
    onSaveCreditMemo,
    creditMemoForEdit,
    contact,
    contacts,
    creditMemoReasons = [],
  } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const onSubmit = (data) => {
    onSaveCreditMemo(data);
    reset();
  };

  const contactsOptions = () => {
    return contacts.map((contact) => {
      return {
        id: contact.id,
        title: contact.full_name,
      };
    });
  };

  const creditMemoReasonsOptions = () => {
    return creditMemoReasons
      .filter(
        (reason) =>
          reason.block_manual_use == 0 ||
          (creditMemoForEdit &&
            reason.id == creditMemoForEdit.creditmemo_reason_id)
      )
      .map((creditMemoReason) => {
        return {
          id: creditMemoReason.id,
          title: creditMemoReason.name,
        };
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          {contact ? (
            <Box mb={5}>
              <Typography component="header" variant="h4">
                Contact: {contact.fname}
              </Typography>
            </Box>
          ) : (
            <Controller
              name="contact_id"
              control={control}
              defaultValue={creditMemoForEdit?.contact_id || 0}
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Contact"
                  options={contactsOptions()}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="amount"
            control={control}
            defaultValue={creditMemoForEdit?.amount || 0}
            as={<Controls.Input label="Amount" />}
            rules={{ required: "Amount is required" }}
          />
          {errors.amount && <p style={errorColor}>{errors.amount.message}</p>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="creditmemo_reason_id"
            control={control}
            defaultValue={creditMemoForEdit?.creditmemo_reason_id || ""}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Reason"
                options={creditMemoReasonsOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
            rules={{ required: "Reason is required" }}
          />
          {errors.creditmemo_reason_id && (
            <p style={errorColor}>{errors.creditmemo_reason_id.message}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="details"
            control={control}
            defaultValue={creditMemoForEdit?.details || ""}
            as={<Controls.Textarea label="Details" />}
            rules={{ required: "Details is required" }}
          />
          {errors.details && <p style={errorColor}>{errors.details.message}</p>}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 15,
        }}
      >
        <Controls.Button type="submit" text="Submit" />
      </Grid>
    </form>
  );
}
