import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  useShowHandleErrors,
  useShowMessagesSnackbar,
} from "../../helpers/util";
import {
  addAccountDataSending,
  changeAccountFolderPage,
  changeAccountFolderPerPage,
  loadAccountFolderMessages,
  loadMessageContent,
} from "../../modules/email_client/slices/EmailClientSlices";

import MessageSidebar from "./MessageSidebar";
import MessageRight from "./MessageRight";

import EmailModal from "./EmailModal";
import ComposeModal from "./ComposeModal";

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(5),
    paddingTop: theme.spacing(3),
    position: "absolute",
    zIndex: "1000",
    height: "94%",
    width: "94%",
  },
  box: {
    border: "1px solid #888",
    borderRadius: 5,
    position: "relative",
  },
  verticalSeparator: {
    backgroundColor: "#EEE",
    height: "100%",
    cursor: "ew-resize",
    display: "flex",
    alignItems: "center",
  },
}));

function conditionalGet(value, concatenatedProperties, defecto = null) {
  let ref = value;
  concatenatedProperties.forEach((p) => {
    if (ref == null) return null;
    if (Object.hasOwn(ref, p)) {
      ref = ref[p];
    } else {
      ref = null;
    }
  });
  return ref !== null ? ref : defecto;
}



function mailPanel(props) {
  const classes = useStyles();
  const showMessage = useShowMessagesSnackbar();
  const showErrorsSnackbar = useShowHandleErrors();
  const dispatch = useDispatch();

  const {
    setSelectedMenu,
    selectedMenu,
    isComposeOrReplayOpen,
    setIsComposeOrReplayOpen,
    accountInfo,
    accountsLoaded,
    accountsLoading,
    fromIndex,
  } = props;

  const { accountsData } = useSelector((state) => state.EmailClient);
  const composeRef = useRef();
  // const loadingFolders = accountsData[accountInfo.name]?.loadingFolders
  //   ? accountsData[accountInfo.name].loadingFolders
  //   : false;

  const loadingMessages = accountsData[accountInfo.name]?.loadingMessages
    ? accountsData[accountInfo.name].loadingMessages
    : false;

  const [emailUID, setEmailUID] = useState(null);
  const [replyToUID, setReplyToUID] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("INBOX");

  const selectEmailUID = (state) => {
    return conditionalGet(state, [
      "EmailClient",
      "accountsData",
      accountInfo.name,
      "messagesStore",
      emailUID,
    ]);
  };

  const selectEmailReplyTo = (state) => {
    return conditionalGet(state, [
      "EmailClient",
      "accountsData",
      accountInfo.name,
      "messagesStore",
      replyToUID,
    ]);
  };

  const selectedEmail = useSelector(selectEmailUID);
  const selectedEmailReplyTo = useSelector(selectEmailReplyTo);

  const [composeOpen, setComposeOpen] = useState(false);

  useEffect(() => {
    if (selectedEmail && !Object.hasOwn(selectedEmail, "body")) {
      dispatch(
        loadMessageContent(accountInfo.name, selectedFolder, selectedEmail.uid)
      ).catch((e) => {
        showErrorsSnackbar(e);
      });
    }
  }, [selectedEmail]);

  const handleOnReply = async () => {
    setReplyToUID(emailUID);
  };
  const messagesPagination =
    accountsData[accountInfo.name]?.messagesOrder &&
    accountsData[accountInfo.name]?.messagesOrder[selectedFolder] &&
    accountsData[accountInfo.name]?.messagesOrder[selectedFolder]["pagination"]
      ? accountsData[accountInfo.name]?.messagesOrder[selectedFolder][
          "pagination"
        ]
      : {
          current_page: 1,
          total: 0,
          total_pages: 0,
        };
  const messagesStore = accountsData[accountInfo.name]?.messagesStore;
  const messagesOrder =
    accountsData[accountInfo.name]?.messagesOrder &&
    accountsData[accountInfo.name]?.messagesOrder[selectedFolder] &&
    accountsData[accountInfo.name]?.messagesOrder[selectedFolder]["pages"] &&
    accountsData[accountInfo.name].messagesOrder[selectedFolder]["pages"][
      messagesPagination.current_page
    ]
      ? accountsData[accountInfo.name].messagesOrder[selectedFolder]["pages"][
          messagesPagination.current_page
        ]
      : [];

  const onPageChange = (page) => {
    if (page >= 0) {
      dispatch(
        changeAccountFolderPage(accountInfo.name, selectedFolder, page + 1)
      );
    }
  };

  const onRowPerPageChange = (rpp) => {
    dispatch(changeAccountFolderPerPage(accountInfo.name, selectedFolder, rpp));
  };

  const onEmailClick = async (message) => {
    setEmailUID(message.uid);
  };

  const onComposeClick = () => {
    setComposeOpen(true);
  };

  const handleComposeClose = async () => {
    setComposeOpen(false);
    setReplyToUID(null);
    setEmailUID(null);
  };

  // const refreshFolders = async () => {
  //   dispatch(loadAccountsFolders(accountInfo.name)).catch((error) => {
  //     showErrorsSnackbar(error);
  //   });
  // };

  const refreshMessages = async () => {
    dispatch(
      loadAccountFolderMessages(
        accountInfo.name,
        selectedFolder,
        messagesPagination.current_page,
        messagesPagination.per_page
      )
    ).catch((error) => {
      showErrorsSnackbar(error);
    });
  };

  const handleSendMail = async (message) => {
    message.id = _.uniqueId();

    //serialice files for redux store
    let file_list = {};
    if (Object.hasOwn(message, "files") && _.isArray(message.files)) {
      message.files.forEach((f) => {
        file_list[f.name] = URL.createObjectURL(f);
        // file_list.push(URL.createObjectURL(f));
      });
    }
    message.files = file_list;

    dispatch(addAccountDataSending(accountInfo.name, message))
      .then((r) => {
        if (r) {
          showMessage(`The Email was sent successfully`);
        }
      })
      .catch((error) => {
        showErrorsSnackbar(error);
      });
    handleComposeClose();
  };

  useEffect(() => {
    if (selectedFolder) {
      const messagesOrder = accountsData[accountInfo.name]?.messagesOrder;

      if (
        !messagesOrder ||
        !Object.hasOwn(messagesOrder, selectedFolder) ||
        !messagesOrder[selectedFolder]?.pages ||
        !Object.hasOwn(
          messagesOrder[selectedFolder].pages,
          messagesPagination.current_page
        )
      ) {
        dispatch(
          loadAccountFolderMessages(
            accountInfo.name,
            selectedFolder,
            messagesPagination.current_page,
            messagesPagination.per_page
          )
        );
      } else {
        //already loaded messages
      }
    }
  }, [
    accountInfo,
    selectedFolder,
    messagesPagination.current_page,
    messagesPagination.per_page,
  ]);

  // useEffect(() => {
  //   if (!(accountsData[accountInfo.name]?.folders?.length > 0)) {
  //     refreshFolders();
  //   }
  // }, [accountInfo]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Box
        display="flex"
        flexWrap="nowrap"
        style={{
          width: "100%",
          backgroundColor: "#eaf1fb",
          height: "96%",
        }}
      >
        {!accountsLoading && (
          <>
            <Box
              p={1}
              style={{
                width: "12vw",
                height: "100%",
                backgroundColor: "#eaf1fb",
              }}
            >
              <MessageSidebar
                setSelectedMenu={setSelectedMenu}
                setSelectedFolder={setSelectedFolder}
                selectedMenu={selectedMenu}
                isComposeOrReplayOpen={isComposeOrReplayOpen}
                setIsComposeOrReplayOpen={setIsComposeOrReplayOpen}
                folders={accountsData[accountInfo.name]?.folders}
                onComposeClick={onComposeClick}
                fromIndex
              />
            </Box>
            <Box
              style={{
                width: "77vw",
                height: "100%",
                marginLeft: 12,
                borderRadius: 18,
              }}
            >
              <MessageRight
                emailOrder={messagesOrder}
                emailStore={messagesStore}
                pagination={messagesPagination}
                loading={loadingMessages}
                title={selectedMenu}
                onPageChange={onPageChange}
                onRowPerPageChange={onRowPerPageChange}
                onEmailClick={onEmailClick}
                refreshMessages={refreshMessages}
                fromIndex
              />
            </Box>
          </>
        )}

        <EmailModal
          open={Boolean(emailUID)}
          onClose={() => setEmailUID(null)}
          email={selectedEmail}
          onReply={handleOnReply}
        />
        <ComposeModal
          ref={composeRef}
          replyTo={selectedEmailReplyTo}
          open={composeOpen}
          onClose={handleComposeClose}
          onSend={handleSendMail}
          title={Boolean(selectedEmailReplyTo) ? "Reply" : "Compose"}
        />
      </Box>
    </div>
  );
}

export default mailPanel;
