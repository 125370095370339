import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ClickToCallForm from "./forms/ClickToCallForm";
import useTable from "./useTable";
import Popup from "./Popup";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
}));

function ClickToCallPopup(props) {
  const classes = useStyles();
  const {
    domain, sourceExt, originationNum, destination, makeACall, destinationName, setOpenClickToCallMenu
  } = props;

  const { TblContainer } = useTable();
  const [openPopup, setOpenPopup] = useState(true);

  const saveComment = (data) => {
    onSave(data);
    onClose();
  };

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };
  
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Wrapper>
      
      <Popup  title={"Click To Call Menu"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >      
      <ClickToCallForm
        domain={domain}
        sourceExt={sourceExt}
        originationNum={originationNum}
        destination={destination}
        makeACall={makeACall}
        destinationName={destinationName}
        onClose={onClose}
        setOpenClickToCallMenu={setOpenClickToCallMenu}
      />
      </Popup>

    </Wrapper>
  );
}

export default ClickToCallPopup;
