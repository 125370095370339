import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOutService } from "../redux/slices/auth";
import { useLocation } from "react-router-dom";
import Api from "../lib/api";

function LocationR() {
  const portal = localStorage.getItem("portal");
  const dispatch = useDispatch();
  const location = useLocation();

  const localP = async () => {
    
    let payload = {};
 

    try {
      if (!portal) {
        const response = await Api.getCurrentUser2(payload);
      } else {
        const response = await Api.getCurrentPortalUser();
      }
  
    } catch {
      dispatch(logOutService());
    }
  };

  useEffect(() => {
    localP();
  }, [location]);

  return "";
}

export default LocationR;
