import React, { useEffect, useState } from "react";

// ----------------------------------------------------------------------
import MaterialTable from "material-table";

import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";

import { ExportCsv, ExportPdf } from "@material-table/exporters";

export default function MTableR({
  data = [],
  columns,
  onSelectionChange,
  headerColor = "#000000",
  headerBackgroundColor = "#e1f5fe",
  isLoading = false,
  showPaging = false,
  pageSize = null,
  pageSizeOptions = null,
  optionstoAdd = [],
  loadingType = "linear",
  emptyRowsWhenPaging = true,
  search = false,
  toolbar = false,
  actions = null,
  detailPanel = null,
  onRowClick = false,
  exportMenu = false,
  exportButton = false,
  title = "",
  showTitle = false,
  boldStyle = null,
  rowColorAlternate = false,
  rowColorAlternateArray = null,
  rowfontSize = null,
  rowStyle = null,
  headerStyle = null,
  exportColumns = null,
  selectionProps = null,
}) {
  const tableRef = React.createRef();
  const { t } = useTranslation("common");



  const [dataMaterialTable, setDataMaterialTable] = useState([]);

  const _exportMenu = exportMenu
    ? [
        {
          label: "Export as CSV",
          exportFunc: (columns, data) => {
            ExportCsv(
              Array.isArray(exportColumns) ? exportColumns : columns,
              data,
              "Crm305_CSV"
            );
          },
        },
        {
          label: "Export as PDF",
          exportFunc: (columns, data) => {
            ExportPdf(
              Array.isArray(exportColumns) ? exportColumns : columns,
              data,
              "Crm305_PDF"
            );
          },
        },
      ]
    : [];

    useEffect(() => {
      if (data) {
        setDataMaterialTable(map(data, (o) => ({ ...o })));
      }
    }, [data]);

  return (
    <MaterialTable
      actions={actions}
      localization={{
        header: {
          actions: t("crmTableComponent.actions.title"),
        },
      }}
      onSelectionChange={(rows) =>
        typeof onSelectionChange === "function" ? onSelectionChange(rows) : ""
      }
      tableRef={tableRef}
      columns={columns}
      data={dataMaterialTable}
      onRowClick={onRowClick}
      title={title}
      options={{
        ...optionstoAdd,
        draggable: false,
        paging: showPaging,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        sorting: true,
        search: search,
        toolbar: toolbar,
        exportButton: exportButton,
        actionsColumnIndex: -1,
        showTitle: showTitle,
        thirdSortClick: false,
        padding: "dense",
        loadingType: loadingType,
        emptyRowsWhenPaging: emptyRowsWhenPaging,
        exportAllData: true,
        selectionProps: selectionProps,
        headerStyle: headerStyle
          ? headerStyle
          : {
              height: 40,
              fontSize: 13,
              fontWeight: 700,
              color: headerColor,
              backgroundColor: headerBackgroundColor,
            },
        rowStyle: rowStyle
          ? rowStyle
          : (rowData, index) => {
              if (rowData && rowData.void) {
                return {
                  backgroundColor: red[50],
                  fontSize: rowfontSize ? rowfontSize : 12,
                };
              }
              if (boldStyle || rowColorAlternate) {
                if (rowData.name === boldStyle) {
                  return {
                    fontWeight: 700,
                    fontSize: rowfontSize ? rowfontSize : 12,
                  };
                }

                if (rowColorAlternate) {
                  if (index % 2 == 0)
                    return {
                      backgroundColor: rowColorAlternateArray[0].color,
                      fontSize: rowfontSize ? rowfontSize : 12,
                    };
                  else
                    return {
                      backgroundColor: rowColorAlternateArray[1].color,
                      fontSize: rowfontSize ? rowfontSize : 12,
                    };
                }
              } else {
                return { fontSize: rowfontSize ? rowfontSize : 12 };
              }
            },
        exportMenu: _exportMenu,
      }}
      isLoading={isLoading}
      detailPanel={detailPanel}
    />
  );
}
