import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentGatewaysService } from "../redux/slices/paymentgateways";
import { isEmpty } from "lodash";
import { renderToString } from "react-dom/server";
import {
  List,
  ListItemText,
  Paper,
  Divider,
  ListItem,
  ListItemIcon,
  Checkbox,
  Typography,
  Box,
} from "@material-ui/core";
import Controls from "./controls";
import PaymentForm from "./forms/PaymentForm";
import Alert from "@material-ui/lab/Alert";
import Popup from "./Popup";
import SendContract from "./SendContract";
import CreditMemosQuotes from "./CreditMemosQuotes";
import CRMUtils from "../utils";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  itemList: {
    padding: "6px 16px",
    marginTop: "10px",
    flexGrow: 1,
  },
  actionBox: {
    marginTop: "20px",
  },
}));

function ApproveQuote(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector((state) => state.auth);

  const userCanCreateMemo = CRMUtils.userHasPermission(authUser, [
    "creditmemo_create",
  ]);

  const userCanMakePayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_create",
  ]);

  const { paymentGateways } = useSelector((state) => state.paymentgateways);

  const {
    items,
    gateways,
    quote,
    contact,
    onApproveQuote,
    onSentContract,
    approving = false,
  } = props;

  const [checked, setChecked] = React.useState([]);
  const [signed, setSigned] = useState(quote.signed || false);
  const [sentContract, setSentContract] = useState(false);
  const [hasPayment, setHasPayment] = useState(quote.hasPayments || false);
  const [hasCredit, setHasCredit] = useState(quote.hasCredits || false);
  const [contract, setContract] = useState(null);
  const [makePaymentPopup, setMakePaymentPopup] = useState(false);
  const [makeCreditPopup, setMakeCreditPopup] = useState(false);
  const [sendContractPopup, setSendContractPopup] = useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const checkboxInit = () => {
    const newChecked = [];
    items.map((item) => {
      newChecked.push(item.id);
    });
    setChecked(newChecked);
  };

  const savePayment = (data) => {
    setHasPayment(data);
    setMakePaymentPopup(false);
  };

  const saveCreditMemo = (data) => {
    setHasCredit(data);
    setMakeCreditPopup(false);
  };

  const ApproveQuote = () => {
    onApproveQuote(checked, hasPayment, contract, hasCredit);
  };

  const sendContract = () => {
    onSentContract();
    setSentContract(true);
    setSendContractPopup(false);
  };

  const closePaymentPopup = () => {
    setMakePaymentPopup(false);
  };

  const closeSendContractPopup = () => {
    setSendContractPopup(false);
  };

  const closeCreditPopup = () => {
    setMakeCreditPopup(false);
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    return item.price * (item.price_includes_qty ? 1 : parseInt(quantity));
  };
  const calculateTotal = () => {
    return items.reduce((total, item) => total + calculateItemTotal(item), 0);
  };

  const renderItemSelected = () => {
    return items.map((item) => {
      return (
        <tr>
          <td> {item.name} </td>
          <td> $ {item.price} </td>
          <td> {item.quantity}</td>
          <td>
            {" "}
            ${" "}
            {(
              item.price * (item.price_includes_qty ? 1 : item.quantity)
            ).toFixed(2)}
          </td>
        </tr>
      );
    });
  };

  const component2html = () => {
    return renderToString(
      <table style={{ width: "90%" }} cellpadding="10">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>{renderItemSelected()}</tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>Total</td>
            <td>$ {calculateTotal()}</td>
          </tr>
        </tfoot>
      </table>
    );
  };

  useEffect(() => {
    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));
    checkboxInit();
  }, []);

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <Typography variant="h5">
          Before approving this quote please make sure to have the contract
          signed by the customer and a minimum payment of $250.
        </Typography>

        <Paper className={classes.itemList}>
          <Typography variant="body2">Select items</Typography>
          <List className={classes.paymentList}>
            {items.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <ListItem
                    role={undefined}
                    dense
                    //button={item.parent_id == null}
                    // onClick={
                    //   item.parent_id != null ? null : handleToggle(item.id)
                    // }
                  >
                    <ListItemIcon>
                      <Checkbox
                        disabled
                        //disabled={item.parent_id != null}
                        edge="start"
                        checked={
                          item.parent_id != null
                            ? checked.indexOf(item.parent_id) !== -1
                            : checked.indexOf(item.id) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText id={item.id} primary={item.name} />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </Paper>

        <Paper className={classes.paper}>
          {signed ? (
            <Alert severity="success">Contract signed.</Alert>
          ) : (
            <>
              <Alert severity="error">No contract signed.</Alert>
              <Controls.Button
                variant="contained"
                color="secondary"
                disabled={sentContract ? true : false}
                text={sentContract ? "Contract sent" : "Send contract"}
                startIcon={<SendIcon />}
                onClick={() => {
                  setSendContractPopup(true);
                }}
              />
              OR
              <Controls.DropzoneDialog
                label="Attacht contract"
                variant="contained"
                startIcon={<AttachFileIcon />}
                color="secondary"
                onChange={(files) => {
                  setContract(files[0]);
                  setSigned(true);
                }}
              />
            </>
          )}
        </Paper>

        <Paper className={classes.paper}>
          {hasPayment || hasCredit ? (
            <Alert severity="success">Payment/Credit received.</Alert>
          ) : (
            <>
              <Alert severity="error">No payment/credit received.</Alert>
              <Controls.Button
                variant="contained"
                color="secondary"
                disabled={!userCanMakePayment}
                text="Make payment"
                startIcon={<CreditCardIcon />}
                onClick={() => {
                  setMakePaymentPopup(true);
                }}
              />
              {userCanCreateMemo && (
                <Controls.Button
                  variant="contained"
                  color="secondary"
                  text="Use credit"
                  startIcon={<CardGiftcardIcon />}
                  onClick={() => {
                    setMakeCreditPopup(true);
                  }}
                />
              )}
            </>
          )}
        </Paper>

        <Divider light />
        <Box
          display="flex"
          className={classes.actionBox}
          justifyContent="flex-end"
        >
          <Controls.Button
            variant="contained"
            disabled={
              !approving &&
              !isEmpty(checked) &&
              (hasPayment || hasCredit) &&
              signed
                ? false
                : true
            }
            color="primary"
            text={approving ? "APPROVING..." : "APPROVE QUOTE"}
            startIcon={<ThumbUpIcon />}
            onClick={() => {
              ApproveQuote();
            }}
          />
        </Box>
      </Paper>

      <Popup
        title="Quote Payment"
        fullWidth={true}
        openPopup={makePaymentPopup}
        onClose={closePaymentPopup}
      >
        <PaymentForm
          invoice={quote}
          savePayment={savePayment}
          gateways={gateways}
          firstPayment={1}
        />
      </Popup>

      <Popup
        title="Quote Credit"
        fullWidth={true}
        openPopup={makeCreditPopup}
        onClose={closeCreditPopup}
      >
        <CreditMemosQuotes
          invoice={quote}
          contact={quote.contact.data}
          applyQuoteCreditMemoupdate={saveCreditMemo}
        />
      </Popup>

      <Popup
        title="Send Contracts"
        fullWidth={true}
        openPopup={sendContractPopup}
        onClose={closeSendContractPopup}
      >
        <SendContract
          model="quote"
          model_id={quote?.id}
          contact={contact}
          onSentContract={sendContract}
        />
      </Popup>
    </Wrapper>
  );
}

export default ApproveQuote;
