import { Grid, TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import Api from "../../../../lib/api";
import SelectWColor from "../../../../components/controls/SelectWColor";
import Select from "../../../../components/controls/Select";
import { useCallback, useEffect, useMemo, useState } from "react";
import AutocompleteGeneric from "../AutocompleteGeneric";
import ContactOption from "../options/ContactOption";
import { isInteger } from "lodash";
import DatePicker2 from "../../../../components/controls/DatePicker2";
import { useDispatch, useSelector } from "react-redux";
import { fetchTagsService } from "../../../../redux/slices/tags";
import UserOption from "../options/UserOption";

const RefundsFilters = () => {
  const { control } = useFormContext();
  const [states, setStates] = useState(null);

  //priorities
  const { data: priorities, isLoading: prioritiesLoading } = useQuery(
    ["rfs_priorities"],
    () =>
      Api.genericQueryModel("rfs_priorities", {
        page: null,
        per_page: null,
      }),
    {
      select: (data) => data?.data?.data,
    }
  );

  //reasons
  const { data: reasons, isLoading: reasonsLoading } = useQuery(
    ["rfs_reasons"],
    () =>
      Api.genericQueryModel("rfs_reasons", {
        page: null,
        per_page: null,
      }),
    {
      select: (data) => data?.data?.data,
    }
  );

  const [tags, setTags] = useState([]);

  const getTags = async () => {
    const params = new URLSearchParams([["model", `App\\Models\\RfsTickets`]]);
    const { data } = await Api.getTags(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );
    setTags([...data.data]);
  };

  const tagsOptions = useMemo(() => {
    return (
      tags?.map((t) => ({
        ...t,
        title: t.tagname,
      })) ?? []
    );
  }, [tags]);

  const { authUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser) {
      dispatch(fetchTagsService(authUser?.organization_id));
    }
  }, [authUser]);

  useEffect(() => {
    getTags();
  }, []);
  //states
  const getStatesByWorkflow = async () => {
    const { data } = await Api.getStatesByWorkflow("refund_basic");
    setStates(data.data);
  };

  //Contact
  const optionLabelContact = useCallback((option, single) => {
    if (isInteger(option)) {
      if (option == single?.id) {
        return `${single.full_name} (${single.email})`;
      }
    } else {
      return `${option.full_name} (${option.email})`;
    }
  }, []);

  //buildings
  const { data: buildings, isLoading: buildingsLoading } = useQuery(
    ["building"],
    () =>
      Api.genericQueryModel("building", {
        page: null,
        per_page: null,
      }),
    {
      select: (data) => data?.data?.data,
    }
  );

  //departments
  const { data: departments, isLoading: deparmentsLoading } = useQuery(
    ["department"],
    () =>
      Api.genericQueryModel("department", {
        page: null,
        per_page: null,
      }),
    {
      select: (data) => data?.data?.data,
    }
  );

  const optionLabelUser = useCallback((option, single) => {
    if (isInteger(option)) {
      if (option == single?.id) {
        return `${single.dropdown_name} [${single.email}](${single.phone})`;
      }
    } else {
      return `${option.dropdown_name} [${option.email}](${option.phone})`;
    }
  }, []);

  useEffect(() => {
    getStatesByWorkflow();
  }, []);

  return (
    <Grid item container spacing={4}>
      <Grid item md={3}>
        <Controller
          name="subject"
          control={control}
          defaultValue={""}
          render={({ onChange, onBlur, value }) => (
            <TextField
              label="Subject"
              name="subject"
              value={value ?? ""}
              onChange={onChange}
              variant="outlined"
              style={{ width: "100%" }}
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name="wf_state_id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <SelectWColor
              label={"State"}
              variant="outlined"
              onChange={onChange}
              allowEmpty={true}
              value={value && value != "" ? value : -1}
              emptyLabel="Select one"
              options={states}
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name="priority_id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <SelectWColor
              label={"Priority"}
              variant="outlined"
              onChange={onChange}
              value={value && value != "" ? value : -1}
              allowEmpty={true}
              emptyLabel="Select one"
              options={priorities}
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name="reasons_id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <Select
              label={"Reasons"}
              variant="outlined"
              onChange={onChange}
              value={value && value != "" ? value : -1}
              allowEmpty={true}
              emptyLabel="Select one"
              options={reasons}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="invoice__contact_id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <AutocompleteGeneric
              label="Contact"
              objectId={value && value != "" ? value : null}
              onChange={onChange}
              RenderOption={ContactOption}
              optionLabel={optionLabelContact}
              model="contact"
              include={[
                "country",
                "stateID",
                "cityID",
                "organization",
                "languages",
              ]}
              searchBy={{ "or:": ["fname", "email"] }}
              allowEmpty={true}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="user_assigned_id"
          defaultValue={-1}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <AutocompleteGeneric
              label="Assigned to"
              objectId={value && value != "" ? value : null}
              onChange={onChange}
              RenderOption={UserOption}
              optionLabel={optionLabelUser}
              model="user"
              include={["department", "languages"]}
              searchBy={{
                "or:": ["%fname", "%lname", "%phone", "%email"],
              }}
              u
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          name="user_created_id"
          defaultValue={-1}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <AutocompleteGeneric
              label="Created by"
              objectId={value && value != "" ? value : null}
              onChange={onChange}
              RenderOption={UserOption}
              optionLabel={optionLabelUser}
              model="user"
              include={["department", "languages"]}
              searchBy={{
                "or:": ["%fname", "%lname", "%phone", "%email"],
              }}
              u
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name=">=created_at"
          control={control}
          defaultValue={null}
          render={({ onChange, onBlur, value }) => (
            <DatePicker2
              fullWidth
              label="Affer"
              format="MM/DD/yyyy"
              onChange={onChange}
              value={value && value != "" ? value : null}
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name="<=created_at"
          control={control}
          defaultValue={null}
          render={({ onChange, onBlur, value }) => (
            <DatePicker2
              fullWidth
              label="Before"
              format="MM/DD/yyyy"
              onChange={onChange}
              value={value && value != "" ? value : null}
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name="building_id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <Select
              label={"Building"}
              variant="outlined"
              onChange={onChange}
              allowEmpty={true}
              value={value ? `${value}` : null}
              options={
                buildings
                  ? buildings.map((building) => {
                      return {
                        ...building,
                        ...{ color: null },
                      };
                    })
                  : []
              }
            />
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Controller
          name="department_id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <Select
              label={"Departments"}
              variant="outlined"
              onChange={onChange}
              value={value ? `${value}` : -1}
              options={departments}
              allowEmpty={true}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="tags__id"
          control={control}
          defaultValue={-1}
          render={({ onChange, onBlur, value }) => (
            <Select
              label={"With Tag"}
              variant="outlined"
              onChange={onChange}
              value={value ? `${value}` : -1}
              allowEmpty={true}
              emptyLabel="Select one"
              options={tagsOptions}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
export default RefundsFilters;
