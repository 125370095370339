import { combineReducers } from "redux";
import authReducer from "./auth";
import usersReducer from "./users";
import users2Reducer from "./users2Slice";
import deactivateHistoryReducer from "./deactivateHistorySlice";
import smsmmsCampaignReducer from "./smsmmsCampaignSlice";
import departmentsNewReducer from "./departmentsNewSlice";
import rolesReducer from "./roles";
import permissionsReducer from "./permissions";
import customFieldsReducer from "./customFields";
import modelsReducer from "./models";
import recordsReducer from "./records";
import smsmmsTemplatesCategoriesReducer from "./smsmmsTemplatesCategoriesSlice";
import smsmmsTemplatesReducer from "./smsmmsTemplatesSlice";
import quotesReducer from "./quotes";
import appointmentTypesReducer from "./appointmentTypesSlice";
import appointmentsNewCancelationReasonsReducer from "./appointmentCancelationReasonSlice";
import appointmentsNewMovementReasonsReducer from "./appointmentMovementsReasonSlice";
import appointmentNewReducer from "./appointmentNewSlice";
import paymentgatewaysReducer from "./paymentgateways";
import contactsReducer from "./contacts";
import documentTypesReducer from "./documenttypes";
import organizationReducer from "./organizations";
import tagsReducer from "./tags";
import invoicesReducer from "./invoices";
import cordinationReportsReducer from "./cordinationReports";
import quotesReportsReducer from "./quotesReports";
import paymentReportReducer from "./paymentReports";
import contractsReportsReducer from "./contractsReports";
import documentsReducer from "./documents";
import contractsReducer from "./contracts";
import tasksReducer from "./tasks";
import recordProductsReducer from "./recordproducts";
import pendingAppointmentsReportReducer from "./pendingAppointmentsReportSlice";
import recordProductTemplates from "./recordProductTemplates";
import expenseItemsReducer from "../../modules/accountingModule/slices/expenseItemSlice";
import expenseCategoryReducer from "../../modules/accountingModule/slices/expenseCategorySlice";
import expenseReducer from "../../modules/accountingModule/slices/expenseSlice";
import cashBoxReducer from "../../modules/accountingModule/slices/cashBoxSlice";
import groupsReducer from "./groups";
import groupsNewReducer from "./groupsNewSlice";
import cancellationMotivesReducer from "./cancellationMotives";
import invoicesReportsReducer from "./invoicesReports";
import cashFlowReducer from "../../modules/accountingModule/slices/cashFlowSlice";
import flowTypeReducer from "../../modules/accountingModule/slices/flowTypeSlice";
import refundReasonsReducer from "./refundReasons";
import categoriesNewReducer from "./categoriesNewSlice";
import communicationGroupsReducer from "./communicationGroupsSlice";
import creditMemosReducer from "./creditmemos.js";
import commissionRulesReducer from "./commissionRules.js";
import templatesNewReducer from "./templatesSlice.js";
import contactFormTemplatesReducer from "./contactFormTemplates";
import invoiceSlice2Reducer from "./invoiceSlice2.js";
import recordsSliceReducer from "./recordsSlice.js";
import contactsSliceReducer from "./contactsSlice.js";
import contactsSliceReducer2 from "./contactsSlice2.js";
import contactsSliceReducer4 from "./contactsSlice4.js";
import autoCallsSliceReducer from "./autoCallsSlice.js";
import autoCallsHistorySliceReducer from "./autoCallsHistorySlice.js";
import contactsNewSearchReducer from "./contactsNewSearchSlice.js";
import invoicesSliceReducer from "./invoiceSlice.js";
import appointmentsMotivesSliceReducer from "./appointmentsMotivesSlice.js";
import languagesReducer from "./languages.js";
import buildingsReducer from "./buildings.js";
import buildingsNewReducer from "./buildingsNewSlice.js";
import buildingsDropdownReducer from "./buildingsDropdownSlice.js";
import buildingsDropdownRestrictedReducer from "./buildingsDropdownRestrictedSlice.js";

import roomsReducer from "./roomsSlice.js";
import roomServicesReducer from "./roomServicesSlice.js";
import roomStatesReducer from "./roomStatesSlice.js";
import recordProductsNewReducer from "./recordproductsSlice.js";
import recordProductsReportReducer from "./recordProductsReportSlice.js";
import quotesNewReducer from "./quotesnewSlice.js";
import invoicesNewReducer from "./invoicesnewSlice.js";
import payrollNewReducer from "./payrollnewSlice.js";
import payrollSliceNewReducer from "./payrollSliceNew.js";
import payrollAssistantsReducer from "./payrollAssistantsSlice.js";
import productsNewReducer from "./productsnewSlice";
import recordWithProductsReducer from "./recordwithproductsSlice.js";
import recordwithproductsNoBalanceReducer from "./recordwithproductsNoBalanceSlice.js";
import financingReducer from "./financingSlice";
import recordProductsPendingReducer from "./recordProductsPendingSlice.js";
import pendingToScheduleReportsSliceReducer from "./pendingToScheduleReportsSlice.js";
import procedureCanceledReportsSliceReducer from "./procedureCanceledReportsSlice.js";
import ticketCategoriesReducer from "./ticketCategoriesSlice.js";
import ticketStatusReducer from "./ticketStatusSlice.js";
import contractReportReducer from "./contractReportSlice.js";
import ticketPrioritiesReducer from "./ticketPrioritiesSlice.js";
import ticketsReducer from "./ticketsSlice.js";
import ticketsContactReducer from "./ticketsContactSlice.js";
import socialPlatformsReducer from "./socialplatformsSlice.js";
import sourcesReducer from "./sourcesSlice";
import paymentsActivityLogsReducer from "./paymentsActivityLogsSlice";
import recordProductCostsReducer from "./recordProductCostsSlice";
import usersNewReducer from "./usersNewSlice";
import doctorsNewReducer from "./doctorsNewSlice";
import coordinatorsListReducer from "./coordinatorsListSlice";
import agentListReducer from "./agentListSlice";
import appointmentsNewReducer from "./appointmentsSlice";
import appointmentsNoDateReducer from "./appointmentsNoDateSlice";
import templatesCategoriesNewReducer from "./templatesCategoriesSlice";
import contactsMassagesControlReducer from "./contactsMassagesControlSlice";
import consultantsSalesReportReducer from "./consultantsSalesReportSlice";
import surgeonQuotesRecordProductReportsReducer from "./surgeonQuotesRecordProductReportsSlice";
import refundChargeBackReportReducer from "./refundChargeBackReportSlice";
import refundsChargeBackRiskReducer from "./refundsChargeBackRiskSlice";
import contactsPlainReducer from "./contactsPlainSlice";
import userSMSMMSReducer from "./userSMSMMSSlice";
import userSMSMMSByGroupsReducer from "./userSMSMMSByGroupsSlice";
import contactCallsReducer from "./contactCallsSlice";
import userCallsNewReducer from "./userCallsSlice";
import salesReportNewReducer from "./salesReportNewSlice";
import salesReportNewWithDoctorReducer from "./salesReportNewWithDoctorSlice";
import salesBySourceReportReducer from "./salesBySourceReportSlice";
import newSMSReducer from "./newSMSSlice";
import newWhastAppReducer from "./newWhatsAppSlice";
import updateWhastAppReducer from "./updateWhatsAppSlice";
import ticketReportsReducer from "./ticketsReportSlice";
import smsReportsReducer from "./smsReportSlice";
import productsNoProcedureReducer from "./contactsProductsNoProcedureSlice";
import productGiftReasonReducer from "./productGiftReasonSlice";
import callsReportsReducer from "./callsReportSlice";
import recordsPersonalAssistantReducer from "./recordsPersonalAssistantSlice";
import recordProductsReportPAReducer from "./recordProductsReportPASlice.js";
import sourcesNewReducer from "./sourcesNewSlice";
import sourcesNewDropdownReducer from "./sourcesNewDropdownSlice";
import surgeonDropdownReducer from "./surgeonDropdownSlice";
import surgeonDropdownRestrictedReducer from "./surgeonDropdownRestrictedSlice.js";

import coordinatorsDropdownReducer from "./coordinatorsDropdownSlice";
import coordinatorsGeneralDropdownReducer from "./coordinatorsGeneralDropdownSlice";
import proceduresDropdownReducer from "./procedureDropdownSlice";
import salesGroupBuildingDropdownReducer from "./salesGroupBuildingDropdownSlice";
import salesGroupsDropdownReducer from "./salesGroupsDropdownSlice";
import assistantsDropdownReducer from "./assistantsDropdownSlice";
import noProcedureDropdownReducer from "./noProcedureDropdownSlice";
import groupsDropdownReducer from "./groupsDropdownSlice";
import usersGeneralDropdownReducer from "./usersGeneralDropdownSlice";
import productGiftReportReducer from "./productGiftReportSlice";
import doctorRemarksTemplatesReducer from "./doctorRemarksTemplatesSlice";
import procedureNoDoctorReportsReducer from "./procedureNoDoctorReportsSlice";
import procedureRefundedReportsReducer from "./procedureRefundedReportsSlice";
import contactAppointmentsReducer from "./contactAppointmentsSlice";
import tagsEditReducer from "./tagsEditSlice";
import commentCategoriesReducer from "./commentCategoriesSlice";
import newItemsReducer from "./newItems";
import missedCallsCountReducer from "./missedCallsSlice";
import totalUnreadChatMessagesReducer from "./totalUnreadChatMessages";
import generalTotalUnreadChatMessagesReducer from "./generalTotalUnreadChatMessages.js";
import userFavoriteSitesReducer from "./userFavoriteSites.js";

//Format2
import contacts2Reducer from "../../pages/contacts/contactSlice";
import customFields2Reducer from "../../pages/contacts/customFieldsSlice";
import invCategoryReducer from "../../modules/inventoryModule/slices/invCategorySlice";
import invProductReducer from "../../modules/inventoryModule/slices/invProductSlice";
import pollMainReducer from "../../modules/pollModule/slices/pollMainSlice";
import pollQuestionReducer from "../../modules/pollModule/slices/pollQuestionSlice";
import pollQuestionOptionReducer from "../../modules/pollModule/slices/pollQuestionOptionSlice";
import pollContactsReducer from "../../modules/pollModule/slices/pollContactSlice";
import ModelStatesReducer from "../../modules/statesModule/slices/ModelStatesSlice";
import WorkflowsReducer from "../../modules/statesModule/slices/WorkflowSlice";
import StatesReducer from "../../modules/statesModule/slices/StatesSlice";
import TransitionsReducer from "../../modules/statesModule/slices/TransitionsSlice";
import StatesActionsReducer from "../../modules/statesModule/slices/ActionsSlice";
import invMeasureReducer from "../../modules/inventoryModule/slices/invMeasureSlice";
import invLocalizationReducer from "../../modules/inventoryModule/slices/invLocalizationSlice";
import invWarehouseTypeReducer from "../../modules/inventoryModule/slices/invWarehouseTypeSlice";
import invWarehouseReducer from "../../modules/inventoryModule/slices/invWarehouseSlice";
import invWarehouseAllowReducer from "../../modules/inventoryModule/slices/invWarehouseAllowSlice";
import invProviderReducer from "../../modules/inventoryModule/slices/invProviderSlice";
import invWhMovementReducer from "../../modules/inventoryModule/slices/invWhMovementSlice";
import invWhMovementTypeReducer from "../../modules/inventoryModule/slices/invWhMovementTypeSlice";
import invWhMovementDetailReducer from "../../modules/inventoryModule/slices/invWhMovementDetailSlice";
import invWhMovementStateReducer from "../../modules/inventoryModule/slices/invWhMovementStateSlice";
import invWhProductReducer from "../../modules/inventoryModule/slices/invWhProductSlice";
import invWhUserPermissionReducer from "../../modules/inventoryModule/slices/InvWhUserPermissionSlice";
import invWhUserReducer from "../../modules/inventoryModule/slices/InvWhUserSlice";
import invPermissionReducer from "../../modules/inventoryModule/slices/invPermissionSlice";
import invWhReportPurchaseReducer from "../../modules/inventoryModule/slices/invWhReportPurchaseSlice";
import admCommissionRulesReducer from "../../modules/adminModule/slices/admCommissionRuleSlice";
import admCommissionRuleTypesReducer from "../../modules/adminModule/slices/admCommissionRuleTypesSlice";
import admCommissionRulesReportReducer from "../../modules/adminModule/slices/admCommissionRuleReportsSlice";
import admCommissionRulesGroupsReducer from "../../modules/adminModule/slices/admCommissionRuleGroupSlice";
import admForgetReducer from "../../modules/portalModule/slices/forgetSlice";
import patientDataReportReducer from "../../modules/portalModule/slices/patientDataSlice";
import admFaqReducer from "../../modules/adminModule/slices/admFaqSlice";
import admFaqProductReducer from "../../modules/adminModule/slices/admFaqProductSlice";
import portalFaqReducer from "../../modules/portalModule/slices/faqSlice";
import portalFaqByProcedureReducer from "../../modules/portalModule/slices/faqByProcedureSlice";
import messageReducer from "../../modules/messagesModule/slices/messageSlice";
import pbxDomaisReducer from "./pbxDomainsSlice";
import NewTagReducer from "../../pages/contacts/optimized/slices/TagSlices";
import contactsCommonReducer from "./commonSlices/contactsSlice";
import consultantGeneralDropdownReducer from "./consultantGeneralDropdownSlice";
import consultantBuildingDropdownReducer from "./consultantBuildingDropdownSlice";
import callsOrgReducer from "./callsOrgSlice";
import unassignedContactsReducer from "./unassignedContactsSlice";
import liveNotificationsReducer from "./liveNotificationsSlice";
import portalMessagesNotificationsReducer from "./portalMessagesNotificationsSlice";
import departmentsDropdownReducer from "./departmentsDropdownSlice";
import fraudulentContactsReasonsReducer from "./fraudolentContactsReasonsSlice";
import pollPuntuationReportReducer from "../../modules/pollModule/slices/pollPuntuationReportSlice";
import pollPuntuationContactReportReducer from "../../modules/pollModule/slices/pollPuntuationContactReportSlice";

import EmailClientReducer from "../../modules/email_client/slices/EmailClientSlices";

// invoicestoapplydiscountReducerimport invoicestoapplydiscountReducer from "./invoicestoapplydiscount";
// import usersLoginsReducer from "./userLoginSlice";
// import creditReportReducer from "./creditReportSlice";
// import refundRequestsNewReducer from "./refundRequestsSlice";
// import contactsBySourceReducer from "./contactsBySourceSlice";
// import usersDirectoryReducer from "./usersDirectorySlice";
// import recordProductsTransferReducer from "./recordProductsTransferSlice";
// import appointmentsReportReducer from "./appointmentsReportSlice";
// import genericReducer from "./common/genericSlice";

const rootReducer = combineReducers({
  // appointmentsReport: appointmentsReportReducer,
  // invoicestoapplydiscount: invoicestoapplydiscountReducer,
  // recordProductsTransfer: recordProductsTransferReducer,
  // refundRequestsNew: refundRequestsNewReducer,
  // usersDirectory: usersDirectoryReducer,
  // contactsBySource: contactsBySourceReducer,
  // usersLogins: usersLoginsReducer,
  // creditReport: creditReportReducer,

  appointmentsNewCancelationReasons: appointmentsNewCancelationReasonsReducer,
  appointmentsNewMovementReasons: appointmentsNewMovementReasonsReducer,
  pendingAppointmentsReport: pendingAppointmentsReportReducer,
  smsmmsCampaign: smsmmsCampaignReducer,
  deactivateHistory: deactivateHistoryReducer,
  auth: authReducer,
  users: usersReducer,
  departmentsNew: departmentsNewReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  customFields: customFieldsReducer,
  models: modelsReducer,
  records: recordsReducer,
  smsmmsTemplatesCategories: smsmmsTemplatesCategoriesReducer,
  smsmmsTemplates: smsmmsTemplatesReducer,
  quotes: quotesReducer,
  appointmentTypes: appointmentTypesReducer,
  appointmentNew: appointmentNewReducer,
  tags: tagsReducer,
  documenttypes: documentTypesReducer,
  contacts: contactsReducer,
  contactsnew: contactsSliceReducer,
  contactsNewSearch: contactsNewSearchReducer,
  organizations: organizationReducer,
  paymentgateways: paymentgatewaysReducer,
  invoices: invoicesReducer,
  invoicesnew: invoicesSliceReducer,
  cordinationReports: cordinationReportsReducer,
  quotesReports: quotesReportsReducer,
  paymentReports: paymentReportReducer,
  contractsReports: contractsReportsReducer,
  documents: documentsReducer,
  contracts: contractsReducer,
  tasks: tasksReducer,
  recordProducts: recordProductsReducer,
  recordProductTemplates: recordProductTemplates,
  expense: expenseReducer,
  cashBoxes: cashBoxReducer,
  cashFlow: cashFlowReducer,
  groups: groupsReducer,
  groupsNew: groupsNewReducer,
  cancellationMotives: cancellationMotivesReducer,
  invoicesReports: invoicesReportsReducer,
  recordsnew: recordsSliceReducer,
  refundReasons: refundReasonsReducer,
  creditMemos: creditMemosReducer,
  commissionRules: commissionRulesReducer,
  templatesCategoriesNew: templatesCategoriesNewReducer,
  categoriesNew: categoriesNewReducer,
  communicationGroups: communicationGroupsReducer,
  templatesNew: templatesNewReducer,
  contactFormTemplates: contactFormTemplatesReducer,
  buildings: buildingsReducer,
  buildingsNew: buildingsNewReducer,
  buildingsDropdown: buildingsDropdownReducer,
  buildingsDropdownRestricted: buildingsDropdownRestrictedReducer,
  rooms: roomsReducer,
  roomServices: roomServicesReducer,
  roomStates: roomStatesReducer,
  recordProductsNew: recordProductsNewReducer,
  recordProductsReport: recordProductsReportReducer,
  quotesNew: quotesNewReducer,
  invoicesNew: invoicesNewReducer,
  recordWithProducts: recordWithProductsReducer,
  languages: languagesReducer,
  financing: financingReducer,
  recordProductsPending: recordProductsPendingReducer,
  payrollNew: payrollNewReducer,
  payrollNew2: payrollSliceNewReducer,
  productsNew: productsNewReducer,
  ticketCategories: ticketCategoriesReducer,
  ticketStatus: ticketStatusReducer,
  ticketPriorities: ticketPrioritiesReducer,
  tickets: ticketsReducer,
  ticketsContact: ticketsContactReducer,
  socialPlatforms: socialPlatformsReducer,
  sources: sourcesReducer,
  payrollAssistants: payrollAssistantsReducer,
  contactAppointments: contactAppointmentsReducer,
  pendingToScheduleReports: pendingToScheduleReportsSliceReducer,
  procedureCanceledReports: procedureCanceledReportsSliceReducer,
  contractReport: contractReportReducer,
  recordWithProductsNoBalance: recordwithproductsNoBalanceReducer,
  recordProductCosts: recordProductCostsReducer,
  paymentsActivityLogs: paymentsActivityLogsReducer,
  usersNew: usersNewReducer,
  doctorsNew: doctorsNewReducer,
  coordinatorsList: coordinatorsListReducer,
  agentList: agentListReducer,
  users2: users2Reducer,
  appointmentsNew: appointmentsNewReducer,
  appointmentsNoDate: appointmentsNoDateReducer,
  contactsMassagesControl: contactsMassagesControlReducer,
  consultantsSalesReport: consultantsSalesReportReducer,
  surgeonQuotesRecordProductReports: surgeonQuotesRecordProductReportsReducer,
  refundChargeBackReport: refundChargeBackReportReducer,
  refundsChargeBackRisk: refundsChargeBackRiskReducer,
  contactCalls: contactCallsReducer,
  userCallsNew: userCallsNewReducer,
  salesReportNew: salesReportNewReducer,
  salesReportNewWithDoctor: salesReportNewWithDoctorReducer,
  salesBySourceReport: salesBySourceReportReducer,
  newSMS: newSMSReducer,
  ticketReports: ticketReportsReducer,
  smsReports: smsReportsReducer,
  productsNoProcedure: productsNoProcedureReducer,
  productGiftReason: productGiftReasonReducer,
  callsReports: callsReportsReducer,
  recordsPersonalAssistant: recordsPersonalAssistantReducer,
  recordProductsReportPA: recordProductsReportPAReducer,
  proceduresDropdown: proceduresDropdownReducer,
  salesGroupBuildingDropdown: salesGroupBuildingDropdownReducer,
  productGiftReport: productGiftReportReducer,
  callsOrg: callsOrgReducer,
  unassignedContacts: unassignedContactsReducer,
  doctorRemarksTemplates: doctorRemarksTemplatesReducer,
  procedureNoDoctorReports: procedureNoDoctorReportsReducer,
  procedureRefundedReports: procedureRefundedReportsReducer,

  //New Format slices Zone
  contacts2: contacts2Reducer,
  contactsPlain: contactsPlainReducer,
  expenseCategories: expenseCategoryReducer,
  expenseItems: expenseItemsReducer,
  customFields2: customFields2Reducer,
  flowTypes: flowTypeReducer,
  invCategories: invCategoryReducer,
  invProducts: invProductReducer,
  invMeasures: invMeasureReducer,
  invLocalizations: invLocalizationReducer,
  invWarehouseTypes: invWarehouseTypeReducer,
  invWarehouses: invWarehouseReducer,
  invWarehousesAllow: invWarehouseAllowReducer,
  invProviders: invProviderReducer,
  invWhMovements: invWhMovementReducer,
  invWhMovementTypes: invWhMovementTypeReducer,
  invWhMovementDetails: invWhMovementDetailReducer,
  invWhMovementStates: invWhMovementStateReducer,
  invWhProducts: invWhProductReducer,
  invWhUserPermissions: invWhUserPermissionReducer,
  invWhUsers: invWhUserReducer,
  invPermissions: invPermissionReducer,
  invoices2: invoiceSlice2Reducer,
  appointmentsMotives: appointmentsMotivesSliceReducer,
  invWhReportPurchases: invWhReportPurchaseReducer,
  admCommissionRules: admCommissionRulesReducer,
  admCommissionRuleTypes: admCommissionRuleTypesReducer,
  admCommissionRulesReports: admCommissionRulesReportReducer,
  admCommissionRulesGroups: admCommissionRulesGroupsReducer,
  patientDataReports: patientDataReportReducer,
  admFaqs: admFaqReducer,
  admFaqProducts: admFaqProductReducer,
  portalFaqs: portalFaqReducer,
  portalFaqsByProcedures: portalFaqByProcedureReducer,
  admForget: admForgetReducer,
  messages: messageReducer,
  pollMain: pollMainReducer,
  pollQuestion: pollQuestionReducer,
  pollQuestionOption: pollQuestionOptionReducer,
  admForget: admForgetReducer,
  messages: messageReducer,
  pollContacts: pollContactsReducer,
  pbxDomains: pbxDomaisReducer,
  commonContacts: contactsCommonReducer,
  pollPuntuationReports: pollPuntuationReportReducer,
  pollPuntuationContactReports: pollPuntuationContactReportReducer,
  ModelStates: ModelStatesReducer,
  States: StatesReducer,
  Workflow: WorkflowsReducer,
  StatesTransitions: TransitionsReducer,
  StatesActions: StatesActionsReducer,
  EmailClient: EmailClientReducer,

  Tags2: NewTagReducer,
  tagsEdit: tagsEditReducer,
  consultantGeneralDropdown: consultantGeneralDropdownReducer,
  consultantBuildingDropdown: consultantBuildingDropdownReducer,
  sourcesNew: sourcesNewReducer,
  sourcesNewDropdown: sourcesNewDropdownReducer,
  surgeonDropdown: surgeonDropdownReducer,
  surgeonDropdownRestricted: surgeonDropdownRestrictedReducer,
  coordinatorsDropdown: coordinatorsDropdownReducer,
  coordinatorsGeneralDropdown: coordinatorsGeneralDropdownReducer,
  salesGroupsDropdown: salesGroupsDropdownReducer,
  assistantsDropdown: assistantsDropdownReducer,
  noProcedureDropdown: noProcedureDropdownReducer,
  groupsDropdown: groupsDropdownReducer,
  usersGeneralDropdown: usersGeneralDropdownReducer,
  commentCategories: commentCategoriesReducer,
  newItems: newItemsReducer,
  liveNotifications: liveNotificationsReducer,
  contactsP: contactsSliceReducer2,
  missedCallsCount: missedCallsCountReducer,
  totalUnreadChatMessages: totalUnreadChatMessagesReducer,
  generalTotalUnreadChatMessages: generalTotalUnreadChatMessagesReducer,
  userFavoriteSites:userFavoriteSitesReducer,
  portalMessagesNotifications: portalMessagesNotificationsReducer,
  departmentsDropdown: departmentsDropdownReducer,
  fraudulentContactsReasons: fraudulentContactsReasonsReducer,
  contactsN: contactsSliceReducer4,
  autoCalls: autoCallsSliceReducer,
  autoCallsHistory: autoCallsHistorySliceReducer,
  newWhastApp: newWhastAppReducer,
  updateWhastApp: updateWhastAppReducer,
  userContactsSMSMMS: userSMSMMSReducer,
  userContactsSMSMMSByGroups: userSMSMMSByGroupsReducer,
});
export default rootReducer;
