import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import Helmet from "react-helmet";
import { findIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import SmsMms from "../../components/SmsMms";
import SmsMmsContactList from "../../components/SmsMmsContactList";

import { red, blue, yellow } from "@material-ui/core/colors";

import {
  updateOnlyMessageCountService,
  addOnlyEntityService as addOnlyContactService,
} from "../../redux/slices/userSMSMMSSlice.js";

import { Card as MuiCard, makeStyles, Paper } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useParams } from "react-router-dom";
//import { fetchEntitiesService as fetchUserContactsSMSMMSService } from "../../redux/slices/userSMSMMSSlice.js";
import { getMessaging } from "firebase/messaging";
var dataSliceUserContactsSMSMMS = {
  stateListName: "userContactsSMSMMS",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Card = styled(MuiCard)(spacing);
var confirmDialogLoading = false;
const Wrapper = styled.div`
  position: relative;
  
  display: "block";
  visibilityIcon
  width: 100%;
  margin: "18px",
  padding: "5px",
`;

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(5),
    paddingTop: theme.spacing(3),
    position: "absolute",
    zIndex: "1000",
    height: "94%",
    width: "94%",
  },
  chatWrapper: {
    width: "60vw",
    display: "flex",
    height: "100%",
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  Toolbar: {
    justifyContent: "space-between",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  visibilityIcon: {
    position: "absolute",
    zIndex: 10,
    top: "265px",
    left: "215px",
  },
  totalValue: {
    position: "absolute",
    zIndex: 10,
    top: "280px",
    left: "270px",
  },
  printButton: {
    position: "absolute",
    zIndex: 10,
    top: "260px",
    right: "350px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "180px",
  },
  avatarRed: {
    backgroundColor: red[500],
  },
  avatarBlue: {
    backgroundColor: blue[500],
  },
  avatarYellow: {
    backgroundColor: yellow[500],
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function SmsMmsPanel(props) {
  const {openNewSMS, setOpenNewSMS} = props;
  const { authUser } = useSelector((state) => state.auth);
  const { newphone } = useParams();
  const phoneFA = useRef();
  const [contact, setContact] = useState(null);

  const [contactPhoneSearchText, setContactPhoneSearchText] = useState("");

  const [phone, setPhone] = useState(null);

  const dispatch = useDispatch();
  dataSliceUserContactsSMSMMS.orgId = authUser.id;
  const classes = useStyles();
  const { userContactsSMSMMS } = useSelector(
    (state) => state.userContactsSMSMMS
  );
  const { userContactsSMSMMSByGroups } = useSelector(
    (state) => state.userContactsSMSMMSByGroups
  );
  const [mergeData, setMergeData] = useState([]);
  const [mergeDataByGroups, setMergeDataByGroups] = useState([]);

  const [dataAll, setDataAll] = useState([...userContactsSMSMMS.concat(userContactsSMSMMSByGroups)]);
  const [margeDataAll, setMergeDataAll] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(authUser.default_cg?authUser.default_cg:null);
  const [selectedGroupContact, setSelectedGroupContact] = useState();
  const [selectedGroupPhone, setSelectedGroupPhone] = useState();

  const [tabValue, setTabValue] = useState(0);

  /*useInterval(async () => {
    dispatch(fetchUserContactsSMSMMSService(dataSliceUserContactsSMSMMS));
  }, 60000);*/
  //console.log('dataAll',dataAll);

  const messaging2 = getMessaging();

  const changePhone = (pphone) => {
    setContact(null);
    setPhone(pphone);
    phoneFA.current = pphone.phone;
  };

  const changeContact = (pcontact) => {
    setContact(pcontact);
    setPhone(null);
    phoneFA.current = pcontact.phone;
  };

  const findNewPhone = (newphone) => {
    // const selectedElement = find(mergeData, { "phone", newphone});
    const index = findIndex(mergeData, ["phone", newphone]);

    const newPhoneLocal = {
      fname: "",
      full_name: newphone,
      id: -1,
      lname: "",
      name: newphone,
      phone: newphone,
    };

    if (index > 0) {
      if (mergeData[index].id > 0) {
        setContact(mergeData[index]);
        setPhone(null);
      } else {
        setPhone(newPhoneLocal);
        setContact(null);
      }
    } else {
      setContact(null);
      setPhone(newPhoneLocal);
    }
  };

  const setOrder = (data) => {
    let index = -1;
    let localpayload = {
      data: { contact_id: null, phone: null },
      addsubtract: -1,
    };

    if (data.to_id) {
      index = findIndex(mergeData, ["id", data.to_id]);
    }

    if (index >= 0) {
      localpayload.data.contact_id = data.to_id;
    } else {
      localpayload.data.phone = data.to;
    }
    dispatch(updateOnlyMessageCountService(localpayload));
  };

  const setNewContact = async (payload) => {
    let index = -1;
    //console.log("setNewContact start payload at SmsMmsPanel data Ronald log",payload);

    if (payload.id >= 1) {
      index = findIndex(mergeData, ["id", payload.id]);

      /* let localContact = { ...mergeData[index] };
      localContact["lastMessageDate"] = moment().format("YYYY-MM-DD HH:mm:ss");

      let mergeDataLocal = [...mergeData];
      mergeDataLocal[index] = localContact;

      setMergeData(mergeDataLocal);*/

      setContact(payload);
      setPhone(null);
    } else {
      index = findIndex(mergeData, ["phone", payload.phone]);

      let localContact = { ...mergeData[index] };
      localContact["lastMessageDate"] = moment().format("YYYY-MM-DD HH:mm:ss");

      let mergeDataLocal = [...mergeData];
      mergeDataLocal[index] = localContact;

      setMergeData(mergeDataLocal);

      setContact(null);
      setPhone(payload);
    }

    if (index < 0) {
      if (payload.id >= 1) {
        // setMergeData([payload, ...mergeData]);
        setContact(payload);
        setPhone(null);
      } else {
        // setMergeData([payload, ...mergeData]);
        setContact(null);
        setPhone(payload);
      }

      /* var payloadL = {
        user_id: authUser.id,
        phone: payload.phone,
        contact_id: payload.id > 1 ? payload.id : null,
        lastmessagebody: "",
        lastmessagedate: moment().format("YYYY-MM-DD HH:mm:ss"),
        last_smsmms_id: null,
        color: "#BDBDBD",
      };*/

      // const { data } = await Api.saveSmsMmsContactColor(payloadL);

      var localPayload = {
        data: {
          id: payload.id,
          fname: payload.fname,
          lname: payload.lname,
          phone: payload.phone,
          record_id: payload.record_id? payload.record_id : null,
          portal_link: payload.portal_link? payload.portal_link: null,
          email: "",
          full_name: payload.fname + payload.lname,
          unread: 0,
          owner_name: payload.owner_name,
          user_id: authUser.id,
          owner_id: payload.owner_id,
          lastMessageDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          lastMessageBody: "",
          lastMessageUser: 0,
          outboundMessagesCount: 0,
          smsMMSColor: "#BDBDBD",
        },
      };
      dispatch(addOnlyContactService(localPayload));      
    }
    //console.log("setNewContact end payload at SmsMmsPanel data Ronald log",payload,index,mergeData);
  };

  const setUnread = (data) => {
    if (phone) {
      const index = findIndex(mergeData, ["phone", phone.phone]);
      let localContact = { ...mergeData[index] };

      localContact.unread = data;

      let localContacts = [...mergeData];
      localContacts[index] = localContact;

      setMergeData(localContacts);
    } else if (contact) {
      const index = findIndex(mergeData, ["id", contact.id]);
      let localContact = { ...mergeData[index] };

      localContact.unread = data;

      let localContacts = [...mergeData];
      localContacts[index] = localContact;

      setMergeData(localContacts);
    }
  };

  useEffect(() => {
    /*if (isEmpty(userContactsSMSMMS))
      dispatch(fetchUserContactsSMSMMSService(dataSliceUserContactsSMSMMS));*/
  }, []);

  useEffect(() => {
    if (newphone) {
      findNewPhone(newphone);
    }
  }, [newphone]);

  /*  useEffect(() => {
    // console.error("authUser", authUser);
  }, [authUser]);*/

  useEffect(() => {

    if (contactPhoneSearchText && contactPhoneSearchText !== " ") {
      const searchedItems = userContactsSMSMMS
        ? userContactsSMSMMS.filter((option) => {
            return (
              (option.full_name
                ? option.full_name
                    .toLowerCase()
                    .includes(contactPhoneSearchText.toLowerCase())
                : false) || option.phone.includes(contactPhoneSearchText)
            );
          })
        : [];

      setMergeData(searchedItems);
    } else {
      if (userContactsSMSMMS) {
        if (
          contact &&
          findIndex(userContactsSMSMMS, ["phone", contact.phone]) < 0
        ) {
          setMergeData([contact, ...userContactsSMSMMS]);
        } else if (
          phone &&
          findIndex(userContactsSMSMMS, ["phone", phone.phone]) < 0
        ) {
          setMergeData([phone, ...userContactsSMSMMS]);
        } else setMergeData(userContactsSMSMMS);
      }
    }



   
  }, [userContactsSMSMMS]);

  useEffect(() => {
    if (userContactsSMSMMSByGroups) {
      if (
        contact &&
        findIndex(userContactsSMSMMSByGroups, ["phone", contact.phone]) < 0
      ) {
        setMergeDataByGroups([contact, ...userContactsSMSMMSByGroups]);
      } else if (
        phone &&
        findIndex(userContactsSMSMMSByGroups, ["phone", phone.phone]) < 0
      ) {
        setMergeDataByGroups([phone, ...userContactsSMSMMSByGroups]);
      } else setMergeDataByGroups(userContactsSMSMMSByGroups);
    }
  }, [userContactsSMSMMSByGroups]);

  useEffect(() => {
    if (contactPhoneSearchText && contactPhoneSearchText !== " ") {
      const searchedItems = userContactsSMSMMS
        ? userContactsSMSMMS.filter((option) => {
          return (
            (option.full_name
              ? option.full_name
                  .toLowerCase()
                  .includes(contactPhoneSearchText.toLowerCase())
              : false) || option.phone.includes(contactPhoneSearchText)
          );
          })
        : [];

      setContact(null);
      setPhone(null);
      setMergeData(searchedItems);
    } else {
      if (userContactsSMSMMS) {
        setMergeData(userContactsSMSMMS);
      }
    }
  }, [contactPhoneSearchText]);

  useEffect(()=>{},[authUser.default_cg]);
 
  const saveTo = (data) => {
    //console.log("saveTo at SmsMmsPanel index data Ronald log",data);
    phoneFA.current = data.phone;
    setNewContact(data);
    setSelectedGroup(null);
  };

  return (
    <>
      <Helmet>
        <title>{"Messages"}</title>
      </Helmet>
      
      <Paper elevation={1} className={classes.paper}>
        <div className={classes.chatWrapper}>
        {/*authUser.default_cg*/}
          <SmsMmsContactList
            saveTo2 = {saveTo}
            newSMS = {openNewSMS}
            setNewSMS ={setOpenNewSMS} 
            mergeData={mergeData}
            mergeDataByGroups={mergeDataByGroups}
            changeContact={changeContact}
            changePhone={changePhone}
            setContactPhoneSearchText={setContactPhoneSearchText}
            phone={phone}
            contact={contact}
            setContact={setContact}
            setNewContact={setNewContact}
            setSelectedGroup={setSelectedGroup}
            setSelectedGroupContact={setSelectedGroupContact}
            selectedGroupContact = {selectedGroupContact}
            selectedGroup = {selectedGroup}
            groups={authUser.communications_groups}
            tabValueExternal={tabValue}
            setTabValueExternal={setTabValue}
            setSelectedGroupPhone={setSelectedGroupPhone}
            selectedGroupPhone = {selectedGroupPhone}
          />
          <SmsMms
            contact={contact}
            fromSMSPanel={true}
            phone={phone ? phone.phone : null}
            setOrder={setOrder}
            phoneF={phoneFA}
            setUnread={setUnread}
            messaging2={messaging2}
            userCanSend={
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.sms_integration === 1 &&
              authUser.receive_sms === 1 &&
              authUser.phone != "" &&
              authUser.phone != null
            }
            groups={authUser.communications_groups}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            selectedGroupContact={selectedGroupContact}
            setSelectedGroupContact={setSelectedGroupContact}
            tabValueExternal={tabValue}
            setTabValueExternal={setTabValue}
            setSelectedGroupPhone={setSelectedGroupPhone}
            selectedGroupPhone = {selectedGroupPhone}
            mergeDataByGroups={mergeDataByGroups}
            
          />
        </div>
      </Paper>
    </>
  );
}

export default SmsMmsPanel;
