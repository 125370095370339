import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

export const initialState = {
  loading: false,
  errors: [],
  newItems: [],
};

const invoicesSlice = createSlice({
  name: "newItems",
  initialState,
  reducers: {
    createNewItem: (state) => {
      state.loading = true;
    },
    createNewItemSuccess: (state, { payload }) => {
      // console.error("createNewItemSuccess", payload);
      state.newItems = [...state.newItems, payload];
      state.loading = false;
      state.errors = [];
    },
    createNewItemFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },
    updateNewItem: (state) => {},
    updateNewItemSuccess: (state, { payload }) => {
      const index = findIndex(state.newItems, ["id", payload.id]);
      const invoice = { ...state.newItems[index], ...payload };

      let newItems = [...state.newItems];
      newItems[index] = invoice;

      state.newItems = [...newItems];
      state.loading = false;
      state.errors = [];
    },

    updateNewItemFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },

    deleteNewItem: (state) => {},
    deleteNewItemSuccess: (state, { payload }) => {
      state.newItems = state.newItems.filter((newItem) => {
        return newItem.id !== payload;
      });
    },
    deleteNewItemFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload];
    },
  },
});

export const {
  createNewItem,
  createNewItemSuccess,
  createNewItemFailure,
  updateNewItem,
  updateNewItemSuccess,
  updateNewItemFailure,
  deleteNewItem,
  deleteNewItemSuccess,
  deleteNewItemFailure,
} = invoicesSlice.actions;
export const invoicesSelector = (state) => state.invoices;
export default invoicesSlice.reducer;

export function createNewItemService(payload) {
  return async (dispatch) => {
    dispatch(createNewItem());

    try {
      dispatch(createNewItemSuccess(payload));
    } catch (error) {
      dispatch(createNewItemFailure(error));
    }
  };
}

export function updateNewItemService(id, payload) {
  return async (dispatch) => {
    dispatch(updateNewItem());

    try {
      dispatch(updateNewItemSuccess(payload));
    } catch (error) {
      dispatch(updateNewItemFailure(error));
    }
  };
}

export function deleteNewItemService(id) {
  return async (dispatch) => {
    dispatch(deleteNewItem());

    try {
      dispatch(deleteNewItemSuccess(id));
    } catch (error) {
      dispatch(deleteNewItemFailure(error));
    }
  };
}
