import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import MTable from "../components_generic/MTableR";

import Iconify from "./Iconify";

const useStyles = makeStyles((theme) => ({
  logList: {
    listStyle: "none",
  },
  listItemTextBold: {
    fontWeight: 700,
  },
}));

function InvoicesContactLogs(props) {
  const crmTable = useRef();

  const { logs } = props;
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const [logsLocal, setLogsLocal] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const headCellsMaterialDetailPanel = [
    {
      field: "by",
      title: "Log Created By",
      width: "10%",
    },
    {
      field: "created_at",
      title: "Date",
      width: "10%",
    },
    {
      field: "event",
      title: "Event",
      width: "10%",
    },
    {
      field: "activities",
      width: "65%",
      title: "Activities",
      render: (rowData) => {
        return rowData.activities && rowData.activities.length > 0 ? (
          <Grid container>
            {rowData.activities.map((activity) => {
              return (
                <Grid item xs={4}>
                  <ListItem style={{ padding: 0, margin: 0, marginLeft: 2 }}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          className={classes.listItemTextBold}
                        >
                          {activity.property}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem style={{ padding: 0, margin: 0, marginLeft: 2 }}>
                    <ListItemText
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        maxWidth: "600px",
                        textAlign: "justify",
                      }}
                      primary={`New: ${
                        activity.new != "" ? activity.new : "None"
                      }`}
                      secondary={`Old: ${
                        activity.old != "" ? activity.old : "None"
                      }`}
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        );
      },
    },
  ];

  const detailPanelItem = [
    (rowData) => ({
      icon: () => (
        <Iconify
          icon={"ic:sharp-play-arrow"}
          width={24}
          height={24}
          sx={{ color: "#009688" }}
        />
      ),

      render: () => <div>{renderDetails(rowData.activitiesLogs?.data)}</div>,
    }),
  ];

  const renderDetails = (info) => {

    return (
      <Paper
        style={{
          margin: 10,
          marginLeft: 20,
          marginRight: 20,
          overflow: "hidden !important",
        }}
      >
        <MTable
          data={info}
          columns={headCellsMaterialDetailPanel}
          headerColor={"#01579b"}
          headerBackgroundColor={"#e3f2fd"}
          showPaging
          emptyRowsWhenPaging={false}
          pageSize={5}
          pageSizeOptions={[5, 10, 20, 30, 50]}
          search={false}
          toolbar={false}
        />
      </Paper>
    );
  };

  const headCellsMaterial = [
    {
      field: "name",
      title: "Name",
      width: "30%",
    },
    {
      field: "activities",
      width: "70%",
      title: "Activities",
      sorting: false,
      render: (rowData) => {
        return rowData.activitiesLogs?.data ? (
          <Grid container>
            {rowData.activitiesLogs?.data.map((activity) => {
              return (
                <Grid item xs={4}>
                  <ListItem style={{ paddingLeft: 0 }}>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variant="body1"
                            className={classes.listItemTextBold}
                            component={"span"}
                          >
                            {`${activity.event} by `}
                          </Typography>

                          <Typography
                            variant="body1"
                            className={classes.listItemTextBold}
                            component={"span"}
                          >
                            {`${activity.by} `}
                          </Typography>
                        </>
                      }
                      secondary={
                        <Typography variant="body2">
                          {`${activity.created_at}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        );
      },
    },
  ];

  //   useEffect(() => {
  //     if (logs) {
  //       console.log("logslogs", logs);
  //     }
  //   }, [logs]);

  return (
    <Card
      style={{
        boxShadow: "0 0 2px 1px #b2dfdb",
        minHeight: "75vh",
      }}
    >
      <CardHeader
        title={
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="tabs"
            TabIndicatorProps={{ style: { background: "#009688" } }}
          >
            <Tab
              label={
                <Typography
                  style={{
                    color: tabValue == 0 ? "#009688" : "#212b36",
                    fontFamily: "Nunito,Roboto",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    margin: 12,
                  }}
                >
                  General
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography
                  style={{
                    color: tabValue == 1 ? "#009688" : "#212b36",
                    fontFamily: "Nunito,Roboto",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    margin: 12,
                  }}
                >
                  Items
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography
                  style={{
                    color: tabValue == 2 ? "#009688" : "#212b36",
                    fontFamily: "Nunito,Roboto",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    margin: 12,
                  }}
                >
                  Payments
                </Typography>
              }
              {...a11yProps(2)}
            />
            <Tab
              label={
                <Typography
                  style={{
                    color: tabValue == 3 ? "#009688" : "#212b36",
                    fontFamily: "Nunito,Roboto",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    margin: 12,
                  }}
                >
                  Refunds
                </Typography>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        }
      />

      <CardContent
        style={{
          marginTop: 15,
        }}
      >
        <TabPanel value={tabValue} index={0}>
          <MTable
            data={logs ? logs[0]?.activitiesLogs?.data : []}
            columns={headCellsMaterialDetailPanel}
            headerColor={"#616161"}
            headerBackgroundColor={"#e8f5e9"}
            showPaging
            pageSize={10}
            pageSizeOptions={[10, 20, 50, 100, 250]}
            emptyRowsWhenPaging={false}
            search={false}
            toolbar={false}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MTable
            data={logs ? logs[0]?.items?.data : []}
            columns={headCellsMaterial}
            headerColor={"#616161"}
            headerBackgroundColor={"#e8f5e9"}
            showPaging
            pageSize={10}
            pageSizeOptions={[10, 20, 50, 100, 250]}
            emptyRowsWhenPaging={false}
            search={false}
            toolbar={false}
            detailPanel={detailPanelItem}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <MTable
            data={logs ? logs[0]?.paymentsNoRefund?.data : []}
            columns={headCellsMaterial}
            headerColor={"#616161"}
            headerBackgroundColor={"#e8f5e9"}
            showPaging
            pageSize={10}
            pageSizeOptions={[10, 20, 50, 100, 250]}
            emptyRowsWhenPaging={false}
            search={false}
            toolbar={false}
            detailPanel={detailPanelItem}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <MTable
            data={logs ? logs[0]?.paymentsRefund?.data : []}
            columns={headCellsMaterial}
            headerColor={"#616161"}
            headerBackgroundColor={"#e8f5e9"}
            showPaging
            pageSize={10}
            pageSizeOptions={[10, 20, 50, 100, 250]}
            emptyRowsWhenPaging={false}
            search={false}
            toolbar={false}
            detailPanel={detailPanelItem}
          />
        </TabPanel>
      </CardContent>
    </Card>
  );
}

export default InvoicesContactLogs;
