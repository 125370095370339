import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  customFields: [],
};

const CustomFieldsSlice = createSlice({
  name: "customFields",
  initialState,
  reducers: {
    getCustomFields: (state) => {
      state.loading = true;
    },
    getCustomFieldsSuccess: (state, { payload }) => {
      state.customFields = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCustomFieldsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createCustomField: (state) => {},
    createCustomFieldSuccess: (state, { payload }) => {
      state.customFields = [...state.customFields, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createCustomFieldFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateCustomField: (state) => {},
    updateCustomFieldSuccess: (state, { payload }) => {
      const index = findIndex(state.customFields, ["id", payload.data.id]);
      const customField = { ...state.customFields[index], ...payload.data };

      let customFields = [...state.customFields];
      customFields[index] = customField;

      state.customFields = [...customFields];
      state.loading = false;
      state.errors = [];
    },
    updateCustomFieldFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteCustomField: (state) => {},
    deleteCustomFieldSuccess: (state, { payload }) => {
      state.customFields = state.customFields.filter((customField) => {
        return customField.id !== payload;
      });
    },
    deleteCustomFieldFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getCustomFields,
  getCustomFieldsSuccess,
  getCustomFieldsFailure,
  createCustomField,
  createCustomFieldSuccess,
  createCustomFieldFailure,
  updateCustomField,
  updateCustomFieldSuccess,
  updateCustomFieldFailure,
  deleteCustomField,
  deleteCustomFieldSuccess,
  deleteCustomFieldFailure,
} = CustomFieldsSlice.actions;
export const customFieldsSelector = (state) => state.customFields;
export default CustomFieldsSlice.reducer;

export function fetchCustomFieldsService(orgId) {
  return async (dispatch) => {
    dispatch(getCustomFields());

    try {
      const response = await Api.getCustomFields(orgId);

      dispatch(getCustomFieldsSuccess(response.data));
    } catch (error) {
      dispatch(getCustomFieldsFailure(error));
    }
  };
}

export function createCustomFieldService(payload) {
  return async (dispatch) => {
    dispatch(createCustomField());

    try {
      const response = await Api.saveCustomField(payload);

      dispatch(createCustomFieldSuccess(response.data));
    } catch (error) {
      dispatch(createCustomFieldFailure(error));
    }
  };
}

export function updateCustomFieldService(id, payload) {
  return async (dispatch) => {
    dispatch(updateCustomField());

    try {
      const response = await Api.updateCustomField(id, payload);

      dispatch(updateCustomFieldSuccess(response.data));
    } catch (error) {
      dispatch(updateCustomFieldFailure(error));
    }
  };
}

export function deleteCustomFieldService(id) {
  return async (dispatch) => {
    dispatch(deleteCustomField());

    try {
      const response = await Api.deleteCustomField(id);

      dispatch(deleteCustomFieldSuccess(id));
    } catch (error) {
      dispatch(deleteCustomFieldFailure(error));
    }
  };
}
