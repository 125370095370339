import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Picker from "emoji-picker-react";
import { blue } from "@material-ui/core/colors";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import SendIcon from "@material-ui/icons/Send";
import Api from "../../lib/api";
import Popup from "../../components/Popup";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { updateDefault_cg } from "../../redux/slices/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      // width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "50px",
    height: "50px",
  },
  inputRoot: {
    borderRadius: "35px",
  },
  icon: {
    transform: "rotate(45deg)",
    color: blue[700],
  },
}));
import Popover from "@material-ui/core/Popover";
import CRMUtils from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import TemplatesSMSMMS from "./TemplatesSMSMMS";
import Iconify from "../Iconify";
import { useRef } from "react";

export default function SmsMmsForm(props) {
  const {
    contactSmsMms,
    sendSmsMms,
    userCanSend = true,
    setOpenData = null,
    selectedGroup = null,
    tabValueExternal = null,
    refreshByGroups = null,
    contact = null,

    setSelectedGroup = null,
  } = props;
  const language_idTypeForm = useRef(0);
  const [isOpenDialogTemplates, setIsOpenDialogTemplates] = useState(false);
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const [urlPictures, setUrlPictures] = useState("");
  const { authUser, pbxConfig } = useSelector((state) => state.auth);
  const classes = useStyles();
  const watchComment = watch("body");
  const watchGroup = watch("group");
  const [anchorElTypeform, setAnchorElTypeform] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [anchorElLanguageTypeForm, setAnchorElLanguageTypeForm] =
    useState(null);
  const [anchorElLanguagePortal, setAnchorElLanguagePortal] = useState(null);
  const [anchorElTemplate, setAnchorElTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [send_unsuscribe_link, setSend_unsuscribe_link] = useState(false);
  const [anchorElEvaluations, setAnchorElEvaluations] = useState(null);
  // const [buildingsWithTypeformDropDown, setBuildingsWithTypeformDropDown] =
  //   useState([]);
  const [typeforms, setTypeforms] = useState([]);

  const [localFormGroup, setLocalFormGroup] = useState(null);

  const [usersToImpersonate, setUsersToImpersonate] = useState([]);
  const [localFormUser, setLocalFormUser] = useState(null);

  const userCanSendSecureInvitation = CRMUtils.userHasPermission(authUser, [
    "user_sendSecureInvitation",
  ]);
  const userCanSendSMSMMSTemplates = CRMUtils.userHasPermission(authUser, [
    "user_sendSMSMMSTemplates",
  ]);

  const userCanSendSMSMMSImpersonatingOwner = CRMUtils.userHasPermission(
    authUser,
    ["user_impersonate"]
  );

  const dispatch = useDispatch();

  const handleClickTypeform = (event) => {
    setAnchorElTypeform(event.currentTarget);
  };

  const handleClickLinkSecureUpload = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleClickTemplates = (event) => {
    setAnchorElTemplate(event.currentTarget);
  };

  const handleCloseTemplates = (event) => {
    setAnchorElTemplate(null);
  };

  const handleCloseEvaluations = () => {
    setAnchorElEvaluations(null);
  };

  const handleClickEvaluations = (event) => {
    setAnchorElEvaluations(event.currentTarget);
  };
  const handleCloseTypeform = () => {
    setAnchorElTypeform(null);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const handleClickLanguageTypeForm = (event) => {
    setAnchorElLanguageTypeForm(event.currentTarget);
  };

  const handleCloseLanguageTypeForm = () => {
    setAnchorElLanguageTypeForm(null);
  };

  const handleClickLanguagePortal = (event) => {
    setAnchorElLanguagePortal(event.currentTarget);
  };

  const handleCloseLanguagePortal = () => {
    setAnchorElLanguagePortal(null);
  };

  const handleSelectTypeformOLD = async (building_id, contact_id) => {
    if (contact_id) {
      var payload = {
        language_id: language_idTypeForm.current,
        building_id: building_id,
      };

      const { data } = await Api.generateEvaluationInvitation(
        contact_id,
        payload
      );

      if (data?.data?.message) {
        setValue("body", data.data.message);
      } else {
        setValue("body", "");
      }
      handleCloseTypeform();
      handleCloseEvaluations();
      handleCloseLanguageTypeForm();
      handleCloseTemplates();
    }
  };

  const handleSelectTypeform = async (link_name, contact_id) => {
    if (contact_id) {
      var payload = {
        language_id: language_idTypeForm.current,
        link_name: link_name,
      };

      const { data } = await Api.generateTypeformLink(contact_id, payload);

      if (data?.data?.message) {
        setValue("body", data.data.message);
      } else {
        setValue("body", "");
      }
      handleCloseTypeform();
      handleCloseEvaluations();
      handleCloseLanguageTypeForm();
      handleCloseTemplates();
    }
  };

  const getUsersWithConversationsWithContact = async (contact_id) => {
    if (contact_id) {
      
      const { data } = await Api.getConversationsUsersOfContact(contact_id);
      console.log("getUsersWithConversationsWithContact",data);
      setUsersToImpersonate(data);
    }
  };
    
  const generateUploadInvitation = async (contact, idLanguage) => {
    if (contact) {
      var payload = { language_id: idLanguage };
      const { data } = await Api.generateUploadInvitation(contact.id, payload);
      if (data?.data?.message) {
        setValue("body", data.data.message);
      } else {
        setValue("body", "");
      }
      setAnchorElLanguage(null);
    }
  };

  const closeTemplates = () => {
    setIsOpenDialogTemplates(false);
  };

  const onEmojiClick = (event, emojiObject) => {
    setValue(
      "body",
      watchComment ? watchComment + emojiObject.emoji : emojiObject.emoji
    );
  };

  const onSubmit = (data) => {
    if (!data.body) {
      data.body = "";
    }

    console.log("onSubmit", data.group, selectedGroup, tabValueExternal);

    if (
      tabValueExternal === 0 &&
      ((data.setDefault == 1 &&
        data.group !== selectedGroup &&
        selectedGroup) ||
        (data.setDefault == 1 && !selectedGroup))
    ) {
      //From user chat
      //if(setSelectedGroup) setSelectedGroup(data.group);
      dispatch(updateDefault_cg(data.group));
    }

    if (data.setDefault == 0 && selectedGroup && tabValueExternal === 1)
      data.group = selectedGroup; //From group chat
    else if (
      data.setDefault == 0 &&
      authUser.default_cg &&
      tabValueExternal === 0
    )
      data.group = authUser.default_cg; //From user chat
    else if (data.setDefault == 0 && !selectedGroup && authUser.default_cg)
      data.group = authUser.default_cg;
    //  console.log("sending message", data, selectedGroup);

    if (!data.group && selectedGroup && tabValueExternal === 1)
      data.group = selectedGroup; //From group chat
    else if (
      !data.group &&
      !selectedGroup &&
      tabValueExternal === 1 &&
      authUser.default_cg
    )
      data.group = authUser.default_cg; //From group chat

    console.log("onSubmit end", data.group, selectedGroup, tabValueExternal);

    if(userCanSendSMSMMSImpersonatingOwner && localFormUser) data.impersonateUser = localFormUser;

    try {
      //if(data.setDefault == 1) setTimeout(sendSmsMms(data),5000);
      /*else*/ sendSmsMms(data);
    } catch (e) {
      //  console.log("error on sendSmsMms", e);
    }

    setValue("body", "");
    setValue("setDefault", 0);
    reset();
    //console.log('selectedGroup',data.group);
    //(refreshByGroups && data.group) ? refreshByGroups(data.phone?data.phone:null) : "";
  };

  useEffect(() => {}, [watchComment, watchGroup]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoriesTemplates, setCategoriesTemplates] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);

  const open = Boolean(anchorEl);
  const idopen = open ? "simple-popover" : undefined;

  const handleKeyDown = (event) => {
    // console.log('sending message event',watchGroup,watchComment);
    let data = {};
    data.body = watchComment;
    data.group = watchGroup;

    if (event.keyCode === 13 && event.shiftKey) {
      event.preventDefault();
      setValue("body", watchComment + "\r\n");
    } else if (event.keyCode === 13) {
      event.preventDefault();
      //if (selectedGroup) data.group = selectedGroup;
      onSubmit(data);
    }
  };

  /*const getSMSMMSTemplatesByCategory = async (id)=>{
    const {data} = await Api.getSMSMMSTemplatesByCategory(id);
    setTemplates([...Object.values(data)]);;
  };
*/

  const openTemplates = (id) => {
    // getSMSMMSTemplatesByCategory(id).then(()=>{
    setIsOpenDialogTemplates(true);
    // })
    setAnchorElTypeform(null);
    setAnchorElTemplate(null);
    setAnchorElEvaluations(null);
  };

  const getCategories = async () => {
    const { data } = await Api.getSMSMMSTemplatesCategories();
    setCategoriesTemplates([...data.data]);
  };

  const categoryOptions = () => {
    var categories = [];
    if (categoriesTemplates && categoriesTemplates.length != 0) {
      var myTemplates = categoriesTemplates.find((a) => a.id == 5);
      categories.push({ id: myTemplates.id, title: "My templates" });

      categoriesTemplates?.map((category) => {
        return category.id != 5
          ? categories.push({
              id: category.id,
              title: category.name,
            })
          : "";
      });
    }

    return categories;
  };

  const GroupOptions = () => {
    let final;
    let tmp = [];
    //  console.log('props.groups',props.groups,'contact',contact);
    if (contact && props.groups)
      tmp = props.groups
        .filter((group) => {
          return group.type === "message_group";
        })

        /*.filter((group)=>{
      return group.building_id == contact.building_id;
    })*/
        .map((group) => {
          return {
            id: group.group_id,
            title: group.name,
          };
        });
    else if (props.groups)
      tmp = props.groups
        .filter((group) => {
          return group.type === "message_group";
        })
        .map((group) => {
          return {
            id: group.group_id,
            title: group.name,
          };
        });
    if (tmp.length > 0) {
      final = tmp;
      if (userCanSendSMSMMSImpersonatingOwner && contact && contact.id && contact.user_id)
        final.push({ id: -5, title: "Impersonate Owner" });
      final.push({ id: 0, title: "My Phone" });
    } else {
      final = [];
      if (userCanSendSMSMMSImpersonatingOwner && contact && contact.id && contact.user_id)
        final.push({ id: -5, title: "Impersonate Owner" });
      final.push({ id: 0, title: "My Phone" });
    }
    return final;
  };

  const ImpersonateUserOptions = () => {
    let final;
    let tmp = [];
    //  console.log('props.groups',props.groups,'contact',contact);
    if (contact && usersToImpersonate.length > 0)
      tmp = usersToImpersonate        
        .map((user) => {
          return {
            id: user.id,
            title: user.fname + " " + user.lname,
          };
        });
        
      final = tmp;
      
    return final;
  };

  const GroupName = (id) => {
    let final = "";
    let tmp = [];
    //  console.log('props.groups',props.groups,'contact',contact);

    if (id) {
      tmp = props.groups.filter((group) => {
        return group.type === "message_group" && group.id == id;
      });
      if (tmp.length > 0) final = tmp[0].name;
    }

    return final;
  };

  // const fetchTypeformDropdown = async () => {
  //   const { data } = await Api.getBuildingsWithTypeformDropDown();
  //   setBuildingsWithTypeformDropDown(data.data);
  // };

  const fetchTypeforms = async () => {
    const { data } = await Api.getBuildingTypeforms();
    setTypeforms(data.data);
  };

  useEffect(() => {
    // console.log("CONTACT: ", contact);
    setSend_unsuscribe_link(
      authUser.organization.data.send_unsuscribe_link ? true : false
    );

    setValue("body", "");
  }, [contact]);

  useEffect(() => {
    //  fetchTypeformDropdown();
    fetchTypeforms();
    getCategories();
  }, []);

  //const sendAsGroupHtml = document.getElementById("sendAsGroupSelect");

  const getPatientToken = async (language = 0) => {
    if (contact) {
      const { data } = await Api.getPortalToken(contact.id, language);

      return data;
    } else {
      return null;
    }
  };

  const generateLinkToPortal = (language) => {
    var link =
      contact && contact.portal_link_name ? contact.portal_link_name : null;

    getPatientToken(language).then((result) => {
      if (result) {
        setValue("body", result);
      } else {
        setValue("body", "");
      }
    });
  };

  useEffect(()=>{
    if(userCanSendSMSMMSImpersonatingOwner && contact && contact.id) getUsersWithConversationsWithContact(contact?.id);
  },[]);

  useEffect(()=>{
    if(userCanSendSMSMMSImpersonatingOwner && contact && contact.id) getUsersWithConversationsWithContact(contact?.id);
    if(!contact) setLocalFormUser(null);
  },[contact]);

  useEffect(() => {
    console.log("localFormGroup", localFormGroup);
  }, [localFormGroup]);

  useEffect(()=>{
    console.log("localFormUser", localFormUser);
  },[localFormUser]);

  useEffect(()=>{
    //console.log("localFormUser", localFormUser);
    if(usersToImpersonate.length == 0) setLocalFormUser(null);
  },[usersToImpersonate]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container>
          {((!selectedGroup && tabValueExternal === 0) ||
            tabValueExternal === 0) && (
            <>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={10}
                direction="row-reverse"
              >
                <Grid item xs={userCanSendSMSMMSImpersonatingOwner && contact && usersToImpersonate.length > 0?6:12}>
                <Controller
                  name="group"
                  control={control}
                  defaultValue={
                    authUser.default_cg && GroupOptions().length > 1
                      ? authUser.default_cg
                      : 0
                  }
                  as={({ onChange, value }) => (
                    <Controls.Select
                      className={classes.inputRoot}
                      label={"Send as Group"+(userCanSendSMSMMSImpersonatingOwner?"/owner":"")+"?"}
                      disabled={props.groups && !localFormUser ? false : true}
                      options={GroupOptions()}
                      onChange={(value) => {
                        onChange(value);
                        setLocalFormGroup(value.target.value);
                      }}
                      value={value}
                      style={{ backgroundColor: "#ffffff" }}
                      id={"sendAsGroupSelect"}
                    />
                  )}
                  //rules={{ required: "Source is required" }}
                />
                {errors.group && (
                  <p className={classes.error}>{errors.group.message}</p>
                )}
                </Grid>

                {userCanSendSMSMMSImpersonatingOwner && contact && usersToImpersonate.length > 0 && <Grid item xs={6}>
              <Controller
                name="impersonateUser"
                control={control}
                // defaultValue={
                //   authUser.default_cg && GroupOptions().length > 1
                //     ? authUser.default_cg
                //     : 0
                // }
                as={({ onChange, value }) => (
                  <Controls.Select
                    className={classes.inputRoot}
                    label="Send as User?"
                    disabled={localFormGroup ? true : false}
                    options={ImpersonateUserOptions()}
                    onChange={(value) => {
                      onChange(value);
                      setLocalFormUser(value.target.value);
                    }}
                    value={value}
                    style={{ backgroundColor: "#ffffff" }}
                    id={"sendAsUserSelect"}
                  />
                )}
                //rules={{ required: "Source is required" }}
              />
              {errors.group && (
                <p className={classes.error}>{errors.group.message}</p>
              )}
              </Grid>}
                
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="setDefault"
                  control={control}
                  defaultValue={0}
                  as={({ value, onChange }) => (
                    <Controls.Checkbox
                      label="Set Default"
                      checked={value === 1 || value === "1" ? true : false}
                      disabled={
                        (GroupOptions().length == 1 || localFormGroup == -5) || localFormUser
                          ? true
                          : false
                      }
                      onChange={(value) => {
                        onChange(value ? 1 : 0);
                      }}
                    />
                  )}
                />
              </Grid>
              {/*document.getElementById("sendAsGroupSelect")
                ? document.getElementById("sendAsGroupSelect").innerText
                    : ""*/}
            </>
          )}

          <Grid
            container
            item
            xs={contact && contact.portal_link_name ? 3 : 2}
            style={{ justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Tooltip title="Insert Emoji">
                <span>
                  <Controls.IconButton
                    style={{ padding: 2, margin: -4 }}
                    aria-label="Emoticon Icon"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    disabled={!userCanSend}
                  >
                    <InsertEmoticonIcon style={{ color: blue[700] }} />
                  </Controls.IconButton>
                </span>
              </Tooltip>
              <Controller
                control={control}
                defaultValue=""
                name="attch"
                as={({ onChange, value }) => (
                  <Tooltip title="Attach Images">
                    <span>
                      <Controls.DropzoneSendPicture
                        style={{ padding: 2, margin: -4 }}
                        icon={<AttachFileIcon className={classes.icon} />}
                        onChange={(files) => {
                          // setAttached(window.URL.createObjectURL(files[0]));
                          //  onChange(files[0]);
                          onSubmit({ attch: files });
                          if (setOpenData) {
                            setOpenData(false);
                          }
                        }}
                        setOpenData={setOpenData ? setOpenData : null}
                        value={value}
                        disabled={!userCanSend}
                      />
                    </span>
                  </Tooltip>
                )}
              />
              {userCanSendSecureInvitation && (
                <Tooltip title="Send Secure Upload Link">
                  <span>
                    <Controls.IconButton
                      style={{ padding: 2, margin: -4 }}
                      onClick={handleClickLinkSecureUpload}
                      disabled={!userCanSend || (!contact && !contactSmsMms)}
                    >
                      <Iconify
                        style={{
                          color:
                            !userCanSend || (!contact && !contactSmsMms)
                              ? "#999797"
                              : "#1976d2",
                        }}
                        icon={"tabler:photo-up"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              )}

              {!userCanSendSMSMMSTemplates && (
                <Tooltip title="Send Evaluation Form Link">
                  <span>
                    <Controls.IconButton
                      style={{ padding: 2, margin: -4 }}
                      onClick={handleClickLanguageTypeForm}
                      disabled={
                        !userCanSend ||
                        !contact ||
                        (contact && contact.user_id !== authUser.id)
                      }
                    >
                      <Iconify
                        style={{
                          color:
                            !userCanSend ||
                            !contact ||
                            (contact && contact.user_id !== authUser.id)
                              ? "#999797"
                              : "#1976d2",
                        }}
                        icon={"healthicons:health-worker-form"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              )}
              {contact && contact.portal_link_name ? (
                <Tooltip title="Send link to the patient portal">
                  <span>
                    <Controls.IconButton
                      style={{ padding: 2, margin: -4 }}
                      onClick={handleClickLanguagePortal}
                      disabled={
                        !userCanSend || !contact || contact.record_id == null
                      }
                    >
                      <Iconify
                        style={{
                          color:
                            !userCanSend ||
                            !contact ||
                            contact.record_id == null
                              ? "#999797"
                              : "#1976d2",
                        }}
                        icon={"grommet-icons:share-rounded"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              ) : (
                ""
              )}

              {userCanSendSMSMMSTemplates && (
                <Tooltip title="Send Templates">
                  <span>
                    <Controls.IconButton
                      style={{ padding: 2, margin: -4 }}
                      onClick={handleClickTemplates}
                    >
                      <Iconify
                        style={{
                          color: "#1976d2",
                        }}
                        icon={"mdi:file-send-outline"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
            <Popover
              id={idopen}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </Popover>
            {/* <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={contact && contact.portal_link_name ? 2 : 3}
              direction="row-reverse"
            >
              {
                <Tooltip title="Insert Emoji">
                  <span>
                    <Controls.IconButton
                      style={{ padding: 2 }}
                      aria-label="Emoticon Icon"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      disabled={!userCanSend}
                    >
                      <InsertEmoticonIcon style={{ color: blue[700] }} />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              }
              <Popover
                id={idopen}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Picker onEmojiClick={onEmojiClick} />
              </Popover>
            </Grid> */}
            {/* <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={contact && contact.portal_link_name ? 2 : 3}
              direction="row-reverse"
            >
              <Controller
                control={control}
                defaultValue=""
                name="attch"
                as={({ onChange, value }) => (
                  <Tooltip title="Attach Items">
                    <span>
                      <Controls.DropzoneSendPicture
                        style={{ padding: 2 }}
                        icon={<AttachFileIcon className={classes.icon} />}
                        onChange={(files) => {
                          // setAttached(window.URL.createObjectURL(files[0]));
                          //  onChange(files[0]);
                          onSubmit({ attch: files });
                          if (setOpenData) {
                            setOpenData(false);
                          }
                        }}
                        setOpenData={setOpenData ? setOpenData : null}
                        value={value}
                        disabled={!userCanSend}
                      />
                    </span>
                  </Tooltip>
                )}
              />
            </Grid> */}
            {/* {userCanSendSecureInvitation && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={contact && contact.portal_link_name ? 2 : 3}
                direction="row-reverse"
              >
                <Tooltip title="Send Secure Upload Link">
                  <span>
                    <Controls.IconButton
                      style={{ marginLeft: -8, padding: 2 }}
                      onClick={handleClickLinkSecureUpload}
                      disabled={!userCanSend || (!contact && !contactSmsMms)}
                    >
                      <Iconify
                        style={{
                          color:
                            !userCanSend || (!contact && !contactSmsMms)
                              ? "#999797"
                              : "#1976d2",
                        }}
                        icon={"tabler:photo-up"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )} */}

            <Menu
              style={{ marginLeft: userCanSendSMSMMSTemplates ? 99 : 105 }}
              id="simple-menu"
              anchorEl={anchorElTypeform}
              open={Boolean(anchorElTypeform)}
              onClose={handleCloseTypeform}
            >
              {typeforms.length > 0 ? (
                typeforms.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => handleSelectTypeform(item.url, contact.id)}
                    >
                      {item.building_name + " " + item.name}
                    </MenuItem>
                  );
                })
              ) : (
                <Typography>No typeform links available</Typography>
              )}
            </Menu>

            <Menu
              style={{ marginLeft: userCanSendSMSMMSTemplates ? 160 : "" }}
              id="simple-menu"
              anchorEl={anchorElLanguageTypeForm}
              open={Boolean(anchorElLanguageTypeForm)}
              onClose={handleCloseLanguageTypeForm}
            >
              <MenuItem
                style={{ padding: 0 }}
                onClick={() => {
                  language_idTypeForm.current = 0;
                }}
              >
                <Button
                  style={{ justifyContent: "left", fontWeight: 400 }}
                  onClick={handleClickTypeform}
                >
                  <Iconify
                    style={{ margin: 4 }}
                    icon={"emojione:flag-for-united-states"}
                  />{" "}
                  English
                  <KeyboardArrowRightIcon />
                </Button>
              </MenuItem>

              <MenuItem
                style={{ padding: 0 }}
                onClick={() => {
                  language_idTypeForm.current = 1;
                }}
              >
                <Button
                  style={{ justifyContent: "left", fontWeight: 400 }}
                  onClick={handleClickTypeform}
                >
                  <Iconify
                    style={{ margin: 4 }}
                    icon={"emojione:flag-for-spain"}
                  />
                  Spanish
                  <KeyboardArrowRightIcon />
                </Button>
              </MenuItem>
            </Menu>

            <Menu
              id="simple-menu"
              anchorEl={anchorElLanguagePortal}
              open={Boolean(anchorElLanguagePortal)}
              onClose={handleCloseLanguagePortal}
            >
              <MenuItem
                style={{ padding: 0 }}
                onClick={() => {
                  language_idTypeForm.current = 0;
                }}
              >
                <Button
                  style={{ justifyContent: "left", fontWeight: 400 }}
                  onClick={() => {
                    generateLinkToPortal(0);
                    handleCloseLanguagePortal();
                  }}
                >
                  <Iconify
                    style={{ margin: 4 }}
                    icon={"emojione:flag-for-united-states"}
                  />{" "}
                  English
                </Button>
              </MenuItem>

              <MenuItem
                style={{ padding: 0 }}
                onClick={() => {
                  language_idTypeForm.current = 0;
                }}
              >
                <Button
                  style={{ justifyContent: "left", fontWeight: 400 }}
                  onClick={() => {
                    generateLinkToPortal(1);
                    handleCloseLanguagePortal();
                  }}
                >
                  <Iconify
                    style={{ margin: 4 }}
                    icon={"emojione:flag-for-spain"}
                  />
                  Spanish
                </Button>
              </MenuItem>
            </Menu>

            <Menu
              id="simple-menu"
              anchorEl={anchorElLanguage}
              keepMounted
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguage}
            >
              <MenuItem
                onClick={() => {
                  generateUploadInvitation(
                    contact ? contact : contactSmsMms ? contactSmsMms : null,
                    0
                  );
                }}
              >
                <Iconify
                  style={{ margin: 4 }}
                  icon={"emojione:flag-for-united-states"}
                />{" "}
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  generateUploadInvitation(
                    contact ? contact : contactSmsMms ? contactSmsMms : null,
                    1
                  );
                }}
              >
                <Iconify
                  style={{ margin: 4 }}
                  icon={"emojione:flag-for-spain"}
                />
                Spanish
              </MenuItem>
            </Menu>

            {/* {!userCanSendSMSMMSTemplates && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={contact && contact.portal_link_name ? 2 : 3}
              >
                <Tooltip title="Send Evaluation Form Link">
                  <span>
                    <Controls.IconButton
                      style={{ marginLeft: -8, padding: 2 }}
                      onClick={handleClickLanguageTypeForm}
                      disabled={
                        !userCanSend ||
                        !contact ||
                        (contact && contact.user_id !== authUser.id)
                      }
                    >
                      <Iconify
                        style={{
                          color:
                            !userCanSend ||
                            !contact ||
                            (contact && contact.user_id !== authUser.id)
                              ? "#999797"
                              : "#1976d2",
                        }}
                        icon={"healthicons:health-worker-form"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )} */}

            {/* {contact && contact.portal_link_name ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={2}
              >
                <Tooltip title="Send link to the patient portal">
                  <span>
                    <Controls.IconButton
                      style={{ marginLeft: -8, padding: 2 }}
                      onClick={handleClickLanguagePortal}
                      disabled={
                        !userCanSend || !contact || contact.record_id == null
                      }
                    >
                      <Iconify
                        style={{
                          color:
                            !userCanSend ||
                            !contact ||
                            contact.record_id == null
                              ? "#999797"
                              : "#1976d2",
                        }}
                        icon={"grommet-icons:share-rounded"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )} */}

            {/* {userCanSendSMSMMSTemplates && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={contact && contact.portal_link_name ? 2 : 3}
              >
                <Tooltip title="Send Templates">
                  <span>
                    <Controls.IconButton
                      style={{ marginLeft: -8, padding: 2 }}
                      onClick={handleClickTemplates}
                    >
                      <Iconify
                        style={{
                          color: "#1976d2",
                        }}
                        icon={"mdi:file-send-outline"}
                        width={25}
                        height={25}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )} */}
          </Grid>

          <Menu
            id="simple-menu"
            anchorEl={anchorElTemplate}
            keepMounted
            open={Boolean(anchorElTemplate)}
            onClose={handleCloseTemplates}
          >
            {categoryOptions().length > 0 ? (
              categoryOptions().map((item) => {
                return item.id == 1 ? (
                  <Button
                    style={{ width: "100%", fontWeight: 400, marginLeft: 6 }}
                    onClick={handleClickEvaluations}
                  >
                    {item.title} <KeyboardArrowRightIcon />
                  </Button>
                ) : (
                  <MenuItem
                    onClick={() => {
                      setCategorySelected(item);
                      openTemplates(item.id);
                    }}
                  >
                    {item.title}
                  </MenuItem>
                );
              })
            ) : (
              <>
                <Typography>No templates available</Typography>
              </>
            )}
          </Menu>

          <Menu
            style={{ marginLeft: 144 }}
            id="simple-menu"
            anchorEl={anchorElEvaluations}
            keepMounted
            open={Boolean(anchorElEvaluations)}
            onClose={handleCloseEvaluations}
          >
            <MenuItem
              onClick={() => {
                setCategorySelected(categoryOptions().find((a) => a.id == 1));
                openTemplates(1);
              }}
            >
              Evaluation Templates
            </MenuItem>

            <Button
              disabled={
                !userCanSend ||
                !contact ||
                (contact && contact.user_id !== authUser.id)
              }
              style={{
                width: "100%",
                fontWeight: 400,
                color:
                  !userCanSend ||
                  !contact ||
                  (contact && contact.user_id !== authUser.id)
                    ? "gray"
                    : "black",
              }}
              onClick={handleClickLanguageTypeForm}
            >
              Evaluation Links <KeyboardArrowRightIcon />
            </Button>
          </Menu>

          <Grid item xs={contact && contact.portal_link_name ? 8 : 9}>
            <Controller
              name="body"
              control={control}
              defaultValue=""
              as={
                <TextField
                  rows={1}
                  rowsMax={2}
                  required
                  multiline
                  fullWidth
                  variant={"outlined"}
                  label="Type a message"
                  disabled={!userCanSend}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                  inputProps={{ minLength: 1, maxLength: 1999 }}
                  onKeyDown={(event) => {
                    var string = watchComment.replace("/n", " ");
                    string = string.trim();
                    if (string != "") {
                      handleKeyDown(event);
                    }
                  }}
                  style={{ backgroundColor: "#ffffff" }}
                />
              }
            />{" "}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={1}
            direction="row-reverse"
          >
            <Controls.IconSubmitButton
              disabled={
                !userCanSend || (userCanSend && contact && !contact.phone)
                  ? true
                  : false
              }
            >
              <SendIcon style={{ color: blue[700] }} />
            </Controls.IconSubmitButton>
          </Grid>
        </Grid>
      </form>

      <Popup
        title="Templates"
        fullWidth={true}
        openPopup={isOpenDialogTemplates}
        onClose={closeTemplates}
      >
        <TemplatesSMSMMS
          authUser={authUser}
          send_unsuscribe_link={send_unsuscribe_link}
          contactForEdit={contact}
          category={categorySelected}
          setTemplates={setTemplates}
          sendSmsMms={sendSmsMms}
          templates={templates}
          setIsOpenDialogTemplates={setIsOpenDialogTemplates}
        />
      </Popup>
    </>
  );
}
