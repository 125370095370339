import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export default function Notification2(props) {
  const { notify, setNotify, autoHide = 3000, preventAutoHide = false } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };
  return (
    <Snackbar
      open={notify.isOpen}
      autoHideDuration={preventAutoHide ? 0 : autoHide}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert
        severity={notify.type}
        onClose={handleClose}
        style={{ display: "flex", alignItems: "center" }}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
}
