import Api from "../lib/api";
import ViewLogsTable from "../components/logs/ViewLogsTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function QuoteItemsLogs(props) {
  const { quoteID } = props;
  const [loadingItems, setLoadingItems] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [quoteItems, setQuoteItems] = useState([]);
  const [openedItems, setOpenedItems] = useState([]);

  const getQuoteItems = async () => {
    if (quoteID) {
      setLoadingItems(true);
      const { data } = await Api.getQuoteItemsForQuoteLogs(quoteID);
      setQuoteItems([...data]);
      setLoadingItems(false);
    } else {
      setQuoteItems([]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    getQuoteItems();
  }, [quoteID]);

  const manageOpenItems = (id) => {
    if (openedItems.find((e) => e == id)) {
      setOpenedItems([...openedItems.filter((e) => e != id)]);
    } else {
      setOpenedItems([...openedItems, id]);
    }
  };
  return (
    <div>
      {loadingItems ? <LinearProgress /> : ""}
      {quoteItems.map((item) => {
        return (
          <Accordion>
            <AccordionSummary
              onClick={() => {
                manageOpenItems(item.id);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                {item.item_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                {" "}
                {openedItems.find((e) => e == item.id) ? (
                  <ViewLogsTable
                    rowsQty={5}
                    modelId={item.id}
                    getLogs={Api.getQuoteItemActivityLogs}
                  />
                ) : (
                  ""
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
