import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  groups: [],
};

const GroupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    getGroups: (state) => {
      state.loading = true;
    },
    getGroupsSuccess: (state, { payload }) => {
      state.groups = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getGroupsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createGroup: (state) => {},
    createGroupSuccess: (state, { payload }) => {
      state.groups = [...state.groups, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createGroupFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateGroup: (state) => {},
    updateGroupSuccess: (state, { payload }) => {
      const index = findIndex(state.groups, ["id", payload.data.id]);
      const user = { ...state.groups[index], ...payload.data };

      let groups = [...state.groups];
      groups[index] = user;

      state.groups = [...groups];
      state.loading = false;
      state.errors = [];
    },
    updateGroupFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteGroup: (state) => {},
    deleteGroupSuccess: (state, { payload }) => {
      state.groups = state.groups.filter((group) => {
        return group.id !== payload;
      });
    },
    deleteGroupFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getGroups,
  getGroupsSuccess,
  getGroupsFailure,
  createGroup,
  createGroupSuccess,
  createGroupFailure,
  updateGroup,
  updateGroupSuccess,
  updateGroupFailure,
  deleteGroup,
  deleteGroupSuccess,
  deleteGroupFailure,
} = GroupsSlice.actions;
export const GroupsSelector = (state) => state.groups;
export default GroupsSlice.reducer;

export function fetchGroupsService(orgId) {
  return async (dispatch) => {
    dispatch(getGroups());

    try {
      const response = await Api.getGroups(orgId);

      dispatch(getGroupsSuccess(response.data));
    } catch (error) {
      dispatch(getGroupsFailure(error));
    }
  };
}

export function createGroupService(payload) {
  return async (dispatch) => {
    dispatch(createGroup());

    try {
      const response = await Api.saveGroup(payload);

      dispatch(createGroupSuccess(response.data));
    } catch (error) {
      dispatch(createGroupFailure(error));
    }
  };
}

export function updateGroupService(id, payload) {
  return async (dispatch) => {
    dispatch(updateGroup());

    try {
      const response = await Api.updateGroup(id, payload);

      dispatch(updateGroupSuccess(response.data));
    } catch (error) {
      dispatch(updateGroupFailure(error));
    }
  };
}

export function deleteGroupService(id) {
  console.log("deleteGroupService ", id);
  return async (dispatch) => {
    dispatch(deleteGroup());

    try {
      const response = await Api.deleteGroup(id);

      dispatch(deleteGroupSuccess(id));
    } catch (error) {
      dispatch(deleteGroupFailure(error));
    }
  };
}
