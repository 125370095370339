import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  textfield: {
    borderRadius: 10,
    maxWidth: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

export default function MultipleSelect(props) {
  const {
    label,
    onChange,
    value,
    options,
    reset = null,
    setClean = null,
    styleProp = null,
    changeValue = false,
    ...other
  } = props;
  const [values, setValues] = useState(value);
  const classes = useStyles();

  const updateValues = (e) => {
    const values = e.target.value;
    setValues(values);
    onChange(values);
  };

  if (reset && reset == 1) {
    if (values.length > 0) setValues([]);
    if (setClean) setClean(0);
  }

  useEffect(() => {
    if (changeValue) {
      setValues(value);
      onChange(value)
    }
  }, [value]);

  return (
    <FormControl style={{ width: styleProp ? "100%" : "" }} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={values}
        onChange={updateValues}
        multiple
        {...other}
        className={classes.textfield}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
