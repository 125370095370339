import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PerfectScrollbar from "react-perfect-scrollbar";
import DOMPurify from "dompurify";
import UndoIcon from "@material-ui/icons/Undo";
import { CloudDownload as CloudDownloadIcon } from "@material-ui/icons";
import { saveAs } from "file-saver";

const EmailModal = ({ open, onClose, email, onReply }) => {
  const loading = email == null || email.loading == true;
  const nullemail = email == null;
  const hasTextbody = email?.body?.text ? true : false;
  const hasHTMLbody = email?.body?.html ? true : false;

  const formatFileSize = (bytes, decimalPoint = 2) => {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: { borderRadius: 18, minHeight: "70vh" },
      }}
    >
      {!nullemail && (
        <>
          <DialogTitle>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
              >
                {email?.subject || "(no subject)"}
              </Typography>
              <IconButton color="secondary" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers style={{ flexShrink: 0 }}>
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <div>
                  <Typography display="inline" variant="subtitle1">
                    From:
                  </Typography>{" "}
                  <Typography display="inline" variant="body2">
                    {email.from}
                  </Typography>
                </div>
                <div>
                  <Typography display="inline" variant="subtitle1">
                    To:
                  </Typography>{" "}
                  <Typography display="inline" variant="body2">
                    {email.to}
                  </Typography>
                </div>
                <div>
                  <Typography display="inline" variant="subtitle1">
                    Date:
                  </Typography>{" "}
                  <Typography display="inline" variant="body2">
                    {email.date}
                  </Typography>
                </div>
              </Box>
              {email?.has_attachment && (
                <Box display="flex" flexDirection="column">
                  {email.attachments?.data?.map((at) => (
                    <Button
                      key={at.download_url}
                      startIcon={<CloudDownloadIcon />}
                      onClick={() => saveAs(at.download_url, at.name)}
                    >
                      {at.name} ({formatFileSize(at.size)})
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogContent dividers>
            {loading ? (
              <Box
                style={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </Box>
            ) : hasHTMLbody ? (
              <PerfectScrollbar>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(email.body.html),
                  }}
                />
              </PerfectScrollbar>
            ) : hasTextbody ? (
              <PerfectScrollbar>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(email.body.text),
                  }}
                />
              </PerfectScrollbar>
            ) : null}
          </DialogContent>
          <DialogActions style={{ margin: 10, padding: 10 }}>
            <Button
              onClick={onReply}
              key={"Reply"}
              variant="outlined"
              startIcon={<UndoIcon />}
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "140px",
                marginRight: 15,
              }}
              disabled={loading}
            >
              Reply
            </Button>

            {/* <Button
              onClick={() => alert("not implemented")}
              key={"Forward"}
              variant="outlined"
              endIcon={<RedoIcon />}
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "140px",
                marginRight: 15,
              }}
              disabled={loading}
            >
              Forward
            </Button> */}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EmailModal;
