import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import DocumentForm from "./forms/DocumentForm";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import Api from "../lib/api";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Fab,
  LinearProgress,
  Box,
  Typography,
} from "@material-ui/core";
import Popup from "./Popup";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CRMUtils from "../utils/index.js";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "100px",
  },
}));
function RecordProductDocuments(props) {
  const { authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation(["common", "records"]);

  const {
    record_id,
    documentTypes,
    recordProductForEdit,
    updateRecordProductData,
    // onSave,
    //onDeleteDocument,
    showDelete,
    coordinatorsFromDoctor,
    canAdd = true,
  } = props;

  const headCells = [
    { id: "selected", label: "", disableSorting: true, filter: false },
    { id: "type", label: t("common:general.Type") },
    { id: "name", label: t("common:general.Name") },
    { id: "file", label: t("common:general.File") },
    { id: "mime", label: t("common:general.Mime") },
    {
      id: "actions",
      label: t("common:general.Actions"),
      disableSorting: true,
      align: "right",
      isVisible: showDelete,
    },
  ];
  const [progressBar, setProgressBar] = useState(false);
  const [documentsrp, setDocuments] = useState([]);
  const [documentstoshow, setdocumentstoshow] = useState([]);
  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  const onSave = async (payload) => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.createProductRecordDocument(
      recordProductForEdit.id,
      formData
    );

    // const newRecord = {
    //   ...recordProductForEdit,
    //   documents: {
    //      ...recordProductForEdit.documents,
    //    data: [...recordProductForEdit.documents.data, data.data],
    //  },
    //  };
    setDocuments([data.data, ...documentsrp]);
    // updateRecordProductData(newRecord);
  };

  const onDeleteDocument = async (orgId, id) => {
    const { data } = await Api.deleteDocument(orgId, id);

    //let filteredArray = recordProductForEdit.documents.data.filter(
    //  (item) => item.id !== id
    //);

    setDocuments(documentsrp.filter((item) => item.id != id));
    // const newRecord = {
    //   ...recordProductForEdit,
    //   documents: {
    //     data: filteredArray,
    //  },
    //};

    // updateRecordProductData(newRecord);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(documentstoshow, headCells, filterFn);

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onAdvanceFilter = () => {
    console.log("filter");
  };

  const onMassiveDelete = async () => {
    console.log("delete");
  };

  const onDelete = async (id) => {
    const orgId = authUser.organization_id;
    onDeleteDocument(orgId, id);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: t("common:general.Del_Doc_ok"),
      type: "error",
    });
  };

  useEffect(() => {
    setdocumentstoshow(documentsrp);
  }, [documentsrp]);

  const saveDocument = (data) => {
    onSave(data);
    onClose();
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (record_id) {
      setProgressBar(true);
      getDocumments().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getDocumments = async () => {
    const { data } = await Api.getDocumentsRP(record_id);
    setDocuments([...data.data]);
  };

  const canUserViewTheDocument = (documentType = "", documentID = null) => {
    if (documentType && documentID) {
      var cleanName = documentType
        .replaceAll(" ", "")
        .replaceAll("_", "")
        .toLowerCase();

      return CRMUtils.userHasPermission(authUser, [
        "doctypesview_" + cleanName + "_" + documentID,
      ]);
    } else {
      return true;
    }
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        {canAdd && (
          <Fab
            color="primary"
            aria-label={t("common:general.AD")}
            size="small"
            onClick={() => {
              setOpenPopup(true);
            }}
            className={classes.newButton}
            disabled={!coordinatorsFromDoctor}
          >
            <AddIcon />
          </Fab>
        )}
        {/* <EnhancedTableToolbar
          label={t("common:general.Documents")}
          numSelected={selected.length}
          advanceFilter={onAdvanceFilter}
          onMassiveDelete={onMassiveDelete}
        /> */}
        <Box style={{ marginTop: 30 }}>
          <Typography variant="h6" id="tableTitle" style={{ marginBottom: 15 }}>
            {t("common:general.Documents")}
          </Typography>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => {
                const isItemSelected = isSelected(item.id);
                return (
                  <TableRow key={item.id} role="checkbox">
                    <TableCell padding="checkbox" align="left">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          handleClick(event, item.id);
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.collection_name}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {!isEmpty(item.url) &&
                        (canUserViewTheDocument(
                          item.doc_type_name,
                          item.doc_type_id
                        ) ? (
                          <a href={item.url} target="_blank" rel="noreferrer">
                            <AttachFileIcon />
                          </a>
                        ) : (
                          <AttachFileIcon style={{ color: "#999999" }} />
                        ))}
                    </TableCell>

                    <TableCell>{item.mime_type}</TableCell>
                    {showDelete ? (
                      <TableCell align="right">
                        <Controls.IconButton
                          color="secondary"
                          disabled={!showDelete || !coordinatorsFromDoctor}
                        >
                          <CloseIcon
                            fontSize="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setConfirmDialog({
                                isOpen: true,
                                title: t("common:general.Del_Documents"),
                                subTitle: t("common:general.OP"),
                                onConfirm: () => onDelete(item.id),
                              });
                            }}
                          />
                        </Controls.IconButton>
                      </TableCell>
                    ) : (
                      <TableCell align="right"></TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>

          {progressBar && (
            <div>
              <div style={{ padding: 10, textAlign: "center" }}>
                No records to display
              </div>
              <LinearProgress />
            </div>
          )}
          <TblPagination />
        </Box>
      </Paper>
      <Popup
        title={t("common:general.AD")}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <DocumentForm
          saveDocument={saveDocument}
          documentTypes={documentTypes}
          className={classes.form}
        />
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RecordProductDocuments;
