import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import HeadersSidebar from "./HeadersSidebar";
import ConversationPanel from "./ConversationPanel";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Controls from "../controls";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import { debounce, isEmpty } from "lodash";
import { Tip } from "../controls/Tip";
import ScrollBar from "react-perfect-scrollbar";
import { Controller, useForm } from "react-hook-form";
import Picker from "emoji-picker-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntitiesService as fetchGroupsDropdownService } from "../../redux/slices/groupsDropdownSlice.js";

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
  resultsTitle: {
    margin: 14,
    fontWeight: 500,
    marginBottom: 0,
    borderBottom: "1px solid #80808036",
    paddingBottom: 10,
  },
  memberHeader: {
    marginBottom: 12,
    borderRadius: 8,
    backgroundColor: "#efefef",
    display: "flex",
    alignItems: "center",
  },
}));

var dataSliceGroupsDropdown = {
  stateListName: "groupsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },

  dataDelete: {
    id: null,
    successHandle: null,
  },

  errorHandle: null,
};

const JoinGroup = (props) => {
  const { joinGroup, onClose } = props;
  const [isJoining, setIsJoining] = useState(null);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [groups, setGroups] = useState([]);

  const getGroups = async () => {
    setLoadingGroups(true);
    const { data } = await Api.getGroupsAvailableToJoin();
    setGroups([...data.data]);
    setLoadingGroups(false);
  };

  useEffect(() => {
    getGroups();
  }, []);

  const handleJoinGroup = async (group) => {
    setIsJoining(group);
    await joinGroup(group);
    setIsJoining(null);
    onClose();
  };

  return (
    <div>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 600,
          borderBottom: "1px solid #b3b4c54d",
          paddingBottom: 6,
        }}
      >
        Groups
      </Typography>
      {!loadingGroups && groups.length == 0 ? (
        <div style={{ textAlign: "center", paddingTop: "12px" }}>
          No results
        </div>
      ) : (
        ""
      )}
      <List>
        {loadingGroups ? <LinearProgress /> : ""}
        <ScrollBar style={{ maxHeight: 600 }}>
          {groups.map((group) => {
            return (
              <ListItem>
                <ListItemIcon>
                  <Avatar style={{ backgroundColor: "rgb(164 174 185 / 30%)" }}>
                    <Iconify
                      style={{ color: "#616a8d" }}
                      icon={"heroicons:user-group-20-solid"}
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ style: { fontWeight: 500 } }}
                  primary={group.name}
                  // secondary={user.department_name}
                />
                <ListItemSecondaryAction>
                  <Button
                    disabled={isJoining}
                    onClick={() => {
                      handleJoinGroup(group.id);
                    }}
                    style={{
                      backgroundColor: isJoining
                        ? "rgb(233 233 233)"
                        : "#d4e4f3",
                      color: isJoining ? "rgb(143 143 145)" : "#3c5291",
                      borderRadius: 25,
                    }}
                    endIcon={<Iconify icon={"iconamoon:arrow-right-2-light"} />}
                  >
                    {isJoining == group.id ? "Joining..." : "Join Chat"}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </ScrollBar>
      </List>
    </div>
  );
};

export default JoinGroup;
