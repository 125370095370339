import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";

import { useForm, Controller } from "react-hook-form";

import Controls from "./controls";
import { StateTransitionError } from "sip.js";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [url, seturl] = useState("");
  const [data, setData] = useState([]);
  const [progres, setProgres] = useState(50);
  //  const [isLoading, setIsLoading] = useState(props.isLoading);
  const { handleSubmit, control } = useForm();

  const {
    onSubmit,
    isLoading,
    setIsLoading,
    isFinishedUploading,
    isError,
    setIsError,
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitLocal = (data) => {
    setIsError(false);
    onSubmit(data, null);
    setIsLoading(true);
  };

  return (
    <Dialog
      open={props.openPopup}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      {!isLoading && !isFinishedUploading && (
        <form onSubmit={handleSubmit(onSubmitLocal)}>
          <DialogTitle id="form-dialog-title">Upload from URL</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter below the URL from where you want to upload the videos.
            </DialogContentText>
            <Controller
              name="url"
              control={control}
              defaultValue=""
              autoFocus
              margin="dense"
              id="url"
              label="url"
              type="url"
              fullWidth
              as={<Controls.Input label="url" />}
            />
            {isError && (
              <Alert severity="error">
                Not videos were founded at the provided URL
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Upload
            </Button>
          </DialogActions>
        </form>
      )}
      {isLoading && !isFinishedUploading && !isError && (
        <Card>
          <CardHeader
            title={"Extracting videos..."}
            avatar={
              <Avatar style={{ alignItems: "center" }}>
                <CloudUploadIcon color="primary" />
              </Avatar>
            }
          ></CardHeader>
          <CardContent
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              Please wait until the videos are completely extracted
            </Typography>
            <LinearProgress />
          </CardContent>
        </Card>
      )}
      {isFinishedUploading && !isError && (
        <Card>
          <CardHeader
            title={"Finished"}
            avatar={
              <Avatar style={{ alignItems: "center" }}>
                <CloudUploadIcon style={{ color: "green" }} />
              </Avatar>
            }
          ></CardHeader>
          <CardContent
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              The videos were succesfully extracted and will be uploaded!
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: "right" }}>
            <Button size="small" color="primary" onClick={props.onClose}>
              Continue
            </Button>
          </CardActions>
        </Card>
      )}
    </Dialog>
  );
}
