import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../lib/api";
import FavoriteIcon from "@material-ui/icons/Star";
import { IconButton } from "@material-ui/core";
import {
  addIsImportantService,
  changeIsImportantService,
} from "../redux/slices/userSMSMMSSlice.js";

export default function IsImportant(props) {
  const dispatch = useDispatch();
  const { item } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [isImportant, setIsImportant] = useState(
    props.item.smsMMSColorIsImportant == 1 ? true : false
  );

  const handleIsImportant = async (id) => {
    let user_id = item.user_id ? item.user_id : authUser.id;
    const payload = {
      phone: item.phone,
      color: item.color,
      contact_id: item.id,
      //status: isImportant ? 2 : 1,
      smsMMSColorIsImportant: isImportant ? false : true,
      user_id: user_id,
    };

    const { data } = await Api.handleIsImportant(id, payload);
    if (data && data[0] && data[0].id) {
      setIsImportant(isImportant ? false : true);
      dispatch(addIsImportantService(data[0]));
    } else {
      setIsImportant(isImportant ? false : true);
      dispatch(changeIsImportantService(payload));
    }
  };

  return (
    <IconButton
      aria-label="mark as important"
      onClick={() => {
        handleIsImportant(item.smsMMSColorId, !item.smsMMSColorIsImportant);
      }}
    >
      <FavoriteIcon
        style={{
          color: isImportant ? "#fcd15e" : "",
          marginLeft: "3px",
        }}
      />
    </IconButton>
  );
}
