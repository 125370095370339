import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import Api from "../../lib/api";
import { Tip } from "../controls/Tip";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function VoidInvoiceItemQuantityForm(props) {
  const { item, onClose = null, updateInvoice } = props;
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const quantityVoid = watch("void_quantity");
  const classes = useStyles();

  const onSubmit = async (payload) => {
    setIsSubmitting(true);
    const { data } = await Api.voidInvoiceItemQuantity(item.id, payload);
    setIsSubmitting(false);

    if (onClose && typeof onClose == "function") {
      onClose();
    }

    if (updateInvoice && typeof updateInvoice == "function") {
      updateInvoice({ ...data.data });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Typography
        style={{ padding: 2, marginLeft: 5, borderBottom: "1px solid #e7e7e7" }}
      >
        <strong
          style={{
            fontWeight: 600,
            color: "rgb(79 79 79)",
          }}
        >
          {" "}
          Item total quantity:
        </strong>
        {" " + item?.quantity || "-"}
      </Typography>
      <Typography
        style={{
          padding: 2,
          marginLeft: 5,
          marginBottom: 12,
          borderBottom: "1px solid #e7e7e7",
        }}
      >
        <strong
          style={{
            fontWeight: 600,
            color: "rgb(149 64 64)",
          }}
        >
          {" "}
          Current void quantity:
        </strong>
        {" " + item?.void_quantity || "-"}
      </Typography>

      <Grid container spacing="2" style={{ alignItems: "center" }}>
        <Grid item xs={12}>
          <Controller
            name="void_quantity"
            fullWidth={true}
            control={control}
            defaultValue={item?.void_quantity || 0}
            as={
              <Controls.Input
                type="number"
                label="Edit current void quantity"
                defaultValue={item?.void_quantity || 0}
                inputProps={{
                  min: 0,
                  max: item?.available_quantity_to_void || 0,
                }}
              />
            }
          />
        </Grid>
      </Grid>

      <Tip
        title={``}
        message={`The void quantity will be adjusted to ${
          quantityVoid === undefined ? item?.void_quantity || 0 : quantityVoid
        }`}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: 4,
        }}
      >
        <Controls.Button
          color="default"
          disabled={isSubmitting}
          text={"Cancel"}
          onClose={() => {
            if (onClose && typeof onClose == "function") {
              onClose();
            }
          }}
        />
        <Controls.Button
          disabled={isSubmitting}
          type="submit"
          text={isSubmitting ? "Submitting..." : "Submit"}
        />
      </div>
    </form>
  );
}
