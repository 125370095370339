import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  records: [],
  fields: [],
};

const recordsSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    getRecords: (state) => {
      state.loading = true;
    },
    getRecordsSuccess: (state, { payload }) => {
      state.records = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getRecordsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateRecord: (state) => {},
    updateRecordsuccess: (state, { payload }) => {
      const index = findIndex(state.records, ["id", payload.data.id]);
      const record = { ...state.records[index], ...payload.data };

      let records = [...state.records];
      records[index] = record;

      state.records = [...records];
      state.loading = false;
      state.errors = [];
    },
    updateRecordFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    getCustomFields: (state) => {
      //state.loading = true;
    },
    getCustomFieldsSuccess: (state, { payload }) => {
      state.fields = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCustomFieldsFailure: (state, { payload }) => {
      state.loading = false;
      state.fields = [];
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getRecords,
  getRecordsSuccess,
  getRecordsFailure,
  updateRecord,
  updateRecordsuccess,
  updateRecordFailure,
  getCustomFields,
  getCustomFieldsSuccess,
  getCustomFieldsFailure,
} = recordsSlice.actions;
export const recordsSelector = (state) => state.records;
export default recordsSlice.reducer;

export function fetchRecordsService(orgId) {
  return async (dispatch) => {
    dispatch(getRecords());

    try {
      const response = await Api.getRecords(orgId);

      dispatch(getRecordsSuccess(response.data));
    } catch (error) {
      dispatch(getRecordsFailure(error));
    }
  };
}

export function updateRecordService(id, payload) {
  return async (dispatch) => {
    dispatch(updateRecord());

    try {
      const response = await Api.updateRecord(id, payload);

      dispatch(updateRecordsuccess(response.data));
    } catch (error) {
      dispatch(updateRecordFailure(error));
    }
  };
}

export function updateRecord2(payload) {

  return async (dispatch) => {
    dispatch(updateRecordsuccess(payload));
  };
}

export function fetchCustomFieldsService(orgId) {
  return async (dispatch) => {
    dispatch(getCustomFields());

    try {
      const response = await Api.getRecordCustomFields(orgId);
      dispatch(getCustomFieldsSuccess(response.data));
    } catch (error) {
      dispatch(getCustomFieldsFailure(error));
    }
  };
}
