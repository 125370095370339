import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import RecordProductCForm from "./RecordProductCForm";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ConfirmDialog from "../ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "../useTable";
import Controls from "../controls";
import CRMUtils from "../../utils";
import MaterialTable from "material-table";
import { Paper, Fab } from "@material-ui/core";
import Popup from "../Popup";

import { useTranslation } from "react-i18next";
import Api from "../../lib/api";
import Notification from "../Notification";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));
function RecordProductCostsForm2(props) {
  const { authUser } = useSelector((state) => state.auth);

  const classes = useStyles();
  //const { usersDropdown } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation(["common", "records"]);

  const {
   // items,
    record_id,
    itemstoAdd,
    updateRecordProductDataCost,
   // onDeleteItem,
    recordProductForEdit,
    coordinatorsFromDoctor,
  } = props;

  


  const headCellsMaterial = [
    { field: "name", title: "Name" },
    { field: "amount", title: "Cost" },
    {
      field: "payroll_cost",
      title: "Payroll Cost",
      render: (rowData) => {
        return rowData.payroll_cost == 1 ? "Yes" : "No";
      },
    },
  ];

  const [progressBar, setProgressBar] = useState(false);
  const [items, setItems] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [noAdd, setNoAdd] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [itemForEdit, setItemForEdit] = useState(null);
  const { TblContainer } = useTable();
  const userCanEditCost = CRMUtils.userHasPermission(authUser, [
    "recordproduct_editCost",
  ]);
  const openInPopup = () => {
    setItemForEdit(null);
    setOpenPopup(true);
  };

  const onEditItem = (item) => {
    setItemForEdit(item);
    setOpenPopup(true);
  };

  const onClose = () => {
    setItemForEdit(null);
    setOpenPopup(false);
  };

  const onDelete = async (id) => {
    onDeleteItem(id);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: t("common:general.Del_Doc_ok"),
      type: "error",
    });
  };

  const createRP = (data) => {
    saveRecordProductCosts(data);
    setNotify({
      isOpen: true,
      message: "Created Successfully",
      type: "success",
    });
    onClose();
  };

  const UpdateRP = (data) => {
    updateRecordProductCosts(data);
    setNotify({
      isOpen: true,
      message: "Updated Successfully",
      type: "success",
    });
    onClose();
  };

  useEffect(() => {
    /*if (isEmpty(usersDropdown))
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));*/
  }, []);


  const updateRecordProductCosts = async (payload) => {
    payload.cost = JSON.stringify(payload);

    const { data } = await Api.updateRecordProductsCosts(
      recordProductForEdit.id,
      payload
    );



    setItems([...data.data]);

 //   updateRecordProductDataCost(recordProductForEdit.id, data.data, null);
  };

  const saveRecordProductCosts = async (payload) => {
    payload.cost = JSON.stringify(payload);
    const { data } = await Api.addRecordProductsCosts(
      recordProductForEdit.id,
      payload
    );
    setItems([...data.data]);
 //   updateRecordProductDataCost(recordProductForEdit.id, data.data, null);
  };

  const onDeleteItem = async (deleted_id) => {
    const { data } = await Api.deleteRecordProductsCosts(
      recordProductForEdit.id,
      deleted_id
    );
    
    setItems(items.filter(a => a.id != deleted_id))
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    //updateRecordProductDataCost(recordProductForEdit.id, null, deleted_id);
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (record_id) {
      setProgressBar(true);
      getItems().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getItems = async () => {
    const { data } = await Api.getRecordProductCosts(record_id);
    setItems([...data.data]);
  };

  const dataMaterialTable = items? items.map((o) => ({ ...o })): [];
  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading = {progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                ),
                tooltip: "Add Item",
                disabled: !coordinatorsFromDoctor,
                hidden: !coordinatorsFromDoctor,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton color="primary">
                    <EditOutlinedIcon fontSize="small" />
                  </Controls.IconButton>
                ),
                tooltip: "Edit Cost",
                disabled: !userCanEditCost || !coordinatorsFromDoctor,
                hidden: !userCanEditCost || !coordinatorsFromDoctor,
                onClick: (event, rowData) => {
                  onEditItem(rowData);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton color="secondary">
                    <CloseIcon fontSize="small" />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                disabled: !userCanEditCost || !coordinatorsFromDoctor,
                hidden: !userCanEditCost || !coordinatorsFromDoctor,
                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this item?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.id),
                  }),
              }),
            ]}
            options={{
              loadingType: 'linear',
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              search: false,
            }}
            title="Costs"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Add Item"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <RecordProductCForm
          saveRP={createRP}
          UpdateRP={UpdateRP}
          itemstoAdd={itemstoAdd}
          className={classes.form}
          items={items}
          item={itemForEdit}
          userCanEditCost={userCanEditCost}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RecordProductCostsForm2;
