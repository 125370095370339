import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import useTable from "./useTable";
import Controls from "./controls";
import moment from "moment";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import {
  Fab,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Popup from "./Popup";
import Api from "../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, isEmpty } from "lodash";
import { fetchEntitiesService as fetchAppointmentTypesService } from "../redux/slices/appointmentTypesSlice";
import AppointmentNewForm from "./forms/AppointmentNewForm";
import Iconify from "./Iconify";
import CRMUtils from "../utils";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  iconInvoice: {
    width: "30px",
    height: "19px",
    color: "#0091f1",
  },
  invoiceItemText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
  },
  invoiceItemProp: {
    display: "flex",
    alignItems: "center",
    padding: 5,
  },
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));

var dataSliceAppointmentTypes = {
  stateListName: "appointmentTypes",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 50,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function AppointmentsContacts(props) {
  const [popupWidth, setPopupWidth] = useState("md");
  const [openInvoiceItem, setOpenInvoiceItem] = useState(false);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(null);
  const { appointmentTypes } = useSelector((state) => state.appointmentTypes);
  const { usersDropdown } = useSelector((state) => state.users);
  const [appointmentForEdit, setAppointmentForEdit] = useState(null);
  const [social_Platforms, setSocialPlatform] = useState([]);
  const [editPopup, setEditPopup] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const classes = useStyles();
  const { socialPlatforms } = useSelector((state) => state.socialPlatforms);
  const {
    contact,
    buildings,
    record_phoneAreaCode = null,
    consultantErrorHandle = null,
  } = props;
  const dispatch = useDispatch();
  const userCanEditAppointment = CRMUtils.userHasPermission(authUser, [
    "appointmentsnew_editappointments",
  ]);
  const userCanCreateAppointment = CRMUtils.userHasPermission(authUser, [
    "appointmentsnew_createappointments",
  ]);
  const userCanDeleteAppointment = CRMUtils.userHasPermission(authUser, [
    "appointmentsnew_deleteappointments",
  ]);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);
  const [progressBar, setProgressBar] = useState(false);

  const getTimze = () => {
    if (record_phoneAreaCode) {
      console.log("AADDASsasas", record_phoneAreaCode);
      const time = moment()
        .tz(record_phoneAreaCode)
        .format("MM-DD-YYYY hh:mm A");

      if (time) {
        return (
          <Typography
            variant="subtitle2"
            style={{
              color: "#009688",
              fontFamily:
                '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            {`Current Time for This Patient: ${time}`}{" "}
          </Typography>
        );
      }
    }

    return (
      <Typography
        variant="subtitle2"
        style={{
          fontStyle: "italic",
          color: "#f44336",
          fontFamily:
            '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        Please ensure the phone is already entered and formatted correctly to
        check the current time for this patient.
      </Typography>
    );
  };

  const headCellsMaterial = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "date_of_appointment",
      title: "Date",
      render: (rowData) => {
        return rowData.date_of_appointment
          ? `${moment(rowData.date_of_appointment).format(
              "YYYY-MM-DD"
            )} at ${moment(rowData.date_of_appointment).format("hh:mm A")}`
          : "";
      },
    },
    {
      field: "owner",
      title: "Assigned to",
      render: (rowData) => {
        return rowData.owner ? rowData.owner.data.users_fname : "";
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <div
            style={{
              maxWidth: "fit-content",
              fontWeight: 600,
              textAlign: "center",
              borderRadius: 14,
              fontSize: 11.5,
              padding: "6px 10px",
              backgroundColor:
                rowData.status == "pending_to_confirm"
                  ? "rgb(249 239 255)"
                  : rowData.status == "pending_to_schedule"
                  ? "rgb(253 255 181)"
                  : rowData.status == "registered"
                  ? "rgb(189 230 254)"
                  : rowData.status == "canceled"
                  ? "rgb(255 215 215)"
                  : rowData.status == "pending_to_reschedule"
                  ? "rgb(255 237 207)"
                  : rowData.status == "confirmed"
                  ? "rgb(219 252 189)"
                  : rowData.status == "requested_call"
                  ? "rgb(204 252 253)"
                  : rowData.status == "checked"
                  ? "rgb(229 230 231)"
                  : "rgb(188 254 214)",
              color:
                rowData.status == "pending_to_confirm"
                  ? "rgb(150 107 182)"
                  : rowData.status == "pending_to_schedule"
                  ? "rgb(174 155 0)"
                  : rowData.status == "registered"
                  ? "#0072ff"
                  : rowData.status == "canceled"
                  ? "#d00404"
                  : rowData.status == "pending_to_reschedule"
                  ? "rgb(184 102 4)"
                  : rowData.status == "confirmed"
                  ? "rgb(80 130 34)"
                  : rowData.status == "requested_call"
                  ? "rgb(5 155 186)"
                  : rowData.status == "checked"
                  ? "rgb(114 119 122)"
                  : "rgb(49 172 98)",
            }}
          >
            {rowData.status.toUpperCase().replaceAll("_", " ")}
          </div>
        );
      },
    },
    {
      field: "time_of_arrival",
      title: "Checked at",
      render: (rowData) => {
        return rowData.time_of_arrival
          ? `${moment(rowData.time_of_arrival).format(
              "YYYY-MM-DD"
            )} at ${moment(rowData.time_of_arrival).format("hh:mm A")}`
          : "";
      },
    },
    {
      field: "type",
      title: "Type",
      render: (rowData) => {
        return rowData.type ? rowData.type.data.name : "";
      },
    },

    {
      field: "duration",
      title: "Duration",
      render: (rowData) => {
        return rowData.duration ? `${rowData.duration} min` : "";
      },
    },

    {
      field: "responsibles",
      title: "Responsibles",
      render: (rowData) => {
        return rowData.responsibles && rowData.responsibles.data.length > 0
          ? rowData.responsibles.data.map((item) => {
              return (
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon
                    style={{
                      margin: 5,
                      minWidth: "fit-content",
                      color: "#0e3b6a",
                    }}
                  >
                    <PersonIcon />
                  </ListItemIcon>

                  <ListItemText primary={item.user.data.users_fname} />
                </ListItem>
              );
            })
          : "";
      },
    },

    {
      field: "invoice",
      title: "From Invoice Item",
      render: (rowData) => {
        return rowData.invoiceitem &&
          rowData.invoice_item_id &&
          rowData.invoiceitem.data ? (
          <Button
            style={{
              backgroundColor: "#e5faff",
              borderRadius: "20px",
              border: "1px solid #b3eeff",
              padding: "0px",
              margin: "10px",
              color: "#406d77",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedInvoiceItem(rowData.invoiceitem?.data);
              setOpenInvoiceItem(true);
            }}
          >
            {rowData.invoiceitem?.data.name}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      field: "createdBy",
      title: "Created by",
    },
    {
      field: "building",
      title: "Building",
      render: (rowData) => {
        return rowData.building ? rowData.building.data.name : "";
      },
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const deleteAppointment = async (id) => {
    try {
      await Api.deleteNewAppointment(id);

      setAppointments(appointments.filter((a) => a.id != id));
      setAppointmentForEdit(null);
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      setEditPopup(false);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const closeInvoiceItem = () => {
    setOpenInvoiceItem(false);
    setSelectedInvoiceItem(null);
  };

  const onClickDelete = (id) => {
    openConfirmDialog(
      setConfirmDialog,
      deleteAppointment(id),
      id,
      "Are you sure to delete this appointment?"
    );
  };

  const openConfirmDialog = (
    setConfirmDialogObj,
    onConfirmHandle,
    onConfirmParam,
    title,
    subtitle = null
  ) => {
    setConfirmDialogObj({
      isOpen: true,
      title: title,
      subTitle: subtitle ? subtitle : "You can't undo this operation",
      onConfirm: () => onConfirmHandle(onConfirmParam),
    });
  };

  const addOrEdit = async (payload, file, isAdd) => {
    try {
      if (appointmentForEdit || payload.entity_to_complete_id) {
        const { data } = await Api.updateNewAppointment(
          appointmentForEdit
            ? appointmentForEdit.id
            : payload.entity_to_complete_id,
          payload
        );

        if (file) {
          Api.attachToAppointment(
            appointmentForEdit
              ? appointmentForEdit.id
              : payload.entity_to_complete_id,
            file
          );
        }

        const index = findIndex(appointments, ["id", data.data.id]);
        const updatedEvent = {
          ...appointments[index],
          ...data.data,
        };

        let newappointments = [...appointments];
        newappointments[index] = updatedEvent;
        setAppointments([...newappointments]);
        setAppointmentForEdit(null);
        setEditPopup(false);
        setConfirmDialog({
          isOpen: false,
          title: "",
          subTitle: "",
        });
      } else {
        const { data } = await Api.createNewAppointment(payload);
        setAppointments([data.data, ...appointments]);
        setAppointmentForEdit(null);
        if (!isAdd) {
          setEditPopup(false);
        }
      }
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  useEffect(() => {
    if (contact) {
      setProgressBar(true);

      getAppointments().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact]);

  useEffect(() => {
    if (isEmpty(usersDropdown)) {
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
    }
    if (isEmpty(appointmentTypes)) {
      dispatch(fetchAppointmentTypesService(dataSliceAppointmentTypes));
    }
  }, []);

  const getAppointments = async () => {
    try {
      const { data } = await Api.getContactAppointments(contact.id);

      setAppointments(data.data);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const onClosePopup = () => {
    setEditPopup(false);
    setAppointmentForEdit(null);
  };

  const dataMaterialTable = appointments
    ? appointments.map((o) => ({ ...o }))
    : [];

  const renderInvoiceItem = (invoiceItem) => {
    if (invoiceItem) {
      return (
        <div
          style={{
            backgroundColor: "#f9f9f9",
            margin: 8,
            padding: 10,
            borderRadius: 14,
          }}
        >
          <div
            style={{
              textAlign: "center",
              padding: 7,
              backgroundColor: "#e0f1ff",
              borderRadius: "12px",
              margin: "4px",
            }}
          >
            <p className={classes.invoiceItemText}>{` ${invoiceItem.name}`}</p>
          </div>

          <div className={classes.invoiceItemProp}>
            <Iconify
              className={classes.iconInvoice}
              icon={"fa-solid:file-invoice-dollar"}
            />
            <p className={classes.invoiceItemText}>From Invoice </p>{" "}
            <p
              className={classes.invoiceItemText}
            >{`: ${invoiceItem.invoice_name}`}</p>
          </div>
          {invoiceItem.is_procedure == true ? (
            <div className={classes.invoiceItemProp}>
              <Iconify className={classes.iconInvoice} icon={"vaadin:doctor"} />
              <p className={classes.invoiceItemText}>Doctor </p>{" "}
              <p
                className={classes.invoiceItemText}
              >{`: ${invoiceItem.responsible}`}</p>
            </div>
          ) : (
            ""
          )}
          <div className={classes.invoiceItemProp}>
            <Iconify
              className={classes.iconInvoice}
              icon={"ri:money-dollar-box-fill"}
            />
            <p className={classes.invoiceItemText}>Price </p>{" "}
            <p
              className={classes.invoiceItemText}
            >{`: $${invoiceItem.price}`}</p>
          </div>
          <div className={classes.invoiceItemProp}>
            <Iconify
              className={classes.iconInvoice}
              icon={"fa-solid:balance-scale"}
            />
            <p className={classes.invoiceItemText}>Balance </p>{" "}
            <p
              className={classes.invoiceItemText}
            >{`: $${invoiceItem.balance}`}</p>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  const getAppointment = async (id) => {
    try {
      const { data } = await Api.getAppointmentNew(id);
      setAppointmentForEdit({ ...data.data });
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            onRowClick={(e, rowData) => {
              getAppointment(rowData.id).then(() => {
                setEditPopup(true);
              });
            }}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <Iconify
                      icon={"fluent:add-12-filled"}
                      width={25}
                      height={25}
                    />
                  </Fab>
                ),
                hidden: !userCanCreateAppointment || userCanViewEditContactMark,
                tooltip: "Add Appointment",
                isFreeAction: true,
                onClick: (event) => {
                  setEditPopup(true);
                },
              },
              {
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        !userCanEditAppointment || userCanViewEditContactMark
                          ? "#bdbdbd"
                          : "#009688",
                    }}
                  >
                    <Iconify
                      icon={"fluent:edit-24-filled"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                hidden: !userCanEditAppointment || userCanViewEditContactMark,
                tooltip: "Edit Appointment",
                onClick: (event, rowData) => {
                  getAppointment(rowData.id).then(() => {
                    setEditPopup(true);
                  });
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        !userCanDeleteAppointment || userCanViewEditContactMark
                          ? "#bdbdbd"
                          : "#f44336",
                    }}
                  >
                    <Iconify
                      icon={"mingcute:delete-fill"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                hidden: !userCanDeleteAppointment || userCanViewEditContactMark,
                tooltip: "Delete Appointment",
                onClick: (event, rowData) => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this appointment?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onClickDelete(rowData.id),
                  });
                },
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Appointments"
          />
        </TblContainer>
      </Paper>
      <Popup
        titleObj={
          <>
            <Typography
              variant="h6"
              component="div"
              style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
            >
              {appointmentForEdit && appointmentForEdit.name
                ? "Appointment: " + appointmentForEdit.name
                : "Appointment"}
            </Typography>

            {record_phoneAreaCode &&
            typeof record_phoneAreaCode === "string" &&
            record_phoneAreaCode !== "" ? (
              getTimze(record_phoneAreaCode)
            ) : (
              <Typography
                variant="subtitle2"
                style={{
                  fontStyle: "italic",
                  color: "#f44336",
                  fontFamily:
                    '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                Please ensure the phone is already entered and formatted
                correctly to check the current time for this patient.
              </Typography>
            )}
          </>
        }
        fullWidth={true}
        openPopup={editPopup}
        onClose={onClosePopup}
        maxWidth={popupWidth}
      >
        <>
          <AppointmentNewForm
            setPopupWidth={setPopupWidth}
            contactProp={{
              contact_id: contact.id,
              search: contact.fname + " " + contact.lname,
            }}
            disabledContact={true}
            users={usersDropdown}
            appointmentTypes={appointmentTypes}
            buildings={buildings}
            appointmentNewForEdit={appointmentForEdit}
            addOrEdit={addOrEdit}
          />
        </>
      </Popup>

      <Popup
        fullWidth={true}
        title={"Invoice Item"}
        openPopup={openInvoiceItem}
        onClose={closeInvoiceItem}
      >
        {renderInvoiceItem(selectedInvoiceItem)}
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default AppointmentsContacts;
