import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TodayIcon from "@material-ui/icons/Today";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Chip } from "@material-ui/core";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
}));

export default function AppointmentTimeline(props) {
  const classes = useStyles();
  const { data } = props;

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";
  const action = data.action;

  const { t, i18n } = useTranslation(["common", "records"]);

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          {action} by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <TodayIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            Appointment
          </Typography>
          <Typography>
            {data.object.name}{" "}
            <Chip
              style={{
                border: "white",
                color: data
                  ? data.object.status == "pending_to_schedule"
                    ? "rgb(208 188 30)"
                    : data.object.status == "pending_to_confirm"
                    ? "rgb(150 107 182)"
                    : data.object.status == "registered"
                    ? "#0072ff"
                    : data.object.status == "canceled"
                    ? "#d00404"
                    : data.object.status == "pending_to_reschedule"
                    ? "rgb(184 102 4)"
                    : "rgb(49 172 98)"
                  : "",
                backgroundColor: data
                  ? data.object.status == "pending_to_schedule"
                    ? "rgb(253 255 181)"
                    : data.object.status == "pending_to_confirm"
                    ? "rgb(249 239 255)"
                    : data.object.status == "registered"
                    ? "rgb(223 243 255)"
                    : data.object.status == "canceled"
                    ? "rgb(255 215 215)"
                    : data.object.status == "pending_to_reschedule"
                    ? "rgb(255 237 207)"
                    : "rgb(188 254 214)"
                  : "",
              }}
              label={data.object.status}
              variant="outlined"
              size="small"
            />
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
