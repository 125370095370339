import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SocialPlatformForm from "./forms/SocialPlatformForm";
import AddIcon from "@material-ui/icons/Add";

import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";
import moment from "moment";
import { Paper, Checkbox, Fab } from "@material-ui/core";
import Popup from "./Popup";
import { fetchEntitiesService as fetchSocialPlatformsService } from "../redux/slices/socialplatformsSlice.js";
import Api from "../lib/api";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "./Iconify.js";

var dataSliceSocialPlatforms = {
  stateListName: "socialPlatforms",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: null,
    page: null,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));
function SocialPlatformsContacts(props) {
  const [social_Platforms, setSocialPlatform] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const classes = useStyles();
  const { socialPlatforms } = useSelector((state) => state.socialPlatforms);

  const {
    orgId,

    updateSocialPlatforms,
    createSocialPlatforms,
    deleteSocialPlatforms,
    contact_id,
    userTeamLeader,
    canAdd = true,
    consultantErrorHandle = null,
  } = props;
  const dispatch = useDispatch();
  const [socialPlatformForEdit, setSocialPlatformForEdit] = useState(null);

  const kFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  const [progressBar, setProgressBar] = useState(false);
  const headCellsMaterial = [
    {
      field: "name",
      title: "Platform",
      render: (rowData) => {
        return rowData.name;
      },
    },
    {
      field: "account_name",
      title: "Profile",
      render: (rowData) => {
        return rowData.pivot
          ? rowData.pivot.account_name
          : rowData.account_name;
      },
    },
    {
      field: "followers",
      title: "Followers",
      render: (rowData) => {
        return kFormatter(
          rowData.pivot ? rowData.pivot.followers : rowData.followers
        );
      },
    },
  ];

  const [openPopup, setOpenPopup] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    if (item) {
      setSocialPlatformForEdit(item);
    }

    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
    setSocialPlatformForEdit(null);
  };

  useEffect(() => {
    dispatch(fetchSocialPlatformsService(dataSliceSocialPlatforms));
  }, []);

  const onDelete = async (id) => {
    try {
      Api.deleteContactSocialPlatform(contact_id, id).then((result) => {
        deleteSocialPlatforms(result);

        setSocialPlatform(social_Platforms.filter((a) => a.pivot.id != id));
      });

      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "error",
      });
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const saveSocialPlatform = async (data) => {
    var payload = {
      account_name: data.account_name,
      followers: data.followers,
      contact_id: contact_id,
      socialplatform_id: data.socialplatform_id,
    };
    try {
      if (socialPlatformForEdit) {
        const { data } = await Api.updateContactSocialPlatform(
          contact_id,
          socialPlatformForEdit.pivot.id,
          payload
        ).then((result) => {
          updateSocialPlatforms(result);

          setSocialPlatform(result.data.data);
        });
      } else {
        const { data } = await Api.saveContactSocialPlatform(
          contact_id,
          payload
        ).then((result) => {
          createSocialPlatforms(result);
          setSocialPlatform(result.data.data);
        });
      }
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
    onClose();
  };

  useEffect(() => {
    if (contact_id) {
      setProgressBar(true);

      socialPlatformFunc().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const socialPlatformFunc = async () => {
    try {
      const { data } = await Api.getContactSocialPlatforms(contact_id);

      setSocialPlatform(data.data);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const dataMaterialTable = social_Platforms
    ? social_Platforms.map((o) => ({ ...o }))
    : [];

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <Iconify
                      icon={"fluent:add-12-filled"}
                      width={25}
                      height={25}
                    />
                  </Fab>
                ),
                tooltip: "Add Social",
                disabled: !canAdd,
                hidden: !canAdd,

                isFreeAction: true,
                onClick: (event) => {
                  openInPopup(null);
                },
              },
              {
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color: "#009688",
                    }}
                  >
                    <Iconify
                      icon={"fluent:edit-24-filled"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "Edit Social",

                onClick: (event, rowData) => {
                  openInPopup(rowData);
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color: "#f44336",
                    }}
                  >
                    <Iconify
                      icon={"mingcute:delete-fill"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "Delete Social",

                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this document?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.pivot.id),
                  }),
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Social Platforms"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Add social account"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <SocialPlatformForm
          socialPlatformForEdit={
            socialPlatformForEdit ? socialPlatformForEdit : null
          }
          socialPlatforms={socialPlatforms}
          onSaveSocialPlatform={saveSocialPlatform}
        />
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default SocialPlatformsContacts;
