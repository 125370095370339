import { createSlice } from "@reduxjs/toolkit";
import Api from "../../lib/api";
import { isEmpty } from "lodash";
import axios from "axios";
import { SimpleUser, SimpleUserOptions } from "sip.js/lib/platform/web";
import {
  /*Invitation,
    Inviter,
    InviterOptions,
    Referral,
    Registerer,
    RegistererOptions,
    Session,*/
  SessionState,
  UserAgent,
  /*UserAgentOptions,
    InvitationAcceptOptions,
    Messager,
    SimpleUser, SimpleUserOptions*/
} from "sip.js";

function pbxConnectAndRegister(data) {
  if (
    data &&
    data.data &&
    data.data.building &&
    data.data.building.data.pbx_domain &&
    data.data.ext &&
    data.data.ext_password &&
    data.data.organization.data.pbx_integration === 1 &&
    data.data.organization.data.pbx_integration_port
  ) {
    let pbx = data.data.pbx_domain
      ? data.data.pbx_domain
      : data.data.building.data.pbx_domain;
    let ext = data.data.ext;
    let extPass = data.data.ext_password;
    if (pbx.includes(";")) pbx = pbx.split(";")[0];
    let port = data.data.organization.data.pbx_integration_port;

    const aor = "sip:" + ext + "@" + pbx;
    const server = "wss://" + pbx + ":" + port + "/ws";
    const authorizationUsername = ext;
    const authorizationPassword = extPass;
    const callTo_domain = "@" + pbx;
    const callTo_start = "sip:";

    return {
      aor: aor,
      server: server,
      authorizationUsername: authorizationUsername,
      authorizationPassword: /*base64_encode(*/ authorizationPassword /*)*/,
      callTo_start: callTo_start,
      callTo_domain: callTo_domain,
      receive_calls: data.data.receive_calls === 1 ? true : false,
      secundary:
        localStorage.getItem("registered") === "1" &&
        data.data.receive_calls === 1
          ? true
          : false,
      receive_active: /*data.data.receive_calls === 1?true:*/ false,
      registered: false,
    };
  } else {
    console.log(
      "Pbx Integration it is off or Configuration it is missing information!"
    );
    //console.error(data);
    return {};
  }
}

export const initialState = {
  loading: false,
  loadingUser: false,
  errors: [],
  authUser: {},
  pbxConfig: {},
  loadingSave: false,
  SavedS: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state) => {
      state.loading = true;
      state.loadingUser = true;
    },
    signInSuccess: (state, { payload }) => {
      state.authUser = payload.data;
      state.loading = false;
      state.loadingUser = false;
      state.errors = [];
      state.pbxConfig = pbxConnectAndRegister(payload.data);
    },
    signInFailure: (state, { payload }) => {
      state.loading = false;
      state.loadingUser = false;
      state.errors = [...state.errors, payload];
    },
    logOut: (state) => {
      state.loading = true;
    },
    logOutSuccess: (state, { payload }) => {
      state.authUser = {};
      state.loading = false;
      state.errors = [];
      state.pbxConfig = {};
      window.location = "/auth/sign-in";
    },
    logOutPatientSuccess: (state, { payload }) => {
      state.authUser = {};
      state.loading = false;
      state.errors = [];
      window.location = "/portal/" + payload.token + "/auth";
    },
    logOutFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateTheme: (state, { payload }) => {
      state.authUser = {
        ...state.authUser,
        organization: {
          ...state.authUser.organization,
          data: {
            ...state.authUser.organization.data,
            theme: payload,
          },
        },
      };
      state.pbxConfig = {
        ...state.pbxConfig,
      };
    },
    updateOrganization: (state, { payload }) => {
      state.loadingSave = true;
    },
    updateOrganizationSuccess: (state, { payload }) => {
      state.authUser = {
        ...state.authUser,
        organization: {
          //...state.authUser.organization,
          data: payload.data,
        },
      };

      state.pbxConfig = {
        ...state.pbxConfig,
      };
      state.loadingSave = false;
      state.SavedS = true;
    },
    updateOrganizationFailure: (state, { payload }) => {
      state.loadingSave = false;
      state.SavedS = false;
      // state.loading = false;
      // state.errors = [...state.errors, payload.message];
    },

    saveOrganizationSuccess: (state, { payload }) => {
      state.authUser = {
        ...state.authUser,
      };
      state.pbxConfig = {
        ...state.pbxConfig,
      };
    },
    saveOrganizationFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateBuildingSuccess: (state, { payload }) => {
      state.authUser = {
        ...state.authUser,
      };
      window.location.reload(true);
    },
    updatePbxInfo: (state, { payload }) => {
      //console.error("updatePbxInfo");
      if (payload && payload !== {} && payload !== state.pbxConfig)
        state.pbxConfig = payload; /*{
        //...state.pbxConfig,
        payload,
      };*/
    },
    updateOnlyEntity: (state) => {
      state.loading = true;
    },
    updateOnlyEntitySuccess: (state, { payload }) => {
      state.authUser = payload.data;

      state.loading = false;
      state.loadingUser = false;
      state.errors = [];
    },
    updateOnlyEntityFailure: (state, { payload }) => {
      state.loading = false;
    },

    updateDefault_cg: (state, { payload }) => {
      //console.log("updateDefault_cg",payload,state.authUser.default_cg);
      state.authUser.default_cg = payload;
      //console.log("updateDefault_cg end",state.authUser.default_cg);
    },
  },
});

export const {
  signIn,
  signInSuccess,
  signInFailure,
  logOut,
  logOutSuccess,
  logOutPatientSuccess,
  logOutFailure,
  updateTheme,
  updateOrganization,
  updateOrganizationSuccess,
  updateOrganizationFailure,
  saveOrganizationSuccess,
  saveOrganizationFailure,
  updateBuildingSuccess,
  updatePbxInfo,
  updateOnlyEntity,
  updateOnlyEntitySuccess,
  updateOnlyEntityFailure,

  updateDefault_cg,
} = authSlice.actions;
export default authSlice.reducer;

export function fetchUser() {
  return async (dispatch) => {
    dispatch(signIn());
    // let location = null;
    // try {
    //   location = await axios.get(`https://geolocation-db.com/json/`);
    //   //  console.log("authSignTT");
    // } catch (err) {
    //   console.error(err);
    // }
    let payload = {};
    // if (!isEmpty(location)) {
    //   Object.assign(payload, location.data);
    // }

    try {
      const response = await Api.getCurrentUser(payload);

      dispatch(signInSuccess(response.data));
      dispatch(updatePbxInfo(pbxConnectAndRegister(response.data)));
    } catch (error) {
      dispatch(signInFailure(error));
    }
  };
}

export function fetchPortalUser() {
  return async (dispatch) => {
    dispatch(signIn());
    try {
      const response = await Api.getCurrentPortalUser();
      dispatch(signInSuccess(response.data));
    } catch (error) {
      dispatch(signInFailure(error));
    }
  };
}

export function fetchGUser(access_token) {
  return async (dispatch) => {
    dispatch(signIn());
    // let location = null;
    // try {
    //   location = await axios.get(`https://geolocation-db.com/json/`);
    // } catch (err) {
    //   console.error(err);
    // }
    let payload = {};
    // if (!isEmpty(location)) {
    //   Object.assign(payload, location.data);
    // }
    try {
      const response = await Api.getCurrentUserByEmail(access_token, payload);
      const data = response.data;
      console.error("Auth", data);
      if (!isEmpty(data.message)) {
        dispatch(signInFailure(data.message));
      } else {
        localStorage.setItem("token", data.meta.access_token);
        dispatch(signInSuccess(data));
        dispatch(updatePbxInfo(pbxConnectAndRegister(data)));
      }
    } catch (error) {
      dispatch(signInFailure(error));
    }
  };
}
export function fetchAuth(payload) {
  return async (dispatch) => {
    dispatch(signIn());
    // let location = null;
    // try {
    //   location = await axios.get(`https://geolocation-db.com/json/`);
    // } catch (err) {
    //   console.error(err);
    // }
    // if (!isEmpty(location)) {
    //   payload.IPv4 = location.data.IPv4;
    //   Object.assign(payload, location.data);
    // }
    try {
      const response = await Api.authUser(payload);
      const data = response.data;
      console.error("Auth", data);
      if (!isEmpty(data.message)) {
        dispatch(signInFailure(data.message));
      } else {
        localStorage.setItem("token", data.meta.access_token);
        dispatch(signInSuccess(data));
        dispatch(updatePbxInfo(pbxConnectAndRegister(data)));
      }
    } catch (error) {
      dispatch(signInFailure("Critical Error"));
    }
  };
}
export function fetchAuthPatient(payload) {
  return async (dispatch) => {
    dispatch(signIn());

    try {
      const response = await Api.authUserPatient(payload);
      const data = response.data;
      if (!isEmpty(data.message)) {
        dispatch(signInFailure(data.message));
      } else {
        localStorage.setItem("token", data.meta.access_token);
        localStorage.setItem("portal", true);
        dispatch(signInSuccess(data));
      }
    } catch (error) {
      dispatch(signInFailure("Critical Error"));
    }
  };
}

export function logOutService(user_id = null) {
  return async (dispatch) => {
    dispatch(logOut());
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("portal");
      /*let simpleUserRegistered = localStorage.getItem('simpleUserRegistered')?localStorage.getItem('simpleUserRegistered'):null;
      if (simpleUserRegistered && simpleUserRegistered.isConnected() === true) {
        simpleUserRegistered.unregister();
        simpleUserRegistered.disconnect();
      }*/
      localStorage.removeItem("registered");
      localStorage.removeItem("devices");
      localStorage.removeItem("defaults");
      localStorage.removeItem("callCheck");

      Api.cleanTemporalRecordingsForUsers();
      Api.revokeAccesToken(user_id);
      dispatch(logOutSuccess());
    } catch (error) {
      dispatch(logOutFailure(error));
    }
  };
}
export function logOutPatientService(payload) {
  return async (dispatch) => {
    dispatch(logOut());
    try {
      localStorage.removeItem("token");
      dispatch(logOutPatientSuccess(payload));
    } catch (error) {
      dispatch(logOutFailure(error));
    }
  };
}

export function updateProfileService(payload) {
  return async (dispatch) => {
    dispatch(signIn());

    try {
      const response = await Api.updateProfile(payload);
      const data = response.data;

      if (isEmpty(response.data)) {
        dispatch(signInFailure(data));
      } else {
        dispatch(signInSuccess(data));
      }
    } catch (error) {
      dispatch(signInFailure(error));
    }
  };
}

export function updateBuildingService(payload) {
  return async (dispatch) => {
    //dispatch(signIn());

    try {
      const response = await Api.updateProfile({
        building_id: payload.id,
      });
      const data = response.data;

      if (isEmpty(response.data)) {
        dispatch(signInFailure(data));
      } else {
        dispatch(updateBuildingSuccess(data));
      }
    } catch (error) {
      dispatch(signInFailure(error));
    }
  };
}

export function updateOnlyEntityService(payload) {
  return async (dispatch) => {
    dispatch(updateOnlyEntity());
    try {
      dispatch(updateOnlyEntitySuccess(payload));
    } catch (error) {
      dispatch(updateOnlyEntityFailure(error));
    }
  };
}

export function updateOrganizationService(id, payload) {
  return async (dispatch) => {
    //dispatch(signIn());

    try {
      dispatch(updateOrganization());

      const response = await Api.updateOrganization(id, payload);
      // console.log("updateOrganizationService ", response);
      dispatch(updateOrganizationSuccess(response.data));
    } catch (error) {
      dispatch(updateOrganizationFailure(error));
    }
  };
}

export function saveOrganizationService(id, payload) {
  return async (dispatch) => {
    dispatch(signIn());

    try {
      const response = await Api.saveOrganization(payload);
      dispatch(saveOrganizationSuccess(response.data));
    } catch (error) {
      dispatch(saveOrganizationFailure(error));
    }
  };
}

export function setTheme(payload) {
  return async (dispatch) => {
    dispatch(updateTheme(payload));
  };
}
