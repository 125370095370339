//import './wdyr';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import rootReducer from "./redux/slices";
import "./i18n";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== "production" ? true : false,
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
