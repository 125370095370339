import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";
import { useSelector } from "react-redux";

var apiGetFiltersDefault={
  include:[],
  limit:0,
  page:0,
  params:[],
  filters:[],
  search:null,
}
var apiUrlDataDefault={
  module:null,
  entity:null,
  entities:null,
  many:false,
}
/*function getOrganizationId()
{
  return localStorage.getItem('organizationId');
}*/

export function newSlice(entitiesName){
  const entitiesNamePagination = entitiesName+'Pagination';
  const getLoading = entitiesName+'Loading';
  const saveLoading = entitiesName+'SaveLoading';
  const deleteLoading = entitiesName+'DeleteLoading';
  const entitiesNameErrors = entitiesName+'Errors';
  const initialState = {
    loading: false,
    [getLoading]: false,
    [saveLoading]:false,
    [deleteLoading]:false,
    errors: [],
    [entitiesName]: [],
    [entitiesNamePagination]:{},
    [entitiesNameErrors]: [],
  };
return createSlice({
  name: entitiesName,
  initialState,
  reducers: {
    getEntities: (state,{ payload }) => {
      var showPageLoading = payload;
      state.loading = showPageLoading==true?true:state.loading;
      state[getLoading]=true;
    },
    getEntitiesSuccess: (state, { payload }) => {
      console.log('getEntitiesSuccess',entitiesName);
      state[entitiesName]=payload.data;
      state[entitiesNamePagination]=payload.meta!=null?payload.meta['pagination']:{};
      state.loading = false;
      state[getLoading]=false;
      state.errors = [];
      state[entitiesNameErrors]=state.errors;
      console.log('getEntitiesSuccess State',state);
    },
    getEntitiesFailure: (state, { payload }) => {
      state.loading = false;
      state[getLoading]=false;
      state.errors = payload.data!=null?[...state.errors, payload.data.message]:state.errors;
      state[entitiesNameErrors]=state.errors;
      console.log('ERROR',payload.message);
    },
    createEntity: (state) => {
      //state.loading = true;
      state[saveLoading]=true;
    },
    createEntitySuccess: (state, { payload }) => {
      state[entitiesName]=[payload.data,...state[entitiesName]];
      state.loading = false;
      state[getLoading]=false;
      state[saveLoading]=false;
      state.errors = [];
      state[entitiesNameErrors]=state.errors;
    },
    createEntityFailure: (state, { payload }) => {
      state.loading = false;
      state[getLoading]=false;
      state[saveLoading]=false;
      state.errors = payload.data!=null?[...state.errors, payload.data.message]:state.errors;
      state[entitiesNameErrors]=state.errors;
    },
    updateEntity: (state) => {
      //state.loading = true;
      state[saveLoading]=true;
    },
    updateEntitySuccess: (state, { payload }) => {
      const index = findIndex(state[entitiesName], ["id", payload.data.id]);
      const entity = { ...state[entitiesName][index], ...payload.data };

      let entities = [...state[entitiesName]];
      entities[index] = entity;

      state[entitiesName] = [...entities];
      state.loading = false;
      state[getLoading]=false;
      state[saveLoading]=false;
      state.errors = [];
      state[entitiesNameErrors]=state.errors;
    },
    updateEntityFailure: (state, { payload }) => {
      state.loading = false;
      state[getLoading]=false;
      state[saveLoading]=false;
      state.errors = payload.data!=null?[...state.errors, payload.data.message]:state.errors;
      state[entitiesNameErrors]=state.errors;
    },
    deleteEntity: (state) => {
      //state.loading = true;
      state[deleteLoading]=true;
      state[getLoading]=true;
    },
    deleteEntitySuccess: (state, { payload }) => {
      state[entitiesName] = state[entitiesName].filter((entity) => {
        return entity.id !== payload;
      });
      state.loading = false;
      state[getLoading]=false;
      state[deleteLoading]=false;
    },
    deleteEntityFailure: (state, { payload }) => {
      state.loading = false;
      state[getLoading]=false;
      state[deleteLoading]=false;
      state.errors = payload.data!=null?[...state.errors, payload.data.message]:state.errors;
      state[entitiesNameErrors]=state.errors;
    },
  },
});

}


export function _loadEvents(entitiesSlice)
{
  const {
    getEntities,
    getEntitiesSuccess,
    getEntitiesFailure,
    createEntity,
    createEntitySuccess,
    createEntityFailure,
    updateEntity,
    updateEntitySuccess,
    updateEntityFailure,
    deleteEntity,
    deleteEntitySuccess,
    deleteEntityFailure,
  } = entitiesSlice.actions;
  return{
    getEntities:getEntities,
    getEntitiesSuccess:getEntitiesSuccess,
    getEntitiesFailure:getEntitiesFailure,
    createEntity:createEntity,
    createEntitySuccess:createEntitySuccess,
    createEntityFailure:createEntityFailure,
    updateEntity:updateEntity,
    updateEntitySuccess:updateEntitySuccess,
    updateEntityFailure:updateEntityFailure,
    deleteEntity:deleteEntity,
    deleteEntitySuccess:deleteEntitySuccess,
    deleteEntityFailure:deleteEntityFailure,
  }
}

function getApiFilters(filtersCustom){
    var filters = apiGetFiltersDefault;
    if(filtersCustom!=null)
    {
      filters.include = filtersCustom.include!=null?filtersCustom.include:filters.include;
      filters.page = filtersCustom.page!=null?filtersCustom.page:filters.page;
      filters.limit = filtersCustom.limit!=null?filtersCustom.limit:filters.limit;
      filters.params = filtersCustom.params!=null?filtersCustom.params:filters.params;
      filters.filters = filtersCustom.filters!=null?filtersCustom.filters:filters.filters;
      filters.search = filtersCustom.search!=null?filtersCustom.search:filters.search;
    }
    return filters;
}
function getApiUrl(apiUrlCustom)
{
  console.log("apiUrlCustom",apiUrlCustom);
  var apiUrl = apiUrlDataDefault;
    if(apiUrlCustom!=null)
    {
      apiUrl.module = apiUrlCustom.module!=null?apiUrlCustom.module:apiUrl.module;
      apiUrl.entity = apiUrlCustom.entity!=null?apiUrlCustom.entity:apiUrl.entity;
      apiUrl.entities = apiUrlCustom.entities!=null?apiUrlCustom.entities:apiUrl.entities;
      apiUrl.many = apiUrlCustom.many!=null?apiUrlCustom.many:apiUrl.many;
      if(apiUrlCustom.orgId) apiUrl.orgId = apiUrlCustom.orgId;
    }
    var many = apiUrl.many;
    var entityName=many&&apiUrl.entities!=null?apiUrl.entities:apiUrl.entity;
    entityName=!many&&entityName==null?apiUrl.entities:entityName;

    var url = apiUrl.module!=null?"/"+apiUrl.module:"";
    if(apiUrl.module === "organizations") url += apiUrl.orgId?"/"+apiUrl.orgId:"";
    url += entityName!=null?"/"+entityName:"";
    apiUrl.url=url;

    console.log("URL:",url);

    return apiUrl;
}


export function _fetchEntitiesService(dataSlice,dispatchEvents) {
  
  if(dataSlice&&dataSlice.dataUrl){
    dataSlice.dataUrl.many=true;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var getFilters = getApiFilters(dataSlice.getFilters);

    /*if(dataSlice.requireOrganizationId==true){
      getFilters.organization_id = getOrganizationId();
    } */
    
    return async (dispatch) => {
        //entitiesName=dataSlice.stateListName;
        var showPageLoading = dataSlice.dataList?dataSlice.dataList.showPageLoading:true;
        dispatch(dispatchEvents.getEntities(showPageLoading));
        try {          
         
          //const response = await Api.getEntities(dataUrl.url,getFilters);
          console.log("dataSlicefinal:",dataSlice);
          var apiParams={dataUrl:dataSlice.dataUrl,filters:getFilters};
          const response = await Api.getEntities(apiParams);

          dispatch(dispatchEvents.getEntitiesSuccess(response.data));

          if(dataSlice.dataList&&dataSlice.dataList.successHandle){
            dispatch(dataSlice.dataList.successHandle);
          }
        } catch (error) {
          dispatch(dispatchEvents.getEntitiesFailure(error));
          if(dataSlice.errorHandle){
            dispatch(dataSlice.errorHandle);
          }
        }
      
    };
  }
}
/*export function getAllEntitiesService(orgId) {
  return async (dispatch) => {
    dispatch(getEntities());
    try {
      const response = await Api.getAllEntities();
      dispatch(getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(getEntitiesFailure(error));
    }
  };
}*/
export function _createEntityService(dataSlice,dispatchEvents) {
  if(dataSlice&&dataSlice.dataCreate&&dataSlice.dataUrl){
    
    dataSlice.dataUrl.many=false;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var payload = dataSlice.dataCreate.data; 
    var getFilters = getApiFilters(dataSlice.getFilters);
    
    /*if(dataSlice.requireOrganizationId==true){
      payload.organization_id = getOrganizationId();
    }   */ 

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      dispatch(dispatchEvents.createEntity());
      try {        
        //const response = await Api.saveEntity(dataUrl.url,payload); 
        var apiParams={dataUrl:dataSlice.dataUrl,filters:getFilters,payload:payload};
        const response = await Api.saveEntity(apiParams);

        dispatch(dispatchEvents.createEntitySuccess(response.data));
        if(dataSlice.dataCreate.successHandle){
          dispatch(dataSlice.dataCreate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.createEntityFailure(error));
        if(dataSlice.errorHandle){
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _updateEntityService(dataSlice,dispatchEvents) {
  if(dataSlice&&dataSlice.dataUpdate&&dataSlice.dataUrl){
    dataSlice.dataUrl.many=false;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var id = dataSlice.dataUpdate.id;
    var payload = dataSlice.dataUpdate.data;
    var getFilters = getApiFilters(dataSlice.getFilters);    

    /*if(dataSlice.requireOrganizationId==true){
      payload.organization_id = getOrganizationId();
    } */

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      dispatch(dispatchEvents.updateEntity());
      try {     
        var url = dataUrl.url+'/'+id;
        getFilters.params['id']=id;  
        //const response = await Api.updateEntity(url,id, payload);
        var apiParams={dataUrl:dataSlice.dataUrl,filters:getFilters,payload:payload};
        const response = await Api.updateEntity(apiParams);

        dispatch(dispatchEvents.updateEntitySuccess(response.data));
        if(dataSlice.dataUpdate.successHandle){
          dispatch(dataSlice.dataUpdate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.updateEntityFailure(error));
        if(dataSlice.errorHandle){
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _deleteEntityService(dataSlice,dispatchEvents) {
  if(dataSlice&&dataSlice.dataDelete&&dataSlice.dataUrl){
    dataSlice.dataUrl.many=false;
    let id = dataSlice.dataDelete.id;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var getFilters = getApiFilters(dataSlice.getFilters);

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      dispatch(dispatchEvents.deleteEntity());
      try {
        var url = dataUrl.url+'/'+id;
        getFilters.params['id']=id;  
        var apiParams={dataUrl:dataSlice.dataUrl,filters:getFilters};        
        await Api.deleteEntity(apiParams);
        
        dispatch(dispatchEvents.deleteEntitySuccess());

        if(dataSlice.dataDelete.successHandle){
          dispatch(dataSlice.dataDelete.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.deleteEntityFailure(error));
        if(dataSlice.errorHandle){
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}



