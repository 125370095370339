import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  commissionRules: [],
};

const CommissionRulesSlice = createSlice({
  name: "commissionRules",
  initialState,
  reducers: {
    getCommissionRules: (state) => {
      state.loading = true;
    },
    getCommissionRulesSuccess: (state, { payload }) => {
      state.commissionRules = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCommissionRulesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createCommissionRule: (state) => {},
    createCommissionRuleSuccess: (state, { payload }) => {
      state.commissionRules = [...state.commissionRules, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createCommissionRuleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateCommissionRule: (state) => {},
    updateCommissionRuleSuccess: (state, { payload }) => {
      const index = findIndex(state.commissionRules, ["id", payload.data.id]);
      const user = { ...state.commissionRules[index], ...payload.data };

      let commissionRules = [...state.commissionRules];
      commissionRules[index] = user;

      state.commissionRules = [...commissionRules];
      state.loading = false;
      state.errors = [];
    },
    updateCommissionRuleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteCommissionRule: (state) => {},
    deleteCommissionRuleSuccess: (state, { payload }) => {
      state.commissionRules = state.commissionRules.filter((group) => {
        return group.id !== payload;
      });
    },
    deleteCommissionRuleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getCommissionRules,
  getCommissionRulesSuccess,
  getCommissionRulesFailure,
  createCommissionRule,
  createCommissionRuleSuccess,
  createCommissionRuleFailure,
  updateCommissionRule,
  updateCommissionRuleSuccess,
  updateCommissionRuleFailure,
  deleteCommissionRule,
  deleteCommissionRuleSuccess,
  deleteCommissionRuleFailure,
} = CommissionRulesSlice.actions;
export const CommissionRulesSelector = (state) => state.commissionRules;
export default CommissionRulesSlice.reducer;

export function fetchCommissionRulesService(orgId) {
  return async (dispatch) => {
    dispatch(getCommissionRules());

    try {
      const response = await Api.getCommissionRules(orgId);

      dispatch(getCommissionRulesSuccess(response.data));
    } catch (error) {
      dispatch(getCommissionRulesFailure(error));
    }
  };
}

export function createCommissionRuleService(payload) {
  return async (dispatch) => {
    dispatch(createCommissionRule());

    try {
      const response = await Api.saveCommissionRule(payload);

      dispatch(createCommissionRuleSuccess(response.data));
    } catch (error) {
      dispatch(createCommissionRuleFailure(error));
    }
  };
}

export function updateCommissionRuleService(id, payload) {
  return async (dispatch) => {
    dispatch(updateCommissionRule());

    try {
      const response = await Api.updateCommissionRule(id, payload);

      dispatch(updateCommissionRuleSuccess(response.data));
    } catch (error) {
      dispatch(updateCommissionRuleFailure(error));
    }
  };
}

export function deleteCommissionRuleService(id) {
  console.log("deleteCommissionRuleService ", id);
  return async (dispatch) => {
    dispatch(deleteCommissionRule());

    try {
      const response = await Api.deleteCommissionRule(id);

      dispatch(deleteCommissionRuleSuccess(id));
    } catch (error) {
      dispatch(deleteCommissionRuleFailure(error));
    }
  };
}
