import { makeStyles } from "@material-ui/core/styles";
import SmsMmsPanel from "./SmsMmsPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  chatWrapper: {
    width: "63vw",
    display: "flex",
    height: "100%",
  },
}));

const smsMmsView = (props) => {
  const {openNewSMS, setOpenNewSMS}= props;
  const classes = useStyles();

  return (
    <div className={classes.chatWrapper}>
      <SmsMmsPanel openNewSMS = {openNewSMS} setOpenNewSMS = {setOpenNewSMS} />
    </div>
  );
};

export default smsMmsView;
