import { Paper } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import IconButton from "./controls/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";


const useStyles = makeStyles((theme) => ({
    image: {
      maxWidth: "100%"
    },
  }));

export default function CameraHD({onCapture, ...props}) {
    const classes = useStyles();
  const [image, setImage] = useState("");

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
  
    setImage(dataUri);
    onCapture(dataUri);
  }

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    
  }

  function handleCameraError(error) {
    
  }

  function handleCameraStart(stream) {
   
  }

  function handleCameraStop() {
    
  }

  return (
    <div>
      {image ? (
        <Paper variant="outlined">
          <img src={image} className={classes.image} />
          <IconButton aria-label="Cancel" color="primary" onClick={()=>setImage("")}>
            <CancelIcon />
          </IconButton>
        </Paper>
      ) : (
        <Camera
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri);
          }}
          onTakePhotoAnimationDone={(dataUri) => {
            handleTakePhotoAnimationDone(dataUri);
          }}
          onCameraError={(error) => {
            handleCameraError(error);
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ width: 640, height: 480 }}
          imageType={IMAGE_TYPES.JPG}
          imageCompression={0.97}
          isMaxResolution={true}
          isImageMirror={false}
          isSilentMode={false}
          isDisplayStartCameraError={true}
          isFullscreen={false}
          sizeFactor={1}
          onCameraStart={(stream) => {
            handleCameraStart(stream);
          }}
          onCameraStop={() => {
            handleCameraStop();
          }}
        />
      )}
    </div>
  );
}
