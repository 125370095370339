import React, { useEffect, useState } from "react";
import Api from "../lib/api";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Iconify from "./Iconify";
import Popup from "./Popup";
import moment from "moment";
import { isEmpty } from "lodash";

export default function LinkAppointmentsToRecordProducts(props) {
  const {
    contactId,
    selectedRecordProducts = [],
    setSelectedRecordProducts,
    appointmentID,
    hidden,
    setSelectedDate,
    selectedDate,
  } = props;
  const [recordProducts, setRecordProducts] = useState(null);
  const [selectedRadioButton, setSelectedRadioButton] = useState(
    !isEmpty(selectedRecordProducts)
      ? moment(selectedRecordProducts[0].date).format("YYYY-MM-DD HH:mm:ss")
      : null
  );
  const [selectedRecordProductsTemp, setSelectedRecordProductsTemp] = useState(
    selectedRecordProducts
  );
  const [isRecordProductsPopupOpen, setIsRecordProductsPopupOpen] =
    useState(false);

  const getRecordProducts = async () => {
    try {
      if (contactId) {
        const { data } = await Api.getContactRecordProductsByDate(
          contactId,
          "?appointment_id=" + appointmentID
        );
        setRecordProducts(data);
        manageSelectedProducts(data);
      } else {
        setRecordProducts(null);
        manageSelectedProducts(null);
      }
    } catch (err) {
      setRecordProducts(null);
      manageSelectedProducts(null);
    }
  };

  const manageSelectedProducts = (obj) => {
    if (!obj) {
      setSelectedRecordProducts([]);
    } else {
      var recordProductsIDs = [];
      Object.keys(obj).forEach((key) => {
        obj[key].forEach((rp) => {
          recordProductsIDs.push(rp.id);
        });
      });
      setSelectedRecordProducts([
        ...selectedRecordProducts.filter((rp) =>
          recordProductsIDs.includes(rp.id)
        ),
      ]);
    }
  };

  const renderSelectedProducts = () => {
    if (selectedRecordProducts.length > 0) {
      return selectedRecordProducts.map((rp) => {
        return (
          <Typography
            style={{
              padding: "5px 10px",
              background: "whitesmoke",
              borderRadius: 12,
              margin: "4px 8px",
            }}
          >
            {rp.invoice_item.item_name}
          </Typography>
        );
      });
    } else {
      return "";
    }
  };

  const openRecordProducts = () => {
    setSelectedRadioButton(
      !isEmpty(selectedRecordProducts)
        ? moment(selectedRecordProducts[0].date).format("YYYY-MM-DD HH:mm:ss")
        : null
    );
    setIsRecordProductsPopupOpen(true);
  };

  const closeRecordProductsPopup = () => {
    setIsRecordProductsPopupOpen(false);
    setSelectedRadioButton(null);
  };

  const handleChange = (e) => {
    var value = e.target.value;

    setSelectedRadioButton(value);

    if (value == "none") {
      setSelectedRecordProductsTemp([]);
    } else {
      setSelectedRecordProductsTemp(recordProducts[value]);
    }
  };

  useEffect(() => {
    getRecordProducts();
  }, [contactId]);

  if (!recordProducts || hidden) {
    return "";
  }

  return (
    <div>
      {selectedRecordProducts.length > 0 ? (
        <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>
          <Typography style={{ marginRight: 4, fontWeight: 500 }}>
            Procedures
          </Typography>
          <IconButton style={{ padding: 5 }} onClick={openRecordProducts}>
            <Iconify icon={"mdi:edit-outline"} />
          </IconButton>
        </div>
      ) : (
        <Button
          style={{ marginLeft: 4 }}
          color="primary"
          startIcon={<Iconify icon="solar:link-round-bold" />}
          onClick={openRecordProducts}
        >
          Link appointment to procedures
        </Button>
      )}

      {renderSelectedProducts()}

      <Popup
        title={"Procedures"}
        fullWidth={true}
        maxWidth={"sm"}
        openPopup={isRecordProductsPopupOpen}
        onClose={() => {
          closeRecordProductsPopup();
        }}
        dialogContentProps={{ style: { padding: "12px 8px", paddingLeft: 18 } }}
      >
        <FormControl component="fieldset" style={{width: '100%'}}>
          <FormLabel style={{ fontWeight: 600 }} component="legend">
            Procedures
          </FormLabel>
          <RadioGroup
            style={{ minHeight: 62, display: "grid", width: "100%" }}
            row
            aria-label="gender"
            name="gender1"
            value={selectedRadioButton}
            onChange={handleChange}
          >
            <FormControlLabel
              value={"none"}
              control={<Radio />}
              label={
                <Typography
                  style={{
                    fontWeight: selectedRadioButton == "none" ? 600 : 400,
                  }}
                >
                  {"None"}
                </Typography>
              }
            />
            {Object.keys(recordProducts).map((key) => {
              return (
                <div>
                  <FormControlLabel
                    value={key}
                    control={<Radio />}
                    label={
                      <Typography
                        style={{
                          fontWeight: selectedRadioButton == key ? 600 : 400,
                        }}
                      >
                        {"Procedures for the date " +
                          moment(key).format("YYYY-MM-DD hh:mm A") +
                          ": "}
                      </Typography>
                    }
                  />
                  <div>
                    {recordProducts[key].map((rp) => {
                      return (
                        <Typography
                          style={{
                            padding: "5px 10px",
                            background: "whitesmoke",
                            borderRadius: 12,
                            margin: "4px 0px",
                          }}
                        >
                          {rp.invoice_item.item_name}
                        </Typography>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginTop: 12,
          }}
        >
          <Button onClick={closeRecordProductsPopup}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSelectedDate(selectedRadioButton);
              setSelectedRecordProducts(selectedRecordProductsTemp);
              closeRecordProductsPopup();
            }}
          >
            Save
          </Button>
        </div>
      </Popup>
    </div>
  );
}
