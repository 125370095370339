import React, { useEffect, useState, useRef } from "react";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactFormTemplatesService } from "../redux/slices/contactFormTemplates";
import { useTranslation } from "react-i18next";
import {
  Paper,
  makeStyles,
  Grid,
  Box,
  Fab,
  Tab,
  Tabs,
  TextField,
  Typography,
  AppBar,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Controls from "./controls";
import reactStringReplace from "react-string-replace";

import styled from "styled-components";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";
import CRMUtils from "../utils";
import MmsIcon from "@material-ui/icons/Mms";

const useStyles = makeStyles((theme) => ({
  input: {
    "&.MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&.MuiInput-underline:after": {
      borderBottom: 0,
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
  },
  pageContent: {
    position: "relative",
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  docHtmlWrapper: {
    padding: "10px",
  },
  docPageBreack: {
    border: "1px red solid",
  },
  docContent: {
    margin: "10px",
    padding: "5px",
    borderRadius: "10px",
  },
  speedDial: {
    position: "absolute",
    right: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    marginLeft: 20,
    marginRight: 20,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: "block";
  height: 100%;
  width: 100%;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {
        //value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
        //)
      }
    </div>
  );
}

function ContactFormTemplateViewer(props) {
  const {
    submitDisabled,
    contactForEdit,
    onSendDocument,
    document = null,
    setSelectedTemplatesTypes = null,
    category_id = null,
  } = props;
  const [personName, setPersonName] = React.useState([]);
  const docRef = useRef();
  
  const handleChange = (event) => {
    if (setSelectedTemplatesTypes) {
      setSelectedTemplatesTypes(event.target.value);
    }
    setPersonName(event.target.value);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["templates", "common", "records"]);
  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [tabValue, setTabValue] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [loadedDocument, setLoadedDocument] = useState(null);

  const [isChecked, setIsChecked] = useState(true);

  const UserCanSendMassiveSmsTemplate = CRMUtils.userHasPermission(authUser, [
    "template_SendMassiveSmsTemplate",
  ]);

  const userCanSendSMSMMSImpersonatingOwner = CRMUtils.userHasPermission(authUser, [
    "user_impersonate",
  ]);

  const options = ["EMAIL"];
  if (UserCanSendMassiveSmsTemplate) {
    options.push("SMS", "MMS");
  }

  const [send_unsuscribe_link, setSend_unsuscribe_link] = useState(false);
  if (document !== null) setLoadedDocument(document);

  const { contactFormTemplates, loading, error } = useSelector(
    (state) => state.contactFormTemplates
  );

  const documentOptions = () => {
    return contactFormTemplates.map((doc) => {
      return {
        id: doc.id,
        title: doc.name,
      };
    });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // useEffect(() => {
  //   console.log("PERSON NAME: ", personName);
  // }, [personName]);

  const onSend = () => {
    let html = decodeURI(loadedDocument.html);
    html = htmlReplaceTokens(html);
    const docName = find(contactFormTemplates, { id: selectedDocument });
    let sms = docName && docName.sms ? htmlReplaceTokens(docName.sms) : null;
    let mms_text =
      docName && docName.mms_text ? htmlReplaceTokens(docName.mms_text) : null;
    let mms_url =
      docName && docName.mms_url ? htmlReplaceTokens(docName.mms_url) : null;
    const a = docRef.current;

    console.log("isChecked onSend",isChecked);
    //return;
    onSendDocument(
      docName.name,
      personName.includes("EMAIL") ? a.innerHTML : null,
      UserCanSendMassiveSmsTemplate && personName.includes("SMS") ? sms : null,
      UserCanSendMassiveSmsTemplate && personName.includes("MMS")
        ? mms_text
        : null,
      UserCanSendMassiveSmsTemplate && personName.includes("MMS")
        ? mms_url
        : null,
      selectedDocument,
      userCanSendSMSMMSImpersonatingOwner && isChecked?isChecked:0
    );
  };
  const createDocMarkup = () => {
    //console.error(loadedDocument.html);
    let html = decodeURI(loadedDocument.html);
    html = htmlReplaceTokens(html);

    if (contactForEdit) {
      return { __html: html.join(" ") };
    } else {
      return { __html: html };
    }
  };

  const htmlReplaceTokens = (html) => {
    if (contactForEdit) {
      html = reactStringReplace(html, "{{CUSTOMER_ID}}", (match, i) => {
        return contactForEdit.id;
      });
      html = reactStringReplace(html, "{{CUSTOMER_FNAME}}", (match, i) => {
        return contactForEdit.fname;
      });
      html = reactStringReplace(html, "{{CUSTOMER_LNAME}}", (match, i) => {
        return contactForEdit.lname;
      });
      html = reactStringReplace(html, "{{CUSTOMER_EMAIL}}", (match, i) => {
        return contactForEdit.email ? contactForEdit.email : "";
      });
      html = reactStringReplace(html, "{{CUSTOMER_PHONE}}", (match, i) => {
        return contactForEdit.phone ? contactForEdit.phone : "";
      });
      html = reactStringReplace(
        html,
        "{{CUSTOMER_UNSUBSCRIBE_LINK}}",
        (match, i) => {
          return send_unsuscribe_link && contactForEdit.subscription_token
            ? process.env.REACT_APP_FRONTEND_ENDPOINT
              ? process.env.REACT_APP_FRONTEND_ENDPOINT +
                "/unsubscribe/" +
                contactForEdit.subscription_token
              : "loccalhost"
            : "";
        }
      );

      html = reactStringReplace(html, "{{CUSTOMER_DOB}}", (match, i) => {
        return contactForEdit.dob ? contactForEdit.dob : "";
      });
      html = reactStringReplace(html, "{{CUSTOMER_SEX}}", (match, i) => {
        return contactForEdit.sex
          ? contactForEdit.sex == "M"
            ? "Male"
            : "Female"
          : "";
      });
      html = reactStringReplace(html, "{{CUSTOMER_WEIGHT}}", (match, i) => {
        return contactForEdit.weight;
      });

      html = reactStringReplace(html, "{{CUSTOMER_HEIGHT}}", (match, i) => {
        return contactForEdit.height;
      });

      html = reactStringReplace(html, "{{CUSTOMER_BMI}}", (match, i) => {
        return contactForEdit.bmi;
      });
      html = reactStringReplace(html, "{{ORGANIZATION_NAME}}", (match, i) => {
        return contactForEdit.building_id
          ? contactForEdit.building.data.name
          : contactForEdit.organization.data.name;
      });
      html = reactStringReplace(
        html,
        "{{ORGANIZATION_ADDRESS}}",
        (match, i) => {
          return contactForEdit.building_id
            ? contactForEdit.building.data.address
            : contactForEdit.organization.data.address;
        }
      );
      html = reactStringReplace(html, "{{ORGANIZATION_PHONE}}", (match, i) => {
        return contactForEdit.building_id
          ? contactForEdit.building.data.phone
          : contactForEdit.organization.data.phone;
      });
      html = reactStringReplace(html, "{{ORGANIZATION_FAX}}", (match, i) => {
        return contactForEdit.building_id
          ? contactForEdit.building.data.fax
          : contactForEdit.organization.data.fax;
      });
      html = reactStringReplace(
        html,
        "{{CONSULTANT_FULL_NAME}}",
        (match, i) => {
          return contactForEdit.owner ? contactForEdit.owner.data.title : "";
        }
      );
      html = reactStringReplace(html, "{{CLAIM_CREDIT}}", (match, i) => {
        return decodeURI(`
          <p><a href="https://crm2.305plasticsurgery.com/claim-credit?invitation=${contactForEdit.id}" style="outline: none; margin: auto; text-decoration: none; border-radius: 50px; display: flex; justify-content: center; align-items: center; cursor: pointer; text-transform: uppercase; height: 60px; width: 210px; opacity: 1; background-color: rgba(0, 174, 239, 1); border: 2px solid rgba(0, 52, 98, 1);"> <span style="font-family: Tahoma;margin: auto;color: rgba(0, 52, 98, 1)">Claim your credit here</span> </a></p>
          `);
      });
      html = reactStringReplace(html, "{{BUILDING_NAME}}", (match, i) => {
        return contactForEdit.building_id
          ? contactForEdit.building.data.name
          : contactForEdit.organization.data.name;
      });
      html = reactStringReplace(html, "{{BUILDING_TYPEFORM}}", (match, i) => {
        return contactForEdit.building_id
          ? contactForEdit.building.data.typeform_link
          : "";
      });
    }
    return html;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
 
  const handleCheckboxChange = () => {
    if(isChecked == true) setIsChecked(false);
    else setIsChecked(true);
  };

  useEffect(() => {
    if (selectedDocument) {
      const doc = find(contactFormTemplates, { id: selectedDocument });
      setLoadedDocument(doc);
    } else {
      setSelectedDocument(0);
      setLoadedDocument(null);
    }
  }, [selectedDocument]);

  useEffect(() => {
    setSend_unsuscribe_link(
      authUser.organization.data.send_unsuscribe_link ? true : false
    );
    const orgId = authUser.organization_id;
    var filter = [];
    var payload = {};
    if (category_id) {
      payload["category"] = category_id;
    }

    dispatch(fetchContactFormTemplatesService(orgId, payload));
  }, []);

  return (
    <Paper className={classes.pageContent}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Controls.Select
            label={"Contact Documents"}
            allowEmpty={true}
            options={documentOptions()}
            onChange={(el) => setSelectedDocument(el.target.value)}
            value={selectedDocument}
          />
        </Grid>
        {loadedDocument && (
          <Grid item xs={6}>
            <div style={{ display: "flex" }}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Send</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={personName.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {userCanSendSMSMMSImpersonatingOwner &&(
                <>
                <InputLabel id="impersonate">Impersonate Owner?</InputLabel>
                <Checkbox
                id="impersonate"
                checked={isChecked}
                onClick={(event) => {
                  //event.preventDefault();
                  //event.stopPropagation();
                  //handleClick(event, item.id);
                  handleCheckboxChange();
                }}
              />
                </>
                


                 )}

              <Fab
                color="primary"
                variant="extended"
                aria-label="Send"
                disabled={
                  personName.length == 0 || submitDisabled ? true : false
                }
                onClick={() =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to send this document?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onSend(),
                  })
                }
              >
                <SendIcon />
                Send
              </Fab>
            </div>
          </Grid>
        )}
      </Grid>

      {loadedDocument ? (
        <AppBar position="static" color="default" style={{ marginTop: 15 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab icon={<EmailIcon />} label="Email" />
            <Tab
              icon={<SmsIcon />}
              label="Sms"
              disabled={
                loadedDocument &&
                loadedDocument.sms &&
                UserCanSendMassiveSmsTemplate
                  ? false
                  : true
              }
              //disabled={true}
            />
            <Tab
              icon={<MmsIcon />}
              label="Mms"
              disabled={
                loadedDocument &&
                (loadedDocument.mms_text || loadedDocument.mms_url) &&
                UserCanSendMassiveSmsTemplate
                  ? false
                  : true
              }
            />
          </Tabs>
        </AppBar>
      ) : (
        <></>
      )}
      <TabPanel value={tabValue} index={0}>
        <Wrapper>
          <div ref={docRef} className={classes.docHtmlWrapper} id="sendemail">
            {loadedDocument ? (
              <div
                dangerouslySetInnerHTML={createDocMarkup()}
                className={classes.docContent}
              />
            ) : (
              <p>Select Document to load</p>
            )}
          </div>
        </Wrapper>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={4} style={{ marginTop: "30px" }}>
          <Grid item xs={10}>
            <TextField
              focused={false}
              id="sms-template"
              style={{ margin: 8 }}
              fullWidth
              multiline
              margin="normal"
              defaultValue={
                loadedDocument && loadedDocument.sms
                  ? htmlReplaceTokens(loadedDocument.sms)
                  : ""
              }
              InputProps={{
                className: classes.input,
                readOnly: true,
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <div style={{ marginTop: "30px" }}>
          <div style={{ display: "flex" }}>
            <div>
              <img
                src={
                  loadedDocument && loadedDocument.mms_url
                    ? loadedDocument.mms_url
                    : null
                }
                style={{
                  width: 300,
                  height: 285,
                  maxHeight: 285,
                  border: "1px solid #dbdbdb",
                  padding: "25",
                  borderRadius: 4,
                }}
              />
            </div>
            <div>
              <TextField
                // className={classes.textField}
                id="outlined-multiline-static"
                label="MMS Text"
                multiline
                rows={15}
                fullWidth
                defaultValue={
                  loadedDocument && loadedDocument.mms_text
                    ? htmlReplaceTokens(loadedDocument.mms_text)
                    : ""
                }
                variant="outlined"
                //onChange={this.handleMmsTextChange}
                InputProps={{
                  className: classes.input,
                  readOnly: true,
                  shrink: true,
                }}
                style={{
                  width: 450,
                  height: 500,
                  //border: "1px solid #dbdbdb",
                  //padding: "25",
                  marginLeft: 10,
                }}
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </Paper>
  );
}

export default ContactFormTemplateViewer;
