import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useMemo, useState } from "react";
import HeadersSidebar from "./HeadersSidebar";
import ConversationPanel from "./ConversationPanel";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../Iconify";
import { ChannelsProvider } from "../../helpers/websockets";
import { findIndex, isEmpty } from "lodash";
import {
  fetchTotalUnreadChatMessages,
  updateTotalUnreadChatMessages,
} from "../../redux/slices/totalUnreadChatMessages.js";
import { fetchUser, updateOnlyEntityService } from "../../redux/slices/auth";
import { updateGeneralTotalUnreadChatMessages } from "../../redux/slices/generalTotalUnreadChatMessages.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  chatWrapper: {
    padding: 8,
    minHeight: "100vh",
    paddingLeft: 4,
  },
  alertContainer: {
    backgroundColor: "#ffe4e4",
    alignItems: "center",
    display: "flex",
    maxWidth: "fit-content",
    color: "#af2c2c",
    padding: "6px 18px",
    borderRadius: "16px",
    marginTop: "18px",
    fontSize: "17px",
  },
  alertIcon: {
    width: 25,
    height: 25,
    marginRight: 8,
  },
}));

const InternalChat = (props) => {
  const { customHeight } = props;
  const classes = useStyles();
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [getMessagesNeeded, setGetMessagesNeeded] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const { authUser } = useSelector((state) => state.auth);
  const Usertoken = useMemo(() => localStorage.getItem("token"), [authUser]);
  const { totalUnreadChatMessages } = useSelector(
    (state) => state.totalUnreadChatMessages
  );
  const { generalTotalUnreadChatMessages } = useSelector(
    (state) => state.generalTotalUnreadChatMessages
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setHeaders([]);
    setSelectedHeader(null);
    setGetMessagesNeeded(false);
  }, [selectedTab]);

  const companyGroupUpatedReceived = (data) => {
    var userGroups = authUser.groups.data;

    if (
      data.header &&
      data.header.data &&
      data.header.data.members &&
      data.header.data.members.find((member) => member.id == authUser.id) &&
      !userGroups.find((group) => group.id == data.group.data.id)
    ) {
      dispatch(
        updateOnlyEntityService({
          data: {
            ...authUser,
            ...{
              groups: { data: [...authUser.groups.data, data.group.data] },
            },
          },
        })
      );
    } else {
      if (
        data.header &&
        data.header.data &&
        data.header.data.members &&
        !data.header.data.members.find((member) => member.id == authUser.id) &&
        userGroups.find((group) => group.id == data.group.data.id)
      ) {
        dispatch(
          updateOnlyEntityService({
            data: {
              ...authUser,
              ...{
                groups: {
                  data: [
                    ...authUser.groups.data.filter(
                      (group) => group.id != data.group.data.id
                    ),
                  ],
                },
              },
            },
          })
        );
      }
    }
  };

  const chatGroupUpatedReceived = (data) => {
    var chatGroups = authUser.chatGroups.data;
    if (
      data.header &&
      data.header.data &&
      data.header.data.recipient &&
      data.header.data.recipient.members &&
      data.header.data.recipient.members.find(
        (member) => member.id == authUser.id
      ) &&
      !chatGroups.find((group) => group.id == data.group.data.id)
    ) {
      var payload = {
        data: {
          ...authUser,
          ...{
            chatGroups: {
              data: [...authUser.chatGroups.data, data.group.data],
            },
          },
        },
      };
      dispatch(updateOnlyEntityService(payload));
    } else {
      if (
        data.header &&
        data.header.data &&
        data.header.data.recipient &&
        data.header.data.recipient.members &&
        !data.header.data.recipient.members.find(
          (member) => member.id == authUser.id
        ) &&
        chatGroups.find((group) => group.id == data.group.data.id)
      ) {
        dispatch(
          updateOnlyEntityService({
            data: {
              ...authUser,
              ...{
                chatGroups: {
                  data: [
                    ...authUser.chatGroups.data.filter(
                      (group) => group.id != data.group.data.id
                    ),
                  ],
                },
              },
            },
          })
        );
      }
    }
  };

  const updateChatTotalUnread = (modelType) => {
    if (totalUnreadChatMessages && !isEmpty(totalUnreadChatMessages)) {
      const index = findIndex(totalUnreadChatMessages, [
        "model_type",
        modelType,
      ]);
      const updatedEvent = {
        ...totalUnreadChatMessages[index],
        ...{ total: totalUnreadChatMessages[index].total + 1 },
      };

      let newTotals = [...totalUnreadChatMessages];
      newTotals[index] = updatedEvent;

      dispatch(updateTotalUnreadChatMessages(newTotals));
    }
  };

  useEffect(() => {
    if (window.Echo) {
      const onUserToUserMessageListener = (data) => {
        if (
          !selectedHeader ||
          (selectedHeader &&
            selectedHeader.conversation_id &&
            data &&
            data.message &&
            data.message.data &&
            data.message.data.conversation_id &&
            data.message.data.conversation_id != selectedHeader.conversation_id)
        ) {
          updateChatTotalUnread("App\\Models\\User");
        }
      };

      const onUserToGroupMessageListener = (data) => {
        if (
          data.from_user_id != authUser.id &&
          (!selectedHeader ||
            (selectedHeader &&
              selectedHeader.conversation_id &&
              data &&
              data.conversation &&
              data.conversation.data &&
              data.conversation.data.id &&
              data.conversation.data.id != selectedHeader.conversation_id))
        ) {
          updateChatTotalUnread("App\\Models\\Group");
        }
      };

      const onUserToChatGroupMessageListener = (data) => {
        if (
          data.from_user_id != authUser.id &&
          (!selectedHeader ||
            (selectedHeader &&
              selectedHeader.conversation_id &&
              data &&
              data.conversation &&
              data.conversation.data &&
              data.conversation.data.id &&
              data.conversation.data.id != selectedHeader.conversation_id))
        ) {
          updateChatTotalUnread("App\\Models\\ChatGroup");
        }
      };

      window.Echo.channel("user_to_user_chat." + authUser.id).listen(
        ".on_message",
        onUserToUserMessageListener
      );

      if (authUser.groups && authUser.groups.data) {
        authUser.groups.data.forEach((group) => {
          window.Echo.channel("user_to_company_group_chat." + group.id).listen(
            ".on_message",
            onUserToGroupMessageListener
          );
        });
      }

      if (authUser.chatGroups && authUser.chatGroups.data) {
        authUser.chatGroups.data.forEach((group) => {
          window.Echo.channel("user_to_group_chat." + group.id).listen(
            ".on_message",
            onUserToChatGroupMessageListener
          );
        });
      }

      window.Echo.channel("user_to_group_chat_by_user." + authUser.id).listen(
        ".group_updated",
        chatGroupUpatedReceived
      );

      window.Echo.channel(
        "user_to_company_group_chat_by_user." + authUser.id
      ).listen(".group_updated", companyGroupUpatedReceived);

      return () => {
        window.Echo.channel("user_to_user_chat." + authUser.id).stopListening(
          ".on_message",
          onUserToUserMessageListener
        );

        if (authUser.groups && authUser.groups.data) {
          authUser.groups.data.forEach((group) => {
            window.Echo.channel(
              "user_to_company_group_chat." + group.id
            ).stopListening(".on_message", onUserToGroupMessageListener);
          });
        }

        if (authUser.chatGroups && authUser.chatGroups.data) {
          authUser.chatGroups.data.forEach((group) => {
            window.Echo.channel("user_to_group_chat." + group.id).stopListening(
              ".on_message",
              onUserToChatGroupMessageListener
            );
          });
        }
        window.Echo.channel(
          "user_to_group_chat_by_user." + authUser.id
        ).stopListening(".group_updated", chatGroupUpatedReceived);

        window.Echo.channel(
          "user_to_company_group_chat_by_user." + authUser.id
        ).stopListening(".group_updated", companyGroupUpatedReceived);
      };
    }
  }, [
    selectedHeader,
    chatGroupUpatedReceived,
    companyGroupUpatedReceived,
    authUser,
  ]);

  useEffect(() => {
    dispatch(fetchTotalUnreadChatMessages());
  }, []);

  useEffect(() => {
    if (totalUnreadChatMessages) {
      var total = totalUnreadChatMessages.reduce(
        (total, item) => total + parseInt(item.total),
        0
      );

      if (total != generalTotalUnreadChatMessages) {
        updateGeneralTotalUnreadChatMessages(total);
      }
    }
  }, [totalUnreadChatMessages]);

  return authUser &&
    authUser.enable_websockets &&
    authUser.enable_chat &&
    authUser.organization &&
    authUser.organization.data &&
    authUser.organization.data.enable_websockets ? (
    <ChannelsProvider authToken={Usertoken} authUserId={authUser?.id}>
      <div
        className={classes.chatWrapper}
        style={{
          minHeight: customHeight
            ? (100 - customHeight).toString() + "vh"
            : "100vh",
        }}
      >
        <Grid
          style={{
            height: customHeight
              ? (98 - customHeight).toString() + "vh"
              : "98vh",
            background: "rgb(251, 251, 251)",
          }}
          container
          spacing={0}
        >
          <Grid
            item
            xs={customHeight ? 3 : 4}
            style={{
              height: customHeight
                ? (98 - customHeight).toString() + "vh"
                : "98vh",
              borderRight: "1px solid #8080801f",
            }}
          >
            <HeadersSidebar
              customHeight={customHeight}
              setSelectedMessage={setSelectedMessage}
              setGetMessagesNeeded={setGetMessagesNeeded}
              getMessagesNeeded={getMessagesNeeded}
              headers={headers}
              setHeaders={setHeaders}
              selectedHeader={selectedHeader}
              setSelectedHeader={setSelectedHeader}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>

          <Grid
            item
            xs={customHeight ? 9 : 8}
            style={{
              height: customHeight
                ? (98 - customHeight).toString() + "vh"
                : "98vh",
            }}
          >
            <ConversationPanel
              customHeight={customHeight}
              selectedMessage={selectedMessage}
              setSelectedMessage={setSelectedMessage}
              setGetMessagesNeeded={setGetMessagesNeeded}
              getMessagesNeeded={getMessagesNeeded}
              headers={headers}
              setSelectedHeader={setSelectedHeader}
              setHeaders={setHeaders}
              selectedHeader={selectedHeader}
              selectedTab={selectedTab}
            />
          </Grid>
        </Grid>
      </div>
    </ChannelsProvider>
  ) : (
    <div style={{ width: "100%", display: "grid", justifyContent: "center" }}>
      <div className={classes.alertContainer}>
        <Iconify
          className={classes.alertIcon}
          icon="mdi:message-bulleted-off"
        />
        You don't have chat enabled
      </div>
    </div>
  );
};

export default InternalChat;
