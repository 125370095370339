import React from "react";
import "./Clockstyle.css"
import moment from "moment";
// function component

var m = '';
const AnimatedCard = ({ animation, digit }) => {
    return(
      <div className={`flipCard ${animation}`}>
        <span>{digit}</span>
      </div>
    );
  };
  
  // function component
  const StaticCard = ({ position, digit }) => {
    return(
      <div className={position}>
        <span>{digit}</span>
      </div>
    );
  };
  
  // function component
  const FlipUnitContainer = ({ digit, shuffle, unit }) => {	

    // assign digit values
    let currentDigit = digit;
    let previousDigit = digit - 1;
  
    // to prevent a negative value
    if ( unit !== 'hours') {
      previousDigit = previousDigit === -1 
        ? 59 
        : previousDigit;
    } else {
      previousDigit = previousDigit === -1 
        ? 23 
        : previousDigit;
    }
  
    // add zero
    if ( currentDigit < 10 ) {
      currentDigit = `0${currentDigit}`;
    } 
    if ( previousDigit < 10 ) {
      previousDigit = `0${previousDigit}`;
    }
  
    // shuffle digits
    const digit1 = shuffle 
      ? previousDigit 
      : currentDigit;
    const digit2 = !shuffle 
      ? previousDigit 
      : currentDigit;
  
    // shuffle animations
    const animation1 = shuffle 
      ? 'fold' 
      : 'unfold';
    const animation2 = !shuffle 
      ? 'fold' 
      : 'unfold';
  
    return(
      <div className={'flipUnitContainer'}>
        <StaticCard 
          position={'upperCard'} 
          digit={currentDigit} 
          />
        <StaticCard 
          position={'lowerCard'} 
          digit={previousDigit} 
          />
        <AnimatedCard 
          digit={digit1}
          animation={animation1}
          />
        <AnimatedCard 
          digit={digit2}
          animation={animation2}
          />
      </div>
    );
  };
  
  // class component
  class FlipClock extends React.Component {
      
    constructor(props) {
          super(props);
          this.state = {
              hours: 0,
              hoursShuffle: true,
              minutes: 0,
              minutesShuffle: true,
              seconds: 0,
              secondsShuffle: true,
              m: ''
          };
      }
    

      
      componentDidMount() {
          this.timerID = setInterval(
              () => this.updateTime(),
              50
          );
      }
    
      componentWillUnmount() {
          clearInterval(this.timerID);
      }
    
      updateTime() {
          // get new date
          const time = new Date;
          // set time units
          const hours = parseInt(moment().format("hh:mm A").substring(0,2)) ;
          const minutes = time.getMinutes();
          const seconds = time.getSeconds();
          m = moment().format("hh:mm A").substring(6);
          // on hour chanage, update hours and shuffle state
          if( hours !== this.state.hours) {
              const hoursShuffle = !this.state.hoursShuffle;
              this.setState({
                  hours,
                  hoursShuffle
              });
          }
          // on minute chanage, update minutes and shuffle state
          if( minutes !== this.state.minutes) {
              const minutesShuffle = !this.state.minutesShuffle;
              this.setState({
                  minutes,
                  minutesShuffle
              });
          }
          // on second chanage, update seconds and shuffle state
          if( seconds !== this.state.seconds) {
              const secondsShuffle = !this.state.secondsShuffle;
              this.setState({
                  seconds,
                  secondsShuffle
              });
          }
      }
    
      render() {
      
      // state object destructuring
          const { 
        hours, 
        minutes, 
        seconds, 
        hoursShuffle, 
        minutesShuffle, 
        secondsShuffle 
      } = this.state;
          
      return(
              <div className={'flipClock'}>
                  <FlipUnitContainer 
                      unit={'hours'}
                      digit={hours} 
                      shuffle={hoursShuffle} 
                      />
                      <p style={{fontSize: 20, fontWeight: "bold", marginTop: '3px'}}>:</p>
                  <FlipUnitContainer 
                      unit={'minutes'}
                      digit={minutes} 
                      shuffle={minutesShuffle} 
                      />
                      {/* <p style={{fontSize: 20, fontWeight: "bold", marginTop: '3px'}}>:</p>
                        

                          <FlipUnitContainer 
					unit={'seconds'}
					digit={seconds} 
					shuffle={secondsShuffle} 
					/>*/}
            <p style={{fontSize: 15, fontWeight: "bold", marginTop: '-1px'}}>{m}</p>
              </div>
          );
      }
  }
  
  // function component
 
  
  // function component
  export default function Clock () {
    
    return (
      <div key={"FlipClock"}>
        <FlipClock />
      </div>
    );
  };
  
 