import React, { useEffect, useState, useCallback } from "react";
import {
  CircularProgress,
  TextField,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import Api from "../../lib/api";
import { debounce, isEmpty } from "lodash";

const filterOptions = createFilterOptions({
  // matchFrom: "any",

  stringify: (option) => option.title + option.phone /*+ option.email*/,
});

function filterBySpaceSeparatedInputValue(options, state) {
  const terms = state.inputValue.split(" ").map((term) => {
    return new RegExp(term, "i");
  });
  return options.filter((option) => {
    return terms.some((term) => {
      return term.test(option.full_name + option.phone /* + option.email*/);
    });
  });
}

export default function AutocompleteAsyncControl(props) {
  const {
    user,
    label,
    onChange,
    value,
    disabled,
    disableClear,
    required,
    id,
    className,
    fullWidth = false,
    loadingB = true,
    ...other
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState();

  const [inputValue, setInputValue] = useState(null);
  const loading = open && options.length === 0 && loadingB;

  const debouncedSave = useCallback(
    debounce((newValue) => getContact(newValue), 1500),
    []
  );

  const getContact = async (search) => {
    if (search && search != authUser.phone) {
      // setLoading(true);
      const params = new URLSearchParams([["search", `${search}`]]);

      const { data } = await Api.getSearchContactsMessages(
        authUser.organization_id,
        `?1=1&${params.toString()}`
      );
      const renderName = (user) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Box m={1}>
                <Typography variant="p" inline style={{ color: "#2196f3" }}>
                  {user.phone}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box m={1}>
                <Typography variant="p" inline style={{ fontWeight: 600 }}>
                  {user.full_name}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              {!user.user ? (
                <Box m={1}>
                  <Typography variant="p" inline style={{ color: "#d50000" }}>
                    {" owner: "} {user.owner_name ? user.owner_name : "None"}
                  </Typography>
                </Box>
              ) : (
                <Box m={1}>
                  <Typography variant="p" inline style={{ color: "#d50000" }}>
                    {"System User"}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        );
      };

      let data1 = data.data.map((user) => {
        return {
          id: user.id,
          name: renderName(user),
          full_name: user.full_name,
          phone: user.phone,
          fname: user.fname,
          lname: user.lname,
          user: user.user ? user.user : null,
          // email: user.email,
          //  owner_name: user.owner_name ? user.owner_name : "",
        };
      });

      var regex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      // console.log("search", search);
      if (isEmpty(data1) && search.match(regex)) {
        //console.log("numbers", search);
        data1 = [
          {
            id: -1,
            name: search,
            full_name: search,
            phone: search,
            fname: "",
            lname: "",
            //  email: "",
          },
        ];
      }

      setOptions(data1);

      // setLoading(false);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    //getContact(inputValue);

    return () => {
      active = false;
    };
  }, [loading, inputValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id ? id : "new"}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      className={className}
      filterOptions={filterBySpaceSeparatedInputValue}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      inputValue={inputValue ? inputValue : value ? value.name : ""}
      options={options}
      onChange={(event, newInputValue) => {
        //console.log(newInputValue);
        onChange(newInputValue);
      }}
      fullWidth={fullWidth}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        debouncedSave(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : ""}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress style={{ color: "#0d47a1" }} size={18} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
