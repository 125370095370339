import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  AppBar,
  Badge,
  IconButton as MuiIconButton,
  Typography,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { logOutPatientService } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import Api from "../../../lib/api";
import PollMenu from "./pollsSection";
import Iconify from "../../../components/Iconify";
import ChangePatientPasswordForm from "../portalComponentsNew/ChangePatientPasswordForm";
import Notification from "../../../components/Notification";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: #fff;
    color: red;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  badge: {
    marginRight: 20,
  },
  menu_grid: {
    padding: 0,
    margin: 0,
  },
}));

function UserMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const dispatch = useDispatch();
  const [openPasswordPopup, setOpenPasswordPopup] = useState(false);
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const { token } = useParams();   
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const doLogout = () => {
    var payload = {token: token};
    dispatch(logOutPatientService(payload));
    //window.location = "/portal/auth";
  };

  const openPasswordConfiguration = () => {
    closeMenu();
    setOpenPasswordPopup(true);
  };

  const onClosePasswordPopup = () => {
    setOpenPasswordPopup(false);
  };

  const changePassword = () => {};

  return (
    <React.Fragment>
      <IconButton
        
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        style={{color: "rgb(44 99 120)" }}
      >
        <PowerSettingsNewIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={openPasswordConfiguration}>
          <Iconify
            style={{ fontSize: 21, marginRight: 4, color: "#4a4a70" }}
            icon="uis:padlock"
          />
          Change password
        </MenuItem>
        <MenuItem onClick={doLogout}>
          <Iconify
            style={{ fontSize: 21, marginRight: 4, color: "#4a4a70" }}
            icon="ic:round-log-out"
          />
          Sign out
        </MenuItem>
      </Menu>
      <Dialog fullWidth open={openPasswordPopup}>
        <ChangePatientPasswordForm
          setNotify={setNotify}
          onClose={onClosePasswordPopup}
        />
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </React.Fragment>
  );
}

const Observer = ({ value, didUpdate }) => {
  useEffect(() => {
    didUpdate(value);
  }, [value]);
  return null; // component does not render anything
};

// const Header = (props) => {
var _this;
class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      countMessages: 0,
      timer: null,
      checkinterval: null,
    };
    _this = this;
  }

  static countMessages = null;

  componentDidMount() {
    this.setState({
      timer: setInterval(() => {
        this.setState({ checkinterval: Date.now() });
      }, 10000),
    });

    Header.getMessagesUnRead();
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  static async getMessagesUnRead() {
    let result = 0;
    await Api.getPortalMessagesUnRead()
      .then((response) => {
        result = response.data;
        _this.setState({ countMessages: result });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <AppBar
          position="sticky"
          elevation={0}
          style={{ backgroundColor: "rgb(245 245 245 / 86%)" }}
        >
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="h6" style={{ color: "rgb(44 99 120)" }}>
                  SURGERY PORTAL
                </Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    localStorage.setItem("portalTab", 3);
                    window.dispatchEvent(new Event("storage"));
                  }}
                  color="inherit"
                  style={{color: "rgb(44 99 120)" }}
                >
                  <Observer
                    value={this.state.checkinterval}
                    didUpdate={Header.getMessagesUnRead}
                  />
                  <Indicator badgeContent={this.state.countMessages}>
                    <Iconify icon = "ion:notifications" style={{ color: "rgb(44 99 120)" }} />
                  </Indicator>
                  {/* <Link to={"/portal2/3"}>
                   
                  </Link> */}
                </IconButton>
                {/*
                  <IconButton color="inherit">
                  <Indicator badgeContent={7}  >
                    <ChatBubbleIcon />
                  </Indicator>
                </IconButton>
                   */}
                <PollMenu interval={this.state.checkinterval} />

                <UserMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default Header;
