import React, { forwardRef, useImperativeHandle } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

var chipsDataExample = [
  { key: 0, label: "Angular" },
  { key: 1, label: "jQuery" },
  { key: 2, label: "Polymer" },
  { key: 3, label: "React" },
  { key: 4, label: "Vue.js" },
];

//export default function ChipsArray(props) {
const ChipsArray = forwardRef((props, ref) => {
  const { chipsItems, onDeleteChipItem } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  //const [chipData, setChipData] = React.useState(chipsItems);
  const chipsItems1 = chipsItems ? chipsItems.map((o) => ({ ...o })) : null;

  useImperativeHandle(ref, () => ({
    refresh() {
      console.log("ChipsArray--refresh");
    },
  }));

  const handleDelete = (chipToDelete) => () => {
    if (onDeleteChipItem) {
      onDeleteChipItem(chipToDelete);
    }
    //setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  function onDeleteChip(chipToDelete) {
    if (onDeleteChipItem) {
      dispatch(onDeleteChipItem(chipToDelete));
    }
  }

  return (
    <Paper component="ul" className={classes.root}>
      {chipsItems1.map((data) => {
       
        let icon;
        return (
          <li key={data.key}>
            <Chip
              icon={icon}
              label={data.label}
              onDelete={handleDelete(data)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Paper>
  );
});
export default ChipsArray;
