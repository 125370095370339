import { CssBaseline, Paper } from "@material-ui/core";
import React, {  } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { isWidthUp } from "@material-ui/core/withWidth";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: url(/portal_background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const MainContent = styled(Paper)`
  flex: 1;
  padding: 0px 20px;
  width: 65%;
  background: none;
  display: flex;
  justify-content: center;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
const PortalAuthDashboard = ({ children, routes, width }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default PortalAuthDashboard;
