import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { isEmpty, uniqueId, map, findIndex, capitalize, find } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import MaterialTable from "material-table";

import moment from "moment";
import TableFooter from "@material-ui/core/TableFooter";
import TableContainer from "@material-ui/core/TableContainer";
import SaveIcon from "@material-ui/icons/Save";
import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Button as MuiButton,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Input,
} from "@material-ui/core";
import { spacing, display } from "@material-ui/system";

import AddIcon from "@material-ui/icons/Add";

import { useDispatch, useSelector } from "react-redux";

import Controls from "./controls";
import Popup from "./Popup";
import CreditMemoForm from "./forms/CreditMemoForm";
import Notification from "./Notification";
import Api from "../lib/api";

import {
  fetchCreditMemosByContactService,
  createCreditMemoService,
} from "../redux/slices/creditmemos";

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  included_margin: {
    width: "100%",
    margin: "25px 0px",
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function CreditMemosQuotes(props) {
  const classes = useStyles();
  const { handleSubmit, setValue, reset, control, errors, watch, getValues } =
    useForm();
  const { contact, invoice, applyQuoteCreditMemoupdate } = props;
  const { creditMemos } = useSelector((state) => state.creditMemos);
  const [creditMemoReasons, setCreditMemoReasons] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const [selectedCreditMemo, setSelectedCreditMemo] = useState(-1);
  const [creditSelected, setCreditSelected] = useState(null);
  const [availablecredit, setAvailablecredit] = useState(0);
  const fields = watch();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getCreditMemo = () => {
    return find(creditMemos, { id: selectedCreditMemo });
  };

  const onSubmit = async (data) => {
    const items = invoice.items.data
      .filter((a) => a.parent_id == null)
      .map((item, index) => {
        return {
          item: item.id,
          amount: parseFloat(fields.itemsAmount[index]) || 0,
        };
      })
      .filter((item) => {
        return item.amount > 0;
      });
    const payload = {
      quote_id: invoice.id,
      user_id: authUser.id,
      credit_memo_id: selectedCreditMemo,
      amount: calculeTotal2Pay(),
      creditmemodetail: JSON.stringify(items),
    };
    if (parseFloat(payload.amount) <= 0) {
      setNotify({
        isOpen: true,
        message: "Credit memo to apply can't be less than 1",
        type: "error",
      });
    } else {
      if (availablecredit >= payload.amount) {
        applyQuoteCreditMemoupdate(payload);
        /*
                await Api.applyInvoiceCreditMemo(invoice.id, payload).then(
                  (updatedResult) => {
                    applyInvoiceCreditMemoupdate(updatedResult.data.data);
                    console.log(updatedResult);
                  }
                );
                */
      } else {
        setNotify({
          isOpen: true,
          message:
            "The credit amount applied to invoice items has to be equal or less than Credit Memo selected",
          type: "error",
        });
      }
    }
    return;
    /*
        const items = creditMemos
          .map((item, index) => {
            return {
              invoice_id: invoice.id,
              credit_memo_id: item.id,
              amount: parseFloat(fields.creditMemosAmount[index]) || 0,
              user_id: authUser.id,
            };
          })
          .filter((item) => {
            return item.amount > 0;
          });
    
        const itemsdetails = creditMemos
          .map((item, index) => {
            return {
              invoice_id: invoice.id,
              credit_memo_id: item.id,
              amount: parseFloat(fields.creditMemosAmount[index]) || 0,
              user_id: authUser.id,
            };
          })
          .filter((item) => {
            return item.amount > 0;
          });
    
        const payload = {
          items: items,
        };
    
        
        if (calculeTotaltoApply() != calculeTotal2Pay()) {
          setNotify({
            isOpen: true,
            message: "Credit Memo amount has to be the same applied to the invoice items",
            type: "error",
          });
        } else {
          await Api.applyInvoiceCreditMemo(invoice.id, payload).then(
            (updatedResult) => {
              applyInvoiceCreditMemoupdate(updatedResult.data.data);
            }
          );
        }
        */
  };

  const calculeTotaltoApply = () => {
    if (!fields.itemsAmount) return 0;

    return fields.creditMemosAmount.reduce(
      (total, item) => total + parseFloat(item),
      0
    );
  };

  const calculeAvailable = (item) => {
    return item.amount - item.used;
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return item.type == "package"
      ? (item.package_price ? item.package_price : 0) * parseInt(quantity) -
          discount * parseInt(quantity)
      : item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
          discount * (item.price_includes_qty ? 1 : parseInt(quantity));
  };

  const calculeTotal2Pay = () => {
    if (!fields.itemsAmount) return 0;

    return fields.itemsAmount.reduce(
      (total, item) => total + parseFloat(item),
      0
    );
  };

  const updateCreditMemoSelected = (itemId) => {
    setSelectedCreditMemo(itemId);
    const creditSelected = find(creditMemos, { id: itemId });

    if (creditSelected) {
      setCreditSelected(creditSelected);
      setAvailablecredit(calculeAvailable(creditSelected).toFixed(2));
    } else {
      setCreditSelected(null);
      setAvailablecredit(0);
    }
  };

  const calculateItemBalance = (item) => {
    const itemPrice = item.price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;
    let itemRefunds = 0;
    let refunddetails;

    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      itemPayments = paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds = refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }
    if (item.creditmemodetail) {
      itemCredits = item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }
    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;
    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const creditMemoOptions = () => {
    return creditMemos.map((credit) => {
      const available = calculeAvailable(credit).toFixed(2);
      return {
        id: credit.id,
        title: `${available} for credit created at ${moment(
          credit.created_at
        ).format("YYYY-MM-DD")}`,
        available: available,
      };
    });
  };

  const calculatePackageBalance = (item) => {
    const itemPrice = item.package_price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;

    let itemRefunds = 0;
    let refunddetails;

    item.childs.forEach((child) => {
      if (child.paymentwith_payments_detail) {
        const paymentdetails = child.paymentwith_payments_detail.filter(
          (paymentdetail) => {
            return !paymentdetail.isRefund;
          }
        );

        itemPayments += paymentdetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);

        refunddetails = child.payment_with_refunds_detail.filter(
          (paymentdetail) => {
            return paymentdetail.isRefund;
          }
        );

        if (refunddetails.length > 0) {
          itemRefunds += refunddetails.reduce((total, payment) => {
            return total + parseFloat(payment.amount);
          }, 0);
        }
      }

      if (child.credit_memo_detail) {
        itemCredits += child.credit_memo_detail.reduce((total, creditmemo) => {
          return total + parseFloat(creditmemo.amount);
        }, 0);
      }
    });

    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      itemPayments += paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds += refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    if (item.creditmemodetail) {
      itemCredits += item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }

    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;
    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const renderInvoiceItems = () => {
    return invoice.items.data
      .filter((a) => a.parent_id == null)
      .map((item, index) => {
        const balance =
          item.type == "package"
            ? calculatePackageBalance(item)
            : calculateItemBalance(item);

        return (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              {item.name}
            </TableCell>
            <TableCell align="right">
              {" "}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>{item.quantity || 1}</Typography>
                {item.void_quantity ? (
                  <Typography
                    style={{
                      fontWeight: 500,
                      marginLeft: 5,
                      color: "#a76c65",
                      fontSize: 12,
                    }}
                  >{`(${item.void_quantity} VOID)`}</Typography>
                ) : (
                  ""
                )}
              </div>
            </TableCell>
            <TableCell align="right">
              $ {item.type == "package" ? item.package_price : item.price}
            </TableCell>
            <TableCell align="right">
              $ {calculateItemTotal(item).toFixed(2)}
            </TableCell>
            <TableCell align="right">$ {balance.toFixed(2)}</TableCell>
            <TableCell align="right">
              <Controller
                name={`itemsAmount[${index}]`}
                className={classes.inputItemAmount}
                control={control}
                defaultValue="0"
                as={
                  <Controls.InputMoney
                    disabled={item.void ? true : false}
                    //maximumValue={balance.toFixed(2)}
                    //disabled={balance <= 0 ? true : false}
                  />
                }
              />
            </TableCell>
            <TableCell align="right">{item.void === 1 ? "VOID" : ""}</TableCell>
          </TableRow>
        );
      });
  };

  const renderCreditMemos = () => {
    return creditMemos.map((item, index) => {
      const available = calculeAvailable(item).toFixed(2);
      return (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            {new moment(item.created_at).format("YYYY-MM-DD")}
          </TableCell>
          <TableCell align="right">{item.amount}</TableCell>
          <TableCell align="right">{available}</TableCell>{" "}
          {
            //change to Available
          }
          <TableCell align="right">
            <Controller
              name={`creditMemosAmount[${index}]`}
              className={classes.inputItemAmount}
              control={control}
              defaultValue="0"
              as={<Controls.InputMoney maximumValue={available} />}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const saveCreditMemo = (data) => {
    if (!isEmpty(authUser)) {
      var payload = {
        amount: data.amount,
        details: data.details,
        contact_id: contact.id,
        user_id: authUser.id,
        creditmemo_reason_id: data.creditmemo_reason_id,
        organization_id: authUser.organization_id,
      };

      dispatch(createCreditMemoService(payload)).then((result) => {
        // console.log("createCreditMemoService ", result);
      });
    }

    closeNewCreditMemoPopup();
  };

  const closeNewCreditMemoPopup = () => {
    setOpenPopup(false);
  };

  const openNewPopup = () => {
    setOpenPopup(true);
  };

  const credit = getCreditMemo();

  const getCreditMemoReasons = async () => {
    const { data } = await Api.getAllCreditMemoReason(authUser.organization_id);
    setCreditMemoReasons([...data.data]);
  };

  useEffect(() => {
    const orgId = authUser.organization_id;

    dispatch(fetchCreditMemosByContactService(contact.id));

    getCreditMemoReasons();
  }, []);

  return (
    <Shadow>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Card>
          <CardContent>
            <Grid container spacing={6}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Controls.Select
                    label="Credit Memo"
                    allowEmpty={true}
                    emptyLabel="Select one credit"
                    options={creditMemoOptions()}
                    onChange={(e) => {
                      updateCreditMemoSelected(e.target.value);
                    }}
                    value={selectedCreditMemo}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={openNewPopup}
                  >
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>

              {creditSelected && (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {moment(creditSelected.created_at).format(
                              "YYYY-MM-DD"
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {creditSelected.amount}
                          </TableCell>
                          <TableCell align="right">
                            {(
                              creditSelected.amount - creditSelected.used
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <Typography component="div">
                          <Box fontWeight="fontWeightMedium" m={1}>
                            Quote Items:
                          </Box>
                        </Typography>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">{"Balance"}</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{renderInvoiceItems()}</TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={6} align="right">
                            Total to Apply: {calculeTotal2Pay()}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Controls.Button
                    disabled={selectedCreditMemo <= 0}
                    type="submit"
                    text="Apply"
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
      <Popup
        fullWidth={true}
        title={"New Credit Memo"}
        openPopup={openPopup}
        onClose={closeNewCreditMemoPopup}
        maxWidth={"xs"}
      >
        <CreditMemoForm
          contact={contact}
          onSaveCreditMemo={saveCreditMemo}
          creditMemoReasons={creditMemoReasons}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </Shadow>
  );
}
export default CreditMemosQuotes;
