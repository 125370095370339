import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";
import moment from "moment";

const InvoiceOption = ({ option, selected }) => {
    return (
        <Card
            style={{
                width: "100%",
            }}
        >
            <CardContent
                style={{
                    backgroundColor: selected ? "lightgreen" : "inherit",
                }}
            >
                <Grid container direction="row">
                    <Grid item container direction="column" md={4}>
                        <Typography variant="subtitle">
                            {option.name}
                        </Typography>
                        <Typography
                            variant="subtitle"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {moment(option.created_at).format("d/MM/YYYY")}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            ${option.totalPrice.toFixed(2)}
                        </Typography>
                        <Typography
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option.items.length} items
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" md={4}>
                        <Typography variant="caption">Contact</Typography>
                        <Typography variant="subtitle1">
                            {option.contact.fname + " " + option.contact.lname}
                        </Typography>
                        <Typography
                            noWrap
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option.contact?.email}
                        </Typography>
                        <Typography
                            noWrap
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option.contact?.phone}
                        </Typography>
                        <Typography
                            noWrap
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option?.payments?.length ?? 0} payments
                        </Typography>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            ${option?.balance?.toFixed(2)} Amount Due
                        </Typography>
                    </Grid>

                    <Grid item container direction="column" md={4} spacing={2}>
                        {option?.user ? (
                            <>
                                <Typography variant="caption">User</Typography>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    spacing={2}
                                >
                                    <Grid item xs={3}>
                                        <Avatar
                                            alt={option?.user?.title}
                                            src={option?.user?.avatar}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="subtitle1">
                                            {option?.user?.title}
                                        </Typography>
                                        <Typography
                                            noWrap
                                            variant="body"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            {option?.user?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Typography>No user</Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default InvoiceOption;
