import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
    width: "100%",
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
}));

export default function BMI(props) {
  const { height, weight, setBmiform } = props;

  const classes = useStyles();

  const BmiLabel = () => {
    let bmiResults = {
      label: "",
      color: "",
    };
    const bmi2 = BmiCalc();
    if (bmi2) {
      const bmi2 = BmiCalc();

      if (bmi2 <= 18.5) {
        bmiResults.label = "Underweight";
        bmiResults.color = "#721c24";
      } else if (bmi2 <= 24.9) {
        bmiResults.label = "Normal weight";
        bmiResults.color = "#004085";
      } else if (bmi2 <= 29.9) {
        bmiResults.label = "Overweight";
        bmiResults.color = "#856404";
      } else if (bmi2 >= 30) {
        bmiResults.label = "Obesity";
        bmiResults.color = "#721c24";
      }
    } else {
      bmiResults.label = "";
      bmiResults.color = "white";
    }
    return bmiResults;
  };

  const BmiCalc = () => {
    const heightsplit = height ? height.split(".") : [];
    const heightft = heightsplit[0] ? heightsplit[0] * 12 : 0;
    const heightin = heightsplit[1] ? heightsplit[1] : 0;

    const height_inchs = parseInt(heightft) + parseInt(heightin);

    const result = height_inchs
      ? ((weight / (height_inchs * height_inchs)) * 703).toFixed(1)
      : 0.0;
    setBmiform(result);
    return result;
  };

  return (
    <div className={classes.root}>
      <div className={classes.section1}>
        <Divider variant="middle" />
        <div className={classes.section2}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography gutterBottom variant="h4">
                BMI
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="h6">
                {BmiCalc()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {BmiCalc() > 0 && (
                <Chip
                  className={classes.chip}
                  color="primary"
                  style={{ backgroundColor: BmiLabel().color }}
                  label={BmiLabel().label}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <Divider variant="middle" />
      </div>
    </div>
  );
}
