import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import useTable from "./useTable";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Api from "../lib/api";
import { useEffect } from "react";
import Controls from "./controls";
import Iconify from "./Iconify";
import CRMUtils from "../utils";
import Popup from "./Popup";
import PaymentLinkRequest from "./PaymentLinkRequest";
import { findIndex } from "lodash";
import { useSelector } from "react-redux";
import ConfirmDialog from "./ConfirmDialog";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
}));

function PaymentLinkRequests(props) {
  const classes = useStyles();
  const { invoiceID, setNotify } = props;

  const { TblContainer } = useTable();
  const [paymentLinkRequests, setPaymentLinkRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dataMaterialTable = paymentLinkRequests.map((o) => ({ ...o }));
  const { authUser } = useSelector((state) => state.auth);
  const userCanClosePaymentLinkRequest = CRMUtils.userHasPermission(authUser, [
    "paymentlinkrequest_close",
  ]);
  const userCanClosePaymentLinkRequestSuper = CRMUtils.userHasPermission(
    authUser,
    ["paymentlinkrequest_closesuper"]
  );
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [entityForEdit, setEntityForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const statuses = [
    {
      id: "pending",
      bcColor: "rgb(247 255 183)",
      color: "rgb(125 127 0)",
      label: "PENDING",
    },
    { id: "rejected", bcColor: "#ffe3d8", color: "#b44a20", label: "REJECTED" },
    { id: "approved", bcColor: "#ddffdf", color: "#069e22", label: "APPROVED" },
  ];

  const headCellsMaterial = [
    {
      field: "requested_at",
      title: "Requested at",
    },
    {
      field: "requested_by",
      title: "Requested by",
      render: (rowData) => rowData?.requestedBy?.data?.dropdown_name || "",
    },
    { field: "card_number", title: "Card Number" },
    {
      field: "max_amount",
      title: "Max amount",
      render: (rowData) =>
        rowData.max_amount !== null ? "$" + rowData.max_amount : "",
    },
    {
      field: "credit_card_authorization",
      title: "Credit Card Authorization",
      render: (rowData) => (
        <a href={rowData.media_url} target="_blank" rel="noreferrer">
          <AttachFileIcon />
        </a>
      ),
    },

    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        var status = statuses.find((s) => s.id == rowData.status);

        if (!status) {
          status = {
            id: rowData.status,
            bcColor: "#eff0ef",
            color: "#282828",
            label: rowData.status.replace(/^\w/, (c) => c.toUpperCase()),
          };
        }
        return (
          <Chip
            label={status.label}
            style={{
              color: status.color,
              backgroundColor: status.bcColor,
              fontWeight: 500,
            }}
          />
        );
      },
    },
    {
      field: "closed_by",
      title: "Closed by",
      render: (rowData) => rowData?.closedBy?.data?.dropdown_name || "",
    },
    {
      field: "closed_at",
      title: "Closed at",
    },
  ];

  const onClickEdit = async (event, rowData) => {
    setEntityForEdit(rowData);
    setOpenPopup(true);
  };

  const resendLink = async (id) => {
    setConfirmDialogLoading(true);
    await Api.resendPaymentLinkRequestLink(id);

    setNotify({
      isOpen: true,
      message: "Sent successfully!",
      type: "success",
    });
    setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    setConfirmDialogLoading(false);
  };

  const extraActions = [
    {
      action: (rowData) => {
        return {
          icon: () => (
            <Controls.IconButton
              style={{
                color: rowData.status != "pending" ? "gray" : "#009688",
              }}
            >
              <Iconify icon={"mdi:list-status"} width={25} height={25} />
            </Controls.IconButton>
          ),
          tooltip: "Close",

          hidden:
            (!userCanClosePaymentLinkRequest &&
              !userCanClosePaymentLinkRequestSuper) ||
            rowData.status != "pending",
          onClick: (event) => {
            event.stopPropagation();
            onClickEdit(event, rowData);
          },
          position: "row",
        };
      },
    },
    {
      action: (rowData) => {
        return {
          icon: () => (
            <Controls.IconButton
              style={{
                color:
                  rowData.status != "approved" ||
                  !rowData.is_link_valid_without3ds
                    ? "gray"
                    : "#009688",
              }}
            >
              <Iconify icon={"ic:round-send"} width={25} height={25} />
            </Controls.IconButton>
          ),
          tooltip: "Resend link",

          hidden:
            rowData.status != "approved" || !rowData.is_link_valid_without3ds,
          onClick: (event) => {
            event.stopPropagation();
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to resend the non-3DS link to the contact?",
              subTitle: "You can't undo this operation",
              onConfirm: () => resendLink(rowData.id),
            });
          },
          position: "row",
        };
      },
    },
  ];

  const getPaymentLinkRequests = async () => {
    setIsLoading(true);
    const { data } = await Api.getInvoicePaymentLinkRequests(invoiceID);

    setPaymentLinkRequests([...data.data]);
    setIsLoading(false);
  };

  const onClose = () => {
    setOpenPopup(false);
    setEntityForEdit(null);
  };

  useEffect(() => {
    getPaymentLinkRequests();
  }, []);

  const updatePaymentLinkRequestInState = (obj) => {
    const index = findIndex(paymentLinkRequests, ["id", obj.id]);

    const updatedEvent = {
      ...paymentLinkRequests[index],
      ...obj,
    };
    let newPaymentLinkRequests = [...paymentLinkRequests];
    newPaymentLinkRequests[index] = updatedEvent;
    setPaymentLinkRequests([...newPaymentLinkRequests]);
  };

  const onSuccessfulSubmit = (data) => {
    updatePaymentLinkRequestInState(data);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={isLoading}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              loadingType: "linear",
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              sorting: true,
            }}
            actions={extraActions}
            title="Payment Link Requests"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Payment Link Request"}
        openPopup={openPopup}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <PaymentLinkRequest
          paymentLinkRequest={entityForEdit}
          onSuccessfulSubmit={onSuccessfulSubmit}
          onClose={onClose}
          setNotify={setNotify}
        />
      </Popup>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />
    </Wrapper>
  );
}

export default PaymentLinkRequests;
