import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export default function Notification(props) {
  const { notify, setNotify, autoHide = 3000, zindex = 10000000 } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };
  return (
    <Snackbar
      open={notify.isOpen}

      autoHideDuration={autoHide}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      
      style={{ zIndex: zindex }}
    >
      <Alert
      
        severity={notify.type}
        onClose={handleClose}
        style={{ display: "flex", alignItems: "center", zIndex: zindex }}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
}
