import React, { useState, useEffect, useRef } from "react";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../components/controls";
import moment from "moment";
import Zoom from "@material-ui/core/Zoom";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./style.css";
import { useTranslation } from "react-i18next";

import Actions from "../components/actions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "90%",
      margin: theme.spacing(1),
    },
  },
  fab: {
    position: "relative",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

var entityFilter = [];

function setupInput(element, control, props) {
  return (
    <>
      {" "}
      <Controller
        name={element.id}
        control={control}
        defaultValue={
          props.entityForEdit ? props.entityForEdit[element.id] : ""
        }
        as={<Controls.Input label={element.label} />}
        rules={element.required != null ? { required: element.required } : null}
      />
    </>
  );
}
function setupDate(element, control, props) {
  return (
    <Controller
      name={element.id}
      control={control}
      defaultValue={
        props.entityForEdit
          ? props.entityForEdit[element.id]
          : moment(new Date()).format("yyyy-MM-DD")
      }
      as={({ onChange, value }) => (
        <Controls.DatePicker
          label={element.label}
          shouldCloseOnSelect
          onChange={(date) => {
            const fdate = moment(date).format("yyyy-MM-DD");
            value = fdate;
            onChange(fdate);
          }}
          value={value}
        />
      )}
    />
  );
}

function setupNumber(element, control, props) {
  return (
    <Controller
      name={element.id}
      control={control}
      defaultValue={props.entityForEdit ? props.entityForEdit[element.id] : ""}
      as={<Controls.InputMoney label={element.label} />}
      rules={element.required != null ? { required: element.required } : null}
    />
  );
}

export default function CrmTableFilter2(props) {
  const {
    addOrEdit,
    entityForEdit,
    formFields,
    saveLoading,
    filterLoading = false,
    fromDate = null,
    toDate = null,
    BackNextButtons = true,
  } = props;
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const { t, i18n } = useTranslation("common");
  const inputRef = useRef();

  const [entityForEdit1, setEntityForEdit1] = useState(entityForEdit);
  const [flag, setFlag] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const onSubmit = (data) => {
    data.from = range.from;

    data.to = range.to;

    if (flag) {
      if (formFields) {
        formFields.forEach((element, i) => {
          setValue(element.id, null);

          if (
            element.id != "date_between_from_to" &&
            element.id != "date_between"
          ) {
            data[element.id] = null;
          }
        });
      }
    }

    addOrEdit(data);

    var entity = entityForEdit1 ? entityForEdit1 : {};
    if (formFields) {
      formFields.forEach((element, i) => {
        entity[element.id] = data[element.id];
        setEntityForEdit1(entity);
      });
    }
    setFlag(false);
  };

  useEffect(() => {
    entityFilter = [];
  }, []);

  const setupAutocomplete = (element, control, props) => {
    let options = [];

    options.push({
      id: "",
      title: "--",
    });
    if (element.options != null && element.options.items != null) {
      element.options.items.forEach((e) => {
        var opt = {
          id: e[element.options.id],
          title: e[element.options.title],
        };
        options.push(opt);
      });
    }

    return (
      <Controller
        control={control}
        name={element.id}
        disabled={!element.enabled}
        as={({ onChange, value }) => (
          <Controls.Autocomplete
            label={element.label}
            disabled={!element.enabled}
            options={options}
            onChange={(value) => {
              var id = value ? value["id"] : null;
              onChange(id);
            }}
          />
        )}
      />
    );
  };

  const setupSelect = (element, control, props) => {
    let options = [];
    options.push({
      id: "",
      title: "--",
    });
    if (element.options != null && element.options.items != null) {
      element.options.items.forEach((e) => {
        options.push({
          id: e[element.options.id],
          title: e[element.options.title],
        });
      });
    }
    entityFilter[element.id] = entityFilter[element.id]
      ? entityFilter[element.id]
      : "";
    var onChangeSelect = (value) => {
      entityFilter[element.id] = value.target.value;
    };

    return (
      <Controller
        control={control}
        name={element.id}
        defaultValue={
          element ? element.default?.id || entityFilter[element.id] : ""
        }
        as={({ onChange, value }) => (
          <Controls.Select
            label={element.label}
            options={options}
            value={value ? value : entityFilter[element.id]}
            onChange={(value) => {
              onChange(value);
              onChangeSelect(value);
            }}
          />
        )}
      />
    );
  };

  const setupMultipleSelect = (element, control, props) => {
    let options = [];
    options.push({
      id: "",
      title: "--",
    });
    if (element.options != null && element.options.items != null) {
      element.options.items.forEach((e) => {
        options.push({
          id: e[element.options.id],
          title: e[element.options.title],
        });
      });
    }
    entityFilter[element.id] = entityFilter[element.id]
      ? entityFilter[element.id]
      : "";
    var onChangeSelect = (value) => {
      entityFilter[element.id] = value;
    };

    return (
      <Controller
        control={control}
        name={element.id}
        defaultValue={
          props.entityForEdit ? props.entityForEdit[element.id] : ""
        }
        as={({ onChange, value }) => (
          <Controls.MultipleSelect
            label={element.label}
            options={options}
            value={[...entityFilter[element.id]]}
            onChange={(value) => {
              onChange(value);
              onChangeSelect(value);
            }}
          />
        )}
      />
    );
  };

  const setupDateRange = (element, control, props) => {
    var onChangeDR = (date) => {
      entityFilter[element.id] = date;
    };
    return (
      <Controller
        name={element.id}
        control={control}
        defaultValue={entityFilter[element.id]}
        as={({ onChange, value }) => (
          <DateRangePicker
            onChange={(date) => {
              onChangeDR(date);
              onChange(date);
            }}
            value={entityFilter[element.id]}
          />
        )}
      />
    );
  };

  const [range, setRange] = useState({
    label: "Custom",
    from: fromDate,
    to: toDate,
  });

  const changeReportDateView = (v) => {
    switch (v.id) {
      case "-1d": // yesterday
        setRange({
          view: v.id,
          label: "Yesterday",
          from: moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"),
          to: moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),
        });

        break;
      case "-7d": // last 7 days
        setRange({
          view: v.id,
          label: "Last 7 days",
          from: moment().subtract(7, "days").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      case "-1w": // last Week
        setRange({
          view: v.id,
          label: "Last Week",
          from: moment()
            .subtract(1, "weeks")

            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "weeks")
            .endOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD"),
        });
        break;
      case "t": // today
        setRange({
          view: v.id,
          label: "Today",
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      case "m": // This month
        setRange({
          view: v.id,
          label: "This month",
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        });
        break;
      case "-1m": // Last month
        setRange({
          view: v.id,
          label: "Last month",
          from: moment()
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DD"),
        });
        break;
      case "custom": // Custom date range
        setRange({
          ...range,
          label: "Custom",
          view: v.id,
        });
        break;
      case "y": // Custom date range
        setRange({
          view: v.id,
          from: moment(`${v.label}-01-01`).format("YYYY-MM-DD"),
          to: moment(`${v.label}-12-31`).format("YYYY-MM-DD"),
        });
        //setLoading(true);
        break;
      default:
      // code block
    }
  };

  const setupDateRange2 = (element, control, props) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={2} style={{ marginBottom: 6 }}>
          <Actions onChange={changeReportDateView} />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name={"from"}
            control={control}
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.DatePicker2
                label="From"
                onChange={(date) => setRange({ ...range, from: date })}
                value={range.from}
              />
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            name={"to"}
            control={control}
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.DatePicker2
                label="To"
                onChange={(date) => setRange({ ...range, to: date })}
                value={range.to}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  const ClearFilters = () => {
    entityFilter = [];
    setRange({
      from: fromDate,
      to: toDate,
    });
  };

  const setupVisualElement = (element, control, props) => {
    var res = "";
    switch (element.type) {
      case "input":
        res = setupInput(element, control, props);
        break;
      case "select":
        res = setupSelect(element, control, props);
        break;
      case "multiple_select":
        res = setupMultipleSelect(element, control, props);
        break;
      case "number":
        res = setupNumber(element, control, props);
        break;
      case "date":
        res = setupDate(element, control, props);
        break;
      case "date_between":
        res = setupDateRange(element, control, props);
        break;
      case "date_between_from_to":
        res = setupDateRange2(element, control, props);
        break;
      default:
        break;
    }
    return res;
  };

  const items = [];

  formFields.forEach((element, i) => {
    var size = element.size ? element.size : 12;
    items.push(
      <Grid key={i} container item xs={size} alignItems="center">
        {setupVisualElement(element, control, props)}
        {errors[element.id] && (
          <p className="error">{errors[element.id].message}</p>
        )}
      </Grid>
    );
  });

  const fab = {
    color: "primary",
    className: classes.fab,
    icon: <FilterListIcon />,
    label: "Add",
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        {items}
        <Grid container item xs justify="flex-end">
          <Zoom
            key={fab.color}
            in={true}
            timeout={transitionDuration}
            unmountOnExit
          >
            <Controls.AdornedButton
              type="submit"
              variant="contained"
              color="primary"
              loading={saveLoading || filterLoading}
              startIcon={<FilterListIcon />}
            >
              {t("crmTableComponent.actions.filter")}
            </Controls.AdornedButton>
          </Zoom>

          {
            <Controls.Button
              color="primary"
              type="submit"
              // disabled={!range.from && !range.to && entityFilter.length == 0}
              startIcon={<ClearIcon />}
              onClick={() => {
                ClearFilters();
                setFlag(true);
              }}
              text="Clear"
            />
          }
        </Grid>
      </Grid>
    </form>
  );
}
