import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,
    _createEntityService,
    _updateEntityService,
    _deleteEntityService,
  } from "./common/sliceFunctions3";
  
  var entitiesName = "autoCallsHistory";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.code = "GET_AUTOCALLSHISTORY";
    //dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
  
    return _fetchEntitiesService(dataSlice, events);
  }
  export function createEntityService(dataSlice) {
    dataSlice.dataUrl.code = "POST_CONTACT";
    return _createEntityService(dataSlice, events);
  }
  
  export function updateEntityService(dataSlice) {
    dataSlice.dataUrl.code = "PUT_CONTACT";
    return _updateEntityService(dataSlice, events);
  }
  
  export function updateEntityServicePlain(dataSlice) {
    dataSlice.dataUrl.code = "PUT_CONTACT_PLAIN";
    return _updateEntityService(dataSlice, events);
  }
  
  export function deleteEntityService(dataSlice) {
    dataSlice.dataUrl.code = "DELETE_AUTOCALLS";
    return _deleteEntityService(dataSlice, events);
  }
  