import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  ListItemIcon,
  Box,
  Typography,
  FormHelperText,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      display: "flex",
      alignItems: "center",
    },
    borderRadius: 10,

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

export default function Select(props) {
  const {
    label,
    value,
    allowEmpty,
    emptyLabel,
    onChange,
    options,
    disabled,
    variant,
    loading,
    ...other
  } = props;

  const CustomLoading = () =>
    loading ? (
      <Box
        display="flex"
        style={{
          marginRight: 15,
        }}
      >
        <CircularProgress
          size={20}
          style={{
            color: "black",
          }}
        />
      </Box>
    ) : (
      <ArrowDropDown />
    );

  const classes = useStyles();
  return (
    <FormControl
      variant={variant ? variant : "outlined"}
      fullWidth
      error={props.error}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled || loading}
        {...other}
        IconComponent={CustomLoading}
        className={classes.textfield}
      >
        {allowEmpty && (
          <MenuItem value={-1}>{emptyLabel ? emptyLabel : "None"}</MenuItem>
        )}

        {options != undefined &&
          options.map((item) =>
            item.title != null ? (
              <MenuItem key={item.id} value={item.id}>
                <ListItemIcon>
                  <Box
                    style={{
                      width: 20,
                      height: 20,
                      display: "flex",
                      borderRadius: "50%",
                      position: "relative",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: `${item.color ? item.color : "#e0e0e0"}`,
                    }}
                  />
                </ListItemIcon>
                <Typography variant="inherit"> {item.title}</Typography>
              </MenuItem>
            ) : (
              <MenuItem key={item.id} value={item.id}>
                <ListItemIcon>
                  <Box
                    style={{
                      width: 20,
                      height: 20,
                      display: "flex",
                      borderRadius: "50%",
                      position: "relative",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: `${item.color ? item.color : "#e0e0e0"}`,
                    }}
                  />
                </ListItemIcon>
                <Typography variant="inherit"> {item.name}</Typography>
              </MenuItem>
            )
          )}
      </MuiSelect>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}
