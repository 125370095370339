import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import HeadersSidebar from "./HeadersSidebar";
import ConversationPanel from "./ConversationPanel";
import {
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Controls from "../controls";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import { debounce, isEmpty } from "lodash";
import { Tip } from "../controls/Tip";
import ScrollBar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
  resultsTitle: {
    margin: 14,
    fontWeight: 500,
    marginBottom: 0,
    borderBottom: "1px solid #80808036",
    paddingBottom: 10,
  },
}));

const NewChatWithUser = (props) => {
  const { onStartChat } = props;
  const classes = useStyles();
  const [search, setSearch] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = async (payload = {}) => {
    if (!payload || !payload.search) {
      setUsers([]);
    } else {
      setIsLoadingUsers(true);
      const { data } = await Api.getAvailableUsersToChat(payload);

      setUsers([...data.data]);
      setIsLoadingUsers(false);
    }
  };

  const onSearchChange = (value) => {
    setSearch(value);
    getUsers({ search: value });
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => onSearchChange(newValue), 800),
    []
  );

  const handleStartChart = (user) => {
    if (typeof onStartChat == "function") {
      onStartChat(user);
    }
  };

  return (
    <div>
      <Tip
        style={{ marginBottom: 10, marginTop: 0 }}
        title={`Tip: `}
        message={`Enter the name/email/phone number of the user you are searching for`}
      />
      <Controls.Input
        fullWidth
        onChange={(value) => {
          !isLoadingUsers
            ? debouncedSearch(value.target.value)
            : onSearchChange(value.target.value);
        }}
        InputProps={{
          startAdornment: (
            <Iconify
              style={{
                width: 22,
                height: 22,
                color: "#5e6e8b",
                marginRight: 10,
              }}
              icon="tabler:search"
            />
          ),
        }}
        label="Search user..."
      />
      {isLoadingUsers ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
            paddingTop: 12,
          }}
        >
          <Iconify
            className={classes.loadingIcon}
            style
            icon="eos-icons:bubble-loading"
          />
          <Typography className={classes.loadingTitle}>Searching...</Typography>
        </div>
      ) : (!users || users.length == 0) && search ? (
        <div
          style={{ textAlign: "center", padding: "8px 0px", fontWeight: 500 }}
        >
          No results found
        </div>
      ) : (
        ""
      )}

      {users && users.length > 0 ? (
        <Card style={{ marginTop: 12 }}>
          <Typography className={classes.resultsTitle}>
            Search results
          </Typography>
          <List >
            <ScrollBar style={{ maxHeight: 500 }}>
              {users.map((user) => {
                return (
                  <ListItem>
                  
                    <ListItemText
                      primaryTypographyProps={{ style: { fontWeight: 500 } }}
                      primary={user.dropdown_name}
                      secondary={user.department_name}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        onClick={() => {
                          handleStartChart(user);
                        }}
                        style={{
                          backgroundColor: "#d4e4f3",
                          color: "#3c5291",
                          borderRadius: 25,
                        }}
                        endIcon={
                          <Iconify icon={"iconamoon:arrow-right-2-light"} />
                        }
                      >
                        Start chat
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </ScrollBar>
          </List>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
};

export default NewChatWithUser;
