import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
} from "./common/sliceTicketsFunctions";

var entitiesName = "contactsMassagesControl";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_CONTACTSMASSAGESCONTROL";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "POST_CONTACTSMASSAGESCONTROL";
  return _updateEntityService(dataSlice, events);
}
