import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
}));

export default function CommentForm(props) {
  const {
    saveComment,
    isSubmitting = false,
    ticketclosed = false,
    readonly = false,
    rReadonly = false,
    canAdd = true,
    editableNoDoctor = false,
  } = props;

  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);

  const onSubmit = (data) => {
    saveComment(data);
    reset();
    setValue("comment", null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="comment"
            control={control}
            defaultValue=""
            as={
              <Controls.Textarea
                label="Comment"
                disabled={
                  ticketclosed ||
                  (!canAdd && !readonly) ||
                  (authUser.is_coordinator && rReadonly && !editableNoDoctor)
                }
              />
            }
            rules={{ required: "Comment is required" }}
          />
          {errors.comment && <p className="error">{errors.comment.message}</p>}

          <div>
            <Controls.Button
              type="submit"
              text={isSubmitting ? "Submitting..." : "Submit"}
              disabled={
                isSubmitting ||
                ticketclosed ||
                (!canAdd && !readonly) ||
                (authUser.is_coordinator && rReadonly && !editableNoDoctor)
              }
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
