import React, { useState, useEffect } from "react";

import { Grid, makeStyles, Box, Typography } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../controls";
import styled from "styled-components";
import Api from "../../lib/api";
import moment from "moment";
import CRMUtils from "../../utils";
import Popup from "../Popup";
import { addInvoicePaymentsService } from "../../redux/slices/invoices";
import { fetchPaymentGatewaysService } from "../../redux/slices/paymentgateways";
import { isEmpty } from "lodash";

import { useDispatch, useSelector } from "react-redux";

import RefundFormNew from "./RefundFormNew";
import { fetchBuildingsService } from "../../redux/slices/buildings";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  tcolor: {
    color: "rgb(211, 47, 47)",
  },

  fcolor: {
    color: "rgb(56, 142, 60)",
  },
}));

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

export default function RefundRequestForm2(props) {
  const {
    addOrEdit,
    refundRequestsForEdit,
    refundReasons,
    onClose,
    invoiceSelected,
  } = props;
  const dispatch = useDispatch();
  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const [openRefund, setOpenRefund] = useState(false);
  const [openRefundRequest, setOpenRefundRequest] = useState(true);
  const [readonly, setReadonly] = useState(
    refundRequestsForEdit
      ? refundRequestsForEdit.status == "pending"
        ? false
        : true
      : false
  );
  const closeRefundPopup = () => {
    setOpenRefund(false);
  };
  const [item, setItem] = useState(
    invoiceSelected && invoiceSelected.items
      ? invoiceSelected.items.data[0].name
      : refundRequestsForEdit
      ? refundRequestsForEdit.product_name
      : null
  );

  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const { authUser } = useSelector((state) => state.auth);
  const isChecked = watch("isFull");

  const isCheckedFullItem = watch("isFullItem");
  const [invoiceForRefund, setInvoiceForRefund] = useState(null);
  const [paymentToRefund, setPaymentToRefund] = useState(null);
  const classes = useStyles();

  const { usersDropdown, usersDropdownLoading } = useSelector(
    (state) => state.users
  );
  const { buildings, buildingsLoading } = useSelector(
    (state) => state.buildings
  );
  const onSubmit = (data) => {
    data.invoice_name = refundRequestsForEdit
      ? refundRequestsForEdit.invoice_name
      : invoiceSelected.name;
    data.record_id = refundRequestsForEdit
      ? refundRequestsForEdit.record_id
      : invoiceSelected.contact.data.record_id;
    data.invoice_id = refundRequestsForEdit
      ? refundRequestsForEdit.invoice_id
      : invoiceSelected.id;
    data.contact_name = refundRequestsForEdit
      ? refundRequestsForEdit.contact_name
      : invoiceSelected.contact.data.full_name;
    addOrEdit(data);
  };

  const openRefundP = async () => {
    const { data } = await Api.getInvoice(
      refundRequestsForEdit
        ? refundRequestsForEdit.invoice_id
        : invoiceSelected.id
    );
    // console.log(refundRequestsForEdit);
    setInvoiceForRefund(data.data);
    setOpenRefund(true);
  };

  const aprovedRequest = () => {
    setOpenRefundRequest(false);
    openRefundP();
  };

  const ItemsOptions = () => {
    if (!refundRequestsForEdit && invoiceSelected) {
      return invoiceSelected.items.data
        .filter((item) => {
          return item.void === 0 && item.type != "package";
        })
        .map((item) => {
          return {
            id: item.name,
            title: item.name,
          };
        });
    } else if (refundRequestsForEdit && !invoiceSelected) {
      return [
        {
          id: refundRequestsForEdit.product_name,
          title: refundRequestsForEdit.product_name,
        },
      ];
    } else return [];
  };

  const saveRefund = (data) => {
    // console.log("saveRefund3", data);
    data.isRefund = 1;

    savePayment(data);

    closeRefundPopup();

    let data1 = {};
    data1.status = "approved";

    addOrEdit(data1);
  };

  const savePayment = async (payload) => {
    const momentDate = new moment(payload.payment_date);
    payload.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    payload.sale_created_at = payload.sale_created_at ? new moment(payload.sale_created_at).format("YYYY-MM-DD HH:mm:ss"): null;
    //var id = 0;
    //if(invoices.length > 0) id = invoices.filter((fInvoice)=>{return fInvoice.name === invoice}).id;

    const { data } = await Api.saveInvoicePayment(invoiceForRefund.id, payload);

    dispatch(addInvoicePaymentsService(data.data));

    closeRefundPopup();
    onClose();
  };

  const declinedRequest = () => {
    setOpenRefundRequest(false);
    var data = {};
    data.status = "declined";
    addOrEdit(data);

    onClose();
  };

  const onSubmitNew = async (info) => {
    const { data } = await Api.saveInvoiceRefundNew(invoiceForRefund.id, info);
    closeRefundPopup();

    let data1 = {};
    data1.status = "approved";

    addOrEdit(data1);
  };

  const refundReasonsOptions = () => {
    return refundReasons.map((refundReason) => {
      return {
        id: refundReason.id,
        title: refundReason.name,
      };
    });
  };

  const defaultReason = () => {
    if (refundRequestsForEdit) {
      const dReason = refundReasons.find(
        (value) => value.id === refundRequestsForEdit.reason_id
      );

      return dReason.id;
    }

    return "";
  };

  const getDataToRefund = async () => {
    const { data } = await Api.getAllPaymentsWItems(invoiceForRefund?.id);
    if (data && data.data) {
      setPaymentToRefund(data?.data);
    }
  };

  useEffect(() => {
    const orgId = authUser.organization_id;
    if (isEmpty(paymentGateways)) dispatch(fetchPaymentGatewaysService(orgId));
    if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));
  }, []);

  useEffect(() => {
    if (invoiceForRefund) {
      getDataToRefund();
    }
  }, [invoiceForRefund]);

  return (
    <Wrapper>
      {openRefundRequest && (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="date"
                control={control}
                defaultValue={
                  refundRequestsForEdit?.date ||
                  moment().format("YYYY-MM-DD HH:mm:ss")
                }
                as={({ onChange, value }) => (
                  <Controls.DateTimePicker
                    label={"Date"}
                    defaultValue={
                      refundRequestsForEdit?.date ||
                      moment().format("YYYY-MM-DD HH:mm:ss")
                    }
                    disabled
                    value={value}
                  />
                )}
              />
              <Controller
                name="record_id"
                control={control}
                as={({ onChange, value }) => (
                  <Controls.Input
                    defaultValue={
                      refundRequestsForEdit
                        ? refundRequestsForEdit.record_id
                        : invoiceSelected && invoiceSelected?.contact?.data
                        ? invoiceSelected.contact.data.record_id
                        : ""
                    }
                    label="Patient ID"
                    disabled
                  />
                )}
              />

              <Controller
                name="contact_name"
                control={control}
                as={({ onChange, value }) => (
                  <Controls.Input
                    defaultValue={
                      refundRequestsForEdit
                        ? refundRequestsForEdit.contact_name
                        : invoiceSelected && invoiceSelected?.contact?.data
                        ? invoiceSelected.contact.data.full_name
                        : ""
                    }
                    label="Contact"
                    disabled
                  />
                )}
              />

              <Controller
                name="invoice_name"
                control={control}
                as={({ onChange, value }) => (
                  <Controls.Input
                    defaultValue={
                      refundRequestsForEdit
                        ? refundRequestsForEdit.invoice_name
                        : invoiceSelected
                        ? invoiceSelected.name
                        : ""
                    }
                    label="Invoice Number"
                    disabled
                  />
                )}
              />
              <Controller
                name="product_name"
                defaultValue={
                  refundRequestsForEdit
                    ? refundRequestsForEdit.product_name
                    : item
                    ? item
                    : null
                }
                control={control}
                as={
                  <Controls.Select
                    label={"Item"}
                    disabled={readonly || refundRequestsForEdit}
                    allowEmpty={true}
                    options={ItemsOptions()}
                    onChange={(value) => {
                      setItem(value);
                    }}
                    value={item}
                  />
                }
              />

              <Controller
                name="amount"
                control={control}
                defaultValue={refundRequestsForEdit?.amount || ""}
                as={<Controls.InputMoney disabled={readonly} label="Amount" />}
                rules={
                  !isChecked && !isCheckedFullItem
                    ? { required: "Amount is required" }
                    : ""
                }
              />
              {errors.amount && (
                <p className="error">{errors.amount.message}</p>
              )}

              {/* <Controller
                name="isFull"
                control={control}
                defaultValue={refundRequestsForEdit?.isFull || 0}
                as={({ value, onChange }) => (
                  <Controls.Checkbox
                    disabled={readonly}
                    label="Full Invoice"
                    checked={value === 1 ? true : false}
                    onChange={(value) => onChange(value ? 1 : 0)}
                  />
                )}
              />

              <Controller
                name="isFullItem"
                control={control}
                defaultValue={refundRequestsForEdit?.isFullItem || 0}
                as={({ value, onChange }) => (
                  <Controls.Checkbox
                    disabled={readonly}
                    label="Full Item"
                    checked={value === 1 ? true : false}
                    onChange={(value) => {
                      onChange(value ? 1 : 0);
                    }}
                  />
                )}
              /> */}
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="reason_id"
                control={control}
                defaultValue={defaultReason()}
                as={({ onChange }) => (
                  <Controls.Select
                    defaultValue={defaultReason()}
                    disabled={readonly}
                    label="Refund Reason"
                    options={refundReasonsOptions()}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
                rules={{ required: "Refund Reason is required" }}
              />

              {errors.reason_id && (
                <p className="error">{errors.reason_id.message}</p>
              )}

              <Controller
                name="comment"
                control={control}
                disabled={readonly}
                defaultValue={refundRequestsForEdit?.comment || ""}
                as={<Controls.Textarea label="Comment" rows="8" />}
                rules={{ required: "Refund Reason comment is required" }}
              />
              {errors.comment && (
                <p className="error">{errors.comment.message}</p>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {CRMUtils.userHasPermission(authUser, ["refundrequest_create"]) &&
                !readonly && (
                  <div>
                    <Grid item xs={4}>
                      <Controls.Button
                        type="submit"
                        text="Save"
                        disabled={readonly}
                      />
                    </Grid>
                  </div>
                )}

              {CRMUtils.userHasPermission(authUser, [
                "refundrequest_approve",
              ]) &&
                !readonly && (
                  <div>
                    <Grid item xs={4}>
                      <Controls.Button
                        type="submit"
                        text="Approved"
                        onClick={() => {
                          aprovedRequest();
                        }}
                        disabled={readonly || !refundRequestsForEdit}
                      />
                    </Grid>
                  </div>
                )}

              {CRMUtils.userHasPermission(authUser, [
                "refundrequest_decline",
              ]) &&
                !readonly && (
                  <div>
                    <Grid item xs={4}>
                      <Controls.Button
                        type="submit"
                        text="Declined"
                        onClick={() => {
                          declinedRequest();
                        }}
                        disabled={readonly || !refundRequestsForEdit}
                      />
                    </Grid>
                  </div>
                )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box m={4}>
                <Grid item xs={4}>
                  {readonly && (
                    <Typography
                      variant="h2"
                      className={
                        refundRequestsForEdit?.status == "declined"
                          ? classes.tcolor
                          : classes.fcolor
                      }
                    >
                      {refundRequestsForEdit?.status.toUpperCase()}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
      <Popup
        title="Make Refund"
        maxWidth="xl"
        fullWidth={true}
        openPopup={openRefund}
        onClose={closeRefundPopup}
      >
        {/* <RefundForm
          refundReasons={refundReasons}
          isRefund={true}
          invoice={invoiceForRefund}
          savePayment={saveRefund}
          gateways={paymentGateways}
          className={classes.form}
          maxAmount={1}
          itemName={refundRequestsForEdit?.product_name || ""}
          itemAmount={refundRequestsForEdit?.amount || 0}
          refundComment={refundRequestsForEdit?.comment || ""}
          refundFull={refundRequestsForEdit?.isFull || 0}
          refundFullItem={refundRequestsForEdit?.isFullItem || 0}
          reload={openRefundP}
          allowFix={
            CRMUtils.userHasPermission(authUser, ["invoicepaymenthistory_edit"])
              ? CRMUtils.userHasPermission(authUser, [
                  "invoicepaymenthistory_edit",
                ])
              : CRMUtils.userHasPermission(authUser, ["refund_create"])
          }
        /> */}

        <RefundFormNew
          gateways={paymentGateways}
          invoice={invoiceForRefund}
          paymentToRefund={paymentToRefund}
          onSubmit={onSubmitNew}
          onClose={closeRefundPopup}
          refundReasons={refundReasons}
          buildings={buildings}
          usersDropdown={usersDropdown}
        />
      </Popup>
    </Wrapper>
  );
}
