import React from "react";
import { TextField } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutocompleteTagsControl(props) {
  const { label, options, onChange, ...other } = props;

  return (
    <Autocomplete
      multiple
      id="multiple"
      fullWidth
      options={options}
      freeSolo
      disableClearable
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              disabled={
                option == "SUPER SALE" ||
                option == "Hot Summer Financing" ||
                option == "Summer Sale Financing" ||
                option == "Summer Financing" ||
                option == "Remarketing 1" ||
                option == "Remarketing 2"
              }
            />
          );
        })
      }
      onChange={(event, values) => {
        onChange(values);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
        />
      )}
      {...other}
    />
  );
}
