import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Api from "../../lib/api";
import Iconify from "../Iconify";
import Notification from "../Notification";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  blockTitle: {
    fontSize: 15,
    fontWeight: 500,
    color: "#575757",
  },
  block: {
    padding: 10,
    borderRadius: 10,
    background: "#f0f3f6",
    margin: "6px 2px",
  },
}));

export default function MultiTransferProceduresForm(props) {
  const { transferForEdit, onCloseRequest } = props;
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeRequest = async (status) => {
    try {
      setIsSubmitting(true);
      const { data } = await Api.updateRecordTransferRequests(
        transferForEdit?.id || null,
        { status: status }
      );
      onCloseRequest(data.data);
      setNotify({
        isOpen: true,
        message: "Updated successfully!",
        type: "success",
      });
    } catch (err) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <div className={classes.block}>
        <Typography className={classes.blockTitle}>Building</Typography>
        <div>
          {/* <Typography>
            <strong>From: </strong>{" "}
            {` ${transferForEdit.old_building_name || "None"}`}
          </Typography> */}
          <Typography>
            <strong>To: </strong>{" "}
            {` ${transferForEdit.building_name || "None"}`}
          </Typography>
        </div>
      </div>
      <div className={classes.block}>
        <Typography className={classes.blockTitle}>Surgeon</Typography>
        <div>
          {/* <Typography>
            <strong>From: </strong>{" "}
            {` ${transferForEdit.old_responsible_fullname || "None"}`}
          </Typography> */}
          <Typography>
            <strong>To: </strong>{" "}
            {` ${transferForEdit.responsible_fullname || "None"}`}
          </Typography>
        </div>
      </div>
      <div className={classes.block}>
        <Typography className={classes.blockTitle}>Coordinator</Typography>
        <div>
          {/* <Typography>
            <strong>From: </strong>{" "}
            {` ${transferForEdit.old_coordinator_fullname || "None"}`}
          </Typography> */}
          <Typography>
            <strong>To: </strong>{" "}
            {` ${transferForEdit.coordinator_fullname || "None"}`}
          </Typography>
        </div>
      </div>

      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          margin: "8px 0px",
        }}
      >
        <Button
          disabled={isSubmitting}
          onClick={() => {
            closeRequest("approved");
          }}
          startIcon={<Iconify icon="ic:round-check" />}
          style={{
            marginRight: 5,
            color: isSubmitting ? "#828282" : "#268f59",
          }}
        >
          Accept
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            closeRequest("declined");
          }}
          style={{ color: isSubmitting ? "#828282" : "#af4040" }}
          startIcon={<Iconify icon="ic:round-close" />}
        >
          Decline
        </Button>
        {isSubmitting ? (
          <CircularProgress style={{ width: 25, height: 25, marginLeft: 15 }} />
        ) : (
          ""
        )}
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
