import { useMemo } from "react";
import GenericTable from "../components/GenericTable";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";

const RefundReasons = () => {
    const columns = useMemo(
        () => [
            { field: "name", title: "Name" },
            { field: "description", title: "Description" },
        ],
        []
    );

    const model = "rfs_reasons";

    const FormData = () => {
        const {
            control,
            formState: { errors },
        } = useFormContext();
        return (
            <Grid container direction="column">
                <Grid item>
                    <Controller
                        name="name"
                        P
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Name is required",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <TextField
                                label="Name"
                                name="name"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            maxLength: {
                                value: 100,
                                message: "Max lenght is 100",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <TextField
                                label="Description"
                                name="description"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.description}
                                multiline
                                helperText={errors.description?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    const useStyles = makeStyles((theme) => ({
        headerIcon: {
          color: theme.palette.primary.main,
        },
      }));
      const classes = useStyles();

    return (
    <>
        <Helmet>
            <title>Refund Reasons</title>
        </Helmet>
        <PageHeader
            title="Refund Reasons"
            subTitle=""
            icon={
              <PeopleOutlineTwoToneIcon
                fontSize="large"
                className={classes.headerIcon}
              />
            }
          />
        <GenericTable model={model} columns={columns} FormData={FormData} tableTitle="Refund Reasons" />
    </>
    );
};

export default RefundReasons;
