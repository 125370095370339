import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Box, Card, Divider, Grid, Paper } from "@material-ui/core";
import MaterialTable from "material-table";

import FingerprintIcon from "@material-ui/icons/Fingerprint";
const useStyles = makeStyles((theme) => ({
  logList: {
    listStyle: "none",
  },
}));

function Logs(props) {
  const { logs } = props;
  const classes = useStyles();
  const renderLogs = () => {
    return logs
      .filter((log) => {
        return (
          Object.keys(
            log.properties.old ? log.properties.old : log.properties.attributes
          ).length > 0
        );
      })
      .map((log) => {
        //let keys = [];
        return (
          <>
            <Box key={log.id} mt={3}>
              <FingerprintIcon fontSize="small" />
              At {moment(log.created_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )} by {log.causer_name}
              {log.subject_name && <a> - Item: {log.subject_name}</a>}
              <Grid container spacing={3}>
                {/*JSON.stringify(logs)*/}
                {log.properties.old && (
                  <Grid item xs={6}>
                    <lu className={classes.logList}>
                      old:{" "}
                      {Object.keys(
                        log.properties.old ? log.properties.old : {}
                      ).find((key) => {
                        return key == "password";
                      })
                        ? "Password was Updated! "
                        : ""}
                      {Object.keys(log.properties.old ? log.properties.old : {})
                        .filter((key) => {
                          return key != "password" && key != null && key != "";
                        })
                        .map(function (key) {
                          return (
                            <li>{key + " -> " + log.properties.old[key]}</li>
                          );
                        })}
                    </lu>
                    <Divider />
                  </Grid>
                )}

                <Grid item xs={6}>
                  <lu className={classes.logList}>
                    {log.properties.old == null ? "Created " : ""} new:{"  "}
                    {Object.keys(
                      log.properties.old ? log.properties.old : {}
                    ).find((key) => {
                      return key == "password";
                    })
                      ? "New Password was set! "
                      : ""}
                    {Object.keys(
                      log.properties.attributes ? log.properties.attributes : {}
                    )
                      .filter((key) => {
                        return key != "password" && key != null && key != "";
                      })
                      .map(function (key) {
                        return (
                          <li>
                            {key + " -> " + log.properties.attributes[key]}
                          </li>
                        );
                      })}
                  </lu>
                  <Divider />
                </Grid>
              </Grid>
            </Box>
          </>
        );
      });
  };
  return <p>{renderLogs()}</p>;
}

export default Logs;
