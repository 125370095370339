import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, updateOnlyEntityService } from "../redux/slices/auth";
import {
  updateOnlyMessageCountService,
  updateOnlyWhatsAppConfirmedService,
} from "../redux/slices/userSMSMMSSlice.js";
import { updateOnlyMessageCountService as updateOnlyMessageCountServiceByGroups } from "../redux/slices/userSMSMMSByGroupsSlice.js";
import { fetchEntitiesService as fetchBuildingsDropdownService } from "../redux/slices/buildingsDropdownSlice.js";
import { updateMissedCallsService } from "../redux/slices/missedCallsSlice.js";
import { updatePortalMessagesNotificationsService } from "../redux/slices/portalMessagesNotificationsSlice.js";
import { addOnlyEntityService as addNewSMSService } from "../redux/slices/newSMSSlice.js";
import { addOnlyEntityService as addNewSMSServiceW } from "../redux/slices/newWhatsAppSlice.js";

import { addOnlyEntityService as updateWhatsServiceW } from "../redux/slices/updateWhatsAppSlice.js";

import { isEmpty, findIndex, isArray } from "lodash";
import MmsIcon from "@material-ui/icons/Mms";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { spacing } from "@material-ui/system";
import {
  MenuItem,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  Drawer as MuiDrawer,
  Typography,
  Button,
  Dialog,
} from "@material-ui/core";
import {
  addOnlyEntityService as addOnlyLiveNotificationsService,
  addUnreadToPaginationEntityService,
} from "../redux/slices/liveNotificationsSlice.js";

import { getMessaging, onMessage } from "firebase/messaging";
import { isWidthUp } from "@material-ui/core/withWidth";
import { Redirect } from "react-router-dom";

import { useSnackbar } from "notistack";

import SmsMmsView from "../components/chat/smsMmsView";
import MailView from "../components/chat/mailView";
import Api from "../lib/api";

import { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LocationR from "./LocationR";

import { useIdleTimer } from "react-idle-timer";
import IconButton from "../components/controls/IconButton";
import SidebarIconsExp from "../components/SidebarIconsExp";
import ScrollBar from "react-perfect-scrollbar";
import Popup from "../components/Popup";
import ContactDetails from "../pages/contacts/details2";
import RecordDetails from "../pages/records/details";
import LiveNotification from "../components/LiveNotifications";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  loadEmailAccounts,
  loadAccountsFolders,
} from "../modules/email_client/slices/EmailClientSlices";
import InternalChat from "../components/internalChat/InternalChat.js";
import {
  fetchGeneralTotalUnreadChatMessages,
  updateGeneralTotalUnreadChatMessages,
} from "../redux/slices/generalTotalUnreadChatMessages.js";
import {
  fetchUserFavoriteSites,
  updateUserFavorites,
} from "../redux/slices/userFavoriteSites.js";
import SystemUpdatedNotification from "../components/SystemUpdatedNotification.js";

import SystemCustomNotification from "../components/SystemCustomNotification.js";

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  errorHandle: null,
};

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};
  padding: 0px 20px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  // background-color: #414141 !important;
  // color: white !important;
  // * {
  //   background-color: #414141 !important;
  //   color: white !important;
  // }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  wrapText: {
    fontSize: 12,
    whiteSpace: "normal",
    wordWrap: "break-word",
    width: 180,
  },
  paper: {
    "& .MuiDrawer-paper": {
      height: "auto",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  sidebar: {
    zIndex: theme.zIndex.drawer + 10,
  },
}));

const Dashboard = ({ children, routes, width }) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    console.log("onIdle...");
    /*    dispatch(logOutService());*/
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
    console.log("onActive...");
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    timeout: 1000 * 60 * 30,
    crossTab: true,
    emitOnAllTabs: true,
  });

  const dispatch = useDispatch();
  const messagingDashboard = getMessaging();

  const { authUser, loading, errors } = useSelector((state) => state.auth);
  const [mmsView, setMmsView] = useState(false);
  const [mmsSelected, setMmsSelected] = useState({});
  const [mmsUnreadNotification, setMmsUnreadNotification] = useState(
    localStorage.getItem("mmsUnread") !== null
      ? JSON.parse(localStorage.getItem("mmsUnread"))
      : []
  );
  const [isViewIcons, setIsViewIcons] = useState(
    localStorage.getItem("isViewIcons")
      ? JSON.parse(localStorage.getItem("isViewIcons").toLowerCase())
      : false
  );
  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem("isViewExp")
      ? JSON.parse(localStorage.getItem("isViewExp").toLowerCase())
      : false
  );
  const [systemUpdatedNotification, setSystemUpdatedNotification] =
    useState(false);

  const [systemCustomNotification, setSystemCustomNotification] =
    useState(false);

  const [systemCustomNotificationText, setSystemCustomNotificationText] =
    useState("");

  const [liveNotification, setLiveNotification] = useState(
    localStorage.getItem("live_notification_data") !== null
      ? JSON.parse(localStorage.getItem("live_notification_data"))
      : null
  );
  const [userCompanyGroups, setUserCompanyGroups] = useState([]);
  const [userChatGroups, setUserChatGroups] = useState([]);
  const [transition, setTransition] = useState(false);
  const portal = localStorage.getItem("portal");
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [isPC, setIsPC] = useState(widthScreen > 1368);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);
  const { buildingsDropdown } = useSelector((state) => state.buildingsDropdown);
  const [openRecordPopup, setOpenRecordPopup] = useState(false);
  const [recordDetail, setRecordDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const contact = useRef();
  const { generalTotalUnreadChatMessages } = useSelector(
    (state) => state.generalTotalUnreadChatMessages
  );
  const { userFavoriteSites } = useSelector(
    (state) => state.userFavoriteSites
  );
  const [unseenMessage, setUnseenMessage] = useState(
    localStorage.getItem("unseen_message")
      ? JSON.parse(localStorage.getItem("unseen_message").toLowerCase())
      : false
  );
  const [unseenNotification, setUnseenNotification] = useState(
    localStorage.getItem("unseen_notification")
      ? JSON.parse(localStorage.getItem("unseen_notification").toLowerCase())
      : false
  );

  const openContact = async (id) => {
    const { data } = await Api.getContactPlain(id);

    setContactDetail(data.data);
    setOpenContactPopup(true);
  };

  const oncloseContact = () => {
    setOpenContactPopup(false);
  };

  const openRecord = async (id) => {
    setIsLoading(true);
    const { data } = await Api.getRecord(id);

    setRecordDetail(data.data);
    setOpenRecordPopup(true);
    setIsLoading(false);
  };
  const onCloseRecord = () => {
    setOpenRecordPopup(false);
  };
  const getContact = async (id) => {
    const { data } = await Api.getContact(id);
    contact.current = { ...data.data };
  };

  const setAsView = async (id) => {
    setMmsUnreadNotification(mmsUnreadNotification.filter((a) => a.id != id));
  };

  useEffect(() => {
    // console.log("mmsUnreadNotification", mmsUnreadNotification);
    localStorage.setItem(
      "live_notification_data",
      JSON.stringify(liveNotification)
    );
  }, [liveNotification]);

  useEffect(() => {
    // console.log("mmsUnreadNotification", mmsUnreadNotification);
    localStorage.setItem(
      "unseen_notification",
      JSON.stringify(unseenNotification)
    );
  }, [unseenNotification]);

  useEffect(() => {
    // console.log("mmsUnreadNotification", mmsUnreadNotification);
    localStorage.setItem("unseen_message", JSON.stringify(unseenMessage));
  }, [unseenMessage]);

  useEffect(() => {
    // console.log("mmsUnreadNotification", mmsUnreadNotification);
    localStorage.setItem("mmsUnread", JSON.stringify(mmsUnreadNotification));
  }, [mmsUnreadNotification]);

  useEffect(() => {
    setIsPC(widthScreen > 1368);
  }, [widthScreen]);

  useEffect(() => {
    if (isEmpty(authUser) && !portal && !loading) dispatch(fetchUser());

    if (localStorage.getItem("live_notification_data") === null) {
      localStorage.setItem("live_notification_data", JSON.stringify(null));
    }
    if (localStorage.getItem("mmsUnread") === null) {
      localStorage.setItem("mmsUnread", JSON.stringify([]));
    }
    if (localStorage.getItem("unseen_notification") === null) {
      localStorage.setItem("unseen_notification", false);
    }

    if (localStorage.getItem("unseen_message") === null) {
      localStorage.setItem("unseen_message", false);
    }

    if (localStorage.getItem("isViewIcons") === null) {
      localStorage.setItem("isViewIcons", false);
    }

    if (localStorage.getItem("isViewExp") === null) {
      localStorage.setItem("isViewExp", false);
    }

    function handleResize() {
      setWidthScreen(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isEmpty(authUser)) {
      if (isEmpty(generalTotalUnreadChatMessages)) {
        dispatch(fetchGeneralTotalUnreadChatMessages());
      }
      if (isEmpty(userFavoriteSites)) {
        dispatch(fetchUserFavoriteSites(authUser.id));
      }
      
      if (!accountsLoaded) {
        dispatch(loadEmailAccounts());
      }
      if (isEmpty(buildingsDropdown)) {
        dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
      }
    }
  }, [authUser]);

  const {
    emailAccounts: {
      data: accounts,
      loaded: accountsLoaded,
      loading: accountsLoading,
    },
    accountsData,
  } = useSelector((state) => state.EmailClient);

  useEffect(() => {
    if (!isEmpty(accounts) && !isEmpty(authUser)) {
      //  loadAccountsFoldersL();
    }
  }, [accounts, authUser]);

  const loadAccountsFoldersL = async () => {
    Object.values(accounts).map((acc) => {
      const loadingFolders = accountsData[acc.name]?.loadingFolders
        ? accountsData[acc.name].loadingFolders
        : false;
      if (!(accountsData[acc.name]?.folders?.length > 0) && !loadingFolders) {
        refreshFolders(acc);
      }
    });
  };

  const refreshFolders = async (account) => {
    // dispatch(loadAccountsFolders(account.name)).catch((error) => {
    //   //showErrorsSnackbar(error);
    // });
  };

  useEffect(() => {
    if (isViewIcons == true) {
      setIsExpanded(true);
    }
    localStorage.setItem("isViewIcons", isViewIcons);
  }, [isViewIcons]);

  useEffect(() => {
    localStorage.setItem("isViewExp", isExpanded);
  }, [isExpanded]);
  const [openNewSMS, setOpenNewSMS] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openMail, setOpenMail] = useState(false);

  const toggleDrawerChat = () => {
    setOpenChat(!openChat);
  };

  const toggleDrawerMail = () => {
    setOpenMail(!openMail);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  onMessage(messagingDashboard, (payload) => {
    //   console.log("onMessage", payload);
    if (payload && payload.data.type === "internal_chat_new_message") {
      dispatch(
        updateGeneralTotalUnreadChatMessages(generalTotalUnreadChatMessages + 1)
      );
    } else if (payload && payload.data.type === "portalMessagesNotifications") {
      const localPayload = {
        count: 1,
      };

      dispatch(updatePortalMessagesNotificationsService(localPayload));
    } else if (payload && payload.data.type === "callMissedCount") {
      const localPayload = {
        count: 1,
      };

      dispatch(updateMissedCallsService(localPayload));
    } else if (payload && payload.data.type === "liveNotification") {
      console.log("payload.data: ", payload.data);
      var localPayload = {
        data: payload.data,
      };
      setUnseenNotification(true);
      if (
        payload.data.notification_type &&
        payload.data.notification_type == "system" &&
        payload.data.data &&
        isArray(JSON.parse(payload.data.data)) &&
        JSON.parse(payload.data.data).length > 0 &&
        JSON.parse(payload.data.data).find((obj) => obj.system_updated)
      ) {
        setSystemUpdatedNotification(true);
      } else if (
        payload.data.notification_type &&
        payload.data.notification_type == "system" &&
        payload.data.data &&
        isArray(JSON.parse(payload.data.data)) &&
        JSON.parse(payload.data.data).length > 0 &&
        JSON.parse(payload.data.data).find((obj) => obj.system_custom)
      ) {
        setSystemCustomNotificationText(payload.data.text);
        setSystemCustomNotification(true);
      } else {
        setLiveNotification(payload.data);
      }

      dispatch(addOnlyLiveNotificationsService(localPayload));
      dispatch(addUnreadToPaginationEntityService(1));
    } else if (
      (payload &&
        payload.data.type === "message" &&
        payload.data.user_id &&
        payload.data.user_id == authUser.id) ||
      (authUser.communications_groups[0] &&
        payload.data.group_id == authUser.communications_groups[0].group_id) ||
      (authUser.communications_groups[1] &&
        payload.data.group_id == authUser.communications_groups[1].group_id) ||
      (authUser.communications_groups[2] &&
        payload.data.group_id == authUser.communications_groups[2].group_id)
    ) {
      let localpayload = { ...payload, addsubtract: 1 };

      if (payload.data.user_id && payload.data.user_id == authUser.id)
        dispatch(updateOnlyMessageCountService(localpayload));
      if (
        authUser.communications_groups[0] &&
        payload.data.group_id == authUser.communications_groups[0].group_id
      )
        dispatch(updateOnlyMessageCountServiceByGroups(localpayload));
      if (
        authUser.communications_groups[1] &&
        payload.data.group_id == authUser.communications_groups[1].group_id
      )
        dispatch(updateOnlyMessageCountServiceByGroups(localpayload));
      if (
        authUser.communications_groups[2] &&
        payload.data.group_id == authUser.communications_groups[2].group_id
      )
        dispatch(updateOnlyMessageCountServiceByGroups(localpayload));

      if (payload.data.message_id) {
        addNewM(payload.data.message_id);
      }
    } else if (
      (payload.data.type === "whastapp" ||
        payload.data.type === "whastapp_status_update") &&
      payload.data.user_id &&
      payload.data.user_id == authUser.id
    ) {
      if (payload.data.w_message_id) {
        addNewMW(payload.data.w_message_id, payload.data.type);
      }
    } else if (
      payload &&
      payload.data.type === "whastapp_reply" &&
      payload.data.user_id &&
      payload.data.user_id == authUser.id
    ) {
      var localPayload = {
        data: payload.data,
      };

      if (payload.data.user_id && payload.data.user_id == authUser.id) {
        dispatch(updateOnlyWhatsAppConfirmedService(localPayload));
      }
    }
  });

  const addNewM = async (message_id) => {
    setUnseenMessage(true);
    const ppayload = {
      message_id: message_id,
    };
    const { data } = await Api.getNewSMS(authUser.id, ppayload);
    if (data.data.view != 1 && data.data.type == "mms") {
      if (data.data.from_type == "contact") {
        getContact(data.data.from_id).then(() => {
          mmsUnreadNotification.findIndex((a) => a.id == data.data.id) == -1
            ? setMmsUnreadNotification([
                { ...data.data, ...{ contact: contact.current } },
                ...mmsUnreadNotification,
              ])
            : "";
        });
      } else {
        mmsUnreadNotification.findIndex((a) => a.id == data.data.id) == -1
          ? setMmsUnreadNotification([
              { ...data.data, ...{ contact: null } },
              ...mmsUnreadNotification,
            ])
          : "";
      }
    }
    dispatch(addNewSMSService(data));
  };

  const addNewMW = async (w_message_id, type) => {
    const ppayload = {
      message_id: w_message_id,
    };
    const { data } = await Api.getNewWhatsApp(authUser.id, ppayload);

    if (type === "whastapp") {
      dispatch(addNewSMSServiceW(data));
    } else if (type === "whastapp_status_update") {
      dispatch(updateWhatsServiceW(data));
    }
  };

  const snackMethods = useSnackbar();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: Infinity,
        onError: (error) => {
          setTimeout(() => {
            snackMethods &&
              snackMethods.enqueueSnackbar(
                "An error occurred: " + error?.data?.message ?? "Unknow",
                {
                  variant: "error",
                }
              );
          }, 1000);
          // You can call any function or code snippet here to handle the error globally
        },
      },
    },
  });

  if (!isEmpty(loading)) return null;
  if (!isEmpty(errors)) {
    const searchParams = new URLSearchParams({
      returnTo: window.location.href,
    }).toString();
    const href = `/auth/sign-in?${searchParams}`;
    return <Redirect to={href} />;
  }
  if (!isEmpty(authUser))
    return (
      /* <MuiThemeProvider theme={maTheme[authUser?.organization.data.theme || 0]}>
        <ThemeProvider theme={maTheme[authUser?.organization.data.theme || 0]}>*/
      <Root>
        <CssBaseline />
        <GlobalStyle />

        {/*<motion.div 
         initial = {{width: 20}}
         animate = {{width: 80}}
         transition={{duration: 0.8}}
        style = {{backgroundColor: 'grey', width: 10}}>*/}
        <Drawer
          style={{
            width: isPC
              ? (isViewIcons && isExpanded) || (!isViewIcons && isExpanded)
                ? 82
                : ""
              : 0,
            opacity: !isViewIcons && isExpanded ? "94%" : "",
          }}
          className={classes.sidebar}
        >
          {!isPC && (
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              user={authUser}
              buildingsDropdown={buildingsDropdown}
            />
          )}

          {!isViewIcons && isPC && (
            <Sidebar
              isPC={isPC}
              buildingsDropdown={buildingsDropdown}
              onMouseLeave={() => {
                if (!isViewIcons && isExpanded) {
                  setIsViewIcons(true);
                }
              }}
              setIsViewIcons={setIsViewIcons}
              isViewIcons={isViewIcons}
              setIsExpanded={setIsExpanded}
              isExpanded={isExpanded}
              routes={routes}
              // PaperProps={{ style: { width : drawerWidth } }}
              user={authUser}
            />
          )}

          {isViewIcons && isPC && (
            <SidebarIconsExp
              onMouseEnter={() => {
                if (isExpanded) {
                  setIsViewIcons(false);
                }
              }}
              setIsViewIcons={setIsViewIcons}
              isViewIcons={isViewIcons}
              setIsExpanded={setIsExpanded}
              isExpanded={isExpanded}
              routes={routes}
              PaperProps={{ style: { width: 82 } }}
              user={authUser}
            />
          )}
        </Drawer>

        <LiveNotification
          notification={liveNotification}
          setNotification={setLiveNotification}
        />

        <MuiDrawer
          id="simple-menu"
          variant="permanent"
          PaperProps={{ style: { right: "5px", left: "auto", top: 67 } }}
          open={mmsUnreadNotification.length > 0}
          style={{ marginTop: 51 }}
          className={classes.paper}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ScrollBar style={{ maxHeight: 800 }}>
            {mmsUnreadNotification?.map((a) => {
              return (
                <MenuItem
                  style={{
                    maxWidth: "",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "beige",
                    margin: "6px",
                  }}
                >
                  <div style={{ display: "inline-grid", minWidth: 180 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: 180,
                        maxHeight: 130,
                      }}
                    >
                      <MmsIcon style={{ fontSize: 19 }} color="primary" />
                      <Typography
                        className={classes.wrapText}
                        style={{ padding: 8, fontWeight: "bold" }}
                      >
                        New message from
                        {a.from_type == "contact" && a.contact ? (
                          <Button
                            onClick={() => {
                              if (
                                authUser.is_coordinator == 1 &&
                                a.contact &&
                                a.contact.record_id
                              ) {
                                openRecord(a.contact.record_id);
                              } else {
                                openContact(a.contact.id);
                              }
                            }}
                            style={{
                              borderRadius: "10%",
                              width: 180,
                              justifyContent: "left",
                            }}
                            color="primary"
                          >
                            <Typography
                              variant="subtitle2"
                              style={{
                                borderColor: "#000",
                                m: 1,
                                border: 1,
                                fontSize: 12,
                              }}
                            >
                              {`${a.contact.full_name}!`}
                            </Typography>
                          </Button>
                        ) : (
                          `${a.from}!`
                        )}
                      </Typography>
                    </div>

                    <img
                      onClick={() => {
                        setMmsSelected(a);
                        setMmsView(true);
                      }}
                      style={{
                        margin: "0 auto",
                        maxWidth: 150,
                        maxHeight: 130,
                        filter: "blur(2.2px)",
                      }}
                      src={a.tempUrl}
                    />
                    <div style={{ padding: 8, textAlign: "right" }}>
                      {" "}
                      <IconButton
                        size="small"
                        onClick={() => {
                          setAsView(a.id);
                        }}
                      >
                        <DoneAllIcon
                          style={{ margin: 3, fontSize: 19 }}
                          color="primary"
                        />
                        <Typography style={{ fontSize: "12px" }}>
                          Mark as Viewed
                        </Typography>
                      </IconButton>
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          </ScrollBar>
        </MuiDrawer>

        <AppContent style={{ maxWidth: "100%" }}>
          <QueryClientProvider client={queryClient}>
            <Header
              setUnseenMessage={setUnseenMessage}
              unseenMessage={unseenMessage}
              unseenNotification={unseenNotification}
              setUnseenNotification={setUnseenNotification}
              setMmsUnreadNotification={setMmsUnreadNotification}
              mmsUnreadNotification={mmsUnreadNotification}
              isPC={isPC}
              setOpenNewSMS={setOpenNewSMS}
              isViewIcons={isViewIcons}
              onDrawerToggle={handleDrawerToggle}
              onChatDrawerToggle={toggleDrawerChat}
              onMailDrawerToggle={toggleDrawerMail}
              buildingsDropdown={buildingsDropdown}
            />

            <Suspense fallback={<div style={{ flex: 1, WebkitFlex: 1 }}></div>}>
              <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
                {children}
              </MainContent>
            </Suspense>
            {/*<NotificationObserver user={authUser} />*/}
          </QueryClientProvider>

          <Footer user={authUser} />
        </AppContent>
        <MuiDrawer
          style={{ overflowX: "none" }}
          anchor="right"
          open={openChat}
          onClose={toggleDrawerChat}
        >
          <div role="presentation" style={{ height: "100%" }}>
            {/*<Chat />*/}
            <SmsMmsView openNewSMS={openNewSMS} setOpenNewSMS={setOpenNewSMS} />
          </div>
        </MuiDrawer>

        <MuiDrawer
          style={{ overflowX: "none" }}
          anchor="right"
          open={openMail}
          onClose={toggleDrawerMail}
          PaperProps={{
            style: {
              width: "80vw",
              height: "94vh",
              marginTop: "4vh",
              fontFamily:
                '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
            },
          }}
        >
          <div role="presentation">
            <MailView
              accounts={accounts}
              accountsLoaded={accountsLoaded}
              accountsLoading={accountsLoading}
            />
          </div>
        </MuiDrawer>

        <Popup
          title={
            <Typography
              style={{ padding: 8, fontSize: 16, fontWeight: "bold" }}
            >
              New message from
              {mmsSelected.from_type == "contact" && mmsSelected.contact ? (
                <Button
                  onClick={() => {
                    if (
                      authUser.is_coordinator == 1 &&
                      mmsSelected.contact &&
                      mmsSelected.contact.record_id
                    ) {
                      openRecord(mmsSelected.contact.record_id);
                    } else {
                      openContact(mmsSelected.contact.id);
                    }
                  }}
                  style={{
                    borderRadius: "10%",
                  }}
                  color="primary"
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      borderColor: "#000",
                      m: 1,
                      border: 1,
                      fontSize: 16,
                      marginBottom: 4,
                    }}
                  >
                    {`${mmsSelected.contact.full_name}!`}
                  </Typography>
                </Button>
              ) : (
                `${mmsSelected.from}!`
              )}
            </Typography>
          }
          openPopup={mmsView}
          onClose={() => {
            setMmsView(false);
          }}
        >
          <div style={{ justifyContent: "center" }}>
            <img
              style={{ maxWidth: "100%", maxHeight: 650 }}
              src={mmsSelected.tempUrl}
            />
          </div>

          <IconButton
            style={{ float: "right" }}
            size="small"
            onClick={() => {
              setAsView(mmsSelected.id);
              setMmsView(false);
            }}
          >
            <DoneAllIcon style={{ margin: 3.5 }} color="primary" />
            Mark as Viewed
          </IconButton>
        </Popup>

        {contactDetail && (
          <Popup
            fullScreen
            title={`Contact ${contactDetail.fname} ${contactDetail.lname}`}
            openPopup={openContactPopup}
            onClose={oncloseContact}
          >
            <ContactDetails
              contact={contactDetail}
              onDetailClose={oncloseContact}
            />
          </Popup>
        )}

        {recordDetail && (
          <Popup
            fullScreen
            title={`Patient ${recordDetail.fname} ${recordDetail.lname}`}
            openPopup={openRecordPopup}
            onClose={onCloseRecord}
          >
            <RecordDetails
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              record={recordDetail}
              onClose={onCloseRecord}
            />
          </Popup>
        )}
        <Dialog
          open={systemUpdatedNotification}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {
            <SystemUpdatedNotification
              setSystemUpdatedNotification={setSystemUpdatedNotification}
            />
          }
        </Dialog>

        <Dialog
          open={systemCustomNotification}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {
            <SystemCustomNotification
              setSystemCustomNotification={setSystemCustomNotification}
              systemCustomNotificationText={systemCustomNotificationText}
            />
          }
        </Dialog>

        <LocationR />
      </Root>
      /*  </ThemeProvider>
      </MuiThemeProvider>*/
    );
  return null;
};

export default withWidth()(Dashboard);
