import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Controls from "./controls";

import Iconify from "./Iconify";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    padding: 28,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function ConfirmDialog(props) {
  const {
    confirmDialog,
    setConfirmDialog,
    confirmLoading,
    disableConfirm = false,
  } = props;
  const classes = useStyles();
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <Iconify
            style={{ width: 75, height: 75, color: "rgb(255 255 255 / 68%)" }}
            icon="fa6-solid:question"
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ marginTop: 10 }}
        className={classes.dialogContent}
      >
        <Typography
          variant="h6"
          style={{
            textAlign: "inherit",
            border: "1px solid #e1e1e1",
            borderLeft: "none",
            borderRight: "none",
            padding: "8px 12px",
            color: "#313131",
            fontSize: 19,
          }}
        >
          {confirmDialog.title}
        </Typography>
        {confirmDialog && confirmDialog.subTitle && confirmDialog.subTitle != "" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 12,
              justifyContent: "center",
            }}
          >
           
            <Typography
              style={{
                fontWeight: 500,
                fontSize: 16,
              }}
              variant="subtitle2"
            >
              {confirmDialog.subTitle}
            </Typography>
          </div>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {confirmDialog.showAsNotification ? (
          <>
            <Controls.Button
              text="Accept"
              color="default"
              onClick={() => {
                setConfirmDialog({ isOpen: false });
              }}
            />
          </>
        ) : (
          <>
            <Controls.Button
              text="No"
              color="default"
              disabled={confirmLoading}
              onClick={() => {
                confirmDialog.onCancel ? confirmDialog.onCancel() : "";
                setConfirmDialog({ isOpen: false });
              }}
            />
            <Controls.AdornedButton
              type="button"
              variant="contained"
              color="secondary"
              disabled={disableConfirm || confirmLoading}
              onClick={() => {
                confirmDialog.onConfirm();
              }}
              loading={confirmLoading}
            >
              YES
            </Controls.AdornedButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
