import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Api from "../lib/api";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY?process.env.REACT_APP_FIREBASE_APIKEY:"AIzaSyC1vjGktu-3n98VQAy3eMs6t5f1LGfjc8Y",
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN?process.env.REACT_APP_FIREBASE_AUTHDOMAIN:"surgerychats.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID?process.env.REACT_APP_FIREBASE_PROJECTID:"surgerychats",
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET?process.env.REACT_APP_FIREBASE_BUCKET:"surgerychats.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID?process.env.REACT_APP_FIREBASE_SENDERID:"957071205921",
  appId: process.env.REACT_APP_FIREBASE_APPID?process.env.REACT_APP_FIREBASE_APPID:"1:957071205921:web:71c1f2e52b075c19616560",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID?process.env.REACT_APP_FIREBASE_MEASUREMENTID:"G-E3WD3H7DTP",
};

initializeApp(firebaseConfig);

const publicKey =
process.env.REACT_APP_FIREBASE_CERTIFICATE?process.env.REACT_APP_FIREBASE_CERTIFICATE:"BHeCTQRjaid-rM5Uqe65RgT5t9Fj4Cw7AnuEVJRF2ti-xx_2R6obERcAchXaRL6WuyKQc5srtVViFZZcr_EIOUs";
const portal = localStorage.getItem("portal");

export const getBrowserToken = async (userId) => {
  const messaging = getMessaging();
  getToken(messaging, {
    vapidKey: publicKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token ", currentToken);
        localStorage.setItem("deviceToken", currentToken);
        const payload = {
          token: currentToken,
          os: "web",
        };
        if (!portal) {
          Api.registerToken(userId, payload);
          return currentToken;
        }
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () => {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    return payload;
  });
};
