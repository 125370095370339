import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

export default function AutocompleteCustom(props) {
  const {
    label,
    options,
    onChange,
    value,
    allowNew,
    disabled,
    disableClear,
    required,
    ...other
  } = props;

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderOption={(option) => option.title}
      getOptionSelected={(option, valueL) => option.id === valueL.id}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      disabled={disabled ? disabled : false}
      id={label}
      options={options}
      getOptionLabel={(option) => option ? option.title: ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          variant="outlined"
          autoComplete="off"
        />
      )}
      {...other}
    />
  );
}
