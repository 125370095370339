import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  contracts: [],
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    getContracts: (state) => {
      state.loading = true;
    },
    getContractsSuccess: (state, { payload }) => {
      state.contracts = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getContractsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createContract: (state) => {},
    createContractSuccess: (state, { payload }) => {
      state.contracts = [...state.contracts, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createContractFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateContract: (state) => {},
    updateContractSuccess: (state, { payload }) => {
      const index = findIndex(state.contracts, ["id", payload.data.id]);
      const user = { ...state.contracts[index], ...payload.data };

      let contracts = [...state.contracts];
      contracts[index] = user;

      state.contracts = [...contracts];
      state.loading = false;
      state.errors = [];
    },
    updateContractFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteContract: (state) => {},
    deleteContractSuccess: (state, { payload }) => {
      state.contracts = state.contracts.filter((contract) => {
        return contract.id !== payload;
      });
    },
    deleteContractFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getContracts,
  getContractsSuccess,
  getContractsFailure,
  createContract,
  createContractSuccess,
  createContractFailure,
  updateContract,
  updateContractSuccess,
  updateContractFailure,
  deleteContract,
  deleteContractSuccess,
  deleteContractFailure,
} = contractsSlice.actions;
export const contractsSelector = (state) => state.contracts;
export default contractsSlice.reducer;

export function fetchContractsService(orgId) {
  return async (dispatch) => {
    dispatch(getContracts());

    try {
      const response = await Api.getContracts(orgId);

      dispatch(getContractsSuccess(response.data));
    } catch (error) {
      dispatch(getContractsFailure(error));
    }
  };
}

export function createContractService(siteId, payload, setIsLoadingToFalse) {
  return async (dispatch) => {
    dispatch(createContract());
    try {
      const response = await Api.saveContract(siteId, payload);

      dispatch(createContractSuccess(response.data));
    } catch (error) {
      dispatch(createContractFailure(error));
    }
    setIsLoadingToFalse();
  };
}

export function updateContractService(id, payload, setIsLoadingToFalse) {
  return async (dispatch) => {
    dispatch(updateContract());

    try {
      const response = await Api.updateContract(id, payload);

      dispatch(updateContractSuccess(response.data));
    } catch (error) {
      dispatch(updateContractFailure(error));
    }
    setIsLoadingToFalse();
  };
}

export function deleteContractService(id) {
  console.log("deleteContractService ", id);
  return async (dispatch) => {
    dispatch(deleteContract());

    try {
      const response = await Api.deleteContract(id);

      dispatch(deleteContractSuccess(id));
    } catch (error) {
      dispatch(deleteContractFailure(error));
    }
  };
}
