import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'invWhMovements';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code="GET_ACC_INVWHMOVEMENT";
  return _fetchEntitiesService(dataSlice,events);
}


export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code="POST_ACC_INVWHMOVEMENT";
  return _createEntityService(dataSlice,events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code="PUT_ACC_INVWHMOVEMENT";
  dataSlice.dataUpdate.isPost=true;
  return _updateEntityService(dataSlice,events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code="DELETE_ACC_INVWHMOVEMENT";
  return _deleteEntityService(dataSlice,events);
}


/* Custom functions */
export function fetchInvWhMovementsByWhService(dataSlice) {
  dataSlice.dataUrl.code = "GET_ACC_INVWHMOVEMENTS_BY_WH";
  return _fetchEntitiesService(dataSlice, events);
}

export function closeEntityService(dataSlice) {
  dataSlice.dataUrl.code="CLOSE_ACC_INVWHMOVEMENT";
  return _updateEntityService(dataSlice,events);
}

export function approveEntityService(dataSlice) {
  dataSlice.dataUrl.code="APPROVE_ACC_INVWHMOVEMENT";
  return _updateEntityService(dataSlice,events);
}



