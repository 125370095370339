import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  Box,
} from "@material-ui/core";

export default function Select(props) {
  const {
    styleProp = null,
    label,
    value,
    allowEmpty,
    emptyLabel,
    onChange,
    options,
    disabled,
    setSelectedItem = null,
    loading = false,
    variant,
    menuProps = null,
    ...other
  } = props;

  // useEffect(()=>{
  //   console.log("options: ", options);
  // }, [options])

  return (
    <FormControl
      style={{ maxWidth: "100%" }}
      variant={variant ? variant : "outlined"}
      
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...other}
        MenuProps={{ PaperProps: { style: menuProps } }}
      >
        {allowEmpty && (
          <MenuItem
            onClick={() => {
              if (setSelectedItem) {
                setSelectedItem({ id: -1, title: "None" });
              }
            }}
            value={-1}
          >
            {emptyLabel ? emptyLabel : "None"}
          </MenuItem>
        )}

        {options != undefined &&
          options.map((item) =>
            item.title != null ? (
              <MenuItem
                onClick={() => {
                  if (setSelectedItem) {
                    setSelectedItem(item);
                  }
                }}
                style={{
                  backgroundColor: item.color ? item.color : "white",
                  borderRadius: 25,
                }}
                key={item.id}
                value={item.id}
                disabled={!item?.active}
              >
                <Box component={"span"}>   {item.title}</Box>
       
             
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  if (setSelectedItem) {
                    setSelectedItem(item);
                  }
                }}
                style={{
                  backgroundColor: item.color ? item.color : "white",
                  borderRadius: 25,
                  padding:8,
                  marginLeft:10
                }}
                key={item.id}
                value={item.id}
                disabled={!item?.active}
              >
                <Box component={"span"}> {item.name}</Box>
                
             
              </MenuItem>
            )
          )}
      </MuiSelect>
    </FormControl>
  );
}
