import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { find } from "lodash";
import Controls from "../../components/controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ResponsibleForm(props) {
  const { user, saveResponsible, users, responsibles } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const getUserOptions = () => {
    let notemp = [];
    users.forEach((c) => {
        if (!find(responsibles, { id: c.id })) {
          notemp.push(c);
        }
      });
    
    return notemp.map((user) => {
      return {
        id: user.id,
        title: user.title?user.title:user.first_name,
      };
    });
  };

  const onSubmit = (data) => {
    saveResponsible(data);
    reset();
  };

  useEffect(() => {
    getUserOptions();
  }, [responsibles,users]);


  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="responsibles"
            control={control}
            defaultValue=""
            as={({ onChange }) => (
              <Controls.Autocomplete
                label="Users"
                options={getUserOptions()}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
              />
            )}
            rules={{ required: "Responsible is required" }}
          />
          {errors.roles && <p className="error">{errors.roles.message}</p>}

          <div>
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
