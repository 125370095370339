import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import DocumentForm from "./forms/DocumentForm";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import Controls from "./controls";
import {
  Paper,
  Fab,
  TextField,
  MenuItem,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  Tooltip,
  Link,
} from "@material-ui/core";
import Popup from "./Popup";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import {
  fetchUserFavoriteSites,
  updateUserFavorites,
} from "../redux/slices/userFavoriteSites.js";
import Iconify from "./Iconify.js";
import { filter, isEmpty } from "lodash";
import { sidebarRoutes } from "../routes/index.js";
import CRMUtils from "../utils/index.js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import Api from "../lib/api.js";
import ScrollBar from "react-perfect-scrollbar";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    padding: "6px 34px",
    marginTop: 8,
    justifyContent: "center",
  },
  titleIcon: {
    width: 18,
    height: 18,
    marginRight: 5,
    color: "#7d8289",
  },
  title: {
    fontWeight: 500,
  },
  listItem: {
    padding: "0px 15px",
  },
  unmarkStarIcon: {
    fontSize: 18,
    color: "#cb6868",
  },
  siteTitle: {
    fontWeight: 500,
  },
  list: {
    background: "#f7f7f7",
    margin: 6,
    borderRadius: 8,
    width: 350,
    maxWidth: "100%",
    maxHeight: 500,
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#888 transparent",
    padding: "0px 4px",
  },
  emptyBookmarksAlert: {
    fontWeight: 500,
    textAlign: "center",
    padding: 5,
    fontSize: 12,
  },
  circleIcon: {
    color: "#53c391",
  },
  secondarySiteTitle: {
    fontSize: 12,
    fontWeight: 500,
  },
  searchIcon: {
    fontSize: 20,
    color: "#717171",
    transform: "rotate(90deg)",
    marginRight: 4,
  },
  textField: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgb(0 0 0 / 18%)",
    },
  },
}));

function FavoriteSitesList(props) {
  const { closeList, customListClass = null, customTitle = null } = props;
  const { userFavoriteSites } = useSelector((state) => state.userFavoriteSites);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const [allowedFavoriteSites, setAllowedFavoriteSites] = useState([]);
  const { t } = useTranslation("common");
  const [searchValue, setSearchValue] = useState("");
  let history = useHistory();

  const getAllowedUserFavoritesSites = () => {
    if (userFavoriteSites.length == 0) {
      setAllowedFavoriteSites([]);
    } else {
      const allowedRoutes = [];

      const checkAccess = (route) => {
        var access = false;

        if (
          ((route.permissions &&
            !isEmpty(route.permissions) &&
            CRMUtils.userHasPermission(authUser, route.permissions)) ||
            !route.permissions ||
            isEmpty(route.permissions)) &&
          ((route.roles &&
            !isEmpty(route.roles) &&
            CRMUtils.userHasRole(authUser, route.roles)) ||
            !route.roles ||
            isEmpty(route.roles))
        ) {
          access = true;
        }

        return access;
      };

      const traverseRoutes = (route, subtitle = "") => {
        var access = checkAccess(route);

        if (access) {
          if (route.children && route.children.length > 0) {
            var sub =
              subtitle +
              "- " +
              (route.name
                ? t(`sidebar.${route.name}`, route.name)
                : t(`sidebar.${route.id}`, route.id)) +
              " ";
            route.children.forEach((child) => traverseRoutes(child, sub));
          } else {
            const favorite = userFavoriteSites.find(
              (site) => site.path == route.path
            );
            if (favorite) {
              allowedRoutes.push({
                ...favorite,
                ...{
                  name: route.name
                    ? t(`sidebar.${route.name}`, route.name)
                    : t(`sidebar.${route.id}`, route.id),
                  subtitle: subtitle,
                },
              });
            }
          }
        }
      };

      sidebarRoutes.forEach((route) => traverseRoutes(route));
      setAllowedFavoriteSites([...allowedRoutes]);
    }
  };

  const removeRouteFromBookmarks = async (path) => {
    await Api.removeUserFavoriteSite(authUser.id, path);
    dispatch(
      updateUserFavorites([
        ...userFavoriteSites.filter((site) => site.path != path),
      ])
    );
  };

  useEffect(() => {
    if (isEmpty(userFavoriteSites)) {
      dispatch(fetchUserFavoriteSites(authUser.id));
    }
  }, []);

  useEffect(() => {
    getAllowedUserFavoritesSites();
  }, [userFavoriteSites]);

  return (
    <div>
      <Card>
        {customTitle ? (
          customTitle()
        ) : (
          <div className={classes.titleContainer}>
            <Iconify className={classes.titleIcon} icon={"uim:star"} />
            <Typography className={classes.title}>My Favorite Pages</Typography>
          </div>
        )}
        <List className={customListClass ? customListClass : classes.list}>
          <TextField
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className={classes.textField}
            fullWidth
            variant="standard"
            InputProps={{
              style: { borderRadius: 9, height: 38, padding: "0px 5px" },
              startAdornment: (
                <Iconify icon="bx:search" className={classes.searchIcon} />
              ),
            }}
            placeholder=" Search..."
          />
          {allowedFavoriteSites.length > 0 ? (
            allowedFavoriteSites
              .filter((site) =>
                searchValue
                  ? site.name.toUpperCase().includes(searchValue.toUpperCase())
                  : true
              )
              .map((site) => {
                return (
                  <Link
                    to={site.path}
                    button
                    dense
                    component={NavLink}
                    exact
                    style={{ color: "black" }}
                  >
                    <ListItem
                      onClick={() => {
                        // history.push(site.path);
                        if (typeof closeList == "function") {
                          closeList();
                        }
                      }}
                      className={classes.listItem}
                      button
                    >
                      {window.location.pathname == site.path ? (
                        <ListItemIcon style={{ minWidth: 22 }}>
                          <Iconify
                            className={classes.circleIcon}
                            icon="material-symbols-light:circle"
                          />
                        </ListItemIcon>
                      ) : (
                        ""
                      )}
                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.siteTitle,
                        }}
                        primary={site.name}
                        secondary={(site?.subtitle || "").substring(1)}
                        secondaryTypographyProps={{
                          className: classes.secondarySiteTitle,
                        }}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="Remove from favorites">
                          <span>
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeRouteFromBookmarks(site.path);
                              }}
                              edge="end"
                            >
                              <Iconify
                                className={classes.unmarkStarIcon}
                                icon="fluent:star-off-24-filled"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                );
              })
          ) : (
            <div className={classes.emptyBookmarksAlert}>
              You don't have favorite pages yet!
            </div>
          )}
        </List>
      </Card>
    </div>
  );
}

export default FavoriteSitesList;
