import { useMemo } from "react";
import GenericTable from "../../refunds/components/GenericTable";
import {
    Typography,
    makeStyles,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import TaskTypeComponents from "../components/types";
import TaskForm from "../components/TaskForm";
import moment from "moment";
import GenericFilter from "../../refunds/components/GenericFilter";
import TaskFilters from "../components/filters/TaskFilters";

const TasksNew = () => {
    const columns = useMemo(
        () => [
            { field: "title", title: "Title" },
            {
                field: "startDate",
                title: "Start Date",
                render: (row) => row && row.startDate? moment(row.startDate).format("MM/DD/yyyy") : "",
            },
            {
                field: "dueDate",
                title: "Due Date",
                render: (row) => row && row.dueDate? moment(row.dueDate).format("MM/DD/yyyy") : "",
            },
            {
                field: "priority_id",
                title: "Priority",
                render: (row) => (
                    <Typography
                        style={{
                            color: row?.priority?.color,
                        }}
                    >
                        {row?.priority?.name}
                    </Typography>
                ),
            },
            {
                field: "state_id",
                title: "State",
                render: (row) => (
                    <Typography
                        style={{
                            color: row?.get_state?.color,
                        }}
                    >
                        {row?.get_state?.name}
                    </Typography>
                ),
            },
            {
                field: "user_id",
                title: "User assigned",
                render: (row) => (
                    <Typography>{row?.user_assigned?.dropdown_name}</Typography>
                ),
            },
            {
                field: "task_type",
                title: "Task Type",
                render: (row) => (
                    <Typography>{row?.task_type?.label}</Typography>
                ),
            },
            {
                field: "created_at",
                title: "Created",
                render: (row) => (
                    <Typography>
                        {moment(row?.created_at).format("MM/DD/yyyy")}
                    </Typography>
                ),
            },
            {
                field: "user_created_id",
                title: "Created By",
                render: (row) => (
                    <Typography>{row?.user_created?.dropdown_name}</Typography>
                ),
            },
        ],
        []
    );

    const model = "tk_tasks";

    const taskCompponentList = useMemo(() => {
        return Object.keys(TaskTypeComponents).map((c) => ({
            id: c,
            name: c,
        }));
    }, [TaskTypeComponents]);

    const useStyles = makeStyles((theme) => ({
        headerIcon: {
            color: theme.palette.primary.main,
        },
    }));
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>Tasks</title>
            </Helmet>
            <PageHeader
                title="Tasks"
                subTitle=""
                icon={
                    <PeopleOutlineTwoToneIcon
                        fontSize="large"
                        className={classes.headerIcon}
                    />
                }
            />
            <GenericFilter Form={TaskFilters}>
                <GenericTable
                    model={model}
                    columns={columns}
                    FormData={TaskForm}
                    tableTitle="Tasks"
                    include={[
                        "priority",
                        "getState",
                        "user_assigned",
                        "task_type",
                        "user_created"
                    ]}
                />
            </GenericFilter>
        </>
    );
};

export default TasksNew;
