import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import { VerifiedUserRounded } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import LinkIcon from "@material-ui/icons/Link";

import Api from "../lib/api";
import Popup from "./Popup";

const BindOnlineTransactionButton = ({
  openBindTransactionPopup,
  setOpenBindTransactionPopup,
  setMerchantIdVerification,
  merchantIdVerification,
  getBuildingId,
  getTotalAmount,
  isRefund,
}) => {
  const [transactionId, setTransactionId] = useState("");

  // Function to handle changes in the transaction ID input
  const handleTransactionIdChange = (event) => {
    const { value } = event.target;
    setTransactionId(value);
    setMerchantIdVerification((prevState) => ({
      ...prevState,
      transactionId: value,
      billingInformation: { cardholder: null, last_4: null },
      payment_date: null
    }));
  };

  // Function to verify online transaction by ID
  const verifyOnlineTransactionById = async () => {
    setMerchantIdVerification((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const { data } = await Api.verifyOnlineTransactionById({
        building_id: getBuildingId(),
        merchant_transaction_id: transactionId,
        is_refund: isRefund,
        amount: getTotalAmount(),
      });

      console.log("DATA:",  data);
      console.log("DATEE:",  data.data.date[0]);
      // Update merchant ID verification state based on API response
      setMerchantIdVerification((prevState) => ({
        ...prevState,
        isVerified: !!data.data.result,
        billingInformation: data.data.billing_information ?? {
          cardholder: null,
          last_4: null,
        },
        message: data.message,
        isLoading: false,
        payment_date: data?.data?.date['0'] || null
      }));
    } catch (error) {
      console.log("Error: ", error);
      // Handle error response
      setMerchantIdVerification((prevState) => ({
        ...prevState,
        isVerified: false,
        message: error.data.message,
        isLoading: false,
        billingInformation: { cardholder: null, last_4: null },
        payment_date: null
      }));
    }
  };

  const { isVerified, isLoading, message } = merchantIdVerification;

  return (
    <div>
      {/* Button to open/close the transaction binding popup */}
      <Button
        variant="outlined"
        style={
          openBindTransactionPopup
            ? { borderColor: "gray", color: "gray" }
            : isVerified
            ? { borderColor: "red", color: "red" }
            : { color: "blue" }
        }
        href="#outlined-buttons"
        startIcon={<LinkIcon />}
        onClick={() => {
          if (isVerified) {
            // Reset verification state if already verified
            setMerchantIdVerification((prevState) => ({
              ...prevState,
              transactionId: null,
              isVerified: false,
              message: null,
              isLoading: false,
            }));
          } else {
            setOpenBindTransactionPopup(true);
          }
        }}
        disabled={openBindTransactionPopup}
      >
        {isVerified ? "Cancel " : ""}Bind to an Online Payment
      </Button>

      {/* Popup for binding CRM and online transactions */}
      <Popup
        title={"Bind CRM and Online Transactions"}
        fullWidth={true}
        maxWidth={"sm"}
        openPopup={openBindTransactionPopup}
        onClose={() => {
          setOpenBindTransactionPopup(false);
          if (!isVerified) {
            // Reset verification state if popup closed without verification
            setMerchantIdVerification((prevState) => ({
              ...prevState,
              transactionId: null,
              isVerified: false,
              message: null,
              isLoading: false,
            }));
          }
        }}
      >
        <Grid
          item
          xs={12}
          style={{ padding: 20, display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Input field for merchant transaction ID */}
              <TextField
                fullWidth
                value={transactionId}
                label="Merchant Transaction ID"
                type="number"
                inputProps={{ min: 0 }}
                onChange={handleTransactionIdChange}
              />
              {isVerified ? (
                // Display chip for verified status
                <Chip
                  icon={<VerifiedUserRounded />}
                  label="Verified"
                  color="primary"
                  style={{ margin: 10 }}
                />
              ) : (
                <div>
                  {/* Button to verify transaction ID */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={verifyOnlineTransactionById}
                    disabled={!transactionId || isVerified || isLoading}
                    style={{ marginLeft: 20, height: 30, marginTop: 10 }}
                  >
                    Verify
                  </Button>
                  {/* Display loading progress if verification is in progress */}
                  {isLoading && (
                    <LinearProgress
                      style={{ width: "100%", marginLeft: 10, marginTop: 5 }}
                    />
                  )}
                </div>
              )}
            </div>
            {/* Display alert for transaction verification */}
            <div style={{ marginTop: 10 }}>
              <Alert
                severity={isVerified ? "success" : message ? "error" : "info"}
              >
                <AlertTitle>Verify transaction</AlertTitle>
                {isVerified
                  ? `You are updating the "MERCHANT TRANSACTION ID" field of this transaction. This action will bind this transaction to the selected transaction on the merchant's platform and consider the transaction as "online" on the CRM records.`
                  : message ||
                    `Before you can update the "MERCHANT TRANSACTION ID" field, you must verify it.`}
              </Alert>
            </div>
          </div>
        </Grid>
      </Popup>
    </div>
  );
};

export default BindOnlineTransactionButton;
