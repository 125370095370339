import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'flowTypes';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code="GET_ACC_FLOWTYPES";
  return _fetchEntitiesService(dataSlice,events);
}