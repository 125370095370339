import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import MaterialTable from "material-table";
import CRMUtils from "../utils";
import { useSelector } from "react-redux";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Controls from "./controls";
import ProductsControlForm from "./forms/ProductsControlForm";
import { useTranslation } from "react-i18next";
import { find, isEmpty, findIndex } from "lodash";
import Api from "../lib/api";
import Popup from "./Popup";
import useTable from "./useTable";
const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

function ProductsControl(props) {
  const { t, i18n } = useTranslation(["common", "records"]);
  const { authUser } = useSelector((state) => state.auth);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  const { record } = props;
  const [progressBar, setProgressBar] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [openPopup, setOpenPopup] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [itemForAdd, setItemForAdd] = useState(null);
  const [selectedRowAvailableAmount, setSelectedRowAvailableAmount] =
    useState(0);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const openInPopup = (item, invoice) => {
    setInvoice(invoice);
    setSelectedRowAvailableAmount(item.available);
    setSelectedRowData(item);
    setItemForAdd(find(invoice.itemsNoProcedure.data, { id: item.id }));
    // console.log(item);
    setMaxQuantity(item.available);

    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };
  const getInvoicesNoProcedure = async () => {
    const { data } = await Api.getContactInvoiceNoProcedure2(record.contact_id);

    let invoicesArray = data.data.filter(
      (item) => item.itemsNoProcedure.data.length > 0
    );

    setInvoices(invoicesArray);
  };

  const addItem = async (itemForAdd, payload) => {
    const momentDate = new moment(payload.product_control_date);
    payload.product_control_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    payload.user_id = authUser.id;
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    if (!payload.file) {
      formData.delete("file");
    }
    const { data } = await Api.createInvoiceItemControlHistory(
      itemForAdd.invoice_id,
      itemForAdd.id,
      formData
    );

    const index = findIndex(invoices, ["id", itemForAdd.invoice_id]);
    let tempInvoice = { ...invoices[index] };

    let filteredItems = tempInvoice.itemsNoProcedure.data.filter(
      (item) => item.id !== itemForAdd.id
    );

    tempInvoice = {
      ...tempInvoice,
      itemsNoProcedure: {
        data: [...filteredItems, data.data],
      },
    };

    let localInvoices = [...invoices];
    localInvoices[index] = tempInvoice;

    setInvoices(localInvoices);
    setMaxQuantity(null);
    setItemForAdd(null);
    setOpenPopup(false);
  };

  const findrecord = (item_id, invoice) => {
    const item = find(invoice.itemsNoProcedure.data, { id: item_id });

    return item;
  };
  const calcUsed = (productcontrols) => {
    let total = 0;
    productcontrols.forEach((productcontrol) => {
      if (productcontrol.in_out === "out") {
        total = total + productcontrol.quantity;
      } else {
        total = total - productcontrol.quantity;
      }
    });
    return total;
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(invoices);

  useEffect(() => {
    setProgressBar(true);
    getInvoicesNoProcedure().then(() => {
      setProgressBar(false);
    });
  }, [record]);

  const renderInvoiceProducts = () => {
    if (invoices.length > 0) {
      return invoices.map((invoice) => {
        return (
          <TblContainer>
            <MaterialTable
              title={
                t("common:sidebar.Products") +
                " in " +
                t("common:general.Invoice") +
                "  " +
                `${invoice?.name}`
              }
              columns={[
                {
                  title: t("common:general.Name"),
                  field: "name",
                  render: (rowData) =>
                    `${rowData.name} 
                     ${
                       rowData.type != "package" &&
                       rowData.itemBalance &&
                       rowData.itemBalance > 0
                         ? `- (Pending Payment: $${rowData.itemBalance})`
                         : rowData.type == "package" &&
                           rowData.package_balance_full &&
                           rowData.package_balance_full > 0
                         ? `- (Pending Package Payment: $${rowData.package_balance_full})`
                         : ""
                     }`,
                },
                {
                  title: t("common:general.Total"),
                  field: "total",
                  type: "numeric",
                  render: (rowData) => {
                    return rowData.type != "package" //ver bien aca porque si un paquete se vacia puede ser probelmatico
                      ? rowData.total
                      : "-";
                  },
                },
                {
                  title: t("common:general.Used"),
                  field: "used",
                  type: "numeric",
                  render: (rowData) => {
                    return rowData.type != "package" ? rowData.used : "-";
                  },
                },
                {
                  title: t("common:general.Available"),
                  field: "available",
                  type: "numeric",
                  render: (rowData) => {
                    return rowData.type != "package" ? rowData.available : "-";
                  },
                },
              ]}
              data={invoice.itemsNoProcedure.data
                .filter((item) => item.parent_id == null)
                .map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    total: item.quantity - item.void_quantity,
                    itemBalance: item?.itemBalance || 0,
                    package_balance_full: item?.package_balance_full || 0,
                    used: item.productcontrols
                      ? calcUsed(item.productcontrols.data)
                      : 0,
                    available:
                      item.paid_quantity -
                        item.void_quantity -
                        (item.productcontrols
                          ? calcUsed(item.productcontrols.data)
                          : 0) >
                      0
                        ? item.paid_quantity -
                          item.void_quantity -
                          (item.productcontrols
                            ? calcUsed(item.productcontrols.data)
                            : 0)
                        : 0,
                    child_products: item.childProducts,
                    type: item.product_type,
                  };
                })}
              detailPanel={(rowData) => {
                // console.log("rowdatax ", rowData);
                const item = findrecord(rowData.id, invoice);
                // console.log("itemx ", item);

                return rowData.type != "package" ? (
                  <Grid item xs={12}>
                    <Box m={6}>
                      <Typography
                        variant="body2"
                        id="tableTitle"
                        component="div"
                      >
                        <Box mb={2}>History</Box>
                      </Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Quantity</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>
                              {item.product_type == "service"
                                ? "Available/Done"
                                : "Returned/Delivered"}
                            </TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>File</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {item.productcontrols.data.map((productcontrol) => {
                            return (
                              <TableRow>
                                <TableCell>{productcontrol.quantity}</TableCell>
                                <TableCell>
                                  {productcontrol.user.data.title}
                                </TableCell>
                                <TableCell>
                                  {item.product_type == "service"
                                    ? productcontrol.in_out == "in"
                                      ? "Available"
                                      : "Done"
                                    : productcontrol.in_out == "in"
                                    ? "Returned"
                                    : "Delivered"}
                                </TableCell>
                                <TableCell>{productcontrol.comment}</TableCell>
                                <TableCell>
                                  {moment(productcontrol.date).format(
                                    "YYYY-MM-DD hh:mm A"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {!isEmpty(productcontrol.document.data) && (
                                    <a
                                      href={productcontrol.document.data.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <AttachFileIcon />
                                    </a>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                ) : (
                  <div>
                    <TblContainer>
                      <MaterialTable
                        style={{ margin: 14, maxWidth: "98%" }}
                        title={"Package Items"}
                        columns={[
                          {
                            title: t("common:general.Name"),
                            field: "name",
                            render: (rowData) => `${rowData.name}`,
                          },
                          {
                            title: t("common:general.Total"),
                            field: "total",
                            type: "numeric",
                            render: (rowData) => {
                              return rowData.type != "package"
                                ? rowData.total
                                : "-";
                            },
                          },
                          {
                            title: t("common:general.Used"),
                            field: "used",
                            type: "numeric",
                            render: (rowData) => {
                              return rowData.type != "package"
                                ? rowData.used
                                : "-";
                            },
                          },
                          {
                            title: t("common:general.Available"),
                            field: "available",
                            type: "numeric",
                            render: (rowData) => {
                              return rowData.type != "package"
                                ? rowData.available
                                : "-";
                            },
                          },
                        ]}
                        data={invoice.itemsNoProcedure.data
                          .filter((item) => item.parent_id == rowData.id)
                          .map((item) => {
                            return {
                              id: item.id,
                              name: item.name,
                              total: item.quantity - item.void_quantity,
                              itemBalance: item?.itemBalance || 0,
                              package_balance_full:
                                item?.package_balance_full || 0,
                              used: item.productcontrols
                                ? calcUsed(item.productcontrols.data)
                                : 0,
                              available:
                                item.paid_quantity -
                                  item.void_quantity -
                                  (item.productcontrols
                                    ? calcUsed(item.productcontrols.data)
                                    : 0) >
                                0
                                  ? item.paid_quantity -
                                    item.void_quantity -
                                    (item.productcontrols
                                      ? calcUsed(item.productcontrols.data)
                                      : 0)
                                  : 0,
                              child_products: item.childProducts,
                              type: item.type,
                            };
                          })}
                        detailPanel={(rowData) => {
                          // console.log("rowdatax ", rowData);
                          const item = findrecord(rowData.id, invoice);
                          // console.log("itemx ", item);

                          return (
                            <Grid item xs={12}>
                              <Box m={6}>
                                <Typography
                                  variant="body2"
                                  id="tableTitle"
                                  component="div"
                                >
                                  <Box mb={2}>History</Box>
                                </Typography>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Quantity</TableCell>
                                      <TableCell>User</TableCell>
                                      <TableCell>
                                        {item.product_type == "service"
                                          ? "Available/Done"
                                          : "Returned/Delivered"}
                                      </TableCell>
                                      <TableCell>Comment</TableCell>
                                      <TableCell>Date</TableCell>
                                      <TableCell>File</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {item.productcontrols.data.map(
                                      (productcontrol) => {
                                        return (
                                          <TableRow>
                                            <TableCell>
                                              {productcontrol.quantity}
                                            </TableCell>
                                            <TableCell>
                                              {productcontrol.user.data.title}
                                            </TableCell>
                                            <TableCell>
                                              {item.product_type == "service"
                                                ? productcontrol.in_out == "in"
                                                  ? "Available"
                                                  : "Done"
                                                : productcontrol.in_out == "in"
                                                ? "Returned"
                                                : "Delivered"}
                                            </TableCell>
                                            <TableCell>
                                              {productcontrol.comment}
                                            </TableCell>
                                            <TableCell>
                                              {moment(
                                                productcontrol.date
                                              ).format("YYYY-MM-DD hh:mm A")}
                                            </TableCell>
                                            <TableCell>
                                              {!isEmpty(
                                                productcontrol.document.data
                                              ) && (
                                                <a
                                                  href={
                                                    productcontrol.document.data
                                                      .url
                                                  }
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <AttachFileIcon />
                                                </a>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Grid>
                          );
                        }}
                        actions={[
                          (rowData) => ({
                            icon: () => <SyncAltIcon />,
                            tooltip: t("common:general.Updatea"),
                            hidden: userCanViewEditContactMark,
                            disabled:
                              !rowData.available && !rowData.used
                                ? true
                                : rowData.type != "package"
                                ? false
                                : true,
                            onClick: (event, rowData) => {
                              openInPopup(rowData, invoice);
                              // console.log("rowdataaa ", rowData);
                            },
                          }),
                        ]}
                        onRowClick={(event, rowData) => {
                          !userCanViewEditContactMark &&
                          (rowData.available || rowData.used) &&
                          rowData.type != "package"
                            ? openInPopup(rowData, invoice)
                            : "";
                        }}
                        options={{
                          pageSize: 20, // make initial page size
                          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                          search: false,
                          actionsColumnIndex: -1,
                          paging: false,
                          rowStyle: (rowData, index) => {
                            const rest =
                              (rowData?.total || 0) - (rowData?.used || 0);
                            if (
                              rowData.package_balance_full &&
                              rowData.package_balance_full > 0 &&
                              rest != 0
                            ) {
                              return {
                                backgroundColor: "#ffebee",
                              };
                            }
                          },
                        }}
                      />
                    </TblContainer>
                  </div>
                );
              }}
              actions={[
                (rowData) => ({
                  icon: () => <SyncAltIcon />,
                  tooltip: t("common:general.Updatea"),
                  hidden: userCanViewEditContactMark,
                  disabled:
                    !rowData.available && !rowData.used
                      ? true
                      : rowData.type != "package"
                      ? false
                      : true,
                  onClick: (event, rowData) => {
                    openInPopup(rowData, invoice);
                    //console.log(rowData);
                  },
                }),
              ]}
              onRowClick={(event, rowData) => {
                !userCanViewEditContactMark &&
                rowData.type != "package" &&
                (rowData.available || rowData.used)
                  ? openInPopup(rowData, invoice)
                  : "";
              }}
              options={{
                pageSize: 20, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                search: false,
                actionsColumnIndex: -1,
                paging: false,
                rowStyle: (rowData, index) => {
                  const rest = (rowData?.total || 0) - (rowData?.used || 0);
                
                  if (
                    (rowData.type != "package" &&
                      rowData.itemBalance &&
                      rowData.itemBalance > 0 &&
                      rest != 0 &&
                      rowData?.available <= 0) ||
                    (rowData.type == "package" &&
                      rowData.package_balance_full &&
                      rowData.package_balance_full > 0)
                  ) {
                    return {
                      backgroundColor: "#ffebee",
                    };
                  } else if (
                    rowData.type != "package" &&
                    rowData.itemBalance &&
                    rowData.itemBalance > 0 &&
                    rest != 0 &&
                    rowData?.available > 0
                  ) {
                    return {
                      backgroundColor: "#fffde7",
                    };
                  }
                },
              }}
            />
          </TblContainer>
        );
      });
    } else if (!progressBar) {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height: "100%",
            marginTop: "10%",
          }}
        >
          <Typography variant="h3">
            There are no products or services available
          </Typography>
        </div>
      );
    }
  };

  return (
    <Wrapper>
      {invoices ? renderInvoiceProducts() : ""}
      {progressBar && (
        <CircularProgress
          style={{ marginLeft: "50%", marginTop: "10%" }}
          size={60}
        />
      )}
      <Popup
        title={
          itemForAdd ? ` ${itemForAdd.name} for ${invoice?.contact_name} ` : ""
        }
        openPopup={openPopup}
        onClose={onClose}
      >
        <ProductsControlForm
          contact_id={record.contact_id}
          itemForAdd={itemForAdd}
          contact={record.contact.data}
          user={authUser}
          maxQuantity={maxQuantity}
          addItem={addItem}
          type={itemForAdd?.type}
          selectedRowData={selectedRowData}
        />
      </Popup>
    </Wrapper>
  );
}

export default ProductsControl;
