import React, { useEffect } from "react";
import { makeStyles, Grid, FormHelperText } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  error: {
    color: "red",
    marginLeft: 15,
  },
}));

import InputMoneyOkWithoutController from "../controls/InputMoneyOkWithoutController";

export default function ApplyItemDiscountForm(props) {
  const { addOrEdit, entityForEdit } = props;

  const defaultValues = {
    discount_applied: entityForEdit?.discount_applied || "",
    discount_comment: entityForEdit?.discount_comment || "",
  };

  const { handleSubmit, setValue, reset, control, errors } = useForm({
    defaultValues,
  });

  const classes = useStyles();

  const onSubmit = (data) => {
    addOrEdit(data);
  };

  useEffect(() => {
    if (!isEmpty(entityForEdit)) {
      reset(defaultValues);
    }
  }, [entityForEdit]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid
        container
        direction="row"
        style={{ justifyContent: "flex-start", alignItems: "center" }}
        spacing={3}
      >
        <Grid item xs={3}>
          <Controller
            name="discount_applied"
            control={control}
            render={({ onChange, value }) => (
              <InputMoneyOkWithoutController
                onChange={onChange}
                value={value}
                label="Amount *"
                type="number"
                maxValue={
                  entityForEdit?.discount_applied &&
                  entityForEdit?.discount_applied > 0 &&
                  entityForEdit?.base_price
                    ? parseFloat(entityForEdit.base_price) +
                      parseFloat(entityForEdit?.discount_applied)
                    : parseFloat(entityForEdit?.base_price) || "999999"
                }
                minValue={"0"}
                step={"1"}
                min={"1"}
              />
            )}
            rules={{
              required: "Amount is required",
            }}
          />
          <FormHelperText
            className={classes.error}
            id="discount_applied-helper-text"
          >
            {errors["discount_applied"]
              ? errors["discount_applied"].message
              : " "}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="discount_comment"
            control={control}
            render={({ onChange, onBlur, value }) => (
              <Controls.Textarea
                label="Comment *"
                fullWidth
                value={value}
                onChange={onChange}
                rows="6"
              />
            )}
            rules={{
              required: "Comment is required",
            }}
          />
          <FormHelperText
            className={classes.error}
            id="discount_comment-helper-text"
          >
            {errors["discount_comment"]
              ? errors["discount_comment"].message
              : " "}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 15,
        }}
      >
        <Controls.Button type="submit" text="Apply" />
      </Grid>
    </form>
  );
}
