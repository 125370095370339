import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import CirculatorForm from "./forms/CirculatorForm";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";

import MaterialTable from "material-table";
import { Paper, Fab } from "@material-ui/core";
import Popup from "./Popup";

import { useTranslation } from "react-i18next";
import Api from "../lib/api";
import { async } from "@firebase/util";
import Notification from "./Notification";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));
function RecordProductCirculators(props) {
  const { authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [circulators, setCirculators] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const { usersDropdown } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation(["common", "records"]);

  const { record_id, onSave, onDeleteCirculator, coordinatorsFromDoctor } =
    props;

  const dataMaterialTable = circulators
    ? circulators.map((o) => ({ ...o }))
    : [];

  const headCellsMaterial = [
    { field: "title", title: "Name" },
    { field: "position", title: "Position" },
    { field: "email", title: "email" },
    { field: "department_name", title: "Department" },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [noAdd, setNoAdd] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onDelete = async (id) => {
    onDeleteCirculator(id);
    // console.log("ID deLETE: ", id);
    setCirculators(circulators.filter((a) => a.id != id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: t("common:general.Del_Doc_ok"),
      type: "error",
    });
  };

  const saveCirculator = (d) => {
    onSave(d).then(async () => {
      //   console.log("ID: ",d);
      const { data } = await Api.getUser(d.circulatorToAdd);
      setCirculators([data.data, ...circulators]);
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
    });
    onClose();
  };

  const circulatorsOptions = () => {
    return usersDropdown.filter((user) => {
      return user.is_circulator === 1;
    });
  };

  useEffect(() => {
    if (isEmpty(usersDropdown))
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  useEffect(() => {
    if (circulators && circulators.length >= 1) {
      setNoAdd(true);
    } else {
      setNoAdd(false);
    }
  }, [circulators]);

  useEffect(() => {
    if (record_id) {
      setProgressBar(true);
      getCirculators().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getCirculators = async () => {
    const { data } = await Api.getCirculatorsRP(record_id);
    setCirculators([...data.data]);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                ),
                tooltip: "Add Circulator (Max 1)",
                disabled: noAdd || !coordinatorsFromDoctor,
                hidden: noAdd || !coordinatorsFromDoctor,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton color="secondary">
                    <CloseIcon fontSize="small" />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                disabled: !coordinatorsFromDoctor,
                hidden: !coordinatorsFromDoctor,

                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this Circulator?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.id),
                  }),
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 3, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Circulator (Max 1)"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Add Circulator"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <CirculatorForm
          saveCirculator={saveCirculator}
          users={circulatorsOptions()}
          circulators={circulators}
          className={classes.form}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RecordProductCirculators;
