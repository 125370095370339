import React  from "react";
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutocompleteTagsControl(props) {
  const {
    label,
    options,
    onChange,
    tags,
    value,
    deleteLocked = false,
    ...other
  } = props;

  const checkDisabled = (option) => {
    const opt = tags.find((tag) => tag.tagname == option);

    if (opt && opt.locked == 1) {
      return true;
    }
    return false;
  };

  const fixedOptions = value.filter((option) => {
    const opt = tags.find((tag) => tag.tagname == option);
    if (deleteLocked) {
      return false;
    }
    if (
      (opt && opt.locked == 1) ||
      option == "SUPER SALE" ||
      option == "Hot Summer Financing" ||
      option == "Summer Sale Financing" ||
      option == "Summer Financing" ||
      option == "Remarketing 1" ||
      option == "Remarketing 2"
    ) {
      return true;
    }
    return false;
  });

  return (
    <Autocomplete
      multiple
      id="multiple"
      fullWidth
      options={options}
      freeSolo
      disableClearable
      value={value}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              disabled={
                deleteLocked
                  ? false
                  : checkDisabled(option) ||
                    option == "SUPER SALE" ||
                    option == "Hot Summer Financing" ||
                    option == "Summer Sale Financing" ||
                    option == "Summer Financing" ||
                    option == "Remarketing 1" ||
                    option == "Remarketing 2"
              }
            />
          );
        })
      }
      onChange={(event, newValue) => {
        onChange([
          ...fixedOptions,
          ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
        ]);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
        />
      )}
      {...other}
    />
  );
}
