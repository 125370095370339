import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import Api from "../../lib/api";
import { useSelector } from "react-redux";

export default function InstructionsForm(props) {
  const { addOrEdit, instructionsForEdit = null } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const { authUser } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await addOrEdit(data);
    setIsSubmitting(false);
  };

  const editorConfig = {
    key: process.env.REACT_APP_FROALA_KEY,
    zIndex: 2501,
    attribution: false,
    dragInline: false,
    documentReady: true,
    htmlAllowedTags: [".*"],
    htmlAllowedAttrs: [".*"],
    useClasses: false,
    htmlRemoveTags: ["script"],
    pasteDeniedAttrs: ["style"],
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "emoticons",
      "entities",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "help",
      "image",
      "inlineClass",
      "inlineStyle",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "print",
      "quote",
      "save",
      "specialCharacters",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "outdent",
          "indent",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "quote",
        ],
        buttonsVisible: 6,
      },
      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
          "emoticons",
          "specialCharacters",
          "insertHR",
        ],
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "selectAll",
          "embedly",
          "html",
          "help",
          "save",
        ],
        align: "right",
        buttonsVisible: 2,
      },
      template: {
        buttonsVisible: 5,
      },
    },

    events: {
      initialized: async function () {
        await new Promise((resolve) => setTimeout(resolve, 1));
        // then do what you want

        // Do something here.
        // this is the editor instance.
        //    console.log(this);
      },
      "image.beforeUpload": function (files) {
        const data = new FormData();
        data.append("fileUrl", files[0]);

        Api.uploadImage(authUser.organization_id, data)
          .then((res) => {
            this.image.insert(res.data.data, null, null, this.image.get());
          })
          .catch((err) => {
            console.log(err);
          });
        return false;
      },
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2} style={{ alignItems: "center" }}>
        <Grid item xs={10}>
          <Controller
            name="name"
            control={control}
            defaultValue={instructionsForEdit?.name || ""}
            as={<Controls.Input label="Name" />}
            rules={{ required: "Name is required" }}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
        </Grid>
        <Grid item xs={2}>
          {" "}
          <Controls.Button
            disabled={isSubmitting}
            type="submit"
            text={isSubmitting ? "Submitting..." : "Submit"}
          />
        </Grid>
      </Grid>
      <Controller
        name="body"
        defaultValue={instructionsForEdit?.body || ""}
        control={control}
        as={({ onChange, value }) => (
          <FroalaEditorComponent
            tag="textarea"
            config={editorConfig}
            model={value}
            onModelChange={(value) => {
              onChange(value);
            }}
          />
        )}
        rules={{ required: "Body is required" }}
      />
      {errors.body && <p className="error">{errors.body.message}</p>}
    </form>
  );
}
