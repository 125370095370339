import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  contactFormTemplates: [],
};

const contactformtemplatesSlice = createSlice({
  name: "contactFormTemplates",
  initialState,
  reducers: {
    getTemplates: (state) => {
      state.loading = true;
    },
    getTemplatesSuccess: (state, { payload }) => {
      state.contactFormTemplates = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getTemplatesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createTemplate: (state) => {},
    createTemplateSuccess: (state, { payload }) => {
      state.contactFormTemplates = [
        ...state.contactFormTemplates,
        payload.data,
      ];
      state.loading = false;
      state.errors = [];
    },
    createTemplateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateTemplate: (state) => {},
    updateTemplateSuccess: (state, { payload }) => {
      const index = findIndex(state.contactFormTemplates, [
        "id",
        payload.data.id,
      ]);
      const user = { ...state.contactFormTemplates[index], ...payload.data };

      let contactFormTemplates = [...state.contactFormTemplates];
      contactFormTemplates[index] = user;

      state.contactFormTemplates = [...contactFormTemplates];
      state.loading = false;
      state.errors = [];
    },
    updateTemplateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteTemplate: (state) => {},
    deleteTemplateSuccess: (state, { payload }) => {
      state.contactFormTemplates = state.contactFormTemplates.filter(
        (template) => {
          return template.id !== payload;
        }
      );
    },
    deleteTemplateFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getTemplates,
  getTemplatesSuccess,
  getTemplatesFailure,
  createTemplate,
  createTemplateSuccess,
  createTemplateFailure,
  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFailure,
  deleteTemplate,
  deleteTemplateSuccess,
  deleteTemplateFailure,
} = contactformtemplatesSlice.actions;
export const templatesSelector = (state) => state.contactFormTemplates;
export default contactformtemplatesSlice.reducer;

export function fetchContactFormTemplatesService(orgId , payload) {
  return async (dispatch) => {
    dispatch(getTemplates());

    try {
      const response = await Api.getContactFormTemplates(orgId, payload);

      dispatch(getTemplatesSuccess(response.data));
    } catch (error) {
      dispatch(getTemplatesFailure(error));
    }
  };
}

export function createContactFormTemplateService(payload) {
  return async (dispatch) => {
    dispatch(createTemplate());
    try {
      const response = await Api.saveContactFormTemplate(payload);

      dispatch(createTemplateSuccess(response.data));
    } catch (error) {
      dispatch(createTemplateFailure(error));
    }
  };
}

export function updateContactFormTemplateService(id, payload) {
  return async (dispatch) => {
    dispatch(updateTemplate());

    try {
      const response = await Api.updateContactFormTemplate(id, payload);

      dispatch(updateTemplateSuccess(response.data));
    } catch (error) {
      dispatch(updateTemplateFailure(error));
    }
  };
}

export function deleteContactFormTemplateService(id) {
  return async (dispatch) => {
    dispatch(deleteTemplate());

    try {
      const response = await Api.deleteContactFormTemplate(id);

      dispatch(deleteTemplateSuccess(id));
    } catch (error) {
      dispatch(deleteTemplateFailure(error));
    }
  };
}
