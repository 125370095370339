import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { escape } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../controls";
import Input from "../controls/Input";
import { useSlice } from "../../helpers/util";
import { fetchEntitiesService as fetchUsersService } from "../../redux/slices/users2Slice";
import PerfectScrollBar from "react-perfect-scrollbar";

import "react-perfect-scrollbar/dist/css/styles.css";

const editorConfig = {
  // documentReady: true,
  htmlAllowedTags: [".*"],
  htmlAllowedAttrs: [".*"],
  htmlAllowedEmptyTags: ["field", "tokens"],
  pluginsEnabled: [
    "align",
    "charCounter",
    "codeBeautifier",
    "codeView",
    "colors",
    "draggable",
    "emoticons",
    "entities",
    "fontFamily",
    "fontSize",
    "fullscreen",
    "help",
    "image",
    "inlineClass",
    "inlineStyle",
    "link",
    "lists",
    "paragraphFormat",
    "paragraphStyle",
    "print",
    "quote",
    "save",
    "specialCharacters",
    "table",
    "url",
    // "video",
    "wordPaste",
  ],
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
      buttonsVisible: 3,
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "alignRight",
        "alignJustify",
        "outdent",
        "indent",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "quote",
      ],
      buttonsVisible: 6,
    },
    moreRich: {
      buttons: [
        "insertLink",
        "insertImage",
        "insertVideo",
        "insertTable",
        "emoticons",
        "specialCharacters",
        "insertHR",
      ],
      buttonsVisible: 4,
    },
    moreMisc: {
      buttons: [
        "undo",
        "redo",
        "fullscreen",
        "print",
        "getPDF",
        "selectAll",
        "embedly",
        "html",
        "help",
        "save",
      ],
      align: "right",
      buttonsVisible: 2,
    },
    template: {
      buttons: ["checkbox", "tokens"],
      buttonsVisible: 5,
    },
  },
};

const ComposeModal = forwardRef(function ComposeModal(
  { open, onClose, title, onSend, replyTo },
  ref
) {
  const localTitle = useMemo(() => (title ? title : "Compose"), [title]);
  const { authUser } = useSelector((state) => state.auth);
  const { users2 } = useSelector((state) => state.users2);
  const dataSliceUsers2 = useSlice(
    "users2",
    {
      orgId: authUser?.organization_id,
      getFilters: {
        limit: 0,
      },
    },
    [authUser]
  );
  const dispatch = useDispatch();
  const onDrop = async (acceptedFiles) => {
    setAttachments((at) => [...at, ...acceptedFiles]);
  };

  const [embededImages, setEmbededImages] = useState([]);
  const localEditorConfig = {
    ...editorConfig,
    placeholderText: "Email body here...",
    fileUploadMethod: "POST",
    fileUploadUrl: "https://localhost:3000/",
    heightMin: 200,
    events: {
      ...editorConfig.events,
      "image.beforeUpload": function (files) {
        // var editor = this;
        // if (files.length) {
        //     let blob = files[0]
        //     if (blob instanceof File) {
        //         blob = new Blob([blob], { type: blob.type })
        //     }
        //     let blob_url = URL.createObjectURL(blob)
        //     editor.image.insert(blob_url, null, null, editor.image.get());
        //     setEmbededImages(em=>({...em, [blob_url]: blob }))
        //     editor.popups.hideAll();
        //     // Stop default upload chain.
        //     return false;
        // }
      },
      "image.removed": function ($img) {
        let url = $img.attr("src");
        // setEmbededImages(em=>{
        //     delete em[url];
        //     return em;
        // })
        setEmbededImages((em) => em.filter((e) => e != url));
      },
      "image.inserted": function ($img, response) {
        // Do something here.
        // this is the editor instance.
        setEmbededImages((em) => [...em, $img.attr("src")]);
        //  console.log(this);
      },
    },
  };

  const [attachments, setAttachments] = useState([]);
  const {
    getRootProps,
    getInputProps,
    open: fileOpen,
    isDragActive,
  } = useDropzone({ onDrop });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      to: "",
      subject: "",
      body: "",
      reply_to: "",
      references: "",
    },
  });

  useEffect(() => {
    if (_.isEmpty(users2)) {
      dispatch(fetchUsersService(dataSliceUsers2));
    }
  }, [authUser]);

  const froalaRef = useRef();

  useImperativeHandle(ref, () => ({
    setValues: (values) => {
      reset(values);
      // Object.keys(values).forEach(k=>{
      //     setValue(k,values[k])
      // })
    },
  }));

  useEffect(() => {
    if (replyTo) {
      let reply_to = `<${replyTo.message_id}>`;
      let references = replyTo.header?.data?.references
        ? `${replyTo.header.data.references} <${replyTo.message_id}>`
        : `<${replyTo.message_id}>`;
      reset({
        to: replyTo.from,
        subject: `Re: ${replyTo.subject}`,
        body: `<br/><br/>On ${replyTo.date} ${escape(
          replyTo.from
        )} wrote:<br/> ${
          replyTo.body?.html ? replyTo.body.html : replyTo.body.text
        }`,
        reply_to: reply_to,
        references: references,
      });
    
    } else {
      reset({
        to: "",
        subject: "",
        body: "",
        reply_to: "",
        references: "",
      });
    }
    setAttachments([]);
    setEmbededImages([]);
  }, [replyTo, open]);

  const onSubmit = async (data) => {
    onSend &&
      onSend({
        ...data,
        files: attachments,
        embedded: embededImages,
      });
  };

  const handleClickRemoveAttach = async (at) => {
    setAttachments((attach) => attach.filter((f) => f != at));
  };

  return (
    <Dialog
      open={open || Boolean(replyTo)}
      onClose={onClose}
      fullWidth
      // fullScreen
      maxWidth="lg"
      PaperProps={{
        style: { borderRadius: 18, minHeight: "70vh" },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
          >
            {localTitle}
          </Typography>
          <IconButton color="secondary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              flexGrow: 1,
            }}
          >
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  style={{ marginBottom: 15 }}
                >
                  <Controller
                    control={control}
                    name="to"
                    rules={{
                      required: true,
                      pattern:
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})|[\w]+[\s\w+]*\w+\s*<(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))>+$/,
                    }}
                    render={({ onChange, value, name, ref, invalid }) => (
                      <Autocomplete
                        id="to"
                        freeSolo
                        defaultValue={value}
                        onChange={(event, value) => onChange(value)}
                        options={users2.map(
                          (user) =>
                            `${user.dropdown_name} <${user["users.email"]}>`
                        )}
                        renderInput={(params) => (
                          <Input
                            value={value}
                            onChange={onChange}
                            label="*To"
                            name={name}
                            inputRef={ref}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                  {errors.to && <Alert severity="error">Email required</Alert>}
                </Box>
                <Box display="flex" flexDirection={"column"}>
                  <Controller
                    control={control}
                    name="subject"
                    rules={{
                      required: true,
                      
                    }}
                    render={({ onChange, value, name, ref, invalid }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        label="*Subject"
                        name={name}
                        inputRef={ref}
                      />
                    )}
                  />
                  {errors.subject && <Alert severity="error">Required</Alert>}
                </Box>
              </Box>

              <Box
                {...getRootProps()}
                style={{
                  width: 100,
                  height: "auto",
                  border: isDragActive ? "3px dashed black" : "3px dashed #999",
                  marginLeft: 15,
                  borderRadius: 5,
                  backgroundColor: isDragActive ? "#DDF" : "transparent",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: 600,
                  color: isDragActive ? "black" : "#AAA",
                }}
              >
                <input {...getInputProps()} />
                <AttachFileIcon
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlignLast: "center",
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={"column"}
              style={{ flexGrow: 1 }}
            >
              <Controller
                control={control}
                name="body"
                rules={{
                  required: true,
                  
                }}
                render={({ onChange, value, name, ref, invalid }) => (
                  <Controls.RichTextarea
                    onChange={onChange}
                    defaultValue={value}
                    editorConfig={localEditorConfig}
                  />
                )}
              />
              <Controller
                control={control}
                name="reply_to"
                rules={
                  {
                    
                  }
                }
                as={<input name="reply_to" type="hidden" />}
              />
              <Controller
                control={control}
                name="references"
                rules={
                  {
                    
                  }
                }
                as={<input name="references" type="hidden" />}
              />
            </Box>
          </Box>
        </form>
      </DialogContent>
      <DialogContent>
        <PerfectScrollBar>
          <Grid
            container
            direction="row"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {attachments.map((at, i) => (
              <Grid item xs={3}>
                <Button
                  key={i}
                  endIcon={<CloseIcon />}
                  onClick={() => handleClickRemoveAttach(at)}
                >
                  <Typography>{at.name}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </PerfectScrollBar>
      </DialogContent>
      <DialogActions style={{ margin: 10, padding: 10 }}>
        <Button
          onClick={handleSubmit(onSubmit)}
          key={"Send"}
          variant="outlined"
          endIcon={<SendIcon />}
          style={{
            borderRadius: "30px",
            height: "40px",
            width: "140px",
            marginRight: 15,
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ComposeModal;
