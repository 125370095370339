import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import {
  isEmpty,
  uniqueId,
  map,
  findIndex,
  capitalize,
  find,
  hasIn,
} from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CRMUtils from "../utils";

import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  ListItemSecondaryAction,
  withStyles,
} from "@material-ui/core";
import { spacing, display, fontSize } from "@material-ui/system";
import CloseIcon from "@material-ui/icons/Close";

import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import SendIcon from "@material-ui/icons/Send";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";

import { useDispatch, useSelector } from "react-redux";
import Controls from "./controls";
import Popup from "./Popup";
import QuoteItemForm from "./forms/QuoteItemForm";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import moment from "moment";
import ApproveQuote from "./ApproveQuote";
import Api from "../lib/api";
import NumberFormatCustom from "./controls/NumberFormatCustom";
import SendContract from "./SendContract";
import PrintQuoteDetails from "./PrintQuoteDetails";
import PrintContractDetails from "./PrintContractDetails";
import Quote2Invoice from "./Quote2Invoice";
import ApplyItemDiscountForm from "./forms/ApplyItemDiscountForm";
import { fetchEntitiesService as fetchProductGiftReasonService } from "../redux/slices/productGiftReasonSlice.js";

import { fetchUsersServiceDropdown } from "../redux/slices/users";

var dataSliceproductGiftReason = {
  stateListName: "productGiftReason",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

import { fetchEntitiesService as fetchFinancingService } from "../redux/slices/financingSlice.js";

import Iconify from "./Iconify";
import ChangeItemDownpaymentForm from "./forms/ChangeItemDownpaymentForm.js";
import PrintQuoteContract from "./PrintQuoteContract.js";

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  included_margin: {
    width: "100%",
    margin: "25px 0px",
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainProduct: {},
  childProduct: {
    marginLeft: "35px",
    color: "#999",
  },
  dialogTitle: {
    padding: "0px 20px",
    backgroundColor: "#e0f2f1",
  },
  offerField: {
    fontWeight: 500,
    color: "#405465",
    fontSize: 12,
  },
  offerFieldTypography: {
    fontSize: 12,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    marginTop: -1,
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function QuoteBuilder(props) {
  const [openChangePricePopup, setOpenChangePricePopup] = useState(false);
  const { authUser } = useSelector((state) => state.auth);
  const userCanSendInvoicePayment = CRMUtils.userHasPermission(authUser, [
    "invoice_sendPayment",
  ]);
  const userCanConvertToInvoice = CRMUtils.userHasPermission(authUser, [
    "quote_ConvertToInvoice",
  ]);
  const userCanFinancing = CRMUtils.userHasPermission(authUser, [
    "invoice_financing",
  ]);
  const userCanEditFinancingInterest = CRMUtils.userHasPermission(authUser, [
    "user_editFinancingInterest",
  ]);

  const userCanChangeConsultant = CRMUtils.userHasPermission(authUser, [
    "quote_ChangeConsultant",
  ]);

  const userCanChangeApproveDate = CRMUtils.userHasPermission(authUser, [
    "quote_changeApproveDate",
  ]);

  const userCanChangeConsultantAll = CRMUtils.userHasPermission(authUser, [
    "quote_ChangeConsultantAll",
  ]);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  const userCanAddGiftItem = CRMUtils.userHasPermission(authUser, [
    "quote_addGiftItem",
  ]);

  const userCanChangeDownpayment = CRMUtils.userHasPermission(authUser, [
    "quote_changeitemdownpayment",
  ]);

  const { usersDropdown, usersDropdownLoanding } = useSelector(
    (state) => state.users
  );
  const {
    setQuotes = null,
    quotes = null,
    onConvert2Invoice,
    quote,
    users,
    products,
    contact,
    //contacts,
    statusOptions,
    onSaveQuote,
    onApproveQuote,
    paymentGateways,
    openItemDefault,
    readOnlySalesManager = false,
    getQuote,
  } = props;
  const [hasInvoice, setHasInvoice] = useState(
    quote && quote.hasInvoice ? quote.hasInvoice : 0
  );
  const quoteRef = useRef();
  const contractRef = useRef();
  const dispatch = useDispatch();
  const { financing } = useSelector((state) => state.financing);
  /*
  const { recordproducts, loading, errors } = useSelector(
    (state) => state.recordProducts
  );
  */
  const [printContractPopup, setPrintContractPopup] = useState(false);
  const [approvingQuote, setApprovingQuote] = useState(false);
  const [updatePackagePriceNeeded, setUpdatePackagePriceNeeded] =
    useState(false);
  const [selectedPackageItem, setSelectedPackageItem] = useState(null);
  const [packageItemPrice, setPackageItemPrice] = useState(null);
  const { productGiftReason } = useSelector((state) => state.productGiftReason);
  const [financingInterest, setFinancingInterest] = useState(0);
  const [recordproducts, setRecordProducts] = useState([]);
  const [userMinPriceAvailableQty, setUserMinPriceAvailableQty] = useState(0);
  const TODAY = moment();
  const [expirationDate, setExpirationDate] = useState(
    quote
      ? quote.expiration_date
        ? moment(quote.expiration_date, "YYYY-MM-DD")
        : null
      : authUser.organization?.data?.maxQuoteTime
      ? moment(TODAY, "YYYY-MM-DD").add(
          authUser.organization.data.maxQuoteTime,
          "days"
        )
      : null
  );

  const [approvedDate, setApprovedDate] = useState(
    quote && quote.approved_at
      ? moment(quote.approved_at, "YYYY-MM-DD HH:mm:ss")
      : null
  );

  const userIsBillingAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingAssistant",
  ]);

  const userCanEditIncluded = CRMUtils.userHasPermission(authUser, [
    "quote_editIncluded",
  ]);

  const userCanApplyItemDiscountAdd = CRMUtils.userHasPermission(authUser, [
    "quote_ItemDiscountAdd",
  ]);

  const userCanApplyItemDiscountEdit = CRMUtils.userHasPermission(authUser, [
    "quote_ItemDiscountEdit",
  ]);

  const userCanApplyItemDiscountDelete = CRMUtils.userHasPermission(authUser, [
    "quote_ItemDiscountDelete",
  ]);
  const userCanUseMinPrice =
    CRMUtils.userHasPermission(authUser, ["user_useminprice"]) &&
    userMinPriceAvailableQty > 0;

  const [items, setItems] = useState(quote ? quote.items.data : []);
  const [status, setStatus] = useState(quote ? quote.status : "unsend");
  const [readOnly, setReadOnly] = useState(false);
  const [included, setIncluded] = useState(quote ? quote.included : "");

  const [userID, setUserID] = useState(null);
  const classes = useStyles();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [financingOption, setFinancingOption] = useState(
    quote ? (quote.financing_id ? quote.financing_id : -1) : -1
  );
  const [updateNeeded, setUpdateNeeded] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [editItemUserPrice, setEditItemUserPrice] = useState(null);

  const [userAZ, setUserAZ] = useState(null);

  const [editItemIndex, setEditItemIndex] = useState(null);
  const [openItem, setOpenItem] = useState(false);
  const [openItemGift, setOpenItemGift] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [sendContractPopup, setSendContractPopup] = useState(false);
  const [openConvert, setConvert] = useState(false);
  const [contractToPrint, setContractToPrint] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isDownpaymentPopupOpened, setIsDownpaymentPopupOpened] =
    useState(false);
  const [downpaymentItem, setDownpaymentItem] = useState(null);
  const [applyDiscountPopUp, setApplyDiscountPopUp] = useState(false);
  const [applyDiscountItem, setApplyDiscountItem] = useState(null);
  const [duplicatingQuote, setDuplicatingQuote] = useState(false);

  const openApplyDiscount = (item) => {
    setApplyDiscountItem(item);
    setApplyDiscountPopUp(true);
  };

  const closeApplyDiscount = () => {
    setApplyDiscountPopUp(false);
    setApplyDiscountItem(null);
  };

  const onApplyDiscount = async (info) => {
    const payload = {
      discount_applied: info.discount_applied,
      discount_comment: info.discount_comment,
      discount_user_id: authUser?.id,
      price:
        financingOption > 0 && applyDiscountItem.product_apply_financing
          ? ((applyDiscountItem.discount_applied && applyDiscountItem.base_price
              ? parseFloat(applyDiscountItem.discount_applied) +
                parseFloat(applyDiscountItem?.base_price)
              : parseFloat(applyDiscountItem?.base_price)) -
              parseFloat(info.discount_applied)) *
            (1 +
              (financingInterest
                ? financingInterest
                : quote && quote.interest
                ? quote.interest
                : 1) /
                100)
          : (applyDiscountItem.discount_applied && applyDiscountItem.base_price
              ? parseFloat(applyDiscountItem.discount_applied) +
                parseFloat(applyDiscountItem?.base_price)
              : parseFloat(applyDiscountItem?.base_price)) -
            parseFloat(info.discount_applied),

      base_price:
        (applyDiscountItem.discount_applied && applyDiscountItem.base_price
          ? parseFloat(applyDiscountItem.discount_applied) +
            parseFloat(applyDiscountItem?.base_price)
          : parseFloat(applyDiscountItem?.base_price)) -
        parseFloat(info.discount_applied),
    };

    // console.log("onApplyDiscount", payload);

    if (typeof applyDiscountItem?.id === "number") {
      const { data } = await Api.quoteApplyItemDiscount(
        quote?.id,
        applyDiscountItem?.id,
        payload
      );

      if (data.data) {
        getQuote();
      }
    } else {
      console.log("onApplyDiscount", payload);

      // const newItems = items.map((item) => {
      //   if (item.id === applyDiscountItem?.id) {
      //     return {
      //       ...item,
      //       ...payload,
      //     };
      //   }
      //   return item;
      // });

      // setItems(newItems);
    }

    closeApplyDiscount();
  };
  const onRemoveDiscount = async (item) => {
    const { data } = await Api.quoteRemoveItemDiscount(quote?.id, item?.id);

    if (data.data) {
      getQuote();
    }

    closeApplyDiscount();
  };

  const getItemPrice = (price, base, product_apply_financing) => {
    if (financingInterest && product_apply_financing) {
      const value = base ? base : price;

      return parseFloat(value + (value * financingInterest) / 100).toFixed(2);
    }
    return base || price;
  };

  const checkItemsProcedure = () => {
    let returnItem = false;

    items.forEach((item) => {
      if (item.isProcedure == 1) {
        returnItem = true;
      }
    });

    return returnItem;
  };

  const saveQuote = async () => {
    //console.error("saveQuote1", items);
    setIsSubmitting(true);
    const itemsUpdated = items.map((item) => {
      return {
        ...item,
        id: item.id > 0 ? item.id : 0,
        base_price: parseFloat(item.base_price || item.price).toFixed(2),
        product_apply_financing: item.product_apply_financing,
        product_gift_reason_id: item.product_gift_reason_id,
        gift_reference_price: item.gift_reference_price,
        user_product_price_id: item.user_product_price_id,
        full_payment_due: item.full_payment_due,
        late_payment_fee: item.late_payment_fee,
        gift_user_id: item.product_gift_reason_id > 0 ? authUser.id : null,
        product_items:
          item.product_items && item.product_items.length > 0
            ? item.product_items.map((product_item) => ({
                ...product_item,
                ...{
                  price: getItemPrice(
                    product_item.price,
                    product_item.price,
                    item.product_apply_financing
                  ),
                  base_price: product_item.price,
                },
              }))
            : [],
        parent_id: item.parent_id,
        price: getItemPrice(
          item.type == "package" && item.package_price
            ? item.package_price
            : item.price,
          item.type == "package" && item.base_package_price
            ? item.base_package_price
            : item.base_price,
          item.product_apply_financing
        ),
      };
    });

    const payload = {
      ...quote,
      financing_id: financingOption > 0 ? financingOption : null,
      status: status,
      items: itemsUpdated,
      included: included,
      interest: financingInterest,
      expiration_date: expirationDate
        ? expirationDate.format("YYYY-MM-DD")
        : null,
      user_id: userID ? userID : quote?.user_id,
      approved_at: approvedDate
        ? approvedDate.format("YYYY-MM-DD HH:mm:ss")
        : null,
    };

    //  console.log("PAYLOAD: ", payload);
    await onSaveQuote(payload);
    //console.error("onSaveQuote",result);
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });

    //getQuote();
    //setItems(quote ? quote.items.data : []);
    setIsSubmitting(false);
    setUpdateNeeded(false);
    setUpdatePackagePriceNeeded(false);
    // console.error("saveQuote ", items);
    closeChangePrice();
  };

  // useEffect(() => {
  //   console.log("QUOTE: ", quote);
  // }, [quote]);

  const canEditPackageItemPrice = CRMUtils.userHasPermission(authUser, [
    "quote_changepackageitemprice",
  ]);

  const openCustomDownpaymentPopup = (item) => {
    setDownpaymentItem(item);
    setIsDownpaymentPopupOpened(true);
  };

  const closeDownpaymentPopup = () => {
    setDownpaymentItem(null);
    setIsDownpaymentPopupOpened(false);
  };

  const getMinPriceAvailableQtyToUse = async () => {
    const { data } = await Api.getMinPriceAvailableQtyToUse(
      authUser.building_id,
      contact.id,
      authUser?.id || null
    );

    setUserMinPriceAvailableQty(data);
  };

  const onDeleteItem = async (id) => {
    if (quote) {
      if (
        quote.status !== "approved" &&
        (!quote.signed ||
          hasInvoice == 0 ||
          !(authUser?.organization?.data?.immutable_signed_quotes || false))
      ) {
        if (Number.isInteger(id))
          try {
            await Api.deleteQuoteItem(quote.id, id);
          } catch (error) {
            if (error.status == 409 && (error?.data?.error || false)) {
              setNotify({
                isOpen: true,
                message: error.data.error,
                type: "error",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "Something went wrong!",
                type: "error",
              });
            }

            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });

            console.log(error);
          }
      }
    }

    const newItems = items.filter(
      (item) => item.id != id && item.parent_id != id
    );

    setItems(newItems);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const addQuoteItem = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItem(true);
  };

  const addQuoteItemGift = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItemGift(true);
  };

  const closeItemPopupGift = () => {
    setOpenItemGift(false);
  };

  const closeItemPopup = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItem(false);
  };

  const closeConvertPopup = () => {
    setConvert(false);
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  const saveQuoteItem = (data) => {
    // console.error("saveQuotedata", data);
    setUpdateNeeded(true);
    const item = {
      product_id: data.productId,
      is_giftable: data.giftable,
      name: data.productName,
      cal_color: data.cal_color,
      base_price: parseFloat(data.productPrice),
      price: parseFloat(
        getItemPrice(data.productPrice, null, data.product_apply_financing)
      ),
      type: data.type,
      responsible: !isEmpty(data.responsible) ? data.responsible : "",

      calendar_date: !isEmpty(data.quoteItemDate)
        ? moment(data.quoteItemDate).format("YYYY-MM-DD")
        : null,
      override_price: null,
      override_price_by: null,
      override_price_date: null,
      quantity: data.quantity,
      isProcedure: data.isProcedure,
      parent_id: data.parent_id,
      is_package: data.is_package,
      product_apply_financing: data.productApplyFinancing,
      product_gift_reason_id: data.product_gift_reason_id,
      gift_reference_price: data.gift_reference_price,
      gift_user_id: data.product_gift_reason_id > 0 ? authUser.id : null,
      product_items: data.product_items,
      parent_id: data.parent_id,
      user_product_price_id: data.user_product_price_id,
      full_payment_due: data.full_payment_due,
      late_payment_fee: data.late_payment_fee,
      downpayment_amount: data.downpayment_amount,
      is_revision: data.is_revision,
      revision_comment: data.revision_comment,
      product_standard_price: data.product_standard_price,
      product_min_price: data.product_min_price,
    };

    console.log("item: ", item);
    if (editItem) {
      items[editItemIndex] = {
        ...item,
        id: editItem.id,
      };
      setItems(items);

      setUpdateNeeded(false);
      setUpdatePackagePriceNeeded(false);
      if (quote && quote.items.data && items && items != []) {
        saveQuote();
      }
    } else {
      setItems([
        ...items,
        {
          ...item,
          id: uniqueId("quote_item_"),
        },
      ]);
    }

    closeItemPopup();
    closeItemPopupGift();
  };

  const updateItemQuantity = (id, value) => {
    const newItems = items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: value,
        };
      }
      return item;
    });
    setItems(newItems);
  };

  const updateItemDiscount = (id, value) => {
    const newItems = items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          discount: value,
        };
      }
      return item;
    });
    setItems(newItems);
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    const itemPrice = getItemPrice(
      item.type == "package" && item.package_price
        ? item.package_price
        : item.price,
      item.type == "package" && item.base_package_price
        ? item.base_package_price
        : item.base_price,
      item.product_apply_financing
    );
    return (
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };
  const changeIncluded = (value) => {
    setIncluded(value);
  };
  const calculateItemDiscount = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      discount * (item.price_includes_qty ? 1 : parseInt(quantity)).toFixed(2)
    );
  };
  const calculateTotalDiscount = () => {
    return items.reduce(
      (total, item) => total + calculateItemDiscount(item),
      0
    );
  };

  const calculateSubtotal = () => {
    return items
      .filter((i) => !i.void)
      .reduce(
        (total, item) =>
          total +
          calculateItemTotal({
            ...item,
            ...{ package_price: 0, base_package_price: 0 },
          }),
        0
      );
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  /*const contactOptions = () => {
    return contacts.map((contact) => {
      return {
        id: contact.id,
        title: contact.full_name,
      };
    });
  };*/

  const convertQuote2Invoice = async (ids) => {
    try {
      const invoiceItems = items
        .filter((item) => {
          return [].concat(ids).includes(item.id);
        })
        .map((item) => {
          return {
            item_name: item.name,
            price: item.type == "package" ? item.package_price : item.price,
            base_package_price: item.base_package_price
              ? item.base_package_price
              : null,
            override_price: item.override_price,
            override_price_by: item.override_price_by,
            override_price_date: item.override_price_date,
            responsible_id: item.responsible_id,
            quantity: item.quantity,
            type: item.type,
            product_id: item.product_id,
            cal_color: item.cal_color,
            base_price: item.base_price,
            calendar_date: item.calendar_date,
            product_gift_reason_id: item.product_gift_reason_id,
            gift_reference_price: item.gift_reference_price,
            gift_user_id: item.gift_user_id,
            product_items: item.product_items,
            discount_applied: item.discount_applied,
            discount_comment: item.discount_comment,
            discount_user_id: item.discount_user_id,
            user_product_price_id: item.user_product_price_id,
            full_payment_due: item.full_payment_due,
            late_payment_fee: item.late_payment_fee,
            downpayment_amount: item.downpayment_amount,
            item_id: item.id,
            void: item.void ? true : false,
            unit_price: item.unit_price ? item.unit_price : null,
            void_quantity: item.void_quantity ? item.void_quantity : 0,
            price_includes_qty: item.price_includes_qty
              ? item.price_includes_qty
              : false,
            is_revision: item.is_revision,
            revision_comment: item.revision_comment,
            product_min_price: item.product_min_price,
            product_standard_price: item.product_standard_price,
          };
        });

      if (onConvert2Invoice) await onConvert2Invoice(invoiceItems);
      closeConvertPopup();
      setHasInvoice(hasInvoice + 1);
    } catch (err) {}
  };

  const closeChangePrice = () => {
    setOpenChangePricePopup(false);
    setSelectedPackageItem(null);
    setPackageItemPrice(null);
  };

  const changePackagePrice = () => {
    setUpdatePackagePriceNeeded(true);
    const index = findIndex(items, ["id", selectedPackageItem.id]);
    const updatedEvent = {
      ...items[index],
      ...{
        price: parseFloat(packageItemPrice),
        totalPrice: parseFloat(
          packageItemPrice *
            (selectedPackageItem.price_includes_qty
              ? 1
              : parseInt(selectedPackageItem.quantity))
        ),
        base_price: parseFloat(packageItemPrice),
      },
    };

    const indexParent = findIndex(items, ["id", selectedPackageItem.parent_id]);
    const updatedEventParent = {
      ...items[indexParent],
      ...{
        package_price:
          parseFloat(items[indexParent].package_price) -
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(selectedPackageItem.price) +
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(packageItemPrice),
        totalPrice:
          (parseFloat(items[indexParent].package_price) -
            (selectedPackageItem.price_includes_qty
              ? 1
              : selectedPackageItem.quantity) *
              parseFloat(selectedPackageItem.price) +
            (selectedPackageItem.price_includes_qty
              ? 1
              : selectedPackageItem.quantity) *
              parseFloat(packageItemPrice)) *
          (items[indexParent].price_includes_qty
            ? 1
            : parseFloat(items[indexParent].quantity)),
        base_package_price:
          parseFloat(items[indexParent].base_package_price) -
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(selectedPackageItem.base_price) +
          (selectedPackageItem.price_includes_qty
            ? 1
            : selectedPackageItem.quantity) *
            parseFloat(packageItemPrice),
      },
    };

    let newItems = [...items];
    newItems[index] = updatedEvent;
    newItems[indexParent] = updatedEventParent;

    setItems([...newItems]);
    closeChangePrice();
  };

  const closeApprovePopup = () => {
    setOpenApproveModal(false);
  };

  const closeSendContractPopup = () => {
    setSendContractPopup(false);
  };

  const onSend = async (id) => {
    try {
      const { data } = await Api.sendQuote(id);
      if (data) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Quote Sent Successfully",
          type: "success",
        });

        if (quote && quote.status == "unsend") {
          setStatus("sent");
          if (quotes && setQuotes) {
            const index = findIndex(quotes, ["id", quote.id]);
            const entity = {
              ...quotes[index],
              ...{
                ...quote,
                status: "sent",
              },
            };
            let entities = [...quotes];
            entities[index] = entity;
            setQuotes([...entities]);
          }
        }
      }
    } catch (error) {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      setNotify({
        isOpen: true,
        message: error?.data?.message,
        type: "error",
      });
    }
  };

  //    ----------------------------------
  function ItemPriceHlp({ item }) {
    // console.log("dddddddddddddddddss", item);

    const calculatePrice = (item) => {
      const price =
        item.type === "package" && item.package_price
          ? item.package_price
          : item.price;
      const basePrice =
        item.type === "package" && item.package_price
          ? item.base_package_price
          : item.base_price;

      return parseFloat(
        getItemPrice(price, basePrice, item.product_apply_financing)
      ).toFixed(2);
    };

    const IconComponent = () => {
      if (item.product_gift_reason_id) {
        return (
          <CardGiftcardIcon
            fontSize="small"
            style={{ color: "#673ab7", marginRight: 5 }}
          />
        );
      } else if (item && item.discount_applied) {
        return (
          <Iconify
            icon={"fluent:credit-card-person-24-filled"}
            width={20}
            height={20}
            style={{ color: "#673ab7", marginRight: 5 }}
          />
        );
      }
      return null;
    };

    const PriceComponent = () => {
      if (item.product_gift_reason_id || item.discount_applied) {
        const originalPrice = calculatePrice(item);
        const discountedPrice = item.discount_applied
          ? (
              parseFloat(originalPrice) + parseFloat(item.discount_applied)
            ).toFixed(2)
          : item.gift_reference_price;

        return (
          <>
            <span> / </span>
            <span style={{ color: "#8080808a" }}>${discountedPrice}</span>
          </>
        );
      }
      return null;
    };

    return (
      <div>
        <IconComponent />${calculatePrice(item)}
        <PriceComponent />
        {item.full_payment_due && (
          <ListItemText
            secondaryTypographyProps={{
              style: {
                fontStyle: "italic",
              },
            }}
            secondary={`Promotional pricing available until ${
              item.full_payment_due
            }. Regular pricing applies thereafter,
            ($${
              item?.late_payment_fee || 0
            } will be added to the invoice as a separate item).`}
          />
        )}
      </div>
    );
  }

  //    ----------------------------------
  const sendContract = async () => {
    await Api.sendContract(quote.id).then((result) => {
      if (quote && quote.status == "unsend") {
        setStatus("sent");
      }

      setSendContractPopup(false);
    });
  };

  const approveContract = async (ids, payment, contract, credit) => {
    try {
      setApprovingQuote(true);
      const contractItems = items
        .filter((a) => a.parent_id == null)
        .filter((item) => {
          return [].concat(ids).includes(item.id);
        })
        .map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            price: item.price,
            base_price: item.base_price,
            package_price: item.package_price,
            base_package_price: item.base_package_price,
            override_price: item.override_price,
            override_price_by: item.override_price_by,
            override_price_date: item.override_price_date,
            responsible_id: item.responsible_id,
            calendar_date: item.calendar_date,
            cal_color: item.cal_color,
            quantity: item.quantity,
            type: item.type,
            product_id: item.product_id,
            product_gift_reason_id: item.product_gift_reason_id,
            gift_reference_price: item.gift_reference_price,
            gift_user_id: item.gift_user_id,
            product_items: item.product_items,
            discount_applied: item.discount_applied,
            discount_comment: item.discount_comment,
            discount_user_id: item.discount_user_id,
            user_product_price_id: item.user_product_price_id,
            full_payment_due: item.full_payment_due,
            late_payment_fee: item.late_payment_fee,
            downpayment_amount: item.downpayment_amount,
            void: item.void ? true : false,
            unit_price: item.unit_price ? item.unit_price : null,
            void_quantity: item.void_quantity ? item.void_quantity : 0,
            price_includes_qty: item.price_includes_qty
              ? item.price_includes_qty
              : false,
            is_revision: item?.is_revision || 0,
            revision_comment: item?.revision_comment || null,
            product_standard_price: item.product_standard_price,
            product_min_price: item.product_min_price,
          };
        });

      if (typeof onApproveQuote === "function") {
        await onApproveQuote(
          quote.id,
          contractItems,
          payment,
          contract,
          credit
        );
      }

      closeApprovePopup();
      setApprovingQuote(false);
    } catch (err) {
      setApprovingQuote(false);
    }
  };

  const getContractToPrint = async () => {
    if (quote) {
      const { data } = await Api.getContractByQuote(quote.id);
      setContractToPrint(data);
    }
  };

  const getActiveStep = () => {
    const index = findIndex(statusOptions, ["id", status]);
    //console.log("index ", index, status);
    return index;
  };

  const checkMainProduct = (id) => {
    //Check that Main product exists
    return items
      ? items.filter((element) => {
          //return element && element.childProduct && pluck(element.childProduct,'child_product_id').includes(id) === true;
          //console.error(id,element,(element && element.childProducts)?element.childProducts.filter((row)=>{return row.child_product_id == id}).length:"");
          return (
            element &&
            element.childProducts &&
            element.childProducts.filter((row) => {
              return row.child_product_id == id;
            }).length > 0
          );
        })
      : [];
  };

  const renderItems = () => {
    return items.map((item, index) => {
      // console.log("ITEM RENDER ITEMS: ", item);
      return (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            <ListItem>
              {item.type == "package" ? (
                <Iconify
                  icon="entypo:shopping-bag"
                  style={{ fontSize: 18, color: "gray", marginRight: 15 }}
                />
              ) : (
                ""
              )}
              {item.responsible && (
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography>
                    {item.name}
                    {item.is_revision ? (
                      <strong
                        style={{
                          color: "#326a9b",
                          marginLeft: 5,
                          fontWeight: 400,
                        }}
                      >
                        {"(Revision)"}
                      </strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                }
                secondary={`${
                  item.responsible
                    ? item.responsible.title ||
                      `${item.responsible.fname} ${item.responsible.lname}`
                    : ""
                }`}
                className={
                  item && item.parent_id
                    ? classes.childProduct
                    : classes.mainProduct
                }
              />{" "}
              {item.is_revision && item.revision_comment ? (
                <HtmlTooltip
                  title={
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(187 187 187 / 87%)",
                          padding: "2px 0px",
                        }}
                      >
                        Revision comment
                      </div>
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          {item.revision_comment}
                        </Typography>
                      </div>{" "}
                    </div>
                  }
                >
                  <span>
                    <Iconify
                      style={{
                        width: "22px",
                        height: "22px",
                        color: "rgb(113, 141, 165)",
                      }}
                      icon="mage:message-dots-round-fill"
                    />
                  </span>
                </HtmlTooltip>
              ) : (
                ""
              )}
              {item.user_product_price_id &&
              item.user_product_price &&
              item.user_product_price.offer_from ? (
                <HtmlTooltip
                  title={
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(187 187 187 / 87%)",
                          padding: "2px 0px",
                        }}
                      >
                        Offer info
                      </div>
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          <strong className={classes.offerField}>Name: </strong>
                          {` ${item?.user_product_price.offer_name || ""}`}
                        </Typography>
                      </div>{" "}
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          <strong className={classes.offerField}>
                            Price:{" "}
                          </strong>
                          {` $${item?.user_product_price.price || ""}`}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          <strong className={classes.offerField}>
                            Sale from:{" "}
                          </strong>
                          {` ${item?.user_product_price.offer_from || "-"}`}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          <strong className={classes.offerField}>
                            Sale to:{" "}
                          </strong>
                          {` ${item?.user_product_price.offer_to || "-"}`}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          <strong className={classes.offerField}>
                            Booking from:{" "}
                          </strong>
                          {` ${item?.user_product_price.booking_from || "-"}`}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.offerFieldTypography}>
                          <strong className={classes.offerField}>
                            Booking to:{" "}
                          </strong>
                          {` ${item?.user_product_price.booking_to || "-"}`}
                        </Typography>
                      </div>
                    </div>
                  }
                >
                  <span>
                    <Iconify
                      style={{
                        width: "22px",
                        height: "22px",
                        color: "#718da5",
                      }}
                      icon="f7:tag-circle-fill"
                    />
                  </span>
                </HtmlTooltip>
              ) : (
                ""
              )}
            </ListItem>
          </TableCell>

          <TableCell>
            {item.calendar_date
              ? moment(item.calendar_date).format("YYYY-MM-DD")
              : ""}
          </TableCell>
          <TableCell style={{ width: "25%" }}>
            {item.parent_id ? (
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.zero_package_value &&
              !item.isProcedure ? (
                <div>$0.00</div>
              ) : (
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgb(14, 85, 126)",
                    backgroundColor: "#deeced",
                    textAlign: "center",
                    padding: "0px 15px",
                    maxWidth: "fit-content",
                    borderRadius: 12,
                  }}
                >
                  {"INCLUDED"}
                </div>
              )
            ) : (
              <ItemPriceHlp item={item} />
            )}
          </TableCell>
          <TableCell style={{ width: "10%", textAlign: "center" }}>
            {!quote ||
            (quote.status !== "approved" &&
              (!quote.signed ||
                hasInvoice == 0 ||
                !(
                  authUser?.organization?.data?.immutable_signed_quotes || false
                ))) ? (
              item.parent_id ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    color: "#adadad",
                    borderBottom: "1px dotted gray",
                  }}
                >
                  <Typography>{item.quantity}</Typography>
                  {item.void_quantity ? (
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginLeft: 5,
                        color: "#a76c65",
                        fontSize: 12,
                      }}
                    >{`(${item.void_quantity} VOID)`}</Typography>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <TextField
                  className={classes.formControl}
                  value={item.quantity || 1}
                  onChange={(e) => updateItemQuantity(item.id, e.target.value)}
                  disabled={
                    item.isProcedure == 1 ||
                    item.parent_id ||
                    item.type == "package"
                      ? true
                      : false
                  }
                  inputProps={{
                    decimalPrecision: 0,
                    decimalSeparator: false,
                    allowNegative: false,
                    isAllowed: (values) => {
                      const { floatValue } = values;
                      return floatValue >= 1 && floatValue <= 100;
                    },
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              )
            ) : (
              <span>{item.quantity || 1}</span>
            )}
          </TableCell>
          {quote && quote.downpayment_per_procedure ? (
            <TableCell align="right">
              {item.type == "package"
                ? item.package_downpayment_amount !== null &&
                  item.package_downpayment_amount !== undefined
                  ? "$" + item.package_downpayment_amount
                  : ""
                : item.downpayment_amount !== null &&
                  !item.parent_id &&
                  item.downpayment_amount !== undefined
                ? "$" + (item?.downpayment_amount || 0)
                : ""}
            </TableCell>
          ) : (
            ""
          )}

          <TableCell align="right">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              {item && item.parent_id
                ? ""
                : "$" + calculateItemTotal(item).toFixed(2)}
              {item.void ? (
                <Typography
                  style={{ fontWeight: 700, fontSize: 12, marginLeft: 5 }}
                >
                  VOID
                </Typography>
              ) : (
                ""
              )}
            </div>
          </TableCell>

          <TableCell align="right">
            <div style={{ display: "flex", alignItems: "center" }}>
              {(!quote && item && !item.parent_id) ||
              (quote.status !== "approved" &&
                (!quote.signed ||
                  hasInvoice == 0 ||
                  !(
                    authUser?.organization?.data?.immutable_signed_quotes ||
                    false
                  )) &&
                item &&
                !item.parent_id) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.type != "package" ? (
                    <>
                      <Tooltip title={"Edit Item"}>
                        <div>
                          <Controls.IconButton
                            style={{
                              color:
                                readOnlySalesManager ||
                                userIsBillingAssistant ||
                                userCanViewEditContactMark ||
                                (item.invoice_item_has_payments &&
                                  quote &&
                                  quote.check_integrity)
                                  ? "#bdbdbd"
                                  : "#009688",
                            }}
                            disabled={
                              readOnlySalesManager ||
                              userIsBillingAssistant ||
                              userCanViewEditContactMark ||
                              (item.invoice_item_has_payments &&
                                quote &&
                                quote.check_integrity)
                            }
                            onClick={async () => {
                              if (item.user_product_price_id) {
                                const { data } = await Api.getUserProductPrice(
                                  authUser.organization_id,
                                  item.user_product_price_id
                                );

                                const userIdNew =
                                  item && item.responsible_id
                                    ? item.responsible_id
                                    : item && !isEmpty(item.responsible)
                                    ? item.responsible.id
                                    : null;

                                if (userIdNew) {
                                  const response = await Api.getUser(userIdNew);

                                  setUserAZ(response.data.data);

                                  setEditItemUserPrice(data.data);
                                  setEditItem(item);
                                  setEditItemIndex(index);
                                  setOpenItem(true);
                                }
                              } else {
                                setEditItem(item);
                                setEditItemIndex(index);
                                setOpenItem(true);
                              }
                            }}
                          >
                            <Iconify
                              icon={"fluent:edit-24-filled"}
                              width={20}
                              height={20}
                            />
                          </Controls.IconButton>
                        </div>
                      </Tooltip>

                      {quote &&
                        userCanApplyItemDiscountAdd &&
                        !item.discount_applied &&
                        !item.product_gift_reason_id && (
                          <Tooltip title={"Apply Discount"}>
                            <div>
                              <Controls.IconButton
                                disabled={
                                  readOnlySalesManager ||
                                  userIsBillingAssistant ||
                                  userCanViewEditContactMark
                                }
                                style={{
                                  color:
                                    readOnlySalesManager ||
                                    userIsBillingAssistant ||
                                    userCanViewEditContactMark
                                      ? "#bdbdbd"
                                      : "#673ab7",
                                }}
                                onClick={() => {
                                  openApplyDiscount(item);
                                }}
                              >
                                <Iconify
                                  icon={"majesticons:creditcard-plus"}
                                  size="small"
                                />
                              </Controls.IconButton>
                            </div>
                          </Tooltip>
                        )}
                      {quote &&
                        item.discount_applied &&
                        userCanApplyItemDiscountEdit &&
                        !item.product_gift_reason_id && (
                          <Tooltip title={"Edit Discount"}>
                            <div>
                              <Controls.IconButton
                                disabled={
                                  readOnlySalesManager ||
                                  userIsBillingAssistant ||
                                  userCanViewEditContactMark
                                }
                                style={{
                                  color:
                                    readOnlySalesManager ||
                                    userIsBillingAssistant ||
                                    userCanViewEditContactMark
                                      ? "#bdbdbd"
                                      : "#673ab7",
                                }}
                                onClick={() => {
                                  openApplyDiscount(item);
                                }}
                              >
                                <Iconify
                                  icon={"mdi:credit-card-refresh"}
                                  size="small"
                                />
                              </Controls.IconButton>
                            </div>
                          </Tooltip>
                        )}
                      {quote &&
                        item.discount_applied &&
                        userCanApplyItemDiscountDelete &&
                        !item.product_gift_reason_id && (
                          <Tooltip title={"Delete Discount"}>
                            <div>
                              <Controls.IconButton
                                disabled={
                                  readOnlySalesManager ||
                                  userIsBillingAssistant ||
                                  userCanViewEditContactMark
                                }
                                style={{
                                  color:
                                    readOnlySalesManager ||
                                    userIsBillingAssistant ||
                                    userCanViewEditContactMark
                                      ? "#bdbdbd"
                                      : "#e91e63",
                                }}
                                onClick={() => {
                                  onRemoveDiscount(item);
                                }}
                              >
                                <Iconify
                                  icon="pepicons-print:credit-card-off"
                                  size="small"
                                />
                              </Controls.IconButton>
                            </div>
                          </Tooltip>
                        )}
                    </>
                  ) : (
                    ""
                  )}

                  {item && !item.parent_id && (
                    <Tooltip title={"Delete Item"}>
                      <div>
                        <Controls.IconButton
                          disabled={
                            readOnlySalesManager ||
                            userIsBillingAssistant ||
                            userCanViewEditContactMark ||
                            (item.invoice_item_has_payments &&
                              (authUser?.organization?.data
                                ?.invoice_quote_integrity ||
                                false))
                          }
                          style={{
                            color:
                              readOnlySalesManager ||
                              userIsBillingAssistant ||
                              userCanViewEditContactMark ||
                              (item.invoice_item_has_payments &&
                                (authUser?.organization?.data
                                  ?.invoice_quote_integrity ||
                                  false))
                                ? "#bdbdbd"
                                : "#f44336",
                          }}
                          onClick={() =>
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to delete this item?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => onDeleteItem(item.id),
                            })
                          }
                        >
                          <Iconify icon="mingcute:delete-fill" size="small" />
                        </Controls.IconButton>
                      </div>
                    </Tooltip>
                  )}
                </div>
              ) : (
                <></>
              )}
              {userCanChangeDownpayment &&
              quote &&
              quote.downpayment_per_procedure &&
              quote.status !== "approved" &&
              (!quote.signed ||
                hasInvoice == 0 ||
                !(
                  authUser?.organization?.data?.immutable_signed_quotes || false
                )) &&
              item.use_downpayment &&
              item.isProcedure ? (
                <Tooltip title={"Change downpayment"}>
                  <span>
                    <Controls.IconButton
                      color="primary"
                      onClick={() => {
                        openCustomDownpaymentPopup(item);
                      }}
                    >
                      <Iconify
                        icon="fluent:money-hand-24-filled"
                        style={{
                          width: 26,
                          height: 26,
                          color: "rgb(46 91 161)",
                        }}
                      />
                    </Controls.IconButton>
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
              {
                //allow edit for admin
                canEditPackageItemPrice &&
                item &&
                item.parent_id != null &&
                !item.product_gift_reason_id &&
                !item.gift_reference_price ? (
                  // &&
                  // item.isProcedure &&
                  // item.price > 0
                  <Tooltip title={"Change item price"}>
                    <span>
                      <Controls.IconButton
                        disabled={readOnlySalesManager}
                        color="primary"
                        onClick={() => {
                          setSelectedPackageItem(item);
                          setPackageItemPrice(item.base_price);
                          setOpenChangePricePopup(true);
                        }}
                      >
                        <Iconify
                          icon="material-symbols:currency-exchange-rounded"
                          size="small"
                          style={{
                            width: "22px",
                            height: "22px",
                            color: "#03a55c",
                          }}
                        />
                      </Controls.IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )
              }
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  const financingOptions = () => {
    return financing.map((financing) => {
      return {
        id: financing.id,
        title: financing.name,
        interest: financing.interest,
      };
    });
  };

  const salesmanOptions = () => {
    if (userCanChangeConsultantAll) {
      return usersDropdown
        .filter((user) => {
          return user.is_salesman || (quote && quote.user_id == user.id);
        })
        .map((user) => {
          return {
            id: user.id,
            title: `${user.fname} ${user.lname}`,
          };
        });
    }
    if (userCanChangeConsultant) {
      const salesman = usersDropdown
        .filter((user) => {
          return (
            (authUser.groups.data[0] &&
              user.groups.data[0] &&
              authUser.groups.data[0].id === user.groups.data[0].id &&
              user.is_salesman) ||
            (quote && quote.user_id == user.id)
          );
        })
        .map((user) => {
          return {
            id: user.id,
            title: `${user.fname} ${user.lname}`,
          };
        });
      //  console.log("salesmansalesman", salesman);
      return salesman;
    }

    return [];
  };

  const applyFinancing = () => {
    const selectedFinancing = find(financing, { id: financingOption });
    if (selectedFinancing) {
      setFinancingInterest(
        quote && quote.interest ? quote.interest : selectedFinancing.interest
      );
    } else {
      setFinancingInterest(false);
    }
  };

  const onClosePrintContractPopup = () => {
    setPrintContractPopup(false);
  };

  useEffect(() => {
    applyFinancing();
  }, [financingOption]);

  useEffect(() => {
    //setItems(quote ? quote.items.data : []);
    if (items && quote && quote.items.data && quote.items.data != items)
      setItems(quote ? quote.items.data : []);

    if (quote && quote.interest) {
      setFinancingInterest(quote.interest);
    } else {
      if (financingOption > 0) {
        const selectedFinancing = find(financing, { id: financingOption });
        setFinancingInterest(selectedFinancing.interest);
      }
    }
    if (quote) {
      setHasInvoice(quote.hasInvoice ? quote.hasInvoice : 0);
    }

    //console.log("quote",quote);
    //console.log("items",items);
  }, [quote]);

  useEffect(() => {
    if (
      quote &&
      quote.items.data &&
      items &&
      items != [] &&
      items != quote.items.data &&
      updateNeeded
    ) {
      saveQuote();
      getQuote();
      //if(quote.items.data != items) setItems(quote ? quote.items.data : []);
    }
    //if(quote && quote.items.data && quote.items.data != items) setItems(quote ? quote.items.data : []);
    //console.log("quote",quote);
    //console.log("items comparison ",quote.items.data,items);
  }, [items]);

  useEffect(() => {
    if (quote) {
      if (quote.status && quote.status === "canceled") {
        setReadOnly(true);
      }
      setUserID(quote.user_id);
    }
  }, [quote]);

  useEffect(() => {
    if (openItemDefault) {
      setOpenItem(true);
    }
    // if (quote) getContractToPrint();
    if (isEmpty(financing)) dispatch(fetchFinancingService(dataSliceFinancing));

    if (isEmpty(productGiftReason)) {
      dispatch(fetchProductGiftReasonService(dataSliceproductGiftReason));
    }
    getMinPriceAvailableQtyToUse();
    if (isEmpty(usersDropdown) && !usersDropdownLoanding)
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  const updateQuoteItemDownpayment = async (data) => {
    try {
      await Api.updateQuoteItemDownpayment(downpaymentItem.id, data);
      getQuote();

      closeDownpaymentPopup();
      setNotify({
        isOpen: true,
        message: "Updated Successfully!",
        type: "success",
      });
    } catch (ex) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!",
        type: "error",
      });
    }
  };

  const duplicateQuote = async () => {
    if (quote) {
      try {
        setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
        setDuplicatingQuote(true);
        const { data } = await Api.duplicateQuote(quote.id);

        if (quotes && setQuotes) {
          setQuotes([...quotes, data.data]);
        }

        setNotify({
          isOpen: true,
          message: "Duplicate successfully!",
          type: "success",
        });
      } catch (err) {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      } finally {
        setDuplicatingQuote(false);
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Quote not found!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    var test =
      quote && quote.items.data
        ? {
            ...quote,
            ...{
              items: {
                data: [
                  ...quote.items.data.map((a) => {
                    return { ...a, ...{ price: a.parent_id ? "-" : a.price } };
                  }),
                ],
              },
            },
          }
        : "aaaa";
  }, [quote]);

  return (
    <Shadow>
      <Card className={classes.card}>
        <CardContent>
          {!quote ||
          (quote.status !== "approved" &&
            (!quote.signed ||
              hasInvoice == 0 ||
              !(
                authUser?.organization?.data?.immutable_signed_quotes || false
              ))) ? (
            <Tooltip title={"Add Item"}>
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                style={{ float: "right" }}
                onClick={addQuoteItem}
                disabled={
                  readOnlySalesManager ||
                  // userIsBillingAssistant ||
                  userCanViewEditContactMark
                }
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          ) : (
            <></>
          )}
          <Stepper nonLinear activeStep={getActiveStep()}>
            {map(statusOptions, (status) => (
              <Step key={status.label}>
                <StepLabel>{status.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container spacing={6}>
            <Grid item xs={8}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  QUOTE CONTACT:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {
                    contact ? (
                      <Typography component="div">
                        {contact.full_name}
                      </Typography>
                    ) : (
                      ""
                    ) /*(
                    <Controls.Select
                      variant="standard"
                      options={contactOptions()}
                      onChange={(value) => console.log(value)}
                    />
                  )*/
                  }
                </Box>
                {contact && (
                  <Box fontWeight="fontWeightMedium" m={1}>
                    {contact.address}
                  </Box>
                )}
                {contact && (
                  <Box fontWeight="fontWeightMedium" m={1}>
                    {contact.phone}
                  </Box>
                )}
                {contact && contact.coordinator_name && (
                  <Box m={1}>{"Consultant: " + contact.coordinator_name}</Box>
                )}
              </Typography>
            </Grid>
            {authUser.organization.data.maxQuoteTime && (
              <Grid item xs={3}>
                <Controls.DatePicker2
                  fullWidth
                  label="Expiration Date"
                  onChange={(date) => setExpirationDate(date)}
                  value={expirationDate}
                  disabled={
                    readOnly ||
                    readOnlySalesManager ||
                    userCanViewEditContactMark
                  }
                />
              </Grid>
            )}
            {userCanFinancing && (
              <Grid item xs={4}>
                <Controls.Select
                  allowEmpty={true}
                  emptyLabel="None"
                  options={financingOptions()}
                  value={financingOption}
                  label="Financing"
                  onChange={(value) => {
                    setFinancingOption(value.target.value);
                  }}
                  disabled={
                    (quote &&
                    (quote.status === "approved" ||
                      (quote.signed &&
                        (authUser?.organization?.data
                          ?.immutable_signed_quotes ||
                          false) &&
                        hasInvoice > 0))
                      ? true
                      : false) ||
                    (submitDisabled && !quote) ||
                    readOnlySalesManager ||
                    readOnly ||
                    userCanViewEditContactMark
                  }
                />
              </Grid>
            )}
            {userCanFinancing &&
              financingOption > 0 &&
              userCanEditFinancingInterest && (
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue={financingInterest ? financingInterest : 0}
                    value={financingInterest}
                    label="Financing Interest"
                    onChange={(value) => {
                      setFinancingInterest(value.target.value);
                    }}
                    disabled={
                      (quote &&
                      quote.status === "approved" &&
                      quote.signed &&
                      hasInvoice > 0 &&
                      (authUser?.organization?.data?.immutable_signed_quotes ||
                        false)
                        ? true
                        : false) ||
                      (submitDisabled && !quote) ||
                      readOnlySalesManager ||
                      readOnly ||
                      userCanViewEditContactMark
                    }
                  />
                </Grid>
              )}

            {(!quote ||
              (quote.status !== "approved" &&
                (!quote.signed ||
                  hasInvoice == 0 ||
                  !(
                    authUser?.organization?.data?.immutable_signed_quotes ||
                    false
                  )))) &&
              userCanAddGiftItem && (
                <Grid item xs={6}>
                  <Tooltip title={"Add Gift Item"}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      size="small"
                      style={{ float: "right" }}
                      onClick={addQuoteItemGift}
                      disabled={
                        readOnlySalesManager || userCanViewEditContactMark
                      }
                    >
                      <Iconify
                        icon="fluent:gift-card-add-24-filled"
                        style={{ fontSize: 24, color: "#fff" }}
                      />
                    </Fab>
                  </Tooltip>
                </Grid>
              )}

            <Grid item xs={2}>
              {/*<Controls.Button text="Apply" onClick={() => applyFinancing()} />*/}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Calendar Date</TableCell>
              <TableCell style={{ width: "25%" }}>Price</TableCell>
              <TableCell style={{ width: "10%" }}>Quantity</TableCell>
              {/*<TableCell>Discount</TableCell>*/}
              {quote && quote.downpayment_per_procedure ? (
                <TableCell align="right">
                  {" "}
                  {quote?.quote_total_name
                    ? quote?.quote_total_name
                    : "Downpayment"}
                </TableCell>
              ) : (
                ""
              )}

              <TableCell align="right">
                {" "}
                {quote?.quote_total_name ? quote?.quote_total_name : "Total"}
              </TableCell>
              {quote &&
                quote.status !== "approved" &&
                (!quote.signed ||
                  hasInvoice == 0 ||
                  !(
                    authUser?.organization?.data?.immutable_signed_quotes ||
                    false
                  )) && <TableCell align="right"></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {renderItems()}

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              {quote && quote.downpayment_per_procedure ? <TableCell /> : ""}
              <TableCell align="right">Subtotal</TableCell>
              <TableCell align="right">
                ${calculateSubtotal().toFixed(2)}
              </TableCell>
              <TableCell />
            </TableRow>

            {/*
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">Discount</TableCell>
                    <TableCell align="right">
                      $ {calculateTotalDiscount().toFixed(2)}
                    </TableCell>
                    
                    <TableCell />
                  </TableRow>
                  */}

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              {quote && quote.downpayment_per_procedure ? <TableCell /> : ""}
              <TableCell align="right">
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote?.quote_total_name ? quote?.quote_total_name : "Total"}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box fontWeight="fontWeightBold" m={1}>
                  ${calculateTotal().toFixed(2)}
                </Box>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      {!isEmpty(items) && (
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              spacing={5}
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={
                  (userCanChangeConsultant ||
                    userCanChangeConsultantAll ||
                    userCanChangeApproveDate) &&
                  quote
                    ? 6
                    : 12
                }
              >
                <Controls.Input
                  defaultValue={included}
                  label="Included"
                  multiline
                  maxRows="3"
                  minRows="3"
                  className={classes.included_margin}
                  onChange={(event) => {
                    changeIncluded(event.target.value);
                  }}
                  disabled={
                    (quote &&
                      (quote.status === "approved" ||
                        (quote.signed &&
                          hasInvoice > 0 &&
                          (authUser?.organization?.data
                            ?.immutable_signed_quotes ||
                            false)))) ||
                    !userCanEditIncluded
                  }
                />
              </Grid>
              {(userCanChangeConsultant || userCanChangeConsultantAll) &&
              quote ? (
                <Grid item xs={userCanChangeApproveDate ? 3 : 6}>
                  <TextField
                    select
                    fullWidth
                    options={salesmanOptions()}
                    variant="outlined"
                    value={userID ? userID : null}
                    label="Owner"
                    onChange={(value) => {
                      setUserID(value.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={
                      !userCanChangeConsultant && !userCanChangeConsultantAll
                    }
                  >
                    {salesmanOptions().map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : (
                ""
              )}
              {userCanChangeApproveDate && quote ? (
                <Grid
                  item
                  xs={
                    userCanChangeConsultant || userCanChangeConsultantAll
                      ? 3
                      : 6
                  }
                >
                  <Controls.DateTimePicker
                    fullWidth
                    label="Approved Date"
                    onChange={(date) => setApprovedDate(date)}
                    value={approvedDate}
                    disabled={
                      !userCanChangeApproveDate ||
                      (quote && quote?.status != "approved")
                    }
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid container justifyContent="flex-start">
              {quote && (
                <>
                  <ReactToPrint
                    trigger={() => (
                      <Controls.Button
                        color="primary"
                        startIcon={<PrintIcon />}
                        text="Print Quote"
                        disabled={
                          readOnly ||
                          userCanViewEditContactMark ||
                          updateNeeded ||
                          updatePackagePriceNeeded ||
                          (quote.financing_id &&
                            financingOption != -1 &&
                            financingOption != quote.financing_id) ||
                          (quote.financing_id && financingOption == -1) ||
                          (!quote.financing_id && financingOption != -1)
                        }
                      />
                    )}
                    content={() => quoteRef.current}
                  />

                  <Controls.Button
                    onClick={() => {
                      setPrintContractPopup(true);
                    }}
                    color="primary"
                    startIcon={<PrintIcon />}
                    text="Print Contract"
                    disabled={
                      readOnly ||
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (quote.financing_id &&
                        financingOption != -1 &&
                        financingOption != quote.financing_id) ||
                      (quote.financing_id && financingOption == -1) ||
                      (!quote.financing_id && financingOption != -1)
                    }
                  />

                  <Controls.Button
                    variant="contained"
                    color="secondary"
                    startIcon={<SendIcon />}
                    text="Send Quote"
                    align="right"
                    onClick={(event, rowData) =>
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to send this quote?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => onSend(quote.id),
                      })
                    }
                    disabled={
                      readOnly ||
                      userCanViewEditContactMark ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (quote.financing_id &&
                        financingOption != -1 &&
                        financingOption != quote.financing_id) ||
                      (quote.financing_id && financingOption == -1) ||
                      (!quote.financing_id && financingOption != -1)
                    }
                  />

                  <Controls.Button
                    variant="contained"
                    color="secondary"
                    startIcon={<SendIcon />}
                    text="Send Contract"
                    onClick={() => setSendContractPopup(true)}
                    disabled={
                      readOnly ||
                      readOnlySalesManager ||
                      userCanViewEditContactMark ||
                      updateNeeded ||
                      updatePackagePriceNeeded ||
                      (quote.financing_id &&
                        financingOption != -1 &&
                        financingOption != quote.financing_id) ||
                      (quote.financing_id && financingOption == -1) ||
                      (!quote.financing_id && financingOption != -1)
                    }
                  />

                  {/* !CRMUtils.userHasPermission(authUser, ["quote_Approve"]) */}
                  {checkItemsProcedure() &&
                  CRMUtils.userHasPermission(authUser, ["quote_Approve"]) ? (
                    <Controls.Button
                      disabled={
                        approvingQuote ||
                        (quote && quote.status === "approved") ||
                        readOnly ||
                        readOnlySalesManager ||
                        userCanViewEditContactMark ||
                        updateNeeded ||
                        updatePackagePriceNeeded ||
                        (quote.financing_id &&
                          financingOption != -1 &&
                          financingOption != quote.financing_id) ||
                        (quote.financing_id && financingOption == -1) ||
                        (!quote.financing_id && financingOption != -1)
                          ? true
                          : false
                      }
                      variant="contained"
                      color="secondary"
                      text={
                        quote && quote.status === "approved"
                          ? "Approved"
                          : approvingQuote
                          ? "Approving..."
                          : "Approve"
                      }
                      startIcon={<DoneIcon />}
                      onClick={() => {
                        setOpenApproveModal(true);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              <Controls.Button
                variant="contained"
                color="secondary"
                text={quote ? "Update" : "Create"}
                disabled={
                  userCanChangeConsultant ||
                  userCanChangeConsultantAll ||
                  userCanChangeApproveDate
                    ? false
                    : (quote &&
                      (quote.status === "approved" ||
                        (quote.signed &&
                          hasInvoice > 0 &&
                          (authUser?.organization?.data
                            ?.immutable_signed_quotes ||
                            false)))
                        ? true
                        : false) ||
                      (submitDisabled && !quote) ||
                      readOnlySalesManager ||
                      readOnly ||
                      userCanViewEditContactMark
                }
                startIcon={<SaveIcon />}
                onClick={() => {
                  setSubmitDisabled(true);
                  // console.error("Before Update Button ", items);
                  saveQuote();
                }}
              />
              {/* SendInvoicePaymentLink */}

              {quote && hasInvoice <= 0 && userCanConvertToInvoice && (
                <Controls.Button
                  variant="contained"
                  color="secondary"
                  text={"Convert to Invoice"}
                  startIcon={<ReceiptIcon />}
                  onClick={() => {
                    setConvert(true);
                  }}
                  disabled={
                    readOnly ||
                    readOnlySalesManager ||
                    (quote.signed == false && quote.quote_signed_mandatory) ||
                    userCanViewEditContactMark ||
                    updateNeeded ||
                    updatePackagePriceNeeded ||
                    (quote.financing_id &&
                      financingOption != -1 &&
                      financingOption != quote.financing_id) ||
                    (quote.financing_id && financingOption == -1) ||
                    (!quote.financing_id && financingOption != -1)
                  }
                />
              )}

              {quote ? (
                <Controls.Button
                  variant="contained"
                  color="secondary"
                  text={
                    duplicatingQuote
                      ? "Duplicating Quote..."
                      : "Duplicate Quote"
                  }
                  startIcon={<Iconify icon="mdi:content-duplicate" />}
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to duplicate this quote?",
                      onConfirm: () => duplicateQuote(),
                    });
                  }}
                  disabled={
                    duplicatingQuote ||
                    readOnly ||
                    readOnlySalesManager ||
                    userCanViewEditContactMark ||
                    updateNeeded ||
                    updatePackagePriceNeeded ||
                    (quote.financing_id &&
                      financingOption != -1 &&
                      financingOption != quote.financing_id) ||
                    (quote.financing_id && financingOption == -1) ||
                    (!quote.financing_id && financingOption != -1)
                  }
                />
              ) : (
                ""
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
      <Popup
        title="Quote item"
        fullWidth={true}
        maxWidth="lg"
        openPopup={openItem}
        onClose={closeItemPopup}
      >
        <QuoteItemForm
          users={usersDropdown}
          products={products}
          recordproducts={recordproducts}
          item={editItem}
          saveItem={(data) => saveQuoteItem(data)}
          productGiftReason={productGiftReason}
          saveQuote={saveQuote}
          editItemUserPrice={editItemUserPrice}
          userAZ={userAZ}
          contactID={contact?.id || null}
        />
      </Popup>

      <Popup
        title="Quote Gift Item"
        fullWidth={true}
        maxWidth="lg"
        openPopup={openItemGift}
        onClose={closeItemPopupGift}
      >
        <QuoteItemForm
          users={usersDropdown}
          products={products}
          recordproducts={recordproducts}
          item={editItem}
          saveItem={(data) => saveQuoteItem(data)}
          productGiftReason={productGiftReason}
          saveQuote={saveQuote}
          giftCheckAuto
          contactID={contact?.id || null}
        />
      </Popup>

      <Popup
        title="Approve Contract"
        fullWidth={true}
        openPopup={openApproveModal}
        onClose={closeApprovePopup}
      >
        <ApproveQuote
          approving={approvingQuote}
          quote={quote}
          contact={contact}
          onSentContract={sendContract}
          items={items}
          onApproveQuote={approveContract}
          gateways={paymentGateways}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} autoHide={6000} />
      <Popup
        title="Notification"
        fullWidth={false}
        openPopup={openNotification}
        onClose={closeNotification}
      >
        This item already exist in the quote, please select another one or
        change responsible person.
      </Popup>

      <Popup
        title="Change item price"
        fullWidth={true}
        maxWidth="xs"
        openPopup={openChangePricePopup}
        onClose={closeChangePrice}
      >
        <div>
          <Typography style={{ fontWeight: 500, color: "#003f76" }}>
            {`Edit ${
              selectedPackageItem && selectedPackageItem.name
                ? selectedPackageItem.name
                : ""
            } price`}
          </Typography>
          <Grid container style={{ alignItems: "center" }} spacing={1}>
            <Grid item xs={9}>
              <TextField
                type="number"
                fullWidth
                placeholder="Price"
                onChange={(event) => setPackageItemPrice(event.target.value)}
                variant="outlined"
                value={packageItemPrice}
                defaultValue={
                  selectedPackageItem && selectedPackageItem.price
                    ? selectedPackageItem.price
                    : null
                }
              ></TextField>
              {packageItemPrice != null &&
              selectedPackageItem &&
              (userCanUseMinPrice && packageItemPrice.product_min_price !== null
                ? packageItemPrice < selectedPackageItem.product_min_price
                : packageItemPrice < selectedPackageItem.base_price) ? (
                <Typography style={{ color: "red" }}>
                  {`*Price must be greater than ${
                    userCanUseMinPrice &&
                    packageItemPrice.product_min_price !== null
                      ? selectedPackageItem.product_min_price
                      : selectedPackageItem.base_price
                  }`}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={
                  isSubmitting ||
                  (packageItemPrice != null &&
                    selectedPackageItem &&
                    (userCanUseMinPrice &&
                    packageItemPrice.product_min_price !== null
                      ? packageItemPrice < selectedPackageItem.product_min_price
                      : packageItemPrice < selectedPackageItem.base_price))
                }
                color="primary"
                variant="contained"
                onClick={() => {
                  changePackagePrice();
                }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popup>

      <Popup
        title="Send Contracts"
        fullWidth={true}
        openPopup={sendContractPopup}
        onClose={closeSendContractPopup}
      >
        <SendContract
          model="quote"
          model_id={quote?.id}
          contact={contact}
          onSentContract={sendContract}
        />
      </Popup>

      <Popup
        title="Convert Quote to Invoice"
        fullWidth={true}
        openPopup={openConvert}
        onClose={closeConvertPopup}
      >
        <Quote2Invoice
          items={items.filter((item) => item && item.parent_id == null)}
          onConvert={convertQuote2Invoice}
        />
      </Popup>

      <Popup
        title="Downpayment amount"
        fullWidth={true}
        openPopup={isDownpaymentPopupOpened}
        onClose={closeDownpaymentPopup}
        maxWidth={"xs"}
      >
        <ChangeItemDownpaymentForm
          addOrEdit={updateQuoteItemDownpayment}
          item={downpaymentItem}
        />
      </Popup>

      <Dialog
        aria-labelledby="discount-quoteitem-dialog"
        open={applyDiscountPopUp}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            borderRadius: 20,
          },
        }}
        onClose={closeApplyDiscount}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }}>
            <MuiTypography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              {`Discount to ${applyDiscountItem?.name}`}
            </MuiTypography>
            <Controls.IconButton
              color="secondary"
              onClick={closeApplyDiscount}
              style={{ textAlign: "right" }}
            >
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers={true}>
          <ApplyItemDiscountForm
            addOrEdit={onApplyDiscount}
            entityForEdit={applyDiscountItem}
          />
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/*<Grid item xs={11}>
        <div
            dangerouslySetInnerHTML={{
              __html:
                "<center><h2>Print Contract Preview</h2></center>",
            }}
          >
            
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                contractToPrint && contractToPrint.contract
                  ? htmlDecode(contractToPrint.contract.html)
                  : "<p></p>",
            }}
          >
            
          </div>
        </Grid>*/}
      {quote && (
        <div style={{ display: "none" }}>
          <div ref={quoteRef}>
            <PrintQuoteDetails
              quote={quote}
              // contract={contractToPrint}
              financingInterest={financingInterest}
              disabled={readOnlySalesManager || userCanViewEditContactMark}
              ItemPriceHlp={ItemPriceHlp}
            />
          </div>
        </div>
      )}

      {quote && (
        <div style={{ display: "none" }}>
          <div ref={contractRef}>
            <PrintContractDetails
              quote={
                quote.items.data
                  ? {
                      ...quote,
                      ...{
                        items: {
                          data: [
                            ...quote.items.data.map((a) => {
                              return {
                                ...a,
                                ...{
                                  price: a.parent_id ? "-" : a.price,
                                  base_price: a.parent_id ? "-" : a.base_price,
                                },
                              };
                            }),
                          ],
                        },
                      },
                    }
                  : quote
              }
              contract={contractToPrint}
            />
          </div>
        </div>
      )}

      <Popup
        title={"Print contract"}
        fullWidth={true}
        openPopup={printContractPopup}
        onClose={onClosePrintContractPopup}
        maxWidth={"sm"}
      >
        <>
          <PrintQuoteContract quote={quote || null} />
        </>
      </Popup>
    </Shadow>
  );
}
export default QuoteBuilder;
