import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  makeStyles,
  FormControl,
  Button as MuiButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  Typography,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Tooltip,
} from "@material-ui/core";
import { isEmpty, startCase, find, findIndex } from "lodash";
import FaceIcon from "@material-ui/icons/Face";
import CheckIcon from "@material-ui/icons/Check";
import Controls from "../../components/controls";
import Api from "../../lib/api";
import IconButton from "../controls/IconButton";
import NumberFormatCustom from "../controls/NumberFormatCustom";
import moment from "moment";
import UserProcedureHeatmap from "../UserProcedureHeatmap";
import UserProcedureHeatmap3 from "../UserProcedureHeatmap3";

import CRMUtils from "../../utils";

import AutocompleteGroupColors from "../../components/controls/AutocompleteByGroupsWithColors";
import { blue, green, yellow, orange } from "@material-ui/core/colors";
import Iconify from "../Iconify";
import Popup from "../Popup";
import HeatMapCalendarMonthly from "../HeatMapCalendarMonthly";
import UserProcedureHeatmap4 from "../UserProcedureHeatmap4";
import EditPackageItemPriceForm from "./EditPackageItemPriceForm";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

const CURRENT_DATE = moment().toDate();

export default function QuoteItemForm(props) {
  const [userMinPriceAvailableQty, setUserMinPriceAvailableQty] = useState(0);
  const [editPackageItemPriceOpened, setEditPackageItemPriceOpened] =
    useState(false);
  const calendarEl = useRef(null);

  const { authUser } = useSelector((state) => state.auth);

  const userCanFinancing = CRMUtils.userHasPermission(authUser, [
    "quote_financing",
  ]);

  const userCanChangeDoctor = CRMUtils.userHasPermission(authUser, [
    "quote_changedoctor",
  ]);

  const userCanChangePrice = CRMUtils.userHasPermission(authUser, [
    "quote_changeprice",
  ]);

  const userCanUpPrice = CRMUtils.userHasPermission(authUser, [
    "quote_upPrice",
  ]);

  const userCanGiftItem = CRMUtils.userHasPermission(authUser, [
    "invoice_giftItem",
  ]);
  const userCanSetItemAsRevision = CRMUtils.userHasPermission(authUser, [
    "quote_revisionmark",
  ]);
  const userSuperDoctorDate = CRMUtils.userHasPermission(authUser, [
    "user_SuperDoctorDate",
  ]);
  const userCanSkipMinDaysUntilSurgery = CRMUtils.userHasPermission(authUser, [
    "user_skipmindaysuntilsurgery",
  ]);
  const userCanUseMinPrice =
    CRMUtils.userHasPermission(authUser, ["user_useminprice"]) &&
    (userMinPriceAvailableQty == -1 || userMinPriceAvailableQty > 0);

  const userIsAdmin = CRMUtils.userHasRole(authUser, ["admin", "super-admin"]);

  const userIsSuperAdmin = CRMUtils.userHasRole(authUser, ["super-admin"]);

  const {
    item,
    saveItem,
    users,
    //products,
    recordproducts,
    productGiftReason,
    saveQuote,
    giftCheckAuto = false,
    revisionCheckAuto = false,
    editItemUserPrice = null,
    userAZ = null,
    contactID = null,
  } = props;
  //const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [packageItemUserPrices, setPackageItemUserPrices] = useState([]);
  const [user, setUser] = useState(userAZ ? userAZ : {});
  const [userPrices, setUserPrices] = useState([]);

  const [userPriceA, setUserPriceA] = useState(editItemUserPrice);

  const [productId, sestProductId] = useState(item ? item.product_id : null);
  const [giftable, setGiftable] = useState(item ? item.is_giftable : null);
  const [products, setProducts] = useState([]);
  const [productSelect, setProductSelect] = useState(item ? item : null);

  const [priceBeforeCheck, setPriceBeforeCheck] = useState(
    item ? item.price : 0
  );
  const [giftCheck, setGiftCheck] = useState(
    (item && item.product_gift_reason_id) || giftCheckAuto ? true : false
  );
  const [productGiftReasonSelected, setProductGiftReasonSelected] = useState(
    item ? item.product_gift_reason_id : ""
  );
  const [itemProps, setItemProps] = useState({
    calendar_date: null,
    responsible: null,
    price: null,
    user_product_price_id: null,
    product_standard_price: null,
    product_min_price: null,
  });
  const [productBasePrice, setProductBasePrice] = useState(
    item ? item.price : 0
  );

  const [giftReferencePrice, setGiftReferencePrice] = useState(
    item ? item.gift_reference_price : null
  );
  const [productApplyFinancing, setproductApplyFinancing] = useState(
    item ? item.apply_financing : 0
  );
  const [productName, setProductName] = useState(item ? item.name : "");
  const [productPrice, setProductPrice] = useState(item ? item.price : 0);

  const minPrice = useRef(item ? item.price : 0);

  const [availableDays, setAvailableDays] = useState(
    userAZ ? userAZ.available_days : []
  );
  const [daysOff, setDaysOff] = useState(userAZ ? userAZ.days_off : []);
  const [dailyLimit, setDailyLimit] = useState(userAZ ? userAZ.daily_limit : 0);
  const [overrideLeadLimit, setOverrideLeadLimit] = useState(false);
  0;

  const [productQuantity, setProductQuantity] = useState(
    item ? item.quantity : 1
  );

  /*const [productIsProcedure, setProductIsProcedure] = useState(
    item ? item.is_procedure : false
  );*/
  const [productIsPackage, setProductIsPackage] = useState(
    item ? item.type == "package" : false
  );
  const [totalRecords, setTotalRecords] = useState([]);
  const [productType, setProductType] = useState(item ? item.type : "");
  const [productColor, setProductColor] = useState(item ? item.cal_color : "");
  const [productResponsible, setProductResponsible] = useState(
    item && !isEmpty(item.responsible) ? item.responsible : ""
  );
  const [selectedPackageItem, setSelectedPackageItem] = useState(null);
  const [quoteItemDate, setQuoteItemDate] = useState(
    item ? item.calendar_date : null
  );
  const [responsibleLoading, setResponsibleLoading] = useState(null);
  const [openPackageItem, setOpenPackageItem] = useState(false);
  const [revisionCheck, setRevisionCheck] = useState(
    (item && item.is_revision) || revisionCheckAuto ? true : false
  );
  const [revisionComment, setRevisionComment] = useState("");
  const [productStandardPrice, setProductStandardPrice] = useState(
    item ? item.product_standard_price : null
  );
  const [productMinPrice, setProductMinPrice] = useState(
    item ? item.product_min_price : null
  );

  const changeGiftCheck = (gift) => {
    if (gift == true) {
      setPriceBeforeCheck(productPrice);
      setGiftReferencePrice(productPrice);
      minPrice.current = 0;
      setProductPrice(0);

      setProductStandardPrice(0);
      setProductMinPrice(0);
    } else {
      setGiftReferencePrice(null);
      setProductGiftReasonSelected("");
      setProductPrice(priceBeforeCheck);

      if (userCanUseMinPrice) {
        minPrice.current = userPriceA
          ? userPriceA.min_price !== null
            ? userPriceA.min_price
            : userPriceA.price
          : productSelect.min_sale_price !== null
          ? productSelect.min_sale_price
          : productSelect.price_b;
      } else {
        minPrice.current = userPriceA
          ? userPriceA.price
          : productSelect.price_b;
      }

      setProductStandardPrice(
        userPriceA && userPriceA.price !== null
          ? userPriceA.price
          : productSelect && productSelect.price_b !== null
          ? productSelect.price_b
          : null
      );
      setProductMinPrice(
        userPriceA && userPriceA.min_price !== null
          ? userPriceA.min_price
          : productSelect && productSelect.min_sale_price !== null
          ? productSelect.min_sale_price
          : null
      );
    }

    setGiftCheck(gift);
  };

  const changeRevisionCheck = (revision) => {
    if (revision == true) {
      setPriceBeforeCheck(productPrice);
      minPrice.current = 0;
      setProductPrice(productSelect.revision_price);

      setProductStandardPrice(productSelect.revision_price);
      setProductMinPrice(null);
    } else {
      setResponsible("");
      setRevisionComment("");
      setProductPrice(priceBeforeCheck);

      if (userCanUseMinPrice) {
        minPrice.current = userPriceA
          ? userPriceA.min_price !== null
            ? userPriceA.min_price
            : userPriceA.price
          : productSelect.min_sale_price !== null
          ? productSelect.min_sale_price
          : productSelect.price_b;
      } else {
        minPrice.current = userPriceA
          ? userPriceA.price
          : productSelect.price_b;
      }

      setProductStandardPrice(
        userPriceA && userPriceA.price !== null
          ? userPriceA.price
          : productSelect && productSelect.price_b !== null
          ? productSelect.price_b
          : null
      );
      setProductMinPrice(
        userPriceA && userPriceA.min_price !== null
          ? userPriceA.min_price
          : productSelect && productSelect.min_sale_price !== null
          ? productSelect.min_sale_price
          : null
      );
    }

    setRevisionCheck(revision);
  };

  const classes = useStyles();

  const getResponsible = () => {
    return [
      {
        resourceId: productResponsible.id,
        resourceTitle: (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt={startCase(productResponsible.title)}
                src={productResponsible.avatar}
              />
            </ListItemAvatar>
            <ListItemText primary={startCase(productResponsible.title)} />
          </ListItem>
        ),
      },
    ];
  };

  const getEvents = () => {
    return recordproducts.map((record) => {
      const startDate = moment(record.date);
      const duration = record.duration ? record.duration : 30;
      return {
        id: record.id,
        title: record.invoiceItem.data.name,
        desc: `Patient: ${record.record.data.full_name}`,
        start: startDate.toDate(),
        end: startDate.add(duration, "minutes").toDate(),
        resourceId: record.responsible.data.id,
        finalized: record.finalized,
        color: record.finalized ? "#e0e0e0" : "#4fc3f7",
      };
    });
  };

  const showPackage = (is_package, name) => {
    if (is_package === 1) return "(Package) " + name;
    else return name;
  };

  const getProductOptions = () => {
    return giftCheckAuto
      ? products
          .filter(
            (product) =>
              ((product.is_procedure == 1 && product.is_giftable == 1) ||
                product.is_procedure == 0) &&
              (product.is_package == 0 ||
                (product.is_package == 1 && product.is_giftable == 1))
          )
          .map((product) => {
            return {
              id: product.id,
              //title: showPackage(product.is_package, product.name),
              title: product
                ? product.name /*+ ((product && product.categories.data[0])?" -> " + product.categories.data[0].name:"")*/
                : "",
              type: `${product.type}`,
              apply_financing: product.apply_financing,
              product_gift_reason_id: product.product_gift_reason_id,
              price_b: product.sale_price,
              color:
                product.type == "package"
                  ? blue[200]
                  : product.is_procedure == 1
                  ? green[200]
                  : product.type == "service"
                  ? yellow[200]
                  : orange[200],
              is_package: product.is_package,
              procedure: product.is_procedure,
              is_giftable: product.is_giftable,
              product_items: product.productItems.data,
              group:
                product.type == "package"
                  ? "Packages"
                  : product.is_procedure == 1
                  ? "Procedures"
                  : product.type == "service"
                  ? "Products/Service"
                  : "Products/Physical",
              reviewable: product.reviewable,
              revision_price: product.revision_price,
              min_sale_price: product.min_sale_price,
            };
          })
          .sort(function (a, b) {
            var groupA = a.group.toUpperCase(); // ignore upper and lowercase
            var groupB = b.group.toUpperCase(); // ignore upper and lowercase
            if (groupA < groupB) {
              return -1;
            }
            if (groupA > groupB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
      : products
          .map((product) => {
            return {
              id: product.id,
              //title: showPackage(product.is_package, product.name),
              title: product
                ? product.name /*+ ((product && product.categories.data[0])?" -> " + product.categories.data[0].name:"")*/
                : "",
              type: `${product.type}`,
              apply_financing: product.apply_financing,
              product_gift_reason_id: product.product_gift_reason_id,
              price_b: product.sale_price,
              color:
                product.type == "package"
                  ? blue[200]
                  : product.is_procedure == 1
                  ? green[200]
                  : product.type == "service"
                  ? yellow[200]
                  : orange[200],
              is_package: product.is_package,
              procedure: product.is_procedure,
              is_giftable: product.is_giftable,
              product_items: product.productItems.data,
              group:
                product.type == "package"
                  ? "Packages"
                  : product.is_procedure == 1
                  ? "Procedures"
                  : product.type == "service"
                  ? "Products/Service"
                  : "Products/Physical",
              reviewable: product.reviewable,
              revision_price: product.revision_price,
              min_sale_price: product.min_sale_price,
            };
          })
          .sort(function (a, b) {
            var groupA = a.group.toUpperCase(); // ignore upper and lowercase
            var groupB = b.group.toUpperCase(); // ignore upper and lowercase
            if (groupA < groupB) {
              return -1;
            }
            if (groupA > groupB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
  };

  const productGiftReasonOptions = () => {
    return productGiftReason.map((productGiftReasona) => {
      return {
        id: productGiftReasona.id,
        title: productGiftReasona.name,
      };
    });
  };

  const getUserOptions = () => {
    if (authUser.organization.data.disable_doctors === 1) {
      return users
        .filter((user) => {
          return (
            user.is_professional === 1 &&
            user.daily_limit != -1 &&
            user.active === 1
          );
        })
        .map((user) => {
          return {
            id: user.id,
            title: `${user.first_name} ${user.last_name}`,
          };
        });
    }
    return users
      .filter((user) => {
        return user.is_professional === 1 && user.active === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const getUserPrices = async (productId) => {
  //  if (!productName) setUserPrices([]);
    setUserPrices([]);
    const { data } = await Api.getUserPrices(productId);

    setUserPrices(data.data);
  };

  const getMinPriceAvailableQtyToUse = async () => {
    const { data } = await Api.getMinPriceAvailableQtyToUse(
      authUser.building_id,
      contactID,
      authUser?.id || null
    );

    setUserMinPriceAvailableQty(data);
  };

  const cleanState = () => {
    sestProductId(null);
    setProductSelect(null);
    setproductApplyFinancing(null);
    setProductGiftReasonSelected("");
    setProductName("");
    setProductType("");
    setProductColor("");
    setProductPrice(0);
    //setProductIsProcedure(false);

    setProductQuantity(1);
    setUserPrices([]);
    minPrice.current = 0;
    setProductResponsible("");
    setAvailableDays([]);
    setDailyLimit(0);
    setOverrideLeadLimit(false);
    setDaysOff([]);
    setRevisionCheck(revisionCheckAuto ? true : false);
    setRevisionComment("");
    setProductStandardPrice(null);
    setProductMinPrice(null);
  };

  const updateProductAndPrice = (value) => {
    setRevisionCheck(revisionCheckAuto ? true : false);
    setRevisionComment("");
    if (value && value.id) {
      setQuoteItemDate(null);
      setProductResponsible("");
      setItemProps({
        calendar_date: null,
        responsible: null,
        price: null,
        user_product_price_id: null,
      });
      getUserPrices(value.id);
      sestProductId(value.id);
      setGiftable(value.is_giftable);
      setProductSelect(value);
      setProductName(value.title);
      setproductApplyFinancing(value.apply_financing);
      setProductType(value.type);
      // setProductIsProcedure(value.isProcedure);
      setProductQuantity(1);
      setProductColor(value.cal_color);
      if (!giftCheckAuto) {
        setGiftCheck(false);
      }
      setRevisionCheck(false);
      setUserPriceA(null);
      setProductGiftReasonSelected("");
      setGiftReferencePrice(null);
      products.forEach((product) => {
        if (product.id === value.id) {
          if (!giftCheckAuto) {
            setProductPrice(product.sale_price);
          }

          if (userCanUseMinPrice) {
            minPrice.current =
              product.min_sale_price !== null
                ? product.min_sale_price
                : product.sale_price;
          } else {
            minPrice.current = product.sale_price;
          }

          setProductStandardPrice(product.sale_price);
          setProductMinPrice(product.min_sale_price);

          // minPrice.current = product.sale_price;
        }
      });
    } else {
      cleanState();
    }

    if (giftCheckAuto) {
      setGiftReferencePrice(value.price_b);
      setProductStandardPrice(0);
      setProductMinPrice(0);
    }
  };

  const selectUserPrice = async (userPrice) => {
    try {
      setResponsibleLoading(userPrice.id);
      if (userPrice) {
        setUserPriceA(userPrice);

        const { data } = await Api.getUser(userPrice.user_id);
        setUser(data.data);
        setAvailableDays(data.data.available_days);
        setDaysOff(data.data.days_off);
        setDailyLimit(data.data.daily_limit);
        setOverrideLeadLimit(data.data.override_lead_limit);
        if (data.data.daily_limit == "-1") {
          setQuoteItemDate(null);
        }
      } else {
        setAvailableDays([]);
        setDaysOff([]);
        setDailyLimit(0);
        setOverrideLeadLimit(false);
      }

      setProductResponsible({
        id: userPrice.user_id,
        title: userPrice.user_name,
      });
      setProductPrice(giftCheck || giftCheckAuto ? 0 : userPrice.price);

      if (userCanUseMinPrice) {
        minPrice.current = giftCheck
          ? 0
          : userPrice.min_price !== null
          ? userPrice.min_price
          : userPrice.price;
      } else {
        minPrice.current = giftCheck ? 0 : userPrice.price;
      }

      setProductStandardPrice(giftCheck ? 0 : userPrice.price);
      setProductMinPrice(giftCheck ? 0 : userPrice.min_price);

      setResponsibleLoading(null);
    } catch (err) {
      setResponsibleLoading(null);
    }
  };

  const setResponsible = async (responsible) => {
    setProductResponsible(responsible);
    if (responsible) {
      const { data } = await Api.getUser(responsible.id);
      setUser(data.data);
      setAvailableDays(data.data.available_days);
      setDaysOff(data.data.days_off);
      setDailyLimit(data.data.daily_limit);
      setOverrideLeadLimit(data.data.override_lead_limit);
      if (data.data.daily_limit == "-1") {
        setQuoteItemDate(null);
      }
    } else {
      setAvailableDays([]);
      setDailyLimit(0);
      setOverrideLeadLimit(false);
      setDaysOff([]);
    }
  };
  const updateQuantity = (value) => {
    setProductQuantity(value);
  };

  const renderUserPrices = () => {
    return userPrices
      .filter((a) => a.parent_product_id == null)
      .map((userPrice) => {
        return (
          <React.Fragment key={`Fragment-${userPrice.id}-${userPrice.user_id}`}>
            <ListItem style={{ marginBottom: 0, paddingBottom: 0 }}>
              <ListItemAvatar>
                <Avatar src={userPrice.user_avatar} alt={userPrice.user_name}>
                  <FaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${userPrice.user_name} for $ ${userPrice.price}`}
                secondary={
                  userPrice.offer_from
                    ? `${userPrice.offer_name ? userPrice.offer_name : ""}
                       ${
                         userPrice.offer_from
                           ? ` from ${userPrice.offer_from}`
                           : " from now "
                       }
                       ${
                         userPrice.offer_to ? ` to  ${userPrice.offer_to}` : ""
                       }`
                    : ""
                }
              />
              {userPrice.booking_from ? (
                <ListItemText
                  secondary={`Booking ${
                    userPrice.booking_from
                      ? ` from ${userPrice.booking_from}`
                      : ""
                  } ${
                    userPrice.booking_to ? ` to ${userPrice.booking_to}` : ""
                  }`}
                />
              ) : (
                ""
              )}
              <ListItemSecondaryAction>
                <IconButton
                  disabled={
                    responsibleLoading && responsibleLoading == userPrice.id
                  }
                  onClick={() => selectUserPrice(userPrice)}
                  edge="end"
                  aria-label="select"
                >
                  {responsibleLoading && responsibleLoading == userPrice.id ? (
                    <Iconify
                      style={{ color: "#7c8d9f", width: 28, height: 28 }}
                      icon="line-md:loading-loop"
                      color="primary"
                    />
                  ) : (
                    <Iconify
                      style={{ color: "#207ee3", width: 28, height: 28 }}
                      icon="ic:round-check"
                      color="primary"
                    />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            {userPrice &&
            (userPrice.full_payment_days ||
              userPrice.full_payment_days == 0) ? (
              <ListItemText
                style={{ paddingLeft: 70, marginTop: 0, paddingTop: 0 }}
                secondary={`To take advantage of the promotional price of $${
                  userPrice.price
                },
                  payments must be made by ${moment(userPrice.booking_to)
                    .add(userPrice?.full_payment_days || 0, "days")
                    .format("MMMM D, YYYY")}.
                  Payments made after this date will be subject to the standard price of $${
                    parseInt(userPrice.price) +
                    parseInt(userPrice.late_payment_fee)
                  }.`}
              />
            ) : (
              ""
            )}
            <Divider variant="inset" style={{ marginBottom: 3 }} />
          </React.Fragment>
        );
      });
  };

  const renderUserPricesForPackages = () => {
    return !isEmpty(packageItemUserPrices) ? (
      <List>
        Recomended responsible prices:
        {packageItemUserPrices
          .filter(
            (a) => a.parent_product_id && a.parent_product_id == productId
          )
          .map((userPrice) => {
            return (
              <>
                <ListItem style={{ marginBottom: 0, paddingBottom: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      src={userPrice.user_avatar}
                      alt={userPrice.user_name}
                    >
                      <FaceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${userPrice.user_name} for $ ${userPrice.price}`}
                    secondary={
                      userPrice.offer_from
                        ? `${userPrice.offer_name ? userPrice.offer_name : ""}
                       ${
                         userPrice.offer_from
                           ? ` from ${userPrice.offer_from}`
                           : " from now "
                       }
                       ${
                         userPrice.offer_to ? ` to  ${userPrice.offer_to}` : ""
                       }`
                        : ""
                    }
                  />
                  {userPrice.booking_from ? (
                    <ListItemText
                      secondary={`Booking ${
                        userPrice.booking_from
                          ? ` from ${userPrice.booking_from}`
                          : ""
                      } ${
                        userPrice.booking_to
                          ? ` to ${userPrice.booking_to}`
                          : ""
                      }`}
                    />
                  ) : (
                    ""
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      disabled={
                        responsibleLoading && responsibleLoading == userPrice.id
                      }
                      onClick={() => selectPackageUserPrice(userPrice)}
                      edge="end"
                      aria-label="select"
                    >
                      {responsibleLoading &&
                      responsibleLoading == userPrice.id ? (
                        <Iconify
                          style={{ color: "#7c8d9f", width: 28, height: 28 }}
                          icon="line-md:loading-loop"
                          color="primary"
                        />
                      ) : (
                        <Iconify
                          style={{ color: "#207ee3", width: 28, height: 28 }}
                          icon="ic:round-check"
                          color="primary"
                        />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

                {userPrice &&
                (userPrice.full_payment_days ||
                  userPrice.full_payment_days == 0) ? (
                  <ListItemText
                    style={{ paddingLeft: 70, marginTop: 0, paddingTop: 0 }}
                    secondary={`To take advantage of the promotional price of $${
                      userPrice.price
                    },
                  payments must be made by ${moment(userPrice.booking_to)
                    .add(userPrice?.full_payment_days || 0, "days")
                    .format("MMMM D, YYYY")}.
                  Payments made after this date will be subject to the standard price of $${
                    parseInt(userPrice.price) +
                    parseInt(userPrice.late_payment_fee)
                  }.`}
                  />
                ) : (
                  ""
                )}
                <Divider variant="inset" style={{ marginBottom: 3 }} />
              </>
            );
          })}
      </List>
    ) : (
      ""
    );
  };

  const getDailyTotal = (date) => {
    const value = find(totalRecords, { date: date.format("YYYY-MM-DD") });
    if (!value) return 0;

    return value.total;
  };

  const disableDates = (date) => {
    if (userIsAdmin) return false;
    const userLimit =
      userSuperDoctorDate && overrideLeadLimit
        ? 10
        : user.daily_limit
        ? parseInt(user.daily_limit)
        : 10;
    const dailyTotal = getDailyTotal(date);
    const weekDay = date.isoWeekday();
    const day = date.format("MM-DD-YYYY");

    if (weekDay === 7) return true; //sunday

    if (dailyTotal >= userLimit) return true;

    if (!isEmpty(daysOff) && !isEmpty(availableDays)) {
      return daysOff.includes(day) || !availableDays.includes(weekDay);
    }
    if (!isEmpty(daysOff)) return daysOff.includes(day);

    if (!isEmpty(availableDays)) return !availableDays.includes(weekDay);

    return false;
  };

  const disableDates2 = (dateL) => {
    // Allow super-admin to select any date
    if (userIsSuperAdmin) return false;

    const date = moment(dateL);

    const userLimit =
      userSuperDoctorDate && overrideLeadLimit
        ? 10
        : parseInt(user.daily_limit || "10", 10);

    const formattedDate = date.format("MM-DD-YYYY");
    const weekDay = date.isoWeekday();

    const isOutsideBookingRange =
      (userPriceA &&
        userPriceA.booking_from &&
        date.isBefore(moment(userPriceA.booking_from))) ||
      (userPriceA &&
        userPriceA.booking_to &&
        date.isAfter(moment(userPriceA.booking_to), "day"));

    if (weekDay === 7) return true;

    if (getDailyTotal(date) >= userLimit || isOutsideBookingRange) {
      return true;
    }

    if (
      !isEmpty(daysOff) &&
      (daysOff.includes(formattedDate) || isOutsideBookingRange)
    ) {
      return true;
    }

    if (
      !isEmpty(availableDays) &&
      (!availableDays.includes(weekDay) || isOutsideBookingRange)
    ) {
      return true;
    }

    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let full_payment_due = null;
    if (
      userPriceA &&
      userPriceA.offer_to &&
      userPriceA.full_payment_days !== null &&
      userPriceA.full_payment_days !== undefined &&
      userPriceA.full_payment_days >= 0
    ) {
      full_payment_due = moment(userPriceA.offer_to)
        .add(userPriceA.full_payment_days, "days")
        .format("YYYY-MM-DD");
    }

    const data = {
      productId,
      giftable,
      productName,
      productPrice,
      productApplyFinancing,
      responsible: productResponsible,
      quoteItemDate,
      type: productType,
      quantity: productQuantity,
      cal_color: productColor,
      product_gift_reason_id: productGiftReasonSelected,
      gift_reference_price: giftReferencePrice,
      gift_user_id:
        productGiftReasonSelected && productGiftReasonSelected > 0
          ? authUser.id
          : null,
      isProcedure: productSelect
        ? productSelect.procedure
        : false /*productIsProcedure*/,
      is_package: productIsPackage,
      product_items:
        productSelect.product_items && productSelect.product_items.length > 0
          ? productSelect.product_items
          : [],
      user_product_price_id: userPriceA ? userPriceA.id : null,
      full_payment_due,
      late_payment_fee: userPriceA ? userPriceA.late_payment_fee : null,
      downpayment_amount:
        item && item.downpayment_amount ? item.downpayment_amount : 0,
      is_revision: revisionCheck,
      revision_comment: revisionComment,
      product_standard_price: productStandardPrice,
      product_min_price: productMinPrice,
    };

    saveItem(data);

    //saveQuote();
  };

  const customDayPropGetter = (date) => {
    if (date.getDate() === 7 || date.getDate() === 15)
      return {
        className: "special-day",
      };
    else return {};
  };
  const customSlotPropGetter = (date) => {
    if (date.getDate() === 7 || date.getDate() === 15)
      return {
        className: "special-day",
      };
    else return {};
  };
  const customEventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: event.color,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  const getRemoteData = async () => {
    const { data } = await Api.getUserRecordProductsGroupByDate(user.id);
    setTotalRecords(data);
  };

  const onClosePackageItem = () => {
    setOpenPackageItem(false);
    setSelectedPackageItem(null);
    setItemProps({
      calendar_date: null,
      responsible: null,
      price: null,
      user_product_price_id: null,
      product_min_price: null,
      product_standard_price: null,
    });
    setProductResponsible("");
  };

  const getPackageUserPrices = async (productId) => {
    if (productId) {
      if (!selectedPackageItem) setPackageItemUserPrices([]);

      const { data } = await Api.getUserPrices(productId);

      setPackageItemUserPrices(data.data);
    }
  };

  const selectPackageUserPrice = async (userPrice) => {
    try {
      setResponsibleLoading(userPrice.id);

      if (userPrice) {
        setUserPriceA(userPrice);
        const { data } = await Api.getUser(userPrice.user_id);
        setUser(data.data);
        setAvailableDays(data.data.available_days);
        setDaysOff(data.data.days_off);
        setDailyLimit(data.data.daily_limit);
        if (data.data.daily_limit == "-1") {
          setQuoteItemDate(null);
        }
      } else {
        setAvailableDays([]);
        setDaysOff([]);
        setDailyLimit(0);
      }

      setProductResponsible({
        id: userPrice.userPrice,
        title: userPrice.user_name,
      });
      setResponsible({ id: userPrice.user_id, title: userPrice.user_name });

      if (userCanUseMinPrice) {
        minPrice.current = giftCheck
          ? 0
          : userPrice.min_price !== null
          ? userPrice.min_price
          : userPrice.price;
      } else {
        minPrice.current = giftCheck ? 0 : userPrice.price;
      }

      setItemProps({
        ...itemProps,
        ...{
          price: userPrice.price,
          responsible: { id: userPrice.user_id, title: userPrice.user_name },
          responsible_id: userPrice.user_id,
          user_product_price_id: userPrice.id,
          product_min_price: userPrice.min_price,
          product_standard_price: userPrice.price,
        },
      });
      setResponsibleLoading(null);
    } catch (ex) {
      setResponsibleLoading(null);
    }
  };

  const renderPackageItems = (items) => {
    //console.log("items ", items)
    return items.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell style={{ maxWidth: 430 }} component="th" scope="row">
            {item.item_name}
          </TableCell>
          <TableCell>{item?.calendar_date}</TableCell>
          <TableCell>{item?.responsible?.title}</TableCell>
          <TableCell>{item.quantity}</TableCell>
          {!(userCanFinancing && !userCanChangePrice && !userCanUpPrice) ? (
            <TableCell>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ marginRight: 4 }}>
                  $ {item.price}
                </Typography>
                {item.price_includes_qty ? (
                  <Tooltip title="The price of this item is total, not unitary per item.">
                    <span>
                      <Iconify
                        style={{
                          color: "#7d8ecf",
                          width: 18,
                          height: 18,
                          marginTop: 6,
                        }}
                        icon={"fluent:info-24-filled"}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </TableCell>
          ) : (
            ""
          )}

          <TableCell align="right">
            {item?.product.data.is_procedure ? (
              <Button
                endIcon={
                  <Iconify
                    style={{ color: "#1f78be", fontSize: 20 }}
                    icon={
                      item && item.responsible && item.responsible.title
                        ? "material-symbols:edit-rounded"
                        : "fa6-solid:user-doctor"
                    }
                  />
                }
                onClick={() => {
                  setSelectedPackageItem(item);
                  setItemProps({
                    calendar_date:
                      item && item.calendar_date ? item.calendar_date : null,
                    responsible:
                      item && item.responsible ? item.responsible : null,
                    responsible_id:
                      item && item.responsible ? item.responsible.id : null,
                    price: item && item.price ? item.price : null,
                    user_product_price_id:
                      item && item.user_product_price_id
                        ? item.user_product_price_id
                        : null,
                    product_min_price: item.product_min_price,
                    product_standard_price: item.product_standard_price,
                  });
                  setResponsible(item?.responsible?.title || "");
                  setOpenPackageItem(true);
                  getPackageUserPrices(item?.product?.data?.id || null);
                }}
              >
                {item && item.responsible && item.responsible.title ? (
                  ""
                ) : (
                  <Typography style={{ fontWeight: 600, color: "#343f5e" }}>
                    Select the doctor
                  </Typography>
                )}
              </Button>
            ) : (
              ""
            )}
            {!(userCanFinancing && !userCanChangePrice && !userCanUpPrice) ? (
              <Tooltip title="Change Item Price">
                <span>
                  <IconButton
                    onClick={() => {
                      setSelectedPackageItem(item);
                      setEditPackageItemPriceOpened(true);
                    }}
                    color="primary"
                  >
                    <Iconify
                      style={{
                        width: "22px",
                        height: "22px",
                        color: "#03a55c",
                      }}
                      icon="ic:round-currency-exchange"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  const onSavePackageItem = () => {
    setProductResponsible("");

    const index = findIndex(productSelect.product_items, [
      "id",
      selectedPackageItem.id,
    ]);
    const entity = {
      ...productSelect.product_items[index],
      ...itemProps,
    };
    let entities = [...productSelect.product_items];
    entities[index] = entity;

    var product = {
      ...productSelect,
      ...{ product_items: entities },
    };

    setProductSelect(product);

    if (giftCheck) {
      setPriceBeforeCheck(
        product.product_items.reduce(
          (total, item) =>
            total +
            (item.price_includes_qty ? item.price : item.price * item.quantity),
          0
        )
      );
      setGiftReferencePrice(
        product.product_items.reduce(
          (total, item) =>
            total +
            (item.price_includes_qty ? item.price : item.price * item.quantity),
          0
        )
      );
      // minPrice.current = 0;
      setProductPrice(0);
    } else {
      setProductPrice(
        product.product_items.reduce(
          (total, item) =>
            total +
            (item.price_includes_qty ? item.price : item.price * item.quantity),
          0
        )
      );
    }

    setSelectedPackageItem(null);
    setItemProps({
      responsible: null,
      calendar_date: null,
      price: null,
      user_product_price_id: null,
      product_min_price: null,
      product_standard_price: null,
    });
    setOpenPackageItem(false);
  };

  const renderPackageProducts = () => {
    return (
      <div>
        <Typography
          style={{
            fontSize: 15,
            padding: 5,
            fontWeight: 600,
            color: "#3780c1",
          }}
        >
          {" "}
          Package Products{" "}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Calendar Date</TableCell>
              <TableCell>Surgeon</TableCell>
              <TableCell>Quantity</TableCell>
              {!(userCanFinancing && !userCanChangePrice && !userCanUpPrice) ? (
                <TableCell>Price</TableCell>
              ) : (
                ""
              )}
              <TableCell></TableCell>
              {/*<TableCell>Discount</TableCell>*/}
            </TableRow>
          </TableHead>

          <TableBody>
            {renderPackageItems(productSelect?.product_items)}
          </TableBody>
        </Table>
      </div>
    );
  };

  const getProducts = async () => {
    const params = new URLSearchParams([["pid", productId]]);
    const { data } = await Api.getActiveProducts(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );

    setProducts([...data.data]);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      getRemoteData();
    }
  }, [user]);

  useEffect(() => {
    getMinPriceAvailableQtyToUse();
    getProducts();
  }, []);

  const closeEditPackageItemPrice = () => {
    setEditPackageItemPriceOpened(false);
    setSelectedPackageItem(null);
  };

  const onSavePackageItemPrice = (price) => {
    if (selectedPackageItem) {
      const index = findIndex(productSelect.product_items, [
        "id",
        selectedPackageItem.id,
      ]);
      const entity = {
        ...productSelect.product_items[index],
        ...{ price: price },
      };
      let entities = [...productSelect.product_items];
      entities[index] = entity;

      var product = {
        ...productSelect,
        ...{ product_items: entities },
      };

      setProductSelect(product);

      if (giftCheck) {
        setPriceBeforeCheck(
          product.product_items.reduce(
            (total, item) =>
              total +
              (item.price_includes_qty
                ? item.price
                : item.price * item.quantity),
            0
          )
        );
        setGiftReferencePrice(
          product.product_items.reduce(
            (total, item) =>
              total +
              (item.price_includes_qty
                ? item.price
                : item.price * item.quantity),
            0
          )
        );
        // minPrice.current = 0;
        setProductPrice(0);
      } else {
        setProductPrice(
          product.product_items.reduce(
            (total, item) =>
              total +
              (item.price_includes_qty
                ? item.price
                : item.price * item.quantity),
            0
          )
        );
      }

      closeEditPackageItemPrice();
    }
  };

  const getMinDateToScheduleProcedure = () => {
    if (userCanSkipMinDaysUntilSurgery) {
      return moment();
    } else {
      const minDaysUntilSurgeryDate =
        authUser?.organization?.data?.min_days_until_surgery_date || 1;
      const date = moment().add(minDaysUntilSurgeryDate, "days");
      if (
        item &&
        item.calendar_date &&
        moment(item.calendar_date).isBefore(date)
      ) {
        return moment(item.calendar_date);
      } else {
        return date;
      }
    }
  };

  useEffect(() => {
    if (userCanUseMinPrice && item && item.product_min_price !== null) {
      minPrice.current = item.product_min_price;
    }
  }, [userMinPriceAvailableQty]);

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutocompleteGroupColors
            allowNew={false}
            defaultValue={productName}
            label="Products"
            options={getProductOptions()}
            onChange={(newValue) => {
              updateProductAndPrice(newValue);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controls.Input
            defaultValue={1}
            value={productQuantity}
            label="Quantity"
            disabled={
              /*productIsProcedure === 1 ||*/ productSelect &&
              (productSelect.type == "package" ||
                productSelect.procedure === 1 ||
                productSelect.isProcedure === 1)
                ? true
                : false
            }
            onChange={(value) => {
              updateQuantity(value.target.value);
            }}
            inputProps={{
              decimalPrecision: 0,
              decimalSeparator: false,
              allowNegative: false,
              isAllowed: (values) => {
                const { floatValue } = values;
                return floatValue >= 1 && floatValue <= 100;
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            spacing={2}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-8px",
            }}
          >
            <Grid
              item
              xs={
                item && item.discount_applied && item.discount_applied > 0
                  ? 5
                  : 12
              }
            >
              <Controls.InputDecoration
                id="price"
                label="Price"
                position={"start"}
                //defaultValue={productPrice}
                value={giftCheckAuto ? 0 : productPrice}
                inputProps={{
                  type: "number",
                  step: "any",
                  min: userCanUpPrice ? minPrice.current : 0,
                }}
                disabled={
                  giftCheckAuto ||
                  giftCheck ||
                  (productSelect && productSelect.is_package) ||
                  ((!userCanUseMinPrice ||
                    (userCanUseMinPrice &&
                      (userMinPriceAvailableQty === 0 ||
                        !productSelect ||
                        productSelect.min_sale_price === null))) &&
                    userCanFinancing &&
                    !userCanChangePrice &&
                    !userCanUpPrice) ||
                  (item &&
                    item.discount_applied &&
                    item.discount_applied > 0) ||
                  productPrice < 0
                }
                onChange={(value) => {
                  setProductPrice(value.target.value);
                }}
                onBlur={(value) => {
                  if (
                    (value.target.value <= minPrice.current &&
                      userCanUpPrice) ||
                    (value.target.value == 0 && userCanUpPrice)
                  ) {
                    setProductPrice(minPrice.current);
                  }
                }}
                decoration={"$"}
              />
            </Grid>
            {userCanUseMinPrice ? (
              productMinPrice !== null ? (
                <Typography
                  style={{
                    fontStyle: "oblique",
                    fontWeight: 500,
                    color: "#6464a7",
                    marginLeft: 8,
                    paddingBottom: 5,
                  }}
                >
                  {" "}
                  {"*Note: This product has a minimum price of $" +
                    productMinPrice +
                    ". You can set the sale price to this amount or higher."}
                </Typography>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {item && item.discount_applied && item.discount_applied > 0 && (
              <Grid item xs={7}>
                <ListItem>
                  <ListItemAvatar>
                    <Iconify
                      icon={"fluent:credit-card-person-24-filled"}
                      width={30}
                      height={30}
                      style={{ color: "#673ab7", marginRight: 5 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`$${item.discount_applied}`}
                    secondary="Special Offer Activated"
                  />
                </ListItem>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {(productSelect &&
        productSelect.reviewable &&
        userCanSetItemAsRevision) ||
      revisionCheckAuto ? (
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={1}>
            <Controls.Checkbox
              label={"Revision?"}
              checked={revisionCheck}
              onChange={(e) => changeRevisionCheck(!revisionCheck)}
              disabled={revisionCheckAuto}
            />
          </Grid>
          <Grid item xs={5}>
            <Controls.Textarea
              rows={2}
              disabled={!revisionCheck}
              style={{ width: "100%" }}
              value={revisionComment}
              label="Revision Comment"
              onChange={(value) => setRevisionComment(value.target.value)}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      ) : (
        ""
      )}
      {!isEmpty(userPrices) && !revisionCheck && (
        <div>
          <Typography variant="body1">
            Recomended responsible prices:
          </Typography>
          <List>{renderUserPrices()}</List>
        </div>
      )}

      {productSelect ? (
        productSelect?.procedure || productSelect?.isProcedure ? (
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Controls.Autocomplete
                label="Surgeon"
                defaultValue={productResponsible}
                disabled={!userCanChangeDoctor && !revisionCheck}
                options={getUserOptions()}
                onChange={(newValue) => {
                  setResponsible(newValue);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Controls.DateTimePicker
                label="Date"
                onChange={(date) => setQuoteItemDate(date)}
                value={quoteItemDate}
                minDate={getMinDateToScheduleProcedure()}
                shouldDisableDate={disableDates2}
                disabled={
                  userSuperDoctorDate && overrideLeadLimit
                    ? false
                    : dailyLimit == "-1" || !productResponsible
                }
                disablePast={true}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {/* {JSON.stringify(productSelect)} */}
      {(productSelect &&
        ((!productSelect.procedure &&
          !productSelect.isProcedure &&
          !productSelect.is_package) ||
          productSelect.is_giftable) &&
        userCanGiftItem) ||
      giftCheckAuto ? (
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Controls.Checkbox
              label={"Gift?"}
              checked={giftCheck}
              onChange={(e) => changeGiftCheck(!giftCheck)}
              disabled={giftCheckAuto}
            />
          </Grid>
          <Grid item xs={5}>
            <Controls.Select
              label="Gift Reason"
              disabled={!giftCheck}
              required={giftCheck}
              options={productGiftReasonOptions()}
              onChange={(value) =>
                setProductGiftReasonSelected(value.target.value)
              }
              value={productGiftReasonSelected}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      ) : (
        ""
      )}

      <Popup
        title={selectedPackageItem?.name}
        fullWidth={true}
        maxWidth={"md"}
        openPopup={openPackageItem}
        onClose={onClosePackageItem}
      >
        <div style={{ maxHeight: "80vh" }}>
          {selectedPackageItem &&
          selectedPackageItem.product &&
          selectedPackageItem.product.data &&
          selectedPackageItem.product.data.is_procedure ? (
            <>
              {" "}
              {renderUserPricesForPackages()}
              <Grid style={{ alignItems: "center" }} container spacing={1}>
                <Grid item xs={7}>
                  <Controls.Autocomplete
                    // disabled={userCanFinancing && !userCanChangePrice}
                    label="Surgeon"
                    defaultValue={
                      productResponsible != ""
                        ? productResponsible
                        : selectedPackageItem && selectedPackageItem.responsible
                        ? selectedPackageItem.responsible.title
                        : ""
                    }
                    options={getUserOptions()}
                    onChange={(newValue) => {
                      setResponsible(newValue);
                      setItemProps({
                        ...itemProps,
                        ...{
                          responsible: newValue,
                          responsible_id: newValue?.id,
                          user_product_price_id: null,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.DateTimePicker
                    fullWidth={true}
                    style={{ width: "100%" }}
                    label="Surgery date"
                    onChange={(date) =>
                      setItemProps({
                        ...itemProps,
                        ...{
                          calendar_date: moment(date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        },
                      })
                    }
                    value={
                      itemProps && itemProps.calendar_date
                        ? itemProps.calendar_date
                        : selectedPackageItem &&
                          selectedPackageItem.calendar_date
                        ? selectedPackageItem.calendar_date
                        : null
                    }
                    minDate={getMinDateToScheduleProcedure()}
                    shouldDisableDate={disableDates2}
                    disabled={
                      userSuperDoctorDate && overrideLeadLimit
                        ? false
                        : dailyLimit == "-1"
                    }
                    disablePast={true}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "right",
                      marginTop: 4,
                    }}
                  >
                    <Controls.Button
                      style={{ margin: 2 }}
                      disabled={
                        productResponsible == "" ||
                        !productResponsible ||
                        responsibleLoading
                      }
                      onClick={onSavePackageItem}
                      text="Submit"
                    />
                  </div>
                </Grid>
              </Grid>
              {productResponsible &&
              /*productIsProcedure*/ selectedPackageItem.product &&
              selectedPackageItem.product.data &&
              selectedPackageItem.product.data.is_procedure &&
              dailyLimit != "-1" ? (
                <>
                  <UserProcedureHeatmap4
                    date={itemProps?.calendar_date || null}
                    monthWidth={"190px"}
                    showDay={true}
                    minDate={getMinDateToScheduleProcedure()}
                    disableDates2={disableDates2}
                    user={user}
                    setDate={(date) =>
                      setItemProps({
                        ...itemProps,
                        ...{
                          calendar_date: moment(date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        },
                      })
                    }
                  />
                  {/* <UserProcedureHeatmap3
                    monthWidth={"190px"}
                    showDay={true}
                    disableDates2={disableDates2}
                    user={user}
                    setDate={(date) =>
                      setItemProps({
                        ...itemProps,
                        ...{
                          calendar_date: moment(date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        },
                      })
                    }
                  /> */}
                </>
              ) : null}
              {/* <HeatMapCalendarMonthly monthWidth={'126px'}/> */}
            </>
          ) : (
            ""
          )}
        </div>
      </Popup>

      {productSelect &&
      productSelect.type == "package" &&
      productSelect.product_items &&
      productSelect.product_items.length > 0 ? (
        <div>{renderPackageProducts()}</div>
      ) : (
        ""
      )}

      {productResponsible &&
      productSelect &&
      (productSelect.procedure || productSelect.isProcedure) &&
      (dailyLimit != "-1" || (userSuperDoctorDate && overrideLeadLimit)) ? (
        <div style={{ marginBottom: 25 }}>
          <UserProcedureHeatmap4
            monthWidth={"175px"}
            showDay={true}
            user={user}
            date={quoteItemDate}
            setDate={(date) =>
              setQuoteItemDate(moment(date).format("YYYY-MM-DD"))
            }
            minDate={getMinDateToScheduleProcedure()}
            disableDates2={disableDates2}
          />
          {/* <UserProcedureHeatmap3
 
            showDay={true}
            user={user}
            setDate={(date) => setQuoteItemDate(date)}
            disableDates2={disableDates2}
          /> */}
        </div>
      ) : null}
      <Grid container spacing={2}>
        <FormControl fullWidth margin="normal">
          <MuiButton
            type="submit"
            disabled={
              productSelect &&
              productSelect.product_items &&
              productSelect.product_items.length > 0
                ? productName &&
                  productPrice >= 0 &&
                  productSelect.product_items.findIndex(
                    (item) =>
                      ((item.product &&
                        item.product.data &&
                        item.product.data.is_procedure) ||
                        item.isProcedure) &&
                      item.responsible_id == null
                  ) == -1
                  ? false
                  : true
                : productSelect?.procedure
                ? ((productName && productPrice >= 0) || giftCheck) &&
                  productSelect &&
                  (productSelect.procedure || productSelect.isProcedure) &&
                  productResponsible &&
                  productResponsible != ""
                  ? false
                  : true
                : productName && productPrice >= 0
                ? false
                : true
            }
            variant="contained"
            color="secondary"
            style={{
              padding: "15px 0",
            }}
          >
            {item ? "SAVE" : "ADD"}
          </MuiButton>
        </FormControl>
      </Grid>

      <Popup
        fullWidth
        maxWidth={"xs"}
        title={`Edit price`}
        openPopup={editPackageItemPriceOpened}
        onClose={closeEditPackageItemPrice}
      >
        <EditPackageItemPriceForm
          minPrice={
            userCanUpPrice && selectedPackageItem
              ? userCanUseMinPrice && selectedPackageItem.min_price !== null
                ? selectedPackageItem.min_price
                : selectedPackageItem.price
              : 0
          }
          onClose={closeEditPackageItemPrice}
          onSavePrice={onSavePackageItemPrice}
          defaultPrice={selectedPackageItem?.price || null}
        />
      </Popup>
    </form>
  );
}
