import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../../lib/api";
import { useSelector } from "react-redux";
import moment from "moment";

/* ALM */
const apiGetFiltersDefault = {
  include: [],
  limit: 0,
  page: 0,
  params: [],
  filters: [],
  search: null,
  sort: null,
};
var apiUrlDataDefault = {
  module: null,
  entity: null,
  entities: null,
  many: false,
};
/*function getOrganizationId()
{
  return localStorage.getItem('organizationId');
}*/

export function newSlice(entitiesName) {
  const entitiesNamePagination = entitiesName + "Pagination";
  const getLoading = entitiesName + "Loading";
  const saveLoading = entitiesName + "SaveLoading";
  const deleteLoading = entitiesName + "DeleteLoading";
  const entitiesNameErrors = entitiesName + "Errors";
  const initialState = {
    loading: false,
    [getLoading]: false,
    [saveLoading]: false,
    [deleteLoading]: false,
    errors: [],
    [entitiesName]: [],
    [entitiesNamePagination]: {},
    [entitiesNameErrors]: [],
  };
  return createSlice({
    name: entitiesName,
    initialState,
    reducers: {
      getEntities: (state, { payload }) => {
        var showPageLoading = payload;
        state.loading = showPageLoading == true ? true : state.loading;
        state[getLoading] = true;
      },
      getEntitiesSuccess: (state, { payload }) => {
        state[entitiesName] = payload.data;
        state[entitiesNamePagination] =
          payload.meta != null ? payload.meta["pagination"] : {};
        state.loading = false;
        state[getLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      getEntitiesFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state.errors =
          payload.data != null
            ? [...state.errors, payload.data.message]
            : state.errors;
        state[entitiesNameErrors] = state.errors;
        console.log("ERROR", payload.message);
      },
      createEntity: (state) => {
        //state.loading = true;
        state[saveLoading] = true;
      },
      createEntitySuccess: (state, { payload }) => {
        state[entitiesName] = [payload.data, ...state[entitiesName]];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      createEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors =
          payload.data != null
            ? [...state.errors, payload.data.message]
            : state.errors;
        state[entitiesNameErrors] = state.errors;
      },
      updateEntity: (state) => {
        //state.loading = true;
        state[saveLoading] = true;
      },
      updateEntitySuccess: (state, { payload }) => {
        const index = findIndex(state[entitiesName], ["id", payload.data.id]);
        const entity = { ...state[entitiesName][index], ...payload.data };

        let entities = [...state[entitiesName]];
        entities[index] = entity;

        state[entitiesName] = [...entities];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      updateEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors =
          payload.data != null
            ? [...state.errors, payload.data.message]
            : state.errors;
        state[entitiesNameErrors] = state.errors;
      },

      addOnlyEntity: (state) => {
        //state.loading = true;
        state[saveLoading] = true;
      },
      addOnlyEntitySuccess: (state, { payload }) => {
        state[entitiesName] = [payload.data, ...state[entitiesName]];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      addOnlyEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      updateOnlyEntity: (state) => {
        state[saveLoading] = true;
      },
      updateOnlyEntitySuccess: (state, { payload }) => {
        //console.error("updateOnlyEntitySuccess", payload);

        const index = findIndex(state[entitiesName], ["id", payload.data.id]);
        const entity = { ...state[entitiesName][index], ...payload.data };

        let entities = [...state[entitiesName]];
        entities[index] = entity;

        state[entitiesName] = [...entities];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      updateOnlyEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      updateOnlyMessageCount: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;

        if (payload.data.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.data.contact_id),
          ]);
        } else if (payload.data.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return (
              o.phone === payload.data.phone && (o.id < 0 || o.id == undefined)
            );
          });
        }

        if (index >= 0) {
          let entity = null;

          if (payload.addsubtract == 1) {
            entity = {
              ...state[entitiesName][index],
              unread: state[entitiesName][index].unread + 1,
              lastMessageBody: payload.data.body,
              lastMessageUser: 0,
              lastMessageDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
          } else if (payload.addsubtract == 0) {
            entity = {
              ...state[entitiesName][index],
              unread: 0,
            };
          }
          if (payload.addsubtract == -1) {
            entity = {
              ...state[entitiesName][index],
              unread: 0,
              lastMessageDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
          }

          let entities = [...state[entitiesName]];
          entities[index] = entity;
          state[entitiesName] = [...entities];
          state.loading = false;
        } else if (payload.addsubtract == 1) {
          const newPhoneLocal = {
            fname: payload.data.fname ? payload.data.fname : "",
            full_name: payload.data.full_name
              ? payload.data.full_name
              : payload.data.phone,
            id: payload.data.id ? parseInt(payload.data.id) : -1,
            lname: payload.data.lname ? payload.data.lname : "",
            name: payload.data.phone,
            phone: payload.data.phone,
            unread: 1,
            lastMessageDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          };

          state[entitiesName] = [newPhoneLocal, ...state[entitiesName]];
        }
      },
      updateOnlyMessageCountSuccess: (state) => {
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      updateOnlyMessageCountFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      updateOnlyWhatsAppConfirmed: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;
        if (payload.data.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.data.contact_id),
          ]);
        } else if (payload.data.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return (
              o.phone === payload.data.phone && (o.id < 0 || o.id == undefined)
            );
          });
        }

        if (index >= 0) {
          let entity = null;
          if (payload.data.whatsapp_confirmed != state[entitiesName][index].whatsapp_confirmed) {
            entity = {
              ...state[entitiesName][index],
              whatsapp_confirmed: payload.data.whatsapp_confirmed,
            };

            let entities = [...state[entitiesName]];
            entities[index] = entity;
            state[entitiesName] = [...entities];
          }

          state.loading = false;
        }
      },
      updateOnlyWhatsAppConfirmedSuccess: (state) => {
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      updateOnlyWhatsAppConfirmedFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      unreadMessageCount: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;
        let entity = null;

        if (payload.data.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.data.contact_id),
          ]);
        } else if (payload.data.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return (
              o.phone === payload.data.phone && (o.id < 0 || o.id == undefined)
            );
          });
        }

        if (payload.unread == 1) {
          entity = {
            ...state[entitiesName][index],
            unread: 1,
          };
        } else if (payload.unread == 0) {
          entity = {
            ...state[entitiesName][index],
            unread: 0,
          };
        }
        let entities = [...state[entitiesName]];
        entities[index] = entity;
        state[entitiesName] = [...entities];
        state.loading = false;
      },
      unreadMessageCountSuccess: (state) => {
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      unreadMessageCountFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      changeColor: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;
        let entity = null;

        if (payload.data.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.data.contact_id),
          ]);
        } else if (payload.data.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return (
              o.phone === payload.data.phone && (o.id < 0 || o.id == undefined)
            );
          });
        }

        entity = {
          ...state[entitiesName][index],
          smsMMSColor: payload.color,
        };

        let entities = [...state[entitiesName]];
        entities[index] = entity;
        state[entitiesName] = [...entities];
        state.loading = false;
      },
      changeColorSuccess: (state) => {
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      changeColorFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      changeIsImportantSuccess: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;
        let entity = null;

        if (payload.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.contact_id),
          ]);
        } else if (payload.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return JSON.stringify(o.phone) === JSON.stringify(payload.phone);
          });
        }

        entity = {
          ...state[entitiesName][index],
          smsMMSColorIsImportant: payload.smsMMSColorIsImportant,
        };

        let entities = [...state[entitiesName]];
        entities[index] = entity;
        state[entitiesName] = [...entities];
        state.loading = false;

        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      changeIsImportantFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      addIsImportantSuccess: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;
        let entity = null;

        if (payload.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.contact_id),
          ]);
        } else if (payload.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return JSON.stringify(o.phone) === JSON.stringify(payload.phone);
          });
        }

        entity = {
          ...state[entitiesName][index],
          smsMMSColorIsImportant: payload.is_important,
          smsMMSColorId: payload.id,
          lastMessageDate: payload.lastmessagedate,
        };

        let entities = [...state[entitiesName]];
        entities[index] = entity;
        state[entitiesName] = [...entities];
        state.loading = false;

        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      changeBody: (state, { payload }) => {
        state[saveLoading] = true;
        let index = -1;
        let entity = null;

        if (payload.data.contact_id) {
          index = findIndex(state[entitiesName], [
            "id",
            parseInt(payload.data.contact_id),
          ]);
        } else if (payload.data.phone) {
          index = findIndex(state[entitiesName], function (o) {
            return (
              o.phone === payload.data.phone && (o.id < 0 || o.id == undefined)
            );
          });
        }

        entity = {
          ...state[entitiesName][index],
          lastMessageBody: payload.lastMessageBody,
          lastMessageUser: payload.lastMessageUser,
        };

        let entities = [...state[entitiesName]];
        entities[index] = entity;
        state[entitiesName] = [...entities];
        state.loading = false;
      },
      changeBodySuccess: (state) => {
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      changeBodyFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      deleteEntity: (state) => {
        //state.loading = true;
        state[deleteLoading] = true;
        state[getLoading] = true;
      },
      deleteEntitySuccess: (state, { payload }) => {
        state[entitiesName] = state[entitiesName].filter((entity) => {
          return entity.id !== payload;
        });
        state.loading = false;
        state[getLoading] = false;
        state[deleteLoading] = false;
      },
      deleteEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[deleteLoading] = false;
        state.errors =
          payload.data != null
            ? [...state.errors, payload.data.message]
            : state.errors;
        state[entitiesNameErrors] = state.errors;
      },
    },
  });
}

export function _loadEvents(entitiesSlice) {
  const {
    getEntities,
    getEntitiesSuccess,
    getEntitiesFailure,
    createEntity,
    createEntitySuccess,
    createEntityFailure,
    updateEntity,
    updateEntitySuccess,
    updateEntityFailure,
    updateOnlyMessageCount,
    updateOnlyMessageCountSuccess,
    updateOnlyMessageCountFailure,
    updateOnlyWhatsAppConfirmed,
    updateOnlyWhatsAppConfirmedSuccess,
    updateOnlyWhatsAppConfirmedFailure,
    unreadMessageCount,
    unreadMessageCountSuccess,
    unreadMessageCountFailure,
    changeColor,
    changeColorSuccess,
    changeColorFailure,
    changeIsImportantSuccess,
    changeIsImportantFailure,
    changeBody,
    changeBodySuccess,
    changeBodyFailure,
    addOnlyEntity,
    addOnlyEntitySuccess,
    addOnlyEntityFailure,
    updateOnlyEntity,
    updateOnlyEntitySuccess,
    updateOnlyEntityFailure,
    deleteEntity,
    deleteEntitySuccess,
    deleteEntityFailure,
    addIsImportantSuccess,
  } = entitiesSlice.actions;
  return {
    getEntities: getEntities,
    getEntitiesSuccess: getEntitiesSuccess,
    getEntitiesFailure: getEntitiesFailure,
    createEntity: createEntity,
    createEntitySuccess: createEntitySuccess,
    createEntityFailure: createEntityFailure,
    updateEntity: updateEntity,
    updateEntitySuccess: updateEntitySuccess,
    updateEntityFailure: updateEntityFailure,
    updateOnlyMessageCount: updateOnlyMessageCount,
    updateOnlyMessageCountSuccess: updateOnlyMessageCountSuccess,
    updateOnlyMessageCountFailure: updateOnlyMessageCountFailure,
    updateOnlyWhatsAppConfirmed: updateOnlyWhatsAppConfirmed,
    updateOnlyWhatsAppConfirmedSuccess: updateOnlyWhatsAppConfirmedSuccess,
    updateOnlyWhatsAppConfirmedFailure: updateOnlyWhatsAppConfirmedFailure,
    unreadMessageCount: unreadMessageCount,
    unreadMessageCountSuccess: unreadMessageCountSuccess,
    unreadMessageCountFailure: unreadMessageCountFailure,
    changeColor: changeColor,
    changeColorSuccess: changeColorSuccess,
    changeColorFailure: changeColorFailure,
    changeIsImportantSuccess: changeIsImportantSuccess,
    changeIsImportantFailure: changeIsImportantFailure,
    addIsImportantSuccess: addIsImportantSuccess,
    changeBody: changeBody,
    changeBodySuccess: changeBodySuccess,
    changeBodyFailure: changeBodyFailure,
    updateOnlyEntity: updateOnlyEntity,
    updateOnlyEntitySuccess: updateOnlyEntitySuccess,
    updateOnlyEntityFailure: updateOnlyEntityFailure,
    addOnlyEntity: addOnlyEntity,
    addOnlyEntitySuccess: addOnlyEntitySuccess,
    addOnlyEntityFailure: addOnlyEntityFailure,

    deleteEntity: deleteEntity,
    deleteEntitySuccess: deleteEntitySuccess,
    deleteEntityFailure: deleteEntityFailure,
  };
}

function getApiFilters(filtersCustom) {
  var filters = { ...apiGetFiltersDefault };
  if (filtersCustom != null) {
    filters.include =
      filtersCustom.include != null ? filtersCustom.include : filters.include;
    filters.page =
      filtersCustom.page != null ? filtersCustom.page : filters.page;
    filters.limit =
      filtersCustom.limit != null ? filtersCustom.limit : filters.limit;
    filters.params =
      filtersCustom.params != null ? filtersCustom.params : filters.params;
    filters.filters =
      filtersCustom.filters != null ? filtersCustom.filters : filters.filters;
    filters.search =
      filtersCustom.search != null ? filtersCustom.search : filters.search;
    filters.sort =
      filtersCustom.sort != null ? filtersCustom.sort : filters.sort;
  }
  return filters;
}
function getApiUrl(apiUrlCustom) {
  var apiUrl = { ...apiUrlDataDefault };
  if (apiUrlCustom != null) {
    apiUrl.module =
      apiUrlCustom.module != null ? apiUrlCustom.module : apiUrl.module;
    apiUrl.entity =
      apiUrlCustom.entity != null ? apiUrlCustom.entity : apiUrl.entity;
    apiUrl.entities =
      apiUrlCustom.entities != null ? apiUrlCustom.entities : apiUrl.entities;
    apiUrl.many = apiUrlCustom.many != null ? apiUrlCustom.many : apiUrl.many;
  }
  var many = apiUrl.many;
  var entityName =
    many && apiUrl.entities != null ? apiUrl.entities : apiUrl.entity;
  entityName = !many && entityName == null ? apiUrl.entities : entityName;

  var url = apiUrl.module != null ? "/" + apiUrl.module : "";
  url += entityName != null ? "/" + entityName : "";
  apiUrl.url = url;

  return apiUrl;
}

export function _fetchEntitiesService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = true;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var getFilters = getApiFilters(dataSlice.getFilters);

    /*if(dataSlice.requireOrganizationId==true){
      getFilters.organization_id = getOrganizationId();
    } */

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      var showPageLoading = dataSlice.dataList
        ? dataSlice.dataList.showPageLoading
        : true;
      dispatch(dispatchEvents.getEntities(showPageLoading));
      try {
        //const response = await Api.getEntities(dataUrl.url,getFilters);
        var apiParams = { dataUrl: dataSlice.dataUrl, filters: getFilters };
        const response = await Api.getEntities(apiParams);

        dispatch(dispatchEvents.getEntitiesSuccess(response.data));

        if (dataSlice.dataList && dataSlice.dataList.successHandle) {
          dispatch(dataSlice.dataList.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.getEntitiesFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _fetchEntitiesServiceGroups(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = true;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var getFilters = getApiFilters(dataSlice.getFilters);

    /*if(dataSlice.requireOrganizationId==true){
      getFilters.organization_id = getOrganizationId();
    } */

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      var showPageLoading = dataSlice.dataList
        ? dataSlice.dataList.showPageLoading
        : true;
      dispatch(dispatchEvents.getEntities(showPageLoading));
      try {
        //const response = await Api.getEntities(dataUrl.url,getFilters);
        var apiParams = { dataUrl: dataSlice.dataUrl, filters: getFilters };
        var response = await Api.getEntities(apiParams);

        if (response.data && response.data.data && response.data.data.length > 0) dispatch(dispatchEvents.getEntitiesSuccess(response.data));
       
        //Data available; get 6 month total
        var i = 0;
        var responseTmp;
        while (i < 5) {
          //console.log("response after",response,apiParams);
          apiParams.filters.page = apiParams.filters.page + 1;
          responseTmp = await Api.getEntities(apiParams);
          if (responseTmp.data.data.length > 0) {
            response.data.data = response.data.data.concat(
              responseTmp.data.data
            );

            dispatch(dispatchEvents.getEntitiesSuccess(response.data));
          }
          i++;
        }

        // dispatch(dispatchEvents.getEntitiesSuccess(response.data));

        if (dataSlice.dataList && dataSlice.dataList.successHandle) {
          dispatch(dataSlice.dataList.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.getEntitiesFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}
/*export function getAllEntitiesService(orgId) {
  return async (dispatch) => {
    dispatch(getEntities());
    try {
      const response = await Api.getAllEntities();
      dispatch(getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(getEntitiesFailure(error));
    }
  };
}*/
export function _createEntityService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataCreate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var payload = dataSlice.dataCreate.data;
    var getFilters = getApiFilters(dataSlice.getFilters);

    /*if(dataSlice.requireOrganizationId==true){
      payload.organization_id = getOrganizationId();
    }   */

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      dispatch(dispatchEvents.createEntity());
      try {
        //const response = await Api.saveEntity(dataUrl.url,payload);
        var apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload: payload,
        };
        const response = await Api.saveEntity(apiParams);

        dispatch(dispatchEvents.createEntitySuccess(response.data));
        if (dataSlice.dataCreate.successHandle) {
          dispatch(dataSlice.dataCreate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.createEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _updateEntityService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUpdate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var id = dataSlice.dataUpdate.id;
    var payload = dataSlice.dataUpdate.data;
    var getFilters = getApiFilters(dataSlice.getFilters);

    /*if(dataSlice.requireOrganizationId==true){
      payload.organization_id = getOrganizationId();
    } */

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      dispatch(dispatchEvents.updateEntity());
      try {
        var url = dataUrl.url + "/" + id;
        getFilters.params["id"] = id;
        //const response = await Api.updateEntity(url,id, payload);
        var apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload: payload,
        };
        const response = await Api.updateEntity(apiParams);

        dispatch(dispatchEvents.updateEntitySuccess(response.data));
        if (dataSlice.dataUpdate.successHandle) {
          dispatch(dataSlice.dataUpdate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.updateEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _updateOnlyEntityService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.updateOnlyEntity());
    try {
      dispatch(dispatchEvents.updateOnlyEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.updateOnlyEntityFailure(error));
    }
  };
}

export function _addOnlyEntityService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.addOnlyEntity());
    try {
      dispatch(dispatchEvents.addOnlyEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.addOnlyEntityFailure(error));
    }
  };
}

export function _addOnlyEntityServiceByGroups(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.addOnlyEntity());
    try {
      dispatch(dispatchEvents.addOnlyEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.addOnlyEntityFailure(error));
    }
  };
}

export function _updateOnlyMessageCountService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.updateOnlyMessageCount(payload));
    try {
      dispatch(dispatchEvents.updateOnlyMessageCountSuccess());
    } catch (error) {
      dispatch(dispatchEvents.updateOnlyMessageCountFailure(error));
    }
  };
}
export function _updateOnlyWhatsAppConfirmedService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.updateOnlyWhatsAppConfirmed(payload));
    try {
      dispatch(dispatchEvents.updateOnlyWhatsAppConfirmedSuccess());
    } catch (error) {
      dispatch(dispatchEvents.updateOnlyWhatsAppConfirmedFailure(error));
    }
  };
}

export function _updateOnlyMessageCountServiceByGroups(
  payload,
  dispatchEvents
) {
  return async (dispatch) => {
    dispatch(dispatchEvents.updateOnlyMessageCount(payload));
    try {
      dispatch(dispatchEvents.updateOnlyMessageCountSuccess());
    } catch (error) {
      dispatch(dispatchEvents.updateOnlyMessageCountFailure(error));
    }
  };
}

export function _unreadMessageCountService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.unreadMessageCount(payload));

    try {
      Api.unreadLast(payload.user_id, payload);

      dispatch(dispatchEvents.unreadMessageCountSuccess());
    } catch (error) {
      dispatch(dispatchEvents.unreadMessageCountFailure(error));
    }
  };
}

export function _unreadMessageCountServiceByGroups(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.unreadMessageCount(payload));

    try {
      Api.unreadLastByGroups(payload.group, payload);

      dispatch(dispatchEvents.unreadMessageCountSuccess());
    } catch (error) {
      dispatch(dispatchEvents.unreadMessageCountFailure(error));
    }
  };
}

export function _changeColorService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.changeColor(payload));

    try {
      Api.setSmsMmsContactColor(payload.user_id, payload);

      dispatch(dispatchEvents.changeColorSuccess());
    } catch (error) {
      dispatch(dispatchEvents.changeColorFailure(error));
    }
  };
}

export function _changeIsImportantService(payload, dispatchEvents) {
  return async (dispatch) => {
    try {
      dispatch(dispatchEvents.changeIsImportantSuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.changeIsImportantFailure(error));
    }
  };
}

export function _addIsImportantService(payload, dispatchEvents) {
  return async (dispatch) => {
    try {
      dispatch(dispatchEvents.addIsImportantSuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.changeIsImportantFailure(error));
    }
  };
}

export function _changeColorServiceByGroups(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.changeColor(payload));

    try {
      Api.setSmsMmsContactColorByGroups(payload.group, payload);

      dispatch(dispatchEvents.changeColorSuccess());
    } catch (error) {
      dispatch(dispatchEvents.changeColorFailure(error));
    }
  };
}

export function _addContactLocalService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.addContactLocal(payload));

    try {
      dispatch(dispatchEvents.addContactLocalSuccess());
    } catch (error) {
      dispatch(dispatchEvents.addContactLocalFailure(error));
    }
  };
}

export function _changeBodyService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.changeBody(payload));

    try {
      dispatch(dispatchEvents.changeBodySuccess());
    } catch (error) {
      dispatch(dispatchEvents.changeBodyFailure(error));
    }
  };
}

export function _deleteEntityService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataDelete && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    let id = dataSlice.dataDelete.id;
    var dataUrl = getApiUrl(dataSlice.dataUrl);
    var getFilters = getApiFilters(dataSlice.getFilters);

    return async (dispatch) => {
      //entitiesName=dataSlice.stateListName;
      dispatch(dispatchEvents.deleteEntity());
      try {
        var url = dataUrl.url + "/" + id;
        getFilters.params["id"] = id;
        var apiParams = { dataUrl: dataSlice.dataUrl, filters: getFilters };
        await Api.deleteEntity(apiParams);

        dispatch(dispatchEvents.deleteEntitySuccess());

        if (dataSlice.dataDelete.successHandle) {
          dispatch(dataSlice.dataDelete.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.deleteEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}
