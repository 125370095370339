import React, {  } from "react";
import { makeStyles } from "@material-ui/core/styles";
// @mui
import {
  Box,
  ListItemIcon,
  ListItemText,
  Drawer,
  Button,
  MenuList,
  MenuItem,
  Typography,
  ListItemSecondaryAction,
} from "@material-ui/core";

// components
import Iconify from "../Iconify";

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  textActive: {
    fontWeight: 700,
    fontSize: ".975rem",
  },
  text: {
    fontWeight: 400,
    fontSize: ".975rem",
  },
  compose: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)",
    "-webkit-font-smoothing": "antialiased",
    letterSpacing: ".25px",
    "-webkit-align-items": "center",
    backgroundImage: "none",
    "-webkit-border-radius": "24px",
    borderRadius: "24px",
    color: "#3c4043",
    fontWeight: 500,
    height: "40px",
    width: "208px",
    overflow: "hidden",
    
    marginTop: 20,
    marginBottom: 20,
    fontSize: ".975rem",

    "&:hover": {
      boxShadow:
        "0 1px 3px 0 rgba(60,64,67,0.302),0 4px 8px 3px rgba(60,64,67,0.149)",
      backgroundColor: "#fafafb",
    },
  },
  refresh: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)",
    "-webkit-font-smoothing": "antialiased",
    "-webkit-align-items": "center",
    backgroundImage: "none",
    "-webkit-border-radius": "24px",
    borderRadius: "24px",
    color: "#3c4043",
    height: "48px",
    width: "250px",
    overflow: "hidden",
    marginTop: 20,
    marginBottom: 20,

    "&:hover": {
      boxShadow:
        "0 1px 3px 0 rgba(60,64,67,0.302),0 4px 8px 3px rgba(60,64,67,0.149)",
      backgroundColor: "#fafafb",
    },
  },
  menuItemActive: {
    backgroundColor: "#d3e3fd",
    margin: 5,
    borderRadius: 25,
    boxShadow: "inset 0 0 0 1px transparent",
    color: "#001d35",
    fontSize: ".975rem",
    fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
  },
  menuItem: {
    backgroundColor: "#eaf1fb",
    borderRadius: 25,
    margin: 5,
    color: "#202124",
    fontSize: ".975rem",
    fontFamily: '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
  },
});

export default function MailSidebar(props) {
  const {
    selectedMenu,
    setSelectedMenu,
    setIsComposeOrReplayOpen,
    folders,
    setSelectedFolder,
    onComposeClick,
    fromIndex,
  } = props;
  const classes = useStyles();

  const getIcon = (gmail) => {
    switch (gmail.name) {
      case "All Mail":
        return "lucide:mails";
        break;
      case "Drafts":
        return "ri:draft-fill";
        break;
      case "Important":
        return "bx:label";
        break;
      case "Sent Mail":
        return "wpf:sent";
        break;
      case "Spam":
        return "gridicons:spam";
        break;
      case "Starred":
        return "fluent:star-arrow-right-start-24-filled";
        break;
      case "Trash":
        return "dashicons:trash";
        break;
      default:
    }
  };

  const getMenu2 = (folder) => {
    switch (folder.name) {
      case "INBOX":
        return (
          <MenuItem
            key={folder.name}
            className={
              selectedMenu === folder.name
                ? classes.menuItemActive
                : classes.menuItem
            }
            onClick={() => {
              setSelectedMenu(folder.name);
              setSelectedFolder(folder.full_name);
            }}
          >
            <ListItemIcon>
              <Iconify
                icon={"bi:inbox-fill"}
                style={{
                  color: "#202124",
                }}
                width={20}
                height={20}
              />
            </ListItemIcon>
            <ListItemText
              primary="Inbox"
              classes={{
                primary:
                  selectedMenu === folder.name
                    ? classes.textActive
                    : classes.text,
              }}
            />
            <ListItemSecondaryAction>
              <Typography
                variant="body2"
                style={{ fontWeight: selectedMenu === folder.name ? 700 : 400 }}
              >
                {folder.info?.exists}
              </Typography>
            </ListItemSecondaryAction>
          </MenuItem>
        );
        break;
      case "[Gmail]":
        return folder.children.data.map((gmail) => {
          return (
            <MenuItem
              key={gmail.name}
              className={
                selectedMenu === gmail.name
                  ? classes.menuItemActive
                  : classes.menuItem
              }
              onClick={() => {
                setSelectedMenu(gmail.name);
                setSelectedFolder(gmail.full_name);
              }}
            >
              <ListItemIcon>
                <Iconify
                  icon={getIcon(gmail)}
                  style={{
                    color: "#202124",
                  }}
                  width={20}
                  height={20}
                />
              </ListItemIcon>
              <ListItemText
                primary={gmail.name}
                classes={{
                  primary:
                    selectedMenu === gmail.name
                      ? classes.textActive
                      : classes.text,
                }}
              />
              <ListItemSecondaryAction>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: selectedMenu === gmail.name ? 700 : 400,
                  }}
                >
                  {gmail.info?.exists}
                </Typography>
              </ListItemSecondaryAction>
            </MenuItem>
          );
        });
        break;
      default:
      // code block
    }
  };

  const renderContent = (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={onComposeClick}
          fullWidth
          variant="contained"
          className={classes.compose}
          startIcon={
            <Iconify icon={"akar-icons:edit"} style={{ color: "#001d35" }} />
          }
        >
          Compose
        </Button>
      </Box>

      <MenuList>
        {folders &&
          folders.map((folder) => {
            return getMenu2(folder);
          })}
      </MenuList>
    </>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        PaperProps={{
          style: {
            width: "12vw",
            height: fromIndex ? "65vh" : "78vh",
            position: "relative",
            borderWidth: 0,
            backgroundColor: "#eaf1fb",
          },
        }}
      >
        {renderContent}
      </Drawer>
    </>
  );
}
