import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function RadioGroup(props) {
  const { label, items, value, onChange, radioRequired=false, ...other } = props;

  return (
    <FormControl >
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup  row value={value}>
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            control={<Radio required={radioRequired} disabled={item?.disabled || false} />}
            label={item.title}
            value={item.id}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            {...other}
            disabled={item?.disabled || false}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
