import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Api from "../lib/api";
import { isEmpty, forEach } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "./controls";
import Iconify from "./Iconify";
import ScrollBar from "react-perfect-scrollbar";
import { maxHeight } from "@material-ui/system";
import InputMoneyOkWithoutController from "./controls/InputMoneyOkWithoutController";
import { findIndex } from "lodash";
import IconButton from "./controls/IconButton";
import Notification from "./Notification";
import Popup from "./Popup";
import InvoiceDetails from "../pages/invoices/details";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  invoiceCard: {
    margin: "5px 0px",
    border: "1px solid #8080800d",
    background: "#90a0b921",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#FFF",
    alignItems: "center",
    marginTop: "5px",
  },
  paymentDetailsTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #80808024",
  },
  paymentBackground: {
    borderRadius: "10px",
    paddingTop: 0,
  },
  paymentDetailsTitle: {
    fontWeight: 600,
    fontSize: 17,
    color: "#263b4d",
  },
  paymentDetailsCreatedBy: {
    fontWeight: 500,
    color: "#534d4d",
  },
  amount: {
    background: "#aaf5b45c",
    padding: "9px 12px",
    borderRadius: 46,
    color: "#09af00",
    fontWeight: 600,
  },
  detail: {
    color: "#404143",
    fontWeight: 500,
    marginLeft: "26px",
  },
  invoiceIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    color: "#617c9f",
  },
  maxButton: {
    borderRadius: 10,
    background: "#daf1ef",
    padding: "0px 12px",
    minWidth: "fit-content",
    fontSize: 13,
  },
  invoicesBackground: {
    background: "#f1f1f1",
    padding: "16px",
    borderRadius: "14px",
    marginTop: 15,
  },
  invoiceName: {
    fontWeight: 500,
    fontSize: 15,
    borderBottom: "2px solid #8c8eb9",
  },
  histories: {
    padding: 10,
    background: "white",
    borderRadius: "0px 0px 10px 10px",
  },
  historyTitle: {
    fontWeight: 500,
    color: "#3c6487",
    fontSize: 14.5,
    marginLeft: 4,
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 0px 4px 0px",
    borderBottom: "1px solid #80808052",
    marginBottom: 5,
  },
}));

function MultipaymentDetails(props) {
  const { history, entity="Payment" } = props;
  const [header, setHeader] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  function groupArrayBy(array, key, groupedKeyName, extraKeys = []) {
    const groupedData = [];

    array.forEach((item) => {
      const itemKey = item[key];
      const existingGroup = groupedData.find(
        (group) => group[groupedKeyName] === itemKey
      );

      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        var extraValues = {};
        if (!isEmpty(extraKeys)) {
          extraKeys.forEach((extraKey) => {
            var keyValue = item[extraKey];
            extraValues[extraKey] = keyValue;
          });
        }
        const newGroup = {
          ...{ [groupedKeyName]: itemKey, items: [item] },
          ...extraValues,
        };
        groupedData.push(newGroup);
      }
    });

    return groupedData;
  }

  const getPaymentHeader = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api.getPaymentHistoryHeader(history.id);
      var tempHeader = { ...data.data };

      if (
        tempHeader["paymentHistories"] &&
        !isEmpty(tempHeader["paymentHistories"]["data"])
      ) {
        tempHeader["paymentHistories"]["data"] = groupArrayBy(
          tempHeader["paymentHistories"]["data"],
          "invoice",
          "invoice_id",
          ["invoice_name"]
        );
      }

      console.log("tempHeader: ", tempHeader);
      setHeader({ ...tempHeader });
      setIsLoading(false);
    } catch (err) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (history) {
      getPaymentHeader();
    }
  }, [history]);

  const getInvoiceTotalAmount = (invoice) => {
    return invoice.items.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
  };

  const openInvoicePopup = (invoice) => {
    setSelectedInvoice({ ...invoice, ...{ id: invoice.invoice_id } });
    setIsInvoiceOpen(true);
  };

  const renderInvoicePayments = (invoice) => {
    return (
      <div style={{ marginBottom: 22 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {history && history.invoice == invoice.invoice_id ? (
            <Typography
              className={classes.invoiceName}
              style={{ borderBottom: "none", padding: 5, display: "flex" }}
            >
              {invoice.invoice_name}
              <Typography
                style={{ color: "#2f4dc1", fontWeight: 600, marginLeft: 10 }}
              >
                {" "}
                {" *Current invoice"}
              </Typography>
            </Typography>
          ) : (
            <Button
              onClick={() => {
                openInvoicePopup(invoice);
              }}
            >
              <Typography className={classes.invoiceName}>
                {invoice.invoice_name}
              </Typography>
            </Button>
          )}

          <Typography
            style={{ fontWeight: 500, color: "rgb(4 169 48)", marginRight: 4 }}
          >{`Total Amount: $${getInvoiceTotalAmount(invoice)}`}</Typography>
        </div>
        <div className={classes.histories}>
          {invoice.items.map((item) => {
            return (
              <div style={{ marginBottom: 12 }}>
                <Typography className={classes.historyTitle}>
                  {" "}
                  {`${entity} of $${item.amount}`}
                </Typography>
                <div style={{ marginLeft: 10, marginTop: 6, marginRight: 10 }}>
                  {item.paymentdetail && !isEmpty(item.paymentdetail.data)
                    ? item.paymentdetail.data.map((detail) => {
                        return (
                          <div className={classes.detailContainer}>
                            <Typography style={{ marginRight: 10 }}>
                              {detail.item_name}
                            </Typography>
                            <Typography
                              style={{
                                color: "rgb(25 91 175)",
                                fontWeight: 500,
                              }}
                            >
                              {"$" + detail.amount}
                            </Typography>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const closeInvoicePopup = () => {
    setSelectedInvoice(null);
    setIsInvoiceOpen(false);
  };

  return (
    <>
      <div className={classes.paymentBackground}>
        <div className={classes.paymentDetailsTitleContainer}>
          <Typography className={classes.paymentDetailsTitle}>
            {`${entity.toUpperCase()} DETAILS`}
          </Typography>
        </div>

        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            <div style={{ padding: "10px 5px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "15px" }}>
                  <Typography
                    style={{ fontWeight: 500, color: "#4d4d4d", fontSize: 15 }}
                  >
                    {header?.payment_type || "-"}{" "}
                  </Typography>
                  <Typography>{header?.payment_date || "-"} </Typography>
                </div>
                <div className={classes.amount}>
                  Amount: $ {header?.amount || "-"}
                </div>

                <div className={classes.detail}>
                  Card Holder: {header?.cardholder || "-"}
                </div>
                <div className={classes.detail}>
                  Last 4: {header?.cc_last_4 || "-"}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoading ? (
        <ScrollBar style={{ maxHeight: 650 }}>
          {" "}
          <div className={classes.invoicesBackground}>
            {header &&
            header.paymentHistories &&
            !isEmpty(header.paymentHistories.data)
              ? header.paymentHistories.data.map((invoice) => {
                  return renderInvoicePayments(invoice);
                })
              : ""}
          </div>
        </ScrollBar>
      ) : (
        ""
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <Popup
        fullScreen
        title={
          selectedInvoice
            ? `Invoice # ${selectedInvoice.invoice_name}`
            : "Invoice details"
        }
        openPopup={isInvoiceOpen}
        onClose={closeInvoicePopup}
      >
        <InvoiceDetails invoice={selectedInvoice} />
      </Popup>
    </>
  );
}

export default MultipaymentDetails;
