import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { findIndex } from "lodash";
import CRMUtils from "../../utils";
import { useSelector } from "react-redux";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import InputMoneyOkWithoutController from "../controls/InputMoneyOkWithoutController";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  moreButton: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 2,
  },
  lessButton: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 2,
    transform: "rotate(180deg)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "63%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  primaryHeadingDisabled: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.disabled,
  },
}));

export default function HeaderHistoriesToMultiRefundForm(props) {
  const { onChange, header } = props;
  const [paymentsData, setPaymentsData] = useState([]);
  const [historiesValues, setHistoriesValues] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const classes = useStyles();

  const getPaymentsData = async () => {
    if (header) {
      const { data } = await Api.getMultipaymentHistories(header.id);

      setPaymentsData([...data.data]);
    }
  };

  useEffect(() => {
    getPaymentsData();
  }, []);

  useEffect(() => {
    if (paymentsData && !isEmpty(paymentsData)) {
      const tempHistories = paymentsData.map((history) => {
        return {
          id: history.id,
          amount: history.amount,
          new_amount: null,
          invoice_name: history.invoice_name,
          items:
            history.paymentsDetail && !isEmpty(history.paymentsDetail)
              ? history.paymentsDetail.map((a) => {
                  return { ...a, ...{ new_amount: "" } };
                })
              : [],
        };
      });

      setHistoriesValues([...tempHistories]);
    }
  }, [paymentsData]);

  useEffect(() => {
    if (onChange) {
      onChange(historiesValues);
    }
  }, [historiesValues]);

  const renderInvoiceItems = (items, history) => {
    return items.map((item, index) => {
      var isRequired = historiesValues.find((a) =>
        a.items.find(
          (i) => i.new_amount && i.new_amount != null && i.new_amount != 0
        )
          ? true
          : false
      )
        ? false
        : true;

      return (
        <TableRow key={item.id}>
          <TableCell style={{ maxWidth: "160px" }} component="th" scope="row">
            <Typography
              className={
                item.type === "forfeiture" ||
                item.type === "system" ||
                (item.amount ? item.amount : 0) -
                  (item?.refund_payment_details_simple_sum_amount || 0) <
                  0 ||
                item.void === 1
                  ? classes.primaryHeadingDisabled
                  : classes.primaryHeading
              }
              key={`item_name-${item.id}`}
            >
              {item.item_name}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              className={
                item.type === "forfeiture" ||
                item.type === "system" ||
                (item.amount ? item.amount : 0) -
                  (item?.refund_payment_details_simple_sum_amount || 0) <
                  0 ||
                item.void === 1
                  ? classes.primaryHeadingDisabled
                  : classes.primaryHeading
              }
              key={`refundableamount_${item.id}`}
            >
              {`$${
                item.type === "forfeiture" || item.type === "system"
                  ? "0.00"
                  : (
                      (item.amount ? item.amount : 0) -
                      (item?.refund_payment_details_simple_sum_amount || 0)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
              }`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <InputMoneyOkWithoutController
              onInvalidCapture={(e) => {
                if (!history.expanded) {
                  expandAccordion(history);
                }
              }}
              required={isRequired}
              label="Amount"
              type="number"
              onChange={(value) => {
                updateAmount(value, history, item);
              }}
              value={item.new_amount}
              disabled={
                item.type === "forfeiture" ||
                item.type === "system" ||
                (item.amount ? item.amount : 0) -
                  (item?.refund_payment_details_simple_sum_amount || 0) <
                  0 ||
                item.void === 1
              }
              maxValue={
                item.type === "forfeiture" || item.type === "system"
                  ? "0"
                  : (item.amount ? item.amount : 0) -
                    (item?.refund_payment_details_simple_sum_amount || 0)
              }
              minValue={userCanEditNegativePayments ? "-999999" : "1"}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const updateAmount = (value, history, detail) => {
    const index = findIndex(historiesValues, ["id", history.id]);

    const items = [...historiesValues[index]["items"]];
    const indexItem = findIndex(items, ["id", detail.id]);

    if (indexItem != -1) {
      const updatedItemEvent = {
        ...items[indexItem],
        ...{ new_amount: value },
      };
      items[indexItem] = updatedItemEvent;
    } else {
      items.push({
        id: item_id,
        new_amount: value,
        edit_id: null,
      });
    }

    const updatedEvent = {
      ...historiesValues[index],
      ...{
        items: items,
        new_amount: items.reduce(
          (total, item) =>
            total +
            parseFloat(
              item.new_amount && item.new_amount != "" ? item.new_amount : 0
            ),
          0
        ),
      },
    };

    let newHistoriesValues = [...historiesValues];
    newHistoriesValues[index] = updatedEvent;
    console.log("newHistoriesValues: ", newHistoriesValues);
    setHistoriesValues([...newHistoriesValues]);
  };

  const expandAccordion = (history) => {
    const index = findIndex(historiesValues, ["id", history.id]);
    const updatedEvent = {
      ...historiesValues[index],
      ...{
        expanded: historiesValues[index]["expanded"] ? false : true,
      },
    };

    let newHistories = [...historiesValues];
    newHistories[index] = updatedEvent;
    setHistoriesValues([...newHistories]);
  };

  return (
    <>
      {historiesValues.map((history) => {
        console.log("HISTORY!@: ", history);
        return (
          <Accordion
            expanded={history.expanded ? true : false}
            style={{ background: "rgb(231 245 243)", margin: "8px 2px" }}
          >
            <AccordionSummary
              onClick={() => {
                expandAccordion(history);
              }}
              style={{
                margin: 5,
                background: "rgb(231 245 243)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Iconify
                    className={
                      history.expanded ? classes.lessButton : classes.moreButton
                    }
                    icon="material-symbols:expand-more-rounded"
                  />
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                  >
                    {" "}
                    {`Payment of $${history.amount} to invoice ${history.invoice_name}`}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ display: "grid", marginRight: 10 }}>
              <TableContainer
                style={{ padding: "10px 18px" }}
                component={Paper}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Refundable Amount</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {" "}
                    {renderInvoiceItems(history.items, history)}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
