import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ExtrafieldForm(props) {
  const { addOrEditExtra, extraForEdit, type } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const onSubmit = (data) => {
    addOrEditExtra(data);
  };



  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="type_label"
            control={control}
            defaultValue={extraForEdit?.type_label || ""}
            as={<Controls.Input label="Label" />}
            rules={{ required: "label is required" }}
          />
          {errors.type_label && <p className="error">{errors.type_label.message}</p>}

          <Controller
            name="value"
            control={control}
            defaultValue={extraForEdit?.value || ""}
            as={<Controls.Input label="Value" />}
            rules={{ required: "Value name is required" }}
          />
          {errors.value && <p className="error">{errors.value.message}</p>}

          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Reset" color="default" />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
