import { Box, ListItemSecondaryAction, TextField, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import GroupIcon from '@material-ui/icons/Group';
import { debounce, map, orderBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SwipeableViews from "react-swipeable-views";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listUsersWrapper: {
    paddingLeft: "15px",
    paddingTop: "15px",
  },
  selectedUser: {
    background: "#6266ce",
    padding: "2px",
    margin: "2px",
  },
  inputSearch: {
    marginBottom: "5px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
  userItem: {
    padding: "2px",
    margin: "2px",
  },
  searchInput: {
    minWidth: "95%",
  },
  bold: {
    fontWeight: 700,
  },
  normal: {
    fontWeight: 400,
  },
  bold1: {
    fontWeight: 700,
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "65%",
  },
  normal1: {
    fontWeight: 400,
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "65%",
  },
}));

const SmsMmsUserList = (props) => {
  const classes = useStyles();
  const {
    contactGroupsDetails,
    contactUsersDetails,
    contact_id,
    groupsOptions,
    user_id,
    setSelectedUserSMSMMS,
    selectedUserSMSMMS,
    setSelectedUserSMSMMSByGroup,
    selectedUserSMSMMSByGroup,

  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [listScrollGroups, setListScrollGroups] = useState({
    initial: 0,
    amount: 14,
  });

  const [isLoadingGroup, setIsLoadingGroup] = useState(false);
  const [contactUsers, setContactUsers] = useState([]);
  const [contactGroups, setContactGroups] = useState([]);
  const [localUsers, setLocalUsers] = useState([]);
  const [localUsersByGroups, setLocalUsersByGroups] = useState([]);

  const [searchText, setSearchText] = useState("");

  const setSearch = (search) => {
    setSearchText(search);
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 500),
    []
  );

  const [localSelectedUsers, setLocalSelectedUsers] = useState();
  const [localSelectedUserByGroups, setLocalSelectedUserByGroups] = useState();
  const [listScroll, setListScroll] = useState({ initial: 0, amount: 14 });
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleChangeIndex = (index) => {
    setTabValue(index);
  };
  const theme = useTheme();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  useEffect(() => {
    if (selectedUserSMSMMS) setLocalSelectedUsers(selectedUserSMSMMS);
    if (selectedUserSMSMMSByGroup)
      setLocalSelectedUserByGroups(selectedUserSMSMMSByGroup);
  }, [selectedUserSMSMMS, selectedUserSMSMMSByGroup]);

  useEffect(() => {
    if (contactUsersDetails) {
      const u = orderBy(contactUsersDetails, ["lastMessageDate"], ["desc"]);
      setLocalUsers(u);
    }
  }, [contactUsersDetails]);

  useEffect(() => {
    if (contactGroupsDetails) {
      const g = orderBy(contactGroupsDetails, ["lastMessageDate"], ["desc"]);
      setLocalUsersByGroups(g);
    } else if (!contactGroupsDetails && groupsOptions) {
      //const g = orderBy(groupsOptions, ["lastMessageDate"], ["desc"]);
      setLocalUsersByGroups(groupsOptions[0].id);
    }
  }, [contactGroupsDetails, groupsOptions]);

  useEffect(() => {
    if (searchText && searchText !== " ") {
      const terms = searchText.split(" ").map((term) => {
        return new RegExp(term, "i");
      });

      const searchedItems = contactUsersDetails
        ? contactUsersDetails.filter((option) => {
            return terms.some((term) => {
              return term.test(option.full_name);
            });
          })
        : [];

      setLocalUsers(searchedItems);
    } else {
      const u = orderBy(contactUsersDetails, ["lastMessageDate"], ["desc"]);
      setLocalUsers(u);
    }
  }, [searchText]);

  /*  useEffect(() => {
    setIsLoading(true);
    setIsLoadingGroup(true);
    if(contact_id){
      smsmmsUsersFunc().then(()=>{
        setIsLoading(false);
      });
      smsmmsGroupsFunc().then(()=>{
        setIsLoadingGroup(false);
      })
    }
    }, [contact_id]);
  
  

    
   /*   const smsmmsUsersFunc = async () => {
        const {data}  = await Api.getUsersSmsMss(contact_id);
        console.log("data.data userList",data.data);
        setContactUsers(data.data);
      }
   
     const smsmmsGroupsFunc = async () => {
        const {data}  = await Api.getGroupsSmsMss(contact_id);
        setContactGroups(data.data);
      }
  
  
*/

  const renderUsers = () => {
    return (
      <InfiniteScroll
        dataLength={
          contactUsersDetails.slice(listScroll.initial, listScroll.amount)
            .length
        }
        next={() => {
       
          setListScroll({
            initial: listScroll.initial,
            amount: listScroll.amount + 14,
          });
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      
        hasMore={true}
        scrollableTarget={"scrollableDiv"}
       
      >
        {contactUsersDetails && contactUsersDetails.length > 15
          ? map(
              contactUsersDetails.slice(listScroll.initial, listScroll.amount),
              (item, index) => {
                return (
                  <>
                    <ListItem
                      className={classes.userItem}
                      onClick={() => {
                        setSelectedUserSMSMMS(item.id);
                        setSelectedUserSMSMMSByGroup(null);
                        setLocalSelectedUserByGroups(null);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <FaceIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        classes={{
                          primary:
                            localSelectedUsers == item.id
                              ? classes.bold1
                              : classes.normal1,
                        }}
                        primary={
                          item.full_name +
                          (item.groups ? " (" + item.groups + ")" : "")
                        }
                        secondary={item.building}
                      />
                      <ListItemSecondaryAction>
                        {item.lastMessageDate ? (
                          moment(item.lastMessageDate).format("MM-DD-YY") ==
                          moment().format("MM-DD-YY") ? (
                            <strong>Today</strong>
                          ) : moment(item.lastMessageDate).format("MM-DD-YY") ==
                            moment().subtract(1, "days").format("MM-DD-YY") ? (
                            <strong>Yesterday</strong>
                          ) : (
                            moment(item.lastMessageDate).format("MM-DD-YY")
                          )
                        ) : (
                          ""
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </>
                );
              }
            )
          : map(contactUsersDetails, (item, index) => {
              return (
                <>
                  <ListItem
                    className={classes.userItem}
                    onClick={() => {
                      setSelectedUserSMSMMS(item.id);
                      setSelectedUserSMSMMSByGroup(null);
                      setLocalSelectedUserByGroups(null);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FaceIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      classes={{
                        primary:
                          localSelectedUsers == item.id
                            ? classes.bold1
                            : classes.normal1,
                      }}
                      primary={
                        item.full_name +
                        (item.groups ? " (" + item.groups + ")" : "")
                      }
                      secondary={item.building}
                    />
                    <ListItemSecondaryAction>
                      {item.lastMessageDate ? (
                        moment(item.lastMessageDate).format("MM-DD-YY") ==
                        moment().format("MM-DD-YY") ? (
                          <strong>Today</strong>
                        ) : moment(item.lastMessageDate).format("MM-DD-YY") ==
                          moment().subtract(1, "days").format("MM-DD-YY") ? (
                          <strong>Yesterday</strong>
                        ) : (
                          moment(item.lastMessageDate).format("MM-DD-YY")
                        )
                      ) : (
                        ""
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
      </InfiniteScroll>
    );

    /*return contactUsersDetails?contactUsersDetails.map((item, index) => {
      if (item.id != user_id) {
        return (
          <ListItem
            className={classes.userItem}
            onClick={() => {
              setSelectedUserSMSMMS(item.id);
              setSelectedUserSMSMMSByGroup(null);
              setLocalSelectedUserByGroups(null);
            }}
          >
      
            <ListItemAvatar>
              <Avatar>
                <FaceIcon />
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              classes={{
                primary:
                localSelectedUsers == item.id
                    ? classes.bold1
                    : classes.normal1,
              }}
              primary={
                item.full_name + (item.groups ? " (" + item.groups + ")" : "")
              }
              secondary={item.building}
            />
            <ListItemSecondaryAction>
              {item.lastMessageDate ? (
                moment(item.lastMessageDate).format("MM-DD-YY") ==
                moment().format("MM-DD-YY") ? (
                  <strong>Today</strong>
                ) : moment(item.lastMessageDate).format("MM-DD-YY") ==
                  moment().subtract(1, "days").format("MM-DD-YY") ? (
                  <strong>Yesterday</strong>
                ) : (
                  moment(item.lastMessageDate).format("MM-DD-YY")
                )
              ) : (
                ""
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      }
    }):"";
    */
  };

  const renderUsersByGroups = () => {
    return (
      <InfiniteScroll
        dataLength={
          contactGroupsDetails.slice(
            listScrollGroups.initial,
            listScrollGroups.amount
          ).length
        }
        next={() => {
         
          setListScrollGroups({
            initial: listScrollGroups.initial,
            amount: listScrollGroups.amount + 14,
          });
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        
        hasMore={true}
        scrollableTarget={"scrollableDivGroup"}
       
      >
        {contactGroupsDetails && contactGroupsDetails.length > 15
          ? map(
              contactGroupsDetails.slice(
                listScrollGroups.initial,
                listScrollGroups.amount
              ),
              (item, index) => {
                if (
                  groupsOptions &&
                  groupsOptions[0] &&
                  item.id != groupsOptions[0].group_id
                ) {
                  return (
                    <>
                      <ListItem
                        className={classes.userItem}
                        onClick={() => {
                          setSelectedUserSMSMMSByGroup(item.id);
                   
                          setSelectedUserSMSMMS(null);
                          setLocalSelectedUsers(null);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <GroupIcon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          classes={{
                            primary:
                              localSelectedUserByGroups == item.id
                                ? classes.bold1
                                : classes.normal1,
                          }}
                          primary={item.full_name}
                          //secondary={item.building}
                        />
                        <ListItemSecondaryAction>
                          {item.lastMessageDate ? (
                            moment(item.lastMessageDate).format("MM-DD-YY") ==
                            moment().format("MM-DD-YY") ? (
                              <strong>Today</strong>
                            ) : moment(item.lastMessageDate).format(
                                "MM-DD-YY"
                              ) ==
                              moment()
                                .subtract(1, "days")
                                .format("MM-DD-YY") ? (
                              <strong>Yesterday</strong>
                            ) : (
                              moment(item.lastMessageDate).format("MM-DD-YY")
                            )
                          ) : (
                            ""
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  );
                }
              }
            )
          : contactGroupsDetails.map((item, index) => {
              if (
                groupsOptions &&
                groupsOptions[0] &&
                item.id != groupsOptions[0].group_id
              ) {
                return (
                  <>
                    <ListItem
                      className={classes.userItem}
                      onClick={() => {
                        setSelectedUserSMSMMSByGroup(item.id);
                      
                        setSelectedUserSMSMMS(null);
                        setLocalSelectedUsers(null);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <GroupIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        classes={{
                          primary:
                            localSelectedUserByGroups == item.id
                              ? classes.bold1
                              : classes.normal1,
                        }}
                        primary={item.full_name}
            
                      />
                      <ListItemSecondaryAction>
                        {item.lastMessageDate ? (
                          moment(item.lastMessageDate).format("MM-DD-YY") ==
                          moment().format("MM-DD-YY") ? (
                            <strong>Today</strong>
                          ) : moment(item.lastMessageDate).format("MM-DD-YY") ==
                            moment().subtract(1, "days").format("MM-DD-YY") ? (
                            <strong>Yesterday</strong>
                          ) : (
                            moment(item.lastMessageDate).format("MM-DD-YY")
                          )
                        ) : (
                          ""
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </>
                );
              }
            })}
      </InfiniteScroll>
    );

    /*  return contactGroupsDetails.map((item, index) => {
      if (groupsOptions && groupsOptions[0] && item.id != groupsOptions[0].group_id) {
        return (
          <ListItem
            className={classes.userItem}
            onClick={() => {
              setSelectedUserSMSMMSByGroup(item.id);
              console.log("item.id",item.id);
              setSelectedUserSMSMMS(null);
              setLocalSelectedUsers(null);
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <GroupIcon />
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              classes={{
                primary:
                localSelectedUserByGroups == item.id
                    ? classes.bold1
                    : classes.normal1,
              }}
              primary={
                item.full_name
              }
              //secondary={item.building}
            />
            <ListItemSecondaryAction>
              {item.lastMessageDate ? (
                moment(item.lastMessageDate).format("MM-DD-YY") ==
                moment().format("MM-DD-YY") ? (
                  <strong>Today</strong>
                ) : moment(item.lastMessageDate).format("MM-DD-YY") ==
                  moment().subtract(1, "days").format("MM-DD-YY") ? (
                  <strong>Yesterday</strong>
                ) : (
                  moment(item.lastMessageDate).format("MM-DD-YY")
                )
              ) : (
                ""
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      }
    });*/
  };

  /*
  useEffect(()=>{
    console.log("selectedUserSMSMMSByGroup",selectedUserSMSMMSByGroup);
  },[selectedUserSMSMMSByGroup]); */

  return (
    <div className={classes.listUsersWrapper}>
      {/*JSON.stringify(groups)*/}

      <TextField
        placeholder="Search…"
        onChange={(event) => debouncedSearch(event.target.value)}
        className={classes.searchInput}
        InputProps={{
          inputProps: { style: { borderRadius: 35 } },
          style: { borderRadius: 35 },
        }}
        variant="outlined"
      ></TextField>

      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="By Users" {...a11yProps(0)} />
          <Tab label="By Groups" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabValue}
        onChangeIndex={handleChangeIndex}
      >
        <List
          component="nav"
          id={"scrollableDiv"}
          style={{
            height: "700px",
            width: "98%",
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TabPanel value={tabValue} index={0} dir={theme.direction}>
            <Box
              //m={3}
              display="flex"
              flexDirection="column"
              style={{
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "45%",
              }}
            >
              <List className={classes.root}>
                <ListItem
                  className={classes.userItem}
                  onClick={() => {
                    setSelectedUserSMSMMS(user_id);
                    setSelectedUserSMSMMSByGroup(null);
                    setLocalSelectedUserByGroups(null);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FaceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{
                      primary:
                        localSelectedUsers == user_id
                          ? classes.bold
                          : classes.normal,
                    }}
                    primary="Me"
                  />
                </ListItem>
                {renderUsers()}
                {/*isLoading && <CircularProgress size={32} style = {{marginLeft: 130}} disableShrink />
                 */}
              </List>
            </Box>
            {isLoading && (
              <CircularProgress
                size={45}
                style={{ marginLeft: "50%" }}
                disableShrink
              />
            )}
          </TabPanel>
        </List>

        <List
          component="nav"
          id={"scrollableDivGroup"}
          style={{
            height: "700px",
            width: "98%",
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TabPanel value={tabValue} index={1} dir={theme.direction}>
            <Box
              //m={3}
              display="flex"
              flexDirection="column"
              style={{
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "45%",
              }}
            >
              <List className={classes.root}>
                {groupsOptions.length > 0 && (
                  <ListItem
                    className={classes.userItem}
                    onClick={() => {
                  
                      setSelectedUserSMSMMSByGroup(groupsOptions[0].group_id);
                      setSelectedUserSMSMMS(null);
                      setLocalSelectedUsers(null);
                     
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      classes={{
                        primary:
                          localSelectedUserByGroups == groupsOptions[0].id
                            ? classes.bold
                            : classes.normal,
                      }}
                      primary={groupsOptions[0].name}
                    />
                  </ListItem>
                )}
                {renderUsersByGroups()}
              </List>
            </Box>
            {isLoadingGroup && (
              <CircularProgress
                size={45}
                style={{ marginLeft: "50%" }}
                disableShrink
              />
            )}
          </TabPanel>
        </List>
      </SwipeableViews>
    </div>
  );
};

export default SmsMmsUserList;
