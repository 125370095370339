import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useRef, useState } from "react";
import Iconify from "../Iconify";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  Chip,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Api from "../../lib/api";
import moment from "moment/moment";
import { findIndex, isEmpty, trim } from "lodash";
import Controls from "../controls";
import Picker from "emoji-picker-react";
import { useDispatch, useSelector } from "react-redux";
import ScrollBar from "react-perfect-scrollbar";
import Popup from "../Popup";
import InfiniteScroll from "react-infinite-scroll-component";
import NewChatGroup from "./NewChatGroup";
import ConfirmDialog from "../ConfirmDialog";
import { Controller, useForm } from "react-hook-form";
import { updateTotalUnreadChatMessages } from "../../redux/slices/totalUnreadChatMessages";
import { updateGeneralTotalUnreadChatMessages } from "../../redux/slices/generalTotalUnreadChatMessages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  error: {
    color: "red",
    fontWeight: "500",
    fontSize: "15px",
    marginLeft: 19,
    marginTop: 2,
  },
  noHeaderMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    maxHeight: "80vh",
    margin: 10,
    width: "100%",
    borderRadius: 20,
  },
  noHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "96vh",
  },
  selectUserMessage: {
    fontWeight: 600,
    color: "#193b59",
    listStyle: "upper-roman",
    background: "#d5d3e37d",
    padding: "4px 15px",
    borderRadius: "15px",
    fontSize: 22,
  },
  selectUserIcon: {
    width: 60,
    height: 60,
    marginBottom: "12px",
    color: "#5e6e8b",
  },
  outMessage: {
    padding: "4px 3px",
    borderRadius: "10px",
    margin: "8px 0px",
    maxWidth: "500px",
    background: "#dfeeff",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
  inMessage: {
    padding: "4px 3px",
    borderRadius: "10px",
    margin: "8px 0px",
    maxWidth: "500px",
    background: "#e5e8eb82",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
  messagesContainer: {
    backgroundColor: "white",
    padding: 14,
    width: "100%",
    border: "1px solid #13678712",
    borderRadius: "15px",
  },
  date: {
    textAlign: "right",
    fontSize: 11,
    fontWeight: 500,
    color: "#6b6868",
  },
  respondsToContainer: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: "0px 7px",
    borderLeft: "2px solid #b9cae7",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    marginBottom: "5px",
    marginTop: 4,
  },
  writeMessageContainer: {
    height: "12vh",
    width: "100%",
    display: "grid",
    alignItems: "center",
  },
  blockAlertButton: {
    background: "#dbdbdbb5",
    borderRadius: 18,
    padding: "6px 20px",
  },
  blockAlert: {
    margin: "0 auto",
    padding: "6px 20px",
    background: "#ffdfdf",
    borderRadius: "13px",
    maxWidth: "fit-content",
    color: "#a50000",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
  fromUserName: {
    fontWeight: 700,
    fontSize: 11,
    marginLeft: "4px",
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
  textfieldWithRespondsTo: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      backgroundColor: "white",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
  textFieldRespondsToHeader: {
    padding: 4,
    border: "1px solid rgb(0 0 0 / 14%)",
    borderBottom: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    background: "white",
  },
  respondsToTextFieldText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "74vh",
  },
  disabledChatAlert: {
    margin: "0 auto",
    padding: "6px 20px",
    background: "#e5e5e5",
    borderRadius: "13px",
    maxWidth: "fit-content",
    color: "#393939",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  headerName: {
    fontSize: "16.5px",
    fontWeight: 500,
    color: "#3f3f3f",
    cursor: "pointer",
    "&:hover": {
      color: "#1b3c7b",
    },
  },
  memberHeader: {
    backgroundColor: "#efefef78",
    borderRadius: 5,
    padding: "2px 10px",
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 350,
    padding: "0px",
    maxHeight: 350,
    marginTop: 2,
  },
  groupDescription: {
    fontSize: 13,
    whiteSpace: "pre-line",
    textAlign: "justify",
    maxWidth: 350,
    wordBreak: "break-word",
    padding: "8px 6px",
  },
  adminChip: {
    height: 22,
    background: "rgb(161 225 193 / 31%)",
    color: "rgb(0, 99, 45)",
    fontWeight: 500,
    border: "1px solid #00894a40",
  },
  headerNameDisabled: {
    fontSize: "16.5px",
    fontWeight: 500,
    color: "#3f3f3f",
  },
  typingContainer: {
    fontStyle: "italic",
    fontSize: 12,
    fontWeight: 700,
    color: "#004f95",
  },
  loadingIconConv: {
    width: 26,
    height: 26,
    color: "#004cff",
  },
}));

var limit = 20;
const ChatGroupConversation = (props) => {
  const classes = useStyles();
  const {
    customHeight,
    selectedMessageID,
    setSelectedMessageID,
    getMessagesNeeded,
    setGetMessagesNeeded,
    selectedTab,
    selectedHeader,
    selectedHeaderID,
    headers,
    setHeaders,
    setSelectedHeader,
  } = props;
  const dispatch = useDispatch();
  const { totalUnreadChatMessages } = useSelector(
    (state) => state.totalUnreadChatMessages
  );
  const { generalTotalUnreadChatMessages } = useSelector(
    (state) => state.generalTotalUnreadChatMessages
  );
  const messagesContainerRef = useRef();
  const [focusedMessage, setFocusedMessage] = useState(null);
  const [isOpenNewGroupPopup, setIsOpenNewGroupPopup] = useState(false);
  const [isOpenChangeGroupAdmin, setIsOpenChangeGroupAdmin] = useState(false);
  const messagesPage = useRef(0);
  const [totalMessages, setTotalMessages] = useState(-1);
  const [selectionStart, setSelectionStart] = useState(-1);
  const [message, setMessage] = useState("");
  const [anchorElMenuOptions, setAnchorElMenuOptions] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [anchorElEmojiPopover, setAnchorElEmojiPopover] = useState(null);
  const emojiPopoverOpened = Boolean(anchorElEmojiPopover);
  const idEmojiPopoverOpen = emojiPopoverOpened ? "simple-popover" : undefined;
  const inputRef = useRef();
  const { authUser } = useSelector((state) => state.auth);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [respondsToMessage, setRespondsToMessage] = useState(null);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const openPopover = Boolean(anchorElPopover);
  const popoverID = openPopover ? "simple-popover" : undefined;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const { handleSubmit, reset, control, errors, setValue, watch } = useForm();
  const typing = useRef(false);
  const typingTimeoutRef = useRef(null);
  const [userTyping, setUserTyping] = useState(null);

  const sendMessage = async (fileObj = null) => {
    const uniqueId = `id_${new Date().getTime()}_${Math.floor(
      Math.random() * 1000
    )}`;
    var payload = {
      from_user_id: authUser.id,
      to_model_type: selectedHeader.recipient_model_type,
      to_model_id: selectedHeader.recipient_model_id,
      body: message,
      file_name:
        fileObj && fileObj.file && fileObj.file_name
          ? fileObj.file_name
          : "File",
      organization_id: authUser.organization_id,
      file: fileObj && fileObj.file ? fileObj.file : null,
      responds_to_message: respondsToMessage ? respondsToMessage.id : null,
    };

    setMessage("");

    var tempMessages = [
      {
        ...payload,
        ...{
          ...fileObj,
          ...{
            message_id: uniqueId,
            direction: "out",
            status: "pending",
            message_date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            view: 0,
            respondsTo: respondsToMessage ? { data: respondsToMessage } : null,
          },
        },
      },
      ...messages,
    ];

    setRespondsToMessage(null);
    setMessages([...tempMessages]);

    const { data } = await Api.sendChatMessage(payload);

    const index = findIndex(tempMessages, ["message_id", uniqueId]);
    const updatedEvent = {
      ...tempMessages[index],
      ...data.data,
    };

    let newMessages = [...tempMessages];
    newMessages[index] = updatedEvent;

    setMessages([...newMessages]);

    setTotalMessages(totalMessages + 1);

    if (selectedHeader.id) {
      const indexHeader = findIndex(headers, ["id", selectedHeader.id]);
      const updatedEventHeader = {
        ...headers[indexHeader],
        ...{ ...data.data.header.data, unread_messages: 0 },
      };

      let newHeaders = [...headers];
      newHeaders[indexHeader] = updatedEventHeader;
      newHeaders = newHeaders.sort((a, b) => {
        if (b.is_pinned - a.is_pinned !== 0) {
          return b.is_pinned - a.is_pinned;
        }

        if (a.is_pinned && b.is_pinned) {
          return new Date(b.pinned_at) - new Date(a.pinned_at);
        }

        return (
          new Date(b.conversation.data.last_message_date) -
          new Date(a.conversation.data.last_message_date)
        );
      });
      setHeaders([...newHeaders]);
    } else {
      let newHeaders = [
        {
          ...selectedHeader,
          ...{ ...data.data.header.data, unread_messages: 0 },
        },
        ...headers,
      ];
      newHeaders = newHeaders.sort((a, b) => {
        if (b.is_pinned - a.is_pinned !== 0) {
          return b.is_pinned - a.is_pinned;
        }

        if (a.is_pinned && b.is_pinned) {
          return new Date(b.pinned_at) - new Date(a.pinned_at);
        }

        return (
          new Date(b.conversation.data.last_message_date) -
          new Date(a.conversation.data.last_message_date)
        );
      });
      setHeaders([...newHeaders]);
    }

    setSelectedHeader({
      ...selectedHeader,
      ...{ ...data.data.header.data, unread_messages: 0 },
    });
  };

  const handleClickOpenPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const groupMembersOptions = () => {
    return selectedHeader &&
      selectedHeader.recipient &&
      selectedHeader.recipient.members
      ? selectedHeader.recipient.members
          .filter((u) => u.id != authUser.id)
          .map((u) => {
            return { id: u.id, title: u.fname + " " + u.lname };
          })
      : [];
  };

  const getMessages = async (messages, messageID = null) => {
    setGetMessagesNeeded(false);
    if (
      selectedHeader &&
      selectedHeader.id &&
      selectedHeader.recipient &&
      selectedHeader.recipient.members &&
      selectedHeader.recipient.members.find((r) => r.id == authUser.id)
    ) {
      setLoadingMessages(true);
      messagesPage.current = messagesPage.current + 1;
      const { data } = await Api.getChatConversationMessages(
        selectedHeader.conversation_id,
        selectedHeader.id,
        messageID ? messageID : "",
        messagesPage.current,
        limit
      );
      if (messagesPage.current == 1 && data.data.length > limit) {
        messagesPage.current = Math.ceil(data.data.length / limit);
      }
      if (data.length == 0) {
        setMessages([]);
        setTotalMessages(0);
        setLoadingMessages(false);
      } else {
        setMessages([...messages, ...data.data]);
        setTotalMessages(data.meta.pagination.total);
        setLoadingMessages(false);
      }
      setFocusedMessage(messageID);
      setSelectedMessageID(null);

      setLoadingMessages(false);
    } else {
      setMessages([]);
      setTotalMessages(0);
      setLoadingMessages(false);
    }
  };

  const pinChat = async () => {
    handleCloseMenuOptions();
    const { data } = await Api.updateChatHeader(selectedHeader.id, {
      is_pinned: selectedHeader.is_pinned ? false : true,
      pinned_at: selectedHeader.is_pinned
        ? null
        : moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedHeader({ ...selectedHeader, ...data.data });
    const index = findIndex(headers, ["id", selectedHeader.id]);
    const updatedEvent = {
      ...headers[index],
      ...data.data,
    };
    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }
      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }
      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
  };

  const exitGroup = async (payload = null) => {
    setConfirmDialogLoading(true);
    handleCloseMenuOptions();
    const { data } = await Api.exitChatGroup(
      payload,
      selectedHeader?.recipient?.id || null
    );

    setSelectedHeader({ ...selectedHeader, ...data.data });
    const index = findIndex(headers, ["id", selectedHeader.id]);
    const updatedEvent = {
      ...headers[index],
      ...data.data,
    };
    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }
      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }
      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
    setConfirmDialogLoading(false);
    closeChangeGroupAdmin();
    setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    getMessages([]);
  };

  const updateSelectionStart = () => {
    setSelectionStart(inputRef.current.selectionStart);
  };

  const clickToMessage = async (id) => {
    var messageFound = messages.find((msg) => msg.id == id) ? true : false;

    if (messageFound) {
      setFocusedMessage(id);
      scrollToMessage(id);
    } else {
      messagesPage.current = 0;
      await getMessages([], id);
      scrollToMessage(id);
    }
  };

  const renderRespondsTo = (message) => {
    return (
      <div
        className={classes.respondsToContainer}
        onClick={() => {
          setFocusedMessage(null);

          clickToMessage(message.id);
        }}
      >
        {message.file_url ? (
          message.file_type && message.file_type.includes("image") ? (
            <img
              style={{ maxWidth: 35, maxHeight: 35, marginRight: 12 }}
              src={message.file_url}
            />
          ) : message.file_type && message.file_type.includes("video") ? (
            <video
              style={{ maxWidth: 35, maxHeight: 35, marginRight: 12 }}
              src={message.file_url}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div>
          <div>
            <Typography style={{ fontWeight: 700, fontSize: 12 }}>
              {message.from_user_full_name}
            </Typography>
          </div>
          {message.file_url ? (
            message.file_type && message.file_type.includes("image") ? (
              <Typography style={{ color: "gray", fontStyle: "italic" }}>
                Sent an image
              </Typography>
            ) : message.file_type && message.file_type.includes("video") ? (
              <Typography style={{ color: "gray", fontStyle: "italic" }}>
                Sent a video
              </Typography>
            ) : (
              <div
                style={{
                  maxWidth: "fit-content",
                  padding: "4px 0px",

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Iconify
                  style={{ color: "#6d747b", marginRight: 4 }}
                  icon="iconamoon:file-fill"
                />
                {message.file_name}
              </div>
            )
          ) : (
            <Typography> {message.body} </Typography>
          )}
        </div>
      </div>
    );
  };

  const onEmojiClick = (event, emojiObject) => {
    handleTyping();
    const start = message.substr(0, selectionStart);
    const end = message.substr(selectionStart, message.length);

    setMessage(
      selectionStart >= 0
        ? start + emojiObject.emoji + end
        : message + emojiObject.emoji
    );

    setAnchorElEmojiPopover(null);
  };

  const updateMessage = (id, props) => {
    const index = findIndex(messages, ["id", id]);
    const updatedEvent = {
      ...messages[index],
      ...props,
    };

    let newMessages = [...messages];
    newMessages[index] = updatedEvent;

    setMessages([...newMessages]);
  };

  const handleTyping = () => {
    if (selectedHeader) {
      if (!typing.current) {
        Api.chatGroupUserTyping({
          conversation_id: selectedHeader.conversation_id,
          to_group_id: selectedHeader.recipient_model_id,
          typing: true,
        });
        typing.current = true;
      }

      if (typing.current && typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      if (typing) {
        typingTimeoutRef.current = setTimeout(() => {
          Api.chatGroupUserTyping({
            conversation_id: selectedHeader.conversation_id,
            to_group_id: selectedHeader.recipient_model_id,
            typing: false,
          });
          typing.current = false;
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (focusedMessage) {
      setTimeout(() => {
        setFocusedMessage(null);
      }, 2000);
    }
  }, [focusedMessage]);

  useEffect(() => {
    if (messages && focusedMessage) {
      scrollToMessage(focusedMessage);
    }
  }, [messages]);

  const scrollToMessage = (messageId) => {
    const container = messagesContainerRef.current;

    const element = container.querySelector(`#message-id-${messageId}`);

    if (element) {
      element.scrollIntoView({ block: "start", inline: "nearest" });
    }
  };

  const renderMessages = () => {
    return selectedHeader &&
      selectedHeader.recipient &&
      selectedHeader.recipient.members &&
      selectedHeader.recipient.members.find((r) => r.id == authUser.id) ? (
      messages.map((message) => {
        if (message.from_user_id == authUser.id) {
          var icon = { icon: null, color: null };

          if (message.view) {
            icon.icon = "ci:check-all";
            icon.color = "rgb(14 67 227)";
          } else {
            switch (message.status) {
              case "sent":
                icon.icon = "uil:check";
                icon.color = "rgb(107 110 123)";
                break;
              case "failed":
                icon.icon = "ic:round-error-outline";
                icon.color = "rgb(225 53 53)";
                break;
              default:
                icon.icon = "uil:clock";
                icon.color = "#5a5f75";
                break;
            }
          }

          return (
            <div
              id={"message-id-" + message.id}
              style={{
                padding: "0px 10px",
                backgroundColor:
                  focusedMessage && focusedMessage == message.id
                    ? "#d1dee72e"
                    : "#ffffff00",
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {/* <IconButton>
              <Iconify style={{ width: 20, height: 20 }} icon="lucide:reply" />
            </IconButton> */}
              <div
                style={{
                  maxWidth: "fit-content",
                  display: "flex",
                  alignItems: "center",
                }}
                onMouseOver={() => {
                  updateMessage(message.id, { mouseOver: true });
                }}
                onMouseLeave={() => {
                  updateMessage(message.id, { mouseOver: false });
                }}
              >
                {" "}
                {message.mouseOver ? (
                  <div style={{ display: "grid", textAlign: "center" }}>
                    {message.file_url ? (
                      <Tooltip title={"Open"}>
                        <span>
                          <a
                            href={message.file_url}
                            download={message.file_name}
                            target="_blank"
                          >
                            <Iconify
                              style={{
                                width: 20,
                                height: 20,
                                color: "#616161",
                              }}
                              icon="majesticons:open"
                            />
                          </a>
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    <Tooltip title={"Reply message "}>
                      <span>
                        <IconButton
                          style={{ padding: 5 }}
                          onClick={() => {
                            setRespondsToMessage(message);
                            if (inputRef.current) {
                              inputRef.current.focus();
                            }
                          }}
                        >
                          <Iconify
                            style={{ width: 20, height: 20 }}
                            icon="lucide:reply"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
                <div className={classes.outMessage}>
                  <Typography className={classes.fromUserName}>
                    {"You"}
                  </Typography>
                  {message.respondsTo && !isEmpty(message.respondsTo.data)
                    ? renderRespondsTo(message.respondsTo.data)
                    : ""}{" "}
                  {message.file_url && message.file_type ? (
                    <div>
                      {message.file_type.includes("image") ? (
                        <CardActionArea
                          style={{
                            height: 180,
                            background: "#393939",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setSelectedMessage(message);
                            setIsPreviewOpen(true);
                          }}
                        >
                          <img
                            style={{
                              maxWidth: 300,
                              maxHeight: 180,
                              padding: "5px 5px 0px 5px",
                            }}
                            src={message.file_url}
                          />
                        </CardActionArea>
                      ) : message.file_type.includes("video") ? (
                        <CardActionArea
                          style={{
                            height: 180,
                            background: "#393939",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setSelectedMessage(message);
                            setIsPreviewOpen(true);
                          }}
                        >
                          <video
                            style={{
                              maxHeight: "180px",
                              maxWidth: "300px",
                              padding: "5px 5px 0px 5px",
                            }}
                            controls
                          >
                            <source
                              src={message.file_url}
                              type={message.file_type}
                            ></source>
                          </video>
                        </CardActionArea>
                      ) : (
                        <Button
                          startIcon={
                            <Iconify
                              icon="iconamoon:file-fill"
                              style={{ color: "#595959" }}
                            />
                          }
                        >
                          <Typography>
                            {" "}
                            {message.file_name ? message.file_name : "File"}
                          </Typography>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <Typography
                      style={{
                        margin: "0px 4px",
                        paddingTop: 0,
                        fontSize: "14.4px",
                      }}
                    >
                      {message.body}
                    </Typography>
                  )}
                  <div
                    style={{
                      padding: "0px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Typography className={classes.date}>
                      {moment(message.message_date).format("MM/DD/YY hh:mm A")}
                    </Typography>
                    <Iconify
                      style={{
                        color: icon.color,
                        width: 17,
                        height: 17,
                        marginLeft: 3,
                      }}
                      icon={icon.icon}
                    />
                  </div>
                </div>
              </div>
              <Avatar
                style={{
                  backgroundColor: "rgb(164 174 185 / 30%)",
                  width: 28,
                  height: 28,
                  marginLeft: 8,
                }}
                src={selectedHeader?.user?.data?.avatar || null}
              >
                <Iconify
                  style={{ color: "#616a8d" }}
                  icon={
                    selectedHeader.user &&
                    selectedHeader.user.data &&
                    selectedHeader.user.data.gender == "F"
                      ? "formkit:avatarwoman"
                      : "formkit:avatarman"
                  }
                />
              </Avatar>
            </div>
          );
        } else {
          return (
            <div
              id={"message-id-" + message.id}
              style={{
                padding: "0px 10px",
                backgroundColor:
                  focusedMessage && focusedMessage == message.id
                    ? "#d1dee72e"
                    : "#ffffff00",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "rgb(164 174 185 / 30%)",
                  width: 28,
                  height: 28,
                  marginRight: 8,
                }}
                src={message?.fromUser?.data?.avatar || null}
              >
                <Iconify
                  style={{ color: "#616a8d" }}
                  icon={
                    message.fromUser &&
                    message.fromUser.data &&
                    message.fromUser.data.gender == "F"
                      ? "formkit:avatarwoman"
                      : "formkit:avatarman"
                  }
                />
              </Avatar>
              <div
                style={{
                  maxWidth: "fit-content",
                  display: "flex",
                  alignItems: "center",
                }}
                onMouseOver={() => {
                  updateMessage(message.id, { mouseOver: true });
                }}
                onMouseLeave={() => {
                  updateMessage(message.id, { mouseOver: false });
                }}
              >
                <div className={classes.inMessage}>
                  <Typography className={classes.fromUserName}>
                    {message.from_user_full_name}
                  </Typography>
                  {message.respondsTo && !isEmpty(message.respondsTo.data)
                    ? renderRespondsTo(message.respondsTo.data)
                    : ""}
                  {message.file_url && message.file_type ? (
                    <div>
                      {message.file_type.includes("image") ? (
                        <CardActionArea
                          style={{
                            height: 180,
                            background: "#393939",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setSelectedMessage(message);
                            setIsPreviewOpen(true);
                          }}
                        >
                          {" "}
                          <img
                            style={{
                              maxWidth: 300,
                              maxHeight: 180,
                              padding: "5px 5px 0px 5px",
                            }}
                            src={message.file_url}
                          />
                        </CardActionArea>
                      ) : message.file_type.includes("video") ? (
                        <CardActionArea
                          style={{
                            height: 180,
                            background: "#393939",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setSelectedMessage(message);
                            setIsPreviewOpen(true);
                          }}
                        >
                          {" "}
                          <video
                            style={{
                              maxHeight: "180px",
                              maxWidth: "300px",
                              padding: "5px 5px 0px 5px",
                            }}
                            controls
                          >
                            <source
                              src={message.file_url}
                              type={message.file_type}
                            ></source>
                          </video>
                        </CardActionArea>
                      ) : (
                        <Button
                          startIcon={
                            <Iconify
                              icon="iconamoon:file-fill"
                              style={{ color: "#595959" }}
                            />
                          }
                        >
                          <Typography>
                            {" "}
                            {message.file_name ? message.file_name : "File"}
                          </Typography>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <Typography
                      style={{
                        margin: "0px 4px",
                        paddingTop: 0,
                        fontSize: "14.4px",
                      }}
                    >
                      {message.body}
                    </Typography>
                  )}
                  <div
                    style={{
                      padding: "0px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Typography className={classes.date}>
                      {moment(message.message_date).format("MM/DD/YY hh:mm A")}
                    </Typography>
                  </div>
                </div>
                {message.mouseOver ? (
                  <div style={{ display: "grid", textAlign: "center" }}>
                    {message.file_url ? (
                      <Tooltip title={"Open"}>
                        <span>
                          <a
                            href={message.file_url}
                            download={message.file_name}
                            target="_blank"
                          >
                            <Iconify
                              style={{
                                width: 20,
                                height: 20,
                                color: "#616161",
                              }}
                              icon="majesticons:open"
                            />
                          </a>
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    <Tooltip title={"Reply message "}>
                      <span>
                        <IconButton
                          style={{ padding: 5 }}
                          onClick={() => {
                            setRespondsToMessage(message);
                            if (inputRef.current) {
                              inputRef.current.focus();
                            }
                          }}
                        >
                          <Iconify
                            style={{ width: 20, height: 20 }}
                            icon="lucide:reply"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        }
      })
    ) : (
      <></>
    );
  };

  const handleClickMenuOptions = (e) => {
    setAnchorElMenuOptions(e.currentTarget);
  };

  const handleCloseMenuOptions = () => {
    setAnchorElMenuOptions(null);
  };

  const onSubmit = (payload) => {
    exitGroup(payload);
  };

  useEffect(() => {
    if (getMessagesNeeded) {
      setSelectedMessageID(null);
      setMessages([]);
      messagesPage.current = 0;
      getMessages([]);
      setMessage("");
      setRespondsToMessage(null);
    }
  }, [getMessagesNeeded]);

  useEffect(() => {
    if (selectedMessageID) {
      setMessages([]);
      messagesPage.current = 0;
      getMessages([], selectedMessageID);
      setMessage("");
      setRespondsToMessage(null);
    }
  }, [selectedMessageID]);

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const closeNewGroupPopup = () => {
    setIsOpenNewGroupPopup(false);
  };

  const closeChangeGroupAdmin = () => {
    setIsOpenChangeGroupAdmin(false);
  };

  const onUpdateGroup = (header) => {
    const index = findIndex(headers, ["id", header.id]);
    const updatedEvent = {
      ...headers[index],
      ...header,
    };
    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }
      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }
      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);

    if (selectedHeader) {
      setSelectedHeader({ ...updatedEvent });
    }

    closeNewGroupPopup();
  };

  const deleteGroup = async () => {
    if (
      selectedHeader &&
      selectedHeader.recipient &&
      selectedHeader.recipient.id
    ) {
      const { data } = await Api.deleteChatGroup(selectedHeader.recipient.id);

      onUpdateGroup({ ...data.data });

      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
    }
  };

  const typingListener = (data) => {
    if (data.user_id != authUser.id) {
      if (
        selectedHeader &&
        selectedHeader.conversation_id == data.conversation_id &&
        data.typing
      ) {
        setUserTyping({ name: data.user_name, id: data.user_id });
      } else {
        setUserTyping(null);
      }
    }
  };

  const messageReceivedListener = (data) => {
    if (data.from_user_id != authUser.id) {
      if (
        selectedHeader &&
        selectedHeader.id &&
        data.conversation &&
        data.conversation.data &&
        data.conversation.data.id == selectedHeader.conversation_id
      ) {
        Api.updateChatHeader(selectedHeader.id, { unread_messages: 0 });
        setSelectedHeader({
          ...selectedHeader,
          ...{ ...selectedHeader, ...{ conversation: data.conversation } },
        });

        if (data.message && data.message.data) {
          setMessages([
            { ...data.message.data, ...{ direction: "in" } },
            ...messages,
          ]);
        }
      }

      if (
        data.conversation &&
        data.conversation.data &&
        data.conversation.data.id
      ) {
        const index = findIndex(headers, [
          "conversation_id",
          data.conversation.data.id,
        ]);
        const updatedEvent = {
          ...headers[index],
          ...{
            ...headers[index],
            ...{
              conversation: data.conversation,
              unread_messages: headers[index].unread_messages + 1,
            },
          },
        };

        if (
          selectedHeader &&
          selectedHeader.id &&
          data.conversation &&
          data.conversation.data &&
          data.conversation.data.id == selectedHeader.conversation_id
        ) {
          if (updatedEvent?.unread_messages || false) {
            const indexTotal = findIndex(totalUnreadChatMessages, [
              "model_type",
              "App\\Models\\ChatGroup",
            ]);
            const newTotal =
              totalUnreadChatMessages[indexTotal].total -
              updatedEvent.unread_messages;
            const updatedEventTotal = {
              ...totalUnreadChatMessages[indexTotal],
              ...{
                total: newTotal < 0 ? 0 : newTotal,
              },
            };

            let newTotals = [...totalUnreadChatMessages];
            newTotals[indexTotal] = updatedEventTotal;

            var generalTotal =
              generalTotalUnreadChatMessages -
              (updatedEvent?.unread_messages || 0);
            dispatch(
              updateGeneralTotalUnreadChatMessages(
                generalTotal > 0? generalTotal : 0
              )
            );

            dispatch(updateTotalUnreadChatMessages(newTotals));
          }

          updatedEvent["unread_messages"] = 0;
        }

        let newHeaders = [...headers];
        newHeaders[index] = updatedEvent;
        newHeaders = newHeaders.sort((a, b) => {
          if (b.is_pinned - a.is_pinned !== 0) {
            return b.is_pinned - a.is_pinned;
          }

          if (a.is_pinned && b.is_pinned) {
            return new Date(b.pinned_at) - new Date(a.pinned_at);
          }

          return (
            new Date(b.conversation.data.last_message_date) -
            new Date(a.conversation.data.last_message_date)
          );
        });
        setHeaders([...newHeaders]);
      }
    }
  };

  const groupUpatedReceived = (data) => {
    if (data.header && data.header.data) {
      var header = data.header.data;
      if (
        selectedHeader &&
        selectedHeader.id &&
        header.id == selectedHeader.id
      ) {
        if (
          selectedHeader.recipient &&
          selectedHeader.recipient.members &&
          selectedHeader.recipient.members.find((u) => u.id != authUser.id) &&
          header.recipient &&
          header.recipient.members &&
          header.recipient.members.find((u) => u.id == authUser.id)
        ) {
          setSelectedHeader({ ...selectedHeader, ...header });

          setGetMessagesNeeded(true);
        } else {
          setSelectedHeader({ ...selectedHeader, ...header });
        }
      }

      const index = findIndex(headers, ["id", header.id]);

      let newHeaders = [];
      if (index >= 0) {
        const updatedEvent = {
          ...headers[index],
          ...header,
        };
        newHeaders = [...headers];
        newHeaders[index] = updatedEvent;
      } else {
        newHeaders = [header, ...headers];
      }

      newHeaders = newHeaders.sort((a, b) => {
        if (b.is_pinned - a.is_pinned !== 0) {
          return b.is_pinned - a.is_pinned;
        }
        if (a.is_pinned && b.is_pinned) {
          return new Date(b.pinned_at) - new Date(a.pinned_at);
        }
        return (
          new Date(b.conversation.data.last_message_date) -
          new Date(a.conversation.data.last_message_date)
        );
      });
      setHeaders([...newHeaders]);
    }
  };

  useEffect(() => {
    if (
      authUser &&
      authUser.organization &&
      authUser.organization.data &&
      authUser.organization.data.enable_websockets &&
      window.Echo
    ) {
      headers.forEach((header) => {
        window.Echo.channel(
          "user_to_group_chat." + header.recipient_model_id
        ).listen(".user_typing", typingListener);
        window.Echo.channel(
          "user_to_group_chat." + header.recipient_model_id
        ).listen(".on_message", messageReceivedListener);
      });
      window.Echo.channel("user_to_group_chat_by_user." + authUser.id).listen(
        ".group_updated",
        groupUpatedReceived
      );

      return () => {
        headers.forEach((header) => {
          window.Echo.channel(
            "user_to_group_chat." + header.recipient_model_id
          ).stopListening(".user_typing", typingListener);
          window.Echo.channel(
            "user_to_group_chat." + header.recipient_model_id
          ).stopListening(".on_message", messageReceivedListener);
        });
        window.Echo.channel(
          "user_to_group_chat_by_user." + authUser.id
        ).stopListening(".group_updated", groupUpatedReceived);
      };
    }
  }, [
    window.Echo,
    authUser,
    typingListener,
    messageReceivedListener,
    groupUpatedReceived,
  ]);

  return (
    <div>
      {!selectedHeader ? (
        <div className={classes.noHeaderContainer}>
          <div className={classes.noHeaderMessage}>
            <div style={{ textAlign: "center" }}>
              <Iconify
                className={classes.selectUserIcon}
                icon={"streamline:chat-two-bubbles-oval-solid"}
              />
              <Typography className={classes.selectUserMessage}>
                Select a group to start chatting
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "7px", position: "relative" }}>
          {loadingMessages ? (
            <div
              style={{
                position: "absolute",
                top: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 8,
                paddingTop: 12,
                zIndex: 100,
                width: "100%",
              }}
            >
              <div
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 2px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 37,
                  padding: "10px",
                }}
              >
                {" "}
                <Iconify
                  className={classes.loadingIconConv}
                  style
                  icon="eos-icons:bubble-loading"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              background: "rgb(251, 251, 251)",
              display: "flex",
              alignItems: "center",
              padding: 4,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "rgb(164 174 185 / 30%)",
                  width: 36,
                  height: 36,
                  marginRight: 10,
                  marginLeft: 6,
                }}
                src={selectedHeader?.recipient?.avatar || null}
              >
                <Iconify
                  style={{ color: "#616a8d" }}
                  icon={
                    selectedHeader.recipient_deleted
                      ? "mingcute:ghost-fill"
                      : "heroicons:user-group-20-solid"
                  }
                />
              </Avatar>
              <div>
                {selectedHeader && selectedHeader.recipient_deleted ? (
                  <Typography className={classes.headerNameDisabled}>
                    {"Deleted group"}
                  </Typography>
                ) : (
                  <Typography
                    onClick={(e) => {
                      handleClickOpenPopover(e);
                    }}
                    className={classes.headerName}
                  >
                    {selectedHeader?.recipient?.name || "Unknown group"}
                  </Typography>
                )}

                {userTyping ? (
                  <div className={classes.typingContainer}>
                    {userTyping.name + " is typing"}
                  </div>
                ) : (
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      color: "rgb(129 129 129)",
                    }}
                  >
                    {(selectedHeader?.recipient?.total_members || 0) +
                      " members"}
                  </Typography>
                )}
              </div>
            </div>
            {selectedHeader && selectedHeader.id ? (
              <IconButton onClick={handleClickMenuOptions}>
                <Iconify
                  className={classes.mediumIcon}
                  icon="mingcute:more-2-fill"
                />
              </IconButton>
            ) : (
              ""
            )}
          </div>
          <div
            ref={messagesContainerRef}
            className={classes.messagesContainer}
            style={{
              minHeight: !(
                selectedHeader &&
                selectedHeader.recipient &&
                selectedHeader.recipient.members &&
                selectedHeader.recipient.members.find(
                  (r) => r.id == authUser.id
                )
              )
                ? customHeight
                  ? (83 - customHeight).toString() + "vh"
                  : "83vh"
                : respondsToMessage
                ? customHeight
                  ? (71 - customHeight).toString() + "vh"
                  : "71vh"
                : customHeight
                ? (79 - customHeight).toString() + "vh"
                : "79vh",
              maxHeight: !(
                selectedHeader &&
                selectedHeader.recipient &&
                selectedHeader.recipient.members &&
                selectedHeader.recipient.members.find(
                  (r) => r.id == authUser.id
                )
              )
                ? customHeight
                  ? (83 - customHeight).toString() + "vh"
                  : "83vh"
                : respondsToMessage
                ? customHeight
                  ? (71 - customHeight).toString() + "vh"
                  : "71vh"
                : customHeight
                ? (79 - customHeight).toString() + "vh"
                : "79vh",
              display: "flex",
              flexDirection: "column-reverse",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            id={"scrollableDiv1"}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={() => {
                if (messages.length != 0) {
                  getMessages(messages);
                }
              }}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              inverse={true} //
              initialScrollY={0}
              hasMore={totalMessages != messages.length ? true : false}
              scrollableTarget={"scrollableDiv1"}
            >
              {renderMessages()}
            </InfiniteScroll>
          </div>
          {!(
            selectedHeader.recipient &&
            selectedHeader.recipient.members &&
            selectedHeader.recipient.members.find((r) => r.id == authUser.id)
          ) ||
          (selectedHeader && selectedHeader.recipient_deleted) ? (
            <div style={{ textAlign: "center", marginTop: 12 }}>
              <div className={classes.disabledChatAlert}>
                <Iconify
                  style={{ width: 19, height: 19, marginRight: 5 }}
                  icon="fluent:chat-off-20-filled"
                />
                {selectedHeader && selectedHeader.recipient_deleted
                  ? "This group has been deleted. You can't send messages on it"
                  : "You are no longer a member of this group. You can't send messages on it"}
              </div>
            </div>
          ) : (
            <div className={classes.writeMessageContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Send file">
                  <span>
                    <Controls.DropzoneDialogIcon
                      formControlStyle={{ margin: 0 }}
                      className={classes.pictureActionButton}
                      filesLimit={1}
                      formats={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "video/mp4",
                      ]}
                      icon={
                        <Iconify
                          style={{ color: "#4f536b" }}
                          icon="material-symbols:upload-rounded"
                        />
                      }
                      color="secondary"
                      onChange={async (files) => {
                        sendMessage({
                          file: await fileToBase64(files[0]),
                          file_url: URL.createObjectURL(files[0]),
                          file_name: files[0].name,
                          file_type: files[0].type,
                        });
                      }}
                    />
                  </span>
                </Tooltip>
                <div style={{ width: "100%", margin: "18px 0px" }}>
                  {respondsToMessage ? (
                    <div className={classes.textFieldRespondsToHeader}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 6px",
                          borderLeft: "2px solid #61616180",
                          margin: "4px 0px",
                        }}
                      >
                        {respondsToMessage.file_url ? (
                          respondsToMessage.file_type &&
                          respondsToMessage.file_type.includes("image") ? (
                            <img
                              style={{
                                maxWidth: 35,
                                maxHeight: 35,
                                marginRight: 12,
                              }}
                              src={respondsToMessage.file_url}
                            />
                          ) : respondsToMessage.file_type &&
                            respondsToMessage.file_type.includes("video") ? (
                            <video
                              style={{
                                maxWidth: 35,
                                maxHeight: 35,
                                marginRight: 12,
                              }}
                              src={respondsToMessage.file_url}
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                style={{
                                  backgroundColor: "rgb(164 174 185 / 30%)",
                                  width: 15,
                                  height: 15,
                                  marginRight: 8,
                                }}
                                src={
                                  respondsToMessage?.fromUser?.data?.avatar ||
                                  null
                                }
                              >
                                <Iconify
                                  style={{ color: "#616a8d" }}
                                  icon={
                                    respondsToMessage.fromUser &&
                                    respondsToMessage.fromUser.data &&
                                    respondsToMessage.fromUser.data.gender ==
                                      "F"
                                      ? "formkit:avatarwoman"
                                      : "formkit:avatarman"
                                  }
                                />
                              </Avatar>
                              <Typography
                                style={{ fontWeight: 700, fontSize: 12 }}
                              >
                                {respondsToMessage.from_user_full_name}
                              </Typography>
                            </div>
                            <IconButton
                              onClick={() => {
                                setRespondsToMessage(null);
                              }}
                              style={{ padding: 6 }}
                            >
                              <Iconify
                                style={{ width: 20, height: 20 }}
                                icon="majesticons:close"
                              />
                            </IconButton>
                          </div>

                          <div>
                            {respondsToMessage.file_url ? (
                              respondsToMessage.file_type &&
                              respondsToMessage.file_type.includes("image") ? (
                                <Typography
                                  style={{ color: "gray", fontStyle: "italic" }}
                                >
                                  Sent an image
                                </Typography>
                              ) : respondsToMessage.file_type &&
                                respondsToMessage.file_type.includes(
                                  "video"
                                ) ? (
                                <Typography
                                  style={{ color: "gray", fontStyle: "italic" }}
                                >
                                  Sent a video
                                </Typography>
                              ) : (
                                <div
                                  style={{
                                    maxWidth: "fit-content",
                                    padding: "2px 10px",
                                    background: "#e0e1e5c7",
                                    borderRadius: "11px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Iconify
                                    style={{ color: "#6d747b", marginRight: 4 }}
                                    icon="iconamoon:file-fill"
                                  />
                                  {respondsToMessage.file_name}
                                </div>
                              )
                            ) : (
                              <Typography
                                className={classes.respondsToTextFieldText}
                              >
                                {" "}
                                {respondsToMessage.body}{" "}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Controls.Textarea
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (!isEmpty(e.target.value)) {
                          if (e.target.value.trim()) {
                            sendMessage(null);
                          }
                        }
                      } else if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();

                        setMessage((prevValue) => {
                          const start = prevValue.substr(0, selectionStart);
                          const end = prevValue.substr(
                            selectionStart,
                            prevValue.length
                          );

                          return selectionStart >= 0
                            ? start + "\n" + end
                            : prevValue + "\n";
                        });
                      }
                    }}
                    className={
                      respondsToMessage
                        ? classes.textfieldWithRespondsTo
                        : classes.textfield
                    }
                    inputRef={inputRef}
                    onSelect={updateSelectionStart}
                    value={message}
                    onChange={(e) => {
                      handleTyping();
                      setMessage(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Add emoji">
                          <span>
                            <IconButton
                              onClick={(e) => {
                                setAnchorElEmojiPopover(e.currentTarget);
                              }}
                            >
                              <Iconify
                                style={{ color: "#4f536b" }}
                                icon="fluent:emoji-24-regular"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    }}
                    rows={3}
                    fullWidth
                    placeholder="Type something..."
                  />{" "}
                </div>
                <Tooltip title="Send message">
                  <span>
                    <IconButton
                      onClick={sendMessage}
                      disabled={!message || !trim(message)}
                      style={{
                        color:
                          !message || !trim(message)
                            ? "rgb(180 183 185)"
                            : "#424d8d",
                      }}
                    >
                      <Iconify icon="ion:send" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorElMenuOptions}
        open={Boolean(anchorElMenuOptions)}
        onClose={handleCloseMenuOptions}
      >
        <MenuItem onClick={pinChat}>
          <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
            <Iconify
              style={{ width: 18, height: 18 }}
              icon={
                selectedHeader && selectedHeader.is_pinned
                  ? "fluent:pin-off-24-filled"
                  : "fluent:pin-12-filled"
              }
            />
          </ListItemIcon>
          <Typography>
            {selectedHeader && selectedHeader.is_pinned
              ? "Unpin chat"
              : "Pin chat"}
          </Typography>
        </MenuItem>
        {selectedHeader &&
        selectedHeader.recipient &&
        selectedHeader.recipient.members &&
        selectedHeader.recipient.members.find((r) => r.id == authUser.id) ? (
          <>
            {" "}
            {selectedHeader &&
            selectedHeader.recipient &&
            selectedHeader.recipient.admin_id == authUser.id ? (
              <MenuItem
                onClick={() => {
                  setIsOpenNewGroupPopup(true);
                  handleCloseMenuOptions();
                }}
              >
                <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
                  <Iconify
                    style={{ width: 18, height: 18 }}
                    icon={"tabler:pencil"}
                  />
                </ListItemIcon>
                <Typography>Edit group</Typography>
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem
              onClick={() => {
                if (
                  selectedHeader &&
                  selectedHeader.recipient &&
                  selectedHeader.recipient.admin_id == authUser.id
                ) {
                  setIsOpenChangeGroupAdmin(true);
                } else {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to leave this group?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      exitGroup();
                    },
                  });
                }
              }}
              style={{ color: "rgb(175 0 0)" }}
            >
              <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
                <Iconify
                  style={{ width: 18, height: 18, color: "rgb(175 0 0)" }}
                  icon={"mingcute:exit-line"}
                />
              </ListItemIcon>
              <Typography>{"Exit group"}</Typography>
            </MenuItem>
            {selectedHeader &&
            selectedHeader.recipient &&
            selectedHeader.recipient.admin_id == authUser.id ? (
              <MenuItem
                style={{ color: "rgb(175 0 0)" }}
                onClick={() => {
                  setAnchorElMenuOptions(null);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this group?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      deleteGroup();
                    },
                  });
                }}
              >
                <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
                  <Iconify
                    style={{ width: 18, height: 18, color: "rgb(175 0 0)" }}
                    icon={"ic:round-delete"}
                  />
                </ListItemIcon>
                <Typography>{"Delete group"}</Typography>
              </MenuItem>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Menu>

      <Popover
        id={idEmojiPopoverOpen}
        open={emojiPopoverOpened}
        anchorEl={anchorElEmojiPopover}
        onClose={() => setAnchorElEmojiPopover(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Picker onEmojiClick={onEmojiClick} />
      </Popover>

      <Popup
        fullWidth={true}
        title={
          selectedMessage && selectedMessage.file_name
            ? selectedMessage.file_name
            : "Message"
        }
        openPopup={isPreviewOpen}
        onClose={() => {
          setSelectedMessage(null);
          setIsPreviewOpen(false);
        }}
        maxWidth={"md"}
      >
        <ScrollBar style={{ maxHeight: 740 }}>
          {selectedMessage && selectedMessage.file_url ? (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                padding: 15,
                boxShadow: "0 0 14px 0 rgba(53,64,82,.09)",
              }}
            >
              {selectedMessage.file_type.includes("image") ? (
                <div>
                  <img
                    style={{ maxWidth: 600, maxHeight: 600 }}
                    src={selectedMessage.file_url}
                  />
                </div>
              ) : selectedMessage.file_type.includes("video") ? (
                <video
                  style={{
                    maxHeight: "600px",
                    maxWidth: "600px",
                    padding: "5px 5px 0px 5px",
                  }}
                  controls
                >
                  <source
                    src={selectedMessage.file_url}
                    type={selectedMessage.file_type}
                  ></source>
                </video>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </ScrollBar>
      </Popup>

      <Popover
        id={popoverID}
        open={openPopover}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "12px", minWidth: 382 }}>
          <div style={{ textAlign: "center", padding: "0px 15px" }}>
            <Avatar
              style={{
                backgroundColor: "rgb(164 174 185 / 30%)",
                width: 90,
                height: 90,
                margin: "0 auto",
              }}
              src={selectedHeader?.recipient?.avatar || null}
            >
              <Iconify
                style={{ color: "#616a8d", width: 32, height: 32 }}
                icon={"heroicons:user-group-20-solid"}
              />
            </Avatar>
            <Typography
              onClick={(e) => {
                handleClickOpenPopover(e);
              }}
              style={{ fontWeight: 600, fontSize: 18, marginTop: 7 }}
            >
              {selectedHeader?.recipient?.name}
            </Typography>
          </div>
          <div className={classes.groupDescription}>
            {selectedHeader?.recipient?.description || ""}
          </div>
          <div style={{ marginTop: 4 }}>
            <div className={classes.memberHeader}>
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                {(selectedHeader?.recipient?.total_members || 0) + " members"}
              </Typography>
            </div>

            <List className={classes.list}>
              {selectedHeader &&
              selectedHeader.recipient &&
              selectedHeader.recipient.members
                ? selectedHeader.recipient.members
                    .map((user) => {
                      return {
                        ...user,
                        ...{
                          is_admin:
                            selectedHeader.recipient &&
                            selectedHeader.recipient.admin_id == user.id,
                        },
                      };
                    })
                    .sort((a, b) => {
                      if (b.is_admin - a.is_admin !== 0) {
                        return b.is_admin - a.is_admin;
                      }
                    })
                    .map((user) => {
                      return (
                        <ListItem style={{ padding: "3px 6px" }}>
                          <ListItemAvatar>
                            <Avatar
                              style={{
                                backgroundColor: "rgb(164 174 185 / 30%)",
                                width: 38,
                                height: 38,
                              }}
                              src={user?.avatar || null}
                            >
                              <Iconify
                                style={{ color: "#616a8d" }}
                                icon={
                                  user.gender == "F"
                                    ? "formkit:avatarwoman"
                                    : "formkit:avatarman"
                                }
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontWeight: 600 },
                            }}
                            style={{ padding: 0 }}
                            primary={
                              user.id == authUser.id
                                ? "You"
                                : user.fname + " " + user.lname
                            }
                            secondary={user.position.replace(/^\w/, (c) =>
                              c.toUpperCase()
                            )}
                          />
                          {user.is_admin ? (
                            <ListItemSecondaryAction>
                              <Chip
                                className={classes.adminChip}
                                label={"Admin"}
                              />
                            </ListItemSecondaryAction>
                          ) : (
                            ""
                          )}
                        </ListItem>
                      );
                    })
                : ""}
            </List>
          </div>
        </div>
      </Popover>

      <Popup
        fullWidth={true}
        title={"New group"}
        openPopup={isOpenNewGroupPopup}
        onClose={closeNewGroupPopup}
        maxWidth={"sm"}
      >
        <NewChatGroup
          selectedHeader={selectedHeader}
          onGroupCreated={onUpdateGroup}
          groupForEdit={selectedHeader?.recipient || null}
        />
      </Popup>

      <Popup
        fullWidth={true}
        title={"Change group admin"}
        openPopup={isOpenChangeGroupAdmin}
        onClose={closeChangeGroupAdmin}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Typography style={{ fontWeight: 500, marginBottom: 4 }}>
            Please select a new admin for the group:
          </Typography>
          <Controller
            name={`admin_id`}
            control={control}
            fullWidth
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.Select
                fullWidth
                options={groupMembersOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
            rules={{ required: "Admin is required" }}
          />
          {errors.admin_id && (
            <p className={classes.error}>{errors.admin_id.message}</p>
          )}
          <div style={{ textAlign: "end", marginTop: "8px" }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={confirmDialogLoading}
              startIcon={
                <Iconify
                  style={{ width: 20, height: 20 }}
                  icon={"mingcute:exit-line"}
                />
              }
            >
              {confirmDialogLoading ? "Leaving the group..." : "Exit group"}
            </Button>
          </div>
        </form>
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />
    </div>
  );
};

export default ChatGroupConversation;
