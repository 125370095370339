import { SvgIcon, Typography, makeStyles } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Iconify from "../Iconify";

const TipRoot = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "rgb(243, 244, 246)",
  borderRadius: 12,
  display: "flex",
  padding: 8,
  margin: "7px 0px",
}));

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  included_margin: {
    width: "100%",
    margin: "25px 0px",
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  typography: {
    color: "#595959",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
    "& span": {
      fontWeight: 700,
      mr: 0.5,
    },
  },
}));

export const Tip = (props) => {
  const {
    message,
    title = "",
    iconColor = "black",
    backgroundColor = "rgb(243, 244, 246)",
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <TipRoot {...rest} style={{ backgroundColor: backgroundColor }}>
      <SvgIcon color="action" style={{ marginRight: 4, color: iconColor }}>
        <Iconify
          style={{ width: 20, height: 20 }}
          icon="solar:lightbulb-broken"
        />
      </SvgIcon>
      <Typography className={classes.typography} variant="caption">
        <span style={{ marginRight: 2 }}>{title}</span> {message}
      </Typography>
    </TipRoot>
  );
};
