import { useMemo, useState } from "react";
import GenericTable from "../components/GenericTable";
import { Badge, Chip, Typography, makeStyles } from "@material-ui/core";
import RefundTicketForm from "../components/RefundTicketForm";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import RestoreTwoToneIcon from "@material-ui/icons/RestoreTwoTone";
import GenericFilter from "../components/GenericFilter";
import RefundsFilters from "../components/filters/RefundsFilters";
import { isInteger } from "lodash";
import moment from "moment";
import { ReactQueryDevtools } from "react-query/devtools";
import { AttachFile } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { green, red } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import Api from "../../../lib/api";

const RefundTickets = () => {
  const columns = useMemo(
    () => [
      {
        field: "created_at",
        title: "Start Date",
        render: (row) => (
          <Typography>{moment(row.created_at).format("MM/DD/yyyy")}</Typography>
        ),
      },
      {
        field: "is_chargeback",
        title: "ChargeBack",
        render: (row) =>
          row.is_chargeback ? (
            <CheckIcon style={{ color: green[500] }} />
          ) : (
            <ClearIcon style={{ color: red[700] }} />
          ),
      },
      { field: "subject", title: "Subject" },
      {
        field: "contact_id",
        title: "Contact",
        render: (row) => (
          <Typography>{row?.invoice?.contact?.full_name}</Typography>
        ),
      },
      {
        field: "priority_id",
        title: "Priority",
        render: (row) => (
          <Typography
            style={{
              color: row.priority?.color,
            }}
          >
            {row.priority?.name}
          </Typography>
        ),
      },
      {
        field: "reasons_id",
        title: "Reason",
        render: (row) => <Typography> {row.reasons?.name}</Typography>,
      },
      {
        field: "amount",
        title: "Amount",
        render: (row) => {
          return `$${row.amount?.toFixed(2)}`;
        },
      },
      {
        field: "tags",
        title: "Tags",
        render: (row) => {
          return row?.tags?.map((t) => (
            <Chip
              label={t?.name?.en}
              size="small"
              style={{
                fontSize: 10,
              }}
            />
          ));
        },
      },
      {
        field: "wf_state_id",
        title: "State",
        render: (row) => (
          <Chip
            label={row?.get_state?.name}
            style={{
              backgroundColor: row?.get_state?.color,
            }}
          />
        ),
      },
      {
        field: "media",
        title: "Files",
        render: (row) =>
          (row?.media_count ?? 0) > 0 ? (
            <Badge badgeContent={row.media_count ?? 0} color="primary">
              <AttachFile />
            </Badge>
          ) : null,
      },
      {
        field: "user_assigned_id",
        title: "Assigned To",
        render: (row) => {
          return `${row?.user_assigned?.dropdown_name || ""}`;
        },
      },
      {
        field: "user_created_id",
        title: "Created By",
        render: (row) => {
          return `${row?.user_created?.dropdown_name || ""}`;
        },
      },
    ],
    []
  );

  const model = "rfs_tickets";

  const useStyles = makeStyles((theme) => ({
    headerIcon: {
      color: theme.palette.primary.main,
    },
  }));
  const classes = useStyles();
  const { tags } = useSelector((state) => state.tags);
  const [filesToUploadLater, setFilesToUploadLater] = useState([]);

  const handleSubmit = (data) => {
    data.invoice_items =
      data.invoice_items?.map((v) => (isInteger(v) ? v : v.id)) ?? [];
    data.body = data.body ? data.body : "";
    data.tags =
      data.tags && data.tags.length > 0
        ? data.tags.map((t) => t?.name?.en || t)
        : [];
    let _filesToUploadLater = data?.files?.filter(
      (f) => f?.data instanceof File
    );
    setFilesToUploadLater(_filesToUploadLater);
    let filesToUpdate = data?.files?.filter((f) => !(f?.data instanceof File));
    data.media = filesToUpdate;
    delete data.files;

    return data;
  };

  const handlePostUpdateInsert = async (data) => {
    let updatedModel = data?.data?.data;
    if (
      updatedModel?.id &&
      filesToUploadLater &&
      filesToUploadLater.length > 0
    ) {
      let promises = [];
      filesToUploadLater.forEach((f) => {
        promises.push(
          Api.fileUpload(f.data, {
            model: "rfs_tickets",
            model_id: updatedModel.id,
            custom_properties: JSON.stringify(f.custom_properties),
          })
        );
      });
      return Promise.all(promises);
      console.log(filesToUploadLater);
    }
  };

  return (
    <>
      <Helmet>
        <title>Refund tickets</title>
      </Helmet>
      <PageHeader
        title="Refund tickets"
        subTitle=""
        icon={
          <RestoreTwoToneIcon fontSize="large" className={classes.headerIcon} />
        }
      />
      <GenericFilter Form={RefundsFilters}>
        <GenericTable
          defaultOrderBy="created_at"
          defaultOrderByIndex={0}
          defaultOrder="desc"
          model={model}
          columns={columns}
          FormData={RefundTicketForm}
          tableTitle="Refund tickets"
          include={[
            "priority",
            "reasons",
            "invoice",
            "invoice.contact",
            "getState",
            "invoice_items",
            "tags",
            "user_data_history",
            "user_created",
            "user_assigned",
            "media_count",
          ]}
          beforeSubmit={handleSubmit}
          afterUpdateInsert={handlePostUpdateInsert}
        />

        <ReactQueryDevtools initialIsOpen={false} />
      </GenericFilter>
    </>
  );
};

export default RefundTickets;
