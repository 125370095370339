import {
	FormControl,
	Grid,
	LinearProgress,
	makeStyles,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useMemo, useRef, useState } from "react";
import Select from "../../../components/controls/Select";
import { useQuery } from "react-query";
import Api from "../../../lib/api";
import FroalaEditor from "react-froala-wysiwyg";
import Button from "../../../components/controls/Button";
import { useSelector } from "react-redux";
import DateTimePicker from "../../../components/controls/DateTimePicker";

const useStyles = makeStyles((them) => ({
	labelFocused: {
		left: 0,
		fontSize: 12,
		color: "#999",
		transition: "all 0.2s ease",
		transform: "translateY(0px) translateX(0px)",
	},
	label: {
		transform: "translateY(10px) translateX(10px)",
		zIndex: 5000,
		fontSize: 12,
		color: "#999",
	},
}));

const ContactInfoHistory = ({
	element = null,
	onCancel,
	onUpdate,
	updating,
}) => {
	const {
		handleSubmit,
		control,
		errors,
		watch,
		formState: { isDirty },
    } = useForm({
        defaultValues: element ?? {}
    });
	const onSubmit = (data) => {
		onUpdate && onUpdate(data);
	};

	const classes = useStyles();

	//contact via
	const { data: contactVia, isLoading: contactViaLoading } = useQuery(
		["rfs_user_contact_via"],
		() =>
			Api.genericQueryModel("rfs_user_contact_via", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) =>
				data?.data?.data?.map((v) => ({
					...v,
					title: v.via,
				})),
		}
    );
    
    const { authUser } = useSelector((state) => state.auth);

	const organization_id = useMemo(() => {
		return authUser?.organization_id;
	}, [authUser]);

	const froala = useRef();
	const froala2 = useRef();

	const editorConfig = {
		key: process.env.REACT_APP_FROALA_KEY,
		zIndex: 2501,
		attribution: false,
		dragInline: false,
		useClasses: false,
		htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["style"],
        pluginsEnabled: [
			"align",
			"charCounter",
			"codeBeautifier",
			"codeView",
			"colors",
			"draggable",
			"emoticons",
			"entities",
			"fontFamily",
			"fontSize",
			"fullscreen",
			"help",
			"image",
			"inlineClass",
			"inlineStyle",
			"link",
			"lists",
			"paragraphFormat",
			"paragraphStyle",
			"print",
			"quote",
			"save",
			"specialCharacters",
			"table",
			"url",
			"video",
			"wordPaste",
		],
		events: {
			initialized: function () {
				const body = watch("description");
				froala.current.editor.html.set(body);
				if (isUpdate) {
					froala.current.editor.edit.off();
				}
			},
			focus: function () {
				setEditorFocused(true);
			},
			blur: function () {
				setEditorFocused(false);
            },
            "image.beforeUpload": function (files) {
				const data = new FormData();
				data.append("fileUrl", files[0]);

				Api.uploadImage(organization_id, data)
					.then((res) => {
						this.image.insert(
							res.data.data,
							null,
							null,
							this.image.get()
						);
					})
					.catch((err) => {
						console.log(err);
					});
				return false;
			},
		},
	};

	const editorConfig2 = {
		key: process.env.REACT_APP_FROALA_KEY,
		zIndex: 2501,
		attribution: false,
		dragInline: false,
		useClasses: false,
		htmlRemoveTags: ["script"],
        pasteDeniedAttrs: ["style"],
        pluginsEnabled: [
			"align",
			"charCounter",
			"codeBeautifier",
			"codeView",
			"colors",
			"draggable",
			"emoticons",
			"entities",
			"fontFamily",
			"fontSize",
			"fullscreen",
			"help",
			"image",
			"inlineClass",
			"inlineStyle",
			"link",
			"lists",
			"paragraphFormat",
			"paragraphStyle",
			"print",
			"quote",
			"save",
			"specialCharacters",
			"table",
			"url",
			"video",
			"wordPaste",
		],
		events: {
			initialized: function () {
				const body = watch("response");
				froala2.current.editor.html.set(body);
				if (isUpdate) {
					froala2.current.editor.edit.off();
				}
			},
			focus: function () {
				setEditor2Focused(true);
			},
			blur: function () {
				setEditor2Focused(false);
            },
            "image.beforeUpload": function (files) {
				const data = new FormData();
				data.append("fileUrl", files[0]);

				Api.uploadImage(organization_id, data)
					.then((res) => {
						this.image.insert(
							res.data.data,
							null,
							null,
							this.image.get()
						);
					})
					.catch((err) => {
						console.log(err);
					});
				return false;
			},
		},
	};

	const [editorFocused, setEditorFocused] = useState(false);
	const [editorFocused2, setEditor2Focused] = useState(false);

	const isUpdate = useMemo(() => {
		return Boolean(element?.id);
	}, [element]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Controller
						name="date"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Date required",
							},
						}}
						render={({ onChange, onBlur, value }) => (
							<DateTimePicker
								fullWidth
								label="Date"
								format="DD/MM/YYYY HH:mm"
								onChange={onChange}
								value={value && value != "" ? value : null}
								error={errors.date}
								helperText={errors.date?.message}
								disabled={isUpdate}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="contact_via_id"
						control={control}
						defaultValue={-1}
						rules={{
							validate: (value) =>
								(value && value != -1) || "Via required",
						}}
						render={({ onChange, onBlur, value }) => (
							<Select
								label={"Contact via"}
								variant="outlined"
								disabled={isUpdate}
								onChange={onChange}
								value={
									value && value != ""
										? value
										: element?.contact_via_id ?? -1
								}
								loading={contactViaLoading}
								allowEmpty={true}
								emptyLabel="Select one"
								options={contactVia}
								error={!!errors.contact_via_id}
								helperText={errors.contact_via_id?.message}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<FormControl>
						<label
							htmlFor="froala-description"
							className={
								editorFocused
									? classes.labelFocused
									: classes.label
							}
						>
							Description
						</label>
						<Controller
							name="description"
							defaultValue={""}
							control={control}
							render={({ onChange, onBlur, value }) => (
								<FroalaEditor
									id="froala-description"
									config={editorConfig}
									ref={froala} //create a ref to the instance
									tag="textarea"
									model={value}
									onModelChange={onChange}
									disabled={isUpdate}
								/>
							)}
						/>
					</FormControl>
				</Grid>

				<Grid item>
					<FormControl>
						<label
							htmlFor="froala-response"
							className={
								editorFocused2
									? classes.labelFocused
									: classes.label
							}
						>
							Response
						</label>
						<Controller
							name="response"
							defaultValue={""}
							control={control}
							render={({ onChange, onBlur, value }) => (
								<FroalaEditor
									id="froala-response"
									config={editorConfig2}
									ref={froala2} //create a ref to the instance
									tag="textarea"
									model={value}
									onModelChange={onChange}
									disabled={isUpdate}
								/>
							)}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Grid
				item
				style={{
					textAlign: "right",
					marginTop: 10,
				}}
			>
				<Button
					text="Save"
					disabled={!isDirty || isUpdate}
					onClick={handleSubmit(onSubmit)}
				/>
				<Button text="Cancel" onClick={onCancel} />
			</Grid>
			{updating && <LinearProgress />}
		</form>
	);
};
export default ContactInfoHistory;
