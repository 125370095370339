import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, find } from "lodash";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "./UserProcedureHeatmap.css";
import { Paper, Card as MuiCard, makeStyles } from "@material-ui/core";

import Moment from "moment";
import { extendMoment } from "moment-range";

import Api from "../lib/api";

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const moment = extendMoment(Moment);

function UserProcedureHeatmap3(props) {
  const { user, setDate, disableDates2, user_id = null } = props;
  const { t, i18n } = useTranslation("users");
  const classes = useStyles();

  // useEffect(()=>{
  //   console.log("HEATMAP USER: ", user)
  // }, [user])

  const userLimit = user.daily_limit ? parseInt(user.daily_limit) : 10;

  const [totalRecords, setTotalRecords] = useState([]);

  const startDate = moment();
  const endDate = moment().add(12, "months");
  const range = moment.range(startDate, endDate);
  const arrayOfDates = Array.from(range.by("days"));

  const getDailyTotal = (date) => {
    const value = find(totalRecords, { date: date });
    if (!value) return 0;

    return value.total;
  };
  const getData = () => {
    return arrayOfDates.map((date) => {
      return {
        date: date.format("YYYY-MM-DD"),
        count: getDailyTotal(date.format("YYYY-MM-DD")),
      };
    });
  };
  const getTooltipDataAttrs = (value) => {
    // Temporary hack around null value.date issue
    if (!value || !value.date) {
      return null;
    }
    // Configuration for react-tooltip
    return {
      "data-tip": `${value.date} has count: ${value.count}`,
    };
  };

  const getRemoteData = async () => {
    const { data } = await Api.getUserRecordProductsGroupByDate(
      user_id ? user_id : user.id
    );
    setTotalRecords(data);
  };

  useEffect(() => {
    getRemoteData();
  }, [user, user_id]);

  return (
    <Paper className={classes.pageContent}>
      <CalendarHeatmap
        startDate={startDate.format("YYYY-MM-DD")}
        endDate={endDate.format("YYYY-MM-DD")}
        showMonthLabels={true}
        showWeekdayLabels={false}
        weekdayLabels={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
        titleForValue={(value) => {
          return `${value.date}`;
          //enable this tooltip for x permission
          //return `${value.date} has ${value.count}`;
        }}
        tooltipDataAttrs={getTooltipDataAttrs}
        values={getData()}
        onClick={(value) => {
          if (setDate) {
            if (!disableDates2(value.date)) {
              setDate(value.date);
            }
          }
        }}
        classForValue={(value) => {
          if (disableDates2(value.date)) {
            return "color-none";
          }
          return `color-scale-${value.count} color-p-${Math.round(
            (value.count / userLimit) * 100
          )}`;
        }}
      />
      {/*
        <ul className="heatMapLeyend">
        <li className="white">0-2 procedure</li>
        <li className="green">3-5 procedure</li>
        <li className="yellow">6-9 procedure</li>
        <li className="orange">10-12 procedure</li>
        <li className="red">13-15 procedure</li>
      </ul>
      */}
    </Paper>
  );
}

export default UserProcedureHeatmap3;
