import React from "react";
import "react-calendar-heatmap/dist/styles.css";
import "./UserProcedureHeatmap.css";
import { makeStyles, Button, Tooltip } from "@material-ui/core";

import moment from "moment";
import { DayPicker } from "react-day-picker";

const useStyles = makeStyles((theme) => ({
  dayPickerContainer: {
    "& .rdp-months .rdp-table": {
      width: "100%",
    },
    "& .rdp-months .rdp-head_cell": {
      fontWeight: 600,
      color: "#676f7b",
      fontSize: "75%",
    },
  },
  day: {
    "&:hover , &$active": {
      border: "1px solid black",
    },
  },
}));

function HeatMapCalendarMonth({
  minDate = moment().add(1, "days"),
  month,
  date = null,
  width = "130px",
  showDay = false,
  dayColor = null,
  isDateDisabled = null,
  setDate = null,
  startDate = moment(Date.now()),
}) {
  const classes = useStyles();

  const getTextColor = (backgroundColor) => {
    // Convert the background color to an RGB object
    const rgb = backgroundColor.match(/\d+/g);

    // Calculate brightness using the luminosity formula
    const brightness =
      (parseInt(rgb[0]) * 299 +
        parseInt(rgb[1]) * 587 +
        parseInt(rgb[2]) * 114) /
      1000;

    // Return 'white' if brightness is less than 128, otherwise, return 'black'
    return brightness < 128 ? "white" : "black";
  };

  return (
    <div style={{ maxWidth: width, minWidth: width }}>
      <DayPicker
        className={classes.dayPickerContainer}
        mode="single"
        disableNavigation
        month={month}
        components={{
          Caption: (props) => {
            return (
              <div
                style={{
                  textAlign: "center",
                  padding: "6px 2px",
                  fontWeight: 600,
                  fontSize: 13,
                }}
              >
                {moment(props.displayMonth).format("MMMM YYYY")}
              </div>
            );
          },

          Day: (props) => {
            var dayDisabled =
              moment(props.date).month() != moment(month).month() ||
              isDateDisabled(moment(props.date).format("YYYY-MM-DD")) ||
              moment(moment(props.date).format("YYYY-MM-DD")).isBefore(moment(moment(minDate).format("YYYY-MM-DD")))
                ? true
                : false;

            var backgroundColor =
              date && moment(date).isSame(moment(props.date), "day")
                ? "rgb(131 173 253)"
                : moment(props.date).month() != moment(month).month() ||
                  moment(moment(props.date).format("YYYY-MM-DD")).isBefore(moment(moment(minDate).format("YYYY-MM-DD")))
                ? "rgb(221 221 221)"
                : dayColor
                ? dayColor(moment(props.date))
                : "rgb(159,204,221)";

            return moment(props.date).month() != moment(month).month() ? (
              ""
            ) : showDay ? (
              <Button
                {...props}
                style={{
                  backgroundColor: backgroundColor,
                  color: getTextColor(backgroundColor),
                  borderRadius: 5,
                  minWidth: `calc(${width}  / 8)`,
                  width: `calc(${width}  / 8)`,
                  height: `calc(${width} / 8)`,
                  maxWidth: `calc(${width}  / 8)`,
                  fontSize: "70%",
                }}
                className={classes.day}
                disabled={dayDisabled}
                onClick={() => {
                  if (setDate && !dayDisabled) {
                    setDate(props.date);
                  }
                }}
              >
                {moment(props.date).format("DD")}
              </Button>
            ) : (
              <Tooltip title={moment(props.date).format("MM/DD/YYYY")}>
                <span>
                  <Button
                    {...props}
                    style={{
                      color: getTextColor(backgroundColor),
                      backgroundColor: backgroundColor,
                      borderRadius: 5,
                      minWidth: `calc(${width}  / 8)`,
                      width: `calc(${width}  / 8)`,
                      height: `calc(${width} / 8)`,
                      maxWidth: `calc(${width}  / 8)`,
                    }}
                    className={classes.day}
                    disabled={dayDisabled}
                    onClick={() => {
                      if (setDate && !dayDisabled) {
                        setDate(props.date);
                      }
                    }}
                  />
                </span>
              </Tooltip>
            );
          },
        }}
        showOutsideDays={false}
      />
    </div>
  );
}

export default HeatMapCalendarMonth;
