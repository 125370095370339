import { CssBaseline, Paper, ThemeProvider } from "@material-ui/core";
import { createMuiTheme as createTheme } from "@material-ui/core/styles";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { isWidthUp } from "@material-ui/core/withWidth";
import CheckInHeader from "../pages/appointmentsnewcheckin/checkInHeader";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #FFF;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: #fff;
  padding: 0px 20px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const themes = {
  pinkTheme: createTheme({
    palette: {
      background: {
        default: "#ffc6cc3b", // Ejemplo de color de fondo para el tema 1
      },
      checkInLogo: "rgb(110 131 149)",
      primary: {
        main: "rgb(93 103 143)",
      },
      secondary: {
        main: "rgb(213 136 136)",
      },
    },
  }),
  defaultTheme: createTheme({
    palette: {
      background: {
        default: "rgb(248, 248, 248)", // Ejemplo de color de fondo para el tema 1
      },
      checkInLogo: "rgb(94 167 117)",

      primary: {
        main: "rgb(94 167 117)",
      },
      secondary: {
        main: "rgb(120 140 191)",
      },
    },
  }),
};
const CheckInLayout = ({ children, routes, width }) => {
  const theme =
    themes[
      process.env.REACT_APP_CHECKINTHEME
        ? process.env.REACT_APP_CHECKINTHEME
        : "defaultTheme"
    ];
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <ThemeProvider theme={theme}>
          <MainContent
            style={{
              padding: 0,
              backgroundColor: theme.palette.background.default,
            }}
            p={isWidthUp("lg", width) ? 10 : 5}
          >
            <CheckInHeader />
            {children}
          </MainContent>
        </ThemeProvider>
      </AppContent>
    </Root>
  );
  return null;
};

export default CheckInLayout;
