import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Iconify from "./Iconify";



const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "60vh",
    textAlign: "center",
  },
  errorMessage: {
    marginTop: theme.spacing(8),
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60vh",
  },
  spinner: {
    color: "#42a5f5", 
    overflow: 'hidden',
    borderRadius: "100%", 
    backgroundColor: "transparent",
  },
}));

export default function CameraHD({ onCapture, ...props }) {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [errorType, setErrorType] = useState("");
  const [loading, setLoading] = useState(true);

  

  function handleTakePhoto(dataUri) {

    const byteString = atob(dataUri.split(",")[1]);
    const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], "photo.jpg", { type: mimeString });

    setImage(dataUri);
   
    onCapture(dataUri, file);
  }

  function handleCameraError(error) {
    setErrorType(error.name || "UNKNOWN_ERROR");
  }

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  const errorMessages = {
    NotAllowedError: {
      icon: "pepicons-print:camera-circle-off",
      text: "Camera access denied. Please allow camera access in your browser settings.",
    },
    NotFoundError: {
      icon: "pepicons-print:camera-circle-off",
      text: "No camera device found. Please connect a camera and try again.",
    },
    NotReadableError: {
      icon: "pepicons-print:camera-circle-off",
      text: "Unable to access your camera. Please check if it's already in use by another application.",
    },
    UNKNOWN_ERROR: {
      icon: "pepicons-print:camera-circle-off",
      text: "An unknown error occurred. Please try again.",
    },
  };

  if (errorType) {
    const { icon, text } =
      errorMessages[errorType] || errorMessages.UNKNOWN_ERROR;
    return (
      <div className={classes.errorContainer}>
        <Iconify
          icon={icon}
          width={250}
          height={250}
          style={{ color: "#ef5350" }}
        />
        <div className={classes.errorMessage}>
          <Typography variant="h6">{text}</Typography>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress
          className={classes.spinner}
          size={80}
          thickness={3.6}
        />
      </div>
    );
  }

  return (
    <div>
      {image ? (
        <Paper variant="outlined">
          <img src={image} className={classes.image} alt="Captured" />
        </Paper>
      ) : (
        <Camera
          onTakePhoto={handleTakePhoto}
          onCameraError={handleCameraError}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ width: 1920, height: 1080 }}
          imageType={IMAGE_TYPES.JPG}
          imageCompression={0.97}
          isMaxResolution
          isImageMirror={false}
          isSilentMode={false}
          isDisplayStartCameraError
          isFullscreen={false}
          sizeFactor={1}
        />
      )}
      
    </div>
  );
}
