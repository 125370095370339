import MaterialTable, { MTableHeader } from "material-table";
import Api from "../../../lib/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Popup from "../../../components/Popup";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../components/controls/Button";
import React, { useEffect, useMemo, useState } from "react";
import {
  DialogActions,
  LinearProgress,
  MuiThemeProvider,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { useFilterContext } from "./GenericFilter";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const GenericTable = ({
  model,
  columns,
  FormData = null,
  beforeSubmit,
  fieldId = "id",
  tableTitle = "Table",
  include = null,
  userCanAdd = true,
  userCanEdit = true,
  defaultOrderBy = null,
  defaultOrder = "asc",
  defaultOrderByIndex = null,
  afterUpdateInsert = null,
}) => {
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [orderByIndex, setOrderByIndex] = useState(defaultOrderByIndex);
  const [order, setOrder] = useState(defaultOrder);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [itemToEdit, setItemToEdit] = useState(null); // item data when editing

  const queryClient = useQueryClient();

  const CustomTableHeader = (props) => {
    return (
      <MTableHeader {...props} orderBy={orderByIndex} orderDirection={order} />
    );
  };

  const filtersPure = useFilterContext(); //filters if parent is filter table
  useEffect(() => {
    setPage(0);
  }, [filtersPure]);

  //normalize filters
  const filtersNormalized = useMemo(() => {
    if (filtersPure) {
      let res = Object.keys(filtersPure).reduce((prev, curr) => {
        if (
          (filtersPure[curr] != undefined &&
            filtersPure[curr] != null &&
            !isEmpty(filtersPure[curr])) ||
          (typeof filtersPure[curr] === "number" && filtersPure[curr] != -1)
        ) {
          return {
            ...prev,
            [curr.replace("__", ".")]: filtersPure[curr],
          };
        }
        return prev;
      }, {});
      // console.log("filtersNormalized", res);
      return res;
    }
    return {};
  }, [filtersPure]);

  useEffect(() => {
    if (Object.keys(filtersNormalized).length > 0) {
      refetchGeneric();
    }
  }, [filtersNormalized]);

  //methods for getting list
  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchGeneric,
  } = useQuery(
    [model, page, pageSize, orderBy, order, include, filtersNormalized],
    async () =>
      Api.genericQueryModel(model, {
        page,
        per_page: pageSize,
        order,
        orderBy,
        with: include,
        filters: filtersNormalized,
      }),
    {
      select: (data) => data?.data,
      staleTime: Infinity,
    }
  );

  const snackMethods = useSnackbar();

  //methods for updating
  const {
    data: updateValue,
    isLoading: updateIsLoading,
    mutate: updateModel,
  } = useMutation(({ id, data }) => Api.genericUpdateModel(model, id, data), {
    onSuccess: async (data) => {
      // let d = data?.data?.data;
      afterUpdateInsert && (await afterUpdateInsert(data));
      refetchGeneric();
      setOpenDialog(false);
      setItemToEdit(null);
    },
    onError: (error) => {
      setTimeout(() => {
        snackMethods &&
          snackMethods.enqueueSnackbar(
            "An error occurred: " + error?.data?.message ?? "Unknow",
            {
              variant: "error",
            }
          );
      }, 1000);
    },
  });

  //methods for inserting
  const {
    data: insertValue,
    isLoading: insertIsLoading,
    mutate: insertModel,
  } = useMutation((data) => Api.genericInsertModel(model, data), {
    onSuccess: async (data) => {
      afterUpdateInsert && (await afterUpdateInsert(data));
      refetchGeneric();
      setOpenDialog(false);
      setItemToEdit(null);
    },
    onError: (error) => {
      setTimeout(() => {
        snackMethods &&
          snackMethods.enqueueSnackbar(
            "An error occurred: " + error?.data?.message ?? "Unknow",
            {
              variant: "error",
            }
          );
      }, 1000);
    },
  });

  const methods = useForm();

  const handleEdit = (event, row) => {
    methods.reset(); //reset all values
    setDialogTitle("Edit");
    setOpenDialog(true);
    setItemToEdit(row);
  };

  const handleNew = () => {
    methods.reset();
    setDialogTitle("New");
    setItemToEdit({});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItemToEdit(null);
  };

  useEffect(() => {
    //update values after has opened
    if (openDialog) {
      setTimeout(() => {
        Object.entries(itemToEdit).forEach(([key, value]) => {
          methods.setValue(key, value);
        });
      });
    }
  }, [openDialog, itemToEdit]);

  const { authUser } = useSelector((state) => state.auth);

  const permissions = useMemo(() => {
    return authUser?.permissions?.data?.map((p) => p.name) ?? [];
  }, [authUser]);

  // console.log(permissions)

  const actions = [
    {
      icon: "add_circle",
      iconProps: {
        color: "primary",
        style: {
          fontSize: "40px",
        },
      },
      tooltip: "Add",
      isFreeAction: true,
      onClick: handleNew,
    },
    {
      icon: "refresh",
      iconProps: {
        color: "primary",
        style: {
          fontSize: "40px",
        },
      },
      tooltip: "Refresh",
      isFreeAction: true,
      onClick: refetchGeneric,
    },
  ];

  const handleSubmitEditInsert = (data) => {
    let d = data;
    if (beforeSubmit) {
      d = beforeSubmit(data);
    }
    if (d == null) {
      return;
    }
    if (Object.hasOwn(itemToEdit, fieldId)) {
      // model has id or key. is to update
      updateModel({ id: itemToEdit[fieldId], data: d });
    } else {
      //model has not id. is for inserting
      insertModel(d);
    }
  };

  const updatingOrCreating = useMemo(
    () => updateIsLoading || insertIsLoading,
    [updateIsLoading, insertIsLoading]
  );

  const handleOrderChange = (orderBy, order) => {
    if (orderBy == -1) {
      setOrderBy(null);
      setOrder("asc");
      setOrderByIndex(orderBy);
    } else {
      let column_name =
        columns.length > orderBy ? columns[orderBy].field : null;
      if (column_name) {
        setOrder(order);
        setOrderBy(column_name);
        setOrderByIndex(orderBy);
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    headerIcon: {
      color: theme.palette.primary.main,
    },
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
    },
    Toolbar: {
      justifyContent: "space-between",
    },
    searchInput: {
      width: "50%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
    totalValue: {
      position: "absolute",
      zIndex: 10,
      top: "205px",
      left: "220px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    table: {
      marginTop: theme.spacing(3),
      "& thead th": {
        fontWeight: "600",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
      },
      "& tbody td": {
        fontWeight: "300",
      },
      "& tbody tr:hover": {
        backgroundColor: "#fffbf2",
        cursor: "pointer",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Paper className={classes.pageContent}>
      <div stickyHeader aria-label="sticky table" className={classes.table}>
        <MuiThemeProvider>
          <MaterialTable
            components={{
              Header: CustomTableHeader,
            }}
            data={data?.data ?? []}
            page={data?.current_page ? data?.current_page - 1 : 0}
            totalCount={data?.total}
            columns={columns}
            title={tableTitle}
            options={{
              emptyRowsWhenPaging: false,
              pageSize,
              pageSizeOptions: [pageSize],
              loadingType: "linear",
              sorting: true,
              search: false,
            }}
            orderBy={orderByIndex}
            isLoading={isLoading || isRefetching}
            onChangePage={(p) => {
              setPage(p + 1);
            }}
            onRowClick={handleEdit}
            onOrderChange={(orderBy, order) =>
              handleOrderChange(orderBy, order)
            }
            actions={actions}
          />
        </MuiThemeProvider>
      </div>

      <Popup
        openPopup={openDialog}
        title={dialogTitle}
        onClose={() => setOpenDialog(false)}
        isfullWidth={true}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmitEditInsert)}>
            <FormData
              {...{
                objectData: itemToEdit,
                refetch: refetchGeneric,
                userId: authUser?.id,
                permissions: permissions,
              }}
            />
            <DialogActions>
              <Button
                text="Save"
                onClick={methods.handleSubmit(handleSubmitEditInsert)}
                disabled={!methods.formState.isDirty || updatingOrCreating}
              />
              <Button
                text="Close"
                onClick={handleCloseDialog}
                disabled={updatingOrCreating}
              />
            </DialogActions>
            {updatingOrCreating && <LinearProgress />}
          </form>
        </FormProvider>
      </Popup>
    </Paper>
  );
};

export default React.memo(GenericTable);
