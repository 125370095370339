import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { isEmpty, find, map } from "lodash";
import Controls from "../../components/controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function RecordProductCForm(props) {
  const { saveRP, UpdateRP, items, itemstoAdd, item, userCanEditCost } = props;
  console.error("resultdata", items, itemstoAdd);
  const { handleSubmit, reset, control, errors, setValue } = useForm();
  const [cost, setCost] = useState(item ? item.amount : 0);

  const classes = useStyles();

  const getItemOptions = () => {
    console.error("resultdata", item, itemstoAdd, items);
    let notemp = [];
    map(itemstoAdd, (c, key) => {
      if (!find(items, { id: c.id })) {
        notemp.push(c);
      }
    });
    if (item && !isEmpty(itemstoAdd)) {
      let selectedItem = find(itemstoAdd, { id: item.id });

      notemp.push(selectedItem);
    }
    return notemp.map((itemN) => {
      return {
        id: itemN.id,
        title: itemN.name,
        cost: itemN.cost,
      };
    });
  };

  const onSubmit = (data) => {
    const fData = {
      id: data.item,
      cost: data.cost,
    };
    if (item) {
      UpdateRP(fData);
    } else {
      saveRP(fData);
    }

    reset();
  };

  useEffect(() => {
    getItemOptions();
  }, [items, itemstoAdd]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={8}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Controller
            name="item"
            control={control}
            defaultValue={item ? item.id : ""}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Item"
                options={getItemOptions()}
                disabled={item}
                onChange={(newValue) => {
                  const newValueItem = find(itemstoAdd, {
                    id: newValue.target.value,
                  });
                  onChange(newValue.target.value);
                  setValue("cost", newValueItem.cost, {
                    shouldValidate: true,
                  });
                }}
                value={value}
              />
            )}
            rules={{ required: "Item is required" }}
          />
          {errors.item && <p className="error">{errors.item.message}</p>}
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Controller
            name={"cost"}
            control={control}
            as={
              <Controls.InputMoney
                disabled={!userCanEditCost}
                label="Cost"
                fullWidth={"true"}
                defaultValue={item ? item.amount : 0}
              />
            }
            rules={{ required: "cost is required" }}
          />
          {errors.item && <p className="error">{errors.item.message}</p>}
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <div>
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
