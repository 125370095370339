import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  invoicesReports: [],
};

const invoicesReportsSlice = createSlice({
  name: "invoicesReports",
  initialState,
  reducers: {
    getInvoicesReports: (state) => {
      state.loading = true;
    },
    getInvoicesReportsSuccess: (state, { payload }) => {
      state.invoicesReports = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getInvoicesReportsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getInvoicesReports,
  getInvoicesReportsSuccess,
  getInvoicesReportsFailure,
} = invoicesReportsSlice.actions;

export const invoicesReportsSelector = (state) => state.reports;
export default invoicesReportsSlice.reducer;

export function fetchInvoicesReportsService(orgId) {
  return async (dispatch) => {
    dispatch(getInvoicesReports());

    try {
      const response = await Api.getInvoicesReport(orgId);
      dispatch(getInvoicesReportsSuccess(response.data));
    } catch (error) {
      dispatch(getInvoicesReportsFailure(error));
    }
  };
}
