import { makeStyles, Step, StepButton, Stepper, Grid, Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

const useStyle = makeStyles((theme) => ({
    step_disabled: {
        color: 'gray',
    },
    stepper: {
        padding: "5px",
        marginTop: "10px",
        marginBottom: "5px",
        "& .MuiButtonBase-root": {
            padding: "20px",
            border: "1px",
            justifyContent:"start",
        },
        "& .MuiStepLabel-label": {
            textAlign: "left",
        }

        
    },
    myComponent: {
        "& .MuiStepper-root": {
            padding: 0
        }
    },
}));

const SteeperStates = ({ currentstate, nextStates, changeStep, showall, showbasedorder, readOnly=false }) => {
    const classes = useStyle();

    const [activeStep, setActiveStep] = useState(currentstate ? 0 : -1);

    const handleStep = (state) => {
        if (changeStep) {
            changeStep(state);
        }
    }

    useEffect(() => {
        if (showall && currentstate != null) {
            let act = nextStates.findIndex((e) => e.id == currentstate.id);
            setActiveStep(act);
        }
    }, [])

    if (currentstate === null && (nextStates === null || nextStates.length === 0)) {
        return null;
    }

    const StepIcon = (props) => {
        if (props.state.enable) {
            return (<PlayArrowIcon style={{ color: props.state.color }} className={props?.disabled ? classes.step_disabled : ''} />)
        }
        else {
            if (props.current) {
                return (
                    <FiberManualRecordRoundedIcon
                        style={{ color: props.state.color }}
                        className={props?.disabled ? classes.step_disabled : ""}
                    />
                );
            } else {
                return (<HighlightOffIcon className={classes.step_disabled} />)
            }
        }
    }

    if (showall) {
        return (
            <Stepper
                nonLinear
                activeStep={activeStep}
                className={classes.stepper}
            >
                <Grid container spacing={4}>
                    {nextStates &&
                        nextStates.map((stateto, index) => (
                            <Grid
                                item
                                xs={6}
                                md={3}
                                justify="flex-start"
                                key={stateto.code}
                            >
                                <Step completed={false}>
                                    <StepButton
                                        disabled={readOnly}
                                        color="inherit"
                                        onClick={() =>
                                            index == activeStep
                                                ? null
                                                : handleStep(stateto)
                                        }
                                        icon={
                                            <StepIcon
                                                state={stateto}
                                                current={index == activeStep}
                                            />
                                        }
                                    >
                                        {stateto.name}
                                    </StepButton>
                                </Step>
                            </Grid>
                        ))}
                </Grid>
            </Stepper>
        );
    } else {
        let _tempnextStates = showbasedorder 
        ? [...nextStates,currentstate].filter((e)=>e!=null).sort((a,b)=>a.order<b.order?-1:1)
        : [currentstate,...nextStates].filter((e)=>e!=null);

        return (
            <Stepper
                nonLinear
                activeStep={activeStep}
                className={classes.stepper}
            >
                <Grid container spacing="4">
                    {_tempnextStates &&
                        _tempnextStates.map((stateto, index) => (
                            <Grid item xs={6} md={3} key={stateto.code}>
                                <Step completed={false}>
                                    <StepButton
                                        disabled={readOnly}
                                        color="inherit"
                                        onClick={() => handleStep(stateto)}
                                        icon={
                                            <StepIcon
                                                state={stateto}
                                                current={
                                                    currentstate != null &&
                                                    currentstate.id ==
                                                        stateto.id
                                                }
                                            />
                                        }
                                    >
                                        {stateto.name}
                                    </StepButton>
                                </Step>
                            </Grid>
                        ))}
                </Grid>
            </Stepper>
        );
    }

}

export default SteeperStates;