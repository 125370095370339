import { motion } from "framer-motion";
import { Button, Typography, makeStyles } from "@material-ui/core";
import Iconify from "./Iconify";

const useStyles = makeStyles((theme) => ({
  refreshButton: {
    color: "#29425b",
    backgroundColor: "#beccd9",
    "&:hover": {
      background: "#a0b2c3",
    },
  },
  okButton: {
    color: "#34516d",
    border: "1px solid #7089a1",
    "&:hover": {
      background: "#d8dde1",
    },
  },
}));

export default function SystemUpdatedNotification(props, { url }) {
  const classes = useStyles();
  const { message = null, setSystemUpdatedNotification, custom } = props;
  return (
    <>
      <div
        style={{
          zIndex: 999999,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#f7f7f7",
          borderRadius: 10,
          padding: 24,
        }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <Iconify
              style={{ width: "48px", height: "48px", color: "#4d6181" }}
              icon="ic:round-browser-updated"
            />
            <Typography
              sx={{ whiteSpace: "pre-line" }}
              style={{ fontSize: "22px", fontWeight: 600 }}
            >
              The system has been updated!
            </Typography>
            <Typography
              variant="h1"
              sx={{ whiteSpace: "pre-line" }}
              style={{ fontSize: "16px", marginTop: 20, color: "#4d6181" }}
            >
              *Please refresh the page to avoid errors and get the new changes.
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: 18,
            }}
          >
            <Button
              className={classes.refreshButton}
              startIcon={<Iconify icon="ic:round-refresh" />}
              variant="contained"
              style={{ marginRight: 3 }}
              onClick={() => {
                window.location.reload(true);
              }}
            >
              Refresh now
            </Button>
            <Button
              className={classes.okButton}
              variant="outlined"
              onClick={() => {
                if (setSystemUpdatedNotification) {
                  setSystemUpdatedNotification(false);
                }
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
