import Api from "../lib/api";
import ViewLogsTable from "../components/logs/ViewLogsTable";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { useState } from "react";
import QuoteItemsLogs from "./QuoteItemsLogs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {
        //value === index && (
        <Box p={2} style={{ backgroundColor: "#b5b5b51c" }}>
          <Typography>{children}</Typography>
        </Box>
        //)
      }
    </div>
  );
}

export default function QuoteLogs(props) {
  const { quoteID } = props;
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Quote" />
          <Tab label="Quote Items" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <ViewLogsTable
          //    title={"Quote Logs"}
          modelId={quoteID}
          getLogs={Api.getQuoteActivityLogs}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <QuoteItemsLogs quoteID={quoteID} />
      </TabPanel>
    </div>
  );
}
