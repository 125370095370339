import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

import moment from "moment";
import { useInterval } from "beautiful-react-hooks";
import Tooltip from "@material-ui/core/Tooltip";
import Api from "../../lib/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ProductsControlForm(props) {
  const {
    addItem = null,
    itemForAdd = null,
    user = null,
    contact_id = null,
    type = null,
    selectedRowData = null,
  } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [file, setFile] = useState(null);

  const [requestActivty, setRequestActivty] = useState([]);
  const [requestId, setRequestId] = useState(0);

  const [requestSigned, setRequestSigned] = useState(false);

  const [inOut, setInOut] = useState([]);

  const [validQuantity, setValidQuantity] = useState(0);

  const [dateText, setDateText] = useState("");

  const [quantityText, setQuantityText] = useState("");

  const [selectedInOut, setSelectedInOut] = useState("out");

  const [submitTooltip, setSubmitTolltip] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const classes = useStyles();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (contact_id) {
      data.contact_id = contact_id;
    }

    await addItem(itemForAdd, data);
    setIsSubmitting(false);
  };
  const getRequestActivity = async () => {
    const { data } = await Api.getContractRequestActivity(
      user.organization_id,
      requestId
    );
    setRequestActivty(data.data);
    // console.log("getRequestActivity... ", data);
  };

  const renderRequestActivty = () => {
    return requestActivty.map((activity) => {
      if (activity.event_type === "agreed") {
        //  console.log("signed!!!!");
        getContractPDF(activity.id);
        setRequestSigned(true);
      }
      return (
        <p>{`contract ${activity.event_type} at ${moment(
          activity.created_time
        ).format("YYYY/MM/DD h:m")}`}</p>
      );
    });
  };

  const getContractPDF = (requestSigned) => {
    // console.log("getContractPDF ", requestSigned);
  };

  useInterval(async () => {
    if (requestId && !requestSigned) {
      getRequestActivity();
    }
  }, 20000);

  useEffect(() => {
    if (selectedInOut == "in") {
      setValidQuantity(selectedRowData?.used);
      setValue("quantity", selectedRowData?.used);
    }
    if (selectedInOut == "out") {
      setValidQuantity(selectedRowData?.available);
      setValue("quantity", selectedRowData?.available);
    }
    //Submit Tooltip Text
    if (selectedRowData?.available == 0 && selectedInOut == "out") {
      setSubmitTolltip("There is no available amount");
    } else {
      setSubmitTolltip("");
    }
  }, [selectedInOut]);

  useEffect(() => {
    //Radio Button Text
    if (type == "physical") {
      setInOut([
        { id: "in", title: "Returned" },
        { id: "out", title: "Delivered" },
      ]);
    } else {
      //Radio Button Text
      setInOut([
        { id: "in", title: "Available" },
        { id: "out", title: "Done" },
      ]);
    }
  }, []);

  useEffect(() => {
    if (type == "physical") {
      //Date and Quantity fields Texts
      if (selectedInOut == "in") {
        setDateText("Returned At");
        setQuantityText("Quantity of returned items");
      } else {
        setDateText("Delivered At");
        setQuantityText("Quantity of delivered items");
      }
    } else {
      //Date and Quantity fields Texts
      if (selectedInOut == "in") {
        setDateText("Date");
        setQuantityText("Quantity of available services");
      } else {
        setDateText("Done At");
        setQuantityText("Quantity of done services");
      }
    }
  }, [selectedInOut]);

  // useEffect(() => {
  //   console.log("CC submit Text ", submitTooltip);
  // }, [submitTooltip]);

  useEffect(() => {
    if (selectedRowData) {
      if (selectedRowData?.available == 0) {
        setSelectedInOut("in");
        setValue("in_out", "in");
        setInOut([{ id: "in", title: "Returned" }]);
      }
      if (selectedRowData?.used == 0) {
        setSelectedInOut("out");
        setValue("in_out", "out");
        setInOut([{ id: "out", title: "Delivered" }]);
      }
    }
    selectedRowData?.available == 1 && selectedInOut == "in";
  }, [selectedRowData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="product_control_date"
            type="date"
            control={control}
            defaultValue={moment().format("YYYY-MM-DD HH:mm:ss")}
            as={({ onChange, value }) => (
              <Controls.DateTimePicker
                label={dateText}
                shouldCloseOnSelect
                // defaultValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                defaultValue={moment().format("YYYY-MM-DD")}
                maxDate={moment().format("YYYY-MM-DD")}
                // disabled
              />
            )}
          />

          <Controller
            name="quantity"
            control={control}
            defaultValue="1"
            as={({ onChange, value }) => (
              <Controls.Input
                label={quantityText}
                type="number"
                className={classes.formControl}
                value={value ? value : 1}
                onChange={(value) => {
                  onChange(value.target.value);
                }}
                inputProps={{
                  decimalPrecision: 0,
                  decimalSeparator: false,
                  allowNegative: false,
                  isAllowed: (values) => {
                    const { floatValue } = values;
                    return floatValue >= 1 && floatValue <= validQuantity;
                  },
                }}
                InputProps={{
                  inputComponent: Controls.NumberFormatCustom,
                }}
              />
            )}
            rules={{ required: "Quantity is required" }}
          />

          {errors.quantity && (
            <p className="error">{errors.quantity.message}</p>
          )}

          <Controller
            control={control}
            name="in_out"
            defaultValue={selectedInOut}
            as={({ onChange, value }) => (
              <Controls.RadioGroup
                label={
                  type == "physical" ? "Returned/Delivered" : "Available/Done"
                }
                onChange={(data) => {
                  setSelectedInOut(data), onChange(data);
                }}
                value={value}
                items={inOut}
              />
            )}
            rules={{ required: "In Out is required" }}
          />
          {errors.in_out && <p className="error">{errors.in_out.message}</p>}

          <Controller
            name="comment"
            control={control}
            defaultValue=""
            as={<Controls.Textarea label="Comments" rows="4" />}
          />
          <Controller
            control={control}
            defaultValue=""
            name="file"
            as={({ onChange, value }) => (
              <Controls.DropzoneDialog
                label="Attach file"
                onChange={(files) => {
                  setFile(files[0]);
                  onChange(files[0]);
                }}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {file && <span>{file.path}</span>}
          {requestActivty && <div>{renderRequestActivty()}</div>}
        </Grid>
        <Grid item xs={12}>
          <div>
            <Tooltip title={submitTooltip}>
              <span>
                <Controls.Button
                  disabled={isSubmitting}
                  type="submit"
                  text={isSubmitting ? "Submitting..." : "Submit"}
                  // disabled={
                  //   (selectedRowData?.available == 0 &&
                  //     selectedInOut == "out") ||
                  //   (selectedRowData?.available == 1 && selectedInOut == "in")
                  // }
                />
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
