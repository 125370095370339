import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'invWarehouses';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code="GET_ACC_INVWAREHOUSE";
  return _fetchEntitiesService(dataSlice,events);
}
export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code="POST_ACC_INVWAREHOUSE";
  return _createEntityService(dataSlice,events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code="PUT_ACC_INVWAREHOUSE";
  return _updateEntityService(dataSlice,events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code="DELETE_ACC_INVWAREHOUSE";
  return _deleteEntityService(dataSlice,events);
}

//Customs
export function fetchWarehousesAllow(dataSlice) {
  dataSlice.dataUrl.code="GET_ACC_INVWAREHOUSE_ALLOW";
  return _fetchEntitiesService(dataSlice,events);
}