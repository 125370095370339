import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuildingsService } from "../redux/slices/buildings";
import {
  FormControl,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import Iconify from "./Iconify";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  formControl: {
    backgroundColor: "#121820",
    color: "#fff",
    "& .MuiInputLabel-outlined": {
      color: "#fff",
    },
  },
}));

function BuildingSwitchDropdown(props) {
  const { onSelectBuilding, defaultValue, buildingsDropdown = null } = props;
  const { buildings } = useSelector((state) => state.buildings);

  const { authUser, pbxConfig } = useSelector((state) => state.auth);

  const classes = useStyles();

  const dispatch = useDispatch();

  const [anchorElNew, setAnchorElNew] = useState(null);

  const handleClickNew = (event) => {
    setAnchorElNew(event.currentTarget);
  };

  const handleCloseNew = () => {
    setAnchorElNew(null);
  };

  const renderBuildingMenu = () => {
    return buildingsDropdown
      ? buildingsDropdown
          .filter((building) => {
            const userBuildings = authUser.buildings.data.map((building) => {
              return building.id;
            });
            return userBuildings.includes(building.id);
          })
          .map((building) => {
            return {
              id: building.id,
              title: building.name,
            };
          })
      : buildings
          .filter((building) => {
            const userBuildings = authUser.buildings.data.map((building) => {
              return building.id;
            });
            return userBuildings.includes(building.id);
          })
          .map((building) => {
            return {
              id: building.id,
              title: building.name,
            };
          });
  };

  const changeBuilding = (id) => {
    if (
      pbxConfig &&
      pbxConfig.aor &&
      pbxConfig.receive_calls &&
      !pbxConfig.secundary &&
      pbxConfig.receive_active
    ) {
      localStorage.removeItem("registered");
      localStorage.removeItem("devices");
      localStorage.removeItem("defaults");
      localStorage.removeItem("callCheck");
    }

    const buildingId = id;
    const building = find(buildingsDropdown ? buildingsDropdown : buildings, {
      id: buildingId,
    });
    onSelectBuilding(building);
  };

  useEffect(() => {
    const orgId = authUser.organization_id;
    if (isEmpty(buildings) && !buildingsDropdown)
      dispatch(fetchBuildingsService(orgId));
  }, []);

  return (
    <>
      <FormControl className={classes.formControl}>
        {/*
          <Controls.Select
            fullWidth
            className={classes.selectBuilding}
            label="Building Select"
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            options={renderBuildingMenu()}
            onChange={(v) => changeBuilding(v)}
          />  */}

        <Button
          onClick={handleClickNew}
          key={"Building Select"}
          variant="outlined"
          style={{
            borderRadius: "30px",
            height: "100%",
            width: "100%",
            color: "white",
            borderColor: "white",
          }}
        >
          Building Select
        </Button>

        <Menu
          id="menu-new"
          anchorEl={anchorElNew}
          keepMounted
          open={Boolean(anchorElNew)}
          onClose={handleCloseNew}
          PaperProps={{
            style: {
              //  marginTop: 50,
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {buildingsDropdown
            ? buildingsDropdown
                .filter((building) => {
                  const userBuildings = authUser.buildings.data.map(
                    (building) => {
                      return building.id;
                    }
                  );
                  return userBuildings.includes(building.id);
                })
                .map((building, index) => {
                  return (
                    <MenuItem
                      key={`changeBuilding_${building.id}`}
                      onClick={() => {
                        changeBuilding(building.id);
                        handleCloseNew();
                      }}
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <ListItemIcon>
                        <Iconify
                          icon={"healthicons:ambulatory-clinic"}
                          style={{ color: "#607d8b" }}
                          width={35}
                          height={35}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{ color: "#263238" }}
                      >
                        {building.name}
                      </Typography>
                    </MenuItem>
                  );
                })
            : buildings
                .filter((building) => {
                  const userBuildings = authUser.buildings.data.map(
                    (building) => {
                      return building.id;
                    }
                  );
                  return userBuildings.includes(building.id);
                })
                .map((building, index) => {
                  return (
                    <MenuItem
                      key={`changeBuilding_${building.id}`}
                      onClick={() => {
                        changeBuilding(building.id);
                        handleCloseNew();
                      }}
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <ListItemIcon>
                        <Iconify
                          icon={"healthicons:ambulatory-clinic"}
                          style={{ color: "#607d8b" }}
                          width={35}
                          height={35}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{ color: "#263238" }}
                      >
                        {building.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
        </Menu>
      </FormControl>
    </>
  );
}

export default BuildingSwitchDropdown;
