import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { isEmpty, uniqueId, map, findIndex, find } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import QuoteDetails from "../pages/quotes/details";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ReactToPrint from "react-to-print";
import InfoIcon from "@material-ui/icons/Info";
import CRMUtils from "../utils";
import BackspaceIcon from "@material-ui/icons/Backspace";

import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Button as MuiButton,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Menu,
  MenuItem,
  Button,
  Container,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { spacing, display } from "@material-ui/system";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import Alert from "@material-ui/lab/Alert";

import PersonIcon from "@material-ui/icons/Person";
import Controls from "./controls";
import Popup from "./Popup";

import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import PrintInvoiceDetails from "./PrintInvoiceDetails";
import { fetchEntitiesService as fetchProductGiftReasonService } from "../redux/slices/productGiftReasonSlice.js";

import deletedStamp from "../assets/img/icons/deletedStamp.png";

var dataSliceproductGiftReason = {
  stateListName: "productGiftReason",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

import { fetchRefundReasonsService } from "../redux/slices/refundReasons";

import { fetchEntitiesService as fetchFinancingService } from "../redux/slices/financingSlice.js";
import Iconify from "./Iconify";
import BallotIcon from "@material-ui/icons/Ballot";

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
var dataSliceRefundRequestsNew = {
  stateListName: "refundRequestsNew",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 50,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "90%",
  },
  included_margin: {
    width: "100%",
    margin: "25px 0px",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainProduct: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "200px",
  },

  childProduct: {
    marginLeft: "35px",
    color: "#999",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "200px",
  },

  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Nunito,Roboto",
  },
  wrapText2: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
    textAlign: "center",
    color: "#f44336",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Nunito,Roboto",
  },
  customTooltip: {
    backgroundColor: "#e0f2f1",
    color: "#607d8b",
  },
  customArrow: {
    color: "#e0f2f1",
  },
  watermarkedContainer: {
    position: "relative",
  },
  watermark: {
    position: "absolute",
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: "0.5",
    pointerEvents: "none",
  },
}));

function InvoiceBuilder(props) {
  const {
    setInvoices = null,
    setInvoice = null,
    getInvoicesContact = null,
    invoices = null,
    invoice,
    statusOptions,
    users,
    products,
    contact,
    readOnlySalesManager,
    onSaveInvoice,
    onMakePayment,
    onSaveCreditMemo,
    openItemDefault,
    authUser,
    userIsCallCenter = false,
    userIsSocialMedia = false,
    userIsFrontDesk = false,
  } = props;

  if (authUser) {
    dataSliceRefundRequestsNew.orgId = authUser.organization_id;
  }
  const invoiceRef = useRef();
  const dispatch = useDispatch();

  const { financing } = useSelector((state) => state.financing);
  const { productGiftReason } = useSelector((state) => state.productGiftReason);
  const [sendPaymentLinkSmsStatus, setSendPaymentLinkSmsStatus] =
    useState(null);

  const org_footer = authUser ? authUser.organization.data.invoice_footer : 1;

  const [items, setItems] = useState(invoice ? invoice.items.data : []);
  const [status, setStatus] = useState(invoice ? invoice.status : "unsend");
  const [included, setIncluded] = useState(invoice ? invoice.included : "");
  const classes = useStyles();

  const [openQuote, setOpenQuote] = useState(false);
  const [quote, setQuote] = useState();

  const [openNotification, setOpenNotification] = useState(false);

  const [financingInterest, setFinancingInterest] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { refundReasons } = useSelector((state) => state.refundReasons);

  const [financingOption, setFinancingOption] = useState(
    invoice ? (invoice.financing_id ? invoice.financing_id : -1) : -1
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const openRefQuote = async () => {
    if (!isEmpty(invoice.quote.data)) {
      setQuote(invoice.quote.data);
    }

    setOpenQuote(true);
  };

  const closeRefQuote = () => {
    setOpenQuote(false);
  };

  const closeItemPopup = () => {
    setEditItem(null);
    setEditItemIndex(null);
    setOpenItem(false);
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  const calulateTotalPayments = () => {
    const payments = invoice
      ? invoice.payments.data.filter((payment) => {
          return !payment.isRefund;
        })
      : [];

    return payments.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const calulateTotalRefunds = () => {
    const refunds = invoice
      ? invoice.payments.data.filter((payment) => {
          return payment.isRefund;
        })
      : [];

    return refunds.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const getItemPrice = (price, base, product_apply_financing) => {
    if (financingInterest && product_apply_financing) {
      const value = base ? base : price;
      return parseFloat(value + (value * financingInterest) / 100).toFixed(2);
    }
    return base || price;
  };

  const calulateTotalPaymentsCreditRefund = () => {
    const totalPayments = calulateTotalPayments();
    const totalRefunds = calulateTotalRefunds();
    const credits = calculateTotalCredits();

    return (
      Math.round(totalPayments * 100) / 100 +
      Math.round(credits * 100) / 100 -
      Math.round(totalRefunds * 100) / 100
    );
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    const itemPrice = getItemPrice(
      item.type == "package" && item.package_price
        ? item.package_price
        : item.price,
      item.type == "package" && item.package_price
        ? item.base_package_price
        : item.base_price,
      item.product_apply_financing
    );

    return (
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };

  const calculateSubtotal = () => {
    const subtotal1 = items.filter((item) => item.void == 0);

    const subtotal2 = subtotal1.reduce(
      (total, item) =>
        total +
        calculateItemTotal(
          invoice
            ? { ...item, ...{ package_price: 0, base_package_price: 0 } }
            : item
        ),
      0
    );

    return subtotal2;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  const calculateTotalCredits = () => {
    return invoice?.totalCredits || 0;
  };

  useEffect(() => {
    if (invoice) {
      setStatus(invoice.status);
      setItems(invoice.items.data);
    }
  }, [invoice]);

  useEffect(() => {
    const orgId = authUser.organization_id;

    if (openItemDefault) {
      setOpenItem(true);
    }

    if (isEmpty(refundReasons)) {
      dispatch(fetchRefundReasonsService(orgId));
    }

    if (isEmpty(financing)) dispatch(fetchFinancingService(dataSliceFinancing));

    if (isEmpty(productGiftReason)) {
      dispatch(fetchProductGiftReasonService(dataSliceproductGiftReason));
    }
  }, []);

  useEffect(() => {
    if (sendPaymentLinkSmsStatus !== "sending") {
      setSendPaymentLinkSmsStatus(null);
    }
  }, [sendPaymentLinkSmsStatus]);

  const renderItems = () => {
    return items
      .filter((item) => {
        return item.type !== "system";
      })
      .map((item, index) => {
        return (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              <ListItem>
                {item.type == "package" ? (
                  <ListItemIcon>
                    <Iconify
                      icon="entypo:shopping-bag"
                      style={{ fontSize: 18, color: "gray" }}
                    />
                  </ListItemIcon>
                ) : (
                  ""
                )}
                {item.responsible && (
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={item.name}
                  secondary={item.responsible ? item.responsible.title : null}
                  className={
                    item && item.parent_id
                      ? classes.childProduct
                      : classes.mainProduct
                  }
                />
              </ListItem>
            </TableCell>
            <TableCell>
              {item.parent_id ? (
                authUser.organization &&
                authUser.organization.data &&
                authUser.organization.data.zero_package_value &&
                item.product &&
                !item.product.is_procedure ? (
                  <div>$ 0.00</div>
                ) : (
                  <div
                    style={{
                      fontWeight: 600,
                      color: "rgb(14, 85, 126)",
                      backgroundColor: "#deeced",
                      textAlign: "center",
                      padding: "0px 15px",
                      maxWidth: "fit-content",
                      borderRadius: 12,
                    }}
                  >
                    {"INCLUDED"}
                  </div>
                )
              ) : (
                <div>
                  {item.product_gift_reason_id ? (
                    <CardGiftcardIcon fontSize="small" color="secondary" />
                  ) : item && item.parent_id ? (
                    ""
                  ) : (
                    ""
                  )}
                  ${" "}
                  {parseFloat(
                    getItemPrice(
                      item.type == "package" && item.package_price
                        ? item.package_price
                        : item.price,
                      item.type == "package" && item.package_price
                        ? item.base_package_price
                        : item.base_price,
                      item.product_apply_financing
                    )
                  ).toFixed(2)}
                  {item.product_gift_reason_id ? (
                    <>
                      <span>{" / "} </span>
                      <span style={{ color: "#8080808a" }}>
                        {" $ " + item.gift_reference_price}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </TableCell>

            <TableCell>
              <span>{item?.quantity || 1}</span>
            </TableCell>
            <TableCell align="right">
              {item && item.parent_id
                ? ""
                : "$ " + calculateItemTotal(item).toFixed(2)}
            </TableCell>

            {invoice ? (
              <TableCell align="right">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                  title={
                    <div>
                      <Box fontWeight="fontWeightBold" m={1}>
                        {`Payments: $ ${
                          item && item.itemPaid
                            ? item.itemPaid.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"
                        }   `}
                      </Box>
                      <Box fontWeight="fontWeightBold" m={1}>
                        {`Credits: $ ${
                          item && item.itemTotalCredits
                            ? item.itemTotalCredits.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"
                        }   `}
                      </Box>

                      <Box fontWeight="fontWeightBold" m={1}>
                        {`Refunds: $ ${
                          item && item.itemRefund
                            ? item.itemRefund.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"
                        }   `}
                      </Box>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon style={{ color: "#26a69a" }} />
                </Tooltip>
              </TableCell>
            ) : (
              <TableCell>{""}</TableCell>
            )}
            <TableCell>{""}</TableCell>
          </TableRow>
        );
      });
  };

  const getActiveStep = () => {
    const index = findIndex(statusOptions, ["key", status]);
    return index;
  };

  const financingOptions = () => {
    return financing.map((financing) => {
      return {
        id: financing.id,
        title: financing.name,
        interest: financing.interest,
      };
    });
  };

  const applyFinancing = () => {
    const selectedFinancing = find(financing, { id: financingOption });
    if (selectedFinancing) {
      setFinancingInterest(
        invoice && invoice.interest
          ? invoice.interest
          : selectedFinancing.interest
      );
    } else {
      setFinancingInterest(false);
    }
  };

  useEffect(() => {
    applyFinancing();
  }, [financingOption]);

  return (
    <Shadow>
      <div>
        <Card className={classes.card}>
          <CardContent>
            <Alert
              severity="error"
              style={{
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontSize: 20, fontStyle: "italic", fontWeight: 600 }}
              >
                DELETED | This Invoice is only for record purposes
              </Typography>
            </Alert>
          </CardContent>
          <Box className={classes.watermarkedContainer}>
            <CardContent>
              <Stepper activeStep={getActiveStep()}>
                {map(statusOptions, (status) => (
                  <Step key={status.label}>
                    <StepLabel>{status.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  {invoice && !isEmpty(invoice.quote.data) && (
                    <Typography component="div">
                      <Box fontWeight="fontWeightMedium" m={1}>
                        <Fab
                          variant="extended"
                          size="medium"
                          color="primary"
                          aria-label="see"
                          style={{ float: "right" }}
                          onClick={openRefQuote}
                          disabled
                        >
                          {"Reference Quote #   " + invoice.quote.data.name}
                        </Fab>
                      </Box>
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightMedium" m={1}>
                      BILLED FROM:
                    </Box>
                    <Box fontWeight="fontWeightBold" m={1}>
                      {invoice
                        ? invoice.building_id
                          ? invoice.building_name
                          : invoice.organization_name
                        : authUser.building_id
                        ? authUser.building_name
                        : authUser.organization.data.name}
                    </Box>
                    <Box fontWeight="fontWeightBold" m={1}>
                      {invoice
                        ? invoice.building_id
                          ? invoice.building_address
                          : invoice.organization_address
                        : authUser.building_id
                        ? authUser.building_address
                        : authUser.organization.data.address}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightMedium" m={1}>
                      BILLED TO:
                    </Box>

                    {contact ? (
                      <Box fontWeight="fontWeightBold" m={1}>
                        <Typography component="div">
                          {contact.full_name}
                        </Typography>{" "}
                      </Box>
                    ) : (
                      <Box m={3}>
                        <Controls.AutocompleteAsync
                          id={"contact_id"}
                          label={"Lead/Patient"}
                          fullWidth={true}
                          onChange={(newValue) => {
                            setLocalContact(newValue);
                          }}
                        />
                      </Box>
                    )}

                    <Box fontWeight="fontWeightMedium" m={1}>
                      {contact ? contact.address : ""}
                    </Box>
                    <Box m={1}>
                      {contact && contact.coordinator_name
                        ? "Consultant: " + contact.coordinator_name
                        : ""}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {invoice && !isEmpty(invoice.pay_before) && (
                    <Typography component="div">
                      <Box fontWeight="fontWeightMedium" m={1}>
                        <Fab
                          variant="extended"
                          size="medium"
                          color="primary"
                          aria-label="see"
                          style={{ float: "right" }}
                          disabled
                        >
                          {"Pay Before: " + invoice.pay_before}
                        </Fab>
                      </Box>
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Controls.Select
                    disabled
                    allowEmpty={true}
                    emptyLabel="None"
                    options={financingOptions()}
                    value={financingOption}
                    label="Financing"
                    onChange={(value) => {
                      setFinancingOption(value.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <img
              src={deletedStamp}
              alt="Watermark"
              className={classes.watermark}
              width={"40%"}
            />
          </Box>
        </Card>
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Price</TableCell>

                <TableCell>Quantity</TableCell>

                <TableCell align="right">
                  {invoice?.invoice_total_name
                    ? invoice?.invoice_total_name
                    : "Total"}
                </TableCell>

                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {renderItems()}

              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    {invoice?.invoice_total_name
                      ? invoice?.invoice_total_name
                      : "Total"}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    ${" "}
                    {calculateTotal().toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Box>
                </TableCell>
                <TableCell />

                <TableCell />
              </TableRow>

              <TableRow></TableRow>
              <TableRow></TableRow>
              {org_footer == 1 && (
                <TableRow>
                  <TableCell />
                  <TableCell />

                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      Pymts/Credits
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      ${" "}
                      {calulateTotalPaymentsCreditRefund().toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      <Tooltip
                        title={
                          <div>
                            <Box fontWeight="fontWeightBold" m={1}>
                              {`Payments: $ ${calulateTotalPayments().toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}   `}
                            </Box>

                            <Box fontWeight="fontWeightBold" m={1}>
                              {`Credits: $ ${calculateTotalCredits().toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}   `}
                            </Box>
                            <Box fontWeight="fontWeightBold" m={1}>
                              {`Refunds: $ ${calulateTotalRefunds().toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}   `}
                            </Box>
                          </div>
                        }
                        arrow
                      >
                        <InfoIcon style={{ color: "#26a69a" }} />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />

                  <TableCell />
                </TableRow>
              )}
              {invoice && org_footer == 2 && calulateTotalPayments() > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      Payments
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      ${" "}
                      {calulateTotalPayments().toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}

              {invoice && org_footer == 2 && invoice.totalCredits > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      Credits
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="green">
                      ${" "}
                      {calculateTotalCredits().toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
              {invoice && org_footer == 2 && calulateTotalRefunds() > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="red">
                      Refunds
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1} color="red">
                      ${" "}
                      {calulateTotalRefunds().toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
              {invoice && (
                <TableRow>
                  <TableCell />
                  <TableCell />

                  <TableCell align="right">
                    <Box
                      fontWeight="fontWeightBold"
                      m={1}
                      style={{
                        color: invoice.balance <= 0 ? "#4caf50" : "#f44336",
                      }}
                    >
                      Balance
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      fontWeight="fontWeightBold"
                      m={1}
                      style={{
                        color: invoice.balance <= 0 ? "#4caf50" : "#f44336",
                      }}
                    >
                      {` ${
                        invoice.balance && invoice.balance < 0
                          ? " (OverPaid)"
                          : ""
                      } $ ${
                        invoice.balance
                          ? invoice.balance.toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"
                      }`}
                    </Box>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
      </div>
      {!isEmpty(items) && (
        <Card>
          <CardContent>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <Controls.Input
                  defaultValue={included}
                  disabled
                  label="Included"
                  className={classes.included_margin}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Popup
        fullScreen
        title="Ref Quote"
        fullWidth={true}
        openPopup={openQuote}
        onClose={closeRefQuote}
      >
        <QuoteDetails quote={quote} />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />

      <Popup
        title="Notification"
        fullWidth={false}
        openPopup={openNotification}
        onClose={closeNotification}
      >
        This item already exist in the invoice, please select another one or
        change responsible person.
      </Popup>
      {invoice && (
        <div style={{ display: "none" }}>
          <div ref={invoiceRef}>
            <PrintInvoiceDetails
              invoice={invoice}
              financingInterest={financingInterest}
            />
          </div>
        </div>
      )}
    </Shadow>
  );
}
export default InvoiceBuilder;
