import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @material
// import { styled } from '@mui/material/styles';
import { styled, Box, Grid, Button } from "@material-ui/core";
//
import BlockContent from "./BlockContent";
import RejectionFiles from "./RejectionFiles";
import MultiFilePreview from "./MultiFilePreview";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#ffffff",
  border: '1px dashed 919EAB',
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));


// const DropZoneStyle = styled.div`
//   outline: none;
//   padding: ${(props) => props.theme.spacing(5, 1)};
//   border-radius: 20px;
//   background-color:#ffffff;
//   border: 1px dashed #919EAB; 
// `;

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  files: PropTypes.array.isRequired,
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  helperText: PropTypes.node,
  style: PropTypes.object,
  iconSelect: PropTypes.string,
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onUpload,
  onRemove,
  onRemoveAll,
  helperText,
  style,
  iconSelect,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    ...other,
  });

  return (
    <Box style={{ width: "100%", ...style }}>

      <DropZoneStyle
        {...getRootProps()}
        style={{
          color: "error.main",
          borderColor: "error.light",
          bgcolor: "error.lighter",
        }}
      >
        <input {...getInputProps()}/>

        <BlockContent iconSelect= {iconSelect} hasFiles= {files.length > 0} />
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      <MultiFilePreview
        files={files}
        showPreview={showPreview}
        onRemove={onRemove}
      />
      
      {helperText && helperText}
    </Box>
  );
}
