import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { findIndex } from "lodash";
import CRMUtils from "../../utils";
import { useSelector } from "react-redux";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import InputMoneyOkWithoutController from "../controls/InputMoneyOkWithoutController";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  moreButton: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 2,
  },
  lessButton: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 2,
    transform: "rotate(180deg)",
  },
}));

export default function EditHeaderHistoryToMultiRefundForm(props) {
  const { onChange, header, histories } = props;
  const [paymentsData, setPaymentsData] = useState([]);
  const [historiesValues, setHistoriesValues] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const classes = useStyles();

  const getPaymentsData = async () => {
    if (header) {
      const { data } = await Api.getMultipaymentHistories(
        header.parent_header_id
      );

      setPaymentsData([...data.data]);
    }
  };

  useEffect(() => {
    getPaymentsData();
  }, []);

  useEffect(() => {
    if (histories && !isEmpty(histories)) {
      const tempHistories = histories.map((history) => {
        return {
          id: history.id,
          amount: history.amount,
          new_amount: history.amount,
          invoice_name: history.invoice_name,
          items:
            history.paymentdetail &&
            history.paymentdetail.data &&
            !isEmpty(history.paymentdetail.data)
              ? history.paymentdetail.data.map((item) => {
                  return {
                    id: item.id,
                    item_id: item.item_id,
                    item_name: item.item_name,
                    amount: item.amount,
                    new_amount: item.amount,
                    parent_detail_id: item.parent_detail_id,
                    payment_id: item.payment,
                  };
                })
              : [],
        };
      });

      setHistoriesValues([...tempHistories]);
    }
  }, [histories]);

  useEffect(() => {
    onChange(historiesValues);
  }, [historiesValues]);

  const renderInvoiceItems = (items, history) => {
    return items.map((item, index) => {
      var isRequired = historiesValues.find((a) =>
        a.items.find((i) => i.new_amount != null && i.new_amount != 0)
          ? true
          : false
      )
        ? false
        : true;

      var matchingDetail = { ...item };

      var parentHistory = paymentsData.find((a) =>
        a.paymentsDetail.some((p) => p.id == item.parent_detail_id)
      );
      if (parentHistory) {
        matchingDetail = parentHistory.paymentsDetail.find(
          (p) => p.id == item.parent_detail_id
        );
      }

      return (
        <TableRow key={item.id}>
          <TableCell style={{ maxWidth: "160px" }} component="th" scope="row">
            <Typography
              className={
                matchingDetail.type === "forfeiture" ||
                matchingDetail.type === "system" ||
                (item.amount ? item.amount : 0) -
                  (matchingDetail?.refund_payment_details_simple_sum_amount ||
                    0) <
                  0 ||
                matchingDetail.void === 1
                  ? classes.primaryHeadingDisabled
                  : classes.primaryHeading
              }
              key={`item_name-${item.id}`}
            >
              {item.item_name}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            <Typography
              className={
                matchingDetail.type === "forfeiture" ||
                matchingDetail.type === "system" ||
                (item.amount ? item.amount : 0) -
                  (matchingDetail?.refund_payment_details_simple_sum_amount ||
                    0) <
                  0 ||
                matchingDetail.void === 1
                  ? classes.primaryHeadingDisabled
                  : classes.primaryHeading
              }
              key={`refundableamount_${item.id}`}
            >
              {`$${
                matchingDetail.type === "forfeiture" ||
                matchingDetail.type === "system"
                  ? "0.00"
                  : (
                      history.amount -
                      (matchingDetail?.refund_payment_details_simple_sum_amount -
                        matchingDetail?.amount)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
              }`}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <InputMoneyOkWithoutController
              // name={`items_amount[${item.item_id}[${item.parent_id}]]`}
              // name="amount"
              onChange={(value) => {
                updateAmount(value, history, item);
              }}
              required={isRequired}
              value={item.new_amount}
              defaultValue={item.amount}
              label="Amount"
              type="number"
              disabled={
                matchingDetail.type === "forfeiture" ||
                matchingDetail.type === "system" ||
                // (item.amount ? item.amount : 0) -
                //   (item?.refunded_amount || 0) <
                //   0 ||
                matchingDetail.void === 1
              }
              maxValue={
                matchingDetail.type === "forfeiture" ||
                matchingDetail.type === "system"
                  ? "0.00"
                  : (
                      history.amount -
                      (matchingDetail?.refund_payment_details_simple_sum_amount -
                        matchingDetail?.amount)
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
              }
              minValue={userCanEditNegativePayments ? "-999999" : "0"}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const updateAmount = (value, history, detail) => {
    const index = findIndex(historiesValues, ["id", history.id]);

    const items = [...historiesValues[index]["items"]];
    const indexItem = findIndex(items, ["id", detail.id]);

    if (indexItem != -1) {
      const updatedItemEvent = {
        ...items[indexItem],
        ...{ new_amount: value },
      };
      items[indexItem] = updatedItemEvent;
    } else {
      items.push({
        id: item_id,
        new_amount: value,
        edit_id: null,
      });
    }

    const updatedEvent = {
      ...historiesValues[index],
      ...{
        items: items,
        new_amount: items.reduce(
          (total, item) =>
            total + parseFloat(item.new_amount ? item.new_amount : 0),
          0
        ),
      },
    };

    let newHistoriesValues = [...historiesValues];
    newHistoriesValues[index] = updatedEvent;
    setHistoriesValues([...newHistoriesValues]);
  };

  return (
    <>
      {historiesValues.map((history) => {
        console.log("history: ", history)
        return (
          <Accordion
            expanded={history.expanded}
            style={{ background: "rgb(231 245 243)", margin: "8px 2px" }}
          >
            <AccordionSummary
              onClick={() => {
                const index = findIndex(historiesValues, ["id", history.id]);
                const updatedEvent = {
                  ...historiesValues[index],
                  ...{
                    expanded: historiesValues[index]["expanded"] ? false : true,
                  },
                };

                let newHistories = [...historiesValues];
                newHistories[index] = updatedEvent;
                setHistoriesValues([...newHistories]);
              }}
              style={{
                margin: 5,
                background: "rgb(231 245 243)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Iconify
                    className={
                      history.expanded ? classes.lessButton : classes.moreButton
                    }
                    icon="material-symbols:expand-more-rounded"
                  />
                  <Typography
                    style={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                  >
                    {" "}
                    {`To Invoice: ${history.invoice_name}`}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ display: "grid", marginRight: 10 }}>
              {history.items.map((item) => {
                return (
                  <TableContainer
                    style={{ padding: "10px 18px" }}
                    component={Paper}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Refundable Amount</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {" "}
                        {renderInvoiceItems(history.items, history)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
