import React, { useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { FormControl } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import Controls from ".";

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);

export default function FileDialogIcon(props) {
  const {
    label,
    onChange,
    value,
    icon,
    filesLimit = null,
    setOpenData = null,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files) => {
    onChange(files);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (setOpenData) {
      setOpenData(true);
    }
  };
  const classes = useStyles();

  return (
    <FormControl>
      <Controls.IconButton {...rest} onClick={handleOpen}>
        {icon}
      </Controls.IconButton>

      <DropzoneDialog
        open={open}
        filesLimit={filesLimit ? filesLimit : 3}
        onSave={handleSave}
        acceptedFiles={["image/jpeg", "image/png"]}
        maxFileSize={1073741824}
        onClose={handleClose}
        Icon={AttachmentIcon}
        showPreviews={true}
        showPreviewsInDropzone={false}
        dialogTitle="Send Pictures (3 files Max. at the same time)"
        dropzoneText="Drag and drop a file to send"
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Preview: "
        submitButtonText="Send"
      />
    </FormControl>
  );
}
