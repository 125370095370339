import { CircularProgress, FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce, isArray, isInteger, isObject, isString } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import Api from "../../../lib/api";

const AutocompleteGeneric = ({
    label,
    optionLabel,
    objectId,
    model,
    include,
    searchBy,
    RenderOption,
    onChange,
    onSelected,
    error,
    helperText,
    filters = {},
    orderBy = "id",
    order = "asc",
    disabled =false
}) => {
    const [inputValue, setInputValue] = useState("");
    const [selected, setSelected] = useState(null);
    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [autocompleteSearchBy, setAutocompleteSearchBy] = useState("");

    //update selected value to outside
    useEffect(() => {
        if (onSelected) {
            onSelected(selected);
        }
    }, [selected]);

    const _filters = useMemo(() => {
        if (isString(searchBy)) {
            return {
                ...filters,
                [searchBy]: autocompleteSearchBy,
            };
        }
        if (isArray(searchBy)) {
            return searchBy.reduce(
                (prev, curr) => ({ ...prev, [curr]: autocompleteSearchBy }),
                { ...filters }
            );
        }
        if (isObject(searchBy)) {
            return Object.keys(searchBy).reduce((prev, curr) => {
                if (isString(searchBy[curr])) {
                    return {
                        ...prev,
                        [curr]:autocompleteSearchBy
                    }
                }
                if (isArray(searchBy[curr])) {
                    return {
                        ...prev,
                        [curr]:searchBy[curr].reduce((p2, c2) =>({...p2,[c2]:autocompleteSearchBy}),{})
                    }
                }
            },{...filters})
        }
        return searchBy
    }, [searchBy, filters, autocompleteSearchBy]);

    //get list of objects
    const { data: objects, isLoading: objectsLoading } = useQuery(
        [model, autocompleteSearchBy,filters, orderBy,order, include],
        () =>
            Api.genericQueryModel(model, {
                page: 0,
                per_page: 10,
                with: include,
                filters: _filters,
                orderBy: orderBy,
                order: order,
            }),
        {
            select: (data) => data?.data?.data,
            enabled:
                autocompleteSearchBy.trim() != "" &&
                autocompleteSearchBy != null,
        }
    );

    useEffect(() => {
        if (objectId == -1) {
            setInputValue("");
            setSelected(null);
        }
    }, [objectId]);

    //get selected
    const { data: singleObject } = useQuery(
        [model, objectId],
        () =>
            Api.genericQueryByIdModel(model, objectId, {
                with: include,
            }),
        {
            select: (data) => data?.data?.data,
            enabled: Boolean(objectId) && objectId > 0,
        }
    );

    useEffect(() => {
        if (singleObject) {
            setSelected(singleObject);
            // setInputValueInvoice(optionLabel(singleInvoice));
        }
    }, [singleObject]);

    const debouncedSave = useCallback(
        debounce((newValue) => setAutocompleteSearchBy(newValue), 500),
        []
    );

    return (
        <Autocomplete
            value={selected}
            onReset={() => setSelected(null)}
            open={autocompleteOpen}
            disabled={disabled}
            onOpen={() => {
                setAutocompleteOpen(true);
            }}
            onClose={() => {
                setAutocompleteOpen(false);
            }}
            getOptionSelected={(option, value) =>
                isInteger(value) ? option.id === value : option.id === value.id
            }
            getOptionLabel={(option) =>
                optionLabel ? optionLabel(option, singleObject) : undefined
            }
            inputValue={inputValue}
            options={selected ? [selected, ...(objects ?? [])] : objects ?? []}
            onChange={(event, newInputValue) => {
                onChange(newInputValue?.id);
                setSelected(newInputValue);
            }}
            renderOption={(option, { selected }) => (
                <RenderOption option={option} selected={selected} />
            )}
            fullWidth={true}
            loading={objectsLoading}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                debouncedSave(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    error={error}
                    disabled={disabled}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {objectsLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AutocompleteGeneric;
