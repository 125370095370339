import React, { useState } from "react";
import styled from "styled-components";
import { Button as MuiButton, makeStyles, Menu, MenuItem } from "@material-ui/core";


import TimelapseIcon from '@material-ui/icons/Timelapse';

import { spacing } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  button:{
     "&.MuiButton-containedSecondary":{
      height: 25,
      width: 5,
      marginTop: 9.5,
     } ,


 
}}));


const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const views = [
  {
    id: "custom",
    label: "Custom",
  },
  {
    id: "t",
    label: "Today",
  },
  {
    id: "-1d",
    label: "Yesterday",
  },
  {
    id: "-7d",
    label: "Last 7 days",
  },

];

const years = (back) => {
  const year = new Date().getFullYear();
  const years = Array.from({length: back}, (v, i) => year - back + i + 1).reverse();
  return years.map( (year)=>{
    return {
      id: "y",
      label: year
    }
  })
}


const Actions = (props) => {
  const { disableDates, canYearRange,userCanDateRange3 = false, size = null, ...other } = props;

  const yearsArray = years(3);

  let views2 = views;
  if(disableDates==false || userCanDateRange3 == true){
    views2 = views.concat([
      {
        id: "-1w",
        label: "Last Week",
      },
      {
        id: "m",
        label: "This month",
      },
      {
        id: "-1m",
        label: "Last month",
      },
    ]);

    if(canYearRange==true){
      views2 = views2.concat(yearsArray);

    }

  }
  
  const classes = useStyles();
  const { onChange, icon = false } = props;
  const [currView, setCurrView] = useState(views2[0]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const changeView = (v) => {
    setCurrView(v);
    setAnchorEl(null);
    onChange(v);
  };

  return (
    <React.Fragment>
      <SmallButton mr={2}></SmallButton>
      <Button
        className={size? classes.button: ''}
        variant="contained"
        color="secondary"
        startIcon={icon == "time"?<TimelapseIcon />:""}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {currView.label}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {views2.map((v) => {
          return (
            <MenuItem key={v.id} onClick={() => changeView(v)}>
              {v.label}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default Actions;
