import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isEmpty, findIndex } from "lodash";
import moment from "moment";
import Api from "../lib/api";
import { useDispatch, useSelector } from "react-redux";
import Controls from "./controls";
import ConfirmDialog from "./ConfirmDialog";
import VOID from "../assets/img/icons/VOID.png";

import CloseIcon from "@material-ui/icons/Close";
import Iconify from "./Iconify";
import MTable from "../components_generic/MTableR";
import { fetchCancellationMotivesService } from "../redux/slices/cancellationMotives";
import { red } from "@material-ui/core/colors";

import {
  makeStyles,
  Box,
  Paper,
  Button,
  Switch,
  Tab,
  Tabs,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  Dialog,
  IconButton,
  Tooltip,
  Grid,
  ListItem,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import PropTypes from "prop-types";

import useTable from "./useTable";
import Popup from "./Popup";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";

import PreoperationData from "./PreoperationData";
import PreoperationDataOrigen from "./PreoperationDataOrigen";

import { useTranslation } from "react-i18next";
import CRMUtils from "../utils";
import ChangeProcedureStatusForm from "./forms/ChangeProcedureStatusForm";
import LoadingScreen from "./LoadingScreen";
import ChangeProcedureCoordinator from "./forms/ChangeProcedureCoordinator";
import ChangeProcedureBuilding from "./forms/ChangeProcedureBuilding";
import TransferProcedureForm from "./forms/TransferProcedureForm";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import EMRTransferRequests from "./EMRTransferRequests";

const useStyles2 = makeStyles((theme) => ({
  rowVoid: {
    backgroundColor: red[50],
  },
  bulkActionsButton: {
    textAlign: "left",
    color: "#41a0d9",
    backgroundColor: "#b5cee32b",
    marginLeft: 6,
    marginBottom: 12,
    "&:hover": {
      backgroundColor: "#1764a52b",
    },
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
}));

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 1}px
    ${(props) => props.theme.spacing(1)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Preoperation(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const TODAY = moment();

  const { authUser } = useSelector((state) => state.auth);
  const { record } = props;
  const { cancellationMotives } = useSelector(
    (state) => state.cancellationMotives
  );
  const dispatch = useDispatch();
  const [productRecords, setProductRecords] = useState([]);
  const [productRecordsOrigen, setProductRecordsOrigen] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [progressBar2, setProgressBar2] = useState(false);
  const [recordProductForEdit, setRecordProductForEdit] = useState(null);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [recordProductOrigen, setRecordProductOrigen] = useState(null);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openConfirmationStatus, setOpenConfirmationStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingRecordProduct, setLoadingRecordProduct] = useState(false);
  const [submittingTransfer, setSubmittingTransfer] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [changeCoordinatorAfterCloseRP, setChangeCoordinatorAfterCloseRP] =
    useState(null);

  const [changeCoordinatorAfterClose, setChangeCoordinatorAfterClose] =
    useState(null);

  const [newAfterCloseCoordinators, setNewAfterCloseCoordinators] = useState(
    []
  );

  const [
    changeCoordinatorAfterCloseRPOpen,
    setChangeCoordinatorAfterCloseRPOpen,
  ] = useState(false);

  const closeChangeCoordinatorAfterCloseRPOpen = () => {
    setChangeCoordinatorAfterCloseRPOpen(false);
    setChangeCoordinatorAfterCloseRP(null);
    setChangeCoordinatorAfterClose(null);
  };

  const submitChangeCoordinatorAfterClose = async () => {
    setProgressBar(true);

    const { data } = await Api.changeCoordinatorAfterClose(
      changeCoordinatorAfterCloseRP.id,
      changeCoordinatorAfterClose
    );

    if (data && data.message) {
      closeChangeCoordinatorAfterCloseRPOpen();

      getRecordProducts().then(() => {
        setProgressBar(false);
      });
    }
  };

  const openNewCoordinatorAfterClose = async (rowData) => {
    if (rowData && rowData.responsible_id) {
      const { data } = await Api.getUserCoordinatorsPlain(
        rowData.responsible_id
      );

      if (data && data.data) {
        setNewAfterCloseCoordinators(data.data);
        setChangeCoordinatorAfterCloseRP(rowData);
        setChangeCoordinatorAfterCloseRPOpen(true);
      }
    }
  };

  const [logs, setLogs] = useState([]);

  const [openLogPopup, setOpenLogPopup] = useState(false);

  const headCellsMaterialLogs = [
    {
      field: "by",
      title: "Log Created By",
      width: "10%",
    },
    {
      field: "created_at",
      title: "Date",
      width: "10%",
    },
    {
      field: "event",
      title: "Event",
      width: "10%",
    },
    {
      field: "activities",
      width: "65%",
      title: "Activities",
      render: (rowData) => {
        return rowData.activities && rowData.activities.length > 0 ? (
          <Grid container>
            {rowData.activities.map((activity) => {
              return (
                <Grid item xs={4}>
                  <ListItem style={{ padding: 0, margin: 0, marginLeft: 2 }}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.listItemTextBold}
                        >
                          {activity.property}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem style={{ padding: 0, margin: 0, marginLeft: 2 }}>
                    <ListItemText
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        maxWidth: "600px",
                        textAlign: "justify",
                      }}
                      primary={`New: ${
                        activity.new != "" ? activity.new : "None"
                      }`}
                      secondary={`Old: ${
                        activity.old != "" ? activity.old : "None"
                      }`}
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        );
      },
    },
  ];

  const showQuoteLogs = async (row) => {
    const { data } = await Api.getRecordProductLogs(row.id);
    try {
      if (data && data.data) {
        setLogs(data.data);
        setOpenLogPopup(true);
      }
    } catch (err) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  const onCloseLog = async (row) => {
    setOpenLogPopup(false);
    setLogs([]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedRecordProducts([]);

    if (newValue == 0) {
      setProgressBar(true);
      getRecordProducts().then(() => {
        setProgressBar(false);
      });
    }

    if (newValue == 2) {
      setProgressBar2(true);
      getRecordProductsOrigen().then(() => {
        setProgressBar2(false);
      });
    }
  };

  const { t, i18n } = useTranslation(["common", "records"]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [userDoctors, setUserDoctors] = useState([]);
  const userCanSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_super",
  ]);

  const [selectedRecordProducts, setSelectedRecordProducts] = useState([]);
  const [openChangeCoordinator, setOpenChangeCoordinator] = useState(false);
  const [openChangeBuilding, setOpenChangeBuilding] = useState(false);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const { usersDropdown } = useSelector((state) => state.users);
  const userIsBillingSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_billingSuper",
  ]);

  const userCanFinalize = CRMUtils.userHasPermission(authUser, [
    "recordproduct_close",
  ]);
  const userCanEditRP = CRMUtils.userHasPermission(authUser, [
    "recordproduct_edit",
  ]);
  const userCanFinalizeAfter = CRMUtils.userHasPermission(authUser, [
    "recordproduct_closeAfter",
  ]);

  const userCanReOpen = CRMUtils.userHasPermission(authUser, [
    "recordproduct_reopen",
  ]);

  const userCanBillingReady = CRMUtils.userHasPermission(authUser, [
    "recordproduct_billingReady",
  ]);

  const userCanDelete = CRMUtils.userHasPermission(authUser, [
    "recordproduct_delete",
  ]);

  const userCanChangeCoordinatorAfterClose = CRMUtils.userHasPermission(
    authUser,
    ["recordproduct_changeCoordinatorClose"]
  );
  const userCanSeeLogs =
    CRMUtils.userHasRole(authUser, ["admin", "super-admin"]) ||
    CRMUtils.userHasPermission(authUser, ["recordproduct_viewLogs"]);

  const userCanSuperTransfer = CRMUtils.userHasPermission(authUser, [
    "recordproduct_supertransfer",
  ]);
  const userCanTransfer = CRMUtils.userHasPermission(authUser, [
    "recordproduct_transfer",
  ]);
  const transferRecordProduct =
    authUser && authUser.organization.data.transferRecordProduct ? true : false;

  const status_options = [
    {
      id: "TENTATIVE",
      title: t("records:records.tentative"),
    },
    {
      id: "SCHEDULED",
      title: t("records:records.scheduled"),
    },
    {
      id: "CONFIRMED",
      title: t("records:records.confirmed"),
    },
    {
      id: "LABS RECEIVED",
      title: t("records:records.labs_received"),
    },
    {
      id: "READY",
      title: t("records:records.ready"),
    },
    {
      id: "CLEARED",
      title: t("records:records.cleared"),
    },
    {
      id: "CANCELED",
      title: t("records:records.canceled"),
    },
    {
      id: "PENDING FOR RE-SCHEDULE",
      title: t("records:records.pending"),
    },
    {
      id: "REFUND",
      title: t("records:records.refund"),
    },
  ];

  const headCells = [
    { id: "name", label: t("common:general.Name") },
    { id: "pre-date", label: t("records:records.predate") },
    { id: "date", label: t("common:general.Date") },
    { id: "salesman", label: t("common:general.Consultant") },
    { id: "responsible", label: t("common:general.Surgeon") },
    { id: "coordinator", label: t("records:records.coordinator") },
    { id: "void", label: t("Void") },
    { id: "balance", label: t("records:records.Balance") },
    { id: "completed", label: t("common:general.Completed") },

    { id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ];

  const updateRecordProductData = (newRecord) => {
    setRecordProductForEdit(newRecord);

    const index = findIndex(productRecords, ["id", newRecord.id]);
    const localproductRecord = { ...productRecords[index], ...newRecord };

    let localproductRecords = [...productRecords];
    localproductRecords[index] = localproductRecord;

    setProductRecords(localproductRecords);
    setSelectedRecordProducts([]);
  };

  const updateRecordProductDataCost = (id, costs, deleted_id) => {
    const index = findIndex(productRecords, ["id", id]);
    let localproductRecord = {};
    if (deleted_id) {
      localproductRecord = {
        ...recordProductForEdit, //...productRecords[index],
        recordProductCosts: {
          data: recordProductForEdit["recordProductCosts"]["data"].filter(
            (cost) => {
              return cost.id !== deleted_id;
            }
          ),
        },
      };
    } else {
      localproductRecord = {
        ...recordProductForEdit, //...productRecords[index],
        recordProductCosts: {
          data: costs,
        },
      };
    }
    let localproductRecords = [...productRecords];
    localproductRecords[index] = localproductRecord;

    setRecordProductForEdit(localproductRecord);
    setProductRecords(localproductRecords);
  };

  const getRecordProducts = async () => {
    const { data } = await Api.getRecordProductsPlain(record.id);
    setProductRecords(data.data);
  };

  const getRecordProductsOrigen = async () => {
    const { data } = await Api.getRecordProductsPlainOrigen(record.id);
    setProductRecordsOrigen(data.data);
  };

  const onDelete = async (id) => {
    const { data } = await Api.deleteProductRecord(id);

    const newProductRecords = productRecords.filter((productRecord) => {
      return productRecord.id != id;
    });
    // console.log("newProductRecords", newProductRecords);

    setProductRecords(newProductRecords);
  };

  const DeleteRecordProduct = (id) => {
    onDelete(id);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  const openInPopup = async (item) => {
    setLoadingRecordProduct(true);
    const { data } = await Api.getProductRecordPlain(item.id);
    setRecordProductForEdit(data.data);

    setOpenPopup(true);
    setLoadingRecordProduct(false);
  };

  const openInPopup2 = async (item) => {
    const { data } = await Api.getProductRecordPlainOrigen(item.id);
    setRecordProductOrigen(data.data);

    setOpenPopup2(true);
  };

  const closePopup2 = () => {
    setOpenPopup2(false);
  };

  const getUserDoctors = async () => {
    const { data } = await Api.getUserDoctors(authUser.id);

    setUserDoctors([...data.data]);
  };

  /* const calulateTotalRefunds = (item) => {
    const refunds = item.invoice_item.payment_detail
      ? item.invoice_item.payment_detail.filter((payment) => {
          return payment.isRefund;
        })
      : [];

    const totalRefunds = refunds.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    return totalRefunds;
  };*/

  /* const calulateTotalPayments = (item) => {
    const payments = item.invoice_item.payment_detail
      ? item.invoice_item.payment_detail.filter((payment) => {
          return !payment.isRefund;
        })
      : [];

    const totalPayments = payments.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    return totalPayments;
  };*/
  /*
  const calulateTotalCredits = (item) => {
    const totalPayments = item.invoice_item.credit_memo_detail
      ? item.invoice_item.credit_memo_detail.reduce(
          (total, item) => total + parseFloat(item.amount),
          0
        )
      : 0;
    return totalPayments;
  };*/

  /*const calcDue = (item) => {
    const Due = item.invoice_item
      ? item.invoice_item.quantity * item.invoice_item.price -
        (calulateTotalPayments(item) +
          calulateTotalCredits(item) -
          calulateTotalRefunds(item))
      : 0;

    return Due;
  };*/
  const closePopup = () => {
    setOpenPopup(false);
  };
  const { TblContainer, TblHead, TblPagination } = useTable(
    productRecords,
    headCells
  );

  const handleFinalizedChange = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    Api.updateProductRecord(item.id, {
      finalized: e.target.checked,
    }).then((result) => {
      const updatedItems = productRecords.map((record) => {
        if (record.id === item.id) {
          return {
            ...record,
            finalized: result.data.data.finalized,
          };
        }

        return record;
      });
      setProductRecords(updatedItems);
      setSelectedRecordProducts([]);
    });
  };

  const handleBillingReadyChange = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    Api.updateProductRecord(item.id, {
      billing_ready: e.target.checked,
    }).then((result) => {
      const updatedItems = productRecords.map((record) => {
        if (record.id === item.id) {
          return {
            ...record,
            billing_ready: result.data.data.billing_ready,
          };
        }

        return record;
      });
      setProductRecords(updatedItems);
      setSelectedRecordProducts([]);
    });
  };

  const canFinalize = (item) => {
    const can = item.date
      ? !userCanFinalizeAfter &&
        TODAY.format("YYYY-MM-DD") >=
          moment(item.date).add(11, "days").format("YYYY-MM-DD")
        ? false
        : userCanFinalize &&
          item.date <= TODAY.format("YYYY-MM-DD HH:mm:ss") &&
          item.invoiceBalance <= 0 &&
          item.responsible_id != null &&
          (item.billing_ready_mandatory === 0 ||
            (item.billing_ready_mandatory === 1 && item.billing_ready))
        ? true
        : false
      : false;

    return can;
  };

  useEffect(() => {
    setProgressBar(true);

    getRecordProducts().then(() => {
      setProgressBar(false);
    });
    if (isEmpty(cancellationMotives))
      dispatch(fetchCancellationMotivesService(authUser.organization_id));

    getUserDoctors();
    if (isEmpty(usersDropdown)) {
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
    }
  }, []);

  const productRecordsColumns = [
    {
      title: t("common:general.Name"),
      field: "invoice_item_name",
      render: (rowData) => {
        return (
          <Typography>
            {rowData.invoice_item_name}
            {rowData.is_revision ? (
              <strong
                style={{
                  color: "#326a9b",
                  marginLeft: 5,
                  fontWeight: 400,
                }}
              >
                {"(Revision)"}
              </strong>
            ) : (
              ""
            )}
          </Typography>
        );
      },
    },
    {
      title: t("records:records.predate"),
      field: "pre_date",
    },
    {
      title: t("common:general.Date"),
      field: "date",
    },
    {
      title: t("common:general.Consultant"),
      field: "salesman_name",
    },
    {
      title: t("common:general.Surgeon"),
      field: "responsible_name",
    },
    {
      title: t("records:records.coordinator"),
      field: "coordinator_name",
    },
    {
      title: t("Void"),
      field: "void",
      render: (rowData) => {
        return rowData.void ? (
          <img
            src={VOID}
            alt={"VOID"}
            style={{ width: "50px", height: "auto" }}
          />
        ) : (
          ""
        );
      },
    },
    {
      title: t("records:records.Balance"),
      field: "balance",
      render: (rowData) => {
        return rowData.itemBalance
          ? rowData.itemBalance.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "";
      },
    },
    {
      title: "Billing Ready",
      field: "billing_ready",
      render: (rowData) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                borderRadius: 25,
                backgroundColor: rowData.billing_ready ? "#e1f5fe" : "#ffebee",
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow:
                  "0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)",
                marginBottom: 5,
              }}
            >
              <Typography
                style={{
                  color: rowData.billing_ready ? "#0288d1" : "#d32f2f",
                  fontWeight: 700,
                }}
              >
                {rowData.billing_ready ? "Yes" : "No"}
              </Typography>
            </Box>

            <Switch
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              checked={rowData.billing_ready}
              disabled={
                (!rowData.billing_ready_payment_approved_mandatory &&
                  (!userCanBillingReady || rowData.finalized)) ||
                (rowData.billing_ready_payment_approved_mandatory &&
                  (!rowData.quoteApproved || rowData.invoiceBalance > 0))
              }
              onChange={(e) => handleBillingReadyChange(e, rowData)}
              name="billing_ready"
              color="primary"
            />
          </Box>
        );
      },
    },
    {
      title: t("common:general.Completed"),
      field: "completed",
      render: (rowData) => {
        // console.log("authUser: ", authUser);
        // console.log("record_product: ", rowData);
        return (
          <>
            <Typography>{rowData.finalized ? "Yes" : "No"}</Typography>
            <Switch
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              checked={rowData.finalized}
              disabled={
                (!rowData.finalized && !canFinalize(rowData)) ||
                (rowData.finalized && !userCanReOpen) ||
                (!rowData.quoteApproved &&
                  authUser.organization &&
                  authUser.organization.data &&
                  authUser.organization.data.quote_approved_mandatory)
              }
              onChange={(e) => handleFinalizedChange(e, rowData)}
              name="finalized"
              color="primary"
            />
          </>
        );
      },
    },
    {
      title: `${t("common:general.Completed")} Comment`,
      field: "done_comment",
      render: (rowData) => {
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "120px",
            }}
          >
            {rowData.done_comment}
          </div>
        );
      },
    },
  ];

  const productRecordsColumns2 = [
    {
      title: t("common:general.Name"),
      field: "invoice_item_name",
    },
    {
      title: t("records:records.predate"),
      field: "pre_date",
    },
    {
      title: t("common:general.Date"),
      field: "date",
    },
    {
      title: t("common:general.Consultant"),
      field: "salesman_name",
    },
    {
      title: t("common:general.Surgeon"),
      field: "responsible_name",
    },
    {
      title: t("records:records.coordinator"),
      field: "coordinator_name",
    },
  ];

  const updateSelectedProcedures = (procedures) => {
    setSelectedRecordProducts(procedures);
  };

  const changeStatus = async (data) => {
    for (const recordProduct of selectedRecordProducts) {
      setChangeStatusLoading(true);
      var payload = {};
      if (data != undefined) {
        payload.record_products_id = recordProduct.id;
        payload.status = selectedStatus;
        payload.comment = data.comment;
        payload.tentative_date_before = recordProduct?.tentative_date || null;
        if (data.rp_date) payload.date_before = data.rp_date;
        if (data.date) {
          payload.date_after = moment(data.date).format("YYYY-MM-DD HH:mm:ss");
          payload.tentative_date_after = null;
        }
        if (data.tentative_date) {
          payload.tentative_date_after = moment(data.tentative_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        payload.created_by = authUser.id;
        payload.cancellation_reason = data.cancellation_reason;
        if (payload.date_after) {
          payload.date = payload.date_after;
        }

        if (data.preop_date) {
          payload.preop_date = moment(data.preop_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        await Api.createProductRecordStatusHistory(recordProduct.id, payload);
      }
    }

    await getRecordProducts();
    setOpenConfirmationStatus(false);
    handleClose();
    setSelectedRecordProducts([]);
    setChangeStatusLoading(false);
  };

  const updateRecordProduct = async (data) => {
    for (const recordProduct of selectedRecordProducts) {
      if (data) {
        await Api.updateProductRecord(recordProduct.id, data);
      }
    }

    await getRecordProducts();
    setOpenChangeBuilding(false);
    setOpenChangeCoordinator(false);
    setSelectedRecordProducts([]);
  };

  const userFromDoctor = (id) => {
    if (userCanSuper) {
      return true;
    }

    if (!isEmpty(userDoctors)) {
      const index = findIndex(userDoctors, ["id", id]);

      return index >= 0 ? true : false;
    }
    return false;
  };

  const disableTransfer = () => {
    if (selectedRecordProducts && selectedRecordProducts.length > 0) {
      var withPendingTransfers = selectedRecordProducts.find(
        (rp) =>
          rp.recordProductTransfers &&
          rp.recordProductTransfers.data &&
          rp.recordProductTransfers.data.find(
            (recordProductTransfer) =>
              recordProductTransfer.status === "pending"
          )
      );
      return !isEmpty(withPendingTransfers);
    } else {
      return true;
    }
  };

  const hasDistinctNonNullResponsibles = (arr) => {
    const uniqueResponsibles = new Set(); // Usamos un Set para almacenar valores únicos

    for (const item of selectedRecordProducts) {
      if (item.responsible_id !== null) {
        uniqueResponsibles.add(item.responsible_id);
      }
    }

    return uniqueResponsibles.size >= 2;
  };

  const getResponsibleOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
          building_id: user.building_id,
        };
      });
  };

  const getCoordinatorOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_coordinator === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const closeTransferPopup = () => {
    setIsTransferOpen(false);
  };

  const saveRecordProductTransferdata = async (payload) => {
    if (typeof setSubmittingTransfer == "function") {
      setSubmittingTransfer(true);
    }
    if (!payload.transfer) {
      if (
        authUser &&
        authUser.organization &&
        !isEmpty(authUser.organization.data) &&
        authUser.organization.data.force_transfer_surgeries_with_same_doctor
      ) {
        payload.record_products = selectedRecordProducts.map((srp) => {
          return {
            ...{
              record_products_id: srp.id,
              old_building_id: srp.building_id,
              old_coordinator_id: srp.coordinator_id,
              old_responsible_id: srp.responsible_id,
              organization_id: authUser.organization_id,
            },
            ...payload,
          };
        });
        const { data } = await Api.createMultiRecordProductTransfer(payload);
      } else {
        selectedRecordProducts.forEach(async (rp) => {
          payload.record_products_id = rp.id;
          payload.old_building_id = rp.building_id;
          payload.old_coordinator_id = rp.coordinator_id;
          payload.old_responsible_id = rp.responsible_id;
          payload.organization_id = authUser.organization_id;
          const { data } = await Api.saveRecordProductTransfer(payload);
        });
      }
    }
    await getRecordProducts();
    if (typeof setSubmittingTransfer == "function") {
      setSubmittingTransfer(false);
    }

    closeTransferPopup();
    closePopup();
    setSelectedRecordProducts([]);
  };

  return (
    <Wrapper>
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={t("common:general.EMR")} />
          <Tab label={"Transfer Requests"} />
          <Tab label={`Origin: ${t("common:general.EMR")}`} />
        </Tabs>
        <TabPanel value={tabValue} index={0} {...a11yProps(0)}>
          <Box p={2}>
            {selectedRecordProducts.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  style={{ fontSize: 17, marginLeft: 8, fontWeight: 500 }}
                >
                  {`Selected rows: ` + selectedRecordProducts.length}
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Change status">
                    <span>
                      <IconButton
                        disabled={
                          (!userCanEditRP ||
                            selectedRecordProducts.find(
                              (rp) => !userFromDoctor(rp.responsible_id)
                            )) &&
                          !userCanSuperTransfer &&
                          !userIsBillingSuper
                        }
                        onClick={(e) => {
                          handleClick(e);
                        }}
                        aria-controls="customized-menu"
                        className={classes2.bulkActionsButton}
                      >
                        <Iconify icon="fluent-mdl2:sync-status" />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title="Change coordinator">
                    <span>
                      <IconButton
                        disabled={
                          (transferRecordProduct &&
                            selectedRecordProducts.find(
                              (rp) => rp.coordinator_id
                            )) ||
                          (!userCanSuper &&
                            selectedRecordProducts.find(
                              (rp) => rp.coordinator_id
                            )) ||
                          !userCanEditRP ||
                          selectedRecordProducts.find(
                            (rp) => !userFromDoctor(rp.responsible_id)
                          )
                        }
                        onClick={(e) => {
                          setOpenChangeCoordinator(true);
                        }}
                        aria-controls="customized-menu"
                        className={classes2.bulkActionsButton}
                      >
                        <Iconify icon="mdi:person-convert" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  {authUser.organization.data.multiple_locations === 1 && (
                    <Tooltip title="Change building">
                      <span>
                        <IconButton
                          disabled={
                            (((transferRecordProduct &&
                              selectedRecordProducts.find(
                                (rp) => rp.building_id
                              )) ||
                              !userCanEditRP ||
                              selectedRecordProducts.find(
                                (rp) => !userFromDoctor(rp.responsible_id)
                              )) &&
                              !userIsBillingSuper) ||
                            hasDistinctNonNullResponsibles()
                          }
                          onClick={(e) => {
                            setOpenChangeBuilding(true);
                          }}
                          aria-controls="customized-menu"
                          className={classes2.bulkActionsButton}
                        >
                          <Iconify icon="bi:building-fill-gear" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                  {(transferRecordProduct && userCanSuperTransfer) ||
                  (transferRecordProduct &&
                    !selectedRecordProducts.find(
                      (rp) => !userFromDoctor(rp.responsible_id)
                    ) &&
                    userCanTransfer) ? (
                    <Tooltip title="Request transfer">
                      <span>
                        <IconButton
                          onClick={(e) => {
                            setIsTransferOpen(true);
                          }}
                          disabled={
                            disableTransfer() ||
                            (selectedRecordProducts.find(
                              (rp) => !userFromDoctor(rp.responsible_id)
                            ) &&
                              !userCanSuperTransfer) ||
                            (!selectedRecordProducts.find(
                              (rp) => !userFromDoctor(rp.responsible_id)
                            ) &&
                              !userCanTransfer &&
                              !userCanSuperTransfer) ||
                            !userCanEditRP ||
                            ((
                              authUser &&
                              authUser.organization &&
                              !isEmpty(authUser.organization.data)
                                ? authUser.organization.data
                                    .force_transfer_surgeries_with_same_doctor
                                : false
                            )
                              ? !selectedRecordProducts.every(
                                  (rp) =>
                                    rp.responsible_id ==
                                    selectedRecordProducts[0].responsible_id
                                ) ||
                                productRecords.find(
                                  (pR) =>
                                    pR.responsible_id ==
                                      selectedRecordProducts[0]
                                        .responsible_id &&
                                    !selectedRecordProducts.find(
                                      (sP) => sP.id == pR.id
                                    ) &&
                                    !pR.finalized
                                )
                              : false)
                          }
                          aria-controls="customized-menu"
                          className={classes2.bulkActionsButton}
                        >
                          <Iconify icon="mingcute:transfer-3-fill" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <MTable
              onSelectionChange={updateSelectedProcedures}
              data={productRecords}
              columns={productRecordsColumns}
              headerColor={"#455a64"}
              headerBackgroundColor={"#e0f2f1"}
              pageSize={5}
              pageSizeOptions={[5, 10, 25]}
              showPaging
              onRowClick={(event, rowData) => {
                openInPopup(rowData);
              }}
              isLoading={progressBar}
              optionstoAdd={{
                actionsColumnIndex: -1,
                selection: true,
                showSelectAllCheckbox: false,
                selectionProps: (rowData) => {
                  return {
                    disabled:
                      rowData.finalized || !userCanEditRP || submittingTransfer,
                  };
                },
              }}
              actions={[
                {
                  action: (rowData) => {
                    return {
                      icon: () => (
                        <Controls.IconButton
                          style={{
                            color:
                              !userCanChangeCoordinatorAfterClose ||
                              !rowData.finalized
                                ? "#bdbdbd"
                                : "#43a047",
                          }}
                        >
                          <Iconify
                            icon={"fluent:person-sync-32-filled"}
                            width={25}
                            height={25}
                          />
                        </Controls.IconButton>
                      ),
                      disabled:
                        !userCanChangeCoordinatorAfterClose ||
                        !rowData.finalized,
                      tooltip: "Change Coordinator After Close",
                      position: "row",
                      onClick: (event, rowData) => {
                        openNewCoordinatorAfterClose(rowData);
                      },
                    };
                  },
                  position: "row",
                },
                {
                  action: (rowData) => {
                    return {
                      icon: () =>
                        !(rowData?.invoiceItemPrice === rowData?.itemBalance) &&
                        !rowData.finalized ? (
                          <div>
                            <Controls.IconButton
                              style={{
                                color:
                                  rowData.finalized ||
                                  !(
                                    rowData?.invoiceItemPrice ===
                                    rowData?.itemBalance
                                  )
                                    ? "#bdbdbd"
                                    : "#f44336",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    zIndex: 10,
                                    top: 8,
                                    left: 22,
                                    fontSize: 20,
                                  }}
                                >
                                  <Iconify
                                    icon={"fluent:info-16-filled"}
                                    style={{ color: "#009688" }}
                                    width={16}
                                    height={16}
                                  />
                                </div>
                                <Iconify
                                  icon={"mingcute:delete-fill"}
                                  width={25}
                                  height={25}
                                />
                              </div>
                            </Controls.IconButton>
                          </div>
                        ) : (
                          <Controls.IconButton
                            style={{
                              color:
                                rowData.finalized ||
                                !(
                                  rowData?.invoiceItemPrice ===
                                  rowData?.itemBalance
                                )
                                  ? "#bdbdbd"
                                  : "#f44336",
                            }}
                          >
                            <Iconify
                              icon={"mingcute:delete-fill"}
                              width={25}
                              height={25}
                            />
                          </Controls.IconButton>
                        ),

                      hidden: !userCanDelete,
                      disabled:
                        rowData.finalized ||
                        !(rowData?.invoiceItemPrice === rowData?.itemBalance),
                      tooltip:
                        !(rowData?.invoiceItemPrice === rowData?.itemBalance) &&
                        !rowData.finalized
                          ? "Item has active payments. Please transfer or remove payments before deleting the procedure."
                          : "Delete",
                      position: "row",
                      onClick: (event, rowData) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to delete this refund?",
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            DeleteRecordProduct(rowData.id);
                          },
                        });
                      },
                    };
                  },
                  position: "row",
                },
                {
                  action: (rowData) => {
                    return {
                      icon: () => (
                        <Controls.IconButton
                          style={{
                            color: "#673ab7",
                          }}
                        >
                          <Iconify icon={"subway:eye"} width={25} height={25} />
                        </Controls.IconButton>
                      ),

                      hidden: !userCanSeeLogs,
                      disabled: !userCanSeeLogs,
                      tooltip: "Logs",
                      position: "row",
                      onClick: (event, rowData) => {
                        showQuoteLogs(rowData);
                      },
                    };
                  },
                  position: "row",
                },
              ]}
            />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1} {...a11yProps(1)}>
          <EMRTransferRequests recordID={record?.id || null} />
        </TabPanel>

        <TabPanel value={tabValue} index={2} {...a11yProps(2)}>
          <Box p={2}>
            <MTable
              data={productRecordsOrigen}
              columns={productRecordsColumns2}
              headerColor={"#000000"}
              headerBackgroundColor={"#e0f2f1"}
              pageSize={5}
              pageSizeOptions={[5, 10, 25]}
              rowColorAlternate={true}
              rowColorAlternateArray={[
                { color: "#ffffff" },
                { color: "#ffebee" },
              ]}
              onRowClick={(event, rowData) => {
                openInPopup2(rowData);
              }}
              isLoading={progressBar2}
              showPaging
              optionstoAdd={{
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  action: (rowData) => {
                    return {
                      icon: () => (
                        <Controls.IconButton
                          style={{
                            color: "#673ab7",
                          }}
                        >
                          <Iconify icon={"subway:eye"} width={25} height={25} />
                        </Controls.IconButton>
                      ),

                      hidden: !userCanSeeLogs,
                      disabled: !userCanSeeLogs,
                      tooltip: "Logs",
                      position: "row",
                      onClick: (event, rowData) => {
                        showQuoteLogs(rowData);
                      },
                    };
                  },
                  position: "row",
                },
              ]}
            />
          </Box>
        </TabPanel>
      </Paper>

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status_options.map((status) => {
          return (
            <MenuItem
              onClick={() => {
                setSelectedStatus(status.id);
                setOpenConfirmationStatus(true);
                setAnchorEl(null);
              }}
            >
              <ListItemText primary={status.title} />
            </MenuItem>
          );
        })}
      </Menu>
      <Popup
        fullScreen
        title={
          recordProductForEdit
            ? `Data for ${recordProductForEdit.invoice_item_name}`
            : ""
        }
        openPopup={openPopup}
        onClose={closePopup}
        fullWidth={true}
      >
        <PreoperationData
          setSubmittingTransfer={setSubmittingTransfer}
          recordProductdatas={recordProductForEdit}
          updateRecordProductData={updateRecordProductData}
          updateRecordProductDataCost={updateRecordProductDataCost}
          closePopup={closePopup}
        />
      </Popup>
      <Popup
        fullScreen
        title={
          recordProductOrigen
            ? `Data for ${recordProductOrigen.invoice_item_name}`
            : ""
        }
        openPopup={openPopup2}
        onClose={closePopup2}
        fullWidth={true}
      >
        <PreoperationDataOrigen
          recordProductdatas={recordProductOrigen}
          updateRecordProductData={updateRecordProductData}
          updateRecordProductDataCost={updateRecordProductDataCost}
          closePopup={closePopup}
        />
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title={t("common:general.Status_con")}
        openPopup={openConfirmationStatus}
        onClose={() => setOpenConfirmationStatus(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <ChangeProcedureStatusForm
          showPredate={true}
          isSubmitting={changeStatusLoading}
          cancellationMotives={cancellationMotives}
          selectedStatus={selectedStatus}
          changeStatus={changeStatus}
          selectedRecordProducts={selectedRecordProducts}
        />
      </Popup>

      <Popup
        title={"Change coordinator"}
        openPopup={openChangeCoordinator}
        onClose={() => setOpenChangeCoordinator(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <ChangeProcedureCoordinator changeCoordinator={updateRecordProduct} />
      </Popup>

      <Popup
        title={"Change building"}
        openPopup={openChangeBuilding}
        onClose={() => setOpenChangeBuilding(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <ChangeProcedureBuilding
          selectedRecordProducts={selectedRecordProducts}
          changeBuilding={updateRecordProduct}
        />
      </Popup>

      <Popup
        title={"Request Transfer"}
        openPopup={isTransferOpen}
        onClose={() => setIsTransferOpen(false)}
        fullWidth={true}
      >
        <TransferProcedureForm
          isSubmitting={submittingTransfer}
          responsibles={getResponsibleOptions()}
          coordinatorsD={getCoordinatorOptions()}
          transferForEdit={null}
          saveRecordProductTransferdata={saveRecordProductTransferdata}
        />
      </Popup>

      <Dialog
        open={loadingRecordProduct}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        {<LoadingScreen />}
      </Dialog>
      <Dialog
        open={openLogPopup}
        onClose={onCloseLog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            borderRadius: 25,
          },
        }}
      >
        <DialogTitle className={classes2.dialogTitle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
              }}
            >
              Logs
            </Typography>
            <Controls.IconButton
              onClick={onCloseLog}
              style={{
                textAlign: "right",
                color: "#000000",
                fontWeight: 700,
              }}
            >
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ margin: 30 }}>
          <Paper>
            <MTable
              data={logs}
              columns={headCellsMaterialLogs}
              headerColor={"#616161"}
              headerBackgroundColor={"#e8f5e9"}
              showPaging
              pageSize={5}
              pageSizeOptions={[5, 10, 15, 20, 25]}
              emptyRowsWhenPaging={false}
              search={false}
              toolbar={false}
            />
          </Paper>
        </DialogContent>
      </Dialog>

      <Dialog
        open={changeCoordinatorAfterCloseRPOpen}
        onClose={closeChangeCoordinatorAfterCloseRPOpen}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            borderRadius: 25,
          },
        }}
      >
        <DialogTitle className={classes2.dialogTitle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
              }}
            >
              Coordinator Change for Closed Surgeries
            </Typography>
            <Controls.IconButton
              onClick={closeChangeCoordinatorAfterCloseRPOpen}
              style={{
                textAlign: "right",
                color: "#000000",
                fontWeight: 700,
              }}
            >
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ marginTop: 30 }}>
          <ListItemText
            primary={
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Nunito,Roboto",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                {changeCoordinatorAfterCloseRP?.invoice_item_name}
              </Typography>
            }
          />
          <Controls.Select
            styleProp={true}
            label="New Coordinator"
            onChange={(e) => {
              setChangeCoordinatorAfterClose(e.target.value);
            }}
            options={newAfterCloseCoordinators?.filter(
              (coordinator) =>
                coordinator.id != changeCoordinatorAfterCloseRP?.coordinator_id
            )}
            value={changeCoordinatorAfterClose}
          />
        </DialogContent>
        <DialogContent style={{ marginTop: 15, marginBottom: 30 }}>
          <ListItemText
            primary={
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "justify",
                  color: "#ef5350",
                  fontFamily: "Nunito,Roboto",
                  fontWeight: 600,
                  fontSize: "1rem",
                  fontStyle: "italic",
                }}
              >
                Note: Changing the coordinator for this procedure will also
                change the coordinators for all procedures scheduled on the same
                date. Additionally, the commission for these procedures will be
                transferred to the new coordinator.
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions
          dividers={true}
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: "#e0f2f1", margin: 15 }}
            onClick={closeChangeCoordinatorAfterCloseRPOpen}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            style={{ backgroundColor: "#ffebee", margin: 15 }}
            onClick={() => submitChangeCoordinatorAfterClose()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
}

export default Preoperation;
