import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import lime from "@material-ui/core/colors/lime";

import {
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  // TableCell,
  // TableRow,
  Typography as MuiTypography,
  Fab,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  // TextField,
  // Tooltip,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import AttachFileIcon from "@material-ui/icons/AttachFile";

import AddIcon from "@material-ui/icons/Add";
import Comments from "../../components/Comments";
import Refunds from "../../components/Refunds";
import Documents from "../../components/Documents";
import CRMUtils from "../../utils";
import Payments from "../../components/Payments";

import Responsible from "../../components/Responsible";
import Timeline from "../../components/Timeline";
// import EditOutlinedIcon from "@material-ui/icons/EditRounded";
// import DeleteIcon from "@material-ui/icons/Delete";
// import PersonIcon from "@material-ui/icons/Person";
import { isEmpty, uniqueId, findIndex } from "lodash";
import { spacing, display } from "@material-ui/system";

import moment from "moment";
// import * as momentTimeZone from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { addInvoicePaymentsService } from "../../redux/slices/invoices";
import { applyInvoiceCreditMemoService } from "../../redux/slices/invoices";
import Api from "../../lib/api";

import { fetchPaymentGatewaysService } from "../../redux/slices/paymentgateways";
import { fetchDocumentTypesService } from "../../redux/slices/documenttypes";
import { fetchUsersService } from "../../redux/slices/users";
import Controls from "../../components/controls";
// import InvoiceItemForm from "../../components/forms/InvoiceItemForm";
import Popup from "../../components/Popup";
import ConfirmDialog from "../../components/ConfirmDialog";

import Iconify from "../../components/Iconify";
import { useTranslation } from "react-i18next";
import InvoiceBuilder from "../../components/InvoiceBuilder";
import CreditMemosInvoices from "../../components/CreditMemosInvoices";
import useTable from "../../components/useTable";

import { fetchRefundReasonsService } from "../../redux/slices/refundReasons";
import { orange } from "@material-ui/core/colors";

// import InfoIcon from "@material-ui/icons/Info";

import { fetchEntitiesService as fetchProductsService } from "../../redux/slices/productsnewSlice.js";
import { fetchEntitiesService as fetchFinancingService } from "../../redux/slices/financingSlice.js";
import InvoicePaymentTransferHistories from "../../components/InvoicePaymentTransferHistories";
import PaymentsComponent from "../../components/PaymentsComponent.js";
import Notification from "../../components/Notification.js";

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceProduct = {
  stateListName: "productsNew",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
// const Card = styled(MuiCard)`
//   ${spacing};
//   box-shadow: none;
// `;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    height: "100%",
  },
  card: {
    position: "relative",
  },

  ribbon: {
    backgroundColor: "skyblue",
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    padding: 5,
    font: "Lato",
    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 60,
    marginLeft: -40,
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const statusArray = [
  {
    key: "unsend",
    label: "Unsend",
    color: grey[700],
  },
  {
    key: "sent",
    label: "Sent",
    color: blue[700],
  },
  {
    key: "unpaid",
    label: "Unpaid",
    color: green[700],
  },
  {
    key: "partial_paid",
    label: "Partial paid",
    color: lime[700],
  },
  {
    key: "paid",
    label: "Paid in full",
    color: red[700],
  },
  {
    key: "canceled",
    label: "Canceled",
    color: orange[700],
  },
];

function InvoiceDetails(props) {
  const dispatch = useDispatch();
  // const { productGiftReason } = useSelector((state) => state.productGiftReason);
  const {
    readOnlySalesManager = false,
    userIsCallCenter = false,
    userIsSocialMedia = false,
    userIsFrontDesk = false,
    userIsbillingMGAssistant = false,
    setInvoices,
    invoices,
    selectedTab = 0,
    getInvoicesContact = null,
    setOpenDetailPopup = null,
    closeOnConsultantChange = null,
    refreshInvoices = null,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation("invoices");

  const [contacts, setContacts] = useState({});
  const { authUser } = useSelector((state) => state.auth);
  dataSliceProduct.orgId = authUser.organization_id;
  const userCanCreateCredit = CRMUtils.userHasPermission(authUser, [
    "creditmemo_create",
  ]);

  const { financing } = useSelector((state) => state.financing);

  const userCanMakePayment = CRMUtils.userHasPermission(authUser, [
    "creditmemo_edit",
  ]);
  const userCanDelete = CRMUtils.userHasPermission(authUser, [
    "creditmemo_delete",
  ]);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);

  const userCanViewInvoice = CRMUtils.userHasPermission(authUser, [
    "invoice_view",
  ]);

  const { productsNew } = useSelector((state) => state.productsNew);
  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const { users } = useSelector((state) => state.users);
  const { documentTypes } = useSelector((state) => state.documenttypes);

  // const [editItem, setEditItem] = useState(null);
  // const [editItemIndex, setEditItemIndex] = useState(null);
  // const [openItem, setOpenItem] = useState(false);

  const [value, setValue] = useState(props?.selectedTab || 0);
  const [invoice, setInvoice] = useState({});
  const [status, setStatus] = useState(invoice ? invoice.status : "unsend");
  const [items, setItems] = useState([]);
  const [openCreditMemo, setOpenCreditMemo] = useState(false);
  const [dataRefundRequests, setDataRefundRequests] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openRefund, setOpenRefund] = useState(false);
  const { refundReasons } = useSelector((state) => state.refundReasons);
  // const [refundRequestsForEdit, setRefundRequestsForEdit] = useState(null);
  // const [notify, setNotify] = useState({
  //   isOpen: false,
  //   message: "",
  //   type: "",
  // });

  // const closeRefundPopup = () => {
  //   setOpenRefund(false);
  // };

  // const getColor = (status) => {
  //   switch (status) {
  //     case "pending":
  //       return "rgb(97, 97, 97)";
  //       break;
  //     case "approved":
  //       return "rgb(56, 142, 60)";
  //       break;
  //     case "declined":
  //       return "rgb(211, 47, 47)";
  //       break;
  //       return null;
  //   }
  // };

  const getContractsReports = async ($id) => {
    const { data } = await Api.getInvoiceRefundRequests($id);

    setDataRefundRequests(data.data);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const CreateCreditMemo = () => {
    setOpenCreditMemo(true);
  };
  const closeCreditMemo = () => {
    setOpenCreditMemo(false);
  };

  // const onDeleteItem = (id) => {
  //   const newItems = items.filter((item) => {
  //     return item.id !== id;
  //   });
  //   setItems(newItems);

  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });
  // };
  const saveResponsible = async (payload) => {
    const { data } = await Api.addInvResponsibles(invoice.id, {
      responsibleId: payload.responsibles.id,
    });

    setInvoice({
      ...invoice,
      items: {
        ...invoice.items,
        data: items,
      },
      responsibles: {
        data: data.data,
      },
    });
  };
  const {
    TblContainer /* , TblHead, TblPagination, recordsAfterPagingAndSorting */,
  } = useTable(items);

  const saveInvoice = async (localinvoice) => {
    const invoiceitems = localinvoice.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        product_id: item.product_id,
        item_name: item.name,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });

    const payload = {
      items: invoiceitems,
      status: localinvoice.status,
      included: localinvoice.included,
      contact_id: invoice.contact_id,
      financing_id: localinvoice.financing_id,
      interest: localinvoice.interest ? localinvoice.interest : null,
      user_id: localinvoice.user_id ? localinvoice.user_id : null,
      pay_before: localinvoice.pay_before ? localinvoice.pay_before : null,
    };

    try {
      const { data } = await Api.updateInvoice(invoice.id, payload);
      setInvoice(data.data);
      if (data.data) {
        if (getInvoicesContact) {
          getInvoicesContact();
        } else {
          if (invoices && typeof setInvoices == "function") {
            const index = findIndex(invoices, ["id", data.data.id]);
            const entity = { ...invoices[index], ...data.data };
            let entities = [...invoices];
            entities[index] = entity;

            setInvoices([...entities]);
          }
        }
      }
    } catch (e) {
      if (
        typeof closeOnConsultantChange === "function" &&
        e.data.message ===
          "*** This contact is already assigned to another consultant***"
      ) {
        closeOnConsultantChange(e.data.message);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const savePayment = async (payload) => {
    const momentDate = new moment(payload.payment_date);
    payload.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    payload.sale_created_at = payload.sale_created_at
      ? new moment(payload.sale_created_at).format("YYYY-MM-DD HH:mm:ss")
      : null;
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.saveInvoicePayment(invoice.id, formData);

    dispatch(addInvoicePaymentsService(data.data));
    if (getInvoicesContact) {
      getInvoicesContact();
    } else {
      await Api.getInvoice(invoice.id).then((result) => {
        if (invoices && typeof setInvoices == "function") {
          const index = findIndex(invoices, ["id", invoice.id]);
          const entity = { ...invoices[index], ...result.data.data };
          let entities = [...invoices];
          entities[index] = entity;
          setInvoices([...entities]);
        }

        setInvoice({
          ...result.data.data,
        });
      });
    }
  };

  const deletePayment = async (id) => {
    try {
      const { data } = await Api.deleteInvoicePayment(invoice.id, id);

      dispatch(addInvoicePaymentsService(data.data));

      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });
      if (getInvoicesContact) {
        getInvoicesContact();
      }
    } catch (error) {
      if (error.status == 409 && (error?.data?.error || false)) {
        setNotify({
          isOpen: true,
          message: error.data.error,
          type: "error",
        });
      } else {
        console.error(error);
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const markPossibleRefund = async (id, $mark) => {
    const payload = { mark: $mark };

    const { data } = await Api.markAsPossibleRefund(invoice.id, id, payload);

    dispatch(addInvoicePaymentsService(data.data));

    await Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });
  };

  const checkCouponsCompability = (invoiceValue) => {
    if (invoice) {
      var result = true;
      const appliedCoupons = invoice.applied_coupons;

      appliedCoupons.forEach((coupon) => {
        var value =
          parseFloat(invoiceValue) -
            (parseFloat(invoice.totalCredits) -
              parseFloat(coupon.coupon_value)) <=
          0
            ? 0
            : parseFloat(invoiceValue) -
              (parseFloat(invoice.totalCredits) -
                parseFloat(coupon.coupon_value));
        if (
          value < parseFloat(coupon.min_invoice_price) ||
          (!coupon.available_for_discounted_invoices && invoice.allow_discounts)
        ) {
          result = false;
        }
      });
    }

    return result;
  };

  const saveCreditMemo = async (payload) => {
    dispatch(applyInvoiceCreditMemoService(payload));

    setInvoice(payload);

    setOpenCreditMemo(false);

    if (getInvoicesContact) {
      getInvoicesContact();
    }
  };

  const getInvoice = async () => {
    try {
      const { data } = await Api.getInvoice(props.invoice.id);
      setInvoice(data.data);

      getContractsReports(props.invoice.id);
    } catch (e) {
      if (
        typeof closeOnConsultantChange === "function" &&
        e.data.message ===
          "*** This contact is already assigned to another consultant***"
      ) {
        closeOnConsultantChange(e.data.message);
      }
    }
  };

  const saveComment = async (payload) => {
    const { data } = await Api.createtInvoiceComment(invoice.id, payload);

    setInvoice({
      ...invoice,
      items: {
        ...invoice.items,
        data: items,
      },
      comments: {
        ...invoice.comments,
        data: [...invoice.comments.data, data.data],
      },
    });
  };
  // const contactOptions = () => {
  //   return contacts.map((contact) => {
  //     return {
  //       id: contact.id,
  //       title: contact.name,
  //     };
  //   });
  // };

  // const getnewContact = (value) => {
  //   const contact = contacts.find((contact) => contact.id == value);
  //   updateContact(contact);
  // };

  // const updateContact = (data) => {
  //   setInvoice({
  //     ...invoice,
  //     contact: {
  //       data: data,
  //     },
  //     contact_id: data.id,
  //   });
  // };
  const saveDocument = async (payload) => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.createtInvoiceDocument(invoice.id, formData);

    setInvoice({
      ...invoice,
      items: {
        ...invoice.items,
        data: items,
      },
      documents: {
        ...invoice.documents,
        data: [...invoice.documents.data, data.data],
      },
    });
  };

  // const addInvoiceItem = () => {
  //   setEditItem({});
  //   setEditItemIndex(null);
  //   setOpenItem(true);
  // };

  // const closeItemPopup = () => {
  //   setEditItem({});
  //   setEditItemIndex(0);
  //   setOpenItem(false);
  // };

  // const updateItemQuantity = (id, value) => {
  //   const newItems = items.map((item) => {
  //     if (item.id === id) {
  //       return {
  //         ...item,
  //         quantity: parseInt(value),
  //       };
  //     }
  //     return item;
  //   });
  //   setItems(newItems);
  // };

  // const updateItemDiscount = (id, value) => {
  //   const newItems = items.map((item) => {
  //     if (item.id === id) {
  //       return {
  //         ...item,
  //         discount: value,
  //       };
  //     }
  //     return item;
  //   });
  //   setItems(newItems);
  // };

  // const calculateItemTotal = (item) => {
  //   const quantity = item.quantity || 1;
  //   const discount = item.discount || 0;
  //   return item.price * parseInt(quantity) - discount * parseInt(quantity);
  // };

  // const calculateItemDiscount = (item) => {
  //   const quantity = item.quantity || 1;
  //   const discount = item.discount || 0;
  //   return discount * parseInt(quantity).toFixed(2);
  // };
  // const calculateTotalDiscount = () => {
  //   return items.reduce(
  //     (total, item) => total + calculateItemDiscount(item),
  //     0
  //   );
  // };

  // const calculateSubtotal = () => {
  //   return items.reduce((total, item) => total + calculateItemTotal(item), 0);
  // };

  // const calculateTotal = () => {
  //   const subtotal = calculateSubtotal();

  //   return subtotal;
  // };

  // const calulateTotalPayments = () => {
  //   const payments = invoice
  //     ? invoice.payments.data.filter((payment) => {
  //         return !payment.isRefund;
  //         /* &&
  //         (payment.possible_refund &&
  //         isEmpty(payment.paymentPossibleRefund?.data)
  //           ? false
  //           : true) */
  //       })
  //     : [];

  //   return payments.reduce((total, item) => total + parseFloat(item.amount), 0);
  // };

  // const calulateTotalRefunds = () => {
  //   const refunds = invoice
  //     ? invoice.payments.data.filter((payment) => {
  //         return payment.isRefund;
  //       })
  //     : [];

  //   return refunds.reduce((total, item) => total + parseFloat(item.amount), 0);
  // };

  // const calculateTotalDue = () => {
  //   const total = calculateTotal();
  //   const payments = calulateTotalPayments();
  //   return total - payments;
  // };

  // const renderItems = () => {
  //   return items.map((item, index) => {
  //     return (
  //       <TableRow key={item.id}>
  //         <TableCell component="th" scope="row">
  //           <ListItem>
  //             {item.responsible && (
  //               <ListItemIcon>
  //                 <PersonIcon />
  //               </ListItemIcon>
  //             )}
  //             <ListItemText
  //               primary={item.name}
  //               secondary={item.responsible ? item.responsible.title : null}
  //             />
  //           </ListItem>
  //         </TableCell>
  //         <TableCell>$ {item.price.toFixed(2)}</TableCell>
  //         <TableCell>
  //           <TextField
  //             value={item.quantity || 1}
  //             size="small"
  //             type="number"
  //             onChange={(e) => updateItemQuantity(item.id, e.target.value)}
  //           />
  //         </TableCell>

  //         <TableCell align="right">$ {calculateItemTotal(item)}</TableCell>

  //         {invoice.status !== "paid" && (
  //           <TableCell align="right">
  //             <Controls.IconButton
  //               color="primary"
  //               onClick={() => {
  //                 setEditItem(item);
  //                 setEditItemIndex(index);
  //                 setOpenItem(true);
  //               }}
  //             >
  //               <EditOutlinedIcon size="small" />
  //             </Controls.IconButton>
  //             <Controls.IconButton
  //               color="primary"
  //               onClick={() =>
  //                 setConfirmDialog({
  //                   isOpen: true,
  //                   title: "Are you sure to delete this item?",
  //                   subTitle: "You can't undo this operation",
  //                   onConfirm: () => onDeleteItem(item.id),
  //                 })
  //               }
  //             >
  //               <DeleteIcon size="small" />
  //             </Controls.IconButton>
  //           </TableCell>
  //         )}
  //       </TableRow>
  //     );
  //   });
  // };

  const filterPayments = () => {
    const paymentsRefunds = invoice.payments.data.filter((payment) => {
      return !payment.isRefund;
    });

    return paymentsRefunds;
  };

  const filterRefunds = () => {
    const paymentsRefunds = invoice.payments.data.filter((payment) => {
      return payment.isRefund;
    });

    return paymentsRefunds;
  };

  // const saveInvoiceItem = (data) => {
  //   if (editItem) {
  //     items[editItemIndex] = {
  //       ...item,
  //       id: editItem.id,
  //     };
  //     setItems(items);
  //   } else {
  //     setItems(
  //       [
  //         ...items,
  //         {
  //           id: uniqueId("invoice_item_"),
  //           name: data.productName,
  //           price: parseInt(data.productPrice),
  //           responsible: data.productResponsible ? data.productResponsible : "",
  //           quantity: 1,
  //           void: 0,
  //         },
  //       ],
  //       () => console.log("callback called now!! =)", items)
  //     );
  //   }
  //   /*

  //   setItems([
  //     ...items,
  //     {
  //       id: uniqueId("invoice_item_"),
  //       name: data.productName,
  //       price: parseInt(data.productPrice),
  //       responsible: data.productResponsible ? data.productResponsible : "",
  //       quantity: 1,
  //     },
  //   ]);*/
  //   closeItemPopup();
  // };
  const deleteResponsible = async (id) => {
    await Api.deleteInvoiceResponsible(invoice.id, id).then(() => {
      const responsibles = invoice.responsibles.data.filter((responsible) => {
        return responsible.id !== id;
      });
      setInvoice({
        ...invoice,
        responsibles: {
          data: [...responsibles],
        },
      });
    });
  };
  // const dataMaterialTableTransferredPayments = invoice.paymentsTransferred
  //   ? invoice.paymentsTransferred.data.map((o) => ({ ...o }))
  //   : [];

  // const headCellsMaterialTransferredPayments = [
  //   { field: "payment_type", title: "Type" },
  //   {
  //     field: "amount",
  //     title: "Amount",
  //     customSort: (a, b) => a.amount - b.amount,
  //     render: (rowData) => (
  //       <ListItem>
  //         <ListItemText primary={rowData.amount} />
  //         <ListItemIcon>
  //           {rowData.refundedAmount && rowData.refundedAmount > 0 ? (
  //             <Tooltip
  //               classes={{
  //                 tooltip: classes.customTooltip,
  //                 arrow: classes.customArrow,
  //               }}
  //               title={
  //                 <div>
  //                   <Box fontWeight="fontWeightBold" m={1}>
  //                     {`Refunded: $${rowData.refundedAmount.toLocaleString(
  //                       "en",
  //                       {
  //                         minimumFractionDigits: 2,
  //                         maximumFractionDigits: 2,
  //                       }
  //                     )}`}
  //                   </Box>
  //                 </div>
  //               }
  //               arrow
  //             >
  //               <InfoIcon style={{ color: "#26a69a" }} />
  //             </Tooltip>
  //           ) : rowData.forfeiture_amount && rowData.forfeiture_amount > 0 ? (
  //             <Tooltip
  //               classes={{
  //                 tooltip: classes.customTooltip,
  //                 arrow: classes.customArrow,
  //               }}
  //               title={
  //                 <div>
  //                   <Box fontWeight="fontWeightBold" m={1}>
  //                     {`Forfeited: $${rowData.forfeiture_amount.toLocaleString(
  //                       "en",
  //                       {
  //                         minimumFractionDigits: 2,
  //                         maximumFractionDigits: 2,
  //                       }
  //                     )}`}
  //                   </Box>
  //                 </div>
  //               }
  //               arrow
  //             >
  //               <InfoIcon style={{ color: "#e65100" }} />
  //             </Tooltip>
  //           ) : (
  //             ""
  //           )}
  //         </ListItemIcon>
  //       </ListItem>
  //     ),
  //   },
  //   {
  //     field: "cc_last_4",
  //     title: "Last 4",
  //   },
  //   {
  //     field: "details",
  //     title: "Details",
  //     render: (rowData) => {
  //       return <div className={classes.wrapText}>{rowData.details}</div>;
  //     },
  //     customSort: (row1, row2) => {
  //       const detail1 = row1.details || "";
  //       const detail2 = row2.details || "";
  //       return detail1.localeCompare(detail2);
  //     },
  //   },
  //   { field: "building_name", title: "Building" },
  //   {
  //     field: "date",
  //     title: "Created",
  //     render: (rowData) => {
  //       return (
  //         <div>
  //           {momentTimeZone(rowData.date).format("YYYY-MM-DD hh:mm:ss A")}
  //         </div>
  //       );
  //     },
  //     customSort: (a, b) =>
  //       new Date(moment(a.date).format("YYYY-MM-DD HH:mm:ss")).getTime() -
  //       new Date(moment(b.date).format("YYYY-MM-DD HH:mm:ss")).getTime(),
  //   },
  //   {
  //     field: "transferred_date",
  //     title: "Transferred Date",
  //     render: (rowData) => {
  //       return (
  //         <div>
  //           {momentTimeZone
  //             .utc(rowData.transferred_date)
  //             .tz("America/New_York")
  //             .format("YYYY-MM-DD hh:mm:ss A")}
  //         </div>
  //       );
  //     },
  //     customSort: (a, b) =>
  //       new Date(
  //         moment(a.transferred_date).format("YYYY-MM-DD HH:mm:ss")
  //       ).getTime() -
  //       new Date(
  //         moment(b.transferred_date).format("YYYY-MM-DD HH:mm:ss")
  //       ).getTime(),
  //   },
  //   {
  //     field: "created_by",
  //     title: "By",
  //     customSort: (a, b) => a.user.data.title.localeCompare(b.user.data.title),
  //     render: (rowData) => {
  //       return rowData.user.data ? rowData.user.data.title : "";
  //     },
  //   },

  //   {
  //     field: "attachment",
  //     title: "File",
  //     sorting: false,
  //     render: (rowData) => {
  //       if (rowData.document.data?.url || rowData.file) {
  //         return (
  //           <a
  //             href={
  //               rowData.document.data?.url
  //                 ? rowData.document.data.url
  //                 : rowData.file
  //             }
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             <AttachFileIcon />
  //           </a>
  //         );
  //       }
  //     },
  //   },
  // ];

  const dataMaterialTable = invoice.creditMemos
    ? invoice.creditMemos.data.map((o) => ({ ...o }))
    : [];
  const headCellsMaterial = [
    {
      field: "date",
      title: "Date",
      render: (rowData) => {
        return moment(rowData.created_at).format("YYYY/MM/DD");
      },
    },
    { field: "amount", title: "Used Amount" },
    /*
    {
      field: "details",
      title: "Details",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.creditmemodetail.data.map((creditmemodetail) => {
            return ( <Grid container>
              <Grid item xs={12}>Credit amount {creditmemodetail.amount} applied to {creditmemodetail.item_name}   </Grid>  </Grid>);
          })
          
          } arrow>
            <InfoIcon />
          </Tooltip>
        );
      },
    },
    */
    {
      field: "details",
      title: "Details",
      render: (rowData) => {
        return rowData.details;
      },
    },
    {
      field: "created_by",
      title: "Created By",
      render: (rowData) => {
        return rowData.user.data.first_name;
      },
    },
  ];

  const renderCreditDetails = (creditDetails) => {
    return (
      <MaterialTable
        title="Credits"
        columns={[
          { title: "Name", field: "item_name" },
          { title: "Amount", field: "amount" },
        ]}
        data={creditDetails.map((credit) => {
          return {
            item_name: (
              <Typography>
                {credit.item_name}
                {credit.item_is_revision ? (
                  <strong
                    style={{
                      color: "#326a9b",
                      marginLeft: 5,
                      fontWeight: 400,
                    }}
                  >
                    {"(Revision)"}
                  </strong>
                ) : (
                  ""
                )}
              </Typography>
            ),
            amount: credit.amount,
          };
        })}
        options={{
          pageSize: 20, // make initial page size
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          search: false,
          header: true,
          paging: false,
          showTitle: false,
          padding: "dense",
          toolbar: false,
          exportButton: false,
        }}
      />
    );
  };

  const onDeleteCredit = async (id) => {
    const { data } = await Api.deleteInvoiceCredit(invoice.id, id);

    dispatch(applyInvoiceCreditMemoService(data.data));
    setInvoice(data.data);

    if (getInvoicesContact) {
      getInvoicesContact();
    }

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  useEffect(() => {
    const orgId = authUser.organization_id;
    if (isEmpty(financing)) dispatch(fetchFinancingService(dataSliceFinancing));

    getInvoice();
    if (isEmpty(productsNew)) {
      dispatch(fetchProductsService(dataSliceProduct));
    }
    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));

    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));
    if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));

    if (isEmpty(refundReasons)) {
      dispatch(fetchRefundReasonsService(orgId));
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(invoice)) {
      setItems(invoice.items.data);
      setStatus(invoice.status);
    }
  }, [invoice]);

  if (isEmpty(invoice)) return "loading...";

  let action;
  if (invoice.status !== "canceled") {
    action = [
      {
        icon: () => (
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        ),
        tooltip: "Apply Credit Memo",
        isFreeAction: true,
        hidden:
          !userCanCreateCredit ||
          readOnlySalesManager ||
          userCanViewEditContactMark ||
          userCanViewInvoice,
        disabled:
          !userCanCreateCredit ||
          readOnlySalesManager ||
          userCanViewEditContactMark ||
          userCanViewInvoice,
        onClick: (event) => {
          CreateCreditMemo();
        },
      },
      (rowData) => ({
        icon: () => (
          <Controls.IconButton color="secondary">
            <CloseIcon fontSize="small" />
          </Controls.IconButton>
        ),
        tooltip: "Delete",
        hidden:
          !userCanDelete ||
          readOnlySalesManager ||
          userCanViewEditContactMark ||
          userCanViewInvoice,
        disabled:
          !userCanDelete ||
          readOnlySalesManager ||
          userCanViewEditContactMark ||
          userCanViewInvoice,
        onClick: (event, rowData) =>
          setConfirmDialog({
            isOpen: true,

            title: "Are you sure to delete this credit memo?",
            subTitle: "You can't undo this operation",

            onConfirm: () => onDeleteCredit(rowData.id),
          }),
      }),
    ];
  } else {
    action = [
      {
        icon: () => <></>,
        tooltip: "",
        isFreeAction: true,
      },
    ];
  }

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />
      <Grid container>
        <Grid item xs={6}>
          <InvoiceBuilder
            setInvoices={setInvoices}
            getInvoicesContact={getInvoicesContact}
            invoices={invoices}
            contacts={contacts}
            statusOptions={statusArray}
            invoice={invoice}
            setInvoice={setInvoice}
            users={users}
            authUser={authUser}
            products={productsNew}
            contact={invoice.contact.data}
            onSaveInvoice={saveInvoice}
            onMakePayment={savePayment}
            onSaveCreditMemo={saveCreditMemo}
            readOnlySalesManager={
              readOnlySalesManager ||
              userCanViewEditContactMark ||
              userCanViewInvoice
            }
            userIsCallCenter={userIsCallCenter}
            userIsSocialMedia={userIsSocialMedia}
            userIsFrontDesk={userIsFrontDesk}
            refreshInvoices={refreshInvoices}
          />
        </Grid>
        <Grid item xs={6}>
          <Paper square>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
            >
              <Tab
                icon={
                  <Iconify
                    icon={"mdi:chart-timeline-variant-shimmer"}
                    style={{ color: "#ff5722" }}
                    width={24}
                    height={24}
                  />
                }
                label="Timeline"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fa:comments"}
                    style={{ color: "#607d8b" }}
                    width={24}
                    height={24}
                  />
                }
                label="Comments"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"bi:file-text-fill"}
                    style={{ color: "#03a9f4" }}
                    width={24}
                    height={24}
                  />
                }
                label="Documents"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fluent:payment-24-filled"}
                    style={{ color: "rgb(62 101 141)" }}
                    width={24}
                    height={24}
                  />
                }
                label="Payments"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fluent:people-24-filled"}
                    style={{ color: "#8bc34a" }}
                    width={24}
                    height={24}
                  />
                }
                label="Responsibles"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"mdi:credit-card-refund"}
                    style={{ color: "#f44336" }}
                    width={24}
                    height={24}
                  />
                }
                label="Refunds"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"icon-park-twotone:gift-bag"}
                    style={{ color: "#009688" }}
                    width={24}
                    height={24}
                  />
                }
                label="Credits"
              />
              <Tab
                icon={
                  <div style={{ display: "flex", position: "relative" }}>
                    <Grid container style={{ margin: 0, padding: 0 }}>
                      <Grid item xs={12}>
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          width={25}
                          height={25}
                          style={{
                            color: "#03a9f4",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          position: "absolute",
                          zIndex: 10,
                          top: "17px",
                          left: "3px",
                        }}
                      >
                        <Iconify
                          icon={"fluent:payment-24-filled"}
                          width={20}
                          height={20}
                          style={{
                            color: "#dd2c00",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
                label="Transferred Payments"
              />
            </Tabs>

            <TabPanel value={value} index={0} {...a11yProps(0)}>
              <Timeline
                modelType="Invoice"
                model={invoice}
                typel="Invoice"
                createdAt={invoice.created_at}
              />
            </TabPanel>
            <TabPanel value={value} index={1} {...a11yProps(1)}>
              <Comments
                comments={invoice.comments.data}
                onSave={saveComment}
                canAdd={
                  !readOnlySalesManager &&
                  !userCanViewEditContactMark &&
                  !userCanViewInvoice
                }
              />
            </TabPanel>
            <TabPanel value={value} index={2} {...a11yProps(2)}>
              <Documents
                documents={invoice.documents.data}
                documentTypes={documentTypes}
                onSave={saveDocument}
                showDelete={false}
                canAdd={
                  (!readOnlySalesManager &&
                    !userCanViewEditContactMark &&
                    !userCanViewInvoice) ||
                  userIsbillingMGAssistant
                }
              />
            </TabPanel>
            <TabPanel value={value} index={3} {...a11yProps(3)}>
              <PaymentsComponent
                invoice={invoice}
                payments={filterPayments()}
                setInvoice={setInvoice}
                getInvoicesContact={getInvoicesContact}
                onDelete={deletePayment}
                gateways={paymentGateways}
                authUser={authUser}
                reload={getInvoice}
                userIsbillingMGAssistant={userIsbillingMGAssistant}
                onMarkPossibleRefund={markPossibleRefund}
                setOpenDetailPopup={setOpenDetailPopup}
              />
            </TabPanel>
            <TabPanel value={value} index={4} {...a11yProps(4)}>
              <Responsible
                responsibles={invoice.responsibles.data}
                users={users}
                onSave={saveResponsible}
                onDelete={deleteResponsible}
                canAdd={
                  !readOnlySalesManager &&
                  !userCanViewEditContactMark &&
                  !userCanViewInvoice
                }
                canDelete={
                  !readOnlySalesManager &&
                  !userCanViewEditContactMark &&
                  !userCanViewInvoice
                }
              />
            </TabPanel>
            <TabPanel value={value} index={5} {...a11yProps(5)}>
              <Refunds
                invoice={invoice}
                refunds={filterRefunds()}
                onSave={savePayment}
                gateways={paymentGateways}
                maxAmount={1}
                refundReasons={refundReasons}
                invoiceSelected={invoice}
                dataRefundRequests={dataRefundRequests}
                setInvoice={setInvoice}
                getInvoicesContact={getInvoicesContact}
                onDelete={deletePayment}
                userIsbillingMGAssistant={userIsbillingMGAssistant}
              />
            </TabPanel>
            <TabPanel value={value} index={6} {...a11yProps(6)}>
              <TblContainer>
                <MaterialTable
                  columns={headCellsMaterial}
                  data={dataMaterialTable}
                  actions={[...action]}
                  options={{
                    pageSize: 20, // make initial page size
                    emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                    actionsColumnIndex: -1,
                    exportButton: true,
                  }}
                  detailPanel={(rowData) => {
                    return renderCreditDetails(rowData.creditmemodetail.data);
                  }}
                  title="Credit Memos Applied"
                />
              </TblContainer>
            </TabPanel>
            <TabPanel value={value} index={7} {...a11yProps(7)}>
              <InvoicePaymentTransferHistories invoice_id={invoice.id} />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
      {invoice && (
        <Popup
          title="Add Credit Memo To Invoice"
          fullWidth={true}
          openPopup={openCreditMemo}
          onClose={closeCreditMemo}
        >
          <CreditMemosInvoices
            checkCouponsCompability={checkCouponsCompability}
            invoice={invoice}
            contact={invoice.contact.data}
            applyInvoiceCreditMemoupdate={saveCreditMemo}
          />
        </Popup>
      )}
      {/* <Popup
        title="Invoice item"
        fullWidth={true}
        openPopup={openItem}
        onClose={closeItemPopup}
      >
        <InvoiceItemForm
          users={users}
          products={productsNew}
          saveItem={(data) => saveInvoiceItem(data)}
          productGiftReason={productGiftReason}
        />
      </Popup> */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </React.Fragment>
  );
}

export default InvoiceDetails;
