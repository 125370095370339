import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  IconButton,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  Typography,
  Drawer as MuiDrawer,
  ListItem,
  Button,
} from "@material-ui/core";
import Iconify from "./Iconify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ScrollBar from "react-perfect-scrollbar";
import Api from "../lib/api";

const useStyles = makeStyles((theme) => ({
  iconProps: {
    marginRight: "2px",
    fontSize: 15,
  },
  accordionDetails: {
    width: "100%",
    marginTop: -20,
    background: "#00000008",
    borderRadius: 25,

    "&.MuiPaper-elevation1": {
      boxShadow: "none",
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 15,
      marginBottom: 25,
      padding: "5px 0px",
      maxWidth: "92%",
    },
  },
}));

const Accordion = withStyles({
  root: {
    borderTop: "1px solid rgb(147 147 147 / 11%)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    justifyContent: "center",
    backgroundColor: "white",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    justifyContent: 'center',
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function LiveNotification(props) {
  const { notification, setNotification, autoHide = 10000 } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();

  const renderNotificationDataNew = (data, type) => {
    console.log("DATA: ", data, "TYPE: ", type);
    const dataLocal = JSON.parse(data);

    console.log("DATA LOCAL: ", dataLocal);
    if (type == "contact") {
      return dataLocal.map((item, index) => {
        return (
          <Card className={classes.accordionDetails}>
            <CardHeader
              title={
                <Typography
                  style={{
                    color: "rgb(62 101 141)",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {item.name}
                </Typography>
              }
              subheader={
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      icon="mingcute:phone-fill"
                      className={classes.iconProps}
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Phone: ${item.phone}`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      icon="eva:email-fill"
                      className={classes.iconProps}
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Email: ${item.email}`}
                    </Typography>
                  </div>
                </>
              }
            />
          </Card>
        );
      });
    }

    if (type == "ticket") {
      return dataLocal.map((item, index) => {
        return (
          <Card className={classes.accordionDetails}>
            <CardHeader
              action={
                item.id ? (
                  <Tooltip title="Open Ticket">
                    <IconButton
                      color="inherit"
                      aria-label="open-Ticket"
                      onClick={() => {
                        documentsTabRef.current = dataLocal[0].documentsTab;
                        setNotify({
                          isOpen: false,
                          message: "",
                          type: "",
                        });
                        item.id ? openTicketSavedAlert(item.id) : "";
                      }}
                    >
                      <Iconify
                        icon={"akar-icons:arrow-forward-thick-fill"}
                        style={{ color: "rgb(62 101 141)" }}
                        width={23}
                        height={23}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )
              }
              title={
                <Typography
                  style={{
                    color: "rgb(62 101 141)",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {item.name}
                </Typography>
              }
              subheader={
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      icon="majesticons:text-line"
                      className={classes.iconProps}
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Subject: ${item.subject}`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      className={classes.iconProps}
                      icon="mdi:state-machine"
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Status: ${item.status}`}
                    </Typography>
                  </div>
                </>
              }
            />
          </Card>
        );
      });
    }

    if (type == "appointment") {
      return dataLocal.map((item, index) => {
        return (
          <Card className={classes.accordionDetails}>
            <CardHeader
              action={
                item.id ? (
                  <Tooltip title="Open Appointment">
                    <IconButton
                      color="inherit"
                      aria-label="open-Ticket"
                      onClick={() => {
                        getAppointmentForEdit(item.id);
                      }}
                    >
                      <Iconify
                        icon={"akar-icons:arrow-forward-thick-fill"}
                        style={{ color: "rgb(62 101 141)" }}
                        width={25}
                        height={25}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )
              }
              title={
                <Typography
                  style={{
                    color: "rgb(62 101 141)",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {item.name}
                </Typography>
              }
              subheader={
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      className={classes.iconProps}
                      icon="bxs:calendar-event"
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Date: ${item.date}`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      className={classes.iconProps}
                      icon="ic:round-person"
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Contact/Patient: ${item.contact}`}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify className={classes.iconProps} icon="jam:medical" />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Type: ${item.type_of_appointment}`}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      className={classes.iconProps}
                      icon="mdi:state-machine"
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Status: ${item.status.replace(/^\w/, (c) =>
                        c.toUpperCase()
                      )}`}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#434644",
                    }}
                  >
                    <Iconify
                      className={classes.iconProps}
                      icon="carbon:user-filled"
                    />
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      {`Assigned to: ${item.doctor}`}
                    </Typography>
                  </div>
                </>
              }
            />
          </Card>
        );
      });
    }

    return "";
  };

  const getNotificationDetails = async (id) => {
    console.log("ID: ", id);
    const { data } = await Api.getNotificationById(id);

    setNotification({ ...data });
    setIsExpanded(!isExpanded);
  };

  // useEffect(() => {
  //   console.log("NOTF: ", notification);
  // }, [notification]);

  return notification ? (
    <MuiDrawer
      id="simple-menu"
      variant="permanent"
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "rgb(246 255 251)",
          right: "4%",
          left: "auto",
          height: "fit-content",
          boxShadow: "0 0 14px 0 rgb(53 64 82 / 41%)",
          width: 370,
          maxWidth: "100%",
          borderRadius: 12,
          marginBottom: 50,
          zIndex: 6000
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchor={"bottom"}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          style={{
            minWidth: "fit-content",
            marginInlineEnd: "auto",
            fontSize: 14.5,
            fontWeight: 500,
            color: "rgb(79 122 143)",
            marginLeft: 9
          }}
        >
          New Notification!
        </Typography>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            onClick={() => {
              setNotification(null);
            }}
            style={{ color: "rgb(255 88 88)", fontSize: 20, padding: 0 }}
          >
            x
          </Button>
        </div>
      </div>

      {/* <Tooltip title={!isExpanded? "Click for more information": ""}> */}

      <Accordion
        expanded={false}
        style={{ background: "rgb(246 255 251)", margin: 2 }}
      >
        <AccordionSummary
          style={{ background: "rgb(246 255 251)", justifyContent: "center" }}
        >
          <div style={{ textAlign: "center", padding: 16 }}>
            <Iconify
              style={{ fontSize: 32, color: "rgb(11 211 146)" }}
              icon="tabler:bell-ringing-filled"
            />
            <Typography
              style={{ fontSize: 16, fontWeight: 500, color: "#196c50" }}
            >
              {notification.text}
            </Typography>
          </div>
        </AccordionSummary>
        {/* <AccordionDetails >
          {renderNotificationDataNew(notification.data, notification.type)}
        </AccordionDetails> */}
      </Accordion>
      {/* </Tooltip> */}
    </MuiDrawer>
  ) : (
    ""
  );
}
