import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  generalTotalUnreadChatMessages: 0,
};

const generalTotalUnreadChatMessages = createSlice({
  name: "generalTotalUnreadChatMessages",
  initialState,
  reducers: {
    updateTotals: (state, { payload }) => {
      state.generalTotalUnreadChatMessages = payload;
    },
    getTotalSucces: (state, { payload }) => {
      state.generalTotalUnreadChatMessages = payload;
      state.loading = false;
      state.errors = [];
    },
  },
});

export const { getTotalSucces, updateTotals } =
  generalTotalUnreadChatMessages.actions;
export const templatesSelector = (state) =>
  state.generalTotalUnreadChatMessages;

export default generalTotalUnreadChatMessages.reducer;

export function fetchGeneralTotalUnreadChatMessages() {
  return async (dispatch) => {
    try {
      const { data } = await Api.getTotalUnreadChatMessages();
      const total = data.reduce(
        (total, item) => total + parseInt(item.total),
        0
      );
      dispatch(getTotalSucces(total));
    } catch (error) {}
  };
}

export function updateGeneralTotalUnreadChatMessages(payload) {
  return async (dispatch) => {
    dispatch(updateTotals(payload));
  };
}
