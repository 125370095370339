import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, map, orderBy } from "lodash";
import MaterialTable from "material-table";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AddIcon from "@material-ui/icons/Add";
import CommentForm from "./forms/CommentForm";
import CommentsRecord from "./CommentsRecord";
import Documents from "./Documents";
import Popup from "./Popup";
import { red } from "@material-ui/core/colors";

import VOID from "../assets/img/icons/VOID.png";
import {
  AppBar,
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Toolbar,
  Typography,
  Grid,
  Fab,
  Tooltip,
  Button,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import Link from "@material-ui/core/Link";
import VIP from "../assets/img/icons/VIP.png";
import { blue } from "@material-ui/core/colors";

import Api from "../lib/api";
import useTable from "./useTable";

import { useTranslation } from "react-i18next";
import DocumentsSummary from "./DocumentsSummary";
import Iconify from "./Iconify";
import FraudolentContactForm from "./forms/FraudolentContactForm";
import MarkOrUnamrkContactAsTransfer from "./MarkOrUnamrkContactAsTransfer";
import CRMUtils from "../utils";

import MTable from "../components_generic/MTableR";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  paperVip: {
    padding: "6px 16px",
    flexGrow: 1,
    //backgroundColor: "#f2f0cb",
    backgroundColor: blue[50],
  },
  form: {
    justifySelf: "self-end",
  },
  socialWrapper: {
    textAlign: "right",
    background: "#f5f5f5",
  },
  socialIcon: {
    borderRadius: "15px",
    padding: "5px",
    margin: "3px",
    border: "1px solid #CCC",
    width: "48px",
    height: "48px",
  },
  rowVoid: {
    backgroundColor: red[50],
  },
  TableHead: {
    backgroundColor: "#4db6ac",
    color: "#ffffff",
  },
  phoneWarningContainer: {
    padding: "2px 8px",
    backgroundColor: "rgb(251 246 202)",
    color: "#252525",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    borderRadius: 10,
    maxWidth: "fit-content",
  },
  phoneWarningIcon: {
    marginRight: 3,
    color: "#bfb100",
    fontSize: 17,
  },
  phoneWarningText: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

function RecordSummary(props) {
  const classes = useStyles();
  const {
    record,
    saveComment,
    documentTypes,
    saveDocument,
    onDeleteDocument,
    showDelete,
    userCanEdit = true,
    canAddComments = true,
    canAddDocuments = true,
    canDeleteDocuments = true,
    readonly = false,
    rReadonly = false,
    editableNoDoctor = false,
    userIsCallCenter = false,
    userIsFrontDesk = false,
    userIsSocialMedia = false,
    userIsBilling = false,
    proceduresDropdown,
    userIsPersonalAssistant = false,
  } = props;
  //const [openCommentPopup, setOpenCommentPopup] = useState(false);
  //const [openDocumentPopup, setOpenDocumentPopup] = useState(false);

  const commentsMaterialTable = map(
    record.contactRecordComments.data,
    (contactRecordComment, index) => ({
      ...contactRecordComment,
    })
  );
  const [openFraudPopup, setOpenFraudPopup] = useState(false);
  const { t, i18n } = useTranslation(["common", "records"]);

  const [commentsToShow, setCommentsToShow] = useState([]);

  const headCells = [
    { id: "invoice_item_name", label: t("common:general.Name") },
    { id: "void", label: t("Void") },
    { id: "status", label: t("common:general.Status") },
    { id: "pre-date", label: t("records:records.predate") },
    { id: "date", label: t("common:general.Date") },
    // { id: "salesman_name", label: t("common:general.Consultant") },
    { id: "responsible_name", label: t("common:general.Surgeon") },
    { id: "coordinator_name", label: t("records:records.coordinator") },
    { id: "itemBalance", label: t("records:records.Balance") },
    { id: "finalized", label: t("common:general.Completed") },
  ];

  const headCellsMaterialProc = [
    { field: "invoice_item_name", title: t("common:general.Name") },
    {
      field: "void",
      title: t("Void"),
      render: (rowData) => {
        return rowData.void ? (
          <img
            src={VOID}
            alt={"VOID"}
            style={{ width: "40px", height: "auto" }}
          />
        ) : (
          ""
        );
      },
    },
    { field: "status", title: t("common:general.Status") },
    {
      field: "pre-date",
      title: t("records:records.predate"),
      render: (rowData) => {
        return rowData.pre_date
          ? moment(rowData.pre_date).format("YYYY-MM-DD")
          : "";
      },
    },
    {
      field: "date",
      title: t("common:general.Date"),
      render: (rowData) => {
        return rowData.date ? moment(rowData.date).format("YYYY-MM-DD") : "";
      },
    },
    // { id: "salesman_name", label: t("common:general.Consultant") },
    { field: "responsible_name", title: t("common:general.Surgeon") },
    { field: "coordinator_name", title: t("records:records.coordinator") },
    {
      field: "itemBalance",
      title: t("records:records.Balance"),
      render: (rowData) => {
        return `$${
          rowData.itemBalance
            ? rowData?.itemBalance.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : ""
        }`;
      },
    },
    {
      field: "finalized",
      title: t("common:general.Completed"),
      render: (rowData) => {
        return rowData.finalized ? "Yes" : "No";
      },
    },
  ];

  const { TblContainer, TblHead, TblPagination } = useTable(
    proceduresDropdown,
    headCells
  );

  const calulateTotalRefunds = (item) => {
    const refunds = item.invoice_item.payment_detail
      ? item.invoice_item.payment_detail.filter((payment) => {
          return payment.isRefund;
        })
      : [];

    const totalRefunds = refunds.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    return totalRefunds;
  };

  const mixCommentsHistory = (item) => {
    const recordProductsStatus = record.recordProductsStatusHistory.data;

    let recordProductsStatusH = [];

    recordProductsStatus.forEach((recordProductsS) => {
      recordProductsS.recordproductsstatushistory.data.forEach(
        (recordProductsSH) => {
          recordProductsStatusH.push(recordProductsSH);
        }
      );
    });

    const mixComments = [
      ...recordProductsStatusH,
      ...record.contactRecordComments.data,
    ];
    let mixCommentsToReturn = orderBy(mixComments, "created_at", "desc");
    setCommentsToShow(mixCommentsToReturn);
    // return mixCommentsToReturn;
  };

  const calulateTotalPayments = (item) => {
    const payments = item.invoice_item.payment_detail
      ? item.invoice_item.payment_detail.filter((payment) => {
          return !payment.isRefund;
        })
      : [];

    const totalPayments = payments.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    return totalPayments;
  };

  const calulateTotalCredits = (item) => {
    const totalPayments = item.invoice_item.credit_memo_detail
      ? item.invoice_item.credit_memo_detail.reduce(
          (total, item) => total + parseFloat(item.amount),
          0
        )
      : 0;
    return totalPayments;
  };

  const calcDue = (item) => {
    const Due = item.invoice_item
      ? (item.invoice_item.price_includes_qty
          ? 1
          : item.invoice_item.quantity) *
          item.invoice_item.price -
        (calulateTotalPayments(item) +
          calulateTotalCredits(item) -
          calulateTotalRefunds(item))
      : 0;

    return Due;
  };

  const closeCommentPopup = () => {
    setOpenCommentPopup(false);
  };

  const closeDocumentPopup = () => {
    setOpenDocumentPopup(false);
  };

  const commentsHeadCellsMaterial = [
    { field: "comment", title: "Comment" },
    {
      field: "user_id",
      title: "Created By",
      render: (rowData) => {
        return rowData.user.data ? rowData.user.data.title : "";
      },
    },
    {
      field: "created",
      title: "Created",
      render: (rowData) => {
        return new moment(rowData.created_at).format("YYYY-MM-DD");
      },
    },
  ];

  const recordproductsMaterialTable = map(
    record.recordproducts.data,
    (recordproduct, index) => ({
      ...recordproduct,
    })
  );

  const recordproductsheadCellsMaterial = [
    {
      field: "name",
      title: "Name",
      render: (rowData) => {
        return rowData.invoiceItem.data.name;
      },
    },
    { field: "status", title: "Status" },
    { field: "pre_date", title: "PreOp Date" },
    { field: "date", title: "Sx Date" },
    { field: "consultant_name", title: "Consultant" },
    { field: "responsible_name", title: "Surgeon" },
    { field: "coordinator_name", title: "Coordinator" },
  ];

  const documentsMaterialTable = map(
    record.contactRecordDocuments.data,
    (contactRecordDocument, index) => ({
      ...contactRecordDocument,
    })
  );

  const documentsHeadCellsMaterial = [
    { field: "collection_name", title: "Type" },
    {
      field: "name",
      title: "Name",
    },
    {
      field: "file",
      title: "File",

      render: (rowData) =>
        rowData.url ? (
          <a href={rowData.url} target="_blank" rel="noreferrer">
            <AttachFileIcon />
          </a>
        ) : (
          ""
        ),
    },
  ];

  const kFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };
  const renderSocialNetwork = () => {
    return record.contact.data.social_platforms.map((platform) => {
      switch (platform.name) {
        case "Facebook":
          return (
            <Tooltip
              arrow
              title={`${kFormatter(platform.pivot.followers)} followers`}
            >
              <Link
                key={platform.id}
                href={`https://facebook.com/${platform.pivot.account_name}`}
                target="_blank"
                rel="noopener"
              >
                <FacebookIcon size="large" className={classes.socialIcon} />
              </Link>
            </Tooltip>
          );
        case "Instagram":
          return (
            <Tooltip
              arrow
              title={`${kFormatter(platform.pivot.followers)} followers`}
            >
              <Link
                key={platform.id}
                href={`https://instagram.com/${platform.pivot.account_name}`}
                target="_blank"
                rel="noopener"
              >
                <InstagramIcon size="large" className={classes.socialIcon} />
              </Link>
            </Tooltip>
          );
        case "Youtube":
          return (
            <Tooltip
              arrow
              title={`${kFormatter(platform.pivot.followers)} followers`}
            >
              <Link
                key={platform.id}
                href={`https://youtube.com/channel/${platform.pivot.account_name}`}
                target="_blank"
                rel="noopener"
              >
                <YouTubeIcon size="large" className={classes.socialIcon} />
              </Link>
            </Tooltip>
          );
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    mixCommentsHistory();
  }, [record]);

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={5}
            className={
              record.contact.data.VIP === 1 ? classes.paperVip : classes.paper
            }
          >
            <Box m={3}>
              {record && !isEmpty(record.contact.data.fraudolent.data) ? (
                <Tooltip title="Click for more information">
                  <Button
                    onClick={() => {
                      setOpenFraudPopup(true);
                    }}
                    style={{
                      borderRadius: 16,
                      backgroundColor: "#ffe8e7",
                      color: "#bf2929",
                      marginBottom: 10,
                    }}
                    startIcon={<Iconify icon={"ri:alarm-warning-fill"} />}
                  >
                    {"This patient has been marked as fraudulent!"}
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}

              <MarkOrUnamrkContactAsTransfer
                recordId={record.id}
                transferedAt={record.contact.data.transfered_at}
              />

              <Typography variant="h2" color="textSecondary">
                {"Patient"}
              </Typography>
              <Typography variant="h4" color="textSecondary">
                {"Name:    " + record.fname + " " + record.lname}
              </Typography>
              {record.contact ? (
                <Typography variant="h4" color="textSecondary">
                  {"DOB:           " + record.contact.data.dob}
                </Typography>
              ) : (
                ""
              )}

              <Typography variant="h4" color="textSecondary">
                {"Email:       " + (record?.email || "-")}{" "}
              </Typography>
              <Typography variant="h4" color="textSecondary">
                {"Phone:        " + (record?.phone || "-")}{" "}
              </Typography>
              {record &&
              record.contact &&
              record.contact.data &&
              record.contact.data.is_phone_invalid_for_voice ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <div className={classes.phoneWarningContainer}>
                    <Iconify
                      icon="ic:round-warning"
                      className={classes.phoneWarningIcon}
                    />
                    <Typography className={classes.phoneWarningText}>
                      Phone not valid for calls
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}
              {record.sex ? (
                <Typography variant="h4" color="textSecondary">
                  {"Sex:           " + record.sex}
                </Typography>
              ) : (
                ""
              )}
              {record.chart_id ? (
                <Typography variant="h4" color="textSecondary">
                  {"Chart ID:           " + record.chart_id}
                </Typography>
              ) : (
                <Typography variant="h4" color="textSecondary">
                  {"Chart ID:           " + record.id}
                </Typography>
              )}

              <Typography variant="h4" color="textSecondary">
                {"BMI: "}
                {record.contact.data.bmi ? record.contact.data.bmi : "0"}
              </Typography>

              {record.contact.data.VIP === 1 ? (
                <Typography variant="h4" color="textSecondary">
                  <img
                    src={VIP}
                    alt={"VIP"}
                    style={{ width: "60px", height: "auto" }}
                  />
                </Typography>
              ) : (
                ""
              )}
              {record.contact.data.social_platforms && (
                <div className={classes.socialWrapper}>
                  {renderSocialNetwork()}
                </div>
              )}
              <Typography variant="h4" color="textSecondary">
                {`${t("common:general.Consultant")}: `}
                {record.contact?.data?.coordinator_name
                  ? record.contact?.data?.coordinator_name
                  : ""}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={7}>
            {/*<Box m={1}>
              {
                <MaterialTable
                  columns={recordproductsheadCellsMaterial}
                  data={recordproductsMaterialTable}
                  options={{
                    search: false,
                    header: true,
                    showTitle: true,
                    padding: "dense",
                    exportButton: false,
                    pageSize: 5,
                  }}
                  title="Procedures"
                />
              }
            </Box>*/}

            <MTable
              data={orderBy(
                proceduresDropdown,
                ["date", "status"],
                ["asc", "desc"]
              )}
              columns={headCellsMaterialProc}
              pageSize={5}
              pageSizeOptions={[5]}
              exportButton={false}
              search={false}
              toolbar={false}
              showPaging={true}
              showTitle={false}
              headerStyle={{
                backgroundColor: "#e0f2f1",
                color: "#00695c",
              }}
              emptyRowsWhenPaging={false}
              //  isLoading={!isEmpty(proceduresDropdown)}
            />

            {/* <TblContainer>
              <TblHead classNameI={classes.TableHead} />
              <TableBody>
                {proceduresDropdown.map((item) => {
                  return (
                    <TableRow>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.invoice_item_name}
                      </TableCell>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.void ? (
                          <img
                            src={VOID}
                            alt={"VOID"}
                            style={{ width: "40px", height: "auto" }}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.status}
                      </TableCell>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.pre_date ? item.pre_date.split(" ")[0] : ""}
                      </TableCell>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.date ? item.date.split(" ")[0] : ""}
                      </TableCell>
                     
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.responsible_name}
                      </TableCell>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.coordinator_name}
                      </TableCell>
                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.itemBalance.toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>

                      <TableCell className={item.void ? classes.rowVoid : ""}>
                        {item.finalized ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TblContainer> */}
          </Grid>

          <Grid item xs={5}>
            <div style={{ overflow: "auto", clear: "both" }}>
              {
                /* <MaterialTable
                  columns={documentsHeadCellsMaterial}
                  data={documentsMaterialTable}
                   actions={[
                    {
                      icon: () => (
                        <Fab color="primary" aria-label="add">
                          <AddIcon />
                        </Fab>
                      ),
                      tooltip: "Add Credit Memo",
                      isFreeAction: true,
                      onClick: (event) => {
                        setOpenCommentPopup(true);
                      },
                    },
                  ]}
                  options={{
                    search: false,
                    header: true,
                    showTitle: true,
                    padding: "dense",
                    exportButton: false,
                    pageSize: 5,
                  }}
                  title="Documents"
                />*/
                <DocumentsSummary
                  documents={record.contactRecordDocuments.data}
                  documentTypes={documentTypes}
                  onSave={saveDocument}
                  onDeleteDocument={onDeleteDocument}
                  showDelete={true}
                  canAdd={
                    (userCanEdit && canAddDocuments) ||
                    userIsFrontDesk ||
                    userIsBilling ||
                    userIsPersonalAssistant
                  }
                  canDelete={userCanEdit && canDeleteDocuments}
                  enableCamera={true}
                  rReadonly={rReadonly}
                />
              }
            </div>
          </Grid>

          <Grid item xs={7}>
            <div
              style={{ overflow: "auto", clear: "both", marginLeft: "-25px" }}
            >
              {
                /*
                <MaterialTable
                  columns={commentsHeadCellsMaterial}
                  data={commentsMaterialTable}
                  actions={[
                    {
                      icon: () => (
                        <Fab color="primary" aria-label="add">
                          <AddIcon />
                        </Fab>
                      ),
                      tooltip: "Add Credit Memo",
                      isFreeAction: true,
                      onClick: (event) => {
                        setOpenDocumentPopup(true);
                      },
                    },
                  ]}
                  options={{
                    search: false,
                    header: true,
                    showTitle: true,
                    padding: "dense",
                    exportButton: false,
                    pageSize: 5,
                  }}
                  title="Comments"
                />*/

                <CommentsRecord
                  comments={commentsToShow}
                  onSave={saveComment}
                  canAdd={
                    (userCanEdit && canAddComments) ||
                    userIsCallCenter ||
                    userIsFrontDesk ||
                    userIsSocialMedia ||
                    userIsBilling ||
                    userIsPersonalAssistant
                  }
                  readonly={
                    readonly &&
                    !userIsCallCenter &&
                    !userIsFrontDesk &&
                    !userIsSocialMedia &&
                    !userIsBilling &&
                    !userIsPersonalAssistant
                  }
                  rReadonly={rReadonly}
                  editableNoDoctor={editableNoDoctor}
                />
              }
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Popup
        title="Fraudulent Contact"
        openPopup={openFraudPopup}
        fullWidth={true}
        onClose={() => {
          setOpenFraudPopup(false);
        }}
        maxWidth="sm"
      >
        <div className={classes.root}>
          <FraudolentContactForm
            entityForEdit={record.contact.data}
            setOpenFraudPopup={setOpenFraudPopup}
            disabledAll={true}
            // onSendDocument={sendDocument}
          />
        </div>
      </Popup>

      {/*<Popup
        title="New Comment"
        fullWidth={true}
        openPopup={openCommentPopup}
        onClose={closeCommentPopup}
      >
        <CommentForm saveComment={saveComment} className={classes.form} />
      </Popup>*/}
    </Wrapper>
  );
}

export default RecordSummary;
