import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../lib/api";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));
import { fetchBuildingsService } from "../../redux/slices/buildings";
export default function TransferProcedureForm(props) {
  const dispatch = useDispatch();
  const {
    isSubmitting = false,
    saveRecordProductTransferdata,
    responsibles,
    recordProductForEdit,
    transferForEdit,
    coordinatorsD,
  } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const [doctors, setDoctors] = useState([]);

  const [coordinators, setCoordinators] = useState([]);
  const classes = useStyles();
  const { authUser } = useSelector((state) => state.auth);
  const { buildings } = useSelector((state) => state.buildings);
  const onSubmit = (data) => {
    if (recordProductForEdit) {
      data.record_products_id = recordProductForEdit.id;
      data.old_building_id = recordProductForEdit.building_id;
      data.old_coordinator_id = recordProductForEdit.coordinator_id;
      data.old_responsible_id = recordProductForEdit.responsible_id;
   
    }
    data.status = "pending";
    data.created_by = authUser.id;
    saveRecordProductTransferdata(data);
  };

  const buildingsOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const coordinatorsOptions = () => {
    return coordinators.map((coordinator) => {
      return {
        id: coordinator.id,
        title: coordinator.title,
      };
    });
  };
  const fillResponsible = async (value) => {
    // const doctors = responsibles.filter((responsible) => {
    //   return responsible.building_id == value.target.value;
    // });

    const { data } = await Api.getDoctorsBuildings(
      authUser.organization_id,
      value.target.value
    );

    setDoctors(data.data);
  };
  const fillCoordinator = async (value) => {
    const { data } = await Api.getUserCoordinators(value.target.value);

    setCoordinators(data.data);
  };

  const acceptTransfer = () => {
    let data = {};
    data.id = transferForEdit.id;
    data.status = "approved";

    saveRecordProductTransferdata(data);
  };

  const declineTransfer = () => {
    let data = {};
    data.id = transferForEdit.id;
    data.status = "declined";

    saveRecordProductTransferdata(data);
  };

  useEffect(() => {
    const orgId = authUser.organization_id;
    if (isEmpty(buildings)) {
      dispatch(fetchBuildingsService(orgId));
    }

    if (transferForEdit) {
      const doctors = responsibles.filter((responsible) => {
        return responsible.id == transferForEdit.old_responsible_id;
      });

      setDoctors(doctors);

      const coordinatorsEdit = coordinatorsD.filter((coordinator) => {
        return coordinator.id == transferForEdit.old_coordinator_id;
      });

      setCoordinators(coordinatorsEdit);
    }
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Controller
                name="building_id"
                control={control}
                defaultValue={
                  transferForEdit ? transferForEdit.old_building_id : ""
                }
                as={({ onChange, value }) => (
                  <Controls.Select
                    label={transferForEdit ? "From Bulding " : "To Bulding"}
                    options={buildingsOptions()}
                    disabled={transferForEdit ? true : false}
                    onChange={(value) => {
                      onChange(value);
                      fillResponsible(value);
                    }}
                    value={value}
                  />
                )}
                rules={{ required: "Building type is required" }}
              />
              {errors.building_id && (
                <p className="error">{errors.building_id.message}</p>
              )}
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="responsible_id"
                control={control}
                defaultValue={
                  transferForEdit ? transferForEdit.old_responsible_id : ""
                }
                as={({ onChange, value }) => (
                  <Controls.Select
                    label={transferForEdit ? "From Surgeon " : "To Surgeon"}
                    disabled={isEmpty(doctors) || transferForEdit}
                    options={doctors}
                    onChange={(value) => {
                      onChange(value);
                      fillCoordinator(value);
                    }}
                    value={value}
                  />
                )}
                rules={{ required: "Surgeon is required" }}
              />
              {errors.responsible_id && (
                <p className="error">{errors.responsible_id.message}</p>
              )}
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="coordinator_id"
                control={control}
                defaultValue={
                  transferForEdit ? transferForEdit.old_coordinator_id : ""
                }
                as={({ onChange, value }) => (
                  <Controls.Select
                    label={
                      transferForEdit ? "From Coordinator " : "To Coordinator"
                    }
                    disabled={isEmpty(coordinators) || transferForEdit}
                    options={coordinatorsOptions()}
                    onChange={(value) => {
                      onChange(value);
                      //
                    }}
                    value={value}
                  />
                )}
                rules={{ required: "Coordinator is required" }}
              />
              {errors.coordinator_id && (
                <p className="error">{errors.coordinator_id.message}</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="comments"
                control={control}
                defaultValue={transferForEdit ? transferForEdit.comments : ""}
                as={({ onChange, value }) => (
                  <Controls.Textarea
                    label="Comments"
                    rows="12"
                    disabled={transferForEdit ? true : false}
                    onChange={(value) => {
                      onChange(value);
                      //
                    }}
                    value={value}
                  />
                )}
                rules={{ required: "Comments is required" }}
              />
              {errors.comments && (
                <p className="error">{errors.comments.message}</p>
              )}
            </Grid>
            {!transferForEdit && (
              <Grid item xs={12}>
                <div>
                  <Controls.Button
                    type="submit"
                    text={isSubmitting ? "Submitting" : "Submit"}
                    disabled={transferForEdit || isSubmitting ? true : false}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
      {transferForEdit && (
        <Grid item xs={6}>
          <Controls.Button
            text="Accept"
            align="right"
            fullWidth={true}
            onClick={() => {
              acceptTransfer();
            }}
          />
        </Grid>
      )}
      {transferForEdit && (
        <Grid item xs={6}>
          <Controls.Button
            text="Decline"
            align="right"
            fullWidth={true}
            onClick={() => {
              declineTransfer();
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
