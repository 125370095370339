import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ResponsibleForm from "./forms/ResponsibleForm";
import { find } from "lodash";
import Controls from "./controls";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "./ConfirmDialog";
import MaterialTable from "material-table";
import { Paper, ListItem as MuiListItem, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import useTable from "./useTable";
import Popup from "./Popup";
import Api from "../lib/api";
import moment from "moment/moment";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

function InvoicePaymentTransferHistories(props) {
  const classes = useStyles();
  const { invoice_id } = props;
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const headCellsMaterial = [
    {
      field: "type",
      title: "Payment type",
      render: (rowData) => {
        return rowData.payment?.data?.payment_type || "";
      },
    },
    {
      field: "old_amount",
      title: "Amount before transfer",
      render: (rowData) => {
        return rowData.old_amount ? "$" + rowData.old_amount : "";
      },
    },

    {
      field: "transferred_amount",
      title: "Transferred amount",
      render: (rowData) => {
        return rowData.transferred_amount
          ? "$" + rowData.transferred_amount
          : "";
      },
    },
    {
      field: "transferred_amount",
      title: "Amount after transfer",
      render: (rowData) => {
        return rowData.transferred_amount && rowData.old_amount
          ? "$" +
              (parseFloat(rowData.old_amount) -
                parseFloat(rowData.transferred_amount))
          : "";
      },
    },
    {
      field: "transferred_by",
      title: "Transferred by",
      render: (rowData) => {
        return rowData.transferredby?.data?.users_fname || "";
      },
    },
    {
      field: "transferred_at",
      title: "Transferred at",
      render: (rowData) => {
        return rowData.transferred_at
          ? moment(rowData.transferred_at).format("MM/DD/YYYY, HH:mm")
          : "";
      },
    },
    {
      field: "transferred_to_invoice",
      title: "Transferred to invoice",
      render: (rowData) => {
        return rowData.newpayment?.data?.invoice_name || "";
      },
    },
    {
      field: "file",
      title: "Payment receipt",
      render: (rowData) => {
        if (
          rowData.payment &&
          rowData.payment.data &&
          rowData.payment.data.file
        ) {
          return (
            <a
              href={rowData.payment.data.file}
              target="_blank"
              rel="noreferrer"
            >
              <AttachFileIcon />
            </a>
          );
        }
      },
    },
  ];

  const { TblContainer } = useTable();

  const getTransferHistories = async () => {
    setIsLoading(true);
    const { data } = await Api.getInvoicePaymentTransferHistories(invoice_id);
    setHistory([...data.data]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (invoice_id) {
      getTransferHistories();
    }
  }, [invoice_id]);

  return (
    <TblContainer>
      <MaterialTable
        isLoading={isLoading}
        columns={headCellsMaterial}
        data={history}
        options={{
          loadingType: "linear",
          rowStyle: (rowData) => ({
            color: "rgb(131 131 131)",
          }),
          headerStyle: {
            backgroundColor: "#e0f2f1",
            color: "#616161",
          },
          pageSize: 10, // make initial page size
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          actionsColumnIndex: -1,
        }}
        title="Payment transfer history"
      />
    </TblContainer>
  );
}

export default InvoicePaymentTransferHistories;
