import React, { useState, useCallback, useRef } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import SmsFailedRoundedIcon from "@material-ui/icons/SmsFailedRounded";
import { useSelector } from "react-redux";
import {
  blue,
  grey,
  //  yellow,
  // orange,
  // pink,
} from "@material-ui/core/colors";

import Api from "../../lib/api";
import { debounce, isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      // width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "50px",
    height: "50px",
  },
  labelColor: {
    color: "white",
    textAlign: "center",
    fontSize: "1.2em",
  },
  spinner: {
    position: "absolute",
    zIndex: 10,
    top: "40%",
    left: "50%",
  },
  resultData: {
    cursor: "pointer",
  },
  inputRoot: {
    borderRadius: "15px",
    backgroundColor: "white",
    "& fieldset": { borderColor: "#066fac !important" },

    width: "550px",
  },

  icon: {
    transform: "rotate(45deg)",
    color: blue[700],
  },
  historyRow: {
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: grey[100],
    },
    fontWeight: 700,
  },
  historyRow2: {
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: grey[50],
    },
    fontWeight: 300,
  },

  pageContent: {
    position: "relative",
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    backgroundColor: "white",
  },
}));

export default function NewSmsMmsForm(props) {
  const { saveTo, onClose } = props;
  const { authUser } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [searchContactText, setSearchContactText] = useState("");

  const [loading, setLoading] = useState(false);

  const [phoneCanReceivedMessage, setPhoneCanReceivedMessage] = useState();

  const phoneCanReceived = useRef();

  const onSubmit = (data) => {
    saveTo(data);
    onClose();
  };
  const [options, setOptions] = useState([]);
  const debouncedSearch = useCallback(
    debounce((newValue) => getContact(newValue), 1500),
    []
  );

  const checkNumberToSendMessage = async (num) => {
    //console.log("csearch authuser.id ", authUser.id);
    const { data } =
      await Api.checkNumberToSendMessageWithBuildingHideOldPTCSMS(num);
    setPhoneCanReceivedMessage(data);
    //console.log("checkNumberToSendMessage data ", data);
    //return data;
  };

  const getContact = async (search) => {
    const letter = /^[a-z]+$/;
    const number = /^[0-9+]+$/;

    if (search && search != authUser.phone) {
      if (
        (letter.test(search) && search.length >= 3) ||
        (number.test(search) && search.length >= 5)
      )
        setLoading(true);
      const params = new URLSearchParams([["search", `${search}`]]);

      const { data } = await Api.getSearchContactsMessages(
        authUser.organization_id,
        `?1=1&${params.toString()}`
      );

      //checkNumberToSendMessage(search);
      //console.log("checkNumberToSendMessage",tmp);
      let tmoOutText =
        "You can not comunicate with this number (Hide Old PTC SMS active)!";
      let data1 = data.data.map((user) => {
        return {
          id: user.id,
          full_name: user.full_name,
          phone: user.phone,
          fname: user.fname,
          lname: user.lname,
          user: user.user ? user.user : null,
          owner_name: user.owner_name ? user.owner_name : null,
          owner_id: user.owner_id ? user.owner_id : null,
          user_id: user.user_id ? user.user_id : null,
          building_id: user.building_id ? user.building_id : null,
          building: user.building ? user.building : null,
          record_id: user.record_id,
          portal_link: user.portal_link
        };
      });

      if (isEmpty(data1)) {
        Api.checkNumberToSendMessageWithBuildingHideOldPTCSMS(search)
          .then((res) => {
            //console.log(res.data);
            phoneCanReceived.current = res.data;

            data1 = [
              {
                id: -1,
                name: res.data && res.data == 1 ? search : tmoOutText,
                full_name: res.data && res.data == 1 ? search : tmoOutText,
                phone: res.data && res.data == 1 ? search : tmoOutText,
                fname: "",
                lname: "",
                owner_name: null,
                owner_id: null,
                user_id: null,
                building_id: null,
                building: null,
                record_id: null,
                portal_link: null
              },
            ];

            setOptions(data1);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setOptions(data1);
        phoneCanReceived.current = 1;
      }

      setLoading(false);
    } else {
      setOptions([]);
      phoneCanReceived.current = 1;
    }
  };

  /*useEffect(()=>{
    //console.log("phoneCanReceivedMessage",phoneCanReceivedMessage);
    
  },[phoneCanReceivedMessage]);*/

  return (
    <>
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
        >
          <div style={{ position: "fixed", zIndex: 1000 }}>
            <TextField
              id="phone"
              type="tel"
              value={searchContactText}
              placeholder={"Enter a Name or Number"}
              variant="outlined"
              autoFocus
              fullWidth
              // style={{ backgroundColor: "white" }}
              InputProps={{
                disableUnderline: true,
                classes: { root: classes.inputRoot },
                startAdornment: (
                  <InputAdornment position="start">
                    <SmsFailedRoundedIcon
                      fontSize="small"
                      style={{ color: "#066fac" }}
                    />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                pattern: "^[0-9*#+a-z]+$",
              }}
              onChange={(el) => {
                setSearchContactText(el.target.value);
                debouncedSearch(el.target.value);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "5%" }}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          {loading && (
            <CircularProgress
              classes={{ root: classes.spinner }}
              style={{ color: "#066fac" }}
              size={18}
            />
          )}
          <div className={classes.resultData}>
            {!isEmpty(options) && (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>PTC</TableCell>
                    <TableCell>Building</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {options.map((item, index) => {
                    return (
                      <TableRow
                        className={
                          item.owner_id == authUser.id
                            ? classes.historyRow
                            : classes.historyRow2
                        }
                        onClick={async () => {
                          // console.log(item.full_name);
                          phoneCanReceived.current &&
                          phoneCanReceived.current === 1
                            ? saveTo(item)
                            : "";
                          onClose();
                        }}
                      >
                        {/* <ListItem
                          key={item.id ? item.phone + item.id : item.phone}
                          alignItems="flex-start"
                          className={
                            item.owner_id == authUser.id
                              ? classes.historyRow
                              : classes.historyRow2
                          }
                          onClick={async () => {
                            // console.log(item.full_name);
                            saveTo(item);
                            onClose();
                          }}
                        >*/}
                        <TableCell>
                          <Typography
                            variant="p"
                            style={{
                              color: "#004d40",
                              fontWeight:
                                item.owner_id == authUser.id ? 700 : 300,
                            }}
                          >
                            {item.id > 1 ? item.full_name : "New message to: "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="p"
                            style={{
                              color: "#004d40",
                              fontWeight:
                                item.owner_id == authUser.id ? 700 : 300,
                            }}
                          >
                            {item.phone}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="p"
                            style={{
                              color: "#004d40",
                              fontWeight:
                                item.owner_id == authUser.id ? 700 : 300,
                            }}
                          >
                            {item.owner_name ? item.owner_name : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="p"
                            style={{
                              color: "#004d40",
                              fontWeight:
                                item.owner_id == authUser.id ? 700 : 300,
                            }}
                          >
                            {item.building ? item.building : ""}
                          </Typography>
                        </TableCell>
                        {/* </ListItem>*/}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
}
