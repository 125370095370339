import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
}));

export default function InvoicePaymentHistory(props) {
  const classes = useStyles();

  const { data } = props;
  const date = moment(data.created_at).format("MMM Do YYYY");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          created by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <MoneyOffIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            Refund
          </Typography>
          <Typography>{data.object.amount}</Typography>
          <Typography>{data.object.details}</Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
