import React, { useState } from "react";
import "react-calendar-heatmap/dist/styles.css";
import "./UserProcedureHeatmap.css";
import { makeStyles } from "@material-ui/core";

import moment from "moment";
import HeatMapCalendarMonth from "./HeatMapCalendarMonth";

const useStyles = makeStyles((theme) => ({
  dayPickerContainer: {
    "& .rdp-months .rdp-table": {
      width: "100%",
    },
    "& .rdp-months .rdp-head_cell": {
      fontWeight: 600,
      color: "#676f7b",
      fontSize: 16,
    },
  },
}));

function HeatMapCalendarMonthly({
  minDate = moment().add(1, "days"),
  startDate = moment(Date.now()),
  endDate = moment(Date.now()).add(11, "months"),
  width = "100%",
  monthWidth = "126px",
  showDay = false,
  dayColor = null,
  isDateDisabled = null,
  setDate = null,
  date = null,
}) {
  const classes = useStyles();

  const getMonthsBetweenDates = () => {
    const months = [];
    let currentDate = new Date(startDate);

    currentDate.setDate("01");

    let end = new Date(endDate);

    end.setDate("01");

    while (currentDate <= end) {
      months.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  };

  return (
    <div
      style={{
        maxWidth: width ? width : "fit-content",
        width: width,
        margin: "10px 0px",
        background: "white",
      }}
    >
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {getMonthsBetweenDates().map((month) => {
          return (
            <div style={{ margin: "0px 10px" }}>
              <HeatMapCalendarMonth
                minDate={minDate}
                date={date}
                startDate={startDate}
                setDate={setDate}
                isDateDisabled={isDateDisabled}
                dayColor={dayColor}
                showDay={showDay}
                width={monthWidth}
                month={month}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HeatMapCalendarMonthly;
