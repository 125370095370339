import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import AssistantForm from "./forms/AssistantForm";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";

import MaterialTable from "material-table";
import {
  Fab,
  Paper,
  Switch,
  withStyles,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core";
import Popup from "./Popup";

import { useTranslation } from "react-i18next";
import Api from "../lib/api";
import Notification from "./Notification";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#ffffff",
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #bdbdbd`,
    backgroundColor: "#cfd8dc",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },

  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function RecordProductAssistants(props) {
  const { authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [assistants, setAssistants] = useState([]);

  const [progressBar, setProgressBar] = useState(false);

  const [allAssistants, setAllAssistants] = useState(true);

  const { t, i18n } = useTranslation(["common", "records"]);

  const {
    record_id,
    onSave,
    onDeleteAssistant,
    coordinatorsFromDoctor,
    recordProductdatas,
  } = props;

  const dataMaterialTable = assistants ? assistants.map((o) => ({ ...o })) : [];

  const headCellsMaterial = [
    { field: "title", title: "Name" },
    { field: "position", title: "Position" },
    { field: "email", title: "email" },
    { field: "department_name", title: "Department" },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [surgicalAssistantDropdown, setSurgicalAssistantDropdown] = useState(
    []
  );

  const [surgicalAssistantDropdownL, setSurgicalAssistantDropdownL] =
    useState(assistants);

  const [noAdd, setNoAdd] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onDelete = async (id) => {
    onDeleteAssistant(id);
    // console.log("ID deLETE: ", id);
    setAssistants(assistants.filter((a) => a.id != id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: t("common:general.Del_Doc_ok"),
      type: "error",
    });
  };

  const saveAssistant = (info) => {
    const surgicalAssistantT = surgicalAssistantDropdown.find(
      (surgicalAssistant) => surgicalAssistant.id == info.assistantsToAdd
    );

    const doctorConfiguration =
      surgicalAssistantT &&
      !isEmpty(surgicalAssistantT.doctor_surgical_assistants)
        ? surgicalAssistantT.doctor_surgical_assistants.find(
            (surgicalAssistantsDoctor) =>
              surgicalAssistantsDoctor.doctor_id ===
              recordProductdatas?.responsible_id
          )
        : null;

    const assistantsToAdd = {
      user_id: info.assistantsToAdd,
      assistant_value: doctorConfiguration ? doctorConfiguration.assistant_value : null,
      percent: doctorConfiguration ? doctorConfiguration.percent : null,
    };

    const payload = {
      assistantsToAdd: JSON.stringify(assistantsToAdd),
    };

    onSave(payload).then(async () => {
      getAssitants();
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
    });
    onClose();
  };

  const assistantsOptions = () => {
    if (allAssistants) {
      return surgicalAssistantDropdown;
    } else {
      const surgicalAssistantDropdownF = surgicalAssistantDropdown.filter(
        (user) => {
          return user.doctor_ids.includes(recordProductdatas?.responsible_id);
        }
      );

      return surgicalAssistantDropdownF;
    }
  };

  const getSurgicalAssistant = async () => {
    const { data } = await Api.getSurgicalAssistant(authUser.organization_id);

    if (data && data.data) {
      setSurgicalAssistantDropdown(data.data);
      setSurgicalAssistantDropdownL(data.data);
    }
  };

  useEffect(() => {
    getSurgicalAssistant();
  }, []);

  useEffect(() => {
    setSurgicalAssistantDropdownL(assistantsOptions());
  }, [allAssistants]);

  useEffect(() => {
    // console.log("ASSISTANS", assistants);
    if (assistants && assistants.length >= 3) {
      setNoAdd(true);
    } else {
      setNoAdd(false);
    }
  }, [assistants]);

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (record_id) {
      setProgressBar(true);
      getAssitants().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getAssitants = async () => {
    const { data } = await Api.getAssistantsRP(record_id);
    setAssistants([...data.data]);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <div style={{ display: "flex", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top: "25px",
              left: "25vw",
            }}
          >
            <FormControlLabel
              labelPlacement="end"
              control={
                <IOSSwitch
                  key="allAssistants"
                  checked={allAssistants}
                  onChange={() => {
                    setAllAssistants(!allAssistants);
                  }}
                />
              }
              label={
                <ListItemText
                  style={{ paddingLeft: 15 }}
                  primary="All Assistants"
                  secondary="Display All Assistants in the Surgery Department."
                />
              }
            />
          </div>
        </div>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                ),
                tooltip: "Add Assistant (Max 3)",
                disabled: noAdd || !coordinatorsFromDoctor,
                hidden: noAdd || !coordinatorsFromDoctor,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton color="secondary">
                    <CloseIcon fontSize="small" />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                disabled: !coordinatorsFromDoctor,
                hidden: !coordinatorsFromDoctor,

                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this assistant?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.id),
                  }),
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 3, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Assistants (Max 3)"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Add Assistant"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <AssistantForm
          saveAssistant={saveAssistant}
          users={surgicalAssistantDropdownL}
          assistants={assistants}
          className={classes.form}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RecordProductAssistants;
