import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import CommentsRecord from "../../components/CommentsRecord";
import Documents from "../../components/Documents";
import Timeline from "../../components/Timeline";
import RecordForm from "../../components/forms/RecordForm";
import ProductsControl from "../../components/ProductsControl";
import RecordSummary from "../../components/RecordSummary";
import { useTranslation } from "react-i18next";
import SmsMmsHistory from "../../components/SmsMmsHistory";
import PhoneCalls from "../../components/PhoneCalls";
import SmsMmsUserList from "../../components/SmsMmsUserList";
import DocumentsSent from "../../components/DocumentsSent";
import Api from "../../lib/api";
import CRMUtils from "../../utils";
import ExtraPaymentsPatients from "../../components/ExtraPaymentsPatients";

import Iconify from "../../components/Iconify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    width: "100%",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "800px",
    margin: "0 auto",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function RecordProfile(props) {
  const {
    setRecord,
    record,
    saveComment,
    saveDocument,
    documentTypes,
    addOrEdit,
    tags,
    fields,
    onDeleteDocument,

    userCanEdit = true,
    userCanCreateComments = true,
    userCanCreateDocuments = true,
    userCanDeleteDocuments = true,
    opentab = 0,
    authUser,
    documents,
    usersMessages,
    readonly = false,
    rReadonly = false,
    editableNoDoctor = false,
    userIsCallCenter = false,
    userIsFrontDesk = false,
    userIsSocialMedia = false,
    errors = null,
    userIsBilling = false,
    userIsPersonalAssistant = false,
  } = props;
  const [selectedUserSMSMMSByGroup, setSelectedUserSMSMMSByGroup] = useState();
  const { t, i18n } = useTranslation(["common", "records"]);
  const [usersSMSMMS, setUsersSMSMMS] = useState([]);
  const [contactGroupsDetails, setContactGroupsDetails] = useState([]);
  const [contactUsersDetails, setContactUsersDetails] = useState([]);
  const [proceduresDropdown, setProceduresDropdown] = useState([]);
  const [selectedUserSMSMMS, setSelectedUserSMSMMS] = useState(
    authUser ? authUser.id : -1
  );

  const classes = useStyles();
  const [value, setValue] = useState(opentab ? opentab : 0);
  const pbxInformation = CRMUtils.userHasPermission(authUser, [
    "user_pbxInformation",
  ]);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);
  const userCanSendSMS = CRMUtils.userHasPermission(authUser, [
    "record_SendSMS",
  ])
    ? true
    : false;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchProceduresDropdown = async () => {
    const { data } = await Api.getRecordProductsPlain(record.id);
    setProceduresDropdown(data.data);
  };

  useEffect(() => {
    setUsersSMSMMS(usersMessages);
  }, [usersMessages]);

  useEffect(() => {
    smsmmsUsersFunc();
    smsmmsGroupsFunc();
  }, [record?.contact_id]);

  useEffect(() => {
    fetchProceduresDropdown();
  }, []);

  const smsmmsUsersFunc = async () => {
    const { data } = await Api.getUsersSmsMss(record?.contact_id);

    setContactUsersDetails(data.data);
  };

  const smsmmsGroupsFunc = async () => {
    const { data } = await Api.getGroupsSmsMss(record?.contact_id);
    setContactGroupsDetails(data.data);
  };

  const setContact = () => {
    setRecord({
      ...record,
      ...{
        contact: {
          data: {
            ...record.contact.data,
            ...{ invalid_phone_number_for_messaging: false },
          },
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="secondary"
        aria-label="icon label tabs example"
        orientation="vertical"
        className={classes.tabs}
        style={{ color: "#00897b" }}
        TabIndicatorProps={{
          style: { background: "#009688" },
        }}
      >
        <Tab
          icon={
            <Iconify
              icon={"material-symbols:person-pin-sharp"}
              width={28}
              height={28}
              style={{ color: "#4caf50" }}
            />
          }
          label={"Summary"}
        />
        <Tab
          icon={
            <Iconify
              icon={"mdi:chart-timeline-variant-shimmer"}
              style={{ color: "#ff5722" }}
              width={28}
              height={28}
            />
          }
          label={t("common:general.Timeline")}
        />
        <Tab
          icon={
            <Iconify
              icon={"fluent:form-multiple-48-filled"}
              width={30}
              height={30}
              style={{ color: "#2196f3" }}
            />
          }
          label={t("common:general.PersonalInfo")}
        />
        <Tab
          icon={
            <Iconify
              icon={"fa6-solid:comment-sms"}
              width={28}
              height={28}
              style={{ color: "#1565c0" }}
            />
          }
          label={t("common:general.SMS")}
          disabled={userCanViewEditContactMark}
        />
        <Tab
          icon={
            <Iconify
              icon={"solar:phone-calling-bold"}
              width={28}
              height={28}
              style={{ color: "#00695c" }}
            />
          }
          label={"Calls"}
          disabled={userCanViewEditContactMark}
        />
        <Tab
          icon={
            <Iconify
              icon={"majesticons:comment-text"}
              width={28}
              height={28}
              style={{ color: "#5e35b1" }}
            />
          }
          label={t("common:general.Comments")}
          disabled={userCanViewEditContactMark}
        />
        <Tab
          icon={
            <Iconify
              icon={"bi:file-text-fill"}
              style={{ color: "#37474f" }}
              width={28}
              height={28}
            />
          }
          label={t("common:general.Documents")}
        />
        <Tab
          icon={
            <Iconify
              icon={"heroicons-solid:credit-card"}
              style={{ color: "#00c853" }}
              width={28}
              height={28}
            />
          }
          label={t("common:general.ExtraPayment")}
        />
        <Tab
          icon={
            <Iconify
              icon={"eva:person-done-fill"}
              style={{ color: "#d32f2f" }}
              width={28}
              height={28}
            />
          }
          label={t("common:sidebar.Products")}
        />
        <Tab
          icon={
            <Iconify
              icon={"heroicons:document-arrow-up-solid"}
              style={{ color: "#33691e" }}
              width={28}
              height={28}
            />
          }
          label="Documents sent"
        />
      </Tabs>

      <TabPanel value={value} index={0} {...a11yProps(0)}>
        <RecordSummary
          record={record}
          saveComment={saveComment}
          documentTypes={documentTypes}
          saveDocument={saveDocument}
          onDeleteDocument={onDeleteDocument}
          showDelete={true}
          userCanEdit={userCanEdit}
          canAddComments={userCanEdit && userCanCreateComments}
          canAddDocuments={userCanEdit && userCanCreateDocuments}
          canDeleteDocuments={userCanEdit && userCanDeleteDocuments}
          readonly={readonly}
          rReadonly={rReadonly}
          editableNoDoctor={editableNoDoctor}
          userIsCallCenter={userIsCallCenter}
          userIsFrontDesk={userIsFrontDesk}
          userIsSocialMedia={userIsSocialMedia}
          userIsBilling={userIsBilling}
          proceduresDropdown={proceduresDropdown}
          userIsPersonalAssistant={userIsPersonalAssistant}
        />
      </TabPanel>
      <TabPanel
        className={classes.wrapText}
        value={value}
        index={1}
        {...a11yProps(1)}
      >
        <Timeline
          modelType="Record"
          model={record}
          typel="Record"
          createdAt={record.created_at}
          pbxInformation={pbxInformation}
          patient_name={record?.fname + record?.lname}
        />
      </TabPanel>

      <TabPanel value={value} index={2} {...a11yProps(2)}>
        <RecordForm
          fullScreen
          recordForEdit={record}
          proceduresDropdown={proceduresDropdown}
          addOrEdit={addOrEdit}
          tags={tags}
          customFields={fields}
          userCanEdit={userCanEdit}
          rReadonly={rReadonly}
          errorsFromRecord={errors}
        />
      </TabPanel>

      <TabPanel value={value} index={3} {...a11yProps(3)}>
        <Paper elevation={1} className={classes.paper}>
          <Grid container className={classes.container}>
            <Grid item xs={3}>
              {
                <SmsMmsUserList
                  contactGroupsDetails={contactGroupsDetails}
                  contactUsersDetails={contactUsersDetails}
                  contact_id={record.contact_id}
                  groupsOptions={authUser.communications_groups}
                  user_id={authUser.id}
                  setSelectedUserSMSMMS={setSelectedUserSMSMMS}
                  selectedUserSMSMMS={selectedUserSMSMMS}
                  setSelectedUserSMSMMSByGroup={setSelectedUserSMSMMSByGroup}
                  selectedUserSMSMMSByGroup={selectedUserSMSMMSByGroup}
                />
              }
            </Grid>
            <Grid item xs={9}>
              {
                <SmsMmsHistory
                  setContact={setContact}
                  contact={record.contact.data}
                  contactUsersDetails={contactUsersDetails}
                  userID={selectedUserSMSMMS}
                  setSelectedUserSMSMMS={setSelectedUserSMSMMS}
                  setSelectedUserSMSMMSByGroup={setSelectedUserSMSMMSByGroup}
                  selectedUserSMSMMSByGroup={selectedUserSMSMMSByGroup}
                  fromRecord={true}
                  userCanSend={
                    (userIsCallCenter ||
                      userIsFrontDesk ||
                      userIsSocialMedia) &&
                    !userCanSendSMS
                      ? false
                      : (authUser.organization &&
                          authUser.organization.data &&
                          authUser.organization.data.sms_integration === 1 &&
                          authUser.receive_sms === 1 &&
                          authUser.phone != "" &&
                          authUser.phone != null) &&
                        !rReadonly
                      ? true
                      : false
                  }
                />
              }
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>

      <TabPanel value={value} index={4} {...a11yProps(4)}>
        {
          <PhoneCalls
            contact_id={record.contact.data.id}
            userCanCall={
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.pbx_integration === 1 &&
              authUser.organization.data.pbx_integration_port
            }
          />
        }
      </TabPanel>

      <TabPanel value={value} index={5} {...a11yProps(5)}>
        <CommentsRecord
          comments={record.contactRecordComments.data}
          onSave={saveComment}
          canAdd={
            (userCanEdit && userCanCreateComments) ||
            userIsCallCenter ||
            userIsFrontDesk ||
            userIsSocialMedia ||
            userIsBilling ||
            userIsPersonalAssistant
          }
          readonly={
            readonly &&
            !userIsCallCenter &&
            !userIsFrontDesk &&
            !userIsSocialMedia &&
            !userIsBilling &&
            !userIsPersonalAssistant
          }
          rReadonly={rReadonly}
          editableNoDoctor={editableNoDoctor}
        />
      </TabPanel>

      <TabPanel value={value} index={6} {...a11yProps(6)}>
        <Documents
          documents={documents}
          documentTypes={documentTypes}
          onSave={saveDocument}
          onDeleteDocument={onDeleteDocument}
          showDelete={true}
          canAdd={
            (userCanEdit && userCanCreateDocuments) ||
            userIsFrontDesk ||
            userIsBilling ||
            userIsPersonalAssistant
          }
          canEdit={userCanEdit}
          canDelete={userCanEdit && userCanDeleteDocuments}
          enableCamera={true}
          rReadonly={rReadonly || userCanViewEditContactMark}
        />
      </TabPanel>

      <TabPanel value={value} index={7} {...a11yProps(7)}>
        <ExtraPaymentsPatients
          contact_id={record.contact_id}
          canAdd={userCanEdit && !rReadonly && !userCanViewEditContactMark}
          canEdit={userCanEdit && !rReadonly && !userCanViewEditContactMark}
          canDelete={userCanEdit && !rReadonly && !userCanViewEditContactMark}
        />
      </TabPanel>

      <TabPanel value={value} index={8} {...a11yProps(8)}>
        <ProductsControl record={record} />
      </TabPanel>

      <TabPanel value={value} index={9} {...a11yProps(9)}>
        <DocumentsSent record_id={record.id} />
      </TabPanel>
    </div>
  );
}

export default RecordProfile;
