import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  roles: [],
  roles2: [],
  saveLoading: false,
};

const RolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getRoles: (state) => {
      state.loading = true;
    },
    getRolesSuccess: (state, { payload }) => {
      state.roles = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getRolesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    getRoles2: (state) => {
      state.loading = true;
    },
    getRoles2Success: (state, { payload }) => {
      state.roles2 = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getRoles2Failure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createRole: (state) => {
      state.saveLoading = true;
    },
    createRoleSuccess: (state, { payload }) => {
      state.roles = [...state.roles, payload.data];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    createRoleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    updateRole: (state) => {
      state.saveLoading = true;
    },
    updateRoleSuccess: (state, { payload }) => {
      const index = findIndex(state.roles, ["id", payload.data.id]);
      const role = { ...state.roles[index], ...payload.data };

      let roles = [...state.roles];
      roles[index] = role;

      state.roles = [...roles];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    updateRoleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    deleteRole: (state) => {},
    deleteRoleSuccess: (state, { payload }) => {
      state.roles = state.roles.filter((role) => {
        return role.id !== payload;
      });
    },
    deleteRoleFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getRoles,
  getRolesSuccess,
  getRolesFailure,
  getRoles2,
  getRoles2Success,
  getRoles2Failure,
  createRole,
  createRoleSuccess,
  createRoleFailure,
  updateRole,
  updateRoleSuccess,
  updateRoleFailure,
  deleteRole,
  deleteRoleSuccess,
  deleteRoleFailure,
} = RolesSlice.actions;
export const RolesSelector = (state) => state.roles;
export default RolesSlice.reducer;

export function fetchRolesService(orgId) {
  return async (dispatch) => {
    dispatch(getRoles());

    try {
      const response = await Api.getRolesWithPermissions(orgId);

      dispatch(getRolesSuccess(response.data));
    } catch (error) {
      dispatch(getRolesFailure(error));
    }
  };
}

export function fetchRolesService2(orgId) {
  return async (dispatch) => {
    dispatch(getRoles2());

    try {
      const response = await Api.getRoles(orgId);

      dispatch(getRoles2Success(response.data));
    } catch (error) {
      dispatch(getRoles2Failure(error));
    }
  };
}

export function createRoleService(payload) {
  return async (dispatch) => {
    dispatch(createRole());

    try {
      const response = await Api.saveRole(payload);

      dispatch(createRoleSuccess(response.data));
    } catch (error) {
      dispatch(createRoleFailure(error));
    }
  };
}

export function updateRoleService(id, payload) {
  return async (dispatch) => {
    dispatch(updateRole());

    try {
      const response = await Api.updateRole(id, payload);

      dispatch(updateRoleSuccess(response.data));
    } catch (error) {
      dispatch(updateRoleFailure(error));
    }
  };
}

export function deleteRoleService(id) {
  return async (dispatch) => {
    dispatch(deleteRole());

    try {
      const response = await Api.deleteRole(id);

      dispatch(deleteRoleSuccess(id));
    } catch (error) {
      dispatch(deleteRoleFailure(error));
    }
  };
}
