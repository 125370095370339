import { Controller, useFormContext } from "react-hook-form"
import AutocompleteGeneric from "../../../../refunds/components/AutocompleteGeneric"
import { TicketsOptions, optionLabelUser } from "./RefundTask"

const EntityTypeRefundTask = "App\\Models\\RfsTickets";
const RefundInput = ({entityId}) => {

    const { control, register, setValue, formState:{errors} } = useFormContext();

    const handleSelected = (selected) => {
        if (selected && EntityTypeRefundTask) {
            setValue('entity_type', EntityTypeRefundTask);
        } else {
            setValue("entity_type", null);
        }
    }

    return (
        <>
                <Controller
                    name="entity_id" //always entity id, in tasks
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Need to select a refund ticket"
                        },
                    }}
                    render={({ onChange, onBlur, value }) => (
                        <AutocompleteGeneric
                            label="Refund Tickets"
                            objectId={entityId}
                            onChange={onChange}
                            RenderOption={TicketsOptions}
                            optionLabel={optionLabelUser}
                            model="rfs_tickets"
                            onSelected={handleSelected}
                            include={[
                                "user_assigned",
                                "building",
                                "department",
                                "priority",
                                "getState",
                            ]}
                            error={errors.entity_id}
                            helperText={errors.entity_id?.message}
                            searchBy={{'or:':["%subject", "%body"]}}
                            filters={{
                                "getState.code": "lost",
                                "task":null
                            }}
                            orderBy={"created_at desc"}
                        />
                    )}
                />
                <input
                    type="hidden"
                    {...register("entity_type")}
                    value="App\Models\RfsTickets"
                />
            </>
    )
}

export default RefundInput