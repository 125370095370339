import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";
import { toInteger, toNumber } from "lodash";
import moment from "moment";
import { fontFace } from "polished";

const UserOption = ({ option, selected }) => {
    return (
        <Card
            style={{
                width: "100%",
            }}
        >
            <CardContent
                style={{
                    backgroundColor: selected ? "lightgreen" : "inherit",
                }}
            >
                <Grid container direction="row">
                    <Grid item container direction="column" md={8}>
                        <Grid item container direction="row" spacing={2}>
                            <Grid item xs={3}>
                                <Avatar
                                    alt={option?.Avatar}
                                    src={option?.dropdown_name}
                                />
                            </Grid>
                            <Grid item xs={9} container direction="column">
                                <Typography variant="subtitle1">
                                    {option?.dropdown_name}
                                </Typography>
                                <Typography
                                    noWrap
                                    variant="body"
                                    style={{
                                        fontSize: 10,
                                    }}
                                >
                                    {option?.email}
                                </Typography>
                                <Typography
                                    noWrap
                                    variant="body"
                                    style={{
                                        fontSize: 10,
                                    }}
                                >
                                    {option?.phone}
                                </Typography>
                                <Typography
                                    variant="body"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    Gender: {option.gender}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container direction="column" md={4}>
                        <Typography variant="subtitle1">
                            {option.department?.name}
                        </Typography>
                        <Typography
                            noWrap
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option.languages?.length > 0
                                ? `Languages:${option.languages
                                      ?.map((l) => l.name)
                                      .join(", ")}`
                                : "No languages set"}
                        </Typography>
                        {/* <Typography
                            noWrap
                            variant="body"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {option?.payments?.length ?? 0} payments
                        </Typography>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            ${option?.balance?.toFixed(2)} Amount Due
                        </Typography> */}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default UserOption;
