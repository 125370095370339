import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Dialog,
} from "@material-ui/core";
import { grey, green, red } from "@material-ui/core/colors";
import MaterialTable from "material-table";
import { map, findIndex } from "lodash";
import styled from "styled-components";
import PersonIcon from "@material-ui/icons/Person";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isEmpty } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { fetchUsersServiceDropdown } from "../../redux/slices/users";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Api from "../../lib/api";
import Popup from "../Popup";
import "../print/toPrintPortrait.css";
import { useTranslation } from "react-i18next";
import SendContract from "../SendContract";
import TransferProcedureForm from "./TransferProcedureForm";
import InvoiceDetails from "../../pages/invoices/details";
import LoadingScreen from "../../components/LoadingScreen";
import CRMUtils from "../../utils";
import Iconify from "../Iconify";
import ConfirmDialog from "../ConfirmDialog";

const Wrapper = styled.div`
  position: relative,
  
  display: "block",
  
  width: 100%,
  margin: "25px",
  padding: "7px",
`;

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    wrapText: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "80px",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "90%",
      margin: theme.spacing(1),
    },
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  mainProduct: {
    maxWidth: "405px",
  },
  childProduct: {
    marginLeft: "35px",
    color: "#999",
    maxWidth: "405px",
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
}));

export default function PreopForm(props) {
  const {
    //recordfollowup,
    saveRecordFollowup,
    updateRecordProductData,
    recordProductForEdit,
    closePopup,
    cancellationMotives,
    saveRecordProductTransfer,
    updateOnlyRecordProduct,
    userDoctors,
    userCanEdit = true,
    userCanEditStatus = true,
    //coordinators,
    coordinatorsFromDoctor,
    userIsBillingSuper = false,
    showInvoice = true,
    userCanOverrideOfferDateLock = false,
  } = props;
  const statusArray = [
    {
      key: "pending",
      label: "Pending",
      color: "rgb(238 248 193)",
    },
    {
      key: "approved",
      label: "Approved",
      color: "rgb(197 248 199)",
    },
    {
      key: "declined",
      label: "Declined",
      color: "rgb(244 212 212)",
    },
  ];

  const getStatus = (status) => {
    const index = findIndex(statusArray, ["key", status]);
    if (index) {
      return statusArray[index];
    }

    return statusArray[0];
  };
  const objRef = useRef();

  const { t, i18n } = useTranslation(["common", "records"]);
  const TODAY = moment();
  const [customFields, setCustomFields] = useState(null);
  const [sendContractPopup, setSendContractPopup] = useState(false);

  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const disableDates2 = (dateL) => {
    // Allow super-admin or permission recordproduct_overrideOfferDateLock to select any date
    if (userCanOverrideOfferDateLock) {
      return false;
    }

    const date = moment(dateL);
    const weekDay = date.isoWeekday();

    if (weekDay === 7) return true;

    const userPriceA =
      recordProductForEdit && recordProductForEdit.userProductPrice
        ? recordProductForEdit.userProductPrice
        : null;

    if (
      (userPriceA &&
        userPriceA.booking_from &&
        date.isBefore(userPriceA.booking_from, "day")) ||
      (userPriceA &&
        userPriceA.booking_to &&
        date.isAfter(userPriceA.booking_to, "day"))
    ) {
      return true;
    }
    return false;
  };

  const openInvoicePopup = async (invoice) => {
    // const { data } = await Api.getInvoice(invoice.id);
    setInvoiceDetails(invoice);

    setOpenInvoice(true);
  };

  const closeInvoicePopup = async () => {
    setOpenInvoice(false);
    setInvoiceDetails(null);
  };

  const [coordinatorFromDoctor, setCoordinatorFromDoctor] = useState(false);
  const [coordinatorToDoctor, setCoordinatorToDoctor] = useState(false);

  const [transferPopup, setTransferPopup] = useState(false);

  const [transfer, setTransfer] = useState(
    recordProductForEdit
      ? recordProductForEdit?.recordProductTransfers?.data
        ? recordProductForEdit.recordProductTransfers.data.find(
            (recordProductTransfer) =>
              recordProductTransfer.status === "pending"
          )
        : null
      : null
  );

  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const [expanded, setExpanded] = useState("panel1");

  const billingReady = watch("rp_billing_ready");

  const dispatch = useDispatch();
  // const [customFieldslocal, setCustomFieldslocal] = useState(customFields);
  const [openConfirmationStatus, setOpenConfirmationStatus] = useState(false);
  // const [coordinators, setCoordinators] = useState([]);
  const calulateTotalRefunds = (item) => {
    const refunds = item.invoice_item.payment_detail
      ? item.invoice_item.payment_detail.filter((payment) => {
          return payment.isRefund;
        })
      : [];

    const totalRefunds = refunds.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    return totalRefunds;
  };

  const disableTransfer = () => {
    if (transfer) return true;

    return false;

    /* let flag = false;
    if (recordProductForEdit && recordProductForEdit?.recordProductTransfers) {
      recordProductForEdit.recordProductTransfers.data.forEach(
        (recordProductTransfer) => {
          if (recordProductTransfer.status === "pending") flag = true;
        }
      );
    }

    return flag;*/
  };

  const doctorTransfer = () => {
    const index = findIndex(userDoctors, [
      "id",
      recordProductForEdit?.responsible_id,
    ]);

    return index >= 0 ? true : false;
  };

  const AcceptTransferDoctor = () => {
    const index = findIndex(userDoctors, ["id", transfer?.responsible_id]);

    return index >= 0 ? true : false;
  };

  const dataMaterialTable =
    recordProductForEdit?.recordProductTransfers?.data.map((o) => ({ ...o }));
  const headCellsMaterial = [
    {
      field: "created",
      title: "Created at",
      sorting: false,
      render: (rowData) => {
        return new moment(rowData.created_at).format("MM-DD-YYYY");
      },
    },
    { field: "createdBy_fullname", title: "Created by", sorting: false },

    {
      field: "status",
      title: "Status",
      sorting: false,
      render: (rowData) => {
        return (
          <Chip
            label={getStatus(rowData.status).label}
            style={{ background: getStatus(rowData.status).color }}
          />
        );
      },
    },

    {
      field: "closedBy_fullname",
      title: "Closed by",
      sorting: false,
    },
    {
      field: "approved_at",
      title: "Closed at",
      sorting: false,
      render: (rowData) => {
        return rowData.approved_at
          ? new moment(rowData.approved_at).format("MM-DD-YYYY")
          : "";
      },
    },
    // { field: "old_building_name", title: "From Building" },
    {
      field: "old_coordinator_fullname",
      title: "From Coordinator",
      sorting: false,
    },
    {
      field: "old_responsible_fullname",
      title: "From Surgeon",
      sorting: false,
    },
    //{ field: "building_name", title: "To Building" },
    { field: "coordinator_fullname", title: "To Coordinator", sorting: false },
    { field: "responsible_fullname", title: "To Surgeon", sorting: false },
  ];

  const editTransfer = () => {
    if (recordProductForEdit && recordProductForEdit?.recordProductTransfers) {
      setTransfer(
        recordProductForEdit.recordProductTransfers.data
          ? recordProductForEdit.recordProductTransfers.data.find(
              (recordProductTransfer) =>
                recordProductTransfer.status === "pending"
            )
          : ""
      );
      setTransferPopup(true);
    }
  };

  const createTransfer = () => {
    setTransfer(null);

    setTransferPopup(true);
  };

  const saveRecordProductTransferdata = (payload) => {
    closeTransferPopup(false);

    if (transfer) {
      payload.transfer = true;
    }

    if (payload.status === "approved" && transfer) {
      let recordProductData = {};

      recordProductData.building_id = transfer.building_id;
      recordProductData.coordinator_id = transfer.coordinator_id;
      recordProductData.responsible_id = transfer.responsible_id;

      updateOnlyRecordProduct(
        recordProductForEdit.id,
        recordProductData,
        payload
      );
    } else {
      saveRecordProductTransfer(payload);
    }
    closePopup();
  };

  /* const assistantsOptionsPlain = (assistants) => {
    return assistants.map((assistant) => assistant.id);
  };

  const assistantsOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_assistant === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };*/

  const calulateTotalPayments = (item) => {
    const payments = item.invoice_item.payment_detail
      ? item.invoice_item.payment_detail.filter((payment) => {
          return !payment.isRefund;
        })
      : [];

    const totalPayments = payments.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    return totalPayments;
  };

  const calulateTotalCredits = (item) => {
    const totalPayments = item.invoice_item.credit_memo_detail
      ? item.invoice_item.credit_memo_detail.reduce(
          (total, item) => total + parseFloat(item.amount),
          0
        )
      : 0;
    return totalPayments;
  };

  const calcDue = () => {
    const Due = recordProductForEdit.invoice_item
      ? (recordProductForEdit.invoice_item.price_includes_qty
          ? 1
          : recordProductForEdit.invoice_item.quantity) *
          recordProductForEdit.invoice_item.price -
        (calulateTotalPayments(recordProductForEdit) +
          calulateTotalCredits(recordProductForEdit) -
          calulateTotalRefunds(recordProductForEdit))
      : 0;

    // console.log("Due", Due);
    return Due;
  };

  const [responsible, setResponsible] = useState(
    recordProductForEdit.responsible
      ? recordProductForEdit.responsible.data.id
      : null
  );
  const [buildings, setBuildings] = useState();
  const { usersDropdown } = useSelector((state) => state.users);
  //const { users } = useSelector((state) => state.users);
  const { authUser } = useSelector((state) => state.auth);

  /*const getSurgeonCoordinator = async (value) => {
    if (recordProductForEdit.responsible_id) {
      const { data } = await Api.getUserCoordinators(
        recordProductForEdit.responsible_id
      );
      setCoordinators(data.data);
    }
  };*/
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const transferRecordProduct =
    authUser && authUser.organization.data.transferRecordProduct ? true : false;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [checked, setChecked] = useState(recordProductForEdit.finalized);

  const classes = useStyles();

  const userCanFinalize = CRMUtils.userHasPermission(authUser, [
    "recordproduct_close",
  ]);

  const userCanFinalizeAfter = CRMUtils.userHasPermission(authUser, [
    "recordproduct_closeAfter",
  ]);

  const userCanTransfer = CRMUtils.userHasPermission(authUser, [
    "recordproduct_transfer",
  ]);

  const userCanSuperTransfer = CRMUtils.userHasPermission(authUser, [
    "recordproduct_supertransfer",
  ]);

  const userCanSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_super",
  ]);

  const userCanReOpen = CRMUtils.userHasPermission(authUser, [
    "recordproduct_reopen",
  ]);

  const userCanBillingReady = CRMUtils.userHasPermission(authUser, [
    "recordproduct_billingReady",
  ]);

  const canFinalize = () => {
    const can = recordProductForEdit.date
      ? !userCanFinalizeAfter &&
        TODAY.format("YYYY-MM-DD") >=
          moment(recordProductForEdit.date).add(11, "days").format("YYYY-MM-DD")
        ? false
        : userCanFinalize &&
          recordProductForEdit.date <= TODAY.format("YYYY-MM-DD HH:mm:ss") &&
          recordProductForEdit.invoice.data.balance <= 0 &&
          recordProductForEdit.responsible_id != null &&
          (recordProductForEdit.billing_ready_mandatory === 0 ||
            (recordProductForEdit.billing_ready_mandatory === 1 &&
              (billingReady || recordProductForEdit.billing_ready)))
        ? true
        : false
      : false;

    return can;
  };

  /*
  const userFromDoctor = (item) => {
    if (userCanSuper) {
      return true;
    }

    if (!isEmpty(coordinators)) {
      const index = findIndex(coordinators, ["id", authUser.id]);

      return index >= 0 ? true : false;
    }
    return false;
  };
*/

  const cancellationOptions = () => {
    return cancellationMotives.map((cancellationMotive) => {
      return {
        id: cancellationMotive.name,
        title: cancellationMotive.name,
      };
    });
  };

  const status_options = [
    {
      id: "TENTATIVE",
      title: t("records:records.tentative"),
    },
    {
      id: "SCHEDULED",
      title: t("records:records.scheduled"),
    },
    {
      id: "CONFIRMED",
      title: t("records:records.confirmed"),
    },
    {
      id: "LABS RECEIVED",
      title: t("records:records.labs_received"),
    },
    {
      id: "READY",
      title: t("records:records.ready"),
    },
    {
      id: "CLEARED",
      title: t("records:records.cleared"),
    },
    {
      id: "CANCELED",
      title: t("records:records.canceled"),
    },
    {
      id: "PENDING FOR RE-SCHEDULE",
      title: t("records:records.pending"),
    },
    {
      id: "REFUND",
      title: t("records:records.refund"),
    },
  ];

  const onSubmit = (data) => {
    const followupData = formatFollowupData(data);
    const recordProductData = formatnewrecordProduct(data);

    saveRecordFollowup(
      !isEmpty(recordProductForEdit.recordfollowup.data)
        ? recordProductForEdit.recordfollowup.data.id
        : null,
      followupData,
      recordProductForEdit.id,
      recordProductData
    );
  };

  const saveRecordProductStatusHistory = async (payload) => {
    if (payload.date_after) {
      payload.date = payload.date_after;
    }

    await Api.createProductRecordStatusHistory(
      recordProductForEdit.id,
      payload
    ).then((updatedResult) => {
      const newRecord = updatedResult.data.data;

      setValue("rp_date", newRecord.date, {
        shouldValidate: true,
      });

      setValue("rp_pre_date", newRecord.pre_date, {
        shouldValidate: true,
      });

      setValue("rp_tentative_date", newRecord.tentative_date, {
        shouldValidate: true,
      });

      updateRecordProductData(newRecord);
    });
  };

  const onSubmit2 = (data) => {
    if (
      data.date &&
      recordProductForEdit.date &&
      data.date != recordProductForEdit.date &&
      data.status == "SCHEDULED" &&
      recordProductForEdit.is_master_with_siblings
    ) {
      setConfirmDialog({
        isOpen: true,
        title:
          "Changing this date will also change the date of all procedures on the same day",
        subTitle: "Are you sure to continue?",
        onConfirm: () => {
          setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
          var payload = {};
          if (data != undefined) {
            payload.record_products_id = recordProductForEdit.id;
            payload.status = data.status;
            payload.comment = data.comment;
            if (data.rp_date) payload.date_before = data.rp_date;
            if (data.date) {
              payload.date_after = moment(data.date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              payload.tentative_date_before = data?.rp_tentative_date || null;
              payload.tentative_date_after = null;
            }

            if (data.rp_tentative_date) {
              payload.tentative_date_before = moment(
                data.rp_tentative_date
              ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (data.tentative_date) {
              payload.tentative_date_after = moment(data.tentative_date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }

            payload.created_by = authUser.id;
            payload.cancellation_reason = data.cancellation_reason;
          }

          try {
            saveRecordProductStatusHistory(payload);
          } catch (error) {
            console.log("Error:", error);
          }
          setOpenConfirmationStatus(false);
        },
      });
    } else {
      var payload = {};
      if (data != undefined) {
        payload.record_products_id = recordProductForEdit.id;
        payload.status = data.status;
        payload.comment = data.comment;
        if (data.rp_date) payload.date_before = data.rp_date;
        if (data.date) {
          payload.date_after = moment(data.date).format("YYYY-MM-DD HH:mm:ss");
          payload.tentative_date_before = data?.rp_tentative_date || null;
          payload.tentative_date_after = null;
        }

        if (data.rp_tentative_date) {
          payload.tentative_date_before = moment(data.rp_tentative_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        if (data.tentative_date) {
          payload.tentative_date_after = moment(data.tentative_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }

        payload.created_by = authUser.id;
        payload.cancellation_reason = data.cancellation_reason;
      }

      try {
        saveRecordProductStatusHistory(payload);
      } catch (error) {
        console.log("Error:", error);
      }
      setOpenConfirmationStatus(false);
    }
  };

  const sendContract = (data) => {
    setSendContractPopup(false);
  };

  const closeTransferPopup = () => {
    setTransferPopup(false);
  };

  const closeSendContractPopup = () => {
    setSendContractPopup(false);
  };

  const formatFollowupData = (data) => {
    let followupData = {};
    let followupProperties = {};

    Object.keys(data).forEach((key) => {
      const xk = key.slice(0, 3);
      if (xk == "cf_") {
        const fixedKey = key.slice(3, key.length);
        followupProperties[fixedKey] = data[key];
      } else {
        followupData[key] = data[key];
      }
    });
    followupData.properties = JSON.stringify(followupProperties);
    followupData.record_product_id = recordProductForEdit.id;
    return followupData;
  };
  const getCustomFields = async () => {
    let cFields = [];

    try {
      cFields = await Api.getRecordfollowupCustomFields(
        authUser.organization_id
      );
    } catch (e) {
      console.log(e);
    }

    setCustomFields(cFields.data);
  };
  const formatnewrecordProduct = (data) => {
    if (recordProductForEdit?.is_ready == 0) {
      if (data.rp_is_ready) {
        data.rp_ready_date = moment().format("YYYY-MM-DD");
      }
    } else if (recordProductForEdit?.is_ready == 1) {
      if (data.rp_is_ready == 0) {
        data.rp_ready_date = null;
      }
    }

    let newrecordProduct = {};

    Object.keys(data).forEach((key) => {
      const xk = key.slice(0, 3);
      if (xk == "rp_") {
        const fixedKey = key.slice(3, key.length);
        newrecordProduct[fixedKey] = data[key];
      }
    });

    return newrecordProduct;
  };

  const getUserOptions = () => {
    return usersDropdown.map((user) => {
      return {
        id: user.id,
        title: `${user.first_name} ${user.last_name}`,
      };
    });
  };
  const getConsultantOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_salesman === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };
  const getCoordinatorOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_coordinator === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };
  const getResponsibleOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
          building_id: user.building_id,
        };
      });
  };
  const getFieldSource2 = (source) => {
    const list = source ? JSON.parse(source) : [];
    return list.map((value) => {
      return {
        value: value.id,
        label: value.value,
      };
    });
  };

  const getBuildingOptions = () => {
    if (!recordProductForEdit.responsible.data) return [];
    return recordProductForEdit?.responsible.data.buildings.data.map(
      (building) => {
        return {
          id: building.id,
          title: building.name,
        };
      }
    );
  };

  const getBuildingOptions2 = () => {
    if (!buildings) return [];
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      (item.type == "package" ? item.package_price : item.price) *
        (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };

  const renderItems = () => {
    /* console.log(
      "recordProductForEdit.invoice.data.items",
      recordProductForEdit.invoice.data.items
    );*/
    const invoiceItems = recordProductForEdit.invoice.data.items.data || [];

    return map(invoiceItems, (item) => {
      return (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            <ListItem>
              {item.type == "package" ? (
                <ListItemIcon>
                  <Iconify
                    icon="entypo:shopping-bag"
                    style={{ fontSize: 18, color: "gray" }}
                  />
                </ListItemIcon>
              ) : (
                ""
              )}
              {item.responsible && (
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography>
                    {item.name}
                    {item.is_revision ? (
                      <strong
                        style={{
                          color: "#326a9b",
                          marginLeft: 5,
                          fontWeight: 400,
                        }}
                      >
                        {"(Revision)"}
                      </strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                }
                className={
                  item && item.parent_id
                    ? classes.childProduct
                    : classes.mainProduct
                }
                secondary={item.responsible ? item.responsible.title : null}
              />
            </ListItem>
          </TableCell>

          <TableCell>
            {" "}
            {item.parent_id != null
              ? "INCLUDED"
              : `$ ${
                  item.type == "package"
                    ? parseFloat(item.package_price).toFixed(2)
                    : parseFloat(item.price).toFixed(2)
                }`}
          </TableCell>
          <TableCell>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{item.quantity || 1}</Typography>
              {item.void_quantity ? (
                <Typography
                  style={{
                    fontWeight: 500,
                    marginLeft: 5,
                    color: "#a76c65",
                    fontSize: 12,
                  }}
                >{`(${item.void_quantity} VOID)`}</Typography>
              ) : (
                ""
              )}
            </div>
          </TableCell>

          <TableCell align="right">
            {item.parent_id != null
              ? "INCLUDED"
              : `$ ${calculateItemTotal(item).toFixed(2)}`}
          </TableCell>

          <TableCell align="right">{item.void === 1 ? "VOID" : ""}</TableCell>
        </TableRow>
      );
    });
  };

  const getFieldSource = (source) => {
    const list = source ? JSON.parse(source) : [];
    return list.map((value) => {
      return {
        id: value.id,
        title: value.value,
      };
    });
  };

  const renderCustomField = (field) => {
    let defaultValue = "";

    if (
      !isEmpty(recordProductForEdit.recordfollowup.data) &&
      !isEmpty(recordProductForEdit.recordfollowup.data.properties)
    ) {
      const properties = JSON.parse(
        recordProductForEdit.recordfollowup.data.properties
      );

      defaultValue = properties[field.key];
    }

    switch (field.type) {
      case "string":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.Input
                  label={field.title}
                  fullWidth={"true"}
                  disabled={!userCanEdit}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "yes/no":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.RadioGroup
                  label={field.title}
                  disabled={!userCanEdit}
                  items={[
                    {
                      id: "yes",
                      title: t("common:general.Yes"),
                    },
                    {
                      id: "no",
                      title: t("common:general.No"),
                    },
                  ]}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "money":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.InputMoney
                  label={field.title}
                  fullWidth={"true"}
                  disabled={!userCanEdit}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "date/time":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.DateTimePicker
                  label={field.title}
                  disabled={!userCanEdit}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "date":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.DatePicker
                  label={field.title}
                  disabled={!userCanEdit}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;

      case "relation":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.SelectModel
                  label={field.title}
                  modelid={field.source_model_id}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{
                required: `${field.title} ` + t("common:general.Required"),
              }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;

      case "list":
        switch (field.design) {
          case "dropdown":
            if (field.multiple) {
              return (
                <>
                  <Controller
                    name={`cf_${field.key}`}
                    control={control}
                    defaultValue={defaultValue}
                    as={({ onChange, value }) => (
                      <Controls.MultipleSelect
                        label={field.title}
                        disabled={!userCanEdit}
                        options={getFieldSource(field.source)}
                        onChange={(value) => onChange(value)}
                        value={value}
                      />
                    )}
                    rules={{ required: field.required === 1 ? true : false }}
                  />
                  {errors[`cf_${field.key}`] && (
                    <p className={classes.error}>
                      {`${field.title} ` + t("common:general.Required")}
                    </p>
                  )}
                </>
              );
              break;
            } else {
              return (
                <>
                  <Controller
                    name={`cf_${field.key}`}
                    control={control}
                    defaultValue={defaultValue}
                    as={({ onChange, value }) => (
                      <Controls.Select
                        disabled={!userCanEdit}
                        label={field.title}
                        options={getFieldSource(field.source)}
                        onChange={(value) => onChange(value)}
                        value={value}
                      />
                    )}
                    rules={{ required: field.required === 1 ? true : false }}
                  />
                  {errors[`cf_${field.key}`] && (
                    <p className={classes.error}>
                      {`${field.title} ` + t("common:general.Required")}
                    </p>
                  )}
                </>
              );
              break;
            }

          case "checkbox":
            if (field.multiple) {
              return (
                <>
                  <Controller
                    name={`cf_${field.key}`}
                    control={control}
                    defaultValue={defaultValue}
                    as={({ onChange, value }) => (
                      <Controls.CheckboxesGroup
                        checkboxes={getFieldSource2(field.source)}
                        defaultValue={defaultValue}
                        disabled={!userCanEdit}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        label={field.title}
                        value={value}
                      />
                    )}
                    rules={{ required: field.required === 1 ? true : false }}
                  />

                  {errors[`cf_${field.key}`] && (
                    <p className={classes.error}>
                      {`${field.title} ` + t("common:general.Required")}
                    </p>
                  )}
                </>
              );

              break;
            } else {
              return (
                <>
                  <Controller
                    name={`cf_${field.key}`}
                    control={control}
                    defaultValue={defaultValue ? defaultValue : ""}
                    as={({ onChange, value }) => (
                      <Controls.Checkbox
                        disabled={!userCanEdit}
                        label={field.title}
                        options={getFieldSource(field.source)}
                        onChange={(value) => onChange(value)}
                        value={value}
                      />
                    )}
                    rules={{ required: field.required === 1 ? true : false }}
                  />

                  {errors[`cf_${field.key}`] && (
                    <p className={classes.error}>
                      {`${field.title} ` + t("common:general.Required")}
                    </p>
                  )}
                </>
              );

              break;
            }
          default:
            return (
              <>
                <Controller
                  name={`cf_${field.key}`}
                  control={control}
                  defaultValue={defaultValue}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={!userCanEdit}
                      label={field.title}
                      options={getFieldSource(field.source)}
                      onChange={(value) => onChange(value)}
                      value={value}
                    />
                  )}
                  rules={{
                    required: `${field.title} ` + t("common:general.Required"),
                  }}
                />
                {errors[`cf_${field.key}`] && (
                  <p className={classes.error}>
                    {`${field.title} ` + t("common:general.Required")}
                  </p>
                )}
              </>
            );
            break;
        }
      default:
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.Input
                  label={field.title}
                  fullWidth={"true"}
                  disabled={!userCanEdit}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
    }
  };

  const renderCustomFields = (items, showid) => {
    if (showid == 1) {
      let item1 = items.slice(0, items.length / 2);
      return item1.map((field) => {
        return <div key={field.key}>{renderCustomField(field)}</div>;
      });
    } else if (showid == 2) {
      let item2 = items.slice(items.length / 2, items.length);
      return item2.map((field) => {
        return <div key={field.key}>{renderCustomField(field)}</div>;
      });
    }
  };

  useEffect(() => {
    if (!recordProductForEdit.finalized && !billingReady) {
      setValue("rp_finalized", 0);
    }
  }, [billingReady]);

  useEffect(() => {
    if (isEmpty(usersDropdown))
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
    getCustomFields();

    // getSurgeonCoordinator();
    //if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));
  }, []);

  useEffect(() => {
    if (responsible) {
      Api.getUserBuildings(responsible).then((result) => {
        setBuildings(result.data.data);
      });
    } else {
      Api.getBuildings(authUser.organization_id).then((result) => {
        setBuildings(result.data.data);
      });
    }
  }, [responsible]);

  useEffect(() => {
    const en = doctorTransfer();
    const es = AcceptTransferDoctor();
    setCoordinatorFromDoctor(en);
    setCoordinatorToDoctor(es);
  }, [userDoctors]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid item xs={3}>
                <Typography variant="h4">
                  Record:{" "}
                  {recordProductForEdit.invoice.data.contact.fname +
                    " " +
                    recordProductForEdit.invoice.data.contact.lname}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box
                  style={{
                    borderRadius: 25,
                    backgroundColor: recordProductForEdit?.billing_ready
                      ? "#e1f5fe"
                      : "#ffebee",
                    width: 250,
                    height: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow:
                      "0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)",
                    marginBottom: 5,
                  }}
                >
                  <Typography
                    style={{
                      color: recordProductForEdit?.billing_ready
                        ? "#0288d1"
                        : "#d32f2f",
                      fontWeight: 700,
                    }}
                  >
                    {recordProductForEdit?.billing_ready
                      ? "Billing Ready: Yes"
                      : "Billing Ready: No"}
                  </Typography>
                </Box>{" "}
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="rp_pre_date"
                  control={control}
                  defaultValue={recordProductForEdit?.pre_date || null}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      disabled={
                        // userIsBillingSuper && userCanEdit
                        //   ? false
                        //   :
                        recordProductForEdit.finalized ||
                        !userCanEdit ||
                        !coordinatorsFromDoctor
                      }
                      label={t("records:records.predate")}
                      onChange={(date) => onChange(date)}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="rp_date"
                  control={control}
                  defaultValue={recordProductForEdit?.date || null}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      disabled /*={
                        recordProductForEdit.date ||
                        recordProductForEdit.finalized
                      }*/
                      label={t("common:general.Date")}
                      format={"MM/DD/yyyy HH:mm"}
                      onChange={(date) => onChange(date)}
                      value={value}
                      shouldDisableDate={disableDates2}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="rp_tentative_date"
                  control={control}
                  defaultValue={recordProductForEdit?.tentative_date || null}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      disabled
                      label={t("common:general.Tentative_date")}
                      format={"MM/DD/yyyy HH:mm"}
                      onChange={(date) => onChange(date)}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="rp_duration"
                  control={control}
                  defaultValue={recordProductForEdit?.duration || null}
                  as={({ onChange, value }) => (
                    <TextField
                      disabled={
                        recordProductForEdit.finalized ||
                        !userCanEdit ||
                        !coordinatorsFromDoctor
                      }
                      className={classes.textfield}
                      value={value}
                      variant="outlined"
                      label={t("common:general.Duration")}
                      onChange={(data) => onChange(data.target.value)}
                      inputProps={{
                        decimalPrecision: 0,
                        decimalSeparator: false,
                        allowNegative: false,
                        /*isAllowed: (values) => {
                  const { floatValue } = values;
                  return floatValue >= 1 && floatValue <= 480;
                },*/
                      }}
                      InputProps={{
                        inputComponent: Controls.NumberFormatCustom,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="rp_responsible_id"
                  control={control}
                  defaultValue={recordProductForEdit?.responsible_id || null}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={
                        recordProductForEdit.finalized ||
                        (((transferRecordProduct &&
                          recordProductForEdit.responsible_id) ||
                          !userCanEdit) &&
                          !userIsBillingSuper)
                      }
                      label={t("common:general.Surgeon")}
                      options={getResponsibleOptions()}
                      onChange={(value) => {
                        onChange(value);
                        setResponsible(value.target.value);
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="rp_coordinator_id"
                  control={control}
                  defaultValue={recordProductForEdit?.coordinator_id || null}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={
                        recordProductForEdit.finalized ||
                        (transferRecordProduct &&
                          recordProductForEdit.coordinator_id) ||
                        (!userCanSuper &&
                          recordProductForEdit?.coordinator_id) ||
                        !userCanEdit ||
                        !coordinatorsFromDoctor
                      }
                      label={t("records:records.coordinator")}
                      options={getCoordinatorOptions()}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="rp_saleman_id"
                  control={control}
                  defaultValue={recordProductForEdit?.saleman_id || null}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label={t("common:general.Consultant")}
                      disabled
                      options={getConsultantOptions()}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={recordProductForEdit?.status || null}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      disabled={
                        // userIsBillingSuper && userCanEdit
                        //   ? false
                        //   :
                        !userCanEditStatus
                          ? true
                          : recordProductForEdit.finalized ||
                            ((!userCanEdit || !coordinatorsFromDoctor) &&
                              !userCanSuperTransfer &&
                              !userIsBillingSuper)
                      }
                      label={t("common:general.Status")}
                      options={status_options}
                      // onChange={(value) => {
                      //   onChange(value);
                      //   setOpenConfirmationStatus(value.target.value);
                      // }}
                      setSelectedItem={(value) => {
                        onChange(value.id);
                        setOpenConfirmationStatus(value.id);
                      }}
                      onClick={(value) => {
                        if (value.target.value == 0) {
                          onChange(recordProductForEdit.status);
                          setOpenConfirmationStatus(
                            recordProductForEdit.status
                          );
                        }
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>

              {authUser.organization.data.multiple_locations === 1 && (
                <Grid item xs={4}>
                  <Controller
                    name="rp_building_id"
                    control={control}
                    defaultValue={recordProductForEdit?.building_id || null}
                    as={({ onChange, value }) => (
                      <Controls.Select
                        disabled={
                          recordProductForEdit.finalized ||
                          (((transferRecordProduct &&
                            recordProductForEdit.building_id) ||
                            !userCanEdit ||
                            !coordinatorsFromDoctor) &&
                            !userIsBillingSuper)
                        }
                        label="Building"
                        options={getBuildingOptions2()}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Controller
                      name="rp_is_ready"
                      control={control}
                      defaultValue={recordProductForEdit?.is_ready || 0}
                      as={({ value, onChange }) => (
                        <Controls.Checkbox
                          label="Ready for Surgery?"
                          disabled={
                            recordProductForEdit.finalized ||
                            !userCanEdit ||
                            !coordinatorsFromDoctor
                          }
                          checked={value === 1 ? true : false}
                          onChange={(value) => onChange(value ? 1 : 0)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="rp_is_confirmed"
                      control={control}
                      defaultValue={recordProductForEdit?.is_confirmed || 0}
                      as={({ value, onChange }) => (
                        <Controls.Checkbox
                          label="Surgery confirmed?"
                          disabled={
                            recordProductForEdit.finalized ||
                            !userCanEdit ||
                            !coordinatorsFromDoctor
                          }
                          checked={value === 1 ? true : false}
                          onChange={(value) => onChange(value ? 1 : 0)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {customFields
                  ? customFields.map((items) => {
                      return (
                        <Accordion
                          disabled={!coordinatorsFromDoctor}
                          expanded={expanded === "panel" + items[0].id}
                          onChange={handleChange("panel" + items[0].id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography>{items[0].category.title}</Typography>
                          </AccordionSummary>
                          {
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  {renderCustomFields(items, 1)}
                                </Grid>
                                <Grid item xs={6}>
                                  {renderCustomFields(items, 2)}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          }
                        </Accordion>
                      );
                    })
                  : ""}
              </Grid>
              {/* billingReady */}
              <Grid item xs={3}>
                Billing Ready :
                <Controller
                  name="rp_billing_ready"
                  control={control}
                  defaultValue={recordProductForEdit?.billing_ready}
                  as={({ onChange, value }) => (
                    <Switch
                      disabled={
                        (!recordProductForEdit?.billing_ready_payment_approved_mandatory &&
                          (!userCanBillingReady ||
                            recordProductForEdit?.finalized)) ||
                        (recordProductForEdit?.billing_ready_payment_approved_mandatory &&
                          (!recordProductForEdit?.quoteApproved ||
                            recordProductForEdit?.invoiceBalance > 0))
                      }
                      checked={value}
                      onChange={(value) => onChange(value.target.checked)}
                      name="billing_ready"
                      color="primary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                Finalized :
                <Controller
                  name="rp_finalized"
                  control={control}
                  defaultValue={checked}
                  as={({ onChange, value }) => (
                    <Switch
                      disabled={
                        (!recordProductForEdit.finalized && !canFinalize()) ||
                        (recordProductForEdit.finalized && !userCanReOpen) ||
                        (!recordProductForEdit.quoteApproved &&
                          authUser.organization &&
                          authUser.organization.data &&
                          authUser.organization.data.quote_approved_mandatory)
                      }
                      /*/ onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}*/
                      checked={value}
                      onChange={(value) => onChange(value.target.checked)}
                      name="finalized"
                      color="primary"
                    />
                  )}
                />
                {/*"To close you must add at least 1 assistant"*/}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {showInvoice && (
              <Box m={3}>
                <Card
                  style={{
                    borderRadius: 25,
                    boxShadow: "0 0 2px 1px rgba(0, 140, 186, 0.5)",
                  }}
                >
                  <CardHeader
                    style={{ backgroundColor: "#e0f2f1" }}
                    title={recordProductForEdit?.invoice?.data.name}
                    subheader={
                      recordProductForEdit?.invoice
                        ? moment(
                            recordProductForEdit?.invoice?.data.date
                          ).format("MMMM Do YYYY, h:mm:ss a")
                        : ""
                    }
                    //"September 14, 2016"
                  />
                  <CardContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>

                          <TableCell>Price</TableCell>
                          <TableCell>Quantity</TableCell>

                          <TableCell align="right">Total</TableCell>

                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {recordProductForEdit.invoice ? renderItems() : ""}

                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell align="right">
                            <Box fontWeight="fontWeightBold" m={1}>
                              Total
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Box m={1}>
                              ${" "}
                              {recordProductForEdit.invoice
                                ? recordProductForEdit.invoice.data.total_price.toLocaleString(
                                    "en",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : 0}
                            </Box>
                          </TableCell>
                          <TableCell />
                        </TableRow>

                        <TableRow></TableRow>
                        <TableRow></TableRow>
                        <TableRow>
                          <TableCell />
                          <TableCell />

                          <TableCell align="right">
                            <Box
                              fontWeight="fontWeightBold"
                              m={1}
                              color="green"
                            >
                              Paid
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Box m={1} color="green">
                              ${" "}
                              {recordProductForEdit.invoice
                                ? recordProductForEdit.invoice.data.paid.toLocaleString(
                                    "en",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : 0}
                            </Box>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        {recordProductForEdit.invoice &&
                          recordProductForEdit.invoice.data.totalCredits >
                            0 && (
                            <TableRow>
                              <TableCell />
                              <TableCell />
                              <TableCell align="right">
                                <Box
                                  fontWeight="fontWeightBold"
                                  m={1}
                                  color="green"
                                >
                                  Credits
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                <Box m={1} color="green">
                                  ${" "}
                                  {recordProductForEdit.invoice.data.totalCredits.toLocaleString(
                                    "en",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          )}
                        {recordProductForEdit.invoice &&
                          recordProductForEdit.invoice.data.refund > 0 && (
                            <TableRow>
                              <TableCell />
                              <TableCell />
                              <TableCell align="right">
                                <Box
                                  fontWeight="fontWeightBold"
                                  m={1}
                                  color="red"
                                >
                                  Refunds
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                <Box m={1} color="red">
                                  ${" "}
                                  {recordProductForEdit.invoice.data.refund.toLocaleString(
                                    "en",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          )}
                        <TableRow>
                          <TableCell />
                          <TableCell />

                          <TableCell align="right">
                            <Box fontWeight="fontWeightBold" m={1} color="red">
                              Due
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Box m={1} color="red">
                              ${" "}
                              {recordProductForEdit.invoice.data.balance.toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Box>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#009688",
                        color: "#ffffff",
                        borderRadius: 8,
                        marginRight: 25,
                        marginBottom: 10,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openInvoicePopup(recordProductForEdit?.invoice?.data);
                      }}
                    >
                      Go to Invoice
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            )}

            {recordProductForEdit &&
            !isEmpty(recordProductForEdit?.recordProductTransfers.data) ? (
              <Box
                m={3}
                border={1}
                borderColor="primary.main"
                style={{ maxWidth: "100%" }}
              >
                <MaterialTable
                  columns={headCellsMaterial}
                  data={dataMaterialTable}
                  title="Transfers"
                  options={{
                    emptyRowsWhenPaging: false,
                    search: false,
                    pageSize: 5,
                    header: true,
                    paging: true,
                    showTitle: true,
                    padding: "dense",

                    exportButton: false,
                  }}
                />
              </Box>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Box className={classes.actions}>
          {((transferRecordProduct &&
            !disableTransfer() &&
            !recordProductForEdit.finalized &&
            userCanSuperTransfer) ||
            (transferRecordProduct &&
              !disableTransfer() &&
              !recordProductForEdit.finalized &&
              coordinatorFromDoctor &&
              userCanTransfer)) &&
          (authUser &&
          authUser.organization &&
          !isEmpty(authUser.organization.data)
            ? !authUser.organization.data
                .force_transfer_surgeries_with_same_doctor
            : true) ? (
            <Controls.Button
              text="Request transfer"
              color="primary"
              disabled={
                (!coordinatorFromDoctor && !userCanSuperTransfer) ||
                (coordinatorFromDoctor &&
                  !userCanTransfer &&
                  !userCanSuperTransfer) ||
                !userCanEdit
              }
              onClick={() => {
                createTransfer();
              }}
            />
          ) : (
            ""
          )}

          {((transferRecordProduct &&
            disableTransfer() &&
            !recordProductForEdit.finalized &&
            userCanSuperTransfer) ||
            (transferRecordProduct &&
              disableTransfer() &&
              !recordProductForEdit.finalized &&
              coordinatorToDoctor &&
              userCanTransfer) ||
            (transferRecordProduct &&
              disableTransfer() &&
              !recordProductForEdit.finalized &&
              coordinatorFromDoctor &&
              userCanTransfer)) &&
          (authUser &&
          authUser.organization &&
          !isEmpty(authUser.organization.data)
            ? !authUser.organization.data
                .force_transfer_surgeries_with_same_doctor
            : true) ? (
            <Controls.Button
              text={
                transfer
                  ? (coordinatorToDoctor && userCanTransfer) ||
                    userCanSuperTransfer
                    ? "Accept / Decline Transfer"
                    : "Pending Transfer"
                  : ""
              }
              color="primary"
              disabled={
                (!coordinatorToDoctor && !userCanSuperTransfer) ||
                (coordinatorToDoctor &&
                  !userCanTransfer &&
                  !userCanSuperTransfer) ||
                !userCanEdit
              }
              onClick={() => {
                editTransfer();
              }}
            />
          ) : (
            ""
          )}
          <Controls.Button
            text="Send Contract"
            color="primary"
            onClick={() => {
              setSendContractPopup(true);
            }}
            disabled={!userCanEdit || !coordinatorsFromDoctor}
          />

          <ReactToPrint
            trigger={() => (
              <Controls.Button
                color="primary"
                startIcon={<PrintIcon />}
                text="Print EMR"
                disabled={!userCanEdit || !coordinatorsFromDoctor}
              />
            )}
            content={() => objRef.current}
          />
          <Controls.Button
            type="submit"
            text={t("common:general.Save")}
            disabled={!userCanEdit && !userIsBillingSuper}
          />
          <Controls.Button
            text={t("common:general.Cancel")}
            color="default"
            onClick={closePopup}
          />
        </Box>
      </form>

      <Popup
        title={t("common:general.Status_con")}
        openPopup={openConfirmationStatus}
        onClose={() => setOpenConfirmationStatus(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <form onSubmit={handleSubmit(onSubmit2)} className={classes.root}>
          <Grid container spacing={2}>
            {openConfirmationStatus === "TENTATIVE" && (
              <Grid item xs={12}>
                <Controller
                  name="tentative_date"
                  type="date"
                  control={control}
                  defaultValue={null}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      style={{ width: "100%" }}
                      label={t("common:general.Tentative_date")}
                      shouldCloseOnSelect
                      onChange={(date) => {
                        onChange(date);
                      }}
                      value={value}
                    />
                  )}
                  rules={{
                    required:
                      t("common:general.Tentative_date") +
                      " " +
                      t("common:general.Required"),
                    pattern: {
                      value:
                        /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,
                    },
                  }}
                />

                {errors.tentative_date && (
                  <p className={classes.error}>
                    {errors.tentative_date.message}
                  </p>
                )}
              </Grid>
            )}

            {openConfirmationStatus === "SCHEDULED" && (
              <Grid item xs={12}>
                <Controller
                  name="date"
                  type="date"
                  control={control}
                  defaultValue={null}
                  as={({ onChange, value }) => (
                    <Controls.DateTimePicker
                      style={{ width: "100%" }}
                      label={t("common:general.Date")}
                      shouldCloseOnSelect
                      onChange={(date) => {
                        onChange(date);
                      }}
                      value={value}
                      shouldDisableDate={disableDates2}
                    />
                  )}
                  rules={{
                    required:
                      t("common:general.Date") +
                      " " +
                      t("common:general.Required"),
                    pattern: {
                      value:
                        /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,
                    },
                  }}
                />

                {errors.date && (
                  <p className={classes.error}>{errors.date.message}</p>
                )}
              </Grid>
            )}

            {openConfirmationStatus === "CANCELED" && (
              <Grid item xs={12}>
                <Controller
                  name="cancellation_reason"
                  control={control}
                  defaultValue={null}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      fullWidth
                      styleProp
                      label={"Cancellation Reason *"}
                      options={cancellationOptions()}
                      onChange={(value) => {
                        onChange(value.target.value);
                      }}
                      value={value}
                    />
                  )}
                  rules={{
                    required:
                      "Cancellation Reason" +
                      " " +
                      t("common:general.Required"),
                  }}
                />

                {errors.cancellation_reason && (
                  <p className={classes.error}>
                    {errors.cancellation_reason.message}
                  </p>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              {openConfirmationStatus != "SCHEDULED" ||
              (openConfirmationStatus == "SCHEDULED" &&
                recordProductForEdit.has_had_date) ? (
                <>
                  <Controller
                    name="comment"
                    control={control}
                    defaultValue=""
                    as={
                      <Controls.Textarea
                        style={{ width: "100%" }}
                        label="Comment *"
                      />
                    }
                    rules={{
                      required:
                        t("common:general.Comment") +
                        " " +
                        t("common:general.Required"),
                    }}
                  />
                  {errors.comment && (
                    <p className={classes.error}>{errors.comment.message}</p>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid>
            <Controls.Button type="submit" text={t("common:general.Save")} />
          </Grid>
        </form>
      </Popup>
      {
        <div style={{ display: "none" }}>
          <Wrapper ref={objRef}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" m={2}>
                  Electronic Medical Records
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {t("common:general.Record") +
                    ": " +
                    recordProductForEdit?.record.data.fname +
                    " " +
                    recordProductForEdit.record.data.lname}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  Date:{" "}
                  {recordProductForEdit.date
                    ? moment(recordProductForEdit.date).format("MM-DD-YYYY")
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  PreDate:{" "}
                  {recordProductForEdit.pre_date
                    ? moment(recordProductForEdit?.pre_date).format(
                        "MM-DD-YYYY"
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {t("common:general.Surgeon")} {": "}
                  {recordProductForEdit.responsible
                    ? recordProductForEdit.responsible.data.title
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {t("records:records.coordinator")} {": "}
                  {recordProductForEdit.coordinator
                    ? recordProductForEdit.coordinator.data.title
                    : ""}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box m={5}>
                  {customFields
                    ? customFields.map((items) => {
                        return (
                          <Box m={5}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  {items[0].category.title}
                                </Typography>
                              </Grid>

                              <Grid item xs={6}>
                                {renderCustomFields(items, 1)}
                              </Grid>
                              <Grid item xs={6}>
                                {renderCustomFields(items, 2)}
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })
                    : ""}
                </Box>
              </Grid>
            </Grid>
            <Popup
              title="Send Contracts"
              fullWidth={true}
              openPopup={sendContractPopup}
              onClose={closeSendContractPopup}
            >
              <SendContract
                model="recordProducts"
                model_id={recordProductForEdit?.id}
                contact={recordProductForEdit.invoice.data.contact}
                onSentContract={sendContract}
              />
            </Popup>

            <Popup
              title={`Make transfer request for ${recordProductForEdit.invoiceItem.data.name}`}
              fullWidth={true}
              openPopup={transferPopup}
              onClose={closeTransferPopup}
            >
              <TransferProcedureForm
                responsibles={getResponsibleOptions()}
                coordinatorsD={getCoordinatorOptions()}
                transferForEdit={transfer}
                recordProductForEdit={recordProductForEdit}
                saveRecordProductTransferdata={saveRecordProductTransferdata}
              />
            </Popup>

            {invoiceDetails && (
              <Popup
                title={`Invoice ${invoiceDetails?.name}`}
                fullScreen
                openPopup={openInvoice}
                onClose={closeInvoicePopup}
              >
                <InvoiceDetails invoice={invoiceDetails} />
              </Popup>
            )}

            <Dialog
              open={loadingInvoice}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              {<LoadingScreen />}
            </Dialog>
          </Wrapper>
        </div>
      }
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={false}
      />
    </div>
  );
}
