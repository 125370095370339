import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  pbxDomains: [],
};

const pbxDomainsSlice = createSlice({
  name: "pbxDomains",
  initialState,
  reducers: {
    getPbxDomains: (state) => {
      state.loading = true;
    },
    getPbxDomainsSuccess: (state, { payload }) => {
      state.pbxDomains = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getPbxDomainsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const { getPbxDomains, getPbxDomainsSuccess, getPbxDomainsFailure } =
pbxDomainsSlice.actions;

export const pbxDomainsSelector = (state) => state.pbxDomains;
export default pbxDomainsSlice.reducer;

export function fetchPbxDomainsService(orgId) {
  //console.error("fetchPbxDomainsService");
  return async (dispatch) => {
    dispatch(getPbxDomains());

    try {
      const response = await Api.getBuildingsPbxDomains();
      //console.error("getPbxDomainsSuccess");
      dispatch(getPbxDomainsSuccess(response));
    } catch (error) {
      //console.error("getPbxDomainsFailure");
      dispatch(getPbxDomainsFailure(error));
    }
  };
}
