import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { AppBar, Tab, Tabs, makeStyles, Paper } from "@material-ui/core";
import MailPanel from "./mailPanel";
import MailLoadingAddGoogleEmail from "./mailLoadingAddGoogleEmail";

import { isEmpty } from "lodash";
import TabPanel from "../../modules/email_client/components/TabPanel";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackbarProvider } from "notistack";

const email_client_id = process.env.REACT_APP_GOOGLE_CLIENT_KEY;

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(5),
    paddingTop: theme.spacing(3),
    position: "absolute",
    zIndex: "1000",
    height: "95%",
    width: "98%",
    backgroundColor: "#eaf1fb",
    borderRadius: 10,
  },
  paper2: {
    margin: theme.spacing(5),
    paddingTop: theme.spacing(3),
    position: "absolute",
    zIndex: "1000",
    height: "90%",
    width: "80%",
    backgroundColor: "#eaf1fb",
    borderRadius: 10,
  },
}));

function mailView(props) {
  const {
    accounts,
    accountsLoaded,
    accountsLoading,
    folders,
    fromIndex = false,
  } = props;
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(
    accounts ? Object.keys(accounts)[0] : 0
  );
  const handleChange = (event, value) => {
    setSelectedTab(value);
  };
  const [selectedMenu, setSelectedMenu] = useState("INBOX");
  const [isComposeOrReplayOpen, setIsComposeOrReplayOpen] = useState(false);
  useEffect(() => {
    if (Object.keys(accounts).length > 0) {
      setSelectedTab(Object.keys(accounts)[0]);
    }
    console.log("accountsaccounts", accounts);
  }, [accounts]);

  useEffect(() => {
    return () => console.log("umount");
  }, []);

  // useEffect(() => {
  //   console.log(
  //     "accountsLoading_accountsLoaded1",
  //     accountsLoading,
  //     accountsLoaded,
  //     accounts
  //   );
  // }, [accountsLoading, accountsLoaded]);

  return (
    <>
      <SnackbarProvider
        maxSnack={1}
        preventDuplicate
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Helmet>
          <title>{"Email"}</title>
        </Helmet>

        <Paper
          elevation={1}
          className={fromIndex ? classes.paper2 : classes.paper}
        >
          <GoogleOAuthProvider clientId={email_client_id}>
            <>
              <AppBar
                position="static"
                style={{
                  background: "#006C9C",
                }}
              >
                <Tabs
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  value={selectedTab}
                  TabIndicatorProps={{ style: { background: "#B71D18" } }}
                >
                  {Object.values(accounts).map((acc) => {
                    let label =
                      acc.name.charAt(0).toUpperCase() + acc.name.slice(1);
                    return (
                      <Tab key={acc.name} label={label} value={acc.name} />
                    );
                  })}
                </Tabs>
              </AppBar>
              {isEmpty(accounts) ? (
                <MailLoadingAddGoogleEmail
                  accounts={accounts}
                  accountsLoaded={accountsLoaded}
                  accountsLoading={accountsLoading}
                />
              ) : (
                Object.values(accounts).map((acc) => {
                  let label =
                    acc.name.charAt(0).toUpperCase() + acc.name.slice(1);
                  return (
                    <TabPanel
                      style={{ height: "97%", width: "100%" }}
                      key={acc.name}
                      value={selectedTab}
                      index={acc.name}
                    >
                      <MailPanel
                        selectedMenu={selectedMenu}
                        setSelectedMenu={setSelectedMenu}
                        isComposeOrReplayOpen={isComposeOrReplayOpen}
                        setIsComposeOrReplayOpen={setIsComposeOrReplayOpen}
                        accountInfo={acc}
                        folders={folders}
                        fromIndex
                      />
                    </TabPanel>
                  );
                })
              )}
            </>
          </GoogleOAuthProvider>
        </Paper>
      </SnackbarProvider>
    </>
  );
}

export default mailView;
