import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import PaymentForm from "./forms/PaymentForm";
import MaterialTable from "material-table";
import { isEmpty, find } from "lodash";
import Api from "../lib/api";
import PropTypes from "prop-types";
import {
  Paper,
  ListItem as MuiListItem,
  Fab,
  Tabs,
  Tab,
  Dialog,
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  DialogActions,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import Popup from "./Popup";
import useTable from "./useTable";
import moment from "moment";
import * as momentTimeZone from "moment-timezone";
import Controls from "./controls";
import ConfirmDialog from "./ConfirmDialog";
import { useDispatch } from "react-redux";
import TransferPayment from "./TransferPayment";
import Notification from "./Notification";
import { getMessaging, onMessage } from "firebase/messaging";
import Iconify from "./Iconify";
import { useForm, Controller } from "react-hook-form";
import Slide from "@material-ui/core/Slide";
import { FormProvider } from "./hook-form";
import InputMoneyOk from "./controls/InputMoneyOk";
import MultipaymentDetails from "./MultipaymentDetails";
import TransferPayment2 from "./TransferPayment2";
import CRMUtils from "../utils";
import { width } from "@material-ui/system";
import { padding } from "polished";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #1b5e20;
  }
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #1b5e20;
    }
    &.focused fieldset {
      border-color: #1b5e20;
    }
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #1b5e20;
  }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "80px",
  },
  wrapText1: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "50px",
  },
  wrapText2: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "120px",
  },
  wrapText4: {
    display: "flex",
    whiteSpace: "normal",
    wordWrap: "break-word",
    width: "100px",
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
    backgroundColor: "#e3f2fd",
  },
  dialogTitle2: {
    padding: "5px 10px",
    paddingRight: "0px",
  },
  dialogWrapper: {
    backgroundColor: "#ffffff",
  },
  indicator: {
    color: "#673ab7",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  dialogWrapper2: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
    zindex: 999999,
  },
  customTooltip1: {
    backgroundColor: "#e3f2fd",
  },
  customTooltip2: {
    backgroundColor: "#e3f2fd",
  },
  customTooltip3: {
    backgroundColor: "#e3f2fd",
  },
  customTooltip4: {
    backgroundColor: "#e3f2fd",
  },
  customArrow1: { color: "#e3f2fd", borderColor: "#ffffff" },
  customArrow2: { color: "#e3f2fd", borderColor: "#ffffff" },
  customArrow3: { color: "#e3f2fd", borderColor: "#ffffff" },
  customArrow4: { color: "#e3f2fd", borderColor: "#ffffff" },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Payments(props) {
  const classes = useStyles();
  const messaging = getMessaging();
  const [tabValue, setTabValue] = useState(0);
  const {
    invoice,
    payments,
    gateways,
    setInvoice,
    getInvoicesContact = null,
    readOnly = false,
    onDelete,
    onMarkPossibleRefund,
    maxAmount,
    authUser,
    setOpenDetailPopup,
    reload,
  } = props;
  // const [canDelete, setCanDelete] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);
  const [openTransferPopup, setOpenTransferPopup] = useState(false);
  // const dispatch = useDispatch();

  const methods = useForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods;

  const watchedFields = watch();

  const sumAllFields = Object.values(watchedFields).reduce(
    (acc, currentValue) => acc + Number(currentValue || 0),
    0
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [paymentToCredit, setPaymentToCredit] = useState(null);
  const [openPaymentToCreditPopup, setPaymentToCreditPopup] = useState(false);
  const [paymentToCreditForEdit, setPaymentToCreditForEdit] = useState(null);
  const [isMultipaymentOpen, setIsMultipaymentOpen] = useState(false);
  const [paymentForEdit, setPaymentForEdit] = useState(null);
  const [paymentForTransfer, setPaymentForTransfer] = useState(null);

  // const [message, setMessage] = useState("");

  const [paymentPossibleRefundPending, setPaymentPossibleRefundPending] =
    useState([]);

  const [selectedPaymentTypeTab, setSelectedPaymentTypeTab] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const checkPaymentPossibleRefundPending = () => {
    setPaymentPossibleRefundPending(
      payments.filter((payment) => {
        return (
          payment.possible_refund == 1 &&
          isEmpty(payment.paymentPossibleRefund.data)
        );
      })
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const selectedColumns = () => {
    if (authUser.organization.data.multiple_locations === 1) {
      return headCellsMaterial;
    }

    return headCellsMaterial.filter((column) => {
      return column.field != "building_name";
    });
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const userCanDeleteMultipaymentHistory = CRMUtils.userHasPermission(
    authUser,
    ["invoices_multipaymenthistorydelete"]
  );

  const savePayment = async (payload) => {
    setSaveLoading(true);
    const momentDate = new moment(payload.payment_date);
    payload.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    payload.sale_created_at = payload.sale_created_at
      ? new moment(payload.sale_created_at).format("YYYY-MM-DD HH:mm:ss")
      : null;

    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    if (!paymentForEdit) {
      const { data } = await Api.saveInvoicePayment(invoice.id, formData);

      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });
    } else {
      if (payload.file === null) {
        formData.delete("file");
      }

      const { data } = await Api.editInvoicePayment(
        invoice.id,
        paymentForEdit.id,
        formData
      );

      try {
        await Api.getInvoice(invoice.id).then((result) => {
          setInvoice({
            ...result.data.data,
          });
        });
      } catch (err) {
        setNotify({
          isOpen: true,
          message:
            "An error occurred while fetching the invoice after payment. Please reopen it to avoid errors.",
          type: "error",
        });

        setTimeout(() => {
          if (setOpenDetailPopup) {
            setOpenDetailPopup(false);
          }
        }, 1500);
      }
    }
    if (getInvoicesContact) {
      getInvoicesContact();
    }
    setSaveLoading(false);
    onClose();
    onCloseEdit();
  };

  /*
  const savePayment = (data) => {
    onSave(data);
    onClose();
  };*/

  const { TblContainer } = useTable();

  const onClose = (notification = null) => {
    setOpenPopup(false);
    setPaymentForEdit(null);
    setTabValue(0);
    if (notification) {
      setNotify(notification);
    }
  };

  const onCloseEdit = (notification = null) => {
    setOpenPopupEdit(false);
    setPaymentForEdit(null);
    setTabValue(0);
    if (notification) {
      setNotify(notification);
    }
  };

  const onCloseTransfer = () => {
    setOpenTransferPopup(false);
    setPaymentForTransfer(null);
  };

  const deletePayment = (id) => {
    onDelete(id);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const forfeitureBackPayment = async (payment_id) => {
    setSaveLoading(true);
    const { data } = await Api.forfeitureBackPayment(invoice.id, payment_id);

    await Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setSaveLoading(false);
  };

  const applyCredit = (rowData) => {
    setPaymentToCreditForEdit(rowData);

    setPaymentToCreditPopup(true);
  };

  const onSubmit = async (info) => {
    setSaveLoading(true);
    let items = [];

    Object.keys(info).forEach((key) => {
      if (info[key] !== "") {
        items.push({
          item_id: key,
          amount: info[key],
        });
      }
    });
    let payload = {};
    payload.payment_id = paymentToCreditForEdit.id;

    payload.items = items;

    const { data } = await Api.forfeitureApplyCredit(invoice.id, payload);

    if (data) {
      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });
      reset();
      closePaymentToCreditPopup();
    }

    setSaveLoading(false);
  };

  const closePaymentToCreditPopup = () => {
    setPaymentToCreditPopup(false);
    setPaymentToCreditForEdit(null);
    setExpanded(false);
  };

  const markPossibleRefund = (id, $mark) => {
    onMarkPossibleRefund(id, $mark);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const unlockPayment = async (id) => {
    setSaveLoading(true);
    const payload = {
      locked: 0,
    };

    const { data } = await Api.editInvoicePayment(invoice.id, id, payload);

    await Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });

    setSaveLoading(false);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const dataMaterialTable = payments.map((o) => ({ ...o }));
  const headCellsMaterial = [
    {
      field: "payment_type",
      title: "Type",
      customSort: (a, b) =>
        a.payment_type
          .toLowerCase()
          .localeCompare(b.payment_type.toLowerCase()),
      render: (rowData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.wrapText2}>{rowData?.payment_type}</div>
          {rowData.header && rowData.header.reconciled ? (
            <Tooltip title={"Reconciled (Can't be updated or deleted)"} arrow>
              <span style={{ marginLeft: 6 }}>
                <Iconify
                  icon={"ic:round-info"}
                  width={20}
                  height={20}
                  style={{
                    color: "rgb(71, 145, 219)",
                  }}
                />
              </span>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      field: "amount",
      title: "Amount",
      customSort: (a, b) => a.amount - b.amount,
      render: (rowData) => (
        <ListItem style={{ margin: 0, padding: 0 }}>
          <ListItemText primary={rowData.amount} />
          <ListItemIcon style={{ display: "block" }}>
            {rowData.refundedAmount && rowData.refundedAmount > 0 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.customArrow1,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Refunded: $${rowData.refundedAmount.toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <IconButton
                    style={{
                      marginLeft: 3,
                      boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                    }}
                  >
                    <Iconify
                      icon={"mdi:letter-r-circle"}
                      width={20}
                      height={20}
                      style={{
                        color: "#e65100",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              ""
            )}
            {rowData.forfeiture_amount && rowData.forfeiture_amount > 0 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip2,
                    arrow: classes.customArrow2,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Forfeited: $${rowData.forfeiture_amount.toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <IconButton
                    style={{
                      marginLeft: 3,
                      boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                    }}
                  >
                    <Iconify
                      icon={"mdi:letter-f-circle"}
                      width={20}
                      height={20}
                      style={{
                        color: "#e65100",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              ""
            )}
            {rowData.transferred_parent_invoice_name &&
            rowData.transferred_parent_invoice_id > 0 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip3,
                    arrow: classes.customArrow3,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`${rowData.transferred_parent_invoice_name}`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <IconButton
                    style={{
                      marginLeft: 3,
                      boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                    }}
                  >
                    <Iconify
                      icon={"mdi:letter-t-circle"}
                      width={20}
                      height={20}
                      style={{
                        color: "#e91e63",
                      }}
                    />
                  </IconButton>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
            {rowData.payments_in_header > 1 ? (
              <Box ml={1}>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip4,
                    arrow: classes.customArrow4,
                  }}
                  title={
                    <Box m={1}>
                      <ListItemText
                        secondary={`Belongs to a multipayment of $${
                          rowData.payments_in_header_amount
                            ? rowData.payments_in_header_amount.toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"
                        }`}
                      />
                    </Box>
                  }
                  arrow
                >
                  <IconButton
                    style={{
                      marginLeft: 3,
                      boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                    }}
                  >
                    <Iconify
                      icon={"emojione-monotone:letter-m"}
                      width={20}
                      height={20}
                      style={{
                        color: "rgb(175 30 233)",
                      }}
                    />
                  </IconButton>
                </Tooltip>{" "}
              </Box>
            ) : (
              ""
            )}
          </ListItemIcon>
        </ListItem>
      ),
    },
    {
      field: "cc_last_4",
      title: "Last 4",
      customSort: (a, b) =>
        a.cc_last_4.toLowerCase().localeCompare(b.cc_last_4.toLowerCase()),
      render: (rowData) => (
        <div className={classes.wrapText1}>{rowData?.cc_last_4}</div>
      ),
    },
    {
      field: "details",
      title: "Details",
      render: (rowData) => (
        <div className={classes.wrapText}>{rowData.details}</div>
      ),
      customSort: (row1, row2) => {
        const detail1 = row1.details || "";
        const detail2 = row2.details || "";
        return detail1.localeCompare(detail2);
      },
    },
    {
      field: "building_name",
      title: "Building",
      customSort: (a, b) =>
        a.building_name
          .toLowerCase()
          .localeCompare(b.building_name.toLowerCase()),
      render: (rowData) => (
        <div className={classes.wrapText2}>{rowData?.building_name}</div>
      ),
    },
    {
      field: "date",
      title: "Payment Date",
      render: (rowData) => (
        <div className={classes.wrapText4}>
          {momentTimeZone(rowData?.date).format("YYYY-MM-DD hh:mm A")}
          {rowData?.sale_created_at ? (
            <Tooltip
              title={`Sale Date: ${momentTimeZone(
                rowData.sale_created_at
              ).format("YYYY-MM-DD hh:mm A")}`}
              arrow
            >
              <InfoIcon style={{ color: "#26a69a", width: 25 }} />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ),
      customSort: (a, b) =>
        new Date(moment(a.date).format("YYYY-MM-DD HH:mm:ss")).getTime() -
        new Date(moment(b.date).format("YYYY-MM-DD HH:mm:ss")).getTime(),
    },
    {
      field: "created_by",
      title: "By",
      customSort: (a, b) => a.user.data.title.localeCompare(b.user.data.title),
      render: (rowData) => {
        return (
          <div className={classes.wrapText2}>
            {!isEmpty(rowData.user.data) ? rowData.user.data.title : "System"}
          </div>
        );
      },
    },
    {
      field: "attachment",
      title: "File",
      sorting: false,
      render: (rowData) => {
        if (rowData.document.data?.url || rowData.file) {
          return (
            <div style={{ maxWidth: "5%" }}>
              <a
                href={
                  rowData.document.data?.url
                    ? rowData.document.data.url
                    : rowData.file
                }
                target="_blank"
                rel="noreferrer"
              >
                <AttachFileIcon />
              </a>
            </div>
          );
        }
      },
    },
  ];

  const openInPopup = (item) => {
    setPaymentForEdit(item);

    setOpenPopupEdit(true);
  };

  const openTransfer = (item) => {
    setPaymentForTransfer(item);
    setOpenTransferPopup(true);
  };

  function userHasPermission(user, routePermissions) {
    if (!routePermissions) return true;

    let hasPermission = false;

    const permissions = user.permissions?.data.map((permission) => {
      return permission.name;
    });
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  }

  // const checkAndFix = async (id) => {
  //   const { data } = await Api.checkAndFixInvoice(id);
  //   //console.error(data);
  //   setMessage(data);
  //   reload ? reload() : "";
  //   //setInvoiceForRefund(data.data);
  //   //setOpenRefund(true);
  // };

  // const brokenPaymentsCount = () => {
  //   let temp = dataMaterialTable.filter((payment) => {
  //     return payment["paymentdetail"]["data"].length === 0;
  //   });
  //   return temp.length;
  // };

  const renderPaymentDetails = (paymentDetails) => {
    return (
      <MaterialTable
        title="Payments"
        columns={[
          { title: "Name", field: "item_name" },
          { title: "Amount", field: "amount" },
        ]}
        data={paymentDetails.map((payment) => {
          return {
            item_name: (
              <Typography>
                {payment.item_name}
                {payment.item_is_revision ? (
                  <strong
                    style={{
                      color: "#326a9b",
                      marginLeft: 5,
                      fontWeight: 400,
                    }}
                  >
                    {"(Revision)"}
                  </strong>
                ) : (
                  ""
                )}
              </Typography>
            ),
            amount: payment.amount,
          };
        })}
        options={{
          pageSize: 20, // make initial page size
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          search: false,
          header: true,
          paging: false,
          showTitle: false,
          padding: "dense",
          toolbar: false,
          exportButton: false,
        }}
      />
    );
  };

  const openMultiPaymentDetails = (payment) => {
    setIsMultipaymentOpen(true);
    setPaymentForEdit(payment);
  };

  const onCloseMultipaymentDetails = () => {
    setIsMultipaymentOpen(false);
    setPaymentForEdit(null);
  };

  let action;
  if (invoice.status !== "canceled" && !readOnly) {
    action = [
      {
        icon: () => (
          <Fab aria-label="add" color="primary">
            <AddIcon />
          </Fab>
        ),
        tooltip: "Add Payment",
        hidden: !userHasPermission(authUser, ["invoicepaymenthistory_create"]),

        isFreeAction: true,
        onClick: (event) => {
          setOpenPopup(true);
        },
      },

      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"mdi:credit-card-sync"}
            width={20}
            height={20}
            style={{
              color:
                (rowData.header && rowData.header.reconciled) ||
                !rowData.paymentdetail ||
                !rowData.paymentdetail.data ||
                isEmpty(rowData.paymentdetail.data) ||
                rowData.possible_refund ||
                rowData.forfeiture_approved_date ||
                (!rowData.editable &&
                  !userHasPermission(authUser, [
                    "invoicepaymenthistory_superedit",
                  ])) ||
                saveLoading
                  ? "#bdbdbd"
                  : "#4caf50",
            }}
          />
        ),
        tooltip: "Edit",
        disabled:
          rowData.header && rowData.header.reconciled
            ? true
            : !rowData.paymentdetail ||
              !rowData.paymentdetail.data ||
              isEmpty(rowData.paymentdetail.data) ||
              (!rowData.editable &&
                !userHasPermission(authUser, [
                  "invoicepaymenthistory_superedit",
                ])) ||
              rowData.possible_refund ||
              rowData.forfeiture_approved_date ||
              saveLoading,
        hidden:
          !userHasPermission(authUser, ["invoicepaymenthistory_edit"]) ||
          rowData.forfeiture_approved_date,
        onClick: (event, rowData) => {
          openInPopup(rowData);
        },
      }),
      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"mingcute:transfer-4-line"}
            width={20}
            height={20}
            style={{
              color:
                rowData.possible_refund ||
                rowData.forfeiture_approved_date ||
                (!rowData.transferable &&
                  invoice.balance >= 0 &&
                  !userHasPermission(authUser, [
                    "invoicepaymenthistory_superedit",
                  ])) ||
                saveLoading ||
                (rowData.refundedAmount &&
                  rowData.refundedAmount == rowData.amount)
                  ? //  || (rowData.refundedAmount && rowData.refundedAmount > 0)
                    // payments with refunds can't be transferred. ToDo ... split payments
                    "#bdbdbd"
                  : "#4caf50",
            }}
          />
        ),
        tooltip: "Transfer",
        disabled:
          (!rowData.transferable &&
            invoice.balance >= 0 &&
            !userHasPermission(authUser, [
              "invoicepaymenthistory_superedit",
            ])) ||
          rowData.possible_refund ||
          rowData.forfeiture_approved_date ||
          saveLoading ||
          // (rowData.refundedAmount && rowData.refundedAmount > 0),
          (rowData.refundedAmount && rowData.refundedAmount == rowData.amount), // payments with refunds can't be transferred. ToDo ... split payments
        hidden: !userHasPermission(authUser, [
          "invoicepaymenthistory_transfer",
        ]),
        onClick: (event, rowData) => {
          openTransfer(rowData);
        },
      }),
      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"majesticons:unlock-open"}
            width={20}
            height={20}
            style={{
              color:
                (!rowData.possible_refund &&
                  !rowData.forfeiture_approved_date) ||
                saveLoading
                  ? "#0d47a1"
                  : "#bdbdbd",
            }}
          />
        ),
        tooltip: "Unlock",
        disabled:
          rowData.possible_refund ||
          rowData.forfeiture_approved_date ||
          saveLoading,
        hidden:
          !userHasPermission(authUser, ["invoicepaymenthistory_unlock"]) ||
          rowData.locked == 0,
        onClick: (event, rowData) => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to unlock this payment?",
            subTitle: "You can't undo this operation",
            onConfirm: () => {
              unlockPayment(rowData.id);
            },
          });
        },
      }),
      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"mdi:credit-card-remove"}
            width={20}
            height={20}
            style={{
              color:
                (rowData.header && rowData.header.reconciled) ||
                (rowData.paymentdetail &&
                  rowData.paymentdetail.data &&
                  rowData.paymentdetail.data.findIndex(
                    (a) => a.has_product_done == true
                  ) != -1) ||
                (rowData.payments_in_header > 1 &&
                  !userCanDeleteMultipaymentHistory) ||
                (!rowData.editable &&
                  !userHasPermission(authUser, [
                    "invoicepaymenthistory_superedit",
                  ])) ||
                rowData.possible_refund ||
                rowData.forfeiture_approved_date ||
                (rowData.refundedAmount && rowData.refundedAmount > 0) ||
                (rowData.paymentdetail &&
                  rowData.paymentdetail.data &&
                  rowData.paymentdetail.data.findIndex(
                    (a) => a.is_recordProduct_finalized == true
                  ) != -1) ||
                saveLoading
                  ? "#bdbdbd"
                  : "#4caf50",
            }}
          />
        ),
        tooltip:
          rowData.payments_in_header > 1 && !userCanDeleteMultipaymentHistory
            ? "This payment also affects other invoices, you cannot delete it"
            : "Delete",
        disabled:
          rowData.header && rowData.header.reconciled
            ? true
            : rowData.paymentdetail &&
              rowData.paymentdetail.data &&
              rowData.paymentdetail.data.findIndex(
                (a) => a.has_product_done == true
              ) != -1
            ? true
            : (rowData.payments_in_header > 1 &&
                !userCanDeleteMultipaymentHistory) ||
              (!rowData.editable &&
                !userHasPermission(authUser, [
                  "invoicepaymenthistory_superedit",
                ])) ||
              rowData.possible_refund ||
              (rowData.refundedAmount && rowData.refundedAmount > 0) ||
              (rowData.paymentdetail &&
                rowData.paymentdetail.data &&
                rowData.paymentdetail.data.findIndex(
                  (a) => a.is_recordProduct_finalized == true
                ) != -1) ||
              saveLoading,
        hidden:
          !userHasPermission(authUser, ["invoicepaymenthistory_delete"]) ||
          rowData.locked == 1 ||
          rowData.forfeiture_approved_date,
        onClick: (event, rowData) =>
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this payment?",
            subTitle: "You can't undo this operation",
            onConfirm: () => {
              deletePayment(rowData.id);
            },
          }),
      }),
      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"fluent:credit-card-person-24-filled"}
            width={20}
            height={20}
            style={{
              color:
                isEmpty(paymentToCredit) ||
                rowData.forfeiture_credit_date ||
                saveLoading
                  ? "#bdbdbd"
                  : "#4caf50",
            }}
          />
        ),
        tooltip: "Apply Credit to Patient",
        disabled:
          isEmpty(paymentToCredit) ||
          rowData.forfeiture_credit_date ||
          saveLoading,
        hidden:
          !userHasPermission(authUser, [
            "invoicepaymenthistory_invoiceForfeitureCredit",
          ]) || !rowData.forfeiture_approved_date,
        onClick: (event, rowData) => applyCredit(rowData),
      }),
      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"mdi:credit-card-refund"}
            width={20}
            height={20}
            style={{
              color:
                rowData.forfeiture_credit_date || saveLoading
                  ? "#bdbdbd"
                  : "#4caf50",
            }}
          />
        ),
        tooltip: "Forfeiture Back",
        disabled: rowData.forfeiture_credit_date || saveLoading,
        hidden:
          !userHasPermission(authUser, [
            "invoicepaymenthistory_invoiceForfeitureBack",
          ]) || !rowData.forfeiture_approved_date,
        onClick: (event, rowData) =>
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to back this payment?",
            subTitle: "You can't undo this operation",
            onConfirm: () => forfeitureBackPayment(rowData.id),
          }),
      }),
      (rowData) => ({
        icon: () => (
          <Iconify
            icon={"fa6-solid:file-invoice-dollar"}
            width={20}
            height={20}
            style={{
              color:
                saveLoading || rowData.payments_in_header <= 1
                  ? "#bdbdbd"
                  : "#4caf50",
            }}
          />
        ),
        tooltip:
          rowData.payments_in_header > 1
            ? "This payment also affects other invoices. Click to see full payment"
            : "",
        disabled: saveLoading || rowData.payments_in_header <= 1,
        onClick: (event, rowData) => openMultiPaymentDetails(rowData),
      }),
    ];
  } else {
    action = [
      {
        icon: () => <></>,
        tooltip: "",
        isFreeAction: true,
      },
    ];
  }

  const onTransferPayment = async () => {
    setSaveLoading(true);

    const { data } = await Api.getInvoice(invoice.id);

    if (data && data.data) {
      setInvoice({
        ...data.data,
      });

      if (getInvoicesContact) {
        getInvoicesContact();
      }
      onCloseTransfer();
    }
    console.log(data.data);
    setSaveLoading(false);
  };

  const handleNotifications = async (notification) => {
    setSaveLoading(true);
    console.log("Notification Example Merchant_transaction", notification);
    if (
      notification?.data?.type &&
      notification?.data?.type === "merchant_transaction"
    ) {
      // Merchant transaction Notification
      await Api.getInvoice(invoice.id).then((result) => {
        setInvoice({
          ...result.data.data,
        });
      });
      if (typeof getInvoicesContact === "function") {
        getInvoicesContact();
      }
      setSaveLoading(false);
    }
  };

  const getDataToApplyCredit = async () => {
    const { data } = await Api.getDataToApplyCredit(invoice.id);
    if (data && data.data) {
      setPaymentToCredit(data?.data);
    }
  };

  useEffect(() => {
    onMessage(messaging, (payload) => {
      handleNotifications(payload);
      console.log("Messaging Payload Notification", payload);
    });

    getDataToApplyCredit();
    return () => console.log("...");
  }, []);

  useEffect(() => {
    checkPaymentPossibleRefundPending();
    return () => console.log("...");
  }, [payments]);

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          {/* {brokenPaymentsCount() > 0 &&
            (userHasPermission(authUser, ["invoicepaymenthistory_edit"]) ||
              userHasPermission(authUser, ["refund_create"])) && (
              <div>
                {"Payment Details not found!"}
                <Grid item xs={4}>
                  <Controls.Button
                    type="submit"
                    text="Check And Fix"
                    onClick={() => {
                      setMessage("Running...");
                      checkAndFix(dataMaterialTable[0]["invoice"]);
                    }}
                    disabled={false}
                  />
                </Grid>
              </div>
            )} */}
          {/* {message} */}
          <MaterialTable
            columns={selectedColumns()}
            data={dataMaterialTable}
            actions={[...action]}
            options={{
              pageSize: 10,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              rowStyle: (rowData, index) => {
                if (rowData.refundedAmount && rowData.refundedAmount > 0) {
                  return {
                    backgroundColor: "#fce4ec",
                  };
                } else if (rowData.forfeiture_approved_date) {
                  return {
                    backgroundColor: "#fff3e0",
                  };
                }
                return {
                  backgroundColor: "#ffffff",
                };
              },
            }}
            title="Payments"
            detailPanel={(rowData) => {
              return renderPaymentDetails(rowData.paymentdetail.data);
            }}
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Make Payment"}
        fullWidth
        openPopup={openPopupEdit}
        onClose={onCloseEdit}
        maxWidth="lg"
      >
        <PaymentForm
          tab={tabValue}
          invoice={invoice}
          savePayment={savePayment}
          gateways={gateways}
          className={classes.form}
          paymentForEdit={paymentForEdit}
          reload={reload}
          onClose={onClose}
          paymentPossibleRefundPending={paymentPossibleRefundPending}
          allowFix={
            userHasPermission(authUser, ["invoicepaymenthistory_edit"])
              ? userHasPermission(authUser, ["invoicepaymenthistory_edit"])
              : userHasPermission(authUser, ["refund_create"])
          }
          setNotify={setNotify}
          selectedPaymentTypeTab={selectedPaymentTypeTab}
          setSelectedPaymentTypeTab={setSelectedPaymentTypeTab}
        />
      </Popup>

      <Dialog
        aria-labelledby="payment-dialog"
        open={openPopup}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            maxWidth: "80%",
          },
        }}
        onClose={onClose}
        disableEscapeKeyDown={selectedPaymentTypeTab !== 1 ? false : true}
        disableBackdropClick={selectedPaymentTypeTab !== 1 ? false : true}
        style={{ borderRadius: "50px" }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="tabs"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Tab
                label={
                  <Typography
                    style={{
                      color: tabValue == 0 ? "#00695c" : "#212b36",
                      fontFamily: "Nunito,Roboto",
                      fontWeight: 700,
                      fontSize: "1.125rem",
                      margin: 12,
                    }}
                  >
                    Make a Payment!
                  </Typography>
                }
                {...a11yProps(0)}
              />
              {paymentPossibleRefundPending.map((item, index) => {
                return (
                  <Tab
                    label={
                      <Typography
                        style={{
                          color: tabValue == index + 1 ? "#00695c" : "#212b36",
                          fontFamily: "Nunito,Roboto",
                          fontWeight: 700,
                          fontSize: "1.125rem",
                          margin: 12,
                        }}
                      >
                        {`Pending: ${item.amount}`}
                      </Typography>
                    }
                    {...a11yProps(index + 1)}
                  />
                );
              })}
            </Tabs>
            {selectedPaymentTypeTab !== 1 && (
              <Controls.IconButton
                color="secondary"
                onClick={onClose}
                style={{ textAlign: "right" }}
              >
                <CloseIcon />
              </Controls.IconButton>
            )}
          </div>
        </DialogTitle>

        <DialogContent dividers={true}>
          <TabPanel value={tabValue} index={0}>
            <PaymentForm
              tab={tabValue}
              invoice={invoice}
              savePayment={savePayment}
              gateways={gateways}
              className={classes.form}
              paymentForEdit={paymentForEdit}
              reload={reload}
              onClose={onClose}
              allowFix={
                userHasPermission(authUser, ["invoicepaymenthistory_edit"])
                  ? userHasPermission(authUser, ["invoicepaymenthistory_edit"])
                  : userHasPermission(authUser, ["refund_create"])
              }
              setNotify={setNotify}
              selectedPaymentTypeTab={selectedPaymentTypeTab}
              setSelectedPaymentTypeTab={setSelectedPaymentTypeTab}
            />
          </TabPanel>
          {paymentPossibleRefundPending.map((item, index) => {
            return (
              <TabPanel value={tabValue} index={index + 1}>
                <PaymentForm
                  tab={tabValue}
                  invoice={invoice}
                  savePayment={savePayment}
                  gateways={gateways}
                  className={classes.form}
                  paymentForEdit={paymentForEdit}
                  reload={reload}
                  onClose={onClose}
                  pendingPayment={item}
                  allowFix={
                    userHasPermission(authUser, ["invoicepaymenthistory_edit"])
                      ? userHasPermission(authUser, [
                          "invoicepaymenthistory_edit",
                        ])
                      : userHasPermission(authUser, ["refund_create"])
                  }
                  setNotify={setNotify}
                  selectedPaymentTypeTab={selectedPaymentTypeTab}
                  setSelectedPaymentTypeTab={setSelectedPaymentTypeTab}
                />
              </TabPanel>
            );
          })}
        </DialogContent>
      </Dialog>

      <Popup
        title={"Transfer Payment"}
        fullWidth={true}
        openPopup={openTransferPopup}
        onClose={onCloseTransfer}
      >
        <TransferPayment
          invoice={invoice}
          payment={paymentForTransfer}
          onTransferPayment={onTransferPayment}
        />
      </Popup>

      <Popup
        title={"Multipayment details"}
        fullWidth={true}
        openPopup={isMultipaymentOpen}
        onClose={onCloseMultipaymentDetails}
      >
        <MultipaymentDetails history={paymentForEdit || null} />
      </Popup>

      <Dialog
        maxWidth="lg"
        fullWidth
        open={openPaymentToCreditPopup}
        classes={{ paper: classes.dialogWrapper2 }}
        onClose={closePaymentToCreditPopup}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            boxShadow: "none",
          },
        }}
        style={{ borderRadius: "50px" }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className={classes.dialogTitle2}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
              >
                {`Apply Credit: ${paymentToCreditForEdit?.forfeiture_amount}`}
              </Typography>

              <Controls.IconButton
                color="secondary"
                onClick={onClose}
                style={{ textAlign: "right" }}
              >
                <CloseIcon />
              </Controls.IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>
            {paymentToCredit
              ? paymentToCredit.map((invoice, index) => (
                  <Accordion
                    expanded={expanded === invoice.id}
                    onChange={handleChange(invoice.id)}
                    key={`${index}`}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={invoice.name}
                      id={`${invoice.id}`}
                      style={{
                        backgroundColor: "#e3f2fd",
                        borderTopLeftRadius: expanded === invoice.id ? 10 : 0,
                        borderTopRightRadius: expanded === invoice.id ? 10 : 0,

                        marginBottom: expanded === invoice.id ? 0 : 3,
                      }}
                    >
                      <Typography className={classes.heading}>
                        {invoice.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        boxShadow: "0 0 12px 0 rgb(53 64 82 / 8%)",
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 15,
                        }}
                      >
                        {invoice.items.map((item, index) => (
                          <>
                            <Grid item xs={10}>
                              <Typography
                                className={classes.secondaryHeading}
                                key={`${item.id}`}
                              >
                                {item.item_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              {expanded === invoice.id && (
                                <InputMoneyOk
                                  name={`${item.id}`}
                                  label="Amount"
                                  type="number"
                                  maxValue={
                                    paymentToCreditForEdit?.forfeiture_amount
                                  }
                                />
                              )}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              : ""}
          </DialogContent>
          <DialogActions style={{ margin: 15 }}>
            {sumAllFields > paymentToCreditForEdit?.forfeiture_amount && (
              <Alert
                severity="error"
                style={{
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  marginRight: 20,
                }}
              >
                <Typography variant="subtitle1" component="div">
                  {`Max Credit To Apply: $${paymentToCreditForEdit?.forfeiture_amount}`}
                </Typography>
              </Alert>
            )}
            <Controls.Button
              text="Cancel"
              onClick={closePaymentToCreditPopup}
              style={{ marginRight: 25 }}
            />

            <Controls.Button
              type="submit"
              text="Submit"
              disabled={
                sumAllFields > paymentToCreditForEdit?.forfeiture_amount
              }
            />
          </DialogActions>
        </FormProvider>
      </Dialog>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </Wrapper>
  );
}

export default Payments;
