import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import CommentFormCategories from "./forms/CommentFormCategories";
import { useDispatch, useSelector } from "react-redux";
import Controls from "./controls";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  Fab,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";

import Iconify from "./Iconify.js";
import MaterialTable from "material-table";
import Api from "../lib/api";
import useTable from "./useTable";
import Popup from "./Popup";
import { fetchEntitiesService as fetchCommentCategoriesService } from "../redux/slices/commentCategoriesSlice.js";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
}));

var dataSliceCommentCategories = {
  stateListName: "commentCategories",
  dataUrl: {},
  orgId: 1,
  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
};

function CommentsContact(props) {
  const classes = useStyles();
  const {
    contact_id,
    rReadonly = false,
    readonly = false,
    canAdd = true,
    editableNoDoctor = false,
    consultantErrorHandle = null,
  } = props;
  const dispatch = useDispatch();
  const { commentCategories } = useSelector((state) => state.commentCategories);

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };
  const [comment, setComment] = useState([]);
  const [commentL, setCommentL] = useState([]);
  const { TblContainer } = useTable();
  const [openPopup, setOpenPopup] = useState(false);
  const [filterCategories, setFilterCategories] = useState(null);
  const saveComment = async (payload) => {
    try {
      const { data } = await Api.createContactComment(contact_id, payload);
      setComment([data.data, ...comment]);
      onClose();
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };
  const [progressBar, setProgressBar] = useState(false);

  useEffect(() => {
    if (contact_id) {
      setProgressBar(true);
      commentsFunc().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  useEffect(() => {
    if (filterCategories && filterCategories > 0) {
      setCommentL(
        comment.filter((e) => {
          return e.comment_category_id === filterCategories;
        })
      );
    } else if (filterCategories && filterCategories == -2) {
      setCommentL(
        comment.filter((e) => {
          return e.comment_category_id == null;
        })
      );
    } else {
      setCommentL(comment);
    }
  }, [filterCategories, comment]);

  useEffect(() => {
    dispatch(fetchCommentCategoriesService(dataSliceCommentCategories));
  }, []);

  const commentsFunc = async () => {
    try {
      const { data } = await Api.getContactComments(contact_id);
      setComment(data.data);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  // const dataMaterialTable = [];
  const dataMaterialTable = commentL ? commentL.map((o) => ({ ...o })) : [];

  const headCellsMaterial = [
    {
      field: "comment",
      title: "Comment",
      render: (rowData) => (
        <ListItemText
          primary={`${rowData?.comment}`}
          primaryTypographyProps={{
            style: {
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "370px",
              fontFamily:
                '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
            },
          }}
        />
      ),
    },
    {
      field: "user_id",
      title: "Created By",
      render: (rowData) => {
        return rowData.user && !isEmpty(rowData.user.data) ? (
          <ListItemText
            primary={`${rowData?.user?.data?.title}`}
            secondary={`${rowData?.user?.data?.building_name} - ${rowData?.user?.data?.department_name}`}
          />
        ) : (
          ""
        );
      },
    },
    {
      field: "category",
      title: "Category",
      render: (rowData) => {
        return (
          <ListItem>
            <ListItemIcon>
              <Box
                style={{
                  width: 20,
                  height: 20,
                  display: "flex",
                  borderRadius: "50%",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: `${rowData.commentCategoryColor}`,
                }}
              />
            </ListItemIcon>
            <ListItemText primary={`${rowData.commentCategoryName}`} />
          </ListItem>
        );
      },
    },
    {
      field: "created_at",
      title: "Created",
      render: (rowData) => {
        return new moment(rowData.created_at).format("YYYY-MM-DD HH:mm:ss");
      },

      customSort: (a, b) =>
        new Date(
          moment(a.created_at).utc().format("YYYY-MM-DD HH:mm:ss")
        ).getTime() -
        new Date(
          moment(b.created_at).utc().format("YYYY-MM-DD HH:mm:ss")
        ).getTime(),
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const getCommentCategoriesOptions = () => {
    let commentCategoriesLocal = [];
    commentCategoriesLocal.push({
      id: -1,
      title: "-------",
      color: "#000000",
    });
    commentCategoriesLocal.push({
      id: -2,
      title: "No Category",
      color: "#e0e0e0",
    });

    commentCategories.forEach((e) => {
      var opt = {
        id: e.id,
        title: e.name,
        color: e.color,
      };
      commentCategoriesLocal.push(opt);
    });

    return commentCategoriesLocal;
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <div style={{ display: "flex", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              top: "20px",
              right: "445px",
              width: "200px",
            }}
          >
            <Controls.SelectWColor
              label="Category"
              value={filterCategories}
              options={getCommentCategoriesOptions()}
              onChange={(newValue) => {
                setFilterCategories(newValue.target.value);
              }}
              disabled={progressBar}
              variant="standard"
            />
          </div>
        </div>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            options={{
              loadingType: "linear",
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              sorting: true,
            }}
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <Iconify
                      icon={"fluent:add-12-filled"}
                      width={25}
                      height={25}
                    />
                  </Fab>
                ),
                tooltip: "Add Comment",
                disabled: !canAdd || readonly,
                hidden: !canAdd || readonly,
                isFreeAction: true,
                onClick: (event) => {
                  openInPopup();
                },
              },
            ]}
            title="Comments"
          />
        </TblContainer>
      </Paper>

      <Popup
        title={"Add comment"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <CommentFormCategories
          saveComment={saveComment}
          className={classes.form}
          canAdd={canAdd}
          readonly={readonly}
          //rReadonly={rReadonly}
          editableNoDoctor={editableNoDoctor}
          commentCategories={commentCategories}
        />
      </Popup>
    </Wrapper>
  );
}

export default CommentsContact;
