import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
  _updateOnlyEntityService,
} from "./common/sliceTicketsFunctions";

var entitiesName = "surgeonQuotesRecordProductReports";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_SURGEONQUOTESRECORDPRODUCTREPORTS";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}
/*export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code = "POST_SURGEONQUOTESRECORDPRODUCTREPORT";
  return _createEntityService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "PUT_SURGEONQUOTESRECORDPRODUCTREPORT";
  return _updateEntityService(dataSlice, events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code = "DELETE_SURGEONQUOTESRECORDPRODUCTREPORT";
  return _deleteEntityService(dataSlice, events);
}
export function updateOnlyEntityService(payload) {
  return _updateOnlyEntityService(payload, events);
}
*/
