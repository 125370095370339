import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Api from "../../../lib/api";

const initialEmailClientState = {
    emailAccounts: {
        data: {},
        loaded: false,
        loading: false,
        saveloading: false,
        deleteLoading:false,
    },
    emailKnownConf: {
        data: {},
        loading: false,
    },
    accountsData: {
        /*
        when populated would be...
        gmail:{
            folders:[],
            loadingFolders,
            loadingMessages
            messagesStore: {
                123:{ messageData},
                3434: {messageData, loadingContent:}
                ...
            },
            sending: {
                id:xxx:{ to:xxxx, subject:xxx, ...data}
                id:xxx:{ to:xxxx, subject:xxx, ...data}
            },
            messagesOrder: {
                inbox : { //folder
                    pages: {
                        1: ['123', sending:true|false, '3434',...,'858'],  //page 1
                        2: ['234', sending:true|false, '3433545',...,'85345345'],  //page 2
                    },
                    pagination: {
                        current_page,
                        total,

                    }
                }
                
            }
        }
        */

    }
}

// const customizer = (obj, src) => {
//     if (_.isUndefined(obj)) {
//         return src
//     }
//     if (_.isArray(src)) {
//         return src
//     }
//     // if (_.isObject(src)) {
//     //     return {
//     //         ...obj,
//     //         ...src
//     //     }
//     // }
// }

const emailslices = createSlice({
    name: 'EmailClient',
    initialState: initialEmailClientState,
    reducers: {
        setEmailAccountsLoaded: (state, { payload }) => {
            state.emailAccounts.loaded = payload;
        },
        setEmailAccountsLoading: (state, { payload }) => {
            state.emailAccounts.loading = payload
        },
        setEmailAccountsSaveLoading: (state, { payload }) => {
            state.emailAccounts.saveloading = payload
        },
        setEmailAccountData: (state, { payload }) => {
            state.emailAccounts.data = {
                ...state.emailAccounts.data,
                ...payload
            }
        },
        setEmailKnowConfLoading: (state, { payload }) => {
            state.emailKnownConf.loading = payload
        },
        setEmailKnowConfData: (state, { payload }) => {
            state.emailKnownConf.data = payload
        },
        //account info
        setAccountDataFolders: (state, { payload: { account, folders } }) => {
            state.accountsData[account] = {
                ...state.accountsData[account],
                folders: folders
            }
        },
        setAccountDataFoldersLoading: (state, { payload: { account, loading } }) => {
            state.accountsData[account] = {
                ...state.accountsData[account],
                loadingFolders: loading
            }
        },
        setAccountDataMessagesLoading: (state, { payload: { account, loading } }) => {
            state.accountsData[account] = {
                ...state.accountsData[account],
                loadingMessages: loading
            }
        },
        setAccountDataMessages: (state, { payload: { account, folder, /*page,*/ messages, pagination } }) => {

            let order = messages.map(m => m.uid);
            let store = messages.reduce((carry, item) => {
                carry[item.uid] = item;
                return carry;
            }, {})



            let page = pagination?.current_page ? pagination.current_page : 1
            state.accountsData[account] = _.merge(state.accountsData[account], {
                messagesOrder: {
                    [folder]: {
                        pages: {
                            [page]: order
                        },
                        // pagination: pagination
                    }
                },
                // messagesStore: store
            })
            state.accountsData[account].messagesOrder[folder].pagination = pagination
            state.accountsData[account].messagesStore = {
                ...state.accountsData[account].messagesStore,
                ...store
            }

        },
        setAccountDataPage: (state, { payload: { account, folder, page } }) => {
            state.accountsData[account] = _.merge(state.accountsData[account], {
                messagesOrder: {
                    [folder]: {
                        pagination: {
                            current_page: page
                        }
                    }
                }
            })
        },
        setAccountDeleteLoading: (state, { payload: {account, value=false }}) => {
            state.emailAccounts.deleteLoading = value
        },
        removeAccountDeleteFromState: (state, { payload: {account }}) => {
            state.emailAccounts.data = Object.keys(state.emailAccounts.data).reduce((p,c)=>{
                if (c!=account) {
                    return {...p,[c]:state.emailAccounts.data[c]}
                } else {
                    return p;
                }
            },{})
        },
        updateAccountDataMessage: (state, { payload: { account, message } }) => {
            state.accountsData[account].messagesStore[message.uid] = message
        },
        setAccountDataMessageLoading: (state, { payload: { account, uid, loading } }) => {
            state.accountsData[account] = _.merge(state.accountsData[account], {
                messagesStore: {
                    [uid]: {
                        loading: loading
                    }
                }
            })
        },
        setAccountDataPerPage: (state, { payload: { account, folder, per_page } }) => {
            let new_order = Object.keys(state.accountsData[account].messagesOrder[folder].pages)
                .reduce((carry, page) => {
                    return carry.concat(state.accountsData[account].messagesOrder[folder].pages[page]);
                }, []);

            const result = new_order.reduce((resultObject, item, index) => {
                const chunkIndex = Math.floor(index / per_page) + 1

                if (!Object.hasOwn(resultObject, chunkIndex)) {
                    resultObject[chunkIndex] = []
                }

                resultObject[chunkIndex].push(item)

                return resultObject
            }, {})

            if (result[Object.keys(result).sort()[Object.keys(result).length - 1]].length < per_page) {
                delete result[Object.keys(result).sort()[Object.keys(result).length - 1]];
            }

            state.accountsData[account] = _.merge(state.accountsData[account], {
                messagesOrder: {
                    [folder]: {
                        pagination: {
                            per_page: per_page
                        }
                    }
                }
            })
            state.accountsData[account].messagesOrder[folder].pages = result
        },
        addAccountDataSending: (state, { payload: { account, message } }) => {
            state.accountsData[account].sending = {
                ...state.accountsData[account].sending,
                [message.id]: {
                    ...message
                }
            }
        },
        updateAccountDataSending: (state, { payload: { account, message } }) => {
            state.accountsData[account].sending = {
                ...state.accountsData[account].sending,
                [message.id]: {
                    ...message
                }
            }
        },
        deleteAccountDataSending: (state, { payload: { account, message } }) => {
            delete state.accountsData[account].sending[message.id]
        },


    }
});

const retryAccountDataSending = (account, message) => {
    return async (dispatch) => {
        let new_message = { ...message }
        if (Object.hasOwn(new_message, 'error')) {
            delete new_message['error']
        }
        try {
            dispatch(emailslices.actions.updateAccountDataSending({ account, message: new_message }))
            dispatch(addAccountDataSending(account, new_message, false))


        } catch (e) {
            new_message['error'] = e
            dispatch(emailslices.actions.updateAccountDataMessage({ account, message: new_message }));
        }
    }
}

const addAccountDataSending = (account, message, addMessageToQueue = true) => {
    return async (dispatch) => {

        let message_to_dispatch = {
            ...message,
        }
        if (addMessageToQueue) {
            dispatch(emailslices.actions.addAccountDataSending({ account, message: message_to_dispatch }));
        }
        try {
            let match = message.to.match(/^(?<email1>\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))|((?<name>[\w]+[\s\w+]*\w+)\s*<(?<email2>(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})))>)+$/)
            if (!match) {
                throw "Email address doesn't match pattern"
            }
            let email = match?.groups?.email1 || match?.groups?.email2
            let nombre = match?.groups?.name;

            let formData = new FormData();
            if (message.files && _.isObject(message.files)) {
                for (let key of Object.keys(message.files)) {
                    let blob = await fetch(message.files[key]).then(r => r.blob());
                    formData.append('attachments[]', blob, key)
                }
            }

            if (message.embedded && _.isArray(message.embedded)) {
                for (let i = 0; i < message.embedded.length; i++) {
                    let blob = await fetch(message.embedded[i]).then(r => r.blob());
                    formData.append('embedded[]', blob, message.embedded[i])
                }

            }

            // message.embedded && _.isArray(message.embedded) && Object.keys(message.embedded).forEach(k => {
            //     formData.append('embedded[]', message.embedded[k], k)
            // })

            formData.append("subject", message.subject);
            formData.append("to", email);
            formData.append("to_name", nombre);
            formData.append("body", message.body);
            formData.append("reply_to", message.reply_to);
            formData.append("references", message.references);

            let { data } = await Api.saveEntity({
                dataUrl: {
                    code: 'POST_EMAIL_MESSAGE'
                },
                payload: formData,
                filters: {
                    params: {
                        account
                    }
                }
            })

            //clean blob url created
            if (message.files && _.isObject(message.files)) {
                for (let key of Object.keys(message.files)) {
                    URL.revokeObjectURL(key)
                }
            }

            message_to_dispatch.embedded.forEach(f => {
                URL.revokeObjectURL(f)
            })

            dispatch(emailslices.actions.deleteAccountDataSending({ account, message: message_to_dispatch }))
            return true

        } catch (e) {
            dispatch(emailslices.actions.updateAccountDataSending({
                account, message: {
                    ...message_to_dispatch,
                    error: e
                }
            }))
        }
    }
}

const deleteAccountData = (account) => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setAccountDeleteLoading({account,value: true}));

        try {
            let { data } = await Api.deleteEntity({
                dataUrl: {
                    code: 'DELETE_EMAIL_MESSAGE'
                },
                filters: {
                    params: {
                        account: account
                    }
                }
            })
            dispatch(emailslices.actions.removeAccountDeleteFromState({account}))
            return true

        } catch (e) {
            throw(e)
        }
        finally {
            dispatch(emailslices.actions.setAccountDeleteLoading({account, value: false}));
        }
    }
}

const loadAccountsFolders = (account) => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setAccountDataFoldersLoading({ account, loading: true }))
        try {
            let { data } = await Api.getEntities({
                dataUrl: {
                    code: 'GET_EMAIL_FOLDERS'
                },
                filters: {
                    include: ['info', 'children.info'],
                    params: {
                        account: account
                    }
                }
            });

            let value = data?.data ? data.data : data
            dispatch(emailslices.actions.setAccountDataFolders({ account, folders: value }));

        } catch (e) {
            throw e
        } finally {
            dispatch(emailslices.actions.setAccountDataFoldersLoading({ account, loading: false }))
        }

    }
}

const loadMessageContent = (account, folder, message_uid) => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setAccountDataMessageLoading({ account, uid: message_uid, loading: true }))
        try {
            let { data } = await Api.getEntities({
                dataUrl: {
                    code: "GET_EMAIL_FOLDERS_MESSAGE"
                },
                filters: {
                    params: {
                        account,
                        folder,
                        uid: message_uid
                    }
                }
            });

            if (data?.data?.uid == message_uid) {
                dispatch(emailslices.actions.updateAccountDataMessage({ account, message: data.data }))
            } else {
                throw "Bad response"
            }
        } catch (e) {
            throw e;
        } finally {
            dispatch(emailslices.actions.setAccountDataMessageLoading({ account, uid: message_uid, loading: false }))
        }
    }
}

const changeAccountFolderPage = (account, folder, page) => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setAccountDataPage({ account, folder, page }))
    }
}

const changeAccountFolderPerPage = (account, folder, per_page) => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setAccountDataPerPage({ account, folder, per_page }))
    }
}

const loadAccountFolderMessages = (account, folder, page = 1, limit = 10) => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setAccountDataMessagesLoading({ account, loading: true }))
        try {
            let { data } = await Api.getEntities({
                dataUrl: {
                    code: 'GET_EMAIL_FOLDERS_MESSAGES'
                },
                filters: {
                    params: {
                        account,
                        folder
                    },
                    page,
                    limit
                }
            })

            if (data.data && data.meta) {
                let meta = data.meta
                data = data.data
                dispatch(emailslices.actions.setAccountDataMessages({
                    account,
                    folder,
                    // page: meta?.pagination?.current_page ? meta.pagination.current_page : 1,
                    messages: data,
                    pagination: meta.pagination
                }))
                return meta.pagination
            }
            return null

        } catch (e) {
            throw e
        } finally {
            dispatch(emailslices.actions.setAccountDataMessagesLoading({ account, loading: false }))
        }
    }
}

const loadEmailAccounts = () => {

    return async (dispatch) => {
        dispatch(emailslices.actions.setEmailAccountsLoading(true));
        try {
            let { data } = await Api.getEntities({
                dataUrl: {
                    code: 'GET_EMAIL_ACCOUNTS'
                },
                filters: {}
            });
            let value = data.data ? data.data : []
            value = value.reduce((carry, item) => { //covert array to object normalized
                carry[item.name] = item;
                return carry;
            }, {})


            dispatch(emailslices.actions.setEmailAccountData(value));
        } catch (e) { throw e }
        finally {
            dispatch(emailslices.actions.setEmailAccountsLoading(false));
        }

    }
}

const storeEmailAccount = (values, authCode = false) => {
    return async (dispatch) => {
      dispatch(emailslices.actions.setEmailAccountsSaveLoading(true));
      try {
        let { data } = await Api.saveEntity({
          dataUrl: {
            code: authCode
              ? "POST_EMAIL_ACCOUNTS_OAUTH_CODE_GMAIL"
              : "POST_EMAIL_ACCOUNTS",
          },
          payload: values,
          filters: {},
        });
        let value = data.data;
        value = {
          [value.name]: value,
        };

        dispatch(emailslices.actions.setEmailAccountData(value));
        return value;
      } catch (e) {
        throw e;
      } finally {
        dispatch(emailslices.actions.setEmailAccountsSaveLoading(false));
      }
    }
}

const loadEmailKnowConf = () => {
    return async (dispatch) => {
        dispatch(emailslices.actions.setEmailKnowConfLoading(true))
        try {
            let { data } = await Api.getEntities({
                dataUrl: {
                    code: 'GET_EMAIL_KNOWN_CONF'
                },
                filters: {}
            })
            dispatch(emailslices.actions.setEmailKnowConfData(data));
        } catch (e) {
            throw e
        } finally {
            dispatch(emailslices.actions.setEmailKnowConfLoading(false));
        }

    }
}

export {
    loadEmailAccounts,
    loadEmailKnowConf,
    storeEmailAccount,
    loadAccountsFolders,
    loadAccountFolderMessages,
    changeAccountFolderPage,
    changeAccountFolderPerPage,
    addAccountDataSending,
    // getMessageBody
    loadMessageContent,
    retryAccountDataSending,
    deleteAccountData
}



export default emailslices.reducer;