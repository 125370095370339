import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "./ConfirmDialog";
import useTable from "./useTable";
import Controls from "./controls";
import MaterialTable from "material-table";
import { Paper, Fab, Chip } from "@material-ui/core";
import Popup from "./Popup";
import { useTranslation } from "react-i18next";
import Api from "../lib/api";
import Notification from "./Notification";
import Iconify from "./Iconify";
import moment from "moment/moment";
import ChangeDateOfProcedureRequestDetails from "./ChangeDateOfProcedureRequestDetails";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  approveButton: {
    background: "#c9fbc9",
    color: "#017f00",
    padding: "6px 11px",
    borderRadius: 14,
    "&:hover": {
      backgroundColor: "#a8dba8",
    },
  },
  denyButton: {
    background: "#ffdaca",
    color: "#a31d06",
    padding: "6px 11px",
    borderRadius: "14px",
    "&:hover": {
      backgroundColor: "#e5ab92",
    },
  },
}));
function RecordProductReschedulingRequests(props) {
  const classes = useStyles();
  const [pendingRequests, setPendingRequests] = useState([]);
  const [closedRequests, setClosedRequests] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const { t, i18n } = useTranslation(["common", "records"]);
  const { recordProduct, setRecordProductForEdit } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const pendingRequestsHeadCellsMaterial = [
    {
      field: "old_date",
      title: "Current date",
      render: (rowData) => {
        return rowData.old_date
          ? moment(rowData.old_date).format("MM/DD/YYYY, hh:mm A")
          : "None";
      },
    },
    {
      field: "requested_date",
      title: "Requested Date",
      render: (rowData) => {
        return moment(rowData.requested_date).format("MM/DD/YYYY, hh:mm A");
      },
    },
    {
      field: "requested_by",
      title: "Requested By",
      render: (rowData) => {
        return rowData?.requestedBy?.data?.users_fname;
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <Chip
            label={rowData?.status?.toUpperCase() || ""}
            style={{
              backgroundColor: "#fffdbd",
              color: "#9f9401",
              border: "none",
            }}
            variant={"outlined"}
          />
        );
      },
    },
    {
      field: "requested_at",
      title: "Requested at",
      render: (rowData) => {
        return moment(rowData.created_at).format("MM/DD/YYYY, hh:mm A");
      },
    },
  ];
  const closedRequestsHeadCellsMaterial = [
    {
      field: "old_date",
      title: "Current date",
      render: (rowData) => {
        return rowData.old_date
          ? moment(rowData.old_date).format("MM/DD/YYYY, hh:mm A")
          : "None";
      },
    },
    {
      field: "requested_date",
      title: "Requested Date",
      render: (rowData) => {
        return moment(rowData.requested_date).format("MM/DD/YYYY, hh:mm A");
      },
    },
    {
      field: "requested_by",
      title: "Requested By",
      render: (rowData) => {
        return rowData?.requestedBy?.data?.users_fname;
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <Chip
            label={rowData?.status?.toUpperCase() || ""}
            style={{
              border: "none",
              backgroundColor:
                rowData.status == "approved"
                  ? "rgb(155 243 131)"
                  : "rgb(253 189 180)",
              color:
                rowData.status == "approved"
                  ? "rgb(0 155 20)"
                  : "rgb(155 17 17)",
              fontWeight: 500,
            }}
            variant={"outlined"}
          />
        );
      },
    },
    {
      field: "requested_at",
      title: "Requested at",
      render: (rowData) => {
        return moment(rowData.created_at).format("MM/DD/YYYY, hh:mm A");
      },
    },
    {
      field: "closed_by",
      title: "Closed by",
      render: (rowData) => {
        return rowData?.closedBy?.data?.users_fname;
      },
    },
    {
      field: "closed_at",
      title: "Closed at",
      render: (rowData) => {
        return moment(rowData.closed_at).format("MM/DD/YYYY, hh:mm A");
      },
    },
  ];

  useEffect(() => {
    if (recordProduct) {
      getRequests();
    }
  }, [recordProduct]);

  const getRequests = async () => {
    setProgressBar(true);
    const { data } = await Api.getRecordProductReschedulingRequests(
      recordProduct?.id || null
    );
    setPendingRequests([...data.pendingRecordProductDateTransfer.data]);
    setClosedRequests([...data.closedRecordProductDateTransfer.data]);
    setProgressBar(false);
  };

  const updateRequestStatus = async (status, id) => {
    setConfirmDialogLoading(true);
    var payload = {
      status: status,
    };
    await Api.closeRecordProductReschedulingRequest(payload, id);

    if (setRecordProductForEdit && status == "approved") {
      const { data } = await Api.getProductRecordPlain(recordProduct.id);
      setRecordProductForEdit({ ...data.data });
    } else {
      await getRequests();
    }
    setConfirmDialogLoading(false);
    setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
  };

  const onClosePopup = () => {
    setSelectedRequest(null);
    setOpenPopup(false);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={pendingRequestsHeadCellsMaterial}
            data={pendingRequests}
            actions={[
              (rowData) => ({
                icon: () => (
                  <Controls.Button
                    disabled={recordProduct.finalized || recordProduct.void}
                    className={classes.approveButton}
                    startIcon={<Iconify icon="pajamas:check-xs" />}
                    text="Approve"
                    color="secondary"
                  />
                ),
                disabled: recordProduct.finalized || recordProduct.void,
                tooltip: "Approve request",
                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to approve this request?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () =>
                      updateRequestStatus("approved", rowData.id),
                  }),
              }),
              (rowData) => ({
                icon: () => (
                  <Controls.Button
                    disabled={recordProduct.finalized || recordProduct.void}
                    className={classes.denyButton}
                    startIcon={<Iconify icon="line-md:cancel" />}
                    text="Deny"
                    color="secondary"
                  />
                ),
                disabled: recordProduct.finalized || recordProduct.void,
                tooltip: "Deny request",
                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to deny this request?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () =>
                      updateRequestStatus("declined", rowData.id),
                  }),
              }),
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color: "#006ac7",
                    }}
                  >
                    <Iconify
                      icon={"fluent:textbox-16-filled"}
                      width={28}
                      height={28}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "See details",
                onClick: (event, rowData) => {
                  setSelectedRequest(rowData);
                  setOpenPopup(true);
                },
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Pending Surgery Scheduling Requests"
          />
        </TblContainer>
      </Paper>

      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={closedRequestsHeadCellsMaterial}
            data={closedRequests}
            actions={[
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color: "#006ac7",
                    }}
                  >
                    <Iconify
                      icon={"fluent:textbox-16-filled"}
                      width={28}
                      height={28}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "See details",
                onClick: (event, rowData) => {
                  setSelectedRequest(rowData);
                  setOpenPopup(true);
                },
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              rowStyle: (rowData, index) => {
                return {
                  backgroundColor:
                    rowData.status == "approved"
                      ? "rgb(228 255 227 / 88%)"
                      : "rgb(255 237 227 / 88%)",
                };
              },
            }}
            title="Closed Surgery Scheduling Requests"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Scheduling Request"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClosePopup}
      >
        <ChangeDateOfProcedureRequestDetails request={selectedRequest} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmLoading={confirmDialogLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default RecordProductReschedulingRequests;
