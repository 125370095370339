import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { StylesProvider } from "@material-ui/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Routes from "./routes/routes";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { isEmpty } from "lodash";
import { fetchUser } from "./redux/slices/auth";
import GetCalls from "./components/click2call/getCallsApp";
import maTheme from "./theme";
import CallGetCallsApp from "./components/calls/CallGetCallsApp";
import { getBrowserToken, onMessageListener } from "./utils/firebase";
import UserContactsSMSMMS from "./components/userContactsSMSMMS";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider, useSnackbar } from "notistack";
import { ChannelsProvider } from "./helpers/websockets";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));


function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser, loading, errors } = useSelector((state) => state.auth);
  const { pbxConfig } = useSelector((state) => state.auth);
  const portal = localStorage.getItem("portal");
  const Usertoken = useMemo(() => localStorage.getItem("token"), [authUser]);

  useEffect(() => {
    if (isEmpty(authUser) && !portal && !loading) dispatch(fetchUser());

    //onMessageListener();
  }, []);

  useEffect(() => {
    if (!isEmpty(authUser)) getBrowserToken(authUser.id);
  }, [authUser]);

  //  console.log("auto render", pbxConfig);

  if (process.env.REACT_APP_ENV === "production") {
    console.log = function no_console() {};
    console.error = function no_console() {};
  }

  if (!isEmpty(authUser)) {
    return (
      <MuiThemeProvider theme={maTheme[authUser?.organization.data.theme || 0]}>
        <ThemeProvider theme={maTheme[authUser?.organization.data.theme || 0]}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <React.Fragment>
              <Helmet titleTemplate="%s | CRM App" />
              <StylesProvider injectFirst>
                <Suspense
                  fallback={
                    <Backdrop className={classes.backdrop} open={true}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  }
                >
                  <Routes user={authUser} />
                </Suspense>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                  }}
                >
                  {pbxConfig && pbxConfig.aor && (
                    <CallGetCallsApp active={true} />
                  )}{" "}
                </div>
              </StylesProvider>

              {/*pbxConfig && pbxConfig.aor && <GetCalls active={true} />*/}
              {/*pbxConfig && pbxConfig.aor && <CallGetCallsApp active={true} />*/}
            </React.Fragment>
          </SnackbarProvider>
        </ThemeProvider>
        <UserContactsSMSMMS />
      </MuiThemeProvider>
    );
  } else
    return (
      <React.Fragment>
        <Helmet titleTemplate="%s | CRM App" />
        <StylesProvider injectFirst>
          <Suspense
            fallback={
              <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            }
          >
            <Routes />
          </Suspense>
          <div style={{ display: "flex", position: "relative" }}>
            {pbxConfig && pbxConfig.aor && <CallGetCallsApp active={true} />}{" "}
          </div>
        </StylesProvider>

        {/*pbxConfig && pbxConfig.aor && <GetCalls active={true} />*/}
        {/*pbxConfig && pbxConfig.aor && <CallGetCallsApp active={true} />*/}
      </React.Fragment>
    );
}

export default App;
