import { useMemo } from "react";
import GenericTable from "../components/GenericTable";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Grid, TextField, makeStyles } from "@material-ui/core";
import ColorPickerPopup from "../../../components_generic/ColorPickerPopup";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";

const RefundPriorities = () => {
    const columns = useMemo(
        () => [
            { field: "name", title: "Name" },
            { field: "description", title: "Description" },
            {
                field: "color",
                title: "Color",
                render: (row) => {
                    return (
                        <Box
                            style={{
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                backgroundColor: row.color,
                            }}
                        />
                    );
                },
            },
        ],
        []
    );

    const model = "rfs_priorities";

    const FormData = () => {
        const {
            control,
            formState: { errors },
        } = useFormContext();
        return (
            <Grid container direction="column">
                <Grid item>
                    <Controller
                        name="name"
                        P
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Name is required",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <TextField
                                label="Name"
                                name="name"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            maxLength: {
                                value: 100,
                                message: "Max lenght is 100",
                            },
                        }}
                        render={({ onChange, onBlur, value }) => (
                            <TextField
                                label="Description"
                                name="description"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.description}
                                multiline
                                helperText={errors.description?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    style={{
                        marginTop: 15,
                    }}
                >
                    <Controller
                        name="color"
                        control={control}
                        defaultValue={"#FF6900"}
                        render={({ onChange, onBlur, value }) => (
                            <ColorPickerPopup
                                value={value}
                                onChange={onChange}
                                label={"Color"}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

    const useStyles = makeStyles((theme) => ({
        headerIcon: {
          color: theme.palette.primary.main,
        },
      }));
      const classes = useStyles();

    return (
    <>
    <Helmet>
        <title>Refund priorities</title>
    </Helmet>
    <PageHeader
        title="Refund priorities"
        subTitle=""
        icon={
          <PeopleOutlineTwoToneIcon
            fontSize="large"
            className={classes.headerIcon}
          />
        }
      />
      <GenericTable model={model} columns={columns} FormData={FormData} tableTitle="Refund priorities"/>
      </>
      );
};

export default RefundPriorities;
