import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  paymentGateways: [],
};

const paymentGatewaysSlice = createSlice({
  name: "paymentGateways",
  initialState,
  reducers: {
    getPaymentGateways: (state) => {
      state.loading = true;
    },
    getPaymentGatewaysSuccess: (state, { payload }) => {
      state.paymentGateways = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getPaymentGatewaysFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createPaymentGateway: (state) => {},
    createPaymentGatewaySuccess: (state, { payload }) => {
      state.paymentGateways = [...state.paymentGateways, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createPaymentGatewayFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updatePaymentGateway: (state) => {},
    updatePaymentGatewaySuccess: (state, { payload }) => {
      const index = findIndex(state.paymentGateways, ["id", payload.data.id]);
      const paymentGateway = { ...state.paymentGateways[index], ...payload.data };

      let paymentGateways = [...state.paymentGateways];
      paymentGateways[index] = paymentGateway;

      state.paymentGateways = [...paymentGateways];
      state.loading = false;
      state.errors = [];
    },
    updatePaymentGatewayFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deletePaymentGateway: (state) => {},
    deletePaymentGatewaySuccess: (state, { payload }) => {
      state.paymentGateways = state.paymentGateways.filter((paymentGateway) => {
        return paymentGateway.id !== payload;
      });
    },
    deletePaymentGatewayFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getPaymentGateways,
  getPaymentGatewaysSuccess,
  getPaymentGatewaysFailure,
  createPaymentGateway,
  createPaymentGatewaySuccess,
  createPaymentGatewayFailure,
  updatePaymentGateway,
  updatePaymentGatewaySuccess,
  updatePaymentGatewayFailure,
  deletePaymentGateway,
  deletePaymentGatewaySuccess,
  deletePaymentGatewayFailure,
} = paymentGatewaysSlice.actions;

export const paymentGatewaysSelector = (state) => state.paymentGateways;
export default paymentGatewaysSlice.reducer;

export function fetchPaymentGatewaysService(orgId, params="") {
  return async (dispatch) => {
    dispatch(getPaymentGateways());

    try {
      const response = await Api.getPaymentGateways(orgId, params);
      dispatch(getPaymentGatewaysSuccess(response.data));
    } catch (error) {
      dispatch(getPaymentGatewaysFailure(error));
    }
  };
}

export function createPaymentGatewayService(payload) {
  return async (dispatch) => {
    dispatch(createPaymentGateway());

    try {
      const response = await Api.savePaymentGateway(payload);

      dispatch(createPaymentGatewaySuccess(response.data));
    } catch (error) {
      dispatch(createPaymentGatewayFailure(error));
    }
  };
}

export function updatePaymentGatewayService(id, payload) {
  return async (dispatch) => {
    dispatch(updatePaymentGateway());

    try {
      const response = await Api.updatePaymentGateway(id, payload);

      dispatch(
        updatePaymentGatewaySuccess(response.data)
      );
    } catch (error) {
      dispatch(updatePaymentGatewayFailure(error));
    }
  };
}

export function deletePaymentGatewayService(id) {
  return async (dispatch) => {
    dispatch(deletePaymentGateway());

    try {
      await Api.deletePaymentGateway(id);

      dispatch(deletePaymentGatewaySuccess(id));
    } catch (error) {
      dispatch(deletePaymentGatewayFailure(error));
    }
  };
}