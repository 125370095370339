import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  quotes: [],
  fields: [],
};

const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    getQuotes: (state) => {
      state.loading = true;
    },
    getQuotesSuccess: (state, { payload }) => {
      state.quotes = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getQuotesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createQuote: (state) => {
      state.saveLoading = true;
    },
    createQuoteSuccess: (state, { payload }) => {
      state.quotes = [...state.quotes, payload.data];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    createQuoteFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    updateQuote: (state) => {},
    updateQuotesuccess: (state, { payload }) => {
      const index = findIndex(state.quotes, ["id", payload.data.id]);
      const quote = { ...state.quotes[index], ...payload.data };

      let quotes = [...state.quotes];
      quotes[index] = quote;

      state.quotes = [...quotes];
      state.loading = false;
      state.errors = [];
    },
    updateQuoteFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    deleteQuote: (state) => {},
    deleteQuoteSuccess: (state, { payload }) => {
      state.quotes = state.quotes.filter((quote) => {
        return quote.id !== payload;
      });
    },
    deleteQuoteFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    getCustomFields: (state) => {
      //state.loading = true;
    },
    getCustomFieldsSuccess: (state, { payload }) => {
      state.fields = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCustomFieldsFailure: (state, { payload }) => {
      state.loading = false;
      state.fields = [];
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getQuotes,
  getQuotesSuccess,
  getQuotesFailure,
  createQuote,
  createQuoteSuccess,
  createQuoteFailure,
  updateQuote,
  updateQuotesuccess,
  updateQuoteFailure,
  getCustomFields,
  deleteQuote,
  deleteQuoteSuccess,
  deleteQuoteFailure,
  getCustomFieldsSuccess,
  getCustomFieldsFailure,
} = quotesSlice.actions;
export const quotesSelector = (state) => state.quotes;
export default quotesSlice.reducer;

export function fetchQuotesService(orgId) {
  return async (dispatch) => {
    dispatch(getQuotes());

    try {
      const response = await Api.getQuotes(orgId);

      dispatch(getQuotesSuccess(response.data));
    } catch (error) {
      dispatch(getQuotesFailure(error));
    }
  };
}

export function fetchQuotesByContactService(contactId) {
  return async (dispatch) => {
    dispatch(getQuotes());

    try {
      const response = await Api.getContactQuotes(contactId);

      dispatch(getQuotesSuccess(response.data));
    } catch (error) {
      dispatch(getQuotesFailure(error));
    }
  };
}
export function createQuoteService(payload) {
  return async (dispatch) => {
    dispatch(createQuote());

    try {
      const response = await Api.saveQuote(payload);

      dispatch(createQuoteSuccess(response.data));
    } catch (error) {
      dispatch(createQuoteFailure(error));
    }
  };
}
export function updateQuoteService(id, payload) {
  return async (dispatch) => {
    dispatch(updateQuote());

    try {
      const response = await Api.updateQuote(id, payload);

      dispatch(updateQuotesuccess(response.data));
    } catch (error) {
      dispatch(updateQuoteFailure(error));
    }
  };
}

export function deleteQuoteService(id) {
  return async (dispatch) => {
    dispatch(deleteQuote());

    try {
      const response = await Api.deleteQuote(id);

      dispatch(deleteQuoteSuccess(id));
    } catch (error) {
      dispatch(deleteQuoteFailure(error));
    }
  };
}

export function approveQuoteService(payload) {
  return async (dispatch) => {
    try {
      dispatch(updateQuotesuccess(payload));
    } catch (error) {
      dispatch(updateQuoteFailure(error));
    }
  };
}
