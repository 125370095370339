import { motion } from "framer-motion";
import { Typography } from "@material-ui/core";

export default function LoadingScreen(props, { url }) {
  const {message = null} = props;
  return (
    <>
      <div
        style={{
          right: 0,
          bottom: 0,
          zIndex: 99999,
          width: "100%",
          height: "100%",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <motion.div
          style={{
            width: 150,
            height: 150,
            borderRadius: 30,
            backgroundColor: "#e3f2fd",
          }}
          animate={{ rotate: 360 }}
          transition={{ ease: "linear", duration: 4, repeat: Infinity }}
        ></motion.div>
      </div>
      <div
        style={{
          right: 0,
          bottom: 0,
          zIndex: 99999,
          width: "100%",
          height: "100%",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1" sx={{ whiteSpace: "pre-line" }}
        style = {{fontSize: message? '15px': '1.5rem'}}
        >
          {message?message : '...Loading'}
        </Typography>
      </div>
    </>
  );
}
