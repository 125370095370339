import React from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      "& fieldset": {
        borderColor: "rgb(0 0 0 / 14%)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(0 0 0 / 40%)",
      },
    },
  },
}));

export default function InputMoneyToPaymentForm(props) {
  const {
    mins = null,
    minValidations2 = null,
    indexValue = null,
    setMinValidations2 = null,
    name,
    label,
    onChange = null,
    minimumValue = 0,
    maximumValue = 0,
    setOverPaymentControl = null,
    onRefundAmountChange = null,
    defaultValue = null,
    itemToPrint = null,
    /* setCustomValue = null,*/ /* overPaymentControl = null, overPaidItem = null, itemType = null,*/ ...other
  } = props;
  const classes = useStyles();

  return (
    <CurrencyTextField
      className={classes.textfield}
      defaultValue={defaultValue}
      label={label}
      variant="outlined"
      currencySymbol="$"
      outputFormat="string"
      maximumValue={maximumValue ? maximumValue : "999999"}
      onChange={(e, value) => {
        //  console.log("rowdatamoney ITEM", itemToPrint);
        onChange ? onChange(value) : "";
        onRefundAmountChange ? onRefundAmountChange(value) : "";
        setOverPaymentControl ? setOverPaymentControl(value) : "";
        if (mins && minValidations2 && typeof setMinValidations2 == "function") {
          var a = {};
          a[`itemsAmount[${indexValue}]`] =
            value < mins[indexValue] ? true : false;
          setMinValidations2({ ...minValidations2, ...a });
        }

        //console.log("InputMoney",value);
      }}
      minimumValue={minimumValue ? minimumValue : "0"}
      //value={setCustomValue?setCustomValue:0}
      //className={"yea"}
      {...other}
    />
  );
}
