import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export default function DatePickerPeriod(props) {
  const { label, onChange, value, ...other } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        inputVariant="outlined"
        openTo="year"
        views={["year", "month"]}
        onChange={(date) => onChange(date)}
        value={value}
        InputProps={{ readOnly: true }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
