import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import ExtraPaymentForm from "./forms/ExtraPaymentForm";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Api from "../lib/api";
import ConfirmDialog from "./ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "./useTable";
import Controls from "./controls";
import InvoiceDetails from "../pages/invoices/details";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { fetchPaymentGatewaysService } from "../redux/slices/paymentgateways";
import { fetchUsersService } from "../redux/slices/users";
import { createInvoiceExtraPaymentService } from "../redux/slices/invoices";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import Iconify from "./Iconify.js";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Fab,
  LinearProgress,
} from "@material-ui/core";
import Popup from "./Popup";
import { Link } from "react-router-dom";

import { fetchEntitiesService as fetchProductsService } from "../redux/slices/productsnewSlice.js";

var dataSliceProduct = {
  stateListName: "productsNew",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButtonDiv: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));
function ExtraPaymentsPatients(props) {
  const { authUser } = useSelector((state) => state.auth);

  dataSliceProduct.orgId = authUser.organization_id;
  const [progressBar, setProgressBar] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { users } = useSelector((state) => state.users);
  const { productsNew } = useSelector((state) => state.productsNew);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoice, setInvoice] = useState();
  const [physicalProducts, setPhysicalProducts] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const {
    contact_id,
    // invoices,
    //record,
    canAdd = true,
    canEdit = true,
    canDelete = true,
  } = props;
  const headCells = [
    { id: "selected", label: "", disableSorting: true, filter: false },
    { id: "type", label: "Type" },

    { id: "amount", label: "Amount" },
    { id: "details", label: "Details" },
    { id: "date", label: "Date" },
    { id: "attachment", label: "Attachment" },
  ];

  const { t, i18n } = useTranslation(["common", "records"]);
  const [invoicesPatient, setInvoicesPatient] = useState([invoices]);
  const [extraPayments, setExtraPayments] = useState(
    invoices.filter((invoice) => {
      return invoice.extra_payment == true;
    })
  );
  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const { paymentGateways } = useSelector((state) => state.paymentgateways);

  const saveExtraPayment = async (data) => {
    const invoiceitems = data.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        item_name: item.name,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });
    const momentDate = new moment(data.payment_date);
    data.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");

    if (!isEmpty(authUser)) {
      var payment = {
        amount: data.amount,
        details: data.details,
        file: data.file,
        payment_date: data.payment_date,
        payment_type: data.payment_type,
      };

      let formData = new FormData();
      Object.keys(payment).forEach((key) => {
        //  console.log(key, payment[key]);
        formData.append(key, payment[key]);
      });

      formData.append("items", JSON.stringify(invoiceitems));
      formData.append("contact_id", contact_id);
      formData.append("user_id", authUser.id);
      formData.append("organization_id", authUser.organization_id);
      formData.append("extra_payment", 1);
      formData.append("status", "paid");
    
      createExtraPayment(formData).then(() => {
        setNotify({
          isOpen: true,
          message: "Create Successfully",
          type: "success",
        });
      });
    }
  };

  const createInvoice = async (payload) => {
    const { data } = await Api.saveInvoice(payload);
    
  };

  const createExtraPayment = async (payload) => {
    const { data } = await Api.saveInvoiceExtraPayment(payload);

    setInvoices([data.data, ...invoices]);
  };

  const openItemInvoice = (invoiceitem) => {
    setInvoice(invoiceitem);
    setOpenInvoice(true);
  };
  const closeInvoice = () => {
    setOpenInvoice(false);
  };

  const opendocument = (url) => {
    window.open(url);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(extraPayments, headCells, filterFn);

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onAdvanceFilter = () => {
    console.log("filter");
  };

  const onMassiveDelete = async () => {
    console.log("delete");
  };

  useEffect(() => {
    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));
    if (isEmpty(productsNew)) {
      dispatch(fetchProductsService(dataSliceProduct));
    }

    if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));
  }, []);

  useEffect(() => {
    setExtraPayments(
      invoices.filter((invoice) => {
        return invoice.extra_payment == true;
      })
    );
  }, [invoices]);

  useEffect(() => {
    setPhysicalProducts(
      productsNew.filter((product) => {
        return product.type == "physical";
      })
    );
  }, [productsNew]);

  const saveEPayment = (data) => {
    saveExtraPayment(data);
    onClose();
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact_id) {
      setProgressBar(true);
      getInvoices().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const getInvoices = async () => {
    const { data } = await Api.getInvoicesContacts(contact_id);
    setInvoices([...data.data]);
  };

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        {canAdd && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Fab
              style={{ marginTop: 7, marginRight: "38px" }}
              color="primary"
              aria-label={t("common:general.ExtraPayment")}
              size="small"
              onClick={() => {
                setOpenPopup(true);
              }}
              className={classes.newButton}
            >
              <Iconify icon={"fluent:add-12-filled"} width={25} height={25} />
            </Fab>
          </div>
        )}
        {/* <EnhancedTableToolbar
          label={t("common:general.ExtraPayment")}
          numSelected={selected.length}
          advanceFilter={onAdvanceFilter}
          onMassiveDelete={onMassiveDelete}
        /> */}
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => {
              const isItemSelected = isSelected(item.id);
              return (
                <TableRow
                  key={item.id}
                  role="checkbox"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    openItemInvoice(item);
                  }}
                >
                  <TableCell padding="checkbox" align="left">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleClick(event, item.id);
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.payments.data[0].payment_type}</TableCell>

                  <TableCell>{item.payments.data[0].amount}</TableCell>
                  <TableCell>{item.payments.data[0].details}</TableCell>
                  <TableCell>{item.payments.data[0].date}</TableCell>

                  <TableCell>
                    {!isEmpty(item.payments.data[0].document.data) && (
                      <a
                        href={""}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          opendocument(item.payments.data[0].document.data.url);
                        }}
                      >
                        <AttachFileIcon />
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>

        {progressBar && (
          <div>
            <div style={{ padding: 10, textAlign: "center" }}>
              No records to display
            </div>
            <LinearProgress />
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title={t("common:general.ExtraPayment")}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <ExtraPaymentForm
          invoice={invoice}
          saveExtraPayment={saveEPayment}
          gateways={paymentGateways}
          products={physicalProducts}
          users={users}
          contactID={contact_id}
        />
      </Popup>

      <Popup
        fullScreen
        title={t("common:general.Invoices")}
        fullWidth={true}
        openPopup={openInvoice}
        onClose={closeInvoice}
      >
        <InvoiceDetails invoice={invoice} />
      </Popup>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default ExtraPaymentsPatients;
