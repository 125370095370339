import { createSlice } from "@reduxjs/toolkit";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  paymentReports: [],
};

const paymentReportsSlice = createSlice({
  name: "paymentReports",
  initialState,
  reducers: {
    getPaymentReports: (state) => {
      state.loading = true;
    },
    getPaymentReportsSuccess: (state, { payload }) => {
      state.paymentReports = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getPaymentReportsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getPaymentReports,
  getPaymentReportsSuccess,
  getPaymentReportsFailure,
} = paymentReportsSlice.actions;

export const paymentReportsSelector = (state) => state.reports;
export default paymentReportsSlice.reducer;

export function fetchPaymentReportsService(orgId) {
  return async (dispatch) => {
    dispatch(getPaymentReports());

    try {
      const response = await Api.getPaymentReport(orgId);
      dispatch(getPaymentReportsSuccess(response.data));
    } catch (error) {
      dispatch(getPaymentReportsFailure(error));
    }
  };
}
