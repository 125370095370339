import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  root2: {
    display: "block",
  },
}));

export default function CheckboxesGroup(props) {
  const classes = useStyles();
  const { checkboxes, onChange, label, value, defaultValue, disabled } = props;
  const [defaultValuelocal, setDefaultValuelocal] = useState(defaultValue);

  const isChecked = (item) => {
    if (defaultValuelocal) {
      return defaultValuelocal.find((value) => value == item.value)
        ? true
        : false;
    }
  };
  const updateValues = (e) => {
    if (!e.target.checked) {
      let newValue = defaultValuelocal.filter(
        (value) => value != e.target.name
      );
      setDefaultValuelocal(newValue);
      onChange(newValue);
    } else {
      let newValue = [...defaultValuelocal, e.target.name];
      setDefaultValuelocal(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{label} </FormLabel>
        <FormGroup className={classes.root2}>
          {checkboxes.map((item) => (
            <FormControlLabel
              key={item.value}
              control={
                <Checkbox
                  disabled={disabled ? disabled : false}
                  checked={isChecked(item)}
                  onChange={updateValues}
                  name={item.value}
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}
