import { motion } from "framer-motion";
import { Button, Typography, makeStyles } from "@material-ui/core";
import Iconify from "./Iconify";

const useStyles = makeStyles((theme) => ({
  refreshButton: {
    color: "#29425b",
    backgroundColor: "#beccd9",
    "&:hover": {
      background: "#a0b2c3",
    },
  },
  okButton: {
    color: "#34516d",
    border: "1px solid #7089a1",
    "&:hover": {
      background: "#d8dde1",
    },
  },
}));

export default function SystemCustomNotification(props, { url }) {
  const classes = useStyles();
  const { message = null, setSystemCustomNotification,  systemCustomNotificationText= ' The system has been updated!'} = props;
  return (
    <>
      <div
        style={{
          zIndex: 999999,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#f7f7f7",
          borderRadius: 10,
          padding: 24,
        }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <Iconify
              style={{ width: "48px", height: "48px", color: "#4d6181" }}
              icon="basil:notification-on-solid"
            />
            <Typography
              sx={{ whiteSpace: "pre-line" }}
              style={{ fontSize: "18px", fontWeight: 500 }}
            >
              {systemCustomNotificationText}
            </Typography>
            <Typography
              variant="h1"
              sx={{ whiteSpace: "pre-line" }}
              style={{ fontSize: "16px", marginTop: 20, color: "#4d6181" }}
            >
              *Please review the message carefully.
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: 18,
            }}
          >
            <Button
              className={classes.okButton}
              variant="outlined"
              onClick={() => {
                if (typeof setSystemCustomNotification === 'function') {
                  setSystemCustomNotification(false);
                }
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
