import React, { useEffect, useState, useMemo, useRef } from "react";

import {
  Card as MuiCard,
  makeStyles,
  Backdrop,
  Typography,
  CircularProgress,
  Paper,
  Chip,
  Fab,
} from "@material-ui/core";
import Api from "../lib/api";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import PopupTicket from "./PopupTicket";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import { isEmpty, findIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TicketForm from "./forms/TicketForm";
import TicketFormResolve from "./forms/TicketFormResolve";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import Controls from "./controls";
import { fetchBuildingsService } from "../redux/slices/buildings";
import {
  fetchContactEntitiesService as fetchContactTicketsService,
  createEntityService as createTicketsService,
  updateEntityService as updateTicketsService,
  deleteEntityService as deleteTicketsService,
  updateOnlyEntityService as updateOnlyTiketService,
} from "../redux/slices/ticketsSlice.js";

import Iconify from "./Iconify.js";

import { orange, green, blue, red, yellow } from "@material-ui/core/colors";
import { fetchEntitiesService as fetchTicketPrioritiesService } from "../redux/slices/ticketPrioritiesSlice.js";

import { fetchEntitiesService as fetchDepartmentsNewService } from "../redux/slices/departmentsNewSlice.js";

var dataSliceDepartmentsNew = {
  stateListName: "departmentsNew",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
import { fetchGroupsService } from "../redux/slices/groups";
import { fetchEntitiesService as fetchTicketCategoriesService } from "../redux/slices/ticketCategoriesSlice.js";
import MaterialTable from "material-table";
import { async } from "@firebase/util";
import CRMUtils from "../utils";

var dataSliceTicketCategories = {
  stateListName: "ticketCategories",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: null,
    page: null,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
var dataSliceTicketPriorities = {
  stateListName: "ticketPriorities",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: null,
    page: null,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const statusArray = [
  {
    key: "new",
    label: "New",
    color: yellow[50],
  },
  {
    key: "in_progress",
    label: "In progress",
    color: blue[50],
  },
  {
    key: "completed",
    label: "Completed",
    color: orange[50],
  },
  {
    key: "resolved",
    label: "Resolved",
    color: green[50],
  },
  {
    key: "canceled",
    label: "Canceled",
    color: red[50],
  },
];

var dataSliceTickets = {
  stateListName: "tickets",
  dataUrl: {},
  orgId: 1,
  contact_id: null,

  getFilters: {
    include: [],
    limit: 50,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
var confirmDialogLoading = false;

function TicketsContact(props) {
  const { authUser } = useSelector((state) => state.auth);

  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);
  dataSliceDepartmentsNew.orgId = authUser.organization_id;
  const {
    // tickets,
    contact_id,
    canAdd = true,
    canEdit = true,
    canDelete = true,
    userTeamLeader = false,
    contactEditable = false,
    consultantErrorHandle= null,
  } = props;

  dataSliceTickets.orgId = authUser.organization_id;
  dataSliceTickets.contact_id = contact_id;
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation(["common", "tickets"]);
  const { ticketCategories } = useSelector((state) => state.ticketCategories);
  const { ticketPriorities } = useSelector((state) => state.ticketPriorities);
  const [progressBar, setProgressBar] = useState(false);

  const [contactTickets, setContactTickets] = useState([]);
  const [load, setLoad] = useState(false);

  const headCellsMaterial = [
    { title: "Subject", field: "subject" },
    {
      field: "created_at",
      title: "Created",
      render: (rowData) => {
        return (
          <div className={classes.wrapText}>
            {" "}
            {moment(rowData.created_at).format("YYYY-MM-DD hh:mm:ss A")}
          </div>
        );
      },
    },
    {
      title: "Priority",
      field: "priority_id",
      render: (rowData) => {
        return rowData.priority_id ? (
          <Chip
            label={rowData.priority.data.name}
            style={{ background: rowData.priority.data.color }}
          />
        ) : (
          ""
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.priority.data.name.toLowerCase().indexOf(term.toLowerCase()) !=
        -1,
    },

    {
      title: "Category",
      field: "category_id",
      render: (rowData) => {
        return rowData.priority_id ? (
          <Chip
            label={rowData.category.data.name}
            style={{ background: rowData.category.data.color }}
          />
        ) : (
          ""
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.category.data.name.toLowerCase().indexOf(term.toLowerCase()) !=
        -1,
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return getStatus(rowData.status).label;
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.status.toLowerCase().indexOf(term.toLowerCase()) != -1,
    },
  ];

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  /*const { tickets, loading, ticketsPagination, ticketsLoading, errors } =
    useSelector((state) => state.tickets);*/
  const [entityForEdit, setEntityForEdit] = useState(null);

  const useStyles = makeStyles((theme) => ({
    headerIcon: {
      color: theme.palette.primary.main,
    },
    wrapText: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "100px",
    },
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
    },
    Toolbar: {
      justifyContent: "space-between",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visibilityIcon: {
      position: "absolute",
      zIndex: 10,
      top: "60px",
      left: "140px",
    },
    totalValue: {
      position: "absolute",
      zIndex: 10,
      top: "75px",
      left: "190px",
    },
    printButton: {
      position: "absolute",
      zIndex: 10,
      top: "260px",
      right: "350px",
    },
  }));
  // const [contactTickets, setContactTickets] = useState(false);
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEdit, setOpenPopupEdit] = useState(false);
  const { usersDropdown } = useSelector((state) => state.users);
  const { buildings } = useSelector((state) => state.buildings);
  const getStatus = (status) => {
    const index = findIndex(statusArray, ["key", status]);
    if (index) {
      return statusArray[index];
    }
    return statusArray[0];
  };
  const { departmentsNew } = useSelector((state) => state.departmentsNew);
  const { groups } = useSelector((state) => state.groups);

  const onClose = () => {
    setOpenPopup(false);
  };

  const onCloseEdit = () => {
    setOpenPopupEdit(false);
  };

  const addOrEdit = async (data) => {
    let formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    if (data.file === null) {
      formData.delete("file");
    }
    if (data.contact_id === null) {
      formData.delete("contact_id");
    }
    formData.delete("users_notifications");

    if (entityForEdit) {
      dataSliceTickets.dataUpdate = {
        id: entityForEdit.id,
        data: formData,
        successHandle: addOrEditSuccefull,
      };
      updateTicket(formData).then(function () {
        addOrEditSuccefull();
      });
    } else {
      if (!isEmpty(authUser)) {
        formData.append("organization_id", authUser.organization_id);
        // dataSliceTickets.dataCreate.data = formData;
        // dataSliceTickets.dataCreate.successHandle = addOrEditSuccefull;
        // dispatch(createTicketsService(dataSliceTickets));
        createTicket(formData).then(function () {
          addOrEditSuccefull();
        });

        // setContactTickets([dt,...contactTickets]);
      }
    }
  };

  const addOrEditSuccefull = () => {
    showMessage(setNotify, "Submitted Successfully");
    setEntityForEdit(null);
    setOpenPopup(false);
    setOpenPopupEdit(false);
  };

  const showMessage = (notifyObj, msg, type = "success") => {
    notifyObj({
      isOpen: true,
      message: msg,
      type: type,
    });
  };
  /*
  const genericErrorHandle = () => {
    console.log("Errors ", errors);
    if (errors && errors.length > 0) {
      let error = errors[errors.length - 1];
      showMessage(setNotify, error, "error");
    }
  };
  dataSliceTickets.errorHandle = genericErrorHandle;*/

  const onClickEdit = async (event, rowData) => {
    try {
    const { data } = await Api.getTicket(rowData.id);

    setEntityForEdit(data.data);
    setOpenPopupEdit(true);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const onClickAdd = (event) => {
    setOpenPopup(true);
    setEntityForEdit(null);
  };

  const updateTicketComment = (data) => {
    dispatch(updateOnlyTiketService(data));
  };

  const updateTicket = async (payload) => {
    try {
    const { data } = await Api.updateTicket(entityForEdit.id, payload);
    const index = findIndex(contactTickets, ["id", data.data.id]);
    const entity = { ...contactTickets[index], ...data.data };
    let entities = [...contactTickets];
    entities[index] = entity;

    console.log(entities);
    setContactTickets([...entities]);
  } catch (e) {
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(e.data.message);
    }
  }
  };

  const createTicket = async (payload) => {
    try {
    const { data } = await Api.createTicket(payload);
    setContactTickets([data.data, ...contactTickets]);
  } catch (e) {
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(e.data.message);
    }
  }
  };

  useEffect(() => {
    const orgId = authUser.organization_id;

    if (isEmpty(usersDropdown))
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));

    if (isEmpty(departmentsNew))
      dispatch(fetchDepartmentsNewService(dataSliceDepartmentsNew));

    if (isEmpty(groups)) dispatch(fetchGroupsService(orgId));

    if (isEmpty(ticketCategories))
      dispatch(fetchTicketCategoriesService(dataSliceTicketCategories));

    if (isEmpty(ticketPriorities))
      dispatch(fetchTicketPrioritiesService(dataSliceTicketPriorities));

    if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));
  }, []);

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact_id) {
      setProgressBar(true);
      ticketsFunc().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact_id]);

  const ticketsFunc = async () => {
    try {
    const { data } = await Api.getContactTickets(contact_id);
    setContactTickets(data.data);
  } catch (e) {
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(e.data.message);
    }
  }
  };

  /*
    const saveTicket = (data) => {
      const invoiceitems = data.items.map((item) => {
        return {
          ...item,
          subject: item.subject ? item.subject: "",
          created_at: item.created_at,
          priority_id: item.prority_id,
          category_id: item.category_id ? item.category_id: "",
          status: item.status ? item.status: "",
        };
      });
  
      if (!isEmpty(authUser)) {
        var payload = {
          items: invoiceitems,
          name: `Invoice for ${data.contact ?  rowData.contact.data.fname + " "+ rowData.contact.data.lname: ""}`,
          contact_id: data.contact.id,
          user_id: authUser.id,
          organization_id: authUser.organization_id,
          financing_id: data.financing_id,
          building_id:
            authUser.organization.data.multiple_locations === 1 &&
            authUser.building_id
              ? authUser.building_id
              : null,
        };
        console.log("save invoice... ", payload);
        dispatch(createTicketsService(payload)).then((result) => {
          console.log("createInvoiceService ", result);
        });
    
      }
    };

*/

  useEffect(() => {
    if (contactTickets) {
      //  console.log("TICKETS", contactTickets);
    }
  }, [contactTickets]);

  //const dataMaterialTable = [];
  const dataMaterialTable = contactTickets
    ? contactTickets.map((o) => ({ ...o }))
    : [];

  return (
    <>
      <Paper className={classes.pageContent}>
        <MaterialTable
          isLoading={progressBar}
          columns={headCellsMaterial}
          data={dataMaterialTable}
          onRowClick={(event, rowData) =>
            /*openInPopup(rowData)*/ console.log("User Can't Edit")
          }
          actions={[
            {
              icon: () => (
                <Fab color="primary" aria-label="add">
                  <Iconify
                    icon={"fluent:add-12-filled"}
                    width={25}
                    height={25}
                  />
                </Fab>
              ),
              tooltip: "Add Invoice",
              disabled:
                !canAdd ||
                userCanViewEditContactMark /* || (userTeamLeader && !contactEditable)*/,
              hidden:
                !canAdd ||
                userCanViewEditContactMark /*  || (userTeamLeader && !contactEditable)*/,
              isFreeAction: true,
              onClick: (event) => {
                onClickAdd(event);
              },
            },
            {
              icon: () => (
                <Controls.IconButton
                  style={{
                    color:
                      !canEdit || userCanViewEditContactMark
                        ? "#bdbdbd"
                        : "#009688",
                  }}
                >
                  <Iconify
                    icon={"fluent:edit-24-filled"}
                    width={20}
                    height={20}
                  />
                </Controls.IconButton>
              ),
              tooltip: "Edit",
              disabled:
                !canEdit ||
                userCanViewEditContactMark /*|| (userTeamLeader && !contactEditable)*/,
              hidden:
                !canEdit ||
                userCanViewEditContactMark /*|| (userTeamLeader && !contactEditable)*/,
              onClick: (event, rowData) => {
                onClickEdit(event, rowData);
              },
            },
          ]}
          options={{
            loadingType: "linear",
            pageSize: 10, // make initial page size
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            actionsColumnIndex: -1,
            exportButton: false,
          }}
          title="Tickets"
        />
      </Paper>
      {
        <PopupTicket
          fullWidth={true}
          title={"Add Ticket"}
          openPopup={openPopup}
          onClose={onClose}
        >
          <TicketForm
            ticket={entityForEdit}
            addOrEdit={addOrEdit}
            authUser={authUser}
            ticketCategories={ticketCategories}
            ticketPriorities={ticketPriorities}
            departments={departmentsNew}
            usersNotifications={usersDropdown}
            buildings={buildings}
            contact_id={contact_id}
          />
        </PopupTicket>
      }

      {
        <PopupTicket
          fullScreen
          title={"Edit Ticket"}
          openPopup={openPopupEdit}
          onClose={onCloseEdit}
        >
          <TicketFormResolve
            ticket={entityForEdit}
            addOrEdit={addOrEdit}
            authUser={authUser}
            ticketCategories={ticketCategories}
            ticketPriorities={ticketPriorities}
            ldepartments={departmentsNew}
            lusers={usersDropdown}
            lgroups={groups}
            updateTicketComment={updateTicketComment}
            buildings={buildings}
          />
        </PopupTicket>
      }

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />
    </>
  );
}

export default TicketsContact;
