import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";

export default function Checkbox(props) {
  const { label, checked, onChange,className=null, controlStyle={}, ...other } = props;

  return (
    <FormControl style ={controlStyle}>
      <FormControlLabel
        className={checked && className? className: ''}
        control={
          <MuiCheckbox
            checked={checked}
            color="primary"
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            {...other}
          />
        }
        label={label}
      />
    </FormControl>
  );
}
