import React from "react";

import { styled } from "@material-ui/core/styles";
import { compose, spacing, palette } from "@material-ui/system";
const Box = styled("div")(compose(spacing, palette));
import { blue } from "@material-ui/core/colors";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Controls from "./controls";
import CloseIcon from "@material-ui/icons/Close";

import { Grid, Avatar } from "@material-ui/core";

import AssignmentIcon from "@material-ui/icons/Assignment";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
  },
  rounded: {
    color: "#fff",
    backgroundColor: blue[500],
    float: "right",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function PopupTicket(props) {
  const {
    title,

    children,
    openPopup,
    onClose,
    isfullWidth,
    ...other
  } = props;
  const classes = useStyles();

  let maxWidth = isfullWidth == true ? false : "md";

  return (
    <Dialog
      open={openPopup}
      maxWidth={maxWidth}
      classes={{ paper: classes.dialogWrapper }}
      TransitionComponent={Transition}
      {...other}
    >
      <Box bgcolor={blue[50]}>
        <DialogTitle className={classes.dialogTitle}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Avatar
                  src={`/static/img/icons/tickets.png`}
                  variant="rounded"
                  className={classes.rounded}
                ></Avatar>
                <Grid
                  item
                  xs={10}
                  style={{
                    margin: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Controls.IconButton color="secondary" onClick={onClose}>
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>
      </Box>
      <DialogContent dividers={true}>{children}</DialogContent>
    </Dialog>
  );
}
