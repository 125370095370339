import { newSlice,_fetchEntitiesService,_loadEvents,_createEntityService,_updateEntityService,_deleteEntityService} from "../../../redux/slices/common/sliceFunctions";

var entitiesName = 'patientDataReports';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesServiceLogin(dataSlice) {
  dataSlice.dataUrl.code="POST_PATIENT_LOGIN";
  return _createEntityService(dataSlice,events);
}

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code="GET_PATIENT_REPORT";
  return _fetchEntitiesService(dataSlice,events);
}
