import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  invoices: [],
  fields: [],
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    getInvoices: (state) => {
      state.loading = true;
    },
    getInvoicesSuccess: (state, { payload }) => {
      state.invoices = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getInvoicesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createInvoice: (state) => {
      state.saveLoading = true;
    },
    createInvoiceSuccess: (state, { payload }) => {
      state.invoices = [...state.invoices, payload.data];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    createInvoiceFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    updateInvoice: (state) => {},
    updateInvoicesuccess: (state, { payload }) => {
      const index = findIndex(state.invoices, ["id", payload.data.id]);
      const invoice = { ...state.invoices[index], ...payload.data };

      let invoices = [...state.invoices];
      invoices[index] = invoice;

      state.invoices = [...invoices];
      state.loading = false;
      state.errors = [];
    },

    updateInvoiceFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    addInvoiceRefunds: (state) => {},

    addInvoiceRefundsSuccess: (state, { payload }) => {
      const index = findIndex(state.invoices, ["id", payload.invoice_id]);

      const invoice = {
        ...state.invoices[index],
        refunds: {
          data: [...state.invoices[index]["refunds"]["data"], payload],
        },
      };

      let invoices = [...state.invoices];
      invoices[index] = invoice;

      state.invoices = [...invoices];
      state.loading = false;
      state.errors = [];
    },

    addInvoiceRefundsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    addInvoiceCreditMemos: (state) => {},

    addInvoiceCreditMemosSuccess: (state, { payload }) => {
      const index = findIndex(state.invoices, ["id", payload.id]);

      let invoices = [...state.invoices];
      invoices[index] = payload;

      state.invoices = [...invoices];
      state.loading = false;
      state.errors = [];
    },

    addInvoiceCreditMemosFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    addInvoicePayments: (state) => {},

    addInvoicePaymentsSuccess: (state, { payload }) => {
      const index = findIndex(state.invoices, ["id", payload.invoice]);
      if (index >= 0) {
        const invoice = {
          ...state.invoices[index],
          status: payload.invoice_status,
          payments: {
            data: [...state.invoices[index]["payments"]["data"], payload],
          },
        };

        let invoices = [...state.invoices];
        invoices[index] = invoice;

        state.invoices = [...invoices];
        state.loading = false;
        state.errors = [];
      }
    },

    addInvoicePaymentsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    editInvoicePayments: (state) => {},

    editInvoicePaymentsSuccess: (state, { payload }) => {
      /*const index = findIndex(state.invoices, ["id", payload.invoice]);

      const invoice = {
        ...state.invoices[index],
        status: payload.invoice_status,
        payments: {
          data: [...state.invoices[index]["payments"]["data"], payload],
        },
      };

      let invoices = [...state.invoices];
      invoices[index] = invoice;

      state.invoices = [...invoices];
      state.loading = false;
      state.errors = [];*/
    },

    editInvoicePaymentsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    deleteInvoice: (state) => {},
    deleteInvoiceSuccess: (state, { payload }) => {
      state.invoices = state.invoices.filter((invoice) => {
        return invoice.id !== payload;
      });
    },
    deleteInvoiceFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    getCustomFields: (state) => {
      //state.loading = true;
    },
    getCustomFieldsSuccess: (state, { payload }) => {
      state.fields = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCustomFieldsFailure: (state, { payload }) => {
      state.loading = false;
      state.fields = [];
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getInvoices,
  getInvoicesSuccess,
  getInvoicesFailure,
  createInvoice,
  createInvoiceSuccess,
  createInvoiceFailure,
  updateInvoice,
  updateInvoicesuccess,
  addInvoiceRefunds,
  addInvoiceRefundsSuccess,
  addInvoiceRefundsFailure,
  addInvoiceCreditMemos,
  addInvoiceCreditMemosSuccess,
  addInvoiceCreditMemosFailure,
  addInvoicePayments,
  addInvoicePaymentsSuccess,
  addInvoicePaymentsFailure,
  editInvoicePayments,
  editInvoicePaymentsSuccess,
  editInvoicePaymentsFailure,
  updateInvoiceFailure,
  deleteInvoice,
  deleteInvoiceSuccess,
  deleteInvoiceFailure,
  getCustomFields,
  getCustomFieldsSuccess,
  getCustomFieldsFailure,
} = invoicesSlice.actions;
export const invoicesSelector = (state) => state.invoices;
export default invoicesSlice.reducer;

export function fetchInvoicesService(orgId) {
  return async (dispatch) => {
    dispatch(getInvoices());

    try {
      const response = await Api.getInvoices(orgId);

      dispatch(getInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(getInvoicesFailure(error));
    }
  };
}

export function createInvoiceService(payload) {
  return async (dispatch) => {
    dispatch(createInvoice());

    try {
      const response = await Api.saveInvoice(payload);

      dispatch(createInvoiceSuccess(response.data));
    } catch (error) {
      dispatch(createInvoiceFailure(error));
    }
  };
}

export function createInvoiceExtraPaymentService(payload) {
  return async (dispatch) => {
    dispatch(createInvoice());

    try {
      const response = await Api.saveInvoiceExtraPayment(payload);

      dispatch(createInvoiceSuccess(response.data));
    } catch (error) {
      dispatch(createInvoiceFailure(error));
    }
  };
}

export function updateInvoiceService(id, payload) {
  return async (dispatch) => {
    dispatch(updateInvoice());

    try {
      const response = await Api.updateInvoice(id, payload);

      dispatch(updateInvoicesuccess(response.data));
    } catch (error) {
      dispatch(updateInvoiceFailure(error));
    }
  };
}
export function addInvoicePaymentsService(payload) {
  return async (dispatch) => {
    dispatch(addInvoicePayments());

    try {
      dispatch(addInvoicePaymentsSuccess(payload));
    } catch (error) {
      dispatch(addInvoicePaymentsFailure(error));
    }
  };
}

export function editInvoicePaymentsService(payload) {
  return async (dispatch) => {
    dispatch(editInvoicePayments());

    try {
      dispatch(editInvoicePaymentsSuccess(payload));
    } catch (error) {
      dispatch(editInvoicePaymentsFailure(error));
    }
  };
}

export function addInvoiceRefundsService(payload) {
  return async (dispatch) => {
    dispatch(addInvoiceRefunds());

    try {
      dispatch(addInvoiceRefundsSuccess(payload));
    } catch (error) {
      dispatch(addInvoiceRefundsFailure(error));
    }
  };
}

export function applyInvoiceCreditMemoService(payload) {
  return async (dispatch) => {
    try {
      dispatch(addInvoiceCreditMemos());

      dispatch(addInvoiceCreditMemosSuccess(payload));
    } catch (error) {
      dispatch(addInvoiceCreditMemosFailure(error));
    }
  };
}

export function deleteInvoiceService(id) {
  return async (dispatch) => {
    dispatch(deleteInvoice());

    try {
      const response = await Api.deleteInvoice(id);

      dispatch(deleteInvoiceSuccess(id));
    } catch (error) {
      dispatch(deleteInvoiceFailure(error));
    }
  };
}
export function fetchInvoicesByContactService(contactId) {
  return async (dispatch) => {
    dispatch(getInvoices());

    try {
      const response = await Api.getContactInvoices(contactId);

      dispatch(getInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(getInvoicesFailure(error));
    }
  };
}
