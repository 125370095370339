import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  List,
  ListItemText,
  ListItem,
  Divider,
  Card,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { isEmpty } from "lodash";
import BMI from "../BMI";
import Api from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { debounce } from "lodash";
import Notification from "../../components/Notification";
import ProcedurePopUp from "../../components/Popup";
//import PrintPatientInformation from "../../components/print/PrintPatientInformation";

import CRMUtils from "../../utils";

const googleMapAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
  cardPlaces: {
    backgroundColor: "white",
    zIndex: "999999",
    position: "fixed",
    boxShadow: "rgba(53, 64, 82, 0.25) 0px 0px 14px 0px",
  },
  error: {
    color: "red",
    fontWeight: "700",
    fontSize: "1.1em",
    marginLeft: 5,
  },
}));

export default function RecordForm(props) {
  const { authUser } = useSelector((state) => state.auth);
  const {
    addOrEdit,
    recordForEdit,
    customFields,
    userCanEdit = true,
    rReadonly = false,
    errorsFromRecord = null,
    proceduresDropdown,
  } = props;

  const userCanEditRecordProfile = CRMUtils.userHasPermission(authUser, [
    "record_editProfile",
  ]);

  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const [completeAddress, setCompleteAddress] = useState(null);
  const [selectProcedurePopUp, setSelectProcedurePopUp] = useState(false);
  const watchWeight = watch("weight");
  const watchHeight = watch("height");
  const [bmi, setBmi] = useState(null);
  const printRef = useRef();
  const { t, i18n } = useTranslation(["common", "records"]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [selectedProcedureDate, setSelectedProcedureDate] = useState(null);
  const selectedProcedureItems = useRef([]);
  const classes = useStyles();
  const [parsedProceduresNames, setParsedProceduresNames] = useState(null);
  const [parsedProceduresDoctors, setParsedProceduresDoctors] = useState(null);
  const [propertiesToPrint, setPropertiesToPrint] = useState(null);
  const [isPrintReady, setIsPrintReady] = useState(false);

  const onSubmit = (data) => {
    const recordData = formatRecordData(data);

    addOrEdit(recordData);
    //reset();
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [blockSave, setBlockSave] = useState(false);

  const [states, setStates] = useState([]);
  const genderItems = [
    { id: "M", title: t("common:general.Male") },
    { id: "F", title: t("common:general.Female") },
  ];

  const sItems = [
    { id: "N", title: t("common:general.No") },
    { id: "Y", title: t("common:general.Yes") },
  ];
  const formatRecordData = (data) => {
    let recordData = {};
    let recordProperties = {};
    Object.keys(data).forEach((key) => {
      const xk = key.slice(0, 3);
      if (xk !== "cf_") {
        recordData[key] = data[key];
      } else {
        const fixedKey = key.slice(3, key.length);
        recordProperties[fixedKey] = data[key];
      }
    });
    recordData.properties = JSON.stringify(recordProperties);
    if (bmi) {
      recordData.bmi = bmi;
    }

    return recordData;
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleMapAPI,
    options: {
      types: ["address"],
      /*componentRestrictions: {
        country: ["us", "ca", "mx", "pr", "bs", "ht", "jm", "es", "gb"],
      },*/
    },
    language: "en-US",
  });
  const [placePredictionsLocal, setPlacePredictionsLocal] = useState([]);
  const [valueP, setValueP] = useState("");
  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1500),
    []
  );

  const handleProcedureSelection = (event) => {
    setParsedProceduresNames(null);
    setIsPrintReady(false);
    selectedProcedureItems.current = null;
    // console.log("procedure correct ", event.target.value);
    setSelectedProcedure(event.target.value);
    setSelectedProcedureDate(event.target.value.date);
    return;
  };

  const setSearch = (search) => {
    getPlacePredictions({ input: search });
    setValueP(search);
  };

  const getFieldSource = (source) => {
    const list = source ? JSON.parse(source) : [];
    return list.map((value) => {
      return {
        id: value.id,
        title: value.value,
      };
    });
  };

  const renderCustomField = (field) => {
    let defaultValue = "";
    if (!isEmpty(recordForEdit) && !isEmpty(recordForEdit.properties)) {
      const properties = JSON.parse(recordForEdit.properties);
      defaultValue = properties[field.key];
    }

    //const { t, i18n } = useTranslation(["common","records"]);

    switch (field.type) {
      case "string":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.Input
                  label={field.title}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "yes/no":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.RadioGroup
                  label={field.title}
                  items={[
                    {
                      id: "yes",
                      title: t("common:general.Yes"),
                    },
                    {
                      id: "no",
                      title: t("common:general.No"),
                    },
                  ]}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "money":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.InputMoney
                  label={field.title}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "date/time":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.DateTimePicker
                  label={field.title}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "relation":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.SelectModel
                  label={field.title}
                  modelid={field.source_model_id}
                  onChange={(value) => onChange(value)}
                  value={value}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              )}
              rules={{ required: "field " + t("common:general.Required") }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "date":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.DatePicker
                  label={field.title}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      case "list":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.Select
                  label={field.title}
                  options={getFieldSource(field.source)}
                  onChange={(value) => onChange(value)}
                  value={value}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              )}
              rules={{ required: "Department " + t("common:general.Required") }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
        break;
      default:
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.Input
                  label={field.title}
                  disabled={
                    userCanEditRecordProfile ? false : !userCanEdit || rReadonly
                  }
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className="error">
                {`${field.title} ` + t("common:general.Required")}
              </p>
            )}
          </>
        );
    }
  };

  const getCountries = async (id) => {
    const { data } = await Api.getCountries(id);
    setCountries(data.data);
  };

  const getStates = async (id, country) => {
    const { data } = await Api.getStates(id, country);
    setStates(data.data);
  };
  const getCities = async (id, country, state) => {
    const { data } = await Api.getCities(id, country, state);
    setCities(data.data);
  };

  const countryOptions = () => {
    return countries.map((country) => {
      return {
        id: country.id,
        title: country.name,
      };
    });
  };

  const stateOptions = () => {
    return states.map((state) => {
      return {
        id: state.id,
        title: state.name,
      };
    });
  };

  const cityOptions = () => {
    return cities.map((city) => {
      return {
        id: city.id,
        title: city.name,
      };
    });
  };

  const renderCustomFields = () => {
    return customFields.map((field) => {
      return <div key={field.key}>{renderCustomField(field)}</div>;
    });
  };

  useEffect(() => {
    if (recordForEdit && recordForEdit.properties) {
      const updatedData = {};
      const propertiesA = JSON.parse(recordForEdit.properties);
      Object.keys(propertiesA).forEach((key) => {
        updatedData[key.replace("-", "_")] = propertiesA[key];
      });
      console.log("toSettoSetB", updatedData);
      setPropertiesToPrint(updatedData);
    }
  }, [recordForEdit]);

  /* useEffect(() => {
    getCountries(authUser.organization_id);
  }, []);

  useEffect(() => {
    if (country) {
      getStates(authUser.organization_id, country);
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      getCities(authUser.organization_id, country, state);
    }
  }, [state]);*/

  useEffect(() => {
    if (proceduresDropdown.length && proceduresDropdown[0]) {
      // console.log(
      //   "getting in change procedure useefect codition, setting it to  ",
      //   proceduresDropdown[0]
      // );
      setSelectedProcedure(proceduresDropdown[0].id);
      setSelectedProcedureDate(
        proceduresDropdown[0].date ? proceduresDropdown[0].date : ""
      );
    }
  }, [proceduresDropdown]);

  useEffect(() => {
    if (!isEmpty(placePredictions)) {
      setPlacePredictionsLocal(placePredictions);
    }
  }, [placePredictions]);

  useEffect(() => {
    try {
      if (completeAddress) {
        for (const component of completeAddress.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "postal_code": {
              setValue("zip", component.long_name, {
                shouldValidate: true,
              });
              break;
            }
            case "locality":
              setValue("city_field", component.long_name, {
                shouldValidate: true,
              });
              break;
            case "administrative_area_level_1": {
              setValue("state_field", component.long_name, {
                shouldValidate: true,
              });

              break;
            }
            case "country":
              setValue("country_field", component.long_name, {
                shouldValidate: true,
              });

              break;
          }
        }
        setValue("address", completeAddress.name, {
          shouldValidate: true,
        });

        setPlacePredictionsLocal([]);
      }
    } catch {
      console.error("completeAddress error");
    }
  }, [completeAddress]);

  const setAddressValues = (item) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => {
        setCompleteAddress(placeDetails);
      }
    );
  };

  useEffect(() => {
    if (recordForEdit.contact.data.country_id) {
      getStates(
        authUser.organization_id,
        recordForEdit.contact.data.country_id
      );
    }
    if (
      recordForEdit.contact.data.country_id &&
      recordForEdit.contact.data.state_id
    ) {
      getCities(
        authUser.organization_id,
        recordForEdit.contact.data.country_id,
        recordForEdit.contact.data.state_id
      );
    }
  }, [recordForEdit]);

  useEffect(() => {
    if (errorsFromRecord && errorsFromRecord.length > 0) {
      setBlockSave(true);
      setNotify({
        isOpen: true,
        message: errorsFromRecord[errorsFromRecord.length - 1],
        type: "error",
      });
      setTimeout(() => {
        setBlockSave(false);
      }, 25000);
    }
  }, [errorsFromRecord]);

  useEffect(() => {}, [proceduresDropdown]);

  useEffect(() => {
    if (parsedProceduresNames) {
      setIsPrintReady(true);
    } else {
      setIsPrintReady(false);
    }
  }, [parsedProceduresNames]);

  useEffect(() => {
    if (selectedProcedure) {
      let p_item = proceduresDropdown.filter(
        (p) => p.id == selectedProcedure
      )[0];

      if (p_item && p_item.date) {
        setSelectedProcedureDate(p_item.date);
        let filtered_procedures = proceduresDropdown.filter((ps) => {
          return ps.date?.split(" ")[0] === p_item.date?.split(" ")[0];
        });
        selectedProcedureItems.current = filtered_procedures;
        let procedures_names = "";

        if (selectedProcedureItems.current) {
          selectedProcedureItems.current.map((item, index) => {
            if (item.responsible_name && !parsedProceduresDoctors) {
              setParsedProceduresDoctors(item.responsible_name);
            }
            if (item.invoice_item_name) {
              procedures_names += item.invoice_item_name;
            }
            if (
              selectedProcedureItems.current.length !== 0 &&
              index !== selectedProcedureItems.current.length - 1
            ) {
              procedures_names += ", ";
            }
          });
        }
        setParsedProceduresNames(procedures_names);
      } else {
        let filtered_procedures = proceduresDropdown.filter((ps) => {
          return ps.id === selectedProcedure;
        });
        setParsedProceduresNames(filtered_procedures[0]?.invoice_item_name);
      }
    }
  }, [selectedProcedure]);

  const handleClosePrint = () => {
    setSelectProcedurePopUp(false);
    setSelectedProcedure(null);
    setSelectedProcedureDate(null);
    if (proceduresDropdown && proceduresDropdown[0]) {
      setSelectedProcedure(proceduresDropdown[0].id);
    }
    if (proceduresDropdown && proceduresDropdown[0]) {
      setSelectedProcedureDate(proceduresDropdown[0].date);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        {errorsFromRecord && (
          <p className={classes.error}>
            {" "}
            {errorsFromRecord[errorsFromRecord.length - 1]}{" "}
          </p>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="fname"
                  control={control}
                  defaultValue={recordForEdit?.fname || ""}
                  as={
                    <Controls.Input
                      label={t("common:general.Fname")}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="lname"
                  control={control}
                  defaultValue={recordForEdit?.lname || ""}
                  as={
                    <Controls.Input
                      label={t("common:general.Lname")}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={recordForEdit?.email || ""}
                  as={
                    <Controls.Input
                      label={t("common:general.Email")}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />

                {/* {errorsFromRecord &&
                  errorsFromRecord.length > 0 &&
                  // errorsFromRecord[errorsFromRecord.length - 1]?.indexOf(
                  //   "email"
                  // ) != -1 &&
                  blockSave && (
                    <p className={classes.error}>
                      {" "}
                      {errorsFromRecord[errorsFromRecord.length - 1]}{" "}
                    </p>
                  )} */}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={recordForEdit?.phone || ""}
                  as={
                    <Controls.Input
                      label={t("common:general.Phone")}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />

                {/* {errorsFromRecord &&
                  errorsFromRecord.length > 0 &&
                  // errorsFromRecord[errorsFromRecord.length - 1].indexOf(
                  //   "phone"
                  // ) != -1 &&
                  blockSave && (
                    <p className={classes.error}>
                      {" "}
                      {errorsFromRecord[errorsFromRecord.length - 1]}{" "}
                    </p>
                  )} */}
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="dob"
                  control={control}
                  defaultValue={
                    recordForEdit
                      ? recordForEdit.contact.data
                        ? recordForEdit.contact.data.dob
                        : ""
                      : ""
                  }
                  as={({ onChange, value }) => (
                    <Controls.DatePicker
                      label={t("common:general.DOB")}
                      onChange={(date) => onChange(date)}
                      value={value}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="chart_id"
                  control={control}
                  defaultValue={recordForEdit?.chart_id || ""}
                  as={
                    <Controls.Input
                      label={"CHART ID"}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>
              {/*<Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                defaultValue={recordForEdit?.contact.data.address || ""}
                as={
                  <Controls.Input label={"Address"} disabled={userCanEditRecordProfile || !userCanEdit || rReadonly} />
                }
              />
            </Grid>*/}
              <Grid item xs={4}>
                <Controller
                  name="weight"
                  control={control}
                  defaultValue={recordForEdit?.contact.data.weight || ""}
                  as={
                    <Controls.Input
                      label={"Weight - pounds"}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="height"
                  control={control}
                  defaultValue={recordForEdit?.contact.data.height || ""}
                  as={
                    <Controls.Input
                      label={"Height - ft.in"}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="hgb"
                  control={control}
                  defaultValue={recordForEdit?.hgb || ""}
                  as={
                    <Controls.Input
                      label={"HGB"}
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="medical_conditions"
                  control={control}
                  defaultValue={
                    recordForEdit?.contact.data.medical_conditions || ""
                  }
                  as={
                    <Controls.Textarea
                      label={"Medical Conditions"}
                      rows="4"
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="surgical_history"
                  control={control}
                  defaultValue={
                    recordForEdit?.contact.data.surgical_history || ""
                  }
                  as={
                    <Controls.Textarea
                      label={"Surgical History"}
                      rows="4"
                      disabled={
                        userCanEditRecordProfile
                          ? false
                          : !userCanEdit || rReadonly
                      }
                    />
                  }
                />
              </Grid>

              <Grid container spacing="4">
                <Grid item xs={8}>
                  <Controller
                    name="address"
                    control={control}
                    defaultValue={recordForEdit?.contact.data.address || ""}
                    as={({ onChange, value }) => (
                      <TextField
                        variant="outlined"
                        value={value}
                        onChange={(data) => {
                          debouncedSearch(data.target.value);
                          onChange(data.target.value);
                        }}
                        label="* Address"
                        disabled={
                          userCanEditRecordProfile
                            ? false
                            : !userCanEdit || rReadonly
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                  <Controller
                    name="apt"
                    control={control}
                    defaultValue={recordForEdit?.contact.data.apt || ""}
                    as={
                      <Controls.Input
                        label="APT"
                        disabled={
                          userCanEditRecordProfile
                            ? false
                            : !userCanEdit || rReadonly
                        }
                      />
                    }
                  />
                </Grid>
                {
                  <Grid item xs={12}>
                    {!isPlacePredictionsLoading &&
                    !isEmpty(placePredictionsLocal) ? (
                      <Card className={classes.cardPlaces}>
                        <Box m={1}>
                          <List>
                            <Divider component="li" />
                            {placePredictionsLocal.map((item, index) => {
                              return (
                                <React.Fragment key={item.id}>
                                  <ListItem
                                    role={undefined}
                                    dense
                                    button
                                    onClick={() => {
                                      setAddressValues(item);
                                    }}
                                  >
                                    <ListItemText
                                      id={item.index}
                                      primary={item.description}
                                    />
                                  </ListItem>
                                  <Divider component="li" />
                                </React.Fragment>
                              );
                            })}
                          </List>
                        </Box>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                }

                <Grid item xs={6}>
                  <Controller
                    name="city_field"
                    control={control}
                    disabled
                    defaultValue={recordForEdit?.contact.data.city || ""}
                    as={({ onChange, value }) => (
                      <TextField
                        variant="outlined"
                        value={value}
                        onChange={(data) => onChange(data.target.value)}
                        label="* City"
                        inputProps={{
                          maxLength: 5,
                        }}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="state_field"
                    control={control}
                    defaultValue={recordForEdit?.contact.data.state || ""}
                    as={({ onChange, value }) => (
                      <TextField
                        variant="outlined"
                        value={value}
                        onChange={(data) => onChange(data.target.value)}
                        label="* State"
                        inputProps={{
                          maxLength: 5,
                        }}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="country_field"
                    control={control}
                    defaultValue={recordForEdit?.contact.data.country || ""}
                    as={({ onChange, value }) => (
                      <TextField
                        variant="outlined"
                        value={value}
                        onChange={(data) => onChange(data.target.value)}
                        label="* Country"
                        inputProps={{
                          maxLength: 5,
                        }}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="zip"
                    control={control}
                    // type="number"
                    defaultValue={recordForEdit?.contact.data.zip || ""}
                    as={({ onChange, value }) => (
                      <TextField
                        variant="outlined"
                        value={value}
                        onChange={(data) => onChange(data.target.value)}
                        label="* Zipcode"
                        inputProps={{
                          maxLength: 5,
                        }}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={8}>
                {
                  <BMI
                    height={watchHeight}
                    weight={watchWeight}
                    setBmiform={setBmi}
                  />
                }
              </Grid>
            </Grid>

            <div>
              <Controls.Button
                type="submit"
                text="Save"
                disabled={
                  userCanEditRecordProfile
                    ? false
                    : !userCanEdit || rReadonly || blockSave
                }
              />
            </div>

            <div>
              <Controls.Button
                className={classes.printButton}
                color="primary"
                startIcon={<PrintIcon />}
                text="Print Patient Form"
                onClick={() => setSelectProcedurePopUp(true)}
              />
              <ProcedurePopUp
                title={"Select the Patient Procedure"}
                openPopup={selectProcedurePopUp}
                onClose={handleClosePrint}
                maxWidth="sm"
                fullWidth
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={9}>
                    <Controls.Select
                      label="Procedures"
                      options={proceduresDropdown.map((procedure) => {
                        return {
                          id: procedure.id,
                          title: procedure.invoice_item_name,
                        };
                      })}
                      value={selectedProcedure}
                      onChange={handleProcedureSelection}
                    />

                    {/* <FormControl
                      variant="outlined"                
                      style={{ width: "100%" }}
                      fullWidth
                    >
                      <InputLabel id="procedure-label">Procedure</InputLabel>
                      <Select
                        value={selectedProcedure}
                        onChange={handleProcedureSelection}
                        labelId="procedure-label"
                        id="procedure-select"
                     
                      >
                        {proceduresDropdown?.map((pr) => {
                          return (
                            <MenuItem value={pr.id}>
                              {pr.invoice_item_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                  </Grid>
                  <Grid item xs={3}>
                    <ReactToPrint
                      trigger={() => (
                        <Controls.Button
                          text="Confirm"
                          disabled={!isPrintReady}
                          style={{ marginLeft: 20, height: 30 }}
                        />
                      )}
                      content={() => printRef.current}
                      //onBeforePrint={async () => {
                      //}}
                      onAfterPrint={() => {
                        setSelectProcedurePopUp(false);
                        setParsedProceduresNames(null);
                        setParsedProceduresDoctors(null);
                        setSelectedProcedure(null);
                        setSelectedProcedureDate(null);
                        setIsPrintReady(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </ProcedurePopUp>
            </div>
          </Grid>
          <Grid item xs={6}>
            {renderCustomFields()}
          </Grid>
        </Grid>
      </form>
      {errorsFromRecord && (
        <Notification notify={notify} setNotify={setNotify} />
      )}
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          {parsedProceduresNames && (
            <Container style={{ padding: 30, width: 900 }}>
              <TextField
                id="full_name"
                label="Patient Name"
                defaultValue={recordForEdit.full_name}
                style={{ width: 600 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="dob"
                label="Date of Birth"
                defaultValue={
                  recordForEdit.dob
                    ? new Date(
                        recordForEdit.dob.replace("-", "/")
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    : recordForEdit.contact.data.dob
                    ? new Date(
                        recordForEdit.contact.data.dob.replace("-", "/")
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    : " "
                }
                style={{ width: 150, marginLeft: 20 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
              <br />
              <TextField
                id="doctors_name"
                label="Doctor's Name"
                defaultValue={
                  parsedProceduresDoctors ? parsedProceduresDoctors : " "
                }
                style={{ width: 600, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="dvt"
                label="DVT"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.dvt
                    ? propertiesToPrint.dvt
                    : " "
                }
                style={{ width: 150, marginLeft: 20, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
              <TextField
                id="procedure"
                label="Procedure"
                defaultValue={
                  parsedProceduresNames ? parsedProceduresNames : " "
                }
                style={{ width: 600, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="date_of_procedure"
                label="Date of Procedure"
                defaultValue={
                  selectedProcedureDate
                    ? new Date(selectedProcedureDate).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )
                    : " "
                }
                style={{ width: 150, marginLeft: 20, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />

              <TextField
                id="m_s_history"
                label="Past Medical/Surgical History"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.previous_surgeries
                    ? propertiesToPrint.previous_surgeries
                    : " "
                }
                style={{ width: 600, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="caprini"
                label="Caprini"
                defaultValue={" "}
                style={{ width: 150, marginLeft: 20, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
              <TextField
                id="allergies_reactions"
                label="Allergies/Reactions"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.allergies
                    ? propertiesToPrint.allergies
                    : " "
                }
                // defaultValue={
                //   recordForEdit?.properties?.allergies
                //     ? JSON.parse(recordForEdit.properties).allergies
                //     : " "
                // }
                style={{ width: 600, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="smoking"
                label="Smoking"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.smoke
                    ? propertiesToPrint.smoke
                    : " "
                }
                style={{ width: 65, marginLeft: 20, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
              <TextField
                id="drugs"
                label="Drugs"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.other_substances
                    ? propertiesToPrint.other_substances
                    : " "
                }
                style={{ width: 65, marginLeft: 21, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
              <TextField
                id="current_medications"
                label="Current Medications"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.medications
                    ? propertiesToPrint.medications
                    : " "
                }
                // defaultValue={
                //   recordForEdit?.properties?.medications
                //     ? JSON.parse(recordForEdit.properties).medications
                //     : " "
                // }
                style={{ width: 365, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="family_history"
                label="Family History"
                defaultValue={
                  propertiesToPrint && propertiesToPrint.family_history
                    ? propertiesToPrint.family_history
                    : " "
                }
                style={{ width: 365, marginLeft: 40, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <div style={{ display: "flex" }}>
                <div>
                  <Typography style={{ marginTop: 20 }}>
                    Pre-op Vitals:
                  </Typography>
                  <div
                    style={{
                      width: 200,
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    BP: _____________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    TEMP: __________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    HR: _____________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    RESP: __________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    HT: _____________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    WT: ____________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 70,
                      marginTop: 10,
                    }}
                  >
                    BMI: ___________________________
                  </div>
                </div>
                <div>
                  <Typography style={{ marginTop: 20, marginLeft: 300 }}>
                    WNL ABNL:
                  </Typography>
                  <div
                    style={{
                      marginLeft: 200,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    General Appearence: ____________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 200,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    HEENT: _________________________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 200,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    Heart: cardiac rythm, heart sounds: ____________
                  </div>
                  <div
                    style={{
                      marginLeft: 200,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    Lung sounds: __________________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 100,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    Comments:
                    _____________________________________________________
                  </div>
                  <div
                    style={{
                      marginLeft: 100,
                      marginTop: 10,
                      display: "flex",
                    }}
                  >
                    __________________________________________________________________
                  </div>
                </div>
              </div>
              <TextField
                id="notes"
                label="Notes"
                defaultValue={" "}
                style={{ width: 770, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <div
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Typography
                  align="center"
                  variant="overline"
                  style={{ fontSize: 14 }}
                >
                  TIME INMEDIATE H&P PERFORMED: AM/PM
                </Typography>
                <Divider
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 770,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 770,
                  }}
                >
                  <Typography style={{ marginTop: 10, fontSize: 17 }}>
                    ASA Clasification: __1 __2 __3
                  </Typography>
                  <Typography
                    style={{ marginTop: 10, fontSize: 17, marginLeft: 20 }}
                  >
                    Cleared for office based procedure with minimal risk: __YES
                    __NO
                  </Typography>
                </div>
              </div>
              <TextField
                id="final_comments"
                label="Comments"
                defaultValue=" "
                style={{ width: 770, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="physicians_signature"
                label="Physicians Signature"
                defaultValue=" "
                style={{ width: 600, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
              <TextField
                id="physicians_signature-date"
                label="Date"
                defaultValue={" "}
                style={{ width: 150, marginLeft: 20, marginTop: 10 }}
                inputProps={{
                  style: { fontSize: 14 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
            </Container>
          )}
        </div>
      </div>
    </>
  );
}
