import React, { useState, useRef } from "react";

import { grey } from "@material-ui/core/colors";
import moment from "moment";
import Popup from "./Popup";
import {
  Card,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText, 
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ImageIcon from "@material-ui/icons/Image";
import { saveAs } from "file-saver";
import Api from "../lib/api";
import ContactDetails from "../pages/contacts/details2";
import Iconify from "./Iconify";

const ChatRoomLeftItem = ({
  item,
  styleList,
  classes,
  fromUser = null,
  fromUserColor = null,
  saveToContactD = false,
  saveToContact,
  documentTypes,
  isPrinting,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);
  const contactOpenTab = useRef(0);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [videoToShow, setVideoToShow] = React.useState({
    tempUrl: "",
    file_name: "",
  });
  const [videoToShowOpenP, setVideoToShowOpenP] = React.useState(false);

  const videoToShowOpen = (tempUrl, file_name) => {
    setVideoToShow({
      tempUrl: tempUrl,
      file_name: file_name,
    });

    setVideoToShowOpenP(true);
  };

  const videoToShowClose = (tempUrl, file_name) => {
    setImageToShow({
      tempUrl: "",
      file_name: "",
    });

    setVideoToShowOpenP(false);
  };

  //const [localDocumentTypes, setLocalDocumentTypes] = React.useState(null);

  const [anchorElSaveAs, setAnchorElSaveAs] = React.useState(null);

  const handleClickSaveAs = (event) => {
    setAnchorElSaveAs(event.currentTarget);
    // console.error("handleClickSaveAs", event.currentTarget);
  };

  const handleCloseSaveAs = () => {
    setAnchorElSaveAs(null);
  };

  const [imageToDownload, setImageToDownload] = React.useState({
    tempUrl: "",
    file_name: "",
    media_id: "",
  });

  /* const saveToContactLocal = () => {
    saveToContact(imageToDownload.media_id);
  };*/

  const [imageToShow, setImageToShow] = React.useState({
    tempUrl: "",
    file_name: "",
  });
  const [imageToShowOpenP, setImageToShowOpenP] = React.useState(false);

  const imageToShowOpen = (tempUrl, file_name) => {
    setImageToShow({
      tempUrl: tempUrl,
      file_name: file_name,
    });

    setImageToShowOpenP(true);
  };

  const imageToShowClose = (tempUrl, file_name) => {
    setImageToShow({
      tempUrl: "",
      file_name: "",
    });

    setImageToShowOpenP(false);
  };

  const renderTypes = () => {
    return documentTypes.map((item) => {
      return (
        <MenuItem
          onClick={() => {
            saveToContact(imageToDownload.media_id, item.name);
            handleCloseSaveAs();
            handleClose();
          }}
        >
          {item.name}
        </MenuItem>
      );
    });
  };

  const openContact = async (id) => {
    const { data } = await Api.getContactPlain(id);

    setContactDetail(data.data);
    setOpenContactPopup(true);
  };

  const oncloseContact = () => {
    setOpenContactPopup(false);
    contactOpenTab = 0;
  };

  const downloadImage = () => {
    /*  var xhr = new XMLHttpRequest();
    xhr.open("get", `${imageToDownload.tempUrl}`, true);
    xhr.responseType = "blob";

    const link = document.createElement("a");
    link.href = imageToDownload.tempUrl;
    link.setAttribute("download", `${imageToDownload.file_name}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);*/

    saveAs(`${imageToDownload.tempUrl}`, `${imageToDownload.file_name}`);
  };

  return (
    <div style={Object.assign({}, styles.parentView, styleList)}>
      <Box m={3}>
        <Card style={styles.cardView} variant={"elevation"} elevation={0.9}>
          {fromUser && fromUserColor && (
            <Box
              style={{
                background:
                  "linear-gradient(15deg,rgba(var(--overlay-rgb),0),rgba(var(--overlay-rgb),0) 45%,rgba(var(--overlay-rgb),.12) 70%,rgba(var(--overlay-rgb),.33))",
              }}
            >
              <Typography
                style={{
                  color: `#${fromUserColor}`,
                }}
              >
                {fromUser ? fromUser : ""}
              </Typography>
            </Box>
          )}

          <Typography style={styles.userMessage}>
            {item && item.body ? item.body : ""}
          </Typography>
          {item &&
          item.tempUrl &&
          (item.type == "mms" || item.type == "whatsapp") ? (
            <div style={{ display: "flex", position: "relative" }}>
              {isPrinting ? (
                <ImageIcon
                  style={{
                    width: "auto",
                    height: "auto",
                    maxHeight: "150px",
                    maxWidth: "150px",
                  }}
                />
              ) : item.mediaType && item.mediaType == "video/mp4" ? (
                <Button
                  onClick={(event) => {
                    videoToShowOpen(item.tempUrl, item.file_name);
                  }}
                >
                  <video
                    style={{
                      width: "auto",
                      height: "auto",
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                    controls
                  >
                    <source src={item.tempUrl} type="video/mp4"></source>
                  </video>
                </Button>
              ) : (
                <div>
                  <Button
                    onClick={(event) => {
                      imageToShowOpen(item.tempUrl, item.file_name);
                    }}
                  >
                    <img
                      src={item.tempUrl}
                      alt={"Attach"}
                      style={{
                        width: "auto",
                        height: "auto",
                        maxHeight: "150px",
                        maxWidth: "150px",
                      }}
                    />
                  </Button>
                  <IconButton
                    aria-label="More"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setImageToDownload({
                        tempUrl: item.tempUrl,
                        file_name: item.file_name,
                        media_id: item.media_id,
                      });
                    }}
                    // onFocus={onFocusHandle}
                    //   onBlur={onFocusoutHandle}
                    className={
                      !Boolean(anchorEl) ? classes.more : classes.moreMenu
                    }
                    boxShadow={3}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    TransitionComponent={Fade}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        downloadImage();
                        handleClose();
                      }}
                    >
                      Download
                    </MenuItem>
                    <MenuItem
                      /*  onClick={(event) => {
                    handleClickSaveAs(event);
                    // handleClose();
                  }}*/

                      onMouseOver={handleClickSaveAs}
                      disabled={!saveToContactD}
                    >
                      Save As
                    </MenuItem>
                  </Menu>

                  <Menu
                    id="saveAs-menu"
                    anchorEl={anchorElSaveAs}
                    keepMounted
                    open={Boolean(anchorElSaveAs)}
                    TransitionComponent={Fade}
                    onClose={() => {
                      handleCloseSaveAs();
                      handleClose();
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {renderTypes()}
                  </Menu>
                </div>
              )}
            </div>
          ) : item && item.tempUrlDocument && item.type == "whatsapp" ? (
            <div >
              {isPrinting ? (
                <Iconify
                  icon={"solar:document-bold"}
                  width={50}
                  height={50}
                  style={{ color: "#00A884" }}
                />
              ) : (
                <div>
                    <ListItem>
                    <ListItemIcon>
                      <Iconify
                        icon={"solar:document-bold"}
                        width={50}
                        height={50}
                        style={{ color: "#00A884" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.file_name?item.file_name:"Document"}
                      
                    />
                   
                      <IconButton
                        aria-label="More"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setImageToDownload({
                            tempUrl: item.tempUrlDocument,
                            file_name: item.file_name,
                            media_id: item.media_id,
                          });
                        }}
                        className={
                          !Boolean(anchorEl) ? classes.more : classes.moreMenu
                        }
                        boxShadow={3}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                  
                 
                    </ListItem>
                  <Menu
                    id="more-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    TransitionComponent={Fade}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        downloadImage();
                        handleClose();
                      }}
                    >
                      Download
                    </MenuItem>
                    <MenuItem
                                    /*  onClick={(event) => {
                              handleClickSaveAs(event);
                              // handleClose();
                            }}*/

                      onMouseOver={handleClickSaveAs}
                      disabled={!saveToContactD}
                    >
                      Save As
                    </MenuItem>
                  </Menu>

                  <Menu
                    id="saveAs-menu"
                    anchorEl={anchorElSaveAs}
                    keepMounted
                    open={Boolean(anchorElSaveAs)}
                    TransitionComponent={Fade}
                    onClose={() => {
                      handleCloseSaveAs();
                      handleClose();
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {renderTypes()}
                  </Menu>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          <Box display="flex" alignItems="center">
            <Typography style={styles.userTime}>
              {item.created_at
                ? moment(item.created_at).format("YYYY-MM-DD hh:mm:ss A")
                : ""}
            </Typography>

            {/*item && item.response ? (
              item.response.includes("Success") || item.response.includes("success") ? (
                <Tooltip title="Success">
                  <DoneIcon fontSize="small" style={{ color: green[500] }} />
                </Tooltip>
              ) : (
                <Tooltip title="Fail">
                  <CloseIcon fontSize="small" style={{ color: red[500] }} />
                </Tooltip>
              )
            ) : (
              ""
            )*/}
          </Box>
        </Card>
      </Box>
      {contactDetail && (
        <Popup
          fullScreen
          title={`Contact ${contactDetail.fname} ${contactDetail.lname}`}
          openPopup={openContactPopup}
          onClose={oncloseContact}
        >
          <ContactDetails
            opentab={contactOpenTab.current}
            contact={contactDetail}
            onDetailClose={oncloseContact}
            documentsTab={1}
          />
        </Popup>
      )}

      {imageToShow && (
        <Popup
          title={`Image: ${imageToShow.file_name}`}
          openPopup={imageToShowOpenP}
          onClose={imageToShowClose}
        >
          <img
            src={imageToShow.tempUrl}
            alt={imageToShow.file_name}
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "500px",
              maxWidth: "500px",
            }}
          />
        </Popup>
      )}

      {videoToShow && (
        <Popup
          title={`Video: ${videoToShow.file_name}`}
          openPopup={videoToShowOpenP}
          onClose={videoToShowClose}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <video style={{ maxHeight: "600px", maxWidth: "600px" }} controls>
              <source src={videoToShow.tempUrl} type="video/mp4"></source>
            </video>
          </div>
        </Popup>
      )}

      {item.response === "SU_NOTIFICATION_MESSAGE" &&
        item.from_type == "contact" && (
          <IconButton
            style={{ marginLeft: -20 }}
            onClick={() => {
              openContact(item.from_id);
              contactOpenTab.current = 5;
            }}
            color="primary"
          >
            <Iconify icon={"carbon:document-export"} />
          </IconButton>
        )}

      {item.response === "EF_NOTIFICATION_MESSAGE" &&
        item.from_type == "contact" && (
          <IconButton
            style={{ marginLeft: -20 }}
            onClick={() => {
              openContact(item.from_id);
              contactOpenTab.current = 12;
            }}
            color="primary"
          >
            <Iconify icon={"carbon:document-export"} />
          </IconButton>
        )}
    </div>
  );
};

export default ChatRoomLeftItem;

const styles = {
  parentView: {
    marginLeft: "2%",
    maxWidth: "60%",
    justifyContent: "flex-start",
    flexDirection: "row",
    display: "flex",
  },
  profileImage: {
    width: 40,
    height: 40,
    marginRight: "2%",
    marginTop: "2%",
  },
  userName: {
    fontSize: 16,
    marginTop: 3,
  },
  userMessage: {
    fontSize: 14,
    color: "#000",
    marginTop: 3,
    alignSelf: "flex-start",
    whiteSpace: "pre-line",
  },
  userTime: {
    fontSize: 11,
    color: grey[500],
    alignSelf: "flex-end",
    textAlign: "right",
  },
  msgIcon: {
    fontSize: 26,
    color: grey[50],
    marginTop: 3,
    alignSelf: "flex-end",
    marginRight: -10,
  },
  cardView: {
    backgroundColor: "#FFF",
    paddingTop: 5,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 3,
    marginTop: 2,
    marginBottom: 2,
  },
};
