import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import ConfirmDialog from "./ConfirmDialog";
import useTable from "./useTable";
import Controls from "./controls";
import {
  Paper,
} from "@material-ui/core";
import Popup from "./Popup";
import Api from "../lib/api";
import { findIndex, isEmpty } from "lodash";
import Iconify from "./Iconify";
import RescheduleProcedureRequestForm from "./forms/RescheduleProcedureRequestForm";
import ChangeDateOfProcedureRequestDetails from "./ChangeDateOfProcedureRequestDetails";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  iconInvoice: {
    width: "30px",
    height: "19px",
    color: "#0091f1",
  },
  invoiceItemText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
  },
  invoiceItemProp: {
    display: "flex",
    alignItems: "center",
    padding: 5,
  },
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "60px",
  },
}));

var dataSliceAppointmentTypes = {
  stateListName: "appointmentTypes",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 50,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function ContactProcedures(props) {
  const { contact } = props;
  const [contactRecordProducts, setContactRecordProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [selectedRecordProduct, setSelectedRecordProduct] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pendingRequestOpen, setPendingRequestOpen] = useState(false);

  const headCellsMaterial = [
    {
      field: "name",
      title: "Procedure Name",
    },
    {
      field: "invoice_name",
      title: "Invoice #",
    },
    {
      field: "has_pending_transfer",
      title: "Has a pending request?",
      render: (rowData) => {
        return rowData.pendingDateTransfer &&
          !isEmpty(rowData.pendingDateTransfer.data) ? (
          <Iconify
            style={{ color: "rgb(0 193 186)", width: 28, height: 28 }}
            icon={"tabler:xbox-y"}
          />
        ) : (
          ""
        );
      },
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer } = useTable();

  const openConfirmDialog = (
    setConfirmDialogObj,
    onConfirmHandle,
    onConfirmParam,
    title,
    subtitle = null
  ) => {
    setConfirmDialogObj({
      isOpen: true,
      title: title,
      subTitle: subtitle ? subtitle : "You can't undo this operation",
      onConfirm: () => onConfirmHandle(onConfirmParam),
    });
  };

  const submitRequest = async (payload) => {
    const { data } = await Api.makeTransferDateRequest(
      selectedRecordProduct.id,
      payload
    );

    const index = findIndex(contactRecordProducts, ["id", data.data.id]);
    const updatedEvent = {
      ...contactRecordProducts[index],
      ...data.data,
    };
    let newContactRecordProducts = [...contactRecordProducts];
    newContactRecordProducts[index] = updatedEvent;
    setContactRecordProducts([...newContactRecordProducts]);
    setIsPopupOpen(false);
  };

  const getContactRecordProducts = async () => {
    setIsLoading(true);
    const { data } = await Api.getContactRecordProducts(contact.id);

    setContactRecordProducts([...data.data]);
    setIsLoading(false);
  };

  const onClose = () => {
    setIsPopupOpen(false);
    setSelectedRecordProduct(null);
  };

  const onClosePendingRequest = () => {
    setPendingRequestOpen(false);
    setSelectedRecordProduct(null);
  };

  useEffect(() => {
    if (contact) {
      getContactRecordProducts();
    }
  }, [contact]);

  return (
    <Wrapper>
      <Paper elevation={1} className={classes.paper}>
        <TblContainer>
          <MaterialTable
            isLoading={isLoading}
            columns={headCellsMaterial}
            data={contactRecordProducts}
            actions={[
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        rowData.pendingDateTransfer &&
                        !isEmpty(rowData.pendingDateTransfer.data)
                          ? "#bdbdbd"
                          : "#006ac7",
                    }}
                  >
                    <Iconify
                      icon={"fluent:calendar-sync-20-regular"}
                      width={28}
                      height={28}
                    />
                  </Controls.IconButton>
                ),
                hidden:
                  rowData.pendingDateTransfer &&
                  !isEmpty(rowData.pendingDateTransfer.data),
                tooltip: "Request date change",
                onClick: (event, rowData) => {
                  setSelectedRecordProduct(rowData);
                  setIsPopupOpen(true);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color: "#006ac7",
                    }}
                  >
                    <Iconify
                      icon={"fluent:textbox-16-filled"}
                      width={28}
                      height={28}
                    />
                  </Controls.IconButton>
                ),
                hidden: !(
                  rowData.pendingDateTransfer &&
                  !isEmpty(rowData.pendingDateTransfer.data)
                ),
                tooltip: "See current request",
                onClick: (event, rowData) => {
                  setSelectedRecordProduct(rowData);
                  setPendingRequestOpen(true);
                },
              }),
            ]}
            options={{
              rowStyle: (rowData, index) => {
                return {
                  backgroundColor:
                    rowData.pendingDateTransfer &&
                    !isEmpty(rowData.pendingDateTransfer.data)
                      ? "#e7fbfa"
                      : "white",
                };
              },

              loadingType: "linear",
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
            }}
            title="Procedures"
          />
        </TblContainer>
      </Paper>
      <Popup
        title={"Request change of surgery date"}
        fullWidth={true}
        maxWidth="sm"
        openPopup={isPopupOpen}
        onClose={onClose}
      >
        <RescheduleProcedureRequestForm
          submitRequest={submitRequest}
          recordProduct={selectedRecordProduct}
        />
      </Popup>

      <Popup
        title={"Pending request"}
        fullWidth={true}
        maxWidth="md"
        openPopup={pendingRequestOpen}
        onClose={onClosePendingRequest}
      >
        <ChangeDateOfProcedureRequestDetails
          request={selectedRecordProduct?.pendingDateTransfer?.data || null}
        />
      </Popup>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default ContactProcedures;
