import React, { useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";
import { isEmpty, findIndex, capitalize } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useTable from "./useTable";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import {
  createInvoiceService,
  deleteInvoiceService,
} from "../redux/slices/invoices";
import {
  Paper,
  Card as MuiCard,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

//import { fetchContactsService } from "../redux/slices/contacts";

import { useTranslation } from "react-i18next";
import Api from "../lib/api";
import CRMUtils from "../utils";
import Iconify from "./Iconify";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import ReactToPrint from "react-to-print";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;
/*const statusArray = [
  {
    key: "unsend",
    label: "Unsend",
    color: grey[700],
  },
  {
    key: "sent",
    label: "Sent",
    color: blue[700],
  },
  {
    key: "unpaid",
    label: "Unpaid",
    color: green[700],
  },
  {
    key: "partial_paid",
    label: "Partial paid",
    color: lime[700],
  },
  {
    key: "paid",
    label: "Paid in full",
    color: red[700],
  },
];*/

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  estrapayment: {
    color: "grey",
  },
  actionButton: {
    width: 22,
    height: 22,
    color: "#517ead",
  },
}));

function StatusHistories(props) {
  const { record_id, fields, onSelect, selectedInvoice, contact } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { authUser } = useSelector((state) => state.auth);
  const userCanExportHistory = CRMUtils.userHasPermission(authUser, [
    "recordproduct_exportstatushistory",
  ]);
  // const { contacts } = useSelector((state) => state.contacts);

  const { usersDropdown } = useSelector((state) => state.users);
  const [recordProductsStatusHistory, setRecordProductsStatusHistory] =
    useState([]);
  const [selected, setSelected] = useState([]);
  const { t, i18n } = useTranslation(["common", "records"]);
  const [progressBar, setProgressBar] = useState(false);
  const headCells = [
    { id: "status", label: t("common:general.Status") },
    { id: "comment", label: t("common:general.Comment") },
    { id: "created_by", label: t("common:general.CreateBy") },
    { id: "created_at", label: "Created at" },
    { id: "cancellationreason", label: t("Cancellation Reason") },
    { id: "date_before", label: t("records:records.DateBefore") },
    { id: "date_after", label: t("records:records.DateAfter") },
    {
      id: "tentative_date_before",
      label: t("records:records.TentativeDateBefore"),
    },
    {
      id: "tentative_date_after",
      label: t("records:records.TentativeDateAfter"),
    },
  ];

  // <TableCell>{item.status}</TableCell>
  // <TableCell>{item.comment}</TableCell>
  // <TableCell>
  //   {findUser(usersDropdown, item.created_by)}
  // </TableCell>
  // <TableCell>
  //   {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
  // </TableCell>
  // <TableCell>
  //   {item.cancellation_reason ? item.cancellation_reason : ""}
  // </TableCell>
  // <TableCell>{item.date_before}</TableCell>
  // <TableCell>{item.date_after}</TableCell>
  // <TableCell>{item.tentative_date_before}</TableCell>
  // <TableCell>{item.tentative_date_after}</TableCell>

  const headCellsToExport = [
    { field: "status", title: t("common:general.Status") },
    { field: "comment", title: t("common:general.Comment") },
    {
      field: "created_by",
      title: t("common:general.CreateBy"),
      render: (rowData) => findUser(usersDropdown, item.created_by),
    },
    {
      field: "created_at",
      title: "Created at",
      render: (rowData) =>
        moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
    },

    { field: "cancellationreason", title: t("Cancellation Reason") },
    { field: "date_before", title: t("records:records.DateBefore") },
    { field: "date_after", title: t("records:records.DateAfter") },
    {
      field: "tentative_date_before",
      title: t("records:records.TentativeDateBefore"),
    },
    {
      field: "tentative_date_after",
      title: t("records:records.TentativeDateAfter"),
    },
  ];

  //if(recordProductsStatusHistory) recordProductsStatusHistory = useSelector((state) => state.recordProduct);
  //console.log('From Parent:',recordProductsStatusHistory);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();
  const [invoiceDetail, setInvoiceDetail] = useState(null);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [openPopup, setOpenPopup] = useState(false);

  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const statusHistoryTableRef = useRef();

  const onDelete = (id) => {
    dispatch(deleteInvoiceService(id));

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setNotify({
      isOpen: true,
      message: t("common:general.Del_Doc_ok"),
      type: "error",
    });
  };

  const fullHeadCells = [].concat(
    headCells /*, [
    { id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ]*/
  );

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(recordProductsStatusHistory, fullHeadCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };
  const openInPopup = (item) => {
    openInvoice(item);
    if (onSelect) onSelect(item);
  };
  const openInvoice = (invoice) => {
    setInvoiceDetail(invoice);
    setOpenDetailPopup(true);
  };

  const closePopup = () => {
    setOpenDetailPopup(false);
  };

  const closeNewInvoicePopup = () => {
    setOpenPopup(false);
  };
  const calculateSubtotal = (items) => {
    return items.reduce((total, item) => total + calculateItemTotal(item), 0);
  };
  const onAdvanceFilter = () => {
    console.log("filter");
  };

  const onMassiveDelete = async () => {
    console.log("delete");
  };
  const calculateTotal = (items) => {
    const subtotal = calculateSubtotal(items);

    return subtotal;
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return item.price * (item.price_includes_qty? 1 : parseInt(quantity)) - discount * (item.price_includes_qty? 1 : parseInt(quantity));
  };

  const calulateTotalPayments = (payments) => {
    return payments.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const calulateTotalRefunds = (refunds) => {
    return refunds.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const calculateTotalDue = (items, payments, refunds) => {
    const total = calculateTotal(items);
    const totalPayments = calulateTotalPayments(payments);
    const totalRefunds = calulateTotalRefunds(refunds);
    return total - totalPayments + totalRefunds;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = recordProductsStatusHistory.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const saveInvoice = (data) => {
    const invoiceitems = data.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        item_name: item.name,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });

    if (!isEmpty(authUser)) {
      var payload = {
        items: invoiceitems,
        name: `t("common:general.Invoicem"), ${
          data.contact ? data.contact.full_name : ""
        }`,
        contact_id: data.contact.id,
        user_id: authUser.id,
        organization_id: authUser.organization_id,
      };
      //console.log("save invoice... ", payload);
      dispatch(createInvoiceService(payload)).then((result) => {
        //console.log("createInvoiceService ", result);
      });
      closeNewInvoicePopup();
    }
  };

  useEffect(() => {
    if (isEmpty(usersDropdown))
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  const findUser = (usersDropdown, id) => {
    var username = false;
    usersDropdown.map((item) => {
      if (item.id === id) username = item.first_name + " " + item.last_name;
    });
    return username;
  };

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (record_id) {
      setProgressBar(true);
      getStatusHistory().then(function () {
        setProgressBar(false);
      });
    }
  }, [record_id]);

  const getStatusHistory = async () => {
    const { data } = await Api.getRecordProductsStatusHistory(record_id);
    setRecordProductsStatusHistory([...data.data]);
  };

  const onExport = (option = null) => {
    if (option == "pdf") {
      ExportPdf(
        headCellsToExport,
        recordProductsStatusHistory,
        "Status History"
      );
    } else {
      if (option == "csv") {
        ExportCsv(
          headCellsToExport,
          recordProductsStatusHistory,
          "Status History"
        );
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Paper className={classes.pageContent}>
        {/* <EnhancedTableToolbar
          label={t("records:records.statush")}
          numSelected={selected.length}
          advanceFilter={onAdvanceFilter}
          onMassiveDelete={onMassiveDelete}
        /> */}
        <Box style={{ marginTop: 30 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              id="tableTitle"
              style={{ marginRight: 14 }}
            >
              {t("records:records.statush")}
            </Typography>
            {userCanExportHistory ? (
              <>
                {" "}
                <Tooltip title={"Export data"}>
                  <span>
                    {" "}
                    <IconButton
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <Iconify
                        className={classes.actionButton}
                        icon="entypo:export"
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={"Print data"}>
                  <span>
                    <ReactToPrint
                      trigger={() => (
                        <IconButton>
                          <Iconify
                            icon="mingcute:print-fill"
                            className={classes.actionButton}
                          />
                        </IconButton>
                      )}
                      content={() => statusHistoryTableRef.current}
                    />
                  </span>
                </Tooltip>
              </>
            ) : (
              ""
            )}
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => {
                //console.log(item);
                const isItemSelected = isSelected(item.id);
                return (
                  <TableRow>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.comment}</TableCell>
                    <TableCell>
                      {findUser(usersDropdown, item.created_by)}
                    </TableCell>
                    <TableCell>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      {item.cancellation_reason ? item.cancellation_reason : ""}
                    </TableCell>
                    <TableCell>{item.date_before}</TableCell>
                    <TableCell>{item.date_after}</TableCell>
                    <TableCell>{item.tentative_date_before}</TableCell>
                    <TableCell>{item.tentative_date_after}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
          {progressBar && (
            <div>
              <div style={{ padding: 10, textAlign: "center" }}>
                No records to display
              </div>
              <LinearProgress />
            </div>
          )}
          <TblPagination />
        </Box>
      </Paper>

      <div style={{ display: "none" }}>
        <div ref={statusHistoryTableRef}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordProductsStatusHistory.map((item) => {
                //console.log(item);
                const isItemSelected = isSelected(item.id);
                return (
                  <TableRow>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.comment}</TableCell>
                    <TableCell>
                      {findUser(usersDropdown, item.created_by)}
                    </TableCell>
                    <TableCell>
                      {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      {item.cancellation_reason ? item.cancellation_reason : ""}
                    </TableCell>
                    <TableCell>{item.date_before}</TableCell>
                    <TableCell>{item.date_after}</TableCell>
                    <TableCell>{item.tentative_date_before}</TableCell>
                    <TableCell>{item.tentative_date_after}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </div>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          key={"csv"}
          onClick={() => {
            onExport("csv");
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Iconify
              style={{
                color: "#0da149",
                width: 22,
                height: 22,
                marginRight: 6,
              }}
              icon="ph:file-csv-fill"
            />
            <Typography> Export as CSV</Typography>
          </div>
        </MenuItem>
        <MenuItem
          key={"pdf"}
          onClick={() => {
            onExport("pdf");
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Iconify
              style={{
                color: "#CF2A2B",
                width: 22,
                height: 22,
                marginRight: 6,
              }}
              icon="ph:file-pdf-fill"
            />
            <Typography> Export as PDF</Typography>
          </div>
        </MenuItem>
      </Menu>
    </Wrapper>
  );
}

export default StatusHistories;
