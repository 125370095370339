import React, { useState, useEffect, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  LinearProgress,
  makeStyles,
  Chip,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Api from "../../lib/api";
import { Pagination } from "@material-ui/lab";
import Iconify from "../Iconify";
import moment from "moment";
import ScrollBar from "react-perfect-scrollbar";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { debounce, findIndex } from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "../controls/IconButton";

const useStyles = makeStyles((theme) => ({
  chipStyle: {
    background: "#deefff",
    color: "#0080db",
    fontWeight: 600,
    border: "1px solid rgb(143 194 237)",
  },
}));

export default function AllNotifications(props) {
  const {
    selectedType,
    renderNotificationDataNew,
    setNotificationView,
    markNotfAsView,
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [paginator, setPaginator] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [date, setDate] = useState({
    start: null,
    end: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getNotifications = async () => {
    const params = new URLSearchParams([
      ["page", page],
      ["limit", limit],
      ["notification_type", selectedType],
      ["search", searchValue ? searchValue : ""],
      ["start", filters && filters.start ? filters.start : ""],
      ["end", filters && filters.end ? filters.end : ""],
    ]);
    setIsLoading(true);
    const { data } = await Api.getAllNotificationsByUser(
      authUser.id,
      `${params}`
    );

    setNotifications([...data.data]);
    setPaginator(data.meta.pagination);
    setIsLoading(false);
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => onSearchChange(newValue), 800),
    []
  );

  useEffect(() => {
    getNotifications();
  }, [page, filters, searchValue]);

  const markAllAsView = () => {
    setNotificationView();
    getNotifications();
  };

  const markAsView = (notf) => {
    markNotfAsView(notf);

    const index = findIndex(notifications, ["id", notf.id]);
    const updatedEvent = {
      ...notifications[index],
      ...{ view: 1 },
    };

    let newnotf = [...notifications];
    newnotf[index] = updatedEvent;
    setNotifications([...newnotf]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "fit-content",
          padding: 14,
          boxShadow: "0 0 14px 0 rgb(53 64 82 / 6%)",
          borderRadius: "13px",
        }}
      >
        <TextField
          style={{ width: "315px" }}
          onChange={(value) => {
            !isLoading
              ? debouncedSearch(value.target.value)
              : onSearchChange(value.target.value);
          }}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "rgb(68 117 255)" }} />,
          }}
          label="Search..."
        />
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              disableToolbar
              variant="inline"
              style={{
                height: 38,
                maxWidth: 130,
                marginTop: 0,
                marginRight: 6,
              }}
              label="From"
              onChange={(d) =>
                setDate({
                  ...date,
                  start:
                    d == null ? null : moment(d).format("YYYY-MM-DD 00:00:00"),
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              value={date.start}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              disableToolbar
              variant="inline"
              style={{ height: 38, maxWidth: 130, marginTop: 0 }}
              label="To"
              onChange={(d) => {
                setDate({
                  ...date,
                  end:
                    d == null ? null : moment(d).format("YYYY-MM-DD 23:59:59"),
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              value={date.end}
            />
          </MuiPickersUtilsProvider>

          <Tooltip title="Apply filters">
            <IconButton
              style={{ marginLeft: 5 }}
              variant="outlined"
              color="primary"
              onClick={() => {
                setFilters({ ...filters, ...date });
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <br />
      <div
        style={{
          padding: 5,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Pagination
          style={{ minWidth: "fit-content", margin: "0 auto" }}
          count={paginator && paginator.total_pages ? paginator.total_pages : 0}
          page={page}
          onChange={(e, p) => {
            setPage(p);
          }}
        />
        <div
          style={{
            display: "grid",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <Button
            color="primary"
            onClick={markAllAsView}
            endIcon={<Iconify icon="mdi:check-all" />}
          >
            Mark all as read
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <LinearProgress />
        </div>
      ) : (
        ""
      )}
      <ScrollBar style={{ maxHeight: 610 }}>
        <div>
          {notifications.length > 0 ? (
            notifications.map((notification) => {
              return (
                <>
                  {
                    <Tooltip
                      title={
                        expanded === notification.id
                          ? ""
                          : "Click here for more information"
                      }
                    >
                      <Box style={{ maxWidth: "100%" }}>
                        <Accordion
                          style={{
                            backgroundColor:
                              notification && notification.view
                                ? "white"
                                : "rgb(247 247 247)",
                          }}
                          square
                          expanded={expanded === notification.id}
                          onChange={handleChange(notification.id)}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor:
                                notification && notification.view
                                  ? "white"
                                  : "rgb(247 247 247)",
                            }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "100%", display: 'flex' }}>
                                <CardHeader
                                  style={{
                                    padding: 6,
                                  }}
                                  avatar={
                                    <Avatar
                                      style={{
                                        backgroundColor: "#00000012",
                                      }}
                                    >
                                      <Iconify
                                        icon={
                                          notification
                                            ? notification.notification_type ==
                                              "contact"
                                              ? "fluent:person-16-filled"
                                              : notification.notification_type ==
                                                "appointment"
                                              ? "heroicons-solid:calendar"
                                              : notification.notification_type ==
                                                "ticket"
                                              ? "heroicons:ticket-solid"
                                              : "octicon:bell-fill-24"
                                            : "octicon:bell-fill-24"
                                        }
                                        style={{
                                          color: "rgb(12 187 130)",
                                        }}
                                        width={25}
                                        height={25}
                                      />
                                    </Avatar>
                                  }
                                  title={
                                    <Typography
                                      id="title"
                                      style={{
                                        fontSize: 15,
                                        fontWeight:
                                          notification && !notification.view
                                            ? 600
                                            : 400,
                                      }}
                                    >
                                      {notification?.text ||
                                        "System Notification"}
                                    </Typography>
                                  }
                                  subheader={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: 4,
                                      }}
                                    >
                                      <Iconify
                                        style={{
                                          marginRight: 4,
                                          color: "#8295a9",
                                        }}
                                        icon="tabler:clock-filled"
                                      />
                                      <Typography
                                        id="subheader"
                                        style={{ fontSize: 12 }}
                                      >
                                        {notification.created_at
                                          ? moment(
                                              notification.created_at
                                            ).format("YYYY-MM-DD") +
                                            " at " +
                                            moment(
                                              notification.created_at
                                            ).format("hh:mm:ss A")
                                          : ""}
                                      </Typography>
                                    </div>
                                  }
                                />

                                {notification && !notification.view ? (
                                  <div>
                                    <Chip
                                      className={classes.chipStyle}
                                      label={`New`}
                                      variant="outlined"
                                      size="small"
                                    ></Chip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              
                                {notification &&
                                (notification.view == 0 ||
                                  notification.view == "0") ? (
                                  <Button
                                  style={{minWidth: 'fit-content'}}
                                    startIcon={<Iconify icon="mdi:check-all" />}
                                    color="primary"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      markAsView(notification);
                                    }}
                                  >
                                    Mark as view
                                  </Button>
                                ) : (
                                  ""
                                )}
                           
                            </div>
                          </AccordionSummary>
                          {expanded &&
                          notification &&
                          expanded == notification.id ? (
                            <AccordionDetails style={{ display: "block" }}>
                              {notification.data.length > 0
                                ? renderNotificationDataNew(
                                    notification.data,
                                    notification.notification_type
                                  )
                                : ""}
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>
                      </Box>
                    </Tooltip>
                  }
                </>
              );
            })
          ) : (
            <>
              <Card style={{ boxShadow: "none" }}>
                <CardHeader
                  avatar={
                    <Iconify
                      icon={"ci:notification-active"}
                      style={{ color: "#A09E9E" }}
                      width={25}
                      height={25}
                    />
                  }
                  title={
                    <Typography id="innerTitle" style={{ fontSize: 16 }}>
                      {"No Notifications to Show"}
                    </Typography>
                  }
                  subheader={
                    <Typography id="innerSubheader" style={{ fontSize: 12 }}>
                      {"System"}
                    </Typography>
                  }
                />
              </Card>
            </>
          )}
        </div>
      </ScrollBar>
    </>
  );
}
