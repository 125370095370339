import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CommentIcon from "@material-ui/icons/Comment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { useTranslation } from "react-i18next";

import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DraftsIcon from "@material-ui/icons/Drafts";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import FaceIcon from "@material-ui/icons/Face";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DialerSipIcon from "@material-ui/icons/DialerSip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import MicOffIcon from "@material-ui/icons/MicOff";

import SmsIcon from "@material-ui/icons/Sms";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import TextsmsIcon from "@material-ui/icons/Textsms";
import MmsIcon from "@material-ui/icons/Mms";

import Api from "../../lib/api";
import Controls from "../../components/controls";
//import CRMUtils from "../../utils";

import { encode, decode } from "base64-emoji";
import {
  /*Paper,
  Card as MuiCard,
  makeStyles,
  Backdrop,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  CircularProgress,
  Grid,
  Card,
  Box,
  AppBar,
  FormControl,
  FormLabel,
  FormGroup,
  Popover,*/
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  error: {
    color: "red",
  },
  referred: {
    color: "rgb(246 184 0)",
  },
  success: {
    color: "green",
  },
  attach: {
    width: "77px",
  },
  image: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "5px",
    width: "77px",
    "&:hover": {
      boxShadow: "0 0 2px 1px rgba(0, 140, 186, 0.5)",
    },
  },
}));

export default function SmsMmsTimeline(props) {
  const classes = useStyles();
  const { data, users = null, pbxInformation = null } = props;
  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";
  const { t, i18n } = useTranslation(["common", "records"]);
  // const [tempUrl, setTempUrl] = useState(null);
  // const [tempUrlRunning, setTempUrlRunning] = useState(false);

  /* const getTempUrl = async (id, minutes) => {
    const { data } = await Api.getContactMmsTemporalUrl(id, minutes);
    setTempUrl(data);
    //setOpenLogPopup(true);
  };*/

  /* if (!tempUrl && tempUrlRunning === false) {
    setTempUrlRunning(true);
    getTempUrl(data.object.id, 15);
  }*/
  const getUserName = (id) => {
    let tmp = {};
    if (users && id)
      tmp = users.find((user) => {
        return user.id === id;
      });
    // console.error("tmp", tmp);
    if (tmp && tmp.dropdown_name) return tmp.dropdown_name;
    else return "No Info...";
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          created by {owner ? owner : "SYSTEM"}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          {data.object.type === "sms" &&
          data.object.response &&
          (data.object.response.includes("Success") ||
            data.object.response.includes("multicast_id") ||
            data.object.response.includes("id")) ? (
            <TextsmsIcon />
          ) : data.object.type === "sms" &&
            data.object.response &&
            !data.object.response.includes("Success") &&
            !data.object.response.includes("multicast_id") &&
            !data.object.response.includes("id") ? (
            <SmsFailedIcon />
          ) : data.object.type === "mms" &&
            data.object.response &&
            !data.object.response.includes("Success") &&
            !data.object.response.includes("multicast_id") &&
            !data.object.response.includes("id") ? (
            <SmsFailedIcon />
          ) : data.object.type === "sms" && !data.object.response ? (
            <TextsmsIcon />
          ) : (
            <MmsIcon />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {data.object.from_type === "contact"
              ? "Contact contacted with " +
                data.object.to +
                " (" +
                getUserName(data.object.to_id) +
                ")"
              : "Contact was contacted by " +
                data.object.from +
                " (" +
                getUserName(data.object.from_id) +
                ")"}
          </Typography>

          {((data.object.response &&
            data.object.response.includes("Success") &&
            data.object.response.includes("202") &&
            data.object.response.includes("DEFERRED")) ||
          (data.object.response && !data.object.response.includes("id")) && data.object.direction === "out") ? (
            <p className={classes.referred}>
              {
                "Deferred (It is not guaranteed that this message was sent successfully)"
              }
            </p>
          ) : data.object.response &&
            !data.object.response.includes("Success") &&
            !data.object.response.includes("success") &&
            !data.object.response.includes("id") &&
            !data.object.response.includes("projects")
            && data.object.direction === "out" ? (
            <p className={classes.error}>
              error {/*data.object.response.split('":"')[1].split('",')[0]*/}
            </p>
          ) : (
            <p className={classes.success}></p>
          )}
          {/*data.object.from_type === "contact"*/data.object.direction === "in" ? (
            <p>
              {" "}
              <Tooltip title="Inbound Message (Received Message)">
                <ArrowDownwardIcon />
              </Tooltip>
              {data.object.body ? "Text: " + data.object.body : ""}
            </p>
          ) : (
            <p>
              {" "}
              <Tooltip title="Outbound Message (Send Message)">
                <ArrowUpwardIcon />
              </Tooltip>
              {data.object.body ? "Text: " + data.object.body : ""}
            </p>
          )}

          <Typography>
            {data.object.type === "mms" && (
              <>
                <a
                  href={
                    data.object.temp_url && data.object.type === "mms"
                      ? data.object.temp_url
                      : ""
                  }
                  target="_blank"
                >
                  <img
                    src={
                      data.object.temp_url && data.object.type === "mms"
                        ? data.object.temp_url
                        : ""
                    }
                    alt="attachment"
                    className={classes.image}
                  ></img>
                </a>
              </>
            )}
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
  {
  }
}
