import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	DialogActions,
	Grid,
	LinearProgress,
	TextField,
	Typography,
	makeStyles,
} from "@material-ui/core";
import {
	Controller,
	FormProvider,
	useForm,
	useFormContext,
	useWatch,
} from "react-hook-form";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import Controls from "../../../components/controls";
import FroalaEditor from "react-froala-wysiwyg";
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField";
import { useMutation, useQuery } from "react-query";
import Api from "../../../lib/api";
import SelectWColor from "../../../components/controls/SelectWColor";
import StatesComponent from "../../../components/statescomponent/StatesComponent";
import { isInteger, isString } from "lodash";
import InvoiceOption from "./options/InvoiceOption";
import UserOption from "./options/UserOption";
import AutocompleteGeneric from "./AutocompleteGeneric";
import Select from "../../../components/controls/Select";
import MultipleSelect from "./MultipleSelect";
import RefundDoc, { formatPrice } from "./RefundDoc";
import Checkbox from "../../../components/controls/Checkbox";
import Popup from "../../../components/Popup";
import Button from "../../../components/controls/Button";
import TaskForm from "../../task/components/TaskForm";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
	Add,
	Event,
	ExpandMore,
	History,
	Visibility,
} from "@material-ui/icons";
import Carousel from "./Carousel";
import IconButton from "../../../components/controls/IconButton";
import ContactInfoHistory from "./ContactInfoHistory";
import moment from "moment-timezone";
import { Calendar, momentLocalizer } from "react-big-calendar";
import FileComponent from "../../filesModule/components/FileComponent";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	backdrop: {
		backgroundColor: "rgb(255,255,255,0)",
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 5000,
	},
	blur: {
		filter: "blur(2px)", // adjust the blur effect here
	},
}));

const RefundTicketForm = ({
	objectData,
	refetch,
	userId,
	permissions = [],
}) => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();
	const classes = useStyles();

	const isUpdate = useMemo(() => {
		return Boolean(objectData?.id);
	}, [objectData]);

	const canSelectAnyUser = useMemo(() => {
		return isUpdate
			? permissions.includes("rfstickets_edit")
			: permissions.includes("rfstickets_create");
	}, [isUpdate, permissions]);

	const calendarLocalizer = momentLocalizer(moment);

	useEffect(() => {
		if (!canSelectAnyUser && !isUpdate) {
			setTimeout(() => {
				setValue("user_assigned_id", userId);
			});
		}
	}, [canSelectAnyUser, isUpdate, setValue]);

	const getTags = async () => {
		const params = new URLSearchParams([
			["model", `App\\Models\\RfsTickets`],
		]);
		const { data } = await Api.getTags(
			authUser.organization_id,
			`?1=1&${params.toString()}`
		);
		setTags([...data.data]);
	};

	useEffect(() => {
		getTags();
	}, []);

	const methods = useForm();

	const [expanded, setExpanded] = useState(null);
	const handleChangeExpanded = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	//priorities
	const { data: priorities, isLoading: prioritiesLoading } = useQuery(
		["rfs_priorities"],
		() =>
			Api.genericQueryModel("rfs_priorities", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	//history
	const {
		data: history,
		isLoading: historyLoading,
		isSuccess: historySuccessLoading,
		refetch: refetchHistory,
		isRefetching: isHistoryRefetching,
	} = useQuery(
		["RfsUserDataHistory", objectData?.id],
		() =>
			Api.genericQueryModel("RfsUserDataHistory", {
				page: null,
				per_page: null,
				filters: {
					rfs_ticket_id: objectData?.id,
				},
				with: ["via", "user_created"],
			}),
		{
			enabled: expanded == "history" && Boolean(objectData?.id),
			select: (data) => data?.data?.data,
		}
	);

	const calendarEvents = useMemo(() => {
		return (
			history?.map((h) => ({
				...h,
				title: `Via:${h?.via?.via}`,
				start: moment.utc(h.date).toDate(),
				end: moment.utc(h.date).toDate(),
				allDay: true,
			})) ?? []
		);
	}, [history]);

	//buildings
	const { data: buildings, isLoading: buildingsLoading } = useQuery(
		["building"],
		() =>
			Api.genericQueryModel("building", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	//departments
	const { data: departments, isLoading: deparmentsLoading } = useQuery(
		["department"],
		() =>
			Api.genericQueryModel("department", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	const { data: reasons, isLoading: reasonsLoading } = useQuery(
		["rfs_reasons"],
		() =>
			Api.genericQueryModel("rfs_reasons", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	const [openDialog, setOpenDialog] = useState(false);
	const [contactHistoryOpenDialog, setContactHistoryOpen] = useState(false);
	const [contactHistoryElement, setContactHistoryElement] = useState(null);
	const handleHistoryCancel = () => {
		setContactHistoryElement(null);
		setContactHistoryOpen(false);
	};

	const [calendarOpenDialog, setCalendarOpenDialog] = useState(false);

	const handleShowHistory = (h) => {
		if (h?.id) {
			setContactHistoryElement(h);
		} else {
			setContactHistoryElement(h);
		}
		setContactHistoryOpen(true);
	};

	const { isLoading: isHistoryCreating, mutate: createHistory } = useMutation(
		(_data) => Api.genericInsertModel("rfs_user_data_history", _data)
	);

	const handleHistoryUpdate = (data) => {
		createHistory(
			{
				...data,
				rfs_ticket_id: objectData?.id,
			},
			{
				onSettled: () => {},
				onSuccess: () => {
					refetchHistory();
					setContactHistoryElement(null);
					setContactHistoryOpen(false);
				},
			}
		);
	};
	const { data: groups, isLoading: groupsLoading } = useQuery(
		["group"],
		() =>
			Api.genericQueryModel("group", {
				page: null,
				per_page: null,
			}),
		{
			select: (data) => data?.data?.data,
		}
	);

	const editorConfig = {
		key: process.env.REACT_APP_FROALA_KEY,
		zIndex: 2501,
		attribution: false,
		dragInline: false,
		useClasses: false,
		htmlRemoveTags: ["script"],
		pasteDeniedAttrs: ["style"],
		pluginsEnabled: [
			"align",
			"charCounter",
			"codeBeautifier",
			"codeView",
			"colors",
			"draggable",
			"emoticons",
			"entities",
			"fontFamily",
			"fontSize",
			"fullscreen",
			"help",
			"image",
			"inlineClass",
			"inlineStyle",
			"link",
			"lists",
			"paragraphFormat",
			"paragraphStyle",
			"print",
			"quote",
			"save",
			"specialCharacters",
			"table",
			"url",
			"video",
			"wordPaste",
		],
		events: {
			initialized: function () {
				const body = watch("body");
				froala.current.editor.html.set(body);
				if (lastState) {
					froala.current.editor.edit.off();
				}
			},
			"image.beforeUpload": function (files) {
				const data = new FormData();
				data.append("fileUrl", files[0]);

				Api.uploadImage(organization_id, data)
					.then((res) => {
						this.image.insert(
							res.data.data,
							null,
							null,
							this.image.get()
						);
					})
					.catch((err) => {
						console.log(err);
					});
				return false;
			},
		},
	};
	const dispatch = useDispatch();

	const [isChargeBack, setIsChargeBack] = useState(
		objectData && objectData.is_chargeback ? true : false
	);
	const [tags, setTags] = useState([]);
	const { authUser } = useSelector((state) => state.auth);

	const objectTags = useMemo(() => {
		return objectData?.tags?.map((t) => t?.name?.en) ?? [];
	}, [objectData]);

	const organization_id = useMemo(() => {
		return authUser?.organization_id;
	}, [authUser]);

	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const invoiceItems = useMemo(() => {
		return (
			selectedInvoice?.items?.map((ii) => ({
				id: ii.id,
				title: ii.item_name,
			})) ?? []
		);
	}, [selectedInvoice]);

	const froala = useRef();

	const canDoResetOfChargeBack = useMemo(() => {
		return Boolean(isUpdate && objectData?.get_state?.code == "wined");
	}, [isUpdate, objectData]);

	const optionLabelInvoice = useCallback((option, single) => {
		if (isInteger(option)) {
			if (option == single?.id) {
				return `${single.name} (${single.items?.length} items)-$${
					single.totalPrice
				} ${
					single.contact.fname +
					" " +
					single.contact.lname +
					"[" +
					single.contact?.email +
					"]" +
					"(" +
					single.contact?.phone +
					")"
				}`;
			}
		} else {
			return `${option.name} (${option.items?.length} items)-$${
				option.totalPrice
			} ${
				option?.contact?.fname +
				" " +
				option?.contact?.lname +
				"[" +
				option.contact?.email +
				"]" +
				"(" +
				option.contact?.phone +
				")"
			}`;
		}
	}, []);

	const optionLabelUser = useCallback((option, single) => {
		if (isInteger(option)) {
			if (option == single?.id) {
				return `${single.dropdown_name} [${single.email}](${single.phone})`;
			}
		} else {
			return `${option.dropdown_name} [${option.email}](${option.phone})`;
		}
	}, []);

	const amount = useWatch({ control, name: "amount" });

	const [loadingTask, setLoadingTask] = useState(false);
	const [taskInfo, setTaskInfo] = useState(null);

	const lastState = useMemo(() => {
		if (objectData?.get_state?.code) {
			let code = objectData.get_state.code;
			if (code == "lost" || code == "winned") {
				return true;
			}
		}
		return false;
	});

	const { data: procesingState, isLoading: isLoadingProcesingState } =
		useQuery(
			["state", "processing"],
			() => Api.getStatesByWorkflow("refund_basic"),
			{
				select: (data) =>
					data?.data?.data
						.filter((s) => s.code == "processing")
						.reduce((p, c) => c, null),
			}
		);

	const {
		data,
		isLoading: isChangingState,
		mutate: changeState,
	} = useMutation(({ state_id, object_id, force }) =>
		Api.setState(state_id, object_id, force)
	);

	useEffect(() => {
		if (lastState) {
			froala?.current?.editor?.edit?.off &&
				froala.current.editor.edit.off();
		} else {
			froala?.current?.editor?.edit?.on &&
				froala.current.editor.edit.on();
		}
	}, [lastState]);

	const onStateUpdate = async ({ nextState }) => {
		if (refetch) {
			refetch(); //refetch parent
		}
		if (nextState?.code == "lost") {
			setOpenDialog(true);
			setTaskInfo(null);
			setLoadingTask(true);
			const { data } = await Api.genericQueryByIdModel(
				"rfs_tickets",
				objectData?.id,
				{
					with: [
						"task",
						"task.priority",
						"task.getState",
						"task.user_assigned",
						"task.task_type",
					],
				}
			);
			if (data?.data?.task) {
				let task = data.data.task;
				setTaskInfo(task);
				setTimeout(() => {
					Object.entries(task).forEach(([key, value]) => {
						methods.setValue(key, value);
					});
				});
			}
			setLoadingTask(false);
		}
	};

	const {
		data: updateValue,
		isLoading: updateIsLoading,
		mutate: updateModel,
	} = useMutation(
		({ id, data }) => Api.genericUpdateModel("tk_tasks", id, data),
		{
			onSuccess: (data) => {
				// let d = data?.data?.data;
				setOpenDialog(false);
				setTaskInfo(null);
			},
		}
	);

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTaskInfo(null);
	};

	const handleSubmitEdit = (data) => {
		if (data == null) {
			return;
		}
		updateModel({ id: taskInfo?.id, data });
	};

	const [confirmationDialog, setConfirmationDialog] = React.useState({
		isOpen: false,
		title: "",
		subTitle: "",
	});

	const doReopen = () => {
		changeState(
			{
				state_id: procesingState?.id,
				object_id: objectData?.id,
				force: true,
			},
			{
				onSettled: () => setConfirmationDialog({ isOpen: false }),
				onSuccess: () => {
					refetch && refetch();
					stateRef?.current?.refresh();
				},
			}
		);
	};

	const stateRef = useRef();

	const doReopenDialog = () => {
		setConfirmationDialog({
			isOpen: true,
			title: "Reopen ",
			subTitle: "Reopen Ticket",
			onConfirm: () => doReopen(),
		});
	};

	const tagOptions = (tags) => {
		return tags.map((tag) => tag.tagname);
	};

	const parseTagValue = (tags) => {
		return tags.map((tag) => (isString(tag) ? tag : tag?.name?.en));
	};

	const maxAmount = useMemo(() => {
		return selectedInvoice
			? selectedInvoice.totalPrice - selectedInvoice.balance
			: 0;
	}, [selectedInvoice]);

	function dayPropGetter(date) {
		const hasEvent = calendarEvents.some((event) =>
			moment(event.date).isSame(date, "day")
		);
		if (!hasEvent) {
			return {
				className: "no-events-day",
				onClick: () => handleShowHistory(null),
				children: (
					<div>
						<IconButton className="add-event-button">
							<Add />
						</IconButton>
					</div>
				),
			};
		}
		return {};
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item md={isUpdate ? 6 : 12}>
					<Controller
						name="invoice_id"
						defaultValue={-1}
						control={control}
						rules={{
							required: {
								value: true,
								message: "Invoice required",
							},
						}}
						render={({ onChange, onBlur, value }) => (
							<AutocompleteGeneric
								label="Invoice"
								objectId={objectData?.invoice_id}
								onSelected={setSelectedInvoice}
								onChange={onChange}
								RenderOption={InvoiceOption}
								optionLabel={optionLabelInvoice}
								model="invoice"
								include={["contact", "payments", "user"]}
								searchBy={{
									"or:": [
										"contact.%fname",
										"contact.%lname",
										"contact.%email",
										"contact.%phone",
										"%name",
									],
								}}
								error={errors.invoice_id}
								helperText={errors.invoice_id?.message}
								disabled={lastState || isUpdate}
							/>
						)}
					/>
				</Grid>
				{canDoResetOfChargeBack && (
					<Grid
						item
						md={1}
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<Button
							size="small"
							text="Re-open"
							variant="outlined"
							onClick={doReopenDialog}
							disabled={
								isLoadingProcesingState ||
								!Boolean(procesingState?.id)
							}
						/>
					</Grid>
				)}
				<Grid item md={canDoResetOfChargeBack ? 5 : 6}>
					{isUpdate && (
						<StatesComponent
							ref={stateRef}
							workflow={"refund_basic"}
							objectId={objectData?.id}
							handleObjectStatesUpdate={onStateUpdate}
							readOnly={lastState}
						/>
					)}
				</Grid>
				<div
					style={{ position: "relative", paddingLeft: 3 }}
					className={
						!Boolean(selectedInvoice) ? classes.blur : undefined
					}
				>
					{!Boolean(selectedInvoice) && (
						<Box className={classes.backdrop} />
					)}
					<Grid item container spacing={2} xs={12}>
						<Grid item md={8}>
							<Controller
								name="subject"
								control={control}
								defaultValue={""}
								rules={{
									required: {
										value: true,
										message: "Subject is required",
									},
								}}
								render={({ onChange, onBlur, value }) => (
									<TextField
										label="Subject"
										name="subject"
										variant="outlined"
										value={value}
										onChange={onChange}
										onBlur={onBlur}
										error={!!errors.subject}
										helperText={errors.subject?.message}
										style={{ width: "100%" }}
										disabled={
											lastState ||
											!Boolean(selectedInvoice)
										}
									/>
								)}
							/>
						</Grid>
						<Grid item md={4}>
							<Controller
								name="is_chargeback"
								control={control}
								defaultValue={false}
								render={({ onChange, onBlur, value }) => (
									<Checkbox
										checked={isChargeBack}
										onChange={() => {
											onChange(!isChargeBack);
											setIsChargeBack(!isChargeBack);
										}}
										label={"Is chargeback"}
										disabled={lastState}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						spacing={2}
						xs={12}
						style={{
							marginTop: 10,
						}}
					>
						<Grid
							container
							item
							direction="column"
							spacing={2}
							xs={Boolean(selectedInvoice) ? 8 : 12}
						>
							<Grid item>
								<Controller
									name="body"
									defaultValue={""}
									control={control}
									render={({ onChange, onBlur, value }) => (
										<FroalaEditor
											config={editorConfig}
											ref={froala} //create a ref to the instance
											tag="textarea"
											model={value}
											onModelChange={onChange}
											disabled={lastState}
										/>
									)}
								/>
							</Grid>
							<Grid container item spacing={2}>
								<Grid item xs={4}>
									<Controller
										name="amount"
										control={control}
										defaultValue={0.0}
										rules={
											isChargeBack
												? null
												: {
														validate: (data) =>
															data <= maxAmount ||
															`Quantity exceeds max available: ${formatPrice(
																maxAmount
															)}`,
												  }
										}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<CurrencyTextField
												label={"Amount"}
												variant="outlined"
												currencySymbol="$"
												outputFormat="number"
												maximumValue={9999999999}
												onChange={(event, value) =>
													onChange(value)
												}
												minimumValue={0.0}
												//value={setCustomValue?setCustomValue:0}
												//className={"yea"}
												value={value}
												style={{ width: "100%" }}
												disabled={lastState}
												decimalPlaces={2}
												error={errors.amount}
												helperText={
													errors.amount?.message
												}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={4}>
									<Controller
										name="priority_id"
										control={control}
										defaultValue={-1}
										rules={{
											min: {
												value: 1,
												message: "Must select one",
											},
										}}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<SelectWColor
												label={"Priority"}
												variant="outlined"
												onChange={onChange}
												value={value}
												emptyLabel="Select one"
												options={priorities}
												error={!!errors.priority_id}
												helperText={
													errors.priority_id?.message
												}
												loading={prioritiesLoading}
												disabled={lastState}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={4}>
									<Controller
										name="reasons_id"
										control={control}
										defaultValue={-1}
										rules={{
											min: {
												value: 1,
												message: "Must select one",
											},
										}}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<Select
												label={"Reasons"}
												variant="outlined"
												onChange={onChange}
												value={value}
												emptyLabel="Select one"
												options={reasons}
												error={!!errors.reasons_id}
												helperText={
													errors.reasons_id?.message
												}
												disabled={lastState}
												loading={reasonsLoading}
											/>
										)}
									/>
								</Grid>
								<Grid item md={6}>
									<Controller
										name="building_id"
										control={control}
										defaultValue={-1}
										rules={{
											required: "Building required",
										}}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<Select
												label={"Building"}
												variant="outlined"
												onChange={onChange}
												allowEmpty={true}
												value={value ? `${value}` : -1}
												options={
													buildings
														? buildings.map(
																(building) => {
																	return {
																		...building,
																		...{
																			color: null,
																		},
																	};
																}
														  )
														: []
												}
												error={!!errors.building_id}
												helperText={
													errors.building_id?.message
												}
												disabled={lastState}
												loading={buildingsLoading}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<Controller
										name="group_id"
										control={control}
										defaultValue={-1}
										rules={{
											min: {
												value: 1,
												message: "Must select one",
											},
										}}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<Select
												label={"Group"}
												variant="outlined"
												onChange={onChange}
												value={
													value && value != ""
														? value
														: -1
												}
												emptyLabel="Select one"
												options={groups}
												error={!!errors.group_id}
												helperText={
													errors.group_id?.message
												}
												disabled={lastState}
												loading={groupsLoading}
											/>
										)}
									/>
								</Grid>
								<Grid item md={6}>
									<Controller
										name="department_id"
										defaultValue={-1}
										control={control}
										rules={{
											required: "Department required",
										}}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<Select
												label={"Departments"}
												variant="outlined"
												onChange={onChange}
												disabled={
													watch("building_id") ==
														null || lastState
												}
												value={value ? `${value}` : -1}
												options={departments}
												allowEmpty={true}
												error={!!errors.department_id}
												helperText={
													errors.department_id
														?.message
												}
												loading={deparmentsLoading}
											/>
										)}
									/>
								</Grid>
								<Grid item md={6}>
									<Controller
										name="user_assigned_id"
										defaultValue={-1}
										control={control}
										rules={{
											required: {
												value: true,
												message:
													"Assigned user required",
											},
										}}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<AutocompleteGeneric
												label="Assigned user"
												objectId={
													isUpdate
														? objectData?.user_assigned_id
														: !canSelectAnyUser
														? userId
														: undefined
												}
												onChange={onChange}
												RenderOption={UserOption}
												optionLabel={optionLabelUser}
												model="user"
												include={[
													"department",
													"languages",
												]}
												searchBy={{
													"or:": [
														"%fname",
														"%lname",
														"%phone",
														"%email",
													],
												}}
												error={errors.user_assigned_id}
												helperText={
													errors.user_assigned_id
														?.message
												}
												disabled={
													lastState ||
													!canSelectAnyUser
												}
											/>
										)}
									/>
								</Grid>
								<Grid item md={6}>
									<Controller
										name="invoice_items"
										control={control}
										defaultValue={[]}
										render={({
											onChange,
											onBlur,
											value,
										}) => (
											<MultipleSelect
												label="Items"
												options={invoiceItems}
												value={
													value?.map((v) =>
														isInteger(v) ? v : v.id
													) ?? []
												}
												onChange={(value) =>
													onChange(value)
												}
												styleProp={{
													width: "100%",
												}}
												disabled={lastState}
											/>
										)}
									/>
								</Grid>
								<Grid item md={6}>
									<Controller
										name="tags"
										defaultValue={tagOptions(
											objectTags || []
										)}
										control={control}
										as={({ onChange, value }) => (
											<Controls.AutocompleteTagsD
												label="Tags"
												disabled={lastState}
												defaultValue={tagOptions(
													objectTags || []
												)}
												options={tagOptions(tags)}
												value={parseTagValue(value)}
												onChange={(value) =>
													onChange(value)
												}
												tags={tags}
											/>
										)}
									/>
								</Grid>
								{isUpdate && (
									<Grid item md={12}>
										<Accordion
											expanded={expanded == "history"}
											onChange={handleChangeExpanded(
												"history"
											)}
										>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<Typography
													variant="h6"
													style={{
														display: "flex",
														alignItems: "center",
														gap: "5px",
													}}
												>
													<History /> Contact History
													{historySuccessLoading && (
														<IconButton
															onClick={() =>
																setCalendarOpenDialog(
																	true
																)
															}
															style={{
																paddingBottom: 0,
															}}
														>
															<Grid
																container
																direction="column"
																alignItems="center"
															>
																<Event />
																<Typography variant="caption">
																	Calendar
																</Typography>
															</Grid>
														</IconButton>
													)}
												</Typography>
											</AccordionSummary>
											<AccordionDetails
												style={{
													display: "block",
													maxWidth: "unset",
												}}
											>
												{historyLoading ? (
													<LinearProgress />
												) : (
													<>
														<Carousel
															cards={[
																...(history?.map(
																	(h) => ({
																		...h,
																		title: moment(
																			h.date
																		).format(
																			"DD/MM/YYYY HH:mm"
																		),
																		content:
																			(
																				<Box
																					direction="column"
																					display="flex"
																					flexDirection="column"
																				>
																					<Typography variant="subtitle">
																						Via:{" "}
																						{h
																							?.via
																							?.via ??
																							"Unknow"}
																					</Typography>
																					<Visibility
																						style={{
																							marginRight:
																								"auto",
																							marginLeft:
																								"auto",
																						}}
																					/>
																					{h?.user_created && (
																						<Typography
																							variant="subtitle"
																							noWrap={
																								true
																							}
																						>
																							By:{" "}
																							{
																								h
																									.user_created
																									.dropdown_name
																							}
																						</Typography>
																					)}
																				</Box>
																			),
																	})
																) ?? []),
																{
																	title: "Add",
																	content: (
																		<Add />
																	),
																},
															]}
															onClick={
																handleShowHistory
															}
														/>
														{isHistoryRefetching && (
															<LinearProgress />
														)}
													</>
												)}
											</AccordionDetails>
										</Accordion>
									</Grid>
								)}
								{(objectData?.media_count > 0 ||
									!lastState) && (
									<Grid xs={12}>
										<FileComponent
											entityId={objectData?.id}
											entityType={"rfs_tickets"}
											disabled={lastState}
											filecount={
												objectData?.media_count ?? 0
											}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={Boolean(selectedInvoice) ? 4 : 0}
						>
							{selectedInvoice && (
								<RefundDoc
									invoice={selectedInvoice}
									selectedItems={watch("invoice_items")}
									amount={amount ?? objectData?.amount}
									ticket={objectData}
								/>
							)}
						</Grid>
					</Grid>
				</div>
			</Grid>
			<ConfirmDialog
				confirmDialog={confirmationDialog}
				setConfirmDialog={setConfirmationDialog}
				confirmLoading={isChangingState}
			></ConfirmDialog>
			<Popup
				openPopup={openDialog}
				title={"SpecifyTask"}
				onClose={() => setOpenDialog(false)}
				isfullWidth={true}
			>
				{loadingTask ? (
					<LinearProgress />
				) : (
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(handleSubmitEdit)}>
							<TaskForm
								{...{
									objectData: taskInfo,
									userId: userId,
									permissions: permissions,
								}}
							/>
							<DialogActions>
								<Button
									text="Save"
									disabled={
										!methods.formState.isDirty ||
										updateIsLoading ||
										!Boolean(selectedInvoice)
									}
									onClick={methods.handleSubmit(
										handleSubmitEdit
									)}
								/>
								<Button
									text="Close"
									onClick={handleCloseDialog}
									// disabled={updatingOrCreating}
								/>
							</DialogActions>
							{updateIsLoading && <LinearProgress />}
						</form>
					</FormProvider>
				)}
			</Popup>
			<Popup
				openPopup={contactHistoryOpenDialog}
				title={"Contact History"}
				onClose={() => setContactHistoryOpen(false)}
				isfullWidth={true}
			>
				<ContactInfoHistory
					element={contactHistoryElement}
					onCancel={handleHistoryCancel}
					onUpdate={handleHistoryUpdate}
					updating={isHistoryCreating}
				/>{" "}
			</Popup>
			<Popup
				openPopup={calendarOpenDialog}
				title={"Calendar"}
				onClose={() => setCalendarOpenDialog(false)}
				isfullWidth={true}
			>
				<Calendar
					localizer={calendarLocalizer}
					startAccessor="start"
					endAccessor="end"
					style={{
						height: 500,
						minWidth: 700,
					}}
					events={calendarEvents}
					views={["month"]}
					onSelectEvent={handleShowHistory}
					onSelectSlot={(slot) =>
						handleShowHistory({
							date: slot.start,
						})
					}
					selectable={true}
				/>
			</Popup>
		</>
	);
};

export default RefundTicketForm;
