import React, { useEffect } from "react";
import { Chip, Grid, Typography, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { useState } from "react";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguagesService } from "../../redux/slices/languages";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  error: {
    color: "#ca3a3a",
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 5,
  },
}));

export default function SpecialPaymentLinkForm(props) {
  const {
    onSuccessfulSubmit,
    invoiceID,
    setNotify = null,
    onClose = null,
    minValue = 0,
  } = props;
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const file = watch("file");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { languages } = useSelector((state) => state.languages);
  const { authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const classes = useStyles();

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onSubmit = async (payload) => {
    try {
      setIsSubmitting(true);
      //payload["file"] = await fileToBase64(payload["file"]);
      payload["invoice_id"] = invoiceID;

      const { data } = await Api.createSpecialPaymentLink(payload);

      if (typeof onSuccessfulSubmit == "function") {
        onSuccessfulSubmit();
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log("error: ", error);
      if (setNotify) {
        if (error.status == 409 && (error?.data?.error || false)) {
          setNotify({
            isOpen: true,
            message: error.data.error,
            type: "error",
          });
        } else if (error.status == 401) {
          setNotify({
            isOpen: true,
            message: "Forbidden",
            type: "error",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Something went wrong",
            type: "error",
          });
        }
      }
    } finally {
      if (typeof onClose == "function") {
        onClose();
      }
    }
  };

  const languageOptions = () => {
    return languages.map((language) => {
      return {
        id: language.id,
        title: language.name,
      };
    });
  };

  useEffect(() => {
    if (isEmpty(languages)) {
      dispatch(fetchLanguagesService(authUser.organization_id));
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="card_number"
            control={control}
            defaultValue={""}
            as={
              <Controls.Input
                style={{ width: "100%" }}
                fullWidth
                label="Card Number*"
                type="number"
              />
            }
            rules={{ required: "Card number is required" }}
          />
          {errors.card_number && (
            <p className={classes.error}>{errors.card_number.message}</p>
          )}
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="language_id"
            control={control}
            defaultValue={""}
            as={({ onChange, value }) => (
              <Controls.Select
                styleProp={true}
                label={"Language*"}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                options={languageOptions()}
                value={value}
              />
            )}
            rules={{
              required: "Language is required",
            }}
          />
          {errors.language_id && (
            <p className={classes.error}>{errors.language_id.message}</p>
          )}
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="max_amount"
            control={control}
            defaultValue={""}
            as={
              <Controls.Input
                style={{ width: "100%" }}
                fullWidth
                label="Max amount"
                type="number"
              />
            }
            rules={{
              validate: (value) => {
                if (value !== null && value !== "" && value <= 0) {
                  return "Max Amount must be greater than 0";
                } else {
                  if (
                    value !== null &&
                    value !== "" &&
                    value < parseFloat(minValue)
                  ) {
                    return (
                      "The minimum value for the transaction is " + minValue
                    );
                  }
                }
              },
            }}
          />
          {errors.max_amount && (
            <p className={classes.error}>{errors.max_amount.message}</p>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={4}>
              <Typography style={{ fontWeight: 500, color: "#4b4b4b" }}>
                Credit Card Authorization:{" "}
              </Typography>
            </Grid>
            {file ? (
              <Grid item xs={4}>
                <a href={window.URL.createObjectURL(file)} target="_blank">
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {file.name}
                  </Typography>
                </a>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={file ? 4 : 8}>
              <Controller
                name={"file"}
                control={control}
                defaultValue=""
                as={({ onChange, value }) => (
                  <Controls.DropzoneDialog
                    startIcon={<Iconify icon="material-symbols:upload" />}
                    label="Upload File"
                    st={{
                      backgroundColor: "rgb(230 236 241)",
                      borderRadius: 12,
                      color: "rgb(1, 87, 155)",
                      marginTop: 10,
                    }}
                    onChange={(files) => {
                      onChange(files[0]);
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      {/* 
      {errors.file && <p className={classes.error}>{errors.file.message}</p>} */}

      <div style={{ display: "flex", justifyContent: "right", marginTop: 8 }}>
        <Controls.Button
          disabled={isSubmitting}
          type="submit"
          startIcon={<Iconify icon="ic:round-send" />}
          text={isSubmitting ? "Sending..." : "Send"}
        />
      </div>
    </form>
  );
}
