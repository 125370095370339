import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  users: [],
  usersDropdown: [],
  usersDropdownLoading: false,
  invoiceUsersDropdown: [],
  invoiceUsersDropdownLoading: false,
  historyUsersDropdown: [],
  historyUsersDropdownLoading: false,
  usersDropdownPlain: [],
  usersDropdownPlainLoading: false,
  fields: [],
  saveLoading: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsers: (state) => {
      state.loading = true;
    },
    getUsersSuccess: (state, { payload }) => {
      state.users = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getUsersFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },

    getUsersDropdown: (state) => {
      state.usersDropdownLoading = true;
    },
    getUsersDropdownSuccess: (state, { payload }) => {
      state.usersDropdown = payload.data;
      state.usersDropdownLoading = false;
      state.errors = [];
    },
    getUsersDropdownFailure: (state, { payload }) => {
      state.usersDropdownLoading = false;
      state.errors = [...state.errors, payload.message];
    },

    getInvoiceUsersDropdown: (state) => {
      state.invoiceUsersDropdownLoading = true;
    },
    getInvoiceUsersDropdownSuccess: (state, { payload }) => {
      state.invoiceUsersDropdown = payload.data;
      state.invoiceUsersDropdownLoading = false;
      state.errors = [];
    },
    getInvoiceUsersDropdownFailure: (state, { payload }) => {
      state.invoiceUsersDropdownLoading = false;
      state.errors = [...state.errors, payload.message];
    },

    getHistoryUsersDropdown: (state) => {
      state.historyUsersDropdownLoading = true;
    },
    getHistoryUsersDropdownSuccess: (state, { payload }) => {
      state.historyUsersDropdown = payload.data;
      state.historyUsersDropdownLoading = false;
      state.errors = [];
    },
    getHistoryUsersDropdownFailure: (state, { payload }) => {
      state.historyUsersDropdownLoading = false;
      state.errors = [...state.errors, payload.message];
    },

    getUsersDropdownPlain: (state) => {
      state.usersDropdownPlainLoading = true;
    },
    getUsersDropdownPlainSuccess: (state, { payload }) => {
      state.usersDropdownPlain = payload.data;
      state.usersDropdownPlainLoading = false;
      state.errors = [];
    },
    getUsersDropdownPlainFailure: (state, { payload }) => {
      state.usersDropdownPlainLoading = false;
      state.errors = [...state.errors, payload.message];
    },
    createUser: (state) => {
      state.saveLoading = true;
    },
    createUserSuccess: (state, { payload }) => {
      state.users = [...state.users, payload.data];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    createUserFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    updateUser: (state) => {
      state.saveLoading = true;
    },
    updateUserSuccess: (state, { payload }) => {
      const index = findIndex(state.users, ["id", payload.data.id]);
      const user = { ...state.users[index], ...payload.data };

      let users = [...state.users];
      users[index] = user;

      state.users = [...users];
      state.loading = false;
      state.errors = [];
      state.saveLoading = false;
    },
    updateUserFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
      state.saveLoading = false;
    },
    deleteUser: (state) => {},
    deleteUserSuccess: (state, { payload }) => {
      state.users = state.users.filter((user) => {
        return user.id !== payload;
      });
    },
    deleteUserFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    getCustomFields: (state) => {
      //state.loading = true;
    },
    getCustomFieldsSuccess: (state, { payload }) => {
      state.fields = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getCustomFieldsFailure: (state, { payload }) => {
      state.loading = false;
      state.fields = [];
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  getUsersDropdown,
  getUsersDropdownSuccess,
  getUsersDropdownFailure,
  getInvoiceUsersDropdown,
  getInvoiceUsersDropdownSuccess,
  getInvoiceUsersDropdownFailure,
  getHistoryUsersDropdown,
  getHistoryUsersDropdownSuccess,
  getHistoryUsersDropdownFailure,
  getUsersDropdownPlain,
  getUsersDropdownPlainSuccess,
  getUsersDropdownPlainFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
  getCustomFields,
  getCustomFieldsSuccess,
  getCustomFieldsFailure,
} = usersSlice.actions;

export const usersSelector = (state) => state.users;
export default usersSlice.reducer;

export function fetchUsersService(orgId) {
  return async (dispatch) => {
    dispatch(getUsers());

    try {
      const response = await Api.getUsers(orgId);
      dispatch(getUsersSuccess(response.data));
    } catch (error) {
      dispatch(getUsersFailure(error));
    }
  };
}

export function fetchUsersServiceDropdown(orgId) {
  return async (dispatch) => {
    dispatch(getUsersDropdown());

    try {
      const response = await Api.getUsersDropdown(orgId);
      dispatch(getUsersDropdownSuccess(response.data));
    } catch (error) {
      dispatch(getUsersDropdownFailure(error));
    }
  };
}

export function fetchInvoiceUsersServiceDropdown(orgId) {
  return async (dispatch) => {
    dispatch(getInvoiceUsersDropdown());

    try {
      const response = await Api.getInvoiceUsersDropdown(orgId);
      dispatch(getInvoiceUsersDropdownSuccess(response.data));
    } catch (error) {
      dispatch(getInvoiceUsersDropdownFailure(error));
    }
  };
}

export function fetchHistoryUsersServiceDropdown(orgId) {
  return async (dispatch) => {
    dispatch(getHistoryUsersDropdown());

    try {
      const response = await Api.getHistoryUsersDropdown(orgId);
      dispatch(getHistoryUsersDropdownSuccess(response.data));
    } catch (error) {
      dispatch(getHistoryUsersDropdownFailure(error));
    }
  };
}

export function fetchUsersServiceDropdownPlain(orgId) {
  return async (dispatch) => {
    dispatch(getUsersDropdownPlain());

    try {
      const response = await Api.getUsersDropdownPlain(orgId);
      dispatch(getUsersDropdownPlainSuccess(response.data));
    } catch (error) {
      dispatch(getUsersDropdownPlainFailure(error));
    }
  };
}

export function createUserService(payload) {
  return async (dispatch) => {
    dispatch(createUser());

    try {
      const response = await Api.saveUser(payload);

      dispatch(createUserSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(createUserFailure(error));
      return error;
    }
  };
}

export function updateUserService(id, payload) {
  return async (dispatch) => {
    dispatch(updateUser());

    try {
      const response = await Api.updateUser(id, payload);

      dispatch(updateUserSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(updateUserFailure(error));
      return error;
    }
  };
}

export function deleteUserService(id) {
  return async (dispatch) => {
    dispatch(deleteUser());

    try {
      const response = await Api.deleteUser(id);

      dispatch(deleteUserSuccess(id));
    } catch (error) {
      dispatch(deleteUserFailure(error));
    }
  };
}

export function fetchCustomFieldsService(orgId) {
  return async (dispatch) => {
    dispatch(getCustomFields());

    try {
      const response = await Api.getUserCustomFields(orgId);
      dispatch(getCustomFieldsSuccess(response.data));
    } catch (error) {
      dispatch(getCustomFieldsFailure(error));
    }
  };
}
