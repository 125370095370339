import React, { useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { fetchBuildingsService } from "../redux/slices/buildings";


const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "6px 16px",
        flexGrow: 1,
    },
    form: {
        justifySelf: "self-end",
    },
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const Logo = styled.img`
  width: 150px;
  heidth: auto;
`;

function BuildingSwitch(props) {

    const { onSelectBuilding } = props;
    const { buildings } = useSelector((state) => state.buildings);

    const { authUser } = useSelector((state) => state.auth);



    const dispatch = useDispatch();

    const renderBuildingMenu = () => {
        return buildings.filter((building) => {
            const userBuildings = authUser.buildings.data.map((building) => {
                return building.id;
            })
            return userBuildings.includes(building.id);
        }).map((building) => {
            return (
                <StyledMenuItem key={building.id} onClick={() => {
                    //console.log(building);
                    onSelectBuilding(building);
                }}>
                    <Logo src={building.logo} alt={building.name} />
                </StyledMenuItem>
            )
        })
    }

    useEffect(() => {
        const orgId = authUser.organization_id;
        if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));
    }, []);

    return (
        <>

            {renderBuildingMenu()}
        </>
    )
}

export default BuildingSwitch;