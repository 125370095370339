import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Popup from "../Popup";
import { Save as SaveIcon } from "@material-ui/icons";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Button,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Iconify from "../Iconify";
import LoadingScreen2 from "../../components/LoadingScreen2";
import { useGoogleLogin } from "@react-oauth/google";
import { isEmpty } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { storeEmailAccount } from "../../modules/email_client/slices/EmailClientSlices";

const useStyles = makeStyles((theme) => ({
  verticalSeparator: {
    backgroundColor: "#EEE",
    height: "100%",
    cursor: "ew-resize",
    display: "flex",
    alignItems: "center",
  },
}));

function mailLoadingAddGoogleEmail(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { accounts, accountsLoaded, accountsLoading } = props;
  const {
    emailAccounts: { saveloading: emailAccountsSaving },
  } = useSelector((state) => state.EmailClient);

  const [authCode, setAuthCode] = useState(null);

  const handleCloseAuthCodePopup = async () => {
    setAuthCode(null);
    setValueAuthCode("auth_code", "");
    setValueAuthCode("name", "");
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    control: controlAuthCode,
    handleSubmit: handleSubmitAuthCode,
    formState: { errors: errorsAuthCode },
    setValue: setValueAuthCode,
    register,
  } = useForm({
    defaultValues: {
      name: "",
      auth_code: "",
    },
  });

  const handleError = (error) => {
    let message = error.data?.message ? error.data.message : "Unknow error";

    if (error.data?.errors) {
      let es = Object.values(error.data.errors)
        .reduce((carry, item) => {
          return [...carry, ...item];
        }, [])
        .join(". ");
      message = message + es;
    }

    showMessage(message, "error");
  };

  const onSubmitAuthCode = async (data) => {
    dispatch(storeEmailAccount(data, true))
      .then((ret) => {
        // console.log(ret);
        handleCloseAuthCodePopup();
      })
      .catch(handleError);
  };

  const showMessage = async (msg, type = "success") => {
    enqueueSnackbar(msg, {
      variant: type,
    });
  };

  const loginWithGoogleCode = async (code) => {
    setAuthCode(code);
    setValueAuthCode("auth_code", code.code);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: loginWithGoogleCode,
    onError: (error) => showMessage(error, "error"),
    // prompt:'consent',
    scope: "https://mail.google.com/",
    flow: "auth-code",
    select_account: true,
    ux_mode: "popup",
  });

  return (
    <Box display="flex" flexWrap="nowrap">
      {accountsLoading ? (
        <Box>
          <LoadingScreen2
            backgroundColor="#006C9C"
            color="#FFFFFF"
            style={{ left: "12vw" }}
          />{" "}
        </Box>
      ) : isEmpty(accounts) ? (
        <>
          <Box
            style={{
              left: "11vw",
              bottom: "12vh",
              zIndex: 99999,
              width: "100%",
              height: "100%",
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" style={{ marginLef: 10 }}>
              {"Please Add a Google Acount"}
            </Typography>
          </Box>
          <Box
            style={{
              left: "12vw",
              bottom: 0,
              zIndex: 99999,
              width: "100%",
              height: "100%",
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={loginWithGoogle}
              key={"AddGoogleAccount"}
              variant="outlined"
              startIcon={
                <Iconify
                  icon={"flat-color-icons:google"}
                  width={25}
                  height={25}
                />
              }
              style={{
                borderRadius: "30px",
                height: "40px",
                width: "280px",
                marginRight: 15,
              }}
            >
              Use Google Account
            </Button>
          </Box>
        </>
      ) : (
        ""
      )}

      <Popup
        title={"Gmail account reference name"}
        open={Boolean(authCode)}
        onClose={handleCloseAuthCodePopup}
      >
        <form onSubmit={handleSubmitAuthCode(onSubmitAuthCode)}>
          <Box display="flex" flexDirection="column" style={{ gap: "10px" }}>
            <Typography>
              Please specify a name to reference this account
            </Typography>
            <Box display="flex" flexDirection={"column"}>
              <Controller
                control={controlAuthCode}
                label="*Name"
                name="name"
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9]+[a-zA-Z0-9_-]*$/,
                }}
                as={<TextField variant="outlined" />}
              />
              {errorsAuthCode.name && (
                <Alert severity="error">
                  Any name (characters: 'a-z', '-', '_'){" "}
                </Alert>
              )}
            </Box>
            <input type="hidden" name="auth_code" {...register("auth_code")} />

            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              onClick={handleSubmitAuthCode(onSubmitAuthCode)}
              startIcon={
                emailAccountsSaving ? (
                  <CircularProgress style={{ color: "#FFF" }} size={20} />
                ) : (
                  <SaveIcon />
                )
              }
            >
              Save
            </Button>
          </Box>
        </form>
      </Popup>
    </Box>
  );
}

export default mailLoadingAddGoogleEmail;
