import React, { useEffect, useState } from "react";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { rgba, darken } from "polished";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { updateBuildingService } from "../redux/slices/auth";
import { makeStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  Avatar,
  Badge,
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  Menu,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import { sidebarRoutes as routes } from "../routes/index";

import LayersIcon from "@material-ui/icons/Layers";
import { useTranslation } from "react-i18next";
import BuildingSwitch from "./BuildingSwitch";
import CRMUtils from "../utils";
import BuildingSwitchDropdown from "./BuildingSwitchDropdown";

/*k = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));*/

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;

  }
`;

const Logo = styled.img`
  margin-left: -17px;
  height: 14px;
  width: 68px;
  heidth: auto;
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`

  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  cursor: default;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(LayersIcon)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 60%;
  height: 20px;
  margin-left: 2px;
  margin-bottom: 1px;
  padding: 4px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.5)}px;
    padding-right: ${(props) => props.theme.spacing(1.5)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;


const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.725rem;
  display: block;
  padding: 1px;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;

  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

function SidebarCategory({
  user,
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  roles,
  permissions,
  ...rest
}) {
  const hasPermission = userHasPermission(user, permissions);
  const hasRoles = userHasRole(user, roles);
  if (!hasPermission) return false;
  if (!hasRoles) return false;
  return (
    <Category style={{marginLeft: '25%'}} {...rest}>
      {icon}
    </Category>
  );
}

function userHasPermission(user, routePermissions) {
  if (!routePermissions) return true;

  let hasPermission = false;

  const permissions = user.permissions.data.map((permission) => {
    return permission.name;
  });
  routePermissions.forEach((permission) => {
    if (permissions.includes(permission)) {
      hasPermission = true;
    }
  });

  return hasPermission;
}
function userHasRole(user, routeRoles) {
  if (!routeRoles) return true;

  let hasRole = false;
  const roles = user.roles.data.map((role) => {
    return role.name;
  });
 
  routeRoles.forEach((role) => {
    if (roles.includes(role)) {
      hasRole = true;
    }
  });

  return hasRole;
}


const useStyles = makeStyles((theme) => ({
  buildingSelectorWrapper: {
    color: "#000",
    padding: "0",
    margin: "10px",
    marginLeft: "1px",
  },
}));

function SidebarIconsExp({
  isExpanded,
  isViewIcons,
  setIsExpanded,
  setIsViewIcons,
  classes,
  staticContext,
  location,
  user,
  ...rest
}) {
  const { t } = useTranslation("common");

  useEffect(() => {
    
  });
  const classes2 = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const userCanChangeBuilding = CRMUtils.userHasPermission(user, [
    "building_change",
  ]);

  const handleClick = (event) => {
   
    if (!userCanChangeBuilding) return false;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const initOpenRoutes = () => {
    
    const pathName = location.pathname;

    let _routes = {};

    routes
      .filter((route) => {

        return route;
      })
      .forEach((route, index) => {
        const isActive =
          pathName.indexOf(route.path) === 0 ||
          (route.children != undefined &&
            route.children.filter((route) => route.path == location.pathname)
              .length > 0);
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === "/" ? true : false;

        _routes = Object.assign({}, _routes, {
          [index]: isActive || isOpen || isHome,
        });
      });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };
  /*
  <Logo
  src={
    user.building_id
      ? user.building_menu_logo
        ? user.building_menu_logo
        : user.building_logo
      : user.organization.data.menu_logo
  }
  onClick={handleClick}
  />
  */
  const dispatch = useDispatch();

  const changeBuilding = (building) => {
    dispatch(updateBuildingService(building));
    handleClose;
  };
  return (
    <div>
      <Drawer variant="permanent" {...rest}>
        <Scrollbar>
          <List disablePadding>
            

            <Items style={{ marginLeft: "-19px" }}>
              {routes.map((category, index) => (
                <React.Fragment key={index}>
                  {category.children ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        user={user}
                        name=""
                        icon={category.icon}
                        button={true}
                        roles={category.roles}
                        permissions={category.permissions}
                      />
                    </React.Fragment>
                  ) : (
                    <SidebarCategory
                      button={true}
                      name=""
                      activeClassName="active"
                      icon={category.icon}
                      exact
                      badge={category.badge}
                      roles={category.roles}
                    />
                  )}
                </React.Fragment>
              ))}
            </Items>
          </List>
        </Scrollbar>

        <SidebarFooter>
          <Grid container spacing={1}>
            <Grid item style={{ margin: "25%" }}>
              <StyledBadge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  style={{ marginLeft: "-10px" }}
                  alt={user.first_name}
                  src={user.avatar}
                />
              </StyledBadge>
            </Grid>
          </Grid>
        </SidebarFooter>
      </Drawer>
    </div>
  );
}
export default withRouter(SidebarIconsExp);
