import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
} from "./common/sliceFunctions2";

var entitiesName = "appointmentsNewCancelationReasons";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_APPOINTMENT_CANCELATION_REASON";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}

export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code = "POST_APPOINTMENT_CANCELATION_REASON";
  return _createEntityService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "PUT_APPOINTMENT_CANCELATION_REASON";
  return _updateEntityService(dataSlice, events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code = "DELETE_APPOINTMENT_CANCELATION_REASON";
  return _deleteEntityService(dataSlice, events);
}

