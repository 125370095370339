import { findIndex } from "lodash";
import {
  newSlice,
  _fetchEntitiesService,
  _fetchContactEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityService,
  _deleteEntityService,
  _updateOnlyEntityService,
} from "./common/sliceTicketsFunctions";

var entitiesName = "ticketsContact";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_TICKETS";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}

export function fetchEntitiesServiceUser(dataSlice) {
  dataSlice.dataUrl.code = "GET_TICKETS_USER";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}

export function fetchEntitiesServiceAgent(dataSlice) {
  dataSlice.dataUrl.code = "GET_TICKETS_AGENT";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}

export function fetchContactEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_CONTACTTICKETS";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
  dataSlice.dataUrl.contact_id = dataSlice.contact_id
    ? dataSlice.contact_id
    : null;

  return _fetchContactEntitiesService(dataSlice, events);
}

export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code = "POST_TICKET";
  return _createEntityService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "PUT_TICKET";
  return _updateEntityService(dataSlice, events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code = "DELETE_TICKET";
  return _deleteEntityService(dataSlice, events);
}

export function updateOnlyEntityService(payload) {
  return _updateOnlyEntityService(payload, events);
}
