import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";

export function DateFormat(value) {
  //const { date } = props;
  return value != null && value != undefined
    ? moment(value).format("DD/MM/YYYY")
    : "";
}

export function DateTimeFormat(value) {
  //const { date } = props;
  return value != null && value != undefined
    ? moment(value).format("DD/MM/YYYY HH:mm:ss")
    : "";
}
export function DateDefaultFormat(value) {
  return value != null && value != undefined
    ? moment(value).format("YYYY-MM-DD")
    : "";
}

export function MoneyFormat(value, defaultValue = "") {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return value != null && value != undefined
    ? formatter.format(value)
    : defaultValue;
}

export function cloneObject(objectToClone) {
  return Object.assign({}, objectToClone);
}

export const getSlice = (listname, data = null) => {
  let slice = {
    stateListName: listname ? listname : "slice",
    dataUrl: {},
    getFilters: {
      include: [],
      limit: 10,
      page: 0,
      params: [],
      filters: [],
      search: "",
      sort: "",
    },
    dataList: {
      showPageLoading: false,
      successHandle: null,
    },
    dataUpdate: {
      id: null,
      data: null,
      successHandle: null,
      disableAutoUpdate: false,
    },
    dataCreate: {
      data: null,
      successHandle: null,
    },
    dataDelete: {
      id: null,
      successHandle: null,
    },
    errorHandle: null,
  };

  return data ? _.merge(slice, data) : slice;
};

export function useSlice(listname, data = null, deps = []) {
  const [slice, setSlice] = useState(getSlice(listname, data));

  useEffect(() => {
    setSlice(getSlice(listname, data));
  }, deps);

  return slice;
}

export function increase_brightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return (
    "#" +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}

export function increase_darkness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return (
    "#" +
    (0 | ((1 << 8) + r * (1 - percent / 100))).toString(16).substr(1) +
    (0 | ((1 << 8) + g * (1 - percent / 100))).toString(16).substr(1) +
    (0 | ((1 << 8) + b * (1 - percent / 100))).toString(16).substr(1)
  );
}
export function useShowHandleErrors() {
  const showMessage = useShowMessagesSnackbar();

  const handleErrors = useCallback((error) => {
    console.error(error);
    let message = error?.message
      ? error.message
      : error?.data?.message
      ? error.data.message
      : "Unknow error";

    if (error.data?.errors) {
      let es = Object.values(error.data.errors)
        .reduce((carry, item) => {
          return [...carry, ...item];
        }, [])
        .join(". ");
      message = message + es;
    }

    showMessage(message, "error");
  });

  return handleErrors;
}

export function useShowMessagesSnackbar() {
  const { enqueueSnackbar } = useSnackbar();
  const messagesFunction = useCallback((message, type = "success") => {
    enqueueSnackbar(message, {
      variant: type,
    });
  });

  return messagesFunction;
}
