import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import HeadersSidebar from "./HeadersSidebar";
import ConversationPanel from "./ConversationPanel";
import {
  Avatar,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Api from "../../lib/api";
import Iconify from "../Iconify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import IconButton from "../controls/IconButton";
import { findIndex, isEmpty } from "lodash";
import ScrollBar from "react-perfect-scrollbar";
import { indigo } from "@material-ui/core/colors";
import Popup from "../../components/Popup";
import NewChatWithUser from "./NewChatWithUser";
import SearchMessagesPanel from "./SearchMessagesPanel";
import CRMUtils from "../../utils";
import JoinGroup from "./JoinGroup";
import { updateContactFormTemplateService } from "../../redux/slices/contactFormTemplates";
import { updateTotalUnreadChatMessages } from "../../redux/slices/totalUnreadChatMessages";
import { updateGeneralTotalUnreadChatMessages } from "../../redux/slices/generalTotalUnreadChatMessages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  header: {
    padding: "3px 14px",
  },
  loadingIcon: {
    width: 19,
    height: 19,
    color: "#004cff",
    marginRight: 10,
  },
  loadingTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#525d6b",
  },
  panelTitle: {
    fontWeight: 500,
    fontSize: 16,
    color: "#3f3f3f",
    marginLeft: 10,
  },
  searchIcon: {
    color: "rgb(105 118 135)",
    width: 21,
    height: 21,
  },
  smallIcon: {
    width: 16,
    height: 16,
    color: "#445667",
  },
  mediumIcon: {
    width: 20,
    height: 20,
    color: "#445667",
  },
  selectedHeader: {
    padding: "3px 14px",
    backgroundColor: "#e5eaeda3",
  },
  fab: {
    "&:hover": {
      background: "#303e57",
    },
    position: "absolute",
    bottom: theme.spacing(6),
    right: theme.spacing(6),
    background: "#5e6e8b",
  },
}));

const UserToGroupHeaders = (props) => {
  const { totalUnreadChatMessages } = useSelector(
    (state) => state.totalUnreadChatMessages
  );
  const { generalTotalUnreadChatMessages } = useSelector(
    (state) => state.generalTotalUnreadChatMessages
  );
  const [isSearchPanelOpened, setIsSearchPanelOpened] = useState(false);
  const [isJoinToGroupOpened, setIsJoinToGroupOpened] = useState(false);
  const classes = useStyles();
  const {
    customHeight,
    setSelectedMessage,
    selectedHeader,
    setSelectedHeader,
    headers,
    setHeaders,
    setGetMessagesNeeded,
    getMessagesNeeded,
  } = props;
  const [openedHeader, setOpenedHeader] = useState(null);
  const { authUser } = useSelector((state) => state.auth);
  const [loadingHeaders, setLoadingHeaders] = useState(false);
  const userCanJoinToGroup = CRMUtils.userHasPermission(authUser, [
    "user_joingroupinchat",
  ]);
  const [anchorElHeaderOptions, setAnchorElHeaderOptions] = useState(null);
  const dispatch = useDispatch();

  const handleClickHeaderOptions = (event) => {
    setAnchorElHeaderOptions(event.currentTarget);
  };

  const handleCloseHeaderOptions = (event) => {
    setAnchorElHeaderOptions(null);
    setOpenedHeader(null);
  };

  const getHeaders = async () => {
    setLoadingHeaders(true);
    const { data } = await Api.getChatHeaders(authUser.id, {
      model_type: "App\\Models\\Group",
    });
    setHeaders([...data.data]);
    setLoadingHeaders(false);
  };

  useEffect(() => {
    getHeaders();
  }, []);

  const pinChat = async () => {
    const { data } = await Api.updateChatHeader(openedHeader.id, {
      is_pinned: openedHeader.is_pinned ? false : true,
      pinned_at: openedHeader.is_pinned
        ? null
        : moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
    });
    if (selectedHeader && openedHeader.id == selectedHeader.id) {
      setSelectedHeader({ ...selectedHeader, ...data.data });
    }
    const index = findIndex(headers, ["id", openedHeader.id]);
    const updatedEvent = {
      ...headers[index],
      ...data.data,
    };
    let newHeaders = [...headers];
    newHeaders[index] = updatedEvent;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }
      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }
      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
    handleCloseHeaderOptions();
  };

  const getDateLabel = (date) => {
    if (!date) {
      return "";
    }
    if (
      moment(moment(date).format("YYYY-MM-DD")).isSame(
        moment(Date.now()).format("YYYY-MM-DD")
      )
    ) {
      return moment(date).format("hh:mm A");
    } else {
      if (
        moment(moment(date).format("YYYY-MM-DD")).isSame(
          moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD")
        )
      ) {
        return "Yesterday";
      } else {
        return moment(date).format("MM/DD/YY");
      }
    }
  };

  const getFileIcon = (file_type) => {
    if (file_type.includes("image")) {
      return "jam:instant-picture-f";
    } else if (file_type.includes("video")) {
      return "eva:video-fill";
    } else {
      return "iconamoon:file-fill";
    }
  };

  const closeJoinToGroupPopup = () => {
    setIsJoinToGroupOpened(false);
  };

  const joinGroup = async (group) => {
    const { data } = await Api.joinCompanyGroupChat(group);

    setSelectedHeader({ ...data.data });

    let newHeaders = [data.data, ...headers];

    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }
      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }
      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
    setGetMessagesNeeded(true);
  };

  const setUnreadQuantityToZero = (id) => {
    const indexHeader = findIndex(headers, ["id", id]);

    if (totalUnreadChatMessages && !isEmpty(totalUnreadChatMessages)) {
      const indexTotal = findIndex(totalUnreadChatMessages, [
        "model_type",
        "App\\Models\\Group",
      ]);

      if (headers[indexHeader]?.unread_messages || false) {
        const newTotal =
          totalUnreadChatMessages[indexTotal].total -
          headers[indexHeader].unread_messages;
        const updatedEventTotal = {
          ...totalUnreadChatMessages[indexTotal],
          ...{
            total: newTotal < 0 ? 0 : newTotal,
          },
        };

        let newTotals = [...totalUnreadChatMessages];
        newTotals[indexTotal] = updatedEventTotal;

        var generalTotal =
          generalTotalUnreadChatMessages -
          (headers[indexHeader]?.unread_messages || 0);
        dispatch(
          updateGeneralTotalUnreadChatMessages(
            generalTotal > 0 ? generalTotal : 0
          )
        );

        dispatch(updateTotalUnreadChatMessages(newTotals));
      }
    }

    const updatedEventHeader = {
      ...headers[indexHeader],
      ...{ unread_messages: 0 },
    };

    let newHeaders = [...headers];
    newHeaders[indexHeader] = updatedEventHeader;
    newHeaders = newHeaders.sort((a, b) => {
      if (b.is_pinned - a.is_pinned !== 0) {
        return b.is_pinned - a.is_pinned;
      }

      if (a.is_pinned && b.is_pinned) {
        return new Date(b.pinned_at) - new Date(a.pinned_at);
      }

      return (
        new Date(b.conversation.data.last_message_date) -
        new Date(a.conversation.data.last_message_date)
      );
    });
    setHeaders([...newHeaders]);
  };

  if (isSearchPanelOpened) {
    return (
      <SearchMessagesPanel
        setSelectedMessage={setSelectedMessage}
        chatHeaders={headers}
        setGetMessagesNeeded={setGetMessagesNeeded}
        setSelectedHeader={setSelectedHeader}
        setIsOpened={setIsSearchPanelOpened}
        modelType={"App\\Models\\Group"}
      />
    );
  }

  return (
    <div>
      <div
        style={{
          background: "rgb(251, 251, 251)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgb(232 233 237)",
          borderTop: "1px solid rgb(232 233 237)",
        }}
      >
        <Typography className={classes.panelTitle}>Internal Chat</Typography>

        <IconButton
          onClick={() => {
            setIsSearchPanelOpened(true);
          }}
        >
          <Iconify className={classes.searchIcon} icon="ion:search" />
        </IconButton>
      </div>
      <div
        style={{
          backgroundColor: "rgb(251 251 251)",
          minHeight: customHeight
            ? (87 - customHeight).toString() + "vh"
            : "87vh",
          position: "relative",
        }}
      >
        <ScrollBar style={{ maxHeight: "87vh" }}>
          {loadingHeaders ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 8,
                paddingTop: 12,
              }}
            >
              <Iconify
                className={classes.loadingIcon}
                style
                icon="eos-icons:bubble-loading"
              />
              <Typography className={classes.loadingTitle}>
                Loading chats
              </Typography>
            </div>
          ) : (
            ""
          )}
          <List component="nav">
            {headers.map((header) => {
              var lastMessageIcon = { icon: null, color: null };
              if (
                header.conversation &&
                header.conversation.data &&
                header.conversation.data.lastMessage &&
                !isEmpty(header.conversation.data.lastMessage.data) &&
                header.conversation.data.lastMessage.data.from_user_id ==
                  authUser.id
              ) {
                var message = header.conversation.data.lastMessage.data;
                if (message.view) {
                  lastMessageIcon.icon = "ci:check-all";
                  lastMessageIcon.color = "rgb(14 67 227)";
                } else {
                  switch (message.status) {
                    case "sent":
                      lastMessageIcon.icon = "uil:check";
                      lastMessageIcon.color = "rgb(107 110 123)";
                      break;
                    case "failed":
                      lastMessageIcon.icon = "ic:round-error-outline";
                      lastMessageIcon.color = "rgb(225 53 53)";
                      break;
                    default:
                      lastMessageIcon.icon = "uil:clock";
                      lastMessageIcon.color = "#5a5f75";
                      break;
                  }
                }
              }

              return (
                <ListItem
                  onClick={() => {
                    if (!selectedHeader || header.id != selectedHeader.id) {
                      setGetMessagesNeeded(true);
                    }
                    setUnreadQuantityToZero(header.id);
                    setSelectedHeader(header);
                  }}
                  className={
                    selectedHeader && selectedHeader.id == header.id
                      ? classes.selectedHeader
                      : classes.header
                  }
                  button
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ListItemIcon>
                        <Avatar
                          style={{ backgroundColor: "rgb(164 174 185 / 30%)" }}
                        >
                          <Iconify
                            style={{ color: "#616a8d" }}
                            icon={"heroicons:user-group-20-solid"}
                          />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontWeight: 500,
                            color: "#434343",
                            fontSize: 14.4,
                          },
                        }}
                        primary={header?.recipient?.name || "Unknown group"}
                        secondary={
                          !(
                            header &&
                            header.members &&
                            header.members.find((r) => r.id == authUser.id)
                          ) && !userCanJoinToGroup ? (
                            ""
                          ) : !(
                              header.conversation &&
                              header.conversation.data &&
                              header.conversation.data.lastMessage &&
                              !isEmpty(
                                header.conversation.data.lastMessage.data
                              ) &&
                              header.conversation.data.lastMessage.data.body
                            ) ? (
                            header.conversation &&
                            header.conversation.data &&
                            header.conversation.data.lastMessage &&
                            !isEmpty(
                              header.conversation.data.lastMessage.data
                            ) &&
                            header.conversation.data.lastMessage.data
                              .file_url ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {
                                  <Iconify
                                    style={{ width: 16, height: 16 }}
                                    icon={getFileIcon(
                                      header.conversation.data.lastMessage.data
                                        .file_type
                                    )}
                                  />
                                }{" "}
                                <Typography>
                                  {" "}
                                  {header.conversation.data.lastMessage.data
                                    .file_name
                                    ? header.conversation.data.lastMessage.data
                                        .file_name.length > 20
                                      ? header.conversation.data.lastMessage.data.file_name.substring(
                                          0,
                                          20
                                        ) + "..."
                                      : header.conversation.data.lastMessage
                                          .data.file_name
                                    : "File"}
                                </Typography>
                                {lastMessageIcon.icon ? (
                                  <Iconify
                                    style={{ color: lastMessageIcon.color }}
                                    icon={lastMessageIcon.icon}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )
                          ) : header.conversation &&
                            header.conversation.data &&
                            header.conversation.data.lastMessage &&
                            !isEmpty(
                              header.conversation.data.lastMessage.data
                            ) &&
                            header.conversation.data.lastMessage.data.body
                              .length > 26 ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {header.conversation.data.lastMessage.data.body.substring(
                                0,
                                26
                              ) + "..."}
                              {lastMessageIcon.icon ? (
                                <Iconify
                                  style={{ color: lastMessageIcon.color }}
                                  icon={lastMessageIcon.icon}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {header.conversation.data.lastMessage.data.body}
                              {lastMessageIcon.icon ? (
                                <Iconify
                                  style={{ color: lastMessageIcon.color }}
                                  icon={lastMessageIcon.icon}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          )
                        }
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ textAlign: "center" }}>
                        <Typography style={{ fontWeight: 600 }}>
                          {getDateLabel(
                            header?.conversation?.data?.last_message_date ||
                              null
                          )}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {header.is_pinned ? (
                            <Iconify
                              className={classes.smallIcon}
                              icon="fluent:pin-12-filled"
                            />
                          ) : (
                            ""
                          )}
                          {header.unread_messages &&
                          header.members &&
                          header.members.find((r) => r.id == authUser.id) ? (
                            <Avatar
                              style={{
                                width: 20,
                                height: 20,
                                fontSize: 10,
                                fontWeight: 700,
                                marginLeft: 5,
                                background: "#657ec1",
                              }}
                            >
                              {header.unread_messages >= 10
                                ? "+9"
                                : header.unread_messages}
                            </Avatar>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <IconButton
                        style={{ padding: 3 }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenedHeader(header);
                          handleClickHeaderOptions(event);
                        }}
                      >
                        <Iconify
                          className={classes.mediumIcon}
                          icon="mingcute:more-2-fill"
                        />
                      </IconButton>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </ScrollBar>{" "}
        {userCanJoinToGroup ? (
          <Fab
            onClick={() => {
              setIsJoinToGroupOpened(true);
            }}
            className={classes.fab}
          >
            <Iconify
              style={{ width: 32, height: 32, color: "white" }}
              icon="healthicons:group-discussion-meeting"
            />
          </Fab>
        ) : (
          ""
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorElHeaderOptions}
          open={Boolean(anchorElHeaderOptions)}
          onClose={handleCloseHeaderOptions}
        >
          <MenuItem
            onClick={() => {
              pinChat();
            }}
          >
            <ListItemIcon style={{ minWidth: "0px", marginRight: 7 }}>
              <Iconify
                style={{ width: 18, height: 18 }}
                icon={
                  openedHeader && openedHeader.is_pinned
                    ? "fluent:pin-off-24-filled"
                    : "fluent:pin-12-filled"
                }
              />
            </ListItemIcon>
            <Typography>
              {openedHeader && openedHeader.is_pinned
                ? "Unpin chat"
                : "Pin chat"}
            </Typography>
          </MenuItem>
        </Menu>
        <Popup
          fullWidth={true}
          title={"Join the company group chat"}
          openPopup={isJoinToGroupOpened}
          onClose={closeJoinToGroupPopup}
          maxWidth={"sm"}
        >
          <JoinGroup
            joinGroup={joinGroup}
            onClose={() => {
              setIsJoinToGroupOpened(false);
            }}
          />
        </Popup>
      </div>
    </div>
  );
};

export default UserToGroupHeaders;
