import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  portalLayoutRoutes,
  portalLayoutRoutes2,
  checkInAppointmentRoute,
  portallayoutRoutes2TabIndex,
  portalAuthLayoutRoutes,
  genericLayoutRoutes,
  unsuscribeLayoutRoutes,
  appointmentConfirmationLayout,
  merchantLayoutRoutes,
  noLogoLayoutRoutes,
} from "./index";
import { isEmpty, filter } from "lodash";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PortalLayout from "../layouts/Portal";
import CheckInLayout from "../layouts/CheckIn";
import PortalAuthLayout from "../layouts/PortalAuth";
import GenericLayout from "../layouts/Generic";
import NologoLayout from "../layouts/Nologo";
import ExtraRoutesLayout from "../layouts/ExtraRoutes";
import Merchantlayout from "../layouts/Merchant";

import CRMUtils from "../utils";
import AppointmentConfirmationLayout from "../layouts/AppointmentConfirmation";
const Page404 = React.lazy(() => import("../pages/auth/Page404"));

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component, hasInside }, index) => (
        <Route
          key={index}
          path={path}
          exact={hasInside ? false : true}
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

  const Routes = (user) => {
    let dashboardLayoutRoutesL = dashboardLayoutRoutes;
    const portal = localStorage.getItem("portal");
    if (!isEmpty(user) && !portal) {
      const dashboardLayoutRoutesLocal = filter(
        dashboardLayoutRoutes,
        function (item) {
          return item && !isEmpty(item.permissions)
            ? CRMUtils.userHasPermission(user.user, item.permissions)
            : true && item && !isEmpty(item.roles)
            ? CRMUtils.userHasRole(user.user, item.roles)
            : true;
        }
      );
      dashboardLayoutRoutesL = dashboardLayoutRoutesLocal.map((item, index) => {
        return {
          ...item,
          children: item.children
            ? item.children.filter((item) => {
                return !isEmpty(item.permissions)
                  ? CRMUtils.userHasPermission(user.user, item.permissions)
                  : true && !isEmpty(item.roles)
                  ? CRMUtils.userHasRole(user.user, item.roles)
                  : true;
              })
            : null,
        };
      });
    }

  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardLayoutRoutesL)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {childRoutes(PortalLayout, portalLayoutRoutes)}
        {childRoutes(PortalLayout, portalLayoutRoutes2)}
        {childRoutes(CheckInLayout, checkInAppointmentRoute)}
        {childRoutes(PortalLayout, portallayoutRoutes2TabIndex)}
        {childRoutes(PortalAuthLayout, portalAuthLayoutRoutes)}
        {childRoutes(GenericLayout, genericLayoutRoutes)}
        {childRoutes(NologoLayout, noLogoLayoutRoutes)}
        {childRoutes(ExtraRoutesLayout, unsuscribeLayoutRoutes)}
        {childRoutes(AppointmentConfirmationLayout, appointmentConfirmationLayout)}

   		{childRoutes(Merchantlayout, merchantLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
