import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import quoteApproved from "../approved.png";
import moment from "moment";
import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
} from "@material-ui/core";
import VOID from "../assets/img/icons/VOID.png";
import { isEmpty } from "lodash";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import ContactFormTemplateViewer from "./ContactFormTemplateViewer";
import BallotIcon from "@material-ui/icons/Ballot";
import Iconify from "./Iconify";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: "block";
  
  width: 100%;
  margin: "10px",
  padding: "5px",
`;

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;
const Approved = styled.img`
  position: absolute;
  width: 450px;
  opacity: 0.4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;
const Logo = styled.img`
  position: absolute;
  width: 150px;
  top: 15px;
  right: 15px;
`;
const QuoteFooter = styled(MuiBox)`
  ${spacing};
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    mainProduct: {},
    childProduct: {
      //marginLeft: "15px",
      paddingLeft: "15px",
      color: "#999",
    },
  },
  disclaimer: {
    border: "1px solid #999",
    padding: "5px",
    textAlign: "center",
  },
}));

function PrintQuoteDetails(props) {
  const { quote, financingInterest, ItemPriceHlp } = props;
  const { authUser } = useSelector((state) => state.auth);

  const getItemPrice = (price, base, product_apply_financing) => {
    if (financingInterest && product_apply_financing) {
      const value = base ? base : price;

      return parseFloat(value + (value * financingInterest) / 100).toFixed(2);
    }
    return base || price;
  };
  const classes = useStyles();

  const renderItems = () => {
    const items = quote.items.data;
    return items.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell
            component="th"
            scope="row"
            className={
              item && item.parent_id
                ? classes.childProduct
                : classes.mainProduct
            }
          >
            {item.childProducts && item.childProducts.length > 0 && (
              <Iconify
                icon="entypo:shopping-bag"
                style={{ fontSize: 18, color: "gray", marginRight: 10 }}
              />
            )}
            {item.parent_id ? (
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {item.name}
              </p>
            ) : (
              <Typography>
                {item.name}
                {item.is_revision ? (
                  <strong
                    style={{
                      color: "#326a9b",
                      marginLeft: 5,
                      fontWeight: 400,
                    }}
                  >
                    {"(Revision)"}
                  </strong>
                ) : (
                  ""
                )}
              </Typography>
            )}
          </TableCell>
          <TableCell style={{ width: "40%" }}>
            {item.parent_id ? (
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.zero_package_value &&
              !item.isProcedure ? (
                <div>$ 0.00</div>
              ) : (
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgb(14, 85, 126)",
                    backgroundColor: "#deeced",
                    textAlign: "center",
                    padding: "0px 15px",
                    maxWidth: "fit-content",
                    borderRadius: 12,
                  }}
                >
                  {"INCLUDED"}
                </div>
              )
            ) : (
              <ItemPriceHlp item={item} />
            )}
          </TableCell>
          <TableCell style={{ width: "10%", textAlign: "center" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{item.quantity || 1}</Typography>
              {item.void_quantity ? (
                <Typography
                  style={{
                    fontWeight: 500,
                    marginLeft: 5,
                    color: "#a76c65",
                    fontSize: 12,
                  }}
                >{`(${item.void_quantity} VOID)`}</Typography>
              ) : (
                ""
              )}
            </div>
          </TableCell>
          {quote && quote.downpayment_per_procedure ? (
            <TableCell align="right">
              {item.type == "package"
                ? "$" + item.package_downpayment_amount
                : item.downpayment_amount !== null && !item.parent_id
                ? "$" + (item?.downpayment_amount || 0)
                : ""}
            </TableCell>
          ) : (
            ""
          )}
          <TableCell align="right">
            {item && item.parent_id && item.void == 1 ? (
              <img
                src={VOID}
                alt={"VOID"}
                style={{ width: "65px", height: "auto" }}
              />
            ) : item && item.parent_id ? (
              ""
            ) : item.void == 0 ? (
              "$ " +
              calculateItemTotal({
                ...item,
                ...{
                  price:
                    item.type == "package" ? item.package_price : item.price,
                },
              }).toFixed(2)
            ) : (
              <img
                src={VOID}
                alt={"VOID"}
                style={{ width: "65px", height: "auto" }}
              />
            )}
          </TableCell>
        </TableRow>
      );
    });
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };
  const calculateSubtotal = () => {
    const items = quote.items.data;
    return items
      .filter((item) => !item.void)
      .reduce((total, item) => total + calculateItemTotal(item), 0);
  };
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Logo
            src={
              quote.building_id
                ? quote.building_logo
                : quote.organization.data.logo
            }
          />
          {quote.status === "approved" && <Approved src={quoteApproved} />}
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box fontWeight="fontWeightMedium" m={1}>
                Quote # {quote.name}
              </Box>
              <Box fontWeight="fontWeightMedium" m={1}>
                Quote Date: {moment(quote.date).format("YYYY/MM/DD")}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  FROM:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote.building_id
                    ? quote.building_name
                    : quote.organization_name}
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote.building_id
                    ? quote.building_address
                    : quote.organization_address}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  TO:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote.contact.data.full_name}
                </Box>
                <Box fontWeight="fontWeightMedium" m={1}>
                  {quote.contact.data.address || ""}
                </Box>
                <Box fontWeight="fontWeightMedium" m={1}>
                  {quote.contact.data.phone || ""}
                </Box>
                {quote.contact && quote.contact.data.coordinator_name && (
                  <Box m={1}>
                    {" "}
                    Consultant: {quote.contact.data.coordinator_name}
                  </Box>
                )}
                {quote.expiration_date && (
                  <Box m={1}> Expiration Date: {quote.expiration_date}</Box>
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {quote && !isEmpty(quote.expiration_date) && (
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" m={1}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="see"
                      style={{ float: "right" }}
                      disabled
                    >
                      {"Expire: " + quote.expiration_date}
                    </Fab>
                  </Box>
                </Typography>
              )}
            </Grid>
          </Grid>

          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell style={{ width: "40%" }}>Price</TableCell>
                  <TableCell style={{ width: "10%" }}>Quantity</TableCell>
                  {quote && quote.downpayment_per_procedure ? (
                    <TableCell align="right">{"Downpayment"}</TableCell>
                  ) : (
                    ""
                  )}

                  <TableCell align="right">
                    {quote?.quote_total_name
                      ? quote?.quote_total_name
                      : "Total"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderItems()}
                {quote.included && (
                  <TableRow>
                    <TableCell
                      colSpan={quote && quote.downpayment_per_procedure ? 5 : 4}
                    >
                      INCLUDED: {quote.included}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              <TableRow>
                <TableCell />
                <TableCell />
                {quote && quote.downpayment_per_procedure ? <TableCell /> : ""}
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    {quote?.quote_total_name
                      ? quote?.quote_total_name
                      : "Total"}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    $ {calculateTotal().toFixed(2)}
                  </Box>
                </TableCell>
              </TableRow>
            </Table>
          </Card>
        </CardContent>

        <QuoteFooter></QuoteFooter>
      </Card>
      <Card className={classes.card}>
        {!financingInterest && quote?.show_disclaimer ? (
          <Box className={classes.disclaimer} m={10}>
            *** Amount to pay if payment type is different to cash or debit: $
            {(calculateTotal() * 1.15).toFixed(2)} ***
          </Box>
        ) : (
          ""
        )}
      </Card>
    </Wrapper>
  );
}

export default PrintQuoteDetails;
