import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  tasks: [],
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getTasks: (state) => {
      state.loading = true;
    },
    getTasksSuccess: (state, { payload }) => {
      state.tasks = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getTasksFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    createTask: (state) => {},
    createTaskSuccess: (state, { payload }) => {
      state.tasks = [...state.tasks, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createTaskFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateTask: (state) => {},
    updateTaskSuccess: (state, { payload }) => {
      const index = findIndex(state.tasks, ["id", payload.data.id]);
      const user = { ...state.tasks[index], ...payload.data };

      let tasks = [...state.tasks];
      tasks[index] = user;

      state.tasks = [...tasks];
      state.loading = false;
      state.errors = [];
    },
    updateTaskFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteTask: (state) => {},
    deleteTaskSuccess: (state, { payload }) => {
      state.tasks = state.tasks.filter((task) => {
        return task.id !== payload;
      });
    },
    deleteTaskFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getTasks,
  getTasksSuccess,
  getTasksFailure,
  createTask,
  createTaskSuccess,
  createTaskFailure,
  updateTask,
  updateTaskSuccess,
  updateTaskFailure,
  deleteTask,
  deleteTaskSuccess,
  deleteTaskFailure,
} = tasksSlice.actions;
export const tasksSelector = (state) => state.tasks;
export default tasksSlice.reducer;

export function fetchTasksService(orgId) {
  return async (dispatch) => {
    dispatch(getTasks());

    try {
      const response = await Api.getTasks(orgId);

      dispatch(getTasksSuccess(response.data));
    } catch (error) {
      dispatch(getTasksFailure(error));
    }
  };
}

export function createTaskService(payload) {
  return async (dispatch) => {
    dispatch(createTask());
    try {
      const response = await Api.saveTask(payload);

      dispatch(createTaskSuccess(response.data));
    } catch (error) {
      dispatch(createTaskFailure(error));
    }
  };
}

export function updateTaskService(id, payload) {
  return async (dispatch) => {
    dispatch(updateTask());

    try {
      const response = await Api.updateTask(id, payload);

      dispatch(updateTaskSuccess(response.data));
    } catch (error) {
      dispatch(updateTaskFailure(error));
    }
  };
}

export function deleteTaskService(id) {
  console.log("deleteTaskService ", id);
  return async (dispatch) => {
    dispatch(deleteTask());

    try {
      const response = await Api.deleteTask(id);

      dispatch(deleteTaskSuccess(id));
    } catch (error) {
      dispatch(deleteTaskFailure(error));
    }
  };
}
