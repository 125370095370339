import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  videoPlayer: {
    margin: theme.spacing(4),
  },
}));

export default function UltraSoundVideo(props) {
  const { video, isLoading } = props;

  const classes = useStyles();

  useEffect(() => {
  }, [video]);

  return (
    <ReactPlayer
      key={video.id}
      url={video.videoUrl}
      controls={true}
      width={240}
      height={320}
      className={classes.videoPlayer}
    />
  );
}
