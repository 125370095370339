import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Link, makeStyles, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

export default function SearchSidenav(props) {
  let history = useHistory();
  const [value, setValue] = useState(null);
  const { routes } = props;
  const [routesList, setRoutesList] = useState([]);
  const [routesListFiltered, setRoutesListFiltered] = useState([]);
  const { authUser } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common");
  const [openMenu, setOpenMenu] = useState(false);

  const useStyles = makeStyles((theme) => ({
    input: {
      color: "#EEEFF1",
      "&.Mui-focused": {
        color: "#EEEFF1",
      },
    },
    sidebar: {
      zIndex: theme.zIndex.drawer + 10,
    },
  }));

  const classes = useStyles();

  function userHasPermission(user, routePermissions) {
    if (!routePermissions) return true;
    let hasPermission = false;
    const permissions = user.permissions.data.map((permission) => {
      return permission.name;
    });
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  function userHasRole(user, routeRoles) {
    if (!routeRoles) return true;

    let hasRole = false;
    const roles = user.roles.data.map((role) => {
      return role.name;
    });
    routeRoles.forEach((role) => {
      if (roles.includes(role)) {
        hasRole = true;
      }
    });

    return hasRole;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRoutes2 = () => {
    setRoutesList([...routes]);
    var routesVar = [];
    routes.forEach((route) => {
      if (
        userHasPermission(authUser, route.permissions) &&
        userHasRole(authUser, route.roles)
      ) {
        if (route.children) {
          route.children.forEach((child) => {
            if (
              userHasPermission(authUser, child.permissions) &&
              userHasRole(authUser, child.roles)
            ) {
              routesVar.push({ ...{ parent: route.id }, ...child });
            }
          });
        } else {
          routesVar.push(route);
        }
      }
    });

    setRoutesList([...routesVar]);
  };

  useEffect(() => {
    if (routes) {
      getRoutes2();
    }
  }, [routes]);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      option.name
        ? t(`sidebar.${option.name}`, option.name)
        : t(`sidebar.${option.id}`, option.id),
  });

  return (
    <div style={{ width: 260, marginTop: -16 }}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={routesList}
        filterOptions={filterOptions}
        onChange={(event, newValue) => {
          history.push(newValue.path);
        }}
        // input
        renderOption={(option) => {
          return (
            <Link to={option.path} button dense component={NavLink} exact style={{color: 'black'}}>
              <Typography style={{ fontWeight: 500 }}>
                {" "}
                {option.name
                  ? t(`sidebar.${option.name}`, option.name)
                  : t(`sidebar.${option.id}`, option.id)}
              </Typography>
              <Typography style={{ fontSize: "14px", color: "grey" }}>{`${
                option.parent ? ">" + option.parent : ""
              }`}</Typography>
            </Link>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search..."
            margin="normal"
            style={{
              color: "white",
              marginLeft: 18,
              width: "220px",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              borderBottomColor: "#EEEFF1",
            }}
            InputLabelProps={{
              className: classes.input,
            }}
            InputProps={{
              ...params.InputProps,
              className: classes.input,
              disableUnderline: true,
              endAdornment: (
                <div style={{ marginBottom: 2 }}>
                  <SearchIcon style={{ color: "white", marginLeft: "15px" }} />
                </div>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
