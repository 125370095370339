import React from "react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
  spinner: {
    marginLeft: 10,
  },
}));

const AdornedButton = (props) => {
  const classes = useStyles();

  const { children, loading, size, ...rest } = props;
  return (
    <MuiButton
      size={size || "large"}
      classes={{ root: classes.root, label: classes.label }}
      {...rest}
    >
      {children}
      {loading && (
        <CircularProgress
          classes={{ root: classes.spinner }}
          size={20}
          color="secondary"
        />
      )}
    </MuiButton>
  );
};

export default AdornedButton;
