import {
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useEffect, useMemo, useRef, useState } from "react";

const cardWidth = 150;
const cardHeight = 100;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflow: "hidden",
	},
	card: {
		margin: "auto",
		marginTop: 5,
		marginRight: 5,
		width: cardWidth,
		height: cardHeight,
		textAlign: "center",
		position: "relative",
		overflow: "hidden",
		borderRadius: 10,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        '&:hover': {
            backgroundColor: blue[700],
            cursor: 'pointer',
            color: '#FFF',
            '& .MuiButtonBase-root': {
                color:'#FFF'
            }
        }
    },
    iconButton: {

    },
	content: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
	},
	arrow: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
		zIndex: 1,
		color: 0,
	},
	arrowLeft: {
		left: 0,
	},
	arrowRight: {
		right: 0,
	},
}));

const Carousel = ({ cards, onClick }) => {
	const classes = useStyles();
	const [index, setIndex] = useState(0);

	const [parentBoxWidth, setParentBoxWidth] = useState(0);
	const parentBoxRef = useRef();
	let cardinView = useMemo(() => {
		return Math.round(parentBoxWidth / cardWidth)-1;
	}, [parentBoxWidth]);

	const maxIndex = useMemo(() => {
		return cards.length - cardinView;
	}, [cards, cardinView]);

	const handlePrevClick = () => {
		setIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
	};

	const handleNextClick = () => {
		setIndex((prevIndex) =>
			prevIndex === maxIndex ? maxIndex : prevIndex + 1
		);
	};

	useEffect(() => {
		if (cards.length > cardinView) {
			let maxIndex = cards.length - cardinView;
			let _i = index > maxIndex ? maxIndex : index;
			setXPos(_i * -cardWidth);
		}
	}, [index, cards, cardinView]);

	useEffect(() => {
		const element = parentBoxRef?.current;
		if (!element) return;

		const resizeObserver = new ResizeObserver(async (entries) => {
			if (entries[0]) {
				setParentBoxWidth(entries[0].contentRect.width);
			}
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.disconnect();
		};
	}, [parentBoxRef.current]);

	const [xPos, setXPos] = useState(0);

	return (
		<div className={classes.root}>
			<IconButton
				className={`${classes.arrow} ${classes.arrowLeft}`}
                onClick={handlePrevClick}
                style={{
                    display: index==0 ? 'none':'block'
                }}
			>
				<ArrowBackIos />
			</IconButton>
			<IconButton
				className={`${classes.arrow} ${classes.arrowRight}`}
                onClick={handleNextClick}
                style={{
                    display: index==maxIndex ? 'none':'block'
                }}
			>
				<ArrowForwardIos />
			</IconButton>
			<Box position="relative" height={120} ref={parentBoxRef}>
				<Box
					display={"flex"}
					flexDirection={"row"}
					spacing={1}
					position={"absolute"}
					style={{
						transition: "transform 0.5s ease-in-out",
						transform: `translateX(${xPos}px)`,
					}}
				>
					{cards.map((card, i) => (
						<Box
							display={"flex"}
							key={card.id}
						>
							<Card className={classes.card} onClick={()=>onClick && onClick(card)}>
								<CardHeader
									title={card.title}
									titleTypographyProps={{
										variant: "subtitle",
									}}
									style={{
										paddingBottom: 0,
										paddingTop: 10,
									}}
								/>
								<CardContent
									style={{
										paddingTop: 0,
									}}
								>
									<Typography variant="body2" component="p">
										{card.content}
									</Typography>
								</CardContent>
							</Card>
						</Box>
					))}
				</Box>
			</Box>
		</div>
	);
};

export default Carousel;
