import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { isEmpty, find, findIndex, map } from "lodash";
import moment from "moment";
import Api from "../lib/api";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  Container,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import PreopFormDeleted from "./forms/PreopFormDeleted";
import { fetchDocumentTypesService } from "../redux/slices/documenttypes";
import { fetchCancellationMotivesService } from "../redux/slices/cancellationMotives";
import { makeStyles } from "@material-ui/core/styles";
import RecordProductDocuments from "../components/RecordProductDocuments";
import StatusHistories from "../components/StatusHistories";
import CRMUtils from "../utils";
import { fetchEntitiesService as fetchRecordProductCostsService } from "../redux/slices/recordProductCostsSlice.js";
import { useTranslation } from "react-i18next";
import { fetchTemplatesService } from "../redux/slices/recordProductTemplates";
import CommentsRP from "./CommentsRP";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  watermarkedContainer: {
    position: "relative",
  },
  watermark: {
    position: "absolute",
    top: "60%",
    right: "25%",
    transform: "translate(-50%, -50%)",
    opacity: "0.6",
    pointerEvents: "none",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

var dataSliceRecordProductCosts = {
  stateListName: "recordProductCosts",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    //limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function PreoperationDataOrigen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);

  if (authUser) {
    dataSliceRecordProductCosts.orgId = authUser.organization_id;
  }

  const [coordinatorsFromDoctor, setCoordinatorsFromDoctor] = useState(false);

  const { recordProductCosts } = useSelector(
    (state) => state.recordProductCosts
  );

  const { recordProductdatas, closePopup } = props;
  const {
    updateRecordProductData,
    updateRecordProductData2,
    updateRecordProductDataCost,
    updateOnlyTransfer = false,
  } = props;

  const { documentTypes } = useSelector((state) => state.documenttypes);

  const { cancellationMotives } = useSelector(
    (state) => state.cancellationMotives
  );

  const [userDoctors, setUserDoctors] = useState([]);

  const [recordProductForEdit, setRecordProductForEdit] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const { t, i18n } = useTranslation(["common", "records"]);
  const [coordinators, setCoordinators] = useState([]);

  const saveRecordProductTransfer = async (payload) => {
    if (!payload.transfer) {
      payload.organization_id = authUser.organization_id;
      const { data } = await Api.saveRecordProductTransfer(payload);

      const localproductRecord = {
        ...recordProductForEdit,
        recordProductTransfers: {
          data: [
            ...recordProductForEdit.recordProductTransfers.data,
            data.data,
          ],
        },
      };

      updateRecordProductData(localproductRecord);
    } else {
      payload.approved_at = new moment().format("YYYY-MM-DD HH:mm:ss");

      const { data } = await Api.updateRecordProductTransfer(
        payload.id,
        payload
      );

      if (updateOnlyTransfer) {
        updateRecordProductData2(data.data);
      }
      let localRecordProductTransfers =
        recordProductForEdit.recordProductTransfers.data;

      const index = findIndex(localRecordProductTransfers, (e) => {
        return e.id == payload.id;
      });

      localRecordProductTransfers[index] = data.data;

      const localproductRecord = {
        ...recordProductForEdit,
        recordProductTransfers: {
          data: localRecordProductTransfers,
        },
      };

      updateRecordProductData(localproductRecord);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const saveRecordFollowup = async (
    id,
    data,
    recordProductID,
    recordProductData
  ) => {
    try {
      const date = recordProductData.date
        ? new moment(recordProductData.date).format("YYYY-MM-DD HH:mm:ss")
        : null;
      recordProductData.date = date;

      const pre_date = recordProductData.pre_date
        ? new moment(recordProductData.pre_date).format("YYYY-MM-DD HH:mm:ss")
        : null;
      recordProductData.pre_date = pre_date;

      Api.updateProductRecord(recordProductID, recordProductData).then(
        (updatedResult) => {
          const localproductRecord = {
            ...recordProductForEdit,
            ...updatedResult.data.data,
          };
          if (id) {
            Api.updaterecordfollowup(id, data).then((result) => {
              const newRecord = {
                ...localproductRecord,
                recordfollowup: {
                  data: result.data.data,
                },
              };

              updateRecordProductData(newRecord);
              closePopup();
            });
          } else {
            Api.saverecordfollowup(data).then((result) => {
              const newRecord = {
                ...localproductRecord,
                recordfollowup: {
                  data: result.data.data,
                },
              };

              updateRecordProductData(newRecord);
              closePopup();
            });
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const updateOnlyRecordProduct = async (
    recordProductID,
    recordProductData,
    payload
  ) => {
    try {
      payload.approved_at = new moment().format("YYYY-MM-DD HH:mm:ss");

      Api.updateRecordProductTransfer(payload.id, payload).then(
        (updatedResult) => {
          if (updateOnlyTransfer) {
            updateRecordProductData2(updatedResult.data);
          }
          Api.updateProductRecord(recordProductID, recordProductData).then(
            (updatedResult) => {
              const localproductRecord = {
                ...recordProductForEdit,
                ...updatedResult.data.data,
              };

              updateRecordProductData(localproductRecord);
            }
          );
        }
      );
      closePopup();
    } catch (e) {
      console.log(e);
    }
  };
  const getDoctorsUser = async () => {
    const { data } = await Api.getUserDoctors(authUser.id);

    setUserDoctors(data.data);
  };

  const getSurgeonCoordinator = async () => {
    if (recordProductForEdit && recordProductForEdit.responsible_id) {
      const { data } = await Api.getUserCoordinators(
        recordProductForEdit.responsible_id
      );
      setCoordinators(data.data);
    }
  };

  const userCanSuper = CRMUtils.userHasPermission(authUser, [
    "recordproduct_super",
  ]);

  const userFromDoctor = () => {
    if (userCanSuper) {
      return true;
    }

    if (!isEmpty(coordinators)) {
      const index = findIndex(coordinators, ["id", authUser.id]);

      return index >= 0 ? true : false;
    }
    return false;
  };

  useEffect(() => {
    getDoctorsUser();
    if (
      authUser &&
      authUser.organization.data.recordproduct_template_variant == 2
    ) {
      dispatch(fetchTemplatesService(authUser.organization_id, -1));
    }

    if (isEmpty(cancellationMotives))
      dispatch(fetchCancellationMotivesService(authUser.organization_id));

    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));

    if (isEmpty(recordProductCosts))
      dispatch(fetchRecordProductCostsService(dataSliceRecordProductCosts));
  }, []);

  useEffect(() => {
    setRecordProductForEdit(recordProductdatas);
  }, [recordProductdatas]);

  useEffect(() => {
    getSurgeonCoordinator();
  }, [recordProductForEdit]);

  useEffect(() => {
    setCoordinatorsFromDoctor(userFromDoctor());
  }, [coordinators]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
          <Tab label={t("records:records.title")} {...a11yProps(0)} />
          <Tab label={"Comments"} {...a11yProps(1)} />
          <Tab label={t("common:general.Documents")} {...a11yProps(2)} />
          <Tab label={t("common:general.SH")} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <Alert
        severity="error"
        style={{
          borderRadius: 25,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
        }}
      >
        <Typography
          style={{ fontSize: 20, fontStyle: "italic", fontWeight: 600 }}
        >
          DELETED | This EMR is only for record purposes
        </Typography>
      </Alert>

      <TabPanel value={tabValue} index={0}>
        {recordProductForEdit && (
          <PreopFormDeleted
            saveRecordFollowup={saveRecordFollowup}
            recordProductForEdit={recordProductForEdit}
            updateRecordProductData={updateRecordProductData}
            closePopup={closePopup}
            cancellationMotives={cancellationMotives}
            saveRecordProductTransfer={saveRecordProductTransfer}
            updateOnlyRecordProduct={updateOnlyRecordProduct}
            userDoctors={userDoctors}
            userCanEdit={false}
            userCanEditStatus={false}
            coordinatorsFromDoctor={[]}
            userIsBillingSuper={false}
            showInvoice={false}
          />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CommentsRP
          record_id={
            recordProductForEdit ? recordProductForEdit.record.data.id : null
          }
          recordProductForEdit={recordProductForEdit}
          updateRecordProductData={updateRecordProductData}
          canAdd={false}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <RecordProductDocuments
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          recordProductForEdit={recordProductForEdit}
          updateRecordProductData={updateRecordProductData}
          coordinatorsFromDoctor={coordinatorsFromDoctor}
          documentTypes={documentTypes ? documentTypes : []}
          showDelete={false}
          canAdd={false}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <StatusHistories
          record_id={recordProductForEdit ? recordProductForEdit.id : null}
          contact={props.contact}
        />
      </TabPanel>
    </div>
  );
}

export default PreoperationDataOrigen;
