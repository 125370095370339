import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  refundReasonsLoading: false,
  errors: [],
  refundReasons: [],

};

const refundReasonsSlice = createSlice({
  name: "refundReasons",
  initialState,
  reducers: {
    getRefundReasons: (state) => {
      state.loading = true;
      state.refundReasonsLoading = true;
    },
    getRefundReasonsSuccess: (state, { payload }) => {
      state.refundReasons = payload.data;
      state.loading = false;
      state.refundReasonsLoading = false;
      state.errors = [];
    },
    getRefundReasonsFailure: (state, { payload }) => {
      state.loading = false;
      state.refundReasonsLoading = false;
      state.errors = [...state.errors, payload.message];
    },
    createRefundReason: (state) => {},
    createRefundReasonsuccess: (state, { payload }) => {
      state.refundReasons = [...state.refundReasons, payload.data];
      state.loading = false;
      state.errors = [];
    },
    createRefundReasonFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    updateRefundReason: (state) => {},
    updateRefundReasonsuccess: (state, { payload }) => {
      const index = findIndex(state.refundReasons, ["id", payload.data.id]);
      const refundReason = {
        ...state.refundReasons[index],
        ...payload.data,
      };

      let refundReasons = [...state.refundReasons];
      refundReasons[index] = refundReason;

      state.refundReasons = [...refundReasons];
      state.loading = false;
      state.errors = [];
    },
    updateRefundReasonFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    deleteRefundReason: (state) => {},
    deleteRefundReasonsuccess: (state, { payload }) => {
      state.refundReasons = state.refundReasons.filter((refundReason) => {
        return refundReason.id !== payload;
      });
    },
    deleteRefundReasonFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const {
  getRefundReasons,
  getRefundReasonsSuccess,
  getRefundReasonsFailure,
  createRefundReason,
  createRefundReasonsuccess,
  createRefundReasonFailure,
  updateRefundReason,
  updateRefundReasonsuccess,
  updateRefundReasonFailure,
  deleteRefundReason,
  deleteRefundReasonsuccess,
  deleteRefundReasonFailure,
} = refundReasonsSlice.actions;

export const refundReasonsSelector = (state) => state.refundReasons;
export default refundReasonsSlice.reducer;

export function fetchRefundReasonsService(orgId) {
  return async (dispatch) => {
    dispatch(getRefundReasons());

    try {
      const response = await Api.getRefundReasons(orgId);
      dispatch(getRefundReasonsSuccess(response.data));
    } catch (error) {
      dispatch(getRefundReasonsFailure(error));
    }
  };
}

export function createRefundReasonService(payload) {
  return async (dispatch) => {
    dispatch(createRefundReason());

    try {
      const response = await Api.saveRefundReason(payload);

      dispatch(createRefundReasonsuccess(response.data));
    } catch (error) {
      dispatch(createRefundReasonFailure(error));
    }
  };
}

export function updateRefundReasonService(id, payload) {
  return async (dispatch) => {
    dispatch(updateRefundReason());

    try {
      const response = await Api.updateRefundReason(id, payload);

      dispatch(updateRefundReasonsuccess(response.data));
    } catch (error) {
      dispatch(updateRefundReasonFailure(error));
    }
  };
}

export function deleteRefundReasonService(id) {
  return async (dispatch) => {
    dispatch(deleteRefundReason());

    try {
      await Api.deleteRefundReason(id);

      dispatch(deleteRefundReasonsuccess(id));
    } catch (error) {
      dispatch(deleteRefundReasonFailure(error));
    }
  };
}
