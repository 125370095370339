import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Button as MuiButton,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";

import contactErrorImage from "../../assets/img/contacts_error.jpg";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InstagramIcon from "@material-ui/icons/Instagram";
import { updateEntityService as updateContactService } from "../../redux/slices/contactsSlice4.js";
import CRMUtils from "../../utils";
import CommentsContact from "../../components/CommentsContact";
import ContactToDoContacts from "../../components/ContactToDoContacts";
import DocumentsContact from "../../components/DocumentsContact";
import DocumentsSentContacts from "../../components/DocumentsSentContacts";
import EvaluationForm from "../../components/EvaluationForm";
import SmsMmsHistory from "../../components/SmsMmsHistory";
import SmsMmsUserList from "../../components/SmsMmsUserList";
import PhoneCalls from "../../components/PhoneCalls";
import { fetchEntitiesService as fetchUserContactsSMSMMSService } from "../../redux/slices/userSMSMMSSlice.js";
import { fetchEntitiesService as fetchUserContactsSMSMMSByGroupsService } from "../../redux/slices/userSMSMMSByGroupsSlice.js";
import Timeline from "../../components/Timeline";
import { isEmpty } from "lodash";
import { spacing, display } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../lib/api";
import Notification from "../../components/Notification";
import { fetchDocumentTypesService } from "../../redux/slices/documenttypes";
import ContactForm2 from "../../components/forms/ContactForm2";
import { fetchEntitiesService as fetchConsultantGeneralDropdownService } from "../../redux/slices/consultantGeneralDropdownSlice.js";
import { fetchBuildingsService } from "../../redux/slices/buildings";
import Popup from "../../components/Popup";
import ContactFormTemplateViewer from "../../components/ContactFormTemplateViewer";
import SocialPlatformsContacts from "../../components/SocialPlatformsContacts.js";
import TicketsContact from "../../components/TicketsContact.js";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Link from "@material-ui/core/Link";
import QuotesContact from "../../components/QuotesContact.js";
import CreditMemosContact from "../../components/CreditMemosContact.js";
import InvoicesContact from "../../components/InvoicesContact.js";
import IconButton from "../../components/controls/IconButton.js";
import AppointmentsContacts from "../../components/AppointmentsContacts.js";
import { Alert } from "@material-ui/lab";
import Iconify from "../../components/Iconify.js";
import FraudolentContactForm from "../../components/forms/FraudolentContactForm.js";
import ConfirmDialog from "../../components/ConfirmDialog.js";
import ContactProcedures from "../../components/ContactProcedures.js";
import ContactPayments from "../../components/ContactPayments.js";
import ContactRefunds from "../../components/ContactRefunds.js";

var dataSliceUserContactsSMSMMS = {
  stateListName: "userContactsSMSMMS",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
var dataSliceUserContactsSMSMMSByGroups = {
  stateListName: "userContactsSMSMMSByGroups",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
var dataSliceConsultantGeneralDropdown = {
  stateListName: "consultantGeneralDropdown",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceContact = {
  stateListName: "contacts",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceContactTickets = {
  stateListName: "ticketsContact",
  dataUrl: {},
  orgId: 1,
  contact_id: null,

  getFilters: {
    include: [],
    limit: null,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  container: {
    height: "85%",
    marginTop: -8,
  },
  tabPanel: {
    height: "100%",
  },
  socialWrapper: {
    textAlign: "right",
    background: "#f5f5f5",
  },
  socialIcon: {
    borderRadius: "15px",
    padding: "5px",
    margin: "3px",
    border: "1px solid #CCC",
    width: "48px",
    height: "48px",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepperWrapper: {
    background: "black",
    padding: "1px",
    marginBottom: "10px",
  },
  stepperContainer: {
    backgroundColor: "#CCC",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  },
  tab: {
    minWidth: "auto",
    fontSize: "12px",
    fontWeight: 600,
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const {
    children,
    value,
    index,

    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function getSteps() {
  return [
    "Lead In",
    "No answer",
    "Contact Made",
    "Estimate",
    "Needs  Financing",
    "Deposit",
  ];
}

function ContactDetails(props) {
  const dispatch = useDispatch();
  const [possibleFraudolent, setPossibleFraudolent] = useState(false);
  const classes = useStyles();
  const [openFraudPopup, setOpenFraudPopup] = useState(false);
  const {
    consultantGeneral = null,
    contactsnew = null,
    isLoading = null,
    setIsLoading = null,
    documentsTab = 0,
    onClose = null,
  } = props;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { opentab = 0 } = props;
  const { authUser } = useSelector((state) => state.auth);

  dataSliceUserContactsSMSMMS.orgId = authUser.id;
  const userCanCreateTickets = CRMUtils.userHasPermission(authUser, [
    "tickets_create",
  ]);
  const userCanEdit = CRMUtils.userHasPermission(authUser, ["contact_edit"]);
  const userCanSeeFraudolentReport = CRMUtils.userHasPermission(authUser, [
    "reports_fraudolentcontacts",
  ]);
  const userCanCreateComments = CRMUtils.userHasPermission(authUser, [
    "comment_create",
  ]);

  const userTeamLeader = CRMUtils.userHasPermission(authUser, [
    "contacts_teamleader",
  ]);

  //Permissions
  const pbxInformation = CRMUtils.userHasPermission(authUser, [
    "user_pbxInformation",
  ]);

  const userCanEditAll = CRMUtils.userHasPermission(authUser, [
    "contacts_editall",
  ]);
  const userCanUnmarkContacts = CRMUtils.userHasPermission(authUser, [
    "contacts_unmarkasfraudolentall",
  ]);

  const userCanMakeTransferDateRequests = CRMUtils.userHasPermission(authUser, [
    "contact_makedatetransferrequest",
  ]);

  const userCanMakeSuperTransferDateRequests = CRMUtils.userHasPermission(
    authUser,
    ["contact_supermakedatetransferrequest"]
  );
  const userIsCallCenter =
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterManager"])
      ? true
      : false;

  const userIsBilling =
    CRMUtils.userHasPermission(authUser, ["dashboard_billingBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingAssistant"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingManager"]) ||
    CRMUtils.userHasPermission(authUser, [
      "dashboard_billingManagerAssistant",
    ]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_billingMGAssistant"])
      ? true
      : false;

  const userIsFrontDesk =
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskManager"])
      ? true
      : false;

  const userIsSocialMedia =
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaManager"])
      ? true
      : false;
  const userIsCallCenterFrontDeskSocialMedia =
    userIsCallCenter || userIsFrontDesk || userIsSocialMedia;

  // Contacts Tabs Permissions
  const userCanViewTimelineTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewTimelineTab",
  ])
    ? true
    : false;
  const userCanViewSmsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewSmsTab",
  ])
    ? true
    : false;
  const userCanViewCallsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewCallsTab",
  ])
    ? true
    : false;
  const userCanViewTodosTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewTodosTab",
  ])
    ? true
    : false;
  const userCanViewCommentsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewCommentsTab",
  ])
    ? true
    : false;
  const userCanViewDocumentsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewDocumentsTab",
  ])
    ? true
    : false;
  const userCanViewQuotesTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewQuotesTab",
  ])
    ? true
    : false;
  const userCanViewInvoicesTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewInvoicesTab",
  ])
    ? true
    : false;
  const userCanViewCreditsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewCreditsTab",
  ])
    ? true
    : false;
  const userCanViewSocialTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewSocialTab",
  ])
    ? true
    : false;
  const userCanViewTicketsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewTicketsTab",
  ])
    ? true
    : false;
  const userCanViewDocsSentTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewDocsSentTab",
  ])
    ? true
    : false;
  const userCanViewEvaluationsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewEvaluationsTab",
  ])
    ? true
    : false;
  const userCanViewAppointmentsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewAppointmentsTab",
  ])
    ? true
    : false;
  const userCanViewPaymentsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewPaymentsTab",
  ])
    ? true
    : false;
  const userCanViewRefundsTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewRefundsTab",
  ])
    ? true
    : false;
  const userCanViewContactProceduresTab = CRMUtils.userHasPermission(authUser, [
    "contacts_viewContactProceduresTab",
  ])
    ? true
    : false;

  const userCanViewContactsBySourceOnly = CRMUtils.userHasPermission(authUser, [
    "contacts_viewcontactsbysourceonly",
  ]);

  dataSliceContact.orgId = authUser.organization_id;

  const [tags, setTags] = useState([]);

  const [openSendFormTemplate, setOpenSendFormTemplate] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const userCanSendSMS = CRMUtils.userHasPermission(authUser, [
    "contacts_SendSMS",
  ])
    ? true
    : false;

  const { errors, contactsNSaveLoading } = useSelector(
    (state) => state.contactsN
  );
  const { consultantGeneralDropdown } = useSelector(
    (state) => state.consultantGeneralDropdown
  );
  const { sourcesNewDropdown } = useSelector(
    (state) => state.sourcesNewDropdown
  );
  const { fields } = useSelector((state) => state.contacts);

  const { documentTypes } = useSelector((state) => state.documenttypes);

  const [contactError, setContactError] = useState(null);

  const [contactGroupsDetails, setContactGroupsDetails] = useState([]);
  const [contactUsersDetails, setContactUsersDetails] = useState([]);
  const { buildings } = useSelector((state) => state.buildings);
  const [updateContact, setUpdateContact] = useState(false);
  const [value, setValue] = useState(opentab ? opentab : 0);
  const [contact, setContact] = useState({});
  const [usersSMSMMS, setUsersSMSMMS] = useState([]);
  const [usersSMSMMSByGroups, setUsersSMSMMSByGroups] = useState([]);
  const [selectedUserSMSMMS, setSelectedUserSMSMMS] = useState(
    authUser ? authUser.id : -1
  );
  const [selectedUserSMSMMSByGroup, setSelectedUserSMSMMSByGroup] = useState();

  const [contactDocuments, setContactDocuments] = useState([]);
  const userCanUnmarkPhoneAsInvalidForVoice =
    (CRMUtils.userHasPermission(authUser, [
      "user_unmarkphoneasinvalidforvoice",
    ]) &&
      contact &&
      contact.invalid_phone_for_voice &&
      contact.invalid_phone_for_voice.created_by == authUser.id) ||
    CRMUtils.userHasPermission(authUser, [
      "user_unmarkphoneasinvalidforvoicesuper",
    ]);
  const userCanMarkPhoneAsInvalidForVoice = CRMUtils.userHasPermission(
    authUser,
    ["user_markphoneasinvalidforvoice"]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getMmsTempUrl = async (id, minutes) => {
    const { data } = await Api.getContactMmsTemporalUrl(id, minutes);

    return data;
  };

  const unmarkContactsAsFraudolent = async () => {
    if (contact) {
      await Api.unmarkContactAsFraudolent(contact.id);

      setContact({ ...contact, ...{ fraudolent: { data: null } } });
      setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
    }
  };

  const markPhoneAsInvalidForCalls = async () => {
    if (contact) {
      setConfirmLoading(true);
      await Api.markPhoneNumberAsInvalidForVoice(contact.phone);
      setContact({ ...contact, ...{ is_phone_invalid_for_voice: true } });
      setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
      setConfirmLoading(false);
    }
  };

  const unmarkPhoneAsInvalidForCalls = async () => {
    if (contact) {
      setConfirmLoading(true);
      await Api.unmarkPhoneNumberAsInvalidForVoice(contact.phone);
      setContact({ ...contact, ...{ is_phone_invalid_for_voice: false } });
      setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
      setConfirmLoading(false);
    }
  };

  const getContact = async () => {
    if (props.contact?.id) {
      try {
        const { data } = await Api.getContactPlain(props.contact?.id);
        if (data && data.data) {
          setContact(data.data);
        }
      } catch (e) {
        setContactError(e.data.message);
      }
    }
  };

  const getContact2 = async () => {
    if (props.contact?.id) {
      const { data } = await Api.getContactPlain2(props.contact?.id);
      setContact(data.data);
    }
  };

  const createSocialPlatforms = async (payload) => {
    var newContact = {
      ...contact,
      socialPlatforms: {
        data: payload.data.data,
      },
    };
    setContact(newContact);
  };

  const deleteSocialPlatforms = async (id) => {
    var newContact = {
      ...contact,
      socialPlatforms: {
        data: contact.socialPlatforms.data.filter((socialPlatform) => {
          return socialPlatform.pivot.id !== id;
        }),
      },
    };
    setContact(newContact);
  };

  const updateSocialPlatforms = async (payload) => {
    var newContact = {
      ...contact,
      socialPlatforms: {
        data: payload.data.data,
      },
    };
    setContact(newContact);
  };

  const isPossibleFraudolent = async () => {
    if (props.contact && props.contact.id) {
      const { data } = await Api.possibleFraudolent(props.contact.id);

      setPossibleFraudolent(data);
    }
  };

  const userIsCoordinator_SalesManager =
    CRMUtils.userHasPermission(authUser, ["user_salesGroupManager"]) ||
    authUser.is_coordinator == 1;

  const saveExtraField = async (payload) => {
    const { data } = await Api.saveContactExtraField(contact.id, payload);

    setContact({
      ...contact,
      crmmultifields: {
        ...contact.crmmultifields,
        data: [...contact.crmmultifields.data, data.data],
      },
    });
  };

  const getCoordinatorOptions = () => {
    if (!consultantGeneralDropdown && !consultantGeneral) return [];
    return consultantGeneral
      ? consultantGeneral.map((user) => {
          return {
            id: user.id,
            title: user.dropdown_name + "  (" + user.group_name + ")",
            active: user.active,
          };
        })
      : consultantGeneralDropdown
          /* .filter((user) => {
        return user.is_salesman === 1 || user.is_fake_salesman === 1;
      })*/
          .map((user) => {
            return {
              id: user.id,
              title: user.dropdown_name + "  (" + user.group_name + ")",
              active: user.active,
            };
          });
  };

  const getBuildingOptions = () => {
    if (!buildings) return [];
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const addOrEdit = (payload) => {
    if (contact) {
      dataSliceContact.dataUpdate = {
        id: contact.id,
        data: payload,
        successHandle: addOrEditSuccefull,
      };
      setUpdateContact(true);

      dispatch(updateContactService(dataSliceContact)).then(() => {
        getContact2();
        if (payload.user_id == authUser.id) {
          dispatch(fetchUserContactsSMSMMSService(dataSliceUserContactsSMSMMS));
          dispatch(
            fetchUserContactsSMSMMSByGroupsService(
              dataSliceUserContactsSMSMMSByGroups
            )
          );
        }
      });
    }
  };

  const addOrEditSuccefull = () => {
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const sendDocument = (docName, html, sms_text, mms_text, mms_url) => {
    const payload = {
      doc_name: docName,
      html: encodeURI(html),
      sms_text: sms_text ? sms_text.toString() : null,
      mms_text: mms_text ? mms_text.toString() : null,
      mms_url: mms_url ? mms_url.toString() : null,
    };
    Api.sendFormTemplate(contact.id, payload);
    setOpenSendFormTemplate(false);
    setNotify({
      isOpen: true,
      message: "Send Successfully",
      type: "success",
    });
  };

  /* Common */
  const showMessage = (notifyObj, msg, type = "success") => {
    notifyObj({
      isOpen: true,
      message: msg,
      type: type,
    });
  };

  const genericErrorHandle = () => {
    if (errors && errors.length > 0) {
      let error = errors[errors.length - 1];
      if (
        error ===
          "*** This contact is already assigned to another consultant***" &&
        typeof onClose === "function"
      ) {
        onClose(error);
      }

      showMessage(setNotify, error, "error");
    }
  };

  const consultantErrorHandle = (error) => {
    if (
      error ===
        "*** This contact is already assigned to another consultant***" &&
      typeof onClose === "function"
    ) {
      onClose(error);
    }

    showMessage(setNotify, error, "error");
  };
  dataSliceContact.errorHandle = genericErrorHandle;

  /* End Common */

  dataSliceContact.errorHandle = genericErrorHandle;

  const kFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };
  const renderSocialNetwork = () => {
    return contact.socialPlatforms?.data.map((platform) => {
      switch (platform.name) {
        case "Facebook":
          return (
            <Tooltip
              arrow
              title={`${kFormatter(platform.pivot.followers)} followers`}
            >
              <Link
                key={platform.id}
                href={`https://facebook.com/${platform.pivot.account_name}`}
                target="_blank"
                rel="noopener"
              >
                <FacebookIcon size="large" className={classes.socialIcon} />
              </Link>
            </Tooltip>
          );
        case "Instagram":
          return (
            <Tooltip
              arrow
              title={`${kFormatter(platform.pivot.followers)} followers`}
            >
              <Link
                key={platform.id}
                href={`https://instagram.com/${platform.pivot.account_name}`}
                target="_blank"
                rel="noopener"
              >
                <InstagramIcon size="large" className={classes.socialIcon} />
              </Link>
            </Tooltip>
          );
        case "Youtube":
          return (
            <Tooltip
              arrow
              title={`${kFormatter(platform.pivot.followers)} followers`}
            >
              <Link
                key={platform.id}
                href={`https://youtube.com/channel/${platform.pivot.account_name}`}
                target="_blank"
                rel="noopener"
              >
                <YouTubeIcon size="large" className={classes.socialIcon} />
              </Link>
            </Tooltip>
          );
        default:
          return null;
      }
    });
  };

  const preventDefault = (event) => event.preventDefault();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 4;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getTags = async () => {
    const params = new URLSearchParams([["model", `App\\Models\\Contact`]]);
    const { data } = await Api.getTags(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );
    setTags([...data.data]);
  };

  useEffect(() => {
    isPossibleFraudolent();
    const orgId = authUser.organization_id;
    if (setIsLoading) {
      setIsLoading(true);
    }

    getContact().then(() => {
      if (setIsLoading) {
        setIsLoading(false);
      }
    });

    getTags();
    if (props.contact?.id) {
      smsmmsUsersFunc();
      smsmmsGroupsFunc();
      dataSliceContactTickets.contact_id = props.contact?.id;
    }
    if (isEmpty(documentTypes)) dispatch(fetchDocumentTypesService(orgId));

    if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));

    if (isEmpty(consultantGeneralDropdown) && !consultantGeneral) {
      dispatch(
        fetchConsultantGeneralDropdownService(
          dataSliceConsultantGeneralDropdown
        )
      );
    }
  }, [props.contact?.id]);

  // useEffect(() => {
  //   console.log("contactErrorcontactError", contactError);
  // }, [contactError]);

  const smsmmsUsersFunc = async () => {
    const { data } = await Api.getUsersSmsMss(props.contact?.id);
    setContactUsersDetails(data.data);
  };

  const smsmmsGroupsFunc = async () => {
    const { data } = await Api.getGroupsSmsMss(props.contact?.id);
    setContactGroupsDetails(data.data);
  };

  useEffect(() => {
    if (updateContact) {
      setUpdateContact(false);
      const index = contactsnew
        ? contactsnew.findIndex((a) => a.id == contact.id)
        : -1;
      if (index >= 0) {
        setContact(contactsnew[index]);
      }
    }
  }, [contactsnew]);

  const tabs = [
    {
      icon: (
        <Iconify
          icon={"mdi:chart-timeline-variant-shimmer"}
          width={26}
          height={26}
        />
      ),
      label: "Timeline",
      component: (
        <Timeline
          modelType="Contact"
          model={contact}
          typel="Contact"
          createdAt={contact.created_at}
          users={
            consultantGeneral ? consultantGeneral : consultantGeneralDropdown
          }
          pbxInformation={pbxInformation}
          patient_name={contact?.fname + contact?.lname}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewTimelineTab,
    },
    {
      icon: <Iconify icon={"fa6-solid:comment-sms"} width={26} height={26} />,
      label: "SMS",
      component: (
        <Paper elevation={1} className={classes.paper}>
          <Grid container className={classes.container}>
            <Grid item xs={3}>
              <SmsMmsUserList
                contactGroupsDetails={contactGroupsDetails}
                contactUsersDetails={contactUsersDetails}
                contact_id={contact.id}
                groupsOptions={authUser.communications_groups}
                user_id={authUser.id}
                setSelectedUserSMSMMS={setSelectedUserSMSMMS}
                selectedUserSMSMMS={selectedUserSMSMMS}
                setSelectedUserSMSMMSByGroup={setSelectedUserSMSMMSByGroup}
                selectedUserSMSMMSByGroup={selectedUserSMSMMSByGroup}
              />
            </Grid>
            <Grid item xs={9}>
              {
                <SmsMmsHistory
                  setContact={setContact}
                  contactUsersDetails={contactUsersDetails}
                  consultantErrorHandle={consultantErrorHandle}
                  contact={contact}
                  userID={selectedUserSMSMMS}
                  setSelectedUserSMSMMS={setSelectedUserSMSMMS}
                  setSelectedUserSMSMMSByGroup={setSelectedUserSMSMMSByGroup}
                  selectedUserSMSMMSByGroup={selectedUserSMSMMSByGroup}
                  userCanSend={
                    authUser.organization &&
                    authUser.organization.data &&
                    authUser.organization.data.sms_integration === 1 &&
                    authUser.receive_sms === 1 &&
                    authUser.phone != "" &&
                    authUser.phone != null &&
                    (!userIsCallCenterFrontDeskSocialMedia ||
                      (userIsCallCenterFrontDeskSocialMedia && userCanSendSMS))
                  }
                />
              }
            </Grid>
          </Grid>
        </Paper>
      ),
      hidden: !userCanViewSmsTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify icon={"solar:phone-calling-bold"} width={26} height={26} />
      ),
      label: "Calls",
      component: (
        <PhoneCalls
          contact_id={contact.id}
          consultantErrorHandle={consultantErrorHandle}
          userCanCall={
            authUser.organization &&
            authUser.organization.data &&
            authUser.organization.data.pbx_integration === 1 &&
            authUser.organization.data.pbx_integration_port
          }
        />
      ),
      hidden: !userCanViewCallsTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify
          icon={"material-symbols:assignment-rounded"}
          width={26}
          height={26}
        />
      ),
      label: "To Do",
      component: (
        <ContactToDoContacts
          category={"sales"}
          contact_id={contact.id}
          notify={notify}
          setNotify={setNotify}
          consultantErrorHandle={consultantErrorHandle}
          editable={!userCanViewContactsBySourceOnly && contact.editable}
          canAdd={!userCanViewContactsBySourceOnly}
        />
      ),
      hidden: !userCanViewTodosTab || authUser.organization.data.contact_simple,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify icon={"majesticons:comment-text"} width={26} height={26} />
      ),
      label: "Comments",
      component: (
        <CommentsContact
          contact_id={contact.id}
          consultantErrorHandle={consultantErrorHandle}
          canAdd={
            (userCanEdit &&
              userCanCreateComments &&
              !userIsCoordinator_SalesManager) ||
            (userCanEdit &&
              userCanCreateComments &&
              userIsCoordinator_SalesManager &&
              contact.editable) ||
            (userCanEdit &&
              (userIsCallCenter || userIsFrontDesk || userIsSocialMedia)) ||
            userIsCallCenterFrontDeskSocialMedia ||
            userIsBilling
          }
        />
      ),
      hidden: !userCanViewCommentsTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: <Iconify icon={"bi:file-text-fill"} width={26} height={26} />,
      label: "Documents",
      component: (
        <DocumentsContact
          consultantErrorHandle={consultantErrorHandle}
          contact_id={contact.id}
          documentTypes={documentTypes}
          documentsTab={documentsTab}
          showDelete={true}
          canAdd={
            (userCanEdit && !userIsCoordinator_SalesManager) ||
            (userCanEdit &&
              userIsCoordinator_SalesManager &&
              contact.editable) ||
            (userCanEdit &&
              (userIsCallCenter || userIsFrontDesk || userIsSocialMedia)) ||
            userIsCallCenterFrontDeskSocialMedia ||
            userIsBilling
          }
          canEdit={
            (userCanEdit && !userIsCoordinator_SalesManager) ||
            (userCanEdit && userIsCoordinator_SalesManager && contact.editable)
          }
          canDelete={
            (userCanEdit && !userIsCoordinator_SalesManager) ||
            (userCanEdit && userIsCoordinator_SalesManager && contact.editable)
          }
        />
      ),
      hidden:
        !userCanViewDocumentsTab || authUser.organization.data.contact_simple,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <div style={{ display: "flex", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top: -10,
              left: -19,
              fontSize: 20,
            }}
          >
            **
          </div>

          <Iconify
            icon={"fluent:reciept-24-filled"}
            width={26}
            height={26}
            style={{
              color:
                (authUser.is_salesman == 1 &&
                  contact.weight &&
                  contact.height &&
                  contact.dob &&
                  contact.medical_conditions &&       
                  contact.surgical_history &&
                  (!authUser.organization.data.contact_financing_required ||
                    contact.has_contracts) &&
                  (!authUser.organization.data.contact_validation ||
                    (authUser.organization.data.contact_validation &&
                      contact.validated))) ||
                (authUser.is_salesman == 1 &&
                  contact.weight &&
                  contact.height &&
                  contact.dob &&
                  contact.medical_conditions &&
                  contact.surgical_history &&
                  authUser.organization.data.contact_financing_required &&
                  contact.financing != null &&
                  (!authUser.organization.data.contact_validation ||
                    (authUser.organization.data.contact_validation &&
                      contact.validated)))
                  ? "#00695c"
                  : authUser.is_salesman == 0
                  ? "#00695c"
                  : userIsCoordinator_SalesManager ||
                    userIsCallCenterFrontDeskSocialMedia ||
                    userIsBilling
                  ? "#00695c"
                  : "#607d8b",
            }}
          />
        </div>
      ),
      label: (
        <Tooltip title="This option it is avalible only when all required contact informations is filled!">
          <span>Quotes</span>
        </Tooltip>
      ),
      component: (
        <QuotesContact
          contact_id={contact.id}
          contact={contact}
          userTeamLeader={userIsCoordinator_SalesManager}
          userIsCallCenter={userIsCallCenter}
          userIsSocialMedia={userIsSocialMedia}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden:
        !userCanViewQuotesTab || authUser.organization.data.contact_simple,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager || authUser.is_salesman == 0
          ? false
          : (authUser.is_salesman == 1 &&
              contact.weight &&
              contact.height &&
              contact.dob &&
              contact.medical_conditions &&
              contact.surgical_history &&
              (!authUser.organization.data.contact_financing_required ||
                contact.has_contracts) &&
              (!authUser.organization.data.contact_validation ||
                (authUser.organization.data.contact_validation &&
                  contact.validated))) ||
            (authUser.is_salesman == 1 &&
              contact.weight &&
              contact.height &&
              contact.dob &&
              contact.medical_conditions &&
              contact.surgical_history &&
              authUser.organization.data.contact_financing_required &&
              contact.financing != null &&
              (!authUser.organization.data.contact_validation ||
                (authUser.organization.data.contact_validation &&
                  contact.validated)))
          ? false
          : true,
    },
    {
      icon: (
        <div style={{ display: "flex", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top: -10,
              left: -19,
              fontSize: 20,
            }}
          >
            **
          </div>

          <Iconify
            icon={"fa6-solid:file-invoice-dollar"}
            width={26}
            height={26}
            style={{
              color:
                (authUser.is_salesman == 1 &&
                  contact.weight &&
                  contact.height &&
                  contact.dob &&
                  contact.medical_conditions &&
                  contact.surgical_history &&
                  (!authUser.organization.data.contact_financing_required ||
                    contact.has_contracts) &&
                  (!authUser.organization.data.contact_validation ||
                    (authUser.organization.data.contact_validation &&
                      contact.validated))) ||
                (authUser.is_salesman == 1 &&
                  contact.weight &&
                  contact.height &&
                  contact.dob &&
                  contact.medical_conditions &&
                  contact.surgical_history &&
                  authUser.organization.data.contact_financing_required &&
                  contact.financing != null &&
                  (!authUser.organization.data.contact_validation ||
                    (authUser.organization.data.contact_validation &&
                      contact.validated)))
                  ? "#00695c"
                  : userIsCoordinator_SalesManager ||
                    userIsCallCenterFrontDeskSocialMedia ||
                    userIsBilling
                  ? "#00695c"
                  : authUser.is_salesman == 0 ||
                    authUser.organization.data.contact_simple
                  ? "#00695c"
                  : "#607d8b",
            }}
          />
        </div>
      ),
      label: (
        <Tooltip
          title={
            authUser.organization.data.contact_simple
              ? "Contact Invoices"
              : "This option it is avalible only when all required contact informations is filled!"
          }
        >
          <span>Invoices</span>
        </Tooltip>
      ),
      component: (
        <InvoicesContact
          contact={contact}
          userTeamLeader={userIsCoordinator_SalesManager}
          userIsCallCenter={userIsCallCenter}
          userIsFrontDesk={userIsFrontDesk}
          userIsSocialMedia={userIsSocialMedia}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewInvoicesTab,
      disabled: authUser.organization.data.contact_simple
        ? false
        : userIsCallCenterFrontDeskSocialMedia || userIsBilling
        ? false
        : userIsCoordinator_SalesManager || authUser.is_salesman == 0
        ? false
        : (authUser.is_salesman == 1 &&
            contact.weight &&
            contact.height &&
            contact.dob &&
            contact.medical_conditions &&
            contact.surgical_history &&
            (!authUser.organization.data.contact_financing_required ||
              contact.has_contracts) &&
            (!authUser.organization.data.contact_validation ||
              (authUser.organization.data.contact_validation &&
                contact.validated))) ||
          (authUser.is_salesman == 1 &&
            contact.weight &&
            contact.height &&
            contact.dob &&
            contact.medical_conditions &&
            contact.surgical_history &&
            authUser.organization.data.contact_financing_required &&
            contact.financing != null &&
            (!authUser.organization.data.contact_validation ||
              (authUser.organization.data.contact_validation &&
                contact.validated)))
        ? false
        : true,
    },
    {
      icon: (
        <Iconify icon={"icon-park-twotone:gift-bag"} width={26} height={26} />
      ),
      label: "Credits",
      component: (
        <CreditMemosContact
          contact={contact}
          userTeamLeader={
            userIsCoordinator_SalesManager ||
            userIsCallCenterFrontDeskSocialMedia
          }
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewCreditsTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: <Iconify icon={"raphael:instagram"} width={26} height={26} />,
      label: "Social",
      component: (
        <SocialPlatformsContacts
          contact_id={contact.id}
          userTeamLeader={
            userIsCoordinator_SalesManager ||
            userIsCallCenterFrontDeskSocialMedia
          }
          updateSocialPlatforms={updateSocialPlatforms}
          createSocialPlatforms={createSocialPlatforms}
          deleteSocialPlatforms={deleteSocialPlatforms}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewSocialTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: <Iconify icon={"heroicons:ticket-solid"} width={26} height={26} />,
      label: "Tickets",
      component: (
        <TicketsContact
          contact_id={contact.id}
          canAdd={userCanCreateTickets}
          userTeamLeader={userIsCoordinator_SalesManager}
          contactEditable={contact.editable}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden:
        !userCanViewTicketsTab || authUser.organization.data.contact_simple,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify
          icon={"heroicons:document-arrow-up-solid"}
          width={26}
          height={26}
        />
      ),
      label: "Docs Sent",
      component: (
        <DocumentsSentContacts
          contact_id={contact.id}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewDocsSentTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify icon={"fluent:person-info-24-filled"} width={26} height={26} />
      ),
      label: "Evaluation",
      component: (
        <EvaluationForm
          contact={contact}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewEvaluationsTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify
          icon={"fluent:calendar-rtl-28-filled"}
          width={26}
          height={26}
        />
      ),
      label: "Appointments",
      component: (
        <AppointmentsContacts
          buildings={buildings}
          contact={contact}
          consultantErrorHandle={consultantErrorHandle}
          record_phoneAreaCode={contact?.phoneAreaCode}
        />
      ),
      hidden:
        !userCanViewAppointmentsTab ||
        !CRMUtils.userHasPermission(authUser, [
          "appointmentsnew_viewappointments",
        ]),
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify icon={"fluent:payment-16-filled"} width={26} height={26} />
      ),
      label: "Payments",
      component: (
        <ContactPayments
          contact={contact}
          contact_id={contact.id}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewRefundsTab,
    },
    {
      icon: <Iconify icon={"mdi:credit-card-refund"} width={26} height={26} />,
      label: "Refunds",
      component: (
        <ContactRefunds
          contact_id={contact.id}
          consultantErrorHandle={consultantErrorHandle}
        />
      ),
      hidden: !userCanViewPaymentsTab,
      disabled:
        userIsCallCenterFrontDeskSocialMedia || userIsBilling
          ? false
          : userIsCoordinator_SalesManager &&
            !contact.editable &&
            !userCanEditAll,
    },
    {
      icon: (
        <Iconify
          icon={"material-symbols:health-and-safety-rounded"}
          width={26}
          height={26}
        />
      ),
      label: "Procedures",
      component: <ContactProcedures contact={contact} />,
      hidden:
        !userCanViewContactProceduresTab ||
        !(
          (userCanMakeSuperTransferDateRequests ||
            (userCanMakeTransferDateRequests &&
              contact &&
              contact.user_id == authUser.id)) &&
          contact.record_id &&
          contact.record_id != ""
        ),
    },
  ];

  if (contactError) {
    return (
      <Grid
        container
        direction="row"
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={5} style={{ marginBottom: 30 }}>
          <Grid
            container
            direction="row"
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              height: 60,
              borderRadius: 50,
              boxShadow: "0 0 5px 0 rgb(53 64 82 / 21%)",
              background:
                "linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255)",
            }}
          >
            <Grid
              container
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 35,
              }}
              item
              xs={1}
            >
              <Box
                style={{
                  backgroundColor: "#edd1cc",
                  borderRadius: 25,
                  width: 45,
                  height: 45,
                  boxShadow: "0 0 5px 0 rgb(53 64 82 / 21%)",
                }}
              >
                <Iconify
                  icon="fluent:person-warning-32-filled"
                  width={32}
                  height={32}
                  sx={{ color: "#d36660", margin: 5 }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={11}
              container
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 35,
              }}
            >
              <Typography variant="h4" style={{ color: "#cd3f3a" }}>
                {contactError}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            direction="row"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <img
                src={contactErrorImage}
                alt={contactError}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: 50,
                  boxShadow: "0 0 5px 0 rgb(53 64 82 / 21%)",
                }}
              />
            </Grid>
          </Grid>

          {/* <Box
          component="img"
          src={contactErrorImage}
          alt={contactError}
          style={{
            width: 1,
            height: 1,
            flexShrink: 0,
            objectFit: "cover",
           
          }}
        /> */}
        </Grid>
      </Grid>
    );
  }

  const getTimze = () => {
    const time = moment()
      .tz(contact?.phoneAreaCode)
      .format("MM-DD-YYYY hh:mm A");

    if (time) {
      return (
        <Typography
          variant="subtitle2"
          style={{
            color: "#009688",
            fontFamily:
              '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
          }}
        >
          {`Current Time for This Contact: ${time}`}
        </Typography>
      );
    }

    return (
      <Typography
        variant="subtitle2"
        style={{
          fontStyle: "italic",
          color: "#f44336",
          fontFamily:
            '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
        }}
      >
        Please ensure the phone is already entered and formatted correctly to
        check the current time for this patient.
      </Typography>
    );
  };

  if (isEmpty(contact) || isLoading) return "";

  return (
    <>
      <Helmet title="Contact Details" />
      <div
        style={{
          position: "absolute",
          top: 25,
          left: "25vw",
          zIndex: 1400,
        }}
      >
        {contact.phoneAreaCode &&
        typeof contact.phoneAreaCode === "string" &&
        contact.phoneAreaCode !== "" ? (
          getTimze(contact.phoneAreaCode)
        ) : (
          <Typography
            variant="subtitle2"
            style={{
              fontStyle: "italic",
              color: "#f44336",
              fontFamily:
                '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
            }}
          >
            Please ensure the phone is already entered and formatted correctly
            to check the current time for this patient.
          </Typography>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: 8,
          left: "70vw",
          zIndex: 1400,
        }}
      >
        {contact &&
        isEmpty(contact?.fraudolent?.data) &&
        possibleFraudolent &&
        userCanSeeFraudolentReport ? (
          <Tooltip title="Click to go to Fraudulent Contacts Report">
            <a
              style={{ textDecoration: "none" }}
              href={"fraudulentcontacts"}
              target="_blank"
              rel="noreferrer"
            >
              <Alert severity="warning">
                <Typography
                  style={{
                    color: "#8f4a04",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  Warning: Another contact with the same ID as this one has been
                  marked as fraudolent
                </Typography>
              </Alert>
            </a>
          </Tooltip>
        ) : contact &&
          isEmpty(contact.fraudolent.data) &&
          possibleFraudolent ? (
          <Alert severity="warning">
            <Typography
              style={{
                color: "#8f4a04",
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              Warning: Another contact with the same ID as this one has been
              marked as fraudolent
            </Typography>
          </Alert>
        ) : (
          ""
        )}
        <Tooltip
          title={
            !isEmpty(contact.fraudolent.data)
              ? `Click for more information`
              : ""
          }
        >
          <MuiButton
            onClick={() => {
              setOpenFraudPopup(true);
            }}
            style={{
              borderRadius: 16,
              backgroundColor: !isEmpty(contact.fraudolent.data)
                ? "#ffe8e7"
                : "rgb(238 242 243)",
              color: !isEmpty(contact.fraudolent.data)
                ? "#bf2929"
                : "rgb(72 71 112)",
              marginLeft: 10,
              marginTop: isEmpty(contact.fraudolent.data) ? 7 : 0,
            }}
            startIcon={
              <Iconify
                icon={
                  !isEmpty(contact.fraudolent.data)
                    ? "ri:alarm-warning-fill"
                    : ""
                }
              />
            }
          >
            {contact && !isEmpty(contact.fraudolent.data)
              ? "This contact has been marked as fraudulent!"
              : "Mark as fraudulent"}
          </MuiButton>
        </Tooltip>

        {contact &&
        !isEmpty(contact.fraudolent.data) &&
        (contact.fraudolent.data.created_by == authUser.id ||
          userCanUnmarkContacts) ? (
          <IconButton
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure to unmark this contact as fraudulent?",
                subTitle: "",
                onConfirm: () => unmarkContactsAsFraudolent(),
              });
            }}
          >
            <Iconify
              style={{ color: "#4e73a1", fontSize: 24 }}
              icon="mdi:account-off"
            />
          </IconButton>
        ) : (
          ""
        )}

        {contact &&
        contact.is_phone_invalid_for_voice &&
        userCanUnmarkPhoneAsInvalidForVoice ? (
          <MuiButton
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title:
                  "Are you sure to unmark the phone " +
                  contact.phone +
                  " as invalid for calls?",
                subTitle: "",
                onConfirm: () => unmarkPhoneAsInvalidForCalls(),
              });
            }}
            style={{
              borderRadius: 16,
              backgroundColor: "rgb(215 250 203)",
              color: "rgb(89 163 64)",
              marginLeft: 10,
              marginTop: 0,
            }}
            startIcon={
              <Iconify
                style={{ marginLeft: 5 }}
                icon={"fluent:call-checkmark-24-filled"}
              />
            }
          >
            {"Unmark phone as invalid for calls"}
          </MuiButton>
        ) : (
          ""
        )}
        {contact &&
        !contact.is_phone_invalid_for_voice &&
        userCanMarkPhoneAsInvalidForVoice ? (
          <MuiButton
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title:
                  "Are you sure to mark the phone " +
                  contact.phone +
                  " as invalid for calls?",
                subTitle: "",
                onConfirm: () => markPhoneAsInvalidForCalls(),
              });
            }}
            style={{
              borderRadius: 16,
              backgroundColor: "rgb(238 242 243)",
              color: "rgb(72 71 112)",
              marginLeft: 10,
              marginTop: 7,
            }}
            startIcon={
              <Iconify
                style={{ marginLeft: 5 }}
                icon={"fluent:call-dismiss-24-filled"}
              />
            }
          >
            {"Mark phone as invalid for calls"}
          </MuiButton>
        ) : (
          ""
        )}
      </div>

      <Grid container>
        <Grid item xs={5} style={{ paddingRight: 0, marginRight: 0 }}>
          <Card>
            <ContactForm2
              setContactForEdit={setContact}
              fullScreen
              user={authUser}
              contactForEdit={contact}
              addOrEdit={addOrEdit}
              tags={tags}
              error={errors ? errors : false}
              saveLoading={contactsNSaveLoading}
              buildings={getBuildingOptions()}
              customFields={fields}
              coordinators={getCoordinatorOptions()}
              setOpenSendFormTemplate={setOpenSendFormTemplate}
              userCanEdit={userCanEdit}
              userTeamLeader={userIsCoordinator_SalesManager}
              onSaveExtraField={saveExtraField}
            />
            {contact.social_platforms && (
              <div className={classes.socialWrapper}>
                {renderSocialNetwork()}
              </div>
            )}
          </Card>
        </Grid>
        <Grid item xs={7} style={{ paddingLeft: 0, marginLeft: 0 }}>
          <Paper>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="icon label tabs example"
              initialSelectedIndex={opentab ? opentab : 1}
              className={classes.tabs}
              style={{
                color: "#00695c",
                marginLeft: 0,
                paddingLeft: 0,
                fontSize: 9,
              }}
              TabIndicatorProps={{
                style: { background: "#009688" },
              }}
            >
              {tabs
                .filter((tab) => !tab.hidden)
                .map((tab, index) => {
                  return (
                    <Tab
                      icon={tab.icon}
                      label={tab.label}
                      {...a11yProps(index)}
                      className={classes.tab}
                      disabled={tab?.disabled || false}
                    />
                  );
                })}
            </Tabs>

            {tabs
              .filter((tab) => !tab.hidden)
              .map((tab, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    {tab.component}
                  </TabPanel>
                );
              })}
          </Paper>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />

      {contact && (
        <Popup
          title="Send Form"
          openPopup={openSendFormTemplate}
          fullWidth={true}
          onClose={() => {
            setOpenSendFormTemplate(false);
          }}
        >
          <div className={classes.root}>
            <ContactFormTemplateViewer
              contactForEdit={contact}
              onSendDocument={sendDocument}
            />
          </div>
        </Popup>
      )}

      <ConfirmDialog
        confirmLoading={confirmLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {contact && (
        <Popup
          title="Fraudulent Contact"
          openPopup={openFraudPopup}
          fullWidth={true}
          onClose={() => {
            setOpenFraudPopup(false);
          }}
          maxWidth="sm"
        >
          <div className={classes.root}>
            <FraudolentContactForm
              entityForEdit={contact}
              setEntityForEdit={setContact}
              setOpenFraudPopup={setOpenFraudPopup}
              userCanUnmarkContacts={userCanUnmarkContacts}
            />
          </div>
        </Popup>
      )}
    </>
  );
}

export default ContactDetails;
