import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"

const MultipleSelect = ({ value, onChange, label, options, disabled=false }) => {
    return (
        <FormControl
            style={{ width: "100%"}}
            variant="outlined"
        >
            <InputLabel>{label}</InputLabel>
            <Select label={label} value={value} onChange={onChange} multiple disabled={disabled}>
                {options.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultipleSelect