import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { isEmpty } from "lodash";
import Api from "../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntitiesService as fetchBuildingsDropdownService } from "../../redux/slices/buildingsDropdownSlice.js";
import moment from "moment/moment";
import Iconify from "../Iconify";
import CRMUtils from "../../utils";
import { Tip } from "../controls/Tip";
import HeaderHistoriesToMultiRefundForm from "./HeaderHistoriesToMultiRefundForm";
import { findIndex } from "lodash";
import ScrollBar from "react-perfect-scrollbar";
import { fetchRefundReasonsService } from "../../redux/slices/refundReasons";
import { fetchUsersService } from "../../redux/slices/users";
import { Alert } from "@material-ui/lab";
import BindOnlineTransactionButton from "../BindOnlineTransactionButton.js";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: "90px",
    height: "90px",
  },
  error: {
    marginLeft: 2,
    color: "#d73131",
    fontWeight: 600,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "63%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  primaryHeadingDisabled: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.disabled,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  expandedAccordionSummaryStyle: {
    backgroundColor: "#e3f2fd",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginBottom: 0,
  },
  accordionSummaryStyle: {
    backgroundColor: "#e3f2fd",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 3,
  },
}));

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

function HeaderTitle(props) {
  const { title, icon, divider = true, secondaryEL } = props;

  return (
    <div style={{ marginBottom: 5, marginTop: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify
            style={{
              marginRight: "5px",
              color: "rgb(92 167 143)",
              width: 22,
              height: 22,
            }}
            icon={icon}
          />
          <Typography
            style={{ fontSize: 16, fontWeight: 500, color: "#4d4d4d" }}
          >
            {title}
          </Typography>
        </div>
        {/* Render secondaryEL if it exists */}
        {secondaryEL && <div>{secondaryEL}</div>}
      </div>

      {divider ? <Divider style={{ margin: "7px", marginBottom: 15 }} /> : ""}
    </div>
  );
}

export default function MultiRefundForm(props) {
  const {
    buildingsDropdown,
    buildingsDropdownLoading,
    onClose = null,
  } = useSelector((state) => state.buildingsDropdown);
  const [isLoading, setIsLoading] = useState(false);
  const { contact_id, createMultiRefund } = props;
  const [payments, setPayments] = useState([]);
  const classes = useStyles();
  const [lockDate, setLockDate] = useState(false);
  const { authUser } = useSelector((state) => state.auth);
  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);
  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { users } = useSelector((state) => state.users);
  const { refundReasons } = useSelector((state) => state.refundReasons);
  const [gateways, setGateways] = useState([]);
  const { handleSubmit, setValue, reset, control, errors, watch } = useForm();
  const watchAllFields = watch();
  const dispatch = useDispatch();
  const userCanApplyFullCredit = CRMUtils.userHasPermission(authUser, [
    "refund_applycredit",
  ]);
  const userCanCreateManualChargeback = CRMUtils.userHasPermission(authUser, [
    "refund_manualchargeback",
  ]);
  const userCanCreateOnlineRefund = CRMUtils.userHasPermission(authUser, [
    "refund_create_online",
  ]);

  const [totalToRefund, setTotalToRefund] = useState(0);

  // Bind Online Transaction
  const userCanBindOnlineTransaction = CRMUtils.userHasPermission(authUser, [
    "merchants_bindonlinetransaction",
  ]);

  const [openBindTransactionPopup, setOpenBindTransactionPopup] =
    useState(false);

  const [merchantIdVerification, setMerchantIdVerification] = useState({
    billingInformation: { cardholder: null, last_4: null },
    transactionId: null,
    isVerified: null,
    isLoading: false,
    message: null,
  });

  const getContactPayments = async () => {
    setIsLoading(true);
    const { data } = await Api.getContactPayments(contact_id);
    setPayments([...data.data]);

    setIsLoading(false);
  };

  const getGateways = async () => {
    const params = new URLSearchParams([["contact_id", contact_id]]);

    const { data } = await Api.getPaymentGateways(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );

    setGateways([...data.data]);
  };

  const paymentOptions = (manualChargeBackOnly = false) => {
    return gateways
      .filter(
        (gateway) =>
          !manualChargeBackOnly || gateway.is_available_for_manual_chargeback
      )
      .map((gateway) => ({
        id: gateway.name,
        title: gateway.name,
      }));
  };

  const buildingOptions = () => {
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  useEffect(() => {
    if (contact_id) {
      getContactPayments();
    }
  }, [contact_id]);

  useEffect(() => {
    if (isEmpty(buildingsDropdown) && !buildingsDropdownLoading) {
      dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
    }
    if (isEmpty(refundReasons)) {
      dispatch(fetchRefundReasonsService(authUser.organization_id));
    }
    if (isEmpty(users)) {
      dispatch(fetchUsersService(authUser.organization_id));
    }
    getGateways();
  }, []);

  useEffect(() => {
    if (watchAllFields.building_id && !userCanEditPaymentDateLock) {
      const building = buildingsDropdown.find((building) => {
        return building.id === watchAllFields.building_id;
      });
      if (building && building.lock_payment_date) {
        setValue("payment_date", moment().format("YYYY-MM-DD HH:mm:ss"));

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  }, [watchAllFields.building_id]);

  const refundReasonsOptions = (manualChargeBackOnly = false) => {
    return refundReasons
      .filter(
        (refundReason) =>
          !manualChargeBackOnly ||
          refundReason.is_available_for_manual_chargeback
      )
      .map((refundReason) => ({
        id: refundReason.id,
        title: refundReason.name,
      }));
  };

  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onSubmit = async (data) => {
    var finalObjects = [];

    var keys = [];
    Object.keys(data).forEach((key) => {
      var pos = key.search("-");
      var final = key.substring(pos + 1, key.length);
      var keyWithoutId = key.substring(0, pos);

      if (!finalObjects.find((a) => a.header_id == final)) {
        finalObjects.push({ header_id: final });
      }

      if (!keys.find((a) => a == keyWithoutId)) {
        keys.push(keyWithoutId);
      }
    });

    var payload = [];
    for (const obj of finalObjects) {
      var payloadObject = {};

      keys.forEach((key) => {
        payloadObject[key] = data[`${key}-${obj.header_id}`];
      });

      if (payloadObject["file"]) {
        const result = await fileToBase64(payloadObject["file"]);
        payloadObject["file"] = result;
      }

      payloadObject["new_amount"] = payloadObject.payment_histories.reduce(
        (total, payment) => {
          return (
            total + parseFloat(payment.new_amount ? payment.new_amount : 0)
          );
        },
        0
      );

      /*
        If the userCanBindOnlineTransaction and the merchantIdVerification is verified,
        add the validation data to the formData
      */
      if (userCanBindOnlineTransaction && merchantIdVerification.isVerified) {
        let merchantTransactionIdVerification = {
          transaction_id: merchantIdVerification.transactionId,
          amount: totalToRefund,
          is_refund: true,
        };

        payloadObject["merchant_transaction_id_verification"] = JSON.stringify(
          merchantTransactionIdVerification
        );
      }

      const object = { ...obj, ...payloadObject };
      payload.push(object);
    }

    setIsSubmitting(true);
    await createMultiRefund(payload);
    setIsSubmitting(false);
  };

  const getUserOptions = () => {
    return users
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const getTotalAmountToRefund = () => {
    var totalRef = 0;
    Object.keys(watchAllFields).forEach((key) => {
      if (key.includes("payment_histories")) {
        totalRef += parseFloat(
          watchAllFields[key]
            ? watchAllFields[key].reduce((total, item) => {
                return (
                  total + parseFloat(item.new_amount ? item.new_amount : 0)
                );
              }, 0)
            : 0
        );
      }
    });

    setTotalToRefund(totalRef);
  };

  const getBuildingId = (payment) =>
    watchAllFields[`building_id-${payment.id}`];
  const getTotalAmount = () => totalToRefund;

  useEffect(() => {
    getTotalAmountToRefund();
  }, [watchAllFields]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <div>
          <Typography
            style={{ margin: 5, textAlign: "center", fontWeight: 500 }}
          >
            {" "}
            Loading Payments
          </Typography>
          <LinearProgress />
        </div>
      ) : (
        ""
      )}
      <ScrollBar style={{ maxHeight: 602 }}>
        <div style={{ padding: 4 }}>
          <Tip
            title={`Important: `}
            message={` Only opened accordions will generate refunds`}
          />

          {payments.map((payment) => {
            const onlineOrManual = !userCanCreateOnlineRefund
              ? "manual"
              : watchAllFields[`online_or_manual-${payment.id}`]
              ? watchAllFields[`online_or_manual-${payment.id}`]
              : payment.isOnlineRefundable;

            let localRefundReasons = [];
            let localPaymentTypes = [];
            if (onlineOrManual == "manual") {
              const showOnlyManualChargebackRefundReasons = true;
              localPaymentTypes = paymentOptions(
                showOnlyManualChargebackRefundReasons
              );
              localRefundReasons = refundReasonsOptions(
                showOnlyManualChargebackRefundReasons
              );
            } else {
              localPaymentTypes = paymentOptions();
              localRefundReasons = refundReasonsOptions();
            }
            return (
              <Accordion
                expanded={payment.expanded}
                classes={{
                  root: classes.MuiAccordionroot,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    const index = findIndex(payments, ["id", payment.id]);

                    const updatedEvent = {
                      ...payments[index],
                      ...{
                        expanded: payments[index]["expanded"] ? false : true,
                      },
                    };

                    let newPayments = [...payments];
                    newPayments[index] = updatedEvent;
                    setPayments([...newPayments]);
                  }}
                  className={
                    payment.expanded
                      ? classes.expandedAccordionSummaryStyle
                      : classes.accordionSummaryStyle
                  }
                >
                  <Grid
                    container
                    spacing={2}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 15,
                    }}
                  >
                    <Grid item xs={3}>
                      <ListItem style={{ margin: 0, padding: 0 }}>
                        <ListItemIcon>
                          <Iconify
                            icon={"fluent:payment-28-filled"}
                            width={28}
                            height={28}
                            style={{ color: "#009688" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${payment.payment_type}`}
                          secondary={`${payment.payment_date}`}
                        />
                      </ListItem>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography
                        key={`${payment.id}`}
                        style={{ textAlign: "center" }}
                        className={classes.heading}
                      >
                        Last 4:
                      </Typography>
                      <Typography
                        key={`${payment.id}`}
                        style={{ textAlign: "center" }}
                        className={classes.secondaryHeading}
                      >
                        {payment.cc_last_4 ? payment.cc_last_4 : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        key={`${payment.id}`}
                        style={{ textAlign: "center" }}
                        className={classes.heading}
                      >
                        Payment Total:
                      </Typography>
                      <Typography
                        key={`${payment.id}`}
                        style={{ textAlign: "center" }}
                        className={classes.secondaryHeading}
                      >
                        {`$${(payment.amount
                          ? payment.amount
                          : 0
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        key={`${payment.id}`}
                        style={{ textAlign: "center" }}
                        className={classes.heading}
                      >
                        Building:
                      </Typography>
                      <Typography
                        key={`${payment.id}`}
                        style={{ textAlign: "center" }}
                        className={classes.secondaryHeading}
                      >
                        {`${payment.building_name}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails style={{ display: "grid", marginRight: 10 }}>
                  {payment.expanded ? (
                    <>
                      <Tip
                        title={`Notice: `}
                        message={`* Indicates a required field`}
                      />
                      {(payment.isOnlineRefundable ||
                        payment.isOnlineRefundable == false) && (
                        <Grid
                          container
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Alert
                            severity={
                              payment.isOnlineRefundable &&
                              onlineOrManual == "online"
                                ? "info"
                                : "warning"
                            }
                            style={{
                              margin: 10,
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {payment.isOnlineRefundable &&
                              onlineOrManual == "online"
                                ? `This Refund will be automatically
                                      reflected on the Merchant's Platform`
                                : `This Refund will not be automatically
                                      reflected on the Merchant's Platform`}
                            </Typography>
                          </Alert>

                          {userCanCreateManualChargeback &&
                            payment.isOnlineRefundable &&
                            userCanCreateOnlineRefund && (
                              <Controller
                                name={`online_or_manual-${payment.id}`}
                                control={control}
                                defaultValue="online"
                                as={
                                  <Controls.RadioGroup
                                    items={[
                                      {
                                        id: "online",
                                        title: "ONLINE REFUND",
                                      },
                                      {
                                        id: "manual",
                                        title: "MANUAL CHARGEBACK",
                                      },
                                    ]}
                                  />
                                }
                                // rules={{
                                //   required:
                                //     field.required === 1 ? true : false,
                                // }}
                              />
                            )}
                        </Grid>
                      )}
                      <div
                        style={{
                          padding: "16px 16px",
                          boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 5,
                            borderBottom: "1px solid #8080803b",
                            marginBottom: "14px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <HeaderTitle
                              icon="ion:wallet"
                              title="Refund details"
                              divider={false}
                            />
                            {userCanApplyFullCredit ? (
                              <ListItem>
                                <ListItemIcon
                                  style={{
                                    margin: 0,
                                    marginLeft: 25,
                                    minWidth: 45,
                                  }}
                                >
                                  <Avatar
                                    style={{ backgroundColor: "#e0f2f1" }}
                                  >
                                    <Iconify
                                      icon={"solar:hand-money-linear"}
                                      width={25}
                                      height={25}
                                      style={{ color: "#009688" }}
                                    />
                                  </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Controller
                                      name={`applyCredit-${payment.id}`}
                                      control={control}
                                      defaultValue={0}
                                      as={({ onChange, value }) => (
                                        <Controls.Checkbox
                                          label="Would you like to apply a Total Credit to all Refunds?"
                                          checked={value === 1 ? true : false}
                                          onChange={(value) =>
                                            onChange(value ? 1 : 0)
                                          }
                                        />
                                      )}
                                    />
                                  }
                                />
                              </ListItem>
                            ) : (
                              ""
                            )}
                          </div>

                          {(!payment.isOnlineRefundable ||
                            (payment.isOnlineRefundable &&
                              onlineOrManual === "manual")) && (
                            <Controller
                              name={`file-${payment.id}`}
                              control={control}
                              defaultValue={null}
                              as={({ onChange, value }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {watchAllFields[`file-${payment.id}`] ? (
                                    <a
                                      style={{ marginLeft: 8 }}
                                      target="_blank"
                                      href={window.URL.createObjectURL(
                                        watchAllFields[`file-${payment.id}`]
                                      )}
                                    >
                                      {
                                        watchAllFields[`file-${payment.id}`][
                                          "name"
                                        ]
                                      }
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {errors[`file-${payment.id}`] && (
                                    <p className={classes.error}>
                                      {errors[`file-${payment.id}`].message}
                                    </p>
                                  )}
                                  <Controls.DropzoneDialog
                                    startIcon={
                                      <Iconify icon="basil:upload-solid" />
                                    }
                                    label="Upload Receipt*"
                                    st={{
                                      borderRadius: 22,
                                      padding: "6px 15px",
                                      marginLeft: 25,
                                      backgroundColor: "rgb(229 240 249 / 55%)",
                                    }}
                                    variant="outlined"
                                    onChange={(files) => {
                                      onChange(files[0]);
                                    }}
                                    value={value}
                                  />
                                </div>
                              )}
                              rules={{
                                required: "*Receipt is required",
                              }}
                            />
                          )}
                        </div>

                        <Grid container spacing={2}>
                          {!merchantIdVerification.isVerified &&
                            !merchantIdVerification.transactionId &&
                            (!payment.isOnlineRefundable ||
                              (payment.isOnlineRefundable &&
                                onlineOrManual === "manual")) && (
                              <Grid item xs={6}>
                                <Controller
                                  name={`payment_type-${payment.id}`}
                                  control={control}
                                  defaultValue={null}
                                  as={({ onChange, value }) => (
                                    <Controls.Select
                                      label="Payment Type*"
                                      options={localPaymentTypes}
                                      onChange={(event) =>
                                        onChange(event.target.value)
                                      }
                                      value={value}
                                    />
                                  )}
                                  rules={{
                                    required: "*Payment Type is required",
                                  }}
                                />
                                {errors[`payment_type-${payment.id}`] && (
                                  <p className={classes.error}>
                                    {
                                      errors[`payment_type-${payment.id}`]
                                        .message
                                    }
                                  </p>
                                )}
                              </Grid>
                            )}

                          {(!payment.isOnlineRefundable ||
                            payment.isOnlineRefundable) && (
                            <Grid item xs={6}>
                              <Controller
                                name={`refund_reason_id-${payment.id}`}
                                control={control}
                                defaultValue={null}
                                as={({ onChange, value }) => (
                                  <Controls.Select
                                    label="Refund Reason"
                                    options={localRefundReasons}
                                    onChange={(event) =>
                                      onChange(event.target.value)
                                    }
                                    value={value}
                                  />
                                )}
                                rules={{
                                  required: "*Reason is required",
                                }}
                              />

                              {errors[`refund_reason_id-${payment.id}`] && (
                                <p className={classes.error}>
                                  {
                                    errors[`refund_reason_id-${payment.id}`]
                                      .message
                                  }
                                </p>
                              )}
                            </Grid>
                          )}

                          <Grid item xs={4}>
                            <Controller
                              name={`payment_date-${payment.id}`}
                              type="date"
                              control={control}
                              defaultValue={moment().format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                              as={({ onChange, value }) => (
                                <Controls.DateTimePicker
                                  label="Date*"
                                  fullWidth
                                  shouldCloseOnSelect
                                  defaultValue={moment().format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                  onChange={(date) => onChange(date)}
                                  value={value}
                                  disabled={
                                    !userCanEditPayment ||
                                    lockDate ||
                                    (payment.isOnlineRefundable &&
                                      onlineOrManual == "online")
                                  }
                                />
                              )}
                              rules={{
                                required: "*Date is required",
                              }}
                            />
                            {errors[`payment_date-${payment.id}`] && (
                              <p className={classes.error}>
                                {errors[`payment_date-${payment.id}`].message}
                              </p>
                            )}
                          </Grid>
                          {!payment.isOnlineRefundable && (
                            <>
                              <Grid item xs={4}>
                                <Controller
                                  name={`building_id-${payment.id}`}
                                  control={control}
                                  defaultValue={
                                    payment?.building_id ||
                                    authUser.building_id ||
                                    ""
                                  }
                                  as={({ onChange, value }) => (
                                    <Controls.Select
                                      label="Building*"
                                      options={buildingOptions()}
                                      onChange={(value) => onChange(value)}
                                      value={value}
                                      disabled={!userCanChangeBuilding}
                                    />
                                  )}
                                  rules={{
                                    required: "*Building is required",
                                  }}
                                />
                                {errors[`building_id-${payment.id}`] && (
                                  <p className={classes.error}>
                                    {
                                      errors[`building_id-${payment.id}`]
                                        .message
                                    }
                                  </p>
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                <Controller
                                  name={`responsible_id-${payment.id}`}
                                  control={control}
                                  defaultValue=""
                                  as={({ onChange, value }) => (
                                    <Controls.Select
                                      label="Surgeon"
                                      // disabled={!userCanChangeBuilding}
                                      options={getUserOptions()}
                                      onChange={(event) =>
                                        onChange(event.target.value)
                                      }
                                      value={value}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={8}>
                            <Controller
                              name={`details-${payment.id}`}
                              control={control}
                              defaultValue={""}
                              as={
                                <Controls.Textarea
                                  rows={4}
                                  style={{ width: "100%" }}
                                  label="Details"
                                />
                              }
                            />
                            {errors[`details-${payment.id}`] && (
                              <p className={classes.error}>
                                {errors[`details-${payment.id}`].message}
                              </p>
                            )}
                          </Grid>
                          <Grid
                            style={{
                              marginTop: 12,
                              paddingLeft: 28,
                              display: "grid",
                            }}
                            item
                            xs={4}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              control={
                                <Controller
                                  name={`ptChangePayment-${payment.id}`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ onChange, value }) => (
                                    <SSwitch
                                      key={`ptChangePayment`}
                                      checked={value}
                                      onChange={(value) => {
                                        onChange(value.target.checked);
                                      }}
                                    />
                                  )}
                                />
                              }
                              label={
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      marginBottom: 0.5,
                                      color: "#607d8b",
                                      textAlign: "center",
                                    }}
                                  >
                                    Patient Change Payment
                                  </Typography>
                                </>
                              }
                            />

                            <FormControlLabel
                              labelPlacement="end"
                              control={
                                <Controller
                                  name={`isChargeback-${payment.id}`}
                                  control={control}
                                  defaultValue={false}
                                  render={({ onChange, value }) => (
                                    <SSwitch
                                      key={`isChargeback`}
                                      checked={value}
                                      onChange={(value) => {
                                        onChange(value.target.checked);
                                      }}
                                    />
                                  )}
                                />
                              }
                              label={
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      marginBottom: 0.5,
                                      color: "#607d8b",
                                      textAlign: "center",
                                    }}
                                  >
                                    Chargeback
                                  </Typography>
                                </>
                              }
                            />
                          </Grid>
                        </Grid>
                        <br />
                        {!payment.merchant_transaction_id &&
                        (userCanBindOnlineTransaction ||
                          (watchAllFields[`payment_type-${payment.id}`] &&
                            (gateways.find(
                              (a) =>
                                a.name ==
                                watchAllFields[`payment_type-${payment.id}`]
                            )?.is_card_required ||
                              false))) ? (
                          <>
                            {" "}
                            <HeaderTitle
                              icon="solar:card-bold"
                              title="Transaction details"
                              secondaryEL={
                                (!payment.isOnlineRefundable ||
                                  (payment.isOnlineRefundable &&
                                    onlineOrManual === "manual")) &&
                                userCanBindOnlineTransaction ? (
                                  <BindOnlineTransactionButton
                                    openBindTransactionPopup={
                                      openBindTransactionPopup
                                    }
                                    setOpenBindTransactionPopup={
                                      setOpenBindTransactionPopup
                                    }
                                    setMerchantIdVerification={
                                      setMerchantIdVerification
                                    }
                                    merchantIdVerification={
                                      merchantIdVerification
                                    }
                                    getBuildingId={() => getBuildingId(payment)}
                                    getTotalAmount={getTotalAmount}
                                    isRefund={true}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                            />
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Controller
                                  name={`cardholder-${payment.id}`}
                                  control={control}
                                  defaultValue={
                                    merchantIdVerification?.billingInformation
                                      .cardholder ?? ""
                                  }
                                  as={
                                    <Controls.Input
                                      fullWidth
                                      label="Card holder"
                                      disabled={
                                        merchantIdVerification
                                          ?.billingInformation.cardholder
                                      }
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Controller
                                  name={`cc_last_4-${payment.id}`}
                                  control={control}
                                  defaultValue={
                                    merchantIdVerification?.billingInformation
                                      .last_4 ?? ""
                                  }
                                  as={
                                    <Controls.Input
                                      fullWidth
                                      label="Last 4 CC"
                                      disabled={
                                        merchantIdVerification
                                          ?.billingInformation.last_4
                                      }
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Controller
                                  name={`transaction_id-${payment.id}`}
                                  control={control}
                                  defaultValue={""}
                                  as={
                                    <Controls.Input
                                      fullWidth
                                      label="Transaction id"
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        <Controller
                          name={`payment_histories-${payment.id}`}
                          control={control}
                          defaultValue={null}
                          render={({ onChange, value }) => (
                            <HeaderHistoriesToMultiRefundForm
                              onChange={onChange}
                              header={payment}
                            />
                          )}
                        />
                        {errors[`payment_histories-${payment.id}`] && (
                          <p className={classes.error}>
                            {errors[`payment_histories-${payment.id}`].message}
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </ScrollBar>
      <ListItem
        style={{
          justifyContent: "right",
          backgroundColor: "rgb(255 234 234 / 63%)",
          border: "3px solid white",
          padding: 12,
        }}
      >
        <ListItemIcon style={{ margin: 0, minWidth: 40, marginRight: 10 }}>
          <Avatar style={{ backgroundColor: "rgb(255 204 204)" }}>
            <Iconify
              icon={"fluent:person-money-24-filled"}
              width={25}
              height={25}
              style={{ color: "#dd3023" }}
            />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: "fit-content",
          }}
          primary={`Total To Refund: `}
          secondary={`$${totalToRefund.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
          primaryTypographyProps={{
            style: {
              fontSize: 17,
              fontWeight: 700,
              textAlign: "left",
              color: "#f44336",
            },
          }}
          secondaryTypographyProps={{
            style: {
              marginLeft: 6,
              fontSize: 16,
              fontWeight: 600,
              textAlign: "left",
              color: "#f44336",
            },
          }}
        />
      </ListItem>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: 8,
        }}
      >
        <Controls.Button
          onClick={() => {
            if (typeof onClose == "function") {
              onClose();
            }
          }}
          disabled={isSubmitting}
          variant="contained"
          color="inherit"
          text={"Cancel"}
        />
        <Controls.Button
          disabled={
            isSubmitting ||
            !totalToRefund ||
            totalToRefund == 0 ||
            (merchantIdVerification.transactionId &&
              merchantIdVerification.transactionId != "" &&
              merchantIdVerification.isVerified === false)
          }
          type="submit"
          variant="contained"
          color="primary"
          text={isSubmitting ? "Submitting..." : "Submit"}
        />
      </div>
    </form>
  );
}
