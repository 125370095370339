import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import lime from "@material-ui/core/colors/lime";

import {
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography as MuiTypography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Comments from "../../components/Comments";
import Refunds from "../../components/Refunds";
import Documents from "../../components/Documents";

import Payments from "../../components/Payments";

import Responsible from "../../components/Responsible";
import Timeline from "../../components/Timeline";

import { isEmpty, findIndex } from "lodash";
import { spacing, display } from "@material-ui/system";

import moment from "moment";
import * as momentTimeZone from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import {
  addInvoicePaymentsService,
} from "../../redux/slices/invoices";

import Api from "../../lib/api";

import { fetchPaymentGatewaysService } from "../../redux/slices/paymentgateways";
import { fetchDocumentTypesService } from "../../redux/slices/documenttypes";
import { fetchUsersService } from "../../redux/slices/users";

import ConfirmDialog from "../../components/ConfirmDialog";

import Iconify from "../../components/Iconify";
import { useTranslation } from "react-i18next";
import InvoiceBuilderDeleted from "../../components/InvoiceBuilderDeleted";

import useTable from "../../components/useTable";

import { fetchRefundReasonsService } from "../../redux/slices/refundReasons";
import { orange } from "@material-ui/core/colors";

import { fetchEntitiesService as fetchProductsService } from "../../redux/slices/productsnewSlice.js";
import InvoicePaymentTransferHistories from "../../components/InvoicePaymentTransferHistories";

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceProduct = {
  stateListName: "productsNew",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    height: "100%",
  },
  card: {
    position: "relative",
  },

  ribbon: {
    backgroundColor: "skyblue",
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    padding: 5,
    font: "Lato",
    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 60,
    marginLeft: -40,
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const statusArray = [
  {
    key: "unsend",
    label: "Unsend",
    color: grey[700],
  },
  {
    key: "sent",
    label: "Sent",
    color: blue[700],
  },
  {
    key: "unpaid",
    label: "Unpaid",
    color: green[700],
  },
  {
    key: "partial_paid",
    label: "Partial paid",
    color: lime[700],
  },
  {
    key: "paid",
    label: "Paid in full",
    color: red[700],
  },
  {
    key: "canceled",
    label: "Canceled",
    color: orange[700],
  },
];

function InvoiceDetails(props) {
  const dispatch = useDispatch();
  const {
    readOnlySalesManager = false,
    userIsCallCenter = false,
    userIsSocialMedia = false,
    userIsFrontDesk = false,
    userIsbillingMGAssistant = false,
    setInvoices,
    invoices,
    selectedTab = 0,
    getInvoicesContact = null,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation("invoices");
 
  const [contacts, setContacts] = useState({});
  const { authUser } = useSelector((state) => state.auth);
  dataSliceProduct.orgId = authUser.organization_id;

  const { productsNew } = useSelector((state) => state.productsNew);
  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const { users } = useSelector((state) => state.users);
  const { documentTypes } = useSelector((state) => state.documenttypes);

  const [value, setValue] = useState(props.selectedTab);
  const [invoice, setInvoice] = useState({});
  const [status, setStatus] = useState(invoice ? invoice.status : "unsend");
  const [items, setItems] = useState([]);

  const [dataRefundRequests, setDataRefundRequests] = useState([]);

  const { refundReasons } = useSelector((state) => state.refundReasons);

  const getContractsReports = async ($id) => {
    const { data } = await Api.getInvoiceRefundRequests($id);

    setDataRefundRequests(data.data);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const saveCreditMemo = async (payload) => {};

  const saveResponsible = async (payload) => {
    const { data } = await Api.addInvResponsibles(invoice.id, {
      responsibleId: payload.responsibles.id,
    });

    setInvoice({
      ...invoice,
      items: {
        ...invoice.items,
        data: items,
      },
      responsibles: {
        data: data.data,
      },
    });
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(items);

  const saveInvoice = async (localinvoice) => {
    const invoiceitems = localinvoice.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        product_id: item.product_id,
        item_name: item.name,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });

    const payload = {
      items: invoiceitems,
      status: localinvoice.status,
      included: localinvoice.included,
      contact_id: invoice.contact_id,
      financing_id: localinvoice.financing_id,
      interest: localinvoice.interest ? localinvoice.interest : null,
      user_id: localinvoice.user_id ? localinvoice.user_id : null,
    };

    const { data } = await Api.updateInvoice(invoice.id, payload);
    setInvoice(data.data);
    if (data.data) {
      if (getInvoicesContact) {
        getInvoicesContact();
      } else {
        const index = findIndex(invoices, ["id", data.data.id]);
        const entity = { ...invoices[index], ...data.data };
        let entities = [...invoices];
        entities[index] = entity;

        setInvoices([...entities]);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const savePayment = async (payload) => {
    const momentDate = new moment(payload.payment_date);
    payload.payment_date = momentDate.format("YYYY-MM-DD HH:mm:ss");
    payload.sale_created_at = payload.sale_created_at ? new moment(payload.sale_created_at).format("YYYY-MM-DD HH:mm:ss"): null;
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.saveInvoicePayment(invoice.id, formData);

    dispatch(addInvoicePaymentsService(data.data));
    if (getInvoicesContact) {
      getInvoicesContact();
    } else {
      await Api.getInvoice(invoice.id).then((result) => {
        const index = findIndex(invoices, ["id", invoice.id]);
        const entity = { ...invoices[index], ...result.data.data };
        let entities = [...invoices];
        entities[index] = entity;


        setInvoices([...entities]);
        setInvoice({
          ...result.data.data,
        });
      });
    }
  };

  const deletePayment = async (id) => {
    const { data } = await Api.deleteInvoicePayment(invoice.id, id);

    dispatch(addInvoicePaymentsService(data.data));

    await Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });
    if (getInvoicesContact) {
      getInvoicesContact();
    }
  };

  const markPossibleRefund = async (id, $mark) => {
    const payload = { mark: $mark };

    const { data } = await Api.markAsPossibleRefund(invoice.id, id, payload);

    dispatch(addInvoicePaymentsService(data.data));

    await Api.getInvoice(invoice.id).then((result) => {
      setInvoice({
        ...result.data.data,
      });
    });
  };

  const getInvoice = async () => {
    const { data } = await Api.getInvoiceDeleted(props.invoice.id);
    setInvoice(data.data);

    getContractsReports(props.invoice.id);
  };

  const saveComment = async (payload) => {
    const { data } = await Api.createtInvoiceComment(invoice.id, payload);

    setInvoice({
      ...invoice,
      items: {
        ...invoice.items,
        data: items,
      },
      comments: {
        ...invoice.comments,
        data: [...invoice.comments.data, data.data],
      },
    });
  };

  const updateContact = (data) => {
    setInvoice({
      ...invoice,
      contact: {
        data: data,
      },
      contact_id: data.id,
    });
  };
  const saveDocument = async (payload) => {
    let formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));

    const { data } = await Api.createtInvoiceDocument(invoice.id, formData);

    setInvoice({
      ...invoice,
      items: {
        ...invoice.items,
        data: items,
      },
      documents: {
        ...invoice.documents,
        data: [...invoice.documents.data, data.data],
      },
    });
  };

  const filterPayments = () => {
    const paymentsRefunds = invoice.payments.data.filter((payment) => {
      return !payment.isRefund;
    });

    return paymentsRefunds;
  };

  const filterRefunds = () => {
    const paymentsRefunds = invoice.payments.data.filter((payment) => {
      return payment.isRefund;
    });

    return paymentsRefunds;
  };

  const deleteResponsible = async (id) => {
    await Api.deleteInvoiceResponsible(invoice.id, id).then(() => {
      const responsibles = invoice.responsibles.data.filter((responsible) => {
        return responsible.id !== id;
      });
      setInvoice({
        ...invoice,
        responsibles: {
          data: [...responsibles],
        },
      });
    });
  };
  const dataMaterialTableTransferredPayments = invoice.paymentsTransferred
    ? invoice.paymentsTransferred.data.map((o) => ({ ...o }))
    : [];

  const headCellsMaterialTransferredPayments = [
    { field: "payment_type", title: "Type" },
    {
      field: "amount",
      title: "Amount",
      customSort: (a, b) => a.amount - b.amount,
      render: (rowData) => (
        <ListItem>
          <ListItemText primary={rowData.amount} />
          <ListItemIcon>
            {rowData.refundedAmount && rowData.refundedAmount > 0 ? (
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div>
                    <Box fontWeight="fontWeightBold" m={1}>
                      {`Refunded: $${rowData.refundedAmount.toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`}
                    </Box>
                  </div>
                }
                arrow
              >
                <InfoIcon style={{ color: "#26a69a" }} />
              </Tooltip>
            ) : rowData.forfeiture_amount && rowData.forfeiture_amount > 0 ? (
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <div>
                    <Box fontWeight="fontWeightBold" m={1}>
                      {`Forfeited: $${rowData.forfeiture_amount.toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`}
                    </Box>
                  </div>
                }
                arrow
              >
                <InfoIcon style={{ color: "#e65100" }} />
              </Tooltip>
            ) : (
              ""
            )}
          </ListItemIcon>
        </ListItem>
      ),
    },
    {
      field: "cc_last_4",
      title: "Last 4",
    },
    {
      field: "details",
      title: "Details",
      render: (rowData) => {
        return <div className={classes.wrapText}>{rowData.details}</div>;
      },
      customSort: (row1, row2) => {
        const detail1 = row1.details || "";
        const detail2 = row2.details || "";
        return detail1.localeCompare(detail2);
      },
    },
    { field: "building_name", title: "Building" },
    {
      field: "date",
      title: "Created",
      render: (rowData) => {
        return (
          <div>
            {momentTimeZone(rowData.date).format("YYYY-MM-DD hh:mm:ss A")}
          </div>
        );
      },
      customSort: (a, b) =>
        new Date(moment(a.date).format("YYYY-MM-DD HH:mm:ss")).getTime() -
        new Date(moment(b.date).format("YYYY-MM-DD HH:mm:ss")).getTime(),
    },
    {
      field: "transferred_date",
      title: "Transferred Date",
      render: (rowData) => {
        return (
          <div>
            {momentTimeZone
              .utc(rowData.transferred_date)
              .tz("America/New_York")
              .format("YYYY-MM-DD hh:mm:ss A")}
          </div>
        );
      },
      customSort: (a, b) =>
        new Date(
          moment(a.transferred_date).format("YYYY-MM-DD HH:mm:ss")
        ).getTime() -
        new Date(
          moment(b.transferred_date).format("YYYY-MM-DD HH:mm:ss")
        ).getTime(),
    },
    {
      field: "created_by",
      title: "By",
      customSort: (a, b) => a.user.data.title.localeCompare(b.user.data.title),
      render: (rowData) => {
        return rowData.user.data ? rowData.user.data.title : "";
      },
    },

    {
      field: "attachment",
      title: "File",
      sorting: false,
      render: (rowData) => {
        if (rowData.document.data?.url || rowData.file) {
          return (
            <a
              href={
                rowData.document.data?.url
                  ? rowData.document.data.url
                  : rowData.file
              }
              target="_blank"
              rel="noreferrer"
            >
              <AttachFileIcon />
            </a>
          );
        }
      },
    },
  ];

  const dataMaterialTable = invoice.creditMemos
    ? invoice.creditMemos.data.map((o) => ({ ...o }))
    : [];
  const headCellsMaterial = [
    {
      field: "date",
      title: "Date",
      render: (rowData) => {
        return moment(rowData.created_at).format("YYYY/MM/DD");
      },
    },
    { field: "amount", title: "Used Amount" },
    {
      field: "details",
      title: "Details",
      render: (rowData) => {
        return rowData.details;
      },
    },
    {
      field: "created_by",
      title: "Created By",
      render: (rowData) => {
        return rowData.user.data.first_name;
      },
    },
  ];

  const renderCreditDetails = (creditDetails) => {
    return (
      <MaterialTable
        title="Credits"
        columns={[
          { title: "Name", field: "item_name" },
          { title: "Amount", field: "amount" },
        ]}
        data={creditDetails.map((credit) => {
          return {
            item_name: credit.item_name,
            amount: credit.amount,
          };
        })}
        options={{
          pageSize: 20, // make initial page size
          emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
          search: false,
          header: true,
          paging: false,
          showTitle: false,
          padding: "dense",
          toolbar: false,
          exportButton: false,
        }}
      />
    );
  };

  useEffect(() => {
    const orgId = authUser.organization_id;

    getInvoice();
    if (isEmpty(productsNew)) {
      dispatch(fetchProductsService(dataSliceProduct));
    }
    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));

    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));
    if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));

    if (isEmpty(refundReasons)) {
      dispatch(fetchRefundReasonsService(orgId));
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(invoice)) {
      setItems(invoice.items.data);
      setStatus(invoice.status);
    }
  }, [invoice]);

  if (isEmpty(invoice)) return "loading...";

  let action = [];

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Grid container>
        <Grid item xs={6}>
          <InvoiceBuilderDeleted
            setInvoices={setInvoices}
            getInvoicesContact={getInvoicesContact}
            invoices={invoices}
            contacts={contacts}
            statusOptions={statusArray}
            invoice={invoice}
            setInvoice={setInvoice}
            users={users}
            authUser={authUser}
            products={productsNew}
            contact={invoice.contact.data}
            onSaveInvoice={saveInvoice}
            onMakePayment={savePayment}
            onSaveCreditMemo={saveCreditMemo}
            readOnlySalesManager={true}
            userIsCallCenter={userIsCallCenter}
            userIsSocialMedia={userIsSocialMedia}
            userIsFrontDesk={userIsFrontDesk}

         
          />
        </Grid>
        <Grid item xs={6}>
          <Paper square>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
            >
              <Tab
                icon={
                  <Iconify
                    icon={"mdi:chart-timeline-variant-shimmer"}
                    style={{ color: "#ff5722" }}
                    width={24}
                    height={24}
                  />
                }
                label="Timeline"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fa:comments"}
                    style={{ color: "#607d8b" }}
                    width={24}
                    height={24}
                  />
                }
                label="Comments"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"bi:file-text-fill"}
                    style={{ color: "#03a9f4" }}
                    width={24}
                    height={24}
                  />
                }
                label="Documents"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fluent:payment-24-filled"}
                    style={{ color: "rgb(62 101 141)" }}
                    width={24}
                    height={24}
                  />
                }
                label="Payments"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"fluent:people-24-filled"}
                    style={{ color: "#8bc34a" }}
                    width={24}
                    height={24}
                  />
                }
                label="Responsibles"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"mdi:credit-card-refund"}
                    style={{ color: "#f44336" }}
                    width={24}
                    height={24}
                  />
                }
                label="Refunds"
              />
              <Tab
                icon={
                  <Iconify
                    icon={"icon-park-twotone:gift-bag"}
                    style={{ color: "#009688" }}
                    width={24}
                    height={24}
                  />
                }
                label="Credits"
              />
              <Tab
                icon={
                  <div style={{ display: "flex", position: "relative" }}>
                    <Grid container style={{ margin: 0, padding: 0 }}>
                      <Grid item xs={12}>
                        <Iconify
                          icon={"akar-icons:arrow-forward-thick-fill"}
                          width={25}
                          height={25}
                          style={{
                            color: "#03a9f4",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          position: "absolute",
                          zIndex: 10,
                          top: "17px",
                          left: "3px",
                        }}
                      >
                        <Iconify
                          icon={"fluent:payment-24-filled"}
                          width={20}
                          height={20}
                          style={{
                            color: "#dd2c00",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
                label="Transferred Payments"
              />
            </Tabs>

            <TabPanel value={value} index={0} {...a11yProps(0)}>
              <Timeline
                modelType="Invoice"
                model={invoice}
                typel="Invoice"
                createdAt={invoice.created_at}
              />
            </TabPanel>
            <TabPanel value={value} index={1} {...a11yProps(1)}>
              <Comments
                comments={invoice.comments.data}
                onSave={saveComment}
                canAdd={false}
              />
            </TabPanel>
            <TabPanel value={value} index={2} {...a11yProps(2)}>
              <Documents
                documents={invoice.documents.data}
                documentTypes={documentTypes}
                onSave={saveDocument}
                showDelete={false}
                readOnly={true}
                canAdd={false}
              />
            </TabPanel>
            <TabPanel value={value} index={3} {...a11yProps(3)}>
              <Payments
                invoice={invoice}
                readOnly={true}
                payments={filterPayments()}
                setInvoice={setInvoice}
                getInvoicesContact={getInvoicesContact}
                onDelete={deletePayment}
                gateways={paymentGateways}
                authUser={authUser}
                reload={getInvoice}
                userIsbillingMGAssistant={userIsbillingMGAssistant}
                onMarkPossibleRefund={markPossibleRefund}
              />
            </TabPanel>
            <TabPanel value={value} index={4} {...a11yProps(4)}>
              <Responsible
                responsibles={invoice.responsibles.data}
                users={users}
                onSave={saveResponsible}
                onDelete={deleteResponsible}
                canAdd={false}
                canDelete={false}
              />
            </TabPanel>
            <TabPanel value={value} index={5} {...a11yProps(5)}>
              <Refunds
                invoice={invoice}
                readOnly={true}
                refunds={filterRefunds()}
                onSave={savePayment}
                gateways={paymentGateways}
                maxAmount={1}
                refundReasons={refundReasons}
                invoiceSelected={invoice}
                dataRefundRequests={dataRefundRequests}
                setInvoice={setInvoice}
                getInvoicesContact={getInvoicesContact}
                onDelete={deletePayment}
                userIsbillingMGAssistant={userIsbillingMGAssistant}
              />
            </TabPanel>
            <TabPanel value={value} index={6} {...a11yProps(6)}>
              <TblContainer>
                <MaterialTable
                  columns={headCellsMaterial}
                  data={dataMaterialTable}
                  options={{
                    pageSize: 20, // make initial page size
                    emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                    actionsColumnIndex: -1,
                    exportButton: true,
                  }}
                  detailPanel={(rowData) => {
                    return renderCreditDetails(rowData.creditmemodetail.data);
                  }}
                  title="Credit Memos Applied"
                />
              </TblContainer>
            </TabPanel>
            <TabPanel value={value} index={7} {...a11yProps(7)}>
              <InvoicePaymentTransferHistories invoice_id={invoice.id} />
              {/* <TblContainer>
                <MaterialTable
                  columns={headCellsMaterialTransferredPayments}
                  data={dataMaterialTableTransferredPayments}
                  options={{
                    rowStyle: (rowData) => ({
                      color: "#9e9e9e",
                    }),
                    headerStyle: {
                      backgroundColor: "#e0f2f1",
                      color: "#616161",
                    },
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                    exportButton: true,
                  }}
                  title="Payments"
                />
              </TblContainer> */}
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </React.Fragment>
  );
}

export default InvoiceDetails;
