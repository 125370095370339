import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Chip } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { useTranslation } from "react-i18next";
import Iconify from "../Iconify";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  platformIcon: {
    marginRight: 5,
    fontSize: 17,
    marginBottom: -3
  },
}));

export default function InfluencerSocialPlatformTimeline(props) {
  const classes = useStyles();
  const { data } = props;

  const date = moment(data.created_at).format("MMMM Do YYYY, h:mm:ss a");
  const owner = data.owner
    ? `${data.owner.fname} ${data.owner.lname}`
    : "System";
  const action = data.action;

  const { t, i18n } = useTranslation(["common", "records"]);

  const getSocialMediaIcon = (name) => {
    switch (name.toLowerCase().replaceAll(" ", "")) {
      case "facebook":
        return "logos:facebook";
        break;
      case "instagram":
        return "skill-icons:instagram";
        break;
      case "youtube":
        return "logos:youtube-icon";
        break;
      case "tiktok":
        return "logos:tiktok-icon";
        break;
      case "twitter":
        return "skill-icons:twitter";
        break;
      case "x":
        return "line-md:twitter-x";
        break;
      default:
        return "icon-park-solid:more-two";
    }
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body1" color="textSecondary">
          {action} by {owner}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <LanguageIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {"Social Platform"}
          </Typography>

          <Typography style={{paddingTop: 4}}>
            <strong >
              <Iconify
                className={classes.platformIcon}
                icon={getSocialMediaIcon(data?.object?.platform || "other")}
              />
            </strong>
            {`${data?.object?.platform || ""}`}
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
