import React  from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { FormControl } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);

export default function FileDialog(props) {
  const { label, onChange, value,initialFiles,addClassNames,...other } = props;

  const handleSave = (files) => {
    onChange(files);
  };

  const classes = useStyles();

  return (
    <FormControl>
      <DropzoneArea
        addClassNames		={addClassNames}
        filesLimit={1}
        onChange={handleSave}
        maxFileSize={1073741824}   
        showPreviews={true}
        initialFiles={initialFiles}
        showPreviewsInDropzone={false}
        useChipsForPreview
        {...other}
      />
    </FormControl>
  );
}
