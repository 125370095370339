import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  totalUnreadChatMessages: [],
};

const totalUnreadChatMessagesSlice = createSlice({
  name: "totalUnreadChatMessages",
  initialState,
  reducers: {
    updateTotals: (state, { payload }) => {
      state.totalUnreadChatMessages = [...payload];
    },
    getTotalSucces: (state, { payload }) => {
      state.totalUnreadChatMessages = payload;
      state.loading = false;
      state.errors = [];
    },
  },
});

export const { getTotalSucces, updateTotals } =
  totalUnreadChatMessagesSlice.actions;
export const templatesSelector = (state) => state.totalUnreadChatMessages;

export default totalUnreadChatMessagesSlice.reducer;

export function fetchTotalUnreadChatMessages() {
  return async (dispatch) => {
    try {
      const {data} = await Api.getTotalUnreadChatMessages();

      dispatch(getTotalSucces(data));
    } catch (error) {}
  };
}

export function updateTotalUnreadChatMessages(payload) {
  return async (dispatch) => {
    dispatch(updateTotals(payload));
  };
}
