import React from "react";
import { TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutocompleteMultipleControl(props) {
  const { label, options, onChange, variant, ...others } = props;

  return (
    <Autocomplete
      multiple
      id="multiple"
      fullWidth
      options={options}
      getOptionLabel={(option) => option.title}
      defaultValue={[]}
      onChange={(event, values)=>{
          onChange(values)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant?variant:"standard"}
          label={label}
          placeholder={label}
        />
      )}
    />
  );
}
