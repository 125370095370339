import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  ListItem,
  Avatar,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import Iconify from "../Iconify";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import moment from "moment";
import Notification from "../../components/Notification";
import { fetchBuildingsService } from "../../redux/slices/buildings";
import CRMUtils from "../../utils";
import { fetchUsersService } from "../../redux/slices/users";

import Api from "../../lib/api";
import MaterialTable from "material-table";
import InputMoneyToPaymentForm from "../controls/InputMoneyToPaymentForm";

import MTableR2 from "../../components_generic/MTableR2";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
  inputItemAmount: {
    width: "60% !important",
  },
}));

export default function RefundForm(props) {
  const {
    user,
    invoice,
    gateways,
    savePayment,
    maxAmount,
    firstPayment,
    itemName,
    itemAmount,
    refundComment,
    refundFull,
    refundFullItem,
    paymentForEdit,
    reload,
    allowFix = false,
    isRefund = false,
    refundReasons = [],
    refundRequestsForEdit = null,
  } = props;

  const { handleSubmit, setValue, reset, control, errors, watch, getValues } =
    useForm();

  // const fields = getValues();
  const watchAllFields = watch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null);
  const [minValidations, setMinValidations] = useState({});
  const { authUser } = useSelector((state) => state.auth);
  const { buildings } = useSelector((state) => state.buildings);

  const [lockDate, setLockDate] = useState(false);
  const { users } = useSelector((state) => state.users);

  const refundDetails = useRef([]);
  const tableRef1 = createRef();
  const tableRef2 = createRef();
  const tableRef3 = createRef();

  const dispatch = useDispatch();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);

  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);

  const userCanApplyFullCredit = CRMUtils.userHasPermission(authUser, [
    "refund_applycredit",
  ]);

  const [totalToRefund, setTotalToRefund] = useState(0);

  const [overPaymentControl, setOverPaymentControl] = useState(0);

  const paymentOptions = () => {
    let options = gateways.map((gateway) => {
      return {
        id: gateway.name,
        title: gateway.name,
      };
    });
    // options.push({
    //   id: "ONLINE DEBIT/CREDIT CARD",
    //   name: "ONLINE DEBIT/CREDIT CARD",
    // });
    return options;
  };


  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return (
      item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };

  const calculeTotal2Pay = () => {
    if (!watchAllFields.itemsAmount) return 0;

    return watchAllFields.itemsAmount.reduce(
      (total, item) => total + parseFloat(item),
      0
    );
  };

  const calculateItemBalance = (item, payment) => {
    const itemPrice = item.price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;

    let itemRefunds = 0;
    let refunddetails;
    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        if (payment) {
          !paymentdetail.isRefund && paymentdetail.payment == payment.id;
        }
        return !paymentdetail.isRefund;
      });

      itemPayments = paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds = refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    if (item.creditmemodetail) {
      itemCredits = item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }

    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;
    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const calculateItemRefund = (item, payment) => {
    let itemRefunds = 0;
    let refunddetails;
    let itemPayments = 0;
    let paymentdetails;

    if (item.paymentdetail) {
      paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        if (payment) {
          return paymentdetail.isRefund && paymentdetail.payment == payment.id;
        }
        return !paymentdetail.isRefund;
      });

      if (paymentdetails.length > 0) {
        itemPayments = paymentdetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds = refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    const itemRefundtoReturn = itemPayments - itemRefunds;

    if (itemRefundtoReturn > 0) {
      return itemRefundtoReturn;
    } else {
      return 0;
    }
  };

  const getDetail = (item_id) => {
    let paymentdetail = paymentForEdit.paymentdetail.data.find(
      (paymentdetail1) => {
        return paymentdetail1.item_id === item_id;
      }
    );

    //  console.log(item_id, paymentdetail);
    return paymentdetail ? paymentdetail.amount : 0;
  };

  const getDetailId = (item_id) => {
    let paymentdetail = paymentForEdit.paymentdetail.data.find(
      (paymentdetail1) => {
        return paymentdetail1.item_id === item_id;
      }
    );

    //console.log(item_id, paymentdetail);
    return paymentdetail ? paymentdetail.id : "null";
  };

  const renderInvoiceItems = (payment) => {
    // console.log("payment123 ", payment);
    let overPaidItem = null;
    var mins = [];
    if (payment) {
      // console.log("paymentttt exists ", payment);
      overPaidItem = invoice.items.data.filter((item) => {
        return (
          item.type === "system" &&
          item.paymentdetail.data.filter((item_payment) => {
            // console.log(
            //   "paymentttt item_payment.payment:",
            //   item_payment.payment,
            //   "payment.id:",
            //   payment.id
            // );
            return (item_payment.payment = payment.id);
          })
        );
      });
    } else {
      overPaidItem = invoice.items.data.filter((item) => {
        return item.type === "system";
      });
    }

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    //console.log("paymentttt overpaid items ", overPaidItem);

    //setOverPaymentControl(0);
    let overPaidPriority = overPaidItem[0]
      ? overPaidItem[0].itemPaid - overPaidItem[0].itemRefund > 0
        ? true
        : false
      : false;

    // let data = payment
    //   ? invoice.items.data.filter((item) => {
    //       let r = item.paymentdetail.data.filter((item_payment) => {
    //         console.log(
    //           "paymentttt item_payment.payment:",
    //           item_payment.payment,
    //           "payment.id:",
    //           payment.id
    //         );
    //         if (item_payment.payment == payment.id) {
    //           console.log(
    //             "paymentttt ",
    //             payment.id,
    //             " has details in item ",
    //             item.id
    //           );
    //         }
    //         return item_payment.payment == payment.id;
    //       });
    //       console.log("paymentttt r", r);
    //       return r;
    //     })
    //   : invoice.items.data;

    let data = [];
    let new_data = [];
    if (payment) {
      new_data = invoice.items.data.filter((item) => {
        //  console.log("paymentttt iterating item ", item);
        let r = item.paymentdetail.data.filter((item_payment) => {
          // console.log(
          //   "paymentttt item_payment.payment:",
          //   item_payment.payment,
          //   "payment.id:",
          //   payment.id
          // );
          if (item_payment.payment == payment.id) {
            // console.log(
            //   "paymentttt ",
            //   payment.id,
            //   " has details in item ",
            //   item.id
            // );
          }
          return item_payment.payment == payment.id;
        });
        //  console.log("paymentttt r", r);
        if (r.length > 0) {
          data.push(item);
        }
        return r.payment == item.payment;
      });
    } else {
      new_data = invoice.items.data;
    }

    if (data.length == 0) {
      data = invoice.items.data;
    }

    //   console.log("paymentttt new_data ", new_data);
    //   console.log("paymentttt data ", data);
    // console.log("overPaidItem",overPaidItem[0]?overPaidItem[0]:"");

    return data /*.filter((item)=>{return !isRefund?item.type !== 'system':item.id > 0})*/
      .map((item, index) => {
        mins[index] = userCanEditNegativePayments
          ? "-999999"
          : item.paymentdetail &&
            item.itemBalance < 0 &&
            item.paymentdetail.data &&
            item.paymentdetail.data.findIndex(
              (a) => a.is_recordProduct_finalized == true
            ) != -1
          ? (paymentForEdit
              ? getDetail(item.id)
              : refundFull
              ? refund
              : refundFullItem && itemName === item.name
              ? refund
              : itemName && itemName === item.name && refund >= itemAmount
              ? itemAmount
              : itemName && itemName === item.name && refund < itemAmount
              ? refund
              : 0) >
            item.itemBalance * -1
            ? (paymentForEdit
                ? getDetail(item.id)
                : refundFull
                ? refund
                : refundFullItem && itemName === item.name
                ? refund
                : itemName && itemName === item.name && refund >= itemAmount
                ? itemAmount
                : itemName && itemName === item.name && refund < itemAmount
                ? refund
                : 0) -
              item.itemBalance * -1
            : 0
          : "0";
        const balance = calculateItemBalance(item, payment ? payment : null);
        const refund = calculateItemRefund(item, payment ? payment : null);

        let paid_amount = 0;
        let refunded_amount = 0;
        let refundable_amount = 0;
        let payment_items = [];
        // if (payment && payment.payments_by_items) {
        //   paid_amount = payment.payments_by_items.filter((i) => {
        //     return i.item_id == item.id;
        //   })[0]?.paid_amount;
        //   refunded_amount = payment.payments_by_items.filter((i) => {
        //     return i.item_id == item.id;
        //   })[0]?.refunded_amount;
        //   refundable_amount = paid_amount - refunded_amount;
        // }

        if (payment && payment.payments_by_items) {
          payment_items = payment.payments_by_items.filter((i) => {
            return i.item_id == item.id;
          });
          //  console.log("arraysss payment_items ", payment_items);

          paid_amount = calculateSum(payment_items, "paid_amount");
          refunded_amount = calculateSum(payment_items, "refunded_amount");
          //  console.log("arraysss paid_amount ", paid_amount);
          //   console.log("arraysss refunded_amount ", refunded_amount);
          refundable_amount = paid_amount - refunded_amount;
          //   console.log("arraysss refundable_amount ", refundable_amount);
        }

        let detailsColumns = [
          {
            title: "Name",
            render: (rowData) => {
              return <div>asd</div>;
            },
          },
          { field: "price", title: "Price" },
          { field: "paid", title: "Paid" },
          { field: "refunded_amount", title: "Refunded Amount" },
          { field: "refundable_amount", title: "Refundable Amount" },
        ];

        return (
          <MaterialTable
            tableRef={tableRef2}
            title={"NONES"}
            columns={detailsColumns}
            // data={invoice.payments?.data.filter(
            //   (payment) => payment.isRefund == 0
            // )}
            //data={rowData}
            //style={{ marginTop: title ? "" : -40 }}
            //isLoading={isLoading}
            options={{
              search: false,
              padding: "dense",
              paging: false,
              pageSize: 10,
              pageSizeOptions: [],
              emptyRowsWhenPaging: false,
            }}
            //detailPanel={detailPanelAA}
            // onRowClick={(event, rowData, togglePanel) =>
            //   rowData.event !== "VIEWED" ? togglePanel() : null
            // }
            // components={{
            //   //Custom Pagination
            //   Pagination: (defaultProps) => (
            //     <TablePagination
            //       {...defaultProps}
            //       count={paginator?.total ? paginator.total : defaultProps.count}
            //       page={page ? page : defaultProps.page}
            //       rowsPerPage={rowsPerPage ? rowsPerPage : defaultProps.rowsPerPage}
            //       onChangePage={(e, newPage) => {
            //         setPage(newPage);
            //         //defaultProps.onChangePage(e, newPage);
            //       }}
            //       /*
            //     onChangeRowsPerPage={(event) => {
            //       setRowsPerPage(event.target.value);
            //     }}
            //     */
            //     />
            //   ),
            // }}
          />
        );
      });
  };

  const renderPaymentPanel = () => {
    return (
      <TableContainer component={Paper}>
        {!paymentForEdit || !isEmpty(paymentForEdit.paymentdetail.data) ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">
                  {maxAmount ? "Amount Paid" : "Balance"}
                </TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderInvoiceItems(null)}</TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} align="right">
                  Total to Pay: {calculeTotal2Pay()}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          ""
        )}

        {paymentForEdit && isEmpty(paymentForEdit.paymentdetail.data) ? (
          <Grid container alignItems="center">
            <Grid item xs>
              <Controller
                name={`itemsAmount[0]`}
                className={classes.inputItemAmount}
                control={control}
                defaultValue={paymentForEdit ? paymentForEdit.amount : 0}
                as={
                  <Controls.InputMoney
                    label="Amount"
                    defaultValue={paymentForEdit ? paymentForEdit.amount : 0}
                  />
                }
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </TableContainer>
    );
  };

  const isSubmitDisabled = () => {
    if (paymentForEdit) {
      if (isSubmitting) {
        return true;
      }
      return false;
    }
    if (refundDetails.current.length > 0 && !isSubmitting) {
      let empty_reasons = refundDetails.current.filter((item) => {
        return item.refund_reason_id == null;
      });
      let empty_files = refundDetails.current.filter((item) => {
        //   console.log("itemasd ", item);
        //   console.log("itemasd item.file == null ", item.file == null);
        return item.file_ref == null;
      });
      //  console.log("asd123asd empty_reasons ", empty_reasons);
      //   console.log("asd123asd empty_files ", empty_files);
      if (empty_reasons.length > 0 || empty_files.length > 0) {
        return true;
      }
      return false;
    }

    return true;
  };

  const initializeRefundDetail = (refund_detail, current_payment) => {
    refund_detail.building_id = current_payment.building_id;
    refund_detail.refund_reason_id = paymentForEdit
      ? current_payment.refund_reason_id
      : null;
    refund_detail.file_ref =
      paymentForEdit && current_payment.file ? true : null;
    refund_detail.file = paymentForEdit ? current_payment.file : null;
    refund_detail.cardholder = current_payment.cardholder;
    refund_detail.cc_last_4 = current_payment.cc_last_4;
    refund_detail.payment_type = current_payment.payment_type;
    refund_detail.date = moment().format("YYYY-MM-DD HH:mm:ss");
    refund_detail.payment_date = moment().format("YYYY-MM-DD HH:mm:ss");
    refund_detail.isChargeback = 0;
    refund_detail.ptChangePayment = 0;
    // console.log("initialized ", refund_detail);
  };

  const updateRefundDetail = (rowData, propertie, value) => {
    let current_payment = invoice.payments?.data?.find(
      (payment) => payment.id === rowData.payment_id
    );
    let item = invoice.items.data.find((item) => item.id === rowData.item_id);

    let refund_detail = {
      payment_id: rowData.payment_id,
      item: item.id,
    };
    refund_detail[propertie] = value;

    let match = refundDetails.current.find(
      (item) =>
        item.item === refund_detail.item &&
        item.payment_id === refund_detail.payment_id
    );

    if (match) {
      if (propertie === "amount" && value === 0) {
        refundDetails.current = refundDetails.current.filter(
          (item) =>
            item.item !== refund_detail.item ||
            item.payment_id !== refund_detail.payment_id
        );
      } else {
        match[propertie] = refund_detail[propertie];
        // if (propertie === "amount" && refund_detail[propertie] > 0) {
        //   console.log("refund_detail match3");
        //   console.log("refund_detail match; bef ", match);
        //   match[propertie] = refund_detail[propertie];
        //   console.log("refund_detail match; aft ", match);
        // // } else if (refund_detail[propertie]) {
        // //   console.log("refund_detail match4");
        // //   console.log("refund_detail refund_detail ", refund_detail);
        // //   console.log("refund_detail propertie ", propertie);
        // //   console.log("refund_detail match4 bef ", match);
        // //   match[propertie] = refund_detail[propertie];
        // //   console.log("refund_detail match4 aft ", match);
        // } else {
        //   console.log("refund_detail match5");
        //   match[propertie] = refund_detail[propertie];
        // }
      }
      getTotalToPay();
    } else {
      if (!(propertie === "amount" && value === 0)) {
        initializeRefundDetail(refund_detail, current_payment);

        if (propertie === "amount" && refund_detail.amount > 0) {
          refundDetails.current = [refund_detail, ...refundDetails.current];
        } else if (refund_detail[propertie]) {
          refundDetails.current = [refund_detail, ...refundDetails.current];
        }
      }
    }
  };

  const getIsDetailPanelDisabled = (rowData) => {
    // console.log(
    //   "match123asdsdasd refindetauls.current ",
    //   refundDetails.current
    // );
    // console.log("match123asdsdasd rowdata ", rowData);
    let match = refundDetails.current.filter((item) => {
      // console.log(
      //   "match123 item123",
      //   item.item,
      //   "    refund_detail",
      //   refund_detail,
      //   " result=",
      //   item.item == refund_detail.item &&
      //     item.payment_id == refund_detail.payment_id
      // );
      return (
        item.item == rowData.item_id && item.payment_id == rowData.payment_id
      );
    })[0];
    // console.log("match123asdsdasd match ", match);
    // console.log("match123asdsdasd returning  ", match ? false : true);
    if (paymentForEdit && !match) {
      let current_payment = invoice.payments?.data?.filter((payment) => {
        return payment.id == rowData.payment_id;
      })[0];
      let item = invoice.items.data.filter((item) => {
        return item.id == rowData.item_id;
      })[0];
      let refund_detail = {
        payment_id: rowData.payment_id,
        item: item.id,
      };
      initializeRefundDetail(refund_detail, current_payment);
      refundDetails.current = [refund_detail, ...refundDetails.current];
      return false;
    }
    return match ? false : true;
  };

  const renderRefundPanel = () => {
    let columns = [
      {
        field: "amount",
        title: paymentForEdit ? "Refunded Amount" : "Paid Amount",
      },
      { field: "building_name", title: "Payment Building" },
      { field: "date", title: "Payment Date" },
    ];

    let detailsData = invoice.payments.data.filter(
      (payment) => payment.isRefund == 0
    );

    // console.log("detailsData ", detailsData);

    let detailsColumns = [
      {
        title: "Item Name",
        render: (rowData) => {
          return <div>{rowData.item_name}</div>;
        },
      },
      // {
      //   title: "Price",
      //   render: (rowData) => {
      //     return <div>asd</div>;
      //   },
      // },
      {
        hidden: paymentForEdit ? true : false,
        title: "Paid Amount",
        render: (rowData) => {
          return <div>{rowData.paid_amount}</div>;
        },
      },
      {
        title: "Refunded Amount",
        render: (rowData) => {
          return <div>{rowData.refunded_amount}</div>;
        },
        hidden: paymentForEdit ? true : false,
      },
      {
        title: "Refundable Amount",
        render: (rowData) => {
          //  console.log("asdasdasd12asdxsdfas rowdata ", rowData);
          return <div>{rowData.paid_amount - rowData.refunded_amount}</div>;
        },
      },
      {
        title: paymentForEdit ? "New Amount To Refund " : "Amount to Refund",
        render: (rowData) => {
          let item = invoice.items.data.filter((item) => {
            return item.id == rowData.item_id;
          })[0];
          let default_value = refundDetails.current.filter((dv) => {
            return (
              dv.item == rowData.item_id && dv.payment_id == rowData.payment_id
            );
          })[0];
          // console.log(
          //   "defaultvalueeeerowData.refundDetails.current ",
          //   refundDetails.current,
          //   " defaultvalueeeerowData.rowData ",
          //   rowData,
          //   " defaultvalueeeerowData.item ",
          //   rowData.item_id,
          //   "defaultvalueeeerowData.payment_id ",
          //   rowData.payment_id
          // );
          // console.log("defaultvalueeee ", default_value);
          let refundable_amount = rowData.paid_amount - rowData.refunded_amount;
          // console.log("this rowData is ", rowData);
          // console.log("this  rowData.refundable_amount is ", refundable_amount);
          // console.log("this item is ", item);
          // console.log(
          //   "tuserCanEditNegativePayments ",
          //   userCanEditNegativePayments
          // );

          return (
            <InputMoneyToPaymentForm
              id={`${rowData.item_id}`}
              defaultValue={
                paymentForEdit
                  ? paymentForEdit.amount
                  : default_value?.amount && default_value?.amount > 0
                  ? default_value.amount
                  : 0
              }
              onRefundAmountChange={(value) => {
                setTotalToRefund(totalToRefund + value);
                // let refund_detail = {
                //   payment_id: rowData.payment_id,
                //   item: item.id,
                //   amount: value ? parseInt(value, 10) : 0,
                // };
                // // console.log(
                // //   "match123 refundDetails.current ",
                // //   refundDetails.current
                // // );
                // let match = refundDetails.current.filter((item) => {
                //   // console.log(
                //   //   "match123 item123",
                //   //   item.item,
                //   //   "    refund_detail",
                //   //   refund_detail,
                //   //   " result=",
                //   //   item.item == refund_detail.item &&
                //   //     item.payment_id == refund_detail.payment_id
                //   // );
                //   return (
                //     item.item == refund_detail.item &&
                //     item.payment_id == refund_detail.payment_id
                //   );
                // })[0];
                // // console.log("match123 match exact ", match);
                // if (match) {
                //   // console.log("match123 before ", match);
                //   if (refund_detail.amount > 0) {
                //     match.amount = refund_detail.amount;
                //   }
                //   getTotalToPay();
                //   // console.log("match123 after ", match);
                // } else {
                //   // console.log("match123 else before ", refundDetails.current);
                //   if (refund_detail.amount > 0) {
                //     refundDetails.current = [
                //       refund_detail,
                //       ...refundDetails.current,
                //     ];
                //   }

                //   // console.log("match123 else after ", refundDetails.current);
                // }
                // // console.log(
                // //   "match123 final match ",
                // //   match,
                // //   "    refudnetail ",
                // //   refundDetails.current
                // // );
                updateRefundDetail(rowData, "amount", value);
              }}
              //mins={mins}
              //indexValue={index}
              minValidations={minValidations}
              setMinValidations={setMinValidations}
              maximumValue={
                userCanEditNegativePayments
                  ? "999999"
                  : refundable_amount > 0
                  ? refundable_amount
                  : 0
                // ? parseFloat(paymentForEdit.amount) + parseFloat(refund)
                // : refundFull
                // ? refund
                // : refundFullItem && itemName === item.name
                // ? refund
                // : maxAmount
                // ? refund
                // : balance
              }
              minimumValue={userCanEditNegativePayments ? "-999999" : "0"}
              disabled={
                // item.paymentdetail &&
                // item.itemBalance == 0 &&
                // item.paymentdetail.data &&
                // item.paymentdetail.data.findIndex(
                //   (a) => a.is_recordProduct_finalized == true
                // ) != -1
                //   ? true
                //   : paymentForEdit && item.type !== "system"
                //   ? false
                //   : !isRefund &&
                //     ((maxAmount === 1 && refund === 0) ||
                //       (!maxAmount && balance <= 0) ||
                //       (refundFullItem &&
                //         itemName === item.name &&
                //         item.void === 0) ||
                //       item.void === 1)
                //   ? true
                //   : isRefund &&
                //     (rowData.refundable_amount <= 0 || item.void === 1)
                //   ? true
                //   : isRefund &&
                //     overPaidPriority &&
                //     item.type !== "system" &&
                //     overPaymentControl <
                //       overPaidItem[0].itemPaid - overPaidItem[0].itemRefund
                //   ? true
                //   : !isRefund && overPaidPriority && item.type === "system"
                //   ? true
                //   : false
                //
                // overPaidPriority &&
                // item.type !== "system" &&
                // overPaymentControl <
                //   overPaidItem[0].itemPaid - overPaidItem[0].itemRefund
                //   ? true
                //   : refundable_amount <= 0 || item.void === 1
                refundable_amount <= 0 || item.void === 1
              }
              setOverPaymentControl={
                rowData?.item_id?.type === "system"
                  ? setOverPaymentControl
                  : null
              }
              //setCustomValue={isRefund && overPaidPriority && item.type !== 'system' && overPaymentControl < (overPaidItem[0].itemPaid - overPaidItem[0].itemRefund)?0:null}
              //overPaymentControl={overPaymentControl}
              //overPaidItem={overPaidItem[0]}
              //itemType={item.type}
            />
          );
        },
      },
    ];

    const detailsDetailPanelAA = [
      (rowData) => ({
        tooltip: getIsDetailPanelDisabled(rowData)
          ? "Amount to Refund must be greater than zero"
          : "",
        disabled: getIsDetailPanelDisabled(rowData),
        //disabled: rowData.event !== "VIEWED" ? false : true,
        // icon: () => (
        //   <ArrowForwardIosIcon
        //     style={{
        //       display: rowData.event !== "VIEWED" ? "" : "none",
        //       width: 15,
        //       height: 15,
        //     }}
        //   />
        // ),
        //openIcon: UnfoldLess,
        render: () => {
          return renderPaymentMenu(rowData);
        },
      }),
    ];

    const detailPanelAA = [
      (rowData) => ({
        //disabled: rowData.event !== "VIEWED" ? false : true,
        // icon: () => (
        //   <ArrowForwardIosIcon
        //     style={{
        //       display: rowData.event !== "VIEWED" ? "" : "none",
        //       width: 15,
        //       height: 15,
        //     }}
        //   />
        // ),
        //openIcon: UnfoldLess,
        render: () => (
          <MaterialTable
            tableRef={tableRef1}
            title={"Details"}
            columns={detailsColumns}
            // data={detailsData}
            data={rowData.payments_by_items}
            //style={{ marginTop: title ? "" : -40 }}
            //isLoading={isLoading}
            options={{
              search: false,
              padding: "dense",
              pageSize: 10,
              pageSizeOptions: [],
              emptyRowsWhenPaging: false,
              paing: false,
            }}
            detailPanel={detailsDetailPanelAA}
            // onRowClick={(event, rowData, togglePanel) => {
            //   let default_value = refundDetails.current.filter((dv) => {
            //     return (
            //       dv.item == rowData.item_id &&
            //       dv.payment_id == rowData.payment_id
            //     );
            //   })[0];
            //   console.log("toggling default_value ", default_value);
            //   console.log("toggling rowdata ", rowData);

            //   rowData.paid_amount - rowData.refunded_amount > 0 &&
            //   default_value?.amount > 0
            //     ? togglePanel()
            //     : "";
            // }}
            // onRowClick={(event, rowData, togglePanel) =>
            //   rowData.event !== "VIEWED" ? togglePanel() : null
            // }
            // components={{
            //   //Custom Pagination
            //   Pagination: (defaultProps) => (
            //     <TablePagination
            //       {...defaultProps}
            //       count={paginator?.total ? paginator.total : defaultProps.count}
            //       page={page ? page : defaultProps.page}
            //       rowsPerPage={rowsPerPage ? rowsPerPage : defaultProps.rowsPerPage}
            //       onChangePage={(e, newPage) => {
            //         setPage(newPage);
            //         //defaultProps.onChangePage(e, newPage);
            //       }}
            //       /*
            //     onChangeRowsPerPage={(event) => {
            //       setRowsPerPage(event.target.value);
            //     }}
            //     */
            //     />
            //   ),
            // }}
          />

          //   <TableContainer component={Paper}>
          //     {!paymentForEdit || !isEmpty(paymentForEdit.paymentdetail.data) ? (
          //       <Table aria-label="simple table">
          //         <TableHead>
          //           <TableRow>
          //             <TableCell>Name</TableCell>
          //             {/* <TableCell align="right">Quantity</TableCell>
          //             <TableCell align="right">Price</TableCell> */}
          //             <TableCell align="right">Item Price</TableCell>
          //             <TableCell align="right">
          //               {maxAmount ? "Paid Amount" : "Balance"}
          //             </TableCell>
          //             <TableCell align="right">
          //               {maxAmount ? "Refunded Amount" : "Balance"}
          //             </TableCell>
          //             <TableCell align="right">Refundable Amount</TableCell>
          //             {/* <TableCell align="right">Amount</TableCell> */}
          //           </TableRow>
          //         </TableHead>
          //         <TableBody>{renderInvoiceItems(rowData)}</TableBody>
          //         {!isRefund && (
          //           <TableFooter>
          //             <TableRow>
          //               <TableCell colSpan={6} align="right">
          //                 Total to Pay: {calculeTotal2Pay()}
          //               </TableCell>
          //             </TableRow>
          //           </TableFooter>
          //         )}
          //       </Table>
          //     ) : (
          //       ""
          //     )}

          //     {paymentForEdit && isEmpty(paymentForEdit.paymentdetail.data) ? (
          //       <Grid container alignItems="center">
          //         <Grid item xs>
          //           <Controller
          //             name={`itemsAmount[0]`}
          //             className={classes.inputItemAmount}
          //             control={control}
          //             defaultValue={paymentForEdit ? paymentForEdit.amount : 0}
          //             as={
          //               <Controls.InputMoney
          //                 label="Amount"
          //                 defaultValue={
          //                   paymentForEdit ? paymentForEdit.amount : 0
          //                 }
          //               />
          //             }
          //           />
          //         </Grid>
          //       </Grid>
          //     ) : (
          //       ""
          //     )}
          //   </TableContainer>
        ),
      }),
    ];

    return (
      <Box style={{ overflowX: "hidden" }}>
        {userCanApplyFullCredit && !paymentForEdit && (
          <div style={{ display: "flex", position: "relative" }}>
            <ListItem
              style={{
                padding: 0,
                position: "absolute",
                zIndex: 10,
                top: "0px",
                left: "350px",
                zIndex: 10000,
              }}
            >
              <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                  <Iconify
                    icon={"solar:hand-money-linear"}
                    width={25}
                    height={25}
                    style={{ color: "#009688" }}
                  />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: "grid",
                      columnGap: 10,
                      rowGap: 10,
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                      },
                    }}
                    mb={1}
                    mr={2}
                  >
                    <Controller
                      name="apply_credit"
                      control={control}
                      as={({ onChange, value }) => (
                        <Controls.Checkbox
                          label="Do you like to apply a Total Credit for this Refund"
                          checked={value === 1 ? true : false}
                          onChange={(value) => onChange(value ? 1 : 0)}
                        />
                      )}
                    />
                  </Box>
                }
              />
            </ListItem>
          </div>
        )}

        <MTableR2
          tableRef={tableRef3}
          title={paymentForEdit ? "Payment" : "Payments"}
          columns={columns}
          data={
            paymentForEdit
              ? invoice.payments.data.filter(
                  (payment) => payment.id == paymentForEdit.id
                )
              : invoice.payments?.data.filter(
                  (payment) => payment.isRefund == 0
                  /* && payment.possible_refund == 0 */
                )
          }
          //style={{ marginTop: title ? "" : -40 }}
          //isLoading={isLoading}
          // options={{
          //   search: false,
          //   padding: "dense",
          //   paging: false,
          //   pageSize: 10,
          //   pageSizeOptions: [],
          //   emptyRowsWhenPaging: false,
          //   headerStyle: {
          //     height: 40,
          //     fontSize: 13,
          //     fontWeight: 700,
          //     color: "#000000",
          //     backgroundColor: "#e0f2f1",
          //   },
          // }}
          toolbar
          detailPanel={detailPanelAA}
        />
      </Box>
    );
  };

  const onSubmit = (data) => {
    console.log("onSubmit__ ", data);
    //console.log(watchAllFields.itemsAmount);
    //console.log(invoice);
    if (!data.responsible_id) {
      delete data.responsible_id;
    }
    if (watchAllFields.itemsAmount) {
      var tempitems = invoice.items.data.map((item, index) => {
        return {
          item: item.id,
          edit_id: paymentForEdit ? getDetailId(item.id) : null,
          item_name: item.name,
          amount:
            paymentForEdit && isEmpty(paymentForEdit.paymentdetail.data)
              ? parseFloat(watchAllFields.itemsAmount[0])
              : parseFloat(watchAllFields.itemsAmount[index]),
        };
      });
      var items = null;
      if (!paymentForEdit) {
        items = tempitems.filter((item) => {
          return item.amount > 0;
        });
      } else {
        items = tempitems;
      }

      if (refundFullItem === 1) {
        var temp = [];
        items.forEach((element) => {
          if (element.item_name === itemName) element.void = 1;
          else element.void = 0;
          temp.push(element);
        });
        items = temp;
      }

      data.amount = calculeTotal2Pay();

      if (isRefund) {
        data.refunddetail = JSON.stringify(refundDetails.current);
        data.amount = totalToRefund;
      } else {
        data.amount = calculeTotal2Pay();
        data.paymentdetail = JSON.stringify(items);
      }

      console.log("theeditrefunddaais ", data);

      if (parseFloat(data.amount) <= 0) {
        setNotify({
          isOpen: true,
          message: "Payment amount can't be less than 1",
          type: "error",
        });
      } else {
        console.log("asdqweasdqwe before data ", data);
        if (
          firstPayment ||
          (paymentForEdit && isEmpty(paymentForEdit.paymentdetail.data))
        ) {
          savePayment(data);
        } else if (maxAmount) {
          savePayment(data);
        } else {
          if (invoice && parseFloat(invoice.balance) >= calculeTotal2Pay()) {
            savePayment(data);
          } else if (paymentForEdit && invoice) {
            if (
              parseFloat(invoice.balance) + parseFloat(paymentForEdit.amount) >=
              calculeTotal2Pay()
            ) {
              savePayment(data);
            }
          } else {
            setNotify({
              isOpen: true,
              message: `Payment amount can't be more than ${invoice.balance}`,
              type: "error",
            });
          }
        }
      }
    } else {
      console.log("theeditrefunddaas else");
    }
  };
  const checkAndFix = async (id) => {
    const { data } = await Api.checkAndFixInvoice(id);
    //console.error(data);
    setMessage(data);
    reload ? reload() : "";
    //setInvoiceForRefund(data.data);
    //setOpenRefund(true);
  };

  const brokenPaymentsCount = () => {
    let temp = [];
    if (invoice && invoice.payments)
      temp = invoice.payments.data.filter((payment) => {
        return payment["paymentdetail"]["data"].length === 0;
      });
    return temp.length;
  };

  const calculateSum = (array, property) => {
    const total = array.reduce((accumulator, object) => {
      return parseInt(accumulator) + parseInt(object[property]);
    }, 0);
    return total;
  };

  const getTotalToPay = () => {
    let total = calculateSum(refundDetails.current, "amount");

    setTotalToRefund(total ? total : 0);
  };

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  useEffect(() => {
    console.log("refundDetailsss ", refundDetails.current);
    getTotalToPay();
  }, [refundDetails.current]);

  useEffect(() => {
    tableRef1.current?.refresh();
    // console.log("payment for edit ", paymentForEdit);
    let dataaa = invoice.payments?.data.filter(
      (payment) => payment.isRefund == 0
    );
    // console.log("the dataaa is ", dataaa);
    // console.log("paymentForEdit", paymentForEdit);
    if (isEmpty(buildings))
      dispatch(fetchBuildingsService(authUser.organization_id));
    if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));
  }, []);

  useEffect(() => {
    if (watchAllFields.building_id && !userCanEditPaymentDateLock) {
      const building = buildings.find((building) => {
        return building.id === watchAllFields.building_id;
      });
      if (building && building.lock_payment_date) {
        setValue("date", moment().format("YYYY-MM-DD HH:mm:ss"));

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  }, [watchAllFields.building_id]);

  let overPaidItem = invoice.items.data.filter((item) => {
    return item.type === "system";
  });
  let overPaidPriority = overPaidItem[0]
    ? overPaidItem[0].itemPaid - overPaidItem[0].itemRefund > 0
      ? true
      : false
    : false;

  useEffect(() => {
    //console.log("overPaymentControl",overPaymentControl);
    if (
      overPaidPriority &&
      overPaymentControl < overPaidItem[0].itemPaid - overPaidItem[0].itemRefund
    ) {
      // noOverPaidItems.forEach((item)=>{
      //   let tmp = document.getElementById(item.id);
      //   tmp.value = 0;

      //   //console.log("document.getElementById(item.id)",tmp);
      // })
      invoice.items.data.forEach((item, index) => {
        if (item.type !== "system") {
          setValue(`itemsAmount[${index}]`, 0);
        }
      });
    }
  }, [overPaymentControl]);

  const defaultReason = (payment_id) => {
    let current_payment = invoice.payments?.data.filter(
      (payment) => payment.id == payment_id
    )[0];

    // console.log("refund reason123 current_payment ", current_payment);

    if (current_payment) {
      const dReason = refundReasons.filter(
        (value) => value.id === current_payment.reason_id
      );
      // console.log("refund reason123 dReason ", dReason);
      // console.log("refund reason123 ", dReason.id);
      return dReason.id;
    }

    return "";
  };

  const refundReasonsOptions = () => {
   
    return refundReasons.map((refundReason) => {
      return {
        id: refundReason.id,
        title: refundReason.name,
      };
    });
  };

  const getUserOptions = () => {
    return users
      .filter((user) => {
        return user.is_professional === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  const submitDisable = () => {
    // disabled={!watchAllFields.itemsAmount || Object.keys(minValidations).forEach((key) => formData.append(key, payload[key]))}

    if (!watchAllFields.itemsAmount) {
      return false;
    } else {
      var result = false;
      Object.keys(minValidations).forEach((key) => {
        if (minValidations[key] == true) {
          result = true;
        }
      });

      return result;
    }
  };

  // useEffect(() => {
  //   console.log("paymentForEdit: ", paymentForEdit);
  // }, [paymentForEdit]);

  const renderPaymentMenu = (rowData) => {
    // console.log("inside optionssss rowData ", rowData);
    // console.log("inside optionssss refundDetails ", refundDetails.current);
    let refund_detail = refundDetails.current.filter((item) => {
      // console.log("inside optionssss item ", item);
      return (
        item.item == rowData.item_id && item.payment_id == rowData.payment_id
      );
    })[0];
    let current_payment = invoice.payments?.data?.filter((payment) => {
      return payment.id == rowData.payment_id;
    })[0];
    // console.log("current_payment123 ", current_payment);
    // console.log("inside optionssss refund_detail ", refund_detail);
    return (
      <Grid container spacing="2">
        <Grid item xs={3}>
          <Controller
            name="refund_reason_id"
            control={control}
            defaultValue={
              refund_detail?.refund_reason_id
                ? refund_detail.refund_reason_id
                : current_payment.refund_reason_id
            }
            as={({ onChange, value }) => (
              <Controls.Select
                // defaultValue={
                //   paymentForEdit && paymentForEdit.refund_reason_id
                //     ? paymentForEdit.refund_reason_id
                //     : refundRequestsForEdit
                //     ? refundRequestsForEdit.reason_id
                //     : defaultReason()
                // }
                //disabled={readonly}
                label="*Refund Reasons"
                options={refundReasonsOptions()}
                onChange={(event) => {
                  onChange(event);
                  //refund_detail.refund_reason_id = event.target.value;
                  updateRefundDetail(
                    rowData,
                    "refund_reason_id",
                    event.target.value
                  );
                  // console.log("refund reason   value ", event.target.value);
                  // console.log(
                  //   "refund reason   refundDetails.current ",
                  //   refundDetails.current
                  // );
                }}
                value={value}
              />
            )}
            //rules={{ required: "Refund Reason is required" }}
          />

          {errors.refund_reason_id && (
            <p className="error">{errors.refund_reason_id.message}</p>
          )}
        </Grid>
        <Grid item xs={refundReasons.length > 0 ? 4 : 7}>
          <Controller
            name="payment_type"
            control={control}
            defaultValue={
              refund_detail?.payment_type
                ? refund_detail.payment_type
                : current_payment.payment_type
            }
            as={({ onChange, value }) => (
              <Controls.Select
                label="Payment Type"
                options={paymentOptions()}
                onChange={(event) => {
                  onChange(event);
                  //refund_detail.payment_type = event.target.value;
                  updateRefundDetail(
                    rowData,
                    "payment_type",
                    event.target.value
                  );
                  // console.log(
                  //   "payment_type   refundDetails.current ",
                  //   refundDetails.current
                  // );
                }}
                value={value}
              />
            )}
            rules={{ required: "Payment type is required" }}
          />
          {errors.payment_type && (
            <p className="error">{errors.payment_type.message}</p>
          )}
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="date"
            type="date"
            control={control}
            defaultValue={
              paymentForEdit
                ? moment(paymentForEdit.date).format("YYYY-MM-DD HH:mm:ss")
                : moment().format("YYYY-MM-DD HH:mm:ss")
            }
            as={({ onChange, value }) => (
              <Controls.DateTimePicker
                label="Date"
                shouldCloseOnSelect
                defaultValue={
                  refund_detail?.date
                    ? moment(refund_detail.date).format("YYYY-MM-DD HH:mm:ss")
                    : current_payment?.payment_type
                    ? moment(current_payment?.payment_type).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : moment().format("YYYY-MM-DD HH:mm:ss")
                }
                // defaultValue={
                //   paymentForEdit
                //     ? moment(paymentForEdit.date).format("YYYY-MM-DD HH:mm:ss")
                //     : moment().format("YYYY-MM-DD HH:mm:ss")
                // }
                disabled={!userCanEditPayment || lockDate}
                onChange={(selectedDate) => {
                  onChange(selectedDate);
                  const formattedDate = moment(selectedDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  updateRefundDetail(rowData, "payment_date", formattedDate);
                }}
                value={value}
              />
            )}
            rules={{
              required: "Date is required",
            }}
          />
          {errors.date && <p className="error">{errors.date.message}</p>}
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name="file"
            defaultValue={refund_detail?.file ? refund_detail.file : null}
            as={({ onChange, value }) => (
              <Controls.DropzoneDialog
                label="*Attach file"
                onChange={(files) => {
                  if (paymentForEdit) {
                    setFile(files[0]);
                    onChange(files[0]);
                    //refund_detail.file = files[0];
                    updateRefundDetail(rowData, "file_ref", true);
                    updateRefundDetail(rowData, "file", files[0]);
                    // console.log(
                    //   "refund reason   refundDetails.current ",
                    //   refundDetails.current
                    // );
                  } else {
                    setFile(files[0]);
                    onChange(files[0]);
                    const file = files[0];
                    updateRefundDetail(rowData, "file_ref", true);
                    fileToBase64(file).then((base64String) => {
                      // console.log("File converted to base64:", base64String);
                      updateRefundDetail(rowData, "file", base64String);
                    });
                    // console.log(
                    //   "refund reason   refundDetails.current ",
                    //   refundDetails.current
                    // );
                  }
                }}
                value={value}
              />
            )}
            // rules={{ required: !paymentForEdit }}
          />
          {/* {errors.file && <p className="error">{"File is required"}</p>} */}
          {refund_detail.file_ref && <span>{"Attached File"}</span>}
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Card Holder"
            variant="outlined"
            defaultValue={
              refund_detail?.cardholder
                ? refund_detail.cardholder
                : current_payment.cardholder
            }
            // defaultValue={paymentForEdit?.cardholder || ""}
            onChange={(event) => {
              //refund_detail.cardholder = event.target.value;
              updateRefundDetail(rowData, "cardholder", event.target.value);
              // console.log(
              //   "cardholder   refundDetails.current ",
              //   refundDetails.current
              // );
            }}
          />
        </Grid>
        {authUser.organization.data.multiple_locations === 1 && (
          <Grid item xs={3}>
            <Controller
              name="building_id"
              control={control}
              defaultValue={
                refund_detail?.building_id
                  ? refund_detail.building_id
                  : current_payment.building_id
              }
              // defaultValue={
              //   paymentForEdit?.building_id || authUser.building_id || ""
              // }
              as={({ onChange, value }) => (
                <Controls.Select
                  label="Building"
                  options={buildingOptions()}
                  onChange={(event) => {
                    onChange(event);
                    //refund_detail.building_id = event.target.value;
                    updateRefundDetail(
                      rowData,
                      "building_id",
                      event.target.value
                    );
                    // console.log(
                    //   "building_id  refundDetails.current ",
                    //   refundDetails.current
                    // );
                  }}
                  value={value}
                  disabled={!userCanChangeBuilding}
                />
              )}
              rules={{
                required: "Building is required",
              }}
            />
            {errors.building_id && (
              <p className="error">{errors.building_id.message}</p>
            )}
          </Grid>
        )}
        {/* <Grid item xs={4}>
          <Controller
            name="transaction_id"
            control={control}
            defaultValue={paymentForEdit?.transaction_id || ""}
            as={<Controls.Input label="Transaction id" />}
          />
        </Grid> */}
        <Grid item xs={2}>
          <TextField
            type="number"
            label="Last 4 CC"
            InputProps={{ inputProps: { max: 9999 } }}
            variant="outlined"
            defaultValue={
              refund_detail?.cc_last_4
                ? refund_detail.cc_last_4
                : current_payment.cc_last_4
            }
            // defaultValue={paymentForEdit?.cc_last_4 || ""}
            onChange={(event) => {
              //refund_detail.cc_last_4 = event.target.value;
              updateRefundDetail(rowData, "cc_last_4", event.target.value);
              // console.log(
              //   "cc_last_4   refundDetails.current ",
              //   refundDetails.current
              // );
            }}
          />
        </Grid>
        {
          <Grid item xs={4}>
            <Controller
              name="responsible_id"
              control={control}
              as={({ onChange, value }) => (
                <Controls.Select
                  defaultValue={
                    refund_detail?.responsible_id
                      ? refund_detail.responsible_id
                      : current_payment.responsible_id
                  }
                  // defaultValue={
                  //   paymentForEdit && paymentForEdit.responsible_id
                  //     ? paymentForEdit.responsible_id
                  //     : -1
                  // }
                  allowEmpty={true}
                  label="Surgeon"
                  options={getUserOptions()}
                  onChange={(event) => {
                    onChange(event);
                    //refund_detail.responsible_id = event.target.value;
                    updateRefundDetail(
                      rowData,
                      "responsible_id",
                      event.target.value
                    );
                    // console.log(
                    //   "responsible_id   refundDetails.current ",
                    //   refundDetails.current
                    // );
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
        }
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Controller
            name="isChargeback"
            control={control}
            defaultValue={
              refund_detail?.isChargeback
                ? refund_detail.isChargeback
                : current_payment.isChargeback
            }
            // defaultValue={paymentForEdit?.isChargeback || 0}
            as={({ onChange, value }) => (
              <Controls.Checkbox
                label="Chargeback"
                checked={value === 1 ? true : false}
                onChange={(value) => {
                  onChange(value ? 1 : 0);
                  //refund_detail.isChargeback = value ? 1 : 0;
                  updateRefundDetail(rowData, "isChargeback", value ? 1 : 0);
                  // console.log(
                  //   "isChargeback   refundDetails.current ",
                  //   refundDetails.current
                  // );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Controller
            name="ptChangePayment"
            control={control}
            defaultValue={
              refund_detail?.ptChangePayment
                ? refund_detail.ptChangePayment
                : current_payment.ptChangePayment
            }
            // defaultValue={paymentForEdit?.ptChangePayment || 0}
            as={({ onChange, value }) => (
              <Controls.Checkbox
                label="Patient Change Payment"
                checked={value === 1 ? true : false}
                onChange={(value) => {
                  onChange(value ? 1 : 0);
                  //refund_detail.ptChangePayment = value ? 1 : 0;
                  updateRefundDetail(rowData, "ptChangePayment", value ? 1 : 0);
                  // console.log(
                  //   "ptChangePayment   refundDetails.current ",
                  //   refundDetails.current
                  // );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Details"
            variant="outlined"
            defaultValue={
              refund_detail?.details
                ? refund_detail.details
                : current_payment.details
            }
            // defaultValue={
            //   paymentForEdit
            //     ? paymentForEdit.details
            //     : refundComment
            //     ? refundComment
            //     : ""
            // }
            onChange={(event) => {
              //refund_detail.details = event.target.value;
              updateRefundDetail(rowData, "details", event.target.value);
              // console.log(
              //   "details   refundDetails.current ",
              //   refundDetails.current
              // );
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {/* {brokenPaymentsCount() > 0 && invoice && allowFix && (
        <div>
          {"Payment Details not found!"}
          <Grid item xs={4}>
            <Controls.Button
              type="submit"
              text="Check And Fix"
              onClick={() => {
                setMessage("Running...");
                checkAndFix(invoice.id);
              }}
              disabled={false}
            />
          </Grid>
        </div>
      )} */}
      {/* {message} */}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container spacing="2">
          {/* {renderPaymentMenu()} */}
          <Grid item xs={12}>
            <Box my={5}>{renderRefundPanel()}</Box>

            {refundFull === 1 && (
              <Controller
                name="isFull"
                control={control}
                defaultValue={
                  paymentForEdit
                    ? paymentForEdit.isFull
                    : refundFull
                    ? refundFull
                    : 0
                }
                as={({ value, onChange }) => (
                  <Controls.Checkbox
                    label="Full"
                    checked={refundFull === 1 ? true : false}
                    onChange={(value) => onChange(value ? 1 : 0)}
                  />
                )}
              />
            )}

            {refundFullItem === 1 && (
              <Controller
                name="isFullItem"
                control={control}
                defaultValue={
                  paymentForEdit
                    ? paymentForEdit.isFullItem
                    : refundFullItem
                    ? refundFullItem
                    : 0
                }
                as={({ value, onChange }) => (
                  <Controls.Checkbox
                    label="Full Item"
                    checked={refundFullItem === 1 ? true : false}
                    onChange={(value) => onChange(value ? 1 : 0)}
                  />
                )}
              />
            )}

            {(itemName || refundFullItem || refundFull) && (
              <Controller
                name="reAdjust"
                control={control}
                defaultValue={paymentForEdit ? paymentForEdit.reAdjust : false}
                as={({ value, onChange }) => (
                  <Controls.Checkbox
                    label="Re - Adjust"
                    checked={value === 1 ? true : false}
                    onChange={(value) => onChange(value ? 1 : 0)}
                  />
                )}
              />
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Tooltip
                  title={
                    isSubmitDisabled()
                      ? "You must fill all required fields"
                      : ""
                  }
                >
                  <span>
                    {" "}
                    <Button
                      color="primary"
                      disabled={isSubmitDisabled()}
                      isLoading={true}
                      onClick={() => {
                        console.log(
                          "asdqweasdqwe clicking submit ",
                          paymentForEdit,
                          " refundDetails.current ",
                          refundDetails.current
                        );
                        // console.log(
                        //   "submitting  refundDetails.currentttttt ",
                        //   refundDetails.current
                        // );
                        paymentForEdit
                          ? savePayment(
                              refundDetails.current[0],
                              setIsSubmitting
                            )
                          : // : Api.saveInvoicePayment(invoice.id, {
                            //     isRefund: true,
                            //     refunds: refundDetails.current,
                            //   });
                            savePayment(
                              {
                                isRefund: true,
                                refunds: refundDetails.current,
                                apply_credit:
                                  watchAllFields.apply_credit !== undefined
                                    ? watchAllFields.apply_credit
                                    : 0,
                              },
                              setIsSubmitting
                            );
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                      {isSubmitting && (
                        <CircularProgress
                          style={{ marginLeft: 10 }}
                          size={20}
                        />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </div>
              {
                <div
                  style={{
                    width: "-webkit-fill-available",
                    textAlign: "right",
                  }}
                >
                  <Typography>Total To Refund: ${totalToRefund}</Typography>
                </div>
              }
            </div>
          </Grid>
          <Notification notify={notify} setNotify={setNotify} />
        </Grid>
      </form>
    </>
  );
}
