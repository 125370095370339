import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  sources: [],
};

const sourcesSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {
    getSources: (state) => {
      state.loading = true;
    },
    getSourcesSuccess: (state, { payload }) => {
      state.sources = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getSourcesFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
  },
});

export const { getSources, getSourcesSuccess, getSourcesFailure } =
  sourcesSlice.actions;

export const sourcesSelector = (state) => state.sources;
export default sourcesSlice.reducer;

export function fetchSourcesService(orgId) {
  return async (dispatch) => {
    dispatch(getSources());

    try {
      const response = await Api.getContactSources(orgId);
      dispatch(getSourcesSuccess(response));
    } catch (error) {
      dispatch(getSourcesFailure(error));
    }
  };
}
