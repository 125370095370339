import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Api from "../lib/api";
import { isEmpty, forEach } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  withStyles,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "./controls";
import Iconify from "./Iconify";
import ScrollBar from "react-perfect-scrollbar";
import { maxHeight } from "@material-ui/system";
import InputMoneyOkWithoutController from "./controls/InputMoneyOkWithoutController";
import { findIndex } from "lodash";
import IconButton from "./controls/IconButton";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import InputMoneyToPaymentForm from "./controls/InputMoneyToPaymentForm";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    marginTop: -1,
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  invoiceCard: {
    margin: "5px 0px",
    border: "1px solid #8080800d",
    background: "#90a0b921",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    background: "#FFF",
    alignItems: "center",
    marginTop: "5px",
  },
  paymentDetailsTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #80808024",
  },
  paymentBackground: {
    borderRadius: "10px",
    padding: "12px 10px",
    paddingTop: 0,
  },
  paymentDetailsTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: "#263b4d",
  },
  paymentDetailsCreatedBy: {
    fontWeight: 500,
    color: "#534d4d",
  },
  amount: {
    background: "#9ad6ff40",
    padding: "9px 12px",
    borderRadius: 46,
    color: "#005daf",
    fontWeight: 600,
  },
  detail: {
    color: "#404143",
    fontWeight: 500,
    marginLeft: "26px",
  },
  invoiceIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    color: "#617c9f",
  },
  maxButton: {
    borderRadius: 10,
    background: "#daf1ef",
    padding: "0px 12px",
    minWidth: "fit-content",
    fontSize: 13,
  },
  detailColumnTitle: {
    fontWeight: 600,
    fontSize: 15,
  },
  detailField: {
    display: "grid",
    alignItems: "center",
  },
  detail: {
    background: "white",
    padding: 10,
    marginTop: 4,
  },
}));

function TransferPayment(props) {
  const {
    payment,
    onTransferPayment = null,
    invoice = null,
    onClose = null,
  } = props;
  const [histories, setHistories] = useState([]);
  const [errors, setErrors] = useState({});
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const classes = useStyles();
  const [totalAmount, setTotalAmount] = useState(0);
  const [siblingInvoices, setSiblingInvoices] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const [transferSaveLoading, setTransferSaveLoading] = useState(false);

  const getSiblingInvoices = async () => {
    setInvoicesLoading(true);
    const { data } = await Api.getInvoicesSiblings(payment.invoice);
    // console.log("siblingInvoices ", data);
    setSiblingInvoices(data.data);

    setInvoicesLoading(false);
  };

  const makeTransfer = async () => {
    try {
      setTransferSaveLoading(true);
      var payload = {
        total_amount: totalAmount,
        histories: histories,
        detailsToUpdate: paymentDetails,
      };

      const { data } = await Api.transferInvoiceMultiPayment(
        payment.id,
        payload
      );
      if (typeof onTransferPayment === "function") {
        await onTransferPayment();
      }
      setTransferSaveLoading(false);
      setNotify({
        isOpen: true,
        message: "Payment transferred successfully!",
        type: "success",
      });
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });
    } catch (error) {
      setTransferSaveLoading(false);
      setConfirmDialog({
        isOpen: false,
        title: "",
        subTitle: "",
      });

      if (error.status == 409 && (error?.data?.error || false)) {
      
        setNotify({
          isOpen: true,
          message: error.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const renderInvoices = () => {
    return siblingInvoices.map((invoice) => {
      return (
        <Card key={invoice.id} className={classes.invoiceCard}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4} spacing={3}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Iconify
                    className={classes.invoiceIcon}
                    icon="fa6-solid:file-invoice-dollar"
                  />
                  <Typography style={{ fontWeight: 500 }}>
                    {" "}
                    {invoice.name}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4} spacing={3} style={{ fontWeight: 500 }}>
                Total: {` $${invoice.total_price}`}
              </Grid>
              <Grid
                item
                xs={4}
                spacing={3}
                style={{ fontWeight: 500, color: "#ab2626" }}
              >
                Balance: {` $${invoice.balance}`}
              </Grid>
              {invoice.items.data
                ? renderInvoiceItems(
                    invoice.items.data.filter((a) => a.type != "package")
                  )
                : ""}
            </Grid>
          </CardContent>
        </Card>
      );
    });
  };

  const updateInvoiceValue = (item, value) => {
    const index = findIndex(histories, ["invoice_id", item.invoice_id]);
    if (index == -1) {
      const tempHistory = {
        ...payment,
        ...{
          invoice: item.invoice_id,
          amount: value,
          invoice_id: item.invoice_id,
          paymentDetailsNew: [
            {
              item_id: item.id,
              amount: value,
            },
          ],
        },
      };

      setHistories([tempHistory, ...histories]);
    } else {
      const details = [...histories[index]["paymentDetailsNew"]];
      const indexItem = findIndex(details, ["item_id", item.id]);

      if (indexItem != -1) {
        const updatedItemEvent = {
          ...details[indexItem],
          ...{ amount: value },
        };
        details[indexItem] = updatedItemEvent;
      } else {
        details.push({
          item_id: item.id,
          amount: value,
        });
      }
      const updatedEvent = {
        ...histories[index],
        ...{
          paymentDetailsNew: details,
          amount: details.reduce(
            (total, item) =>
              total +
              parseFloat(item.amount && item.amount != "" ? item.amount : 0),
            0
          ),
        },
      };

      let newHistories = [...histories];
      newHistories[index] = updatedEvent;
      setHistories([...newHistories]);
    }
  };

  const renderInvoiceItems = (items) => {
    return items.map((item) => {
      var itemHistory = histories.find((a) =>
        a.paymentDetailsNew.find((t) => t.item_id == item.id)
      );
      var itemInHistory = itemHistory
        ? itemHistory.paymentDetailsNew.find((t) => t.item_id == item.id)
        : null;

      return (
        <Grid container spacing={2} my={2} className={classes.paper}>
          <Grid style={{ color: "#232c47" }} item xs={4} spacing={3}>
            {item.name}
          </Grid>
          <Grid style={{ color: "#232c47" }} item xs={4} spacing={3}>
            ${item.price}
          </Grid>
          <Grid item xs={4} spacing={3}>
            <div>
              <InputMoneyOkWithoutController
                greaterThanZero={false}
                label="Amount"
                customInputProps={{
                  endAdornment: (
                    <Button
                      className={classes.maxButton}
                      disabled={
                        parseFloat(totalAmount) ==
                          parseFloat(
                            paymentDetails.reduce(
                              (total, item) =>
                                total +
                                parseFloat(
                                  item.amount_to_transfer &&
                                    item.amount_to_transfer != ""
                                    ? item.amount_to_transfer
                                    : 0
                                ),
                              0
                            )
                          ) ||
                        parseFloat(
                          paymentDetails.reduce(
                            (total, item) =>
                              total +
                              parseFloat(
                                item.amount_to_transfer &&
                                  item.amount_to_transfer != ""
                                  ? item.amount_to_transfer
                                  : 0
                              ),
                            0
                          )
                        ) -
                          parseFloat(totalAmount) <
                          0
                      }
                      onClick={() => {
                        updateInvoiceValue(
                          item,
                          parseFloat(
                            paymentDetails.reduce(
                              (total, item) =>
                                total +
                                parseFloat(
                                  item.amount_to_transfer &&
                                    item.amount_to_transfer != ""
                                    ? item.amount_to_transfer
                                    : 0
                                ),
                              0
                            )
                          ) -
                            (parseFloat(totalAmount) -
                              parseFloat(
                                itemInHistory && itemInHistory.amount
                                  ? itemInHistory.amount
                                  : 0
                              ))
                        );
                      }}
                    >
                      Max
                    </Button>
                  ),
                }}
                onChange={(v) => {
                  if (
                    ((paymentDetails.reduce(
                      (total, item) =>
                        total +
                        parseFloat(
                          item.amount_to_transfer &&
                            item.amount_to_transfer != ""
                            ? item.amount_to_transfer
                            : 0
                        ),
                      0
                    ) == 0 ||
                      paymentDetails.reduce(
                        (total, item) =>
                          total +
                          parseFloat(
                            item.amount_to_transfer &&
                              item.amount_to_transfer != ""
                              ? item.amount_to_transfer
                              : 0
                          ),
                        0
                      ) -
                        totalAmount <
                        0) &&
                      itemInHistory &&
                      itemInHistory.amount) ||
                    v <=
                      parseFloat(
                        paymentDetails.reduce(
                          (total, item) =>
                            total +
                            parseFloat(
                              item.amount_to_transfer &&
                                item.amount_to_transfer != ""
                                ? item.amount_to_transfer
                                : 0
                            ),
                          0
                        )
                      ) -
                        (parseFloat(totalAmount) -
                          parseFloat(
                            itemInHistory && itemInHistory.amount
                              ? itemInHistory.amount
                              : 0
                          ))
                  ) {
                    updateInvoiceValue(item, v);
                  }
                }}
                value={
                  itemInHistory &&
                  (itemInHistory.amount == 0 || itemInHistory.amount)
                    ? itemInHistory.amount
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
      );
    });
  };

  useEffect(() => {
    if (payment) {
      getSiblingInvoices();

      if (
        payment &&
        payment.paymentdetail &&
        payment.paymentdetail.data &&
        !isEmpty(payment.paymentdetail.data)
      ) {
        var tempPaymentDetails = payment.paymentdetail.data.map((det) => {
          return {
            id: det.id,
            amount: det.amount,
            amount_to_transfer: 0,
            checked: false,
            item_id: det.item_id,
            item_name: det.item_name,
            min_detail_amount: det.min_detail_amount,
            payment: det.payment,
          };
        });
        setPaymentDetails([...tempPaymentDetails]);
      }
    }
  }, [payment]);

  const updateTotalAmount = () => {
    var totalA = 0;

    if (!isEmpty(histories)) {
      totalA = histories.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
    }

    setTotalAmount(totalA);
  };

  const updateDetail = (detail_id, props) => {
    const index = findIndex(paymentDetails, ["id", detail_id]);
    const updatedEvent = {
      ...paymentDetails[index],
      ...props,
    };
    let newDetails = [...paymentDetails];
    newDetails[index] = updatedEvent;
    setPaymentDetails([...newDetails]);
  };

  const renderPaymentDetails = () => {
    return paymentDetails && paymentDetails.length > 0 ? (
      <div style={{ padding: 8, background: "#90a0b921", borderRadius: 4 }}>
        {paymentDetails.map((detail) => {
          var invoice_item =
            invoice &&
            invoice.items &&
            invoice.items.data &&
            invoice.items.data.find((item) => item.id == detail.item_id)
              ? invoice.items.data.find((item) => item.id == detail.item_id)
              : null;
          var availableAmount =
            invoice_item.done || invoice_item.done_product_control
              ? invoice_item.itemBalance < 0
                ? invoice_item.itemBalance * -1 -
                    parseFloat(detail.amount) -
                    parseFloat(
                      detail.min_detail_amount && detail.min_detail_amount != ""
                        ? detail.min_detail_amount
                        : 0
                    ) <
                  0
                  ? invoice_item.itemBalance * -1
                  : parseFloat(detail.amount) -
                    parseFloat(
                      detail.min_detail_amount && detail.min_detail_amount != ""
                        ? detail.min_detail_amount
                        : 0
                    )
                : 0
              : parseFloat(detail.amount) -
                parseFloat(
                  detail.min_detail_amount && detail.min_detail_amount != ""
                    ? detail.min_detail_amount
                    : 0
                );

          return (
            <Grid container className={classes.detail}>
              <Grid item xs={5}></Grid>
              <Grid item xs={2}>
                <Typography className={classes.detailColumnTitle}>
                  Amount
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.detailColumnTitle}>
                  Available to transfer
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.detailColumnTitle}>
                  Amount to transfer
                </Typography>
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={5}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={availableAmount == 0}
                      onClick={() => {
                        updateDetail(detail.id, {
                          amount_to_transfer: detail.checked
                            ? 0
                            : detail.amount_to_transfer,
                          checked: !detail.checked,
                        });
                      }}
                      checked={detail.checked}
                    />
                  }
                  label={detail.item_name}
                />
              </Grid>
              <Grid className={classes.detailField} item xs={2}>
                <Typography>{"$" + detail.amount}</Typography>
              </Grid>
              <Grid className={classes.detailField} item xs={3}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>{"$" + availableAmount}</Typography>
                  {!invoice_item.done &&
                  !invoice_item.done_product_control &&
                  detail.min_detail_amount &&
                  detail.min_detail_amount != "" &&
                  parseFloat(detail.min_detail_amount) > 0 ? (
                    <HtmlTooltip
                      title={
                        <>
                          <table>
                            <tr style={{ color: "#263b4d" }}>
                              <td>Total Amount</td>
                              <td style={{ textAlign: "right" }}>
                                {"$" + detail.amount}
                              </td>
                            </tr>
                            {detail.min_detail_amount ? (
                              <tr style={{ color: "rgb(169 0 0)" }}>
                                <td>Refunded Amount</td>
                                <td style={{ textAlign: "right" }}>
                                  {"- $" + detail.min_detail_amount}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            <tr
                              style={{
                                color: "rgb(18 125 72)",
                                borderTop: "1px solid gray",
                              }}
                            >
                              <td>Available Amount</td>
                              <td
                                style={{
                                  textAlign: "right",
                                  borderTop: "1px solid #80808052",
                                }}
                              >
                                {"$" +
                                  (parseFloat(detail.amount) -
                                    parseFloat(detail.min_detail_amount))}
                              </td>
                            </tr>
                          </table>
                        </>
                      }
                    >
                      <span>
                        {" "}
                        <Iconify
                          style={{
                            marginTop: 8,
                            marginLeft: 4,
                            width: 21,
                            height: 21,
                            color: "rgb(106 165 217)",
                          }}
                          icon="fluent:info-24-filled"
                        />
                      </span>
                    </HtmlTooltip>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid className={classes.detailField} item xs={2}>
                <InputMoneyToPaymentForm
                  onChange={(value) => {
                    updateDetail(detail.id, { amount_to_transfer: value });
                  }}
                  value={detail.amount_to_transfer}
                  disabled={!detail.checked}
                  maximumValue={availableAmount}
                  minimumValue={0}
                />
              </Grid>
            </Grid>
          );
        })}
      </div>
    ) : (
      ""
    );
  };

  useEffect(() => {
    updateTotalAmount();
  }, [histories]);

  if (!payment) return null;
  return (
    <Box my={5}>
      <ScrollBar style={{ maxHeight: 630 }}>
        <div className={classes.paymentBackground}>
          <div className={classes.paymentDetailsTitleContainer}>
            <Typography className={classes.paymentDetailsTitle}>
              PAYMENT DETAILS{" "}
            </Typography>
            {payment.user && !isEmpty(payment.user.data) ? (
              <Typography className={classes.paymentDetailsCreatedBy}>
                Created By: {payment.user ? payment.user.data.title : ""}
              </Typography>
            ) : (
              ""
            )}
          </div>

          <div style={{ padding: "10px 5px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "15px" }}>
                <Typography style={{ fontWeight: 500, color: "#4d4d4d" }}>
                  {payment.payment_type}{" "}
                </Typography>
                <Typography style={{}}>{payment.date} </Typography>
              </div>
              <div className={classes.amount}>Amount: $ {payment.amount}</div>

              <div className={classes.detail}>
                Card Holder: {payment.cardholder ? payment.cardholder : "-"}
              </div>
              <div className={classes.detail}>Last 4: {payment.cc_last_4}</div>
            </div>
          </div>
        </div>

        {payment &&
        parseFloat(payment.amount) !=
          payment.paymentdetail.data.reduce(
            (total, item) => total + parseFloat(item.amount),
            0
          ) ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#b72121",
              background: "#ffeeee",
              padding: "5px 10px",
              margin: "0 auto",
              maxWidth: "fit-content",
              borderRadius: "13px",
              fontSize: "17px",
            }}
          >
            <Iconify icon="eva:alert-triangle-fill" />
            <Typography style={{ marginLeft: 5 }}>
              You cannot transfer this payment because the sum of the payment
              details amount is not the same as the payment amount.
            </Typography>
          </div>
        ) : (
          <>
            <div style={{ margin: "15px 8px" }}>
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 500,
                  color: "#192937",
                  marginLeft: 6,
                }}
              >
                Transfer from:
              </Typography>
              {renderPaymentDetails()}
            </div>

            <div style={{ margin: "15px 8px", marginTop: 28 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}
                >
                  <Iconify
                    style={{ width: 20, height: 20, color: "#4f80ab" }}
                    icon="foundation:arrow-right"
                  />
                  <Typography
                    style={{
                      fontSize: 17,
                      fontWeight: 500,
                      color: "#192937",
                      marginLeft: 6,
                    }}
                  >
                    Transfer to:
                  </Typography>
                </div>
                <ListItem
                  style={{
                    justifyContent: "right",
                    padding: "10px 14px",
                    paddingRight: 0,
                  }}
                >
                  <ListItemIcon
                    style={{
                      margin: 0,
                      minWidth: "fit-content",
                      marginRight: 10,
                      borderRadius: 32,
                      padding: 7,
                      backgroundColor: "#00b16129",
                    }}
                  >
                    <Iconify
                      icon={
                        "streamline:money-cash-bill-2-currency-billing-payment-finance-cash-bill-money-accounting"
                      }
                      width={23}
                      height={23}
                      style={{ color: "rgb(7 139 103)" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "fit-content",
                      marginRight: 10,
                    }}
                    primary={`Available Amount: `}
                    secondary={`$${(parseFloat(
                      paymentDetails.reduce(
                        (total, item) =>
                          total +
                          parseFloat(
                            item.amount_to_transfer &&
                              item.amount_to_transfer != ""
                              ? item.amount_to_transfer
                              : 0
                          ),
                        0
                      )
                    ) -
                      parseFloat(totalAmount) <
                    0
                      ? 0
                      : paymentDetails.reduce(
                          (total, item) =>
                            total +
                            parseFloat(
                              item.amount_to_transfer &&
                                item.amount_to_transfer != ""
                                ? item.amount_to_transfer
                                : 0
                            ),
                          0
                        ) - totalAmount
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                    primaryTypographyProps={{
                      style: {
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "left",
                        color: "rgb(7 139 103)",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: {
                        marginLeft: 6,
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "left",
                        color: "rgb(7 139 103)",
                      },
                    }}
                  />
                </ListItem>
              </div>

              {invoicesLoading ? <LinearProgress /> : ""}
              {invoicesLoading ||
              (siblingInvoices && siblingInvoices.length > 0) ? (
                renderInvoices()
              ) : (
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 17,
                    padding: "6px 24px",
                    textAlign: "center",
                    fontStyle: "oblique",
                    color: "#44526d",
                  }}
                >
                  Sorry, this contact has no more invoices!
                </div>
              )}
            </div>
          </>
        )}
      </ScrollBar>

      {payment &&
      parseFloat(payment.amount) !=
        payment.paymentdetail.data.reduce(
          (total, item) => total + parseFloat(item.amount),
          0
        ) ? (
        ""
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              paddingRight: 12,
              borderTop: "2px solid #8080801f",
            }}
          >
            {/* <HtmlTooltip
            title={
              <>
                <table>
                  <tr style={{ color: "#263b4d" }}>
                    <td>Total Payment</td>
                    <td style={{ textAlign: "right" }}>{"$" + payment.amount}</td>
                  </tr>
                  {payment.refundedAmount ? (
                    <tr style={{ color: "rgb(169 0 0)" }}>
                      <td>Refunded Amount</td>
                      <td style={{ textAlign: "right" }}>
                        {"- $" + payment.refundedAmount}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
  
                  <tr style={{ color: "rgb(169 0 0)" }}>
                    <td>Distributed Amount</td>
                    <td style={{ textAlign: "right" }}>{"- $" + totalAmount}</td>
                  </tr>
                  <tr
                    style={{
                      color: "rgb(18 125 72)",
                      borderTop: "1px solid gray",
                    }}
                  >
                    <td>Available Amount</td>
                    <td
                      style={{
                        textAlign: "right",
                        borderTop: "1px solid #80808052",
                      }}
                    >
                      {"$" +
                        (parseFloat(payment.amount) -
                          parseFloat(payment.refundedAmount) -
                          totalAmount)}
                    </td>
                  </tr>
                </table>
              </>
            }
          >
            <span>
              {" "}
              <Iconify
                style={{
                  marginTop: 7,
                  marginLeft: 4,
                  width: 21,
                  height: 21,
                  color: "#3b81bf",
                }}
                icon="fluent:info-24-filled"
              />
            </span>
          </HtmlTooltip> */}
          </div>
          <Button
            disabled={
              totalAmount >
                paymentDetails.reduce(
                  (total, item) =>
                    total +
                    parseFloat(
                      item.amount_to_transfer && item.amount_to_transfer != ""
                        ? item.amount_to_transfer
                        : 0
                    ),
                  0
                ) ||
              totalAmount == 0 ||
              transferSaveLoading ||
              totalAmount !=
                paymentDetails.reduce(
                  (total, item) =>
                    total +
                    parseFloat(
                      item.amount_to_transfer && item.amount_to_transfer != ""
                        ? item.amount_to_transfer
                        : 0
                    ),
                  0
                )
            }
            onClick={() =>
              setConfirmDialog({
                isOpen: true,
                title:
                  "Are you sure you want to make the $" +
                  totalAmount +
                  " transfer?",
                subTitle: "",
                onConfirm: () => makeTransfer(),
              })
            }
            color="primary"
            variant="contained"
            style={{ width: "100%", fontSize: 15 }}
            startIcon={
              <Iconify
                style={{ width: 25, height: 25 }}
                icon={transferSaveLoading ? "" : "solar:card-transfer-bold"}
              />
            }
          >
            {transferSaveLoading
              ? "Transferring..."
              : "Transfer " +
                (totalAmount && totalAmount != 0 ? `$${totalAmount}` : "")}
          </Button>
        </>
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={transferSaveLoading}
      />
    </Box>
  );
}

export default TransferPayment;
