import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import paidInFull from "../paid-full.png";
import moment from "moment";
import VOID from "../assets/img/icons/VOID.png";
import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import BallotIcon from "@material-ui/icons/Ballot";
import Iconify from "./Iconify";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: "block";
  height: 100%;
  width: 100%;
  margin: "10px",
  padding: "5px",
`;

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;
const Paid = styled.img`
  position: absolute;
  width: 450px;
  opacity: 0.4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;
const Logo = styled.img`
  position: absolute;
  width: 150px;
  top: 15px;
  right: 15px;
`;
const InvoiceFooter = styled(MuiBox)`
  ${spacing};
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  invoiceDisclaimer: {
    border: "1px solid #999",
    padding: "5px",
    textAlign: "center",
  },
  mainProduct: {},
  childProduct: {
    //marginLeft: "15px",
    paddingLeft: "15px",
    color: "#999",
  },
}));

function PrintInvoiceDetails(props) {
  const { invoice, financingInterest } = props;
  const getItemPrice = (price, base, product_apply_financing) => {
    if (financingInterest && product_apply_financing) {
      const value = base ? base : price;
      return parseFloat(value + (value * financingInterest) / 100).toFixed(2);
    }
    return base || price;
  };
  const { authUser } = useSelector((state) => state.auth);
  const classes = useStyles();

  const renderPayments = () => {
    const payments = invoice.payments.data;
    return payments.map((payment, index) => {
      return (
        <TableRow key={payment.id}>
          <TableCell component="th" scope="row">
            {payment.isRefund ? "Refund" : "Payment"}
          </TableCell>
          <TableCell>{payment.date}</TableCell>
          <TableCell>{payment.payment_type}</TableCell>
          <TableCell>{payment.cc_last_4}</TableCell>
          <TableCell align="right">
            {payment.possible_refund &&
            isEmpty(payment.paymentPossibleRefund.data) ? (
              <Typography variant="body2" style={{ fontStyle: "italic" }}>
                {/* Possible Refund*/}
              </Typography>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell align="right">
            {payment.isRefund ? "-" : ""} {payment.amount}
          </TableCell>
        </TableRow>
      );
    });
  };

  const checkMainProduct = (id) => {
    //Check that Main product exists
    let response = invoice.items.data
      ? invoice.items.data.filter((element) => {
          //return element && element.childProduct && pluck(element.childProduct,'child_product_id').includes(id) === true;
          //console.error(id,element,(element && element.childProducts)?element.childProducts.filter((row)=>{return row.child_product_id == id}).length:"");
          return (
            element &&
            element.childProducts &&
            element.childProducts.filter((row) => {
              return row.child_product_id == id;
            }).length > 0
          );
        })
      : [];

    response = response ? response : [];
    // console.log("response: ", response);
    return response ? response : [];
  };

  const renderItems = () => {
    const items = invoice.items.data;
    return items.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell
            style={{ alignItems: "center" }}
            component="th"
            scope="row"
            className={
              item &&
              item.mainProducts &&
              item.mainProducts.length > 0 &&
              checkMainProduct(item.product_id).length > 0
                ? classes.childProduct
                : classes.mainProduct
            }
          >
            {item.childProducts && item.childProducts.length > 0 && (
              <Iconify
                icon="entypo:shopping-bag"
                style={{ fontSize: 18, color: "gray", marginRigth: 10 }}
              />
            )}
            {checkMainProduct(item.product_id).length > 0 ? (
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {item.name}
              </p>
            ) : (
              <Typography>
                {item.name}
                {item.is_revision ? (
                  <strong
                    style={{
                      color: "#326a9b",
                      marginLeft: 5,
                      fontWeight: 400,
                    }}
                  >
                    {"(Revision)"}
                  </strong>
                ) : (
                  ""
                )}
              </Typography>
            )}
          </TableCell>
          <TableCell>
            {item.parent_id ? (
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.zero_package_value &&
              item.product &&
              !item.product.is_procedure ? (
                <div>$ 0.00</div>
              ) : (
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgb(14, 85, 126)",
                    backgroundColor: "#deeced",
                    textAlign: "center",
                    padding: "0px 15px",
                    maxWidth: "fit-content",
                    borderRadius: 12,
                  }}
                >
                  {"INCLUDED"}
                </div>
              )
            ) : (
              <div>
                {item.product_gift_reason_id ? (
                  <CardGiftcardIcon fontSize="small" color="secondary" />
                ) : item && item.parent_id ? (
                  ""
                ) : (
                  ""
                )}
                ${" "}
                {parseFloat(
                  getItemPrice(
                    item.type == "package" && item.package_price
                      ? item.package_price
                      : item.price,
                    item.type == "package" && item.base_package_price
                      ? item.base_package_price
                      : item.base_price,
                    item.product_apply_financing
                  )
                ).toFixed(2)}
                {item.product_gift_reason_id ? (
                  <>
                    <span>{" / "} </span>
                    <span style={{ color: "#8080808a" }}>
                      {" $ " + item.gift_reference_price}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </TableCell>
          <TableCell>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{item.quantity || 1}</Typography>
              {item.void_quantity ? (
                <Typography
                  style={{
                    fontWeight: 500,
                    marginLeft: 5,
                    color: "#a76c65",
                    fontSize: 12,
                  }}
                >{`(${item.void_quantity} VOID)`}</Typography>
              ) : (
                ""
              )}
            </div>
          </TableCell>
          {invoice && invoice.downpayment_per_procedure ? (
            <TableCell align="right">
              {item.type == "package"
                ? "$" + item.package_downpayment_amount
                : item.downpayment_amount !== null && !item.parent_id
                ? "$" + (item?.downpayment_amount || 0)
                : ""}
            </TableCell>
          ) : (
            ""
          )}

          <TableCell align="right">
            {item && item.parent_id && item.void == 1 ? (
              <img
                src={VOID}
                alt={"VOID"}
                style={{ width: "65px", height: "auto" }}
              />
            ) : item && item.parent_id ? (
              ""
            ) : item.void == 0 ? (
              `$${calculateItemTotal({
                ...item,
                ...{
                  price:
                    item.type == "package" ? item.package_price : item.price,
                },
              }).toFixed(2)}`
            ) : (
              <img
                src={VOID}
                alt={"VOID"}
                style={{ width: "65px", height: "auto" }}
              />
            )}
          </TableCell>
        </TableRow>
      );
    });
  };
  const calulateTotalPayments = () => {
    const payments = invoice
      ? invoice.payments.data.filter((payment) => {
          return !payment.isRefund;
          /* &&
            (payment.possible_refund &&
            isEmpty(payment.paymentPossibleRefund?.data)
              ? false
              : true) */
        })
      : [];

    return payments.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const calulateTotalRefunds = () => {
    const refunds = invoice
      ? invoice.payments.data.filter((payment) => {
          return payment.isRefund;
        })
      : [];

    return refunds.reduce((total, item) => total + parseFloat(item.amount), 0);
  };

  const calculateTotalDue = (items, payments, refunds) => {
    const total = calculateTotal(items);
    const totalPayments = calulateTotalPayments(payments);
    const totalRefunds = calulateTotalRefunds(refunds);
    const credits = calculateTotalCredits();

    return (
      Math.round(total * 100) / 100 -
      Math.round(totalPayments * 100) / 100 -
      Math.round(credits * 100) / 100 +
      Math.round(totalRefunds * 100) / 100
    );
  };

  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    const itemPrice = item.type == "package" ? item.package_price : item.price;
    return (
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };

  const calculateSubtotal = () => {
    const items = invoice.items.data;
    return items
      .filter((item) => item.void == 0)
      .reduce(
        (total, item) =>
          total +
          calculateItemTotal({
            ...item,
            ...{ package_price: 0, base_package_price: 0 },
          }),
        0
      );
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  const calulateTotalPaymentsCreditRefund = () => {
    //const total = calculateTotal(items);
    const totalPayments = calulateTotalPayments();
    const totalRefunds = calulateTotalRefunds();
    const credits = calculateTotalCredits();

    return (
      Math.round(totalPayments) + Math.round(credits) - Math.round(totalRefunds)
    );
  };

  const calculateTotalCredits = () => {
    return invoice?.totalCredits || 0;
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Logo
            src={
              invoice.building_id
                ? invoice.building_logo
                : invoice.organization.data.logo
            }
          />
          {invoice.status === "paid" && <Paid src={paidInFull} />}
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box fontWeight="fontWeightMedium" m={1}>
                INVOICE # {invoice.name}
              </Box>
              <Box fontWeight="fontWeightMedium" m={1}>
                INVOICE Date: {moment(invoice.date).format("YYYY/MM/DD")}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  BILLED FROM:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {invoice.building_id
                    ? invoice.building_name
                    : invoice.organization_name}
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {invoice.building_id
                    ? invoice.building_address
                    : invoice.organization_address}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  BILLED TO:
                </Box>
                <Box fontWeight="fontWeightBold" m={1}>
                  {invoice.contact.data.full_name}
                </Box>
                <Box m={1}>
                  {invoice.contact.data.coordinator_name
                    ? "Consultant: " + invoice.contact.data.coordinator_name
                    : ""}
                </Box>
              </Typography>
            </Grid>

            {/* <Grid item xs={12}>
              {invoice && !isEmpty(invoice.pay_before) && (
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" m={1}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="see"
                      style={{ float: "right" }}
                      disabled
                    >
                      {"Pay Before: " + invoice.pay_before}
                    </Fab>
                  </Box>
                </Typography>
              )}
            </Grid> */}
          </Grid>

          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  {/*
{financingInterest ? "Cash Value / Regular Price" : "Price"}
              */}

                  <TableCell>Quantity</TableCell>
                  {invoice && invoice.downpayment_per_procedure ? (
                    <TableCell align="right">{"Downpayment"}</TableCell>
                  ) : (
                    ""
                  )}

                  <TableCell align="right">
                    {invoice?.invoice_total_name
                      ? invoice?.invoice_total_name
                      : "Total"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderItems()}
                {invoice.included && (
                  <TableRow>
                    <TableCell
                      colSpan={invoice && invoice.downpayment_per_procedure}
                    >
                      INCLUDED: {invoice.included}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              <TableRow>
                <TableCell />
                <TableCell />
                {invoice && invoice.downpayment_per_procedure ? (
                  <TableCell />
                ) : (
                  ""
                )}
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    {invoice?.invoice_total_name
                      ? invoice?.invoice_total_name
                      : "Total"}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    $ {calculateTotal().toFixed(2)}
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
                {invoice && invoice.downpayment_per_procedure ? (
                  <TableCell />
                ) : (
                  ""
                )}
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    Pymts/Credits
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    ${" "}
                    {calulateTotalPaymentsCreditRefund().toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Box>
                </TableCell>
              </TableRow>
              {/*invoice && invoice.totalCredits > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1}>
                      Credits
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1}>
                      $ {calculateTotalCredits()}
                    </Box>
                  </TableCell>
                  <TableCell />
                </TableRow>
              )*/}
              {/*invoice && calulateTotalRefunds() > 0 && (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1}>
                      Refunds
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box fontWeight="fontWeightBold" m={1}>
                      $ {calulateTotalRefunds().toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell />
                </TableRow>
              )*/}
              <TableRow>
                <TableCell />
                <TableCell />
                {invoice && invoice.downpayment_per_procedure ? (
                  <TableCell />
                ) : (
                  ""
                )}
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    Due
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box fontWeight="fontWeightBold" m={1}>
                    {`$ ${
                      invoice.balance
                        ? invoice.balance.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"
                    }`}
                    {/* $ {calculateTotalDue().toFixed(2)} */}
                  </Box>
                </TableCell>
              </TableRow>
            </Table>
          </Card>
        </CardContent>

        {!financingInterest && invoice?.show_disclaimer ? (
          <Box className={classes.invoiceDisclaimer} m={10}>
            *** Amount to pay if payment type is different to cash or debit: $
            {(calculateTotal() * 1.15).toFixed(2)} ***
          </Box>
        ) : (
          ""
        )}

        {invoice.invoice_disclaimer && invoice.invoice_disclaimer != "" && (
          <Typography
            variant="subtitle1"
            component="div"
            style={{ textAlign: "right", marginRight: 10, marginTop: 40 }}
          >
            X________________________________________________________________
          </Typography>
        )}

        {invoice.payments.data && (
          <Box m={4}>
            <Typography variant="body2" component="div">
              Payments history
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Last 4</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderPayments()}</TableBody>
            </Table>
          </Box>
        )}

        <InvoiceFooter>
          <Box m={10}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" component="div">
                  DIRECT ALL INQUIRES TO:
                </Typography>
                <Typography variant="caption" component="div">
                  {!isEmpty(invoice.building_name)
                    ? invoice.building_name
                    : invoice.organization_name}
                </Typography>
                <Typography variant="caption" component="div">
                  {!isEmpty(invoice.building_phone)
                    ? invoice.building_phone
                    : invoice.organization_phone}
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography variant="caption" component="div">
                  MAKE ALL CHECKS PAYABLE TO:
                </Typography>
                <Typography variant="caption" component="div">
                  {!isEmpty(invoice.building_name)
                    ? invoice.building_name
                    : invoice.organization_name}
                </Typography>
                <Typography variant="caption" component="div">
                  Attn: Accounts Receivable
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </InvoiceFooter>
      </Card>
      {invoice.invoice_disclaimer && invoice.invoice_disclaimer != "" && (
        <Typography
          variant="body1"
          component="div"
          style={{ margin: 10, fontStyle: "italic", textAlign: "justify" }}
        >
          {invoice.invoice_disclaimer}
        </Typography>
      )}
    </Wrapper>
  );
}

export default PrintInvoiceDetails;
