import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../lib/api";

export const initialState = {
  loading: false,
  errors: [],
  quotesReports: [],
};

const quotesReportsSlice = createSlice({
  name: "quotesReports",
  initialState,
  reducers: {
    getQuotesReports: (state) => {
      state.loading = true;
    },
    getQuotesReportsSuccess: (state, { payload }) => {
      state.quotesReports = payload.data;
      state.loading = false;
      state.errors = [];
    },
    getQuotesReportsFailure: (state, { payload }) => {
      state.loading = false;
      state.errors = [...state.errors, payload.message];
    },
    
  },
});

export const {
  getQuotesReports,
  getQuotesReportsSuccess,
  getQuotesReportsFailure,
 
} = quotesReportsSlice.actions;

export const quotesReportsSelector = (state) => state.reports;
export default quotesReportsSlice.reducer;

export function fetchQuotesReportsService(orgId) {
  return async (dispatch) => {
    dispatch(getQuotesReports());

    try {
      const response = await Api.getQuotesReport(orgId);
      dispatch(getQuotesReportsSuccess(response.data));
    } catch (error) {
      dispatch(getQuotesReportsFailure(error));
    }
  };
}


