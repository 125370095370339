import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { blue, green, grey, red, yellow } from "@material-ui/core/colors";
import {
  AlternateEmail,
  Check as CheckIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons";
import { isInteger } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import Iconify from "../../../components/Iconify";
import Api from "../../../lib/api";
import Popup from "../../../components/Popup";
import InvoiceDetails from "../../../pages/invoices/details";
import { useState } from "react";

export const formatPrice = (price) => {
  return price && price.toFixed ? `$ ${price?.toFixed(2)}` : 0;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    // transform: "scale(0.5) translate(-50%, -50%);",
  },
  page: {
    // width: "210mm",
    // height: "297mm",
    margin: "auto",
    overflow: "hidden",
    padding: 50,
  },
  header: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: "center",
  },
  textHeader: {
    fontSize: "1.2em",
  },
  textTitles: {
    fontSize: "1em",
  },
  textContent: {
    fontSize: theme.typography.caption.fontSize,
  },
  content: {
    flexGrow: 1,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
  },
  invoiceData: {
    textAlign: "right",
    "& .header": {
      borderBottom: "1px solid black",
    },
  },
  userData: {
    textAlign: "left",
    "& .header": {
      borderBottom: "1px solid black",
    },
    "& .icon": {
      fontSize: theme.typography.caption.fontSize,
      marginRight: "0.4em",
    },
    "& .text": {
      display: "flex",
      alignItems: "center",
    },
  },
  invoiceItems: {
    borderTop: "1px solid black",
    textAlign: "right",
    "& .icon": {
      fontSize: theme.typography.caption.fontSize,
      marginLeft: "0.5em",
    },
  },
}));
const PageHeader = ({ text, ticket = null }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      {ticket && ticket.created_at && (
        <Typography variant="caption">
          Ticket creation date:{moment(ticket.created_at).format("MM/DD/YYYY")}
        </Typography>
      )}
      <Typography variant="h6" className={classes.textHeader}>
        {text}
      </Typography>
    </div>
  );
};

const InvoiceData = ({ invoice }) => {
  const classes = useStyles();
  const statusArray = useMemo(
    () => ({
      unsend: {
        label: "Unsend",
        color: grey[700],
      },
      sent: {
        label: "Sent",
        color: blue[700],
      },
      unpaid: {
        label: "Unpaid",
        color: red[700],
      },
      partial_paid: {
        label: "Partial paid",
        color: yellow[500],
      },
      paid: {
        label: "Paid in full",
        color: green[700],
      },
      canceled: {
        label: "Canceled",
        color: grey[900],
      },
    }),
    []
  );
  return (
    <Box display="flex" flexDirection="column" className={classes.invoiceData}>
      <Typography variant="h6" className={`${classes.textTitles} header`}>
        Invoice
      </Typography>
      <Typography variant="caption">
        Invoice number: {invoice?.name ?? "Unknown"}
      </Typography>
      <Typography variant="caption">
        Date created:
        {invoice?.created_at
          ? moment(invoice.created_at).format("D/MM/yyyy")
          : "Unknown"}
      </Typography>
      <Typography variant="caption">
        Date send:
        {invoice?.sent_date
          ? moment(invoice.sent_date).format("D/MM/yyyy")
          : "Unknown"}
      </Typography>
      <Typography variant="caption">
        Due Date:
        {invoice?.pay_before
          ? moment(invoice.pay_before).format("D/MM/yyyy")
          : "Unknown"}
      </Typography>
      <Typography variant="caption">
        Status:
        {invoice?.status ? statusArray[invoice.status]?.label : "Unknown"}
      </Typography>
    </Box>
  );
};

const ContactData = ({ contact }) => {
  const classes = useStyles();
  return (
    <div className={classes.userData}>
      <Typography variant="h6" className={`${classes.textTitles} header`}>
        Contact
      </Typography>
      <Typography variant="caption">
        Name: {contact?.full_name ?? "Unknown"}
      </Typography>
      <Typography variant="caption" className="text">
        <PhoneIcon className="icon" /> {contact?.phone ?? "Unknown"}{" "}
      </Typography>
      <Typography variant="caption" className="text">
        <AlternateEmail className="icon" /> {contact?.email ?? "Unknown"}{" "}
      </Typography>
      <Typography variant="caption">
        Created:{" "}
        {contact?.created_at
          ? moment(contact.created_at).format("D/MM/yyyy")
          : "Unknown"}
      </Typography>
    </div>
  );
};

const InvoiceItems = ({ invoice, selected = [], amount = 0 }) => {
  const classes = useStyles();

  const items = useMemo(() => {
    return invoice?.items ?? [];
  }, [invoice]);
  const selectedIds = useMemo(() => {
    return selected?.map((s) => (isInteger(s) ? s : s.id));
  }, [selected]);

  const totalRefund = useMemo(() => {
    return (
      items
        ?.filter((i) => selectedIds.includes(i.id))
        .reduce((p, c) => p + c.price, 0) ?? 0
    );
  }, [items, selectedIds]);

  return (
    <div className={classes.invoiceItems}>
      <Typography variant="h6" className={classes.textTitles}>
        Items
      </Typography>
      <TableContainer
        style={{
          fontSize: "12px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.textContent}>Name</TableCell>
              <TableCell align="right" className={classes.textContent}>
                Price
              </TableCell>
              <TableCell align="right" className={classes.textContent}>
                Want refund
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((i) => (
              <TableRow key={i.id}>
                <TableCell className={classes.textContent}>
                  {i.item_name}
                </TableCell>
                <TableCell align="right" className={classes.textContent}>
                  {i?.price ? `$ ${i.price.toFixed(2)}` : ""}
                </TableCell>
                <TableCell align="right" className={classes.textContent}>
                  {selectedIds?.includes(i.id) ? (
                    <CheckIcon className="icon" />
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell rowSpan={4} />
              <TableCell className={classes.textContent}>
                Invoice Total
              </TableCell>
              <TableCell align="right" className={classes.textContent}>
                {formatPrice(invoice?.totalPrice)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.textContent}>
                Invoice Balance{" "}
              </TableCell>
              <TableCell align="right" className={classes.textContent}>
                {formatPrice(invoice?.balance)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.textContent}>
                Requested Refund
              </TableCell>
              <TableCell align="right" className={classes.textContent}>
                {formatPrice(amount)}
              </TableCell>
            </TableRow>
            {/* <TableRow>
                            <TableCell className={classes.textContent}>
                                Amount left
                            </TableCell>
                            <TableCell
                                align="right"
                                className={classes.textContent}
                            >
                                {formatPrice((invoice?.balance ?? 0) - amount)}
                            </TableCell>
                        </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
const RefundDoc = ({ ticket = null, invoice, selectedItems, amount = 0 }) => {
  const classes = useStyles();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isOpenInvoice, setIsOpenInvoice] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const openInvoice = async () => {
    if (invoice) {
      setLoadingInvoice(true);
      const { data } = await Api.getInvoice(invoice.id);

      setSelectedInvoice(data.data);
      setIsOpenInvoice(true);
      setLoadingInvoice(false);
    }
  };

  return (
    <Paper className={classes.root}>
      <Paper className={classes.page}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ minWidth: "fit-content" }}>
            <PageHeader text={"Invoice Refund"} ticket={ticket} />
          </div>

          <div style={{ width: "100%", textAlign: "end" }}>
            {invoice && invoice.id ? (
              <Button
                disabled={loadingInvoice}
                onClick={openInvoice}
                color="primary"
                startIcon={<Iconify icon="pepicons-pop:open-circle-filled" />}
              >
              {loadingInvoice? "Opening..." : "Open Invoice"}  
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={classes.content}>
          <Grid container direction="column" spacing={4}>
            <Grid container item>
              <Grid item xs={5}>
                <ContactData contact={invoice?.contact} />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <InvoiceData invoice={invoice} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InvoiceItems
                invoice={invoice}
                selected={selectedItems}
                amount={amount}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.footer}></div>
        <Popup
          fullWidth={true}
          title={"Invoice"}
          fullScreen
          openPopup={isOpenInvoice}
          onClose={() => {
            setIsOpenInvoice(false);
            setSelectedInvoice(null);
          }}
        >
          <InvoiceDetails invoice={selectedInvoice} />
        </Popup>
      </Paper>
    </Paper>
  );
};

export default RefundDoc;
