import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, findIndex } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import moment from "moment";
import { fetchEntitiesService as fetchBuildingsDropdownService } from "../../redux/slices/buildingsDropdownSlice.js";
import { fetchEntitiesService as fetchSurgeonsService } from "../../redux/slices/surgeonDropdownSlice.js";

import CRMUtils from "../../utils";

import Api from "../../lib/api";
import Iconify from "../Iconify";
import ConfirmDialog from "../ConfirmDialog";

import { getMessaging } from "firebase/messaging";

var dataSliceBuildingsDropdown = {
  stateListName: "buildingsDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceSurgeonDropdown = {
  stateListName: "surgeonDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  largeAvatar: {
    width: "100px",
    height: "100px",
  },
  inputItemAmount: {
    width: "60% !important",
  },
  errors: {
    color: "#f44336",
    fontWeight: 500,
  },
}));

export default function PaymentForm(props) {
  const {
    tab = 0,
    user,
    invoice,
    savePayment,
    maxAmount,
    firstPayment,
    itemName,
    itemAmount,
    refundComment,
    refundFull,
    refundFullItem,
    paymentForEdit,
    reload,
    onClose = null,
    allowFix = false,
    isRefund = false,
    refundReasons = [],
    refundRequestsForEdit = null,
    setNotify = null,
    pendingPayment = null,
    selectedPaymentTypeTab = 0,
    setSelectedPaymentTypeTab = null,
  } = props;

  const { handleSubmit, setValue, reset, control, errors, watch, getValues } =
    useForm();
  const messaging = getMessaging();
  const [gateways, setGateways] = useState([]);
  // const fields = getValues();
  const watchAllFields = watch();
  const [file, setFile] = useState(null);
  const [minValidations, setMinValidations] = useState({});
  const { authUser } = useSelector((state) => state.auth);
  dataSliceBuildingsDropdown.orgId = authUser.organization_id;
  dataSliceSurgeonDropdown.orgId = authUser.organization_id;

  const { buildingsDropdown, buildingsDropdownLoading } = useSelector(
    (state) => state.buildingsDropdown
  );

  const { surgeonDropdown, surgeonDropdownLoading } = useSelector(
    (state) => state.surgeonDropdown
  );

  const [lockDate, setLockDate] = useState(false);
  const { users } = useSelector((state) => state.users);

  const [devicesDropDown, setDevicesDropDown] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [pendingPaymentDetails, setPendingPaymentDetails] = useState(
    pendingPayment?.paymentdetail?.data
  );

  // const [anchorElDevices, setAnchorElDevices] = useState(null);
  const [isDeviceListOpen, setIsDeviceListOpen] = useState(false);
  const [bottonError, setBottonError] = useState("");

  const handleChangeTab = (event, newValue) => {
    if (!isSubmitting) {
      setSelectedPaymentTypeTab ? setSelectedPaymentTypeTab(newValue) : "";
    }
  };
  const handleOpenDevicesList = () => {
    setIsDeviceListOpen(true);
    getDevicesList();
  };
  const handleCloseDevicesList = () => {
    setIsDeviceListOpen(false);
  };

  const handleSelectInDevicesList = async (event) => {
    console.log(event.target.value);
    setSelectedDevice(event.target.value);
    //setSelectedDeviceTitle(event.target.value);
    // let payload = {
    //   invoice_id: invoice.id,
    //   device_id: device.id,
    //   amount: 0.01,
    //   details: ["tst", "test"],
    // };
    // sendPaymentToDevice(payload);
  };
  const sendPaymentToDevice = async (payload) => {
    let response = await Api.sendPaymentRequest(payload);
    setIsSubmitting(false);
    // The way this response is obtained should change when other POS Tecnologies are created in order to have a general way to controll it
    if (response?.data.status == "approved") {
      // Close popup and reload the invoice
      onClose &&
        (() => {
          onClose({
            isOpen: true,
            message: "The POS payment was completed successfully",
            type: "success",
          });
        })();

      reload && reload();
    } else if (response?.data.status == "declined") {
      setConfirmDialog({
        isOpen: true,
        title: "The POS payment was declined",
        showAsNotification: true,
      });
      setIsSubmitting(false);
    } else {
      if (response?.data?.show_message && response?.data?.message) {
        setConfirmDialog({
          isOpen: true,
          title: response?.data?.message || "The POS payment was not completed",
          showAsNotification: true,
        });
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "The POS payment was not completed",
          showAsNotification: true,
        });
      }

      setIsSubmitting(false);
    }
  };

  const requestDevicePaymentCancellation = async (selectedDevice) => {
    const { data } = await Api.requestDevicePaymentCancellation(invoice?.id, {
      poi_device_id: selectedDevice.id,
    });
    setIsCanceling(false);
    setConfirmDialog({
      isOpen: true,
      title: "Cancellation Request Sent",
      subTitle:
        "The cancellation request was sent to the device, make sure the transaction cancellation request was made before it was approved, otherwise the transaction was not canceled and will be reflected in the system",
      showAsNotification: true,
      onConfirm: () => {
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const handleRequestDevicePaymentCancellation = (selectedDevice) => {
    setConfirmDialog({
      isOpen: true,
      title: "Request to Cancel POS Payment",
      subTitle: (
        <Typography component="span">
          Are you sure you want to try canceling the transaction of the selected
          POS device <strong>{selectedDevice.title}</strong>? Make sure that
          there are not cards in the "authorizing" process on the POS device,
          note that if the transaction was already approved it will not be
          canceled
        </Typography>
      ),
      onConfirm: () => {
        setConfirmDialog({ isOpen: false });
        setIsCanceling(true);
        requestDevicePaymentCancellation(selectedDevice);
      },
      confirmLoading: false,
    });
  };

  const getDevicesList = async () => {
    if (invoice.enable_pos_payments) {
      const { data } = await Api.getInvoiceAvailableDevicesDropDown(invoice.id);

      setDevicesDropDown(data.data);
    }
  };
  const getDetailsForDevicePayment = () => {
    var tempitems = invoice.items.data
      .filter((a) =>
        (paymentForEdit &&
          paymentForEdit.paymentdetail &&
          paymentForEdit.paymentdetail.data) ||
        tab == 1
          ? a.type != "package"
          : a.parent_id == null
      )
      .map((item, index) => {
        return {
          item_id: item.id,
          current_amount: parseFloat(watchAllFields.itemsAmount[index]),
        };
      });
    var items = null;
    items = tempitems.filter((item) => {
      return item.current_amount > 0;
    });
    //return JSON.stringify(items);
    return items;
  };
  // const handleClickDevicesListItem = async (device) => {
  //   console.log("clicking id ", device.id);
  //   let payload = {
  //     invoice_id: invoice.id,
  //     device_id: device.id,
  //     amount: 0.01,
  //     details: ["tst", "test"],
  //   };
  //   sendPaymentToDevice(payload);
  //   // set is loading
  // };

  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const classes = useStyles();
  const [message, setMessage] = useState("");
  const userCanEditPayment = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_edit",
  ]);

  const userCanEditPaymentDateLock = CRMUtils.userHasPermission(authUser, [
    "user_EditPaymentDateLock",
  ]);
  const userCanEditNegativePayments = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_negativePayments",
  ]);
  const userCanChangeBuilding = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_changeBuilding",
  ]);

  const userCanChangeSalesCreatedAt = CRMUtils.userHasPermission(authUser, [
    "invoicepaymenthistory_salesCreatedAt",
  ]);

  const userCanSendPosCancellationRequest = CRMUtils.userHasPermission(
    authUser,
    ["invoice_sendPosCancellationRequest"]
  );

  const [overPaymentControl, setOverPaymentControl] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const paymentOptions = () => {
    // Get the selected payment type from paymentForEdit
    const selectedPaymentType = paymentForEdit?.payment_type?.toUpperCase();

    // Filter gateways where is_system is equal to 0
    const filteredOptions = gateways.filter(
      (gateway) => gateway.is_system === 0
    );

    // Check if the selected payment type is not in the filtered options
    const isNotInFilteredOptions = !filteredOptions.some(
      (option) => option.name.toUpperCase() === selectedPaymentType
    );

    // Map filtered options to options
    const options = filteredOptions.map((option) => ({
      id: option.name,
      title: option.name,
      selected: option.name.toUpperCase() === selectedPaymentType,
    }));

    // If the selected payment type is not in the filtered options, add it to options
    if (isNotInFilteredOptions && selectedPaymentType) {
      options.push({
        id: selectedPaymentType,
        title: selectedPaymentType,
        selected: true,
      });
    }

    return options;
  };

  const buildingOptions = () => {
    return buildingsDropdown.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    return item.type == "package"
      ? (item.package_price ? item.package_price : 0) *
          (item.price_includes_qty ? 1 : parseInt(quantity)) -
          discount * (item.price_includes_qty ? 1 : parseInt(quantity))
      : item.price * (item.price_includes_qty ? 1 : parseInt(quantity)) -
          discount * (item.price_includes_qty ? 1 : parseInt(quantity));
  };

  const calculeTotal2Pay = () => {
    if (!watchAllFields.itemsAmount) return 0;

    return watchAllFields.itemsAmount.reduce(
      (total, item) => total + parseFloat(item),
      0
    );
  };

  const getGateways = async () => {
    const params = new URLSearchParams([["contact_id", invoice.contact_id]]);

    const { data } = await Api.getPaymentGatewaysAll(
      authUser.organization_id,
      `?1=1&${params.toString()}`
    );

    setGateways([...data.data]);
  };

  const calculateItemBalance = (item) => {
    const itemPrice = item.price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;

    let itemRefunds = 0;
    let refunddetails;
    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      itemPayments = paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds = refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    if (item.creditmemodetail) {
      itemCredits = item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }

    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;
    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const calculatePackageBalance = (item) => {
    const itemPrice = item.package_price;
    const quantity = item.quantity || 1;
    let itemPayments = 0;
    let itemCredits = 0;

    let itemRefunds = 0;
    let refunddetails;

    item.childs.forEach((child) => {
      if (child.paymentwith_payments_detail) {
        const paymentdetails = child.paymentwith_payments_detail.filter(
          (paymentdetail) => {
            return !paymentdetail.isRefund;
          }
        );

        itemPayments += paymentdetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);

        refunddetails = child.payment_with_refunds_detail.filter(
          (paymentdetail) => {
            return paymentdetail.isRefund;
          }
        );

        if (refunddetails.length > 0) {
          itemRefunds += refunddetails.reduce((total, payment) => {
            return total + parseFloat(payment.amount);
          }, 0);
        }
      }

      if (child.credit_memo_detail) {
        itemCredits += child.credit_memo_detail.reduce((total, creditmemo) => {
          return total + parseFloat(creditmemo.amount);
        }, 0);
      }
    });

    if (item.paymentdetail) {
      const paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      itemPayments += paymentdetails.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds += refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    if (item.creditmemodetail) {
      itemCredits += item.creditmemodetail.data.reduce((total, creditmemo) => {
        return total + parseFloat(creditmemo.amount);
      }, 0);
    }

    const balance =
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      itemPayments -
      itemCredits +
      itemRefunds;

    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  };

  const calculateItemRefund = (item) => {
    let itemRefunds = 0;
    let refunddetails;
    let itemPayments = 0;
    let paymentdetails;

    if (item.paymentdetail) {
      paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      if (paymentdetails.length > 0) {
        itemPayments = paymentdetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds = refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    const itemRefundtoReturn = itemPayments - itemRefunds;

    if (itemRefundtoReturn > 0) {
      return itemRefundtoReturn;
    } else {
      return 0;
    }
  };

  const calculatePackageRefund = (item) => {
    let itemRefunds = 0;
    let refunddetails;
    let itemPayments = 0;
    let paymentdetails;

    item.childs.forEach((child) => {
      if (child.paymentdetail) {
        paymentdetails = child.paymentdetail.data.filter((paymentdetail) => {
          return !paymentdetail.isRefund;
        });

        if (paymentdetails.length > 0) {
          itemPayments += paymentdetails.reduce((total, payment) => {
            return total + parseFloat(payment.amount);
          }, 0);
        }

        refunddetails = child.paymentdetail.data.filter((paymentdetail) => {
          return paymentdetail.isRefund;
        });

        if (refunddetails.length > 0) {
          itemRefunds += refunddetails.reduce((total, payment) => {
            return total + parseFloat(payment.amount);
          }, 0);
        }
      }
    });

    if (item.paymentdetail) {
      paymentdetails = item.paymentdetail.data.filter((paymentdetail) => {
        return !paymentdetail.isRefund;
      });

      if (paymentdetails.length > 0) {
        itemPayments += paymentdetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }

      refunddetails = item.paymentdetail.data.filter((paymentdetail) => {
        return paymentdetail.isRefund;
      });

      if (refunddetails.length > 0) {
        itemRefunds += refunddetails.reduce((total, payment) => {
          return total + parseFloat(payment.amount);
        }, 0);
      }
    }

    const itemRefundtoReturn = itemPayments - itemRefunds;

    if (itemRefundtoReturn > 0) {
      return itemRefundtoReturn;
    } else {
      return 0;
    }
  };

  const getDetail = (item_id) => {
    let paymentdetail = paymentForEdit.paymentdetail.data.find(
      (paymentdetail1) => {
        return paymentdetail1.item_id === item_id;
      }
    );

    return paymentdetail ? paymentdetail.amount : 0;
  };

  const getDetailMin = (item) => {
    var item_id = item.id;
    let paymentdetail =
      paymentForEdit &&
      paymentForEdit.paymentdetail &&
      paymentForEdit.paymentdetail.data &&
      !isEmpty(paymentForEdit.paymentdetail.data)
        ? paymentForEdit.paymentdetail.data.find((paymentdetail1) => {
            return paymentdetail1.item_id === item_id;
          })
        : null;

    var minDetailValue =
      paymentdetail && paymentdetail.min_detail_amount != 0
        ? parseFloat(paymentdetail.min_detail_amount)
        : null;

    var minBasedOnDoneQty = paymentdetail
      ? parseFloat(item.itemPaid) -
          parseFloat(item.itemRefund) -
          parseFloat(paymentdetail.amount) >=
        parseFloat(item.done_quantity_price)
        ? null
        : parseFloat(paymentdetail.amount) >=
          parseFloat(item.done_quantity_price)
        ? parseFloat(item.done_quantity_price) -
          (parseFloat(item.itemPaid) -
            parseFloat(item.itemRefund) -
            parseFloat(paymentdetail.amount) >
          0
            ? parseFloat(item.itemPaid) -
              parseFloat(item.itemRefund) -
              parseFloat(paymentdetail.amount)
            : 0)
        : parseFloat(paymentdetail.amount) -
          (parseFloat(item.itemPaid) -
            parseFloat(item.itemRefund) -
            parseFloat(paymentdetail.amount) >
          0
            ? parseFloat(item.itemPaid) -
              parseFloat(item.itemRefund) -
              parseFloat(paymentdetail.amount)
            : 0)
      : null;

    if (
      minBasedOnDoneQty !== null &&
      minDetailValue !== null &&
      minBasedOnDoneQty < minDetailValue
    ) {
      return minDetailValue;
    } else {
      return minBasedOnDoneQty;
    }
  };

  const getPackageDetail = (item) => {
    var childIds = item.childs.map((item) => {
      return item.id;
    });

    let paymentdetail = paymentForEdit.paymentdetail.data.filter(
      (paymentdetail1) => {
        return childIds.includes(paymentdetail1.item_id);
      }
    );

    var total = paymentdetail.reduce((total, item) => total + item.amount, 0);

    //  console.log(item_id, paymentdetail);
    return total ? total : 0;
  };

  const getDetailId = (item_id) => {
    let paymentdetail = paymentForEdit.paymentdetail.data.find(
      (paymentdetail1) => {
        return paymentdetail1.item_id === item_id;
      }
    );

    //console.log(item_id, paymentdetail);
    return paymentdetail ? paymentdetail.id : "null";
  };

  const renderDevicesList = () => {
    return devicesDropDown?.map((device) => {
      return (
        <MenuItem
          value={device?.title}
          onClick={() => setSelectedDevice(device)}
        >
          <div style={{ display: "flex" }}>
            <Iconify
              style={{ width: 35, height: 20 }}
              icon={"fontisto:shopping-pos-machine"}
            />
            {/* <ListItemIcon>
            <Iconify
              style={{ width: 30, height: 30 }}
              icon={"fontisto:shopping-pos-machine"}
            />
          </ListItemIcon>
          <ListItemText style={{ marginLeft: -20 }}>
            {device.title}
          </ListItemText>
          <Divider component="li" variant="inset" /> */}
            <Typography>{device.title}</Typography>
          </div>
        </MenuItem>
      );
    });
  };

  const renderInvoiceItems = () => {
    var mins = [];
    let overPaidItem = invoice.items.data.filter((item) => {
      return item.type === "system";
    });
    //setOverPaymentControl(0);
    let overPaidPriority = overPaidItem[0]
      ? overPaidItem[0].itemPaid - overPaidItem[0].itemRefund > 0
        ? true
        : false
      : false;
    //console.log("overPaidItem",overPaidItem[0]?overPaidItem[0]:"");

    return invoice.items.data
      .filter((a) =>
        paymentForEdit || tab == 1 ? a.type != "package" : a.parent_id == null
      ) /*.filter((item)=>{return !isRefund?item.type !== 'system':item.id > 0})*/
      .map((item, index) => {
        mins[index] = getDetailMin(item)
          ? getDetailMin(item)
          : userCanEditNegativePayments
          ? "-999999"
          : // : item.paymentdetail &&
            //   (item.type == "package"
            //     ? item.package_balance < 0
            //     : item.itemBalance < 0) &&
            //   item.paymentdetail.data &&
            //   item.paymentdetail.data.findIndex(
            //     (a) => a.is_recordProduct_finalized == true
            //   ) != -1
            // ? (paymentForEdit
            //     ? item.type == "package"
            //       ? getPackageDetail(item)
            //       : getDetail(item.id)
            //     : refundFull
            //     ? refund
            //     : refundFullItem && itemName === item.name
            //     ? refund
            //     : itemName && itemName === item.name && refund >= itemAmount
            //     ? itemAmount
            //     : itemName && itemName === item.name && refund < itemAmount
            //     ? refund
            //     : 0) >
            //   (item.type == "package"
            //     ? item.package_balance * -1
            //     : item.itemBalance * -1)
            //   ? (paymentForEdit
            //       ? item.type == "package"
            //         ? getPackageDetail(item)
            //         : getDetail(item.id)
            //       : refundFull
            //       ? refund
            //       : refundFullItem && itemName === item.name
            //       ? refund
            //       : itemName && itemName === item.name && refund >= itemAmount
            //       ? itemAmount
            //       : itemName && itemName === item.name && refund < itemAmount
            //       ? refund
            //       : 0) -
            //     (item.type == "package"
            //       ? item.package_balance * -1
            //       : item.itemBalance * -1)
            //   : 0
            "0";
        const balance =
          item.type == "package"
            ? calculatePackageBalance(item)
            : calculateItemBalance(item);
        const refund =
          item.type == "package"
            ? calculatePackageRefund(item)
            : calculateItemRefund(item);

        return (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row" style={{ maxWidth: "385px" }}>
              <Typography>
                {item.name}
                {item.is_revision ? (
                  <strong
                    style={{
                      color: "#326a9b",
                      marginLeft: 5,
                      fontWeight: 400,
                    }}
                  >
                    {"(Revision)"}
                  </strong>
                ) : (
                  ""
                )}
              </Typography>
            </TableCell>
            <TableCell align="right">
              {" "}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>{item.quantity || 1}</Typography>
                {item.void_quantity ? (
                  <Typography
                    style={{
                      fontWeight: 500,
                      marginLeft: 5,
                      color: "#a76c65",
                      fontSize: 12,
                    }}
                  >{`(${item.void_quantity} VOID)`}</Typography>
                ) : (
                  ""
                )}
              </div>
            </TableCell>
            <TableCell align="right">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ marginRight: 4 }}>
                  $ {item.type == "package" ? item.package_price : item.price}
                </Typography>
                {item.price_includes_qty ? (
                  <Tooltip title="The price of this item is total, not unitary per item.">
                    <span>
                      <Iconify
                        style={{
                          color: "#6579e3",
                          width: 18,
                          height: 18,
                          marginTop: "6px",
                        }}
                        icon={"fluent:info-24-filled"}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </TableCell>
            <TableCell align="right">
              $ {calculateItemTotal(item).toFixed(2)}
            </TableCell>
            <TableCell align="right">
              $ {maxAmount ? refund : balance.toFixed(2)}
            </TableCell>
            <TableCell align="right">
              <Controller
                name={`itemsAmount[${index}]`}
                id={`${item.id}`}
                className={classes.inputItemAmount}
                control={control}
                defaultValue={
                  paymentForEdit
                    ? item.type == "package"
                      ? getPackageDetail(item)
                      : getDetail(item.id)
                    : refundFull
                    ? refund
                    : refundFullItem && itemName === item.name
                    ? refund
                    : itemName && itemName === item.name && refund >= itemAmount
                    ? itemAmount
                    : itemName && itemName === item.name && refund < itemAmount
                    ? refund
                    : 0
                }
                render={({ onChange, value }) => (
                  <Controls.InputMoneyToPaymentForm
                    itemToPrint={item}
                    mins={mins}
                    value={value}
                    onChange={onChange}
                    indexValue={index}
                    minValidations2={minValidations}
                    setMinValidations2={setMinValidations}
                    maxValue={
                      999999
                      // userCanEditNegativePayments
                      //   ? "999999"
                      //   : paymentForEdit && !isRefund
                      //   ? parseFloat(paymentForEdit.amount) +
                      //     parseFloat(balance)
                      //   : paymentForEdit && isRefund
                      //   ? parseFloat(paymentForEdit.amount) + parseFloat(refund)
                      //   : refundFull
                      //   ? refund
                      //   : refundFullItem && itemName === item.name
                      //   ? refund
                      //   : maxAmount
                      //   ? refund
                      //   : balance
                    }
                    disabled={
                      item.done_product_control &&
                      parseFloat(item.done_quantity_price) ==
                        parseFloat(item.itemPaid) - parseFloat(item.itemRefund)
                        ? true
                        : !isRefund &&
                          item.paymentdetail &&
                          // item.itemBalance == 0 &&
                          item.paymentdetail.data &&
                          item.paymentdetail.data.findIndex(
                            (a) => a.is_recordProduct_finalized == true
                          ) != -1
                        ? true
                        : pendingPayment ||
                          (paymentForEdit &&
                            paymentForEdit.possible_refund_from_id)
                        ? true
                        : paymentForEdit && item.type !== "system"
                        ? false
                        : (maxAmount === 1 && refund === 0) ||
                          // (!maxAmount && balance <= 0) ||
                          (refundFullItem &&
                            itemName === item.name &&
                            item.void === 0) ||
                          item.void === 1
                        ? true
                        : isRefund &&
                          overPaidPriority &&
                          item.type !== "system" &&
                          overPaymentControl <
                            overPaidItem[0].itemPaid -
                              overPaidItem[0].itemRefund
                        ? true
                        : !isRefund &&
                          overPaidPriority &&
                          item.type === "system"
                        ? true
                        : false
                    }
                    setOverPaymentControl={
                      isRefund && item.type === "system"
                        ? setOverPaymentControl
                        : null
                    }
                    //setCustomValue={isRefund && overPaidPriority && item.type !== 'system' && overPaymentControl < (overPaidItem[0].itemPaid - overPaidItem[0].itemRefund)?0:null}
                    //overPaymentControl={overPaymentControl}
                    //overPaidItem={overPaidItem[0]}
                    //itemType={item.type}
                  />
                )}
              />
              {minValidations && minValidations[`itemsAmount[${index}]`] ? (
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#b92e2e",
                  }}
                >
                  *Invalid Amount
                </p>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell align="right">
              {refundFullItem && itemName === item.name && item.void === 0
                ? "VOID"
                : item.void === 1
                ? "VOID"
                : ""}
            </TableCell>
          </TableRow>
        );
      });
  };

  const onSubmit = async (data) => {
    try {
      setIsCanceling(false);

      // Calculate the total amount to pay based on invoice items
      const totalToPay = calculeTotal2Pay();

      // Check if there is an existing payment being edited, it's an online payment,
      // and the total amount to pay is different from the payment amount
      if (
        paymentForEdit &&
        paymentForEdit.isOnline &&
        totalToPay !== parseInt(paymentForEdit.amount)
      ) {
        // Calculate the maximum available amount for the online payment
        const maxAvailableAmount =
          parseInt(paymentForEdit.payments_in_header_original_amount) -
          parseInt(paymentForEdit.payments_in_header_amount) +
          parseInt(paymentForEdit.amount);

        // Check if the total amount to pay exceeds the payment amount and the maximum available amount
        if (
          totalToPay > parseInt(paymentForEdit.amount) &&
          maxAvailableAmount > 0 &&
          totalToPay > maxAvailableAmount
        ) {
          // Display an error message indicating the maximum available amount for the online payment
          showBottomError(
            `The max amount available for this Online Payment is $${maxAvailableAmount}`,
            null
          );
          return true;
        } else if (
          // Check if the total amount to pay is not within the valid range
          !(
            totalToPay > parseInt(paymentForEdit.amount) &&
            totalToPay <= maxAvailableAmount
          )
        ) {
          // Display an error message indicating the required amount distribution for the online payment
          showBottomError(
            `For This Online Payment, the amount distributed among the items must be ${
              maxAvailableAmount - parseInt(paymentForEdit.amount) > 0
                ? "at least"
                : "equal to"
            } the payment's current amount ($${paymentForEdit.amount})`,
            null
          );
          return true;
        }
      }

      if (
        paymentForEdit &&
        paymentForEdit.payments_in_header > 1 &&
        !data["confirm"]
      ) {
        setConfirmDialog({
          isOpen: true,
          title:
            "This payment belongs to a multiple payment that affects other invoices, so all the modifications made except the amount will also be modified in the rest of the payments belonging to the multiple payment.",
          subTitle: "Are you sure to continue?",
          showAsNotification: false,
          onConfirm: async () => {
            data["confirm"] = true;
            setConfirmDialog({ ...confirmDialog, ...{ isOpen: false } });
            onSubmit(data);
          },
        });
        return null;
      }
      if (selectedPaymentTypeTab == 0) {
        // Send Manual Payment
        if (!data.responsible_id) {
          delete data.responsible_id;
        }
        if (watchAllFields.itemsAmount) {
          var tempitems = invoice.items.data
            .filter((a) =>
              (paymentForEdit &&
                paymentForEdit.paymentdetail &&
                paymentForEdit.paymentdetail.data) ||
              tab == 1
                ? a.type != "package"
                : a.parent_id == null
            )
            .map((item, index) => {
              return {
                item: item.id,
                edit_id: paymentForEdit ? getDetailId(item.id) : null,
                item_name: item.name,
                amount:
                  paymentForEdit && isEmpty(paymentForEdit.paymentdetail.data)
                    ? parseFloat(watchAllFields.itemsAmount[0])
                    : parseFloat(watchAllFields.itemsAmount[index]),
              };
            });
          var items = null;
          if (!paymentForEdit) {
            items = tempitems.filter((item) => {
              return item.amount > 0;
            });
          } else {
            items = tempitems;
          }

          if (refundFullItem === 1) {
            var temp = [];
            items.forEach((element) => {
              if (element.item_name === itemName) element.void = 1;
              else element.void = 0;
              temp.push(element);
            });
            items = temp;
          }

          data.amount = calculeTotal2Pay();

          data.paymentdetail = JSON.stringify(items);

          if (parseFloat(data.amount) <= 0 || isNaN(data.amount)) {
            setIsSubmitting(false);
            showBottomError("Payment amount can't be less than 1");
            // setNotify({
            //   isOpen: true,
            //   message: "Payment amount can't be less than 1",
            //   type: "error",
            // });
          } else if (
            paymentForEdit &&
            paymentForEdit.refundedAmount > 0 &&
            calculeTotal2Pay() < paymentForEdit?.refundedAmount
          ) {
            // setNotify({
            //   isOpen: true,
            //   message: `Payment amount can't be less than the Refunded Amount: ${paymentForEdit?.refundedAmount}`,
            //   type: "error",
            // });
            showBottomError(
              `Payment amount can't be less than the Refunded Amount: ${paymentForEdit?.refundedAmount}`
            );
            setIsSubmitting(false);
          } else {
            setIsSubmitting(true);
            if (pendingPayment) {
              data.possible_refund_from_id = pendingPayment.id;

              await savePayment(data);
              setIsSubmitting(false);
            } else if (
              firstPayment ||
              (paymentForEdit && isEmpty(paymentForEdit?.paymentdetail.data))
            ) {
              await savePayment(data);
              setIsSubmitting(false);
            } else if (maxAmount) {
              await savePayment(data);
              setIsSubmitting(false);
            } else {
              if (
                invoice &&
                (parseFloat(invoice.balance) >= calculeTotal2Pay() ||
                  calculeTotal2Pay() == paymentForEdit?.amount)
              ) {
                await savePayment(data);
                setIsSubmitting(false);
              } else if (
                invoice &&
                parseFloat(invoice.balance) < calculeTotal2Pay()
              ) {
                setIsSubmitting(false);
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to overpaid this Invoice?",
                  showAsNotification: false,
                  onConfirm: async () => {
                    try {
                      setIsSubmitting(true);
                      await savePayment(data);
                      setIsSubmitting(false);
                    } catch (error) {
                      //probar pagar item borrado
                      if (
                        error.status == 409 &&
                        (error?.data?.error || false)
                      ) {
                        setNotify({
                          isOpen: true,
                          message: error.data.error,
                          type: "error",
                        });
                      } else {
                        setNotify({
                          isOpen: true,
                          message: "Something went wrong!",
                          type: "error",
                        });
                      }
                      setIsSubmitting(false);
                    }
                  },
                });
              } else if (paymentForEdit && invoice) {
                // if (
                //   parseFloat(invoice.balance) + parseFloat(paymentForEdit.amount) >=
                //   calculeTotal2Pay()
                // ) {
                await savePayment(data);
                setIsSubmitting(false);
                // }
              } else {
                setNotify({
                  isOpen: true,
                  message: `Payment amount can't be more than ${invoice.balance}`,
                  type: "error",
                });
                setIsSubmitting(false);
              }
            }
          }
        }
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Send Payment to POS Device",
          subTitle: (
            <Typography component="span">
              Are you sure you want to send this payment to the device{" "}
              <strong>{selectedDevice.title}</strong>?
            </Typography>
          ),
          onConfirm: () => {
            setConfirmDialog({ isOpen: false });
            const paymentDetails = getDetailsForDevicePayment();
            if (selectedDevice.id) {
              let payload = {
                invoiceId: invoice.id,
                deviceId: selectedDevice.id,
                patientName: invoice.contact.data.full_name,
                amount: calculeTotal2Pay(),
                details: paymentDetails,
                createdBy: authUser.id,
                isFromPos: true,
              };
              sendPaymentToDevice(payload);
              setIsSubmitting(true);
            } else {
              setIsSubmitting(false);
              setIsCanceling(false);
              setNotify({
                isOpen: true,
                message: "There was a problem trying to use the POS machine",
                type: "error",
              });
            }
            // Send POS Payment
          },
        });
      }
    } catch (error) {
      //probar pagar item borrado
      if (error.status == 409 && (error?.data?.error || false)) {
        setNotify({
          isOpen: true,
          message: error.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
      setIsSubmitting(false);
    }
  };

  const checkAndFix = async (id) => {
    const { data } = await Api.checkAndFixInvoice(id);
    //console.error(data);
    setMessage(data);
    reload ? reload() : "";
    //setInvoiceForRefund(data.data);
    //setOpenRefund(true);
  };

  const brokenPaymentsCount = () => {
    let temp = [];
    if (invoice && invoice.payments)
      temp = invoice.payments.data.filter((payment) => {
        return payment["paymentdetail"]["data"].length === 0;
      });
    return temp.length;
  };

  const showBottomError = (message, timeout = 3000) => {
    if (message && message != "") {
      setBottonError(message);
      if (timeout) {
        setTimeout(() => {
          setBottonError("");
        }, timeout);
      }
    }
  };

  useEffect(() => {
    console.log("the paymentOrEdit " + JSON.stringify(paymentForEdit));
  }, [paymentForEdit]);

  // useEffect(() => {
  //   if (bottonError != "")
  //     setTimeout(() => {
  //       setBottonError("");
  //     }, 3000);
  // }, [bottonError]);

  useEffect(() => {
    if (pendingPayment && !isEmpty(pendingPayment?.paymentdetail.data)) {
      pendingPayment?.paymentdetail.data
        .filter((a) =>
          paymentForEdit || tab == 1 ? a.type != "package" : a.parent_id == null
        )
        .forEach((element) => {
          const index = findIndex(
            invoice.items.data.filter((a) =>
              paymentForEdit || tab == 1
                ? a.type != "package"
                : a.parent_id == null
            ),
            ["id", element.item_id]
          );

          setValue(`itemsAmount[${index}]`, element.amount);
        });
    }
  }, [pendingPayment]);

  // useEffect(() => {
  //   // Handle POS payment status
  //   onMessage(messaging, (payload) => {
  //     console.log("receiving payload from paymentForm", payload);
  //     handleDevicePaymentResult(payload);
  //   });

  //   // console.log("paymentForEdit", paymentForEdit);
  //   if (isEmpty(buildings))
  //     dispatch(fetchBuildingsService(authUser.organization_id));
  //   if (isEmpty(users)) dispatch(fetchUsersService(authUser.organization_id));
  // }, []);

  useEffect(() => {
    if (isEmpty(buildingsDropdown) && !buildingsDropdownLoading) {
      dispatch(fetchBuildingsDropdownService(dataSliceBuildingsDropdown));
    }

    if (isEmpty(surgeonDropdown) && !surgeonDropdownLoading) {
      dispatch(fetchSurgeonsService(dataSliceSurgeonDropdown));
    }
    getGateways();
    getDevicesList();
  }, []);

  useEffect(() => {
    if (watchAllFields.building_id && !userCanEditPaymentDateLock) {
      const building = buildingsDropdown.find((building) => {
        return building.id === watchAllFields.building_id;
      });
      if (building && building.lock_payment_date) {
        setValue("payment_date", moment().format("YYYY-MM-DD HH:mm:ss"));

        setLockDate(true);
      } else {
        setLockDate(false);
      }
    }
  }, [watchAllFields.building_id]);

  let overPaidItem = invoice.items.data.filter((item) => {
    return item.type === "system";
  });
  let overPaidPriority = overPaidItem[0]
    ? overPaidItem[0].itemPaid - overPaidItem[0].itemRefund > 0
      ? true
      : false
    : false;

  useEffect(() => {
    //console.log("overPaymentControl",overPaymentControl);
    if (
      isRefund &&
      overPaidPriority &&
      overPaymentControl < overPaidItem[0].itemPaid - overPaidItem[0].itemRefund
    ) {
      // noOverPaidItems.forEach((item)=>{
      //   let tmp = document.getElementById(item.id);
      //   tmp.value = 0;

      //   //console.log("document.getElementById(item.id)",tmp);
      // })
      invoice.items.data.forEach((item, index) => {
        if (item.type !== "system") {
          setValue(`itemsAmount[${index}]`, 0);
        }
      });
    }
  }, [overPaymentControl]);

  // useEffect(() => {
  //   console.log("paymentForEditpaymentForEdit", paymentForEdit);
  // }, [paymentForEdit]);

  const defaultReason = () => {
    if (refundRequestsForEdit) {
      const dReason = refundReasons.find(
        (value) => value.id === refundRequestsForEdit.reason_id
      );

      return dReason.id;
    }

    return "";
  };

  const refundReasonsOptions = () => {
    return refundReasons.map((refundReason) => {
      return {
        id: refundReason.id,
        title: refundReason.name,
      };
    });
  };

  const getUserOptions = () => {
    return surgeonDropdown.map((user) => {
      return {
        id: user.id,
        title: `${user.first_name} ${user.last_name}`,
      };
    });
  };

  const isSubmitDisable = () => {
    // disabled={!watchAllFields.itemsAmount || Object.keys(minValidations).forEach((key) => formData.append(key, payload[key]))}

    const totalToPay = calculeTotal2Pay();
    if (
      (selectedPaymentTypeTab == 1 && !selectedDevice) ||
      totalToPay <= 0 ||
      isNaN(totalToPay) ||
      isSubmitting
    ) {
      return true;
    }
    if (isSubmitting) {
      return true;
    }
    if (!watchAllFields.itemsAmount) {
      return false;
    } else {
      var result = false;
      Object.keys(minValidations).forEach((key) => {
        if (minValidations[key] == true) {
          result = true;
        }
      });
      return result;
    }
  };

  return (
    <>
      {/* {brokenPaymentsCount() > 0 && invoice && allowFix && (
        <div>
          {"Payment Details not found!"}
          <Grid item xs={4}>
            <Controls.Button
              type="submit"
              text="Check And Fix"
              onClick={() => {
                setMessage("Running...");
                checkAndFix(invoice.id);
              }}
              disabled={false}
            />
          </Grid>
        </div>
      )}
      {message} */}
      <div style={{ maxWidth: "100%" }}>
        {invoice.enable_pos_payments == true &&
          !paymentForEdit &&
          invoice.contact &&
          invoice.contact.data &&
          isEmpty(invoice.contact.data.fraudolent.data) && (
            <Tabs
              value={selectedPaymentTypeTab}
              onChange={handleChangeTab}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
              style={{
                flexGrow: 1,
                width: "fit-content",
                margin: "0 auto",
                marginBottom: 20,
              }}
              disabled={isSubmitDisable()}
            >
              <Tab
                style={{ maxWidth: "fit-content" }}
                icon={
                  <Iconify
                    style={{ width: 30, height: 30 }}
                    icon={"mdi:payment"}
                  />
                }
                label="MANUAL PAYMENT"
              />

              <Tab
                style={{ maxWidth: "fit-content" }}
                icon={
                  <Iconify
                    style={{ width: 30, height: 30 }}
                    icon={"fontisto:shopping-pos-machine"}
                  />
                }
                label="POS PAYMENT"
              />
            </Tabs>
          )}

        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Grid container spacing="2">
            {isRefund && refundReasons.length > 0 && (
              <Grid item xs={3}>
                <Controller
                  name="refund_reason_id"
                  control={control}
                  defaultValue={
                    paymentForEdit && paymentForEdit.refund_reason_id
                      ? paymentForEdit.refund_reason_id
                      : refundRequestsForEdit
                      ? refundRequestsForEdit.reason_id
                      : defaultReason()
                  }
                  as={({ onChange, value }) => (
                    <Controls.Select
                      defaultValue={
                        paymentForEdit && paymentForEdit.refund_reason_id
                          ? paymentForEdit.refund_reason_id
                          : refundRequestsForEdit
                          ? refundRequestsForEdit.reason_id
                          : defaultReason()
                      }
                      //disabled={readonly}
                      label="*Refund Reason"
                      options={refundReasonsOptions()}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      value={value}
                    />
                  )}
                  rules={{ required: "Refund Reason is required" }}
                />

                {errors.refund_reason_id && (
                  <h4 className={classes.errors}>
                    {errors.refund_reason_id.message}
                  </h4>
                )}
              </Grid>
            )}

            {(invoice.enable_pos_payments == 0 ||
              selectedPaymentTypeTab == 0) && (
              <Grid container spacing="2">
                <Grid item xs={isRefund && refundReasons.length > 0 ? 2 : 3}>
                  <Controller
                    name="payment_type"
                    control={control}
                    defaultValue={
                      paymentForEdit
                        ? paymentForEdit.payment_type
                        : gateways && gateways.length > 1
                        ? gateways[1].name
                        : gateways && gateways.length == 1
                        ? gateways[0].name
                        : ""
                    }
                    as={({ onChange, value }) => (
                      <Controls.Select
                        label="Payment Type"
                        options={paymentOptions()}
                        onChange={(value) => onChange(value)}
                        value={value}
                      />
                    )}
                    rules={{ required: "Payment type is required" }}
                  />
                  {errors.payment_type && (
                    <h4 className={classes.errors}>
                      {errors.payment_type.message}
                    </h4>
                  )}
                </Grid>
                {authUser.organization.data.multiple_locations === 1 && (
                  <Grid item xs={isRefund && refundReasons.length > 0 ? 2 : 3}>
                    <Controller
                      name="building_id"
                      control={control}
                      defaultValue={
                        paymentForEdit?.building_id ||
                        authUser.building_id ||
                        ""
                      }
                      as={({ onChange, value }) => (
                        <Controls.Select
                          label="Building"
                          options={buildingOptions()}
                          onChange={(value) => onChange(value)}
                          value={value}
                          disabled={!userCanChangeBuilding}
                        />
                      )}
                      rules={{
                        required: "Building is required",
                      }}
                    />
                    {errors.building_id && (
                      <h4 className={classes.errors}>
                        {errors.building_id.message}
                      </h4>
                    )}
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Controller
                    name="payment_date"
                    type="date"
                    control={control}
                    defaultValue={
                      paymentForEdit
                        ? moment(paymentForEdit.date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : moment().format("YYYY-MM-DD HH:mm:ss")
                    }
                    as={({ onChange, value }) => (
                      <Controls.DateTimePicker
                        label="Payment Date"
                        shouldCloseOnSelect
                        defaultValue={
                          paymentForEdit
                            ? moment(paymentForEdit.date).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : moment().format("YYYY-MM-DD HH:mm:ss")
                        }
                        disabled={!userCanEditPayment || lockDate}
                        onChange={(date) => onChange(date)}
                        value={value}
                      />
                    )}
                    rules={{
                      required: "Date is required",
                    }}
                  />
                  {errors.payment_date && (
                    <h4 className={classes.errors}>
                      {errors.payment_date.message}
                    </h4>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="sale_created_at"
                    type="date"
                    control={control}
                    defaultValue={
                      paymentForEdit
                        ? moment(paymentForEdit.sale_created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : moment().format("YYYY-MM-DD HH:mm:ss")
                    }
                    as={({ onChange, value }) => (
                      <Controls.DateTimePicker
                        label="Sale Date"
                        shouldCloseOnSelect
                        defaultValue={
                          paymentForEdit
                            ? moment(paymentForEdit.sale_created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : moment().format("YYYY-MM-DD HH:mm:ss")
                        }
                        disabled={!userCanEditPayment || lockDate}
                        onChange={(date) => onChange(date)}
                        value={value}
                      />
                    )}
                    rules={{
                      required: "Date is required",
                    }}
                  />
                  {errors.sale_created_at && (
                    <h4 className={classes.errors}>
                      {errors.sale_created_at.message}
                    </h4>
                  )}
                </Grid>

                {watchAllFields["payment_type"] &&
                (gateways?.find((a) => a.name == watchAllFields["payment_type"])
                  ?.is_card_required ||
                  false) ? (
                  <>
                    {" "}
                    <Grid item xs={5}>
                      <Controller
                        name="cardholder"
                        control={control}
                        defaultValue={paymentForEdit?.cardholder || ""}
                        as={<Controls.Input label="Card holder" />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="transaction_id"
                        control={control}
                        defaultValue={paymentForEdit?.transaction_id || ""}
                        as={<Controls.Input label="Transaction id" />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="cc_last_4"
                        control={control}
                        defaultValue={paymentForEdit?.cc_last_4 || ""}
                        as={<Controls.Input label="Last 4 CC" />}
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                {isRefund && (
                  <Grid item xs={4}>
                    <Controller
                      name="responsible_id"
                      control={control}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          defaultValue={
                            paymentForEdit && paymentForEdit.responsible_id
                              ? paymentForEdit.responsible_id
                              : -1
                          }
                          allowEmpty={true}
                          label="Surgeon"
                          options={getUserOptions()}
                          onChange={(value) => {
                            onChange(value);
                          }}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                )}

                {isRefund && (
                  <>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="isChargeback"
                        control={control}
                        defaultValue={paymentForEdit?.isChargeback || 0}
                        as={({ onChange, value }) => (
                          <Controls.Checkbox
                            label="Chargeback"
                            checked={value === 1 ? true : false}
                            onChange={(value) => {
                              onChange(value ? 1 : 0);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </>
                )}

                {isRefund && (
                  <Grid item xs={3}>
                    <Controller
                      name="ptChangePayment"
                      control={control}
                      defaultValue={paymentForEdit?.ptChangePayment || 0}
                      as={({ onChange, value }) => (
                        <Controls.Checkbox
                          label="Patient Change Payment"
                          checked={value === 1 ? true : false}
                          onChange={(value) => {
                            onChange(value ? 1 : 0);
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={10}>
                  <Controller
                    name="details"
                    control={control}
                    defaultValue={
                      paymentForEdit
                        ? paymentForEdit.details
                        : refundComment
                        ? refundComment
                        : ""
                    }
                    as={<Controls.Textarea label="Details" rows="2" />}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    control={control}
                    name="file"
                    defaultValue={file}
                    as={({ onChange, value }) => (
                      <Controls.DropzoneDialog
                        label="*Attach file"
                        onChange={(files) => {
                          setFile(files[0]);
                          onChange(files[0]);
                        }}
                        value={value}
                      />
                    )}
                    rules={{ required: !paymentForEdit }}
                  />
                  {errors.file && (
                    <h4 className={classes.errors}>{"File is required"}</h4>
                  )}
                  {file && (
                    <span>
                      {file.path ? file.path : file.name ? file.name : ""}
                    </span>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box my={5}>
                <TableContainer component={Paper}>
                  {!paymentForEdit ||
                  !isEmpty(paymentForEdit.paymentdetail.data) ? (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">
                            {maxAmount ? "Amount Paid" : "Balance"}
                          </TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{renderInvoiceItems()}</TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "rgb(109 109 109)",
                            }}
                            colSpan={6}
                            align="right"
                          >
                            Total to Pay:{" "}
                            {isNaN(calculeTotal2Pay())
                              ? "$0.00"
                              : "$" + calculeTotal2Pay()}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  ) : (
                    ""
                  )}

                  {paymentForEdit &&
                  isEmpty(paymentForEdit.paymentdetail.data) ? (
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Controller
                          name={`itemsAmount[0]`}
                          className={classes.inputItemAmount}
                          control={control}
                          defaultValue={
                            paymentForEdit ? paymentForEdit.amount : 0
                          }
                          as={
                            <Controls.InputMoney
                              label="Amount"
                              defaultValue={
                                paymentForEdit ? paymentForEdit.amount : 0
                              }
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </TableContainer>
              </Box>

              {refundFull === 1 && (
                <Controller
                  name="isFull"
                  control={control}
                  defaultValue={
                    paymentForEdit
                      ? paymentForEdit.isFull
                      : refundFull
                      ? refundFull
                      : 0
                  }
                  as={({ value, onChange }) => (
                    <Controls.Checkbox
                      label="Full"
                      checked={refundFull === 1 ? true : false}
                      onChange={(value) => onChange(value ? 1 : 0)}
                    />
                  )}
                />
              )}

              {refundFullItem === 1 && (
                <Controller
                  name="isFullItem"
                  control={control}
                  defaultValue={
                    paymentForEdit
                      ? paymentForEdit.isFullItem
                      : refundFullItem
                      ? refundFullItem
                      : 0
                  }
                  as={({ value, onChange }) => (
                    <Controls.Checkbox
                      label="Full Item"
                      checked={refundFullItem === 1 ? true : false}
                      onChange={(value) => onChange(value ? 1 : 0)}
                    />
                  )}
                />
              )}

              {(itemName || refundFullItem || refundFull) && (
                <Controller
                  name="reAdjust"
                  control={control}
                  defaultValue={
                    paymentForEdit ? paymentForEdit.reAdjust : false
                  }
                  as={({ value, onChange }) => (
                    <Controls.Checkbox
                      label="Re - Adjust"
                      checked={value === 1 ? true : false}
                      onChange={(value) => onChange(value ? 1 : 0)}
                    />
                  )}
                />
              )}

              {/*  

              <Button
                id="demo-positioned-button"
                aria-controls={
                  isDeviceListOpen ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={isDeviceListOpen ? "true" : undefined}
                onClick={handleClickDevicesList}
              >
                Pay Throught POS
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorElDevices}
                open={isDeviceListOpen}
                onClose={handleCloseDevicesList}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {renderDevicesList()}
              </Menu>

              */}
              {selectedPaymentTypeTab == 1 && !paymentForEdit && (
                <div
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                  }}
                >
                  <div>
                    <div style={{ maxWidth: 150, marginBottom: 20 }}>
                      <Button onClick={handleOpenDevicesList}>
                        Select POS Device
                      </Button>
                      <FormControl>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={isDeviceListOpen}
                          onClose={handleCloseDevicesList}
                          onOpen={handleOpenDevicesList}
                          value={selectedDevice?.title}
                          //onChange={handleSelectInDevicesList}
                        >
                          <MenuItem
                            value={null}
                            onClick={() => setSelectedDevice(null)}
                          >
                            <em>None</em>
                          </MenuItem>
                          {renderDevicesList()}
                          {!devicesDropDown && !selectedDevice && (
                            <LinearProgress />
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {isSubmitting && (
                    <div style={{ marginLeft: 250, marginTop: 17, width: 300 }}>
                      {" "}
                      <div style={{ textAlign: "center" }}>
                        <Chip
                          style={{ margin: 10 }}
                          label="Processing POS Payment"
                          color="primary"
                          variant="outlined"
                        />
                        <LinearProgress />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div style={{ display: "flex" }}>
                {" "}
                <div>
                  <Controls.Button
                    type="submit"
                    text={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitDisable()}
                  />
                </div>
                {userCanSendPosCancellationRequest &&
                  selectedPaymentTypeTab == 1 &&
                  selectedDevice && (
                    <div>
                      <Controls.Button
                        text={
                          isCanceling
                            ? "Canceling......"
                            : "Cancel Device Transactions"
                        }
                        onClick={() =>
                          handleRequestDevicePaymentCancellation(selectedDevice)
                        }
                        disabled={isCanceling}
                      />
                    </div>
                  )}
                {selectedPaymentTypeTab == 1 && !isSubmitting && (
                  <div>
                    <Controls.Button text={"Close"} onClick={() => onClose()} />
                  </div>
                )}
              </div>
              <Box style={{ margin: 5, height: "50px", width: "100%" }}>
                {bottonError != "" ? (
                  <Alert severity="error">{bottonError}</Alert>
                ) : (
                  ""
                )}
              </Box>
              {/* <Button
                id="demo-positioned-button"
                aria-controls={
                  isDeviceListOpen ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={isDeviceListOpen ? "true" : undefined}
                onClick={handleClickDevicesList}
              >
                Pay Throught POS
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorElDevices}
                open={isDeviceListOpen}
                onClose={handleCloseDevicesList}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {renderDevicesList()}
              </Menu> */}
            </Grid>
            <ConfirmDialog
              disableConfirm={isCanceling}
              confirmLoading={isCanceling}
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Grid>
        </form>
      </div>
    </>
  );
}
