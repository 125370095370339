import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  AppBar,
  Badge,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Api from "../lib/api";
import EmailIcon from "@material-ui/icons/Email";
import { green, teal } from "@material-ui/core/colors";
import Iconify from "./Iconify";
import { useDispatch, useSelector } from "react-redux";

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

import { createPortalMessagesNotificationsService } from "../redux/slices/portalMessagesNotificationsSlice.js";

function MessagesNotification(props) {
  const dispatch = useDispatch();

  const { portalMessagesNotifications } = useSelector(
    (state) => state.portalMessagesNotifications
  );

  const getMessagesUnread = async () => {
    const { data } = await Api.getMessagesUnRead();
    dispatch(createPortalMessagesNotificationsService(data));
  };

  useEffect(() => {
    if (!portalMessagesNotifications) {
      getMessagesUnread();
    }
  }, []);

  return (
    <IconButton style={{ color: green[700] }}>
      <Link to={"/messages"}>
        <Indicator
          badgeContent={
            portalMessagesNotifications ? portalMessagesNotifications : 0
          }
        >
          <Iconify
            icon={"fluent:mail-copy-24-filled"}
            style={{ color: green[700] }}
            width={25}
            height={25}
          />
        </Indicator>
      </Link>
    </IconButton>
  );
}

export default MessagesNotification;
