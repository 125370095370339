import React, { useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";
import { isEmpty, uniqueId, findIndex, capitalize } from "lodash";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import CRMUtils from "../utils";
import PersonIcon from "@material-ui/icons/Person";
import useTable from "./useTable";
import Controls from "./controls";
import {
  Paper,
  Card as MuiCard,
  makeStyles,
  Chip,
  Checkbox,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  Box,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import AddIcon from "@material-ui/icons/Add";
import QuoteDetails from "../pages/quotes/details";
import QuoteDetailsDeleted from "../pages/quotes/detailsDeleted";
import Popup from "./Popup";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import { fetchEntitiesService as fetchProductsService } from "../redux/slices/productsnewSlice.js";
import { fetchPaymentGatewaysService } from "../redux/slices/paymentgateways";
import { fetchDocumentTypesService } from "../redux/slices/documenttypes";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import QuoteBuilder from "./QuoteBuilder";
import { createInvoiceService } from "../redux/slices/invoices";
import ViewLogsTable from "../components/logs/ViewLogsTable";
import { useTranslation } from "react-i18next";

import VisibilityIcon from "@material-ui/icons/Visibility";
import Logs from "./logs";
import Api from "../lib/api";
import Iconify from "./Iconify";
import { yellow } from "@material-ui/core/colors";
import QuoteLogs from "./QuoteLogs.js";

var dataSliceProduct = {
  stateListName: "productsNew",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Wrapper = styled.div`
  height: 100%;
`;

const statusArray = [
  {
    label: "Unsend",
    color: grey[700],
    id: "unsend",
  },
  {
    label: "Sent",
    color: blue[700],
    id: "sent",
  },
  {
    label: "Approved",
    color: green[700],
    id: "approved",
  },
  {
    label: "Declined",
    color: red[700],
    id: "declined",
  },

  {
    label: "Canceled",
    color: red[700],
    id: "canceled",
  },
  {
    label: "Re-sign Needed",
    color: yellow[700],
    id: "re-sign_needed",
  },
];

const headCells = [
  { id: "selected", label: "", disableSorting: true },
  { id: "name", label: "Name" },
  { id: "contact", label: "Contact" },
  { id: "user", label: "User" },
  { id: "signed", label: "Signed" },
  { id: "approved", label: "Approved" },
  { id: "approved_by", label: "Approved by" },
  { id: "approved_at", label: "Approved at" },
  { id: "status", label: "Status" },
];

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(0.1),
    padding: theme.spacing(0.5),
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  childProduct: {
    marginLeft: "35px",
    color: "#999",
  },
}));

function QuotesContact(props) {
  const {
    quotes,
    onSelect,
    selectedQuote,
    contact,
    location,
    userTeamLeader = false,
    rReadonly = false,
    userIsCallCenter = false,
    userIsSocialMedia = false,
    consultantErrorHandle = null,
  } = props;

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{
          paddingLeft: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingRight: 0,
        }}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation(["common", "quotes"]);
  const readOnlySalesManager = userTeamLeader && !contact.editable;

  const { authUser } = useSelector((state) => state.auth);
  dataSliceProduct.orgId = authUser.organization_id;
  const userCanAdd =
    CRMUtils.userHasPermission(authUser, ["quote_create"]) && !rReadonly;
  const userCanEdit =
    CRMUtils.userHasPermission(authUser, ["quote_edit"]) && !rReadonly;
  const userCanDelete =
    CRMUtils.userHasPermission(authUser, ["quote_delete"]) && !rReadonly;
  const userCanCancel =
    CRMUtils.userHasPermission(authUser, ["quote_cancel"]) && !rReadonly;

  const userIsbillingMGAssistant = CRMUtils.userHasPermission(authUser, [
    "dashboard_billingMGAssistant",
  ]);
  const userCanViewEditContactMark = CRMUtils.userHasPermission(authUser, [
    "user_ContactMark",
  ]);
  const quoteLogsIdRef = useRef(null);
  const { productsNew } = useSelector((state) => state.productsNew);
  const { paymentGateways } = useSelector((state) => state.paymentgateways);
  const { usersDropdown, usersDropdownLoanding } = useSelector(
    (state) => state.users
  );
  const { documentTypes } = useSelector((state) => state.documenttypes);
  const [contactQuotes, setContactQuotes] = useState([]);
  const [contactQuotesDeleted, setContactQuotesDeleted] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [progressBar2, setProgressBar2] = useState(false);
  const [selected, setSelected] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();
  const [quoteDetail, setQuoteDetail] = useState(null);

  const [quoteDetailDeleted, setQuoteDetailDeleted] = useState(null);

  const [openPopup, setOpenPopup] = useState(
    location == "/quotesnew" ? true : false
  );

  const checkMainProduct = (id, items) => {
    //Check that Main product exists
    return items
      ? items.filter((element) => {
          return (
            element &&
            element.childProducts &&
            element.childProducts.filter((row) => {
              return row.child_product_id == id;
            }).length > 0
          );
        })
      : [];
  };

  const headCellsMaterial = [
    { field: "name", title: "Name" },
    {
      field: "items",
      title: "Items",
      render: (rowData) => {
        return rowData.items.data.map((item) => {
          return (
            <ListItem>
              <ListItemText
                primary={
                  <Typography>
                    {item.name}
                    {item.is_revision ? (
                      <strong
                        style={{
                          color: "#326a9b",
                          marginLeft: 5,
                          fontWeight: 400,
                        }}
                      >
                        {"(Revision)"}
                      </strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                }
                secondary={
                  item.responsible
                    ? item.responsible.title ||
                      `${item.responsible.fname} ${item.responsible.lname}`
                    : null
                }
                className={item && item.parent_id ? classes.childProduct : ""}
              />
            </ListItem>
          );
        });
      },
    },
    {
      field: "contact",
      title: "Contact",
      render: (rowData) => {
        return rowData.contact_name;
      },
    },
    {
      field: "user",
      title: "User",
      render: (rowData) => {
        return rowData.user_name;
      },
    },
    {
      field: "signed",
      title: "Signed",
      render: (rowData) => {
        return rowData.signed ? <CheckIcon /> : "";
      },
    },
    {
      field: "approved",
      title: "Approved",
      render: (rowData) => {
        return rowData.approved_by ? <CheckIcon /> : "";
      },
    },
    {
      field: "approved_by",
      title: "Approved by",
      render: (rowData) => {
        return rowData.approvedBy
          ? (rowData.approvedBy.data.first_name
              ? rowData.approvedBy.data.first_name
              : "") +
              " " +
              (rowData.approvedBy.data.last_name
                ? rowData.approvedBy.data.last_name
                : "")
          : "";
      },
    },
    {
      field: "approved_at",
      title: "Approved at",
      render: (rowData) => {
        return rowData.approved_at;
      },
    },
    {
      field: "status",
      title: "Status",
      render: (rowData) => {
        return (
          <Chip
            label={getStatus(rowData.status)?.label || ""}
            style={{
              background: getStatus(rowData.status)?.color || "",
              color: rowData.status === "canceled" ? "white" : "black",
            }}
          />
        );
      },
    },
  ];

  const [openDetailPopup, setOpenDetailPopup] = useState(false);

  const [openDetailPopupDeleted, setOpenDetailPopupDeleted] = useState(false);

  const onDelete = async (id) => {
    try {
      const data = await Api.deleteQuote(id).then(function () {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });

        setContactQuotes(contactQuotes.filter((a) => a.id != id));
      });
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const onCancel = async (id) => {
    var payload = {
      status: "canceled",
    };
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      const { data } = await Api.updateQuote(id, payload);

      if (data) {
        setQuoteDetail(data.data);
        const index = findIndex(contactQuotes, ["id", data.data.id]);
        const entity = { ...contactQuotes[index], ...data.data };
        let entities = [...contactQuotes];
        entities[index] = entity;
        setContactQuotes([...entities]);
      }
      setNotify({
        isOpen: true,
        message: "Update Successfully",
        type: "success",
      });
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const fullHeadCells = [].concat(headCells, [
    { id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ]);

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    openQuote(item);
    if (onSelect) onSelect(item);
  };
  const openQuote = (quote) => {
    setQuoteDetail(quote);
    setOpenDetailPopup(true);
  };

  const openInPopupDeleted = (quote) => {
    setQuoteDetailDeleted(quote);
    setOpenDetailPopupDeleted(true);
  };

  const closePopupDeleted = () => {
    setQuoteDetailDeleted(null);
    setOpenDetailPopupDeleted(false);
  };

  const closePopup = () => {
    setOpenDetailPopup(false);
  };

  const closeNewQuotePopup = () => {
    setOpenPopup(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contactQuotes.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const userCanSeeLogs =
    CRMUtils.userHasRole(authUser, ["admin", "super-admin"]) ||
    CRMUtils.userHasPermission(authUser, ["quotes_viewLogs"]);

  const [openLogPopup, setOpenLogPopup] = useState(false);
  const [logs, setLogs] = useState([]);

  const closeLogPopup = () => {
    setOpenLogPopup(false);
    quoteLogsIdRef.current = null;
  };
  const showQuoteLogs = async (row) => {
    const { data } = await Api.getQuoteLogs(row.id);
    setLogs(data);
    setOpenLogPopup(true);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const createQuote = async (payload) => {
    try {
      const { data } = await Api.saveQuote(payload);
      setContactQuotes([data.data, ...contactQuotes]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const saveQuote = (data) => {
    const quoteitems = data.items.map((item) => {
      return {
        ...item,
        responsible_id: item.responsible ? item.responsible.id : null,
        product_id: item.product_id,
        product_gift_reason_id: item.product_gift_reason_id,
        item_name: item.name,
        quantity: item.quantity ? parseInt(item.quantity) : 1,
      };
    });

    // console.log("quoteitems: ", quoteitems);

    if (!isEmpty(authUser)) {
      var payload = {
        items: quoteitems,
        status: data.status,
        name: `t("common:general.Quotefor") ${
          contact.fname + " " + contact.lname
        }`,
        contact_id: contact.id,
        financing_id: data.financing_id,
        organization_id: authUser.organization_id,
        included: data.included,
        expiration_date: data.expiration_date ? data.expiration_date : null,
        building_id: authUser.building_id,
        interest: data.interest ? data.interest : null,
        user_id: data.user_id ? data.user_id : authUser.id,
        approved_at: data.approved_at,
      };

      createQuote(payload).then(() => {
        setNotify({
          isOpen: true,
          message: "Quote created",
          type: "success",
        });
        setOpenPopup(false);
      });
    }
  };

  const convert2Invoice = (items) => {
    const payload = {
      name: `Invoice for ${contact.fname + " " + contact.lname}`,
      included: quoteDetail.included,
      contact_id: contact.id,
      user_id: quoteDetail.user_id,
      organization_id: authUser.organization_id,
      items: items,
      interest: quoteDetail.interest,
      financing_id: quoteDetail.financing_id,
      downpayment_per_procedure: quoteDetail.downpayment_per_procedure,
      downpayment_amount: quoteDetail.downpayment_amount,
    };

    dispatch(createInvoiceService(payload)).then((result) => {
      setNotify({
        isOpen: true,
        message: "Invoice created",
        type: "success",
      });
    });
  };

  const getStatus = (status) => {
    const index = findIndex(statusArray, ["id", status]);
    if (index) {
      return statusArray[index];
    }
    return statusArray[0];
  };

  const userIsFrontDesk =
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskBasic"]) ||
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskManager"])
      ? true
      : false;

  useEffect(() => {
    dispatch(fetchProductsService(dataSliceProduct));

    if (isEmpty(paymentGateways))
      dispatch(fetchPaymentGatewaysService(authUser.organization_id));
    if (isEmpty(documentTypes))
      dispatch(fetchDocumentTypesService(authUser.organization_id));
    if (isEmpty(usersDropdown) && !usersDropdownLoanding)
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  useEffect(() => {
    if (selectedQuote) openQuote(selectedQuote);
  }, [selectedQuote]);

  useEffect(() => {
    //  setLocalToDos(toDos);
    if (contact.id) {
      setProgressBar(true);
      getQuotesContact().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact.id]);

  useEffect(() => {
    if (contact.id && tabValue == 1) {
      setProgressBar2(true);

      getInvoicesContactDeleted().then(function () {
        setProgressBar2(false);
      });
    }
  }, [contact.id, tabValue]);

  const getQuotesContact = async () => {
    try {
      const { data } = await Api.getContactQuotesPlain(contact.id);
      setContactQuotes([...data.data]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const closeOnConsultantChange = (error) => {
    setOpenDetailPopup(false);
    if (typeof consultantErrorHandle === "function") {
      consultantErrorHandle(error);
    }
  };

  const getInvoicesContactDeleted = async () => {
    try {
      const { data } = await Api.getContactQuotesPlainDelted(contact.id);
      setContactQuotesDeleted([...data.data]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const dataMaterialTable = contactQuotes
    ? contactQuotes.map((o) => ({ ...o }))
    : [];

  const dataMaterialTableDeleted = contactQuotesDeleted
    ? contactQuotesDeleted.map((o) => ({ ...o }))
    : [];

  return (
    <Wrapper>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="tabs"
        TabIndicatorProps={{ style: { background: "#009688" } }}
      >
        <Tab
          label={
            <Typography
              style={{
                color: tabValue == 0 ? "#009688" : "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
              }}
            >
              Quotes
            </Typography>
          }
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Typography
              style={{
                color: tabValue == 1 ? "#009688" : "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
              }}
            >
              Deleted Quotes
            </Typography>
          }
          {...a11yProps(1)}
        />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <TblContainer>
          <div
            style={{
              maxWidth: "98%",
              paddingLeft: 0,
              marginLeft: 0,
              marginRight: 0,
              paddingRight: 0,
            }}
          >
            <MaterialTable
              isLoading={progressBar}
              columns={headCellsMaterial}
              data={dataMaterialTable}
              onRowClick={(event, rowData) =>
                (userCanEdit && !userTeamLeader) ||
                (userCanEdit && userTeamLeader && contact.editable) ||
                userIsCallCenter ||
                userIsFrontDesk ||
                userIsSocialMedia ||
                userIsbillingMGAssistant
                  ? openInPopup(rowData)
                  : console.log("User Can't Edit")
              }
              actions={[
                {
                  icon: () => (
                    <Fab color="primary" aria-label="add">
                      <Iconify
                        icon={"fluent:add-12-filled"}
                        width={25}
                        height={25}
                      />
                    </Fab>
                  ),
                  tooltip: "Add Quote",
                  disabled:
                    !userCanAdd || (userTeamLeader && !contact.editable),
                  hidden: !userCanAdd || (userTeamLeader && !contact.editable),
                  isFreeAction: true,
                  onClick: (event) => {
                    setOpenPopup(true);
                  },
                },
                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color: "#3f51b5",
                      }}
                    >
                      <Iconify
                        icon={"material-symbols:view-compact-rounded"}
                        width={20}
                        height={20}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "View",
                  disabled: !userCanViewEditContactMark,
                  hidden: !userCanViewEditContactMark,
                  onClick: (event, rowData) => {
                    openInPopup(rowData);
                  },
                }),
                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color:
                          (!userCanEdit &&
                            !userIsCallCenter &&
                            !userIsFrontDesk &&
                            !userIsSocialMedia &&
                            !userIsbillingMGAssistant) ||
                          rowData.status === "approved" ||
                          rowData.status === "canceled"
                            ? "#bdbdbd"
                            : "#009688",
                      }}
                    >
                      <Iconify
                        icon={"fluent:edit-24-filled"}
                        width={20}
                        height={20}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "Edit",
                  disabled:
                    (!userCanEdit &&
                      !userIsCallCenter &&
                      !userIsFrontDesk &&
                      !userIsSocialMedia &&
                      !userIsbillingMGAssistant) ||
                    rowData.status === "approved" ||
                    rowData.status === "canceled",
                  hidden:
                    (!userCanEdit &&
                      !userIsCallCenter &&
                      !userIsFrontDesk &&
                      !userIsSocialMedia &&
                      !userIsbillingMGAssistant) ||
                    rowData.status === "approved" ||
                    rowData.status === "canceled",

                  onClick: (event, rowData) => {
                    openInPopup(rowData);
                  },
                }),

                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color:
                          !userCanCancel ||
                          userIsFrontDesk ||
                          (userTeamLeader && !contact.editable) ||
                          rowData.status === "approved" ||
                          rowData.status === "canceled"
                            ? "#bdbdbd"
                            : "#f44336",
                      }}
                    >
                      <Iconify icon={"mdi:cancel"} width={20} height={20} />
                    </Controls.IconButton>
                  ),
                  tooltip: "Cancel",
                  disabled:
                    !userCanCancel ||
                    userIsFrontDesk ||
                    (userTeamLeader && !contact.editable) ||
                    rowData.status === "approved" ||
                    rowData.status === "canceled",
                  hidden:
                    !userCanCancel ||
                    userIsFrontDesk ||
                    (userTeamLeader && !contact.editable) ||
                    rowData.status === "approved" ||
                    rowData.status === "canceled",
                  onClick: (event, rowData) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to cancel this quote?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => onCancel(rowData.id),
                    }),
                }),
                (rowData) => ({
                  icon: () => (
                    <Controls.IconButton
                      style={{
                        color:
                          rowData.has_invoices ||
                          !userCanDelete ||
                          userIsFrontDesk ||
                          (userTeamLeader && !contact.editable) ||
                          rowData.status === "approved"
                            ? "#bdbdbd"
                            : "#f44336",
                      }}
                    >
                      <Iconify
                        icon={"mingcute:delete-fill"}
                        width={20}
                        height={20}
                      />
                    </Controls.IconButton>
                  ),
                  tooltip: "Delete",
                  disabled:
                    rowData.has_invoices ||
                    !userCanDelete ||
                    userIsFrontDesk ||
                    (userTeamLeader && !contact.editable) ||
                    rowData.status === "approved",
                  hidden:
                    !userCanDelete ||
                    userIsFrontDesk ||
                    (userTeamLeader && !contact.editable) ||
                    rowData.status === "approved",
                  onClick: (event, rowData) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this quote?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => onDelete(rowData.id),
                    }),
                }),
                {
                  icon: () => (
                    <Controls.IconButton
                      style={{ color: !userCanSeeLogs ? "#bdbdbd" : "#673ab7" }}
                    >
                      <Iconify icon={"subway:eye"} width={20} height={20} />
                    </Controls.IconButton>
                  ),
                  tooltip: "Logs",
                  onClick: (event, rowData) => {
                    quoteLogsIdRef.current = rowData.id;
                    setOpenLogPopup(true);
                    //showQuoteLogs(rowData);
                  },
                  hidden: !userCanSeeLogs,
                },
              ]}
              options={{
                loadingType: "linear",
                pageSize: 20, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                actionsColumnIndex: -1,
                exportButton: true,
                rowStyle: (rowData, index) => {
                  if (rowData.status === "canceled") {
                    return {
                      backgroundColor: red[50],
                    };
                  }
                  return {
                    backgroundColor: "#ffffff",
                  };
                },
              }}
              title={t("common:general.Quotes")}
            />
          </div>
        </TblContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TblContainer>
          <MaterialTable
            actions={[
              {
                icon: () => (
                  <Controls.IconButton
                    style={{ color: !userCanSeeLogs ? "#bdbdbd" : "#673ab7" }}
                  >
                    <Iconify icon={"subway:eye"} width={20} height={20} />
                  </Controls.IconButton>
                ),
                tooltip: "Logs",
                onClick: (event, rowData) => {
                  quoteLogsIdRef.current = rowData.id;
                  setOpenLogPopup(true);
                  //showQuoteLogs(rowData);
                },
                hidden: !userCanSeeLogs,
              },
            ]}
            isLoading={progressBar2}
            columns={headCellsMaterial}
            data={dataMaterialTableDeleted}
            onRowClick={(event, rowData) => openInPopupDeleted(rowData)}
            options={{
              loadingType: "linear",
              pageSize: 20, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              exportButton: true,
              rowStyle: (rowData, index) => {
                if (rowData.status === "canceled") {
                  return {
                    backgroundColor: red[50],
                  };
                }
                return {
                  backgroundColor: "#ffffff",
                };
              },
            }}
            title={t("common:general.Quotes")}
          />
        </TblContainer>
      </TabPanel>

      {/* <Popup
        title="Quote Logs"
        openPopup={openLogPopup}
        onClose={closeLogPopup}
        fullWidth={true}
      >
        <Logs logs={logs} />
      </Popup> */}

      <Popup
        fullScreen
        title={
          quoteDetail
            ? t("common:general.Quote") + ` #${quoteDetail.name}`
            : t("common:general.NewQuote")
        }
        openPopup={openDetailPopup}
        onClose={closePopup}
      >
        <QuoteDetails
          contact_id={contact.id}
          quotes={contactQuotes}
          quote={quoteDetail}
          setQuotes={setContactQuotes}
          onApprove={closePopup}
          readOnlySalesManager={
            readOnlySalesManager ||
            userIsSocialMedia ||
            userIsCallCenter ||
            userIsbillingMGAssistant
          }
          userIsbillingMGAssistant={userIsbillingMGAssistant}
          closeOnConsultantChange={closeOnConsultantChange}
        />
      </Popup>

      <Popup
        fullScreen
        title={"common:general.Quote" + ` #${quoteDetailDeleted?.name}`}
        openPopup={openDetailPopupDeleted}
        onClose={closePopupDeleted}
      >
        <QuoteDetailsDeleted
          contact_id={contact.id}
          quotes={contactQuotes}
          quote={quoteDetailDeleted}
          setQuotes={setContactQuotes}
          onApprove={closePopup}
          readOnlySalesManager={true}
          userIsbillingMGAssistant={false}
        />
      </Popup>
      <Popup
        fullWidth={true}
        title={t("common:general.NewQuote")}
        openPopup={openPopup}
        onClose={closeNewQuotePopup}
      >
        <QuoteBuilder
          users={usersDropdown}
          products={productsNew}
          contact={contact}
          paymentGateways={paymentGateways}
          statusOptions={statusArray}
          onSaveQuote={saveQuote}
          onConvert2Invoice={convert2Invoice}
          openItemDefault={true}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Quote Logs"
        openPopup={openLogPopup}
        onClose={closeLogPopup}
        fullWidth={true}
      >
        <QuoteLogs quoteID={quoteLogsIdRef.current} />
      </Popup>
    </Wrapper>
  );
}

export default QuotesContact;
