import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { isEmpty, findIndex, capitalize } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useTable from "./useTable";
import Controls from "./controls";
import CRMUtils from "../utils";
import {
  createCreditMemoService,
  updateCreditMemoService,
  deleteCreditMemoService,
} from "../redux/slices/creditmemos";
import {
  Paper,
  Card as MuiCard,
  makeStyles,
  Chip,
  Checkbox,
  Fab,
  Typography,
  Box,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import MaterialTable from "material-table";
import Popup from "./Popup";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";

import Iconify from "./Iconify.js";

// import { fetchContactsService } from "../redux/slices/contacts";

import CreditMemoForm from "./forms/CreditMemoForm";
import Api from "../lib/api";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const headCells = [
  { id: "selected", label: "", disableSorting: true },
  { id: "contact", label: "Contact" },
  { id: "amount", label: "Amount" },
  { id: "details", label: "Details" },
  { id: "user", label: "Created By" },
  { id: "created_at", label: "Created at" },
];

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  estrapayment: {
    color: "grey",
  },
}));

function CreditMemosContact(props) {
  const {
    //creditMemos,
    onSelect,
    selectedCreditMemo,
    contact,
    userTeamLeader = false,
    rReadonly = false,
    consultantErrorHandle = null,
  } = props;
  const dispatch = useDispatch();

  const { authUser } = useSelector((state) => state.auth);

  const userCanCreateCredit =
    CRMUtils.userHasPermission(authUser, ["creditmemo_create"]) && !rReadonly;

  const userCanEditCredit =
    CRMUtils.userHasPermission(authUser, ["creditmemo_edit"]) && !rReadonly;

  const userCanDeleteCredit =
    CRMUtils.userHasPermission(authUser, ["creditmemo_delete"]) && !rReadonly;

  // const { contacts } = useSelector((state) => state.contacts);

  const [selected, setSelected] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();
  const [creditMemoDetail, setCreditMemoDetail] = useState(null);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [contactCreditMemo, setContactCreditMemo] = useState([]);
  const [creditMemoReasons, setCreditMemoReasons] = useState([]);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [progressBar, setProgressBar] = useState(false);

  const onDelete = async (id) => {
    try {
      var deleteCreditMemo = await Api.deleteCreditMemo(id).then(() => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      });

      setContactCreditMemo(contactCreditMemo.filter((a) => a.id != id));
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const fullHeadCells = [].concat(headCells, [
    { id: "actions", label: "Actions", disableSorting: true, align: "right" },
  ]);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(contactCreditMemo, fullHeadCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };
  const openInPopup = (item) => {
    openCreditMemo(item);
    if (onSelect) onSelect(item);
  };
  const openCreditMemo = (creditMemo) => {
    setCreditMemoDetail(creditMemo);
    setOpenDetailPopup(true);
  };

  const closePopup = () => {
    setOpenDetailPopup(false);
  };

  const closeNewCreditMemoPopup = () => {
    setOpenPopup(false);
  };

  const onMassiveDelete = async () => {};

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contactCreditMemo.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const updateCreditMemo = async (id, payload) => {
    try {
      const { data } = await Api.updateCreditMemo(id, payload);
      const index = findIndex(contactCreditMemo, ["id", data.data.id]);
      const entity = { ...contactCreditMemo[index], ...data.data };
      let entities = [...contactCreditMemo];
      entities[index] = entity;

      setContactCreditMemo([...entities]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const createCreditMemo = async (payload) => {
    const { data } = await Api.saveCreditMemo(payload);
    setContactCreditMemo([data.data, ...contactCreditMemo]);
  };

  const saveCreditMemo = (data) => {
    if (!isEmpty(authUser)) {
      var payload = {
        amount: data.amount,
        details: data.details,
        contact_id: contact.id,
        user_id: authUser.id,
        creditmemo_reason_id: data.creditmemo_reason_id,
        organization_id: authUser.organization_id,
      };

      if (creditMemoDetail) {
        updateCreditMemo(creditMemoDetail.id, payload).then((result) => {
          setNotify({
            isOpen: true,
            message: "Update Successfully",
            type: "success",
          });
        });
      } else {
        createCreditMemo(payload).then((result) => {
          setNotify({
            isOpen: true,
            message: "Create Successfully",
            type: "success",
          });
        });
      }
      closePopup();
      closeNewCreditMemoPopup();
    }
  };

  const headCellsMaterial = [
    {
      field: "contact",
      title: "Contact",
      render: (rowData) => {
        return rowData.contact.data.fname + " " + rowData.contact.data.lname;
      },
    },
    { field: "amount", title: "Amount" },
    {
      field: "used",
      title: "Used",
      render: (rowData) => {
        return <Box color="red">{rowData.used}</Box>;
      },
    },
    {
      field: "available",
      title: "Available",
      render: (rowData) => {
        return <Box color="green">{rowData.amount - rowData.used}</Box>;
      },
    },
    {
      field: "details",
      title: "Details",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.details} arrow>
            <InfoIcon />
          </Tooltip>
        );
      },
    },
    {
      field: "creditmemo_reason_name",
      title: "Credit Memo Reason",
    },
    {
      field: "created_by",
      title: "Created By",
      render: (rowData) => {
        return rowData.user.data.title;
      },
    },
    {
      field: "created_at",
      title: "Created at",
      render: (rowData) => {
        return moment(rowData.created_at).format("YYYY/MM/DD");
      },
    },
  ];

  const getCreditMemoReasons = async () => {
    const { data } = await Api.getAllCreditMemoReasonPlain(
      authUser.organization_id
    );
    setCreditMemoReasons([...data.data]);
  };

  useEffect(() => {
    getCreditMemoReasons();
  }, []);

  useEffect(() => {
    if (selectedCreditMemo) openCreditMemo(selectedCreditMemo);
  }, [selectedCreditMemo]);

  useEffect(() => {
    if (contact.id) {
      setProgressBar(true);
      getCreditContact().then(function () {
        setProgressBar(false);
      });
    }
  }, [contact.id]);

  const getCreditContact = async () => {
    try {
      const { data } = await Api.getContactCreditMemos(contact.id);
      setContactCreditMemo([...data.data]);
    } catch (e) {
      if (typeof consultantErrorHandle === "function") {
        consultantErrorHandle(e.data.message);
      }
    }
  };

  const dataMaterialTable = contactCreditMemo
    ? contactCreditMemo.map((o) => ({ ...o }))
    : [];

  return (
    <Wrapper>
      <Paper className={classes.pageContent}>
        <TblContainer>
          <MaterialTable
            isLoading={progressBar}
            columns={headCellsMaterial}
            data={dataMaterialTable}
            onRowClick={(event, rowData) =>
              ((userCanEditCredit && !userTeamLeader) ||
                (userCanEditCredit && userTeamLeader && contact.editable)) &&
              !rowData.coupon
                ? openInPopup(rowData)
                : console.log("userCanNotEditCredit")
            }
            actions={[
              {
                icon: () => (
                  <Fab color="primary" aria-label="add">
                    <Iconify
                      icon={"fluent:add-12-filled"}
                      width={25}
                      height={25}
                    />
                  </Fab>
                ),
                tooltip: "Add Credit Memo",
                isFreeAction: true,
                hidden:
                  !userCanCreateCredit || (userTeamLeader && !contact.editable),
                disabled:
                  !userCanCreateCredit || (userTeamLeader && !contact.editable),
                onClick: (event) => {
                  setOpenPopup(true);
                },
              },
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        !userCanEditCredit ||
                        (userTeamLeader && !contact.editable)
                          ? "#bdbdbd"
                          : "#009688",
                    }}
                  >
                    <Iconify
                      icon={"fluent:edit-24-filled"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "Edit",
                hidden:
                  !userCanEditCredit ||
                  (userTeamLeader && !contact.editable) ||
                  rowData.forfeiture_payment_history_id ||
                  rowData.coupon,
                disabled:
                  !userCanEditCredit || (userTeamLeader && !contact.editable),
                onClick: (event, rowData) => {
                  openInPopup(rowData);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <Controls.IconButton
                    style={{
                      color:
                        !userCanDeleteCredit ||
                        (userTeamLeader && !contact.editable)
                          ? "#bdbdbd"
                          : "#f44336",
                    }}
                  >
                    <Iconify
                      icon={"mingcute:delete-fill"}
                      width={20}
                      height={20}
                    />
                  </Controls.IconButton>
                ),
                tooltip: "Delete",
                hidden:
                  !userCanDeleteCredit || (userTeamLeader && !contact.editable),
                disabled:
                  !userCanDeleteCredit || (userTeamLeader && !contact.editable),
                onClick: (event, rowData) =>
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this credit memo?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => onDelete(rowData.id),
                  }),
              }),
            ]}
            options={{
              loadingType: "linear",
              pageSize: 20, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              actionsColumnIndex: -1,
              exportButton: true,
            }}
            title="Credit Memos"
          />
        </TblContainer>
      </Paper>

      <Popup
        fullWidth={true}
        title={
          creditMemoDetail
            ? `Credit Memo # ${creditMemoDetail.id}`
            : "New Credit Memo"
        }
        openPopup={openDetailPopup}
        onClose={closePopup}
        maxWidth={"xs"}
      >
        <CreditMemoForm
          creditMemoForEdit={creditMemoDetail}
          contact={contact}
          onSaveCreditMemo={saveCreditMemo}
          creditMemoReasons={creditMemoReasons}
        />
      </Popup>
      <Popup
        fullWidth={true}
        title={"New Credit Memo"}
        openPopup={openPopup}
        onClose={closeNewCreditMemoPopup}
        maxWidth={"xs"}
      >
        <CreditMemoForm
          contact={contact}
          onSaveCreditMemo={saveCreditMemo}
          creditMemoReasons={creditMemoReasons}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Wrapper>
  );
}

export default CreditMemosContact;
