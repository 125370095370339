import {
	Box,
	DialogActions,
	Grid,
	LinearProgress,
	Typography,
} from "@material-ui/core";
import Popup from "../../../components/Popup";
import { Controller, useForm } from "react-hook-form";
import Textarea from "../../../components/controls/Textarea";
import Button from "../../../components/controls/Button";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AttachFile, Delete } from "@material-ui/icons";
import IconButton from "../../../components/controls/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";
import moment from "moment";

const fileTypes = [
	{ name: "Image", mimeTypes: ["image/jpeg", "image/png", "image/gif"] },
	{ name: "PDF", mimeTypes: ["application/pdf"] },
	{
		name: "Word Document",
		mimeTypes: [
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		],
	},
	{
		name: "Excel Spreadsheet",
		mimeTypes: [
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		],
	},
	{
		name: "PowerPoint Presentation",
		mimeTypes: [
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		],
	},
	{
		name: "Audio File",
		mimeTypes: ["audio/mpeg", "audio/ogg", "audio/wav", "audio/webm"],
	},
	{
		name: "Video File",
		mimeTypes: ["video/mp4", "video/ogg", "video/webm"],
	},
	{
		name: "ZIP",
		mimeTypes: ["application/zip", "application/x-rar-compressed"],
	},
	{
		name: "Text File",
		mimeTypes: ["text/plain", "text/html", "text/css", "text/javascript"],
	},
	{ name: "CSV File", mimeTypes: ["text/csv"] },
	{ name: "JSON", mimeTypes: ["application/json"] },
	{ name: "XML", mimeTypes: ["application/xml", "text/xml"] },
	{
		name: "Font Files",
		mimeTypes: [
			"application/font-woff",
			"application/font-sfnt",
			"application/vnd.ms-fontobject",
		],
	},
];

const FileBox = ({
	filename,
	download = true,
	isdelete = false,
	onClickDelete,
	onClickDownload,
	progress = 0,
	fileSize = 0,
	fileMime = null,
}) => {
	const fileSizeString = useMemo(() => {
		const k = 1024;
		const dm = 2;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

		const i = Math.floor(Math.log(fileSize) / Math.log(k));
		return (
			parseFloat((fileSize / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
		);
	}, [fileSize]);

	const fileMimeString = useMemo(() => {
		return fileTypes.find((f) => f.mimeTypes.includes(fileMime))?.name;
	}, [fileMime]);

	return (
		<Box
			style={{
				height: 50,
				position: "relative",
				border: "1px solid #ccc",
				borderRadius: 5,
				backgroundColor: "#DDF",
				alignItems: "center",
				display: "flex",
				fontWeight: 600,
				color: "black",
			}}
		>
			{/* <Box
				style={{
					position: "absolute",
					height: "100%",
					left: 0,
					top: 0,
					backgroundColor: "lightblue",
					width: `${progress}%`,
				}}
			></Box> */}
			<Box
				style={{
					padding: 5,
					border: "1px solid #AAA",
					borderRadius: 5,
					marginLeft: 5,
				}}
			>
				<Typography variant="caption">{fileSizeString}</Typography>
			</Box>
			{Boolean(fileMimeString) && (
				<Box
					style={{
						padding: 5,
						border: "1px solid #AAA",
						borderRadius: 5,
						marginLeft: 5,
					}}
				>
					<Typography variant="caption">{fileMimeString}</Typography>
				</Box>
			)}
			<Typography
				style={{
					color: "black",
					marginLeft: "auto",
					marginRight: "auto",
					zIndex: 500,
				}}
			>
				{filename}
			</Typography>
			{download && (
				<IconButton onClick={onClickDownload}>
					<GetAppIcon />
				</IconButton>
			)}
			{isdelete && (
				<IconButton onClick={onClickDelete}>
					<Delete />
				</IconButton>
			)}
		</Box>
	);
};
const FileModal = ({
	open,
	onClose,
	loading,
	file = {},
	onStore,
	onDelete,
}) => {
	const {
		handleSubmit,
		control,
		errors,
		formState: { isDirty },
		setValue,
	} = useForm({
		defaultValues: file ?? {},
	});

	const fileData = useMemo(() => {
		if (file && file.data instanceof File) {
			return file.data;
		} else return file;
	}, [file]);

	const isFileToUpload = useMemo(() => {
		return Boolean(file?.data instanceof File);
	}, [file]);

	const fileProperties = useMemo(() => {
		if (file && file.custom_properties) {
			return file.custom_properties ?? {};
		} else {
			return file;
		}
	}, [file]);

	useEffect(() => {
		if (fileProperties) {
			setTimeout(() => {
				setValue("comment", fileProperties.comment);
			});
		} else {
			setTimeout(() => {
				setValue("comment", "");
			});
			setFileToUpload(null);
			// setFileUploaded(null);
		}
	}, [fileProperties]);

	// const [fileUploadProgress, setFileUploadProgress] = useState(0);

	// const uploadProgress = (progress) => {
	// 	// console.log(progress);
	// 	const percentCompleted = Math.round(
	// 		(progress.loaded * 100) / progress.total
	// 	);
	// 	// setFileUploadProgress((p) => percentCompleted);
	// };

	// const [fileUploading, setFileUploading] = useState(false);

	const handleSubmitEdit = async (data) => {
		onStore &&
			onStore({
				data: fileToUpload,
				custom_properties: data,
			});

		// setFileUploading(true);
		// try {
		// 	let f = await Api.fileUpload(fileToUpload, data);
		// 	setFileUploaded(f?.data);
		// 	setFileToUpload(null);
		// 	// setTimeout(() => {
		// 	//     setFileUploadProgress(0);
		// 	// }, 1000);
		// } catch (e) {
		// } finally {
		// 	setFileUploading(false);
		// }
	};

	const handleStore = async (data) => {
		onStore && onStore(fileToUpload);
	};

	// const [fileUploaded, setFileUploaded] = useState(null);
	const [fileToUpload, setFileToUpload] = useState(null);
	// console.log(fileUploaded);

	const isUpdate = useMemo(() => {
		return Boolean(fileData?.id);
	}, [fileData]);
	const isToUpload = useMemo(() => {
		return Boolean(fileToUpload);
	}, [fileToUpload]);
	// const isUploaded = useMemo(() => {
	// 	return Boolean(fileUploaded);
	// }, [fileUploaded]);

	const onDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		if (acceptedFiles.length > 0) {
			setFileToUpload(acceptedFiles[0]);
			// let f = await Api.fileUpload(acceptedFiles[0])
			// setFileUploaded(f)
		}

		// setAttachments((at) => [...at, ...acceptedFiles]);
	};

	const {
		getRootProps,
		getInputProps,
		open: fileOpen,
		isDragActive,
	} = useDropzone({ onDrop, maxFiles: 1 });

	const onClickDownload = (path) => {
		event.stopPropagation();
		saveAs(path);
	};

	const cancelFileUpload = () => {
		setFileToUpload(null)
	};

	const deleteStoredFile = (id) => {
		onDelete && onDelete()
	};

	return (
		<Popup
			openPopup={open}
			title={"File"}
			onClose={onClose}
			isfullWidth={true}
		>
			{false ? (
				<LinearProgress />
			) : (
				<form onSubmit={handleSubmit(handleStore)}>
					<Grid item container spacing={2} xs={12}>
						<Grid item xs={12}>
                                {isUpdate && (
                                    <Box display='flex' flexDirection='column'>
								<FileBox
									filename={fileData?.name}
									download={true}
									onClickDownload={() =>
										onClickDownload(file.url)
									}
									fileSize={fileData.size}
									isdelete={false}
									fileMime={fileData.mime_type}
									onClickDelete={cancelFileUpload}
                                        />
                                        <Box display='flex' flexDirection='row' alignItems='center' gridGap={12}
                                            style={{
                                                background: 'black',
                                                padding: 5,
                                                borderRadius: 5,
                                                marginTop: 5,
                                                color: 'white',
                                                paddingLeft: 10
                                        }}>
                                            <Typography>Uploaded by:</Typography>
                                            <Typography variant="subtitle" >{fileData?.user?.dropdown_name}</Typography>
                                            <Typography>On:</Typography>
                                            <Typography variant="subtitle" >{moment(fileData.created_at).format('DD/MM/yyyy HH:mm')}</Typography>
                                            </Box>
                                        </Box>
                                )}
                                {isFileToUpload && (
								<FileBox
									filename={fileData?.name}
									download={false}
									// onClickDownload={() =>
									// 	onClickDownload(file.path)
									// }
									fileSize={fileData.size}
									isdelete={true}
									fileMime={fileData.type}
									onClickDelete={deleteStoredFile}
								/>
							)}
							{isToUpload && (
								<FileBox
									filename={fileToUpload.name}
									download={false}
									isdelete={true}
									// progress={fileUploadProgress}
									onClickDelete={cancelFileUpload}
									fileSize={fileToUpload.size}
									fileMime={fileToUpload.type}
								/>
							)}
							{/* {isUploaded && (
								<FileBox
									filename={fileUploaded.filename}
									download={true}
									isdelete={true}
									onClickDownload={() =>
										onClickDownload(fileUploaded.path)
									}
									onClickDelete={() =>
										deleteStoredFile(fileUploaded.id)
									}
									fileSize={fileUploaded.size}
									fileMime={fileUploaded.mime_type}
								/>
							)} */}
							{!(isUpdate || isFileToUpload) && !isToUpload && (
								<Box
									{...getRootProps()}
									style={{
										height: 100,
										border: isDragActive
											? "3px dashed black"
											: "3px dashed #999",
										borderRadius: 5,
										backgroundColor: isDragActive
											? "#DDF"
											: "transparent",
										alignItems: "center",
										display: "flex",
										fontWeight: 600,
										color: isDragActive ? "black" : "#AAA",
									}}
								>
									<input {...getInputProps()} />
									<AttachFile
										style={{
											marginLeft: "auto",
											marginRight: "auto",
											textAlignLast: "center",
										}}
									/>
								</Box>
							)}
						</Grid>
						<Grid item md={12}>
							<Controller
								name="comment"
								control={control}
								rules={{
									required: {
										value: true,
										message: "Comment is required",
									},
								}}
								render={({ onChange, onBlur, value }) => (
									<Textarea
										label="Comment"
										name="comment"
										variant="outlined"
										value={value ?? ""}
										onChange={onChange}
										onBlur={onBlur}
										error={errors.comment}
										helperText={errors.comment?.message}
										style={{ width: "100%" }}
										disabled={isUpdate || isFileToUpload}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<DialogActions>
						{/* {!isUpdate && Boolean(fileToUpload) && (
							<Button
								text="Upload"
								onClick={handleSubmit(handleSubmitEdit)}
								// disabled={fileUploading}
							/>
						)} */}
						{fileToUpload && (
							<Button
								text="Save"
								onClick={handleSubmit(handleSubmitEdit)}
							/>
						)}
						{(isUpdate || isFileToUpload) && (
							<Button
								text="Close"
								onClick={onClose}
								// disabled={fileUploading}
								// disabled={updatingOrCreating}
							/>
						)}
					</DialogActions>
					{loading && <LinearProgress />}
				</form>
			)}
		</Popup>
	);
};

export default FileModal;
