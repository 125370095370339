import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  List,
  ListItemText,
  ListItem,
  Divider,
  Card,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguagesService } from "../../redux/slices/languages";
import { isEmpty } from "lodash";
import Api from "../../lib/api";
import BMI from "../BMI";
import AddIcon from "@material-ui/icons/Add";
import ExtrafieldForm from "./ExtrafieldForm";
import Popup from "../../components/Popup";
import CRMUtils from "../../utils";
import moment from "moment";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { debounce } from "lodash";
import { withStyles } from "@material-ui/styles";
import Iconify from "../Iconify";
import ConfirmDialog from "../ConfirmDialog";
import Notification from "../Notification";

const googleMapAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  extraWrapper: {
    backgroundColor: "#f2f2f2",
    padding: "2px 4px",
  },
  error: {
    color: "red",
    fontWeight: "700",
    fontSize: "1.1em",
  },

  cardPlaces: {
    backgroundColor: "white",
    zIndex: "999999",
    position: "fixed",
    boxShadow: "rgba(53, 64, 82, 0.25) 0px 0px 14px 0px",
  },

  phoneWarningContainer: {
    padding: "2px 8px",
    backgroundColor: "rgb(251 246 202)",
    color: "#252525",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    borderRadius: 10,
    maxWidth: "fit-content",
  },
  phoneWarningIcon: {
    marginRight: 3,
    color: "#bfb100",
    fontSize: 17,
  },
  phoneWarningText: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

function DuplicateStatus(props) {
  const classes = useStyles();
  const { phone, ...other } = props;

  const checkDuplicatePhone = async (value) => {
    const { data } = await Api.checkDuplicate("phone", value);

    if (data === 1) {
      return "duplicated";
    }
    return null;
  };

  return <div></div>;
}

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

export default function ContactForm(props) {
  const { authUser } = useSelector((state) => state.auth);
  const { contactsN, contactsNErrors } = useSelector(
    (state) => state.contactsN
  );

  const dispatch = useDispatch();
  const {
    addOrEdit,
    contactForEdit,
    tags,
    customFields,
    coordinators,
    buildings,
    saveLoading,
    userCanEdit = true,
    user,
    setOpenSendFormTemplate,
    getContact = null,
    userTeamLeader = false,
    onSaveExtraField,
    setContactForEdit,
    error,
  } = props;
  const { handleSubmit, setValue, reset, watch, control, errors, register } =
    useForm();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleMapAPI,
    options: {
      types: ["address"],
      /*componentRestrictions: {
        country: ["us", "ca", "mx", "pr", "bs", "ht", "jm", "es", "gb"],
      },*/
    },
    language: "en-US",
  });
  const [placePredictionsLocal, setPlacePredictionsLocal] = useState([]);

  const [valueP, setValueP] = useState("");

  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1500),
    []
  );

  const setSearch = (search) => {
    getPlacePredictions({ input: search });
    setValueP(search);
  };

  const watchWeight = watch("weight");
  const watchHeight = watch("height");
  const [bmi, setBmi] = useState(null);
  const [phone, setPhone] = useState(null);
  const { contactsnewErrors } = useSelector((state) => state.contactsnew);
  const [errorFlag, setErrorFlag] = useState(false);
  const { languages } = useSelector((state) => state.languages);
  const [consultants, setConsultants] = useState([]);
  const [sources, setSources] = useState([]);
  const [idTypes, setIdTypes] = useState([]);
  const [completeAddress, setCompleteAddress] = useState(null);
  const [description, setDescription] = useState(
    contactForEdit?.description || null
  );
  const [openExtraPopup, setOpenExtraPopup] = useState(false);
  const [extraType, setExtraType] = useState(null);
  const closeExtraPopup = () => {
    setOpenExtraPopup(false);
    setExtraType(null);
  };
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  /*
  const checkDuplicatePhone = async (value) => {
    const result = Api.checkDuplicate("phone", value);
    if (result === 1) {
      setDuplicatedPhone(true);
    } else {
      setDuplicatedPhone(false);
    }
  };
  */
  const userCanEditAll = CRMUtils.userHasPermission(authUser, [
    "contacts_editall",
  ]);
  const userCanchangeSource = CRMUtils.userHasPermission(authUser, [
    "contacts_changesource",
  ]);
  const userIsSalesManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["user_salesGroupManager"])
      ? true
      : false;

  const userIsCallCenterBasic =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterBasic"])
      ? true
      : false;

  const userIsFrontDeskBasic =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskBasic"])
      ? true
      : false;

  const userIsSocialMediaBasic =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaBasic"])
      ? true
      : false;

  const userIsCallCenterManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterManager"])
      ? true
      : false;

  const userIsFrontDeskManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskManager"])
      ? true
      : false;

  const userIsSocialMediaManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaManager"])
      ? true
      : false;
  const [age, setAge] = useState(null);
  const readOnlyComponent =
    !userCanEdit ||
    (authUser.is_coordinator &&
      contactForEdit &&
      !contactForEdit.editable &&
      !userCanEditAll) ||
    (userIsSalesManager &&
      contactForEdit &&
      !contactForEdit.editable &&
      !userCanEditAll);

  const userBuilding =
    (contactForEdit &&
      CRMUtils.userHasPermission(authUser, ["contacts_assignbuilding"])) ||
    userIsCallCenterManager ||
    userIsFrontDeskManager ||
    userIsSocialMediaManager ||
    (!contactForEdit &&
      (CRMUtils.userHasPermission(authUser, ["contacts_createwithbuilding"]) ||
        CRMUtils.userHasPermission(authUser, ["contacts_assignbuilding"])))
      ? true
      : false;
  const userCanUnmarkPhoneAsInvalid =
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalid"]) ||
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalidsuper"]);
  const financingOptions = () => {
    return [
      {
        id: 0,
        title: "No",
      },
      {
        id: 1,
        title: "Yes",
      },
    ];
  };

  const languageOptionsPlain = (languages) => {
    return languages.map((language) => language.id);
  };
  const languageOptions = (languages) => {
    return languages.map((language) => {
      return {
        id: language.id,
        title: language.name.replace(/^\w/, (c) => c.toUpperCase()),
      };
    });
  };
  const updateUserData = async (building_id) => {
    const { data } = await Api.getUsersBuilding(
      authUser.organization_id,
      building_id
    );
    setConsultants(
      data.data
        .filter((user) => {
          return user.is_salesman === 1 || user.is_fake_salesman === 1;
        })
        .map((user) => {
          return {
            id: user.id,
            title: !isEmpty(user.groups.data)
              ? user.title + "  (" + user.groups?.data[0]?.name + ")"
              : user.title,
          };
        })
    );
  };

  const setAddressValues = (item) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => {
        setCompleteAddress(placeDetails);
      }
    );
  };

  const priorityOptions = () => {
    return [
      {
        id: 1,
        title: "Very High Priority",
      },
      {
        id: 2,
        title: "High Priority",
      },
      {
        id: 3,
        title: "Medium Priority",
      },
      {
        id: 4,
        title: "Low Priority",
      },
      {
        id: 5,
        title: "Very Low Priority",
      },
      {
        id: 21,
        title: "Default Priority",
      },
    ];
  };

  const classes = useStyles();
  const userCanAssignLead = () => {
    if (
      contactForEdit &&
      !CRMUtils.userHasPermission(user, ["contacts_assign"])
    ) {
      return false;
    }

    return true;
  };

  const SourceOptions = () => {
    return sources.map((source) => {
      return {
        id: source.id_name,
        title: source.name,
      };
    });
  };

  const maritalStatusOptions = () => {
    return [
      { id: "single", title: "Single" },
      { id: "married", title: "Married" },
      { id: "widowed", title: "Widowed" },
      { id: "divorced", title: "Divorced" },
      { id: "domestic-partnership", title: "Domestic Partnership" },
    ];
  };

  const onSubmit = async (data) => {
    setErrorFlag(true);
    const contactData = formatContactData(data);
    await addOrEdit(contactData);
    getContact ? getContact() : "";

    setOnSubmitClick(true);
  };

  const formatContactData = (data) => {
    let contactData = {};
    let contactProperties = {};
    Object.keys(data).forEach((key) => {
      const xk = key.slice(0, 3);
      if (xk !== "cf_") {
        contactData[key] = data[key];
      } else {
        const fixedKey = key.slice(3, key.length);
        contactProperties[fixedKey] = data[key];
      }
    });
    contactData.properties = JSON.stringify(contactProperties);
    if (bmi) {
      contactData.bmi = bmi;
    }
    if (description) {
      contactData.description = description;
    }
    return contactData;
  };

  const tagOptions = (tags) => {
    return tags.map((tag) => tag.tagname);
  };
  const renderCustomFields = () => {
    return customFields.map((field) => {
      return <div key={field.key}>{renderCustomField(field)}</div>;
    });
  };

  const getSources = async (orgID) => {
    const { data } = contactForEdit
      ? await Api.getAllSourcesDropdown(orgID)
      : await Api.getManualSourcesDropdown(orgID);

    setSources(data.data);
  };

  const getIDTypes = async () => {
    const { data } = await Api.getIDTypes();

    setIdTypes([...data]);
  };

  useEffect(() => {
    const orgID = authUser.organization_id;
    if (isEmpty(languages)) {
      dispatch(fetchLanguagesService(orgID));
    }
    getIDTypes();
    getSources(orgID);

    if (contactForEdit && contactForEdit.dob) {
      getAgeByDOB(moment(contactForEdit.dob).format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(placePredictions)) {
      setPlacePredictionsLocal(placePredictions);
    }
  }, [placePredictions]);

  useEffect(() => {
    if (!isEmpty(contactForEdit?.owner?.data)) {
      setConsultants([
        {
          id: contactForEdit?.owner?.data?.id,
          title: `${contactForEdit?.owner?.data?.first_name} ${contactForEdit?.owner?.data?.last_name}`,
        },
        ...coordinators,
      ]);
    } else {
      setConsultants(coordinators);
    }
  }, [coordinators, contactForEdit]);

  useEffect(() => {
    try {
      if (completeAddress) {
        for (const component of completeAddress.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "postal_code": {
              setValue("zip", component.long_name, {
                shouldValidate: true,
              });
              break;
            }
            case "locality":
              setValue("city_field", component.long_name, {
                shouldValidate: true,
              });
              break;
            case "administrative_area_level_1": {
              setValue("state_field", component.long_name, {
                shouldValidate: true,
              });

              break;
            }
            case "country":
              setValue("country_field", component.long_name, {
                shouldValidate: true,
              });

              break;
          }
        }
        setValue("address", completeAddress.name, {
          shouldValidate: true,
        });

        setPlacePredictionsLocal([]);
      }
    } catch {
      console.error("completeAddress error");
    }
  }, [completeAddress]);

  useEffect(() => {}, [onSubmitClick]);
  useEffect(() => {
    if (contactsNErrors.length > 0) {
      setOnSubmitClick(false);
      setNotify({
        isOpen: true,
        message: contactsNErrors[contactsNErrors.length - 1],
        type: "error",
      });
    }
  }, [contactsNErrors]);

  const renderCustomField = (field) => {
    let defaultValue = "";
    if (!isEmpty(contactForEdit) && !isEmpty(contactForEdit.properties)) {
      const properties = JSON.parse(contactForEdit.properties);
      defaultValue = properties[field.key];
    }
    switch (field.type) {
      case "string":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.Input label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "yes/no":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.RadioGroup
                  label={field.title}
                  items={[
                    {
                      id: "yes",
                      title: "YES",
                    },
                    {
                      id: "no",
                      title: "NO",
                    },
                  ]}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "money":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.InputMoney label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "date/time":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.DateTimePicker label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "date":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.DatePicker label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "list":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.Select
                  label={field.title}
                  options={getFieldSource(field.source)}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{ required: "field is required" }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "relation":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.SelectModel
                  label={field.title}
                  modelid={field.source_model_id}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{ required: "field is required" }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      default:
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.Input label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
    }
  };

  const getFieldSource = (source) => {
    const list = source ? JSON.parse(source) : [];
    return list.map((value) => {
      return {
        id: value.id,
        title: value.value,
      };
    });
  };

  const renderExtraPhones = () => {
    if (!contactForEdit) return null;
    return contactForEdit.multifields.data
      .filter((field) => {
        return field.type == "phone";
      })
      .map((field) => {
        return `${field.value}, `;
      });
  };
  const renderExtraEmails = () => {
    if (!contactForEdit) return null;
    return contactForEdit.multifields.data
      .filter((field) => {
        return field.type == "email";
      })
      .map((field) => {
        return `${field.value}, `;
      });
  };

  const openExtraPopupType = (type) => {
    setExtraType(type);
    setOpenExtraPopup(true);
  };

  const addOrEditExtra = async (payload) => {
    payload.type = extraType;
    onSaveExtraField(payload);
    setOpenExtraPopup(false);
  };

  const getIdTypesOptions = () => {
    if (!idTypes) return [];
    return idTypes.map((type) => {
      return {
        id: type.id,
        title: type.name,
      };
    });
  };

  useEffect(() => {
    if (errorFlag)
      setTimeout(() => {
        setErrorFlag(false);
      }, 20000);
  }, [errorFlag]);

  const getAgeByDOB = (dob) => {
    if (dob) {
      const current_year = moment(Date.now()).year();
      const dob_age = moment(dob).year();

      var age_number = current_year - dob_age;

      if (
        !moment(moment(Date.now()).format("MM-DD")).isAfter(
          moment(dob).format("MM-DD")
        ) &&
        !moment(moment(Date.now()).format("MM-DD")).isSame(
          moment(dob).format("MM-DD")
        )
      ) {
        age_number = age_number - 1;
      }

      setAge(age_number);
    }
  };

  const onUnmarkPhoneAsInvalid = async () => {
    try {
      setConfirmDialogLoading(true);
      await Api.unmarkPhoneNumberAsInvalidForMessaging(contactForEdit.phone);

      setNotify({
        isOpen: true,
        message: "Successfully unmarked!",
        type: "success",
      });

      if (setContactForEdit) {
        setContactForEdit({
          ...contactForEdit,
          ...{ invalid_phone_number_for_messaging: false },
        });
      }
    } catch (err) {
      if (
        (err.status == 401 || err.status == 422) &&
        err.data &&
        err.data.error
      ) {
        setNotify({
          isOpen: true,
          message: err.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setConfirmDialogLoading(false);
      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        <Grid item xs={6}>
          <ListItemText
            primary="* Required field: New contact"
            style={{ fontStyle: "italic" }}
          />
        </Grid>
        <Grid item xs={6}>
          <ListItemText
            primary="** Required field: Quote / Invoice"
            style={{ fontStyle: "italic" }}
          />
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <div style={{ padding: 20, marginTop: -10 }}>
          <Grid container spacing="4">
            <Grid item xs={!authUser.organization.data.contact_simple ? 6 : 12}>
              <Grid container spacing="2">
                <Grid item xs={6}>
                  {" "}
                  <Controller
                    name="fname"
                    type="text"
                    control={control}
                    defaultValue={contactForEdit?.fname || ""}
                    as={
                      <Controls.Input
                        label="* First name"
                        disabled={
                          // (contactForEdit &&
                          //   !isEmpty(contactForEdit.fraudolent.data)) ||
                          !userCanEdit ||
                          (authUser.is_coordinator &&
                            contactForEdit &&
                            !contactForEdit.editable &&
                            !userCanEditAll) ||
                          (userIsSalesManager &&
                            contactForEdit &&
                            contactForEdit.building_id !=
                              authUser.building_id &&
                            !userCanEditAll) ||
                          ((userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager) &&
                            contactForEdit &&
                            contactForEdit.building_id !=
                              authUser.building_id &&
                            !userCanEditAll)
                        }
                      />
                    }
                    rules={{
                      required: "Contact first name is required",
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z\s]*$/i,
                        message:
                          "Contact's first name can only accept characters",
                      },
                    }}
                  />
                  {errors.fname && (
                    <p className={classes.error}>{errors.fname.message}</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {" "}
                  <Controller
                    name="lname"
                    type="text"
                    control={control}
                    defaultValue={contactForEdit?.lname || ""}
                    as={
                      <Controls.Input
                        label="* Last name"
                        disabled={
                          // (contactForEdit &&
                          //   !isEmpty(contactForEdit.fraudolent.data)) ||
                          !userCanEdit ||
                          (authUser.is_coordinator &&
                            contactForEdit &&
                            !contactForEdit.editable &&
                            !userCanEditAll) ||
                          (userIsSalesManager &&
                            contactForEdit &&
                            contactForEdit.building_id !=
                              authUser.building_id &&
                            !userCanEditAll) ||
                          ((userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager) &&
                            contactForEdit &&
                            contactForEdit.building_id !=
                              authUser.building_id &&
                            !userCanEditAll)
                        }
                      />
                    }
                    rules={{
                      required: "Contact last name is required",
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z\s]*$/i,
                        message:
                          "Contact's last name can only accept characters",
                      },
                    }}
                  />
                  {errors.lname && (
                    <p className={classes.error}>{errors.lname.message}</p>
                  )}
                </Grid>
              </Grid>

              <Controller
                name="email"
                control={control}
                type="email"
                defaultValue={contactForEdit?.email || ""}
                as={
                  <Controls.Input
                    label="Email"
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent ||
                      ((userIsCallCenterBasic ||
                        userIsCallCenterManager ||
                        userIsFrontDeskBasic ||
                        userIsFrontDeskManager ||
                        userIsSocialMediaBasic ||
                        userIsSocialMediaManager) &&
                        contactForEdit &&
                        contactForEdit.building_id != authUser.building_id &&
                        !userCanEditAll)
                    }
                  />
                }
                rules={{
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,8})+$/i,
                    message: "invalid email address",
                  },
                }}
              />
              {contactForEdit && !contactForEdit.email && (
                <p style={{ color: "red", fontSize: 13 }}>
                  {"***Please contact the patient to ask for email***"}
                </p>
              )}
              {errors.email && (
                <p className={classes.error}>{errors.email.message}</p>
              )}

              {contactForEdit && contactForEdit.email && (
                <Box className={classes.extraWrapper}>
                  {renderExtraEmails()}
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => openExtraPopupType("email")}
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent ||
                      ((userIsCallCenterBasic ||
                        userIsCallCenterManager ||
                        userIsFrontDeskBasic ||
                        userIsFrontDeskManager ||
                        userIsSocialMediaBasic ||
                        userIsSocialMediaManager) &&
                        contactForEdit &&
                        contactForEdit.building_id != authUser.building_id &&
                        !userCanEditAll)
                    }
                  >
                    Add
                  </Button>
                </Box>
              )}
              <Controller
                name="phone"
                control={control}
                defaultValue={contactForEdit?.phone || ""}
                as={({ onChange, value, onBlur }) => (
                  <Controls.Input
                    label="Phone"
                    type="number"
                    onChange={onChange}
                    value={value}
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent ||
                      ((userIsCallCenterBasic ||
                        userIsCallCenterManager ||
                        userIsFrontDeskBasic ||
                        userIsFrontDeskManager ||
                        userIsSocialMediaBasic ||
                        userIsSocialMediaManager) &&
                        contactForEdit &&
                        contactForEdit.building_id != authUser.building_id &&
                        !userCanEditAll)
                    }
                    onBlur={(e) => {
                      setPhone(e.target.value);
                      //checkDuplicatePhone(e.target.value)
                    }}
                  />
                )}
                rules={
                  {
                    // required: "Contact Phone is required",
                    /* pattern: {
                  value: /^\d{2}(?: ?\d+)*$/i,
                  message: "Contact's phone can only accept numbers",
                }, */
                  }
                }
              />
              {contactForEdit &&
              contactForEdit.invalid_phone_number_for_messaging ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <div className={classes.phoneWarningContainer}>
                    <Iconify
                      icon="ic:round-warning"
                      className={classes.phoneWarningIcon}
                    />
                    <Typography className={classes.phoneWarningText}>
                      Phone not valid for messaging
                    </Typography>
                  </div>
                  {userCanUnmarkPhoneAsInvalid ? (
                    <Tooltip title="Unmark phone as invalid">
                      <span>
                        <IconButton
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                "Are you sure to unmark the phone " +
                                contactForEdit.phone +
                                " as invalid?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => onUnmarkPhoneAsInvalid(),
                            });
                          }}
                          style={{ padding: 4, marginLeft: 2 }}
                        >
                          <Iconify
                            style={{ color: "#527493", width: 20, height: 20 }}
                            icon="basil:phone-off-solid"
                          />
                        </IconButton>{" "}
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {contactForEdit && contactForEdit.is_phone_invalid_for_voice ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <div className={classes.phoneWarningContainer}>
                    <Iconify
                      icon="ic:round-warning"
                      className={classes.phoneWarningIcon}
                    />
                    <Typography className={classes.phoneWarningText}>
                      Phone not valid for calls
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}
              <DuplicateStatus phone={phone} />
              {errors.phone && (
                <p className={classes.error}>{errors.phone.message}</p>
              )}
              {contactForEdit && contactForEdit.phone && (
                <Box className={classes.extraWrapper}>
                  {renderExtraPhones()}
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => openExtraPopupType("phone")}
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent ||
                      ((userIsCallCenterBasic ||
                        userIsCallCenterManager ||
                        userIsFrontDeskBasic ||
                        userIsFrontDeskManager ||
                        userIsSocialMediaBasic ||
                        userIsSocialMediaManager) &&
                        contactForEdit &&
                        contactForEdit.building_id != authUser.building_id &&
                        !userCanEditAll)
                    }
                  >
                    Add
                  </Button>
                </Box>
              )}

              <Grid container spacing="2">
                <Grid item xs={6}>
                  {!authUser.organization.data.contact_simple && (
                    <Controller
                      name="weight"
                      type="number"
                      control={control}
                      defaultValue={contactForEdit?.weight || ""}
                      as={
                        <Controls.Input
                          label="** Weight - pounds"
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent ||
                            userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager
                          }
                        />
                      }
                    />
                  )}
                  {errors.weight && (
                    <p className={classes.error}>{errors.weight.message}</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!authUser.organization.data.contact_simple && (
                    <Controller
                      name="height"
                      type="number"
                      control={control}
                      defaultValue={contactForEdit?.height || ""}
                      as={
                        <Controls.Input
                          label="** Height - ft.in"
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent ||
                            userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager
                          }
                        />
                      }
                    />
                  )}
                  {errors.height && (
                    <p className={classes.error}>{errors.height.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing="2">
                <Grid item xs={6}>
                  {!authUser.organization.data.contact_simple && (
                    <Controller
                      name="id_number"
                      control={control}
                      defaultValue={contactForEdit?.id_number || ""}
                      as={
                        <Controls.Input
                          label="ID Number"
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent ||
                            userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager
                          }
                        />
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!authUser.organization.data.contact_simple && (
                    <Controller
                      name="id_type"
                      control={control}
                      defaultValue={contactForEdit?.id_type || ""}
                      as={({ onChange, value }) => (
                        <Controls.Select
                          label="ID Type"
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent ||
                            userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager
                          }
                          options={getIdTypesOptions()}
                          value={value}
                          onChange={(value) => onChange(value)}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              {userBuilding && !authUser.organization.data.contact_simple && (
                <Controller
                  name="building_id"
                  control={control}
                  defaultValue={contactForEdit?.building_id}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label="Building"
                      disabled={
                        // (contactForEdit &&
                        //   !isEmpty(contactForEdit.fraudolent.data)) ||
                        readOnlyComponent ||
                        (contactForEdit &&
                          (userIsCallCenterBasic ||
                            userIsFrontDeskBasic ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager))
                      }
                      options={buildings || []}
                      onChange={(value) => {
                        onChange(value);
                        updateUserData(value.target.value);
                      }}
                      value={value}
                    />
                  )}
                />
              )}

              {userCanAssignLead() ||
              userIsCallCenterBasic ||
              userIsCallCenterManager ||
              userIsFrontDeskBasic ||
              userIsFrontDeskManager ||
              userIsSocialMediaBasic ||
              userIsSocialMediaManager ? (
                <Controller
                  name="user_id"
                  control={control}
                  defaultValue={contactForEdit?.user_id}
                  as={({ onChange, value }) => (
                    <Controls.Select
                      label="Consultant"
                      disabled={
                        // (contactForEdit &&
                        //   !isEmpty(contactForEdit.fraudolent.data)) ||
                        !userCanEdit ||
                        (authUser.is_coordinator &&
                          contactForEdit &&
                          !contactForEdit.editable &&
                          !userCanEditAll) ||
                        (userIsSalesManager &&
                          contactForEdit &&
                          !contactForEdit.editable &&
                          !userCanEditAll) ||
                        ((userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager) &&
                          contactForEdit &&
                          !contactForEdit.editable &&
                          !userCanEditAll)
                      }
                      options={consultants}
                      onChange={(value) => onChange(value)}
                      value={value}
                    />
                  )}
                />
              ) : (
                ""
              )}

              <Controller
                name="source"
                control={control}
                defaultValue={contactForEdit?.source || "Call Center"}
                as={({ onChange, value }) => (
                  <Controls.Select
                    label="Source"
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      contactForEdit && !userCanEditAll && !userCanchangeSource
                    }
                    options={SourceOptions()}
                    onChange={(value) => onChange(value)}
                    value={value}
                  />
                )}
              />
              {errors.source && (
                <p className={classes.error}>{errors.source.message}</p>
              )}

              {/*
  
      <Controller
      name="source"
      control={control}
      defaultValue={contactForEdit?.source || ""}
      as={<Controls.Input label="Source" />}
      rules={{ required: "source is required" }}
      />
    */}
              {!authUser.organization.data.contact_simple && (
                <Controls.Textarea
                  value={description}
                  disabled={
                    // (contactForEdit &&
                    //   !isEmpty(contactForEdit.fraudolent.data)) ||
                    readOnlyComponent ||
                    userIsCallCenterBasic ||
                    userIsCallCenterManager ||
                    userIsFrontDeskBasic ||
                    userIsFrontDeskManager ||
                    userIsSocialMediaBasic ||
                    userIsSocialMediaManager
                  }
                  label="Description"
                  rows="5"
                  onChange={(value) => {
                    //console.error(value.target.value);
                    setDescription(value.target.value);
                  }}
                />
              )}

              {authUser &&
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.contact_validation ? (
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  labelPlacement="end"
                  control={
                    <Controller
                      name="validated"
                      control={control}
                      defaultValue={
                        contactForEdit && contactForEdit.validated ? 1 : 0
                      }
            
                      render={({ onChange, value }) => (
                        <SSwitch
                        disabled={contactForEdit && !contactForEdit.address}
                          checked={value === 1 ? true : false}
                          onChange={(value) =>
                            onChange(value.target.checked ? 1 : 0)
                          }
                        />
                      )}
                    />
                  }
                  label={<ListItemText primary="Validate information**" />}
                />
              ) : (
                ""
              )}

              <Controller
                name="tags"
                defaultValue={tagOptions(contactForEdit?.tags.data || [])}
                control={control}
                as={({ onChange, value }) => (
                  <Controls.AutocompleteTagsD
                    label="Tags"
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent &&
                      !userIsCallCenterBasic &&
                      !userIsCallCenterManager &&
                      !userIsFrontDeskBasic &&
                      !userIsFrontDeskManager &&
                      !userIsSocialMediaBasic &&
                      !userIsSocialMediaManager
                    }
                    defaultValue={tagOptions(contactForEdit?.tags.data || [])}
                    options={tagOptions(tags)}
                    value={value}
                    onChange={(value) => onChange(value)}
                    tags={tags}
                  />
                )}
              />
              <Grid container spacing="3">
                <Grid item xs={12}>
                  {!authUser.organization.data.contact_simple && (
                    <Grid style={{ width: "100%" }} container spacing="4">
                      <Grid item xs={4}>
                        <Controller
                          name="Influencer"
                          control={control}
                          defaultValue={contactForEdit?.Influencer || 0}
                          as={({ value, onChange }) => (
                            <Controls.Checkbox
                              label="Influencer"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent
                              }
                              checked={value === 1 ? true : false}
                              onChange={(value) => onChange(value ? 1 : 0)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          style={{ marginLeft: 10 }}
                          name="VIP"
                          control={control}
                          defaultValue={contactForEdit?.VIP || 0}
                          as={({ value, onChange }) => (
                            <Controls.Checkbox
                              label="VIP"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                              checked={value === 1 ? true : false}
                              onChange={(value) => onChange(value ? 1 : 0)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          style={{ marginLeft: -10 }}
                          name="SuperVIP"
                          control={control}
                          defaultValue={contactForEdit?.SuperVIP || 0}
                          as={({ value, onChange }) => (
                            <Controls.Checkbox
                              label="Super VIP"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                              checked={value === 1 ? true : false}
                              onChange={(value) => onChange(value ? 1 : 0)}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!authUser.organization.data.contact_simple && (
              <Grid style={{ marginTop: -9 }} item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    {
                      <BMI
                        height={watchHeight}
                        weight={watchWeight}
                        setBmiform={setBmi}
                      />
                    }
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <Controller
                        name="address"
                        control={control}
                        defaultValue={contactForEdit?.address || ""}
                        as={({ onChange, value }) => (
                          <TextField
                            variant="outlined"
                            value={value}
                            onChange={(data) => {
                              debouncedSearch(data.target.value);
                              onChange(data.target.value);
                            }}
                            label={
                              authUser &&
                              authUser.organization &&
                              authUser.organization.data &&
                              authUser.organization.data.contact_validation
                                ? "Address **"
                                : "Address"
                            }
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="apt"
                        control={control}
                        defaultValue={contactForEdit?.apt || ""}
                        as={
                          <Controls.Input
                            label="APT"
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  {
                    <Grid item xs={12}>
                      {!isPlacePredictionsLoading &&
                      !isEmpty(placePredictionsLocal) ? (
                        <Card className={classes.cardPlaces}>
                          <Box m={1}>
                            <List>
                              <Divider component="li" />
                              {placePredictionsLocal.map((item, index) => {
                                return (
                                  <React.Fragment key={item.id}>
                                    <ListItem
                                      role={undefined}
                                      dense
                                      button
                                      onClick={() => {
                                        setAddressValues(item);
                                      }}
                                    >
                                      <ListItemText
                                        id={item.index}
                                        primary={item.description}
                                      />
                                    </ListItem>
                                    <Divider component="li" />
                                  </React.Fragment>
                                );
                              })}
                            </List>
                          </Box>
                        </Card>
                      ) : (
                        ""
                      )}
                    </Grid>
                  }

                  <Grid container spacing="4">
                    <Grid item xs={6}>
                      <Controller
                        name="city_field"
                        control={control}
                        disabled
                        defaultValue={contactForEdit?.city || ""}
                        as={({ onChange, value }) => (
                          <TextField
                            variant="outlined"
                            value={value}
                            onChange={(data) => onChange(data.target.value)}
                            label="City"
                            inputProps={{
                              maxLength: 5,
                            }}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="state_field"
                        control={control}
                        defaultValue={contactForEdit?.state || ""}
                        as={({ onChange, value }) => (
                          <TextField
                            variant="outlined"
                            value={value}
                            onChange={(data) => onChange(data.target.value)}
                            label="State"
                            inputProps={{
                              maxLength: 5,
                            }}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="country_field"
                        control={control}
                        defaultValue={contactForEdit?.country || ""}
                        as={({ onChange, value }) => (
                          <TextField
                            variant="outlined"
                            value={value}
                            onChange={(data) => onChange(data.target.value)}
                            label="Country"
                            inputProps={{
                              maxLength: 5,
                            }}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="zip"
                        control={control}
                        // type="number"
                        defaultValue={contactForEdit?.zip || ""}
                        as={({ onChange, value }) => (
                          <TextField
                            variant="outlined"
                            value={value}
                            onChange={(data) => onChange(data.target.value)}
                            label="Zipcode"
                            inputProps={{
                              maxLength: 5,
                            }}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Controller
                    name="medical_conditions"
                    control={control}
                    defaultValue={contactForEdit?.medical_conditions || ""}
                    as={
                      <Controls.Textarea
                        label="** Medical Conditions"
                        rows="4"
                        disabled={
                          // (contactForEdit &&
                          //   !isEmpty(contactForEdit.fraudolent.data)) ||
                          readOnlyComponent ||
                          userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager
                        }
                      />
                    }
                  />
                  {errors.medical_conditions && (
                    <p className={classes.error}>
                      {errors.medical_conditions.message}
                    </p>
                  )}
                  <Controller
                    name="surgical_history"
                    control={control}
                    defaultValue={contactForEdit?.surgical_history || ""}
                    as={
                      <Controls.Textarea
                        label="** Surgical History"
                        rows="4"
                        disabled={
                          // (contactForEdit &&
                          //   !isEmpty(contactForEdit.fraudolent.data)) ||
                          readOnlyComponent ||
                          userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager
                        }
                      />
                    }
                  />
                  {errors.surgical_history && (
                    <p className={classes.error}>
                      {errors.surgical_history.message}
                    </p>
                  )}
                  <Grid container spacing="2">
                    <Grid item xs={6}>
                      <Controller
                        name="marital_status"
                        control={control}
                        defaultValue={
                          contactForEdit?.marital_status || "single"
                        }
                        as={({ onChange, value }) => (
                          <Controls.Select
                            label="Marital Status"
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                            options={maritalStatusOptions()}
                            onChange={(value) => onChange(value)}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="financing"
                        control={control}
                        defaultValue={contactForEdit?.financing}
                        as={({ onChange, value }) => (
                          <Controls.Select
                            label={
                              authUser.organization.data
                                .contact_financing_required
                                ? "** Financing"
                                : "Financing"
                            }
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                            options={financingOptions()}
                            value={value}
                            onChange={(value) => onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing="2">
                    <Grid item xs={age != null ? 8 : 12}>
                      <Controller
                        name="dob"
                        control={control}
                        defaultValue={contactForEdit?.dob || null}
                        as={({ onChange, value }) => (
                          <Controls.DatePicker
                            label="** Date Of Birth"
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                            onChange={(date) => {
                              onChange(date ? date.format("YYYY-MM-DD") : "");
                              getAgeByDOB(
                                date && date != "" && moment(date).isValid()
                                  ? date.format("YYYY-MM-DD")
                                  : null
                              );
                            }}
                            value={value}
                          />
                        )}
                      />
                      {errors.dob && (
                        <p className={classes.error}>{errors.dob.message}</p>
                      )}
                    </Grid>

                    {age != null ? (
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            padding: "5px 10px",
                            background: "#bcdbf5",
                            borderRadius: "20px",
                            fontSize: 15,
                            fontWeight: 500,
                            maxWidth: "fit-content",
                            margin: 10,
                            marginInlineStart: "auto",
                          }}
                        >
                          {" "}
                          {age != null ? `Age: ${age}` : ""}
                        </Typography>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid item xs={12}>
                      <Controller
                        name="languages"
                        control={control}
                        defaultValue={languageOptionsPlain(
                          contactForEdit?.languages?.data || []
                        )}
                        as={({ onChange, value }) => (
                          <Controls.MultipleSelect
                            label="Languages"
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                            options={languageOptions(languages)}
                            value={languageOptionsPlain(
                              contactForEdit?.languages?.data || []
                            )}
                            // value={value}
                            onChange={(value) => onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Controller
                    name="priority"
                    control={control}
                    defaultValue={contactForEdit?.priority}
                    as={({ onChange, value }) => (
                      <Controls.Select
                        label="Priority"
                        disabled={
                          // (contactForEdit &&
                          //   !isEmpty(contactForEdit.fraudolent.data)) ||
                          readOnlyComponent ||
                          userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager
                        }
                        options={priorityOptions()}
                        value={value}
                        onChange={(value) => onChange(value)}
                      />
                    )}
                  />
                </Grid>

                {renderCustomFields()}
                <span className={classes.error}>
                  * for required fields of information
                </span>
                {error && errorFlag && !saveLoading && (
                  <p className={classes.error}>
                    {contactsnewErrors[contactsnewErrors.length - 1]}
                  </p>
                )}
              </Grid>
            )}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              {" "}
              <div>
                <Controls.AdornedButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={saveLoading}
                  disabled={
                    // (contactForEdit &&
                    //   !isEmpty(contactForEdit.fraudolent.data)) ||
                    !userCanEdit ||
                    (authUser.is_coordinator &&
                      contactForEdit &&
                      !contactForEdit.editable &&
                      !userCanEditAll) ||
                    (userIsSalesManager &&
                      contactForEdit &&
                      contactForEdit.building_id != authUser.building_id &&
                      !userCanEditAll) ||
                    /*((userIsCallCenterBasic ||
                              userIsFrontDeskBasic ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager) &&
                              contactForEdit &&
                              contactForEdit.building_id !=
                                authUser.building_id &&
                              !userCanEditAll) ||*/
                    (!isEmpty(contactsnewErrors) && errorFlag) ||
                    onSubmitClick
                  }
                >
                  Submit
                </Controls.AdornedButton>
                {error && errorFlag && !saveLoading && (
                  <p className={classes.error}>
                    {contactsnewErrors[contactsnewErrors.length - 1]}
                  </p>
                )}
                {contactForEdit && (
                  <Controls.Button
                    text="Send Form"
                    onClick={() => setOpenSendFormTemplate(true)}
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent
                    }
                  />
                )}
              </div>{" "}
            </Grid>
          </Grid>

          {contactForEdit && (
            <div className={classes.root}>
              <Popup
                title={`Extra ${extraType}`}
                openPopup={openExtraPopup}
                onClose={closeExtraPopup}
              >
                {
                  <ExtrafieldForm
                    contact={contactForEdit}
                    addOrEditExtra={addOrEditExtra}
                  />
                }
              </Popup>{" "}
            </div>
          )}
        </div>
      </form>
      <ConfirmDialog
        confirmLoading={confirmDialogLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
