import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 40%)",
    },
  },
}));

export default function DateTimePicker(props) {
  const { label, onChange, value, format = null, ...rest } = props;
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        {...rest}
        label={label}
        format={format ? format : "yyyy/MM/DD HH:mm"}
        onChange={(date) => onChange(date)}
        value={value}
        inputVariant="outlined"
        className={classes.textfield}
      />
    </MuiPickersUtilsProvider>
  );
}
