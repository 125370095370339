import React, { useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Box,
  ListItemText,
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChipID(props) {
  const {
    options,
    label,
    onChange,
    fullWidth = false,
    defaultNames,
    defaultIDS,
  } = props;
  const [selected, setSelected] = useState(defaultIDS ? defaultIDS : []);
  const [showNames, setShowNames] = useState(defaultNames ? defaultNames : []);

  const handleChange = (value) => {
    const index = selected.indexOf(value.id);
    let fields = [];
    if (index >= 0) {
      fields = selected.filter((zz, i) => {
        return index !== i;
      });
    } else {
      fields = [...selected, value.id];
    }
    let names = options.filter((f) => fields.includes(f.id));

    if (isEmpty(names)) {
      setShowNames([]);
    } else {
      setShowNames(
        names.map((name) => {
          return name.title;
        })
      );
    }

    setSelected(fields);
    onChange(fields);
  };

  return (
    <div>
      <FormControl fullWidth={fullWidth}>
        <InputLabel id="checkbox-label">{label ? label : ""}</InputLabel>
        <Select
          labelId="checkbox-label"
          id="multiple-checkbox"
          multiple
          value={showNames}
          //onChange={handleChange}
          input={<OutlinedInput label={label ? label : ""} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              onClick={() => {
                handleChange(option);
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontWeight: selected.includes(option.id) ? 700 : 500,
                  },
                }}
                primary={option.title}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
