import React, {  } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import { Paper, Typography, Chip } from "@material-ui/core";

import moment from "moment/moment";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    borderBottom: "1px solid #80808040",
    marginBottom: 5,
  },
  detailTitle: {
    fontSize: 15,
    fontWeight: 500,
    marginRight: 8,
  },
  detailValue: {
    fontSize: 15,
  },
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
    background: "#f5faff96",
    border: "1px solid #eef2f5",
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  title: {
    padding: "10px 16px",
    flexGrow: 1,
    backgroundColor: "#dff1f1",
    borderRadius: "15px 15px 0px 0px",
  },
}));
function ChangeDateOfProcedureRequestDetails(props) {
  const { request } = props;
  const classes = useStyles();
  return (
    <Wrapper>
      {request ? (
        <>
          {" "}
          <div className={classes.title}>
            <Typography style={{ fontWeight: 500, fontSize: 16 }}>
              Request to change the date of surgery
            </Typography>
          </div>
          <Paper elevation={1} className={classes.paper}>
            <div>
              <div className={classes.detailContainer}>
                <Typography className={classes.detailTitle}>
                  Requested at:
                </Typography>
                <Typography className={classes.detailValue}>
                  {moment(request?.created_at || null).format(
                    "MM/DD/YYYY, hh:mm A"
                  ) || ""}
                </Typography>
              </div>
              <div className={classes.detailContainer}>
                <Typography className={classes.detailTitle}>
                  Requested by:
                </Typography>

                <Typography className={classes.detailValue}>
                  {request?.requestedBy?.data?.users_fname || ""}
                </Typography>
              </div>

              <div className={classes.detailContainer}>
                <Typography className={classes.detailTitle}>
                  Status:{" "}
                </Typography>
                <Chip
                  style={{
                    border: "none",
                    backgroundColor:
                      request.status == "approved"
                        ? "rgb(155 243 131)"
                        : request.status == "declined"
                        ? "rgb(253 189 180)"
                        : "#fffdbd",
                    color:
                      request.status == "approved"
                        ? "rgb(0 155 20)"
                        : request.status == "declined"
                        ? "rgb(155 17 17)"
                        : "#9f9401",
                    fontWeight: 500,
                  }}
                  label={request?.status?.toUpperCase() || ""}
                  variant={"outlined"}
                />
              </div>

              <div className={classes.detailContainer}>
                <Typography className={classes.detailTitle}>
                  Current date:{" "}
                </Typography>

                <Typography className={classes.detailValue}>
                  {request && request.old_date? moment(request?.old_date || null).format(
                    "MM/DD/YYYY, hh:mm A"
                  ) : "None"}
                </Typography>
              </div>

              <div className={classes.detailContainer}>
                <Typography className={classes.detailTitle}>
                  Requested date:
                </Typography>

                <Typography className={classes.detailValue}>
                  {moment(request?.requested_date || null).format(
                    "MM/DD/YYYY, hh:mm A"
                  ) || ""}
                </Typography>
              </div>

              <div className={classes.detailContainer}>
                <Typography className={classes.detailTitle}>Reason:</Typography>
                <Typography className={classes.detailValue}>
                  {request?.reason || ""}
                </Typography>
              </div>

              {request.status != "pending" ? (
                <>
                  {" "}
                  <div className={classes.detailContainer}>
                    <Typography className={classes.detailTitle}>
                      Closed by:
                    </Typography>
                    <Typography className={classes.detailValue}>
                      {request?.closedBy?.data?.users_fname || ""}
                    </Typography>
                  </div>
                  <div className={classes.detailContainer}>
                    <Typography className={classes.detailTitle}>
                      Closed at:
                    </Typography>
                    <Typography className={classes.detailValue}>
                      {moment(request?.closed_at || null).format(
                        "MM/DD/YYYY, hh:mm A"
                      ) || ""}
                    </Typography>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </Paper>
        </>
      ) : (
        ""
      )}
    </Wrapper>
  );
}

export default ChangeDateOfProcedureRequestDetails;
