import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Controls from "./controls";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
    zindex: theme.zIndex.tooltip + 100,
  },
  dialogWrapper2: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(8),
    zindex: theme.zIndex.tooltip + 100,
    minWidth: 600,
  },
  dialogTitle: {
    padding: "5px 10px",
    paddingRight: "0px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Popup(props) {
  const {
    title,
    titleObj = null,
    children,
    openPopup,
    onClose,
    isfullWidth,
    maxWidth,
    className = null,
    dialogContentProps = {},
    ...other
  } = props;
  const classes = useStyles();

  let maxWidthlocal = isfullWidth == true ? false : "md";

  return (
    <Dialog
      open={openPopup}
      maxWidth={maxWidth ? maxWidth : maxWidthlocal}
      classes={{
        paper: className ? classes.dialogWrapper2 : classes.dialogWrapper,
      }}
      TransitionComponent={Transition}
      {...other}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          {titleObj ? (
            titleObj
          ) : (
            <Typography
              variant="h6"
              component="div"
              style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
            >
              {title}
            </Typography>
          )}

          <Controls.IconButton color="secondary" onClick={onClose}>
            <CloseIcon />
          </Controls.IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers={true} {...dialogContentProps}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
