import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textfield: {
    borderRadius: 10,

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 0 0 / 14%)",
    },
  },
}));

export default function Select(props) {
  const {
    styleProp = null,
    label,
    value,
    allowEmpty,
    emptyLabel,
    onChange,
    options,
    disabled,
    setSelectedItem = null,
    loading = false,
    variant,
    className = null,
    menuProps = null,
    formStyle = null,
    ...other
  } = props;
  const classes = useStyles();
  const inputProps = other.inputProps ? other.inputProps : {};

  // useEffect(()=>{
  //   console.log("options: ", options);
  // }, [options])

  return (
    <FormControl
      style={formStyle ? formStyle : { width: styleProp ? "100%" : "" }}
      variant={variant ? variant : "outlined"}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...other}
        className={className ? className : classes.textfield}
        MenuProps={{ PaperProps: { style: menuProps } }}
      >
        {allowEmpty && (
          <MenuItem
            onClick={() => {
              if (setSelectedItem) {
                setSelectedItem({ id: -1, title: "None" });
              }
            }}
            value={-1}
          >
            {emptyLabel ? emptyLabel : "None"}
          </MenuItem>
        )}

        {options != undefined &&
          options.map((item) =>
            item.title != null ? (
              <MenuItem
                disabled={item.disabled}
                onClick={() => {
                  if (setSelectedItem) {
                    setSelectedItem(item);
                  }
                }}
                style={{
                  backgroundColor: item.color ? item.color : "white",
                  borderRadius: 25,
                }}
                key={item.id}
                value={item.id}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>{item.title}</Typography>
                  {item.extraInfo ? item.extraInfo : ""}
                </div>
              </MenuItem>
            ) : (
              <MenuItem
                disabled={item.disabled}
                onClick={() => {
                  if (setSelectedItem) {
                    setSelectedItem(item);
                  }
                }}
                style={{
                  backgroundColor: item.color ? item.color : "white",
                  borderRadius: 25,
                }}
                key={item.id}
                value={item.id}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>{item.name}</Typography>
                  {item.extraInfo ? item.extraInfo : ""}
                </div>
              </MenuItem>
            )
          )}
      </MuiSelect>
    </FormControl>
  );
}
