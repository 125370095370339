import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { m, AnimatePresence } from "framer-motion";
// @mui
// import { alpha } from '@mui/material/styles';
import { fade } from "@material-ui/core/styles/colorManipulator";
import {
  Grid,
  List,
  IconButton,
  ListItemText,
  ListItem,
} from "@material-ui/core";
// utils
import { fData } from "../../dashboard/formatNumber";
import getFileData from "./getFileData";
//
import Iconify from "../../Iconify";
import { varFade } from "../../animate";

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  showPreview: PropTypes.bool,
};

export default function MultiFilePreview({
  showPreview = false,
  files,
  onRemove,
}) {
  // useEffect(() => {
  //   console.log("filesTfilesT", files);
  // }, [files]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Grid container alignItems="center">
      {files.map((file, index) => {
        const { key, name, size, type } = getFileData(file, index);
        const preview = URL.createObjectURL(file);

        if (showPreview) {
          return (
            <Grid item xs={3} key={key}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                {type === "application/pdf" ? (
                  <Iconify
                    icon={"fa6-solid:file-pdf"}
                    width={80}
                    height={80}
                    style={{ color: "#00A884" }}
                  />
                ) : (
                  <img
                    src={preview}
                    alt={`preview-${index}`}
                    style={{
                      width: "auto",
                      height: "auto",
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ListItemText
                  primary={name}
                  secondary={fData(size || 0)}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    style: { textAlign: "center" },
                  }}
                  secondaryTypographyProps={{
                    variant: "caption",
                    style: { textAlign: "center" },
                  }}
                />
              </div>
              {onRemove && (
                <IconButton
                  size="small"
                  onClick={() => onRemove(file)}
                  style={{
                    top: 6,
                    padding: "2px",
                    right: 6,
                    position: "absolute",
                    color: "common.white",
                    bgcolor: (theme) => fade(theme.palette.grey[900], 0.72),
                    "&:hover": {
                      bgcolor: (theme) => fade(theme.palette.grey[900], 0.48),
                    },
                  }}
                >
                  <Iconify icon={"eva:close-fill"} />
                </IconButton>
              )}
            </Grid>
          );
        }

        return (
          <Grid item xs={3}>
            <ListItem
              key={key}
              component={m.div}
              {...varFade().inRight}
              style={{
                marginTop: 1,
                marginBottom: 1,
                paddingLeft: 2,
                paddingRight: 1,
                paddingTop: 0.75,
                paddingBottom: 0.75,
                borderRadius: 0.75,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <Iconify
                icon={"eva:file-fill"}
                style={{
                  width: 28,
                  height: 28,
                  color: "text.secondary",
                  marginRight: 2,
                }}
              />

              <ListItemText
                primary={typeof file === "string" ? file : name}
                secondary={typeof file === "string" ? "" : fData(size || 0)}
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondaryTypographyProps={{ variant: "caption" }}
              />

              {onRemove && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => onRemove(file)}
                >
                  <Iconify icon={"eva:close-fill"} />
                </IconButton>
              )}
            </ListItem>
          </Grid>
        );
      })}
    </Grid>
  );
}
