import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { isEmpty, map, findIndex, capitalize, find } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

import {
  CardContent,
  Box as MuiBox,
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Button as MuiButton,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Container,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import { spacing, display } from "@material-ui/system";
import PersonIcon from "@material-ui/icons/Person";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { useDispatch, useSelector } from "react-redux";
import Controls from "./controls";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import moment from "moment";
import NumberFormatCustom from "./controls/NumberFormatCustom";
import { fetchEntitiesService as fetchProductGiftReasonService } from "../redux/slices/productGiftReasonSlice.js";
import { fetchUsersServiceDropdown } from "../redux/slices/users";
import deletedStamp from "../assets/img/icons/deletedStamp.png";
var dataSliceproductGiftReason = {
  stateListName: "productGiftReason",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
import { fetchEntitiesService as fetchFinancingService } from "../redux/slices/financingSlice.js";
import Iconify from "./Iconify";

var dataSliceFinancing = {
  stateListName: "financing",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
  position: relative;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  included_margin: {
    width: "100%",
    margin: "25px 0px",
  },
  searchInput: {
    width: "90%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainProduct: {},
  childProduct: {
    marginLeft: "35px",
    color: "#999",
  },
  watermarkedContainer: {
    position: "relative",
  },
  watermark: {
    position: "absolute",
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: "0.5",
    pointerEvents: "none",
    zIndex: 1000,
  },
}));

function QuoteBuilderDeleted(props) {
  const { authUser } = useSelector((state) => state.auth);
  const userCanFinancing = false;
  const userCanEditFinancingInterest = false;
  const userCanChangeConsultant = false;
  const userCanChangeApproveDate = false;
  const userCanChangeConsultantAll = false;
  const { usersDropdown, usersDropdownLoanding } = useSelector(
    (state) => state.users
  );
  const {
    setQuotes = null,
    quotes = null,
    onConvert2Invoice,
    quote,
    users,
    products,
    contact,
    //contacts,
    statusOptions,
    onSaveQuote,
    onApproveQuote,
    paymentGateways,
    openItemDefault,
    readOnlySalesManager = false,
    getQuote,
  } = props;
  const dispatch = useDispatch();
  const { financing } = useSelector((state) => state.financing);
  const { productGiftReason } = useSelector((state) => state.productGiftReason);
  const [financingInterest, setFinancingInterest] = useState(false);
  const TODAY = moment();
  const [items, setItems] = useState(quote ? quote.items.data : []);
  const [status, setStatus] = useState(quote ? quote.status : "unsend");
  const [included, setIncluded] = useState(quote ? quote.included : "");
  const classes = useStyles();
  const [financingOption, setFinancingOption] = useState(
    quote ? (quote.financing_id ? quote.financing_id : -1) : -1
  );

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getItemPrice = (price, base, product_apply_financing) => {
    if (financingInterest && product_apply_financing) {
      const value = base ? base : price;

      return parseFloat(value + (value * financingInterest) / 100).toFixed(2);
    }
    return base || price;
  };

  const updateItemQuantity = (id, value) => {
    const newItems = items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: value,
        };
      }
      return item;
    });
    setItems(newItems);
  };
  const calculateItemTotal = (item) => {
    const quantity = item.quantity || 1;
    const discount = item.discount || 0;
    const itemPrice = getItemPrice(
      item.type == "package" && item.package_price
        ? item.package_price
        : item.price,
      item.type == "package" && item.base_package_price
        ? item.base_package_price
        : item.base_price,
      item.product_apply_financing
    );
    return (
      itemPrice * (item.price_includes_qty ? 1 : parseInt(quantity)) -
      discount * (item.price_includes_qty ? 1 : parseInt(quantity))
    );
  };
  const calculateSubtotal = () => {
    return items.reduce(
      (total, item) =>
        total +
        calculateItemTotal({
          ...item,
          ...{ package_price: 0, base_package_price: 0 },
        }),
      0
    );
  };
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();

    return subtotal;
  };

  const getActiveStep = () => {
    const index = findIndex(statusOptions, ["label", capitalize(status)]);

    return index;
  };

  const renderItems = () => {
    return items.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            <ListItem>
              {item.type == "package" ? (
                <Iconify
                  icon="entypo:shopping-bag"
                  style={{ fontSize: 18, color: "gray", marginRight: 15 }}
                />
              ) : (
                ""
              )}
              {item.responsible && (
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={item.name}
                secondary={
                  item.responsible
                    ? item.responsible.title ||
                      `${item.responsible.fname} ${item.responsible.lname}`
                    : null
                }
                className={
                  item && item.parent_id
                    ? classes.childProduct
                    : classes.mainProduct
                }
              />
            </ListItem>
          </TableCell>

          <TableCell>
            {" "}
            {item.calendar_date
              ? moment(item.calendar_date).format("YYYY-MM-DD")
              : ""}
          </TableCell>
          <TableCell>
            {item.parent_id ? (
              authUser.organization &&
              authUser.organization.data &&
              authUser.organization.data.zero_package_value &&
              !item.isProcedure ? (
                <div>$ 0.00</div>
              ) : (
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgb(14, 85, 126)",
                    backgroundColor: "#deeced",
                    textAlign: "center",
                    padding: "0px 15px",
                    maxWidth: "fit-content",
                    borderRadius: 12,
                  }}
                >
                  {"INCLUDED"}
                </div>
              )
            ) : (
              <div>
                {" "}
                {item.product_gift_reason_id ? (
                  <CardGiftcardIcon fontSize="small" color="secondary" />
                ) : item && item.parent_id ? (
                  ""
                ) : (
                  ""
                )}
                ${" "}
                {parseFloat(
                  getItemPrice(
                    item.type == "package" && item.package_price
                      ? item.package_price
                      : item.price,
                    item.type == "package" && item.package_price
                      ? item.base_package_price
                      : item.base_price,
                    item.product_apply_financing
                  )
                ).toFixed(2)}
                {item.product_gift_reason_id ? (
                  <>
                    <span>{" / "} </span>
                    <span style={{ color: "#8080808a" }}>
                      {" $ " + item.gift_reference_price}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </TableCell>
          <TableCell>
            {!quote || quote.status !== "approved" ? (
              <TextField
                className={classes.formControl}
                value={item.quantity || 1}
                disabled
                inputProps={{
                  decimalPrecision: 0,
                  decimalSeparator: false,
                  allowNegative: false,
                  isAllowed: (values) => {
                    const { floatValue } = values;
                    return floatValue >= 1 && floatValue <= 100;
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            ) : (
              <span>{item.quantity || 1}</span>
            )}
          </TableCell>

          <TableCell align="right">
            {item && item.parent_id
              ? ""
              : "$ " + calculateItemTotal(item).toFixed(2)}
          </TableCell>

          <TableCell align="right"></TableCell>
        </TableRow>
      );
    });
  };

  const financingOptions = () => {
    return financing.map((financing) => {
      return {
        id: financing.id,
        title: financing.name,
        interest: financing.interest,
      };
    });
  };

  const salesmanOptions = () => {
    if (userCanChangeConsultantAll) {
      return usersDropdown
        .filter((user) => {
          return user.is_salesman || (quote && quote.user_id == user.id);
        })
        .map((user) => {
          return {
            id: user.id,
            title: `${user.fname} ${user.lname}`,
          };
        });
    }
    if (userCanChangeConsultant) {
      const salesman = usersDropdown
        .filter((user) => {
          return (
            (authUser.groups.data[0] &&
              authUser.groups.data[0].id === user.groups.data[0].id &&
              user.is_salesman) ||
            (quote && quote.user_id == user.id)
          );
        })
        .map((user) => {
          return {
            id: user.id,
            title: `${user.fname} ${user.lname}`,
          };
        });

      return salesman;
    }

    return [];
  };

  const applyFinancing = () => {
    const selectedFinancing = find(financing, { id: financingOption });
    if (selectedFinancing) {
      setFinancingInterest(
        quote && quote.interest ? quote.interest : selectedFinancing.interest
      );
    } else {
      setFinancingInterest(false);
    }
  };

  useEffect(() => {
    applyFinancing();
  }, [financingOption]);

  useEffect(() => {
    if (items && quote && quote.items.data && quote.items.data != items)
      setItems(quote ? quote.items.data : []);
  }, [quote]);

  useEffect(() => {
    if (isEmpty(financing)) dispatch(fetchFinancingService(dataSliceFinancing));

    if (isEmpty(productGiftReason)) {
      dispatch(fetchProductGiftReasonService(dataSliceproductGiftReason));
    }
    if (isEmpty(usersDropdown) && !usersDropdownLoanding)
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
  }, []);

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  useEffect(() => {
    var test =
      quote && quote.items.data
        ? {
            ...quote,
            ...{
              items: {
                data: [
                  ...quote.items.data.map((a) => {
                    return { ...a, ...{ price: a.parent_id ? "-" : a.price } };
                  }),
                ],
              },
            },
          }
        : "aaaa";
  }, [quote]);

  return (
    <Shadow>
      <Card className={classes.card}>
        <CardContent>
          <Alert
            severity="error"
            style={{
              borderRadius: 25,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{ fontSize: 20, fontStyle: "italic", fontWeight: 600 }}
            >
              DELETED | This Quote is only for record purposes
            </Typography>
          </Alert>
        </CardContent>
        <Box className={classes.watermarkedContainer}>
          <CardContent>
            <Stepper nonLinear activeStep={getActiveStep()}>
              {map(statusOptions, (status) => (
                <Step key={status.label}>
                  <StepLabel>{status.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Grid container spacing={6}>
              <Grid item xs={8}>
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" m={1}>
                    QUOTE CONTACT:
                  </Box>
                  <Box fontWeight="fontWeightBold" m={1}>
                    {contact ? (
                      <Typography component="div">
                        {contact.full_name}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  {contact && (
                    <Box fontWeight="fontWeightMedium" m={1}>
                      {contact.address}
                    </Box>
                  )}
                  {contact && (
                    <Box fontWeight="fontWeightMedium" m={1}>
                      {contact.phone}
                    </Box>
                  )}
                  {contact && contact.coordinator_name && (
                    <Box m={1}>{"Consultant: " + contact.coordinator_name}</Box>
                  )}
                </Typography>
              </Grid>
              {authUser.organization.data.maxQuoteTime && (
                <Grid item xs={3}>
                  <Controls.DatePicker2
                    fullWidth
                    label="Expiration Date"
                    value={
                      quote
                        ? quote.expiration_date
                          ? moment(quote.expiration_date, "YYYY-MM-DD")
                          : null
                        : authUser.organization?.data?.maxQuoteTime
                        ? moment(TODAY, "YYYY-MM-DD").add(
                            authUser.organization.data.maxQuoteTime,
                            "days"
                          )
                        : null
                    }
                    disabled
                  />
                </Grid>
              )}

              <Grid item xs={4}>
                <Controls.Select
                  allowEmpty={true}
                  emptyLabel="None"
                  options={financingOptions()}
                  value={financingOption}
                  label="Financing"
                  disabled
                />
              </Grid>

              <Grid item xs={2}>
                {/*<Controls.Button text="Apply" onClick={() => applyFinancing()} />*/}
              </Grid>
            </Grid>
          </CardContent>
          <img
            src={deletedStamp}
            alt="Watermark"
            className={classes.watermark}
            width={"28%"}
          />
        </Box>
      </Card>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Calendar Date</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              {/*<TableCell>Discount</TableCell>*/}
              <TableCell align="right">
                {" "}
                {quote?.quote_total_name ? quote?.quote_total_name : "Total"}
              </TableCell>
              {quote && quote.status !== "approved" && (
                <TableCell align="right"></TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {renderItems()}

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="right">Subtotal</TableCell>
              <TableCell align="right">
                $ {calculateSubtotal().toFixed(2)}
              </TableCell>
              <TableCell />
            </TableRow>

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="right">
                <Box fontWeight="fontWeightBold" m={1}>
                  {quote?.quote_total_name ? quote?.quote_total_name : "Total"}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box fontWeight="fontWeightBold" m={1}>
                  $ {calculateTotal().toFixed(2)}
                </Box>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      {!isEmpty(items) && (
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              spacing={5}
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={6}>
                <Controls.Input
                  defaultValue={included}
                  label="Included"
                  multiline
                  maxRows="3"
                  minRows="3"
                  className={classes.included_margin}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={
                    usersDropdown.find((a) => quote?.user_id == a.id)
                      ?.dropdown_name || ""
                  }
                  label="Owner"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Shadow>
  );
}
export default QuoteBuilderDeleted;
