import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchContractsService } from "../redux/slices/contracts";
import { fetchBuildingsService } from "../redux/slices/buildings";
import { isEmpty } from "lodash";
import Controls from "./controls";
import {
  MenuItem,
  Select,
  Typography,
  Button,
  Grid,
  Dialog,
} from "@material-ui/core";
import ScrollBar from "react-perfect-scrollbar";
import Iconify from "./Iconify";
import Api from "../lib/api";
import Notification from "./Notification";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import PrintContractDetails from "./PrintContractDetails";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    flexGrow: 1,
  },
  form: {
    justifySelf: "self-end",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  paper: {
    boxShadow: "0 0 14px 0 rgb(0 0 0 / 9%)",
    borderRadius: 8,
    padding: "18px !important",
    marginTop: 6,
  },
}));

const Wrapper = styled.div`
position: relative;
overflow: hidden;
display: "block";
width: 100%;
margin: "10px",
padding: "5px",
`;

function PrintQuoteContract(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { quote = null } = props;
  const { authUser } = useSelector((state) => state.auth);
  const { buildings } = useSelector((state) => state.buildings);
  const { contracts, loading, errors } = useSelector(
    (state) => state.contracts
  );
  const contractRef = useRef();
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [contractToPrint, setContractToPrint] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const buildingOptions = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        title: building.name,
      };
    });
  };

  const contractOptions = () => {
    return contracts
      .filter((c) => c.building_id == selectedBuilding)
      .map((c) => {
        return {
          id: c.id,
          title: c.name,
          html: c.html,
        };
      });
  };

  const handleContractChange = (value) => {
    setSelectedContract(value);
  };
  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;

    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  const printContract = async () => {
    try {
      setIsLoading(true);
      if (selectedContract && quote) {
        const { data } = await Api.getQuoteContract(
          quote.id,
          selectedContract.id
        );
        setContractToPrint({ ...data });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!",
        type: "error",
      });
    }finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const orgId = authUser.organization_id;
    dispatch(fetchContractsService(orgId));

    if (isEmpty(buildings)) dispatch(fetchBuildingsService(orgId));
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {" "}
          <Controls.Select
            label={"Building"}
            allowEmpty={false}
            options={buildingOptions()}
            onChange={(el) => {
              setSelectedContract(null);
              setSelectedBuilding(el.target.value);
            }}
            value={selectedBuilding}
          />
        </Grid>

        <Grid item xs={12}>
          <Controls.Autocomplete
            label="Select the contract"
            options={contractOptions()}
            onChange={(newValue) => {
              handleContractChange(newValue);
            }}
            defaultValue={selectedContract}
          />
        </Grid>
        {selectedContract ? (
          <Grid item xs={12} className={classes.paper}>
            <ScrollBar
              style={{
                maxHeight: 350,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlDecode(selectedContract.html),
                }}
              />
            </ScrollBar>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          <ReactToPrint
            trigger={() => (
              <Controls.Button
                disabled={!selectedBuilding || !selectedContract}
                fullWidth
                startIcon={<Iconify icon="ic:round-print" />}
                text="Print Contract"
              />
            )}
            onBeforeGetContent={async () => {
              await printContract();
            }}
            content={() => contractRef.current}
            onAfterPrint={() => {
              setContractToPrint(null);
            }}
          />
        </Grid>
        <Notification notify={notify} setNotify={setNotify} />
      </Grid>

      <div style={{ display: "none" }}>
        <div ref={contractRef}>
          <PrintContractDetails
            quote={
              quote && quote.items && quote.items.data
                ? {
                    ...quote,
                    ...{
                      items: {
                        data: [
                          ...quote.items.data.map((a) => {
                            return {
                              ...a,
                              ...{
                                price: a.parent_id ? "-" : a.price,
                                base_price: a.parent_id ? "-" : a.base_price,
                              },
                            };
                          }),
                        ],
                      },
                    },
                  }
                : quote
            }
            contract={contractToPrint}
          />
        </div>
      </div>
      <Dialog open={isLoading}>
        <div style={{ textAlign: "center", padding: '15px 35px' }}>
          <Typography style={{ fontSize: 20, color: "#696e75" }}>
            Loading...
          </Typography>
          <Iconify
            style={{ fontSize: 36, color: "#4fb5ed", marginTop: 6 }}
            icon="eos-icons:three-dots-loading"
          />
        </div>
      </Dialog>
    </>
  );
}

export default PrintQuoteContract;
