import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import Timeline from "@material-ui/lab/Timeline";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import CommentTimeline from "./timeline/Comment";
import ToDoTimeline from "./timeline/ToDo";
import CallTimeline from "./timeline/Call";
import SmsMmsTimeline from "./timeline/SmsMms";
import TicketTimeline from "./timeline/Ticket";
import DocumentTimeline from "./timeline/Document";
import QuotePaymentHistory from "./timeline/QuotePaymentHistory";
import InvoicePaymentHistory from "./timeline/InvoicePaymentHistory";
import CreditMemoInvoice from "./timeline/CreditMemoInvoice";
//import InvoiceItem from "./timeline/InvoiceItem";
import BaseTimeline from "./timeline/Base";

import { useInterval } from "beautiful-react-hooks";
import Api from "../lib/api";
import QuoteTimeline from "./timeline/Quote";
import InvoiceTimeline from "./timeline/Invoice";
import RecordProductTransferTimeline from "./timeline/RecordProductTransfer";
import RefundTimeline from "./timeline/Refund";
import ReactToPrint from "react-to-print";
import Controls from "./controls";
import PrintIcon from "@material-ui/icons/Print";
import RichTextarea from "./controls/RichTextarea";
import { Typography } from "@material-ui/core";
import CRMUtils from "../utils";
import { useSelector } from "react-redux";
import AppointmentTimeline from "./timeline/Appointment";
import InfluencerSocialPlatformTimeline from "./timeline/InfluencerSocialPlatformTimeline";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Scrollbar = styled(PerfectScrollbar)``;

export default function CustomizedTimeline(props) {
  const classes = useStyles();
  const printRef = useRef();
  const {
    model,
    modelType,
    typel,
    createdAt,
    users = null,
    pbxInformation = null,
    patient_name,
    consultantErrorHandle = null,
  } = props;

  const [activities, setActivities] = useState([]);
  //console.log(typel);
  const getActivity = async () => {
    try {
      if (typel === "Quote") {
        const { data } = await Api.getQuoteActivity(model.id);
        setActivities(data.data);
      } else if (typel === "Contact") {
        const { data } = await Api.getContactActivity(model.id);
        setActivities(data.data);
      } else if (typel === "Record") {
        const { data } = await Api.getRecordActivity(model.id);
        setActivities(data.data);
      } else if (typel === "Invoice") {
        const { data } = await Api.getInvoiceActivity(model.id);
        setActivities(data.data);
      } else if (typel === "Influencer") {
        const { data } = await Api.getInfluencerActivities(model.id);
        setActivities(data.data);
      }
    } catch (error) {
      if (
        typeof consultantErrorHandle === "function" &&
        error?.data?.data?.message
      ) {
        consultantErrorHandle(error?.data?.data?.message);
      }
    }
  };

  const { authUser } = useSelector((state) => state.auth);
  const userCanPrintTimeline = CRMUtils.userHasPermission(authUser, [
    "contact_PrintTimeline",
  ]);

  useInterval(async () => {
    getActivity();
  }, 20000);

  useEffect(() => {
    getActivity();
  }, []);

  /*useEffect(() => {
    if(backOrNext){
      getActivity().then(()=>{
        setBackOrNext(false);
      })
    }
  }, [backOrNext]);
  */

  const renderActivity = (activity) => {
    //  console.log(activity);
    if (isEmpty(activity.object)) return;
    switch (activity.type) {
      case "AppointmentNew":
        return <AppointmentTimeline key={activity.id} data={activity} />;
        break;
      case "Comment":
        return <CommentTimeline key={activity.id} data={activity} />;
        break;
      case "Ticket":
        return <TicketTimeline key={activity.id} data={activity} />;
        break;
      case "MediaCollections":
        return <DocumentTimeline key={activity.id} data={activity} />;
        break;
      case "QuotePaymentHistory":
        return <QuotePaymentHistory key={activity.id} data={activity} />;
        break;
      case "InvoicePaymentHistory":
        return <InvoicePaymentHistory key={activity.id} data={activity} />;
        break;
      case "CreditMemosInvoice":
        return <CreditMemoInvoice key={activity.id} data={activity} />;
        break;
      /*case "InvoiceItem":
        return <InvoiceItem key={activity.id} data={activity} />;
        break;*/
      case "Quote":
        return <QuoteTimeline key={activity.id} data={activity} />;
        break;
      case "Invoice":
        return <InvoiceTimeline key={activity.id} data={activity} />;
        break;
      case "ContactToDo":
        return <ToDoTimeline key={activity.id} data={activity} />;
        break;
      case "Call":
        return (
          <CallTimeline
            key={activity.id}
            data={activity}
            users={users}
            pbxInformation={pbxInformation}
          />
        );
        break;
      case "SmsMms":
        return (
          <SmsMmsTimeline key={activity.id} data={activity} users={users} />
        );
        break;
      case "RecordProductTransfer":
        return (
          <RecordProductTransferTimeline key={activity.id} data={activity} />
        );
        break;
      case "InfluencerSocialPlatform":
        return (
          <InfluencerSocialPlatformTimeline key={activity.id} data={activity} />
        );
        break;
      default:
      // code block
    }
  };
  return (
    <Scrollbar style={{ maxHeight: 700 }}>
      {userCanPrintTimeline && (
        <ReactToPrint
          trigger={() => (
            <Controls.Button
              text="Print"
              startIcon={<PrintIcon />}
              style={{ marginLeft: 20, height: 30, float: "right", margin: 15 }}
            />
          )}
          content={() => printRef.current}
        />
      )}
      <Timeline align="alternate">
        {activities.map((activity) => {
          return renderActivity(activity);
        })}

        <BaseTimeline key="base" model={modelType} createdAt={createdAt} />
      </Timeline>
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          {patient_name && <Typography>Patient:{patient_name}</Typography>}
          <Timeline align="alternate">
            {activities.map((activity) => {
              return renderActivity(activity);
            })}
            <BaseTimeline key="base" model={modelType} createdAt={createdAt} />
          </Timeline>
        </div>
      </div>
    </Scrollbar>
  );
}
