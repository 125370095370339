import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersServiceDropdown } from "../../redux/slices/users";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ChangeProcedureCoordinator(props) {
  const { changeCoordinator } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { usersDropdown } = useSelector((state) => state.users);
  const { authUser } = useSelector((state) => state.auth);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await changeCoordinator(data);
    setIsSubmitting(false);
  };

  const getCoordinatorOptions = () => {
    return usersDropdown
      .filter((user) => {
        return user.is_coordinator === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: `${user.first_name} ${user.last_name}`,
        };
      });
  };

  useEffect(() => {
    if (isEmpty(usersDropdown)) {
      dispatch(fetchUsersServiceDropdown(authUser.organization_id));
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="coordinator_id"
            control={control}
            defaultValue={null}
            as={({ onChange, value }) => (
              <Controls.Select
                fullWidth
                styleProp
                label="Coordinator"
                options={getCoordinatorOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
          />

          <div>
            <Controls.Button
              disabled={isSubmitting}
              type="submit"
              text={isSubmitting ? "Submitting..." : "Submit"}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
