import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { map, isEmpty } from "lodash";

import Controls from "../controls";
import Iconify from "../Iconify";

import MTableR from "../../components_generic/MTableRSelected";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  error: {
    color: "#ca3a3a",
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 5,
  },
}));

export default function SplitInvoiceForm(props) {
  const { onSplit, splitInvoiceLoading, getItemPrice, localItems = [] } = props;
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState([]);

  const onSelectItem = (rows) => {
    setSelectedItems(rows);
  };

  const calculatePrice = (item) => {
    const price =
      item.type === "package" && item.package_price
        ? item.package_price
        : item.price;
    const basePrice =
      item.type === "package" && item.package_price
        ? item.base_package_price
        : item.base_price;
    return parseFloat(
      getItemPrice(price, basePrice, item.product_apply_financing)
    ).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const onSubmit = () => {
    const idsAndChildProductIds = selectedItems.flatMap((item) => [
      item.id,
      ...(item.childs ? item.childs.map((cp) => cp.id) : []),
    ]);

    const payload = {
      invoiceItemsIds: idsAndChildProductIds,
    };

    onSplit(payload);
  };

  const headCellsMaterial = [
    {
      field: "name",
      title: "Description",
      width: "61%",
      render: (rowData) => (
        <ListItemText
          primary={rowData?.name}
          secondary={
            rowData?.type === "package"
              ? map(localItems, (childProduct, index) => (
                  <ListItem
                    style={{ padding: 0, margin: 0 }}
                    key={`key_${index}`}
                  >
                    <ListItemIcon
                      style={{
                        margin: 5,
                        minWidth: "fit-content",
                        color: "#0e3b6a",
                      }}
                    >
                      <Iconify icon="ion:arrow-redo-outline" />
                    </ListItemIcon>
                    <ListItemText
                      secondary={childProduct?.name}
                      secondaryTypographyProps={{
                        style: {
                          fontFamily:
                            '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                          textAlign: "left",
                          fontSize: 12,
                          marginLeft: 15,
                        },
                      }}
                    />
                  </ListItem>
                ))
              : ""
          }
        />
      ),
    },
    {
      field: "price",
      title: "Price",
      width: "13%",
      render: (rowData) => (
        <ListItemText
          primary={
            rowData?.type !== "package"
              ? parseFloat(rowData?.price || 0).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : calculatePrice(rowData)
          }
        />
      ),
    },
    {
      field: "quantity",
      title: "Quantity",
      width: "13%",
      render: (rowData) => (
        <ListItemText
          primary={parseInt(rowData?.quantity || 1).toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        />
      ),
    },
    {
      field: "total",
      title: "Total",
      width: "13%",
      render: (rowData) => (
        <ListItemText
          primary={
            rowData?.type !== "package"
              ? (
                  parseInt(rowData?.quantity || 1) *
                  parseFloat(rowData?.price || 0)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : calculatePrice(rowData)
          }
        />
      ),
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MTableR
            data={localItems || []}
            columns={headCellsMaterial}
            headerColor={"#616161"}
            headerBackgroundColor={"#e0f2f1"}
            loadingType="overlay"
            showPaging={false}
            emptyRowsWhenPaging={false}
            search={false}
            toolbar={false}
            onSelectionChange={(rows) => {
              onSelectItem(rows);
            }}
            optionstoAdd={{
              actionsColumnIndex: -1,
              selection: !splitInvoiceLoading,
              showTextRowsSelected: false,
              showSelectAllCheckbox: false,
            }}
            selectionProps={(rowData) => ({
              disabled:
                selectedItems?.length === localItems?.length - 1 &&
                !selectedItems.includes(rowData),
              checked: selectedItems.includes(rowData),
            })}
         
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Grid item xs={2}>
          <Controls.Button
            disabled={splitInvoiceLoading || isEmpty(selectedItems)}
            startIcon={<Iconify icon="fluent:split-vertical-32-regular" />}
            text={splitInvoiceLoading ? "Splitting..." : "Split"}
            onClick={() => {
              onSubmit();
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
