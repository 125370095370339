import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { isEmpty } from "lodash";

import { fetchEntitiesService as fetchUserContactsSMSMMSService } from "../redux/slices/userSMSMMSSlice.js";
import { fetchEntitiesService as fetchUserContactsSMSMMSByGroupsService } from "../redux/slices/userSMSMMSByGroupsSlice.js";

var dataSliceUserContactsSMSMMS = {
  stateListName: "userContactsSMSMMS",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceUserContactsSMSMMSByGroups = {
  stateListName: "userContactsSMSMMSByGroups",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};



function UserContactsSMSMMS() {
  const portal = localStorage.getItem("portal");

  const dispatch = useDispatch();
  const [floading, setFloading] = useState(true);
  const { authUser, loading, errors } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isEmpty(authUser) && floading && !portal) {
      setFloading(false);
      dataSliceUserContactsSMSMMS.orgId = authUser.id;
      dataSliceUserContactsSMSMMSByGroups.orgId = authUser.id;
      dispatch(fetchUserContactsSMSMMSService(dataSliceUserContactsSMSMMS));
      dispatch(
        fetchUserContactsSMSMMSByGroupsService(
          dataSliceUserContactsSMSMMSByGroups
        )
      );
    }
  }, [authUser]);

  return "";
}

export default UserContactsSMSMMS;
