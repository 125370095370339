import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { blue } from "@material-ui/core/colors";
import {
  Paper,
  makeStyles,
  Fab,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import Controls from "../components/controls";
import ConfirmDialog from "../components/ConfirmDialog";
import Notification from "../components/Notification";
import CloseIcon from "@material-ui/icons/Close";
import useTable from "../components/useTable";
import { useDispatch, useSelector } from "react-redux";

import CrmTableFilter2 from "./CrmTableFilterComponent4";
import ChipsArray from "./ChipsArrayComponent";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CrmGenericBasicForm from "./CrmGenericBasicForm";

import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  Toolbar: {
    justifyContent: "space-between",
  },
  searchInput: {
    width: "50%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var sliceDataEntity = {
  getFilters: {
    include: [],
    limit: 5,
    page: 1,
    params: [],
    filters: [],
    search: "",
    sort: "",
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
};

var isLoaded = false;
var isLoadingData = false;
var page = 0;
var minimumPageSize = 50;
var pageSize = 50;
var pageSizeOptions = [50, 100, 200, 250, 500];
var loadingType = "linear";
var time = null;
var searchText = "";
var pendingSearchText = null;
var actions = [];
var confirmDialogLoading = false;
var filterParams = [];

var orderBy = -1;

var orderDirection = "";

//tableRef.current.dataManager.changePageSize(newPageSIze)
//tableRef.current.dataManager.getRenderState().data
//tableRef.current.dataManager.searchText = filter;
//tableRef.current.renderTable(tableRef.current);

const CrmTable = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation("common");
  const {
    columns,
    data,
    onRowClick,
    title,
    sliceData,
    sliceGet,
    paginator,
    onClickAdd,
    onClickEdit,
    onCLickLogs,
    editHidden,
    deleteHidden,
    onClickDelete,
    isLoading,
    isShort,
    vip,
    sliceDelete,
    filterFormData,
    basicFormData,
    extraActions,
    canEdit = true,
    canAdd = true,
    canDelete = true,
    chipsArrayNshow = false,
    userFilter = true,
    exportButton = true,
    userCanSeeLogs = false,
    rowColorAlternate = false,
    rowColorAlternateArray = null,
    detailPanel = false,
    dpageSize = null,
    dpageSizeOptions = null,
    search = true,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [entityForEdit, setEntityForEdit] = useState(null);
  const [showFormBasic, setShowFormBasic] = useState(true);
  const { authUser } = useSelector((state) => state.auth);
  var paging = paginator == null ? false : true;
  var totalCount =
    paging == true
      ? paginator?.total != undefined
        ? paginator.total
        : 0
      : data.length;
  const [pageSizeTest, setPageSizeTest]= useState(10);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const crmTableFilter = useRef();
  const crmGenericBasicForm = useRef();

  const [chipsFilterItemsState, setChipsFilterItemsState] = useState([]);

  useEffect(() => {
    
    setPageSizeTest(isShort && !isLoaded ? minimumPageSize : dpageSize ? dpageSize : pageSize);
    sliceDataEntity.getFilters.filters =
      sliceDataEntity.getFilters.filters != null
        ? sliceDataEntity.getFilters.filters
        : [];
    dataQuery = null;
  }, []);

  pageSize =
    isShort && !isLoaded ? minimumPageSize : dpageSize ? dpageSize : pageSize;

  //loadingType=pageSize<=minimumPageSize?'linear':'overlay';

  useImperativeHandle(ref, () => ({
    refresh(resetPage = false) {
      page = resetPage ? 0 : page;
      searchText = "";
      sliceDataEntity.getFilters.search = searchText;
      loadPage(null, true);
    },
  }));

  const dataMaterialTable = data ? data.map((o) => ({ ...o })) : null;

  sliceDataEntity = sliceData ? sliceData : sliceDataEntity;
  sliceDataEntity.dataList = !sliceDataEntity.dataList
    ? {}
    : sliceDataEntity.dataList;
  sliceDataEntity.getFilters.search = searchText;

  const onChangePage = (p) => {
    if (page != p) {
      loadPage(p);
    }
  };
  const onSearchChange = (search) => {
    let date = new Date();
    time = date.getTime();
    loadingType = "linear";
    if (!isLoading) {
      searchText = search;
      console.log("Search: --- ", search);
      sliceDataEntity.getFilters.search = searchText;
      page = 0;
      loadPage();
    } else pendingSearchText = search;
  };
  const onChangeRowsPerPage = (_pageSize) => {
    setPageSizeTest(_pageSize);
    loadingType = "overlay";
    pageSize = _pageSize;
    loadPage(0);
  };



  const onOrderChange = (order, direction) => {
    let column = columns[order];

    let date = new Date();
    time = date.getTime();
    loadingType = "overlay";
    let sort = "";
    if (!isLoading) {
      if (direction == "desc") {
        sort = "-";
      }
      sort = sort + column.field;
      sliceDataEntity.getFilters.sort = sort;
      page = 0;

      orderBy = order;
      orderDirection = direction;
      loadPage();
    }
  };
  const loadDataSuccess = () => {
    // console.log("loadDataSuccess");
    isLoadingData = false;
    /* if(totalCount<pageSize&&pageSize>minimumPageSize){
              pageSize=dataMaterialTable.length;
              pageSize=pageSize<minimumPageSize?minimumPageSize:pageSize;
              tableRef.current.dataManager.changePageSize(pageSize)  
          }*/
    //var x = tableRef.current.dataManager;
    if (pendingSearchText != null) {
      var s = pendingSearchText;
      pendingSearchText = null;
      onSearchChange(s);
    }
    if (tableRef.current != null && orderBy != -1) {
      tableRef.current.dataManager.changeOrder(orderBy, orderDirection);
      console.log(tableRef.current);
    }
  };
  sliceDataEntity.dataList.successHandle = loadDataSuccess;

  const loadPage = (p, isRefreshNoPaging = false) => {
    page = p != null ? p : page;
    if (paging) {
      pageSize =
        tableRef.current != null
          ? tableRef.current.dataManager.pageSize
          : pageSize;
          setPageSizeTest(tableRef.current != null
            ? tableRef.current.dataManager.pageSize
            : pageSize)
      sliceDataEntity.getFilters.page = page + 1;
      sliceDataEntity.getFilters.limit = pageSize;
      loadData();
    } else if (isRefreshNoPaging == true) {
      //&&(page==null||page==0))
      sliceDataEntity.getFilters.page = 0;
      sliceDataEntity.getFilters.limit = 0;
      loadData();
    }
  };

  const loadData = () => {
    if (isLoadingData == false) {
      isLoadingData = true;
      sliceDataEntity.dataList.successHandle = loadDataSuccess;
      dispatch(sliceGet(sliceDataEntity));
    }
  };

  const onDelete = (id) => {
    sliceDataEntity.dataDelete.id = id;
    sliceDataEntity.dataDelete.successHandle = () => {
      showMessage(setNotify, "Deleted Successfully");
      loadPage(null, true);
    };
    dispatch(sliceDelete(sliceDataEntity));
    closeConfirmDialog(setConfirmDialog, confirmDialog);
  };
  const onClickDeleteLocal = (event, rowData) => {
    if (sliceDelete) {
      openConfirmDialog(
        setConfirmDialog,
        onDelete,
        rowData.id,
        t("messages.confirm_delete")
      );
    } else if (onClickDelete) {
      onClickDelete(event, rowData);
    }
  };

  const onRowClickLocal = (event, rowData) => {
    if (canEdit == true) {
      if (onRowClick) {
        onRowClick(event, rowData);
      } else {
        onClickEditLocal(event, rowData);
      }
    }
  };

  const onClickEditLocal = (event, rowData) => {
    setShowFormBasic(false);
    if (basicFormData) {
      //setEntityForEdit(null);
      setEntityForEdit(rowData);
      setShowFormBasic(true);
      if (crmGenericBasicForm.current) {
        crmGenericBasicForm.current.refresh(rowData);
      }
    } else {
      if (onClickEdit) {
        onClickEdit(event, rowData);
      }
    }
  };

  const onClickAddLocal = (event) => {
    if (basicFormData) {
      setEntityForEdit(null);
      setShowFormBasic(true);
    } else {
      onClickAdd(event);
    }
  };

  var dataQuery = (query) =>
    new Promise((resolve, reject) => {
      resolve({
        data: dataMaterialTable,
        page: page,
        totalCount: totalCount /*paging?paginator.total:data.length*/,
      });
    });

  if (!isLoaded) {
    isLoaded = true;
  }
  const onClickLogsLocal = (event, rowData) => {
    if (userCanSeeLogs) {
      onCLickLogs(event, rowData);
    }
  };

  const checkHiddenOperation = (rowData, objHidden) => {
    //return false;
    var keyArr = objHidden ? objHidden.key.split(".") : null;
    if (keyArr) {
      var res = rowData;
      keyArr.forEach((element) => {
        res = res[element];
      });
      switch (objHidden.operator) {
        case "=":
          return res == objHidden.value;
          break;
        case "!=":
          return res != objHidden.value;
          break;
        default:
          return false;
          break;
      }
    }
    return false;
  };

  const initActions = () => {
    actions = [];
    if (onClickAdd && canAdd == true) {
      actions.push({
        icon: () => (
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        ),
        //tooltip: translation("utils:crmTableComponent.actions.add"),
        tooltip: t("crmTableComponent.actions.add"),
        //tooltip:"Add",
        isFreeAction: true,
        onClick: (event) => {
          onClickAddLocal(event);
        },
      });
    }
    if (onClickEdit && canEdit == true) {
      actions.push((rowData) => ({
        icon: () => (
          <Controls.IconButton color="primary">
            <EditOutlinedIcon fontSize="small" />
          </Controls.IconButton>
        ),
        //tooltip: translation("utils:crmTableComponent.actions.edit"),
        tooltip: t("crmTableComponent.actions.edit"),
        //tooltip: "Edit",
        onClick: (event, rowData) => {
          onClickEditLocal(event, rowData);
        },
        hidden: checkHiddenOperation(rowData, editHidden),
      }));
    }

    if ((onClickDelete || sliceDelete) && canDelete == true) {
      actions.push((rowData) => ({
        icon: () => (
          <Controls.IconButton color="secondary">
            <CloseIcon fontSize="small" />
          </Controls.IconButton>
        ),
        //tooltip: translation("utils:crmTableComponent.actions.delete"),
        tooltip: t("crmTableComponent.actions.delete"),
        //tooltip: "Delete",
        onClick: (event, rowData) => {
          onClickDeleteLocal(event, rowData);
        },
        hidden: checkHiddenOperation(rowData, deleteHidden) || rowData.paid > 0,
      }));
    }
    if (userCanSeeLogs) {
      actions.push({
        action: (rowData) => {
          return {
            icon: () => (
              <Controls.IconButton color="primary">
                <VisibilityIcon fontSize="small" />
              </Controls.IconButton>
            ),
            //tooltip: translation("utils:crmTableComponent.actions.logs"),
            tooltip: t("Logs"),
            //tooltip: "Logs",
            onClick: (event, rowData) => {
              onClickLogsLocal(event, rowData);
            },
            hidden: checkHiddenOperation(rowData, editHidden),
          };
        },
        position: "row",
      });
    }
    actions = actions.concat(extraActions);
  };
  initActions();

  const openConfirmDialog = (
    setConfirmDialogObj,
    onConfirmHandle,
    onConfirmParam,
    title,
    subtitle = null
  ) => {
    setConfirmDialogObj({
      isOpen: true,
      title: title,
      subTitle: subtitle ? subtitle : t("messages.can_not_undo_operation"),
      onConfirm: () => onConfirmHandle(onConfirmParam),
    });
  };
  const closeConfirmDialog = (setConfirmDialogObj, confirmDialogObj) => {
    setConfirmDialogObj({
      ...confirmDialogObj,
      isOpen: false,
    });
  };
  const showMessage = (notifyObj, msg, type = "success") => {
    notifyObj({
      isOpen: true,
      message: msg,
      type: type,
    });
  };

  const { TblContainer } = useTable();

  const tableRef = React.createRef();

  const onFilterSubmit = (data) => {
    console.log("dataFilter", data);

    let from = data["from"]
      ? new moment(data["from"]).format("YYYY-MM-DD 00:00:00")
      : "";
    let to = data["to"]
      ? new moment(data["to"]).format("YYYY-MM-DD 23:59:59")
      : "";

    var filters = [];
    if (data != null) {
      for (var key in data) {
        var value = data[key];

        if (key == "from" || key == "to") {
          filters["date_between_from_to"] = [from, to];
        } else {
          if (value) {
            if (key == "date_between") {
              const momentDate = new moment(value[0]).format("YYYY-MM-DD");
              const momentDate2 = new moment(value[1]).format("YYYY-MM-DD");
              value = momentDate + "," + momentDate2;
            }

            filters[key] = value;
          }
        }
      }
    }
    if (!userFilter) {
      filters["user_id"] = authUser.id;
    }
    sliceDataEntity.getFilters.filters = filters;

    checkChips();
    loadPage(0);
  };

  /* Show selected filters as chips */
  const checkChips = () => {
    var chips = [];
    if (sliceDataEntity.getFilters.filters) {
      for (var key in sliceDataEntity.getFilters.filters) {
        var value = sliceDataEntity.getFilters.filters[key];
        if (value && filterFormData && filterFormData.filterFields) {
          var itFilter = filterFormData.filterFields.find((x) => x.id == key);
          if (itFilter) {
            var label = itFilter.label;
            var optSelected = itFilter.options
              ? itFilter.options.items.find((x) => x.id == value)
              : null;
            label = optSelected
              ? label + ": " + optSelected[itFilter.options.title]
              : label;
            chips.push({
              key: key,
              label: label,
            });
          }
        }
      }
    }
    setChipsFilterItemsState(chips);
  };

  const onDeleteChipItem = (chipToDelete) => {
    console.log(chipToDelete);
    delete sliceDataEntity.getFilters.filters[chipToDelete.key];
    checkChips();
    loadPage(0);
  };



  const onFormBasicAddOrEdit = (data) => {
    //setEntityForEdit(null);
    //basicFormData.addOrEdit(data);
    data = basicFormData.buildData ? basicFormData.buildData(data) : data;

    if (entityForEdit) {
      sliceData.dataUpdate = {
        id: entityForEdit.id,
        data: data,
        successHandle: addOrEditSuccefull,
      };
      dispatch(basicFormData.sliceUpdate(sliceData));
    } else {
      sliceData.dataCreate.data = data;
      sliceData.dataCreate.successHandle = addOrEditSuccefull;
      dispatch(basicFormData.sliceCreate(sliceData));
    }
  };
  const addOrEditSuccefull = () => {
    showMessage(setNotify, "Submitted Successfully");
    setEntityForEdit(null);
    setShowFormBasic(false);
    //setOpenPopup(false);
  };
  const onFormBasicCancel = (data) => {
    setEntityForEdit(null);
    setShowFormBasic(false);
    //basicFormData.closePopUp();
  };

  var filterForm = "";
  if (filterFormData) {
    filterForm = (
      <Paper className={classes.pageContent}>
        <CrmTableFilter2
          forwardRef={crmTableFilter}
          addOrEdit={onFilterSubmit}
          formFields={filterFormData.filterFields}
          BackNextButtons={false}
        />
        {!chipsArrayNshow && (
          <ChipsArray
            chipsItems={chipsFilterItemsState}
            onDeleteChipItem={onDeleteChipItem}
          ></ChipsArray>
        )}
      </Paper>
    );
  }

  var basicForm = "";
  if (basicFormData) {
    basicForm =
      showFormBasic == true && canEdit == true ? (
        <Paper className={classes.pageContent}>
          <CrmGenericBasicForm
            ref={crmGenericBasicForm}
            entityForEdit={entityForEdit}
            addOrEdit={onFormBasicAddOrEdit}
            formFields={basicFormData.formFields}
            saveLoading={basicFormData.saveLoading}
            closePopUp={onFormBasicCancel}
            inlineControls={true}
          />
        </Paper>
      ) : (
        ""
      );
  }

  var tableComponents = null;
  if (paging == true) {
    tableComponents = {
      Toolbar: (props) => (
        <MTableToolbar
          {...props}
          onSearchChanged={(searchText) => {
            //tableRef.current.onQueryChange();
            onSearchChange(searchText);
          }}
        />
      ),
    };
  }


  return (
    <>
      {filterForm}
      {basicForm}
      <Paper className={classes.pageContent}>
        <TblContainer>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            onRowClick={onRowClickLocal}
            actions={actions}
            localization={{
              header: {
                actions: t("crmTableComponent.actions.title"),
              },
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 1200,
              exportButton: exportButton,
              search: search,
              initialPage: 0,
              pageSize: pageSizeTest,
              pageSizeOptions: dpageSizeOptions
                ? dpageSizeOptions
                : pageSizeOptions,
              loadingType: loadingType,
              searchText: searchText,
              searchAutoFocus: true,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
              rowStyle: (rowData, index) => {
                if (vip) {
                  return {
                    backgroundColor: rowData.VIP ? blue[50] : "#FFF",
                  };
                } else if (rowColorAlternate) {
                  if (index % 2 == 0)
                    return {
                      backgroundColor: rowColorAlternateArray[0].color,
                    };
                  else
                    return { backgroundColor: rowColorAlternateArray[1].color };
                } else "";
              },
            }}
            onChangeRowsPerPage={(pageSize) => onChangeRowsPerPage(pageSize)}
            onChangePage={(e) => onChangePage(e)}
            onSearchChange={(searchText) =>
              console.log("search changed: " + searchText)
            }
            onOrderChange={(order, direction) =>
              onOrderChange(order, direction)
            }
            onQueryChange={(e) => console.log("search changedqqq: " + e)}
            title={title}
            data={paging == true ? dataQuery : dataMaterialTable}
            isLoading={isLoading}
            components={tableComponents}
            detailPanel={detailPanel}
          />
        </TblContainer>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />
    </>
  );
});
export default CrmTable;
