const apiGetFiltersDefault = {
  include: [],
  limit: 0,
  page: 0,
  params: [],
  filters: [],
  search: null,
  sort: null,
};


const CRMUtils = {
  userHasPermission: function (user, routePermissions) {
    if (!routePermissions) return true;

    let hasPermission = false;

    const permissions = user.permissions.data.map((permission) => {
      return permission.name;
    });
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  },
  userHasRole: function (user, routeRoles) {
    if (!routeRoles) return true;

    let hasRole = false;
    const roles = user.roles.data.map((role) => {
      return role.name;
    });
    routeRoles.forEach((role) => {
      if (roles.includes(role)) {
        hasRole = true;
      }
    });

    return hasRole;
  },
  getApiFilters(filtersCustom) {
    const filters = { ...apiGetFiltersDefault };
    if (filtersCustom != null) {
      filters.include =
        filtersCustom.include != null ? filtersCustom.include : filters.include;
      filters.page =
        filtersCustom.page != null ? filtersCustom.page : filters.page;
      filters.limit =
        filtersCustom.limit != null ? filtersCustom.limit : filters.limit;
      filters.params =
        filtersCustom.params != null ? filtersCustom.params : filters.params;
      filters.filters =
        filtersCustom.filters != null ? filtersCustom.filters : filters.filters;
      filters.search =
        filtersCustom.search != null ? filtersCustom.search : filters.search;
      filters.sort =
        filtersCustom.sort != null ? filtersCustom.sort : filters.sort;
    }
    return filters;
  },
};

export default CRMUtils;
