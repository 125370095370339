import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../components/controls";
import AutoCompleteAsyncGeneric from "./AutoCompleteAsyncGeneric";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "../components/controls/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ColorPickerPopup from "./ColorPickerPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "90%",
      margin: theme.spacing(1),
    },
  },
}));

function setupDate(element, control, props) {
  return (
    <Controller
      name={element.id}
      control={control}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={
        props.entityForEdit
          ? props.entityForEdit[element.id]
          : moment(new Date()).format("yyyy-MM-DD")
      }
      as={({ onChange, value }) => (
        <Controls.DatePicker2
          label={element.label}
          shouldCloseOnSelect
          onChange={(date) => {
            const fdate = moment(date).format("yyyy-MM-DD");
            value = fdate;
            onChange(fdate);
          }}
          value={value}
          format="DD/MM/YYYY"
        />
      )}
    />
  );
}
function setupNumber(element, control, props) {
  return (
    <Controller
      name={element.id}
      control={control}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={props.entityForEdit ? props.entityForEdit[element.id] : ""}
      as={<Controls.InputDecoration label={element.label} type="number" />}
      rules={element.required != null ? { required: element.required } : null}
    />
  );
}
function setupMoney(element, control, props) {
  return (
    <Controller
      name={element.id}
      control={control}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={props.entityForEdit ? props.entityForEdit[element.id] : ""}
      as={<Controls.InputMoney label={element.label} />}
      rules={element.required != null ? { required: element.required } : null}
    />
  );
}

function setupSelect(element, control, props) {
  //return setupAutocomplete(element,control,props);
  let options = [];
  if (element.options != null && element.options.items != null) {
    element.options.items.forEach((e) => {
      if (e) {
        options.push({
          id: e[element.options.id],
          title: e[element.options.title],
        });
      }
    });
  }
  return (
    <Controller
      control={control}
      name={element.id}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={props.entityForEdit ? props.entityForEdit[element.id] : ""}
      as={({ onChange, value }) => (
        <Controls.Select
          label={element.label}
          disabled={props.entityForEdit ? !element.enabled : false}
          options={options}
          value={value}
          onChange={(value) => {
            onChange(value);
          }}
        />
      )}
    />
  );
}

function setupSelectMultiple(element, control, props) {
  //return setupAutocomplete(element,control,props);
  let options = [];
  if (element.options != null && element.options.items != null) {
    element.options.items.forEach((e) => {
      options.push({
        id: e[element.options.id],
        title: e[element.options.title],
      });
    });
  }

  let default_value =
    props.entityForEdit && props.entityForEdit[element.id].data
      ? props.entityForEdit[element.id].data.map((e) => e[element.options.id])
      : [];

  return (
    <Controller
      control={control}
      name={element.id}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={default_value}
      as={({ onChange, value }) => (
        <Controls.Select
          label={element.label}
          disabled={props.entityForEdit ? !element.enabled : false}
          options={options}
          value={value}
          onChange={(value) => {
            onChange(value.target.value);
          }}
          multiple={true}
        />
      )}
    />
  );
}

function getFile(imageDataUrl) {
  fetch(imageDataUrl, { mode: "no-cors" }).then((res) => {
    res.arrayBuffer().then((buf) => {
      const file = new File([buf], "image_data_url.jpg", {
        type: "image/jpeg",
      });
      //  console.log("file", file);
      return file;
    });
  });
}

const setupColorPicker = (element, control, props) => {
  const defaultColor = props.defaultColor ? props.defaultColor : "#000000";
  return (
    <Controller
      control={control}
      name={element.id}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={props.entityForEdit ? props.entityForEdit[element.id] : ""}
      render={({ onChange, value }) => (
        <ColorPickerPopup
          value={value}
          onChange={onChange}
          defaultColor={defaultColor}
          label={element.label}
        />
      )}
    />
  );
};

function setupFile(element, control, props) {
  return (
    <div className={"dropzone-sm"}>
      <Controller
        control={control}
        name={element.id}
        as={({ onChange, value }) => (
          <Controls.DropzoneBox
            label="Document"
            //initialFiles={[props.entityForEdit? getFile(props.entityForEdit["documents"]):null]}
            //initialFiles={["https://yt3.ggpht.com/ytc/AAUvwnja9lgs5WpOdw7z08DNC3BKdjdU1g0zPhc9N1Wx=s900-c-k-c0x00ffffff-no-rj"]}
            value={value}
            onChange={(value) => {
              onChange(value[0]);
            }}
          />
        )}
      />
    </div>
  );
}

function setupRichText(element, control, props) {
  return (
    <Controller
      name={element.id}
      control={control}
      disabled={props.entityForEdit ? !element.enabled : false}
      defaultValue={props.entityForEdit ? props.entityForEdit[element.id] : ""}
      as={
        <Controls.RichTextarea
          defaultValue={
            props.entityForEdit ? props.entityForEdit[element.id] : ""
          }
          label={element.label}
        />
      }
      rules={element.required != null ? { required: element.required } : null}
    />
  );
}

const CrmGenericBasicForm = forwardRef((props, ref) => {
  const {
    addOrEdit,
    entityForEdit,
    formFields,
    saveLoading,
    closePopUp,
    inlineControls,
    deleteFileCallBack,
    sliceData,
    sliceGet,
    saveButtonText = null,
  } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  const [checkeds, setCheckeds] = useState([]);
  const [entityForEdit1, setEntityForEdit1] = useState(entityForEdit);
  const [file, setFile] = useState(null);
  const [disableButton, setDisabledButton] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /*const ControlInput = forwardRef((props, ref) => {
    return <Controls.Input ref={ref} />
  });*/
  const onSubmit = async (data) => {
    setDisabledButton(true);
    setIsSaving(true);
    if (isSaving == false) {
      addOrEdit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    refresh(_entityForEdit) {
      setEntityForEdit1(_entityForEdit);
    },
  }));

  function setupAutocomplete(element, control, props) {
    let options = [];
    var itSelectedId = props.entityForEdit
      ? props.entityForEdit[element.id]
      : "";
    var itSelected = "";

    if (element.options != null && element.options.items != null) {
      element.options.items.forEach((e) => {
        var opt = {
          id: e[element.options.id],
          title: e[element.options.title],
        };
        options.push(opt);
        if (opt.id == itSelectedId) {
          itSelected = opt;
        }
      });
    }
    var required = element.required != null ? "required" : "";

    return (
      <Controller
        control={control}
        name={element.id}
        required={required}
        disabled={props.entityForEdit ? !element.enabled : false}
        defaultValue={
          props.entityForEdit ? props.entityForEdit[element.id] : ""
        }
        as={({ onChange, value }) => (
          <Controls.Autocomplete
            label={element.label}
            required={required}
            defaultValue={itSelected}
            disabled={props.entityForEdit ? !element.enabled : false}
            options={options}
            onChange={(value) => {
              var id = value ? value["id"] : null;
              itSelected = value;
              onChange(id);
            }}
          />
        )}
        rules={element.required != null ? { required: element.required } : null}
      />
    );
  }
  const setupAutocompleteAsync = (element, control, props) => {
    var required = element.required != null ? "required" : "";
    return (
      <Controller
        control={control}
        name={element.id}
        required={required}
        disabled={props.entityForEdit ? !element.enabled : false}
        defaultValue={
          props.entityForEdit ? props.entityForEdit[element.id] : ""
        }
        render={({ onChange, value }) => (
          <AutoCompleteAsyncGeneric
            id={element.id}
            label={element.label}
            fullWidth={true}
            required={required}
            sliceData={element.sliceData}
            sliceGet={element.sliceGet}
            options={element.options.items}
            optionsId={element.options.id}
            optionsTitle={element.options.title}
            mapResults={element.options.mapResults}
            onChange={(value) => {
              var id = value ? value["id"] : null;
              //setItSelected(value);
              onChange(id);
            }}
          />
        )}
        rules={element.required != null ? { required: element.required } : null}
      />
    );
  };

  const deleteFile = () => {
    const file = document.getElementById("file");
    const iconFile = document.getElementById("iconFile");
    const buttonDeleteFile = document.getElementById("buttonDeleteFile");
    file.src = "";
    iconFile.style.display = "none";
    buttonDeleteFile.style.display = "none";
    let e = entityForEdit;
    e.fileDelete = true;
    setEntityForEdit1(e);
    if (deleteFileCallBack) {
      deleteFileCallBack();
    }
  };

  const setupImage = (element, control, props) => {
    return (
      <div>
        <img
          width="200"
          id="file"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "";
          }}
          src={props.entityForEdit ? props.entityForEdit[element.id] : ""}
        />{" "}
        <br />
        {props.entityForEdit ? (
          props.entityForEdit[element.id] != "" &&
          props.entityForEdit[element.id] ? (
            <a
              id="iconFile"
              href={props.entityForEdit[element.id]}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton aria-label="Download">
                <AttachFileIcon />
              </IconButton>
            </a>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {props.entityForEdit ? (
          props.entityForEdit[element.id] != "" &&
          props.entityForEdit[element.id] ? (
            <IconButton
              id="buttonDeleteFile"
              aria-label="Clear"
              onClick={deleteFile}
            >
              <ClearIcon />
            </IconButton>
          ) : null
        ) : null}
      </div>
    );
  };

  const onCheckClick = (key, value) => {
    setCheckeds({ ...checkeds, [key]: value });
  };
  const setupCheckBox = (element, control, props) => {
    var checkVal = null;
    checkVal = checkeds[element.id] ? checkeds[element.id] : null;
    checkVal =
      checkVal == null && props.entityForEdit
        ? props.entityForEdit[element.id]
        : false;
    checkVal = checkVal == 1 ? (checkVal = true) : checkVal;
    checkVal = checkVal == 0 ? (checkVal = false) : checkVal;

    if (checkeds[element.id] == null) {
      setCheckeds({ ...checkeds, [element.id]: checkVal });
    }

    return (
      <Controller
        as={({ onChange, value }) => (
          <Controls.Checkbox
            label={element.label}
            defaultValue={checkeds[element.id]}
            checked={checkeds[element.id]}
            value={checkeds[element.id]}
            onChange={(value) => {
              onChange(value);
              onCheckClick(element.id, value);
            }}
          />
        )}
        defaultValue={checkeds[element.id]}
        name={element.id}
        control={control}
        rules={element.required != null ? { required: element.required } : null}
      />
    );
  };

  const setupInput = (element, control, props) => {
    var itValue = "";
    if (props.entityForEdit) {
      itValue = props.entityForEdit[element.id];
    }

    return (
      <Controller
        name={element.id}
        control={control}
        disabled={props.entityForEdit ? !element.enabled : false}
        defaultValue={itValue}
        as={
          <Controls.Input
            label={element.label}
            defaultValue={itValue}
            onChange={(value) => {
              itValue = value;
            }}
          />
        }
        rules={element.required != null ? { required: element.required } : null}
      />
    );
  };
  const setupLabel = (element, control, props) => {
    return (
      <label>
        {" "}
        {element.label}: {element.defaultValue}
      </label>
    );
  };

  const setupVisualElement = (element, control, props) => {
    var res = "";
    switch (element.type) {
      case "input":
        res = setupInput(element, control, props);
        break;
      case "select":
        res = setupSelect(element, control, props);
        break;
      case "selectmultiple":
        res = setupSelectMultiple(element, control, props);
        break;
      case "number":
        res = setupNumber(element, control, props);
        break;
      case "money":
        res = setupMoney(element, control, props);
        break;
      case "date":
        res = setupDate(element, control, props);
        break;
      case "autocomplete":
        res = setupAutocomplete(element, control, props);
        break;
      case "autocompleteasync":
        res = setupAutocompleteAsync(element, control, props);
        break;
      case "file":
        res = setupFile(element, control, props);
        break;
      case "image":
        res = setupImage(element, control, props);
        break;
      case "checkbox":
        res = setupCheckBox(element, control, props);
        break;
      case "label":
        res = setupLabel(element, control, props);
        break;
      case "richText":
        res = setupRichText(element, control, props);
        break;
      case "color":
        res = setupColorPicker(element, control, props);
        break;
      default:
        break;
    }
    return res;
  };

  const items = [];

  useEffect(() => {
    setCheckeds([]);
    setEntityForEdit1(entityForEdit);
  }, []);

  useEffect(() => {
    loadElements();
    //  console.log("fffffff ", formFields);
  }, [formFields]);

  useEffect(() => {
    if (saveLoading == false && disableButton == true) {
      setDisabledButton(false);
      setIsSaving(false);
    }
  }, [saveLoading]);

  const loadElements = () => {
    if (formFields) {
      // console.log("forfff ", formFields);
      formFields.forEach((element, i) => {
        if (element.hidden != true) {
          if (element.enabled == null) {
            element.enabled = true;
          }
          var size = element.size && inlineControls == true ? element.size : 12;
          items.push(
            <Grid key={i} item xs={size}>
              {setupVisualElement(element, control, props)}
              {errors[element.id] && (
                <p className="error">{errors[element.id].message}</p>
              )}
            </Grid>
          );
        }
      });
    }
  };
  loadElements();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        {items}
        <Grid item xs={12}>
          <div>
            <Controls.AdornedButton
              disabled={disableButton}
              type="submit"
              variant="contained"
              color="primary"
              loading={saveLoading}
            >
              {saveButtonText ? saveButtonText : t("genericForm.actions.save")}
            </Controls.AdornedButton>
            <Controls.Button
              text={t("genericForm.actions.cancel")}
              color="default"
              onClick={closePopUp}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
});
export default CrmGenericBasicForm;
