import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";

import { useForm, Controller } from "react-hook-form";

import Controls from "./controls";
import { StateTransitionError } from "sip.js";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [url, seturl] = useState("");
  const [data, setData] = useState([]);
  const [progres, setProgres] = useState(50);
  //  const [isLoading, setIsLoading] = useState(props.isLoading);
  const { handleSubmit, control } = useForm();

  const [openData, setOpenData] = useState(false);
  const [value, setValue] = useState(null);

  const {
    onSubmit,
    isLoading,
    setIsLoading,
    isFinishedUploading,
    isError,
    setIsError,
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitLocal = (data) => {
    setIsError(false);
    onSubmit(data);
    setIsLoading(true);
  };

  return (
    <Dialog
      open={props.openPopup}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <Controls.DropzoneSendPicture
        onChange={(files) => {
          // setAttached(window.URL.createObjectURL(files[0]));
          //  onChange(files[0]);
          //   onSubmit({ attch: files });
          //   if (setOpenData) {
          //     setOpenData(false);
          //   }
          console.log("submitting");
        }}
        setOpenData={setOpenData ? setOpenData : null}
        value={value}
      />
    </Dialog>
  );
}
