import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IconButton, Tooltip } from "@material-ui/core";
import { isEmpty } from "lodash";
import {
  fetchUserFavoriteSites,
  updateUserFavorites,
} from "../redux/slices/userFavoriteSites";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "./Iconify";
import Api from "../lib/api";

export default function BookmarkButton(props) {
  const {
    height,
    weight,
    setBmiform,
    cardHeight = "70px",
    pTop = "5px",
  } = props;
  const { authUser } = useSelector((state) => state.auth);
  const { userFavoriteSites } = useSelector((state) => state.userFavoriteSites);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentPathBookmarked, setIsCurrentPathBookmarked] = useState(false);

  useEffect(() => {
    if (
      userFavoriteSites.length > 0 &&
      userFavoriteSites.find((site) => site.path == window.location.pathname)
    ) {
      setIsCurrentPathBookmarked(true);
    } else {
      setIsCurrentPathBookmarked(false);
    }
  }, [userFavoriteSites, window.location.pathname]);

  useEffect(() => {
    if (isEmpty(userFavoriteSites)) {
      dispatch(fetchUserFavoriteSites(authUser.id));
    }
  }, []);

  const removeRouteFromBookmarks = async () => {
    setIsLoading(true);
    await Api.removeUserFavoriteSite(authUser.id, window.location.pathname);
    dispatch(
      updateUserFavorites([
        ...userFavoriteSites.filter(
          (site) => site.path != window.location.pathname
        ),
      ])
    );
    setIsLoading(false);
  };

  const addRouteToBookmarks = async () => {
    setIsLoading(true);
    const { data } = await Api.createUserFavoriteSite(authUser.id, {
      path: window.location.pathname,
    });
    dispatch(
      updateUserFavorites([
        ...userFavoriteSites.filter(
          (site) => site.path != window.location.pathname
        ),
        data,
      ])
    );
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip
        title={
          isCurrentPathBookmarked ? "Remove from favorites" : "Add to favorites"
        }
      >
        <span>
          <IconButton
            onClick={() => {
              if (isCurrentPathBookmarked) {
                removeRouteFromBookmarks();
              } else {
                addRouteToBookmarks();
              }
            }}
            disabled={isLoading}
          >
            <Iconify
              style={{ fontSize: 24, color: "#4577b7" }}
              icon={
                isLoading
                  ? "line-md:loading-twotone-loop"
                  : isCurrentPathBookmarked
                  ? "uim:star"
                  : "humbleicons:star"
              }
            />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}
