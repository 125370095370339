import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";

import Api from "../../lib/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));

export default function ClickToCallForm(props) {
  const { domain, sourceExt, originationNum, destination, makeACall, destinationName, onClose, setOpenClickToCallMenu } = props;
  const { handleSubmit, setValue, reset, control, errors } = useForm();

  const classes = useStyles();

  const clickToCallExternal = async (domain, sourceExt, originationNum, destination) => {


    const { data } = await Api.originateCallTo(
      domain,
      sourceExt,
      originationNum,
      destination
    );
  };

  const onSubmit = (data) => {
    if (data.call_from == 1) {
        makeACall(destination,destinationName);
    } else if(data.call_from == 2){
        clickToCallExternal(domain, sourceExt, originationNum, destination);
    }
    setOpenClickToCallMenu(false);
    onClose();
    //addOrEdit(data);
  };

  const callOptions = () => {
    return [
      {
        id: 1,
        title: "CRM Web Soft Phone",
      },
      {
        id: 2,
        title: "External Phone",
      },
    ];
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          
          <Controller
            name="call_from"
            control={control}
            defaultValue={1}
            as={({ onChange, value }) => (
              <Controls.Select
                label="Call From?"
                options={callOptions()}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )}
          />
          <p>External Phone allow you to use any other device external to the CRM and configure with your extension.</p>
          <p>When External Phone is selected you will received a call from your own number, this call must be answered by you in the phone you want use, then you must wait for the call to be connected to the contact.</p>
          <div>
            <Controls.Button type="submit" text="Make Call" />
            
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
