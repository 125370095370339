import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const useStyles = makeStyles(() => ({
  root2: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .MuiAutocomplete-listbox": {
      borderRadius: 8,
    },
  },
}));

export default function AutocompleteControl(props) {
  const {
    label,
    options,
    onChange,
    value,
    required = false,    
    ...other
  } = props;

  const classes = useStyles();


  return (
    <Autocomplete      
      fullWidth
      className={classes.root2}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      id={label}
      options={options}
      getOptionSelected={(option, valueL) => option.id === valueL.id}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={label}
          required={required}
          variant="outlined"
          autoComplete="off"
        />
      )}
      {...other}
      value={value}
    />
  );
}
